import React, { KeyboardEvent, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/rootReducer'

import { ReactComponent as SearchIcon } from 'bootstrap-icons/icons/search.svg'
import { ReactComponent as RefreshIcon } from 'bootstrap-icons/icons/arrow-clockwise.svg'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import InlineLoader from '../../../common/components/InlineLoader';
import Loader from '../../../common/components/Loader'
import CompanyMatrixList from './components/CompanyMatrixList'
import { fetchLaboratories } from '../../../state/configSlice'
import { fetchCompaniesByFormulation, fetchExportCompanies, setFilterBy } from '../../../state/companiesSlice'

import { Badge } from 'react-bootstrap';
import { setAuthCurLabId } from '../../../state/authUserSlice';

import { buildSelectNormalStyle } from '../../../common/utils/utils';


import Select from 'react-select'
import { Laboratory } from '../../../api/configApi';
import SelectControl from '../../../common/components/SelectControl';
import userHasRequiredRoles from '../../../common/auth/userHasRequiredRoles';


type CompanyListContainerProps = WithTranslation

const CompanyMatrixListContainer: React.FC<CompanyListContainerProps> = ({ t }) => {
    const dispatch = useDispatch()
    const { user: authUser, authCurLabId } = useSelector((state: RootState) => state.authUser)

    // Laboratories state
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const laboratoriesError = useSelector((state: RootState) => state.config.laboratories.fetchError)

    // Company state
    const filteredCompanies = useSelector((state: RootState) => state.companies.filtered)
    const pagination = useSelector((state: RootState) => state.companies.pagination)
    const companiesStatus = useSelector((state: RootState) => state.companies.fetchStatus)
    const companiesStatusAll = useSelector((state: RootState) => state.companies.fetchAllStatus)
    const fetchError = useSelector((state: RootState) => state.companies.fetchError)
    const companiesByFormulation = useSelector((state: RootState) => state.companies.byFormulation)

    const [currentLaboratoryId, setCurrentLaboratoryId] = useState(authUser.formulations && authUser.formulations.some(formulation => formulation.laboratory_id === authCurLabId) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']) ? authCurLabId : authUser.formulations[0].laboratory_id)

    const [filter, setFilter] = useState("")

    const filterCompany = (s: string) => {
        dispatch(setFilterBy({ ...pagination.filterBy, all: { like: s } }))
    }

    //Catch event 'Enter' key
    function KeyDown(e: KeyboardEvent) {
        if (e.key === 'Enter') {
            filterCompany(filter)
        }
    }

    useEffect(() => {
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }

        if (companiesStatus === 'idle') {
            dispatch(fetchCompaniesByFormulation(currentLaboratoryId))
        }
    }, [laboratoriesStatus, dispatch])

    useEffect(() => {
        if (!companiesByFormulation || !companiesByFormulation[currentLaboratoryId]) {
            dispatch(fetchCompaniesByFormulation(currentLaboratoryId))
        }
    }, [currentLaboratoryId, dispatch])

    return (
        <Container className="justify-content-center">
            <Row className="mt-5">
                <Col xs={4} md={4} lg={4}>
                    <h5 className="d-inline">
                        <Badge pill bg="primary">{Intl.NumberFormat(authUser.locale).format(Object.keys(filteredCompanies).length)}</Badge>
                        <span className="ms-2 align-text-bottom fw-bold">{t('admin.companies.title')}</span>
                    </h5>
                </Col>
                <Col xs={2} md={3} lg={3}>
                    {laboratoriesStatus === 'loading' &&
                        <InlineLoader />}
                            {laboratoriesStatus === 'succeeded' && (() => {
                                const ownedLaboratoriesList = Object.values(laboratories).filter(l => l.lab_type === 'cargill' && ((authUser.formulations && authUser.formulations.some(formulation => formulation.laboratory_id === l.id) && userHasRequiredRoles(['MyProvilab_ROLE_nutrition'])) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])))
                                const ownedLaboratories = Object.fromEntries(ownedLaboratoriesList.map(l => [l.id, l]))
                                const hasMultipleLabs: boolean = ownedLaboratoriesList.length > 1 || (authUser.formulations.length > 0 && userHasRequiredRoles(['MyProvilab_ROLE_nutrition'])) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])

                                if (hasMultipleLabs) {
                                    return (
                                        <SelectControl name="laboratories" items={ownedLaboratories} currentItemId={currentLaboratoryId} onChange={(e) => {
                                            setCurrentLaboratoryId(Number(e.target.value))
                                            dispatch(setAuthCurLabId(Number(e.target.value)))
                                        }}
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })()}
                    {laboratoriesStatus === 'failed' &&
                        <div>{laboratoriesError}</div>}
                </Col>
                <Col xs={4} md={3} lg={3}>
                    <InputGroup>
                        <FormControl
                            placeholder={t('admin.companies.search_placeholder')}
                            aria-label={t('admin.companies.search_button')}
                            onChange={e => setFilter(e.target.value)}
                            onKeyDown={KeyDown}
                            value={filter}
                        />
                        <Button variant="secondary" onClick={() => filterCompany(filter)} >
                            <SearchIcon width="20px" height="100%" className="align-text-top" />
                        </Button>
                    </InputGroup>
                </Col>
                <Col xs={1} md={1} lg={1}>
                    <Button variant="secondary" onClick={() => dispatch(fetchCompaniesByFormulation(currentLaboratoryId))} >
                        <RefreshIcon width="24px" height="100%" className="align-text-top" />
                    </Button>
                </Col>
                <Col>
                    <Button variant="secondary" type="button" onClick={() => dispatch(fetchExportCompanies(currentLaboratoryId))}>
                        <span>{t('buttons.export')}</span>
                    </Button>
                </Col>
            </Row>
            {companiesStatus === 'loading' &&
                <Loader />
            }
            {(companiesStatus === 'succeeded' || companiesStatusAll === 'succeeded') && currentLaboratoryId &&
                <CompanyMatrixList currentLaboratoryId={currentLaboratoryId as number} />
            }
            {companiesStatus === 'failed' &&
                <div className="text-danger">{fetchError}</div>
            }
        </Container>
    )
}

export default withTranslation()(CompanyMatrixListContainer)