import React from 'react';
import Form from 'react-bootstrap/Form';
import { FormikProps } from 'formik';
import { InputInternalUser, InputUser, PdcNotification, SampleNotification } from '../../api/usersApi';
import { Col, Container, FormSelect, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface UserNotificationsProps {
    formikProps: FormikProps<InputInternalUser|InputUser>
}

const sampleStatusOrder = ["received_improper", "received_compliant", "partial", "done"]

const controlPlanStatusOrder = ["published", "closed"]

export const getFilledSampleNotif = (sample_notifs: Array<SampleNotification>, isCreation = false): Array<SampleNotification> => {
    const filled_sample_notifs: SampleNotification[] = []
    const status: string[] = []
    if (sample_notifs) {
        sample_notifs.forEach(sample_notif => {
            status.push(sample_notif.sample_status)
            filled_sample_notifs.push(sample_notif)
        })
    }
    if (!status.includes("received_improper")) {filled_sample_notifs.push({"sample_status": "received_improper", "notif_type": isCreation ? 'unit' : '', "last_mailing": []})}
    if (!status.includes("received_compliant")) {filled_sample_notifs.push({"sample_status": "received_compliant", "notif_type": '', "last_mailing": []})}
    if (!status.includes("partial")) {filled_sample_notifs.push({"sample_status": "partial", "notif_type": '', "last_mailing": []})}
    if (!status.includes("done")) {filled_sample_notifs.push({"sample_status": "done", "notif_type": '', "last_mailing": []})}
    return filled_sample_notifs
}

export const getFilledControlPlanNotif = (control_plan_notifs: Array<PdcNotification>): Array<PdcNotification> => {
    const filled_control_plan_notifs: PdcNotification[] = []
    const status: string[] = []
    if (control_plan_notifs) {
        control_plan_notifs.forEach(control_plan_notif => {
            status.push(control_plan_notif.sample_status)
            filled_control_plan_notifs.push(control_plan_notif)
        })
    }
    if (!status.includes("published")) {filled_control_plan_notifs.push({"sample_status": "published", "notif_type": ''})}
    if (!status.includes("closed")) {filled_control_plan_notifs.push({"sample_status": "closed", "notif_type": ''})}
    return filled_control_plan_notifs
}

export const sanitizeUserNotifications = (user: InputInternalUser|InputUser): InputInternalUser|InputUser => {
    const now = new Date().toISOString()
    const sanitizedUser = {
        ...user,
        sample_notifs: user.sample_notifs.filter(sn => sn.notif_type !== "").map(sn => ({...sn, last_mailing: sn.last_mailing || now})),
        control_plan_notifs: user.control_plan_notifs.filter(cpn => cpn.notif_type !== "")
    }
    return sanitizedUser
}

const UserNotifications: React.FC<UserNotificationsProps> = ({ formikProps }: UserNotificationsProps) => {

    const { t } = useTranslation()
    const { values, handleChange, setFieldValue } = formikProps

    
    const notif_types_array = ["unit","daily","weekly"]

    const toggleSampleNotif = (status: string) => {
        const index = values.sample_notifs.findIndex(sn => sn.sample_status === status)
        if (index > -1) {
            if (values.sample_notifs[index].notif_type !== '') {
                setFieldValue(`sample_notifs[${index}].notif_type`, '')
            } else {
                setFieldValue(`sample_notifs[${index}].notif_type`, 'unit')
            }
        }
    }

    const toggleControlPlanNotif = (status: string) => {
        const index = values.control_plan_notifs.findIndex(sn => sn.sample_status === status)
        if (index > -1) {
            if (values.control_plan_notifs[index].notif_type !== '') {
                setFieldValue(`control_plan_notifs[${index}].notif_type`, '')
            } else {
                setFieldValue(`control_plan_notifs[${index}].notif_type`, 'unit')
            }
        }
    }

    return (
        <Container className="formbox pt-2 pb-2 mt-2">

            <Row>
                <Col lg>
                    <h4 className="text-primary fw-bold">{t('forms.laboratories.notifications')}</h4>
                </Col>
            </Row>
            <Row className="pb-2" ><Col lg className="lineTitle"></Col></Row>
            <Form.Check
                        type="checkbox"
                        name={`mail_partial_attachment`}
                        checked={values.mail_partial_attachment || false}
                        onChange={() => {
                            setFieldValue(`mail_partial_attachment`, !values.mail_partial_attachment)
                        }}
                        label={t('forms.companies.Send_only_reports')}
                    />
            <Form.Label>{t('forms.profile.samples')}</Form.Label>
            <Form.Group controlId="formSamples" className="ps-3" >
                {
                    values.sample_notifs
                        .sort((a, b) => sampleStatusOrder.indexOf(a.sample_status) - sampleStatusOrder.indexOf(b.sample_status))
                        .map((sample_notif, index) => {
                        return (
                            <Row key={sample_notif.sample_status} className="py-0">
                                <Col xs={12} lg={3} xl={2}>
                                    <Row className="d-flex justify-content-between">
                                        <Form.Label className="fw-normal col-auto">• {t(`analysis.status.${sample_notif.sample_status}`)} :</Form.Label>
                                        <Form.Check type="switch" id={`${sample_notif.sample_status}-switch`} className="py-1 col-auto" onChange={() => toggleSampleNotif(sample_notif.sample_status)} checked={sample_notif.notif_type !== '' ? true : false} />
                                    </Row>
                                </Col>
                                {sample_notif.notif_type !== '' &&
                                    <Col lg={4} xl={3}>
                                        <Row className="d-flex">
                                            <Form.Label className="fw-normal col-auto">{t('forms.profile.frequency')}</Form.Label>
                                            <FormSelect
                                                size="sm"
                                                name={`sample_notifs[${index}].notif_type`}
                                                value={values.sample_notifs[index].notif_type}
                                                className="ms-2 me-2 col"
                                                onChange={handleChange}
                                            >
                                                {
                                                    Object.values(notif_types_array).map(notif_type => {
                                                        return (
                                                            <option key={notif_type} value={notif_type}>{t(`forms.profile.notif_type.${notif_type}`)}</option>
                                                        )
                                                    })
                                                }
                                            </FormSelect>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                        )
                    })
                }
            </Form.Group>
            <Form.Label>{t('forms.pdc.title')}</Form.Label>
            <Form.Group controlId="formPdc" className="ps-3">
                {
                    values.control_plan_notifs
                        .sort((a, b) => controlPlanStatusOrder.indexOf(a.sample_status) - controlPlanStatusOrder.indexOf(b.sample_status))
                        .map((control_plan_notif, index) => {
                        return (
                            <Row key={control_plan_notif.sample_status} className="py-0">
                                <Col xs={12} lg={3} xl={2}>
                                    <Row className="d-flex justify-content-between">
                                        <Form.Label className="fw-normal col-auto">• {t(`forms.pdc.status.${control_plan_notif.sample_status}`)} :</Form.Label>
                                        <Form.Check type="switch" id={`${control_plan_notif.sample_status}-switch`} className="py-1 col-auto" onChange={() => toggleControlPlanNotif(control_plan_notif.sample_status)} checked={control_plan_notif.notif_type !== '' ? true : false} />
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })
                }
            </Form.Group>
        </Container>
    )
}

export default UserNotifications
