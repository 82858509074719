import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/rootReducer'

import { useParams } from "react-router-dom";
import { fetchFamilies, fetchImpropers, fetchLaboratories, fetchLanguages, fetchPackages, fetchPatterns, fetchTypes } from '../../../state/configSlice'
import { fetchFarmers } from '../../../state/farmerSlice'
import { fetchEmployees } from '../../../state/employeesSlice'
import { fetchAllCompanies } from '../../../state/companiesSlice'

import Loader from '../../../common/components/Loader';
import Container from 'react-bootstrap/Container';
import { fetchSinglePdc } from '../../../state/pdcSlice';
import AnalysisForm from './components/AnalysisForm';
import { Analysis, NirInternalStatus, AnalysisPackage, AnalysisPackageTests, TypeAnalysisInputResultStatus, AnalysisInputResultStatus} from '../../../api/analysisApi';
import { fetchFields } from '../../../state/fieldsSlice';
import { fetchForms } from '../../../state/formsSlice';
import PricesCalculator from '../../pdc/helpers/PricesCalculator';
import { fetchAnalytes } from '../../../state/analyteSlice';


type PdcUseContainerProps = WithTranslation

interface urlParams {
    pdcId: string,
    laboratoryId: string,
    sampleId: string
}

const PdcUseContainer: React.FC<PdcUseContainerProps> = ({ t }) => {
    const { pdcId, laboratoryId, sampleId } = useParams<urlParams>();

    const dispatch = useDispatch()

    const { user: authUser, fetchStatus: authUserStatus, fetchError: authUserError } = useSelector((state: RootState) => state.authUser)

    // Pdc state
    const controlPlans = useSelector((state: RootState) => state.pdc.data)
    const pdcStatus = useSelector((state: RootState) => state.pdc.singleFetchStatus)
    const pdcError = useSelector((state: RootState) => state.pdc.singleFetchError)

    // Forms & fields state
    const { fetchStatus: formsStatus, fetchError: formsError } = useSelector((state: RootState) => state.forms)
    const { fetchStatus: fieldsStatus, fetchError: fieldsError } = useSelector((state: RootState) => state.fields)

    // patterns state
    const patternsStatus = useSelector((state: RootState) => state.config.patterns.fetchStatus)
    const patternsError = useSelector((state: RootState) => state.config.patterns.fetchError)

    // languages state
    const languagesStatus = useSelector((state: RootState) => state.config.languages.fetchStatus)

    // families state
    const types = useSelector((state: RootState) => state.config.types.data)
    const typesStatus = useSelector((state: RootState) => state.config.types.fetchStatus)
    const typesError = useSelector((state: RootState) => state.config.types.fetchError)

    // families state
    const familiesStatus = useSelector((state: RootState) => state.config.families.fetchStatus)
    const familiesError = useSelector((state: RootState) => state.config.families.fetchError)

    // farmers state
    const farmersStatusByUser = useSelector((state: RootState) => state.farmers.farmer.fetchStatusByUser)
    const farmersError = useSelector((state: RootState) => state.farmers.farmer.fetchError)

    // Package state
    const packagesStatus = useSelector((state: RootState) => state.config.packages.fetchStatus)
    const packagesError = useSelector((state: RootState) => state.config.packages.fetchError)

    //  Laboratories state
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const laboratoriesError = useSelector((state: RootState) => state.config.laboratories.fetchError)

    // analytes state
    const analytesStatus = useSelector((state: RootState) => state.analytes.fetchStatus)
    const analytesError = useSelector((state: RootState) => state.analytes.fetchError)

    // company state
    const companiesAllStatus = useSelector((state: RootState) => state.companies.fetchAllStatus)
    const companiesError = useSelector((state: RootState) => state.companies.fetchError)

    // Employees state
    const employees = useSelector((state: RootState) => state.employees.data)
    const employeesStatus = useSelector((state: RootState) => state.employees.fetchStatus)
    const employeesError = useSelector((state: RootState) => state.employees.fetchError)

    // price lists state
    const priceLists = useSelector((state: RootState) => state.priceList.data);

    // Impropers state
    const impropersStatus = useSelector((state: RootState) => state.config.impropers.fetchStatus)
    const impropersError = useSelector((state: RootState) => state.config.impropers.fetchError)

    const [errorMessage, setErrorMessage] = useState(false)

    const [analysis, setAnalysis] = useState<Analysis | undefined>(undefined)

    useEffect(() => {
        if (fieldsStatus === 'idle') {
            dispatch(fetchFields())
        }
        if (formsStatus === 'idle') {
            dispatch(fetchForms({}))
        }
        if (typesStatus === 'idle') {
            dispatch(fetchTypes())
        }
        if (familiesStatus === 'idle') {
            dispatch(fetchFamilies())
        }
        if (languagesStatus === 'idle') {
            dispatch(fetchLanguages())
        }
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }
        if (farmersStatusByUser === 'idle') {
            dispatch(fetchFarmers())
        }
        if (companiesAllStatus === 'idle') {
            dispatch(fetchAllCompanies())
        }
        if (employeesStatus === 'idle') {
            dispatch(fetchEmployees())
        }
        if (patternsStatus === 'idle') {
            dispatch(fetchPatterns())
        }
        if (packagesStatus === 'idle') {
            dispatch(fetchPackages())
        }
        if (analytesStatus === 'idle') {
            dispatch(fetchAnalytes())
        }
        if (impropersStatus === 'idle') {
            dispatch(fetchImpropers())
        }
        if (typesStatus === 'succeeded' && pdcStatus === 'succeeded' && controlPlans[laboratoryId] && controlPlans[laboratoryId][pdcId] && controlPlans[laboratoryId][pdcId].control_plan_samples) {
            if (!controlPlans[laboratoryId][pdcId].control_plan_samples) {
                setErrorMessage(true)
            } else {
                const packagesAnalysis: AnalysisPackage[] = []

                const sample = controlPlans[laboratoryId][pdcId].control_plan_samples?.filter((item) => item.id === Number(sampleId))[0]
                if (sample) {
                    sample.sample_packages.forEach((arrayItemPackage) => {
                        const aPackage: AnalysisPackage = {
                            package_id: Number(arrayItemPackage.package_id),
                            comment: arrayItemPackage.comment,
                            price: arrayItemPackage.price,
                            currency: arrayItemPackage.currency || '',
                            weight: arrayItemPackage.weight,
                            period: arrayItemPackage.period,
                            analysis_package_tests: []
                        }
                        arrayItemPackage.sample_package_tests.forEach((arrayItemTest) => {
                            const aTest: AnalysisPackageTests = {
                                code_test: arrayItemTest.code_test,
                                code_analyte: arrayItemTest.code_analyte,
                                analysis_group_name: arrayItemTest.analysis_group_name || '',
                                price: arrayItemTest.price,
                                currency: arrayItemTest.currency || '',
                                weight: arrayItemTest.weight,
                                period: arrayItemTest.period,
                                theoretical_value: arrayItemTest.theoretical_value,
                                unit: arrayItemTest.unit || '',
                            }
                            aPackage.analysis_package_tests.push(aTest)
                        })
                        packagesAnalysis.push(aPackage)
                    })

                    const employeesOfCompany = Object.values(employees)
                    .filter(item => (!item.disabled && item.companies.map(c => c.company_id).includes(Number(controlPlans[laboratoryId][pdcId].company_id))))

                    const analysisTemp: Analysis = Object.assign(
                        {
                            id: 0,
                            display_id: '',
                            status: "",
                            employee_id: employeesOfCompany.length === 1 ? Number(employeesOfCompany[0].id) : (employeesOfCompany.find(e => e.id === authUser.employee_id) ? authUser.employee_id : 0),
                            company_id: Number(controlPlans[laboratoryId][pdcId].company_id),
                            laboratory_id: controlPlans[laboratoryId][pdcId].laboratory_id,
                            type_code: types[sample.type_id].code,
                            type_id: Number(sample.type_id),
                            family_id: Number(sample.family_id) > 0 ? Number(sample.family_id) : 0,
                            family_details: "",
                            pattern_id: Number(sample.pattern_id) > 0 ? Number(sample.pattern_id) : 0,
                            price: sample.pattern_price || null,
                            currency: sample.currency || '',
                            cost: sample.pattern_cost || null,
                            period: sample.pattern_period || null,
                            weight: sample.pattern_weight || null,
                            client_reference: "",
                            sample_date: null,
                            delivery_date: null,
                            creation_date: null,
                            receipt_date: null,
                            receipt_glims_date: null,
                            conformity_date: null,
                            validation_date: null,
                            cancellation_date: null,
                            denomination: sample.denomination,
                            comment: "",
                            last_update_by: authUser.id,
                            analysis_packages: packagesAnalysis,
                            id_pdc: Number(pdcId),
                            id_sample: Number(sampleId),
                            urgent:false,
                            nir_code: '',
                            internal_status: '' as NirInternalStatus,
                            input_result_status: AnalysisInputResultStatus.empty as TypeAnalysisInputResultStatus,
                            input_analysis_results: [], 
                            custom_values: [],
                            transports: []
                        },
                        types[sample.type_id].code === "FG" ? { farmer_id: 0, silo: "", harvest_date: null, sample_comment: "" } : null,
                        types[sample.type_id].code === "RM" ? { receipt_date_raw_material: null, provider: "", origin: "", lot: "", factory: "" } : null,
                        types[sample.type_id].code === "FP" ? { manufacturing_date: null, farmer: "", formula_code: "", lot: "", factory: "" } : null,
                    )
                    setAnalysis({ ...analysisTemp })
                }
            }
        }
    }, [dispatch, pdcId, controlPlans, pdcStatus, errorMessage, authUser, companiesAllStatus, farmersStatusByUser, analytesStatus, packagesStatus, typesStatus, familiesStatus, languagesStatus, patternsStatus, impropersStatus, employeesStatus, laboratoriesStatus, sampleId])

    useEffect(() => {
        if (!controlPlans[laboratoryId] || !controlPlans[laboratoryId][pdcId] || !controlPlans[laboratoryId][pdcId].control_plan_samples) {
            dispatch(fetchSinglePdc({ pdcId: Number(pdcId), laboratoryId: Number(laboratoryId) }))
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [controlPlans, dispatch, pdcId, laboratoryId])

    return (
        <Container className="mt-2 p-0">

            {companiesAllStatus === 'succeeded' &&
                employeesStatus === 'succeeded' &&
                typesStatus === 'succeeded' &&
                familiesStatus === 'succeeded' &&
                laboratoriesStatus === 'succeeded' &&
                patternsStatus === 'succeeded' &&
                packagesStatus === 'succeeded' &&
                analytesStatus === 'succeeded' &&
                authUserStatus === 'succeeded' &&
                pdcStatus === 'succeeded' &&
                formsStatus === "succeeded" &&
                fieldsStatus === "succeeded" &&
                analysis !== undefined &&
                errorMessage === false &&
                <Container className="p-0">
                    <Container className="mt-2 p-0">
                        <AnalysisForm analysisDB={analysis} initStepParam={"1"} />
                    </Container>
                </Container>
            }

            {(
                analysis === undefined ||
                pdcStatus === 'loading' ||
                impropersStatus === 'loading' ||
                companiesAllStatus === 'loading' ||
                laboratoriesStatus === 'loading' ||
                employeesStatus === 'loading' ||
                typesStatus === 'loading' ||
                familiesStatus === 'loading' ||
                patternsStatus === 'loading' ||
                packagesStatus === 'loading' ||
                analytesStatus === 'loading' ||
                authUserStatus === 'loading' ||
                formsStatus === "loading" ||
                fieldsStatus === "loading" ||
                farmersStatusByUser === 'loading'
            ) &&
                <Loader />
            }
            {(!controlPlans[laboratoryId] || !controlPlans[laboratoryId][pdcId] || !controlPlans[laboratoryId][pdcId].control_plan_samples) && (
                pdcStatus === 'failed' ||
                impropersStatus === 'failed' ||
                companiesAllStatus === 'failed' ||
                employeesStatus === 'failed' ||
                typesStatus === 'failed' ||
                familiesStatus === 'failed' ||
                laboratoriesStatus === 'loading' ||
                patternsStatus === 'failed' ||
                packagesStatus === 'failed' ||
                analytesStatus === 'failed' ||
                authUserStatus === 'failed' ||
                formsStatus === "failed" ||
                fieldsStatus === "failed" ||
                farmersStatusByUser === 'failed'
            ) &&
                <Container className="mt-2 bg-white text-danger">
                    {pdcError && <p>{t('forms_errors.unexpected_error') + ' (' + pdcError + ')'}</p>}
                    {typesError && <p>{t('forms.analysis.type') + ' - ' + t('forms_errors.unexpected_error') + ' (' + typesError + ')'}</p>}
                    {familiesError && <p>{t('forms.analysis.family') + ' - ' + t('forms_errors.unexpected_error') + ' (' + familiesError + ')'}</p>}
                    {laboratoriesError && <p>{t('forms.analysis.laboratory') + ' - ' + t('forms_errors.unexpected_error') + ' (' + laboratoriesError + ')'}</p>}
                    {patternsError && <p>{t('forms.analysis.pattern') + ' - ' + t('forms_errors.unexpected_error') + ' (' + patternsError + ')'}</p>}
                    {farmersError && <p>{t('forms.analysis.farmer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + farmersError + ')'}</p>}
                    {(packagesError || analytesError) && <p>{t('forms.analysis.management_packagesTests') + ' - ' + t('forms_errors.unexpected_error') + ' (' + (packagesError !== "undefined" ? packagesError : "") + (analytesError !== "undefined" ? analytesError : "") + ')'}</p>}
                    {authUserStatus && <p>{t('forms.analysis.user') + ' - ' + t('forms_errors.unexpected_error') + ' (' + authUserError + ')'}</p>}
                    {companiesError && <p>{t('forms.analysis.company') + ' - ' + t('forms_errors.unexpected_error') + ' (' + companiesError + ')'}</p>}
                    {employeesStatus && <p>{t('forms.analysis.technician') + ' - ' + t('forms_errors.unexpected_error') + ' (' + employeesError + ')'}</p>}
                    {impropersError && <p>{t('forms.analysis.impropers.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + impropersError + ')'}</p>}
                    {fieldsError && <p>{"Custom fields" + ' - ' + t('forms_errors.unexpected_error') + ' (' + fieldsError + ')'}</p>}
                    {formsError && <p>{"Custom forms" + ' - ' + t('forms_errors.unexpected_error') + ' (' + formsError + ')'}</p>}
                </Container>
            }

            {errorMessage &&
                <Container className="mt-2 bg-white text-danger">
                    <p>{t('home.analysis_request_ID_not_found')}</p>
                </Container>
            }

        </Container>
    )
}

export default withTranslation()(PdcUseContainer)