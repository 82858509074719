import { createSlice, PayloadAction, Action, createAsyncThunk } from '@reduxjs/toolkit'
import { Language } from '../api/configApi';

import UsersApi, { Employee, InputInternalUser, InputUser, InternalUser, User } from '../api/usersApi';
import { Data } from '../types/types';
import { CurrentUser, userUpdate } from './authUserSlice';
import { addEmployee, updateEmployee } from './employeesSlice';


export interface Page {
    ids: Array<string>
    fetching: boolean
    after: string|undefined
}

export interface Pagination {
    nbByPage: number
    nbPage: number
    currentPage: number
    pages: Data<Page>
    count: number
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: string | undefined
}

interface CurrentState {
    data: Data<User>
    filtered: Array<string>
    pagination: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postError: string | undefined
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 100,
        nbPage: 1,
        currentPage: 1,
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    fetchStatus: 'idle',
    fetchError: undefined,
    singleFetchStatus: 'idle',
    singleFetchError: undefined,
    postStatus: 'idle',
    postError: undefined
} as CurrentState

export const sanitizeUser = (user: InputInternalUser|InputUser): InputInternalUser|InputUser => {
    const now = new Date().toISOString()
    const sanitizedUser = {
        ...user,
        language_id: Number(user.language_id),
        sample_notifs: user.sample_notifs.filter(sn => sn.notif_type !== "").map(sn => ({...sn, last_mailing: sn.last_mailing || now})),
        control_plan_notifs: user.control_plan_notifs.filter(cpn => cpn.notif_type !== "")
    }
    return sanitizedUser
}

export const fetchUsers = createAsyncThunk(
    'users/fetchUsers', 
    async (args: {page: number, disabled: boolean}, {getState}) => {
        const globalState: any = getState()
        const pagination: Pagination = globalState.users.pagination

        const after = args.page > 1 && pagination.pages[args.page-1].after ? pagination.pages[args.page-1].after : undefined

        const response = await UsersApi.getInstance().getUsers(args.disabled, pagination.nbByPage, after)
        return response
    }
)

export const fetchSingleUser = createAsyncThunk(
    'users/fetchSingleUser', 
    async (externalId: string) => {
        const response = await UsersApi.getInstance().getUserByExternalId(externalId)
        return response as User
    }
)

export const fetchSingleUserById = createAsyncThunk(
    'users/fetchSingleUserById', 
    async (id: number) => {
        const response = await UsersApi.getInstance().getUserById(id)
        return response as User
    }
)

export const updateInternalUser = createAsyncThunk<InternalUser, {internalUser: InputInternalUser, id: number}>(
    'users/updateInternalUser', 
    async ({internalUser, id}: {internalUser: InputInternalUser, id: number}, { dispatch, getState }) => {

        const globalState: any = getState()
        const authUser: CurrentUser = globalState.authUser.user
        const languages: Data<Language> = globalState.config.languages.data

        const user = sanitizeUser(internalUser)

        if (id) {
            const retInternalUser = await UsersApi.getInstance().putInternalUser(id, user)
            const retUser = await UsersApi.getInstance().getUserById(id)

            dispatch(updateUser(retUser))

            const updatedEmployee: Employee = {
                id: retInternalUser.employee_id,
                firstname: retInternalUser.firstname,
                lastname: retInternalUser.lastname,
                disabled: retInternalUser.disabled,
                code: retInternalUser.code,
                customers: retInternalUser.customers,
                companies: retInternalUser.companies,
                formulations: retInternalUser.formulations,
                role_levels: retInternalUser.role_levels,
                pdc_levels: retInternalUser.pdc_levels,
                all_customers_laboratories: retInternalUser.all_customers_laboratories,
                mail_partial_attachment : retInternalUser.mail_partial_attachment
            }

            dispatch(updateEmployee(updatedEmployee))

            if (authUser.external_id === retInternalUser.external_id) {
                const currentUser: CurrentUser = {
                    ...retInternalUser,
                    locale: languages[retInternalUser.language_id].code_language
                }
                dispatch(userUpdate(currentUser))
            }
            
            return retInternalUser
        } else {
            throw new Error("Cannot update user without id")
        }
    }
)

export const upsertUser = createAsyncThunk<User, {inputUser: InputUser, id?: number, external_id?: string}>(
    'users/upsertUser', 
    async ({inputUser, id, external_id}: {inputUser: InputUser, id?: number, external_id?: string}, { dispatch, getState }) => {

        const globalState: any = getState()
        const authUser: CurrentUser = globalState.authUser.user
        const languages: Data<Language> = globalState.config.languages.data

        const user = sanitizeUser(inputUser)

        let retUser: User
        if (id) {
            retUser = await UsersApi.getInstance().putUser(id, user)
        } else if (external_id) {
            retUser = await UsersApi.getInstance().postUserFromExternal(external_id, user)
        } else {
            retUser = await UsersApi.getInstance().postUser(user)
        }

        dispatch(updateUser(retUser))

        const employee: Employee = {
            id: retUser.employee_id,
            firstname: retUser.firstname,
            lastname: retUser.lastname,
            disabled: retUser.disabled,
            code: retUser.code,
            customers: retUser.customers,
            companies: retUser.companies,
            formulations: retUser.formulations,
            role_levels: retUser.role_levels,
            pdc_levels: retUser.pdc_levels,
            all_customers_laboratories: retUser.all_customers_laboratories,
            mail_partial_attachment: retUser.mail_partial_attachment
        }

        if (inputUser.employee_id > 0) {
            dispatch(updateEmployee(employee))
        } else {
            dispatch(addEmployee(employee))
        }

        if (authUser.external_id === retUser.external_id) {
            const currentUser: CurrentUser = {
                ...retUser,
                locale: languages[retUser.language_id].code_language
            }
            dispatch(userUpdate(currentUser))
        }
        
        return retUser
    }
)

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        addUser(state: CurrentState, action: PayloadAction<User>) {
            const item = action.payload
            if (item.id)
                state.data[item.external_id] = item
        },
        updateUser(state: CurrentState, action: PayloadAction<User>) {
            const item = action.payload
            if (item.id)
                state.data[item.external_id] = item
        },
        deactivateUser(state: CurrentState, action: PayloadAction<string>) {
            const externalId = action.payload
            state.data[externalId].disabled = true
        },
        activateUser(state: CurrentState, action: PayloadAction<string>) {
            const externalId = action.payload
            state.data[externalId].disabled = false
        },
        deleteUserByExternalId(state: CurrentState, action: PayloadAction<string>) {
            const externalId = action.payload
            if (externalId) {
                delete state.data[externalId]
                const index = state.filtered.indexOf(externalId)
                if (index > -1) {
                    state.filtered.splice(index, 1)
                }
            }
        },
        setFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload
        },
        resetSingleFetch(state: CurrentState) {
            state.singleFetchStatus = 'idle'
            state.singleFetchError = undefined
        },
        setNbByPage(state: CurrentState, action: PayloadAction<number>) {
            state.pagination.nbByPage = action.payload
        },
        setCurPage(state: CurrentState, action: PayloadAction<number>) {
            state.pagination.currentPage = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchUsers.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            action.payload.data.forEach((item: User) => {
                if (item.external_id)
                    state.data[item.external_id] = item
            })

            const page = action.meta.arg.page

            if (page === 1) {
                state.pagination.nbPage = 1
                state.pagination.currentPage = 1
                state.pagination.pages = {}
                state.pagination.count = 0
            }

            const count = (page > 1 ? state.pagination.count : 0) + action.payload.data.length
            state.pagination.count = count
            state.pagination.nbPage = (page > 1 ? state.pagination.nbPage : 1) + (action.payload.after ? 1 : 0)

            state.pagination.pages[page] = {
                ids: action.payload.data.map(item => item.external_id),
                fetching: false,
                after: action.payload.after
            }
            state.pagination.currentPage = page

            state.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchUsers.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
        builder.addCase(fetchSingleUser.pending, (state) => {
            state.singleFetchStatus = 'loading'
        })
        builder.addCase(fetchSingleUser.fulfilled, (state, action) => {
            const item = action.payload
            if (item && item.external_id) {
                state.data[item.external_id] = item
            }
            state.singleFetchStatus = 'succeeded'
        })
        builder.addCase(fetchSingleUser.rejected, (state, action) => {
            state.singleFetchStatus = 'failed'
            state.singleFetchError = action.error.message
        })
        builder.addCase(fetchSingleUserById.pending, (state) => {
            state.singleFetchStatus = 'loading'
        })
        builder.addCase(fetchSingleUserById.fulfilled, (state, action) => {
            const item = action.payload
            if (item && item.external_id) {
                state.data[item.external_id] = item
            }
            state.singleFetchStatus = 'succeeded'
        })
        builder.addCase(fetchSingleUserById.rejected, (state, action) => {
            state.singleFetchStatus = 'failed'
            state.singleFetchError = action.error.message
        })
    }
  })

  export const {
    addUser,
    updateUser,
    deactivateUser,
    activateUser,
    deleteUserByExternalId,
    setFiltered,
    resetSingleFetch,
    setNbByPage,
    setCurPage
  } = usersSlice.actions

  export default usersSlice.reducer