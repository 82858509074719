import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../state/rootReducer'
import Container from 'react-bootstrap/Container';
import ControlPlanForm from './components/ControlPlanForm'
import { Alert } from 'react-bootstrap';


import { useParams } from "react-router-dom";
import { fetchSinglePdc } from '../../state/pdcSlice';

import Loader from '../../common/components/Loader';
import { fetchLaboratories } from '../../state/configSlice';
import { fetchPatternFamilies } from '../../state/patternsFamiliesSlice';
import { fetchPackagesPatterns } from '../../state/packagesPatternsSlice';
import { fetchAnalyteTestPackages } from '../../state/analyteTestsPackagesSlice';
import { fetchPriceLists } from '../../state/priceListSlice';
import { setAuthCurLabId } from '../../state/authUserSlice';

type PdcContainerProps = WithTranslation

interface urlParams {
    pdcId: string,
    laboratoryId: string
}

const PdcContainer: React.FC<PdcContainerProps> = ({ t }) => {
    const { pdcId, laboratoryId } = useParams<urlParams>();

    const dispatch = useDispatch()

    // Pdc state
    const controlPlans = useSelector((state: RootState) => state.pdc.data)
    const pdcStatus = useSelector((state: RootState) => state.pdc.singleFetchStatus)
    const pdcError = useSelector((state: RootState) => state.pdc.singleFetchError)
    
    // user state
    const { user: authUser, authCurLabId } = useSelector((state: RootState) => state.authUser)

    function fetchRelationship() {
        dispatch(fetchPatternFamilies({ laboratoryId: Number(authCurLabId) }));
        dispatch(fetchPackagesPatterns({ laboratoryId: Number(authCurLabId) }));
        dispatch(fetchAnalyteTestPackages({ laboratoryId: Number(authCurLabId) }));
    }
    // PriceList state
    const priceListStatus = useSelector((state: RootState) => state.priceList.fetchStatus)

    //  Laboratories state
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)

    const [curId, setCurId] = useState<string|undefined>(undefined)

    useEffect(() => {
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }
        if(Number(pdcId) !== 0 && (pdcStatus === 'idle' || (!controlPlans[laboratoryId] || !controlPlans[laboratoryId][pdcId] || !controlPlans[laboratoryId][pdcId].control_plan_samples)) && pdcId !== curId ){
            dispatch(fetchSinglePdc({ pdcId: Number(pdcId), laboratoryId: Number(laboratoryId) }))
        }
        setCurId(pdcId)
    }, [controlPlans, curId, laboratoriesStatus, pdcId, laboratoryId, pdcStatus])

    useEffect(() => {
        if(Number(authCurLabId)){
            fetchRelationship();
            dispatch(fetchPriceLists({laboratoryId: Number(authCurLabId)}));
        }
    }, [authCurLabId])

    useEffect(() => {
        if(Number(laboratoryId) !== 0){
            dispatch(setAuthCurLabId(Number(laboratoryId)))
        }
    }, [])

    return (
        <Container className="p-0 pdc-container">
            { Number(pdcId) !== 0 && laboratoriesStatus === 'succeeded' && priceListStatus === 'succeeded' && controlPlans !== undefined && controlPlans[laboratoryId] && controlPlans[laboratoryId][pdcId] && controlPlans[laboratoryId][pdcId].control_plan_samples &&

                <Container className="mt-2 p-0 pdc-container">
                    <ControlPlanForm dataKey={{labId: laboratoryId, id: pdcId}} />
                </Container>
            }
            { Number(pdcId) === 0 && laboratoriesStatus === 'succeeded' && priceListStatus === 'succeeded' && 
                <Container className="mt-2 p-0 pdc-container">
                    <ControlPlanForm dataKey={{labId: "0", id: "0"}} />
                </Container>
            }

            { (pdcStatus === 'loading' || laboratoriesStatus === 'loading' || priceListStatus === 'loading')  &&
                <Loader />
            }

            { (pdcStatus === 'failed' || laboratoriesStatus === 'failed' || priceListStatus === 'failed') &&
                <Container className="mt-2 bg-white text-danger">
                    {pdcError && <p>{t('forms_errors.unexpected_error') + ' (' + pdcError + ')'}</p>}
                </Container>
            }
        </Container>
    )
}

export default withTranslation()(PdcContainer)