import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import { Data, FilterBy, Pagination, SortBy } from "../types/types";
import DashboardApi, { DashboardJobFrequencies } from "../api/dashboardApi";
import { fetchAlert } from "./alertSlice";

interface CurrentState {
    data: Data<DashboardJobFrequencies>;
    filtered: Array<string>;
    pagination: Pagination;
    fetchStatus: "idle" | "loading" | "succeeded" | "failed";
    fetchError: string | undefined;
    singleFetchStatus: "idle" | "loading" | "succeeded" | "failed";
    singleFetchError: string | undefined;
    postStatus: "idle" | "loading" | "succeeded" | "failed";
    postError: string | undefined;
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    byCompany: {},
    fetchStatus: "idle",
    fetchError: undefined,
    singleFetchStatus: "idle",
    singleFetchError: undefined,
    postStatus: "idle",
    postError: undefined,
} as CurrentState;

export const fetchDashboardJobFrequencies = createAsyncThunk(
    "fetchDashboardJobFrequencies",
    async (_, { dispatch }) => { 
        dispatch(purgeReducer())

        const response = await DashboardApi.getInstance().getDashboardJobFrequencies();
        return response as Array<DashboardJobFrequencies>;
    }
); 

export const putDashboardJobFrequencies = createAsyncThunk(
    "putDashboardJobFrequencies",
    async (dashboardJobFrequencies: DashboardJobFrequencies[]) => {
        const response = await DashboardApi.getInstance().putDashboardJobFrequencies(
            dashboardJobFrequencies
        );

        return response;
    }
);

const dashboardJobFrequenciesSlice = createSlice({
    name: "dashboardJobFrequencies",
    initialState,
    reducers: {
        purgeReducer(state: CurrentState, action: PayloadAction<void>) {

            state.data = {}
            state.filtered = []
        },

        setFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload;

        },
        setFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload };
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all;
            }
            state.pagination.filterBy = filterBy;
        },
        setSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload;
        },
        refresh(state: CurrentState) {
            state.fetchStatus = "idle";
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchDashboardJobFrequencies.pending, (state) => {
            state.fetchStatus = "loading";
        });
        builder.addCase(fetchDashboardJobFrequencies.fulfilled, (state, action) => {

            action.payload.forEach((item: DashboardJobFrequencies) => {
                if (item.id) state.data[item.id] = item
            });
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchDashboardJobFrequencies.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
    },
});

export const {
    setFiltered,
    setFilterBy,
    setSortBy,
    purgeReducer,
} = dashboardJobFrequenciesSlice.actions;

export default dashboardJobFrequenciesSlice.reducer;