import React from 'react';
import Row from 'react-bootstrap/Row'
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Button } from 'react-bootstrap';


interface UploadMatrixExcelFileProp  extends WithTranslation {
    uploadAction:any,
    type: string
}

const UploadMatrixExcelFile: React.FC<UploadMatrixExcelFileProp> = ({t,uploadAction, type}) => {

    const SUPPORTED_TYPES = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]

    const onExcelFileChange = (file : any) => {
        uploadAction(file);       
    };

    return (
        <Form.Group controlId={type} className="mb-2" >
            <Button className="btn btn-xs" variant="secondary" type="button">
                <Form.Label className="m-0 fw-normal px-1 hover-button">
                    {t('buttons.import')}
                </Form.Label>
            </Button>     
            <Form.Control 
                style={{display:'none'}} 
                className='me-1' 
                type="file" 
                accept={SUPPORTED_TYPES.join(',')}
                onChange={onExcelFileChange}
            />                 
        </Form.Group>
    )
}

export default withTranslation()(UploadMatrixExcelFile)
