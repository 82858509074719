import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import ConfigApi, { Form, FormFilter } from '../api/configApi';
import { Data } from '../types/types';

interface CurrentState {
    data: Data<Form>
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postError: string | undefined
}

const initialState = {
    data: {},
    byCompany: {},
    fetchStatus: 'idle',
    fetchError: undefined,
    singleFetchStatus: 'idle',
    singleFetchError: undefined,
    postStatus: 'idle',
    postError: undefined
} as CurrentState

export const fetchForms = createAsyncThunk(
    'forms',
    async (formFilter: FormFilter) => {
        const response = await ConfigApi.getInstance().getForms(formFilter)
        return response as Array<Form>
    }
)


const employeesSlice = createSlice({
    name: 'fields',
    initialState,
    reducers: {
        addForm(state: CurrentState, action: PayloadAction<Form>) {
            const item = action.payload
            if (item && item.id) {
                state.data[item.id] = item
            }
        },
        updateForm(state: CurrentState, action: PayloadAction<Form>) {
            const item = action.payload
            if (item.id)
                state.data[item.id] = item
        },
        deleteForm(state: CurrentState, action: PayloadAction<Form>) {
            const item = action.payload
            if (item.id)
                delete state.data[item.id];
        }
        
    },
    extraReducers: builder => {
        builder.addCase(fetchForms.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchForms.fulfilled, (state, action) => {
            action.payload.forEach((item: Form) => {
                if (item.id)
                    state.data[item.id] = item
            })
            state.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchForms.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
    }
})

export const {
    addForm,
    updateForm,
} = employeesSlice.actions

export default employeesSlice.reducer