import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import MatrixApi, { AutocalcCallState, MatrixTemplate } from '../api/matrixApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';
import exp from 'constants';

interface CurrentState {
    data: Data<AutocalcCallState>;
    filtered: Array<string>;
    pagination: Pagination;
    fetchStatus: "idle" | "loading" | "succeeded" | "failed";
    fetchError: string | undefined;
    singleFetchStatus: "idle" | "loading" | "succeeded" | "failed";
    singleFetchError: string | undefined;
    postStatus: "idle" | "loading" | "succeeded" | "failed";
    postError: string | undefined;
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    byCompany: {},
    fetchStatus: "idle",
    fetchError: undefined,
    singleFetchStatus: "idle",
    singleFetchError: undefined,
    postStatus: "idle",
    postError: undefined,
} as CurrentState;



export const fetchAutocalcCallState = createAsyncThunk(
    'matrix/fetchAutocalcCallState',
    async ({ companyId, exportCode, version}: { companyId: string, exportCode: string, version: string}) => {/* *|MARCADOR_CURSOR|* */

        const response = await MatrixApi.getInstance().getAutocalcCallState(Number(companyId), encodeURIComponent(exportCode), Number(version))
        return response as AutocalcCallState
    }
)

const autocalcCallState = createSlice({
    name: "autocalcCallState",
    initialState,
    reducers: {
        upsertAutocalcCallState(
            state: CurrentState,
            action: PayloadAction<AutocalcCallState>
        ) {
            const item = action.payload;
            if (item.company_id && item.export_code && item.version) state.data[item.company_id+"#"+item.export_code+"#"+item.version] = item;
        },

        setAutocalcCallStateFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload;

        },
        setAutocalcCallStateFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload };
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all;
            }
            state.pagination.filterBy = filterBy;
        },
        setAutocalcCallStateSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload;
        },
        refreshAutocalcCallState(state: CurrentState) {
            state.fetchStatus = "idle";
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchAutocalcCallState.pending, (state) => {

            state.fetchStatus = "loading";
        });
        builder.addCase(fetchAutocalcCallState.fulfilled, (state, action) => {
            const item: AutocalcCallState = action.payload
                let key = ""
                if(item.company_id && item.export_code && item.version) {
                    key = item.company_id+'#'+item.export_code+'#'+item.version
                    state.data[key] = item
                    state.data[key].id = key
                }
            
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchAutocalcCallState.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
    },
});

export const {

} = autocalcCallState.actions

export default autocalcCallState.reducer