import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import ApiError from '../common/utils/exceptions/ApiError';

class AbstractApi { 

    protected service: AxiosInstance

    protected constructor(url: string, token: string, timeout: number = 60000) {
        if (this.constructor === AbstractApi) {
            throw new TypeError('Abstract class "AbstractApi" cannot be instantiated directly');
        }
        this.service = axios.create({
            baseURL: url,
            timeout: timeout,
            headers: {
                'Authorization': 'Bearer '+token
            }
        })

        axiosRetry(this.service, {
            retries: 3,
            retryDelay: axiosRetry.exponentialDelay
        })
    }

    protected static handleError(err: unknown): unknown {
        if (axios.isAxiosError(err)) {
            if (err.response) {
                if (err.response.data && err.response.data.error) {
                    const error = err.response.data.error
                    return new ApiError(err.response.status, error.type, error.message, error.errors)
                } 
            } else if (err.request) {
                return new Error('Network error')
            }
        }
        return err
    }

    protected static async handleBlobError(err: unknown): Promise<unknown> {
        if (axios.isAxiosError(err)) {
            if (err.response) {
                let data = err.response.data
                if (err.request.responseType === "blob") {
                    data = JSON.parse(await data.text());
                }
                if (data && data.error) {
                    const error = data.error
                    return new ApiError(err.response.status, error.type, error.message, error.errors)
                } 
            } else if (err.request) {
                return new Error('Network error')
            }
        }
        return err
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static initInstance(token: string) : void {
        throw new Error('You must implement this function to create instance with URL');
    }
}

export default AbstractApi