import React, { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterEnum, Filter } from '../../types/types';
import { Button, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import { isUndefined } from 'util';

interface FilterFormProps extends React.ComponentPropsWithoutRef<'form'> {
    name: string
    type: Array<"label" | "date" | "filter" | "list" | "check" | "number" | 'boolean'>
    filter?: Filter
    filterByEnum?: FilterEnum[]
    boolLabels?: { [key in 'true' | 'false']: string }
    filterReducer: (name: string, filter: Filter | undefined) => void
    addFilter?: (name: string, filter: Filter | undefined) => void
    children?: React.ReactNode
    style?: CSSProperties
    className?: string
    'aria-labelledby'?: string
    size?: "xs" | "sm"
    maxHeight?: string
    mergeTwin?: boolean
}

const FilterForm: React.FC<FilterFormProps> = React.forwardRef<HTMLFormElement, FilterFormProps>(function FilterForm({ name, type, filter: _filter, filterByEnum, boolLabels, filterReducer, addFilter, children, style, className, 'aria-labelledby': labeledBy, size = "sm",maxHeight, mergeTwin }: FilterFormProps, ref) {

    const { t } = useTranslation()
    const [filter, setFilter] = useState<Filter | undefined>(_filter)

    const styleForm = { maxHeight: maxHeight }

    React.useEffect(() => {
        if (!addFilter) {
            setFilter(_filter)
        }
    }, [_filter])

    const validateForm = () => {
        filterReducer(name, filter)
        if (addFilter) {
            addFilter(name, filter)
        }
    }

    //Supprimer les doublons de la liste affichée
    const uniqueFilterByEnumSet = new Set();

    const uniqueFilterByEnum = filterByEnum?.filter(item => {
        const duplicate = uniqueFilterByEnumSet.has(item.label);
        uniqueFilterByEnumSet.add(item.label);
        return !duplicate;
    });

    const toggleSelected = (value: string) => {
        // Vérifier si filterByEnum est défini
        if (!filterByEnum) return;
    
        let updated: Filter | undefined = { ...filter };
    
        // Si mergeTwin est vrai, on traite les doublons
        if (mergeTwin) {
            // Trouver le label correspondant au name sélectionné
            const selectedLabel = filterByEnum.find(item => item.name === value)?.label;
    
            if (!selectedLabel) return;
    
            // Trouver tous les names ayant le même label
            const relatedNames = filterByEnum
                .filter(item => item.label === selectedLabel)
                .map(item => item.name);
    
            if (filter && filter.in) {
                const inClause = filter.in;
                // Vérifier si tous les relatedNames sont déjà dans inClause
                const allSelected = relatedNames.every(name => inClause.includes(name));
    
                if (allSelected) {
                    // Supprimer tous les relatedNames du filtre
                    updated = { in: inClause.filter(item => !relatedNames.includes(item.toString())) };
                } else {
                    // Ajouter tous les relatedNames au filtre
                    updated = { in: [...new Set([...filter.in, ...relatedNames])] };
                }
            } else {
                // Créer un nouveau filtre avec les relatedNames
                updated = { in: relatedNames };
            }
        } else {
            // Traitement de base sans traiter les doublons
            if (filter && filter.in) {
                const inClause = filter.in;
                if (inClause && inClause.filter(item => item === value).length > 0) {
                    updated = { in: inClause.filter(item => item !== value) };
                } else {
                    updated = { in: [...filter.in, value] };
                }
            } else {
                updated = { in: [value] };
            }
        }
    
        if (updated.in && updated.in.length === 0) {
            updated = undefined;
        }
    
        setFilter(updated);
        if (!addFilter) {
            filterReducer(name, updated);
        }
    };

    function handleKeyDown(): React.KeyboardEventHandler<HTMLInputElement> {
        return (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                const element = e.target as HTMLInputElement
                filterReducer(name, { ...filter, like: element.value })
                validateForm()
            }
        }
    }

    const inClause = filter && filter.in;
    const gteClause = filter && filter.gte;
    const lteClause = filter && filter.lte;

    return (
        <Form onSubmit={e => { e.preventDefault(); }}
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
        >
            <div className={"overflow-auto p-0 m-0"} style={maxHeight ? styleForm : undefined}>
                {type.includes("filter") &&
                    <Form.Group className="mb-1">
                        <Form.Control size="sm" type="text" placeholder={t('sortFilter.filter_placeholder')}
                            value={filter && filter.like ? filter.like : ""}
                            onChange={e => {
                                let updated
                                if (e.target.value !== "") {
                                    updated = { ...filter, like: e.target.value }
                                } else {
                                    updated = { ...filter }
                                    delete updated.like
                                    if (Object.keys(updated).length === 0) {
                                        updated = undefined
                                    }
                                }
                                setFilter(updated)
                                if (!addFilter) {
                                    filterReducer(name, updated)
                                }
                            }}
                            onKeyDown={handleKeyDown()}
                        />

                    </Form.Group>
                }
                {type.includes("list") && filterByEnum &&
                    <Form.Group className="ps-1 mb-1">

                        {uniqueFilterByEnum?.map((arrayItem: FilterEnum) => {
                            return (
                                <div className="dropdown-item" key={arrayItem.name} onClick={() => toggleSelected(arrayItem.name)}>
                                    <Form.Check className={size === "xs" ? "d-flex align-items-center" : ""} readOnly type="checkbox" key={arrayItem.name} label={arrayItem.label} checked={inClause ? inClause.findIndex(item => item === arrayItem.name) > -1 : false} onChange={() => toggleSelected(arrayItem.name)} />
                                </div>
                            )
                        })}

                    </Form.Group>

                }
                {type.includes("boolean") &&
                    <Form.Group className="ps-1 mb-1">
                        <div className="dropdown-item" onClick={() => {
                            const updated = { bool: true }
                            setFilter(updated)
                            if (!addFilter) {
                                filterReducer(name, updated)
                            }
                        }}>
                            <Form.Check className={size === "xs" ? "d-flex align-items-center" : ""} readOnly type="radio" label={boolLabels && boolLabels.true ? boolLabels.true : t('yes')} checked={filter ? String(filter.bool) === "true" : false} onChange={() => {
                                const updated = { ...filter, bool: true }
                                setFilter(updated)
                                if (!addFilter) {
                                    filterReducer(name, updated)
                                }
                            }} />
                        </div>
                        <div className="dropdown-item" onClick={() => {
                            const updated = { bool: false }
                            setFilter(updated)
                            if (!addFilter) {
                                filterReducer(name, updated)
                            }
                        }}>
                            <Form.Check className={size === "xs" ? "d-flex align-items-center" : ""} readOnly type="radio" label={boolLabels && boolLabels.false ? boolLabels.false : t('no')} checked={filter ? String(filter.bool) === "false" : false} onChange={() => {
                                const updated = { ...filter, bool: false }
                                setFilter(updated)
                                if (!addFilter) {
                                    filterReducer(name, updated)
                                }
                            }} />
                        </div>
                    </Form.Group>

                }
                {type.includes("date") &&
                    <>
                        <Form.Group className="mb-1">
                            <Row className={"p-0 m-0"}><Form.Label className="text-center">{t('sortFilter.from')}</Form.Label></Row>
                            <Form.Control size="sm" type="date" className="text-center"
                                value={gteClause ? moment(gteClause).format("YYYY-MM-DD") : ""}
                                onChange={(e) => {
                                    let updated
                                    if (e.target.value !== "") {
                                        updated = { ...filter, gte: e.target.value }
                                    } else {
                                        updated = undefined
                                    }
                                    setFilter(updated)
                                    if (!addFilter) {
                                        filterReducer(name, updated)
                                    }
                                }}
                                onKeyDown={handleKeyDown()}
                            />
                        </Form.Group>
                        <Form.Group className="mb-1">
                            <Row className={"p-0 m-0"}><Form.Label className="text-center">{t('sortFilter.to')}</Form.Label></Row>
                            <Form.Control size="sm" type="date" className="text-center"
                                value={lteClause ? moment(lteClause).format("YYYY-MM-DD") : ""}
                                onChange={(e) => {
                                    let updated
                                    if (e.target.value !== "") {
                                        updated = { ...filter, lte: e.target.value }
                                    } else {
                                        updated = undefined
                                    }
                                    setFilter(updated)
                                    if (!addFilter) {
                                        filterReducer(name, updated)
                                    }
                                }}
                                onKeyDown={handleKeyDown()}
                            />
                        </Form.Group>
                    </>
                }
                {type.includes("number") &&
                    <>
                        <Form.Group className="mb-1">
                            <Row className={"p-0 m-0"}><Form.Label className="text-center">{t('sortFilter.from_number')}</Form.Label></Row>
                            <Form.Control size="sm" type="number" className="text-center"
                                value={gteClause ? gteClause : ""}
                                onChange={(e) => {
                                    let updated
                                    if (e.target.value !== "") {
                                        updated = { ...filter, gte: Number(e.target.value) }
                                    } else {
                                        updated = undefined
                                    }
                                    setFilter(updated)
                                    if (!addFilter) {
                                        filterReducer(name, updated)
                                    }
                                }}
                                onKeyDown={handleKeyDown()}
                            />
                        </Form.Group>
                        <Form.Group className="mb-1">
                            <Row className={"p-0 m-0"}><Form.Label className="text-center">{t('sortFilter.to_number')}</Form.Label></Row>
                            <Form.Control size="sm" type="number" className="text-center"
                                value={lteClause ? lteClause : ""}
                                onChange={(e) => {
                                    let updated
                                    if (e.target.value !== "") {
                                        updated = { ...filter, lte: Number(e.target.value) }
                                    } else {
                                        updated = undefined
                                    }
                                    setFilter(updated)
                                    if (!addFilter) {
                                        filterReducer(name, updated)
                                    }
                                }}
                                onKeyDown={handleKeyDown()}
                            />
                        </Form.Group>
                    </>
                }
                {children}
            </div>

            <Button className={"btn-block btn-secondary btn-sm w-100 d-none d-lg-block" + (size === "xs" ? " fs-8" : "")} variant="secondary" type="button" onClick={validateForm} >
                {t('buttons.validate')}
            </Button>
        </Form>
    )
})

export default FilterForm