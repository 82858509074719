import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { addUserRequest } from '../../../state/userRequestSlice';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Loader from '../../../common/components/Loader';
import InlineLoader from '../../../common/components/InlineLoader';
import SelectControl from '../../../common/components/SelectControl';

import { Formik } from 'formik';
import * as Yup from 'yup';

import UsersApi, { UserRequest } from '../../../api/usersApi';

type UsersContainerProps = WithTranslation

const SignUpForm: React.FC<UsersContainerProps> = ({t}) => {
    
    const dispatch = useDispatch()

    const { language } = useSelector( (state: RootState) => state.local )

    const languages = useSelector( (state: RootState) => state.config.languages.data )
    const languagesStatus = useSelector( (state: RootState) => state.config.languages.fetchStatus )
    const languagesError = useSelector( (state: RootState) => state.config.languages.fetchError )

    const [apiError, setApiError] = useState(undefined)
    const [formSubmitted, setFormSubmitted] = useState(false)

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().trim()
            .required(t('forms_errors.firstname_required')),
        lastname: Yup.string().trim()
            .required(t('forms_errors.lastname_required')),
        email: Yup.string()
            .email(t('forms_errors.email_valid'))
            .required(t('forms_errors.email_required')),
        company: Yup.string()
            .required(t('forms_errors.company_required')),
        languageId: Yup.number()
    })

    return (
        <Container className="mt-5"> 
            <h4 className="fw-bold">{t('signup.title')}</h4>

            { formSubmitted && 
                <Container className="mt-3">
                    {t('signup.msg_success')}<br />
                    {t('signup.msg_admin_validation')}<br/>
                    <br />
                    {t('msg_go_back')}<Link to="/">{t('nav.login')}</Link>
                </Container>
            }

            { !formSubmitted &&
                <Formik
                    initialValues={{ 
                        firstname: "", 
                        lastname: "", 
                        email: "", 
                        company: "",
                        languageId: language.id
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting, setFieldError}) => {
                        // When button submits form and form is in the process of submitting, submit button is disabled
                        setSubmitting(true)
                        setApiError(undefined)
            
                        const userRequest = {
                            firstname: values.firstname,
                            lastname: values.lastname,
                            email: values.email,
                            company: values.company,
                            language_id: Number(values.languageId)
                        }

                        UsersApi.postUserRequest(userRequest)
                        .then((response: UserRequest) => {
                            dispatch(addUserRequest(response))
                            setFormSubmitted(true)
                        })
                        .catch((err) => {
                            if (err.message === 'DUPLICATE_KEY')
                                setFieldError('email', t('forms_errors.email_already_used'))
                            else
                                setApiError(t('forms_errors.unexpected_error'))
                        })
                        .finally(() => {
                            setSubmitting(false)
                        })
                    }}
                >
                    {/* Callback function containing Formik state and helpers that handle common form actions */}
                    {( {values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            { isSubmitting && 
                                <Loader />
                            }
                            { apiError && 
                                <Container className="text-danger">
                                    {t('signup.msg_failed')}
                                    <br /> 
                                    {apiError}
                                </Container>
                            }
                            <Form.Group className="mb-2" controlId="formFirstname">
                                <Form.Label>{t('forms.firstname')} *</Form.Label>
                                <Form.Control className={touched.firstname && errors.firstname ? "is-invalid" : undefined} type="text" name='firstname' value={values.firstname} onChange={handleChange} onBlur={handleBlur} />
                                <Form.Text className="invalid-feedback">{errors.firstname}</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="formLastname">
                                <Form.Label>{t('forms.lastname')} *</Form.Label>
                                <Form.Control className={touched.lastname && errors.lastname ? "is-invalid" : undefined} type="text" name='lastname' value={values.lastname} onChange={handleChange} onBlur={handleBlur} />
                                <Form.Text className="invalid-feedback">{errors.lastname}</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="formEmail">
                                <Form.Label>{t('forms.email')} *</Form.Label>
                                <Form.Control className={touched.email && errors.email ? "is-invalid" : undefined}  type="email" name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                                <Form.Text className="invalid-feedback">{errors.email}</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="formCompany">
                                <Form.Label>{t('forms.company')} *</Form.Label>
                                <Form.Control className={touched.company && errors.company ? "is-invalid" : undefined}  type="text" name='company' value={values.company} onChange={handleChange} onBlur={handleBlur} />
                                <Form.Text className="invalid-feedback">{errors.company}</Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="formLanguage">
                                <Form.Label>{t('forms.language')} *</Form.Label>
                                {languagesStatus === 'loading' && 
                                    <InlineLoader /> }
                                {languagesStatus === 'succeeded' && 
                                    <SelectControl name='languageId' items={languages} currentItemId={values.languageId} onChange={handleChange} /> }
                                {languagesStatus === 'failed' && 
                                    <div>{languagesError}</div> }
                            </Form.Group>

                            <Container className="text-end">
                                <Button disabled={isSubmitting} variant="secondary" type="submit">
                                    {t('signup.submit')}
                                </Button>
                            </Container>
                        </Form>
                    )}
                </Formik>
            }
        </Container>
    )
}

export default withTranslation()(SignUpForm)