import { ReactComponent as RefreshIcon } from 'bootstrap-icons/icons/arrow-clockwise.svg';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import UploadExcelFile from '../../../common/components/UploadExcelFile';
import { fetchLaboratories, fetchLanguages } from '../../../state/configSlice';
import { setAuthCurLabId } from '../../../state/authUserSlice';
import { RootState } from '../../../state/rootReducer';
import MatrixTemplatesList from './components/MatrixTemplateInputsList';
import InlineLoader from '../../../common/components/InlineLoader';
import SelectControl from '../../../common/components/SelectControl';
import userHasRequiredRoles from '../../../common/auth/userHasRequiredRoles';
import { AppDispatch } from '../../../state/store';
import ExceptionModal from '../../../common/utils/ExceptionModal';
import { fetchExportMatrixTemplates, fetchMatrixTemplates } from '../../../state/matrixTemplateSlice';
import { fetchMatrixTemplateInputs } from '../../../state/matrixTemplateInputSlice';
import MatrixTemplateInputsImportExportContainer from './components/MatrixTemplateInputsImportExportContainer';
import MatrixTemplateInputsList from './components/MatrixTemplateInputsList';

const MatrixTemplateInputsListContainer: React.FC<WithTranslation> = ({ t }) => {
    const dispatch = useDispatch<AppDispatch>()
    const { user: authUser, authCurLabId } = useSelector((state: RootState) => state.authUser)
    const pagination = useSelector((state: RootState) => state.matrixTemplateInputs.pagination)

    // api error
    const [apiError, setApiError] = useState<unknown>(undefined)

    const matrixTemplateInputsError = useSelector(
        (state: RootState) => state.matrixTemplateInputs.fetchError
    );
    const matrixTemplateInputsStatus = useSelector(
        (state: RootState) => state.matrixTemplateInputs.fetchStatus
    );

    // laboratories state
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)

    const [laboratoryId, setCurrentLaboratoryId] = useState(authUser.formulations && authUser.formulations.some(formulation => formulation.laboratory_id === authCurLabId) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']) ? authCurLabId : authUser.formulations[0].laboratory_id)

    const laboratoriesError = useSelector((state: RootState) => state.config.laboratories.fetchError)

    useEffect(() => {
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }

    }, [laboratoriesStatus])

    useEffect(() => {
        if (pagination.filterBy && pagination.status === 'idle') {
            dispatch(fetchMatrixTemplateInputs({ laboratoryId, page: pagination.currentPage }))
        }

    }, [dispatch, pagination.filterBy, pagination.sortBy, pagination.status, laboratoryId])

    useEffect(() => {
        dispatch(fetchMatrixTemplateInputs({ laboratoryId, page: pagination.currentPage }))
    }, [dispatch, laboratoryId])

    return (
        <Container className="justify-content-center container-xxl" >

            <ExceptionModal
                type="import"
                title={t('matrix.matrix_template_inputs.title')}
                message={t('matrix.errors.msg_import_failed')}
                error={apiError}
                onHide={() => {
                    setApiError(undefined)
                }}
            />

            <Row className="mt-5 me-auto" >
                <Col>
                    <h5 className="d-inline">
                    <Badge pill bg="primary">{Intl.NumberFormat(authUser.locale).format(pagination.count)}</Badge>
                    <span className="ms-2 align-text-bottom fw-bold">{t('matrix.matrix_template_inputs.title')}</span>
                    </h5>

                </Col>

                <Col>
                    <Row>
                        <Col>

                            {laboratoriesStatus === 'loading' &&
                                <InlineLoader />}
                            {laboratoriesStatus === 'succeeded' && (() => {
                                const ownedLaboratoriesList = Object.values(laboratories).filter(l => l.lab_type === 'cargill' && ((authUser.formulations && authUser.formulations.some(formulation => formulation.laboratory_id === l.id) && userHasRequiredRoles(['MyProvilab_ROLE_nutrition'])) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])))
                                const ownedLaboratories = Object.fromEntries(ownedLaboratoriesList.map(l => [l.id, l]))
                                const hasMultipleLabs: boolean = ownedLaboratoriesList.length > 1 || (authUser.formulations.length > 0 && userHasRequiredRoles(['MyProvilab_ROLE_nutrition'])) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])

                                if (hasMultipleLabs) {
                                    return (
                                        <SelectControl name="laboratories" items={ownedLaboratories} currentItemId={laboratoryId} onChange={(e) => {
                                            setCurrentLaboratoryId(Number(e.target.value))
                                            dispatch(setAuthCurLabId(Number(e.target.value)))
                                        }}
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })()}
                            {laboratoriesStatus === 'failed' &&
                                <div>{laboratoriesError}</div>}
                        </Col>

                        <Col>
                            <Button variant="secondary" onClick={() => dispatch(fetchMatrixTemplateInputs({ laboratoryId: laboratoryId, page: pagination.currentPage }))}>
                                <RefreshIcon width="20px" height="100%" className="align-text-top" />
                            </Button>
                        </Col>
                    </Row>
                </Col>

                <Col md="auto">
                    <Row>
                        <Col>
                            <MatrixTemplateInputsImportExportContainer laboratoryId={laboratoryId} type='inputs' setApiError={setApiError}/>
                        </Col>

                        <Col>
                            <MatrixTemplateInputsImportExportContainer laboratoryId={laboratoryId} type='traductions' setApiError={setApiError}/>
                        </Col>
                    </Row>

                </Col>
            </Row>
            {(matrixTemplateInputsStatus === 'loading' || laboratoriesStatus === 'loading') &&
                <Loader />
            }
            {matrixTemplateInputsStatus === 'succeeded' && laboratoriesStatus === 'succeeded' &&
                <MatrixTemplateInputsList currentLaboratoryId={laboratoryId} />
            }
            {(matrixTemplateInputsStatus === 'failed' || laboratoriesStatus === 'failed') &&
                <div className="text-danger">Matrix template inputs : {matrixTemplateInputsError}</div>
            }
        </Container>
    )
}

export default withTranslation()(MatrixTemplateInputsListContainer)

