import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import dbt from '../../../../common/utils/dbTranslation';

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../state/rootReducer'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { setFilterBy, setSortBy, setFiltered } from '../../../../state/companiesSlice';
import useFilterToolkit from '../../../../common/utils/useFilterToolkit';
import { Col, Row } from 'react-bootstrap';
import { fetchLanguages } from '../../../../state/configSlice';

interface CompanyListProps extends WithTranslation {
    currentLaboratoryId: number
}

const CompanyMatrixList: React.FC<CompanyListProps> = ({ t, currentLaboratoryId }) => {

    const dispatch = useDispatch()

    // Languages state
    const { language } = useSelector((state: RootState) => state.local)
    const languages = useSelector((state: RootState) => state.config.languages.data)
    const languagesStatus = useSelector((state: RootState) => state.config.languages.fetchStatus)
    const companies = useSelector((state: RootState) => state.companies.data)
    const companiesByFormulation = useSelector((state: RootState) => state.companies.byFormulation)
    const filteredCompany = useSelector((state: RootState) => state.companies.filtered)
    const pagination = useSelector((state: RootState) => state.companies.pagination)

    const { FilterList, ListHeader, filterAndSortList } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "admin.companies.columns.",
        onlyActive: false,
        fields: {
            id: {
                type: ["number"],
                sortable: true
            },
            name: {
                type: ["filter"],
                sortable: true
            },
            address: {
                type: ["filter"],
                sortable: true
            },
            zip_code: {
                type: ["filter"],
                sortable: true
            },
            city: {
                type: ["filter"],
                sortable: true
            },
            country: {
                type: ["filter"],
                sortable: true
            },
            language: {
                type: ["list"],
                sortable: true,
                inOpt: {
                    dbt: {
                        elements: languages,
                        field: "name",
                        idFieldName: "language_id"
                    }
                }
            }
        },
        reducers: {
            filterBy: setFilterBy,
            sortBy: setSortBy
        }
    })

    useEffect(() => {
        if (languagesStatus === 'idle') {
            dispatch(fetchLanguages())
        }
        if (companiesByFormulation && companiesByFormulation[currentLaboratoryId]) {
            const filteredList = filterAndSortList(
                Object.values(companies).filter (c => c.formulation_id === currentLaboratoryId).map(c => ({
                    ... c,
                    all: c.id + c.name + c.address + c.zip_code + c.city + c.country + c.active,
                }))
            )
            dispatch(setFiltered(filteredList))
        }
    }, [pagination.filterBy, pagination.sortBy, companies, companiesByFormulation, currentLaboratoryId, language.id])

    return (
        <Container className="mt-2 c">
            <Container className="mt-2">
                <Row>
                    <Col>
                        <FilterList />
                    </Col>
                </Row>
            </Container>

            <Table striped hover responsive="lg" className="mt-2 d-none d-lg-table bg-white text-center">
                <thead>
                    <tr>
                        <th className="p-1" />
                        <th><ListHeader name="id" /></th>
                        <th><ListHeader name="name" /></th>
                        <th><ListHeader name="address" /></th>
                        <th><ListHeader name="zip_code" /></th>
                        <th><ListHeader name="city" /></th>
                        <th><ListHeader name="country" /></th>
                        <th><ListHeader name="language" /></th>
                    </tr>
                </thead>

                <tbody>
                    {filteredCompany.map((id) => {
                        if (companies[id].active) {
                            const companyLanguageId = companies[id].language_id
                            return (
                                <tr key={id} className="align-middle">
                                    <td className="p-1 table-hover-hint" />
                                    <td className="text-primary fw-bold">{companies[id].id}</td>
                                    <td>{companies[id].name}</td>
                                    <td>{companies[id].address}</td>
                                    <td>{companies[id].zip_code}</td>
                                    <td>{companies[id].city}</td>
                                    <td>{companies[id].country}</td>
                                    <td>{companyLanguageId ? dbt(languages, companyLanguageId, language.id, "name") : ""}</td>
                                </tr>
                            )
                        }else{
                            return null
                        }
                    })}
                </tbody>
            </Table>
        </Container>
    )
}

export default withTranslation()(CompanyMatrixList)