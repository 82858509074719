import React, { useState, useContext, useRef, useEffect } from 'react';
import { Container, useAccordionButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import dbt from '../utils/dbTranslation';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import AccordionContext from 'react-bootstrap/AccordionContext';
import InlineLoader from './InlineLoader';
import { Formik, FormikProps } from 'formik';
import ListGroup from 'react-bootstrap/ListGroup';

import { ReactComponent as DeleteIcon } from 'bootstrap-icons/icons/x-circle.svg'
import { ReactComponent as AddIcon } from 'bootstrap-icons/icons/plus.svg'
import { ReactComponent as CaretDownFill } from 'bootstrap-icons/icons/caret-down-fill.svg'
import { ReactComponent as CaretUpFill } from 'bootstrap-icons/icons/caret-up-fill.svg'

import InputGroup from 'react-bootstrap/InputGroup';
import Select from 'react-select'
import Loader from './Loader';
import PcpwComponent from './PcpwComponent';
import useViewport from '../utils/useViewport';
import { IPcpw } from '../utils/pcpwUtils';
import useSecurityRoles from '../auth/useSecurityRoles';
import { AnalyteKey, PriceListFlat, allowTheoreticalValueEnum, getAnalyteKey } from '../../api/configApi';
import { PatternPackageTestUtils } from '../../pages/pdc/helpers/PatternPackageTestsUtils';
import PricesCalculator from '../../pages/pdc/helpers/PricesCalculator';
import { Data } from '../../types/types';


export type CheckStatus = 'checked' | 'unchecked' | 'indeterminate'
export type Type = 'analysis' | 'pdc'

export interface ITest {
    code_test: string
    code_analyte: string
    analysis_group_name: string
    check: CheckStatus
    theoretical_value?: number | null
    price?: number | null
    cost?: number | null
    unit: string
    period?: number | null
    weight?: number | null
    currency: string
}

export interface IPackages {
    packages: Array<{
        key: string
        check: CheckStatus
        comment?: string
        price?: number | null
        currency: string
        cost?: number | null
        period?: number | null

        weight?: number| null
        tests: Array<ITest>
    }>
    patternPcpw: IPcpw
}

interface ModalPackagesTestsProps {
    data: IPackages
    setShowPackages: (show: boolean) => void
    showPackages: boolean
    submitValues: (data: IPackages) => void
    type?: Type
    itemLabId: number
    companyId: number
    priceLists: Data<PriceListFlat>
}

const ModalPackagesTests: React.FC<ModalPackagesTestsProps> = ({ data, setShowPackages, showPackages, submitValues, type, itemLabId, companyId, priceLists }: ModalPackagesTestsProps) => {

    const { t } = useTranslation()
    const { lg } = useViewport()

    const {userIsInternal, userGrantControlPlanAdmin} = useSecurityRoles()

    const language = useSelector((state: RootState) => state.local.language)
    // user state
    const authUser = useSelector((state: RootState) => state.authUser.user)
    //  Laboratories state
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    // Package state
    const packages = useSelector((state: RootState) => state.config.packages.data)
    const packagesStatus = useSelector((state: RootState) => state.config.packages.fetchStatus)
    const packagesError = useSelector((state: RootState) => state.config.packages.fetchError)

    // Test state
    const analytes = useSelector((state: RootState) => state.analytes.data)
    const analyteStatus = useSelector((state: RootState) => state.analytes.fetchStatus)
    const analyteError = useSelector((state: RootState) => state.analytes.fetchError)

    const [selectValue, setSelectValue] = useState<ITest | null>(null)
    const [addAnalyteCodeTestCodeAnalyte, setAddAnalyteCodeTestCodeAnalyte] = useState<{code_test: string, code_analyte: string} | null>(null)
    
    const [pricePackagesValues, setPricePackagesValues] = useState<number|null>(null);
    const [costPackagesValues, setCostPackagesValues] = useState<number|null>(null);
    const [weightPackagesValues, setWeightPackagesValues] = useState<number|null>(null);
    const [periodPackagesValues, setPeriodPackagesValues] = useState<number|null>(null);
    const [currencyPackagesValues, setCurrencyPackagesValues] = useState('');

    const isInternal = userIsInternal()
    const hasRolePDCAdmin =  userGrantControlPlanAdmin(itemLabId, authUser)
    const updatePcpwState = (entries: IPackages) => {
        const initPackagesArray = entries.packages.filter(p => p.check === "checked" || p.check === "indeterminate").map(p => ({
            ...p,
            tests: PatternPackageTestUtils.getTestsOrGroups(p.tests.filter(t => t.check === "checked")).map(t => ({...t}))
        }))
    
        const initPriceArray = [entries.patternPcpw && entries.patternPcpw.price != null ? entries.patternPcpw.price : null].concat(
            initPackagesArray.map(p => (
                [p.price != null ? p.price : null].concat(
                    p.tests.map(t => t.price != null ? t.price : null)
                )
            )).flat()
        )

        const initCostArray = [entries.patternPcpw && entries.patternPcpw.cost != null ? entries.patternPcpw.cost : null].concat(
            initPackagesArray.map(p => (
                [p.cost != null  ? p.cost : null].concat(
                    p.tests.map(t => t.cost != null  ? t.cost : null)
                )
            )).flat()
        )
    
        const initWeightArray = [entries.patternPcpw && entries.patternPcpw.weight != null ? entries.patternPcpw.weight : null].concat(
            initPackagesArray.map(p => (
                [p.weight != null  ? p.weight : null].concat(
                    p.tests.map(t => t.weight != null  ? t.weight : null)
                )
            )).flat()
        )
    
        const initPeriodArray = [entries.patternPcpw && entries.patternPcpw.period != null ? entries.patternPcpw.period : null].concat(
            initPackagesArray.map(p => (
                [p.period != null  ? p.period : null].concat(
                    p.tests.map(t => t.period != null  ? t.period : null)
                )
            )).flat()
        )

        setPricePackagesValues(initPriceArray.filter(x => x !== null).length > 0 ? initPriceArray.reduce((a, b) => (a || 0) + (b || 0), 0) : null)
        setCostPackagesValues(initCostArray.filter(x => x !== null).length > 0 ? initCostArray.reduce((a, b) => (a || 0) + (b || 0), 0) : null)
        setWeightPackagesValues(initWeightArray.filter(x => x !== null).length > 0 ? initWeightArray.reduce((a, b) => (a || 0) + (b || 0), 0) : null)
        setPeriodPackagesValues(initPeriodArray.filter(x => x !== null).length > 0 ? Math.max(...initPeriodArray.filter(x => x !== null) as number[]) : null)
        setCurrencyPackagesValues(entries.patternPcpw.currency || '')
    }

    useEffect(() => {
        updatePcpwState(data)
    }, [data])

    const maxPeriod = (entries: IPackages) => {
        const selectedPeriods = [entries.patternPcpw && entries.patternPcpw.period != null ? entries.patternPcpw.period : null].concat(
            entries.packages.filter(p => p.check === "checked" || p.check === "indeterminate").map(p => (
                [p.period != null ? p.period : null].concat(
                    p.tests.filter(t => t.check === "checked").map(t => (t.period != null ? t.period : null))
            ))).flat()
        
        )
        
        return selectedPeriods.filter(x => x !== null).length > 0 ? Math.max(...selectedPeriods.filter(x => x !== null) as number[]) : null
    }


    return (

        
        <Modal size="xl" animation={true} centered show={showPackages} onHide={() => setShowPackages(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Row className="p-0" >
                        <Col xs="auto">
                            <span  className="text-primary fw-bold"> {t('test_manager.title')}</span>
                        </Col>
                        {lg && 
                            <Col xs={11} lg="auto">
                                <PcpwComponent
                                    showCost={type === "pdc" ? isInternal && hasRolePDCAdmin : true}
                                    price={pricePackagesValues}
                                    cost={type === "pdc" ? costPackagesValues : undefined}
                                    period={periodPackagesValues}
                                    weight={weightPackagesValues}
                                    currency={currencyPackagesValues}
                                    size={lg ? "lg" : "md"}
                                    type="modal">    
                                </PcpwComponent>
                            </Col>
                        }
                    </Row>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0" >
                {(packagesStatus === 'loading' || analyteStatus === 'loading') &&
                    <InlineLoader />}

                {(packagesStatus === 'failed' || analyteStatus === 'failed') &&
                    <Container className="mt-2 bg-white text-danger">
                        {packagesError && <p>{t('admin_labo.packages.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + packagesError + ')'}</p>}
                        {analyteError && <p>{t('admin_labo.tests.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + analyteError + ')'}</p>}
                    </Container>

                }

                {packagesStatus === 'succeeded' && analyteStatus === 'succeeded' &&
                    <Formik
                        initialValues={data}
                        onSubmit={(values, { setSubmitting }) => {

                            // When button submits form and form is in the process of submitting, submit button is disabled
                            setSubmitting(true)

                            try {
                                submitValues(values)
                                setShowPackages(false)
                            } catch(err) {
                                console.error("Unexpected error while submitting packages and tests selection", err)
                            } finally {
                                setSubmitting(false)
                            }
                        }}
                    >
                        {/* Callback function containing Formik state and helpers that handle common form actions */}
                        {(props: FormikProps<IPackages>) => {
                            const {
                                values,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                handleChange,
                                handleBlur
                            } = props

                            interface IProps {
                                eventKey: string
                                itemKey: string
                                itemIndex: number
                            }

                            interface CheckboxProps {
                                checked: CheckStatus
                                onChange: () => void
                            }

                            const updatePackagesAndTestsAfterTestAddOrDelete = (updatedValues: IPackages, indexPackage: number, testsUpdated: ITest[],updated: CheckStatus) => {
                                let price: number | null = null
                                let cost: number | null = null
                                let weight: number | null = null
                                let calcPrice: boolean = false
                                let calcCost: boolean = false
                                let calcWeight: boolean = false

                                testsUpdated.forEach((te, indexTest) => {
                                    const testInValues = values.packages[indexPackage].tests.find(t => t.code_analyte === te.code_analyte && t.code_test === te.code_test)
                                    const indexTestValues = testInValues ?  values.packages[indexPackage].tests.indexOf(testInValues) : -1

                                    if (!updatedValues.packages[indexPackage].tests[indexTestValues].analysis_group_name) {
                                        updatedValues.packages[indexPackage].tests[indexTestValues].check = updated
                                    }
                                    else {
                                        updatedValues.packages[indexPackage].tests.forEach(
                                            t => {
                                                if (t.analysis_group_name === updatedValues.packages[indexPackage].tests[indexTestValues].analysis_group_name) {
                                                    t.check = updated
                                                }
                                            }
                                        )
                                    }

                                    setFieldValue(`packages[${indexPackage}].tests[${indexTestValues}].check`, updated)

                                })
                                let updatedPackageCheck: CheckStatus = "unchecked"
                                const everyChecked = updatedValues.packages[indexPackage].tests.every(t => t.check === 'checked')
                                
                                if(testsUpdated.length === 0){
                                    updatedPackageCheck = updated
                                }else if (everyChecked) {
                                    updatedPackageCheck = 'checked'
                                } else {
                                    const someChecked = updatedValues.packages[indexPackage].tests.some(t => t.check === 'checked')
                                    if (someChecked) {
                                        updatedPackageCheck = 'indeterminate'
                                    } else {
                                        updatedPackageCheck = 'unchecked'
                                    }
                                }

                                updatedValues.packages[indexPackage].check = updatedPackageCheck
                                setFieldValue(`packages[${indexPackage}].check`, updatedPackageCheck)

                                
                               

                                if((data.patternPcpw && data.patternPcpw.price != null) || (values.packages.filter((pack) => (pack.check === "indeterminate" || pack.check === "checked") && pack.price != null || pack.tests.filter((test)=> test.check === "checked" && test.price != null).length > 0).length > 0)){
                                    price = data.patternPcpw && data.patternPcpw.price != null ? data.patternPcpw.price : 0
                                    calcPrice = true
                                }
                                if((data.patternPcpw && data.patternPcpw.cost != null) || (values.packages.filter((pack) => (pack.check === "indeterminate" || pack.check === "checked") && pack.cost != null || pack.tests.filter((test)=> test.check === "checked" && test.cost != null).length > 0).length > 0)){
                                    cost = data.patternPcpw && data.patternPcpw.cost != null ? data.patternPcpw.cost : 0
                                    calcCost = true
                                }
                                if((data.patternPcpw && data.patternPcpw.weight != null) || (values.packages.filter((pack) =>(pack.check === "indeterminate" || pack.check === "checked") && pack.weight != null || pack.tests.filter((test)=> test.check === "checked" && test.weight != null).length > 0).length > 0)){
                                    weight = data.patternPcpw && data.patternPcpw.weight != null ? data.patternPcpw.weight : 0
                                    calcWeight = true
                                }

                                values.packages.forEach((pack)=>{
                                    if(pack.check === "indeterminate" || pack.check === "checked"){
                                        if(calcPrice) { price!+= pack.price  || 0 }
                                        if(calcCost)  { cost!+= pack.cost  || 0 }
                                        if(calcWeight) { weight!+= pack.weight  || 0 }
                                    }
                                    pack.tests.forEach((test)=>{
                                        if(test.check === "checked"){
                                            if(calcPrice) { price!+= test.price || 0 }
                                            if(calcCost) { cost!+= test.cost  || 0 }
                                            if(calcWeight) { weight!+= test.weight  || 0 }
                                        }
                                    })
                                   
                                })

                                setPricePackagesValues(price);
                                setCostPackagesValues(cost);
                                setWeightPackagesValues(weight);
                                setPeriodPackagesValues(maxPeriod(updatedValues))
                                setCurrencyPackagesValues(updatedValues.patternPcpw.currency || '')
                            }
                            const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange }: CheckboxProps) => {
                                const checkboxRef = useRef<HTMLInputElement>(null);

                                useEffect(() => {
                                    if (checkboxRef && checkboxRef.current) {
                                        if (checked === "checked") {
                                            checkboxRef.current.checked = true;
                                            checkboxRef.current.indeterminate = false;
                                        } else if (checked === "unchecked") {
                                            checkboxRef.current.checked = false;
                                            checkboxRef.current.indeterminate = false;
                                        } else if (checked === "indeterminate") {
                                            checkboxRef.current.checked = false;
                                            checkboxRef.current.indeterminate = true;
                                        }
                                    }
                                }, [checked]);

                                return (
                                    <Form.Check.Input ref={checkboxRef} onChange={onChange} className="position-static" />
                                )
                            }

                            function ContextAwareToggle({ eventKey, itemKey, itemIndex }: IProps) {
                                const { activeEventKey } = useContext(AccordionContext);

                                const decoratedOnClick = useAccordionButton(
                                    eventKey,
                                  );

                                const isCurrentEventKey = activeEventKey === eventKey;

                                return (
                                    <Row className={`ms-0 me-0 align-items-center ${isCurrentEventKey ? "bgColorAccordionOpen ps-0" : "bg-white ps-0"}`} >
                                        <Col xs="auto" >
                                            <Form.Check>
                                                <Checkbox checked={values.packages[itemIndex].check}
                                                    onChange={() => {
                                                        const updatedValues: IPackages = {...values}
                                                        const updated = values.packages[itemIndex].check === 'unchecked' || values.packages[itemIndex].check === 'indeterminate' ? 'checked' : 'unchecked'
                                                        updatePackagesAndTestsAfterTestAddOrDelete(updatedValues, itemIndex, values.packages[itemIndex].tests, updated)                                          }}
                                                />
                                            </Form.Check>
                                        </Col>
                                        <Col className="ps-0" >
                                                <Card.Header onClick={decoratedOnClick} className={`border-0 ${isCurrentEventKey ? "bgColorAccordionOpen ps-0" : "bg-white ps-0"}`} >
                                                    <Row className="p-0">
                                                        <Col xs={!lg ? 10 : 5} className="ps-0">
                                                            <span className="ps-2 fw-bold " >{dbt(packages, itemKey, language.id, "name")}  </span>
                                                        </Col>
                                                        {lg &&
                                                            <Col>
                                                                <PcpwComponent
                                                                    showCost={type === "pdc" ? isInternal && hasRolePDCAdmin : true}
                                                                    price={values.packages[itemIndex].price}
                                                                    cost={values.packages[itemIndex].cost}
                                                                    weight={values.packages[itemIndex].weight}
                                                                    period={values.packages[itemIndex].period}
                                                                    currency={values.packages[itemIndex].currency}
                                                                    size={"md"}
                                                                    type="test">
                                                                </PcpwComponent>
                                                            </Col>
                                                        }
                                                        
                                                        <Col xs="auto" className="text-end">
                                                            {(values.packages[itemIndex].tests.length > 0) && 
                                                                <>
                                                                {isCurrentEventKey ?
                                                                    <CaretUpFill width="16px" height="100%" />
                                                                    :
                                                                    <CaretDownFill width="16px" height="100%" />
                                                                }
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                        </Col>
                                    </Row>

                                );
                            }

                            return (
                                <Form onSubmit={handleSubmit}>
                                    {isSubmitting &&
                                        <Loader />
                                    }
                                    {!lg && 
                                        <Row className="p-0 mt-3 mb-3" >
                                            <Col>
                                                <PcpwComponent
                                                    showCost={type === "pdc" ? isInternal && hasRolePDCAdmin : true}
                                                    price={pricePackagesValues}
                                                    cost={type === "pdc" ? costPackagesValues !== null ? costPackagesValues : 0 : undefined}
                                                    period={periodPackagesValues}
                                                    weight={weightPackagesValues}
                                                    currency={currencyPackagesValues}
                                                    size={lg ? "lg" : "md"}
                                                    type="modal">    
                                                </PcpwComponent>
                                            </Col>
                                        </Row>
                                    }
                                    {values.packages.map((packageItem, indexPackage) => {

                                        return (
                                            <Accordion key={packageItem.key}>

                                                <Card className="select-overflow">
                                                    <ContextAwareToggle eventKey={String(packageItem.key)} itemKey={packageItem.key} itemIndex={indexPackage}></ContextAwareToggle>
                                                    <Accordion.Collapse className="bgColorAccordionOpen" eventKey={String(packageItem.key)}>
                                                        <Card.Body className="p-0 ">
                                                            < Form.Group controlId="formHorizontalCheck" className="m-0">
                                                                {(!lg || PatternPackageTestUtils.getTestsOrGroups(packageItem.tests).length <= 30) ?
                                                                    <Row className="ms-4 me-4">
                                                                        
                                                                        {PatternPackageTestUtils.getTestsListByPackage(packageItem.tests, itemLabId, analytes, language, authUser, laboratories[itemLabId].currency).map((testItem, indexTest) => {
                                                                            const testInValues = packageItem.tests.find(t => t.code_analyte === testItem.keys[0].code_analyte && t.code_test === testItem.keys[0].code_test)
                                                                            const indexTestValues = testInValues ?  packageItem.tests.indexOf(testInValues) : -1
                                                                            return (
                                                                                <Col key={values.packages[indexPackage].tests[indexTestValues].code_test + '#' + values.packages[indexPackage].tests[indexTestValues].code_analyte} sm={6} className="ps-0 pe-0">
                                                                                    <Row key={values.packages[indexPackage].tests[indexTestValues].code_test + '#' + values.packages[indexPackage].tests[indexTestValues].code_analyte} className="ms-0 me-0">
                                                                                        <Col xs="auto" className="pe-1" >
                                                                                            <Form.Check key={indexTestValues}
                                                                                                className="align-self-center"
                                                                                                name={`packages[${indexPackage}].tests[${indexTestValues}].check`}
                                                                                                checked={values.packages[indexPackage].tests[indexTestValues].check === 'checked'}
                                                                                                onChange={() => {                                                                                                    
                                                                                                    const updated: CheckStatus = values.packages[indexPackage].tests[indexTestValues].check === 'unchecked' ? 'checked' : 'unchecked'
                                                                                                    
                                                                                                    updatePackagesAndTestsAfterTestAddOrDelete(values, indexPackage, [values.packages[indexPackage].tests[indexTestValues]], updated)
                                                                                                }} />
                                                                                        </Col>
                                                                                        <Col className="ps-0 pe-0" >
                                                                                            <span className="align-self-center"> {testItem.displayName}</span>
                                                                                        </Col>
                                                                                        {lg &&
                                                                                            <Col xs={4}>

                                                                                
                                                                                            </Col>
                                                                                        }
                                                                                    </Row>
                                                                                </Col>
                                                                            )
                                                                        })}
                                                                    </Row>
                                                                    :
                                                                    <>
                                                                        <ListGroup className="overflow-auto h-20rem">
                                                                            {PatternPackageTestUtils.getTestsListByPackage(packageItem.tests, itemLabId, analytes, language, authUser, laboratories[itemLabId].currency).map((testItem) => {
                                                                                const testInValues = packageItem.tests.find(t => t.code_analyte === testItem.keys[0].code_analyte && t.code_test === testItem.keys[0].code_test)
                                                                                const indexTestValues = testInValues ?  packageItem.tests.indexOf(testInValues) : -1
                                                                                return (
                                                                                    <ListGroup.Item className="d-flex" key={testItem.keys[0].code_test + '#' + testItem.keys[0].code_analyte}>
                                                                                        <Row className="w-100">
                                                                                            <Col xs={!lg ? 10 : 5}>
                                                                                                <span className="me-auto">{testItem.displayName}</span>
                                                                                                <Button className="text-danger p-1" variant="link"
                                                                                                    onClick={() => {
                                                                                                        const updated: CheckStatus = 'unchecked'
                                                                                                        
                                                                                                        updatePackagesAndTestsAfterTestAddOrDelete(values, indexPackage, [values.packages[indexPackage].tests[indexTestValues]], updated)

                                                                                                    }}
                                                                                                >
                                                                                                    <DeleteIcon width="20px" height="100%" className="align-text-top" />
                                                                                                </Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </ListGroup.Item>
                                                                                )
                                                                            })
                                                                            }

                                                                        </ListGroup>

                                                                        <Row className="m-2">
                                                                            <InputGroup className="w-50">
                                                                                <Select
                                                                                    className="w-75"
                                                                                    size="sm"
                                                                                    styles={{
                                                                                        menu: (styles: { [key: string]: string }) => ({
                                                                                            ...styles,
                                                                                            'z-index': '1031'
                                                                                        }),
                                                                                        option: (styles: { [key: string]: string }) => ({
                                                                                            ...styles,
                                                                                            textAlign: 'left'
                                                                                        })
                                                                                    }}

                                                                                    noOptionsMessage={() => t('test_manager.testNotFound')}
                                                                                    placeholder=""
                                                                                    getOptionLabel={(test: ITest) => PatternPackageTestUtils.getAnalyteLine(test, itemLabId, analytes, language, authUser, laboratories[itemLabId].currency, true)}
                                                                                    getOptionValue={(test: ITest) => test.code_test + '#' + test.code_analyte }
                                                                                    isDisabled={false}
                                                                                    isClearable={true}
                                                                                    value={selectValue}
                                                                                    options={PatternPackageTestUtils.getTestsOrGroups(packageItem.tests).filter(tVal => (tVal as ITest).check === 'unchecked')}
                                                                                    onChange={(test: ITest) => {
                                                                                        if (test) {
                                                                                            setSelectValue(test)
                                                                                            setAddAnalyteCodeTestCodeAnalyte({
                                                                                                code_test: test.code_test,
                                                                                                code_analyte: test.code_analyte
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <Button disabled={isSubmitting} variant="secondary" size="sm" type="button"
                                                                                    onClick={async () => {
                                                                                        if (addAnalyteCodeTestCodeAnalyte) {
                                                                                            const indexTest = values.packages[indexPackage].tests.findIndex(tVal => tVal.code_test === addAnalyteCodeTestCodeAnalyte.code_test && tVal.code_analyte === addAnalyteCodeTestCodeAnalyte.code_analyte)
                                                                                            if (indexTest > -1) {
                                                                                                const updatedValues: IPackages = {...values}
                                                                                                const updated: CheckStatus = 'checked'

                                                                                                updatePackagesAndTestsAfterTestAddOrDelete(updatedValues, indexPackage, [updatedValues.packages[indexPackage].tests[indexTest]], updated)

                                                                                                setSelectValue(null)
                                                                                            }
                                                                                        }
                                                                                    }} >
                                                                                    <AddIcon width="20px" height="100%" />
                                                                                    {t('buttons.add')}
                                                                                </Button>
                                                                            </InputGroup>
                                                                            
                                                                            <Col xs="auto">
                                                                                <Button disabled={isSubmitting} variant="secondary" size="sm" type="button" className="ms-2"
                                                                                    onClick={async () => {
                                                                                        const updatedValues: IPackages = {...values}
                                                                                        packageItem.tests.forEach((t, i) => {
                                                                                            updatedValues.packages[indexPackage].tests[i].check = 'checked'
                                                                                        })
                                                                                        setFieldValue(`packages[${indexPackage}].tests`, updatedValues.packages[indexPackage].tests)
                                                                                        updatedValues.packages[indexPackage].check = 'checked'
                                                                                        setFieldValue(`packages[${indexPackage}].check`, 'checked')
                                                                                        updatePcpwState(updatedValues)
                                                                                    }} >
                                                                                    <AddIcon width="20px" height="100%" />
                                                                                    {t('buttons.add_all')}
                                                                                </Button>

                                                                                <Button disabled={isSubmitting} variant="danger" size="sm" type="button" className="ms-2"
                                                                                    onClick={async () => {
                                                                                        const updatedValues: IPackages = {...values}
                                                                                        packageItem.tests.forEach((t, i) => {
                                                                                            updatedValues.packages[indexPackage].tests[i].check = 'unchecked'
                                                                                        })
                                                                                        setFieldValue(`packages[${indexPackage}].tests`, updatedValues.packages[indexPackage].tests)

                                                                                        updatedValues.packages[indexPackage].check = 'unchecked'
                                                                                        setFieldValue(`packages[${indexPackage}].check`, 'unchecked')
                                                                                        updatePcpwState(updatedValues)
                                                                                    }} >
                                                                                    <DeleteIcon width="20px" height="100%" className="me-1" />
                                                                                    {t('buttons.remove_all')}
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </>


                                                                }
                                                            </Form.Group>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                            </Accordion>
                                        )
                                    })}
                                    <Row className="p-2">
                                        <Col className="text-end">
                                            <Button variant="secondary" type="submit" >{t('buttons.validate')}</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                    </Formik>

                }

            </Modal.Body>

        </Modal>
    )
}

export default ModalPackagesTests
