import React, { useEffect, useState } from "react";

import {
  Button,
  Toast,
  ProgressBar,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { ReactComponent as SquareFillIcon } from "bootstrap-icons/icons/square-fill.svg";
import { useTranslation } from "react-i18next";
import { RootState } from "../../state/rootReducer";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../state/store";
import { isError } from "../../state/DefaultSlice";
import { fetchCancelMatrixJob, fetchDownloadMatrixJob, fetchMatrixJobStatus, postMatrixJob } from "../../state/matrixJobsSlice";
import ModalMatrixDetailsImport from "../../pages/matrix/ModalMatrixDetailsImport";
import { BadRequestError, ResultsDetails } from "../../api/matrixApi";

let interval: NodeJS.Timer | null = null;

const SWMatricesProgress: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user: authUser } = useSelector((state: RootState) => state.authUser);

  const { t } = useTranslation();

  const matrixResponses = useSelector(
    (state: RootState) => state.matrixResponses.data
  );
  const matrixStatus = useSelector(
    (state: RootState) => state.matrixResponses.dataStatus
  );

  const [showDic, setShowDic] = useState<{[key: string]: { show: boolean, closable: boolean }}>({});

  const [showErrorDic, setShowErrorDic] = useState<{[key: string]: boolean}>({});
  const [apiErrorDic, setApiErrorDic] = useState<{[key: string]: string | undefined}>({});

  const [downloadOnGoing, setDownloadOnGoing] = useState<{[key: string]: boolean}>({})

  const [resultsDetails, setResultsDetails] = useState<ResultsDetails[]>([])
  const [showModalDetailsImport, setShowModalDetailsImport] = useState<boolean>(false)

  const updateShowDic = (id: string, show?: boolean, closable?: boolean) => {
    const clone = {...showDic}
    clone[id] = Object.assign(
      {...clone[id]},
      show !== undefined ? {show} : null,
      closable !== undefined ? {closable} : null,
    )
    setShowDic(clone)
  }

  const checkStatus = () => {
    // don't check if user is not connected

    if (authUser.id !== 0) {
      Object.keys(matrixResponses).forEach(async (id) => {
        if (
          !matrixStatus[id] ||
          !["cancelled", "done", "failed"].includes(matrixStatus[id].status)
        ) {
          await dispatch(fetchMatrixJobStatus(id)).unwrap();
          updateShowDic(id, true, false)
        }
      });
    }
  };

  useEffect(() => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(checkStatus, 2500);
  }, [matrixResponses, matrixStatus, authUser]);

  function showApiError(show: boolean, id: string, err?: unknown) {
    const showError = { ...showErrorDic };
    showError[id] = show;
    setShowErrorDic(showError);

    if (isError(err)) {
      updateShowDic(id, undefined, true)
      const apiError = { ...apiErrorDic };
      apiError[id] = err.message;
      setApiErrorDic(apiError);
    }
  }
  
  /**
   * Return warning component that notify the user that an update is available
   */
  return (
    <>
      <ModalMatrixDetailsImport resultsDetails={resultsDetails} setShowModalDetailsImport={setShowModalDetailsImport} showModalDetailsImport={showModalDetailsImport}/>
      {Object.keys(matrixStatus).map((id) => {
        
        let progressVariant: string|undefined
        if (matrixStatus[id]?.status === 'failed') progressVariant = "danger"
        if (matrixStatus[id]?.status === 'done') progressVariant = "success"
        if (matrixStatus[id]?.status === 'queued') progressVariant = "warning"

        let headerVariant = 'text-primary'
        if (matrixStatus[id]?.status === 'failed') headerVariant = "text-danger"
        if (matrixStatus[id]?.status === 'done') headerVariant = "text-success"
        if (matrixStatus[id]?.status === 'queued') headerVariant = "text-warning"

        return (
          <Toast className={showDic[id] && showDic[id].show ? 'sticky-toast-component' : ''}
            key={id} 
            show={showDic[id] && showDic[id].show} 
            onClose={async () => {
              try {
                  updateShowDic(id, false)
                  await dispatch(fetchCancelMatrixJob(id)).unwrap()
                  showApiError(false, id)
              } catch (err) {
                  showApiError(true, id, err)
              }
            }}           
          >
            <Toast.Header closeButton={true}>
              <SquareFillIcon
                width="20px"
                height="100%"
                className={`${headerVariant} rounded me-2`}
              />

              {matrixStatus[id]?.status === "queued" && (
                <strong className="me-auto">{matrixStatus[id].config.type === "export" ? t("notif_export.title_exporting") : t("notif_matrix_import.title_importing")}</strong>
              )}

              {matrixStatus[id]?.status === "done" && !downloadOnGoing[id] && (
                <strong className="me-auto">{matrixStatus[id].config.type === "export" ? t("notif_export.title_exported") : t("notif_matrix_import.title_imported")}</strong>
              )}

              {matrixStatus[id]?.status === "done" && downloadOnGoing[id] && (
                <strong className="me-auto">{t("notif_export.title_downloading")}</strong>
              )}

              {matrixStatus[id]?.status === "running" && (
                <strong className="me-auto">{matrixStatus[id].config.type === "export" ? t("notif_export.title_exporting") : t("notif_matrix_import.title_importing")}</strong>
              )}

              {matrixStatus[id]?.status === "created" && (
                <strong className="me-auto">{matrixStatus[id].config.type === "export" ? t("notif_export.title_waiting") : t("notif_matrix_import.title_waiting")}</strong>
              )}

              {matrixStatus[id]?.status === "failed" && (
                <strong className="me-auto text-danger">{matrixStatus[id].config.type === "export" ? t("notif_export.title_failed") : t("notif_matrix_import.title_failed")}</strong>
              )}

            </Toast.Header>
            <Toast.Body>
              
              <ProgressBar now={matrixStatus[id].progress} variant={progressVariant} />

              <br/>
              { matrixStatus[id]?.status === 'failed' &&
                <Alert variant="danger">
                    <span>{matrixStatus[id].config.type === "export" ? t("notif_export.text_failed") : t("notif_matrix_import.text_failed")}</span>
                    <br/>
                </Alert>
              }

              { matrixStatus[id]?.status === 'queued' &&
                <Alert variant="warning">
                    <span>{matrixStatus[id].config.type === "export" ? t("notif_export.text_waiting") : t("notif_matrix_import.text_waiting")}</span>
                    <br/>
                </Alert>
              }

              { showErrorDic[id] &&
                <Alert variant="danger">
                    {apiErrorDic[id]}
                </Alert>
              }
                

              <Row className="mt-2">
                <Col className="text-end">
                  
                  {(['running','queued', 'done'].includes(matrixStatus[id]?.status) && matrixStatus[id]?.config.type === "export" || ['running','queued'].includes(matrixStatus[id]?.status) && matrixStatus[id]?.config.type === "import") &&   (
                    <Button
                      disabled = {downloadOnGoing[id]}
                      className="ms-2"
                      variant="secondary"
                      onClick={async () => {
                        try {
                            await dispatch(fetchCancelMatrixJob(id)).unwrap()
                            showApiError(false, id)
                        } catch (err) {
                            showApiError(true, id, err)
                        }

                      }}
                    >
                      {t("buttons.cancel")}
                    </Button>
                  )}

                  {matrixStatus[id]?.status === "done"  && matrixStatus[id]?.config.type === 'export' && (
                    <Button
                      disabled = {downloadOnGoing[id]}
                      className="ms-2"
                      variant="secondary"
                      onClick={async () => {

                        try{
                          const cloneDownloadOnGoing = {...downloadOnGoing}
                          cloneDownloadOnGoing[id] = true
                          setDownloadOnGoing({
                            ...cloneDownloadOnGoing,
                            
                          })
                          await dispatch(fetchDownloadMatrixJob(matrixStatus[id])).unwrap()
                          cloneDownloadOnGoing[id] = false

                          setDownloadOnGoing(cloneDownloadOnGoing)
                          showApiError(false, id)
                         }
                        catch (err) {
                          showApiError(true, id, err)
                          }
                      }}
                    >
                      {t("buttons.download")}
                    </Button>
                  )}

              {["done", "failed"].includes(matrixStatus[id]?.status) && matrixStatus[id]?.config.type === 'import' && (
                    <Button
                      className="ms-2"
                      variant="secondary"
                      onClick={async () => {

                        try{
                          setResultsDetails(matrixStatus[id].results_details)

                          setShowModalDetailsImport(true)
                          showApiError(false, id)
                         }
                        catch (err) {
                          showApiError(true, id, err)
                          }
                      }}
                    >
                      {t("buttons.details")}
                    </Button>
                  )}

                  {(matrixStatus[id]?.status === "failed" && matrixStatus[id]?.config.type === "export" || showErrorDic[id]) && (
                    <Button
                      className="ms-2"
                      variant="secondary"
                      onClick={async () => {

                        try{
                          dispatch(postMatrixJob(matrixResponses[id].config)).unwrap()
                          updateShowDic(id, false)
                          showApiError(false, id)
                         }
                        catch (err) {
                          showApiError(true, id, err)
                          }
                      }}
                    >
                      {t("buttons.retry")}
                    </Button>
                  )}


                </Col>
              </Row>
            </Toast.Body>
          </Toast>
        );
      })}
    </>
  );
};

export default SWMatricesProgress;
