import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/rootReducer'
import Container from 'react-bootstrap/Container';

import { useParams } from "react-router-dom";

import Loader from '../../../common/components/Loader';
import ConsultMatrix from './components/consultMatrix';
import { fetchMatrices, fetchSingleMatrice } from '../../../state/matrixSlice';
import { fetchMatrixTemplateInputs, fetchMatrixTemplateInputsByTemplateId } from '../../../state/matrixTemplateInputSlice';
import { fetchMatrixTemplateById, fetchMatrixTemplates } from '../../../state/matrixTemplateSlice';
import { fetchAllCompanies, fetchCompanies, fetchSingleCompany } from '../../../state/companiesSlice';
import { fetchAutocalcCallState } from '../../../state/autocalcCallStateSlice';
import { fetchIngredients, fetchLaboratories, fetchRefOrigins, fetchRefSuppliers } from '../../../state/configSlice';

type MatrixContainerProps = WithTranslation

interface urlParams {
    templateId: string
    companyId: string
    exportCode: string
    version: string
}

const MatrixContainer: React.FC<MatrixContainerProps> = ({ t }) => {
    let { templateId, companyId, exportCode, version } = useParams<urlParams>();
    exportCode = decodeURIComponent(exportCode!)

    const dispatch = useDispatch()
    // matrix state
    const matrixTemplates = useSelector((state: RootState) => state.matrixTemplates.data)
    const matrixTemplatesStatus = useSelector((state: RootState) => state.matrixTemplates.fetchStatus)

    const matrixTemplateInputsStatus = useSelector((state: RootState) => state.matrixTemplateInputs.fetchStatus)
    const autocalcCallStateStatus = useSelector((state: RootState) => state.autocalcCallState.fetchStatus)

    const matrix = useSelector((state: RootState) => state.matrix.matrix.data)
    const matrixStatus = useSelector((state: RootState) => state.matrix.matrix.fetchStatus)
    const matrixError = useSelector((state: RootState) => state.matrix.matrix.fetchError)
    const companies = useSelector((state: RootState) => state.companies.data)
    const companyStatus = useSelector((state: RootState) => state.companies.singleFetchStatus)
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const refOriginsStatus = useSelector( (state: RootState) => state.config.refOrigins.fetchStatus )
    const refSupplierStatus = useSelector( (state: RootState) => state.config.refSuppliers.fetchStatus )   
    const ingredientsStatus = useSelector((state: RootState) => state.config.ingredients.fetchStatus)

    const [laboratoryId, setLabId] = useState(0)

    useEffect(() => {
        if(refOriginsStatus === 'idle') {
            dispatch(fetchRefOrigins())
        }
        if (ingredientsStatus === 'idle') {
            dispatch(fetchIngredients())
        } 
        if(refSupplierStatus === 'idle') {
            dispatch(fetchRefSuppliers())
        }
        if (matrixStatus === 'idle') {
            dispatch(fetchSingleMatrice({ companyId, exportCode, version }))
        }
        if(autocalcCallStateStatus === 'idle') {
            dispatch(fetchAutocalcCallState({ companyId, exportCode, version }))
        }
        if (matrixTemplatesStatus === 'idle') {
            dispatch(fetchMatrixTemplateById({ templateId }))
        }
        if (matrixTemplateInputsStatus === 'idle') {
            dispatch(fetchMatrixTemplateInputsByTemplateId({ laboratoryId, templateId }))
        }
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }
        if(!companies[companyId]){
            dispatch(fetchSingleCompany(Number(companyId)))
        }

    }, [dispatch, matrixTemplates, companyStatus, laboratoriesStatus, ingredientsStatus, refOriginsStatus, refSupplierStatus])

    //On company_ID, export_code or version change, re-fetch autocalcCallState and matrix : 
    useEffect(() => {
        if(!companies[companyId]){
            dispatch(fetchSingleCompany(Number(companyId)))
        }
        dispatch(fetchSingleMatrice({ companyId, exportCode, version }))
        dispatch(fetchAutocalcCallState({ companyId, exportCode, version }))
        dispatch(fetchMatrixTemplateInputsByTemplateId({ laboratoryId: laboratoryId ? laboratoryId : companies[companyId].formulation_id!, templateId }))
    }, [companyId, exportCode, version, laboratoryId, templateId])

    useEffect(() => {
        if (matrixTemplates[templateId]) {
            setLabId(matrixTemplates[templateId].laboratory_id)
        }
    }, [matrixTemplates, templateId])

    return (
        <Container>

            {(companyStatus === 'loading' || laboratoriesStatus === 'loading' || matrixStatus === 'loading' || matrixTemplatesStatus === 'loading' || matrixTemplateInputsStatus === 'loading' || autocalcCallStateStatus === 'loading') &&
                <Loader />
            }
            {companies[companyId] && laboratoriesStatus === 'succeeded' && matrixStatus === 'succeeded' && matrixTemplatesStatus === 'succeeded' && matrixTemplateInputsStatus === 'succeeded' && autocalcCallStateStatus === 'succeeded' &&
                <Container>
                    <ConsultMatrix matrixDb={matrix[`${companyId}#${exportCode}#${version}`]}/>
                </Container>
            }
            {(companyStatus === 'failed' || laboratoriesStatus === 'failed' || matrixStatus === 'failed' || matrixTemplatesStatus === 'failed' || matrixTemplateInputsStatus === 'failed' || autocalcCallStateStatus === 'failed') &&
                <div className="text-danger">{matrixError}</div>

            }
        </Container>
    )
}

export default withTranslation()(MatrixContainer)