import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import DashboardFilterComponent from './components/DashboardFilterComponent';
import DashboardSamplesComponent from './components/DashboardSamplesComponent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import DashboardSelectionComponent from './components/DashboardSelectionComponent';
import { ScrollSync } from 'scroll-sync-react'
import DashboardOptionsComponent from './components/DashboardOptionsComponent';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import DashboardParamsComponent from './components/DashboardParamsComponent';
import DashboardGraphsComponent from './components/DashboardGraphsComponent';
import DashboardGroupByComponent from './components/DashboardGroupByComponent';
import Loader from '../../common/components/Loader';
import { getManageDashboard } from '../../state/manageDashboardSlice';
import { AppDispatch } from '../../state/store';
import { manageOutliers, resetOutliers, setSelectedValues } from '../../state/dashboardSlice';
import { fetchAllCompanies } from '../../state/companiesSlice';
import { fetchLaboratories } from '../../state/configSlice';
import DashboardInitialFilteringComponent from './components/DashboardInitialFilteringComponent';
import { Formik } from 'formik';
import DashboardFormerMatrixComponent from './components/DashboardFormerMatrixComponent';
import { StatusMatrix } from '../../api/matrixApi';
import * as Yup from 'yup';
import DashboardApi, { Preference, SelectedValue } from '../../api/dashboardApi';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { fetchAllDashboardPreferences, resetSinglePreferences } from '../../state/dashboardPreferencesSlice';
import { useDidMountEffect } from './components/utils';


interface DashboardContainerProps extends WithTranslation {
    dashboardName: string
}

const DashboardContainer: React.FC<DashboardContainerProps> = ({ t, dashboardName }) => {
    const dispatch = useDispatch<AppDispatch>()

    // search state
    const searchStatus = useSelector((state: RootState) => state.dashboard.searchStatus)
    const groupBy = useSelector((state: RootState) => state.dashboard.groupBy)

    // States
    const manageDashboardStatus = useSelector((state: RootState) => state.manageDashboard.fetchStatus)
    const manageDashboardError = useSelector((state: RootState) => state.manageDashboard.fetchError)
    const datasOfAnalytes = useSelector((state: RootState) => state.dashboard.dataOfAnalytes)
    const companyStatus = useSelector((state: RootState) => state.companies.fetchStatus)
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const singleMatrixStatus = useSelector((state: RootState) => state.matrix.matrix.singleFetchStatus)
    const resultsHeader = useSelector((state: RootState) => state.dashboard.resultsHeaders)
    const samplesForData = useSelector((state: RootState) => state.dashboard.samplesForData)

    const matrice = useSelector((state: RootState) => state.matrix.matrix.singleMatrix)
    const lastActiveMatrice = useSelector((state: RootState) => state.matrix.matrix.activeMatrix)

    //Preference states
    const matrixFields = useSelector((state: RootState) => state.dashboard.matrixFields)
    const valuesForSearch = useSelector((state: RootState) => state.dashboard.valuesForSearch)
    const paramsFromComponents = useSelector((state: RootState) => state.dashboard.paramsFromComponents)
    const weights = useSelector((state: RootState) => state.dashboard.weights)
    const samplesInfo = useSelector((state: RootState) => state.dashboard.fieldsForSamplesInfo)
    const preferencesStatus = useSelector((state: RootState) => state.preferences.singleFetchStatus)
    const preferences = useSelector((state: RootState) => state.preferences.singlePreferences)

    const [triggerMatrixFields, setTriggerMatrixFields] = useState(false);

    useEffect(() => {

        if (manageDashboardStatus === 'idle') {
            dispatch(getManageDashboard())
        }
        if (companyStatus === 'idle') {
            dispatch(fetchAllCompanies())
        }
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }
    }, [manageDashboardStatus, companyStatus, laboratoriesStatus])

    useEffect(() => {
        if (paramsFromComponents.averageOutliers && (paramsFromComponents.average1 || paramsFromComponents.average2 || paramsFromComponents.average3)) {
            dispatch(manageOutliers())
        }
    }, [paramsFromComponents.averageOutliers, paramsFromComponents.average1, paramsFromComponents.average2, paramsFromComponents.average3, datasOfAnalytes])


    async function saveMatrixPreference(values: SelectedValue[]): Promise<void> {
        const preference: Preference = {
            category: matrixFields && matrixFields.category ? matrixFields.category : '',
            sub_category: matrixFields && matrixFields.sub_category ? matrixFields.sub_category : '',
            raw_material: matrixFields && matrixFields.raw_material ? matrixFields.raw_material : '',
            common_filter: {
                exclude_outliers: valuesForSearch!.common_filter.exclude_outliers,
                period_field: valuesForSearch!.common_filter.period_field,
                from: new Date(valuesForSearch!.common_filter.from),
                to: new Date(valuesForSearch!.common_filter.to),
                filter: valuesForSearch!.common_filter.filter
            },
            filter_set: valuesForSearch!.filter_set,
            analytes: [],
            options: {
                average: paramsFromComponents.average,
                average_1sd: paramsFromComponents.average1,
                average_2sd: paramsFromComponents.average2,
                average_3sd: paramsFromComponents.average3,
                active_matrix: paramsFromComponents.activeMatrix,
                selected_values: paramsFromComponents.selectedValues,
                connect_dots: paramsFromComponents.connectDots,
                data_show: paramsFromComponents.dataShow,
                trend: paramsFromComponents.trend,
                show_outliers: paramsFromComponents.outliers
            },
            groups: {
                field: groupBy.field,
                values: groupBy.values.map((v) => {
                    return {
                        value: v.value,
                        color: v.color
                    }
                })
            },
            weights: weights,
            samples_info: samplesInfo.map((s) => {
                return {
                    field: s.field,
                    display: s.display
                }
            }),
            selected_values: values.map((v) => {
                return {
                    value: Number(v.value),
                    analyte_id: v.analyte_id
                }
            })
        }
        if (matrixFields) await DashboardApi.getInstance().putDashboardPreferences(matrixFields.company_id, matrixFields.export_code, matrice ? matrice.version : 1, preference)

    }

    const history = useHistory()

    function parseSelectedValues(values: any): SelectedValue[] {
        const parsed = values.selected_values.filter((sv: SelectedValue) => sv !== null && sv !== undefined).map((sv: SelectedValue) => {
            const decimalPlaces = resultsHeader.find((rh) => rh.id.toUpperCase() === sv.analyte_id.toUpperCase())?.decimals
            if (decimalPlaces) {
                const selectedValueStr = sv.value.toString()
                const decimalIndex: number = selectedValueStr.indexOf('.');
                if (decimalIndex === -1) {
                    return { analyte_id: sv.analyte_id, value: sv.value }; // Pas de chiffres après la virgule, retourner le nombre d'origine
                }
                const numDigitsAfterDecimal: number = selectedValueStr.length - decimalIndex - 1;
                if (numDigitsAfterDecimal <= decimalPlaces) {
                    return { analyte_id: sv.analyte_id, value: sv.value }; // Le nombre de chiffres après la virgule est inférieur ou égal au paramètre spécifié, retourner le nombre d'origine
                }
                const truncatedValue: string = Number(sv.value).toFixed(decimalPlaces);
                return { analyte_id: sv.analyte_id, value: Number(truncatedValue) };
            }

            return { analyte_id: sv.analyte_id, value: sv.value }
        })

        return parsed
    }

    function saveMatrixDashboard(): void {
        if (matrice) {
            if (matrice.matrix_status === StatusMatrix.active || StatusMatrix.archived) {
                history.push(`/secure/matrix/edit_matrix/update/${matrice.template_id}/${matrice.company_id}/${matrice.export_code}/${matrice.version}`)
            } else {
                history.push(`/secure/matrix/edit_matrix/edition/${matrice.template_id}/${matrice.company_id}/${matrice.export_code}/${matrice.version}`)
            }
        }
        if (!matrice) {
            history.push(`/secure/matrix/new_matrix`)
        }
    }


    useEffect(() => {
        dispatch(resetSinglePreferences())
        dispatch(resetOutliers())
    }, [dashboardName])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showPreferences, setShowPreferences] = useState(false);
    const handleClosePreferences = () => setShowPreferences(false);

    useDidMountEffect(() => {
        if (searchStatus === 'succeeded' && Object.values(samplesForData).length === 0) {
            setShow(true)
        }
    }, [searchStatus])


    useDidMountEffect(() => {
        if (preferencesStatus === 'succeeded' && preferences) {
            setShowPreferences(true)
        }
    }, [preferencesStatus])

    return (
        <Container className='container-xl'>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('error')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('dashboard.no_results')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('buttons.close')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPreferences} onHide={handleClosePreferences}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('dashboard.matrix.preferences.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('dashboard.matrix.preferences.loaded')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePreferences}>
                        {t('buttons.close')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {(manageDashboardStatus === 'loading' ||
                companyStatus === 'loading' ||
                laboratoriesStatus === 'loading' ||
                preferencesStatus === 'loading') &&
                <Loader />
            }

            {manageDashboardStatus === 'succeeded' &&
                companyStatus === 'succeeded' &&
                laboratoriesStatus === 'succeeded' &&
                <Container className={'mt-2 p-0 container-xl'}>

                    {/* Composant filter */}
                    <DashboardFilterComponent dashboardName={dashboardName} triggerMatrixFields={triggerMatrixFields} setTriggerMatrixFields={setTriggerMatrixFields} />

                    {searchStatus === 'succeeded' && Object.values(samplesForData).length > 0 &&
                        <Container className={'m-0 p-0 container-xl'}>

                            <Formik
                                key={dashboardName}
                                initialValues={{
                                    selected_values: [],
                                    commentary: ''
                                }}
                                onSubmit={async (values) => {
                                    setTriggerMatrixFields(true)
                                    const parsed = parseSelectedValues(values)

                                    dispatch(setSelectedValues({ commentary: values.commentary, selected_values: parsed }))

                                    await saveMatrixDashboard()
                                }}
                            >

                                {/* Callback function containing Formik state and helpers that handle common form actions */}
                                {function myForm({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue }) {

                                    useEffect(() => {
                                        const savePreferences = async () => {
                                            await saveMatrixPreference(parseSelectedValues(values))
                                            dispatch(resetSinglePreferences())
                                            dispatch(fetchAllDashboardPreferences())

                                        }
                                        savePreferences()
                                            .catch(console.error);
                                    }, [matrixFields])


                                    return (
                                        <Form onSubmit={handleSubmit}>
                                            {/* Composant graph */}
                                            <DashboardGraphsComponent values={values} dashboardName={dashboardName} />
                                            <Row>
                                                <Col lg={2} className='pe-2'>
                                                    {/* Composant options */}
                                                    <DashboardOptionsComponent dashboardName={dashboardName} />

                                                    {/* Composant parameters */}
                                                    <DashboardParamsComponent dashboardName={dashboardName} />

                                                    {dashboardName === 'matrix' &&
                                                        <Container className={'bg-white mt-2 p-0 ms-0 me-0 rounded-3'}>
                                                            <Container className="p-2 bg-white rounded-3 mb-0">
                                                                <Button className="no-print w-100 m-auto btn btn-secondary" type="submit" variant="secondary">
                                                                    {t('dashboard.buttons.save_dashboard')}
                                                                </Button>
                                                            </Container>
                                                        </Container>
                                                    }
                                                </Col>
                                                <Col lg={10} className='ps-0'>

                                                    <ScrollSync>
                                                        <>
                                                            {/* Composant initial filtering */}
                                                            {dashboardName === 'matrix' &&
                                                                <>
                                                                    <DashboardInitialFilteringComponent dashboardName={dashboardName} />

                                                                    <DashboardSelectionComponent
                                                                        dashboardName={dashboardName}
                                                                        errors={errors}
                                                                        setFieldValue={setFieldValue}
                                                                        handleChange={handleChange}
                                                                        touched={touched}
                                                                        values={values}
                                                                    />

                                                                    {singleMatrixStatus === 'succeeded' && matrice &&
                                                                        [StatusMatrix.archived, StatusMatrix.active].includes(matrice.matrix_status) &&
                                                                        <DashboardFormerMatrixComponent dashboardName={dashboardName} matrice={matrice} />
                                                                    }
                                                                    {singleMatrixStatus === 'succeeded' && matrice && lastActiveMatrice &&
                                                                        [StatusMatrix.in_progress, StatusMatrix.ready].includes(matrice.matrix_status) &&
                                                                        <DashboardFormerMatrixComponent dashboardName={dashboardName} matrice={lastActiveMatrice} />
                                                                    }
                                                                </>
                                                            }
                                                            {dashboardName === 'results-analysis' &&
                                                                <DashboardSelectionComponent dashboardName={dashboardName} />

                                                            }
                                                            {/* Composant group by */}
                                                            {groupBy.field && groupBy.field !== '' &&
                                                                <DashboardGroupByComponent dashboardName={dashboardName} />
                                                            }

                                                            {/* Composant samples */}
                                                            <DashboardSamplesComponent dashboardName={dashboardName} />
                                                        </>
                                                    </ScrollSync>
                                                </Col>

                                            </Row>
                                        </Form>

                                    )
                                }}
                            </Formik>
                        </Container>
                    }
                </Container>
            }
            {(manageDashboardStatus === 'failed') &&

                <div className="text-danger">{manageDashboardError}</div>
            }
        </Container>
    )
}

export default withTranslation()(DashboardContainer)