import qs from 'qs'
import env from '../common/utils/env'
import { FilterBy, SortBy } from '../types/types'
import AbstractApi from './AbstractApi'

export interface Translations {
    [key: string]: string
}

export interface JsonName {
    name: string
    translations: Translations
}

export interface ResultsCategory {
    category_code: string
    category_name: JsonName
    order: number
    results_data: Array<ResultsData>
}

export interface State {
    status: string
    error_code: string
    error_parameters: any
    message: string
    integration_date: Date
    internal_status: string
}

export interface AnalysisState extends State {
    analysis_value: string
    nir_value: string
}

export interface Results {
    method: 'NIR'|'WC'
    analyte: string
    raw_result: number
    unit: string
    mdi?: number
    gh?: number
    gh_max: number
    target_concentration: number
    warning_upper_limit: number
    warning_lower_limit: number
    alarm_upper_limit: number
    alarm_lower_limit: number
    status: 'OK'|'NOK'|'Pending validation' 
    state?: State
}

export interface Input_Result {
    code_test: string
    code_analyte: string
    result: string
    status: string
    error_message: string
    creation_date: string
    created_by: string
    last_update: string
    last_update_by: string
    validation_date: string
    validated_by: string
}

export interface NirAnalysis {
    laboratory_id: number
    nir_code: string
    sample_id: number
    scan_date_time: Date
    nir_type: string
    product_group: string
    product: string
    label_product_info_1: string
    value_product_info_1: string
    label_product_info_2: string
    value_product_info_2: string
    label_product_info_3: string
    value_product_info_3: string
    label_product_info_4: string
    value_product_info_4: string
    label_product_info_5: string
    value_product_info_5: string
    value_product_info_6: string
    value_product_info_7: string
    value_product_info_8: string
    value_product_info_9: string
    state?: AnalysisState
    results: Array<Results>
}

export interface ResultsData {
    test: string
    analyte: string
    analyte_id: string
    analyte_name: JsonName
    test_name: JsonName
    raw_result: string
    raw_dm_result: string
    raw_ai_result: string
    dm_result: string
    ai_result: string
    conversion_factor: number
    decimals: number
    raw_units: string
    units: string
    target: number
    raw_uncertainty: string
    uncertainty: number
    samtest_status: string
    method_type: string
    method: string
    quantification_low: number
    quantification_high: number
    re_analysis: boolean
    broker_lab: boolean
    accreditation: boolean
    order: number
    color: string
    interpretation: string
    result_type: string
    visible: boolean
    mdi: number
    gh: number
    target_range: string
    source: string
    nir_code: string
}

export interface NutrientResult {
    analysis_glims_id: number
    type_detail: string
    cofanotes: string
    sample_status: string
    release_id: string
    product_code: string
    product_name: string
    date_analysis_released: Date
    date_analysis_started: Date
    mass: number
    mass_units: string
    packaging: string
    package_condition: string
    results_pattern_name: string
    dm_flag: boolean
    results_categories: Array<ResultsCategory>
}
export interface Result {
    id: number
    analysis_id: number
    type: string
    result_date: Date
    link: string
}

export interface AnalysisPackageTests {
    code_test: string
    code_analyte: string
    analysis_group_name: string
    theoretical_value: number | null
    price?: number  | null
    currency: string
    cost?: number  | null
    weight?: number | null
    period?: number | null
    unit: string
}

export interface AnalysisPackage {
    id?: number
    package_id: number
    comment: string
    price?: number | null
    currency: string
    cost?: number | null
    weight?: number | null
    period?: number | null
    analysis_package_tests: Array<AnalysisPackageTests>
}

export interface StatusLabel {
    status: string
    label: string
}

export interface CustomValue {
    field_id: number
    value: string|null
}

export interface Transport {
    transport_id: number
    distance: string
}

export type NirInternalStatus = 'Pending NIR' | 'Integration issue' | 'NIR blocked' | 'Pending results'| 'Pending Autocalc' | 'Administration issue' | 'Autocalc issue' | 'Pending validation' | 'Pending calculation' | 'Calculation issue' | 'released' | 'partial' | 'in progress'| 'Publication issue' | 'Autocalc integration issue' | 'Ambiguity issue' | ''

export enum AnalysisInputResultStatus {
    pending_results = 'Pending results',
    pending_validation = 'Pending validation',
    released = 'Released',
    empty = '',
  }
  export type TypeAnalysisInputResultStatus = AnalysisInputResultStatus.pending_results | AnalysisInputResultStatus.pending_validation | AnalysisInputResultStatus.released | AnalysisInputResultStatus.empty
export interface Analysis {
    id: number 
    display_id: string
    employee_id: number
    company_id: number
    laboratory_id: number
    type_code: "FG" | "RM" | "FP"
    type_id: number
    family_id: number
    family_details: string
    pattern_id: number
    client_reference: string
    sample_date: Date | null
    denomination: string
    denomination_id?: number
    comment: string
    client_sample_id?: string

    // Feed & Raw material
    lot?: string
    factory?: string

    // Feed
    manufacturing_date?: Date | null
    farmer?: string
    formula_code?: string

    // Fodder
    farmer_id?: number
    silo?: string
    variety_id?: number
    harvest_date?: Date | null
    harvest_year?: number|null
    sample_comment?: string

    // Raw material
    delivery_date?: Date | null
    provider?: string
    provider_id?: number
    origin?: string
    origin_id?: number

    status: string
    creation_date: Date | null
    receipt_date: Date | null
    receipt_glims_date: Date | null
    conformity_date: Date | null
    validation_date: Date | null
    cancellation_date: Date | null
    last_update?: Date
    last_update_by?: number
    improper_id?: number
    comment_improper?:string
    analysis_packages: Array<AnalysisPackage>
    id_pdc: number | null
    id_sample: number | null
    last_print_date?: Date
    last_date?: Date,
    currency: string
    price: number | null,
    cost: number | null, 
    period: number | null, 
    weight: number | null
    total_price?: number
    total_weight?: number
    max_period?: number
    urgent:boolean
    nir_code: string
    internal_status: NirInternalStatus
    internal_status_date?: Date
    //input result
    input_result_status: TypeAnalysisInputResultStatus
    input_result_status_date?: Date
    input_analysis_results?: Array<AnalysisInputResult>

    custom_values: Array<CustomValue>
    transports: Array<Transport>
    nutrient_result?: NutrientResult
    additional_analysis?: Array<AdditionalAnalysis>
    processing_id?: number
}

export interface AdditionalAnalysis {
    ref_laboratory_id: number
    ref_analysis_id: number
}
export interface AnalysisInputResult {
  code_test: string
  code_analyte: string
  result?: string | null
  status?: TypeInputResultStatus | null
  error_message?: TypeErrorMessage | null
  creation_date?: Date | null
  created_by?: string | null
  last_update?: Date | null
  last_update_by?: string | null
  validation_date?: Date | null
  validated_by?: TypeValidationBy | null
}


export enum InputResultStatus {
    pending_results = 'Pending results',
    pending_calculation = 'Pending Calculation',
    pending_validation = 'Pending validation',
    incorrect_format = 'Incorrect format',
    nok = 'NOK',
    ok = 'OK',
  }
  
  export enum CreationName {
    ajout_automatique = 'Ajout automatique',
  }
  
  export enum ErrorMessage {
    numericalExpected = 'Incorrect format - numerical expected',
    analyteTestNotFound = 'Incorrect format - analyte_test not found',
    noError = '',
  }
  
  export enum ValidationBy {
    autoValidation = 'Auto validation',
  }
  
  export type TypeInputResultStatus = InputResultStatus.pending_results | InputResultStatus.pending_calculation | InputResultStatus.pending_validation | InputResultStatus.incorrect_format | InputResultStatus.nok | InputResultStatus.ok
  
  export type TypeErrorMessage = ErrorMessage.numericalExpected | ErrorMessage.analyteTestNotFound | ErrorMessage.noError
  
  export type TypeValidationBy = ValidationBy.autoValidation

  
export interface AnalysisRequest {
    employee_id: number
    company_id: number
    type_code: "FG" | "RM" | "FP"
    type_id: number
    family_id: number
    family_details: string
    pattern_id?: number | null
    client_reference: string
    sample_date?: Date | null
    denomination: string
    denomination_id?: number
    comment: string
    

    // Feed & Raw material
    lot?: string
    factory?: string

    // Feed
    manufacturing_date?: Date | null
    farmer?: string
    formula_code?: string

    // Fodder
    farmer_id?: number
    silo?: string
    variety_id?: number
    harvest_date?: Date | null
    harvest_year?: number|null
    sample_comment?: string


    // Raw material
    delivery_date?: Date | null
    provider?: string
    provider_id?: number
    origin?: string
    origin_id?: number

    last_print_date?: Date | null

    price?: number | null,
    currency: string
    cost?: number | null, 
    period?: number | null, 
    weight?: number | null

    id_pdc?: number | null
    id_sample?: number | null
    
    urgent:boolean,
    custom_values: Array<CustomValue>
    transports: Array<Transport>

    analysis_packages: Array<AnalysisPackage>

    nir_code?: string | null
    additional_analysis?: Array<AdditionalAnalysis>
    processing_id?: number
}

export interface ResultsList {
    files_tab: string[]
}

export interface NirActionBody {
    laboratory_id: number,
    nir_code: string,
    sample_id: number,
    scan_date_time: Date
}

export interface NirActionBodyAllScan {
    laboratory_id: number,
    sample_id: number,
}

interface Details {
    trace_id: string
    external_code: string
    external_message: string
}
export interface ExternalCallState {
    laboratory_id: number,
    analysis_id: number,
    system: 'autocalc' | 'sustainability',
    call_date_time: Date,
    status: string,
    error_code: string,
    error_message: string,
    param: string,
    details: Details
  
}

export enum System {
    autocalc ='autocalc',
    sustainability= 'sustainability'
}


class AnalysisApi extends AbstractApi { 

    private static instance: AnalysisApi

    public static getInstance() : AnalysisApi {
        if (!AnalysisApi.instance) {
            throw new Error('Initialize instance before.')
        }
        return AnalysisApi.instance
    }

    public static initInstance(token: string) : void {
        AnalysisApi.instance = new AnalysisApi(env.ANALYSIS_API_URL, token)
    }

    public async getResultsByAnalysisId(laboratoryId: number, id: number, pdf: boolean, plb: boolean, archives: boolean): Promise<ResultsList> {
        try {
            const response = await this.service.get(`/results/${laboratoryId}/${id}?pdf=${pdf}&plb=${plb}&archives=${archives}`)
            return response.data || []
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }
    
    public async getExternalCallState(laboratoryId: number, analysisId: number, system: System): Promise<ExternalCallState> {
        try {

            const response = await this.service.get(`/analysis/${laboratoryId}/${analysisId}/externals?system=${system}`)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async nirReimport(body: NirActionBody): Promise<void> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            await this.service.post(`/nir/results/reimport`,body, config)
            return
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async nirDelete(body: NirActionBodyAllScan): Promise<void> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            await this.service.post(`/nir/results/delete`,body, config)
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async nirRelease(body: NirActionBody): Promise<void> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            await this.service.post(`/nir/results/release`,body, config)
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    
    public async relaunchAutocalc(laboratoryId: number, analysisId: number): Promise<void> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            await this.service.post(`/nir/results/relaunch-autocalc/${laboratoryId}/${analysisId}`, config)
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

        
    public async forceAutocalc(analysis: Analysis): Promise<void> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            await this.service.post(`/nir/results/force-autocalc/${analysis.laboratory_id}/${analysis.id}`, config)
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getResults(filterBy: FilterBy, pdf: boolean, plb: boolean): Promise<Blob> {

        const filter = Object.assign(
            {...filterBy},
            filterBy.pk && filterBy.pk.inTuple ? {pk: {inTuple: filterBy.pk.inTuple.map(pk => `${pk.labId}:${pk.id}`)}} : null
        )
        const config = {
            params: {
                filter: qs.stringify(filter, {arrayFormat: "comma"}),
                pdf,
                plb
            }
        }

        try {
            const response = await this.service.get(`/results`, {...config, responseType: 'blob'})
            return response.data || []
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteGlimsResults(laboratoryId: number, id: number): Promise<void> {
        try {
            await this.service.delete(`/results/${laboratoryId}/${id}`)
            return; 
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async updateInputResult(laboratoryId: number, id: number, input_analysis_results: AnalysisInputResult): Promise<AnalysisInputResult> {
        try {
            const response = await this.service.put(`/analysis/${laboratoryId}/${id}/inputs`, input_analysis_results )
            const data = await response.data
            return data 
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async transferAnalysis(filterBy: FilterBy, companyId: number, employeeId: number): Promise<{count: number, data: Array<Analysis>}> {
        const filter = Object.assign(
            {...filterBy},
            filterBy.pk && filterBy.pk.inTuple ? {pk: {inTuple: filterBy.pk.inTuple.map(pk => `${pk.labId}:${pk.id}`)}} : null
        )
        try {
            const response = await this.service.post(`/transfer-analysis?filter=${qs.stringify(filter, {arrayFormat: "comma"})}&companyId=${companyId}&employeeId=${employeeId}`)
            return {
                count: response.data.count || 0,
                data: response.data.data || []
            }
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getDocument(key: string): Promise<Blob> {
        key =encodeURIComponent(key)
        try {
            const response = await this.service.get(`/documents/download?key=${key}`, {responseType: 'blob'})
            return response.data || []
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getAnalysis(filterBy: FilterBy, sortBy: Array<SortBy>, limit: number, offset: number): Promise<{count: number, result_count: number, data: Array<Analysis>}> {
        try {
            const filters: FilterBy = { ...filterBy }
            Object.keys(filters).forEach(name => {
                if (filters[name].in) {
                    filters[name] = { in: filters[name].in }
                }
            })
            const config = {
                params: Object.assign(
                    {
                        limit, 
                        offset
                    },
                    Object.keys(filters).length > 0 ? {filter: qs.stringify(filters)} : null,
                    Object.keys(sortBy).length > 0 ? {sortby: sortBy.map(item => item.direction + item.name)} : null
                )
            }
            const response = await this.service.get('/analysis', config)
            return {
                count: response.data.count || 0,
                result_count: response.data.result_count,
                data: response.data.data || []
            }
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getAnalysisById(laboratoryId: number, id: number): Promise<Analysis> {
        try {
            const response = await this.service.get(`/analysis/${laboratoryId}/${id}`)
            const data = await response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    
    public async getAnalysisByDisplayedId(laboratoryId: number, displayedId: string): Promise<Analysis> {
        try {
            const encodedDisplayedId = encodeURIComponent(displayedId)
            const response = await this.service.get(`/analysis/${laboratoryId}/displayedId/${encodedDisplayedId}`)
            const data = await response.data

            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getNirAnalysisByKey(laboratoryId: number, sampleId: number): Promise<NirAnalysis[]> {
        try {
            const response = await this.service.get(`/nir/results/${laboratoryId}/${sampleId}/status`)
            const data = await response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getNirAnalysisInputsResults(laboratoryId: number, sampleId: number): Promise<Input_Result[]> {
        try {
            const response = await this.service.get(`/analysis/${laboratoryId}/${sampleId}/inputs`)
            const data = await response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postAnalysis(laboratoryId: number, analysis: AnalysisRequest): Promise<Analysis> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await this.service.post(`/analysis/${laboratoryId}`, analysis, config)

            const data = await response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async updateAnalysis(analysis: AnalysisRequest, laboratoryId: number, id: number): Promise<Analysis> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            const response = await this.service.put(`/analysis/${laboratoryId}/${id}`, analysis, config)

            const data = await response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async updatePartialAnalysis(analysis: AnalysisRequest, laboratoryId: number, id: number): Promise<Analysis> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await this.service.post(`/analysis/${laboratoryId}/${id}/partial`, analysis, config)
            const data = await response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async updateStatusAnalysis(status: string, laboratoryId: number, id: number, improperId?: number, comment_improper?: string): Promise<Analysis> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const encodedStatus = encodeURIComponent(status)

            let improperParams=""       
            if(status === "received_improper" && improperId!== undefined){
                improperParams = `&improperId=${improperId}`
            }
            const response = await this.service.put(`/analysis/${laboratoryId}/${id}/status/?status=${encodedStatus}${improperParams}`, {"comment_improper" : comment_improper}, config)
          
            const data = await response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }
}

export default AnalysisApi
