import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Container, Form, Button, Row, Col } from 'react-bootstrap';

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/rootReducer'
import { fetchSingleAnalysisDisplayedById } from '../../../state/analysisSlice';
import Loader from '../../../common/components/Loader';
import {useFormik } from 'formik';
import * as Yup from 'yup';
import InlineLoader from '../../../common/components/InlineLoader';
import SelectControl from '../../../common/components/SelectControl'
import userHasRequiredRoles from '../../../common/auth/userHasRequiredRoles';
import { fetchLaboratories } from '../../../state/configSlice';
import { setAuthCurLabId } from '../../../state/authUserSlice';

type SearchAnalysisProps = WithTranslation

const SearchAnalysis: React.FC<SearchAnalysisProps> = ({ t }) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const { user: authUser, authCurLabId } = useSelector( (state: RootState) => state.authUser )

    const analysisByDisplayedId = useSelector((state: RootState) => state.analysis.analysis.dataByDisplayedId)
    const analysisStatus = useSelector((state: RootState) => state.analysis.analysis.singleFetchStatus)

    // Laboratories state
    const laboratories = useSelector( (state: RootState) => state.config.laboratories.data )
    const laboratoriesStatus = useSelector( (state: RootState) => state.config.laboratories.fetchStatus )
    const laboratoriesError = useSelector( (state: RootState) => state.config.laboratories.fetchError )

    const hasMultipleLabs : boolean = authUser.laboratories.length > 1 || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])
    const isUserAdmin : boolean = userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])

    const validationSchema = Yup.object().shape({
        analysisId: Yup.string()
            .required(t('home.analysis_request_ID_not_found'))
    })

    const {values, errors, touched, isSubmitting, setSubmitting, setFieldError, handleChange, handleSubmit} = useFormik({
        initialValues: {
            analysisId: '',
            laboratoryId: authCurLabId
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setSubmitting}) => {
            setSubmitting(true)
            dispatch(fetchSingleAnalysisDisplayedById({laboratoryId: values.laboratoryId, displayId: values.analysisId}))
        },
    })

    useEffect(()=> {
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }
    },[laboratoriesStatus, dispatch])

    useEffect(() => {
        if (analysisStatus === 'succeeded') {
            setSubmitting(false)
            if (analysisByDisplayedId[values.laboratoryId] && analysisByDisplayedId[values.laboratoryId][values.analysisId]) {
                history.push(`/secure/analysis/${values.laboratoryId}/${analysisByDisplayedId[values.laboratoryId][values.analysisId].id}`)
            } else {
                setFieldError('analysisId', t('home.analysis_request_ID_not_found'))
            }
        }
        if (analysisStatus === 'failed') {
            setSubmitting(false)
            setFieldError('analysisId', t('home.analysis_request_ID_not_found'))
        }
    }, [analysisByDisplayedId, analysisStatus])



    return (

        <Container className="mt-3 m-lg-5">

            { isSubmitting &&
                <Loader />
            }

            <Form onSubmit={handleSubmit}>
                <Row className="justify-content-center">
                    <Col xs={12} lg={hasMultipleLabs ? 3 : 5} className="text-center text-lg-end">
                        <Form.Label htmlFor="formAnalysisID" className="pt-1">
                            {t('home.search_analysis_request')}
                        </Form.Label>
                    </Col>
                    <Col xs={12} lg={2}>
                        <Form.Group className="mb-2">
                            <Form.Control
                                placeholder={t('home.analysis_request_ID')}
                                name="analysisId"
                                isInvalid={touched.analysisId && !!errors.analysisId}
                                value={values.analysisId}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">{errors.analysisId}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={1} className="text-center text-lg-start">
                        <Button variant="secondary" type="submit">
                            {t('buttons.search')}
                        </Button>
                    </Col>
                    <Col xs={12} lg={2}>
                        {
                            hasMultipleLabs ?
                            <Form.Group className="mb-2">
                                {laboratoriesStatus === 'loading' && 
                                    <InlineLoader /> }
                                {laboratoriesStatus === 'succeeded' && 
                                    <SelectControl 
                                        name="laboratoryId" 
                                        items={Object.fromEntries(Object.values(laboratories).filter(l => isUserAdmin || authUser.role_levels.find(r => r.laboratory_id === l.id) !== undefined).map(l => ([l.id, l])))} 
                                        currentItemId={values.laboratoryId} 
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {handleChange(e); dispatch(setAuthCurLabId(Number(e.target.value)))}} 
                                    /> }
                                {laboratoriesStatus === 'failed' && 
                                    <div>{laboratoriesError}</div> }
                            </Form.Group>
                            :
                            ""

                        }
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default withTranslation()(SearchAnalysis)