import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import AnalysisApi, { NirActionBody, NirActionBodyAllScan, NirAnalysis, Input_Result} from '../api/analysisApi';
import { FilterBy, SortBy, Filter, Data, TupleId } from '../types/types';

interface Page {
    ids: Array<TupleId>
    fetching: boolean
}

interface Pagination {
    nbByPage: number
    nbPage: number
    currentPage: number
    filterBy: FilterBy
    sortBy: Array<SortBy>
    pages: Data<Page>
    count: number
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: string | undefined
}

export interface State {
    data: Data<Data<NirAnalysis[]>>
    pagination: Pagination
    filtered: Array<string>
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    filter: {
        filterBy: FilterBy
        sortBy: Array<SortBy>
    }
}

const initialState = {
    data: {},
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filter: undefined,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    filtered: [],
    fetchStatus: 'idle',
    fetchError: undefined,
    filter: {
        filterBy: {},
        sortBy: []
    }
} as State

export const fetchNirAnalysis = createAsyncThunk(
    'analysis/fetchNirAnalysis', 
    async ({laboratoryId, analysisId}: {laboratoryId: number, analysisId: number}) => {
        const response = await AnalysisApi.getInstance().getNirAnalysisByKey(laboratoryId, analysisId)
        return response as NirAnalysis[]
    }
)

export const fetchInputResults = createAsyncThunk(
    'analysis/InputResults', 
    async ({laboratoryId, analysisId}: {laboratoryId: number, analysisId: number}) => {
        const response = await AnalysisApi.getInstance().getNirAnalysisInputsResults(laboratoryId, analysisId)
        return response as Input_Result[]
    }
)

export const nirReimport = createAsyncThunk(
    'nir/results/nirReimport', 
    async (body: NirActionBody) => {
        await AnalysisApi.getInstance().nirReimport(body)
    }
)

export const nirRelease = createAsyncThunk(
    'nir/results/nirRelease', 
    async (body: NirActionBody) => {
        await AnalysisApi.getInstance().nirRelease(body)
    }
)

export const nirCancel = createAsyncThunk(
    'nir/results/nirCancel', 
    async (body: NirActionBodyAllScan) => {
        await AnalysisApi.getInstance().nirDelete(body)
    }
)

export const nirDelete = createAsyncThunk(
    'nir/results/nirDelete', 
    async (body: NirActionBodyAllScan) => {
        await AnalysisApi.getInstance().nirDelete(body)
    }
)

const nirAnalysisSlice = createSlice({
    name: 'nirAnalysis',
    initialState,
    reducers: {
        nirFilterByReducer(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy
            state.filter.filterBy = filterBy
            state.pagination.pages = {}
            state.pagination.currentPage = 1
            state.pagination.status = 'idle'
        },
        addFilterByClause(state: State, action: PayloadAction<{name: string, filter: Filter}>) {
            if (Object.keys(action.payload.filter).length > 0) {
                state.pagination.filterBy[action.payload.name] = action.payload.filter
            } else {
                delete state.pagination.filterBy[action.payload.name]
            }
            state.pagination.pages = {}
            state.pagination.currentPage = 1
            state.pagination.status = 'idle'
        },
        removeFilterByClauseByName(state: State, action: PayloadAction<string>) {
            delete state.pagination.filterBy[action.payload]
            state.pagination.pages = {}
            state.pagination.currentPage = 1
            state.pagination.status = 'idle'
        },
        nirSortByReducer(state: State, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload
            state.filter.sortBy = action.payload
            state.pagination.pages = {}
            state.pagination.currentPage = 1
            state.pagination.status = 'idle'
        },
        addSortByClause(state: State, action: PayloadAction<SortBy>) {
            const index = state.pagination.sortBy.findIndex(item => item.name === action.payload.name)
            if (index > -1) {
                state.pagination.sortBy[index] = action.payload
            } else {
                state.pagination.sortBy.push(action.payload)
            }
            state.pagination.pages = {}
            state.pagination.currentPage = 1
            state.pagination.status = 'idle'
        },
        removeSortByClauseByName(state: State, action: PayloadAction<string>) {
            const index = state.pagination.sortBy.findIndex(item => item.name === action.payload)
            if (index > -1) {
                state.pagination.sortBy.splice(index, 1)
            }
            state.pagination.pages = {}
            state.pagination.currentPage = 1
            state.pagination.status = 'idle'
        },
        filterReducer(state: State, action: PayloadAction<string|undefined>) {
            if (action.payload === "" || action.payload === undefined) {
                delete state.pagination.filterBy.all
            } else {
                state.pagination.filterBy.all = { like: action.payload }
            }
            state.pagination.status = 'idle'
        },
        setFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchNirAnalysis.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchNirAnalysis.fulfilled, (state, action) => {
            const item = action.payload
            if (item.length > 0) {
                if (!state.data[item[0].laboratory_id]) {
                    state.data[item[0].laboratory_id] = {}
                }
                state.data[item[0].laboratory_id][item[0].sample_id] = item
            }

            else {
                if (state.data[action.meta.arg.laboratoryId] && state.data[action.meta.arg.laboratoryId][action.meta.arg.analysisId]){
                    delete state.data[action.meta.arg.laboratoryId][action.meta.arg.analysisId]
                }
            }
            
            state.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchNirAnalysis.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
    }
  })

  export const {
    nirFilterByReducer,
    addFilterByClause,
    removeFilterByClauseByName,
    nirSortByReducer,
    addSortByClause,
    removeSortByClauseByName,
    filterReducer,
    setFiltered
  } = nirAnalysisSlice.actions

  export default nirAnalysisSlice.reducer