import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import bwipjs from 'bwip-js';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../state/rootReducer';

import dbt from '../../../../../common/utils/dbTranslation';
import AnalysisApi, { Analysis } from '../../../../../api/analysisApi';
import { useReactToPrint } from 'react-to-print';
import { analysisReducer } from '../../../../../state/analysisSlice';
import { getAnalyteKey } from '../../../../../api/configApi';
import { PatternPackageTestUtils } from '../../../../pdc/helpers/PatternPackageTestsUtils';

interface PrintLabelButtonProps extends WithTranslation {
    analysis: Analysis
}

const PrintLabelButton: React.FC<PrintLabelButtonProps> =({t, analysis}) => {

    const dispatch = useDispatch()

    const laboratoryLabelRef = useRef(null)
    
    const [printDate, setPrintDate] = useState<Date|undefined>()
    
    const [isPrint, setIsPrint] = useState<boolean>(false)

    const qrCodeRef = useRef<HTMLCanvasElement|null>(null)
    const [qrCode, setQrCode] = useState<string|undefined>()

    const { language } = useSelector((state: RootState) => state.local)
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    const types = useSelector((state: RootState) => state.config.types.data)
    const families = useSelector((state: RootState) => state.config.families.data)
    const patterns = useSelector((state: RootState) => state.config.patterns.data)
    const packages = useSelector((state: RootState) => state.config.packages.data)
    const analytes = useSelector((state: RootState) => state.analytes.data)
    const companies = useSelector((state: RootState) => state.companies.data)
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
 
    const handleAfterPrint = async () => {
        setIsPrint(true)
    }

    const handleBeforeGetContent = async () => {
        setPrintDate(new Date())
    }

    const handlePrint = useReactToPrint({
        pageStyle: `@page { 
            size: 105mm 70mm; margin: 0; 
            border: 1px solid white;
            height: 99%;
            page-break-after: avoid;
            page-break-before: avoid; }`,
        onBeforeGetContent: handleBeforeGetContent,
        content: () => laboratoryLabelRef.current,
        onAfterPrint: handleAfterPrint
    })

    useEffect(() => {
        if (isPrint) {
            const updateAnalysis = {...analysis, last_print_date: printDate}
            
            AnalysisApi.getInstance().updatePartialAnalysis(updateAnalysis, analysis.laboratory_id, analysis.id)
            .then((response) => {
                dispatch(analysisReducer(response))
            })
            .catch((err) => {
                console.error("Unable to update print date", err)
            })
            .finally(() => {
                setIsPrint(false)
            })
        }
    }, [analysis, dispatch, isPrint, printDate])

    useEffect(() => {
        if (qrCodeRef.current) {
            let qrText = ""
            switch (types[analysis.type_id].code) {
                case 'RM':
                    qrText += analysis.id
                    break;
                case 'FG':
                    qrText += analysis.id+ 
                    "\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" + // 24 tabs to go to field "product"
                    families[analysis.family_id].code +
                    "\t" + // 1 tabs to go to field "company_id"
                    (analysis.company_id ? companies[analysis.company_id].code : "")+
                    "\t" // 1 tabs to go to field "other"
                    if(laboratories[analysis.laboratory_id].language_id === 1){// language_id === 1 Français
                        qrText += "Autre"
                    }else  if(laboratories[analysis.laboratory_id].language_id === 3){// language_id === 3 Espagnol
                        qrText += "Otras"
                    }else{
                        qrText += "Other"
                    }
                    break;
                case 'FP':
                    qrText += 
                        (analysis.company_id ? companies[analysis.company_id].code : "") + 
                        "\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" + // 21 tabs to go to field "external reference"
                        analysis.id + 
                        "\t\t" + // 2 tabs to go to field "product"
                        families[analysis.family_id].code +
                        "\t\t\t\t" // 4 tabs to validate field value and avoid to open select menu (actual field here: manufactoring date)
                    break;
            }

            if( qrText !== "" ) {
                try {
                    const canvas = bwipjs.toCanvas(qrCodeRef.current, {
                        bcid:        'qrcode',      // Barcode type
                        text:        qrText            // Text to encode
                    })
                    const url = canvas.toDataURL('image/png')
                    setQrCode(url)
                } catch(err) {
                    console.error("Unable to generate QR code. ", err)
                }
            }
        }
    }, [analysis.company_id, analysis.family_id, analysis.id, analysis.type_id, companies, families, t, types])

    /**
     * Component containing analysis label printed content
     */
    const LaboratoryLabel = React.forwardRef<HTMLDivElement, unknown>(function LaboratoryLabel(props, ref: React.Ref<HTMLDivElement>) {
        return (
            <Container ref={ref} className="d-none d-print-block lab-label">
            {/* <Container ref={ref} className="d-print-block lab-label"> */}
                <Row>
                    <Col>
                        {/* Analysis ID */}
                        <b>{t('print.lab_label.analysis_id')}{t('colon')}{Intl.NumberFormat(authUser.locale).format(analysis.id)}</b>
                        <br />

                        {/* Emergency */}
                        {!!analysis.urgent &&
                            <b className="text-urgent">{t('print.lab_label.emergency')}</b>
                        }
                        <br />

                        {/* Sample type */}
                        {t('print.lab_label.type')}{t('colon')}<b>{dbt(types, analysis.type_id, language.id, "name")}</b>
                        <br />
                        {/* Company name */}
                        {t('print.lab_label.company_name')}{t('colon')}<b>{analysis.company_id ? companies[analysis.company_id]?.name : ""}</b>
                        <br />
                        {/* Family name */}
                        {t('print.lab_label.family_name')}{t('colon')}<b>{dbt(families, analysis.family_id, language.id, "name")}</b>
                        <br />
                        {/* Denomination */}
                        {t('print.lab_label.denomination')}{t('colon')}<b>{analysis.denomination}</b>
                        <br />
                        {/* Family details */}
                        {t('print.lab_label.family_details')}{t('colon')}<i>{analysis.family_details}</i>
                        <br />

                        {/* FP only */}
                        {analysis.type_code === "FP" &&
                            <>
                                {/* Code formule */}
                                {t('print.lab_label.formula_code')}{t('colon')}<b>{analysis.formula_code ? analysis.formula_code : ""}</b>
                                <br />
                            </>
                        }

                        {/* RM only */}
                        {analysis.type_code === "RM" &&
                            <>
                                {/* Client reference */}
                                {t('print.lab_label.client_reference')}{t('colon')}<b>{analysis.client_reference}</b>
                                <br />
                            </>
                        }
                        {/* FP or RM only */}
                        {(analysis.type_code === "FP" || analysis.type_code === "RM") &&
                            <>
                                {/* N° Lot */}
                                {t('print.lab_label.lot')}{t('colon')}<b>{analysis.lot ? analysis.lot : ""}</b>
                                <br />
                            </>
                        }

                        {/* Receipt date */}
                        {t('print.lab_label.receipt_date')}{t('colon')}<i>{analysis.receipt_date && Intl.DateTimeFormat(authUser.locale).format(new Date(analysis.receipt_date))}</i>
                        <br />
                        {/* Receipt by - populated by authenticated user */}
                        {t('print.lab_label.receipt_by')}{t('colon')}<i>{authUser.lastname + ' ' + authUser.firstname}</i>
                        <br />
                        <br />

                    </Col>
                    {/* Reference QR code */}
                    <Col xs="auto" className="d-flex flex-column align-items-center">
                        <canvas hidden ref={qrCodeRef} />
                        {!!qrCode && 
                            <>
                                {types[analysis.type_id].code === "FG" &&
                                    <span>{t('print.lab_label.reference')}{t('colon')}</span>
                                }
                                {types[analysis.type_id].code === "RM" &&
                                    <span>{t('print.lab_label.reference')}{t('colon')}</span>
                                }
                                {types[analysis.type_id].code === "FP" &&
                                    <span>{t('print.lab_label.customer_code')}{t('colon')}</span>
                                }
                                <span>
                                    <img alt="QR-Code" src={qrCode} />
                                </span>
                            </>
                        }
                    </Col>
                </Row>

                {/* Pattern name */}
                <Row>
                    <Col>
                        {t('print.lab_label.pattern')}{t('colon')}<span>{dbt(patterns, analysis.pattern_id, language.id, "name")}</span>
                    </Col>
                </Row>

                {/* Tests list */}
                <Row className="smalltext">
                    {analysis.analysis_packages.map(pack => (
                        <Col key={pack.id} xs={6}>
                            <ul className="mb-0">
                                <li>{dbt(packages, pack.package_id, language.id, "name")}
                                    {pack.comment && 
                                        <>
                                            <br />
                                            <i>{pack.comment}</i>
                                        </>
                                    }
                                    <ul className="mb-0">
                                        {pack.analysis_package_tests.map(test => (
                                            <li key={getAnalyteKey({...test, laboratory_id: analysis.laboratory_id})}>
                                                {PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup ({...test, laboratory_id: analysis.laboratory_id }, analytes, language, false) + (test.theoretical_value !== 0 ? " (" + test.theoretical_value + ")" : "")}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </Col>
                    ))}
                </Row>
                <br/>

                {/* Analysis global comment */}
                <Row>
                    <Col>
                        {t('print.lab_label.comment')}{t('colon')}<i className="smalltext">{analysis.comment}</i>
                        <br/>
                    </Col>
                </Row>
            </Container>   
        )
    })

    return (
        <>
            <LaboratoryLabel ref={laboratoryLabelRef} />
            <Button className="m-1 no-print" variant="secondary" onClick={handlePrint} >{t('buttons.print_ticket')}</Button>
        </>
    )
}

export default withTranslation()(PrintLabelButton)