import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Loader from '../../../common/components/Loader'
import moment from 'moment';

import useQuery from '../../../common/utils/useQuery'
import { getResultsByFilter } from '../../../common/utils/getResults';
import { FilterBy } from '../../../types/types';
import { Redirect } from 'react-router-dom';

type ResultsContainerProps = WithTranslation

const ResultsContainer: React.FC<ResultsContainerProps> = ({ t }) => {
    const query = useQuery();

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error, setError] = useState<string|undefined>(undefined)

    useEffect(() => {
        const lastDateAllQuery = query.get("last_date_all")
        const employeeQuery = query.get("employee")
        const companyQuery = query.get("company")
        const statusQuery = query.get("status")
        
        if(lastDateAllQuery || employeeQuery || companyQuery || statusQuery){
            const filter: FilterBy = Object.assign({},
                lastDateAllQuery ? { last_date_all: { gte: moment(lastDateAllQuery).format("YYYY-MM-DD") } } : null,
                statusQuery ? { status: { in: statusQuery.split(',') } } : null,
                employeeQuery ? { employee: { in: employeeQuery } } : null,
                companyQuery ? { company: { in: companyQuery } } : null
            )

            getResultsByFilter(filter, true, true)
            .catch(err => {
                setError(err.message)
            })
            .finally(() => {
                setIsLoading(false)
            })
        } else {
            setError("Missing query parameters")
            setIsLoading(false)
        }
    }, [query])

    return (
        <>
            {isLoading &&
                <Loader />
            }
            {!isLoading && !error &&
                <Redirect to="/secure/home" />
            }
            {!isLoading && error &&
                <Container className="mt-2 bg-white text-danger">
                    <p>{t('forms_errors.unexpected_error') + ' (' + error + ')'}</p>
                </Container>
            }
        </>
    )
}

export default withTranslation()(ResultsContainer)

