import { useOktaAuth } from '@okta/okta-react';

function userHasOnlyRole(onlyRole: string): boolean {
    const { oktaAuth } = useOktaAuth();

    try {
        const accessToken = oktaAuth.getAccessToken()
        if (accessToken) {
            const accessDecoded = oktaAuth.token.decode(accessToken)

            let userHasOnlyRole = false
            if (accessDecoded.payload.groups && Array.isArray(accessDecoded.payload.groups)) {
                userHasOnlyRole = accessDecoded.payload.groups.includes(onlyRole) && accessDecoded.payload.groups.length < 3
            }
            return userHasOnlyRole
        }
    } catch(err) {
        return false
    }
    return false
}

export default userHasOnlyRole