import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../state/rootReducer'

import { Row, Col, Button, Modal, Badge, OverlayTrigger, Popover, Container, FormControl, InputGroup } from 'react-bootstrap';
import {ReactComponent as SearchIcon} from 'bootstrap-icons/icons/search.svg'
import {ReactComponent as AddIcon} from 'bootstrap-icons/icons/plus.svg'
import {ReactComponent as RefreshIcon} from 'bootstrap-icons/icons/arrow-clockwise.svg'
import { ReactComponent as QuestionCircle } from 'bootstrap-icons/icons/question-circle.svg'

import Loader from '../../common/components/Loader'
import FarmerList from './components/FarmerList'
import FarmerForm from './components/FarmerForm'
import { fetchFarmers, setMyFilterBy, fetchExportFarmers, refreshFarmers } from '../../state/farmerSlice'
import { fetchCompanies } from '../../state/companiesSlice';
import { fetchEmployees } from '../../state/employeesSlice';
import UploadExcelFile from '../../common/components/UploadExcelFile';
import UsersApi from '../../api/usersApi';
import ExceptionModal from '../../common/utils/ExceptionModal';


type FarmerListContainerProps = WithTranslation

const FarmerListContainer: React.FC<FarmerListContainerProps> = ({t}) => {
    const dispatch = useDispatch()
    const { user: authUser } = useSelector( (state: RootState) => state.authUser )

    // api error
    const [apiError, setApiError] = useState<unknown>(undefined)

    // farmer state
    const farmers = useSelector( (state: RootState) => state.farmers.farmer.data )
    const farmersFiltered = useSelector( (state: RootState) => state.farmers.farmer.filtered )
    const fetchStatusByUser = useSelector( (state: RootState) => state.farmers.farmer.fetchStatusByUser )
    const pagination = useSelector( (state: RootState) => state.farmers.farmer.myPagination )
    const filterStatus = useSelector( (state: RootState) => state.farmers.farmer.filterStatus )
    const fetchError = useSelector( (state: RootState) => state.farmers.farmer.fetchError )

    const companiesStatus = useSelector((state: RootState) => state.companies.fetchStatus)
    const employeesStatus = useSelector((state: RootState) => state.employees.fetchStatus)

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const farmerPost: any = {id: 0, employee_id: authUser.employee_id, name: "", code: "", zip_code: "", city: "", country: "", active: true}

    const [filter, setFilter] = useState("")

    const filterFarmer = (s: string) => {
        dispatch(setMyFilterBy({...pagination.filterBy, all: {like: s}}))
    }

    //Catch event 'Enter' key
    function KeyDown(e: any) {
        if (e.key === 'Enter') {
            filterFarmer(filter)
        }
    }
    
    useEffect( () => {
        if (companiesStatus === 'idle') {
           dispatch(fetchCompanies())
        }
       if (employeesStatus === 'idle') {
          dispatch(fetchEmployees())
        }
        if (companiesStatus === 'succeeded' && employeesStatus === 'succeeded' && fetchStatusByUser === 'idle') {
            dispatch(fetchFarmers())
        }
    }, [farmers, fetchStatusByUser,filterStatus, companiesStatus, employeesStatus, authUser.employee_id])

    const onExcelFileChange = async (e : React.ChangeEvent<HTMLInputElement>) => {
        setApiError(undefined)
        if (e.target.files && e.target.files[0]) {
            try {
                await UsersApi.getInstance().importFarmers(e.target.files[0], null, authUser.employee_id, "user")
                dispatch(fetchFarmers())
            } catch (err) {
                setApiError(err)
            } finally {
                e.target.value = '' // to be able to select the same file twice
            }
        }
    };
    
    return (
        <Container className="justify-content-center">
            <ExceptionModal 
                type="import" 
                title={t('admin.farmers.title')} 
                message={t('admin.farmers.errors.msg_import_failed')}
                error={apiError}
                onHide={() => {
                    setApiError(undefined)
                }}
            />
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{t('forms.farmers.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FarmerForm farmerObject={farmerPost} />
                </Modal.Body>
            </Modal>

            <Row className="mt-2 mt-lg-5 align-center">
                <Col xs={12} md={12} lg={1} className="mb-2 me-auto">
                    <h5 className="d-inline-flex">
                        <Badge pill bg="primary">{Intl.NumberFormat(authUser.locale).format(Object.keys(farmersFiltered).length)}</Badge> 
                        <span className="ms-2 align-text-bottom fw-bold">{t('admin.farmers.title')}</span>
                    </h5>

                </Col>
                <Col xs={6} md={6} lg={5} className="mb-2 me-auto">
                    <Button size="sm"  variant="secondary" type="button" onClick={handleShow}>
                        <span>
                            <AddIcon width="20px" height="20px" className="align-text-bottom" />
                            <span className="align-text-bottom">{t('buttons.add')}</span>
                        </span>
                    </Button>
                </Col>

                <Col md="auto" className="border border-primary rounded">
                    <Row className="mb-1">
                        <Col>
                            <span className="px-1">{t('forms.farmers.export_import.title')}</span>
                        </Col>
                        <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Popover style={{ maxWidth: 'none' }} >
                                        <Popover.Header as="h3">{t('forms.farmers.export_import.header')}</Popover.Header>
                                        <Popover.Body>
                                            <div>{t('forms.farmers.export_import.text')}</div>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <QuestionCircle width="16px" height="100%" />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <UploadExcelFile uploadAction={onExcelFileChange} tiny={true} />
                        </Col>
                        <Col>
                            <Button className="btn btn-xs" variant="secondary" type="button" onClick={() => dispatch(fetchExportFarmers({company_id: null, employee_id: authUser.employee_id, type : "user"}))}>
                                <span className="px-1">
                                    {t('buttons.export')}
                                </span>
                            </Button>
                        </Col>
                    </Row>   
                </Col>

                <Col xs={10} md={10} lg={3} className="pe-1">
                    <InputGroup size="sm">
                        <FormControl
                            placeholder={t('admin.farmers.search_placeholder')}
                            aria-label={t('admin.farmers.search_button')}
                            onChange={e => setFilter(e.target.value)}
                            onKeyDown={KeyDown}
                            value={filter}
                        />
                        <Button variant="secondary" onClick={e => filterFarmer(filter)} >
                            <SearchIcon width="20px" height="100%" className="align-text-top"/>
                        </Button>
                    </InputGroup>
                </Col>
                <Col xs="auto" className="ps-1">
                    <Button size="sm" variant="secondary" onClick={e => dispatch(refreshFarmers)} >
                        <RefreshIcon width="20px" height="100%" className="align-text-top"/>
                    </Button>
                </Col>
            </Row>

            { (fetchStatusByUser === 'loading' || employeesStatus === 'loading' || companiesStatus === 'loading') &&
                <Loader />
            }
            { fetchStatusByUser === 'succeeded' && employeesStatus === 'succeeded' && companiesStatus === 'succeeded' &&
                <FarmerList/>
            }
            { (fetchStatusByUser === 'failed' || employeesStatus === 'failed' || companiesStatus === 'failed') &&
                <div className="text-danger">{fetchError}</div>
            }
        </Container>
    )
}

export default withTranslation()(FarmerListContainer)