import React, { useState, } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../state/rootReducer';

import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import Select from 'react-select'

import Loader from '../../../../common/components/Loader';
import InlineLoader from '../../../../common/components/InlineLoader';

import { Formik } from 'formik';
import * as Yup from 'yup';

import UsersApi, { Company, Employee, Farmer } from '../../../../api/usersApi';
import { farmerReducer} from '../../../../state/farmerSlice';
import { Data } from '../../../../types/types';

interface FarmerContainerProps extends WithTranslation {
    currentLaboratoryId: number
    currentCompanyId: number
    currentEmployeeId: number
    farmerObject: Farmer
}


const FarmerForm: React.FC<FarmerContainerProps> = ({ t, currentLaboratoryId, currentCompanyId, currentEmployeeId, farmerObject }) => {

    const dispatch = useDispatch()
    const companies = useSelector((state: RootState) => state.companies.data)
    const companiesByLabId = useSelector((state: RootState) => state.companies.byLabId)
    const companiesStatus = useSelector((state: RootState) => state.companies.fetchStatus)
    const companiesError = useSelector((state: RootState) => state.companies.fetchError)

    const employees = useSelector((state: RootState) => state.employees.data)
    const employeesStatus = useSelector((state: RootState) => state.employees.fetchStatus)
    const employeesError = useSelector((state: RootState) => state.employees.fetchError)

    // farmers state
    const farmers = useSelector((state: RootState) => state.farmers.farmer.data)

    const [apiError, setApiError] = useState(undefined)
    const [formSubmitted, setFormSubmitted] = useState(false)


    const validationSchema = Yup.object().shape({
        companyId: Yup.number()
            .moreThan(0, t('admin.farmers.errors.company_required'))
            .required(t('admin.farmers.errors.company_required')),
        employeeId: Yup.number()
            .moreThan(0, t('admin.farmers.errors.employee_required'))
            .required(t('admin.farmers.errors.employee_required')),
        name: Yup.string().trim()
            .max(50, t('forms_errors.max_length'))
            .required(t('admin.farmers.errors.name_required'))
            .test("test-name", t('admin.farmers.errors.name_exists'), 
              function(value: string|undefined) {
                  if(Object.values(farmers).filter(item => item.name === value && item.employee_id === this.parent.employeeId  && item.id !== farmerObject.id).length > 0 ){
                    return false;
                  }else{
                    return true
                  }
              }),
        code: Yup.string()
            .max(255, t('forms_errors.max_length')),
        zip_code: Yup.string()
            .max(18, t('forms_errors.max_length')),
        city: Yup.string()
            .max(255, t('forms_errors.max_length')),
        country: Yup.string()
            .max(255, t('forms_errors.max_length'))
    })



    return (
        <Container>

            { formSubmitted &&
                <Container className="mt-3 mb-3">
                    {farmerObject.id === 0 ? t('forms.farmers.msg_success_add') : t('forms.farmers.msg_success_modify')}<br />
                </Container>
            }

            { !formSubmitted &&
                <Formik
                    initialValues={{
                        companyId: currentCompanyId ? currentCompanyId : farmerObject.employee_id ? employees[farmerObject.employee_id].companies[0] ? employees[farmerObject.employee_id].companies[0].company_id : 0 : 0,
                        employeeId: currentEmployeeId || farmerObject.employee_id || 0,
                        code: farmerObject.code,
                        name: farmerObject.name,
                        zip_code: farmerObject.zip_code,
                        city: farmerObject.city,
                        country: farmerObject.country,
                        active: farmerObject.active
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        // When button submits form and form is in the process of submitting, submit button is disabled
                        setSubmitting(true)
                        setApiError(undefined)

                        const farmer: Farmer = {
                            employee_id: values.employeeId,
                            name: values.name,
                            code: values.code,
                            zip_code: values.zip_code,
                            city: values.city,
                            country: values.country,
                            active : values.active
                        }

                        if (farmerObject.id && farmerObject.id !== 0) {
                            UsersApi.getInstance().putFarmer(farmer, farmerObject.id)
                                .then(() => {
                                    farmer.id = farmerObject.id
                                    dispatch(farmerReducer(farmer))
                                    setFormSubmitted(true)
                                })
                                .catch(() => {
                                    setApiError(t('forms_errors.unexpected_error'))
                                })
                                .finally(() => {
                                    setSubmitting(false)
                                })
                        } else {
                            UsersApi.getInstance().postFarmer(farmer)
                                .then((response: Farmer) => {
                                    dispatch(farmerReducer(response))
                                    setFormSubmitted(true)
                                })
                                .catch((err) => {
                                    console.error(err)
                                    setApiError(t('forms_errors.unexpected_error'))
                                })
                                .finally(() => {
                                    setSubmitting(false)
                                })
                        }
                    }}
                >
                    {/* Callback function containing Formik state and helpers that handle common form actions */}
                    {({ values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            { isSubmitting &&
                                <Loader />
                            }
                            { apiError &&
                                <Container className="text-danger">
                                    {farmerObject.id === 0 ? t('forms.farmers.msg_failed_add') : t('forms.farmers.msg_failed_modify')}
                                    <br />
                                    {apiError}

                                </Container>
                            }

                            <Form.Group>
                                <Form.Label>{t('admin.farmers.form.company')} *</Form.Label>
                                {/* <SelectControlUsers name='employeeId' items={getEmployees()} currentItemId={values.employeeId} onChange={handleChange} /> */}
                                {companiesStatus === 'loading' &&
                                    <InlineLoader />}
                                {companiesStatus === 'failed' &&
                                    <div>{companiesError}</div>}
                                {companiesStatus === 'succeeded' &&
                                    <>
                                        <Select
                                            className={touched.companyId && !!errors.companyId ? `imgErrorInvalidInput` : ``}
                                            isInvalid={touched.companyId && !!errors.companyId}
                                            styles={{
                                                control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                    ...styles,
                                                    borderColor: touched.companyId && !!errors.companyId ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                    boxShadow: state.isFocused ? touched.companyId && !!errors.companyId ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                    '&:hover': {
                                                        borderColor: touched.companyId && !!errors.companyId ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                        boxShadow: state.isFocused ? touched.companyId && !!errors.companyId ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                    }

                                                }),
                                                option: (base: Data<string>) => ({
                                                    ...base,
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    height: 25,
                                                    minHeight: 25
                                                })
                                            }}

                                            noOptionsMessage={() => t('admin.users.form.no_company')}
                                            value={companies[values.companyId]}
                                            name='companyId'
                                            placeholder={t('admin.farmers.form.default_company')}
                                            getOptionLabel={(company: Company) => company.code + " - " + company.name}
                                            getOptionValue={(company: Company) => company.id}
                                            isDisabled={currentCompanyId > 0 ? true : false}
                                            options={companiesByLabId[currentLaboratoryId].map(id => companies[id]).sort((a, b) => a.name.localeCompare(b.name))}

                                            onChange={(company: Company) => {
                                                if (values.companyId !== company.id) {
                                                    setFieldValue("employeeId", 0)
                                                }
                                                setFieldValue("companyId", company.id)
                                            }}
                                        />
                                        {touched.companyId && !!errors.companyId ? <Form.Text className="invalid-feedback d-block">{errors.companyId}</Form.Text> : null}
                                    </>
                                }
                            </Form.Group>

                            <Form.Group controlId="formUser">
                                <Form.Label>{t('forms.farmers.employee')} *</Form.Label>
                                {/* <SelectControlUsers name='employeeId' items={getEmployees()} currentItemId={values.employeeId} onChange={handleChange} /> */}
                                    {employeesStatus === 'loading' &&
                                        <InlineLoader />}
                                    {employeesStatus === 'failed' &&
                                        <div>{employeesError}</div>}
                                    {employeesStatus === 'succeeded' &&
                                    <>
                                        <Select
                                            className={touched.employeeId && !!errors.employeeId ? `imgErrorInvalidInput` : ``}
                                            isInvalid={touched.employeeId && !!errors.employeeId}
                                            styles={{
                                                control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                    ...styles,
                                                    borderColor: touched.employeeId && !!errors.employeeId ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                    boxShadow: state.isFocused ? touched.employeeId && !!errors.employeeId ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                    '&:hover': {
                                                        borderColor: touched.employeeId && !!errors.employeeId ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                        boxShadow: state.isFocused ? touched.employeeId && !!errors.employeeId ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                    }

                                                }),
                                                option: (base: Data<string>) => ({
                                                    ...base,
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    height: 25,
                                                    minHeight: 25
                                                })
                                            }}

                                            noOptionsMessage={() => t('admin.users.form.no_employee')}
                                            value={values.employeeId && employees[values.employeeId]}
                                            name='employeeId'
                                            placeholder={t('admin.farmers.form.default_employee')}
                                            getOptionLabel={(employee: Employee) => employee.code + " - " + employee.firstname + " " + employee.lastname}
                                            getOptionValue={(employee: Employee) => employee.id}
                                            isDisabled={currentEmployeeId > 0 ? true : false}
                                            isClearable={true}
                                            options={Object.values(employees).filter(item => item.companies.findIndex(x => x.company_id ===  values.companyId) >= 0 ? true : false).sort((a, b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname))}
                                            
                                            onChange={(employee: Employee) => {
                                                if (employee) {
                                                    setFieldValue("employeeId", employee.id)
                                                } else {
                                                    setFieldValue("employeeId", 0)
                                                }
                                            }}
                                        />
                                        {touched.employeeId && !!errors.employeeId ? <Form.Text className="invalid-feedback d-block">{errors.employeeId}</Form.Text> : null}
                                        </>
                                    }
                            </Form.Group>

                            <Row>
                                <Col sm="6">
                                    <Form.Group controlId="formName">
                                        <Form.Label>{t('forms.farmers.name')} *</Form.Label>
                                        <Form.Control className={touched.name && errors.name ? "is-invalid" : undefined} type="text" name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                        <Form.Text className="invalid-feedback">{errors.name}</Form.Text>
                                    </Form.Group>
                                </Col>

                                <Col sm="6">
                                    <Form.Group controlId="formCode">
                                        <Form.Label>{t('forms.farmers.code')}</Form.Label>
                                        <Form.Control className={touched.code && errors.code ? "is-invalid" : undefined} type="text" name='code' value={values.code} onChange={handleChange} onBlur={handleBlur} />
                                        <Form.Text className="invalid-feedback">{errors.code}</Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="4">
                                    <Form.Group controlId="formZipCode">
                                        <Form.Label>{t('forms.farmers.zip_code')}</Form.Label>
                                        <Form.Control className={touched.zip_code && errors.zip_code ? "is-invalid" : undefined} type="text" name='zip_code' value={values.zip_code} onChange={handleChange} onBlur={handleBlur} />
                                        <Form.Text className="invalid-feedback">{errors.zip_code}</Form.Text>
                                    </Form.Group>
                                </Col>

                                <Col sm="8">
                                    <Form.Group controlId="formCity">
                                        <Form.Label>{t('forms.farmers.city')}</Form.Label>
                                        <Form.Control className={touched.city && errors.city ? "is-invalid" : undefined} type="text" name='city' value={values.city} onChange={handleChange} onBlur={handleBlur} />
                                        <Form.Text className="invalid-feedback">{errors.city}</Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group controlId="formCountry">
                                <Form.Label>{t('forms.farmers.department')}</Form.Label>
                                <Form.Control className={touched.country && errors.country ? "is-invalid" : undefined} type="text" name='country' value={values.country} onChange={handleChange} onBlur={handleBlur} />
                                <Form.Text className="invalid-feedback">{errors.country}</Form.Text>
                            </Form.Group>

                            <Container className="text-end mt-2">
                                <Button disabled={isSubmitting} variant="secondary" type="submit">
                                    {farmerObject.id === 0 ? t('buttons.add') : t('buttons.edit')}
                                </Button>
                            </Container>
                        </Form>
                    )}
                </Formik>
            }
        </Container>
    )
}

export default withTranslation()(FarmerForm)