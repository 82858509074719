const env = {
    OKTA_DOMAIN: window._env_.OKTA_DOMAIN,
    OKTA_ENDPOINT: window._env_.OKTA_ENDPOINT,
    OKTA_CLIENTID: window._env_.OKTA_CLIENTID,
    OKTA_SSO_LINK: window._env_.OKTA_SSO_LINK,
    CONFIG_API_URL: window._env_.CONFIG_API_URL,
    ANALYSIS_API_URL: window._env_.ANALYSIS_API_URL,
    PUBLISH_API_URL: window._env_.PUBLISH_API_URL,
    USERS_API_URL: window._env_.USERS_API_URL,
    MATRIX_API_URL: window._env_.MATRIX_API_URL,
    PDC_API_URL: window._env_.PDC_API_URL,
    EXTERNAL_SERVICES_API_URL: window._env_.EXTERNAL_SERVICES_API_URL,
    DASHBOARD_API_URL: window._env_.DASHBOARD_API_URL,
}

export default env