import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Container, Form, Button, Modal, Row, Col, Accordion } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../state/rootReducer';

import dbt from '../../../../../common/utils/dbTranslation';
import { Link } from "react-router-dom";
import { analysisReducer, StatusAnalysis } from '../../../../../state/analysisSlice';
import AnalysisApi, { Analysis, AnalysisPackage, AnalysisPackageTests } from '../../../../../api/analysisApi';
import AnalysisImproperForm from '../components/AnalysisImproperForm';
import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg';
import getResults from '../../../../../common/utils/getResults';
import PcpwComponent from '../../../../../common/components/PcpwComponent';
import pcpwUtils from '../../../../../common/utils/pcpwUtils';
import { TupleId } from '../../../../../types/types';
import { useFormik } from 'formik';
import CustomField from '../components/CustomField';
import FormCheckBoxUrgent from '../components/FormCheckboxUrgent'
import ContextAwareToggle from '../../../../../common/components/ContextAwareToggle';
import ResultsTable from '../../../../../common/components/ResultsTable';
import GoBackButton from '../../../../../common/components/GoBackButton';
import AccordionColToggle from '../../../../../common/components/AccordionColToggle';
import useSecurityRoles from '../../../../../common/auth/useSecurityRoles';
import { printPotentialNumber } from '../../../../../common/utils/utils';
import { getAnalyteKey } from '../../../../../api/configApi';
import { PatternPackageTestUtils } from '../../../../pdc/helpers/PatternPackageTestsUtils';


interface ConsultAnalysisMobileContainerProps extends WithTranslation {
    analysisDB: Analysis,
}

const ConsultAnalysisMobile: React.FC<ConsultAnalysisMobileContainerProps> = ({ t, analysisDB }) => {

    const dispatch = useDispatch()
    const { userGrantLaboratory, userGrantUpdateDone, userGrantUpdateReceived, userGrantUpdateRegistered, userHasRoleAll, userHasRoleCancel, userHasRoleClient, userHasRoleUser } = useSecurityRoles()
    
    // user state
    const authUser = useSelector((state: RootState) => state.authUser.user)

    // fields state
    const fields = useSelector((state: RootState) => state.fields.data)
    
    // forms state
    const forms = useSelector((state: RootState) => state.forms.data)

    // pdc state
    const controlPlans = useSelector((state: RootState) => state.pdc.data)

    const typeLabels = useSelector((state: RootState) => state.local.typeLabels)

    // patterns state
    const patterns = useSelector((state: RootState) => state.config.patterns.data)

    // languages state
    const language = useSelector((state: RootState) => state.local.language)

    //  Laboratories state
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)

    // Types state
    const types = useSelector((state: RootState) => state.config.types.data)

    // families state
    const families = useSelector((state: RootState) => state.config.families.data)

    // Package state
    const packages = useSelector((state: RootState) => state.config.packages.data)
    const packagesStatus = useSelector((state: RootState) => state.config.packages.fetchStatus)

    // analytes state
    const analytes = useSelector((state: RootState) => state.analytes.data)
    const analytesStatus = useSelector((state: RootState) => state.analytes.fetchStatus)

    // Employees state
    const employees = useSelector((state: RootState) => state.employees.data)

    // Companies state
    const companies = useSelector((state: RootState) => state.companies.data)

    // Impropers state
    const impropers = useSelector((state: RootState) => state.config.impropers.data)

    // Local states
    const [apiStatusError, setApiStatusError] = useState(undefined)

    const [packageWithTests, setpackageWithTests] = useState<AnalysisPackage[]>([]);

    const [canUpdate, setCanUpdate] = useState(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPopupCancel, setShowPopupCancel] = useState(false);

    const [showError, setShowError] = useState(false);
  
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const [msgError, setMsgError] = useState("");

    // Init custom forms
    const customForm = Object.values(forms).find(f => f.laboratory_id === analysisDB.laboratory_id && f.type_code === analysisDB.type_code)

    let flattenFields: number[] = []
    if (customForm) {
        // Flatten form's fields to initialize missing values in further steps
        const flattenClientFields = customForm.fields.client.map(line => line.filter(col => fields[col.field_id].storage_type === "dynamic"))
            .reduce<number[]>((acc, cur) => {
                const fieldIds = cur.map(c => c.field_id)
                return acc.concat(fieldIds)
            }, [])
        const flattenSampleFields = customForm.fields.sample.map(line => line.filter(col => fields[col.field_id].storage_type === "dynamic"))
            .reduce<number[]>((acc, cur) => {
                const fieldIds = cur.map(c => c.field_id)
                return acc.concat(fieldIds)
            }, [])
        flattenFields = flattenClientFields.concat(flattenSampleFields)
    }

    const formikProps = useFormik<Analysis>({
        initialValues: {
            ...analysisDB, 
            analysis_packages: analysisDB.analysis_packages.map(
                ap => ({
                    ...ap,
                    analysis_package_tests: [...ap.analysis_package_tests]
                        .sort((a: AnalysisPackageTests, b: AnalysisPackageTests) => 
                            (PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup( {...a, laboratory_id: analysisDB.laboratory_id}, analytes, language, false)).localeCompare(PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup( {...b, laboratory_id: analysisDB.laboratory_id}, analytes, language, false)))

                })
            ),
            custom_values: analysisDB.custom_values.concat(
                flattenFields.filter(fieldId => !analysisDB.custom_values.map(cv => cv.field_id).includes(fieldId))
                .map(fieldId => (
                    { field_id: fieldId, value: null }
                ))
            )
        },
        onSubmit: () => undefined
    })

    const { values, setValues } = formikProps

    let NameTypeAnalysis = ""

    switch (values.type_code) {
        case "FG":
            NameTypeAnalysis = t('forms.analysis.FG')

            break;
        case "RM":
            NameTypeAnalysis = t('forms.analysis.RM')

            break;
        case "FP":
            NameTypeAnalysis = t('forms.analysis.FP')

            break;
    }

    const updateStatus = async (status: StatusAnalysis) => {

        await AnalysisApi.getInstance().updateStatusAnalysis(status, values.laboratory_id, values.id)
            .then((analysisRep: Analysis) => {
                if (Object.prototype.hasOwnProperty.call(analysisRep, "error")) {
                    setApiStatusError(t('forms_errors.unexpected_error'))
                } else {
                    //update local state 
                    const analysisData: Analysis = { ...values }
                    analysisData.last_update = analysisRep.last_update
                    analysisData.status = analysisRep.status
                    analysisData.cancellation_date = analysisRep.cancellation_date !== undefined ? analysisRep.cancellation_date : null
                    analysisData.conformity_date = analysisRep.conformity_date !== undefined ? analysisRep.conformity_date : null
                    analysisData.receipt_date = analysisRep.receipt_date !== undefined ? analysisRep.receipt_date : null
                    analysisData.validation_date = analysisRep.validation_date !== undefined ? analysisRep.validation_date : null
                    setValues(analysisData)

                    //dispatch analysis in root state analsyis
                    const analysisdispatch: Analysis = { ...analysisDB }
                    analysisdispatch.last_update = analysisRep.last_update
                    analysisdispatch.status = analysisRep.status
                    analysisdispatch.cancellation_date = analysisRep.cancellation_date !== undefined ? analysisRep.cancellation_date : null
                    analysisdispatch.conformity_date = analysisRep.conformity_date !== undefined ? analysisRep.conformity_date : null
                    analysisdispatch.receipt_date = analysisRep.receipt_date !== undefined ? analysisRep.receipt_date : null
                    analysisdispatch.validation_date = analysisRep.validation_date !== undefined ? analysisRep.validation_date : null

                    dispatch(analysisReducer(analysisdispatch))
                    setApiStatusError(undefined)
                }
            })
            .catch(() => {
                setApiStatusError(t('forms_errors.unexpected_error'))
            })

    }

    const familieInitValue = {
        id: values.family_id,
        comment: families[values.family_id] !== undefined ? dbt(families,values.family_id, language.id, "comment")  : "",
        detailsRequired: families[values.family_id] !== undefined ? families[values.family_id].details_required : false,
        name: families[values.family_id] !== undefined ? dbt(families, values.family_id, language.id, "name") : ""
    }

    const patternInitValue = {
        id: values.pattern_id,
        option: patterns[values.pattern_id] !== undefined ?  dbt(patterns, values.pattern_id, language.id, "option") : false,
        name: patterns[values.pattern_id] !== undefined ? dbt(patterns, values.pattern_id, language.id, "name") : ""
    }

    const analysisInitValue = {
        denomination: values.denomination,
        client_reference: values.client_reference,
        comment: values.comment,
        sample_date: values.sample_date && values.sample_date != null ? new Intl.DateTimeFormat(authUser.locale).format(new Date(values.sample_date)) : "",
        family_details: values.family_details,
        employee_id: values.employee_id,
        company_id: values.company_id,
        price: values.price,
        currency: values.currency,
        cost: values.cost,
        period: values.period,
        weight: values.weight,
        total_price: values.total_price,
        total_weight: values.total_weight,
        max_period: values.max_period,
        urgent: values.urgent
    }

    const grantUpdateRegistered = userGrantUpdateRegistered(analysisDB.laboratory_id, authUser)

    const grantUpdateReceived =userGrantUpdateReceived(analysisDB.laboratory_id, authUser)
    const grantUpdateDone = userGrantUpdateDone(analysisDB.laboratory_id, authUser)

    const hasRoleAll = userHasRoleAll(analysisDB.laboratory_id, authUser)
    const hasRoleClient = userHasRoleClient(analysisDB.laboratory_id, authUser)
    const hasRoleUser = userHasRoleUser(analysisDB.laboratory_id, authUser)
    const hasRoleCancel = userHasRoleCancel(analysisDB.laboratory_id, authUser)


    const getResultsFormatting = (checkedAnalysis: TupleId[], pdf: boolean, plb: boolean) => {
        return async () => {
            if(checkedAnalysis.length === 0){
                setMsgError(t('analysis.download_error_checked_msg'))
                handleShowError()
            }else{
                try {
                    await getResults(checkedAnalysis, pdf, plb)
                } catch(err) {
                    setMsgError(t("forms_errors.unexpected_error"))
                    handleShowError()
                }
            }
            handleClose()
        }
    }

    useEffect(() => {
        if (values) {
            const isUpdateAllowed = (
                grantUpdateRegistered && 
                values.status === StatusAnalysis.registered
            ) || (
                grantUpdateRegistered && 
                values.status === StatusAnalysis.received_improper &&
                values.improper_id && impropers[values.improper_id].editable
            ) || (
                grantUpdateReceived && (
                    values.status === StatusAnalysis.received_compliant ||
                    values.status === StatusAnalysis.received_improper
                )
            ) || (
                grantUpdateDone  && (
                    values.status === StatusAnalysis.partial ||
                    values.status === StatusAnalysis.done
                )
            )
            setCanUpdate(isUpdateAllowed)
        }
    }, [values, grantUpdateDone, grantUpdateReceived, grantUpdateRegistered])

    useEffect(() => {
        if (packagesStatus === 'succeeded' && analytesStatus === 'succeeded') {
            const packageWithTestsTmp: AnalysisPackage[] = []
            for (const Apackage of values.analysis_packages) {
                const testsInAnalysis: AnalysisPackageTests[] = []
                for (const Atest of Apackage.analysis_package_tests) {
                    testsInAnalysis.push({ ...Atest, ...{ name: PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup({...Atest, laboratory_id: values.laboratory_id}, analytes, language, false), ...{ theoretical_value: Atest.theoretical_value } }})
                }
                packageWithTestsTmp.push({ ...Apackage, ...{ name: dbt(packages, Apackage.package_id, language.id, "name") }, ...{ comment: Apackage.comment != null ? Apackage.comment : "" }, ...{ analysis_package_tests: testsInAnalysis } })
            }
            setpackageWithTests(packageWithTestsTmp)
        }
    }, [analytesStatus, packagesStatus, dispatch, values.analysis_packages, packages, language.id, analytes])

    return (
        <Container className="mt-2 p-0">
            <Modal size="lg" show={showError}  onHide={handleCloseError}>
                <Modal.Header>
                    <Modal.Title><span className="text-primary fw-bold"> {t('error')} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{msgError}</p>
                </Modal.Body>
            </Modal>

            <Container className="p-0">

                <Modal className="no-print" size="lg" show={showPopupCancel} onHide={() => setShowPopupCancel(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('forms.analysis.question_cancel_da')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="m-2">
                            {t('forms.analysis.message_cancel_da')}
                        </Row>
                        <Row >
                            <Col className="text-center p-0">
                                <Button className="m-1 no-print" variant="secondary" onClick={() => { setShowPopupCancel(false) }} >{t('forms.analysis.go_back')}</Button>
                            </Col>
                            <Col className="text-center p-0">
                                <Button className="m-1 no-print" variant="danger" onClick={() => { updateStatus(StatusAnalysis.canceled); setShowPopupCancel(false) }} >{t('forms.analysis.cancel_da')}</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('forms.analysis.impropers.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AnalysisImproperForm analysis={values} close={handleClose} setAnalysis={setValues} />
                    </Modal.Body>
                </Modal>
                <Container>
                    <Row >
                        <Col xs="auto">
                            <GoBackButton label={t('analysis.return_to_my_list_analysis')} />
                        </Col>
                    </Row>
                </Container>

                <Container className="pt-2 " >

                    <Row className="align-items-end">
                        <Col>
                            <h5 className="fw-bold"> {t('analysis.ar')} <span className="text-primary fw-bold"> {t('forms.analysis.number')}{printPotentialNumber(values.id, values.display_id, authUser)}</span></h5>
                        </Col>

                        <Col className="text-end">
                            <h6 className="fw-bold"> {t('analysis.status_word')} <span className="text-primary fw-bold"> {Object.keys(StatusAnalysis).includes(values.status) ? t('analysis.status.' + values.status) : ""}</span></h6>
                        </Col>
                    </Row>
                    {values.id_pdc !== null &&
                        <Row className="align-items-end">
                            <Col>
                                <h5 className="fw-bold"> {t('pdc.ar')} <span className="text-primary fw-bold"> {controlPlans[values.laboratory_id][values.id_pdc].name}</span></h5>
                            </Col>
                        </Row>
                    }
                    {values.additional_analysis && values.additional_analysis[0] && values.additional_analysis[0].ref_laboratory_id && values.additional_analysis[0].ref_analysis_id &&
                        <Row className="align-items-end">
                            <Col>
                                <h5 className="fw-bold">{t('forms.analysis.cargill_analysis')}<span className="text-primary fw-bold">
                                    <Link className="text-decoration-none text-primary fw-bold" to={`/secure/analysis/${values.additional_analysis[0].ref_laboratory_id}/${values.additional_analysis[0].ref_analysis_id}`}>
                                        {` ${t('forms.analysis.number')}${Intl.NumberFormat(authUser.locale).format(values.additional_analysis[0].ref_analysis_id)}`}
                                    </Link>
                                </span></h5>
                            </Col>
                        </Row>
                    }
                    {values.status === StatusAnalysis.received_improper &&
                        <Row className="pt-0 align-items-end">
                            <Col className="pt-0 text-end">
                                <span className="text-break fw-bold text-danger ">  {values.improper_id ? dbt(impropers, values.improper_id, language.id, "improper") : ""}{values.comment_improper !== undefined ? ` (${values.comment_improper})` : ``} </span>
                            </Col>
                        </Row>
                    }

                    
                        <Container className="p-2 bg-white mb-2 rounded-3">
                            <Accordion defaultActiveKey={values.status == 'done' || values.status == 'partial' ? [] : ["company"]}>
                            <Container className="p-0">
                                <Row>
                                    <AccordionColToggle eventKey={"company"} className={"text-primary fw-bold m-0"} label={t('forms.analysis.customer') + ' : ' + (companies[values.company_id] ? companies[values.company_id].name : "") }/>
                                    {canUpdate &&
                                        <Col xs={values.status == StatusAnalysis.done || values.status == StatusAnalysis.partial ? 1 : 2} className="text-end">
                                            <Link className="btn btn-link p-0 pe-1 text-primary" to={`/secure/analysis/${values.laboratory_id}/${values.id}/edit/1`}>
                                                <EditIcon width="24px" height="100%" className="align-text-top" />
                                            </Link>
                                        </Col>
                                    }
                                    {(values.status == StatusAnalysis.done || values.status == StatusAnalysis.partial) && 
                                        <Col xs="auto" className="text-end">
                                            <ContextAwareToggle eventKey="company" />
                                        </Col>
                                    }
                                </Row>

                                <Accordion.Collapse eventKey="company">
                                    <>
                                        <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>

                                        {(hasRoleAll || hasRoleClient) &&
                                            <Row>
                                                <Col lg>
                                                    <Form.Group>
                                                        <Form.Label><span className="text-black fw-bold">{t('forms.analysis.technician')}</span> {employees[analysisInitValue.employee_id] ? employees[analysisInitValue.employee_id].firstname + " " + employees[analysisInitValue.employee_id].lastname : ""} </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        }
                                            
                                        {customForm && customForm.fields.client.map((line, lIndex) => {
                                            return (
                                                <Row key={lIndex}>
                                                    {line.map((col, cIndex) => {
                                                        return (
                                                            <Col lg key={cIndex}>
                                                                <CustomField 
                                                                    laboratoryId={values.laboratory_id} // Current laboratory ID
                                                                    familyId={values.family_id} // Current family ID
                                                                    formId={customForm.id ?? 0} // Id of the form (used to retrieve matching datalist)
                                                                    fieldId={col.field_id} // ID of the field to display
                                                                    isReadOnly={true} // Boolean to know if field is editable or not
                                                                    isRequired={col.required} // Boolean to know if the field is required or not
                                                                    disabled={false} // Boolean to know if field is disabled
                                                                    formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                                />
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            )
                                        })} 
                                    </>
                                </Accordion.Collapse>
                            </Container>
                            </Accordion>
                        </Container>

                    <Container className="p-2 bg-white mb-2 rounded-3">
                        <Accordion defaultActiveKey={values.status == 'done' || values.status == 'partial' ? [] : ["family"]}>
                        <Container className="p-0">
                            <Row >
                                <AccordionColToggle eventKey={"family"} className={"text-primary fw-bold m-0"} label={`${NameTypeAnalysis} : ${familieInitValue.name}`}/>
                                {canUpdate &&
                                    <Col xs={values.status == StatusAnalysis.done || values.status == StatusAnalysis.partial ? 1 : 2} className="text-end">
                                        <Link className="btn btn-link p-0 pe-1 text-primary" to={`/secure/analysis/${values.laboratory_id}/${values.id}/edit/2`}>
                                            <EditIcon width="24px" height="100%" className="align-text-top" />
                                        </Link>
                                    </Col>
                                }
                                {(values.status == StatusAnalysis.done || values.status == StatusAnalysis.partial) && 
                                    <Col xs="auto" className="text-end">
                                        <ContextAwareToggle eventKey="family" />
                                    </Col>
                                }
                            </Row>

                            <Accordion.Collapse eventKey="family">
                                <>
                                    {familieInitValue.comment &&
                                        <Row>
                                            <Col>
                                                <Container className="p-1 mb-2 rounded-3 bg-info">
                                                    <p className="with-linebreak text-black fw-bold fst-italic">{familieInitValue.comment} </p>
                                                </Container>
                                            </Col>
                                        </Row>
                                    }

                                    {familieInitValue.detailsRequired === true &&
                                        <Row>
                                            <Col lg>
                                                <Form.Group controlId="formFamily_details">
                                                    <Row>
                                                        <Col>
                                                            <Form.Label className="mb-0"><span className="text-black fw-bold">{t('forms.analysis.' + typeLabels[types[values.type_id].code] + '_detail')}</span></Form.Label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <span className="with-linebreak">{analysisInitValue.family_details}</span>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    }

                                    {customForm && customForm.fields.sample.map((line, lIndex) => {
                                        return (
                                            <Row key={lIndex}>
                                                {line.map((col, cIndex) => {
                                                    return (
                                                        <Col lg key={cIndex}>
                                                            <CustomField 
                                                                laboratoryId={values.laboratory_id} // Current laboratory ID
                                                                familyId={values.family_id} // Current family ID
                                                                formId={customForm.id ?? 0} // Id of the form (used to retrieve matching datalist)
                                                                fieldId={col.field_id} // ID of the field to display
                                                                isReadOnly={true} // Boolean to know if field is editable or not
                                                                isRequired={col.required} // Boolean to know if the field is required or not
                                                                disabled={false} // Boolean to know if field is disabled
                                                                formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                            />
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        )
                                    })}
                                </>
                            </Accordion.Collapse>
                        </Container>
                        </Accordion>
                    </Container>

                    {values.nir_code &&
                        <Container className="p-2 bg-white mb-2 rounded-3" >
                            <Accordion defaultActiveKey={values.status == 'done' || values.status == 'partial' ? [] : ["nir"]}>
                                <Container className="p-0">
                                    <Row>
                                        <AccordionColToggle eventKey={"nir"} className={"text-primary fw-bold m-0"} label={`${t('forms.analysis.nirAnalysis')}`}/>
                                        {userHasRoleAll(values.laboratory_id, authUser) && (values.status == StatusAnalysis.done || values.status == StatusAnalysis.partial) && 
                                            <Col xs="auto" className="text-end">
                                                <ContextAwareToggle eventKey="nir" />
                                            </Col>
                                        }
                                    </Row>
                                    {userHasRoleAll(values.laboratory_id, authUser) && 
                                        <Accordion.Collapse eventKey="nir">
                                            <>
                                                <Row className="pb-3"><Col lg className="lineTitle"></Col></Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Form.Group className="mb-2">
                                                            <Form.Label>
                                                                <span className="text-black fw-bold">{t('forms.analysis.nir_productgroup')}{t('colon')} </span>
                                                                {(() => {
                                                                    const nir_type = types[values.type_id].nir_types.find(nt => nt.nir_code === values.nir_code)
                                                                
                                                                    return (
                                                                        <span>{nir_type ? nir_type.nir_type_code : types[values.type_id].name}</span>
                                                                    )
                                                                })()}
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <Form.Group className="mb-2">
                                                            <Form.Label>
                                                                <span className="text-black fw-bold">{t('forms.analysis.nir_product')}{t('colon')} </span>{values.family_id && families[values.family_id] ? families[values.family_id].name : ''} 
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </>
                                        </Accordion.Collapse>
                                    }
                                </Container>
                            </Accordion>
                        </Container>
                    }

                    {!values.nir_code &&
                        <Container className="p-2 bg-white mb-2 rounded-3" >

                            <Accordion defaultActiveKey={values.status == 'done' || values.status == 'partial' ? [] : ["pattern"]}>
                                <Container className="p-0">
                                    <Row>
                                        <AccordionColToggle eventKey={"pattern"} className={"text-primary fw-bold m-0"} label={`${t('forms.analysis.pattern')} ${patternInitValue.name}`}/>
                                        {canUpdate &&
                                            <Col xs={values.status == StatusAnalysis.done || values.status == StatusAnalysis.partial ? 1 : 2} className="text-end">
                                                <Link className="btn btn-link p-0 pe-1 text-primary" to={`/secure/analysis/${values.laboratory_id}/${values.id}/edit/3`}>
                                                    <EditIcon width="24px" height="100%" className="align-text-top" />
                                                </Link>
                                            </Col>
                                        }
                                        {(values.status == StatusAnalysis.done || values.status == StatusAnalysis.partial) && 
                                            <Col xs="auto" className="text-end">
                                                <ContextAwareToggle eventKey="pattern" />
                                            </Col>
                                        }
                                    </Row>

                                    <Accordion.Collapse eventKey="pattern">
                                        <>
                                            <PcpwComponent 
                                                price={analysisInitValue.total_price ? analysisInitValue.total_price : pcpwUtils.calculatePatternPrice(values, values.analysis_packages)}
                                                period={analysisInitValue.max_period ? analysisInitValue.max_period : pcpwUtils.calculatePatternPeriod(values, values.analysis_packages)} 
                                                weight={analysisInitValue.total_weight ? analysisInitValue.total_weight : pcpwUtils.calculatePatternWeight(values, values.analysis_packages)} 
                                                currency={laboratories[values.laboratory_id].currency} 
                                                type={"pattern"}
                                                size="lg"
                                            />
                                            {patternInitValue.option &&
                                                <Container className="p-1 mb-2 rounded-3 bg-info">
                                                    <p className="with-linebreak text-black fw-bold fst-italic">{patternInitValue.option} </p>
                                                </Container>
                                            }
                                            <Row>
                                                <Form.Group className="mt-2" controlId="formUrgent">
                                                    <FormCheckBoxUrgent
                                                        analysis = {values}
                                                        analysisFieldName = 'analysis'
                                                        markup = {laboratories[values.laboratory_id].markup}
                                                        readOnly = {true}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <span className="text-black fw-bold">{t('forms.analysis.comment')}</span>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col>
                                                    <span className="with-linebreak">{analysisInitValue.comment}</span>
                                                </Col>
                                            </Row>

                                            {packageWithTests.length > 0 && 
                                                <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                            }

                                            {
                                                <Container>
                                                    <Row >
                                                        {packageWithTests.map((arrayItemPackage: AnalysisPackage, indexPackage) => {
                                                            return (
                                                                <Col key={indexPackage} sm={12} className="p-0" >
                                                                    <Row>
                                                                        <Col>
                                                                            <Row><Col xs="auto" className="text-break" ><span className="text-black fw-bold">{dbt(packages, arrayItemPackage.package_id, language.id, "name")}</span></Col></Row>
                                                                            <Row><Col className="text-break"> <span className="with-linebreak text-black fw-normal fst-italic">{arrayItemPackage.comment}</span></Col></Row>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row >
                                                                        <Col sm={12}>
                                                                            <ul>
                                                                            {PatternPackageTestUtils.getTestsListByPackage(arrayItemPackage.analysis_package_tests, values.laboratory_id, analytes, language, authUser, laboratories[values.laboratory_id].currency).map((arrayItemTests, index) => {
                                                                                return (
                                                                                    <li key={index} className="text-break">
                                                                                                                                                                               
                                                                                        {arrayItemTests.displayName}

                                                                                        {arrayItemTests.theoretical_value !== null ? " (" + t('analysis.tv') + ": " + arrayItemTests.theoretical_value + ") " : null}
                                                                                    </li>
                                                                                    )
                                                                            })
                                                                            }
                                                                            </ul>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>)
                                                        }
                                                        )
                                                        }
                                                    </Row>
                                                </Container>
                                            }
                                        </>
                                    </Accordion.Collapse>
                                </Container>
                            </Accordion>
                        </Container>
                    }

                    {(values.status == 'done' || values.status == 'partial') &&
                        <Container className="p-2 bg-white mb-2 rounded-3" >
                            <Container className="p-0">
                                <Row>
                                    <Col>
                                        <h5 className="text-primary fw-bold m-0">{t('results.title')}</h5>
                                    </Col>
                                </Row>
                                    <ResultsTable analysis={values} user={authUser}></ResultsTable>
                            </Container>
                        </Container>
                    }

                </Container>




                <Container>
                    {(apiStatusError !== undefined) &&
                        <Container className="mt-2 bg-white text-danger">
                            <p>{t('forms.analysis.updateStatus') + ' - ' + t('forms_errors.unexpected_error') + ' (' + apiStatusError + ')'}</p>
                        </Container>
                    }
                    <Row >

                        {/* Cancel button */}
                        {(
                            ((values.status === StatusAnalysis.received_compliant || values.status === StatusAnalysis.received_improper || values.status === StatusAnalysis.registered) && hasRoleCancel) ||
                            (values.status === StatusAnalysis.registered && hasRoleUser)
                        ) &&
                            <Col xs="12" className="mt-2 " >
                                <Button variant="danger" className="btn-block" onClick={() => { setShowPopupCancel(true) }} >{t('analysis.cancel_analysis')}</Button>
                            </Col>
                        }

                        {/* Receive improper button */}
                        {(values.status === StatusAnalysis.registered || values.status === StatusAnalysis.received_fixed) && userGrantLaboratory(analysisDB.laboratory_id, authUser) &&
                            <Col xs="12" className="mt-2 " >
                                <Button hidden={true} variant="secondary" className="btn-block" onClick={handleShow} >{t('analysis.receive_improper')}</Button>

                            </Col>
                        }

                        {/* Receive proepr button */}
                        {(values.status === StatusAnalysis.registered || values.status === StatusAnalysis.received_improper || values.status === StatusAnalysis.received_fixed) && userGrantLaboratory(analysisDB.laboratory_id, authUser) &&
                            <Col xs="12" className="mt-2 " >
                                <Button hidden={true} variant="success" className="btn-block" onClick={() => { updateStatus(StatusAnalysis.received_compliant) }} >{t('analysis.receive_proper')}</Button>
                            </Col>
                        }

                        {/* Download partial results button */}
                        {values.status === StatusAnalysis.partial &&
                            <Col xs="12" className="mt-0 " >
                                <Button variant="secondary" className="btn-block" onClick={getResultsFormatting([{labId: String(values.laboratory_id), id: String(values.id)}], true, false)} >{t('analysis.download_partial_reports')}</Button>
                            </Col>
                        }

                        {/* Download results button */}
                        {values.status === StatusAnalysis.done &&
                            <Col xs="12" className="mt-0 " >
                                <Button variant="secondary" className="btn-block" onClick={getResultsFormatting([{labId: String(values.laboratory_id), id: String(values.id)}], true, false)} >{t('analysis.download_reports')}</Button>
                            </Col>
                        }

                    </Row>

                </Container>


            </Container>
        </Container>

    )

}

export default withTranslation()(ConsultAnalysisMobile)