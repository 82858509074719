import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Data } from '../types/types'
import {AnalysisInputResultStatus} from '../api/analysisApi';

export interface Language {
    id: number
    locale: string
}

export type AnalysisType = "FG" | "RM" | "FP"

interface CurrentState {
    language: Language
    groups: Array<string>
    rolesByGroup: Data<Array<string>>
    analysisStatus: Array<string>
    pdcStatus: Array<string>
    nirStatus: Array<string>
    inputStatus: Array<string>
    analysisDateByStatus: Data<string>
    typeLabels: Data<string>
    relationshipTypesLabels: Data<string>

    roleLevelLevels: Data<string>
    pdcLevelLevels: Data<string>
    currencies: Array<{
        code: string,
        name: string
    }>
    exportFileTypeLabels: Data<string>
    exportFormatLabels: Data<string>
    typeAppendix: Data<string>
    reportGenerationModes: Data<string>
    resultTypesLabels: Data<string>
    componentLabels: Data<string>
    allowedPageSizes: Array<number>
    session: {
        expiringTimeout: number
        autoRenewTimeout: number
    }
}

const initialState = {
    language: {
        locale: "fr",
        id: 1
    },
    groups: [
        "MyProvilab_InternalUsers",
        "MyProvilab_ExternalUsers"
    ],
    rolesByGroup: {
        MyProvilab_InternalUsers: [
            "MyProvilab_ROLE_technician",
            "MyProvilab_ROLE_technicianAdmin",
            "MyProvilab_ROLE_quality",
            "MyProvilab_ROLE_controlPlan",
            "MyProvilab_ROLE_salesperson",
            "MyProvilab_ROLE_readOnly",
            "MyProvilab_ROLE_readOnlyFG",
            "MyProvilab_ROLE_readOnlyRM",
            "MyProvilab_ROLE_readOnlyFP",
            "MyProvilab_ROLE_laboratory",
            "MyProvilab_ROLE_customerLabAdmin",
            "MyProvilab_ROLE_laboratoryAdmin",
            "MyProvilab_ROLE_nutrition",
            "MyProvilab_ROLE_usersAdmin",
        ],
        MyProvilab_ExternalUsers: [
            "MyProvilab_ROLE_technician",
            "MyProvilab_ROLE_technicianAdmin",
            "MyProvilab_ROLE_quality",
            "MyProvilab_ROLE_controlPlan",
            "MyProvilab_ROLE_readOnly",
            "MyProvilab_ROLE_readOnlyFG",
            "MyProvilab_ROLE_readOnlyRM",
            "MyProvilab_ROLE_readOnlyFP",
            "MyProvilab_ROLE_laboratory",
            "MyProvilab_ROLE_customerLabAdmin",
            "MyProvilab_ROLE_laboratoryAdmin",
            "MyProvilab_ROLE_nutrition",
        ],
    },
    analysisStatus: ['registered', 'received_compliant', 'received_improper', 'received_fixed', 'canceled', 'partial', 'done'],
    pdcStatus: ['created', 'published', 'canceled', 'closed'],
    nirStatus: ['Pending NIR', 'Pending Autocalc', 'Administration issue', 'Autocalc issue', 'Integration issue', 'NIR blocked', 'partial', 'released', 'Pending results', 'Pending validation', 'Pending calculation', 'Calculation issue', 'in progress', 'Publication issue', 'Autocalc integration issue', 'Ambiguity issue'],
    inputStatus: [AnalysisInputResultStatus.pending_results, AnalysisInputResultStatus.pending_validation, AnalysisInputResultStatus.released],
    analysisDateByStatus: {
        "registered": "last_update",
        "received_improper": "receipt_date",
        "received_compliant": "conformity_date",
        "received_fixed":"last_update",
        "canceled": "cancellation_date",
        "partial": "validation_date",
        "done": "validation_date"
    },
    typeLabels: {
        "FG": "FG",
        "RM": "RM",
        "FP": "FP"
    },
    relationshipTypesLabels: {
        "patterns_families": "Family Pattern",
        "packages_patterns": "Packages Patterns",
        "analytes_test_packages": "Analytes tests packages"
    },

    resultTypesLabels: {
        "AI": "AI",
        "DM": "DM",
    },
    roleLevelLevels:{
        "customer": "customer",
        "laboratory": "laboratory"
    },
    pdcLevelLevels:{
        "user": "user",
        "admin": "admin"
    },
    currencies: [
        {"name":"British Pound Sterling","code":"GBP"},
        {"name":"Canadian Dollar","code":"CAD"},
        {"name":"Euro","code":"EUR"},
        {"name":"US Dollar","code":"USD"},
        {"name":"South African Rand","code":"ZAR"},
    ],
    exportFileTypeLabels: {
        "Excel" : "xlsx",
        "Csv" : "csv"    
    },
    componentLabels: {
        "client": "client",
        "sample": "sample"
    },
    exportFormatLabels: {
        "FRB" : "FRB",
        "Libra" : "Libra",
        "Brill" : "Brill",   
        "Allix2" : "Allix2",    
        "Allix3" : "Allix3",
        "plb" : "plb",    
        "Bestmix" : "Bestmix" 

    },
    allowedPageSizes: [25, 50, 75, 100],
    session: {
        expiringTimeout: 120000,
        autoRenewTimeout: 300000
    },
    typeAppendix: {
        "homogeneity": "homogeneity",
        "default": "default"
    },
    reportGenerationModes : {
        default: 'default',
        cofrac: 'COFRAC'
    }
  } as CurrentState

const localSlice = createSlice({
    name: 'local',
    initialState,
    reducers: {
        updateLanguage(state, action: PayloadAction<Language>) {
            state.language = action.payload
        }
    }
  })

  export const {
    updateLanguage
  } = localSlice.actions

  export default localSlice.reducer