import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import ConfigApi, {RefAnalyte } from '../api/configApi';
import { buildDefaultExtraReducersWithFrontPagination, getDefaultReducersWithFrontPagination, getInitialState } from './DefaultSlice';
import MatrixApi, { ExcelExportType, MatrixExports, MatrixExportsExport, MatrixExportsFlat, MatrixExportsOutput, getMatrixExportsFlatKey } from '../api/matrixApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';

interface CurrentState {
    data: Data<MatrixExportsFlat>;
    byLabId: Data<Array<string>>
    filtered: Array<string>;
    pagination: Pagination;
    fetchStatus: "idle" | "loading" | "succeeded" | "failed";
    fetchError: string | undefined;
    singleFetchStatus: "idle" | "loading" | "succeeded" | "failed";
    singleFetchError: string | undefined;
    postStatus: "idle" | "loading" | "succeeded" | "failed";
    postError: string | undefined;
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    byLabId: {},
    fetchStatus: "idle",
    fetchError: undefined,
    singleFetchStatus: "idle",
    singleFetchError: undefined,
    postStatus: "idle",
    postError: undefined,
} as CurrentState;



// ref analytes
export const fetchMatrixExports = createAsyncThunk(
    'matrix/fetchMatrixExports', 
    async () => {/* *|MARCADOR_CURSOR|* */
        const response =  await MatrixApi.getInstance().getMatrixExports() 
        return response as Array<MatrixExports>
    }
)

export const fetchImportMatrixExports = createAsyncThunk(
    'matrix/fetchImportMatrixExports', 
    async ({type, laboratoryId, file}:{type: ExcelExportType, laboratoryId: number, file: File}) => {
       return await MatrixApi.getInstance().importMatrixExports(type, file, laboratoryId)
    }
)

export const fetchExportMatrixExports = createAsyncThunk(
    'matrix/fetchExportMatrixExports', 
    async ({type, laboratoryId} : {type: string, laboratoryId:number}) => {
       return await MatrixApi.getInstance().exportMatrixExports(type, laboratoryId)
    }
)

export const deleteMatrixExportsOutput = createAsyncThunk(
    'matrix/deleteMatrixExportsOutput',
    async ({laboratoryId, name, analyteId}: {laboratoryId: number, name: string, analyteId: string}) => {
        return await MatrixApi.getInstance().deleteMatrixExportsOutput(laboratoryId, name, analyteId)
    }
)



const matrixExportssSlice = createSlice({
    name: "matrixExports",
    initialState,
    reducers: {

        purgeReducer(state: CurrentState, action: PayloadAction<void>) {
            state.data = {}
            state.filtered = []
        },

        setMatrixExportsFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload;
        },
        setMatrixExportsFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload };
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all;
            }
            state.pagination.filterBy = filterBy;
        },
        setMatrixExportsSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload;
        },
        refreshMatrixExports(state: CurrentState) {
            state.fetchStatus = "idle";
        },
        requestPage(state: CurrentState, action: PayloadAction<number>) {
             state.pagination.currentPage = action.payload;
        },
        setNbByPage(state: CurrentState, action: PayloadAction<number>) {
            const nbByPage = action.payload;

            state.pagination.nbByPage = nbByPage;
            const count =  state.filtered.length;
            state.pagination.count = count;
            state.pagination.nbPage = Math.ceil(
                count /  state.pagination.nbByPage
            );
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchMatrixExports.pending, (state) => {

            state.fetchStatus = "loading";
        });
        builder.addCase(fetchMatrixExports.fulfilled, (state, action) => {
                state.data = {}
                state.byLabId = {}
                state.filtered = []

                const matrixExports : Array<MatrixExports> = action.payload
                matrixExports.forEach((me) => {
                    me.exports.forEach((e: MatrixExportsExport) => {
                        e.outputs.forEach((o: MatrixExportsOutput) => {
                            const matrixExportFlat: MatrixExportsFlat = {
                                laboratory_id: me.laboratory_id,
                                analyte_id: o.analyte_id,
                                cargill_only: e.cargill_only,
                                crit_company: e.crit_company,
                                export_conversion_factor: o.export_conversion_factor,
                                export_id: o.export_id,
                                export_name: o.export_name,
                                export_unit: o.export_unit,
                                format: e.format,
                                name: e.name
                            }
                            state.data[getMatrixExportsFlatKey(matrixExportFlat)] = matrixExportFlat
                            state.data[getMatrixExportsFlatKey(matrixExportFlat)].id = getMatrixExportsFlatKey(matrixExportFlat)

                            // bylabId
                            if (state.byLabId[me.laboratory_id]) {
                                state.byLabId[me.laboratory_id].push(getMatrixExportsFlatKey(matrixExportFlat))
                            } else {
                                state.byLabId[me.laboratory_id] = []
                                state.byLabId[me.laboratory_id].push(getMatrixExportsFlatKey(matrixExportFlat))
                            }
                        })
                    })
                })
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchExportMatrixExports.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
    },
});

export const {
    purgeReducer,
    setMatrixExportsFilterBy,
    setMatrixExportsFiltered,
    setMatrixExportsSortBy,
    refreshMatrixExports,
    requestPage,
    setNbByPage
} = matrixExportssSlice.actions

export default matrixExportssSlice.reducer