import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import useViewport from '../utils/useViewport';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Laboratory } from '../../api/configApi';
import { Analysis, ResultsCategory, ResultsData } from '../../api/analysisApi';
import useFilterToolkit from '../utils/useFilterToolkit';
import { RootState } from '../../state/rootReducer';
import { fetchSingleAnalysis, setResultsFiltered, singleFilterByReducer, singleSortByReducer } from '../../state/analysisSlice';
import { CurrentUser } from '../../state/authUserSlice';
import userHasRequiredRoles from '../auth/userHasRequiredRoles';

interface ResultsTableProps {
    analysis: Analysis
    user: CurrentUser
}

const ResultsTable: React.FC<ResultsTableProps> = ({analysis, user}) => {

    const { lg } = useViewport()
    const { t } = useTranslation()
    const dispatch = useDispatch();

    const authUser = useSelector((state: RootState) => state.authUser.user)

    const { filterBy, sortBy } = useSelector((state: RootState) => state.analysis.analysis.resultsFilter)
    const resultsFiltered = useSelector((state: RootState) => state.analysis.analysis.resultsFiltered)

    // Analysis state
    const analysisState = useSelector((state: RootState) => state.analysis.analysis.data)

    //  Laboratories state
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    
    //  Nir state
    const nirs = useSelector((state: RootState) => state.nir.data)

    const { FilterList, ListHeader, filterAndSortList } = useFilterToolkit(filterBy, sortBy, {
        i18nPrefix: "results.table.columns.",
        onlyActive: false,
        size: "xs",
        fields: {
            laboratory: {
                type: ["filter"],
                sortable: false
            },
            nir: {
                type: ["filter"],
                sortable: false
            },
            category: {
                type: ["filter"],
                sortable: false
            },
            test: {
                type: ["filter"],
                sortable: false
            },
            results: {
                type: [],
                sortable: false
            },
            ai_result: {
                type: [],
                sortable: false
            },
            dm_result: {
                type: [],
                sortable: false
            },
            units: {
                type: [],
                sortable: false
            },
            mdi: {
                type: [],
                sortable: false
            },
            gh: {
                type: [],
                sortable: false
            },
            method: {
                type: ["filter"],
                sortable: false
            },
            uncertainty: {
                type: [],
                sortable: false
            },
            target: {
                type: [],
                sortable: false
            }

        },
        reducers: {
            filterBy: singleFilterByReducer,
            sortBy: singleSortByReducer
        }
    })

    const ListHeaderMobile = useFilterToolkit(filterBy, sortBy, {
        i18nPrefix: "results.table.columns.",
        onlyActive: false,
        size: "xs",
        fields: {
            category: {
                type: [],
                sortable: false
            },
            test: {
                type: [],
                sortable: false
            },
            results: {
                type: [],
                sortable: false
            }
        },
        reducers: {
            filterBy: singleFilterByReducer,
            sortBy: singleSortByReducer
        }
    }).ListHeader

    type ResultsList = Omit<ResultsCategory, "results_data" | "category_name"> & ResultsData & { category: string, results: string }

    const getDisplayColumn = (column: 'uncertainty'|'laboratory'|'nir'|'result'|'mdi'|'gh'|'color'|'category'|'target') => {
        return Object.values(laboratories).find((lab: Laboratory) => (userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']) || authUser.laboratories.includes(lab.id)) && lab.raw_analysis_columns && lab.results_columns[column])
    }

    //filtering
    useEffect(() => {
        const resultsData: any = []

        analysis.nutrient_result?.results_categories.forEach((rc) => {
            rc.results_data.forEach((rd: ResultsData) => {
                let category_name = ''
                if (rc.category_name) {
                    category_name = rc.category_name.translations[user.language_id] ? rc.category_name.translations[user.language_id] : rc.category_name.name
                }
                const resultsDataCopy: ResultsList = {
                    ...rd,
                    category_code: rc.category_code,
                    category: category_name,
                    results: rd.result_type === "AI" ? rd.ai_result : rd.dm_result
                }
                resultsData.push(resultsDataCopy)
            })
        })

        let filteredList = filterAndSortList(resultsData.map((item: any) => ({
            ...item,
            test: 
                item.test_name ?  
                    item.test_name.translations[user.language_id] ? item.test_name.translations[user.language_id] : item.test_name.name
                    :
                    item.analyte,
            id: `${item.category_code}#${item.test}#${item.analyte}`,
            laboratory: laboratories[analysis.laboratory_id].name,
            nir: nirs[`${analysis.laboratory_id}#${analysis.nir_code}`] ? nirs[`${analysis.laboratory_id}#${analysis.nir_code}`].name : ''
        })));

        if(analysis.additional_analysis && analysis.additional_analysis[0] && analysis.additional_analysis[0].ref_laboratory_id && analysis.additional_analysis[0].ref_analysis_id && analysisState[analysis.additional_analysis[0].ref_laboratory_id] && analysisState[analysis.additional_analysis[0].ref_laboratory_id][analysis.additional_analysis[0].ref_analysis_id]){
            const resultsDataAdditionalAnalysis: any = []
            const additionalAnalysis = analysisState[analysis.additional_analysis[0].ref_laboratory_id][analysis.additional_analysis[0].ref_analysis_id]
            
            additionalAnalysis.nutrient_result?.results_categories.forEach((rc) => {
                rc.results_data.forEach((rd: ResultsData) => {
                    let category_name = ''
                    if (rc.category_name) {
                        category_name = rc.category_name.translations[user.language_id] ? rc.category_name.translations[user.language_id] : rc.category_name.name
                    }
                    const resultsDataCopy: ResultsList = {
                        ...rd,
                        category_code: rc.category_code,
                        category: category_name,
                        results: rd.result_type === "AI" ? rd.ai_result : rd.dm_result
                    }
                    resultsDataAdditionalAnalysis.push(resultsDataCopy)
                })
            })
    
            filteredList = filteredList.concat(filterAndSortList(resultsDataAdditionalAnalysis.map((item: any) => ({
                ...item,
                test: item.test_name.translations[user.language_id] ? item.test_name.translations[user.language_id] : item.test_name.name,
                id: `${item.category_code}#${item.test}#${item.analyte}`,
                laboratory: laboratories[additionalAnalysis.laboratory_id].name,
                nir: nirs[`${additionalAnalysis.laboratory_id}#${additionalAnalysis.nir_code}`] ? nirs[`${additionalAnalysis.laboratory_id}#${additionalAnalysis.nir_code}`].name : ''
            }))));
        }

        dispatch(setResultsFiltered(filteredList));
    }, [filterBy, sortBy, analysis]);

    function returnStarForResultType(resultType: string, resultAi: string, resultDm: string, decimals: number){
        if(analysis.nutrient_result?.dm_flag){
            if(resultType === 'AI'){
                return Number(resultAi) ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(Number(resultAi)) : resultAi
            }else{
                return `${Number(resultDm) ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(Number(resultDm)) : resultDm} *`
            }
        }else{
            if(resultType === 'AI'){
                return `${Number(resultAi) ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(Number(resultAi)) : resultAi} *`
            }else{
                return Number(resultDm) ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(Number(resultDm)) : resultDm
            }
        }
    }

    function computeUncertainty(type: string, ai: string, dm: string, uncertainty: number, decimals: number) {
        if(type==='AI'){
            if(Number(ai)){
                return uncertainty ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(Number((Number(ai)*uncertainty))) : null
            }else{
                return null
            }
        }else{
            if(Number(dm)){
                return uncertainty ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: decimals, maximumFractionDigits: decimals}).format(Number((Number(dm)*uncertainty))) : null
            }else{
                return null
            }
        }
    }

    return (
        <>
            {/* Display for mobile and tablet */}
            <Container className="d-lg-none mb-2 p-0">
                <Table striped hover className="d-lg-none text-center mt-1 table-fixed table-xs">
                    <thead className="bg-white">
                        <tr>
                            <th className="p-1">
                                <ListHeaderMobile name="category" />
                            </th>
                            <th className="p-1">
                                <ListHeaderMobile name="test" />
                            </th>
                            <th className="p-1">
                                <ListHeaderMobile name="results" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {analysis.nutrient_result?.results_categories.map((category, categIndex) => {
                            return category.results_data.filter(data => data.visible === true).map((data, index) => {
                                if(resultsFiltered.find((item => item.split('#')[0] === category.category_code && item.split('#')[1] === data.test && item.split('#')[2] === data.analyte))){
                                    return (
                                        <tr key={category.order+"-"+data.order+"-"+index+"-"+categIndex} className="align-middle" style={{backgroundColor: `var(--mp-${data.color})`}}>
                                            <td>{category.category_name ? category.category_name.translations && category.category_name.translations[user.locale] ? category.category_name.translations[user.locale] : category.category_name.name : ""}</td>
                                            <td>{data.test_name ? data.test_name.translations[user.locale] ? data.test_name.translations[user.locale] : data.test_name.name : ''}</td>
                                            <td>{returnStarForResultType(data.result_type, data.ai_result, data.dm_result, data.decimals)}</td>
                                        </tr>
                                    )
                                }
                            })
                        }) ?? null}
                        {analysis.additional_analysis && analysis.additional_analysis[0] && analysis.additional_analysis[0].ref_laboratory_id && analysis.additional_analysis[0].ref_analysis_id && analysisState[analysis.additional_analysis[0].ref_laboratory_id] && analysisState[analysis.additional_analysis[0].ref_laboratory_id][analysis.additional_analysis[0].ref_analysis_id] && analysisState[analysis.additional_analysis[0].ref_laboratory_id][analysis.additional_analysis[0].ref_analysis_id].nutrient_result?.results_categories.map((category, categIndex) => {
                            return category.results_data.filter(data => data.visible === true).map((data, index) => {
                                if(resultsFiltered.find((item => item.split('#')[0] === category.category_code && item.split('#')[1] === data.test && item.split('#')[2] === data.analyte))){
                                    return (
                                        <tr key={category.order+"-"+data.order+"-"+index+"-"+categIndex} className="align-middle" style={{backgroundColor: `var(--mp-${data.color})`}}>
                                            <td>{category.category_name ? category.category_name.translations && category.category_name.translations[user.locale] ? category.category_name.translations[user.locale] : category.category_name.name : ""}</td>
                                            <td>{data.test_name ? data.test_name.translations[user.locale] ? data.test_name.translations[user.locale] : data.test_name.name : ''}</td>
                                            <td>{returnStarForResultType(data.result_type, data.ai_result, data.dm_result, data.decimals)}</td>
                                        </tr>
                                    )
                                }
                            })
                        })}
                    </tbody>
                </Table>
                {analysis.nutrient_result?.dm_flag &&
                    <p className="fst-italic text-end">{t('results.dry')}</p>
                }
                {!analysis.nutrient_result?.dm_flag &&
                    <p className="fst-italic text-end">{t('results.raw')}</p>
                }
                <p className="fst-italic text-end">{t('results.report')}</p>
            </Container>

            {/* Display for desktop */}
            <Container className="d-none d-lg-table bg-white mb-2 rounded-3 ps-0 pe-0 pt-2 pb-2">
                <Row className="ps-2">
                    <Col md={"auto"}>
                        <h5 className="text-primary fw-bold p-0 m-0"> {t('results.title')}</h5>
                    </Col>
                    <Col>
                        <FilterList />
                    </Col>
                </Row>

                <Row className="w-100 ms-0 row-for-table-without-padding">
                    <Table responsive="lg" className="d-none d-lg-table text-center table-fixed table-xs">
                        <thead className='bg-white'>
                            <tr>
                                <th className="p-0" />
                                {getDisplayColumn('laboratory') &&
                                    <th className="p-1">
                                        <ListHeader name="laboratory" />
                                    </th>
                                }
                                {getDisplayColumn('nir') &&
                                    <th className="p-1">
                                        <ListHeader name="nir" />
                                    </th>
                                }
                                {getDisplayColumn('category') &&
                                    <th className="p-1">
                                        <ListHeader name="category" />
                                    </th>
                                }
                                <th className="p-1">
                                    <ListHeader name="test" />
                                </th>
                                {!getDisplayColumn('result') &&
                                    <th className="p-1">
                                        <ListHeader name="results" />
                                    </th>
                                }
                                {getDisplayColumn('result') &&
                                    <>
                                        <th className="p-1">
                                            <ListHeader name="ai_result" />
                                        </th>
                                        <th className="p-1">
                                            <ListHeader name="dm_result" />
                                        </th>
                                    </>
                                }
                                <th className="p-1">
                                    <ListHeader name="units" />
                                </th>
                                {getDisplayColumn('mdi') &&
                                    <th className="p-1">
                                        <ListHeader name="mdi" />
                                    </th>
                                }
                                {getDisplayColumn('gh') &&
                                    <th className="p-1">
                                        <ListHeader name="gh" />
                                    </th>
                                }
                                {getDisplayColumn('uncertainty') &&
                                    <th className="p-1">
                                        <ListHeader name="uncertainty" />
                                    </th>
                                }
                                {getDisplayColumn('target') &&
                                    <th className="p-1">
                                        <ListHeader name="target" />
                                    </th>
                                }
                                <th className="p-1">
                                    <ListHeader name="method" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {analysis.nutrient_result?.results_categories.map((category, categIndex) => {
                                return category.results_data.filter(data => data.visible === true).map((data, index) => {
                                    if(resultsFiltered.find((item => item.split('#')[0] === category.category_code && item.split('#')[1] === data.test && item.split('#')[2] === data.analyte))){
                                        return (
                                            <tr key={category.order+"-"+data.order+"-"+index+"-"+categIndex} className="align-middle" style={getDisplayColumn('color') && data.color ? {backgroundColor: `var(--mp-${data.color})`} : {}}>
                                                <td className="table-hover-hint" />
                                                {getDisplayColumn('laboratory') &&
                                                    <td >{laboratories[analysis.laboratory_id].name}</td>
                                                }
                                                {getDisplayColumn('nir') &&
                                                    <td >{nirs && nirs[`${analysis.laboratory_id}#${data.nir_code}`] ? nirs[`${analysis.laboratory_id}#${data.nir_code}`].name : ''}</td>
                                                }
                                                {getDisplayColumn('category') &&
                                                    <td >{category.category_name ? category.category_name.translations && category.category_name.translations[user.locale] ? category.category_name.translations[user.locale] : category.category_name.name : ""}</td>
                                                }
                                                <td>{data.test_name ? data.test_name.translations[user.locale] ? data.test_name.translations[user.locale] : data.test_name.name : ''}</td>
                                                {!getDisplayColumn('result') &&
                                                    <td>{returnStarForResultType(data.result_type, data.ai_result, data.dm_result, data.decimals)}</td>
                                                }
                                                {getDisplayColumn('result') &&
                                                    <>
                                                        <td>{Number(data.ai_result) ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: data.decimals, maximumFractionDigits: data.decimals}).format(Number(data.ai_result)) : data.ai_result}</td>
                                                        <td>{Number(data.dm_result) ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: data.decimals, maximumFractionDigits: data.decimals}).format(Number(data.dm_result)) : data.dm_result}</td>
                                                    </>
                                                }
                                                <td>{data.units}</td>
                                                {getDisplayColumn('mdi') &&
                                                    <td>{Intl.NumberFormat(authUser.locale).format(data.mdi)}</td>
                                                }
                                                {getDisplayColumn('gh') &&
                                                    <td>{Intl.NumberFormat(authUser.locale).format(data.gh)}</td>
                                                }
                                                {getDisplayColumn('uncertainty') &&
                                                    <td>{computeUncertainty(data.result_type, data.ai_result, data.dm_result, data.uncertainty, data.decimals)}</td>
                                                }
                                                {getDisplayColumn('target') &&
                                                    <td>{data.target ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: data.decimals, maximumFractionDigits: data.decimals}).format(Number(data.target.toFixed(data.decimals))) : data.target}</td>
                                                }
                                                <td>{data.method}</td>
                                            </tr>
                                        )
                                    }
                                })
                            }) ?? null}
                            {analysis.additional_analysis && analysis.additional_analysis[0] && analysis.additional_analysis[0].ref_laboratory_id && analysis.additional_analysis[0].ref_analysis_id && analysisState[analysis.additional_analysis[0].ref_laboratory_id] && analysisState[analysis.additional_analysis[0].ref_laboratory_id][analysis.additional_analysis[0].ref_analysis_id] && analysisState[analysis.additional_analysis[0].ref_laboratory_id][analysis.additional_analysis[0].ref_analysis_id].nutrient_result?.results_categories.map((category, categIndex) => {
                                return category.results_data.filter(data => data.visible === true).map((data, index) => {
                                    if(resultsFiltered.find((item => item.split('#')[0] === category.category_code && item.split('#')[1] === data.test && item.split('#')[2] === data.analyte))){
                                        return (
                                            <tr key={category.order+"-"+data.order+"-"+index+"-"+categIndex} className="align-middle" style={getDisplayColumn('color') && data.color ? {backgroundColor: `var(--mp-${data.color})`} : {}}>
                                                <td className="table-hover-hint" />
                                                {getDisplayColumn('laboratory') &&
                                                    <td >{laboratories[analysis.additional_analysis![0].ref_laboratory_id].name}</td>
                                                }
                                                {getDisplayColumn('nir') &&
                                                    <td >{nirs && nirs[`${analysis.additional_analysis![0].ref_laboratory_id}#${analysis.nir_code}`] ? nirs[`${analysis.laboratory_id}#${analysis.nir_code}`].name : ''}</td>
                                                }
                                                {getDisplayColumn('category') &&
                                                    <td >{category.category_name ? category.category_name.translations && category.category_name.translations[user.locale] ? category.category_name.translations[user.locale] : category.category_name.name : ""}</td>
                                                }
                                                <td>{data.test_name ? data.test_name.translations[user.locale] ? data.test_name.translations[user.locale] : data.test_name.name : ''}</td>
                                                {!getDisplayColumn('result') &&
                                                    <td>{returnStarForResultType(data.result_type, data.ai_result, data.dm_result, data.decimals)}</td>
                                                }
                                                {getDisplayColumn('result') &&
                                                    <>
                                                        <td>{Number(data.ai_result) ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: data.decimals, maximumFractionDigits: data.decimals}).format(Number(data.ai_result)) : data.ai_result}</td>
                                                        <td>{Number(data.dm_result) ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: data.decimals, maximumFractionDigits: data.decimals}).format(Number(data.dm_result)) : data.dm_result}</td>
                                                    </>
                                                }
                                                <td>{data.units}</td>
                                                {getDisplayColumn('mdi') &&
                                                    <td>{Intl.NumberFormat(authUser.locale).format(data.mdi)}</td>
                                                }
                                                {getDisplayColumn('gh') &&
                                                    <td>{Intl.NumberFormat(authUser.locale).format(data.gh)}</td>
                                                }
                                                {getDisplayColumn('uncertainty') &&
                                                    <td>{computeUncertainty(data.result_type, data.ai_result, data.dm_result, data.uncertainty, data.decimals)}</td>
                                                }
                                                {getDisplayColumn('target') &&
                                                    <td>{data.target ? Intl.NumberFormat(authUser.locale, {minimumFractionDigits: data.decimals, maximumFractionDigits: data.decimals}).format(Number(data.target.toFixed(data.decimals))) : data.target}</td>
                                                }
                                                <td>{data.method}</td>
                                            </tr>
                                        )
                                    }
                                })
                            })}
                        </tbody>
                    </Table>
                    {analysis.nutrient_result?.dm_flag && !getDisplayColumn('result') &&
                        <p className="fst-italic text-end">{t('results.dry')}</p>
                    }
                    {!analysis.nutrient_result?.dm_flag && !getDisplayColumn('result') &&
                        <p className="fst-italic text-end">{t('results.raw')}</p>
                    }
                    <p className="fst-italic text-end">{t('results.report')}</p>
                </Row>
            </Container>
        </>
    )
}

export default ResultsTable
