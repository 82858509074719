import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ConfigApi, { RuleResultsPatternTests, getKeyForTabAssocRuleResultsPatternTests } from '../api/configApi';
import { buildDefaultExtraReducersWithFrontPagination, CurrentState, getDefaultReducersWithFrontPagination, getInitialState } from './DefaultSlice';

const initialState = getInitialState<RuleResultsPatternTests>()


export const fetchRuleResultsPatternsTests = createAsyncThunk(
    'config/fetchRuleResultsPatternsTests', 
    async () => {
        return await ConfigApi.getInstance().getRulesResultsPatternTests() 
    }
)
export const fetchRuleResultPatternTest = createAsyncThunk(
    'config/fetchRuleResultPatternTest',
    async ({ laboratoryId }: { laboratoryId: number }) => {
        const response = await ConfigApi.getInstance().getRuleResultPatternTest(laboratoryId)
        return response as RuleResultsPatternTests
    }
)

export const fetchExportRuleResultsPatternTests = createAsyncThunk(
    'config/fetchExportRruleResultsPatternTests', 
    async (lab_id:number) => {
        const response =  await ConfigApi.getInstance().exportRuleResultPatternTest(lab_id)
        return response 
    }
)

const addItemToRuleResulstPatternTests =   (state:CurrentState<RuleResultsPatternTests>,item:RuleResultsPatternTests) => {

    if (item.laboratory_id && item.code_test && item.code_analyte && item.order) {
        state.data[getKeyForTabAssocRuleResultsPatternTests(item)] = item
    }
}

const postDeleteRuleResultsPatternTests = (state:CurrentState<RuleResultsPatternTests>,item:RuleResultsPatternTests)=> {
    delete state.data[getKeyForTabAssocRuleResultsPatternTests(item)]
    state.filtered.splice(state.filtered.indexOf(getKeyForTabAssocRuleResultsPatternTests(item)),1)
}

const ruleResultsPatternTestsSlice = createSlice({
    name: 'ruleResultsPatternTests',
    initialState,
    reducers: getDefaultReducersWithFrontPagination(addItemToRuleResulstPatternTests,postDeleteRuleResultsPatternTests)
    ,
    extraReducers: builder => {
        buildDefaultExtraReducersWithFrontPagination(
            addItemToRuleResulstPatternTests,
            builder,
            fetchRuleResultsPatternsTests
        )
    }
})
export const {
    setFiltered,
    setFilterBy,
    setSortBy,
    requestPage,
    setNbByPage,
    tableReducer


} = ruleResultsPatternTestsSlice.actions

export default ruleResultsPatternTestsSlice.reducer