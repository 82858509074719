import React, { useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import { Modal, Container, Form, Button, Row, Col } from 'react-bootstrap'

import { Formik, FormikProps, Field } from 'formik'

import Loader from './Loader'
import { t } from 'i18next'
import { TupleId } from '../../types/types'
import getResults from '../utils/getResults';
import { RootState } from '../../state/rootReducer'
import { useSelector } from 'react-redux'

type DownloadFormat = 'reports'|'lynx'|'all'

interface ModalDownloadProps extends WithTranslation {
    selectedAnalysisIds: TupleId[]
    setShowModal: (show: boolean) => void
    showModal: boolean
}

interface IFormValues {
    downloadFormat: DownloadFormat
}

const ModalDownload: React.FC<ModalDownloadProps> = ({ selectedAnalysisIds, setShowModal, showModal }: ModalDownloadProps) => {
    const [formSubmitted, setFormSubmitted] = useState(false)

    const analysis = useSelector((state: RootState) => state.analysis.analysis.data)
  
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    
    const [showError, setShowError] = useState(false);
    
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const [msgError, setMsgError] = useState("");

    const getResultsFormatting = async (pdf: boolean, plb: boolean) => {
        try {
            const analysisWithResults = selectedAnalysisIds.filter(tuple => analysis[tuple.labId][tuple.id].status === "partial" || analysis[tuple.labId][tuple.id].status === "done")
            if (analysisWithResults.length > 0) {
                await getResults(analysisWithResults, pdf, plb)
            } else {
                setMsgError(t('analysis.download_error_checked_msg'))
                handleShowError()
            }
        } catch(err) {
            setMsgError(t("forms_errors.unexpected_error"))
            handleShowError()
        }
        handleClose()
    }

    return (
        <Container>
            <Modal size="lg" show={showError}  onHide={handleCloseError}>
                <Modal.Header>
                    <Modal.Title><span className="text-primary fw-bold"> {t('error')} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{msgError}</p>
                </Modal.Body>
            </Modal>
            <Modal size="lg" animation={true} show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('analysis_list.choose_files_to_download')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            downloadFormat: 'all'
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            // When button submits form and form is in the process of submitting, submit button is disabled
                            setSubmitting(true)
                            try {
                                switch (values.downloadFormat) {
                                    case 'all':
                                        getResultsFormatting(true,true);
                                        break;
                                    case 'reports':
                                        getResultsFormatting(true,false);
                                        break;
                                    case 'lynx':
                                        getResultsFormatting(false,true);
                                        break;
                                    default:
                                        break;
                                }
                                setShowModal(false)
                            } catch (err) {
                                console.error('Unexpected error while downloading results', err)
                            } finally {
                                setFormSubmitted(false)
                            }
                        }}
                    >
                        {/* Callback function containing Formik state and helpers that handle common form actions */}
                        {(props: FormikProps<IFormValues>) => {
                            const { values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue } = props

                            return (
                                <Form className="" onSubmit={handleSubmit}>
                                    {isSubmitting && <Loader />}
                                    <Form.Group controlId="formFormat">
                                        <div className="mb-2">{t('analysis_list.sub_title')}</div>

                                        <Row className ="m-0 p-0">
                                        <Col onClick={
                                                () => setFieldValue('downloadFormat',"all")
                                            } className=" w-25 border rounded p-3  m-1 ms-0">
                                                <Field label="" name="downloadFormat" type="radio" value="all" />

                                                <Row></Row>
                                                <strong>{t('analysis_list.all')}</strong>

                                                <div className="mt-1">{t('analysis_list.all_text')}</div>
                                            </Col>

                                            <Col onClick={
                                                () => setFieldValue('downloadFormat',"reports")
                                            } className=" w-25 border rounded p-3 m-1">
                                                <Field label="" name="downloadFormat" type="radio" value="reports" />

                                                <Row></Row>

                                                <strong>{t('analysis_list.reports')}</strong>

                                                <div className="mt-1">{t('analysis_list.reports_text')}</div>
                                            </Col>

                                            <Col onClick={
                                                () => setFieldValue('downloadFormat',"lynx")
                                            } className="w-25 border rounded p-3 m-1 me-0">
                                                <Field label="" name="downloadFormat" type="radio" value="lynx" />

                                                <Row></Row>
                                                <strong>{t('analysis_list.lynx_files')}</strong>

                                                <div className="mt-1">{t('analysis_list.lynx_files_text')}</div>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    {/* To compensate marginLeft of radio buttons*/}
                                    <Row className='m-0 p-0'>
                                            <Col className="text-end p-0 pt-1">
                                            <Button disabled={isSubmitting} variant="secondary" type="submit">
                                                {t('buttons.validate')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default withTranslation()(ModalDownload)
