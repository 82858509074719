import axios from 'axios'
import axiosRetry from 'axios-retry'
import env from '../common/utils/env'
import AbstractApi from './AbstractApi'
import moment from 'moment'
import { FilterBy, SortBy } from '../types/types'
import qs from 'qs'
import * as Downloader from "../common/utils/download";
import SubErrorDetails from '../common/utils/exceptions/SubErrorDetails'


export type ExcelExportType = 'companies' | 'exports'
export type ExportType = 'FRB' | 'Libra'|'Brill'|'Allix2'|'Allix3'|'plb'|'Bestmix'
export type ImportStatus = 'OK' | 'NOK'
export type JobStatus = 'created'|'queued'|'running'|'done'|'failed'|'cancelled'|'cancelling'
export type Types = 'export' | 'import'
export type BackupMode = 'creation' | 'edition' | 'update';

// ADD LANG: TO CHANGE
export interface MatrixTemplate {

    laboratory_id: number
    id: number
    category: {
        name: string
        translations: {
            en: string
            fr: string
            es: string
            it: string
            pl: string
            tur: string
            pt: string
            nl: string
            de: string
        }
    }
    sub_category: {
        name: string
        translations: {
            en: string
            fr: string
            es: string
            it: string
            pl: string
            tur: string
            pt: string
            nl: string
            de: string
        }
    }
    raw_material: {
        name: string
        translations: {
            en: string
            fr: string
            es: string
            it: string
            pl: string
            tur: string
            pt: string
            nl: string
            de: string
        }
    }
    jcode: string
    crit_company: string
    memo_url: string
    active: boolean
}
export interface MatrixTemplateInputsFilter {
    matrix_id?: number
    analyte_id?: string
    order?: number
    analyte_name?: string
    is_toggle?: boolean
    unit?: string
    group?: string
    max_group?: number
    active?: boolean
}

// ADD LANG: TO CHANGE
export interface MatrixTemplateInput {
    id: string
    matrix_id: number
    analyte_id: string
    order: number
    analyte_name: {
        name: string
        translations: {
            en: string
            fr: string
            es: string
            it: string
            pl: string
            tur: string
            pt: string
            nl: string
            de: string
        }
    }
    is_toggle: boolean
    unit: string
    group: {
        name: string
        translations: {
            en: string
            fr: string
            es: string
            it: string
            pl: string
            tur: string
            pt: string
            nl: string
            de: string
        }
    }
    max_group: number
    min_value: number
    max_value: number
    default_value: number
    active: boolean
    category?: string
    sub_category?: string
    raw_material?: string

}
export interface MatrixTemplatesFilter {
    id?: number
    category?: string
    sub_category?: string
    raw_material?: string
    jcode?: string
    crit_company?: string
    active?: boolean
}

export interface MatrixExportsOutput {
    analyte_id: string
    export_name: string
    export_id: string
    export_unit: string
    export_conversion_factor: number
}

export interface MatrixExportsExport {
    name: string
    crit_company: string
    format: ExportType
    cargill_only: boolean
    outputs: MatrixExportsOutput []        
}
export interface MatrixExports
{
    laboratory_id: number
    exports: MatrixExportsExport []
}

export interface MatrixExportsFlat { // for display
    id? : string // key for front
    laboratory_id: number
    name: string
    crit_company: string
    format: ExportType
    cargill_only: boolean
    analyte_id: string
    export_name: string
    export_id: string
    export_unit: string
    export_conversion_factor: number
}

// interfaces representing errors of backupmatrix
export interface InvalidInputs {
    analyte_id: string
    ai_result: string
    analyte_status: 'OK' | 'NOK' | undefined,
    min: number | undefined
    max: number | undefined
                  
}

export interface MaxGroupResult {
    analyte_status : 'OK' | 'NOK'
    min: number | undefined
    max: number | undefined 
    group_status: 'OK' | 'NOK'
    max_group: number | undefined
} 


export interface ResultsDetails {
    export_code: string
    import_status: ImportStatus
    comment: string
    errors: ImportMatrixError | BadRequestError // Other errors cannot occur because we force NOK inputs
    params: object


}

export interface ImportMatrixError{
    message: string,
    exportCode: string
}

export interface BadRequestError {
    message: string,
    errors: SubErrorDetails[]
}

export interface MatrixRequestImport {
    type: Types
    file: File
}

export interface MatrixRequestExport extends MatrixRequestImport {
    formulation_id: number
    filter: Filter
    output: Output
}

export interface MatrixResponse {
    id: string // job's uuid to poll on
    url: string
    status: JobStatus
    created_at: Date
    config: MatrixRequestExport | MatrixRequestImport
}


export interface StatusResponse extends MatrixResponse {
    progress: number, 
    results_details: ResultsDetails[]
}

export interface JobDb {
    id:string 
    user_id:number
    created_at:Date
    status:JobStatus
    progress:number
    url:string 
    config:MatrixRequestExport | MatrixRequestImport // no config if import (only one file)
    results_details: ResultsDetails[]
}



export function getMatrixExportsFlatKey(matrixExportFlat: MatrixExportsFlat){
    return `${matrixExportFlat.laboratory_id}#${matrixExportFlat.name}#${matrixExportFlat.analyte_id}`
}

export function getMatrixExportsFlatWithoutOutputsKey(matrixExportFlat: MatrixExportsFlat){
    return `${matrixExportFlat.laboratory_id}#${matrixExportFlat.name}`
}


export interface ValorizationRequest {
    company_id: number
    export_codes: Array<string>
  }
  
  export interface MatriceValorization  {
    export_code: string
    version: number
    matrix_status?: StatusMatrix | 'Inexistant'
    valo_status: 'OK' | 'Error'
    message: string
    message_id: ApiMessagesIds
    new_version_created: boolean
  }

  type ApiMessagesIds =
    | "template_deactivated"
    | "template_not_available"
    | "new_version_in_progress_calculation_impossible"
    | "new_version_in_progress_created_calculation_impossible"
    | "waiting"
    | "matrix_activated"
    | "new_version_created_waiting"
    | "new_version_created_active"
    | "matrix_not_found"
    | "matrix_pending_autocalc" 
    
  export interface ValorizationResponse {
    company_id: number
    matrices: MatriceValorization[]
  }
  

export interface MatrixTemplatesSort {
    field: string;
    direction: "ASC" | "DESC";
}
export interface Language {
    id: number
    name: string
    code_language: string
    date_format: string
    currency: string
    translations: Translations<DefaultTranslation>
}

interface DefaultTranslation {
    language_id: number
    name_translation: string
}

export interface ObjectWithNameAndTranslations {
    name: string
    translations: Translations<DefaultTranslation>
}

interface Translations<T> {
    [key: number]: T
}

export interface TranslationForArray {
    //translations used in formulars
    name_translation: string
    language_id: number
}

export interface TranslationsJson {
    // translations used in json fields in database
    name: string
    translations: TranslationsMap
}
export interface TranslationsMap {
    [key: string]: string
}

export function convertObjectWithNameAndTranslationsToTranslationsJSon(
    object: ObjectWithNameAndTranslations,
    languages: { [key: string]: Language }
): TranslationsJson {
    const translationsMapObject: TranslationsMap = {}

    Object.keys(object.translations).forEach((key) => {
        translationsMapObject[languages[key].code_language] = object.translations[Number(key)].name_translation
    })

    const objectTranslationsJSon: TranslationsJson = {
        name: object.name,
        translations: translationsMapObject,
    }
    return objectTranslationsJSon
}

export interface MatrixKey {
    company_id: number
    export_code: string
    version: number
}

export enum StatusMatrix {
    in_progress = "In progress",
    ready = "Ready",
    autocalc_issue = "Autocalc issue",
    autocalc_integration_issue = 'Autocalc integration issue',
    administration_issue = 'Administration issue',
    pending_autocalc = "Pending Autocalc",
    active = "Active",
    archived = "Archived"
}

export enum ResultSource {
    autocalc = "Autocalc",
    input = "Input"
}

export interface Matrix extends MatrixKey {
    id: string; // front id
    company_id: number
    export_code: string
    version: number
    category?: string
    sub_category?: string
    raw_material?: string
    template_id: number
    customer_name: string
    origin_id: number
    supplier_id: number
    matrix_status: StatusMatrix
    jcode: string
    engine_version: string
    comment: string
    creation_mode: 'form' | 'import' | 'dashboard' | 'auto'
    created_by: number
    creation_date: Date
    last_update_by: number
    last_update: Date
    ready_by: number
    ready_date: Date
    active_by: number
    active_date: Date
    last_export_by: number
    last_export: Date
    archived_by: number
    archived_date: Date
    results: {
        analyte_id: string
        ai_result: string
        unit: string
        source: ResultSource
        analyte_status: string
    }[]
}

//Create new matrix interface that will be used in the form to create a new matrix with juste the necessary fields :
export interface BodyMatrixCreate {
    company_id: number
    export_code: string
    version: number
    matrix_status?: string
    template_id: number
    customer_name: string
    jcode: string
    origin_id: number
    supplier_id: number
    comment: string
    creation_mode: 'form'|'import'|'dashboard'|'auto'
    results: {
        analyte_id: string
        ai_result: string
        unit: string
        source: 'Input'|'Autocalc'
        group_name: string
    }[]
}

export interface ResponseMatrixCreate {   
    results: {
        analyte_id: string;
        ai_result: string;
        analyte_status: 'OK' | 'NOK';
        min: number;
        max: number;
        group_status: 'OK' | 'NOK';
        max_group: number;
    }[];
}

// ADD LANG: TO CHANGE
interface Analyte {
    active: boolean;
    analyte_id: string;
    analyte_name: {
        name: string;
        translations: {
            en: string;
            es: string;
            fr: string;
            it: string;
            pl: string;
            tur: string;
            pt: string;
            nl: string;
            de: string;
        };
    };
    default_value: number;
    group: {
        name: string;
        translations: {
            en: string;
            es: string;
            fr: string;
            it: string;
            pl: string;
            tur: string;
            pt: string;
            nl: string;
            de: string;
            };
        };
    is_toggle: boolean;
    matrix_id: number;
    max_group: number;
    max_value: number;
    min_value: number;
    order: number;
    unit: string;
    id: string;
}
  
export interface MatrixTemplateInputDb {
    [key: string]: Analyte[];
}  

interface MatrixTemplateInputForm {
    name: string;    
    id: string;
    is_toggle: boolean;
    default_value: number; 
    unit: string;
}

export interface InputFieldItem {
    id: number;
    name: string;
    input: string;
    unit: string;
    active: boolean
}
export interface InputFieldsTable {
    [key: string]: InputFieldItem[]; 
}
  
export interface MatrixTemplateInputsFormated {
    [key: string]: MatrixTemplateInputForm[];
}  

export interface BodyDeleteMatrix {
    export_code: string
    version: number
}

export interface BodyDeleteMatrices {
    company_id: number
    matrices: Array<BodyDeleteMatrix>
}

export interface AutocalcCallState {
    id: string
    company_id: number
    export_code: string
    version: number
    call_date_time: Date
    param: string
    status: string
    error_code: string
    error_message: string
    details: Details
}  
export interface Details {
    trace_id: string
    external_code: string
    external_message: string
}  

export interface Filter {
    company_id: number
    matrices: {
        export_code: string
        version: number
    }[] 
}

export interface Output {
    export_name: string
    excel_format: 'inputs' | 'outputs' | 'inputs-outputs'
}

export interface JobRequest {
    formulation_id: number
    type: 'export' | 'import'
    filter: Filter
    output: Output
}

const standaloneInstance = axios.create({
    baseURL: env.MATRIX_API_URL,
    timeout: 60000
})

axiosRetry(standaloneInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay
})

export function getKeyForMatrix(matrix: MatrixKey): string {
    return matrix ?matrix.company_id + '#' + matrix.export_code + '#' + matrix.version : ''
}

class MatrixApi extends AbstractApi {

    private static instance: MatrixApi

    public static getInstance(): MatrixApi {
        if (!MatrixApi.instance) {
            throw new Error('Initialize instance before.')
        }
        return MatrixApi.instance
    }

    public static initInstance(token: string): void {
        MatrixApi.instance = new MatrixApi(env.MATRIX_API_URL, token)
    }

    public async getMatrixTemplateById(id: number): Promise<MatrixTemplate | null> {

        try {

            const response = await this.service.get(`/admin/matrix/templates/${id}`)

            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getMatrixTemplatesByLaboratory(labId: number, filter: FilterBy, sortBy: Array<SortBy>, limit: number, offset: number): Promise<{count: number, data: Array<MatrixTemplate>}> {

        try {
            const config = {
                params: Object.assign(
                    {
                        limit,
                        offset
                    },
                    Object.keys(filter).length > 0 ? { filter: qs.stringify(filter) } : null,
                    Object.keys(sortBy).length > 0 ? { sortby: sortBy.map(item => item.direction + item.name) } : null
                )
            }

            const response = await this.service.get(`/admin/matrix/${labId}/templates`, config)

            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getMatrixTemplateInputsByLaboratory(labId: number, filter?: FilterBy, sortBy?: Array<SortBy>, limit?: number, offset?: number): Promise<{count: number, data: Array<MatrixTemplateInput>}> {       
            try {
                const filters: FilterBy = { ...filter }
                Object.keys(filters).forEach(name => {
                    if (filters[name].in) {
                        filters[name] = { in: filters[name].in }
                    }
                    if (filters[name].bool) {
                        filters[name] = { bool: filters[name].bool }
                    }
                })
                const config = {
                    params: Object.assign(
                        {
                            limit, 
                            offset
                        },
                        Object.keys(filters).length > 0 ? {filter: qs.stringify(filters)} : null,
                        Object.keys(sortBy || []).length > 0 ? {sortby: (sortBy || []).map(item => item.direction + item.name)} : null
                    )
                }
            const response = await this.service.get(`/admin/matrix/${labId}/templates/inputs`, config)

            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async desactivateMatrixTemplate(labId: number, id: number): Promise<boolean> {
        try {

            const response = await this.service.delete(`/admin/matrix/${labId}/templates/${id}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async desactivateMatrixTemplateInput(labId: number, id: number, analyteId: string): Promise<boolean> {
        try {

            const response = await this.service.delete(`/admin/matrix/${labId}/templates/${id}/inputs/${analyteId}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteMatrixTemplateMemo(labId: number, id: number): Promise<boolean> {
        try {

            const response = await this.service.delete(`/admin/matrix/${labId}/templates/${id}/delete`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async uploadMatrixTemplateMemo(labId: number, id: number, file: File): Promise<MatrixTemplate> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/admin/matrix/${labId}/templates/${id}/upload`, formData)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async downloadMatrixTemplateMemo(labId: number, id: number, url: string): Promise<boolean> {
        try {
            const response = await this.service.get(`/admin/matrix/${labId}/templates/${id}/download`,
                { responseType: "blob" }
            );

            Downloader.downloadBlob(response.data, url)
            return true;
        } catch (err) {
            throw await AbstractApi.handleBlobError(err);
        }
    }

    public async importMatrixTemplates(type: string, file: File, labId: number): Promise<Array<MatrixTemplate>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/admin/matrix/${labId}/templates/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importMatrixTemplateInputs(type: string, file: File, labId: number): Promise<Array<MatrixTemplateInput>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/admin/matrix/${labId}/templates/inputs/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportMatrixTemplates(type: string, labId: number): Promise<boolean> {
        try {
            const response = await this.service.get(`/admin/matrix/${labId}/templates/export?type=${type}`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('matrix-templates', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    // exports

    public async getMatrixExports(): Promise<Array<MatrixExports>> {

        try {
            const response = await this.service.get(`/admin/matrix/exports`)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteMatrixExportsOutput(labId: number, name: string, analyteId: string): Promise<boolean> {
        try {

            const response = await this.service.delete(`/admin/matrix/${labId}/exports/${name}/${analyteId}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importMatrixExports(type: ExcelExportType, file: File, labId: number): Promise<Array<MatrixExports>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/admin/matrix/${labId}/exports/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportMatrixExports(type: string, labId: number): Promise<boolean> {
        try {
            const response = await this.service.get(`/admin/matrix/${labId}/exports/export?type=${type}`, {
                responseType: 'blob',
            })


            this.downloadBlob(response.data, this.getExportedFileName(`matrix-exports-${type}`, 'xlsx'))
            return true
        }catch (err) {
            throw AbstractApi.handleError(err)
        }

    }

    public async exportMatrixTemplateInputs(type: string, labId: number): Promise<boolean> {
        try {
            const response = await this.service.get(`/admin/matrix/${labId}/templates/inputs/export?type=${type}`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('matrix-template-inputs', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    private downloadBlob(blob: string, fileName: string) {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
    }

    private getExportedFileName(tableName: string, extension: string) {
        const date = new Date()
        return `${tableName}_export_${moment(date).format('YYYYMMDD-HHmm')}.${extension}`
    }

    public async getAutocalcCallState(company_id: number, export_code: string, version: number): Promise<AutocalcCallState> {
        try {
            const response = await this.service.get(`/secure/matrix/${company_id}/${encodeURIComponent(export_code)}/${version}/externals`)
            const data: AutocalcCallState = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    // matrices
    public async getMatrix(company_id: number, export_code: string, version: number): Promise<Matrix> {
        try {
            const response = await this.service.get(`/secure/matrix/${company_id}/${encodeURIComponent(export_code)}/${version}`)
            const data: Matrix = response.data
            data.id = getKeyForMatrix(data) // ajout d'un id pour le front
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getMatrixLastVersion(company_id: number, export_code: string): Promise<Matrix> {
        try {
            const response = await this.service.get(`/secure/matrix/${company_id}/${encodeURIComponent(export_code)}`)
            const data: Matrix = response.data
            if (data) {
                data.id = getKeyForMatrix(data) // ajout d'un id pour le front
            }
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getMatrices(filterBy: FilterBy, sortBy: Array<SortBy>, limit: number, offset: number): Promise<{count: number, data: Array<Matrix>}> {
        try {

            const filters: FilterBy = { ...filterBy }
            Object.keys(filters).forEach(name => {
                if (filters[name].in) {
                    filters[name] = { in: filters[name].in }
                }
            })
            const config = {
                params: Object.assign(
                    {
                        limit, 
                        offset
                    },
                    Object.keys(filters).length > 0 ? {filter: qs.stringify(filters)} : null,
                    Object.keys(sortBy).length > 0 ? {sortby: sortBy.map(item => item.direction + item.name)} : null
                )
            }
            const response = await this.service.get('/secure/matrix', config)
            return {
                count: response.data.count || 0,
                data: response.data.data || []
            }
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteMatrices(body: BodyDeleteMatrices): Promise<boolean> {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
            data: body
        }
        try {
            const response = await this.service.delete(`/secure/matrix`, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async updateMatrixStatus(companyId: number, exportCode: string, version: number, status: string): Promise<Matrix> {
        try {
            const response = await this.service.put(`/secure/matrix/${companyId}/${encodeURIComponent(exportCode)}/${version}/status?status=${encodeURIComponent(status)}`)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    // Valorization
    public async valorizeMatrix(valoReq: ValorizationRequest): Promise<ValorizationResponse> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                },
            }
            const response = await this.service.post(`/secure/matrix/valorisation`, valoReq, config)
            const data: ValorizationResponse = response.data            
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }
    
    /* Matrix Exports */
    public async postMatrixJob(jobRequest : FormData | MatrixRequestImport |MatrixRequestExport): Promise<MatrixResponse> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const response = await this.service.post('/secure/matrix/jobs', jobRequest, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    // Matrix jobs (for import and export)


    public async getMatrixJobStatus(jobId: string): Promise<StatusResponse> {
        try {

            const response = await this.service.get(`/secure/matrix/jobs/${jobId}/status`);
            return response.data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async cancelMatrixJob(jobId: string): Promise<boolean> {
        try {

            await this.service.delete(`/secure/matrix/jobs/${jobId}`);
            return true
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async downloadMatrixJob(jobId: string, url: string, exportName?: string): Promise<boolean> {


        try {
            const response = await this.service.get(`/secure/matrix/jobs/${jobId}/download`,
                { responseType: "blob" }
            );
            if(exportName){
                Downloader.downloadBlob(response.data, Downloader.getExportedFileName(url.split('.')[0], url.split('.').pop() || ''))
            }
            else {
                Downloader.downloadBlob(response.data, Downloader.getExportedFileName(`matrix_jobs`, 'xlsx'))
            }
            return true;
        } catch (err) {
            throw await AbstractApi.handleBlobError(err);
        }
    }

    public async getAllMatrixJobs(): Promise<JobDb[]> {
        try {

            const response = await this.service.get(`/secure/matrix/jobs`);
            return response.data as JobDb[];
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async backupMatrix(mode: BackupMode, force: boolean, matrixInput: BodyMatrixCreate): Promise<ResponseMatrixCreate> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            } 
          const data = JSON.stringify(matrixInput) 
          const response = await this.service.post(`/admin/matrix?mode=${mode}&force=${force}`, data, config);      
          return {results : response.data}           
        } catch (err) {
            console.log(err)
          throw AbstractApi.handleError(err);
        }
      }
}

export default MatrixApi