import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FormikProps } from 'formik';
interface AutonomousFieldProps {
    value: string
    isInvalid: boolean
    storageName: string
    rows: number
    maxLength: number
    formikProps: FormikProps<any>// All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
    triggerRefreshCustomFields?: boolean | null// Boolean to trigger refresh of custom fields when new analysis
}

const AutonomousField: React.FC<AutonomousFieldProps> = ({ value,isInvalid,storageName, rows, maxLength, formikProps, triggerRefreshCustomFields = null }: AutonomousFieldProps) => {
    const {  setFieldValue } = formikProps

    const [valueTemp, setValueTemp] = useState<string | null>(null);

    // Effect to refresh custom fields when new analysis
    useEffect(() => {
        setValueTemp(null);
    }, [triggerRefreshCustomFields])

    return (
        <Form.Control isInvalid={isInvalid} type="text" as="textarea" rows={rows} name={storageName} maxLength={maxLength} value={valueTemp != null ? valueTemp : value} 
        onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            setValueTemp(e.target.value)
        }} 
        onBlur={() => {
            setFieldValue(storageName, valueTemp != null ? valueTemp : value)
        }}/>
    )
}

export default AutonomousField
