/* eslint-disable no-restricted-globals */
import React from 'react';

import { Switch, useRouteMatch } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';

import RoleSecureRoute from '../../common/auth/RoleSecureRoute'
import MatrixListContainer from './matrix_list/MatrixListContainer';
import ConsultMatrixContainer from './matrix/ConsultMatrixContainer';
import CreateMatrixContainer from './matrix/CreateMatrixContainer';
import { SecureRoute } from '@okta/okta-react'



const AppRouter: React.FC<WithTranslation> = () => {

    const match = useRouteMatch();

    return (
        <Switch>
            <RoleSecureRoute exact path={`${match.path}/my_matrix`} requiredRoles={['MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_nutrition']}>
                <MatrixListContainer />                
            </RoleSecureRoute>
            <RoleSecureRoute exact path={`${match.path}/new_matrix`} requiredRoles={['MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_nutrition']}>
                <CreateMatrixContainer />
            </RoleSecureRoute>
            <RoleSecureRoute exact path={`${match.path}/edit_matrix/:mode/:templateId/:companyId/:exportCode/:version`} requiredRoles={['MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_nutrition']}>
                <CreateMatrixContainer/>
            </RoleSecureRoute>
            <SecureRoute exact path={`${match.path}/:templateId/:companyId/:exportCode/:version`}>
                <ConsultMatrixContainer key={new Date().getTime().toString()} /> {/* Force reload on parameters change (for additional analysis consultation) */}
            </SecureRoute>
        </Switch>        
    );
}

export default withTranslation()(AppRouter)