/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios'
import axiosRetry from 'axios-retry'
import moment from 'moment'
import env from '../common/utils/env'
import { TypeCode } from '../types/types'
import AbstractApi from './AbstractApi'
import qs from 'qs'
import { elements } from 'chart.js'
interface DefaultTranslation {
    language_id: number
    name_translation: string
}

export interface PriceListWithoutId {
    name: string
    order: number
    crit_companies: string
    currency: string
}
export interface PriceList extends PriceListWithoutId{
    id: number
}

export interface PriceListList {
    laboratory_id: number
    price_lists: PriceList[]
}

// We add interfaces without ids because id needs to be a string in DragAndDropUtils.ts
export interface PriceListFlatWithoutId extends PriceListWithoutId {
    laboratory_id: number
}

export interface PriceListFlat extends PriceListFlatWithoutId {
    id: number
}

export function  getKeyForTabAssocPriceListFlat(priceList: PriceListFlat): string {
    return priceList.laboratory_id + '#' + priceList.name
}

export type RelationShipType = 'patterns_families' | 'packages_patterns' | 'analytes_test_packages'
export type PatternsExportType = 'patterns' | 'translations' | 'prices'
export type AnalyteExportType = 'tests' | 'translations' | 'prices'
export type PackagesExportType = 'packages' | 'translations' | 'prices'
export type FamiliesExportType = 'families' | 'translations'
export type OriginsExportType = 'origins' | 'translations'
export type RefOriginsExportType = 'ref_origins' | 'translations'

export type AllowTheoreticalValue = 'FALSE' | 'OPTIONAL' | 'MANDATORY'


export interface PatternFamily  {
    id?: string // id for front
    laboratory_id: number
    type_id: number
    family_id: number
    pattern_id: number
  }
  export interface PatternsFamilies {
    patterns_families: PatternFamily []
  }

  export function getKeyForTabAssocPatternFamily(patternFamily: PatternFamily){
    return patternFamily.laboratory_id + '#' + (patternFamily.family_id || '0') + '#' + (patternFamily.type_id || '0')  + '#' + patternFamily.pattern_id
  }

  export interface PackagePattern {
    id?: string // key for front
    laboratory_id: number
    package_id: number
    pattern_id: number
  }

  export function getKeyForTabAssocPackagePattern(item: PackagePattern){
    return item.laboratory_id + '#' + item.package_id + '#' + item.pattern_id
  }
  export interface PackagesPatterns {
    packages_patterns: PackagePattern[]
  }

  export interface AnalyteTestPackage {
    id?: string
    laboratory_id: number
    code_test: string
    code_analyte: string
    package_id: number
  }

  export function getKeyForTabAssocAnalyteTestPackage(analyteTestPackage: AnalyteTestPackage): string {
    return analyteTestPackage.laboratory_id + '#' + analyteTestPackage.code_analyte + '#' + analyteTestPackage.code_test + '#' + analyteTestPackage.package_id
}
  
  export interface AnalyteTestsPackages {
    analytes_test_packages: AnalyteTestPackage[]
  }
  


export interface ObjectWithNameAndTranslations {
    name: string
    translations: Translations<DefaultTranslation>
}

interface Translations<T> {
    [key: number]: T
}

export interface TranslationForArray {
    //translations used in formulars
    name_translation: string
    language_id: number
}

export interface TranslationsJson {
    // translations used in json fields in database
    name: string
    translations: TranslationsMap
}
export interface TranslationsMap {
    [key: string]: string
}

export function convertObjectWithNameAndTranslationsToTranslationsJSon(
    object: ObjectWithNameAndTranslations,
    languages: { [key: string]: Language }
): TranslationsJson {
    const translationsMapObject: TranslationsMap = {}

    Object.keys(object.translations).forEach((key) => {
        translationsMapObject[languages[key].code_language] = object.translations[Number(key)].name_translation
    })

    const objectTranslationsJSon: TranslationsJson = {
        name: object.name,
        translations: translationsMapObject,
    }
    return objectTranslationsJSon
}

export type StatusType = 'analysis' | 'control-plan'

interface StatusTranslation {
    language_id: number
    label_translation: string
}

export enum labTypes {
    cargill = 'cargill',
    client = 'client'
}

export type LabType = 'cargill' | 'client' | string;

export interface Status {
    id: number
    type: StatusType
    key: string
    label: string
    translation: Translations<StatusTranslation>
}

export interface Language {
    id: number
    name: string
    code_language: string
    date_format: string
    currency: string
    translations: Translations<DefaultTranslation>
}

export interface Email {
    email: string
}

export interface NotificationFrequency {
    type: string
    job_id: string
    week_day: string
    hours: string
    minutes: string
    timezone: string
}

export type TresholdsColors = 'worst' | 'bad' | 'average' | 'good' | 'better'
export const THRESHOLDS_COLORS: Array<TresholdsColors> = ['worst', 'bad', 'average', 'good', 'better']

export type RawAnalysisColumns = 'nir' | 'product' | 'denomination' | 'farmer' | 'requester' | 'company' | 'lot' | 'client_reference'| 'origin' | 'supplier' | 'client_denomination'
export const RAWANALYSIS_COLUMNS: Array<RawAnalysisColumns> = ['nir', 'product', 'denomination', 'farmer', 'requester', 'company', 'lot', 'client_reference', 'origin', 'supplier', 'client_denomination']

export type RawResultsColumns = 'laboratory' | 'nir' | 'method' | 'results' | 'mdi' | 'gh' | 'gh_max' | 'target' | 'warning_low' | 'warning_high' | 'limit_low' | 'limit_high' | 'status' | 'unit'
export const RAWRESULTS_COLUMNS: Array<RawResultsColumns> = ['laboratory', 'nir', 'method', 'results', 'mdi', 'gh', 'gh_max', 'target', 'warning_low', 'warning_high', 'limit_low', 'limit_high', 'status', 'unit']

export type AnalysisResultsColumns = 'ref_sample_id' | 'type_cargill' | 'pattern' | 'farmer' | 'employee' | 'company' | 'lot' | 'client_reference' | 'denomination' | 'origin' | 'supplier' | 'client_denomination'
export const ANALYSISRESULTS_COLUMNS: Array<AnalysisResultsColumns> = [ 'ref_sample_id', 'type_cargill', 'pattern', 'farmer', 'employee', 'company', 'lot', 'client_reference', 'denomination', 'origin', 'supplier', 'client_denomination']

export type ResultsColumns = 'laboratory' | 'nir' | 'result' | 'mdi' | 'gh' | 'color' | 'category' | 'uncertainty' | 'target'
export const RESULTS_COLUMNS: Array<ResultsColumns> = ['laboratory', 'nir', 'result', 'mdi', 'gh', 'color', 'category', 'uncertainty', 'target']

export interface BarCode {
    code: string
    regex: string
    mappings: Array<string>
}

export interface NirGroup {
    group_name: string
    nir_code: Array<string>
  }
  

export interface Laboratory {
    address1: string;
    address2: string;
    city: string;
    country: string;
    currency: string;
    email: string;
    emails: Array<Email>;
    report_generation_mode: string;
    flow_code: string;
    footer_link: string;
    footer_link_label: string;
    footer_logo: string;
    header_logo: string;
    id: number;
    lab_type: LabType;
    language_id: number;
    mail_image: string;
    mail_image_link: string;
    markup: number;
    name: string;
    notif_frequencies: Array<NotificationFrequency>;
    phone: string;
    theme_primary: string;
    theme_primary_light: string;
    theme_secondary: string;
    theme_thresholds: { [key in TresholdsColors]: string }
    zip_code: string;
    results_columns: { [key in ResultsColumns]: boolean };
    analysis_results_columns: { [key in AnalysisResultsColumns]: boolean };
    pivot_error_emails: Array<string>;
    barcodes: Array<BarCode>;
    ref_lab_id: number | null;
    combined_analysis_allowed: boolean | undefined;
    raw_analysis_columns: { [key in RawAnalysisColumns]: boolean };
    raw_results_columns: { [key in RawResultsColumns]: boolean };
    apply_daysilo_calc: boolean;
    default_harvest_date : Date | null ;
    auto_receive: string;
    nir_first_types: Array<string>;
    udf_settings: Array<UdfSettings>;
    nir_groups : Array<NirGroup>
    glims_registration_header: boolean
    glims_registration_package_fg: boolean
    glims_registration_package_rm: boolean
    glims_registration_package_fp: boolean
    glims_instance_id: string 
    glims_origin: string 
    glims_supplier_code: string 
    glims_species: string 
}

export interface Type {
    id: number
    code: 'FG' | 'RM' | 'FP'
    name: string
    laboratory_id: number
    ref_type_id: number | null
    active: boolean
    nir_types: Array<NirTypes>
    translations: Translations<DefaultTranslation>
}

export interface TypeDocument {
    code: 'FG' | 'RM' | 'FP'
    name: string
    laboratory_id: number
    ref_type_id: number | null
    nir_types: Array<NirTypes>
    translations: Translations<DefaultTranslation>
}

export interface DataList {
    component: string
    field_id: number
    laboratory_id: number
    sample_form_id: number
    type_code: string
    values: Array<string>
}

export interface DataListRequest {
    values: Array<string>
}

export interface FamilyTranslation extends DefaultTranslation {
    comment_translation: string
}

export interface Family {
    id: number
    laboratory_id: number
    code: string
    name: string
    type_id: number
    details_required: boolean
    comment: string
    active: boolean
    pdc: boolean
    jcode?: string | null
    product_category_id?: number | null
    species_id?: number | null
    suppliers: Array<number>
    origins: Array<number>
    ref_family_id?: number | null
    translations: Translations<FamilyTranslation>
    dm1_auto: boolean
}

export interface FamilyDocument {
    code: string
    name: string
    type_id: number
    details_required: boolean
    comment: string
    pdc: boolean
    jcode?: string | null
    product_category_id?: number | null
    species_id?: number | null
    suppliers: Array<number>
    origins: Array<number>
    ref_family_id?: number | null
    translations: Translations<FamilyTranslation>
    dm1_auto: boolean
}

export interface ImproperTranslation {
    language_id: number
    improper_translation: string
}

export interface Improper {
    id: number
    improper: string
    type_id: number
    active: boolean
    editable: boolean
    translations: Translations<ImproperTranslation>
}

export interface ImproperDocument {
    improper: string
    type_id: number
    translations: Translations<ImproperTranslation>
}

export interface Ingredient {
    jcode?: string
    name: string
}


export interface LabSettings {
    laboratory_id: number
    id? : string
    settings_name: string
    properties: {
        tenantId: string
        engineVersion: string
        inputNutrientMoistureBasis: string
        returnAllCalculatedOutputNutrients: boolean
        useNutrientReferenceValuesForMissingOutputs: boolean
        usePreviousStoredNutrientsInCalc: boolean
    }
    test_call_request: string
}

export interface LabSettingsKey {
    laboratory_id: number
    settings_name: string
}


export interface LabSettingsFilter {
    laboratory_id?: number
    settings_name?: string
}

export interface PatternTranslation extends DefaultTranslation {
    option_translation: string
}

export interface patternsFamilies {
    family_id: number
}


export type Price = {
    price_list_id: number
    price: number
}

export type Partial = {
    cost: number
    period: number
    weight: number
}

export interface PatternWithoutIdActive{
    laboratory_id: number
    name: string
    glims_name: string
    option: string
    pdc: boolean
    cost: number
    period: number
    weight: number
    package_test_mandatory: boolean
    translations: Translations<PatternTranslation>
    prices: Translations<Price>
}
export interface Pattern extends PatternWithoutIdActive {
    id: number,
    active: boolean

   
}

export interface PatternDocument {
    name: string
    option: string | null
    active: boolean
    pdc: boolean
    cost: number
    period: number
    weight: number
    translations: Translations<PatternTranslation>
    prices: Array<Price>
}

export interface PackageTranslation extends DefaultTranslation {
    new_translation?: string
}

export interface PackagesPatternsOld {
    pattern_id: number
}

export interface PriceContainer {
    prices: Translations<Price>
}

export interface Package {
    id: number
    name: string
    glims_name: string
    type_id: number
    laboratory_id: number
    active: boolean
    cost: number
    period: number
    weight: number
    patterns: Array<PackagesPatternsOld>
    translations: Translations<PackageTranslation>
    prices: Translations<Price>
}

export interface PackageDocument {
    name: string
    laboratory_id: number
    cost: number
    period: number
    weight: number
    patterns: Array<PackagesPatternsOld>
    translations: Translations<DefaultTranslation>
    prices: Array<Price>
}

export interface ProcessingTranslation extends DefaultTranslation {
    new_translation?: string
}

export interface Processing {
    id: number
    code: string
    name: string
    lca_name: string
    translations: Translations<ProcessingTranslation>
    active: boolean
}

export interface ProcessingDocument {
    code: string
    name: string
    lca_name: string
    translations: Translations<DefaultTranslation>
}

export interface ProductDenomination {
    laboratory_id: number
    id: number
    code: string
    name: string
    active: boolean
}

export interface ProductCategoryTranslation extends DefaultTranslation {
    new_translation?: string
}

export interface ProductCategory {
    id: number
    type_code: string
    name: string
    code: string
    active: boolean
    translations: Translations<ProductCategoryTranslation>
}
export interface ProductCategoryDocument {
    type_code: string
    name: string
    code: string
    translations: Translations<ProductCategoryTranslation>
}

export interface RefOriginTranslation extends DefaultTranslation {
    new_translation?: string
}

export interface RefOrigin {
    id: number
    name: string
    lca_name: string
    code: string
    active: boolean
    translations: Translations<DefaultTranslation>
}

export interface RefOriginDocument {
    name: string
    lca_name: string
    code: string
    translations: Translations<DefaultTranslation>
}

export interface OriginTranslation extends DefaultTranslation {
    new_translation?: string
}

export interface Origin {
    id: number
    name: string
    code: string
    active: boolean
    laboratory_id: number
    ref_origin_id: number
    translations: Translations<OriginTranslation>
}

export interface OriginDocument {
    name: string
    code: string
    laboratory_id: number
    ref_origin_id: number
    translations: Translations<OriginTranslation>
}

export interface RefSupplier {
    id: number
    name: string
    code: string
    active: boolean
}

export interface RefSupplierDocument {
    name: string
    code: string
}
export interface Supplier {
    id: number
    name: string
    code: string
    active: boolean
    laboratory_id: number
    ref_supplier_id: number | null
}

export interface SupplierDocument {
    name: string
    code: string
    laboratory_id: number
    ref_supplier_id: number | null
}

export interface SpecieTranslation extends DefaultTranslation {
    new_translation?: string
}

export interface Specie {
    id: number
    name: string
    code: string
    active: boolean
    translations: Translations<SpecieTranslation>
}

export interface SpecieDocument {
    name: string
    code: string
    translations: Translations<SpecieTranslation>
}

export enum allowTheoreticalValueEnum {
    FALSE = 'FALSE',
    OPTIONAL = 'OPTIONAL',
    MANDATORY = 'MANDATORY'
  }

export type allowTheoreticalValue = allowTheoreticalValueEnum.FALSE | allowTheoreticalValueEnum.OPTIONAL | allowTheoreticalValueEnum.MANDATORY

export interface TransportTranslation extends DefaultTranslation {
    new_translation?: string
}

export interface Transport {
    id: number
    code: string
    name: string
    lca_name: string
    translations: Translations<TransportTranslation>
    active: boolean
}

export interface TransportDocument {
    code: string
    name: string
    lca_name: string
    translations: Translations<DefaultTranslation>
}

export interface VarietyTranslation extends DefaultTranslation {
    comment_translation: string
}

export interface Variety {
    id: number
    name: string
    laboratory_id: number
    comment: string
    active: boolean
    translations: Translations<VarietyTranslation>
}

export interface VarietyDocument {
    name: string
    laboratory_id: number
    comment: string
    active: boolean
    translations: Translations<VarietyTranslation>
}

export interface FieldsTranslation extends DefaultTranslation {
    name_translation: string
}
export interface Fields {
    id: number
    name: string
    active?: boolean
    data_type: string
    input_name?: string
    source_type?: string
    source_name?: string
    storage_type: string
    storage_name?: string
    mandatory: boolean
    type_codes: Array<string>
    translations: Array<FieldsTranslation>
}

export interface FieldsDocument {
    id?: number
    name: string
    active?: boolean
    data_type: string
    input_name?: string
    source_type?: string
    source_name?: string
    storage_type: string
    storage_name?: string
    mandatory: boolean
    type_codes: Array<string>
    translations: Array<FieldsTranslation>
}

export interface FieldsDocPutPost {
    name: string
    data_type: string
    input_name?: string
    source_type?: string
    source_name?: string
    storage_type: string
    storage_name?: string
    mandatory: boolean
    type_codes: Array<string>
    translations: Array<FieldsTranslation>
}

export interface RefAnalyte {
    id: string
    json_name: TranslationsJson
    default_unit?: string
}

export interface AutocalcResultsFilter {
    laboratory_id: number
    autocalc_ids: Array<string>
}

export interface AutocalcResultsFilterExcel {
    laboratory_id: number
    autocalc_id: string
}
export interface ResultsPatternsKey {
    laboratory_id: number
    name: string
}
export interface ResultsPatterns extends ResultsPatternsKey {
    id?: string // id for front (concat of key components useful only in list) (to update when an element is added/modified to the list))
    laboratory_id: number
    name: string
    only_pattern_results: boolean
    dm_flag: boolean
}

export function getKeyForTabAssocResultsPatterns(resultsPatterns: ResultsPatternsKey): string {
    return resultsPatterns.laboratory_id + '#' + resultsPatterns.name
}

//clé pour l'affichage
export interface BaseTestsKey {
    laboratory_id: number
    results_pattern_name: string
    type_code: TypeCode
    family_id: number
}

export interface BaseTests extends BaseTestsKey {
    id?: string // front id
    analyte_ids: string[]
}

export interface RefUnit {
    unit: string
    conversions: UnitsConversion[]
}

export interface UnitsConversion {
    source_unit: string
    target_unit: string
    conversion_factor: number
}

export enum RefUnitsType {
    unit = 'unit',
    conversion = 'conversion'
}

export function getKeyForTabAssocBaseTests(base_tests: BaseTestsKey): string {
    return (
        base_tests.laboratory_id +
        '#' +
        base_tests.results_pattern_name +
        '#' +
        base_tests.type_code +
        '#' +
        base_tests.family_id
    )
}
export enum AnalyteSource {
    glims = 'GLIMS',
    nir = 'NIR',
    inputs = 'Inputs',
    calculated = 'Calculated',
}
export type TypeAnalyteSource = AnalyteSource.glims | AnalyteSource.nir | AnalyteSource.inputs | AnalyteSource.calculated
export type ExpectedFormat = 'numeric' | 'text'

export interface AnalyteKey {
    laboratory_id: number
    code_test: string
    code_analyte: string
}
export interface Analyte extends AnalyteKey {
    id: string; // front id
    laboratory_id: number;
    code_test: string;
    code_analyte: string;
    analyte_id: string;
    method: string;
    quantification_low: number | null;
    quantification_high: number | null;
    uncertainty: number | null;
    visible: boolean;
    json_name: TranslationsJson;
    category_order: number;
    result_type: string;
    conversion_factor: number;
    decimals: number;
    unit: string;
    target_interval: string;
    accreditation: boolean;
    denominator: string;
    appendix: string;
    source: AnalyteSource
    expected_format: ExpectedFormat | null
    auto_validation: boolean | null
    apply_aidm_calc: boolean | null
    allow_theoretical_value: allowTheoreticalValueEnum
    analysis_group_name: TranslationsJson
    cost: number
    period: number
    weight: number
    active: boolean
    prices: Price[]

}

export function getAnalyteKey(analyte: AnalyteKey): string {
    return analyte.laboratory_id + '#' + analyte.code_test + '#' + analyte.code_analyte
}

//  Autocalc Analytes
export interface AutocalcAnalytesApi {
    laboratory_id: number 
    analytes: [{
        analyte_id: string
        autocalc_id: string
        toggle: boolean
    }]
}
export interface AutocalcAnalyteKey {
    laboratory_id: number
    analyte_id: string
}
export interface AutocalcAnalyte extends AutocalcAnalyteKey{
    laboratory_id: number
    analyte_id: string
    autocalc_id: string
    toggle: boolean
}
export function getKeyForTabAssocAutocalcAnalyte(autocalcAnalyte: AutocalcAnalyteKey): string {
    return autocalcAnalyte.laboratory_id + '#' + autocalcAnalyte.analyte_id
}
export function convertAutocalcAnalytesApiToAutocalcAnalytes (autocalcAnalytesApi: Array<AutocalcAnalytesApi>): Array<AutocalcAnalyte> {
    const autocalcAnalytes: Array<AutocalcAnalyte> = []
    autocalcAnalytesApi.forEach((autocalcAnalytesForLab) => {
        autocalcAnalytesForLab.analytes.forEach((analyte) => {
            const autocalcAnalyte: AutocalcAnalyte = {
                laboratory_id: autocalcAnalytesForLab.laboratory_id, 
                analyte_id: analyte.analyte_id, 
                autocalc_id: analyte.autocalc_id, 
                toggle: analyte.toggle
            }
            autocalcAnalytes.push(autocalcAnalyte)
        })
    });
    return autocalcAnalytes;
}

//  Test Category
export interface TestCategoryKey {
    laboratory_id: number
    results_pattern_name: string
    code: string
}

export interface TestCategoryDocument extends TestCategoryKey {
    laboratory_id: number
    results_pattern_name: string
    json_name: TranslationsJson
    code: string
    order: number
    is_default: boolean
}
export interface TestCategory extends TestCategoryDocument {
    id: string // id for front (concat of key components useful only in list unless it is drag and drop table (so id is different))
}

export function getKeyForTabAssocTestCategory(test_category: TestCategoryKey): string {
    return test_category.laboratory_id + '#' + test_category.results_pattern_name + '#' + test_category.code
}

export enum FieldsType {
    static = 'static',
    dynamic = 'dynamic',
}

export type FormFilter = {
    laboratory_id?: number
    type_code?: string
}

export type FormFieldDocument = {
    field_id: number
    required: boolean
}

export type FormFields = {
    client: FormFieldDocument[][]
    sample: FormFieldDocument[][]
}

export type Form = {
    id?: number
    laboratory_id?: number
    type_code?: string
    fields: FormFields
}

export type FormRequest = {
    id?: number
    laboratory_id?: number
    type_code?: string
    fields: FormFields
}

export interface Report {
    name: string
    theme: string
    custom: {
        filename?: string
    }
}

export type RulesResultsPatternsDocument = {

    order: number;
    crit_company: string;
    crit_typecode: string;
    crit_family: string;
    results_pattern_name: string;
    report: Report;
    appendices: Array<Report>;
    exports: Array<string>;
    apply_autocalc: boolean;
};

export type RuleResultsPatterns = {
    laboratory_id: number
    rule: Array<RulesResultsPatternsDocument>
}

export type TestThresholdsDocument = {
    order: number
    low_threshold: number | null
    high_threshold: number | null
    interpretation: TranslationsJson
    range_color: string
}

export type TestThresholdsExcel = {
    threshold_id: number
    order: number
    low_threshold: number | null
    high_threshold: number | null
    interpretation: TranslationsJson
    range_color: string
}

export type RuleAnalyteTestThresholdDocument = {
    ruleId?: number
    code_test: string
    code_analyte: string
    order: number
    crit_results_pattern: string
    crit_typecode: string
    crit_family: string
    min_value: number
    max_value: number
    result_type: string
    thresholds: Array<TestThresholdsDocument>
}

export type Threshold = {
    laboratory_id: number
    rule: Array<RuleAnalyteTestThresholdDocument>
}
export interface RuleResultsPatternTestsKey {
    laboratory_id: number
    code_test: string
    code_analyte: string
    order: number
}

export interface RuleResultsPatternTests extends RuleResultsPatternTestsKey {
    id: string // front id
    order: number
    laboratory_id: number
    crit_results_pattern: string
    code_test: string;
    code_analyte: string;
    crit_typecode: string;
    crit_family: string;
    visible: boolean;
    json_name: TranslationsJson;
    category_code: string;
    category_order: number;
    result_type: string;
    conversion_factor: number;
    decimals: number;
    unit: string;
    target_interval: string;
    accreditation: boolean;
    denominator: string;
    appendix: string;
}

export interface TargetValues {
    id: string // front id
    laboratory_id: number
    company_id: number
    type_code: string
    ref_field_id: number
    ref_field_value: string
    analyte_id: string
    starting_date: Date
    target: number
    warning_low: number
    warning_high: number
    limit_low: number
    limit_high: number
}

export interface NirTypes {
    laboratory_id: number;
    type_id: number;
    nir_code: string;
    nir_type_code: string;
}

export interface UdfSettings {
    type: string
    udfs: Array<Udf>
}

export interface Udf {
    udf: string
    sample_form_id: number
    field_id: number
}

export function getKeyForTabAssocRuleResultsPatternTests(rules: RuleResultsPatternTestsKey): string {
    return rules.laboratory_id + "#" + rules.code_test + "#" + rules.code_analyte + "#" + rules.order
}

export function getKeyForTabAssocTargetValues(targetValue: TargetValues): string {
    return targetValue.laboratory_id + "#" + targetValue.type_code + "#" + targetValue.starting_date + "#" + targetValue.ref_field_id+ "#" + targetValue.ref_field_value+ "#" + targetValue.company_id+ "#" + targetValue.analyte_id
}

export interface FooterStateForMobile {
    footerState: boolean
}

export enum ThresholdsType {
    thresholds = 'thresholds',
    rules = 'rules',
  }

const standaloneInstance = axios.create({
    baseURL: env.CONFIG_API_URL,
    timeout: 60000,
})

axiosRetry(standaloneInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
})

class ConfigApi extends AbstractApi {
    private static instance: ConfigApi

    public static InputNutrientMoistureBasis = ["AsFed", "DryMatter"]

    public static getInstance() {
        if (!ConfigApi.instance) {
            throw new Error('Initialize instance before.')
        }
        return ConfigApi.instance
    }

    public static initInstance(token: string) {
        ConfigApi.instance = new ConfigApi(env.CONFIG_API_URL, token)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static translationsToDictionary<T>(inputItem: any): T {
        const { translations, ...item } = inputItem
        item.translations = {}
        translations.forEach((translation: { language_id: number }) => {
            item.translations[translation.language_id] = translation
        })
        return item
    }

    private static translationArrayToDictionary<T>(inputArray: Array<unknown>): Array<T> {
        const data: Array<T> = []
        inputArray.forEach((inputItem) => {
            const item: T = ConfigApi.translationsToDictionary(inputItem)
            data.push(item)
        })
        return data
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static pricesToDictionary<T>(inputItem: any): T {
        const { prices, ...item } = inputItem
        if (Array.isArray(prices)) {
            item.prices = {}
            prices.forEach((price: { price_list_id: number }) => {
                item.prices[price.price_list_id] = price
            })
        }

        return item
    }

    public static pricesToDictionaryCompany<T>(inputItem: any): T {
        const { prices, ...item } = inputItem
        if (Array.isArray(prices)) {
            item.prices = {}
            prices.forEach((price: { company_id: number }) => {
                item.prices[price.company_id] = price
            })
        }

        return item
    }

    public static priceArrayToDictionary<T>(inputArray: Array<unknown>): Array<T> {
        const data: Array<T> = []
        inputArray.forEach((inputItem) => {
            const item: T = ConfigApi.pricesToDictionary(inputItem)
            data.push(item)
        })

        return data
    }

    public static priceArrayToDictionaryCompany<T>(inputArray: Array<unknown>): Array<T> {
        const data: Array<T> = []
        inputArray.forEach((inputItem) => {
            const item: T = ConfigApi.pricesToDictionaryCompany(inputItem)
            data.push(item)
        })

        return data
    }

    public async getDataLists(): Promise<Array<DataList>> {
        try {
            const response = await this.service.get(`/forms/datalists`)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getDataListByIds(form_id: number, field_id: number): Promise<DataList> {
        try {
            const response = await this.service.get(`/forms/${form_id}/fields/${field_id}/datalist`)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteDataList(form_id: number, field_id: number): Promise<void> {
        try {
            await this.service.delete(`/forms/${form_id}/fields/${field_id}/datalist`)
            return
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async upsertDataList(data_list: DataListRequest, form_id: number, field_id: number): Promise<DataList> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(data_list)
            const response = await this.service.put(`/forms/${form_id}/fields/${field_id}/datalist`, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public static async getLanguages(): Promise<Array<Language>> {
        try {
            const response = await standaloneInstance.get('/language')
            const data: Array<Language> = ConfigApi.translationArrayToDictionary(response.data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public static async getLanguageById(id: number): Promise<Language> {
        try {
            const response = await standaloneInstance.get(`/language/${id}`)
            const data: Language = ConfigApi.translationsToDictionary(response.data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getLaboratories(): Promise<Array<Laboratory>> {
        try {
            const response = await this.service.get('/laboratory')
            const data: Array<Laboratory> = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getLaboratoryById(laboratory_id: number): Promise<Laboratory> {
        try {
            const response = await this.service.get(`/laboratory/${laboratory_id}`)
            const data: Laboratory = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postLaboratory(laboratory: FormData): Promise<Laboratory> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
            const response = await this.service.post('/laboratory', laboratory, config)
            return response.data as Laboratory
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putLaboratory(laboratory: FormData, laboratoryId: number): Promise<Laboratory> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
            const response = await this.service.put('/laboratory/' + laboratoryId, laboratory, config)
            return response.data as Laboratory
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteLaboratoryById(laboratory_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete('/laboratory/' + laboratory_id)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getTypes(): Promise<Array<Type>> {
        try {
            const response = await this.service.get(`/type`)
            const data: Array<Type> = ConfigApi.translationArrayToDictionary(response.data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteType(type_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete('/type/' + type_id)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postType(type: TypeDocument): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(type)
            const response = await this.service.post('/type', data, config)
            return response.data.id
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putType(type: TypeDocument, typeId: number): Promise<boolean> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(type)
            const response = await this.service.put('/type/' + typeId, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getFamilies(laboratory_id?: number): Promise<Array<Family>> {
        try {
            const encodedLaboratory = laboratory_id ? encodeURIComponent(laboratory_id) : ''
            const response = await this.service.get(
                laboratory_id ? `/family?laboratory_id=${encodedLaboratory}` : `/family`
            )
            const data: Array<Family> = ConfigApi.translationArrayToDictionary(response.data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteFamily(family_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete('/family/' + family_id)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postFamily(family: FamilyDocument): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            family.type_id = Number(family.type_id)
            const data = JSON.stringify(family)
            const response = await this.service.post('/family', data, config)
            return response.data.id
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putFamily(family: FamilyDocument, familyId: number): Promise<boolean> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            family.type_id = Number(family.type_id)
            const data = JSON.stringify(family)
            const response = await this.service.put('/family/' + familyId, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importFamilies(file: File, laboratoryId: number, type: FamiliesExportType): Promise<Array<Family>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/family/${laboratoryId}/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportFamilies(laboratoryId: number, type: FamiliesExportType): Promise<boolean> {
        try {
            const response = await this.service.get(`/family/${laboratoryId}/export?type=${type}`, {
                responseType: 'blob',
            })
    
            this.downloadBlob(response.data, this.getExportedFileName(`families_${type}_${laboratoryId}`, 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getImpropers(laboratory_id?: number): Promise<Array<Improper>> {
        try {
            const encodedLaboratory = laboratory_id ? encodeURIComponent(laboratory_id) : ''
            const response = await this.service.get(
                laboratory_id ? `/improper/?laboratory_id=${encodedLaboratory}` : `/improper`
            )
            const data: Array<Improper> = ConfigApi.translationArrayToDictionary(response.data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteImproper(improper_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete('/improper/' + improper_id)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postImproper(improper: ImproperDocument): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            improper.type_id = Number(improper.type_id)
            const data = JSON.stringify(improper)
            const response = await this.service.post('/improper', data, config)
            return response.data.id
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putImproper(improper: ImproperDocument, improperId: number): Promise<boolean> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            improper.type_id = Number(improper.type_id)
            const data = JSON.stringify(improper)
            const response = await this.service.put('/improper/' + improperId, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public getLabSettingsId(key : LabSettingsKey) : string {
        
        return key.laboratory_id + '#' + key.settings_name

    }
    
    public async getLabSettings(settingsName: string): Promise<Array<LabSettings>> {
        try {

            const response = await this.service.get(`/lab_settings/?settings_name=${settingsName}`)
            const data : Array<LabSettings> = response.data
            data.forEach((item) => {
                item.id = this.getLabSettingsId(item)
            })
            return data

        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postLabSettings(labSettings: LabSettings): Promise<LabSettings> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(labSettings)
            const response = await this.service.post(`/lab_settings/${labSettings.laboratory_id}/?settings_name=${labSettings.settings_name}`, data, config)
            const item = response.data
            return item
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putLabSettings(labSettings: LabSettings): Promise<LabSettings> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(labSettings)
            const response = await this.service.put(`/lab_settings/${labSettings.laboratory_id}/?settings_name=${labSettings.settings_name}`, data, config)
            const item = response.data
            return item
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteLabSettings(labSettings: LabSettings): Promise<boolean> {
        try {

            const response = await this.service.delete(`/lab_settings/${labSettings.laboratory_id}/?settings_name=${labSettings.settings_name}`)

            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getIngredients(): Promise<Array<Ingredient>> {
        try {
            const response = await this.service.get(`/ingredient`)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postIngredient(ingredient: Ingredient): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(ingredient)
            const response = await this.service.post('/ingredient', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putIngredient(ingredient: Ingredient, jcode: string): Promise<Specie> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(ingredient)
            const response = await this.service.put('/ingredient/' + jcode, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getPatterns(laboratory_id?: number): Promise<Array<Pattern>> {
        try {
            const encodedLaboratory = laboratory_id ? encodeURIComponent(laboratory_id) : ''
            const response = await this.service.get(
                laboratory_id ? `/pattern/?laboratory_id=${encodedLaboratory}` : `/pattern`
            )
            let data: Array<Pattern> = ConfigApi.translationArrayToDictionary(response.data)
            data = ConfigApi.priceArrayToDictionary(data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deletePattern(pattern_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete('/pattern/' + pattern_id)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postPattern(pattern: PatternWithoutIdActive): Promise<Pattern> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(pattern)
            const response = await this.service.post('/pattern', data, config)
            const dataRes: Pattern = ConfigApi.pricesToDictionary(response.data)
            return dataRes
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putPattern(pattern: PatternWithoutIdActive, patternId: number): Promise<Pattern> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }

            const data = JSON.stringify(pattern)
            const response = await this.service.put('/pattern/' + patternId, data, config)
            const dataRes: Pattern = ConfigApi.pricesToDictionary(response.data)
            return dataRes
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postPatternPartial(partial: Partial, patternId: number): Promise<boolean> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(partial)
            const response = await this.service.post('/pattern/' + patternId, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putPatternPrices(prices: Array<Price>, patternId: number): Promise<boolean> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(prices)
            const response = await this.service.put('/pattern/' + patternId + '/prices', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getPackages(laboratory_id?: number): Promise<Array<Package>> {
        try {
            const encodedLaboratory = laboratory_id ? encodeURIComponent(laboratory_id) : ''
            const response = await this.service.get(
                laboratory_id ? `/package/?laboratory_id=${encodedLaboratory}` : `/package`
            )
            let data: Array<Package> = ConfigApi.translationArrayToDictionary(response.data)
            data = ConfigApi.priceArrayToDictionary(data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deletePackage(package_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete('/package/' + package_id)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deletePrice(company_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete(`/prices?company_id=${company_id}`)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postPackage(packageObject: PackageDocument): Promise<Package> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(packageObject)
            const response = await this.service.post('/package', data, config)
            const dataRes: Package = ConfigApi.pricesToDictionary(response.data)
            return dataRes
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putPackage(packageObject: PackageDocument, packageId: number): Promise<Package> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(packageObject)
            const response = await this.service.put('/package/' + packageId, data, config)
            const dataRes: Package = ConfigApi.pricesToDictionary(response.data)
            return dataRes
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postPackagePartial(partial: Partial, packageId: number): Promise<boolean> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(partial)
            const response = await this.service.post('/package/' + packageId, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putPackagePrices(prices: Array<Price>, packageId: number): Promise<boolean> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(prices)
            const response = await this.service.put('/package/' + packageId + '/prices', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getProcessing(): Promise<Array<Processing>> {
        try {
            const response = await this.service.get(`/ref-processing`)
            let data: Array<Transport> = ConfigApi.translationArrayToDictionary(response.data)
            data = ConfigApi.priceArrayToDictionaryCompany(data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getProcessingById(id: number): Promise<Processing> {
        try {
            const response = await this.service.get(`/ref-processing/${id}`)
            const data: Transport = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteProcessing(ref_processing_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete(`/ref-processing/${ref_processing_id}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postProcessing(ref_processing: ProcessingDocument): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(ref_processing)
            const response = await this.service.post('/ref-processing', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putProcessing(ref_processing: ProcessingDocument, ref_processing_id: number): Promise<Processing> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(ref_processing)
            const response = await this.service.put('/ref-processing/' + ref_processing_id, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getProductCategories(): Promise<Array<ProductCategory>> {
        try {
            const response = await this.service.get(`/product-categories`)
            let data: Array<ProductCategory> = ConfigApi.translationArrayToDictionary(response.data)
            data = ConfigApi.priceArrayToDictionaryCompany(data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getProductCategoryById(id: number): Promise<ProductCategory> {
        try {
            const response = await this.service.get(`/product-categories/${id}`)
            const data: ProductCategory = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteProductCategory(product_category_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete(`/product-categories/${product_category_id}`)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postProductCategory(product_category: ProductCategoryDocument): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(product_category)
            const response = await this.service.post('/product-categories', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putProductCategory(
        product_category: ProductCategoryDocument,
        product_category_id: number
    ): Promise<ProductCategory> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(product_category)
            const response = await this.service.put('/product-categories/' + product_category_id, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getRefOrigins(): Promise<Array<RefOrigin>> {
        try {
            const response = await this.service.get(`/ref-origin`)
            let data: Array<RefOrigin> = ConfigApi.translationArrayToDictionary(response.data)
            data = ConfigApi.priceArrayToDictionaryCompany(data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getRefOriginById(id: number): Promise<RefOrigin> {
        try {
            const response = await this.service.get(`/ref-origin/${id}`)
            const data: RefOrigin = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteRefOrigin(ref_origin_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete(`/ref-origin/${ref_origin_id}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postRefOrigin(ref_origin: RefOriginDocument): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(ref_origin)
            const response = await this.service.post('/ref-origin', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putRefOrigin(ref_origin: RefOriginDocument, ref_origin_id: number): Promise<Specie> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(ref_origin)
            const response = await this.service.put('/ref-origin/' + ref_origin_id, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importRefOrigins(file: File,  type: RefOriginsExportType): Promise<Array<RefOrigin>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)

            const response = await this.service.post(`/ref-origin/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportRefOrigins(type: RefOriginsExportType): Promise<boolean> {
        try {
            const response = await this.service.get(`/ref-origin/export?type=${type}`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName(`ref_origins_${type}`, 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getOrigins(): Promise<Array<Origin>> {
        try {
            const response = await this.service.get(`/origin`)
            let data: Array<Origin> = ConfigApi.translationArrayToDictionary(response.data)
            data = ConfigApi.priceArrayToDictionaryCompany(data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getOriginById(id: number): Promise<Origin> {
        try {
            const response = await this.service.get(`/origin/${id}`)
            const data: Origin = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteOrigin(origin_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete(`/origin/${origin_id}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postOrigin(origin: OriginDocument): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(origin)
            const response = await this.service.post('/origin', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putOrigin(origin: OriginDocument, origin_id: number): Promise<Specie> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(origin)
            const response = await this.service.put('/origin/' + origin_id, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importOrigins(file: File, laboratoryId: number, type: OriginsExportType): Promise<Array<Origin>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/origin/${laboratoryId}/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportOrigins(laboratoryId: number, type: OriginsExportType): Promise<boolean> {
        try {
            const response = await this.service.get(`/origin/${laboratoryId}/export?type=${type}`, {
                responseType: 'blob',
            })
    
            this.downloadBlob(response.data, this.getExportedFileName(`origins_${type}_${laboratoryId}`, 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getRefSuppliers(): Promise<Array<RefSupplier>> {
        try {
            const response = await this.service.get(`/ref-supplier`)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getRefSupplierById(id: number): Promise<RefSupplier> {
        try {
            const response = await this.service.get(`/ref-supplier/${id}`)
            const data: RefSupplier = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteRefSupplier(ref_supplier_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete(`/ref-supplier/${ref_supplier_id}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postRefSupplier(ref_supplier: RefSupplierDocument): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            // const data = JSON.stringify(ref_supplier)
            const response = await this.service.post('/ref-supplier', ref_supplier, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importRefSuppliers(file: File): Promise<Array<RefSupplier>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)

            const response = await this.service.post(`/ref-supplier/import`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportRefSuppliers(): Promise<boolean> {
        try {
            const response = await this.service.get(`/ref-supplier/export`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('ref_suppliers', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putRefSupplier(ref_supplier: RefSupplierDocument, ref_supplier_id: number): Promise<Specie> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(ref_supplier)
            const response = await this.service.put('/ref-supplier/' + ref_supplier_id, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getSuppliers(): Promise<Array<Supplier>> {
        try {
            const response = await this.service.get(`/supplier`)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getSupplierById(id: number): Promise<Supplier> {
        try {
            const response = await this.service.get(`/supplier/${id}`)
            const data: Supplier = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteSupplier(supplier_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete(`/supplier/${supplier_id}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importSuppliers(labId: number, file: File): Promise<Array<Supplier>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)

            const response = await this.service.post(`/supplier/${labId}/import`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportSuppliers(labId: number): Promise<boolean> {
        try {
            const response = await this.service.get(`/supplier/${labId}/export`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('suppliers', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postSupplier(supplier: SupplierDocument): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(supplier)
            const response = await this.service.post('/supplier', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putSupplier(supplier: SupplierDocument, supplier_id: number): Promise<Specie> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(supplier)
            const response = await this.service.put('/supplier/' + supplier_id, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getSpecies(): Promise<Array<Specie>> {
        try {
            const response = await this.service.get(`/species`)
            let data: Array<Specie> = ConfigApi.translationArrayToDictionary(response.data)
            data = ConfigApi.priceArrayToDictionaryCompany(data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getSpecieById(id: number): Promise<Specie> {
        try {
            const response = await this.service.get(`/specie/${id}`)
            const data: Specie = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteSpecie(specie_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete(`/species/${specie_id}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postSpecie(test: SpecieDocument): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(test)
            const response = await this.service.post('/species', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putSpecie(specie: SpecieDocument, specie_id: number): Promise<Specie> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(specie)
            const response = await this.service.put('/species/' + specie_id, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }


    public async getTransports(): Promise<Array<Transport>> {
        try {
            const response = await this.service.get(`/ref-transport`)
            let data: Array<Transport> = ConfigApi.translationArrayToDictionary(response.data)
            data = ConfigApi.priceArrayToDictionaryCompany(data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getTransportById(id: number): Promise<Transport> {
        try {
            const response = await this.service.get(`/ref-transport/${id}`)
            const data: Transport = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteTransport(ref_transport_id: number): Promise<boolean> {
        try {
            const response = await this.service.delete(`/ref-transport/${ref_transport_id}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postTransport(transport: TransportDocument): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(transport)
            const response = await this.service.post('/ref-transport', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putTransport(transport: TransportDocument, ref_transport_id: number): Promise<Transport> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(transport)
            const response = await this.service.put('/ref-transport/' + ref_transport_id, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getStatus(type?: string): Promise<Array<Status>> {
        try {
            const encodedType = type ? encodeURIComponent(type) : ''
            const response = await this.service.get(`/status` + (type ? `?type=${encodedType}` : ''))
            const data: Array<Status> = ConfigApi.translationArrayToDictionary(response.data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getFields(type_code?: string): Promise<Array<Fields>> {
        try {
            const encodedType_code = type_code ? encodeURIComponent(type_code) : ''
            const response = await this.service.get(type_code ? `/fields/?type_code=${encodedType_code}` : `/fields`)
            const data: Array<Fields> = ConfigApi.translationArrayToDictionary(response.data)
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteFields(Fields_id: number): Promise<boolean> {
        try {
            const response = await this.service.post('/fields/' + Fields_id + '/disable')
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }
    public async activateFields(Fields_id: number): Promise<boolean> {
        try {
            const response = await this.service.post('/fields/' + Fields_id + '/enable')

            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postFields(field: FieldsDocPutPost): Promise<Fields> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(field)
            const response = await this.service.post('/fields', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putField(field: FieldsDocPutPost, fieldId: number): Promise<Fields> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(field)
            const response = await this.service.put('/fields/' + fieldId, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importRuleAnalyteTestThresholds(type: string, file: File, labId: number): Promise<Array<TestThresholdsExcel>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)

            const response = await this.service.post(`/results/rules/thresholds/${labId}/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportRuleAnalyteTestThresholds(type: string, labId: number): Promise<boolean> {
        try {
            const response = await this.service.get(`/results/rules/thresholds/${labId}/export?type=${type}`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('test_thresholds_' + type, 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getThresholds(lab_Id?: number): Promise<Threshold> {
        try {
            const response = await this.service.get(`/results/rules/thresholds/${lab_Id}`)
            const data = response.data
            return {laboratory_id: data.laboratory_id, rule: data.rule.map((e: any) => ({
                ruleId: e.id,
                code_test: e.code_test,
                code_analyte: e.code_analyte,
                order: e.order,
                crit_results_pattern: e.crit_results_pattern,
                crit_typecode: e.crit_typecode,
                crit_family: e.crit_family,
                min_value: e.min_value,
                max_value: e.max_value,
                result_type: e.result_type,
                thresholds: e.thresholds
            }))} as Threshold
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putThresholds(thresholds: Threshold, lab_Id: number): Promise<Threshold> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const formatedThresholds = {laboratory_id: thresholds.laboratory_id, rule: thresholds.rule.map((e) => ({
                code_test: e.code_test,
                code_analyte: e.code_analyte,
                order: e.order,
                crit_results_pattern: e.crit_results_pattern,
                crit_typecode: e.crit_typecode,
                crit_family: e.crit_family,
                min_value: e.min_value,
                max_value: e.max_value,
                result_type: e.result_type,
                thresholds: e.thresholds
            }))}

            const data = JSON.stringify(formatedThresholds)
            const response = await this.service.put(`/results/rules/thresholds/${lab_Id}`, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getRuleResultsPatterns(lab_Id?: number): Promise<RuleResultsPatterns> {
        try {
            const response = await this.service.get(`/results/rules/patterns/${lab_Id}`)
            const data: RuleResultsPatterns = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putRuleResultsPatterns(
        ruleResultsPatterns: RuleResultsPatterns,
        lab_Id: number
    ): Promise<RuleResultsPatterns> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(ruleResultsPatterns)
            const response = await this.service.put(`/results/rules/patterns/${lab_Id}`, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getForms(formFilter: FormFilter): Promise<Array<Form>> {
        try {
            const encodedlaboratory_id = formFilter.laboratory_id ? encodeURIComponent(formFilter?.laboratory_id) : ''
            const encodedType_code = formFilter.type_code ? encodeURIComponent(formFilter?.type_code) : ''

            const conditionLaboratory_id = formFilter.laboratory_id ? `?laboratory_id=${encodedlaboratory_id}` : ''
            const conditionType_code = formFilter.type_code ? `?type_code =${encodedType_code}` : ''

            const response = await this.service.get(`/forms/${conditionLaboratory_id}${conditionType_code}`)
            const data: Array<Form> = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getForm(formFilter: FormFilter): Promise<Form> {
        try {
            const encodedlaboratory_id = formFilter.laboratory_id ? encodeURIComponent(formFilter?.laboratory_id) : ''
            const encodedType_code = formFilter.type_code ? encodeURIComponent(formFilter?.type_code) : ''

            const response = await this.service.get(`/forms/${encodedlaboratory_id}/${encodedType_code}`)
            const data: Form = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postForm(Form: FormRequest, type_code: string, laboratory_id: number): Promise<Form> {
        try {
            const encodedlaboratory_id = laboratory_id ? encodeURIComponent(laboratory_id) : ''
            const encodedType_code = type_code ? encodeURIComponent(type_code) : ''

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(Form)
            const response = await this.service.post(`/forms/${encodedlaboratory_id}/${encodedType_code}`, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putForm(Form: FormRequest, type_code: string, laboratory_id: number): Promise<Form> {
        try {
            const encodedlaboratory_id = laboratory_id ? encodeURIComponent(laboratory_id) : ''
            const encodedType_code = type_code ? encodeURIComponent(type_code) : ''

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(Form)
            const response = await this.service.put(`/forms/${encodedlaboratory_id}/${encodedType_code}`, data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteForm(Form: Form): Promise<boolean> {
        try {
            const encodedlaboratory_id = Form.laboratory_id ? encodeURIComponent(Form?.laboratory_id) : ''
            const encodedType_code = Form.type_code ? encodeURIComponent(Form?.type_code) : ''

            const response = await this.service.delete(`/forms/${encodedlaboratory_id}/${encodedType_code}`)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportRefAnalyte(): Promise<boolean> {
        try {
            const response = await this.service.get('/results/ref-analytes/export', {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('ref-analytes', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportAutocalcResultsFilter(): Promise<boolean> {
        try {
            const response = await this.service.get('/results/autocalc-results-filter/export', {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('autocalc-results-filter', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    //results_patterns

    //test_categories
    public async postResultsPatterns(resultsPatterns: ResultsPatterns): Promise<ResultsPatterns> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            const data = JSON.stringify(resultsPatterns)
            const response = await this.service.post('/results/patterns', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getResultsPattern(lab_id: number, name: string): Promise<ResultsPatterns> {
        try {
            const response = await this.service.get(`/results/patterns/${lab_id}/${encodeURIComponent(name)}`)
            const data: ResultsPatterns = response.data
            data.id = getKeyForTabAssocResultsPatterns(data) // ajout d'un id pour le front
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putResultsPatterns(
        resultsPatterns: ResultsPatterns,
        resultsPatternsKey: ResultsPatternsKey
    ): Promise<ResultsPatterns> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }

            const data = JSON.stringify(resultsPatterns)
            const response = await this.service.put(
                `/results/patterns/${resultsPatternsKey.laboratory_id}/${encodeURIComponent(resultsPatternsKey.name)}`,
                data,
                config
            )

            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getResultsPatterns(): Promise<Array<ResultsPatterns>> {
        try {
            const response = await this.service.get('/results/patterns')
            const data: Array<ResultsPatterns> = response.data
            //add id for front
            data.forEach((dataElem) => {
                dataElem.id = getKeyForTabAssocResultsPatterns(dataElem)
            })

            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteResultsPattern(lab_id: number, name: string): Promise<boolean> {
        try {
            const response = await this.service.delete(`/results/patterns/${lab_id}/${encodeURIComponent(name)}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    // base_tests

    public async importBaseTests(file: File, lab_id: number): Promise<Array<BaseTests>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)

            const response = await this.service.post(`/admin/results/base-tests/${lab_id}/import`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportBaseTests(lab_id: number): Promise<boolean> {
        try {
            const response = await this.service.get(`/admin/results/base-tests/${lab_id}/export`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('base_tests', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postBaseTests(baseTest: BaseTests): Promise<BaseTests> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify

            const data = JSON.stringify(baseTest)

            const response = await this.service.post('/results/base-tests', data, config)
            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getBaseTests(
        lab_id: number,
        pattern: string,
        typecode: string,
        family_id: number
    ): Promise<BaseTests> {
        try {
            const response = await this.service.get(`/results/base-tests/${lab_id}/${encodeURIComponent(pattern)}/${encodeURIComponent(typecode)}/${family_id}`)
            const data: BaseTests = response.data
            data.id = getKeyForTabAssocBaseTests(data) // ajout d'un id pour le front
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putBaseTests(baseTests: BaseTests, baseTestsKey: BaseTestsKey): Promise<BaseTests> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(baseTests)

            const response = await this.service.put(
                `/results/base-tests/${baseTestsKey.laboratory_id}/${encodeURIComponent(baseTestsKey.results_pattern_name)}/${encodeURIComponent(baseTestsKey.type_code)}/${baseTestsKey.family_id}`,
                data,
                config
            )

            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getAllBaseTests(): Promise<Array<BaseTests>> {
        try {
            const response = await this.service.get('/results/base-tests')
            const data: Array<BaseTests> = response.data
            //add id for front
            data.forEach((dataElem) => {
                dataElem.id = getKeyForTabAssocBaseTests(dataElem)
            })

            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteBaseTests(
        lab_id: number,
        pattern: string,
        typecode: string,
        family_id: number
    ): Promise<boolean> {
        try {
            const response = await this.service.delete(
                `/results/base-tests/${lab_id}/${encodeURIComponent(pattern)}/${encodeURIComponent(typecode)}/${family_id}`
            )
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    //test_categories
    public async postTestCategory(category: TestCategory): Promise<TestCategory> {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
        const data = JSON.stringify(category)

        const response = await this.service.post('/results/categories', data, config)
        return response.data
    }

    public async getTestCategory(lab_id: number, pattern: string, code: string): Promise<TestCategory> {
        try {
            const response = await this.service.get(`/results/categories/${lab_id}/${encodeURIComponent(pattern)}/${encodeURIComponent(code)}`)
            const data: TestCategory = response.data
            data.id = getKeyForTabAssocTestCategory(data) // ajout d'un id pour le front
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putTestCategories(categories: TestCategoryDocument[], laboratoryId: number): Promise<TestCategory[]> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(categories)
            const response = await this.service.put(`/results/categories/${laboratoryId}`, data, config)

            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getTestCategories(laboratoryId: number): Promise<Array<TestCategory>> {
        try {
            const response = await this.service.get(`/results/categories?lab_id=${laboratoryId}`)
            const data: Array<TestCategory> = response.data
            //add id for front
            data.forEach((dataElem) => {
                dataElem.id = getKeyForTabAssocTestCategory(dataElem)
            })

            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteTestCategory(lab_id: number, pattern: string, code: string): Promise<boolean> {
        try {
            const response = await this.service.delete(`/results/categories/${lab_id}/${encodeURIComponent(pattern)}/${encodeURIComponent(code)}`)
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    // analytes
    public async importAnalyte(file: File, lab_id: number, type: AnalyteExportType): Promise<Array<Analyte>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/results/analytes/${lab_id}/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getAnalyte(lab_id: number, code_test: string, code_analyte: string): Promise<Analyte> {
        try {
            const response = await this.service.get(`/results/analytes/${lab_id}/${encodeURIComponent(code_test)}/${encodeURIComponent(code_analyte)}`)
            const data: Analyte = response.data
            data.id = getAnalyteKey(data) // ajout d'un id pour le front
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportAnalyte(lab_id: number, type: AnalyteExportType): Promise<boolean> {
        try {
            const response = await this.service.get(`/results/analytes/${lab_id}/export?type=${type}`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName(`analyte-tests_${type}_${lab_id}`, 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getAnalytes(): Promise<Array<Analyte>> {
        try {
            const response = await this.service.get('/results/analytes')
            const data: Array<Analyte> = response.data
            data.forEach((dataElem) => {
                dataElem.id = getAnalyteKey(dataElem)
            })

            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteAnalyte(laboratory_id: number, code_test: string, code_analyte: string): Promise<boolean> {
        try {
            const response = await this.service.delete(
                `/results/analytes/${laboratory_id}/${encodeURIComponent(code_test)}/${encodeURIComponent(code_analyte)}`
            )
            return response.data.response
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

// client denomination 
public async importProductDenominations(lab_id: number, file: File): Promise<Array<ProductDenomination>> {
    try {
        const formData = new FormData()
        formData.append('file', file, file.name)
        const response = await this.service.post(`/product-denomination/${lab_id}/import`, formData)
        return response.data || []
    } catch (err) {
        throw AbstractApi.handleError(err)
    }
}

public async exportProductDenominations(lab_id: number): Promise<boolean> {
    try {
        const response = await this.service.get(`/product-denomination/${lab_id}/export`, {
            responseType: 'blob',
        })

        this.downloadBlob(response.data, this.getExportedFileName('product-denomination', 'xlsx'))
        return true
    } catch (err) {
        throw AbstractApi.handleError(err)
    }
}

public async getProductDenominations(): Promise<Array<ProductDenomination>> {
    try {
        const response = await this.service.get(`/product-denomination`)
        const data: Array<ProductDenomination> = response.data
        return data
    } catch (err) {
        throw AbstractApi.handleError(err)
    }
}

public async getProductDenominationsByLaboratory(lab_id: number): Promise<Array<ProductDenomination>> {
    try {
        const response = await this.service.get(`/product-denomination/${lab_id}`)
        const data: Array<ProductDenomination> = response.data
        return data
    } catch (err) {
        throw AbstractApi.handleError(err)
    }
}

public async getProductDenominationsById(lab_id: number, id: number): Promise<Array<ProductDenomination>> {
    try {
        const response = await this.service.get(`/product-denomination/${lab_id}/${id}`)
        const data: Array<ProductDenomination> = response.data
        return data
    } catch (err) {
        throw AbstractApi.handleError(err)
    }
}

public async desactivateProductDenomination(lab_id: number, id: number): Promise<boolean> {
    try {

        const response = await this.service.delete(`/product-denomination/${lab_id}/${id}`)
        return response.data.response
    } catch (err) {
        throw AbstractApi.handleError(err)
    }
}

    //autocalc-results-filter
    public async importAutocalcResultsFilter(file: File): Promise<Array<AutocalcResultsFilter>> {
        const formData = new FormData()

        formData.append('file', file, file.name)
        try {
            const response = await this.service.post(`/results/autocalc-results-filter/import`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }
    public async getAutocalcResultsFilterById(laboratory_id: string): Promise<AutocalcResultsFilter> {
        try {
            const response = await this.service.get(`/results/autocalc-results-filter/${laboratory_id}`)
            const data: AutocalcResultsFilter = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getAutocalcResultsFilter(): Promise<Array<AutocalcResultsFilter>> {
        try {
            const response = await this.service.get('/results/autocalc-results-filter')
            const data: Array<AutocalcResultsFilter> = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    //  Autocalc Analytes
    public async getAutocalcAnalytes(): Promise<Array<AutocalcAnalyte>> {
        try {
            const response = await this.service.get(
                `/admin/autocalc_analytes`
            )
            const data: Array<AutocalcAnalytesApi> = response.data
            return convertAutocalcAnalytesApiToAutocalcAnalytes(data)
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }
    public async getAutocalcAnalytesById(lab_id: number): Promise<Array<AutocalcAnalyte>> {
        try {
            const response = await this.service.get(
                `/admin/autocalc_analytes/${lab_id}`
            )
            const data: Array<AutocalcAnalytesApi> = response.data
            return convertAutocalcAnalytesApiToAutocalcAnalytes(data)
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }
    public async exportAutocalcAnalytes(): Promise<boolean> {
        try {
            const response = await this.service.get(`/admin/autocalc_analytes/export`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('AutocalcAnalytes', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }
    public async importAutocalcAnalytes(file: File): Promise<Array<AutocalcAnalyte>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/admin/autocalc_analytes/import`, formData)
            const data: Array<AutocalcAnalytesApi> = response.data || []
            return convertAutocalcAnalytesApiToAutocalcAnalytes(data)
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    //ref-analytes
    public async importRefAnalyte(file: File): Promise<Array<RefAnalyte>> {
        const formData = new FormData()

        formData.append('file', file, file.name)
        try {
            const response = await this.service.post(`/results/ref-analytes/import`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getRefAnalyte(id: string): Promise<RefAnalyte> {
        try {
            const response = await this.service.get(`/results/ref-analytes/${id}`)
            const data: RefAnalyte = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getRefAnalytes(): Promise<Array<RefAnalyte>> {
        try {
            const response = await this.service.get('/results/ref-analytes')
            const data: Array<RefAnalyte> = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }
    //Ref Units
    public async getRefUnitsWithConversion(): Promise<Array<RefUnit>> {
        try {
            const response = await this.service.get('/admin/ref-units')
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getUnitsConversion(source_unit: string, target_unit: string): Promise<UnitsConversion | null> {
        try {
            const response = await this.service.get(`/admin/ref-units/conversions/${encodeURIComponent(source_unit)}/${encodeURIComponent(target_unit)}`)
            const data: UnitsConversion | null = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importRefUnits(type: RefUnitsType, file: File): Promise<Array<RefUnit>> {
        const formData = new FormData()

        formData.append('file', file, file.name)
        try {
            const response = await this.service.post(`/admin/ref-units/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportRefUnits(type: RefUnitsType): Promise<boolean> {
        try {
            const response = await this.service.get(`/admin/ref-units/export?type=${type}`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName(`ref-units-${type}`, 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    //rule results pattern tests

    public async getRulesResultsPatternTests(): Promise<Array<RuleResultsPatternTests>> {
        try {
            const response = await this.service.get('/results/rules/pattern-tests')
            const data: Array<RuleResultsPatternTests> = response.data
            data.forEach((dataElem) => {
                dataElem.id = getKeyForTabAssocRuleResultsPatternTests(dataElem)
            })
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }
    public async importRuleResultsPatternTests(file: File, lab_id: number): Promise<Array<RuleResultsPatternTests>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)

            const response = await this.service.post(`/results/rules/pattern-tests/import?lab_id=${lab_id}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getRuleResultPatternTest(lab_id: number): Promise<RuleResultsPatternTests> {
        try {
            const response = await this.service.get(`/results/rules/pattern-tests/${lab_id}`)
            const data: RuleResultsPatternTests = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportRuleResultPatternTest(lab_id: number): Promise<boolean> {
        try {
            const response = await this.service.get(`/results/rules/pattern-tests/export?lab_id=${lab_id}`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('rule-results-pattern_tests', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putPriceLists(categories: PriceListList, laboratoryId: number): Promise<PriceListList> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
            const data = JSON.stringify(categories)
            const response = await this.service.put(`/laboratory/price_lists/${laboratoryId}`, data, config)

            return response.data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getPriceLists(laboratoryId: number): Promise<PriceListList> {
        try {
            const response = await this.service.get(`/laboratory/price_lists/${laboratoryId}`)
            const data: PriceListList = response.data

            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }


    public async getRelationships(laboratoryId: number, type: RelationShipType): Promise<PatternsFamilies | PackagesPatterns | AnalyteTestsPackages> {
        try {
            const response = await this.service.get(`/laboratory/relationships/${laboratoryId}/${type}`)
            const data: PatternsFamilies | PackagesPatterns | AnalyteTestsPackages = response.data

            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteRelationship(laboratoryId: number, relationship: PatternFamily | PackagePattern | AnalyteTestPackage) {
        try {
            const { laboratory_id, ... relationshipWithoutLabId} = relationship
            const queryParams =qs.stringify(relationshipWithoutLabId, {skipNulls: true})
           await this.service.delete(`/laboratory/relationships/${laboratoryId}?${queryParams}`)

           return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importRelationShips(file: File, laboratoryId: number, type: RelationShipType): Promise<Array<Analyte>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/laboratory/relationships/${laboratoryId}/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportRelationShips(laboratoryId: number, type: RelationShipType): Promise<boolean> {
        try {
            const response = await this.service.get(`/laboratory/relationships/${laboratoryId}/export?type=${type}`, {
                responseType: 'blob',
            })
    
            this.downloadBlob(response.data, this.getExportedFileName(`patterns_${type}_${laboratoryId}`, 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importPatterns(file: File, laboratoryId: number, type: PatternsExportType): Promise<Array<Analyte>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/pattern/${laboratoryId}/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportPatterns(laboratoryId: number, type: PatternsExportType): Promise<boolean> {
        try {
            const response = await this.service.get(`/pattern/${laboratoryId}/export?type=${type}`, {
                responseType: 'blob',
            })
    
            this.downloadBlob(response.data, this.getExportedFileName(`patterns_${type}_${laboratoryId}`, 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importPackages(file: File, laboratoryId: number, type: PackagesExportType): Promise<Array<Analyte>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/package/${laboratoryId}/import?type=${type}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportPackages(laboratoryId: number, type: PackagesExportType): Promise<boolean> {
        try {
            const response = await this.service.get(`/package/${laboratoryId}/export?type=${type}`, {
                responseType: 'blob',
            })
    
            this.downloadBlob(response.data, this.getExportedFileName(`packages_${type}_${laboratoryId}`, 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    } 

    public async getTargetValues(): Promise<Array<TargetValues>> {
        try {
            const response = await this.service.get('/admin/target-values')
            const data: Array<TargetValues> = response.data
            data.forEach((dataElem) => {
                dataElem.id = getKeyForTabAssocTargetValues(dataElem)
            })
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }
    public async importTargetValues(file: File, lab_id: number): Promise<Array<TargetValues>> {
        try {
            const formData = new FormData()
            formData.append('file', file, file.name)

            const response = await this.service.post(`/admin/target-values/import/${lab_id}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportTargetValues(lab_id: number): Promise<boolean> {
        try {
            const response = await this.service.get(`/admin/target-values/export/${lab_id}`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('target_values', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteTargetValues(targetValues: TargetValues ): Promise<void> {
        try {
            const config = {
                params: {
                    laboratory_id : encodeURIComponent(targetValues.laboratory_id),
                    company_id : encodeURIComponent(targetValues.company_id),
                    type_code : encodeURIComponent(targetValues.type_code),
                    ref_field_id : encodeURIComponent(targetValues.ref_field_id),
                    ref_field_value : encodeURIComponent(targetValues.ref_field_value),
                    analyte_id : encodeURIComponent(targetValues.analyte_id),
                    starting_date : encodeURIComponent(targetValues.starting_date.toString()),
                }
            }
            await this.service.delete(`/admin/target-values`,config)
            return
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    private downloadBlob(blob: string, fileName: string) {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
    }

    private getExportedFileName(tableName: string, extension: string) {
        const date = new Date()
        return `${tableName}_export_${moment(date).format('YYYYMMDD-HHmm')}.${extension}`
    }
}

export default ConfigApi
