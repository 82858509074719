import { ReactComponent as RefreshIcon } from 'bootstrap-icons/icons/arrow-clockwise.svg';
import {ReactComponent as SearchIcon} from 'bootstrap-icons/icons/search.svg'
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Badge, Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import { RootState } from '../../../state/rootReducer';
import { AppDispatch } from '../../../state/store';
import { fetchMatrices, filterReducer } from '../../../state/matrixSlice';
import MatrixList from './components/MatrixList';
import { fetchAllCompanies } from '../../../state/companiesSlice';
import { fetchEmployees } from '../../../state/employeesSlice';
import { fetchAllDashboardPreferences } from '../../../state/dashboardPreferencesSlice';


const MatrixListContainer: React.FC<WithTranslation> = ({t}) => {
    type InputEvent = ChangeEvent<HTMLInputElement>
    type ChangeHandler = (e: InputEvent) => void
    
    const dispatch = useDispatch<AppDispatch>()
    const { user: authUser } = useSelector( (state: RootState) => state.authUser )

    // matrix state
    const matrixStatus = useSelector( (state: RootState) => state.matrix.matrix.fetchStatus )
    const matrixError = useSelector( (state: RootState) => state.matrix.matrix.fetchError )
    const pagination = useSelector((state: RootState) => state.matrix.matrix.pagination)

    //preferences states
    const preferencesStatus = useSelector((state: RootState) => state.preferences.fetchStatus)

    // company state
    const companyStatus = useSelector( (state: RootState) => state.companies.fetchStatus )

    // employee state
    const employeeStatus = useSelector( (state: RootState) => state.employees.fetchStatus )

    useEffect(() => {
        if (companyStatus === 'idle') {
            dispatch(fetchAllCompanies())
        }
        if (employeeStatus === 'idle') {
            dispatch(fetchEmployees())
        }
        if (preferencesStatus === 'idle') {
            dispatch(fetchAllDashboardPreferences())
        }
    }, [dispatch, companyStatus, employeeStatus])

    useEffect(() => {
        if (pagination.filterBy && pagination.status === 'idle') {
            dispatch(fetchMatrices({page: 1}))
        }
    }, [dispatch, pagination.filterBy, pagination.sortBy, pagination.status])

    const [filter, setFilter] = useState<string>(pagination.filterBy.all && pagination.filterBy.all.like ? pagination.filterBy.all.like : '')

    //Catch event 'Enter' key
    function KeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            triggerFilter()
        }
    }

    function triggerFilter() {
        dispatch(filterReducer(filter))
    }
    
    const onFilterChanged: ChangeHandler = e => {
        setFilter(e.target.value)
    }

    return (
        <Container className="justify-content-center container-xxl" >

            <Row className="mt-3 me-auto" >
                <Col xs={6} md={7} lg={8}>
                    <h5 className="d-inline">
                        <Badge pill bg="primary">{Intl.NumberFormat(authUser.locale).format(pagination.count)}</Badge> 
                        <span className="ms-2 align-text-bottom fw-bold">{t('matrix.matrix.title')}</span>
                    </h5>
                </Col>
                <Col xs={5} md={4} lg={4}>
                    <Row className="justify-content-end">
                        <Col xs={"auto"} md={9} lg={8} className="flex-fill">
                            <InputGroup>
                                <FormControl 
                                    size="sm"
                                    placeholder={t('matrix.matrix.search_placeholder')}
                                    aria-label={t('matrix.matrix.search_button')}
                                    onChange={onFilterChanged}
                                    onKeyDown={KeyDown}
                                    value={filter}
                                />
                                <Button size="sm" variant="secondary" onClick={ () => {triggerFilter()}}>
                                    <SearchIcon width="20px" height="100%" className="align-text-top"/>
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col xs={"auto"} className="ps-0">
                            <Button size="sm" variant="secondary"
                                onClick={() => {dispatch(fetchMatrices({page: pagination.currentPage}))}}
                            >
                                <RefreshIcon width="20px" height="100%" className="align-text-top" />
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            { (matrixStatus === 'loading' || companyStatus === 'loading' || employeeStatus === 'loading' || preferencesStatus === 'loading') && 
                <Loader />
            }
            { matrixStatus === 'succeeded' && companyStatus === 'succeeded' && employeeStatus === 'succeeded' && preferencesStatus  === 'succeeded' &&
                <MatrixList />   
            }
            { (matrixStatus === 'failed' || companyStatus === 'failed' || employeeStatus === 'failed' || preferencesStatus === 'failed') &&
                <div className="text-danger">{matrixError}</div>
            }
        </Container>
    )
}

export default withTranslation()(MatrixListContainer)

