import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import ConfigApi, {
    PatternFamily,
    PatternsFamilies, getKeyForTabAssocPatternFamily,
} from "../api/configApi";
import {
    buildDefaultExtraReducersWithFrontPagination,
    CurrentState,
    getDefaultReducersWithFrontPagination,
    getInitialState,
} from "./DefaultSlice";
import { Data } from "../types/types";

interface PatternsFamiliesCurrentState<PatternFamily> extends CurrentState<PatternFamily> {
    byPattern: Data<Array<PatternFamily>>
}
const initialState: PatternsFamiliesCurrentState<PatternFamily> = {
    
    ...getInitialState<PatternFamily>(),
    byPattern: {}
    
};

// pattern_families

export const fetchPatternFamilies = createAsyncThunk(
    "config/fetchPatternFamilies",
    async ({ laboratoryId }: { laboratoryId: number }) => {
        const response = await ConfigApi.getInstance().getRelationships(
            laboratoryId,
            'patterns_families'
        );
        return (response as PatternsFamilies).patterns_families
    }
);

export const fetchDeletePatternFamily = createAsyncThunk(
    "config/fetchDeletePatternFamily",
    async (patternFamily: PatternFamily, { dispatch }) => {
        const response = await ConfigApi.getInstance().deleteRelationship(patternFamily.laboratory_id, patternFamily );

        if (!response) {
            throw new Error("Unable to delete pattern family");
        }   

        dispatch(deleteReducer(patternFamily));

        return true;
    }
);




const addItemToPatternFamilies = (
    state: PatternsFamiliesCurrentState<PatternFamily>,
    item: PatternFamily
) => {
        if (item.laboratory_id && item.pattern_id){
            state.data[getKeyForTabAssocPatternFamily(item)] = item;
            state.data[getKeyForTabAssocPatternFamily(item)].id = getKeyForTabAssocPatternFamily(item)
            if (!(state).byPattern[item.pattern_id]){
                (state).byPattern[item.pattern_id] = []
            }
            (state).byPattern[item.pattern_id].push(item)
        }

};

const postDeletePatternFamily = (
    state: CurrentState<PatternFamily>,
    item: PatternFamily
) => {
    delete state.data[getKeyForTabAssocPatternFamily(item)];
    if ((state as PatternsFamiliesCurrentState<PatternFamily>).byPattern[item.pattern_id]){
        ((state as PatternsFamiliesCurrentState<PatternFamily>).byPattern[item.pattern_id].splice((state as PatternsFamiliesCurrentState<PatternFamily>).byPattern[item.pattern_id].indexOf(item), 1))
    }
    state.filtered.splice(
        state.filtered.indexOf(getKeyForTabAssocPatternFamily(item)),
        1
    );
};


const patternFamilySlice = createSlice({
    name: "relationship patternfamilies",
    initialState,
    reducers: {...getDefaultReducersWithFrontPagination(
        addItemToPatternFamilies,
        postDeletePatternFamily  
    ), updatePatternFamily : (state: PatternsFamiliesCurrentState<PatternFamily>, action: PayloadAction<PatternFamily[]>) => {
        state.data = {};
        action.payload.forEach((item: PatternFamily) => {
            addItemToPatternFamilies(state, item);
        });
    }},
    extraReducers: (builder) => {
        buildDefaultExtraReducersWithFrontPagination(
            addItemToPatternFamilies,
            builder,
            fetchPatternFamilies
        );
    },
});

export const {
    updatePatternFamily,
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage,
} = patternFamilySlice.actions;

export default patternFamilySlice.reducer;
