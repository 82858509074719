import React, { useEffect, useState } from 'react'
import { Form, Button, Col, Row, InputGroup, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import { withTranslation, WithTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as DeleteIcon } from 'bootstrap-icons/icons/x-circle.svg'
import { fetchFamilies, fetchLaboratories, fetchLanguages, fetchTypes } from '../../../state/configSlice'
import { RootState } from '../../../state/rootReducer'

import InlineLoader from '../../../common/components/InlineLoader'
import SelectControl from '../../../common/components/SelectControl'
import userHasRequiredRoles from '../../../common/auth/userHasRequiredRoles'
import { setAuthCurLabId } from '../../../state/authUserSlice'
import Select from 'react-select'
import { FieldArray, Formik, FormikProps, getIn } from 'formik'
import { Data, FilterBy } from '../../../types/types'
import moment from 'moment'
import { Company } from '../../../api/usersApi'
import dbt from '../../../common/utils/dbTranslation'
import { Analyte, Family, getAnalyteKey } from '../../../api/configApi'
import { fetchCompanies } from '../../../state/companiesSlice'
import { exportExcelReducer } from '../../../state/exportExcelSlice'
import PublishApi, { ExportRequest } from '../../../api/publishApi'
import ModalExportExcel from './ModalExportExcel'
import { fetchAnalysis, filterByReducer, resetNbResults } from '../../../state/analysisSlice'
import { fetchAnalytes } from '../../../state/analyteSlice'

import AnalysisTableContainer from '../../../common/components/AnalysisTableContainer'
import ExceptionModal from "../../../common/utils/ExceptionModal";
import { AppDispatch } from '../../../state/store'

interface select {
    value: string
    label: string
}

export interface ExportRequestFront {
    laboratoryId: number
    type_codes: Array<string>
    startDate: Date | null
    endDate: Date | null
    companies: Array<number>
    selectedCompany: Company | null
    families: Array<number>
    selectedFamily: Family | null
    analytes: Array<string> // composite key
    selectedAnalyte: Analyte | null,
    analysis_ids: Array<number>
}

const ExportExcelContainer: React.FC<WithTranslation> = ({ t }) => {
    const dispatch = useDispatch<AppDispatch>()
    const { user: authUser, authCurLabId, authCompanies, authCustomers } = useSelector((state: RootState) => state.authUser)
    const isUserAdmin : boolean = userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])
    const nbLimitFilter = 15
    const nbLimitExport = 2
    
    const exportStatus = useSelector((state: RootState) => state.exportResponses.dataStatus);

    // analysis state

    const pagination = useSelector((state: RootState) => state.analysis.analysis.pagination)
    const nbResults = useSelector((state: RootState) => state.analysis.analysis.nbResults)

    // Companies state
    const companies = useSelector((state: RootState) => state.companies.data)
    const companiesStatus = useSelector((state: RootState) => state.companies.fetchStatus)
    const companiesError = useSelector((state: RootState) => state.companies.fetchError)
    const companiesByLabId = useSelector((state: RootState) => state.companies.byLabId)

    // Families state
    const families = useSelector((state: RootState) => state.config.families.data)
    const familiesStatusByLabId = useSelector((state: RootState) => state.config.families.fetchStatusByLabId)
    const familiesError = useSelector((state: RootState) => state.config.families.fetchError)
    const familiesByLabId = useSelector((state: RootState) => state.config.families.byLabId)

    // laboratories state
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    const hasMultipleLabs: boolean = authUser.laboratories.length > 1 || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])
    const [currentLaboratoryId, setCurrentLaboratoryId] = useState(authCurLabId)
    const laboratoriesError = useSelector((state: RootState) => state.config.laboratories.fetchError)
    // Languages state
    const languagesStatus = useSelector((state: RootState) => state.config.languages.fetchStatus)
    const { language, typeLabels } = useSelector((state: RootState) => state.local)


    // Analytes
    const analytes = useSelector((state: RootState) => state.analytes.data)
    const analytesStatus = useSelector((state: RootState) => state.analytes.fetchStatus)
    const analytesError = useSelector((state: RootState) => state.analytes.fetchError)

    // types state
    const types = useSelector((state: RootState) => state.config.types.data)
    const typesError = useSelector((state: RootState) => state.config.types.fetchError)
    const typesStatus = useSelector((state: RootState) => state.config.types.fetchStatus)

   const fetchStatus = useSelector((state: RootState) => state.analysis.analysis.fetchStatus)

   // Employees state
   const employeesStatus = useSelector((state: RootState) => state.employees.fetchStatus)

   // Patterns state
   const patternsStatus = useSelector((state: RootState) => state.config.patterns.fetchStatus)

   // Farmers state
   const farmersStatusByUser = useSelector((state: RootState) => state.farmers.farmer.fetchStatusByUser)

   // Impropers state
   const impropersStatus = useSelector((state: RootState) => state.config.impropers.fetchStatus)

   // pdc state
   const pdcStatus = useSelector((state: RootState) => state.pdc.fetchDAStatus)

    // form
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [apiError, setApiError] = useState<unknown>(undefined)

    const [disableExport, setDisableExport] = useState(true)

    // export modal
    const [showExportExcel, setShowExportExcel] = useState(false)

    const defaultFilterDate = new Date();
    defaultFilterDate.setMonth(defaultFilterDate.getMonth()- 1)

    const handleShowExportExcel = () => setShowExportExcel(true)

    // simulation
    const [showTableSimulation, setShowTableSimulation] = useState(false)
    const handleShowTableSimulation = () => setShowTableSimulation(true)
    const handleHideTableSimulation = () => {
        setShowTableSimulation(false)
        dispatch(resetNbResults())
        setDisableExport(false)
    }


    useEffect(() => {
        if (languagesStatus === 'idle') {
            dispatch(fetchLanguages())
        }

        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }

        if (typesStatus === 'idle') {
            dispatch(fetchTypes())
        }

        if (analytesStatus === 'idle') {
            dispatch(fetchAnalytes())
        }


        if (companiesStatus === "idle" || companiesStatus === "succeeded" && (!companiesByLabId || !companiesByLabId[currentLaboratoryId])) {
            dispatch(fetchCompanies(currentLaboratoryId))
        }

        if (familiesStatusByLabId === "idle" || familiesStatusByLabId === "succeeded" && (!familiesByLabId || !familiesByLabId[currentLaboratoryId])) {
            dispatch(fetchFamilies(currentLaboratoryId))
        }


    }, [languagesStatus, laboratoriesStatus, companiesStatus, analytesStatus, typesStatus, familiesStatusByLabId])

    useEffect(() => {
        if (companiesStatus === "succeeded" &&  (!companiesByLabId || !companiesByLabId[currentLaboratoryId])) {
            dispatch(fetchCompanies(currentLaboratoryId))
        }

        if (familiesStatusByLabId === "succeeded" && (!familiesByLabId || !familiesByLabId[currentLaboratoryId])) {
            dispatch(fetchFamilies(currentLaboratoryId))
        }
    }, [currentLaboratoryId])

    useEffect(() => {
        if (showTableSimulation && pagination.status === 'idle' && pagination.filterBy) {
            setDisableExport(true)
            dispatch(fetchAnalysis({ page: 1 }))
        }
    }, [dispatch, pagination.filterBy, pagination.sortBy, pagination.status])

    return (
        <Container className="justify-content-center">

            <ExceptionModal
                title={t('export_screen.title')}
                message={t('export_screen.errors.msg_failed_export')}
                error={apiError}
                onHide={() => {
                    setApiError(undefined)
                }}
            />
            <Row className="mt-2">
                <Col>
                    <h4 className="fw-bold">{t('export_screen.title')}</h4>
                </Col>
            </Row>

            <Container className="p-0">
                {!formSubmitted && (
                    <Formik
                        initialValues={{
                            companies: [],
                            selectedCompany: null,
                            startDate: defaultFilterDate,
                            endDate: new Date(),
                            laboratoryId: currentLaboratoryId,
                            families: [],
                            selectedFamily: null,
                            type_codes: [],
                            analytes: [],
                            selectedAnalyte: null,
                            analysis_ids: []
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            // When button submits form and form is in the process of submitting, submit button is disabled
                            setSubmitting(true)
                            setApiError(undefined)
                        }}
                    >
                        {/* Callback function containing Formik state and helpers that handle common form actions */}
                        {(props: FormikProps<ExportRequestFront>) => {
                            const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setValues, isSubmitting } = props

                            const handleExportExcel = () => {
                                return async (data: ExportRequest) => {
                                    try {
                                        setApiError(undefined)

                                        dispatch(exportExcelReducer(await PublishApi.getInstance().postExportJob(data)))
                                    }

                                    catch (err) {

                                        setApiError(err)
                                    }
                                }
                            }

                            const preview = async (values: ExportRequestFront) => {
                                const filterBy: FilterBy = {
                                    laboratory: {
                                        in: [values.laboratoryId],
                                    },
                                    type_code: {
                                        in: values.type_codes.length > 0 ? values.type_codes : undefined,
                                    },
                                    company: {
                                        in: values.companies.length > 0 ? values.companies : undefined,
                                    },
                                    family: {
                                        in: values.families.length > 0 ? values.families : undefined,
                                    },
                                    date: {
                                        lte: values.endDate ? new Date(values.endDate).toISOString() : undefined,
                                        gte: values.startDate ? new Date(values.startDate).toISOString() : undefined,
                                    },
                                    nutrient: {
                                        in: values.analytes,
                                    },
                                    result_number: {
                                        bool: true,
                                    },
                                    status: {
                                        in: ['partial', 'done']
                                    }
                                }

                                dispatch(filterByReducer(filterBy))
                                handleShowTableSimulation()
                            }

                            return (
                                <Container className="p-0">

                                    <ModalExportExcel exportRequestFront={values} setShowExportExcel={setShowExportExcel} showExportExcel={showExportExcel} submitValues={handleExportExcel()} nbResults={nbResults} />
                                    <Form onSubmit={handleSubmit}>
                                        <Container className="formbox">
                                            <Row className="mt-2">
                                                {hasMultipleLabs &&

                                                    <Col xs={3} md={3} lg={3}>
                                                        <Row className="mt-2">
                                                            <Col xs={4} md={4} lg={4}>
                                                                <Form.Label htmlFor="formAnalysisID" className="pt-1">
                                                                    {t('export_screen.forms.laboratory')}
                                                                </Form.Label>

                                                            </Col>

                                                            <Col xs={8} md={8} lg={8}>
                                                                {laboratoriesStatus === 'loading' && <InlineLoader />}
                                                                {laboratoriesStatus === 'succeeded' && 
                                                                <SelectControl
                                                                    name="laboratories"
                                                                        items={Object.fromEntries(Object.values(laboratories).filter(l => isUserAdmin ||  authUser.role_levels.find(r => r.laboratory_id === l.id) !== undefined).map(l => ([l.id, l])))}
                                                                    currentItemId={currentLaboratoryId}
                                                                    onChange={async (e) => {
                                                                        setCurrentLaboratoryId(Number(e.target.value))

                                                                        const updatedValues = { ...values }
                                                                        updatedValues.companies = []
                                                                        updatedValues.families = []
                                                                        updatedValues.analytes = []
                                                                        updatedValues.laboratoryId = Number(e.target.value)
                                                                        updatedValues.selectedCompany = null
                                                                        updatedValues.selectedFamily = null
                                                                        updatedValues.selectedAnalyte = null
                                                                        setValues(updatedValues)
                                                                        handleHideTableSimulation()

                                                                        dispatch(setAuthCurLabId(Number(e.target.value)))
                                                                    }}
                                                                />
                                                                }

                                                                {laboratoriesStatus === 'failed' && <div>{laboratoriesError}</div>}
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                }



                                                <Col xs={hasMultipleLabs ? 3 : 4} md={hasMultipleLabs ? 3 : 4} lg={hasMultipleLabs ? 3 : 4} className={hasMultipleLabs ? "text-center text-lg-end" : ""}>
                                                    <Row className="mt-2">
                                                        <Col xs={3} md={3} lg={3}>
                                                            <Form.Label htmlFor="formAnalysisID" className="pt-1">
                                                                {t('export_screen.forms.type')}
                                                            </Form.Label>
                                                        </Col>

                                                        <Col xs={9} md={9} lg={9}>
                                                            <Form.Group controlId="formType">
                                                                <Select
                                                                    noOptionsMessage={() => t('admin.fields.form.notype')}
                                                                    value={Object.values(values.type_codes).map((t) => ({ value: t, label: t }))}
                                                                    multiple={true}
                                                                    placeholder={t('export_screen.selects.type')}
                                                                    getOptionLabel={(keys: select) => keys.label}
                                                                    getOptionValue={(keys: select) => keys.value}
                                                                    options={Object.keys(typeLabels).map((t) => ({
                                                                        value: t,
                                                                        label: t,
                                                                    }))}
                                                                    isMulti
                                                                    isClearable
                                                                    onChange={async (typesCode: select[]) => {
                                                                        if (typesCode.length !== 0) {
                                                                            setFieldValue('families', values.families.filter((family) => Object.values(types).filter((type) => (type.active && type.laboratory_id === currentLaboratoryId && typesCode.map((item: select) => item['value']).includes(type.code))).map((type) => (type.id)).includes(families[family].type_id)))
                                                                        }
                                                                        setFieldValue( 'type_codes',typesCode.map((item: select) => item['value']))
                                                                        handleHideTableSimulation()

                                                                    }}
                                                                    styles={{
                                                                        control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                                            ...styles,
                                                                            borderColor: getIn(touched, 'type_codes') && typeof getIn(errors, 'type_codes') === 'string' ? '#dc3545' : state.isFocused ? '#02e9ff' : '#ced4da',
                                                                            boxShadow: state.isFocused
                                                                                ? getIn(touched, 'type_codes') && typeof getIn(errors, 'type_codes') === 'string'
                                                                                    ? '0 0 0 0.2rem rgb(158 43 47 / 25%);'
                                                                                    : `0 0 0 0.2rem rgb(0 118 129 / 25%);`
                                                                                : 'none',
                                                                            '&:hover': {
                                                                                borderColor: getIn(touched, 'type_codes') && typeof getIn(errors, 'type_codes') === 'string' ? '#dc3545' : state.isFocused ? '#02e9ff' : '#ced4da',
                                                                                boxShadow: state.isFocused
                                                                                    ? getIn(touched, 'type_codes') && typeof getIn(errors, 'type_codes') === 'string'
                                                                                        ? '0 0 0 0.2rem rgb(158 43 47 / 25%);'
                                                                                        : `0 0 0 0.2rem rgb(0 118 129 / 25%);`
                                                                                    : 'none',
                                                                            },
                                                                        }),
                                                                        menu: (styles: Data<string>) => ({
                                                                            ...styles,
                                                                            zIndex: '1031',
                                                                        }),
                                                                    }}
                                                                />
                                                                <Form.Text className="invalid-feedback d-block">{errors.type_codes}</Form.Text>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col xs={hasMultipleLabs ? 3 : 4} md={hasMultipleLabs ? 3 : 4} lg={hasMultipleLabs ? 3 : 4} className={hasMultipleLabs ? "text-center text-lg-end" : ""}>
                                                    <Row className="mt-2">
                                                        <Col xs={5} md={5} lg={5}>
                                                            <Form.Label htmlFor="formAnalysisID" className="pt-1">
                                                                {t('export_screen.forms.start_date')}
                                                            </Form.Label>
                                                        </Col>

                                                        <Col xs={7} md={7} lg={7}>
                                                            <Form.Group className="mb-2" controlId="formStartDate">
                                                                <Form.Control
                                                                    isInvalid={getIn(touched, 'startDate') && !!getIn(errors, 'startDate')}
                                                                    type="date"
                                                                    name="startDate"
                                                                    maxLength={255}
                                                                    value={values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : undefined}
                                                                    onChange={async (e) => {
                                                                        handleChange(e)
                                                                        handleHideTableSimulation()
                                                                    }}

                                                                    onBlur={e => {
                                                                        handleBlur(e)

                                                                    }}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{getIn(errors, 'startDate')}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col xs={hasMultipleLabs ? 3 : 4} md={hasMultipleLabs ? 3 : 4} lg={hasMultipleLabs ? 3 : 4} className={hasMultipleLabs ? "text-center text-lg-end" : ""}>
                                                    <Row className="mt-2">
                                                        <Col xs={5} md={5} lg={5}>
                                                            <Form.Label htmlFor="formAnalysisID" className="pt-1">
                                                                {t('export_screen.forms.end_date')}
                                                            </Form.Label>
                                                        </Col>

                                                        <Col xs={7} md={7} lg={7}>
                                                            <Form.Group className="mb-2" controlId="endDate">
                                                                <Form.Control
                                                                    isInvalid={getIn(touched, 'endDate') && !!getIn(errors, 'endDate')}
                                                                    type="date"
                                                                    name="endDate"
                                                                    maxLength={255}
                                                                    value={values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : undefined}
                                                                    onChange={async (e) => {
                                                                        handleChange(e)
                                                                        handleHideTableSimulation()
                                                                    }}

                                                                    onBlur={e => {
                                                                        handleBlur(e)

                                                                    }}

                                                                />
                                                                <Form.Control.Feedback type="invalid">{getIn(errors, 'endDate')}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="formCompanies">
                                                        <Form.Label>{t('export_screen.forms.company')}</Form.Label>
                                                        {companiesStatus === 'loading' && <InlineLoader />}
                                                        {companiesStatus === 'succeeded' && (
                                                            <FieldArray
                                                                name="companies"
                                                                render={(arrayHelpers) => {
                                                                    return (
                                                                        <>
                                                                            {touched.companies && typeof errors.companies === 'string' ? <Form.Text className="invalid-feedback d-block">{errors.companies}</Form.Text> : null}
                                                                            <Row>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Popover id={`popover-disabled`} className={values.companies.length + values.families.length + values.analytes.length >= nbLimitFilter ? "" : "d-none"}>
                                                                                            <Popover.Header as="h3">{t('export_screen.popover.filters.header')}</Popover.Header>
                                                                                            <Popover.Body>
                                                                                                {t('export_screen.popover.filters.body')}
                                                                                            </Popover.Body>
                                                                                        </Popover>
                                                                                    }
                                                                                >
                                                                                    <Col xs={8} md={8} lg={8}>
                                                                                        <InputGroup className="w-100">
                                                                                            <Select
                                                                                                className="w-100"
                                                                                                name="addCompany"
                                                                                                noOptionsMessage={() => t('modal_select_sample.form.no_sample')}
                                                                                                placeholder={t('export_screen.selects.company')}
                                                                                                value={values.selectedCompany}
                                                                                                getOptionLabel={(company: Company) => company.name}
                                                                                                getOptionValue={(company: Company) => company.id}
                                                                                                isDisabled={values.companies.length + values.families.length + values.analytes.length >= nbLimitFilter}
                                                                                                options={Object.values(companies).filter(
                                                                                                    (company) => ((isUserAdmin && company.active && (company.laboratory_id === currentLaboratoryId || company.client_laboratory_id === currentLaboratoryId)) || (authCompanies.concat(authCustomers)).some(c => c.company_id === company.id && c.lab_type === laboratories[values.laboratoryId]?.lab_type) && company.active && (company.laboratory_id === currentLaboratoryId || company.laboratory_id === laboratories[currentLaboratoryId]?.ref_lab_id && company.client_laboratory_id === currentLaboratoryId) && company.id && !values.companies.includes(company.id)
                                                                    ))}
                                                                                                onChange={async (company: Company) => {
                                                                                                    setFieldValue('selectedCompany', null)

                                                                                                    if (company.id) {
                                                                                                        arrayHelpers.push(company.id)
                                                                                                        handleHideTableSimulation()
                                                                                                    }

                                                                                                }}
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </Col>
                                                                                </OverlayTrigger>

                                                                                <Col xs={4} md={4} lg={4}>
                                                                                    <Button
                                                                                        disabled={isSubmitting}
                                                                                        variant="danger"
                                                                                        size="sm"
                                                                                        type="button"
                                                                                        className="mt-1"
                                                                                        onClick={async () => {
                                                                                            setFieldValue('companies', [])
                                                                                            handleHideTableSimulation()
                                                                                        }}
                                                                                    >
                                                                                        {t('buttons.remove_all')}
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                            <ListGroup
                                                                                style={{
                                                                                    minHeight: '18vh',
                                                                                    maxHeight: '18vh',
                                                                                }}
                                                                                className="overflow-auto h-200rem border mt-2 mb-2 ml-2"
                                                                            >
                                                                                {values.companies
                                                                                    .map((id, index) => (
                                                                                        <ListGroup.Item className="d-flex border-0" key={id}>
                                                                                            <span className="me-auto">{dbt(companies, id, language.id, 'name')}</span>
                                                                                            <Button className="text-danger p-0 align-text-end" variant="link" onClick={async () => {
                                                                                                arrayHelpers.remove(index)
                                                                                                handleHideTableSimulation()

                                                                                            }}>
                                                                                                <DeleteIcon width="20px" height="100%" className="align-text-end" />
                                                                                            </Button>
                                                                                        </ListGroup.Item>
                                                                                    ))}
                                                                                {values.companies.length == 0 &&
                                                                                    <ListGroup.Item className="d-flex border-0" key={1}>
                                                                                        <span className="me-auto">{t('export_screen.defaults.companies')}</span>
                                                                                    </ListGroup.Item>
                                                                                }
                                                                            </ListGroup>
                                                                        </>
                                                                    )
                                                                }}
                                                            />
                                                        )}
                                                        {companiesStatus === 'failed' && <div>{companiesError}</div>}
                                                    </Form.Group>
                                                </Col>

                                                <Col>
                                                    <Form.Group controlId="formFamilies">
                                                        <Form.Label>{t('export_screen.forms.family')}</Form.Label>
                                                        {(familiesStatusByLabId === 'loading' || typesStatus === 'loading') && <InlineLoader />}
                                                        {familiesStatusByLabId === 'succeeded' && typesStatus === 'succeeded' && (
                                                            <FieldArray
                                                                name="families"
                                                                render={(arrayHelpers) => {
                                                                    return (
                                                                        <>
                                                                            {touched.families && typeof errors.families === 'string' ? <Form.Text className="invalid-feedback d-block">{errors.families}</Form.Text> : null}
                                                                            <Row>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Popover id={`popover-disabled`} className={values.companies.length + values.families.length + values.analytes.length >= nbLimitFilter ? "" : "d-none"}>
                                                                                            <Popover.Header as="h3">{t('export_screen.popover.filters.header')}</Popover.Header>
                                                                                            <Popover.Body>
                                                                                                {t('export_screen.popover.filters.body')}
                                                                                            </Popover.Body>
                                                                                        </Popover>
                                                                                    }
                                                                                >
                                                                                    <Col xs={8} md={8} lg={8}>
                                                                                        <InputGroup className="w-100">
                                                                                            <Select
                                                                                                className="w-100"
                                                                                                name="addSample"
                                                                                                noOptionsMessage={() => t('modal_select_sample.form.no_sample')}
                                                                                                value={values.selectedFamily}
                                                                                                placeholder={t('export_screen.selects.family')}
                                                                                                getOptionLabel={(sample: Family) => (sample.translations[language.id] ? sample.translations[language.id].name_translation : sample.name)}
                                                                                                getOptionValue={(sample: Family) => sample.id}
                                                                                                isDisabled={values.companies.length + values.families.length + values.analytes.length >= nbLimitFilter}
                                                                                                options={Object.values(families).filter(
                                                                                                    (family) =>
                                                                                                        familiesByLabId && familiesByLabId[currentLaboratoryId]?.includes(String(family.id)) && family.id && !values.families.includes(family.id)
                                                                                                        && (values.type_codes.length === 0 || Object.values(types).filter((type) => (type.active && type.laboratory_id === currentLaboratoryId && values.type_codes.includes(type.code))).map((type) => (type.id)).includes(family.type_id))
                                                                                                )

                                                                                                }
                                                                                                onChange={async (family: Family) => {
                                                                                                    setFieldValue('selectedFamily', null)

                                                                                                    if (family.id) {
                                                                                                        arrayHelpers.push(family.id)
                                                                                                        handleHideTableSimulation()
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </Col>
                                                                                </OverlayTrigger>

                                                                                <Col xs={4} md={4} lg={4}>
                                                                                    <Button
                                                                                        disabled={isSubmitting}
                                                                                        variant="danger"
                                                                                        size="sm"
                                                                                        type="button"
                                                                                        className="mt-1"
                                                                                        onClick={async () => {
                                                                                            setFieldValue('families', [])
                                                                                            handleHideTableSimulation()
                                                                                        }}
                                                                                    >
                                                                                        {t('buttons.remove_all')}
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                            <ListGroup
                                                                                style={{
                                                                                    minHeight: '18vh',
                                                                                    maxHeight: '18vh',
                                                                                }}
                                                                                className="overflow-auto h-200rem border mt-2 mb-2 ml-2"
                                                                            >
                                                                                {values.families
                                                                                    .filter((family) => familiesByLabId && familiesByLabId[currentLaboratoryId].includes(String(family)))
                                                                                    .map((id, index) => (
                                                                                        <ListGroup.Item className="d-flex border-0" key={id}>
                                                                                            <span className="me-auto">{dbt(families, id, language.id, 'name')}</span>
                                                                                            <Button className="text-danger p-0" variant="link" onClick={async () => {
                                                                                                arrayHelpers.remove(index)
                                                                                                handleHideTableSimulation()
                                                                                            }}>
                                                                                                <DeleteIcon width="20px" height="100%" className="align-text-mid" />
                                                                                            </Button>
                                                                                        </ListGroup.Item>
                                                                                    ))}
                                                                                    {values.families.length == 0 &&
                                                                                        <ListGroup.Item className="d-flex border-0" key={1}>
                                                                                            <span className="me-auto">{t('export_screen.defaults.families')}</span>
                                                                                        </ListGroup.Item>
                                                                                    }
                                                                            </ListGroup>
                                                                        </>
                                                                    )
                                                                }}
                                                            />
                                                        )}

                                                        {familiesStatusByLabId === 'failed' && <div>{familiesError}</div>}
                                                        {typesStatus === 'failed' && <div>{typesError}</div>}
                                                    </Form.Group>
                                                </Col>

                                                    <Col>
                                                    <Form.Group controlId="formAnalytes">
                                                        <Form.Label>{t('export_screen.forms.nutrient')}</Form.Label>
                                                        {analytesStatus === 'loading' && <InlineLoader />}
                                                        {analytesStatus === 'succeeded' && (
                                                            <FieldArray
                                                                name="analytes"
                                                                render={(arrayHelpers) => {

                                                                    return (
                                                                        <>
                                                                            {touched.analytes && typeof errors.analytes === 'string' ? <Form.Text className="invalid-feedback d-block">{errors.analytes}</Form.Text> : null}
                                                                            <Row>
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                        <Popover id={`popover-disabled`} className={values.companies.length + values.families.length + values.analytes.length >= nbLimitFilter ? "" : "d-none"}>
                                                                                            <Popover.Header as="h3">{t('export_screen.popover.filters.header')}</Popover.Header>
                                                                                            <Popover.Body>
                                                                                                {t('export_screen.popover.filters.body')}
                                                                                            </Popover.Body>
                                                                                        </Popover>
                                                                                    }
                                                                                >
                                                                                    <Col xs={8} md={8} lg={8}>
                                                                                        <InputGroup className="w-100">
                                                                                            <Select
                                                                                                className="w-100"
                                                                                                name="addAnalyte"
                                                                                                noOptionsMessage={() => t('modal_select_sample.form.no_sample')}
                                                                                                value={values.selectedAnalyte}
                                                                                                placeholder={t('export_screen.selects.nutrient')}
                                                                                                getOptionLabel={(analyte: Analyte) => `${analyte.code_test} - ${analyte.code_analyte}`}
                                                                                                getOptionValue={(analyte: Analyte) => getAnalyteKey(analyte)}
                                                                                                isDisabled={values.companies.length + values.families.length + values.analytes.length >= nbLimitFilter}
                                                                                                options={Object.values(analytes).filter(
                                                                                                    (analyte) => analyte.laboratory_id === currentLaboratoryId && !values.analytes.includes(getAnalyteKey(analyte))
                                                                                                )}
                                                                                                onChange={async (analyte: Analyte) => {
                                                                                                    setFieldValue('selectedAnalyte', null)
                                                                                                    arrayHelpers.push(getAnalyteKey(analyte))
                                                                                                    handleHideTableSimulation()
                                                                                                }}
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </Col>
                                                                                </OverlayTrigger>

                                                                                <Col xs={4} md={4} lg={4}>
                                                                                    <Button
                                                                                        disabled={isSubmitting}
                                                                                        variant="danger"
                                                                                        size="sm"
                                                                                        type="button"
                                                                                        className="mt-1"
                                                                                        onClick={async () => {
                                                                                            setFieldValue('analytes', [])
                                                                                            handleHideTableSimulation()
                                                                                        }}
                                                                                    >
                                                                                        {t('buttons.remove_all')}
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                            <ListGroup
                                                                                style={{
                                                                                    minHeight: '18vh',
                                                                                    maxHeight: '18vh',
                                                                                }}
                                                                                className="overflow-auto h-200rem border mt-2 mb-2 ml-2"
                                                                            >
                                                                                {values.analytes?.map((id, index) => (
                                                                                    <ListGroup.Item className="d-flex border-0" key={id}>
                                                                                        <span className="me-auto">
                                                                                            {analytes[id].code_test}/{analytes[id].code_analyte}
                                                                                        </span>
                                                                                        <Button className="text-danger p-0" variant="link" onClick={async () => {
                                                                                            arrayHelpers.remove(index)
                                                                                            handleHideTableSimulation()

                                                                                        }}>
                                                                                            <DeleteIcon width="20px" height="100%" className="align-text-mid" />
                                                                                        </Button>
                                                                                    </ListGroup.Item>
                                                                                ))}
                                                                                {values.analytes.length == 0 &&
                                                                                    <ListGroup.Item className="d-flex border-0" key={1}>
                                                                                        <span className="me-auto">{t('export_screen.defaults.analytes')}</span>
                                                                                    </ListGroup.Item>
                                                                                }
                                                                            </ListGroup>
                                                                        </>
                                                                    )
                                                                }}
                                                            />
                                                        )}
                                                        {analytesStatus === 'failed' && <div>{analytesError}</div>}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Container>

                                        <Col className="text-end mt-2">
                                            <Row>
                                                <Col>
                                                    <Button
                                                        className="m-1 no-print"
                                                        variant="secondary"
                                                        type="button"
                                                        onClick={async () => {
                                                            await preview(values)
                                                        }}
                                                    >
                                                        {t('export_screen.forms.btn_emulate')}
                                                    </Button>
                                                </Col>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Popover id={`popover-export-disabled`} className={nbResults === 0 || Object.values(exportStatus).filter((exportStatuObject) => ['created', 'queued', 'running', 'cancelling'].includes(exportStatuObject.status)).length >= nbLimitExport ? "" : "d-none"}>
                                                        <Popover.Header as="h3">{t('export_screen.popover.header')}</Popover.Header>
                                                        <Popover.Body>
                                                            {nbResults === 0 ? t('export_screen.popover.preview_required') : t('export_screen.popover.body_export_limit')}
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <Col xs="auto">
                                                    <Button
                                                        className="m-1 no-print"
                                                        variant="secondary"
                                                        type="button"
                                                        disabled={nbResults === 0 || Object.values(exportStatus).filter((exportStatuObject) => ['created', 'queued', 'running', 'cancelling'].includes(exportStatuObject.status)).length >= nbLimitExport}
                                                        onClick={() => {
                                                            handleShowExportExcel()
                                                        }}
                                                    >
                                                        {t('export_screen.forms.btn_export')}
                                                    </Button>
                                                </Col>
                                            </OverlayTrigger>
                                            </Row>
                                        </Col>
                                    </Form>

                                    {showTableSimulation && 
                                        <Col>
                                            <Row>
                                                {laboratoriesStatus === 'succeeded' && pdcStatus === 'succeeded' && familiesStatusByLabId === 'succeeded' && fetchStatus === 'succeeded' && typesStatus === 'succeeded' && patternsStatus === 'succeeded' && employeesStatus === 'succeeded' && companiesStatus === 'succeeded' && farmersStatusByUser === 'succeeded' && impropersStatus === 'succeeded' && analytesStatus === 'succeeded' &&
                                                        <>
                                                            <Col xs="auto" md="auto" lg="auto">
                                                                <span className="fw-bold">{t('export_screen.nb_analysis')}</span> : {pagination.count}
                                                            </Col>
                                                            <Col xs="auto" md="auto" lg="auto">
                                                                <span className="fw-bold">{t('export_screen.nb_results')}</span> : {nbResults}
                                                            </Col>
                                                        </>
                                                }
                                            </Row>

                                            <AnalysisTableContainer currentLaboratoryId={currentLaboratoryId} origin='EXPORT_EXCEL'></AnalysisTableContainer>
                                        </Col>
                                    }
                                </Container>
                            )
                        }}
                    </Formik>
                )}
            </Container>
        </Container>
    )
}

export default withTranslation()(ExportExcelContainer)
