import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import { fetchLanguages, fetchLaboratories } from '../../state/configSlice';
import { fetchSingleUserById, updateInternalUser } from '../../state/usersSlice';

import InlineLoader from '../../common/components/InlineLoader';
import SelectControl from '../../common/components/SelectControl';
import Container from 'react-bootstrap/Container';
import {Button, Col, Row, Form, Modal} from 'react-bootstrap';

import { InputInternalUser } from '../../api/usersApi';

import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import UserNotifications, { getFilledControlPlanNotif, getFilledSampleNotif } from '../../common/components/UserNotifications';
import Loader from '../../common/components/Loader';
import { AppDispatch } from '../../state/store';

type ProfileContainerProps = WithTranslation

const ProfileContainer: React.FC<ProfileContainerProps> = ({ t }: ProfileContainerProps) => {

    const dispatch = useDispatch<AppDispatch>()
    const { user: authUser } = useSelector((state: RootState) => state.authUser)
    const { id, external_id, disabled, locale, laboratories, owned_laboratories, ...sanitizedAuthUser } = authUser

    const languages = useSelector((state: RootState) => state.config.languages.data)
    const languagesStatus = useSelector((state: RootState) => state.config.languages.fetchStatus)
    const languagesError = useSelector((state: RootState) => state.config.languages.fetchError)

    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)

    const usersStatus = useSelector((state: RootState) => state.users.singleFetchStatus)

    const validationSchema = Yup.object().shape({
        firstname: Yup.string(),
        lastname: Yup.string(),
        email: Yup.string().email(),
        language_id: Yup.number(),
        laboratory_id: Yup.number()
    })

    useEffect(() => {
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }
        if (languagesStatus === 'idle') {
            dispatch(fetchLanguages())
        }
        if (usersStatus === 'idle') {
            dispatch(fetchSingleUserById(authUser.id))
        }
    }, [laboratoriesStatus, languagesStatus, usersStatus, dispatch, authUser.id])

    const [show, setShow] = useState(false);
    const [processing] = useState(false)
    const [submitError, setSubmitError] = useState(undefined)

    const handleClose = () => {
        setShow(false)
    }

    return (
        <Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {!submitError && t('success')}
                        {submitError && t('error')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!submitError &&
                        <Container>
                            {t('admin.users.success.msg_update')}
                        </Container>
                    }
                    {submitError &&
                        <Container className="text-danger">
                            {t('admin.users.errors.msg_failed')}
                            <br />
                            <span className="smalltext">{submitError}</span>
                        </Container>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('buttons.close')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <h1>{t('nav.my_profile')}</h1>
            {usersStatus === "succeeded" &&
                <Formik
                    initialValues={{
                        ...sanitizedAuthUser,
                        sample_notifs: getFilledSampleNotif(authUser.sample_notifs),
                        control_plan_notifs: getFilledControlPlanNotif(authUser.control_plan_notifs)
                    }}
                    validationSchema={validationSchema}
                    onSubmit= {async (values, { setSubmitting }) => {
                        setSubmitting(true)
                        
                        try {
                            await dispatch(updateInternalUser({internalUser: values, id: authUser.id})).unwrap()
                        } catch(err) {
                            setSubmitError(t('forms_errors.unexpected_error'))
                        } finally {
                            setSubmitting(false)
                            setShow(true)
                        }
                    }}
                >
                   
                    {(props: FormikProps<InputInternalUser>) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting
                        } = props

                        return (
                            <Form onSubmit={handleSubmit}>
                                {(isSubmitting || processing) &&
                                    <Loader />
                                }
                                <Container className="formbox py-2">
                                    <Row>
                                        <Form.Group as={Col} controlId="formFirstname">
                                            <Form.Label>{t('forms.firstname')}</Form.Label>
                                            <Form.Control type="text"
                                                name="firstname"
                                                isInvalid={touched.firstname && !!errors.firstname}
                                                value={values.firstname}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formLastname">
                                            <Form.Label>{t('forms.lastname')}</Form.Label>
                                            <Form.Control type="text"
                                                name="lastname"
                                                isInvalid={touched.lastname && !!errors.lastname}
                                                value={values.lastname}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Form.Group controlId="formEmail">
                                        <Form.Label>{t('forms.email')}</Form.Label>
                                        <Form.Control type="email"
                                            name="email"
                                            isInvalid={touched.email && !!errors.email}
                                            value={values.email}
                                            onChange={handleChange}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formLanguage">
                                        <Form.Label>{t('forms.language')}</Form.Label>
                                        {languagesStatus === 'loading' &&
                                            <InlineLoader />
                                        }
                                        {languagesStatus === 'succeeded' &&
                                            <SelectControl 
                                                name='language_id'
                                                items={languages}
                                                currentItemId={values.language_id}
                                                isInvalid={touched.language_id && !!errors.language_id}
                                                onChange={handleChange}
                                            />
                                        }
                                        {languagesStatus === 'failed' &&
                                            <div>{languagesError}</div>
                                        }
                                    </Form.Group>
                                </Container>

                                <UserNotifications formikProps={props} />

                                <Container className="pt-2 text-end">
                                    {authUser.id > 0 && 
                                        <Button variant="secondary" type="submit">
                                            {t('buttons.save')}
                                        </Button>
                                    }
                                    {!authUser.id && 
                                        <Form.Text className="text-danger">{t('profile.user.to_initialize')}</Form.Text>
                                    }
                                </Container>
                            </Form>
                        )
                    }}
                </Formik>
        }
        </Container>
    )
}

export default withTranslation()(ProfileContainer)


