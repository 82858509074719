import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import MatrixApi, { MatrixTemplate } from '../api/matrixApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';
import ConfigApi, { RefUnit, RefUnitsType } from '../api/configApi';

interface CurrentState {
    data: Data<RefUnit>;
    filtered: Array<string>;
    pagination: Pagination;
    fetchStatus: "idle" | "loading" | "succeeded" | "failed";
    fetchError: string | undefined;
    fetchStatusByPass: "idle" | "loading" | "succeeded" | "failed";
    fetchErrorByPass: string | undefined;
    singleFetchStatus: "idle" | "loading" | "succeeded" | "failed";
    singleFetchError: string | undefined;
    postStatus: "idle" | "loading" | "succeeded" | "failed";
    postError: string | undefined;
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    byCompany: {},
    fetchStatus: "idle",
    fetchError: undefined,
    fetchStatusByPass: "idle",
    fetchErrorByPass: undefined,
    singleFetchStatus: "idle",
    singleFetchError: undefined,
    postStatus: "idle",
    postError: undefined,
} as CurrentState;


// ref units
export const fetchRefUnitsWithConversion = createAsyncThunk(
    'config/fetchRefUnitsWithConversion', 
    async () => {
       
        const response =  await ConfigApi.getInstance().getRefUnitsWithConversion() 
        return response as Array<RefUnit>
    }
)

export const fetchExportRefUnits = createAsyncThunk(
    'config/fetchExportRefUnits', 
    async ({type}: {type: RefUnitsType}) => {
        const response =  await ConfigApi.getInstance().exportRefUnits(type)
        return response 
    }
)

const refUnitsSlice = createSlice({
    name: "refUnits",
    initialState,
    reducers: {

        purgeReducer(state: CurrentState, action: PayloadAction<void>) {
            state.data = {}
            state.filtered = []
        },

        setRefUnitsFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload;
        },
        refreshRefUnits(state: CurrentState) {
            state.fetchStatus = "idle";
        },
        setRefUnitsFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy

        },
        setRefUnitsSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchRefUnitsWithConversion.pending, (state) => {
            state.fetchStatus = "loading";

        });
        builder.addCase(fetchRefUnitsWithConversion.fulfilled, (state, action) => {            
            if (typeof action.payload !== "string") {

                action.payload.forEach((item: RefUnit) => {
                    if (item.unit) state.data[item.unit] = item
                });
            }
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchRefUnitsWithConversion.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
            state.pagination.status = 'failed';
        });
    },
});

export const {
    purgeReducer,
    setRefUnitsFiltered,
    refreshRefUnits,
    setRefUnitsFilterBy,
    setRefUnitsSortBy
} = refUnitsSlice.actions

export default refUnitsSlice.reducer