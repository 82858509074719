import React, { ChangeEvent, MouseEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../state/rootReducer'

import { ReactComponent as CheckedIcon } from 'bootstrap-icons/icons/check-square.svg'
import { ReactComponent as UncheckedIcon } from 'bootstrap-icons/icons/square.svg'
import { ReactComponent as SeeIcon } from 'bootstrap-icons/icons/zoom-in.svg'
import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg'
import { ReactComponent as SearchIcon } from 'bootstrap-icons/icons/search.svg'
import { ReactComponent as RefreshIcon } from 'bootstrap-icons/icons/arrow-clockwise.svg'
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Select from 'react-select'

import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import Button from 'react-bootstrap/Button'
import { useHistory } from "react-router-dom";
import ButtonHeaderList from '../../../../common/components/ButtonHeaderList';

import { fetchAnalysis, filterByReducer, sortByReducer, setNbByPage, filterReducer, refreshAnalysis } from '../../../../state/analysisSlice';
import userHasRequiredRoles from '../../../../common/auth/userHasRequiredRoles';
import dbt from '../../../../common/utils/dbTranslation';
import Loader from '../../../../common/components/Loader';
import CustomPagination from '../../../../common/components/CustomPagination';
import getResults from '../../../../common/utils/getResults';
import useFilterToolkit from '../../../../common/utils/useFilterToolkit';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import useViewport from '../../../../common/utils/useViewport';
import { Data, TupleId } from '../../../../types/types';
import { getIn, useFormik } from 'formik';
import AnalysisApi, { Analysis } from '../../../../api/analysisApi';
import { Company, Employee } from '../../../../api/usersApi';
import { refreshFarmers } from '../../../../state/farmerSlice';
import ModalDownload from '../../../../common/components/ModalDownload';
import useSecurityRoles from '../../../../common/auth/useSecurityRoles';
import { printPotentialNumber } from '../../../../common/utils/utils';


type AnalysisListProps = WithTranslation

interface StringMap {
    [key: string]: string
}

const AnalysisList: React.FC<AnalysisListProps> = ({ t }) => {

    type InputEvent = ChangeEvent<HTMLInputElement>
    type ChangeHandler = (e: InputEvent) => void
    
    const history = useHistory()
    const dispatch = useDispatch()
    const { userHasRoleTechnician, userHasRoleQuality, userHasRoleAll, userHasRoleClient, userHasRoleReadOnly, userGrantUpdateRegistered, userGrantUpdateReceived, userGrantUpdateDone } = useSecurityRoles()
    const {lg} = useViewport()

    const { user: authUser } = useSelector((state: RootState) => state.authUser)
    const hasMultipleLabs : boolean = authUser.laboratories.length > 1 || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])

    const analysis = useSelector((state: RootState) => state.analysis.analysis.data)
    const pagination = useSelector((state: RootState) => state.analysis.analysis.pagination)
    const fetchStatus = useSelector((state: RootState) => state.analysis.analysis.fetchStatus)

    // pdc state
    const pdc = useSelector((state: RootState) => state.pdc.dataDA)

    // Config states
    const types = useSelector((state: RootState) => state.config.types.data)
    const families = useSelector((state: RootState) => state.config.families.data)
    const patterns = useSelector((state: RootState) => state.config.patterns.data)

    // Laboratory state
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    const isUserAdmin : boolean = userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])

    const authUserLaboratories = Object.fromEntries(Object.values(laboratories).filter(l => isUserAdmin || authUser.role_levels.find(r => r.laboratory_id === l.id) !== undefined).map(l => ([l.id, l])))

    // Local state
    const { language, analysisStatus, analysisDateByStatus, allowedPageSizes } = useSelector((state: RootState) => state.local)
    
    // Users state
    const farmers = useSelector((state: RootState) => state.farmers.farmer.data)
    const employees = useSelector((state: RootState) => state.employees.data)
    const companies = useSelector((state: RootState) => state.companies.data)
    const companiesByLabId = useSelector((state: RootState) => state.companies.byLabId)

    // Impropers state
    const impropers = useSelector((state: RootState) => state.config.impropers.data)

    const [isChecked, setIsChecked] = useState(false);
    const [selectedAnalysisIds, setSelectedAnalysisIds] = useState<TupleId[]>([])

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showTransfer, setShowTransfer] = useState(false);
  
    const handleCloseTransfer = () => setShowTransfer(false);
    const handleShowTransfer = () => setShowTransfer(true);

    const [showError, setShowError] = useState(false);
  
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const [msgError, setMsgError] = useState("");

    const statusVariantMap: StringMap = {
        'registered': "",
        'received_improper': "warning",
        'received_compliant': "info",
        'received_fixed': "",
        'canceled': "dark",
        'partial': "secondary",
        'done': "success"
    }

    const hasRoleTechnician = userHasRoleTechnician()
    const hasRoleQuality = userHasRoleQuality()
    const hasRoleAll = userHasRoleAll()
    const hasRoleClient = userHasRoleClient()
    const hasRoleReadOnly = userHasRoleReadOnly()

    const grantUpdateRegistered = userGrantUpdateRegistered()

    const grantUpdateReceived = userGrantUpdateReceived()

    const grantUpdateDone = userGrantUpdateDone()

    const { FilterList, ListHeader, FitlerModalButton } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "analysis_list.table.columns.",
        fields: {

            urgent: {
                type: ["boolean"],
                sortable: false,
            },
            
            laboratory: {
                type: ["list"],
                sortable: true,
                modal: {
                    displayed: true
                },
                inOpt: {
                    dbt: {
                        elements: authUserLaboratories,
                        field: "name",
                        idFieldName: "laboratory"
                    }
                }
            },
            date: {
                type: ["date"],
                sortable: true,
                modal: {
                    displayed: true
                }
            },
            status: {
                type: ["list"],
                sortable: true,
                modal: {
                    displayed: true
                },
                inOpt: {
                    t: {
                        tPrefix: "analysis.status.",
                        elements: analysisStatus
                    }
                }
            },
            farmer: {
                type: ["filter"],
                modal: {
                    displayed: true,
                    collapsible: true
                },
                sortable: true
            },
            id: {
                type: ["number"],
                modal: {
                    displayed: true,
                    collapsible: true
                },
                sortable: true
            },
            product: {
                type: ["filter"],
                modal: {
                    displayed: true,
                    collapsible: true
                },
                sortable: true
            },
            type: {
                type: ["list"],
                sortable: true,
                inOpt: {
                    mergeTwin: true,
                    dbt: {
                        elements: Object.fromEntries(Object.entries(types).filter(([k,v]) => v.active)),
                        field: "name",
                        idFieldName: "type_id"
                    }
                }
            },
            pattern: {
                type: ["filter"],
                sortable: true
            },
            employee: {
                type: ["filter"],
                sortable: true
            },
            company: {
                type: ["filter"],
                sortable: true
            },
            pdc: {
                type: ["filter", "boolean"],
                sortable: true,
                boolOpt: {
                    labels: {
                        true: t('pdc.is_pdc'),
                        false: t('pdc.is_not_pdc')
                    }
                }
            }
            
        },
        reducers: {
            filterBy: filterByReducer,
            sortBy: sortByReducer
        }
    })

    useEffect(() => {
        resetSelected()
    }, [pagination.filterBy, pagination.sortBy])

    const selectAll = (isChecked: boolean) => {
        setIsChecked(isChecked)
        if (isChecked) {
            if (pagination.pages[pagination.currentPage]) {
                setSelectedAnalysisIds(pagination.pages[pagination.currentPage].ids)
            }
        } else {
            setSelectedAnalysisIds([])
        }
    }

    const toggleSelected = (tuple: TupleId) => {
        return (e: MouseEvent) => {
            e.stopPropagation()
            if (selectedAnalysisIds.find(k => k.labId === tuple.labId && k.id === tuple.id)) {
                setSelectedAnalysisIds(selectedAnalysisIds.filter(key => !(key.labId === tuple.labId && key.id === tuple.id)))
            } else {
                setSelectedAnalysisIds([...selectedAnalysisIds, tuple])
            }
        }
    }

    const resetSelected = () => {
        setSelectedAnalysisIds([])
        setIsChecked(false)
    }


    const download = async () => {
        let bool = false
        for(let i=0; i<selectedAnalysisIds.length; i++){
            if(bool === false){
               await AnalysisApi.getInstance().getResultsByAnalysisId(Number(selectedAnalysisIds[i].labId), Number(selectedAnalysisIds[i].id), false, true, false).then((keys) => {
                        if (keys.files_tab.length > 0) {
                            bool = true;
                        } 
                    }).catch(() => {
                        bool = false
                    });
            }
        }
        if(bool){
            handleShow()
        }else{
            getResultsFormatting(true, false)
        }
    }

    const transfer = () => {
        const labId = selectedAnalysisIds[0] ? selectedAnalysisIds[0].labId : 0
        if(selectedAnalysisIds.length === 0){
            setMsgError(t('analysis.transfer_error_checked_msg'))
            handleShowError()
        }else if(selectedAnalysisIds.filter(tuple => tuple.labId !== labId).length > 0){
            setMsgError(t('analysis.transfer_error_checked_msg_labo'))
            handleShowError()
        }else{
            handleShowTransfer()
        }
    }

    const getResultsFormatting = async (pdf: boolean, plb: boolean) => {
        try {
            const analysisWithResults = selectedAnalysisIds.filter(tuple => analysis[tuple.labId][tuple.id].status === "partial" || analysis[tuple.labId][tuple.id].status === "done")
            if (analysisWithResults.length > 0) {
                await getResults(analysisWithResults, pdf, plb)
            } else {
                setMsgError(t('analysis.download_error_checked_msg'))
                handleShowError()
            }
        } catch(err) {
            setMsgError(t("forms_errors.unexpected_error"))
            handleShowError()
        }
        handleClose()
    }

    const [filter, setFilter] = useState<string>(pagination.filterBy.all && pagination.filterBy.all.like ? pagination.filterBy.all.like : '')
    
    const onFilterChanged: ChangeHandler = e => {
        setFilter(e.target.value)
    }

    function triggerFilter() {
        dispatch(filterReducer(filter))
    }

    //Catch event 'Enter' key
    function KeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            triggerFilter()
        }
    }
    
    const validationSchema = Yup.object().shape({
        transfer: Yup.object().shape({
            employee_id: Yup.number()
                .notOneOf([0], t("forms_errors.analysis.technician_required"))
                .required(t("forms_errors.analysis.technician_required")),
            company_id: Yup.number()
                .notOneOf([0], t("forms_errors.analysis.company_required"))
                .required(t("forms_errors.analysis.company_required")),
        }),
    })

    interface IValues {
        transfer: {
            company_id: number,
            employee_id: number
        }
    }
    
    const { 
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting } = useFormik<IValues>({
            initialValues: {
                transfer: {
                    company_id: 0, 
                    employee_id: 0
                }
            },

            validationSchema: validationSchema,
            onSubmit: async (values, { setSubmitting }) => {
                // When button submits form and form is in the process of submitting, submit button is disabled
                setSubmitting(true)
                
                try {
                    await AnalysisApi.getInstance().transferAnalysis({pk: {inTuple: selectedAnalysisIds}}, values.transfer.company_id, values.transfer.employee_id)

                    dispatch(refreshAnalysis())
                    dispatch(refreshFarmers())
                } catch (err) {
                    const message = err instanceof Error ? err.message : ""
                    setMsgError(t('forms_errors.unexpected_error') + "(" + message + ")")
                    setShowError(true)
                } finally {
                    setSubmitting(false)
                }
            }
        }
    )

    return (
        <Container className="justify-content-center">
            <Modal size="lg" show={showError}  onHide={handleCloseError}>
                <Modal.Header>
                    <Modal.Title><span className="text-primary fw-bold"> {t('error')} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{msgError}</p>
                </Modal.Body>
            </Modal>

            <ModalDownload selectedAnalysisIds={selectedAnalysisIds} setShowModal={setShow} showModal={show}/>

            <Modal size="lg" show={showTransfer}  onHide={handleCloseTransfer}>
                <Modal.Header>
                    <Modal.Title><span className="text-primary fw-bold"> {t('analysis_list.transfer_title')} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>{t('analysis_list.choose')}</p>
                    
                        <Form onSubmit={handleSubmit}>

                                    <Container className="p-0 pb-2" >
                                        <Row className="mt-2 mb-1">
                                            <Form.Group as={Col} className="mb-2" controlId="formCustomer"> 
                                                <Form.Label>
                                                    {t('forms.analysis.customer')} : *
                                                </Form.Label>
                                                <Select
                                                    className={getIn(touched.transfer, "company_id") && typeof getIn(errors.transfer, "company_id") === 'string' ? `imgErrorInvalidInput` : ``}
                                                    styles={{
                                                        control: (styles: Data<string>, state: Data<string|number|boolean>) => ({
                                                            ...styles,
                                                            borderColor: (getIn(touched.transfer, "company_id") && typeof getIn(errors.transfer, "company_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                            boxShadow: state.isFocused ? (getIn(touched.transfer, "company_id") && typeof getIn(errors.transfer, "company_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                            '&:hover': {
                                                                borderColor: (getIn(touched.transfer, "company_id") && typeof getIn(errors.transfer, "company_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                boxShadow: state.isFocused ? (getIn(touched.transfer, "company_id") && typeof getIn(errors.transfer, "company_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                            }

                                                        }),
                                                        menu: (styles: Data<string>) => ({
                                                            ...styles,
                                                            zIndex: '1031'
                                                        })
                                                    }}

                                                    noOptionsMessage={() => t('forms.analysis.companyNotFound')}
                                                    value={values.transfer.company_id && companies[values.transfer.company_id]}
                                                    name='transfer.company_id'
                                                    placeholder={t('forms.analysis.default_company')}
                                                    getOptionLabel={(company: Company) => company.name}
                                                    getOptionValue={(company: Company) => company.id}
                                                    isClearable={true}
                                                    options={companiesByLabId[selectedAnalysisIds[0] ? selectedAnalysisIds[0].labId : 1] && companiesByLabId[selectedAnalysisIds[0] ? selectedAnalysisIds[0].labId : 1].map(id => companies[id])
                                                        .filter(item => item.active)
                                                        .sort((a, b) => a.name.localeCompare(b.name))}

                                                    onChange={async (company: Company) => {
                                                        if (company) {
                                                            const oldValue = values.transfer.company_id
                                                            if (oldValue !== company.id) {
                                                                await setFieldValue("transfer.company_id", company.id)
                                                                await setFieldValue("transfer.employee_id", 0)
                                                            }
                                                        } else {
                                                            await setFieldValue("transfer.company_id", 0)
                                                            await setFieldValue("transfer.employee_id", 0)
                                                        }
                                                        setFieldTouched("transfer.company_id", true)
                                                    }}
                                                    onBlur={async () => { setFieldTouched("transfer.company_id", true) }}
                                                />
                                                {getIn(touched.transfer, "company_id") && typeof getIn(errors.transfer, "company_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.transfer, "company_id")}</Form.Text> : null}
                                            </Form.Group>
                                        </Row>
                                    </Container>
                                
                                    <Container className="p-0 pb-2" >
                                        <Form.Label>
                                            {t('forms.analysis.technician')} *
                                        </Form.Label>
                                        <Form.Group className="mb-2" controlId="formTechnician" >
                                            <Select
                                                className={getIn(touched.transfer, "employee_id") && typeof getIn(errors.transfer, "employee_id") === 'string' ? `imgErrorInvalidInput` : ``}
                                                styles={{
                                                    control: (styles: Data<string>, state: Data<string|number|boolean>) => ({
                                                        ...styles,
                                                        borderColor: (getIn(touched.transfer, "employee_id") && typeof getIn(errors.transfer, "employee_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                        boxShadow: state.isFocused ? (getIn(touched.transfer, "employee_id") && typeof getIn(errors.transfer, "employee_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                        '&:hover': {
                                                            borderColor: (getIn(touched.transfer, "employee_id") && typeof getIn(errors.transfer, "employee_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                            boxShadow: state.isFocused ? (getIn(touched.transfer, "employee_id") && typeof getIn(errors.transfer, "employee_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                        }
                                                    }),
                                                    menu: (styles: Data<string>) => ({
                                                        ...styles,
                                                        zIndex: '1031'
                                                    })
                                                }}

                                                noOptionsMessage={() => t('forms.analysis.applicantNotFound')}
                                                value={values.transfer.employee_id && employees[values.transfer.employee_id]}
                                                name='transfer.employee_id'
                                                placeholder=""
                                                getOptionLabel={(employee: Employee) => employee.firstname + " " + employee.lastname}
                                                getOptionValue={(employee: Employee) => employee.id}
                                                isDisabled={!values.transfer.company_id}
                                                isClearable={true}
                                                options={Object.values(employees)
                                                    .filter(item => values.transfer.employee_id === item.id ||
                                                        (!item.disabled && item.companies.map(c => c.company_id).includes(values.transfer.company_id)))
                                                    .sort((a, b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname))
                                                }

                                                onChange={async (employee: Employee) => {
                                                    if (employee) {
                                                        const oldValue = values.transfer.employee_id
                                                        if (oldValue !== employee.id) {
                                                            await setFieldValue("transfer.employee_id", employee.id)
                                                        }
                                                    } else {
                                                        await setFieldValue("transfer.employee_id", 0)
                                                    }
                                                    setFieldTouched("transfer.employee_id", true)
                                                }}
                                                onBlur={async () => { setFieldTouched("transfer.employee_id", true) }}
                                            />
                                            {getIn(touched.transfer, "employee_id") && typeof getIn(errors.transfer, "employee_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.transfer, "employee_id")}</Form.Text> : null}
                                            {Number(values.transfer.company_id) === 0 && (hasRoleAll || hasRoleClient) ? <Form.Text className="invalid-feedback d-block">{t('forms.analysis.needClientForSelectFarmer')}</Form.Text> : null}
                                        </Form.Group>
                                    </Container>

                            <Row>
                                <Col className="text-center">
                                    <Button className="m-1 no-print" variant="secondary" onClick={() => { setShowTransfer(false) }} >{t('forms.analysis.go_back')}</Button>
                                </Col>
                                <Col className="text-center"> 
                                    <Button disabled={isSubmitting} variant="success" type="submit" >{t('analysis_list.transfer')}</Button>
                                </Col>
                            </Row>

                    </Form>

                </Modal.Body>
            </Modal>
            
            {(pagination.status === 'loading') &&
                <Loader />
            }

            <Row className="mt-2 mt-lg-5 d-flex align-center">
                <Col xs={12} md={6} lg={8} className="mb-2 me-auto">
                    <h5>
                        <Badge pill bg="primary">{Intl.NumberFormat(authUser.locale).format(pagination.count)}</Badge> 
                        <span className="ms-2 align-text-bottom fw-bold">{t('analysis_list.see_my_analysis_requests')}</span>
                    </h5>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <Row className="justify-content-end">
                        <Col xs={"auto"} md={9} lg={8} className="flex-fill">
                            <InputGroup size="sm">
                                <FormControl
                                    placeholder={t('analysis_list.search_analysis_placeholder')}
                                    aria-label={t('analysis_list.search_analysis')}
                                    value={filter}
                                    onChange={onFilterChanged}
                                    onKeyDown={KeyDown}
                                />
                                <Button variant="secondary" onClick={() => triggerFilter()}>
                                    <SearchIcon width="20px" height="100%" className="align-text-top" />
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col xs={"auto"} className="ps-0">
                            {!lg && 
                                <FitlerModalButton />
                            }
                            <Button size="sm" variant="secondary"
                                onClick={() => { dispatch(fetchAnalysis({page: pagination.currentPage})) }}
                            >
                                <RefreshIcon width="20px" height="100%" className="align-text-top" />
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Container className="justify-content-center mt-2">
                <Row className="d-none d-lg-flex flex-row-reverse">
                    <Col md="auto" className="pe-0 text-end align-self-end" >
                        <Button size="sm" variant="secondary" onClick={download}> {t('analysis_list.download')} </Button>
                    </Col>
                    {hasRoleAll &&
                        <Col md="auto" className="pe-0 text-end align-self-end" >
                            <Button size="sm" variant="secondary" onClick={transfer}> {t('analysis_list.transfer')} </Button>
                        </Col>
                    }
                    <Col>
                        <FilterList />
                    </Col>
                </Row>

            </Container>

            {/* Display for mobile and tablet */}
            <Container className="d-lg-none mt-2 mb-2 p-0">
                {pagination.pages[pagination.currentPage] && !pagination.pages[pagination.currentPage].fetching && pagination.pages[pagination.currentPage].ids.map((key) => {
                    const currentAnalysis = analysis[key.labId][key.id]
                    const statusDateName = analysisDateByStatus[currentAnalysis.status] as keyof Analysis
                    const statusDate = currentAnalysis[statusDateName] as string
                    return (
                        <ListGroup key={currentAnalysis.id} horizontal className="text-center smalltext" onClick={() => { history.push(`/secure/analysis/${key.labId}/${key.id}`) }}>
                            <ListGroup.Item className="col-3 bg-light text-primary p-2">
                                <span>{t('analysis_list.table.columns.id')}</span>
                                <br />
                                <span className="fw-bold" >{printPotentialNumber(currentAnalysis.id, currentAnalysis.display_id, authUser)}</span>
                            </ListGroup.Item>
                            <ListGroup.Item className="col-5 p-2">

                                {Object.values(analysisStatus).includes(currentAnalysis.status) &&
                                    <Badge bg={statusVariantMap[currentAnalysis.status]} className={currentAnalysis.status}>
                                        {t('analysis.status.' + currentAnalysis.status)}
                                    </Badge>
                                }
                                {"  "}
                                <span className="text-size-75">{statusDate ? 
                                    new Intl.DateTimeFormat(authUser.locale).format(new Date(statusDate)) : ""
                                }</span>
                                <br />
                                <span className="text-size-75 text-break">{
                                    typeof currentAnalysis.denomination === "string" && currentAnalysis.denomination !== "" ? 
                                        currentAnalysis.denomination 
                                    : typeof currentAnalysis.family_details === "string" && currentAnalysis.family_details !== "" && currentAnalysis.type_code === "FG" ? 
                                        currentAnalysis.family_details
                                    : typeof currentAnalysis.family_id === "number" && families[currentAnalysis.family_id] ? 
                                        dbt(families, currentAnalysis.family_id, language.id, "name")
                                    : ""
                                }</span>
                            </ListGroup.Item>
                            <ListGroup.Item className="col-4 p-2">
                                <span className="fw-bold text-break">{
                                        typeof currentAnalysis.farmer_id === "number" && farmers[currentAnalysis.farmer_id] ? 
                                            farmers[currentAnalysis.farmer_id].code !== "" ? farmers[currentAnalysis.farmer_id].code+"/"+farmers[currentAnalysis.farmer_id].name : farmers[currentAnalysis.farmer_id].name
                                        : ""
                                    }</span>
                                <br />
                                <span className="text-size-75">
                                    {t('analysis_list.table.columns.reference_sm')}
                                    {typeof currentAnalysis.client_reference === "string" ? currentAnalysis.client_reference : "" 
                                }</span>
                            </ListGroup.Item>
                        </ListGroup>
                    )
                })}
            </Container>

            {/* Display for desktop */}
            <Table striped hover responsive="lg" className="d-none d-lg-table bg-white text-center mt-2">
                <thead>
                    <tr>
                        <th className="p-0" />
                        <th className="p-1">
                                <ListHeader name="urgent" />
                            </th>
                        {hasMultipleLabs &&
                            <th className="p-1">
                                <ListHeader name="laboratory" />
                            </th>
                        }
                        <th className="p-1">
                            <ListHeader name="id" />
                        </th>
                        {(hasRoleClient || hasRoleAll || hasRoleQuality) &&
                            <th className="p-1">
                                <ListHeader name="type" mergeTwin={true} />
                            </th>
                        }
                        <th className="p-1">
                            <ListHeader name="status" />
                        </th>
                        <th className="p-1">
                            <ListHeader name="date" />
                        </th>
                        <th className="p-1">
                            <ListHeader name="product" />
                        </th>
                        <th className="p-1">
                            <ListHeader name="pattern" />
                        </th>
                        {(hasRoleClient || hasRoleAll || hasRoleTechnician) &&
                            <th className="p-1">
                                <ListHeader name="farmer" />
                            </th>
                        }
                        {(hasRoleClient || hasRoleAll || hasRoleReadOnly) &&
                            <th className="p-1">
                                <ListHeader name="employee" />
                            </th>
                        }
                        <th className="p-1">
                            <ListHeader name="company" />
                        </th>
                        <th className="p-1">
                            <ListHeader name="pdc" />
                        </th>

                        <th></th>

                        <th className="p-1">
                            <ButtonHeaderList
                                title={""}
                                name="pdc"
                                type={["check"]}
                                onClickSelect={selectAll}
                                isChecked={isChecked}
                                

                            />
                        </th>
                    </tr>
                </thead>
                {fetchStatus === 'succeeded' &&
                    <tbody>
                        {pagination.pages[pagination.currentPage] && !pagination.pages[pagination.currentPage].fetching && pagination.pages[pagination.currentPage].ids.map((key) => {
                            const analysisObject = analysis[key.labId][key.id]
                            return (

                                <tr key={analysisObject.id} className="align-middle" onClick={() => { history.push(`/secure/analysis/${analysisObject.laboratory_id}/${analysisObject.id}`) }}>
                                    <td className="p-1 table-hover-hint" />
                                    <td className="lg fw-bold text-urgent">{analysisObject.urgent?'!' : ''}</td> 
                                    {hasMultipleLabs && <td className="text-primary fw-bold">{dbt(laboratories, analysisObject.laboratory_id, language.id, "name")}</td> }
                                    <td className="text-primary fw-bold">{printPotentialNumber(analysisObject.id, analysisObject.display_id, authUser)}</td>
                                    {(hasRoleClient || hasRoleAll || hasRoleQuality) && <td>{dbt(types, analysisObject.type_id, language.id, "name")}</td>}
                                    <td>
                                        {Object.values(analysisStatus).includes(analysisObject.status) &&
                                            <Badge bg={statusVariantMap[analysisObject.status]} className={analysisObject.status}>
                                                <span>{t('analysis.status.' + analysisObject.status)}</span>
                                            </Badge>
                                        }
                                    </td>
                                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                                    <td>{analysisObject.last_date && new Intl.DateTimeFormat(authUser.locale).format(new Date(analysisObject.last_date!))}</td>
                                    <td className="text-break">{
                                        typeof analysisObject.denomination === "string" && analysisObject.denomination !== "" ? 
                                        analysisObject.denomination 
                                        : typeof analysisObject.family_details === "string" && analysisObject.family_details !== "" && analysisObject.type_code === "FG" ? 
                                            analysisObject.family_details
                                        : typeof analysisObject.family_id === "number" && families[analysisObject.family_id] ? 
                                            dbt(families, analysisObject.family_id, language.id, "name")
                                        : ""
                                    }</td>
                                    
                                    <td>{dbt(patterns, analysisObject.pattern_id, language.id, "name")}</td>
                                    {(hasRoleClient || hasRoleAll || hasRoleTechnician) && 
                                        <td>{
                                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                            typeof analysisObject.farmer_id === "number" && farmers[analysisObject.farmer_id!] ? 
                                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                farmers[analysisObject.farmer_id!].code !== "" ? farmers[analysisObject.farmer_id!].code+"/"+farmers[analysisObject.farmer_id!].name : farmers[analysisObject.farmer_id!].name
                                            : ""
                                        }</td>
                                    }
                                    {(hasRoleClient || hasRoleAll || hasRoleReadOnly) && 
                                        <td>{
                                            typeof analysisObject.employee_id === "number" && employees[analysisObject.employee_id] ? 
                                                employees[analysisObject.employee_id].firstname + " " + employees[analysisObject.employee_id].lastname
                                            : ""
                                        }</td>
                                    }
                                    <td>{
                                        typeof analysisObject.company_id === "number" && companies[analysisObject.company_id] ? 
                                            companies[analysisObject.company_id].name
                                        : ""
                                    }</td>
                                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                                    <td>{analysis[key.labId] && analysisObject.id_pdc !== null && pdc[key.labId] && pdc[key.labId][analysisObject.id_pdc!] ? pdc[key.labId][analysisObject.id_pdc!].name : null}</td>
                                    <td className="text-nowrap">
                                        <Button className="p-0 pe-1 text-primary" variant="link" onClick={(e) => { e.stopPropagation(); history.push(`/secure/analysis/${analysisObject.laboratory_id}/${analysisObject.id}`) }} >
                                            <SeeIcon width="24px" height="100%" className="align-text-top" />
                                        </Button>
                                        {(
                                            (['registered'].includes(analysisObject.status) && userGrantUpdateRegistered (analysisObject.laboratory_id, authUser))
                                            ||
                                            (['received_improper'].includes(analysisObject.status) && userGrantUpdateRegistered (analysisObject.laboratory_id, authUser) && analysisObject.improper_id && impropers[analysisObject.improper_id]?.editable)
                                            ||
                                            (['received_improper', 'received_compliant', 'received_fixed'].includes(analysisObject.status) && userGrantUpdateReceived(analysisObject.laboratory_id, authUser))
                                            ||
                                            (['partial', 'done'].includes(analysisObject.status) && userGrantUpdateDone(analysisObject.laboratory_id, authUser))
                                            
                                        ) &&
                                            <Button className="p-0 pe-1 text-secondary" variant="link" onClick={(e) => { e.stopPropagation(); history.push(`/secure/analysis/${analysisObject.laboratory_id}/${analysisObject.id}/edit/1`) }} >
                                                <EditIcon width="24px" height="100%" className="align-text-top" />
                                            </Button>
                                        }
                                    </td>

                                    <td>
                                        <Button className="p-0 text-primary" variant="link" onClick={toggleSelected(key)} >
                                            {(selectedAnalysisIds.includes(key) ?
                                                <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                                : <UncheckedIcon width="18px" height="18px" className="align-text-top" /> )
                                            }
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}


                    </tbody>
                }
            </Table>
            <CustomPagination 
                nbPages={pagination.nbPage}
                curPage={pagination.currentPage}
                nbNeighbours={1}
                curPageSize={pagination.nbByPage}
                allowedPageSizes={allowedPageSizes}
                goToPage={(page) => { dispatch(fetchAnalysis({page})); resetSelected()}}
                setPageSize={(nbPage) => { dispatch(setNbByPage(nbPage)); dispatch(fetchAnalysis({page: 1})); resetSelected()}}
            />

        </Container>
    )

}

export default withTranslation()(AnalysisList)