import React, { useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import { Modal, Container, Form, Button, Row, Col } from 'react-bootstrap'

import { Formik, FormikProps } from 'formik'
import Select from 'react-select'

import Loader from './Loader'
import { t } from 'i18next'
import { RootState } from '../../state/rootReducer'
import { useDispatch, useSelector } from 'react-redux'
import { getMatrixExportsFlatWithoutOutputsKey, MatrixExportsFlat, MatrixKey } from '../../api/matrixApi'
import { AppDispatch } from "../../state/store";
import { postMatrixJob } from '../../state/matrixJobsSlice'
import { fetchMatrixExports } from '../../state/matrixExportsSlice'

interface ModalExportMatrixProps extends WithTranslation {
    selectedMatricesIds: MatrixKey[]
    setShowModal: (show: boolean) => void
    showModal: boolean
}

interface IFormValues {
    selectedMatrixExport: MatrixExportsFlat | null
}

const ModalExportMatrix: React.FC<ModalExportMatrixProps> = ({ selectedMatricesIds, setShowModal, showModal }: ModalExportMatrixProps) => {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const dispatch = useDispatch<AppDispatch>();  
    
    const [showError, setShowError] = useState(false);
    
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    // laboratories
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)

    // matrix exports state
    const matrixExports = useSelector((state: RootState) => state.matrixExports.data);
    const matrixExportsStatus = useSelector((state: RootState) => state.matrixExports.fetchStatus);

    // matrices states
    const matrices = useSelector(
        (state: RootState) => state.matrix.matrix.data
    )

    const companies = useSelector((state: RootState) => state.companies.data)

    const [msgErrors, setMsgErrors] = useState<string[]>([]);

    useEffect(() => {
        if (selectedMatricesIds && selectedMatricesIds[0]) {
            if (matrixExportsStatus === 'idle') {
                dispatch(fetchMatrixExports())
            } 
        }
    }, [matrixExportsStatus, selectedMatricesIds])


    function getUniqueBasedOnNameAndLaboratoryId(matrixExportsFlat: MatrixExportsFlat[]){
        let mapObj = new Map()
        
        matrixExportsFlat.forEach(v => {
          let prevValue = mapObj.get(getMatrixExportsFlatWithoutOutputsKey(v))
          if(!prevValue){
            mapObj.set(getMatrixExportsFlatWithoutOutputsKey(v), v)
          } 
        })
        return [...mapObj.values()]
    }

    const getSelectedMatricesLabIds = (selectedMatricesIds: MatrixKey[]) => {
        let ids : number[] = []
        selectedMatricesIds.forEach(m => {
            ids.push(companies[m.company_id].formulation_id!)
        })
        return ids
    }
    
    return (
        <Container>
            <Modal size="lg" show={showError}  onHide={handleCloseError}>
                <Modal.Header>
                    <Modal.Title><span className="text-primary fw-bold"> {t('error')} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgErrors.map(

                        msgError => {
                            return (
                                <p>{msgError}</p>

                            )
                        }
                    )
                    }
                </Modal.Body>
            </Modal>
            <Modal size="lg" animation={true} show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('matrix.matrix.modal_export.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            selectedMatrixExport: null
                        }}
                        
                        onSubmit={async (values, { setSubmitting }) => {
                            // When button submits form and form is in the process of submitting, submit button is disabled
                            
                            setSubmitting(true)
                            const errorMessages = []
                            try {
                                setShowModal(false)
                                for (const companyId of new Set(selectedMatricesIds.map(c => c.company_id))){
                                    try{
                                    // formData object for job post
                                    const formData = new FormData();
                                    formData.append("type", "export");
                                    formData.append("filter[company_id]", String(companyId));
                                    selectedMatricesIds.filter(m => m.company_id === companyId).forEach((matrix, index) => {
                                        formData.append('filter[matrices]['+ index +'][export_code]', matrix.export_code)
                                        formData.append('filter[matrices]['+ index +'][version]', matrix.version.toString())
                                    })

                                    if (values.selectedMatrixExport){
                                        formData.append("output[export_name]", (values.selectedMatrixExport as MatrixExportsFlat).name);
                                }

                                
                                
                               await dispatch(postMatrixJob(formData)).unwrap()

                            }
                            catch(err){
                                errorMessages.push(((err as any).message))
                            }
                            }
                            } catch (err) {                                
                                console.error('Unexpected error while downloading results', err)
                                setMsgErrors([t('matrix.matrix.download_modal.unexpected_error')])
                                handleShowError()
                            } finally {
                                setFormSubmitted(false)

                                if (errorMessages.length > 0){
                                    setMsgErrors(errorMessages)
                                    handleShowError()
                                }
                            }
                        }}
                    >
                        {/* Callback function containing Formik state and helpers that handle common form actions */}
                        {(props: FormikProps<IFormValues>) => {
                            const { values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue } = props

                            return (
                                <Form className="" onSubmit={handleSubmit}>
                                    {isSubmitting && <Loader />}
                                    <Form.Group controlId="formFormat">
                                        <div className="mb-2">{t('matrix.matrix.modal_export.subtitle')}</div>

                                        <Row className ="m-0 p-0">
                                        <Col className=" w-25 border rounded p-3  m-1 ms-0">
                                                <Select
                                                    className="w-100"
                                                    name="addAnalyte"
                                                    noOptionsMessage={() => t('mmatrix.matrix.modal_export.selects.no_option')}
                                                    value={values.selectedMatrixExport}
                                                    placeholder={t('matrix.matrix.modal_export.selects.export_format')}
                                                    getOptionLabel={(matrixExportFlat: MatrixExportsFlat) => `${matrixExportFlat.format} - ${matrixExportFlat.name}`}
                                                    getOptionValue={(matrixExportFlat: MatrixExportsFlat) => getMatrixExportsFlatWithoutOutputsKey(matrixExportFlat)}
                                                    options={getUniqueBasedOnNameAndLaboratoryId(Object.values(matrixExports).filter(
                                                        (matrixExportFlat) => 
                                                            getSelectedMatricesLabIds(selectedMatricesIds).includes(matrixExportFlat.laboratory_id)  &&
                                                            (matrixExportFlat.crit_company === '*' || matrixExportFlat.crit_company.split('; ').some(c => selectedMatricesIds.map(m => String(m.company_id)).includes(c)))
                                                        ))}

                                                    onChange={
                                                        (matrixExportFlat: MatrixExportsFlat) => {
                                                            setFieldValue('selectedMatrixExport', matrixExportFlat)
                                                        }
                                                    }

                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    {/* To compensate marginLeft of radio buttons*/}
                                    <Row className='m-0 p-0'>
                                            <Col className="text-end p-0 pt-1">
                                            <Button disabled={isSubmitting} variant="secondary" type="submit">
                                                {t('buttons.export')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default withTranslation()(ModalExportMatrix)