import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import PublishApi, { AnalysisReports,getKeyAnalysisReports } from '../api/publishApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';

interface CurrentState {
    data: Data<AnalysisReports>,
    dataWithoutBackground: Data<AnalysisReports>,
    filtered: Array<string>
    pagination: Pagination
    fetchStatusWithBackground: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchStatusWithoutBackground: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postError: string | undefined
}

const initialState = {
    data: {},
    dataWithoutBackground: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    byCompany: {},
    fetchStatusWithBackground: 'idle',
    fetchStatusWithoutBackground: 'idle',
    fetchError: undefined,
    singleFetchStatus: 'idle',
    singleFetchError: undefined,
    postStatus: 'idle',
    postError: undefined
} as CurrentState


export const fetchAnalysisReportWithoutBackground = createAsyncThunk(
    'AnalysisReportsWithoutBackground',
    async () => {
        const response = await PublishApi.getInstance().getAnalysisReports(false)
        return response as Array<AnalysisReports>
    }
)

export const fetchAnalysisReportWithBackground = createAsyncThunk(
    'AnalysisReportsWithBackground ',
    async () => {
        const response = await PublishApi.getInstance().getAnalysisReports(true)
        return response as Array<AnalysisReports>
    }
)


const analysisReportSlice = createSlice({
    name: 'analysisReport',
    initialState,
    reducers: {
        refreshAnalysisReport(state: CurrentState){
            state.data = {}
            state.fetchStatusWithBackground = 'loading'
        },
        updateAnalysisReport(state: CurrentState, action: PayloadAction<AnalysisReports>) {
            const item = action.payload
            if (item.laboratory_id)
                state.data[getKeyAnalysisReports(item)] = item
        },
        deleteAnalysisReport(state: CurrentState, action: PayloadAction<AnalysisReports>) {
            const item = action.payload
            if (item) {
                delete state.data[getKeyAnalysisReports(item)]
                const index = state.filtered.indexOf(getKeyAnalysisReports(item))
                if (index > -1) {
                    state.filtered.splice(index, 1)
                }
            }
        },
        setFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload
        },
        setFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy
        },
        setSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchAnalysisReportWithBackground.pending, (state) => {
            state.fetchStatusWithBackground = 'loading'
        })
        builder.addCase(fetchAnalysisReportWithBackground.fulfilled, (state, action) => {
            action.payload.forEach((item: AnalysisReports) => {
                state.data[getKeyAnalysisReports(item)] =item
            })     
            state.fetchStatusWithBackground = 'succeeded'

        })
        builder.addCase(fetchAnalysisReportWithBackground.rejected, (state, action) => {
            state.fetchStatusWithBackground = 'failed'
            state.fetchError = action.error.message
        })

        

        builder.addCase(fetchAnalysisReportWithoutBackground.pending, (state) => {
            state.fetchStatusWithoutBackground = 'loading'
        })
        builder.addCase(fetchAnalysisReportWithoutBackground.fulfilled, (state, action) => {
            action.payload.forEach((item: AnalysisReports) => {
                state.dataWithoutBackground[getKeyAnalysisReports(item)] =item
            })     
            state.fetchStatusWithoutBackground = 'succeeded'
        })
        builder.addCase(fetchAnalysisReportWithoutBackground.rejected, (state, action) => {
            state.fetchStatusWithoutBackground = 'failed'
            state.fetchError = action.error.message
        })
    }
})

export const {
    updateAnalysisReport,
    setFiltered,
    setFilterBy,
    setSortBy,
    refreshAnalysisReport,
    deleteAnalysisReport,
} = analysisReportSlice.actions

export default analysisReportSlice.reducer