/* eslint-disable no-restricted-globals */
import React from 'react';

import { Switch, useRouteMatch } from "react-router-dom";
import { withTranslation, WithTranslation } from 'react-i18next';

import RoleSecureRoute from '../../common/auth/RoleSecureRoute'

import  MatrixManagementContainer from './MatrixManagementContainer';
import CompanyMatrixListContainer from './companies_matrix/CompanyMatrixListContainer';
import MatrixTemplatesListContainer from './matrix_templates/MatrixTemplatesListContainer';
import MatrixExportsListContainer from './matrix_exports/MatrixExportsListContainer';
import MatrixTemplateInputsList from './matrix_template_inputs/components/MatrixTemplateInputsList';
import MatrixTemplateInputsListContainer from './matrix_template_inputs/MatrixTemplateInputsListContainer';


const AppRouter: React.FC<WithTranslation> = () => {

    const match = useRouteMatch();

    return (
        <Switch>
            <RoleSecureRoute exact path={`${match.path}`} requiredRoles={['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_nutrition']}>
                <MatrixManagementContainer />
            </RoleSecureRoute>
            <RoleSecureRoute exact path={`${match.path}/companies`} requiredRoles={['MyProvilab_ROLE_nutrition', 'MyProvilab_ROLE_usersAdmin']}>
                <CompanyMatrixListContainer />
            </RoleSecureRoute>
            <RoleSecureRoute exact path={`${match.path}/matrix`} requiredRoles={['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_nutrition']}>
                <MatrixTemplatesListContainer />
            </RoleSecureRoute>
            <RoleSecureRoute exact path={`${match.path}/exports`} requiredRoles={['MyProvilab_ROLE_nutrition', 'MyProvilab_ROLE_usersAdmin']}>
                <MatrixExportsListContainer />
            </RoleSecureRoute>
            <RoleSecureRoute exact path={`${match.path}/input_data`} requiredRoles={['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_nutrition']}>
                <MatrixTemplateInputsListContainer />
            </RoleSecureRoute>
        </Switch>
        
    );
}

export default withTranslation()(AppRouter)