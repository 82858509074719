import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ConfigApi, { Analyte, AnalyteKey, getAnalyteKey } from '../api/configApi';
import { buildDefaultExtraReducersWithFrontPagination, CurrentState, getDefaultReducersWithFrontPagination, getInitialState } from './DefaultSlice';

const initialState = getInitialState<Analyte>()

export const fetchAnalytes = createAsyncThunk(
    'config/fetchAnalytes', 
    async () => {
        return await ConfigApi.getInstance().getAnalytes() 
    }
)

export const deleteAnalyte = createAsyncThunk(
    'config/deleteAnalyte',
    async (analyte:AnalyteKey,{dispatch}) => {
        const response = await ConfigApi.getInstance().deleteAnalyte(analyte.laboratory_id,analyte.code_test,analyte.code_analyte)

        if (!response) {
            throw new Error("Unable to delete analyte")
        }

        dispatch(deleteReducer(analyte))

        return true
    }
)

const addItemToAnalyte =   (state:CurrentState<Analyte>,item:Analyte) => {

    if (item.laboratory_id && item.code_test && item.code_analyte) 
    state.data[getAnalyteKey(item)] = item
}

const postDeleteAnalyte = (state:CurrentState<Analyte>,item:AnalyteKey)=> {
    delete state.data[getAnalyteKey(item)]
                state.filtered.splice(state.filtered.indexOf(getAnalyteKey(item)),1)
}

const analyteSlice = createSlice({
    name: 'analytes',
    initialState,
     reducers: getDefaultReducersWithFrontPagination(addItemToAnalyte,postDeleteAnalyte)
    ,
    extraReducers: builder => {
            buildDefaultExtraReducersWithFrontPagination(
              addItemToAnalyte,
                builder,
                fetchAnalytes
            )  
        }
})

export const {
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage
} = analyteSlice.actions

export default analyteSlice.reducer