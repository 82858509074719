import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import ConsultAnalysis from './components/desktop/consultAnalysis'
import ConsultAnalysisMobile from './components/mobile/consultAnalysisMobile'
import { RootState } from '../../../state/rootReducer'
import Container from 'react-bootstrap/Container';

import { useParams } from "react-router-dom";
import { fetchSingleAnalysis } from '../../../state/analysisSlice';

import Loader from '../../../common/components/Loader';
import useViewport from '../../../common/utils/useViewport';
import { fetchSinglePdc } from '../../../state/pdcSlice';
import { fetchFields } from '../../../state/fieldsSlice';
import { fetchForms } from '../../../state/formsSlice';
import { fetchFamilies, fetchImpropers, fetchLaboratories, fetchLanguages, fetchPackages, fetchPatterns, fetchTypes } from '../../../state/configSlice';
import { fetchFarmers } from '../../../state/farmerSlice';
import { fetchEmployees } from '../../../state/employeesSlice';
import { fetchAllCompanies } from '../../../state/companiesSlice';
import { fetchNirs } from '../../../state/nirSlice';
import { fetchAnalytes } from '../../../state/analyteSlice';

type AnalysisContainerProps = WithTranslation

interface urlParams {
    laboratoryId: string
    analysisId: string
}

const AnalysisContainer: React.FC<AnalysisContainerProps> = ({ t }) => {
    const { laboratoryId, analysisId } = useParams<urlParams>();

    const dispatch = useDispatch()

    // pdc state
    const controlPlans = useSelector((state: RootState) => state.pdc.data)
    const pdcStatus = useSelector((state: RootState) => state.pdc.singleFetchStatus)

    // Analysis state
    const analysis = useSelector((state: RootState) => state.analysis.analysis.data)
    const analysisStatus = useSelector((state: RootState) => state.analysis.analysis.singleFetchStatus)
    const allAnalysisStatus = useSelector((state: RootState) => state.analysis.analysis.fetchStatus)
    const analysisError = useSelector((state: RootState) => state.analysis.analysis.singleFetchError)

    // fields state
    const fieldsStatus = useSelector((state: RootState) => state.fields.fetchStatus)
    const fieldsError = useSelector((state: RootState) => state.fields.fetchError)
    
    // forms state
    const formsStatus = useSelector((state: RootState) => state.forms.fetchStatus)
    const formsError = useSelector((state: RootState) => state.forms.fetchError)

    // patterns state
    const patternsStatus = useSelector((state: RootState) => state.config.patterns.fetchStatus)
    const patternsError = useSelector((state: RootState) => state.config.patterns.fetchError)

    // languages state
    const languagesStatus = useSelector((state: RootState) => state.config.languages.fetchStatus)

    //  Laboratories state
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)

    //  Nirs state
    const nirsStatus = useSelector((state: RootState) => state.nir.fetchStatus)
    const nirsError = useSelector((state: RootState) => state.nir.fetchError)

    // types state
    const typesStatus = useSelector((state: RootState) => state.config.types.fetchStatus)
    const typesError = useSelector((state: RootState) => state.config.types.fetchError)

    // families state
    const familiesStatus = useSelector((state: RootState) => state.config.families.fetchStatus)
    const familiesError = useSelector((state: RootState) => state.config.families.fetchError)

    // farmers state
    const farmersStatusByUser = useSelector((state: RootState) => state.farmers.farmer.fetchStatusByUser)
    const farmersError = useSelector((state: RootState) => state.farmers.farmer.fetchError)

    // Package state
    const packagesStatus = useSelector((state: RootState) => state.config.packages.fetchStatus)
    const packagesError = useSelector((state: RootState) => state.config.packages.fetchError)

    // Analytes state
    const analytesStatus = useSelector((state: RootState) => state.analytes.fetchStatus)
    const analytesError = useSelector((state: RootState) => state.analytes.fetchError)

    // Companies state
    const companiesAllStatus = useSelector((state: RootState) => state.companies.fetchAllStatus)
    const companiesError = useSelector((state: RootState) => state.companies.fetchError)

    // Employees state
    const employeesStatus = useSelector((state: RootState) => state.employees.fetchStatus)
    const employeesError = useSelector((state: RootState) => state.employees.fetchError)

    // Impropers state
    const impropersStatus = useSelector((state: RootState) => state.config.impropers.fetchStatus)
    const impropersError = useSelector((state: RootState) => state.config.impropers.fetchError)

    const [errorMessage, setErrorMessage] = useState(false)
    const [hasFetchPdc, setHasFetchPdc] = useState(false)

    const { lg } = useViewport()


    useEffect(() => {
        if (fieldsStatus === 'idle') {
            dispatch(fetchFields())
        }
        if (formsStatus === 'idle') {
            dispatch(fetchForms({}))
        }
        if (typesStatus === 'idle') {
            dispatch(fetchTypes())
        }
        if (familiesStatus === 'idle') {
            dispatch(fetchFamilies())
        }
        if (languagesStatus === 'idle') {
            dispatch(fetchLanguages())
        }
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }
        if (nirsStatus === 'idle') {
            dispatch(fetchNirs())
        }
        if (farmersStatusByUser === 'idle') {
            dispatch(fetchFarmers())
        }
        if (patternsStatus === 'idle') {
            dispatch(fetchPatterns())
        }
        if (packagesStatus === 'idle') {
            dispatch(fetchPackages())
        }
        if (analytesStatus === 'idle') {
            dispatch(fetchAnalytes())
        }
        if (employeesStatus === 'idle') {
            dispatch(fetchEmployees())
        }
        if (impropersStatus === 'idle') {
            dispatch(fetchImpropers())
        }

        if (companiesAllStatus === 'idle') {
            dispatch(fetchAllCompanies())
        }
        if (analysisStatus === 'succeeded') {
            if (!analysis[laboratoryId] || !analysis[laboratoryId][analysisId]) {
                setErrorMessage(true)
            }
        }
        if ((allAnalysisStatus === 'succeeded' || analysisStatus === 'succeeded') && analysis[laboratoryId] && analysis[laboratoryId][analysisId] && (analysis[laboratoryId][analysisId].id_pdc !== null && (pdcStatus === 'idle' || (pdcStatus === 'succeeded' && (!controlPlans[laboratoryId] || !controlPlans[laboratoryId][String(analysis[laboratoryId][analysisId].id_pdc)]))))) {
            if (!hasFetchPdc) {
                dispatch(fetchSinglePdc({ pdcId: Number(analysis[laboratoryId][analysisId].id_pdc), laboratoryId: analysis[laboratoryId][analysisId].laboratory_id }))
                setHasFetchPdc(true)
            } else {
                setErrorMessage(true)
            }
        }
    }, [dispatch, analysisId, analysisStatus, nirsStatus, errorMessage, analysis, allAnalysisStatus, pdcStatus, controlPlans, employeesStatus, analytesStatus, laboratoriesStatus, packagesStatus, farmersStatusByUser, typesStatus, familiesStatus, languagesStatus, patternsStatus, companiesAllStatus, impropersStatus, fieldsStatus, formsStatus])

    useEffect(() => {
        if(analysisId != undefined){
            dispatch(fetchSingleAnalysis({laboratoryId: Number(laboratoryId), analysisId: Number(analysisId)}))
        }
    }, [])

    useEffect(() => {
        if (!analysis[laboratoryId] || !analysis[laboratoryId][analysisId]) {
            dispatch(fetchSingleAnalysis({laboratoryId: Number(laboratoryId), analysisId: Number(analysisId)}))
        }

        if(laboratoryId &&
            analysisId &&
            analysis[laboratoryId] &&
            analysis[laboratoryId][analysisId] &&
            analysis[laboratoryId][analysisId].additional_analysis &&
            analysis[laboratoryId][analysisId].additional_analysis![0] &&
            analysis[laboratoryId][analysisId].additional_analysis![0].ref_laboratory_id &&
            analysis[laboratoryId][analysisId].additional_analysis![0].ref_analysis_id &&
            (!analysis[analysis[laboratoryId][analysisId].additional_analysis![0].ref_laboratory_id] || !analysis[analysis[laboratoryId][analysisId].additional_analysis![0].ref_laboratory_id][analysis[laboratoryId][analysisId].additional_analysis![0].ref_analysis_id] || !analysis[analysis[laboratoryId][analysisId].additional_analysis![0].ref_laboratory_id][analysis[laboratoryId][analysisId].additional_analysis![0].ref_analysis_id].analysis_packages )){ // analysis_packages are recovered only by fetchSingle
                dispatch(fetchSingleAnalysis({laboratoryId: analysis[laboratoryId][analysisId].additional_analysis![0].ref_laboratory_id, analysisId: analysis[laboratoryId][analysisId].additional_analysis![0].ref_analysis_id}))
        }
    }, [analysis, analysisId])

    return (
        <Container className={analysis[laboratoryId] ? analysis[laboratoryId][analysisId] ? analysis[laboratoryId][analysisId].status == 'done' || analysis[laboratoryId][analysisId].status == 'partial' ? 'p-0 result-consult-container' : 'p-0' : 'p-0' : 'p-0'}>
            { employeesStatus === 'succeeded' && 
            companiesAllStatus === 'succeeded' && 
            typesStatus === 'succeeded' && 
            familiesStatus === 'succeeded' && 
            patternsStatus === 'succeeded' && 
            packagesStatus === 'succeeded' && 
            analytesStatus === 'succeeded' && 
            farmersStatusByUser === 'succeeded' && 
            impropersStatus === 'succeeded' &&
            fieldsStatus === 'succeeded' &&
            formsStatus === 'succeeded' &&
            nirsStatus === 'succeeded' &&
            analysis[laboratoryId] && analysis[laboratoryId][analysisId] && errorMessage === false && (analysis[laboratoryId][analysisId].id_pdc === null || (pdcStatus === 'succeeded' && controlPlans[laboratoryId] && controlPlans[laboratoryId][String(analysis[laboratoryId][analysisId].id_pdc)])) &&
            (analysis[laboratoryId][analysisId].analysis_packages != undefined || analysis[laboratoryId][analysisId].nutrient_result != undefined) &&
                <Container className={analysis[laboratoryId][analysisId].status == 'done' || analysis[laboratoryId][analysisId].status == 'partial' ? 'mt-2 p-0 result-consult-container' : 'mt-2 p-0'}>
                    { lg ? <ConsultAnalysis analysisDB={analysis[laboratoryId][analysisId]} /> : <ConsultAnalysisMobile analysisDB={analysis[laboratoryId][analysisId]} /> }
                </Container>
            }

            { (!analysis[laboratoryId] || !analysis[laboratoryId][analysisId]) && errorMessage &&
                <Container className="mt-2 bg-white text-danger">
                    <p>{t('home.analysis_request_ID_not_found')}</p>
                </Container>
            }
            { analysis[laboratoryId] && analysis[laboratoryId][analysisId] && (!controlPlans[laboratoryId] || !controlPlans[laboratoryId][String(analysis[laboratoryId][analysisId].id_pdc)]) && errorMessage &&
                <Container className="mt-2 bg-white text-danger">
                    <p>{t('home.ar_control_plan_not_found')}</p>
                </Container>
            }

            {((!analysis[laboratoryId] || !analysis[laboratoryId][analysisId]) && analysisStatus === 'loading' ||
            impropersStatus === 'loading' || 
            employeesStatus === 'loading' || 
            companiesAllStatus === 'loading' || 
            typesStatus === 'loading' || 
            familiesStatus === 'loading' || 
            patternsStatus === 'loading' || 
            packagesStatus === 'loading' || 
            analytesStatus === 'loading' || 
            fieldsStatus === 'loading' || 
            formsStatus === 'loading' ||  
            farmersStatusByUser === 'loading' ||
            nirsStatus === 'loading' ||
            (laboratoryId != undefined && analysisId != undefined && analysis[laboratoryId] && analysis[laboratoryId][analysisId] && (analysis[laboratoryId][analysisId].analysis_packages === undefined))) &&
                <Loader />
            }
            {((!analysis[laboratoryId] || !analysis[laboratoryId][analysisId]) && analysisStatus === 'failed' ||
            impropersStatus === 'failed' || 
            employeesStatus === 'failed' || 
            companiesAllStatus === 'failed' || 
            typesStatus === 'failed' || 
            familiesStatus === 'failed' || 
            patternsStatus === 'failed' || 
            packagesStatus === 'failed' || 
            analytesStatus === 'failed' || 
            fieldsStatus === 'failed' || 
            formsStatus === 'failed' ||  
            nirsStatus === 'failed' ||  
            farmersStatusByUser === 'failed') &&
                <Container className="mt-2 bg-white text-danger">
                    {analysisError && <p>{t('forms_errors.unexpected_error') + ' (' + analysisError + ')'}</p>}
                    {typesError && <p>{t('forms.analysis.type') + ' - ' + t('forms_errors.unexpected_error') + ' (' + typesError + ')'}</p>}
                    {familiesError && <p>{t('forms.analysis.family') + ' - ' + t('forms_errors.unexpected_error') + ' (' + familiesError + ')'}</p>}
                    {patternsError && <p>{t('forms.analysis.pattern') + ' - ' + t('forms_errors.unexpected_error') + ' (' + patternsError + ')'}</p>}
                    {farmersError && <p>{t('forms.analysis.farmer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + farmersError + ')'}</p>}
                    {companiesError && <p>{t('forms.analysis.customer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + companiesError + ')'}</p>}
                    {(packagesError || analytesError) && <p>{t('forms.analysis.management_packagesTests') + ' - ' + t('forms_errors.unexpected_error') + ' (' + (packagesError !== "undefined" ? packagesError : "") + (analytesError !== "undefined" ? analytesError : "") + ')'}</p>}
                    {(employeesStatus) && <p>{t('forms.analysis.technician') + ' - ' + t('forms_errors.unexpected_error') + ' (' + (employeesError !== "undefined" ? employeesError : "") + ')'}</p>}
                    {impropersError && <p>{t('forms.analysis.impropers.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + impropersError + ')'}</p>}
                    {fieldsError && <p>{"Fields" + ' - ' + t('forms_errors.unexpected_error') + ' (' + fieldsError + ')'}</p>}
                    {formsError && <p>{"Forms" + ' - ' + t('forms_errors.unexpected_error') + ' (' + formsError + ')'}</p>}
                    {nirsError && <p>{"Forms" + ' - ' + t('forms_errors.unexpected_error') + ' (' + nirsError + ')'}</p>}

                </Container>
            }
        </Container>
    )
}

export default withTranslation()(AnalysisContainer)