import moment from "moment";

export function downloadBlob(blob: string, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
}

export function getExportedFileName(tableName:string,extension:string) {
    const date = new Date();
    return `${tableName}_export_${moment(date).format('YYYYMMDD-HHmm')}.${extension}`
}