import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

const SWUpdateHandler: React.FC = () => {

  const history = useHistory();

  /**
   * Trigger service worker to check for update at each navigation event
   */
  useEffect(() => {
    history.listen(() => {
      navigator.serviceWorker
        .getRegistrations()
        .then((regs) => regs.forEach((reg) => reg.update()));
    });
  }, []);

  return null

};

export default SWUpdateHandler;