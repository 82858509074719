import { configureStore, PayloadAction } from '@reduxjs/toolkit'
import { triggerRefreshSession } from './authUserSlice'

import rootReducer, { RootState } from './rootReducer'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sessionRefresher = (store: any) => (next: any) => (action: PayloadAction) => {
  const state: RootState = store.getState()

  // TODO: arbitrary action exclusion, to be upgrade when all api interraction will be managed by slices and well identified by type
  if (!action.type.includes("local") && ! action.type.includes("Language") && !action.type.includes("authUser")) { 
    const autoRenewTimeout = state.local.session.autoRenewTimeout
    const expiresAt = state.authUser.session.expiresAt
    if (expiresAt) {
      const nowTimestamp = new Date().getTime()
      const expiresAtTimestamp = new Date(expiresAt).getTime()
      const expiresIn = Math.abs(expiresAtTimestamp - nowTimestamp) // - 1800000  + autoRenewTimeout + 15000 // for development purpose (- 30min + timeout + 15 sec, renew at 15 sec after login)
      
      if (expiresIn < autoRenewTimeout) {
        store.dispatch(triggerRefreshSession())
      }
    }
  }

  return next(action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => 
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['analysis/paginationReducer/fulfilled']
    }
  }).concat(sessionRefresher)
})

export type AppDispatch = typeof store.dispatch

export default store