import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { ReactComponent as AddCircleIcon } from 'bootstrap-icons/icons/plus-circle-fill.svg';
import { ReactComponent as BackIcon } from 'bootstrap-icons/icons/arrow-left-short.svg'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import useSecurityRoles from '../../../../common/auth/useSecurityRoles';

type SelectAnalysisMobileProps = WithTranslation


const SelectAnalysisMobile: React.FC<SelectAnalysisMobileProps> = ({ t }) => {

  const { userHasRoleAll, userHasRoleClient, userHasRoleTechnician } = useSecurityRoles()

  const hasRoleAll = userHasRoleAll()
  const hasRoleClient = userHasRoleClient()
  const hasRoleTechnician = userHasRoleTechnician()

  return (

    <Container>
      <Container className="mt-3 p-0 ">
        <Row >
          <Col xs="auto" className="pe-1 normaltext">
            <Link className="btn btn-link" to={"/secure/analysis"}>
              <BackIcon width="30px" height="100%" className="bg-primary text-white dot fw-bold" />
              <span className="ps-2 text-body fw-bold align-middle"> {t('analysis.return_to_my_list_analysis')} </span>
            </Link>
          </Col>
        </Row>
      </Container>

      <Container className="mt-5 p-4">
        {(hasRoleAll || hasRoleClient || hasRoleTechnician) &&
          <Link to={"/secure/analysis/fodder"} className="btn btn-light btn-lg mb-3 w-100" style={{ borderRadius: "50px" }}>
            <Row >
              <Col xs="auto" className="pe-1 normaltext">
                <AddCircleIcon width="50px" height="100%" style={{ color: "#228790" }} />
                <span className="ps-2 text-body fw-bold align-middle"> {t('analysis.new_fodder_mobile')} </span>
              </Col>
            </Row>
          </Link>
        }
        <Link to={"/secure/analysis/rawmaterial"} className="btn btn-light btn-lg mb-3 w-100" style={{ borderRadius: "50px" }}>
          <Row >
            <Col xs="auto" className="pe-1 normaltext">
              <AddCircleIcon width="50px" height="100%" style={{ color: "#228790" }} />
              <span className="ps-2 text-body fw-bold align-middle"> {t('analysis.new_rawMaterial_mobile')} </span>
            </Col>
          </Row>
        </Link>
        <Link to={"/secure/analysis/feed"} className="btn btn-light btn-lg mb-3 w-100" style={{ borderRadius: "50px" }}>
          <Row >
            <Col xs="auto" className="pe-0 normaltext">
              <AddCircleIcon width="50px" height="100%" style={{ color: "#228790" }} />
              <span className="ps-2 text-body fw-bold align-middle"> {t('analysis.new_feed_mobile')} </span>
            </Col>
          </Row>
        </Link>
      </Container>


    </Container>
  )

}

export default withTranslation()(SelectAnalysisMobile)