/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import env from "../common/utils/env";
import AbstractApi from "./AbstractApi";
import * as Downloader from "../common/utils/download";

export interface Nir {
    id?: string // computed id
    laboratory_id: number
    nir_code: string
    healthy?: 'healthy' | 'unhealthy'
    last_healthcheck: Date | null
    name: string
    consumer: string
    is_multiscan: boolean
    nb_scan: number
    equation_type: string
    address1: string
    address2: string
    zip_code: string
    city: string
    country: string
    phone: string
    email: string
    dm1_method: null | 'provided' | 'calculated'
    alert?:boolean
    default_company_id: number | null
    default_employee_id: number | null
    default_farmer_id: number | null
}

export interface Alert {
    uuid: string,
    laboratory_id: number,
    nir_code: string,
    timestamp: Date
    message: string
    params: object
}

export interface NirEquation {
    id?: string // computed id
    nir_type: string
    equation: string
}

export interface NirBinary {
    build: string
    checksum_sha256: string
    link: string
    name: string
    publish_date: Date
    release_notes: string
    version: string
}

export function getKeyForTabAssocNirEquation(nirEquation: NirEquation): string {
    return (
        nirEquation.equation +
        "#" +
        nirEquation.nir_type
    );
}

export function getKeyForTabAssocAlerts(alert: Alert): string {
    return (
        alert.laboratory_id +
        '#' +
        alert.nir_code +
        '#' +
        alert.uuid
    )
}

export function getNirId(nir: { laboratory_id: number, nir_code: string }): string {
    return `${nir.laboratory_id}#${nir.nir_code}`
}

class ExternalServicesApi extends AbstractApi {
    private static instance: ExternalServicesApi;

    public static EQUATION_TYPES = ["moist_sample", "dry_samples", "residual_moisture"]
    public static DM1_METHODS = ["provided", "calculated"]

    public static getInstance() {
        if (!ExternalServicesApi.instance) {
            throw new Error("Initialize instance before.");
        }
        return ExternalServicesApi.instance;
    }

    public static initInstance(token: string) {
        ExternalServicesApi.instance = new ExternalServicesApi(env.EXTERNAL_SERVICES_API_URL, token);
    }

    public async getAlerts(laboratory_id: number, code: string): Promise<Array<Alert>> {
        try {
            const response = await this.service.get(`/secure/laboratory/${laboratory_id}/nir/${code}/logs`)
            const data: Array<Alert> = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getNirs(laboratoryId?: number): Promise<Array<Nir>> {
        try {
            const response = await this.service.get(`/secure/laboratory/${laboratoryId ? laboratoryId + "/" : ""}nir`);
            const data: Array<Nir> = response.data;

            for (const dataElem of data) {
                dataElem.id = getNirId(dataElem);
            }
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async getNirsByLabId(laboratory_id: number): Promise<Array<Nir>> {
        try {
            const response = await this.service.get(`/secure/laboratory/${laboratory_id}/nir`)
            const data: Array<Nir> = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getNir(laboratory_id: number, code: string): Promise<Nir> {
        try {
            const response = await this.service.get(
                `/secure/laboratory/${laboratory_id}/nir/${encodeURIComponent(code)}`
            );
            const data: Nir = response.data;
            data.id = getNirId(data);
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async postNir(laboratory_id: number, nir: Omit<Nir, 'id' | 'healthy' | 'alerts'>): Promise<Nir> {
        try {
            const response = await this.service.post(`/secure/laboratory/${laboratory_id}/nir`, nir);
            const data: Nir = response.data;
            data.id = getNirId(data);
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async putNir(laboratory_id: number, code: string, nir: Omit<Nir, 'id' | 'healthy' | 'alerts'>): Promise<Nir> {
        try {
            const response = await this.service.put(`/secure/laboratory/${laboratory_id}/nir/${encodeURIComponent(code)}`, nir);
            const data: Nir = response.data;
            data.id = getNirId(data);
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async deleteNir(laboratory_id: number, code: string): Promise<void> {
        try {
            await this.service.delete(`/secure/laboratory/${laboratory_id}/nir/${encodeURIComponent(code)}`);
            return
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async deleteNirAlert(laboratory_id: number, code: string, alertId: string): Promise<void> {
        try {
            await this.service.delete(`/secure/laboratory/${laboratory_id}/nir/${encodeURIComponent(code)}/alerts/${encodeURIComponent(alertId)}`);
            return
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async deleteNirAlerts(laboratory_id: number, code: string): Promise<void> {
        try {
            await this.service.delete(`/secure/laboratory/${laboratory_id}/nir/${encodeURIComponent(code)}/alerts`);
            return
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async importNirEquations(file: File): Promise<Array<NirEquation>> {
        try {
            const formData = new FormData();
            formData.append("file", file, file.name);
            const response = await this.service.post(
                `/secure/nir/equations/import`,
                formData
            );
            return response.data || [];
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async exportNirEquations(): Promise<boolean> {
        try {
            const response = await this.service.get(
                `/secure/nir/equations/export`,
                { responseType: "blob" }
            );

            Downloader.downloadBlob(response.data, Downloader.getExportedFileName('nir-equations', 'xlsx'))
            return true;
        } catch (err) {
            throw await AbstractApi.handleBlobError(err);
        }
    }

    public async getNirEquations(): Promise<Array<NirEquation>> {
        try {
            const response = await this.service.get("/secure/nir/equations");
            const data: Array<NirEquation> = response.data;
            data.forEach((dataElem) => {
                dataElem.id = getKeyForTabAssocNirEquation(dataElem);
            });

            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async getNirBinaries(): Promise<Array<NirBinary>> {
        try {
            const response = await this.service.get(`/secure/binaries`);
            const data: Array<NirBinary> = response.data;
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async downloadNirBinary(build: string): Promise<Blob> {
        try {
            const response = await this.service.get(
                `/secure/binaries/${encodeURIComponent(build)}/download`, {responseType: 'blob', timeout: 600000}
            );
            return response.data || []
        } catch (err) {
            throw await AbstractApi.handleBlobError(err);
        }
    }

    public async deleteNirBinary(build: string): Promise<void> {
        try {
            await this.service.delete(`/secure/binaries/${encodeURIComponent(build)}`);
            return
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

}

export default ExternalServicesApi;
