import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import UsersApi, { UserRequest } from '../api/usersApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';

interface CurrentState {
    data: Data<UserRequest>
    filtered: Array<string>
    pagination: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postError: string | undefined
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    fetchStatus: 'idle',
    fetchError: undefined,
    singleFetchStatus: 'idle',
    singleFetchError: undefined,
    postStatus: 'idle',
    postError: undefined
} as CurrentState

export const fetchUserRequests = createAsyncThunk(
    'userRequests/fetchUserRequests', 
    async () => {
        const response = await UsersApi.getInstance().getUserRequests()
        return response as Array<UserRequest>
    }
)

export const fetchSingleUserRequest = createAsyncThunk(
    'userRequests/fetchSingleUserRequest', 
    async (id: number) => {
        const response = await UsersApi.getInstance().getUserRequest(id)
        return response as UserRequest
    }
)

export const deleteSingleUserRequest = createAsyncThunk<void, {id: number}>(
    'userRequests/deleteSingleUserRequest', 
    async ({id}: {id: number}) => {

        const reqId = Number(id)
        await UsersApi.getInstance().deleteUserRequestById(reqId)
        
        return 
    }
)

const userRequestSlice = createSlice({
    name: 'userRequests',
    initialState,
    reducers: {
        addUserRequest(state: CurrentState, action: PayloadAction<UserRequest>) {
            const item = action.payload
            if (item.id)
                state.data[item.id] = item
        },
        deleteUserRequest(state: CurrentState, action: PayloadAction<number>) {
            const id = action.payload
            if (id) {
                delete state.data[id]
                const index = state.filtered.indexOf(String(id))
                if (index > -1) {
                    state.filtered.splice(index, 1)
                }
            }
        },
        setFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload
        },
        setFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy
        },
        setSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload
        },
        resetSingleFetch(state: CurrentState) {
            state.singleFetchStatus = 'idle'
            state.singleFetchError = undefined
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchUserRequests.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchUserRequests.fulfilled, (state, action) => {
            state.fetchStatus = 'succeeded'
            action.payload.forEach((item: UserRequest) => {
                if (item.id)
                    state.data[item.id] = item
            })
        })
        builder.addCase(fetchUserRequests.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
        builder.addCase(fetchSingleUserRequest.pending, (state) => {
            state.singleFetchStatus = 'loading'
        })
        builder.addCase(fetchSingleUserRequest.fulfilled, (state, action) => {
            const item = action.payload
            if (item && item.id) {
                state.data[item.id] = item
            }
            state.singleFetchStatus = 'succeeded'
        })
        builder.addCase(fetchSingleUserRequest.rejected, (state, action) => {
            state.singleFetchStatus = 'failed'
            state.singleFetchError = action.error.message
        })
    }
  })

  export const {
    addUserRequest,
    deleteUserRequest,
    setFiltered,
    setFilterBy,
    setSortBy,
    resetSingleFetch
  } = userRequestSlice.actions

  export default userRequestSlice.reducer