import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/rootReducer'

import Container from 'react-bootstrap/Container';

import Loader from '../../../common/components/Loader'
import AnalysisList from './components/AnalysisList'

import { fetchFamilies, fetchTypes, fetchPatterns, fetchLaboratories, fetchImpropers } from '../../../state/configSlice'
import { fetchAllCompanies } from '../../../state/companiesSlice'
import { fetchEmployees } from '../../../state/employeesSlice'
import { fetchFarmers } from '../../../state/farmerSlice'
import { fetchAnalysis, filterByReducer } from '../../../state/analysisSlice'

import { fetchPdcDA } from '../../../state/pdcSlice';
import moment from 'moment';

import useQuery from '../../../common/utils/useQuery'

type AnalysisListContainerProps = WithTranslation

const AnalysisListContainer: React.FC<AnalysisListContainerProps> = ({ t }) => {
    const query = useQuery();

    const gteDateQuery = query.get("gteDateQuery")
    const lteDateQuery = query.get("lteDateQuery")
    const employeeQuery = query.get("employee")
    const companyQuery = query.get("company")
    const statusQuery = query.get("status")
    const laboratoryQuery = query.get("laboratory")

    const dispatch = useDispatch()
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    // pdc state
    const pdcStatus = useSelector((state: RootState) => state.pdc.fetchDAStatus)
    const pdcError = useSelector((state: RootState) => state.pdc.fetchError)

    // Types state
    const typesStatus = useSelector((state: RootState) => state.config.types.fetchStatus)
    const typesError = useSelector((state: RootState) => state.config.types.fetchError)

    // Patterns state
    const patternsStatus = useSelector((state: RootState) => state.config.patterns.fetchStatus)
    const patternsError = useSelector((state: RootState) => state.config.patterns.fetchError)

    // Employees state
    const employees = useSelector((state: RootState) => state.employees.data)
    const employeesStatus = useSelector((state: RootState) => state.employees.fetchStatus)
    const employeesError = useSelector((state: RootState) => state.employees.fetchError)

    // Companies state
    const companies = useSelector((state: RootState) => state.companies.data)
    const companiesAllStatus = useSelector((state: RootState) => state.companies.fetchAllStatus)
    const companiesError = useSelector((state: RootState) => state.companies.fetchError)

    // Laboratories state
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const laboratoriesError = useSelector((state: RootState) => state.config.laboratories.fetchError)

    // families state
    const familiesStatus = useSelector((state: RootState) => state.config.families.fetchStatus)
    const familiesError = useSelector((state: RootState) => state.config.families.fetchError)

    // Farmers state
    const farmersStatusByUser = useSelector((state: RootState) => state.farmers.farmer.fetchStatusByUser)
    const farmersError = useSelector((state: RootState) => state.farmers.farmer.fetchError)

    // Impropers state
    const impropersStatus = useSelector((state: RootState) => state.config.impropers.fetchStatus)
    const impropersError = useSelector((state: RootState) => state.config.impropers.fetchError)

    // Analysis state
    const analysis = useSelector((state: RootState) => state.analysis.analysis.data)
    const fetchStatus = useSelector((state: RootState) => state.analysis.analysis.fetchStatus)
    const fetchError = useSelector((state: RootState) => state.analysis.analysis.fetchError)
    const pagination = useSelector((state: RootState) => state.analysis.analysis.pagination)

    useEffect(() => {
        if(companiesAllStatus === 'succeeded' && employeesStatus === 'succeeded' && (laboratoryQuery || gteDateQuery || lteDateQuery || employeeQuery || companyQuery || statusQuery)){
            const filter = Object.assign({},
                gteDateQuery ||  lteDateQuery ? {last_date_all: {gte: gteDateQuery ? moment.utc(gteDateQuery).format() : null, lte: lteDateQuery ? moment.utc(lteDateQuery).format() : null}} : null,
                statusQuery ? {status: {in: statusQuery.split(',')}} : null,
                laboratoryQuery? {laboratory: {in: [Number(laboratoryQuery)]}} : null,
                employeeQuery ? {employee: {like: employees[employeeQuery].firstname + " " + employees[employeeQuery].lastname}} : null,
                companyQuery ? {company: {like: companies[companyQuery].name}} : null
            )
            dispatch(filterByReducer(filter))
        }
    }, [companies, companiesAllStatus, companyQuery, gteDateQuery, lteDateQuery, laboratoryQuery, dispatch, employeeQuery, employees, employeesStatus,  statusQuery])

    useEffect(() => {
        if (pdcStatus === 'idle') {
            dispatch(fetchPdcDA({page: 0}))
        }
        if (employeesStatus === 'idle') {
            dispatch(fetchEmployees())
        }
        if (typesStatus === 'idle') {
            dispatch(fetchTypes())
            
        }
        if (patternsStatus === 'idle') {
            dispatch(fetchPatterns())
        }
        if (companiesAllStatus === 'idle') {
            dispatch(fetchAllCompanies())
        }
        if (familiesStatus === 'idle') {
            dispatch(fetchFamilies())
        }
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
    }
        if (farmersStatusByUser === 'idle') {
            dispatch(fetchFarmers())
        }
        if (impropersStatus === 'idle') {
            dispatch(fetchImpropers())
        }
        if (pdcStatus === 'succeeded'
            && employeesStatus === 'succeeded'
            && typesStatus === 'succeeded'
            && patternsStatus === 'succeeded'
            && familiesStatus === 'succeeded'
            && companiesAllStatus === 'succeeded'
            && farmersStatusByUser === 'succeeded'
            && impropersStatus === 'succeeded'
            && fetchStatus === 'idle') {
            dispatch(fetchAnalysis({page: 1}))
        }

    }, [authUser, analysis, familiesStatus, employeesStatus, fetchStatus, typesStatus, patternsStatus, companiesAllStatus, farmersStatusByUser, pdcStatus, impropersStatus, dispatch])

    useEffect(() => {
        if (pagination.filterBy && pagination.status === 'idle' && (!laboratoryQuery && !gteDateQuery && !lteDateQuery && !employeeQuery && !companyQuery && !statusQuery)) {
            dispatch(fetchAnalysis({page: 1}))
        }
        
    }, [dispatch, pagination.filterBy, pagination.sortBy, pagination.status])

    return (
        <>
            {(pdcStatus === 'loading' || familiesStatus === 'loading' || fetchStatus === 'loading' || typesStatus === 'loading' || patternsStatus === 'loading' || employeesStatus === 'loading' || companiesAllStatus === 'loading' || farmersStatusByUser === 'loading' || impropersStatus === 'loading') &&
                <Loader />
            }
            {pdcStatus === 'succeeded' && familiesStatus === 'succeeded' && fetchStatus === 'succeeded' && typesStatus === 'succeeded' && patternsStatus === 'succeeded' && employeesStatus === 'succeeded' && companiesAllStatus === 'succeeded' && farmersStatusByUser === 'succeeded' && impropersStatus === 'succeeded' &&
                <AnalysisList />
            }
            {(pdcStatus === 'failed' || familiesStatus === 'failed' || fetchStatus === 'failed' || typesStatus === 'failed' || patternsStatus === 'failed' || employeesStatus === 'failed' || companiesAllStatus === 'failed' || farmersStatusByUser === 'failed' || impropersStatus === 'failed') &&
                <Container className="mt-2 bg-white text-danger">
                    {laboratoriesError && <p>{t('analysis_list.table.columns.laboratory') + ' - ' + t('forms_errors.unexpected_error') + ' (' + laboratoriesError + ')'}</p>}
                    {familiesError && <p>{t('analysis_list.table.columns.product') + ' - ' + t('forms_errors.unexpected_error') + ' (' + familiesError + ')'}</p>}
                    {typesError && <p>{t('analysis_list.table.columns.echantillon') + ' - ' + t('forms_errors.unexpected_error') + ' (' + typesError + ')'}</p>}
                    {patternsError && <p>{t('analysis_list.table.columns.motif') + ' - ' + t('forms_errors.unexpected_error') + ' (' + patternsError + ')'}</p>}
                    {employeesError && <p>{t('analysis_list.table.columns.applicant') + ' - ' + t('forms_errors.unexpected_error') + ' (' + employeesError + ')'}</p>}
                    {companiesError && <p>{t('analysis_list.table.columns.nameCustomer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + companiesError + ')'}</p>}
                    {farmersError && <p>{t('analysis_list.table.columns.farmer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + farmersError + ')'}</p>}
                    {impropersError && <p>{t('forms.analysis.impropers.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + impropersError + ')'}</p>}
                    {pdcError && <p>{t('analysis_list.table.columns.pdc') + ' - ' + t('forms_errors.unexpected_error') + ' (' + pdcError + ')'}</p>}
                    {fetchError && <p>{t('analysis_list.see_my_analysis_requests') + ' - ' + t('forms_errors.unexpected_error') + ' (' + fetchError + ')'}</p>}
                </Container>

            }


        </>
    )
}

export default withTranslation()(AnalysisListContainer)
