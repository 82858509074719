import React from 'react';
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { withTranslation, WithTranslation } from 'react-i18next';

import { RootState } from '../../state/rootReducer';
import { useSelector } from 'react-redux';

interface StatusProps  extends WithTranslation {
    Date1?:string
    Date2?:string
    Date3?:string
    Date4?:string
    Date5?:string
    Date6?:string
    Date7?:string
    Status?:string
}


const StatusBar: React.FC<StatusProps> = ({t,Date1 = "",Date2= "",Date3= "",Date4= "",Date5= "",Date6= "",Date7= "", Status= ""}) => {

    const { language } = useSelector( (state: RootState) => state.local)

    return (
        <Container>
            <Row className="text-center">
                <Col sm={2} className="p-0"  >
                    <Row className="justify-content-md-center" >
                        <Col className="p-0 my-auto"></Col>
                        <Col sm="auto" className="p-0" >
                            <span className={`bg-primary dot fw-bold text-white` }>1</span>
                        </Col>
                        <Col  className="p-0 my-auto"  >
                            <span className="bg-primary lineStatus"></span>
                        </Col>
                    </Row>
                </Col>
                <Col sm={2} className="p-0"  >
                    <Row className="justify-content-md-center" >
                        <Col   className="p-0 my-auto">
                            <span className="bg-primary lineStatus"> </span>
                        </Col>
                        <Col sm="auto" className="p-0" >
                            <span className="bg-primary text-white dot fw-bold">2</span>
                        </Col>
                        <Col  className="p-0 my-auto"  >
                            <span className={!Date3 && !Date6 && ! Date7 ? "bg-dark lineStatus" : "bg-primary lineStatus"}></span>
                        </Col>
                    </Row>
                </Col>
                <Col sm={2} className="p-0"  >
                    <Row className="justify-content-md-center" >
                   
                        <Col  className="p-0 my-auto">
                            <span className={!Date3 && !Date6 && ! Date7 ? "bg-dark lineStatus" : "bg-primary lineStatus"}> </span>
                        </Col>
                        <Col sm="auto" className="p-0" >
                            <span className={!Date3 && !Date6 && ! Date7 ? "bg-dark text-white dot fw-bold" : "bg-primary text-white dot fw-bold"}>3</span>
                        </Col>
                        <Col className="p-0 my-auto"  >
                            <span className={!Date4 && !Date6 && ! Date7 ? "bg-dark lineStatus" : "bg-primary lineStatus"}></span>
                        </Col>
                    </Row>
                </Col>
                <Col sm={2} className="p-0"  >
                    <Row className="justify-content-md-center" >
                        <Col  className="p-0 my-auto">
                            <span className={!Date4 && !Date6 && ! Date7 ? "bg-dark lineStatus" : "bg-primary lineStatus"}> </span>
                        </Col>
                        <Col sm="auto" className="p-0" >
                            <span className={!Date4 && !Date6 && ! Date7 ? "bg-dark text-white dot fw-bold" : "bg-primary text-white dot fw-bold"}>4</span>
                        </Col>
                        <Col  className="p-0 my-auto"  >
                            <span className={!Date5 && !Date6 && ! Date7 ? "bg-dark lineStatus" : "bg-primary lineStatus"}></span>
                        </Col>
                    </Row>
                </Col>
                <Col sm={2} className="p-0"  >
                    <Row className="justify-content-md-center" >
                   
                        <Col className="p-0 my-auto">
                            <span className={!Date5 && !Date6 && ! Date7 ? "bg-dark lineStatus" : "bg-primary lineStatus"}> </span>
                        </Col>
                        <Col sm="auto" className="p-0" >
                            <span className={!Date5 && !Date6 && ! Date7 ? "bg-dark text-white dot fw-bold" : "bg-primary text-white dot fw-bold"}>5</span>
                        </Col>
                        <Col  className="p-0 my-auto"  >
                            <span className={!Date6 && ! Date7 ? "bg-dark lineStatus" : "bg-primary lineStatus"}></span>
                        </Col>
                    </Row>
                </Col>
                <Col sm={2} className="p-0"  >
                    <Row className="justify-content-md-center" >
                   
                        <Col className="p-0 my-auto">
                            <span className={!Date6 && ! Date7 ? "bg-dark lineStatus" : "bg-primary lineStatus"}> </span>
                        </Col>
                        <Col sm="auto" className="p-0" >
                            <span className={!Date6 && !Date7 ? "bg-dark text-white dot fw-bold" : "bg-primary text-white dot fw-bold"}>6</span>
                        </Col>
                        <Col className="p-0 my-auto"></Col>
                    </Row>
                </Col>
            </Row>
            <Row className="pt-2 pb-2 justify-content-md-center" >
                <Col sm={2} className="text-center">      
                <span className={Date1!== "" ? "fw-bold" : "text-black-50"}> {Date1 !== ""? new Intl.DateTimeFormat(language.locale).format(new Date(Date1)) : ""  } <br/>  {t('analysis.status_analysis.creation')} </span>
                </Col>
                <Col sm={2} className="text-center">
                <span className={Date2!== "" ? "fw-bold" : "text-black-50"}> {Date2 !== ""? new Intl.DateTimeFormat(language.locale).format(new Date(Date2)) : "" } <br/> {t('analysis.status_analysis.last_edition')} </span>
                </Col>
                <Col sm={2} className="text-center">
                <span className={Date3!== "" ? "fw-bold" : "text-black-50"}> {Date3 !== ""? new Intl.DateTimeFormat(language.locale).format(new Date(Date3)) : "" } <br/> {t('analysis.status_analysis.reception')} </span>
                </Col>
                <Col sm={2} className="text-center">
                <span className={Date4!== "" ? "fw-bold" : "text-black-50"}> {Date4 !== ""? new Intl.DateTimeFormat(language.locale).format(new Date(Date4)) : "" } <br/> {t('analysis.status_analysis.compliance')} </span>
                </Col>
                <Col sm={2} className="text-center">
                <span className={Date5!== "" ? "fw-bold" : "text-black-50"}> {Date5 !== ""? new Intl.DateTimeFormat(language.locale).format(new Date(Date5)) : "" } <br/> {t('analysis.status_analysis.registration')} </span>
                </Col>
                <Col sm={2} className="text-center">
                <span className={Date6!== "" || Date7 !== "" ? "fw-bold" : "text-black-50"}> {Date7 !== "" ? new Intl.DateTimeFormat(language.locale).format(new Date(Date7)) : Date6 !== "" ? new Intl.DateTimeFormat(language.locale).format(new Date(Date6)) : "" } <br/> {Status === "canceled" ? t('analysis.status_analysis.cancellation'): Status === "done" ? t('analysis.status_analysis.done') : Status === "partial" ? t('analysis.status_analysis.partial') : ""} </span>
                </Col>
            </Row>
        </Container>
    )
}

export default withTranslation()(StatusBar)
