import React from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ApiError from './exceptions/ApiError'
import SubErrorDetails from './exceptions/SubErrorDetails'
import { buildImportPrefix } from './utils'

interface Props {
    title: string
    message: string
    type?: 'import' | 'default'
    error?: unknown
    onHide: () => void
}

const ExceptionModal: React.FC<Props> = ({ title, message, type = "default", error, onHide }: Props) => {
    const { t } = useTranslation()

    const ErrorLabel: React.FC<{ err: unknown }> = ({ err }: { err: unknown }) => {
        let label = t('errors.unknown')
        if ((err instanceof ApiError) || ( err && (err as any)['message']) && (err as any)['type']) {
            label = t(`errors.${(err as any).type}`) + ` (${(err as any).message})`
        } else if ((err instanceof Error) || (err && (err as any)['message'])) {
            label = t(`errors.internal_server_error`) + ` (${(err as any).message})`
        }

        return (
            <div>
                <br />
                <span>{`${label}`}</span>
                <br />
            </div>
        )
    }

   

    const ErrorDetails: React.FC<{ err: unknown }> = ({ err }: { err: unknown }) => {
        if (err instanceof ApiError && err.errors && err.errors.length > 0) {
            return (
                <div className="smalltext">
                    <span>{t('errors.details')}</span>
                    <ul>
                        {err.errors.map((e, index) => {
                            if (type === 'import' && e.errorCode) {
                                const detailMsg = t(`errors.import.${e.errorCode}`)
                                const prefix = buildImportPrefix(e)
                                return <li key={index}>{`${prefix}${detailMsg} (${e.message})`}</li>
                            } else {
                                return <li key={index}>{`${e.message}`}</li>
                            }
                        })}
                    </ul>
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <Modal size="lg" show={error !== undefined} onHide={onHide}>
            <Modal.Header closeButton={error !== undefined}>
                <Modal.Title>
                    <span className="text-primary fw-bold">{title}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="danger">
                    <span>{message}</span>
                    <ErrorLabel err={error} />
                    <ErrorDetails err={error} />
                </Alert>
            </Modal.Body>
        </Modal>
    )
}

export default ExceptionModal
