import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from "react-router-dom";

import SignUpForm from './components/SignUpForm';

type UsersContainerProps = WithTranslation

const SignUpContainer: React.FC<UsersContainerProps> = () => {
    
    return (
        <Container className="bg-white pb-2">
            <Row>
                <Col className="text-center mt-3">
                    <Link to='/'>
                        <img
                            alt=""
                            src="/provilab-30.png"
                            width="auto"
                            height="30"
                            className="align-top d-inline-block d-md-none"
                        />
                        <img
                            alt=""
                            src="/provilab-60.png"
                            width="auto"
                            height="60"
                            className="align-top d-none d-md-inline-block"
                        />
                    </Link>
                </Col>
            </Row>
            <SignUpForm />
        </Container>
    )
}

export default withTranslation()(SignUpContainer)