import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Container, Button, Modal, Form, Table } from 'react-bootstrap';
import { GroupBy, setGroupBy, setSamplesFilterBy, setSamplesSortBy, setSelectOptions } from '../../../state/dashboardSlice';
import { Formik } from 'formik';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';
import Select from 'react-select'
import { buildSelectNormalStyle } from '../../../common/utils/utils';
import { ReactComponent as SquareIcon } from 'bootstrap-icons/icons/square-fill.svg'
import { others } from './DashboardGroupTableComponent';
import { DashboardAnalysisHeader } from '../../../api/dashboardApi';
import { DatasCalculated } from './utils';
import { Data } from '../../../types/types';

interface ModalDashboardOptionsGroupsComponentContainerProps extends WithTranslation {
    dashboardName: string,
    showModal: boolean,
    setShowModal: any
}

interface FieldSelect {
    value: string
    label: string
}

interface ValueGroup {
    value: string
    color: string
}

const resultAnalysisFilterToExclude = [
    'type_code',
    'type_name',
    'family_jcode',
    'pattern',
    'control_plan',
    'last_update',
    'is_mdi_ok',
    'is_gh_ok',
    'is_in_between_limits',
    'day',
    'month',
    'group',
    'sample_date',
    'delivery_date'
]

const colors = [
    '#36a2eb',
    '#ff6384',
    '#4bc0c0',
    '#ff9f40',
    '#9966ff',
    '#ffcd56',
    '#c9cbcf'
]

const ModalDashboardOptionsGroupsComponent: React.FC<ModalDashboardOptionsGroupsComponentContainerProps> = ({ t, dashboardName, showModal, setShowModal }) => {
    const dispatch = useDispatch()

    // samples state
    const filters = useSelector((state: RootState) => state.dashboard.filters)
    const datas = useSelector((state: RootState) => state.dashboard.samplesForData)
    const filtersForGroupBy = useSelector((state: RootState) => state.dashboard.filtersForGroupBy)
    const groupBy = useSelector((state: RootState) => state.dashboard.groupBy)
    const pagination = useSelector((state: RootState) => state.dashboard.paginationSamples)
    const maxValues = useSelector((state: RootState) => state.manageDashboard.data)[`${dashboardName}#dashboard_analysis_header`]?.properties.max_group_values

    // types for fields
    const fields = () => {
        const fields = {
            value: {
                type: [],
                sortable: false
            },
            color: {
                type: [],
                sortable: false
            }
        }
        return fields
    }

    const { ListHeader } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "dashboard.options.modal_group.columns.",
        onlyActive: false,
        size: 'xs',
        fields: fields(),
        reducers: {
            filterBy: setSamplesFilterBy,
            sortBy: setSamplesSortBy
        }
    })

    let initialValues: GroupBy = {...groupBy, values: groupBy.values.filter(v => v.value !== others)}

    return (
        <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title><span className="text-primary fw-bold"> {t('dashboard.options.modal_group.title')} </span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        dispatch(setGroupBy(values))
                        setShowModal(false)
                    }}
                >
                    {/* Callback function containing Formik state and helpers that handle common form actions */}
                    {function myForm({   
                        values,
                        setValues,
                        setFieldValue,
                        handleSubmit }){
                            useEffect(() => {
                                dispatch(setGroupBy(groupBy))
                            }, [groupBy])
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group>
                                        <Container className='d-flex'>
                                            <Form.Label className="text-break">{t('dashboard.options.modal_group.group_by')} : *</Form.Label>
                                            <Container className='m-0 w-75'>
                                                <Select
                                                        name="field"
                                                        value={values.field === '' ? null : {label: values.field, value: values.field}}
                                                        isClearable={false}
                                                        placeholder={t('dashboard.options.modal_group.select_placeholder')}
                                                        options={filters.filter.filter(f => !resultAnalysisFilterToExclude.includes(f.field) && f.values.filter(v => (!filtersForGroupBy.find(field => field.field === f.field) || filtersForGroupBy.find(field => field.field === f.field)!.values.includes(v)) && Object.values(datas).filter(d => d[f.field as keyof DashboardAnalysisHeader] && d[f.field as keyof DashboardAnalysisHeader].toString() === v).length > 0).length).map(f => ({label: f.field, value: f.field}))}
                                                        getOptionLabel={(field: FieldSelect) => t(`dashboard.filter.form.add_filter.${field.value}`)?.includes('dashboard.filter.form.add_filter.') ? field.value : t(`dashboard.filter.form.add_filter.${field.value}`)}
                                                        getOptionValue={(field: FieldSelect) => field.value}
                                                        onChange={(field: FieldSelect) => {
                                                            const filtersOnField = filtersForGroupBy.find(f => f.field === field.value)
                                                            const filter = filters.filter.find(f => f.field === field.value)!
                                                            const valuesFilter = filter.values.filter(v => (!filtersOnField || filtersOnField.values.includes(v)) && Object.values(datas).filter(d => d[field.value as keyof DashboardAnalysisHeader] && d[field.value as keyof DashboardAnalysisHeader].toString() === v).length > 0)
                                                            if(valuesFilter.length <= maxValues){
                                                                const datasOfAnalytes: Data<DatasCalculated> = {}
                                                                const valuesForForm =  valuesFilter.map((v, index) => ({value: v, color: index > 6 ? '' : colors[index], dataOfAnalytes: datasOfAnalytes}))
                                                                setValues({field: field.value, values: valuesForForm})
                                                            }else{
                                                                setValues({field: field.value, values: groupBy.values.filter(v => v.value !== others)})
                                                            }
                                                        }}
                                                        styles={buildSelectNormalStyle()}
                                                    />
                                            </Container>
                                        </Container>
                                    </Form.Group>
                                    <Container className='mt-3'>
                                        <Table striped hover size='sm' className="bg-white text-center table-fixed table-xs">
                                            <thead className='bg-white'>
                                                <tr>
                                                    <th className="p-0" />
                                                    <th className="p-1">
                                                        <ListHeader name="value" />
                                                    </th>
                                                    <th className="p-1">
                                                        <ListHeader name="color" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filters.filter.find(f => f.field === values.field) && filters.filter.find(f => f.field === values.field)!.values.filter(v => Object.values(datas).filter(d => d[values.field as keyof DashboardAnalysisHeader] && d[values.field as keyof DashboardAnalysisHeader].toString() === v).length > 0).length > maxValues &&
                                                    <tr key='max_value'>
                                                        <td className="p-1 table-hover-hint" />
                                                        <td colSpan={2} className="text-danger">{t('dashboard.options.modal_group.max_values')}</td>
                                                    </tr>
                                                }
                                                {values.values && values.values.map((v: ValueGroup, index) => {
                                                    return (
                                                        <tr key={v.value}>
                                                            <td className="p-1 table-hover-hint" />
                                                            <td>{v.value}</td>
                                                            <td>
                                                                <Form.Group controlId={`color-${v.value}`}>
                                                                    <Select
                                                                        name={`values[${index}].color`}
                                                                        value={{color: v.color, value: v.value} || null}
                                                                        isClearable={false}
                                                                        isSearchable={false}
                                                                        getOptionValue={(color: ValueGroup) => color.color}
                                                                        formatOptionLabel={(color: ValueGroup) => {
                                                                            if (color && color.color) {
                                                                                return (
                                                                                    <SquareIcon width={25} height={25} style={{color: `${color.color}`}} />
                                                                                )
                                                                            }
                                                                        }}
                                                                        options={colors.map(c => ({color: c, value: c}))}
                                                                        onChange={(color: ValueGroup) => {
                                                                            if (color) {
                                                                                setFieldValue(`values[${index}].color`, color.color)
                                                                            } else {
                                                                                setFieldValue(`values[${index}].color`, "")
                                                                            }
                                                                        }}
                                                                        styles={buildSelectNormalStyle()}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                )})}
                                            </tbody>
                                        </Table>
                                    </Container>
                                    <Container className='d-flex justify-content-end'>
                                        <Button className="m-1 no-print" type="submit" variant="secondary" disabled={filters.filter.find(f => f.field === values.field) && filters.filter.find(f => f.field === values.field)!.values.filter(v => Object.values(datas).filter(d => d[values.field as keyof DashboardAnalysisHeader] && d[values.field as keyof DashboardAnalysisHeader].toString() === v).length > 0).length > maxValues} > {t('dashboard.options.modal_group.validate')}</Button>
                                    </Container>
                                </Form>
                            );
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default withTranslation()(ModalDashboardOptionsGroupsComponent)