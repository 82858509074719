import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import NavBox from '../../common/components/NavBox';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import userHasRequiredRoles from '../../common/auth/userHasRequiredRoles';


type MatrixManagementContainerProps = WithTranslation

const MatrixManagementContainer: React.FC<MatrixManagementContainerProps> = ({ t }) => {

    return (
        <Container>
            {userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_nutrition']) &&
                <Row className="mt-2">
                    <Col lg>
                        <NavBox
                            label={t('matrix.nav.labels.matrix_management')}
                            nbButtonMaxPLine={4}
                            buttons={[
                                { key: t('matrix.nav.buttons.matrix'), link: '/secure/matrix_management/matrix' },
                                { key: t('matrix.nav.buttons.input_data'), link: '/secure/matrix_management/input_data' },
                                { key: t('matrix.nav.buttons.exports'), link: '/secure/matrix_management/exports' },
                                { key: t('matrix.nav.buttons.company'), link: '/secure/matrix_management/companies' }
                            ]}
                        />
                    </Col>
                </Row>
            }
        </Container>
    )
}

export default withTranslation() (MatrixManagementContainer)