import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import ConfigApi, {
    LabSettings,
    LabSettingsFilter,
    LabSettingsKey,
    
} from "../api/configApi";
import { Data, FilterBy, Pagination, SortBy } from "../types/types";

interface CurrentState {
    data: Data<LabSettings>;
    filtered: Array<string>;
    pagination: Pagination;
    fetchStatus: "idle" | "loading" | "succeeded" | "failed";
    fetchError: string | undefined;
    singleFetchStatus: "idle" | "loading" | "succeeded" | "failed";
    singleFetchError: string | undefined;
    postStatus: "idle" | "loading" | "succeeded" | "failed";
    postError: string | undefined;
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    byCompany: {},
    fetchStatus: "idle",
    fetchError: undefined,
    singleFetchStatus: "idle",
    singleFetchError: undefined,
    postStatus: "idle",
    postError: undefined,
} as CurrentState;

export const fetchLabSettings = createAsyncThunk(
    "fetchLabSettings",
    async (settingsName: string) => {      
        const response = await ConfigApi.getInstance().getLabSettings(settingsName);
        return response as Array<LabSettings>;
    }
); 

export const deleteLabSettings = createAsyncThunk(
    "deleteLabSettings",
    async (labSettings: LabSettings, { dispatch }) => {
        const response = await ConfigApi.getInstance().deleteLabSettings(
            labSettings
        );

        if (!response) {
            throw new Error("Unable to delete lab settings");
        }

        dispatch(deleteLabSettingsReducer(labSettings));

        return true;
    }
);

const labSettingsSlice = createSlice({
    name: "labSettings",
    initialState,
    reducers: {
        updateLabSettingsReducer(
            state: CurrentState,
            action: PayloadAction<LabSettings>
        ) {
            const item = action.payload;
            if (item.id) state.data[item.id] = item;
        },

        addLabSettingsReducer(
            state: CurrentState,
            action: PayloadAction<LabSettings>
        ) {
            const item = action.payload;
            if (item.id) state.data[item.id] = item;

        },

        deleteLabSettingsReducer(
            state: CurrentState,
            action: PayloadAction<LabSettings>
        ) {
            const item = action.payload;
            const labSettingsKey: LabSettingsKey = {
                laboratory_id: item.laboratory_id,
                settings_name: item.settings_name
            }
            const key = ConfigApi.getInstance().getLabSettingsId(labSettingsKey)
            if (labSettingsKey.laboratory_id && labSettingsKey.settings_name) {
                delete state.data[key];
                state.filtered.splice(state.filtered.indexOf(key), 1)
            }
        },

        setLabSettingsFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload;

        },
        setLabSettingsFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload };
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all;
            }
            state.pagination.filterBy = filterBy;
        },
        setLabSettingsSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload;
        },
        refreshLabSettings(state: CurrentState) {
            state.fetchStatus = "idle";
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchLabSettings.pending, (state) => {
            state.fetchStatus = "loading";
        });
        builder.addCase(fetchLabSettings.fulfilled, (state, action) => {

            action.payload.forEach((item: LabSettings) => {
                if (item.id) state.data[item.id] = item
            });
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchLabSettings.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
    },
});

export const {
    addLabSettingsReducer,
    updateLabSettingsReducer,
    deleteLabSettingsReducer,
    setLabSettingsFiltered,
    setLabSettingsFilterBy,
    setLabSettingsSortBy,
    refreshLabSettings,
} = labSettingsSlice.actions;

export default labSettingsSlice.reducer;