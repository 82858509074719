import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import ConfigApi, { ProductDenomination } from '../api/configApi';
import { Data, FilterBy, Pagination, SortBy } from "../types/types";

interface CurrentState {
    data: Data<ProductDenomination>;
    filtered: Array<string>;
    pagination: Pagination;
    fetchStatus: "idle" | "loading" | "succeeded" | "failed";
    fetchError: string | undefined;
    singleFetchStatus: "idle" | "loading" | "succeeded" | "failed";
    singleFetchError: string | undefined;
    postStatus: "idle" | "loading" | "succeeded" | "failed";
    postError: string | undefined;
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    byCompany: {},
    fetchStatus: "idle",
    fetchError: undefined,
    singleFetchStatus: "idle",
    singleFetchError: undefined,
    postStatus: "idle",
    postError: undefined,
} as CurrentState;

export const fetchProductDenominations= createAsyncThunk(
    'config/fetchProductDenominations', 
    async (v, {dispatch}) => {
        dispatch(purgeReducer())
        
        const res = await ConfigApi.getInstance().getProductDenominations()

        return res 
    }
)

export const fetchProductDenominationsByLaboratory= createAsyncThunk(
    'config/fetchProductDenominationsByLaboratory', 
    async (lab_id: number, {dispatch}) => {
        dispatch(purgeReducer())
        
        const res = await ConfigApi.getInstance().getProductDenominationsByLaboratory(lab_id)

        return res 
    }
)

export const fetchImportProductDenominations = createAsyncThunk(
    'config/fetchImportProductDenominations', 
    async ({lab_id, file}:{lab_id: number, file: File}) => {
       return await ConfigApi.getInstance().importProductDenominations(lab_id, file)
    }
)

export const fetchExportProductDenominations = createAsyncThunk(
    'config/fetchExportProductDenominations', 
    async (lab_id:number) => {
       return await ConfigApi.getInstance().exportProductDenominations(lab_id)
    }
)

export const desactivateProductDenomination = createAsyncThunk(
    'config/desactivateProductDenomination',
    async ({lab_id, id}: {lab_id: number, id: number}, {dispatch}) => {
        const response = await ConfigApi.getInstance().desactivateProductDenomination(lab_id, id)

        if (!response) {
            throw new Error("Unable to delete product denomination")
        }

        dispatch(deleteReducer(id))

        return true
    }
)


const productDenominationsSlice = createSlice({
    name: "productDenominations",
    initialState,
    reducers: {

        purgeReducer(state: CurrentState, action: PayloadAction<void>) {
            state.data = {}
            state.filtered = []
        },

        deleteReducer(
            state: CurrentState,
            action: PayloadAction<number>
        ) {
            const item = action.payload;
            if (item) {
                state.data[item].active=false;
            }
        },

        setProductDenominationsFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload;
        },
        setProductDenominationsFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload };
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all;
            }
            state.pagination.filterBy = filterBy;
        },
        setProductDenominationsSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload;
        },
        refreshProductDenominations(state: CurrentState) {
            state.fetchStatus = "idle";
        },
        requestPage(state: CurrentState, action: PayloadAction<number>) {
             state.pagination.currentPage = action.payload;
        },
        setNbByPage(state: CurrentState, action: PayloadAction<number>) {
            const nbByPage = action.payload;

            state.pagination.nbByPage = nbByPage;
            const count =  state.filtered.length;
            state.pagination.count = count;
            state.pagination.nbPage = Math.ceil(
                count /  state.pagination.nbByPage
            );
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchProductDenominationsByLaboratory.pending, (state) => {

            state.fetchStatus = "loading";
        });
        builder.addCase(fetchProductDenominationsByLaboratory.fulfilled, (state, action) => {

            action.payload.forEach((item: ProductDenomination) => {
                if (item.id) state.data[item.id] = item
            });
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchProductDenominationsByLaboratory.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
        builder.addCase(fetchProductDenominations.pending, (state) => {

            state.fetchStatus = "loading";
        });
        builder.addCase(fetchProductDenominations.fulfilled, (state, action) => {

            action.payload.forEach((item: ProductDenomination) => {
                if (item.id) state.data[item.id] = item
            });
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchProductDenominations.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
    },
});

export const {
    purgeReducer,
    setProductDenominationsFilterBy,
    setProductDenominationsFiltered,
    setProductDenominationsSortBy,
    refreshProductDenominations,
    deleteReducer,
    requestPage,
    setNbByPage
} = productDenominationsSlice.actions

export default productDenominationsSlice.reducer