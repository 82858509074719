import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Container, Button, Modal, Form, Table } from 'react-bootstrap';
import { setFieldsForSamplesInfo, setSamplesFilterBy, setSamplesSortBy, setSelectOptions } from '../../../state/dashboardSlice';
import { Formik } from 'formik';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';

interface ModalDashboardParamsSamplesInfoComponentContainerProps extends WithTranslation {
    dashboardName: string,
    showModal: boolean,
    setShowModal: any
}

const ModalDashboardParamsSamplesInfoComponent: React.FC<ModalDashboardParamsSamplesInfoComponentContainerProps> = ({ t, dashboardName, showModal, setShowModal }) => {
    const dispatch = useDispatch()

    // samples state
    const pagination = useSelector((state: RootState) => state.dashboard.paginationSamples)
    const fields = useSelector((state: RootState) => state.dashboard.fieldsForSamplesInfo)
    const datas = useSelector((state: RootState) => state.dashboard.samplesForData)

    // types for fields
    const samplesInfoFields = () => {
        const fields = {
            field: {
                type: [],
                sortable: false
            },
            display: {
                type: [],
                sortable: false
            },
        }
        return fields
    }

    const { ListHeader, filterAndSortList, FilterList } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "dashboard.params.modal_samples_info.columns.",
        onlyActive: false,
        size: 'xs',
        fields: samplesInfoFields(),
        reducers: {
            filterBy: setSamplesFilterBy,
            sortBy: setSamplesSortBy
        }
    })

    return (
        <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title><span className="text-primary fw-bold"> {t('dashboard.params.modal_samples_info.title')} </span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        fields: fields
                    }}
                    onSubmit={(values) => {
                        dispatch(setFieldsForSamplesInfo(values.fields))
                        setShowModal(false)
                    }}
                >
                    {/* Callback function containing Formik state and helpers that handle common form actions */}
                    {function myForm({   
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit }){
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Container style={{maxHeight: '400px', overflow: 'auto'}}>
                                        <Table striped hover size='sm' className="bg-white text-center table-fixed table-xs" style={{maxHeight: '400px'}}>
                                            <thead className='bg-white'>
                                                <tr>
                                                    <th className="p-0" />
                                                    <th className="p-1">
                                                        <ListHeader name="field" />
                                                    </th>
                                                    <th className="p-1">
                                                        <ListHeader name="display" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {values.fields.map((f: any, index: number) => {
                                                    if(Object.values(datas).filter(d => d[f.field as keyof typeof d] !== null && d[f.field as keyof typeof d] !== undefined && d[f.field as keyof typeof d] !== '').length > 0){
                                                        return (
                                                            <tr key={f.field}>
                                                                <td className="p-1 table-hover-hint" />
                                                                <td>{t(`dashboard.samples.columns.${f.field}`).includes('dashboard.samples.columns.') ? f.field : t(`dashboard.samples.columns.${f.field}`)}</td>
                                                                <td>
                                                                    <Form.Group className="mb-2" controlId="formDisplayInTables">
                                                                        <Form.Check
                                                                            className="mt-1"
                                                                            aria-label={t(`fields[${index}].display`)}
                                                                            type="checkbox"
                                                                            name={`fields[${index}].display`}
                                                                            checked={values.fields[index].display}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                        />
                                                                    </Form.Group>
                                                                </td>
                                                            </tr>
                                                )}})}
                                            </tbody>
                                        </Table>
                                    </Container>
                                    <Container className='d-flex justify-content-end'>
                                        <Button className="m-1 no-print" type="submit" variant="secondary">{t('dashboard.params.modal_samples_info.validate')}</Button>
                                    </Container>
                                </Form>
                            );
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default withTranslation()(ModalDashboardParamsSamplesInfoComponent)