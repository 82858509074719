import React from 'react';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { ReactComponent as AddCircleIcon } from 'bootstrap-icons/icons/plus-circle-fill.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import userHasRequiredRoles from '../../common/auth/userHasRequiredRoles';


type FooterProps = {
    enableHidden?: boolean
    noFullWidth?: boolean
}

const Footer: React.FC<FooterProps> = ({ enableHidden = true, noFullWidth = false }) => {
    
    const { t } = useTranslation()

    const footerState = useSelector((state: RootState) => state.footer.footerStateForMobile.footerState)
    const hideFooter = useSelector((state: RootState) => state.footer.hideFooter)

    const hasRoleAll = userHasRequiredRoles(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'])
    const hasRoleClient = userHasRequiredRoles(['MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_readOnly', 'MyProvilab_ROLE_readOnlyFG', 'MyProvilab_ROLE_readOnlyRM', 'MyProvilab_ROLE_readOnlyFP', 'MyProvilab_ROLE_salesperson', 'MyProvilab_ROLE_controlPlanAdmin'])
    const hasRoleTechnician = userHasRequiredRoles(['MyProvilab_ROLE_technician'])
    const hasRoleQuality = userHasRequiredRoles(['MyProvilab_ROLE_quality'])

    const theme = useSelector((state: RootState) => state.authUser.theme)

    return (
        <div hidden={enableHidden ? hideFooter : false} className={"p-0 no-print mt-auto pt-3 w-100" + (!enableHidden && !noFullWidth ? " full-width" : "") }>

            {
                <div className="d-none d-lg-block p-0">
                    <div className="p-3 bg-white text-center">
                        <Row className="label mx-auto">
                            <Col xs={4}><img height="50" width="auto" src={theme && theme.footerLogo ? `data:image/png;base64,${theme.footerLogo}` : '/cargill.png'} /></Col>
                            <Col md="auto" className="mt-2 d-none d-lg-block"><a href="/secure/confidentiality">{t('footer.confidentiality')} </a></Col>
                            <Col md="auto" className="mt-2 d-none d-lg-block"><a href={theme && theme.footerLink ? theme.footerLink : "https://www.cargill.fr"} target="_blank" rel="noreferrer"> {theme && theme.footerLinkLabel ? theme.footerLinkLabel : "Cargill.fr"} </a></Col>
                            <Col md="auto" className="mt-2 d-none d-lg-block"><a href="/secure/cookies">{t('footer.manage_cookies')} </a></Col>
                            <Col xs="auto" className="ms-3 mt-2">© {(new Date().getFullYear())} {t('footer.copyright')} </Col>
                        </Row>
                    </div>
                </div>
            }

            {
                <Container className="pt-5 pb-5 d-lg-none">
                    <div className="fixed-bottom w-100 p-1 bg-white text-center">
                        <Row className="label mx-auto">
                            <Col xs={4}><Image src={theme && theme.footerLogo ? `data:image/png;base64,${theme.footerLogo}` : '/cargill.png'} fluid /></Col>
                            <Col className="ms-1 mt-2 smalltext text-end">© {(new Date().getFullYear())} <a href={theme && theme.footerLink ? theme.footerLink : "https://www.cargill.fr"} target="_blank" rel="noreferrer">{theme && theme.footerLinkLabel ? theme.footerLinkLabel : "Cargill.fr"}</a> </Col>
                        </Row>
                    </div>
                    {(hasRoleAll || hasRoleClient || hasRoleQuality) &&
                        <Row className="fixed-bottom p-0 pb-3">
                            <Col className="text-center p-0">
                                <Link hidden={footerState} className="btn btn-link p-0 pe-1 text-primary" to={"/secure/selectAnalysis"}>
                                    <AddCircleIcon width="55px" height="60%" className="align-text-top p-1 rounded-circle bg-white" />
                                </Link>
                            </Col>
                        </Row>
                    }
                    {!hasRoleAll && !hasRoleClient && !hasRoleQuality && hasRoleTechnician &&
                        <Row className="fixed-bottom p-0 pb-3">
                            <Col className="text-center p-0">
                                <Link hidden={footerState} className="btn btn-link p-0 pe-1 text-primary" to={"/secure/analysis/fodder"}>
                                    <AddCircleIcon width="55px" height="60%" className="align-text-top p-1 rounded-circle bg-white" />
                                </Link>
                            </Col>
                        </Row>}
                </Container>
            }
        </div>
    )
}

export default withTranslation()(Footer)