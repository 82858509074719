import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import PublishApi, { ExportSystem, ExportSystemKey, getKeyForTabAssocExportSystem } from '../api/publishApi';
import { buildDefaultExtraReducersWithFrontPagination, CurrentState, getDefaultReducersWithFrontPagination, getInitialState } from './DefaultSlice';


const initialState = getInitialState<ExportSystem>()



export const fetchExportSystems = createAsyncThunk(
    'config/fetchExportSystems', 
    async () => {
        return await PublishApi.getInstance().getExportSystems() 
    }
)

export const fetchExportExportSystems = createAsyncThunk(
    'config/fetchExportExportSystems', 
    async (lab_id:number) => {
       return await PublishApi.getInstance().exportExportSystems(lab_id)
    }
)

const addItemToExportSystems =   (state:CurrentState<ExportSystem>,item:ExportSystem) => {

    if (item.laboratory_id && item.code_test && item.code_analyte) 
    state.data[getKeyForTabAssocExportSystem(item)] = item
}

const postDeleteExportSystems = (state:CurrentState<ExportSystem>,item:ExportSystemKey)=> {
    delete state.data[getKeyForTabAssocExportSystem(item)]
                state.filtered.splice(state.filtered.indexOf(getKeyForTabAssocExportSystem(item)),1)
}


const exportSystemsSlice = createSlice({
    name: 'export_systems',
    initialState,
     reducers: getDefaultReducersWithFrontPagination(addItemToExportSystems,postDeleteExportSystems)
    ,
    extraReducers: builder => {
            buildDefaultExtraReducersWithFrontPagination(
              addItemToExportSystems,
                builder,
                fetchExportSystems
            )
          
        }
})

export const {
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage
} = exportSystemsSlice.actions

export default exportSystemsSlice.reducer