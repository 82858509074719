import { combineReducers } from '@reduxjs/toolkit'
import {default as exportSystemsSlice} from './exportSystemsSlice'
import {default as exportExcelSlice} from './exportExcelSlice'
import {default as analyteSlice} from './analyteSlice'
import {default as refUnitsSlice} from './refUnitSlice'
import {default as autocalcAnalyteSlice} from './autocalcAnalyteSlice'
import {default as refAnalyteSlice} from './refAnalyteSlice'
import {default as testCategorySlice} from './testCategorySlice'
import {default as baseTestsSlice} from './baseTestsSlice'
import {default as autocalcResultsFilterSlice} from './autocalcResultsFilterSlice'
import {default as labSettingsSlice} from './labSettingsSlice'
import {default as resultsPatternsSlice} from './resultsPatternsSlice'
import { default as localSlice } from './localSlice'
import { default as footerSlice }from './footerSlice'
import { default as usersSlice } from './usersSlice'
import { default as internalUsersSlice } from './internalUsersSlice'
import { default as companiesSlice } from './companiesSlice'
import { default as userRequestSlice } from './userRequestSlice'
import { default as authUserReducer } from './authUserSlice'
import { default as configReducer } from './configSlice'
import { default as analysisReducer } from './analysisSlice'
import { default as farmerReducer} from './farmerSlice'
import { default as employeesReducer} from './employeesSlice'
import { default as pdcReducer} from './pdcSlice'
import { default as fieldsReducer} from './fieldsSlice'
import { default as formsReducer} from './formsSlice'
import { default as thresholdsReducer} from './thresholdSlice'
import { default as alertReducer} from './alertSlice'
import { default as ruleResultsPatternsReducer} from './ruleResultsPatternsSlice'
import { default as ruleResultsPatternTestsSlice} from './ruleResultsPatternTestsSlice'
import { default as targetValuesSlice} from './targetValuesSlice'
import { default as analysisReportsSlice} from './analysisReportsSlice'
import { default as reportTemplatesSlice} from './reportTemplatesSlice'
import { default as nirSlice} from './nirSlice'
import nirAnalysisSlice from './nirAnalysisSlice'
import nirEquationSlice from './nirEquationSlice'
import nirBinariesSlice from './nirBinariesSlice'
import externalCallStateSlice from './externalCallStateSlice'
import productDenominationsSlice from './productDenominationsSlice'
import matrixTemplatesSlice from './matrixTemplateSlice'
import matrixExportsSlice from './matrixExportsSlice'
import matrixTemplateInputSlice from './matrixTemplateInputSlice'
import matrixSlice from './matrixSlice'
import autocalcCallStateSlice from './autocalcCallStateSlice'
import matrixJobsSlice from './matrixJobsSlice'
import priceListSlice from './priceListSlice'
import patternFamiliesSlice from './patternsFamiliesSlice'
import packagesPatternsSlice from './packagesPatternsSlice'
import analyteTestsPackagesSlice from './analyteTestsPackagesSlice'
import dashboardSlice from './dashboardSlice'
import manageDashboardSlice from './manageDashboardSlice'
import dashboardJobFrequenciesSlice from './dashboardJobFrequenciesSlice'
import dashboardTablesSlice from './dashboardTablesSlice'
import preferences from './dashboardPreferencesSlice'
import dashboardPreferencesSlice from './dashboardPreferencesSlice'

const rootReducer = combineReducers({
    nirEquations: nirEquationSlice,
    exportSystems: exportSystemsSlice,
    analytes: analyteSlice,
    productDenominations: productDenominationsSlice,
    autocalcAnalytes: autocalcAnalyteSlice,
    testCategories: testCategorySlice,
    baseTests: baseTestsSlice,
    refAnalytes:refAnalyteSlice,
    resultsPatterns: resultsPatternsSlice,
    footer: footerSlice,
    local: localSlice,
    users: usersSlice,
    internalUsers: internalUsersSlice,
    exportResponses: exportExcelSlice,
    matrixResponses: matrixJobsSlice, 
    companies: companiesSlice,
    userRequests: userRequestSlice,
    authUser: authUserReducer,
    config: configReducer,
    analysis: analysisReducer,
    externalCallState: externalCallStateSlice,
    farmers: farmerReducer,
    employees: employeesReducer,
    pdc: pdcReducer,
    fields: fieldsReducer,
    forms: formsReducer,
    thresholds: thresholdsReducer,
    alerts: alertReducer,
    ruleResultsPatterns: ruleResultsPatternsReducer,
    ruleResultsPatternTests: ruleResultsPatternTestsSlice,
    analysisReports: analysisReportsSlice,
    reportTemplates: reportTemplatesSlice,
    priceList: priceListSlice,
    nir: nirSlice,
    nirAnalysis: nirAnalysisSlice,
    nirBinaries: nirBinariesSlice,
    autocalcResultsFilter: autocalcResultsFilterSlice,
    labSettings: labSettingsSlice,
    matrix: matrixSlice,
    matrixTemplates: matrixTemplatesSlice,
    matrixExports: matrixExportsSlice,
    matrixTemplateInputs: matrixTemplateInputSlice,
    autocalcCallState: autocalcCallStateSlice,
    patternsFamilies: patternFamiliesSlice,
    packagesPatterns: packagesPatternsSlice,
    analyteTestsPackages: analyteTestsPackagesSlice,
    matrixJobs: matrixJobsSlice,
    dashboard: dashboardSlice,
    manageDashboard: manageDashboardSlice,
    refUnits: refUnitsSlice,
    targetValues: targetValuesSlice,
    dashboardJobFrequencies: dashboardJobFrequenciesSlice,
    dashboardTables: dashboardTablesSlice,
    preferences: dashboardPreferencesSlice
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer