import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as PriceIcon } from '../resources/price.svg'
import { ReactComponent as CostIcon } from '../resources/cost.svg'
import { ReactComponent as PeriodIcon } from '../resources/period.svg'
import { ReactComponent as WeightIcon } from '../resources/weight.svg'
import useViewport from '../utils/useViewport';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';

export type Size = 'sm' | 'md' | 'lg'
export type Type = 'test' | 'pattern' | 'modal'

interface PcpwComponentProps {
    price?: number | null
    cost?: number  | null
    period?: number  | null
    weight?: number  | null
    unit?: string
    currency: string
    type?: Type
    size?: Size
    showCost?: boolean
}

const PcpwComponent: React.FC<PcpwComponentProps> = ({price, cost, period, weight, currency, unit, type, size, showCost = true}) => {
    const { lg } = useViewport()
    const { t } = useTranslation()

    if(currency === ''){
        currency = 'EUR'
    }

    const authUser = useSelector((state: RootState) => state.authUser.user)

    let widthIcon = '20'
    switch (size) {
        case 'sm':
            widthIcon = '15'
            break;
        case 'md':
            widthIcon = '20'
            break;
        case 'lg':
            widthIcon = '25'
            break;
    }

    return (
        
        // <Col xs={type !== 'pattern' && type !== 'mobile' ? 5 : "auto"}>
            <Row className={!lg && type !== 'modal' ? "mt-3 mb-3" : ""}>
                <Col className={"text-nowrap " + (lg ? "p-0 ps-2" : "text-center")}>
                    {unit ? (
                        <>
                            <span className={size+" pl-1"}>({unit})</span>
                        </>
                    ) : ""}
                </Col>
                <Col className={"text-nowrap " + (lg ? "p-0 ps-2" : "text-center")}>
                    {price != null ? (
                        <>
                            <PriceIcon width={widthIcon} height="100%" className="text-primary"/>
                            <span className={size+" pl-1"}>{Intl.NumberFormat(authUser.locale, { style: 'currency', currency: currency }).format(price).replace(/\s/g, '')}</span>
                        </>
                    ) : ""}
                </Col>
                    {cost != null && showCost? (
                        <Col className={"text-nowrap " + (lg ? "p-0 ps-2" : "text-center")}>
                             <CostIcon width={widthIcon} height="100%"/>
                             <span className={size+" ps-1"}>{Intl.NumberFormat(authUser.locale, { style: 'currency', currency: currency }).format(cost).replace(/\s/g, '')}</span>
                        </Col>
                    ) : null}
                <Col className={"text-nowrap " + (lg ? "p-0 ps-2" : "text-center")}>
                    {period != null ? (
                        <>
                            <PeriodIcon width={widthIcon} height="100%" className="text-primary"/>
                            <span className={size+" pl-1"}>{period+t('pcpw.period_unit')}</span>
                        </>
                    ) : ""}
                </Col>
                {(type === "pattern" || type === "modal" || type === "test") && 
                    <>
                    {(!lg || type === 'test') ? (
                        <Col className={"text-nowrap " + (lg ? "p-0 ps-2" : "text-center")}> 
                            {weight != null ? (
                                <>
                                    <WeightIcon width={widthIcon} height="100%" className="text-primary"/>
                                    <span className={size+" pl-1"}>{weight+t('pcpw.weight_unit')}</span>
                                </>
                            ) : ""}
                        </Col>
                    ) : (
                            <Col className={"text-nowrap " + (lg ? "p-0 ps-5" : "text-center")}> 
                                {weight != null ? (
                                    <span className={size+" fw-bold"}>{t('pcpw.message_weight1')+weight+t('pcpw.weight_unit')+t('pcpw.message_weight2')}</span>
                                ) : ""}
                            </Col>
                    )}
                    </>
                }
            </Row>
        // </Col>
    )
}

export default PcpwComponent
