import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import PublishApi, { ExportRequest, ExportResponse, ExportStatus } from '../api/publishApi';
import ApiError from '../common/utils/exceptions/ApiError';

import { Data, Pagination } from '../types/types';

interface CurrentState {
    data: Data<ExportResponse>
    dataStatus: Data<ExportStatus>

    byLabId: Data<Array<string>>
    filtered: Array<string>
    pagination: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    fetchStatusOfGetStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchErrorOfGetStatus: string | undefined
    fetchStatusOfDownload: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchErrorOfDownload: string | undefined
    fetchAllStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchAllError: string | undefined
    filterStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postError: string | undefined
}

const initialState = {
    data: {},
    dataStatus:{},
    byLabId: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    fetchStatus: 'idle',
    fetchError: undefined,
    fetchStatusOfGetStatus: 'idle',
    fetchErrorOfGetStatus: undefined,
    fetchStatusOfDownload: 'idle',
    fetchErrorOfDownload: undefined,
    fetchAllStatus: 'idle',
    fetchAllError: undefined,
    filterStatus: 'idle',
    singleFetchStatus: 'idle',
    singleFetchError: undefined,
    postStatus: 'idle',
    postError: undefined
} as CurrentState

export const postExportExcel = createAsyncThunk(
    'exportExcel/postExportExcel', 
    async (exportRequest:ExportRequest, {rejectWithValue}) => {
        try {
            const response = await PublishApi.getInstance().postExportJob(exportRequest)
            return response as ExportResponse
        } catch(err) {
            if (err instanceof ApiError) {
                return rejectWithValue({...err, message: err.message})
            }
            throw err
        }
    }
)

export const fetchExportExcelStatus = createAsyncThunk(
    'exportExcel/fetchExportExcelStatus', 
    async (jobId:string, {rejectWithValue}) => {
        try {
            const response = await PublishApi.getInstance().getExportJobStatus(jobId)
            return response as ExportStatus
        } catch(err) {
            if (err instanceof ApiError) {
                return rejectWithValue({...err, message: err.message})
            }
            throw err
        }
    }
)

export const fetchDownloadExcel = createAsyncThunk(
    'exportExcel/fetchDownloadExcel', 
    async (exportStatus: ExportStatus, {rejectWithValue}) => {
        try {
            await PublishApi.getInstance().downloadExportJob(exportStatus.id,exportStatus.config.output.file_type)
            
            return exportStatus.id
        } catch(err) {
            if (err instanceof ApiError) {
                return rejectWithValue({...err, message: err.message})
            }
            throw err
        }
    }
)

export const fetchCancelExportJob = createAsyncThunk(
    'exportExcel/fetchCancelExportJob', 
    async (jobId: string, {rejectWithValue}) => {
        try {
            await PublishApi.getInstance().cancelExportJob(jobId)
            
            return jobId
        } catch(err) {
            if (err instanceof ApiError) {
                return rejectWithValue({...err, message: err.message})
            }
            throw err
        }

    }
)

export const fetchAllExportJobs = createAsyncThunk(
    'exportExcel/fetchAllExportJobs', 
    async (arg, {rejectWithValue}) => {
        try {
            const response = await PublishApi.getInstance().getAllExportJobs()
            
            return response
        } catch(err) {
            if (err instanceof ApiError) {
                return rejectWithValue({...err, message: err.message})
            }
            throw err
        }

    }
)



const exportExcelSlice = createSlice({
    name: 'exportExcel',
    initialState,
    reducers: {
        exportExcelReducer(state: CurrentState, action: PayloadAction<ExportResponse>) {
            state.data[action.payload.id] = action.payload
            state.dataStatus[action.payload.id] = {... action.payload, progress: 0}
        },
    },
    extraReducers: builder => {
          // Languages API call reducers
          builder.addCase(postExportExcel.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(postExportExcel.fulfilled, (state, action) => {
            state.data[action.payload.id] = action.payload
            state.dataStatus[action.payload.id] = {... action.payload, progress: 0}

            state.fetchStatus = 'succeeded'
        })
        builder.addCase(postExportExcel.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })

            // Languages API call reducers
            builder.addCase(fetchExportExcelStatus.pending, (state) => {

                state.fetchStatusOfGetStatus = 'loading'
            })
            builder.addCase(fetchExportExcelStatus.fulfilled, (state, action) => {
                if (action.payload.status !== 'cancelled'){
                    state.dataStatus[action.payload.id] = action.payload
                }
                state.fetchStatusOfGetStatus = 'succeeded'
            })
            builder.addCase(fetchExportExcelStatus.rejected, (state, action) => {
                state.fetchStatusOfGetStatus = 'failed'
                state.fetchErrorOfGetStatus = action.error.message
            })

            builder.addCase(fetchDownloadExcel.fulfilled, (state, action) => {

                delete state.dataStatus[action.payload]
                delete state.data[action.payload]

                state.fetchStatusOfDownload = 'succeeded'
            })

            
            builder.addCase(fetchDownloadExcel.pending, (state) => {
                state.fetchStatusOfDownload = 'loading'
           
            })
    

            
            builder.addCase(fetchDownloadExcel.rejected, (state, action) => {
                state.fetchStatusOfDownload = 'failed'
                state.fetchErrorOfDownload = action.error.message
            })

            builder.addCase(fetchCancelExportJob.fulfilled, (state, action) => {
                // action.payload = id

                delete state.dataStatus[action.payload]
                delete state.data[action.payload]

                state.fetchStatusOfGetStatus = 'succeeded'
            })

            
            builder.addCase(fetchCancelExportJob.pending, (state) => {
                state.fetchStatusOfGetStatus = 'loading'
            })
    

            
            builder.addCase(fetchCancelExportJob.rejected, (state, action) => {

                state.fetchStatusOfGetStatus = 'failed'
                state.fetchErrorOfGetStatus = action.error.message
            })

            builder.addCase(fetchAllExportJobs.fulfilled, (state, action) => {
                state.data = {}
                state.dataStatus = {}
                action.payload.forEach(
                    exportJob => {
                        if (!['cancelled','cancelling', 'failed'].includes(exportJob.status)){
                            state.data[exportJob.id] = exportJob
                        }
                    }
                )

                state.fetchAllStatus = 'succeeded'
            })

            builder.addCase(fetchAllExportJobs.pending, (state) => {

                state.fetchAllStatus = 'loading'
            })
          
            builder.addCase(fetchAllExportJobs.rejected, (state, action) => {
                state.fetchAllStatus = 'failed'
                state.fetchAllError = action.error.message          
            })
    }
  })

  export const {
    exportExcelReducer
  } = exportExcelSlice.actions

  export default exportExcelSlice.reducer