import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/x-circle.svg";
import Loader from "../../../../common/components/Loader";
import useFilterToolkit from "../../../../common/utils/useFilterToolkit";
import { RootState } from "../../../../state/rootReducer";
import { Data } from "../../../../types/types";
import CustomPagination from "../../../../common/components/CustomPagination";
import { AppDispatch } from "../../../../state/store";
import MatrixApi, { MatrixTemplate } from "../../../../api/matrixApi";
import { setMatrixTemplatesFilterBy, setMatrixTemplatesFiltered, setMatrixTemplatesSortBy, setNbByPage, requestPage, desactivateMatrixTemplate, fetchMatrixTemplates } from "../../../../state/matrixTemplateSlice";
import CellWithTranslation from "../../../../common/components/CellWithTranslation";
import UploadMatrixMemoFile from "../../../../common/components/UploadMatrixMemoFile";


interface MatrixTemplatesListProps extends WithTranslation {
    currentLaboratoryId: number;
}

const MatrixTemplatesList: React.FC<MatrixTemplatesListProps> = ({
    t,
    currentLaboratoryId,
}) => {
    const dispatch = useDispatch<AppDispatch>();

    const { allowedPageSizes } = useSelector(
        (state: RootState) => state.local
    );

    // companies state
    const companies = useSelector( (state: RootState) => state.companies.data )

    //matrix template state
    const matrixTemplates: Data<MatrixTemplate> = useSelector(
        (state: RootState) => state.matrixTemplates.data
    );
    const filteredMatrixTemplates = useSelector(
        (state: RootState) => state.matrixTemplates.filtered
    );
    const pagination = useSelector(
        (state: RootState) => state.matrixTemplates.pagination
    );

    // Filter
    const { ListHeader, filterAndSortList, FilterList } = useFilterToolkit(
        pagination.filterBy,
        pagination.sortBy,
        {
            i18nPrefix: "matrix.matrix_templates.columns.",
            onlyActive: true,
            fields: {
                id: {
                    type: ["filter"],
                    sortable: true
                },
                category: {
                    type: ["filter"],
                    sortable: true
                },
                sub_category: {
                    type: ["filter"],
                    sortable: true
                },
                raw_material: {
                    type: ["filter"],
                    sortable: true
                },
                jcode: {
                    type: ["filter"],
                    sortable: true
                },
                crit_company: {
                    type: ["filter"],
                    sortable: true
                },
                memo_url: {
                    type: [],
                    sortable: false
                }
            },
            reducers: {
                filterBy: setMatrixTemplatesFilterBy,
                sortBy: setMatrixTemplatesSortBy,
            },
        }
    );

    const [laboratoryId, setLaboratoryId] = useState(currentLaboratoryId);

    const [show, setShow] = useState(false);
    const [matrixId, setId] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [showDeleteMemo, setShowDeleteMemo] = useState(false);

    const [processing, setProcessing] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseDelete = () => setShowDelete(false);
    const handleCloseDeleteMemo = () => setShowDeleteMemo(false);

    const handleShowDelete = () => setShowDelete(true);
    const handleShowDeleteMemo = () => setShowDeleteMemo(true);

    const message = t("forms_errors.unexpected_error");

    function launchDelete(lab_id: number, id: number,) {
        setLaboratoryId(lab_id);
        setId(id);
        handleShowDelete();
    }

    function launchDeleteMemo(lab_id: number, id: number,) {
        setLaboratoryId(lab_id);
        setId(id);
        handleShowDeleteMemo();
    }

    async function deleteMatrixTemplateMemoOnClick() {
        handleCloseDeleteMemo();
        setProcessing(true);
        try {
            await MatrixApi.getInstance().deleteMatrixTemplateMemo(laboratoryId, matrixId);
            dispatch(fetchMatrixTemplates({ laboratoryId: laboratoryId, page: pagination.currentPage }))

        } catch (err) {
            console.log(err)
            handleShow();
        } finally {
            setProcessing(false);
        }
    }

    async function desactivateMatrixTemplateOnClick() {
        handleCloseDelete();
        setProcessing(true);
        try {
            await dispatch(
                desactivateMatrixTemplate({
                    laboratoryId: laboratoryId,
                    id: matrixId,
                })
            ).unwrap();
        } catch (err) {
            console.log(err)
            handleShow();
        } finally {
            setProcessing(false);
        }
    }

    const onMemoFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.files && e.target.files[0]) {
            try {
                await MatrixApi.getInstance().uploadMatrixTemplateMemo(laboratoryId, matrixId, e.target.files[0])
                dispatch(fetchMatrixTemplates({ laboratoryId: laboratoryId, page: pagination.currentPage }))
            } catch (err) {
                handleShow();
            } finally {
                e.target.value = '' // to be able to select the same file twice
            }
        }
    };

    const handleSetId = (id: number) => {
        // Faites ce que vous avez à faire pour définir l'ID ici
        setId(id); // Assurez-vous de passer la nouvelle valeur de l'ID
      }


    return (
        <Container className="mt-2 container-xxl">
            {processing && <Loader />}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("error")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("buttons.disable")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("matrix.matrix_templates.msg_delete")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        {t("buttons.cancel")}
                    </Button>
                    <Button variant="primary" onClick={desactivateMatrixTemplateOnClick}>
                        {t("buttons.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteMemo} onHide={handleCloseDeleteMemo}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("buttons.delete")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("matrix.matrix_templates.msg_delete_memo")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteMemo}>
                        {t("buttons.cancel")}
                    </Button>
                    <Button variant="primary" onClick={deleteMatrixTemplateMemoOnClick}>
                        {t("buttons.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>


            <Row className="mt-2 mx-1">
                <Col>
                    <FilterList />
                </Col>
            </Row>


            <Table
                striped
                hover
                responsive="lg"
                className="mt-2 bg-white text-center"
            >
                <thead>
                    <tr>
                        <th className="p-1" />
                        <th><ListHeader name="id" /></th>
                        <th><ListHeader name="category" /></th>
                        <th><ListHeader name="sub_category" /></th>
                        <th><ListHeader name="raw_material" /></th>
                        <th><ListHeader name="jcode" /></th>
                        <th><ListHeader name="crit_company" /></th>
                        <th><ListHeader name="memo_url" /></th>
                        <th>{t("matrix.matrix_templates.columns.actions")}</th>
                    </tr>
                </thead>
                <tbody>
                {pagination.pages[pagination.currentPage] && !pagination.pages[pagination.currentPage].fetching && pagination.pages[pagination.currentPage].ids.map((id) => {
                                return (
                                    <tr key={id}>
                                        <td className="p-1 table-hover-hint" />
                                        <td>
                                            {matrixTemplates[id].id}
                                        </td>
                                        <td>
                                            <CellWithTranslation
                                                headerName={t(
                                                    "matrix.matrix_templates.columns.translation_category"
                                                )}
                                                id={id}
                                                json_name={
                                                    matrixTemplates[id].category
                                                }
                                            />
                                        </td>
                                        <td>
                                            <CellWithTranslation
                                                headerName={t(
                                                    "matrix.matrix_templates.columns.translation_sub_category"
                                                )}
                                                id={id}
                                                json_name={
                                                    matrixTemplates[id].sub_category
                                                }
                                            />
                                        </td>
                                        <td>
                                            <CellWithTranslation
                                                headerName={t(
                                                    "matrix.matrix_templates.columns.translation_raw_material"
                                                )}
                                                id={id}
                                                json_name={
                                                    matrixTemplates[id].raw_material
                                                }
                                            />
                                        </td>
                                        <td>
                                            {matrixTemplates[id].jcode}
                                        </td>
                                        <td>
                                            {matrixTemplates[id].crit_company !== '*' ? matrixTemplates[id].crit_company && matrixTemplates[id].crit_company.split('; ').filter(id => companies[Number(id)]).map(id => companies[Number(id)].name).map((n, i) => (
                                                <span key={i}>
                                                    {i > 0 ? <br/> : null} {n}
                                                </span>
                                                ))
                                                :
                                                <span>*</span>
                                            }
                                        </td>
                                        <td className="text-nowrap">
                                            {matrixTemplates[id].memo_url}
                                            <Row className="justify-content-md-center mt-2">
                                                <Col md="auto">
                                                    <UploadMatrixMemoFile uploadAction={onMemoFileChange} id={matrixTemplates[id].id} onButtonClick={handleSetId}/>
                                                </Col>
                                                {matrixTemplates[id].memo_url && matrixTemplates[id].memo_url !== "" &&

                                                    <Col md="auto">
                                                        <Button
                                                            className="p-0 pe-1 text-danger"
                                                            variant="link"
                                                            onClick={launchDeleteMemo.bind(null, laboratoryId, matrixTemplates[id].id)}
                                                        >
                                                            <DeleteIcon
                                                                width="24px"
                                                                height="100%"
                                                                className="align-text-top"
                                                            />
                                                        </Button>
                                                    </Col>
                                                }
                                            </Row>
                                        </td>
                                        <td className="text-nowrap">
                                            <Button
                                                className="p-0 pe-1 text-danger"
                                                variant="link"
                                                onClick={launchDelete.bind(null, laboratoryId, matrixTemplates[id].id)}
                                            >
                                                <DeleteIcon
                                                    width="24px"
                                                    height="100%"
                                                    className="align-text-top"
                                                />
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })
                    }

                </tbody>
            </Table>
            <CustomPagination
                nbPages={pagination.nbPage}
                curPage={pagination.currentPage}
                nbNeighbours={1}
                curPageSize={pagination.nbByPage}
                allowedPageSizes={allowedPageSizes}
                goToPage={(page) => { dispatch(fetchMatrixTemplates({laboratoryId, page})); }}
                setPageSize={(nbPage) => { dispatch(setNbByPage(nbPage)); dispatch(fetchMatrixTemplates({laboratoryId, page: 1}));}}
            />
        </Container>
    );
};

export default withTranslation()(MatrixTemplatesList);


