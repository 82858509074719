import { useOktaAuth } from '@okta/okta-react';

function userHasRequiredRoles(requiredRoles: Array<string>): boolean {
    const { oktaAuth } = useOktaAuth();

    try {
        const accessToken = oktaAuth.getAccessToken()
        if (accessToken) {
            const accessDecoded = oktaAuth.token.decode(accessToken)

            let userHasRequiredRoles = false
            if (accessDecoded.payload.groups && Array.isArray(accessDecoded.payload.groups)) {
                userHasRequiredRoles = accessDecoded.payload.groups.some((userGroup) => requiredRoles.includes(userGroup as string))
            }
            return userHasRequiredRoles
        }
    } catch(err) {
        return false
    }
    return false
}

export default userHasRequiredRoles