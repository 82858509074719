import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import {ReactComponent as BackIcon} from 'bootstrap-icons/icons/arrow-left-short.svg'
import { Button } from 'react-bootstrap';

interface GoBackButtonProps extends WithTranslation {
    label: string
}

const GoBack: React.FC<GoBackButtonProps> = ({label}: GoBackButtonProps) => {
    const history = useHistory()
    return (
        <div className="pointer" onClick={() => history.goBack()}>
            <BackIcon width="30px" height="100%" className="bg-primary text-white dot fw-bold"  />
            <span className="ps-2 text-body fw-bold align-middle">{label}</span>
        </div>
    )
}

export default withTranslation()(GoBack)