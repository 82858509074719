import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Row, Container, Col, Accordion, useAccordionButton } from 'react-bootstrap';
import DashboardGenericGraphComponent from './DashboardGenericGraphComponent';
import ContextAwareToggle from '../../../common/components/ContextAwareToggle';

interface DashboardGraphsComponentContainerProps extends WithTranslation {
    dashboardName: string
    values: {
        selected_values: {
            analyte_id: string,
            value: number
        }[];
        commentary: string;
    }
}

const DashboardGraphsComponent: React.FC<DashboardGraphsComponentContainerProps> = ({ t, dashboardName, values }) => {
    // samples state
    const resultsHeader = useSelector((state: RootState) => state.dashboard.resultsHeaders)

    return (
        <Container className={'mt-2 p-0 ms-0 me-0 container-xl'}>
            <Container className="p-2 bg-white rounded-3 mb-0 container-xl">
                <Accordion defaultActiveKey={"graphs"}>
                    <Row>
                        <Col lg>
                            <h4 className="text-primary fw-bold">
                            {t('dashboard.graphs.title')}
                            </h4>
                        </Col>
                        <Col lg="auto">
                            <ContextAwareToggle eventKey={"graphs"} />
                        </Col>
                    </Row>
                    <Accordion.Collapse eventKey={"graphs"} style={{height: '250px'}}>
                        <>
                            <Row className="pb-1" ><Col lg className="lineTitle"></Col></Row>
                            <Row className='me-1' style={{height: '250px', overflow: 'auto'}}>
                                {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_graph).map(header => {
                                    return (
                                        <Col key={header.id} lg={'auto'} className='ms-auto me-auto'>
                                            <DashboardGenericGraphComponent values={values} dashboardName={dashboardName} analyteId={header.id} />
                                        </Col>
                                )})}
                            </Row>
                        </>
                    </Accordion.Collapse>
                </Accordion>
            </Container>
        </Container>
    )
}

export default withTranslation()(DashboardGraphsComponent)