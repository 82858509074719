import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import ConfigApi, {
    getKeyForTabAssocResultsPatterns,
    ResultsPatterns,
    ResultsPatternsKey,
} from "../api/configApi";
import { fetchBaseTests } from "./baseTestsSlice";
import {
    buildDefaultExtraReducersWithFrontPagination,
    CurrentState,
    getDefaultReducersWithFrontPagination,
    getInitialState,
} from "./DefaultSlice";
import { fetchRuleResultsPatterns } from "./ruleResultsPatternsSlice";
import { fetchTestCategories } from "./testCategorySlice";

const initialState = getInitialState<ResultsPatterns>();

export const fetchResultsPatterns = createAsyncThunk(
    "config/fetchResultsPatterns",
    async () => {
        return await ConfigApi.getInstance().getResultsPatterns();
    }
);

export const fetchSingleResultsPatterns = createAsyncThunk(
    "analysis/fetchSingleResultsPatterns",
    async (resultsPatternsKey: ResultsPatternsKey) => {
        return await ConfigApi.getInstance().getResultsPattern(
            resultsPatternsKey.laboratory_id,
            resultsPatternsKey.name
        );
    }
);

export const deleteResultsPatterns = createAsyncThunk(
    "config/deleteResultsPatterns",
    async (resultsPatterns: ResultsPatternsKey, { dispatch }) => {
        const response = await ConfigApi.getInstance().deleteResultsPattern(
            resultsPatterns.laboratory_id,
            resultsPatterns.name
        );

        if (!response) {
            throw new Error("Unable to delete results patterns");
        } else {
            await dispatch(
                fetchTestCategories({
                    laboratoryId: resultsPatterns.laboratory_id,
                })
            );
            await dispatch(fetchBaseTests());
            await dispatch(
                fetchRuleResultsPatterns({
                    laboratoryId: resultsPatterns.laboratory_id,
                })
            );

            dispatch(deleteReducer(resultsPatterns));
        }

        return true;
    }
);

export const upsertResultsPatterns = createAsyncThunk<
    ResultsPatterns,
    {
        resultsPatterns: ResultsPatterns;
        resultsPatternsKey: ResultsPatternsKey | undefined;
    }
>(
    "config/upsertResultsPatterns",
    async (
        {
            resultsPatterns,
            resultsPatternsKey,
        }: {
            resultsPatterns: ResultsPatterns;
            resultsPatternsKey: ResultsPatternsKey | undefined;
        },
        { dispatch }
    ) => {
        let retResultsPatterns: ResultsPatterns | null = null;

        if (!resultsPatternsKey) {
            const response = await ConfigApi.getInstance().postResultsPatterns(
                resultsPatterns
            );
            retResultsPatterns = response;
        } else {
            const response = await ConfigApi.getInstance().putResultsPatterns(
                resultsPatterns,
                resultsPatternsKey
            );
            retResultsPatterns = response;
        }
        retResultsPatterns.id =
            getKeyForTabAssocResultsPatterns(retResultsPatterns);
        dispatch(tableReducer(retResultsPatterns));
        return retResultsPatterns;
    }
);

const addItemToResultsPattern = (
    state: CurrentState<ResultsPatterns>,
    item: ResultsPatterns
) => {
    if (item.laboratory_id && item.name)
        state.data[getKeyForTabAssocResultsPatterns(item)] = item;
};

const postDeleteResultsPatterns = (
    state: CurrentState<ResultsPatterns>,
    item: ResultsPatternsKey
) => {
    delete state.data[getKeyForTabAssocResultsPatterns(item)];
    state.filtered.splice(
        state.filtered.indexOf(getKeyForTabAssocResultsPatterns(item)),
        1
    );
};

const resultsPatternsSlice = createSlice({
    name: "results patterns",
    initialState,
    reducers: getDefaultReducersWithFrontPagination(
        addItemToResultsPattern,
        postDeleteResultsPatterns
    ),
    extraReducers: (builder) => {
        buildDefaultExtraReducersWithFrontPagination(
            addItemToResultsPattern,
            builder,
            fetchResultsPatterns
        );
    },
});

export const {
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage,
} = resultsPatternsSlice.actions;

export default resultsPatternsSlice.reducer;
