import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import './App.scss';

import {
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";

import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuthConfig, oktaSignInConfig } from './common/auth/config';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './state/rootReducer';
import { updateLanguage } from './state/localSlice';
import { userLogout } from './state/authUserSlice';
import { fetchLanguages } from './state/configSlice';

import Header from './common/components/Header';
import Footer from './common/components/Footer';
import AppRouter from './AppRouter';
import LoginContainer from './common/auth/LoginContainer';
import SignUpContainer from './pages/signup/SignUpContainer';
import AuthUserManager from './common/auth/AuthUserManager';
import CustomLoginCallback from './common/auth/CustomLoginCallback';
import { Badge, Button, Col, Modal, Row } from 'react-bootstrap';
import AnalysisApi from './api/analysisApi';
import ConfigApi from './api/configApi';
import UsersApi from './api/usersApi';
import ControlPlanApi from './api/controlPlanApi';
import PublishApi from './api/publishApi';
import ExternalServicesApi from './api/externalServicesApi';
import MatrixApi from './api/matrixApi';
import DashboardApi from './api/dashboardApi';

const oktaAuth = new OktaAuth(oktaAuthConfig)

const AppSecurityRouter: React.FC<WithTranslation> = ({t, i18n}) => {

    const history = useHistory();
    const dispatch = useDispatch()

    const { user: authUser } = useSelector( (state: RootState) => state.authUser )

    const languages = useSelector( (state: RootState) => state.config.languages.data )
    const languagesStatus = useSelector( (state: RootState) => state.config.languages.fetchStatus )

    const [sessionNeedRefresh, setSessionNeedRefresh] = useState<boolean>(false);

    const [secToLogOut, setSecToLogOut] = useState<number>(0);
    const secToLogOutRef = useRef(secToLogOut);
    secToLogOutRef.current = secToLogOut;
    const [secToLogOutInterval, setSecToLogOutInterval] = useState<NodeJS.Timer|undefined>(undefined);

    const restoreOriginalUri = useCallback(async (_oktaAuth: OktaAuth, originalUri: string) => {
        history.replace(toRelativeUrl( originalUri, window.location.origin));
    }, [])

    /**
     * Authentication handler called when auth is required
     * Redirect to the login page
     */
    const customAuthHandler = () => {
        history.push('/login');
    };

    /**
     * Add custom button to sign up on login page
     */
    if (oktaSignInConfig.customButtons.findIndex(cb => cb.i18nKey === 'customButton.signup') === -1) {
        oktaSignInConfig.customButtons.push(
            {
                i18nKey: 'customButton.signup',
                className: 'button-primary',
                click: function() {
                    history.push("/signup");
                }
            }
        )
    }

    /**
     * Update page title according to user language
     */
    useEffect( () => {
        document.title = t('nav.title')
    }, [i18n.language])

    /**
     * Update translations language when user locale change
     */
    useEffect(() => {
        if (authUser.locale) {
            i18n.changeLanguage(authUser.locale)
            dispatch(updateLanguage({id: authUser.language_id, locale: authUser.locale}))
        }
    }, [authUser.locale])

    /**
     * Fetch language list at startup and populate translation language
     */
    useEffect( () => {
        if (languagesStatus === 'idle') {
            dispatch(fetchLanguages())
        } else if (languagesStatus === 'succeeded') {
            const curLanguage = Object.entries(languages).find(item => item[1].code_language === i18n.language )
            if (curLanguage) {
                dispatch(updateLanguage({ id: curLanguage[1].id, locale: curLanguage[1].code_language}))
            }
        }
    }, [languagesStatus, dispatch])

    /**
     * Token expiration handler
     * Display refresh session modal if autoRenew is set to false
     */
    const handleTokenExpired = useCallback(async () => {
        if (!oktaAuthConfig.tokenManager.autoRenew && !sessionNeedRefresh) {
            const accessToken = await oktaAuth.tokenManager.get("accessToken")
            if (accessToken) {
                if (accessToken.expiresAt <= Math.floor(Date.now() / 1000)) {
                // if (accessToken.expiresAt - oktaAuthConfig.tokenManager.expireEarlySeconds + 30 <= Math.floor(Date.now() / 1000)) { // For debug purpose
                    await oktaAuth.revokeAccessToken()
                    await oktaAuth.signOut({revokeAccessToken: true, revokeRefreshToken: true})
                } else {
                    setSessionNeedRefresh(true)
                }
            }
        }
    }, [sessionNeedRefresh])

    /**
     * Token renewed handler
     * Hide refresh session modal and refresh API's access token with renewed token
     */
    const handleTokenRenewed = (key: string) => {
        setSessionNeedRefresh(false)

        // Initialize API instance to use new access token
        if (key === "accessToken") {
            const accessToken = oktaAuth.getAccessToken()
            if (accessToken) {
                AnalysisApi.initInstance(accessToken)
                ConfigApi.initInstance(accessToken)
                UsersApi.initInstance(accessToken)
                ControlPlanApi.initInstance(accessToken)
                PublishApi.initInstance(accessToken)
                ExternalServicesApi.initInstance(accessToken)
                MatrixApi.initInstance(accessToken)
                DashboardApi.initInstance(accessToken)

            }
        }
    }

    /**
     * Register token expiration to token manager events an re-register on each sessionNeedRefresh update
     */
     useEffect(() => {
        oktaAuth.tokenManager.on('expired', handleTokenExpired)

        return () => {
            oktaAuth.tokenManager.off('expired', handleTokenExpired);
        }
    }, [sessionNeedRefresh])

    /**
     * Register token renewal handler to token manager events
     */
    useEffect(() => {
        oktaAuth.tokenManager.on('renewed', handleTokenRenewed)

        return () => {
            oktaAuth.tokenManager.off('renewed', handleTokenRenewed);
        }
    }, [])

    /**
     * Timer initialization on session refresh modal display
     */
    useEffect(() => {
        let counter: NodeJS.Timer | undefined
        if (sessionNeedRefresh) {
            setSecToLogOut(oktaAuthConfig.tokenManager.expireEarlySeconds)
            // setSecToLogOut(30) // For debug purpose
            counter = setInterval(() => {
                if (secToLogOutRef.current > 0) {
                    setSecToLogOut(curCount => curCount - 1);
                } else {
                    handleGoToLogin()
                }
            }, 1000);
            setSecToLogOutInterval(counter)
        } else {
            if (counter) {
                clearInterval(counter);
            }
        }
        return () => {
            if (counter) {
                clearInterval(counter);
                setSecToLogOutInterval(undefined)
            }
        }
    }, [sessionNeedRefresh])

    /**
     * Handle "go to login" action
     * Hide refresh session modal, clear timer and signout
     */
    const handleGoToLogin = async () => {
        if (secToLogOutInterval) {
            clearInterval(secToLogOutInterval)
            setSecToLogOutInterval(undefined)
        }
        await oktaAuth.revokeAccessToken()
        await oktaAuth.signOut({revokeAccessToken: true, revokeRefreshToken: true})
    }

    /**
     * Handle "renew session" action
     * Clear timer, refresh session and hide refresh session modal
     */
    const handleRenew = async () => {
        if (secToLogOutInterval) {
            clearInterval(secToLogOutInterval)
            setSecToLogOutInterval(undefined)
        }
        try {
            await oktaAuth.tokenManager.renew("refreshToken")
            setSessionNeedRefresh(false)
        } catch(err) {
            console.error("Refresh session error: ", err)
            await oktaAuth.revokeAccessToken()
            await oktaAuth.signOut({revokeAccessToken: true, revokeRefreshToken: true})
        }
    }

    return (
        <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
            
            {/* Refresh session modal */}
            <Modal show={sessionNeedRefresh} backdrop="static">
                <Modal.Header>
                    <Modal.Title>{t('session.expiring.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('session.expiring.msg')}</p>
                    <Row className="justify-content-center">
                        {/* Display timer countdown */}
                        <Col xs="auto">
                            <h1 className="justify-content-center"><Badge bg="dark">{Math.floor(secToLogOut / 60).toString().padStart(2, '0') + ':' + (secToLogOut % 60).toString().padStart(2, '0')}</Badge></h1>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleGoToLogin}>
                        {t('buttons.leave')}
                    </Button>
                    <Button variant="secondary" onClick={handleRenew}>
                        {t('buttons.stay')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Security router */}
            <Switch>
                <Route exact path="/">
                    <Redirect to="/secure/home" />
                </Route>
                <Route path='/signup'>
                    <SignUpContainer />
                </Route> 
                <Route path='/login' exact>
                    <LoginContainer config={oktaSignInConfig} />
                </Route> 
                <Route path='/login/callback'>
                    <CustomLoginCallback  restoreOriginalUri={restoreOriginalUri}/>
                </Route>
                {/* Clear tokens and user state on logout */}
                <Route path='/logout' render={() => {
                    oktaAuth.tokenManager.clear();
                    dispatch(userLogout())
                    return <Redirect to="/" />
                }} />
                <AuthUserManager path="/secure">
                    <Header/>
                    <AppRouter />
                    <Footer/>
                </AuthUserManager>
            </Switch>
        </Security>
    );
}

export default withTranslation()(AppSecurityRouter);