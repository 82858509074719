import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Data, FilterBy, Pagination, SortBy } from "../types/types";
import DashboardApi, { Analyte, AnalyteRequest, DashboardAnalysisHeader, Filter, FilterSet, FiltersRequest, FiltersResponse, Outliers, PeriodField, SearchRequest, SelectedValue, SelectedValues, Selection } from "../api/dashboardApi";
import { JsonName } from "../api/analysisApi";
import { DatasCalculated } from "../pages/dashboard/components/utils";
import { others } from "../pages/dashboard/components/DashboardGroupTableComponent";
import { Weight } from "../pages/dashboard/components/ModalDashboardParamsWeightComponent";
import { NumberLocale } from "yup/lib/locale";
import { Matrix } from "../api/matrixApi";

export interface ResultHeader {
    id: string,
    name: JsonName,
    selected: boolean,
    display_in_graph: boolean,
    display_in_selection: boolean,
    decimals?: number
}

const arrayOfFieldForSamplesInfo = [    
    {field: 'laboratory_name', display: false, typeOfField: "filter"},
    {field: 'display_id', display: false, typeOfField: "filter"},
    {field: 'company_name', display: false, typeOfField: "filter"},
    {field: 'group', display: false, typeOfField: "filter"},
    {field: 'family_name', display: true, typeOfField: "filter"},
    {field: 'pattern', display: false, typeOfField: "filter"},
    {field: 'control_plan', display: false, typeOfField: "filter"},
    {field: 'last_update', display: false, typeOfField: "date"},
    {field: 'plant', display: false, typeOfField: "filter"},
    {field: 'denomination', display: false, typeOfField: "filter"},
    {field: 'customer_reference', display: false, typeOfField: "filter"},
    {field: 'sample_date', display: false, typeOfField: "date"},
    {field: 'silo', display: false, typeOfField: "filter"},
    {field: 'delivery_date', display: false, typeOfField: "date"},
    {field: 'lot', display: false, typeOfField: "filter"},
    {field: 'provider', display: false, typeOfField: "filter"},
    {field: 'origin', display: false, typeOfField: "filter"},
    {field: 'processing_name', display: false, typeOfField: "filter"},
    {field: 'harvest_year', display: false, typeOfField: "number"},
    {field: 'farmer', display: false, typeOfField: "filter"},
    {field: 'harvest_date', display: false, typeOfField: "date"},
    {field: 'manufacturing_date', display: false, typeOfField: "date"},
    {field: 'formula', display: false, typeOfField: "filter"},
    {field: 'day', display: false, typeOfField: "number"},
    {field: 'month', display: false, typeOfField: "number"},
    {field: 'year', display: false, typeOfField: "number"},
    {field: 'is_mdi_ok', display: false, typeOfField: "boolean"},
    {field: 'is_gh_ok', display: false, typeOfField: "boolean"},
    {field: 'is_in_between_limit', display: false, typeOfField: "boolean"},
    {field: 'nir_name', display: false, typeOfField: "filter"}
]

interface FieldForSamplesInfo {
    field: string,
    display: boolean,
    typeOfField: string
}

interface ParamsFromComponents {
    dataShow: Selection,
    date: PeriodField,
    type: string,
    average: boolean,
    average1: boolean,
    average2: boolean,
    average3: boolean,
    targetValues: boolean,
    warning: boolean,
    limits: boolean,
    connectDots: boolean,
    trend: boolean,
    outliers: boolean,
    filterOutliers: boolean,
    sampleSelectedInGraphs: string,
    averageOutliers: boolean
    activeMatrix: boolean
    selectedValues: boolean
}

export interface ValueGroupBy {
    value: string,
    color: string,
    dataOfAnalytes: Data<DatasCalculated>;
}

export interface GroupBy {
    field: string,
    values: ValueGroupBy[]
}

export interface CustomValuesForData {
    field_default_name: string,
    field_translation: string,
    value: string
}

export interface ResultsForData {
    analyte_id: string,
    analyte_name: JsonName,
    default_unit: string,
    default_unit_result: number,
    method_type: string,
    is_mdi_ok: boolean,
    is_gh_ok: boolean,
    is_in_between_limit: boolean,
    target: number,
    warning_low: number,
    warning_high: number,
    limit_low: number,
    limit_high: number,
    selected: boolean,
    outlier: boolean
}

export interface DashboardSamplesData {
    analysis_id: number,
    laboratory_id: number,
    laboratory_name: string,
    display_id: string,
    company_id: number,
    company_name: string,
    employee_id: number,
    group: string,
    pattern: string,
    control_plan: string,
    creation_date: Date,
    validation_date: Date,
    last_update: Date,
    plant: string,
    denomination: string,
    customer_reference: string,
    sample_date: Date,
    silo: string,
    delivery_date: Date,
    lot: string,
    provider: string,
    origin: string,
    processing_name: string,
    harvest_year: number,
    farmer: string,
    harvest_date: Date,
    manufacturing_date: Date,
    formula: string,
    is_mdi_ok: boolean,
    is_gh_ok: boolean,
    is_in_between_limit: boolean,
    day: number,
    month: number,
    year: number,
    custom_values: CustomValuesForData[],
    results: ResultsForData[],
    selected: boolean,
    outlier: boolean,
    nir_name: string,
    family_name: string
}

interface CommonFilter {
    type: string,
    period_field: 'creation_date' | 'validation_date' | 'sample_date' | 'delivery_date',
    from: string,
    to: string,
    exclude_outliers: boolean,
    filter: Filter[]
}

export interface AnalyteForModal {
    analyte_id: string,
    max_value: number | null,
    min_value: number | null,
    checked: boolean
}

export interface OutliersForModal {
    outliers_on: string,
    mdi: boolean,
    gh: boolean,
    low_high_limit: boolean,
    analytes: AnalyteForModal[]
}

export interface ValuesForSearch {
    company_id?: number
    common_filter: CommonFilter,
    filter_set: FilterSet[],
    analytes?: AnalyteRequest[],
    outliers?: OutliersForModal
}

export interface MatrixFields {
    laboratory_id: number,
    company_id: number,
    export_code: string,
    category: string,
    sub_category: string,
    raw_material: string,
    template_id: number,
}

interface CurrentState {
    matrixFields: MatrixFields | undefined,
    weights: Weight[]
    selectedValues: SelectedValues,
    activeMatrix: Matrix | undefined,
    samplesForData: Data<DashboardSamplesData>;
    initialSamplesForData: Data<DashboardSamplesData>;
    filteredSamples: string[];
    paginationSamples: Pagination;
    resultsHeaders: ResultHeader[];
    initialDataOfAnalytes: Data<DatasCalculated>;
    dataOfAnalytes: Data<DatasCalculated>;
    averageOutliers: Data<boolean>;
    averageOutliersResults: Data<boolean>;
    filters: FiltersResponse;
    groupBy: GroupBy;
    filtersForGroupBy: Filter[];
    fieldsForSamplesInfo: FieldForSamplesInfo[];
    paramsFromComponents: ParamsFromComponents;
    valuesForSearch: ValuesForSearch | null;
    outliers: OutliersForModal;
    postFiltersStatus: "idle" | "loading" | "succeeded" | "failed";
    postFiltersError: string | undefined;
    searchStatus: "idle" | "loading" | "succeeded" | "failed";
    searchError: string | undefined;
}

export type OptionType = 'average' | 'average1' | 'average2' | 'average3' | 'targetValues' | 'warning' | 'limits' | 'trend' | 'connectDots' | 'outliers' | 'filterOutliers' | 'activeMatrix' | 'selectedValues'

const initialState = {
    matrixFields: undefined,
    weights: [],
    selectedValues: {
        selected_values : [],
        commentary: ''
    },
    samplesForData: {},
    activeMatrix: undefined,
    initialSamplesForData: {},
    filteredSamples: [],
    paginationSamples: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    resultsHeaders: [],
    initialDataOfAnalytes: {},
    dataOfAnalytes: {},
    averageOutliers: {},
    averageOutliersResults: {},
    filters: {
        filter: []
    },
    groupBy: {
        field: '',
        values: []
    },
    filtersForGroupBy: [],
    fieldsForSamplesInfo: arrayOfFieldForSamplesInfo,
    paramsFromComponents: {
        dataShow: 'selection',
        date: 'creation_date',
        type: 'FG',
        average: false,
        average1: false,
        average2: false,
        average3: false,
        targetValues: false,
        warning: false,
        limits: false,
        connectDots: false,
        trend: false,
        outliers: false,
        filterOutliers: false,
        sampleSelectedInGraphs: '',
        averageOutliers: false,
        activeMatrix: false,
        selectedValues: false

    },
    outliers: {
        outliers_on: 'analyte',
        mdi: false,
        gh: false,
        low_high_limit: false,
        analytes: [
            {
                analyte_id: '',
                max_value: null,
                min_value: null,
                checked: false
            }
        ]
    },
    valuesForSearch: null,
    fetchStatus: "idle",
    fetchError: undefined,
    postFiltersStatus: "idle",
    postFiltersError: undefined,
    searchStatus: "idle",
    searchError: undefined,
} as CurrentState;

// FG
const analytesSelectedInGrapheFG: string[] = ['MOI', 'SST_AS', 'FIBER', 'ASH', 'CP', 'FAT', 'DM', 'PSTARCH']
const analytesSelectedInSelectionFG: string[] = ['MOI', 'SST_AS', 'FIBER', 'ASH', 'CP', 'FAT', 'DM', 'PSTARCH']

// RM
const analytesSelectedInGrapheRM: string[] = ['MOI', 'SST_AS', 'FIBER', 'ASH', 'CP', 'FAT', 'DM', 'PSTARCH']
const analytesSelectedInSelectionRM: string[] = ['MOI', 'SST_AS', 'FIBER', 'ASH', 'CP', 'FAT', 'DM', 'PSTARCH']

// FP
const analytesSelectedInGrapheFP: string[] = ['MOI', 'SST_AS', 'FIBER', 'ASH', 'CP', 'FAT', 'DM', 'PSTARCH']
const analytesSelectedInSelectionFP: string[] = ['MOI', 'SST_AS', 'FIBER', 'ASH', 'CP', 'FAT', 'DM', 'PSTARCH']

const removeDuplicates = (array : ResultHeader[]) => {
    const mapWithoutDuplicates = new Map()

    array.forEach(item => {
        const alreadyExist = mapWithoutDuplicates.get(item.id.toLocaleUpperCase())
        if(!alreadyExist){
            mapWithoutDuplicates.set(item.id.toLocaleUpperCase(), item)
        }
    })

    return [...mapWithoutDuplicates.values()]
}

export const getKeyForDashbpardAnalysisHeader = (dashboardAnalysisHeader: DashboardAnalysisHeader) => {
    return `${dashboardAnalysisHeader.laboratory_id}#${dashboardAnalysisHeader.analysis_id}`
}

export const postFilters = createAsyncThunk(
    "postFilters",
    async ({ dashboardName, body }: { dashboardName: string, body: FiltersRequest }) => {
        const response = await DashboardApi.getInstance().postFilters(dashboardName, body);
        return response as FiltersResponse;
    }
); 

export const search = createAsyncThunk(
    "search",
    async ({ dashboardName, body }: { dashboardName: string, body: SearchRequest }) => {
        const response = await DashboardApi.getInstance().search(dashboardName, body);
        return response as DashboardAnalysisHeader[];
    }
); 

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        resetFiltersState(state: CurrentState) {
            state.filters = {filter: []}
            state.samplesForData = {}
            state.filteredSamples = []
            state.resultsHeaders = []
            state.selectedValues = {
                selected_values : [],
                commentary : ""
            }
            state.activeMatrix = undefined
            state.paginationSamples = {
                nbByPage: 25,
                nbPage: 0,
                currentPage: 1,
                filterBy: {},
                sortBy: [],
                pages: {},
                count: 0,
                status: "idle",
                error: undefined,
            }
            state.fieldsForSamplesInfo = arrayOfFieldForSamplesInfo
            state.groupBy = {
                field: '',
                values: []
            }
            state.postFiltersStatus = "idle";
            state.searchStatus = "idle";
            state.paramsFromComponents.sampleSelectedInGraphs = ""
        },
        resetSearchState(state: CurrentState) {
            state.samplesForData = {}
            state.filteredSamples = []
            state.groupBy = {
                field: '',
                values: []
            }
            state.fieldsForSamplesInfo = arrayOfFieldForSamplesInfo
            state.paginationSamples = {
                nbByPage: 25,
                nbPage: 0,
                currentPage: 1,
                filterBy: {},
                sortBy: [],
                pages: {},
                count: 0,
                status: "idle",
                error: undefined,
            }
            state.searchStatus = "idle";
        },
        setWeights(state: CurrentState, action: PayloadAction<Array<Weight>>) {
            state.weights = action.payload;
        },
        resetParamsFromComponents(state: CurrentState) {
            state.paramsFromComponents = {
                dataShow: 'selection',
                date: 'creation_date',
                type: 'FG',
                average: false,
                average1: false,
                average2: false,
                average3: false,
                targetValues: false,
                warning: false,
                limits: false,
                connectDots: false,
                trend: false,
                outliers: false,
                filterOutliers: false,
                sampleSelectedInGraphs: '',
                averageOutliers: false,
                activeMatrix: false,
                selectedValues: false
            }
        },
        setSelectedValues(state: CurrentState, action: PayloadAction<SelectedValues>) {
            state.selectedValues = action.payload;
        },
        setSamplesFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filteredSamples = action.payload;
        },
        setMatrixFields(state: CurrentState, action: PayloadAction<MatrixFields>) {
            state.matrixFields = action.payload;
        },
        resetMatrixFields(state: CurrentState) {
            state.matrixFields = undefined;
        },
        setSamplesFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload };
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all;
            }
            state.paginationSamples.filterBy = filterBy;
        },
        setSamplesSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.paginationSamples.sortBy = action.payload;
        },
        requestSamplesPage(state: CurrentState, action: PayloadAction<number>) {
            state.paginationSamples.currentPage = action.payload;
        },
        setSamplesNbByPage(state: CurrentState, action: PayloadAction<number>) {
            state.paginationSamples.nbByPage = action.payload;
            const count = state.filteredSamples.length;
            state.paginationSamples.count = count;
            state.paginationSamples.nbPage = Math.ceil(
                count / state.paginationSamples.nbByPage
            );
        },
        setType(state: CurrentState, action: PayloadAction<'FG' | 'RM' | 'FP'>) {
            state.paramsFromComponents.type = action.payload;
        },
        setDate(state: CurrentState, action: PayloadAction<'creation_date' | 'validation_date' | 'delivery_date' | 'sample_date'>) {
            state.paramsFromComponents.date = action.payload;
        },
        setAverageOutliers(state: CurrentState, action: PayloadAction<void>) {
            state.paramsFromComponents.averageOutliers = !state.paramsFromComponents.averageOutliers;
        },
        selectUnselectLinesOfSample(state: CurrentState, action: PayloadAction<string>) {
            const bool = !(state.samplesForData[action.payload].results.filter(r => r.selected).length > 0)
            state.samplesForData[action.payload].selected = bool
            state.samplesForData[action.payload].results = state.samplesForData[action.payload].results.map(r => {return {...r, selected: bool}})
        },
        selectUnselectAllLinesOfSamples(state: CurrentState, action: PayloadAction<{samples: string[], bool: boolean}>) {
            action.payload.samples.forEach((key: string) => {
                state.samplesForData[key].selected = action.payload.bool
                state.samplesForData[key].results = state.samplesForData[key].results.map(r => {return {...r, selected: action.payload.bool}})
            })
        },
        selectUnselectResultOfSample(state: CurrentState, action: PayloadAction<{key: string, analyteId: string}>) {
            const key = action.payload.key
            const analyteId = action.payload.analyteId
            const index = state.samplesForData[key].results.findIndex(r => r.analyte_id.toUpperCase() === analyteId.toUpperCase())
            if(index !== -1){
                const bool = !state.samplesForData[key].results[index].selected
                state.samplesForData[key].results[index] = {...state.samplesForData[key].results[index], selected: bool}
            }
        },
        setHeaderSelected(state: CurrentState, action: PayloadAction<{bool: boolean, analyteId: string}>) {
            const bool = action.payload.bool
            const analyteId = action.payload.analyteId
            const index = state.resultsHeaders.findIndex(rh => rh.id.toUpperCase() === analyteId.toUpperCase())
            if(index !== -1){
                state.resultsHeaders[index].selected = bool
            }
        },
        selectUnselectAllResultsOfAnalyte(state: CurrentState, action: PayloadAction<{samples: string[], bool: boolean, analyteId: string}>) {
            const bool = action.payload.bool
            const analyteId = action.payload.analyteId
            const samples = action.payload.samples
            Object.values(state.samplesForData).forEach((s) => {
                if(samples.includes(getKeyForDashbpardAnalysisHeader(s))){
                    s.results.forEach((r, index) => {
                        if(r.analyte_id.toUpperCase() === analyteId.toUpperCase()){
                            state.samplesForData[getKeyForDashbpardAnalysisHeader(s)].results[index] = {...r, selected: bool}
                        }
                    })
                }
            })
        },
        setDataShow(state: CurrentState, action: PayloadAction<Selection>) {
            const option = action.payload
            state.paramsFromComponents.dataShow = option
        },
        setSelectOptions(state: CurrentState, action: PayloadAction<OptionType>) {
            const option = action.payload
            state.paramsFromComponents[option] = !state.paramsFromComponents[option]
        },
        setResultsHeader(state: CurrentState, action: PayloadAction<any[]>) {
            state.resultsHeaders = action.payload
        },
        setInitialDataOfAnalytes(state: CurrentState, action: PayloadAction<Data<DatasCalculated>>) {
            state.initialDataOfAnalytes = action.payload
        },
        setDataOfAnalytes(state: CurrentState, action: PayloadAction<Data<DatasCalculated>>) {
            state.dataOfAnalytes = action.payload
        },
        setSampleSelectedInGraphs(state: CurrentState, action: PayloadAction<string>) {
            state.paramsFromComponents.sampleSelectedInGraphs = action.payload
        },
        setGroupBy(state: CurrentState, action: PayloadAction<GroupBy>) {
            state.groupBy = action.payload
        },
        setDataOfAnalytesForGroupBy(state: CurrentState, action: PayloadAction<{value: string, datas: Data<DatasCalculated>}>) {
            if(state.groupBy.values.find(v => v.value === action.payload.value)){
                state.groupBy.values.find(v => v.value === action.payload.value)!.dataOfAnalytes = action.payload.datas
            }else if(action.payload.value === others){
                state.groupBy.values.push({
                    value: others,
                    color: "",
                    dataOfAnalytes: action.payload.datas
                })
            }
        },
        setFiltersForGroup(state: CurrentState, action: PayloadAction<SearchRequest>) {
            const arrayFilters: Filter[] = []
            action.payload.filter_set.forEach(fs => fs.filter.forEach(f => {
                const alreadyExist = arrayFilters.find(af => af.field === f.field)
                if(alreadyExist){
                    f.values.forEach(v => {
                        if(!alreadyExist.values.includes(v)){
                            alreadyExist.values.push(v)
                        }
                    })
                }else{
                    arrayFilters.push(f)
                }
            }))
            action.payload.common_filter.filter.forEach(f => {
                const alreadyExist = arrayFilters.find(af => af.field === f.field)
                if(alreadyExist){
                    f.values.forEach(v => {
                        if(!alreadyExist.values.includes(v)){
                            alreadyExist.values.push(v)
                        }
                    })
                }else{
                    arrayFilters.push(f)
                }
            })
            state.filtersForGroupBy = arrayFilters
        },
        setFieldsForSamplesInfo(state: CurrentState, action: PayloadAction<FieldForSamplesInfo[]>) {
            state.fieldsForSamplesInfo = action.payload
        },
        setValuesForSearch(state: CurrentState, action: PayloadAction<ValuesForSearch>) {
            state.valuesForSearch = action.payload
        },
        setOutliers(state: CurrentState, action: PayloadAction<OutliersForModal>) {
            state.outliers = action.payload
        },
        resetOutliers(state: CurrentState) {
            state.outliers = {
                outliers_on: 'analyte',
                mdi: false,
                gh: false,
                low_high_limit: false,
                analytes: [
                    {
                        analyte_id: '',
                        max_value: null,
                        min_value: null,
                        checked: false
                    }
                ]
            }
        },
        manageOutliers(state: CurrentState) {
            const averageOutliers = JSON.parse(JSON.stringify(state.averageOutliers))
            const averageOutliersResults = JSON.parse(JSON.stringify(state.averageOutliersResults))
            Object.values(state.samplesForData).forEach((sample: any) => {
                let outlierSample = false
                sample.results.forEach((r: any) => {
                    if(state.dataOfAnalytes[r.analyte_id.toUpperCase()]){
                        if(state.paramsFromComponents.average1){
                            if(r.default_unit_result > state.dataOfAnalytes[r.analyte_id.toUpperCase()].averagePlus || r.default_unit_result < state.dataOfAnalytes[r.analyte_id.toUpperCase()].averageMinus){
                                if(state.outliers.outliers_on === 'sample'){
                                    outlierSample = true
                                }
                                averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sample)}#${r.analyte_id.toUpperCase()}`] = true
                            }else{
                                averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sample)}#${r.analyte_id.toUpperCase()}`] = false
                            }
                        }else if(state.paramsFromComponents.average2){
                            if(r.default_unit_result > state.dataOfAnalytes[r.analyte_id.toUpperCase()].averagePlus2 || r.default_unit_result < state.dataOfAnalytes[r.analyte_id.toUpperCase()].averageMinus2){
                                if(state.outliers.outliers_on === 'sample'){
                                    outlierSample = true
                                }
                                averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sample)}#${r.analyte_id.toUpperCase()}`] = true
                            }else{
                                averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sample)}#${r.analyte_id.toUpperCase()}`] = false
                            }
                        }else if(state.paramsFromComponents.average3){
                            if(r.default_unit_result > state.dataOfAnalytes[r.analyte_id.toUpperCase()].averagePlus3 || r.default_unit_result < state.dataOfAnalytes[r.analyte_id.toUpperCase()].averageMinus3){
                                if(state.outliers.outliers_on === 'sample'){
                                    outlierSample = true
                                }
                                averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sample)}#${r.analyte_id.toUpperCase()}`] = true
                            }else{
                                averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sample)}#${r.analyte_id.toUpperCase()}`] = false
                            }
                        }
                    }
                })
                averageOutliers[`${getKeyForDashbpardAnalysisHeader(sample)}`] = outlierSample
            });
            state.averageOutliers = averageOutliers
            state.averageOutliersResults = averageOutliersResults
        },
    },
    extraReducers: builder => {
        builder.addCase(postFilters.pending, (state) => {
            state.postFiltersStatus = "loading";
        });
        builder.addCase(postFilters.fulfilled, (state, action) => {
            state.filters = action.payload
            state.postFiltersStatus = "succeeded";
        });
        builder.addCase(postFilters.rejected, (state, action) => {
            state.postFiltersStatus = "failed";
            state.postFiltersError = action.error.message;
        });
        builder.addCase(search.pending, (state) => {
            state.searchStatus = "loading";
        });
        builder.addCase(search.fulfilled, (state, action) => {
            state.samplesForData = {}
            state.filteredSamples = []
            state.resultsHeaders = []
            action.payload.sort((a, b) => a[state.paramsFromComponents.date] < b[state.paramsFromComponents.date] ? 1 : -1).forEach((item: DashboardAnalysisHeader) => {
                if(item){
                    const results = item.results.map(result => {
                        let listByTypeGraph: string[] = []
                        let listByTypeSelection: string[] = []
                        switch (state.paramsFromComponents.type) {
                            case 'FG':
                                listByTypeGraph = analytesSelectedInGrapheFG
                                listByTypeSelection = analytesSelectedInSelectionFG
                                break;
                            case 'RM':
                                listByTypeGraph = analytesSelectedInGrapheRM
                                listByTypeSelection = analytesSelectedInSelectionRM
                                break;
                            case 'FP':
                                listByTypeGraph = analytesSelectedInGrapheFP
                                listByTypeSelection = analytesSelectedInSelectionFP
                                break;
                        }
                        state.resultsHeaders.push({ id: result.analyte_id.toUpperCase(), name: result.analyte_name, selected: true, display_in_graph: listByTypeGraph.includes(result.analyte_id.toUpperCase()) ? true : false, display_in_selection: listByTypeSelection.includes(result.analyte_id.toUpperCase()) ? true : false });
                        return {...result, selected: true}
                    })
                    state.samplesForData[getKeyForDashbpardAnalysisHeader(item)] = {...item, results, selected: true}
                    

                }
            })
            state.initialSamplesForData = state.samplesForData
            state.resultsHeaders = removeDuplicates(state.resultsHeaders)
            state.searchStatus = "succeeded";
        });
        builder.addCase(search.rejected, (state, action) => {
            state.searchStatus = "failed";
            state.searchError = action.error.message;
        });
    },
});

export const {
    resetFiltersState,
    resetSearchState,
    setSamplesFiltered,
    setSamplesFilterBy,
    setSamplesSortBy,
    requestSamplesPage,
    setSamplesNbByPage,
    setDate,
    setType,
    setMatrixFields,
    resetMatrixFields,
    setWeights,
    setSelectedValues,
    selectUnselectLinesOfSample,
    selectUnselectResultOfSample,
    selectUnselectAllLinesOfSamples,
    setHeaderSelected,
    selectUnselectAllResultsOfAnalyte,
    setSelectOptions,
    setResultsHeader,
    setDataOfAnalytes,
    setDataShow,
    setSampleSelectedInGraphs,
    setGroupBy,
    setInitialDataOfAnalytes,
    setDataOfAnalytesForGroupBy,
    setFiltersForGroup,
    setFieldsForSamplesInfo,
    setValuesForSearch,
    setOutliers,
    setAverageOutliers,
    resetOutliers,
    manageOutliers,
    resetParamsFromComponents
} = dashboardSlice.actions;

export default dashboardSlice.reducer;