import axios from 'axios'
import axiosRetry from 'axios-retry'
import env from '../common/utils/env'
import AbstractApi from './AbstractApi'
import moment from 'moment'

export type RoleLevelType = 'laboratory'|'customer'
export type PdcLevelType = 'admin'|'user'

export interface EmployeeRoleLevel {
  laboratory_id: number
  level: RoleLevelType
}

export interface LaboratoryKey {
    laboratory_id : number
  }  

export interface EmployeePdcLevel {
    laboratory_id: number
    level: PdcLevelType
  }

export interface CompaniesIds {
    company_id: number
    lab_type: 'cargill' | 'client'
}

export interface FormulationsIds {
    laboratory_id: number
    lab_type: 'cargill' | 'client'
}

export interface InputInternalUser {
    firstname: string
    lastname: string
    email: string
    language_id: number
    code: string
    employee_id: number
    laboratories?: Array<number> 
    owned_laboratories?: Array<number>
    customers: Array<CompaniesIds>
    companies: Array<CompaniesIds>
    formulations: Array<FormulationsIds>
    role_levels: Array<EmployeeRoleLevel>
    pdc_levels: Array<EmployeePdcLevel>
    all_customers_laboratories: Array<LaboratoryKey>
    sample_notifs: Array<SampleNotification>
    control_plan_notifs: Array<PdcNotification>
    mail_partial_attachment: boolean
}

export interface InputUser extends InputInternalUser {
    group?: string
    roles?: Array<string>
}

export interface PdcNotification {
    sample_status: string
    notif_type: string
}

export interface last_mailing {
    id_labo: number
    date_last_mailing: Date
  }

export interface SampleNotification {
    last_mailing: Array<last_mailing>
    sample_status: string
    notif_type: string
}

export interface User {
    id: number
    external_id: string
    firstname: string
    lastname: string
    email: string
    disabled: boolean
    language_id: number
    code: string
    employee_id: number
    laboratories: Array<number>
    owned_laboratories: Array<number>
    customers: Array<CompaniesIds>
    companies: Array<CompaniesIds>
    formulations: Array<FormulationsIds>
    role_levels: Array<EmployeeRoleLevel>
    pdc_levels: Array<EmployeePdcLevel>
    all_customers_laboratories: Array<LaboratoryKey>
    sample_notifs: Array<SampleNotification>
    control_plan_notifs: Array<PdcNotification>
    mail_partial_attachment: boolean
}

export interface InternalUser {
    id: number
    external_id: string
    firstname: string
    lastname: string
    email: string
    disabled: boolean
    language_id: number
    code: string
    employee_id: number
    laboratories: Array<number>
    owned_laboratories: Array<number>
    role_levels: Array<EmployeeRoleLevel>
    pdc_levels: Array<EmployeePdcLevel>
    all_customers_laboratories: Array<LaboratoryKey>
    customers: Array<CompaniesIds>
    companies: Array<CompaniesIds>
    formulations: Array<FormulationsIds>
    sample_notifs: Array<SampleNotification>
    control_plan_notifs: Array<PdcNotification>
    additional_customers?: Array<CompaniesIds> // calculated field
    mail_partial_attachment: boolean
}

export interface Employee {
    id?: number
    firstname: string
    lastname: string
    signature?: string
    disabled?: boolean
    code: string
    customers: Array<CompaniesIds>
    companies: Array<CompaniesIds>
    formulations: Array<FormulationsIds>
    role_levels: Array<EmployeeRoleLevel>
    pdc_levels: Array<EmployeePdcLevel>
    all_customers_laboratories: Array<LaboratoryKey>
    mail_partial_attachment: boolean
}

export interface Company {
    id?: number
    code: string
    name: string
    address: string
    zip_code: string
    city: string
    country: string
    farmer_level:  'company' | 'employee'
    laboratory_id: number
    language_id?: number
    active: boolean
    email_sample_notifs: EmailsNotifs
    email_control_plan_notifs : EmailCPNotifs
    nir_codes: Array<string>
    client_laboratory_id?: number
    formulation_id?: number
    plant_code?: string
    mail_partial_attachment:{
        FG: boolean
        RM: boolean
        FP: boolean
      }
    group?: string
}

export interface EmailsNotifs {
    FG?: SampleTypeNotif
    RM?: SampleTypeNotif
    FP?: SampleTypeNotif
}

export interface SampleTypeNotif {
    sample_notifs: Array<SampleNotifs>
    cc_emails: Array<string>
    to_emails: string[]
}

export interface EmailCPNotifs {
    control_plan_notifs: Array<ControlPlanNotifs>
    cc_emails: Array<string>
}

export interface SampleNotifs {
    sample_status: string
    notif_type: string
    last_mailing: Date|null
}

export interface ControlPlanNotifs {
    sample_status: string
    notif_type: string
}


export interface Farmer {
    id?: number
    employee_id: number
    name: string
    code: string
    zip_code: string
    city: string
    country: string
    active: boolean
    company_id?:number
}

export interface UserRequest {
    id?: number
    firstname: string
    lastname: string
    email: string
    company: string
    language_id: number
}

const standaloneInstance = axios.create({
    baseURL: env.USERS_API_URL,
    timeout: 60000
})
axiosRetry(standaloneInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay
})

class UsersApi extends AbstractApi {

    private static instance: UsersApi

    public static getInstance() : UsersApi {
        if (!UsersApi.instance) {
            throw new Error('Initialize instance before.')
        }
        return UsersApi.instance
    }

    public static initInstance(token: string) : void {
        UsersApi.instance = new UsersApi(env.USERS_API_URL, token)
    }

    public async getInternalUsers(): Promise<Array<InternalUser>> {
        try {
            const response = await this.service.get('/mp-user')
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getInternalUserById(id: number): Promise<InternalUser> {
        try {
            const encodedId = encodeURIComponent(id)
            const response = await this.service.get('/mp-user/' + encodedId)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getInternalUserByExternalId(external_id: string): Promise<InternalUser> {
        try {
            const encodedExternalId = encodeURIComponent(external_id)
            const response = await this.service.get(`/mp-user/external/${encodedExternalId}`)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getUsers(disabled: boolean, limit: number, after?: string): Promise<{data: Array<User>, after: string|undefined}> {
        try {
            const response = await this.service.get(`/user?limit=${limit}&disabled=${disabled}` + (after ? `&after=${after}` : ""))
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getUserById(id: number): Promise<User> {
        try {
            const encodedId = encodeURIComponent(id)
            const response = await this.service.get('/user/' + encodedId)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getUserByExternalId(external_id: string): Promise<User> {
        try {
            const encodedExternalId = encodeURIComponent(external_id)
            const response = await this.service.get(`/user/external/${encodedExternalId}`)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getUserGroupsByExternalId(external_id: string): Promise<Array<string>> {
        try {
            const encodedExternalId = encodeURIComponent(external_id)
            const response = await this.service.get(`/user/external/${encodedExternalId}/groups`)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postUser(user: InputUser): Promise<User> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const data = JSON.stringify(user)
            const response = await this.service.post('/user', data, config)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postUserFromExternal(external_id: string, user: InputUser): Promise<User> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const encodedExternalId = encodeURIComponent(external_id)
            const response = await this.service.post(`/user/external/${encodedExternalId}`, user, config)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putInternalUser(id: number, user: InputInternalUser): Promise<InternalUser> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const encodedId = encodeURIComponent(id)
            const response = await this.service.put(`/mp-user/${encodedId}`, user, config)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putUser(id: number, user: InputUser): Promise<User> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const encodedId = encodeURIComponent(id)
            const response = await this.service.put(`/user/${encodedId}`, user, config)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deactivateUser(id: number): Promise<void> {
        try {
            const encodedId = encodeURIComponent(id)
            await this.service.put(`/user/${encodedId}/disable`)
            return 
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async activateUser(id: number): Promise<void> {
        try {
            const encodedId = encodeURIComponent(id)
            await this.service.put(`/user/${encodedId}/enable`)
            return 
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getEmployees(laboratory_id?: number, company_id?: number): Promise<Array<Employee>> {
        try {
            let params = ""
            if (laboratory_id || company_id) {
                params += "?"
                if (laboratory_id) {
                    const encodedLabId = encodeURIComponent(laboratory_id)
                    params += `laboratory_id=${encodedLabId}`
                }
                if (company_id) {
                    params += `company_id=${company_id}`
                }
            }
            const response = await this.service.get(`/employees${params}`)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getEmployeeById(id: number): Promise<Employee> {
        try {
            const encodedId = encodeURIComponent(id)
            const response = await this.service.get('/employees/' + encodedId)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postEmployee(employee: FormData): Promise<Employee> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const response = await this.service.post('/employees', employee, config)
            return response.data as Employee;
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putEmployee(id: number, employee: FormData): Promise<Employee> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const encodedId = encodeURIComponent(id)
            const response = await this.service.put(`/employees/${encodedId}`, employee, config)
            return response.data as Employee;
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deactivateEmployee(id: number): Promise<void> {
        try {
            const encodedId = encodeURIComponent(id)
            await this.service.put(`/employees/${encodedId}/disable`)
            return 
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async activateEmployee(id: number): Promise<void> {
        try {
            const encodedId = encodeURIComponent(id)
            await this.service.put(`/employees/${encodedId}/enable`)
            return 
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getCompanies(laboratory_id?: number): Promise<Array<Company>> {
        try {
            let query = ""
            if (laboratory_id) {
                const encodedLaboratory = encodeURIComponent(laboratory_id)
                query += `?laboratory_id=${encodedLaboratory}`
            }
            const response = await this.service.get(`/company${query}`)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getCompaniesByFormulation(formulationId?: number): Promise<Array<Company>> {
        try {
            let query = ""
            if (formulationId) {
                const encodedFormulation = encodeURIComponent(formulationId)
                query += `?formulation_id=${encodedFormulation}`
            }
            const response = await this.service.get(`/company${query}`)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async exportCompanies(formulationId: number): Promise<boolean> {
        try {
            const response = await this.service.get(`/company/export?formulation_id=${formulationId}`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('companies', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getAllCompanies(): Promise<Array<Company>> {
        try {
            const response = await this.service.get('/company')
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getCompanyById(id: number): Promise<Company> {
        try {
            const encodedId = encodeURIComponent(id)
            const response = await this.service.get('/company/' + encodedId)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postCompany(company: Company): Promise<number> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            company.laboratory_id = Number(company.laboratory_id)
            const data = JSON.stringify(company)
            const response = await this.service.post('/company', data, config)
            return response.data.id
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteCompany(company_id: number): Promise<void> {
        try {
            const encodedId = encodeURIComponent(company_id)
            await this.service.delete('/company/'+encodedId)
            return 
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putCompany(company: Company, companyId: number): Promise<Company> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            company.laboratory_id = Number(company.laboratory_id)
            const data = JSON.stringify(company)
            const response = await this.service.put('/company/'+companyId, data, config)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getFarmers(): Promise<Array<Farmer>> {
        try {
            const response = await this.service.get('/farmer')
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getFarmersById(id: number): Promise<Farmer> {
        try {
            const encodedId = encodeURIComponent(id)
            const response = await this.service.get('/farmer/' + encodedId)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }


    public async getFarmersByCompany(companies_ids: number[]): Promise<Array<Farmer>> {
        try {
            let stringcompany_id = "?"
            for (const [index, element] of companies_ids.entries()) {
                if (index > 0) {
                    stringcompany_id += "&"
                }
                stringcompany_id +="company_id="+ encodeURIComponent(element)
            }
            const response = await this.service.get(`/farmer${stringcompany_id}`)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postFarmer(farmer: Farmer): Promise<Farmer> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            farmer.employee_id = Number(farmer.employee_id)
            const data = JSON.stringify(farmer)
            const response = await this.service.post('/farmer', data, config)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteFarmer(farmer_id: number): Promise<void> {
        try {
            await this.service.delete('/farmer/'+farmer_id)
            return 
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putFarmer(farmer: Farmer, farmerId: number): Promise<void> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            // we have to do that because stringify will convert it to a string, with that it is a number after the stringify
            farmer.employee_id = Number(farmer.employee_id)
            const data = JSON.stringify(farmer)
            await this.service.put('/farmer/'+farmerId, data, config)
            return 
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getUserRequests(): Promise<Array<UserRequest>> {
        try {
            const response = await this.service.get('/user_request')
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getUserRequest(id: number): Promise<UserRequest> {
        try {
            const encodedId = encodeURIComponent(id)
            const response = await this.service.get('/user_request/'+encodedId)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public static async postUserRequest(user_request: UserRequest): Promise<UserRequest> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const data = JSON.stringify(user_request)
            const response = await standaloneInstance.post('/user_request/public', data, config)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async deleteUserRequestById(id: number): Promise<void> {
        try {
            const encodedId = encodeURIComponent(id)
            const response = await this.service.delete('/user_request/'+encodedId)
            return response.data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    private downloadBlob(blob: string, fileName: string) {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        document.removeChild(link)
    }

    private getExportedFileName(tableName: string, extension: string) {
        const date = new Date()
        return `${tableName}_export_${moment(date).format('YYYYMMDD-HHmm')}.${extension}`
    }

    public async exportFarmers(company_id: number | null, employee_id: number | null, type : string): Promise<boolean> {
        try {
            let params = `?type=${type}`
            if (employee_id) {
                const encodedEmployeeId = encodeURIComponent(employee_id)
                params += `&employee_id=${encodedEmployeeId}`
            }
            if (company_id) {
                params += `&company_id=${company_id}`
            }
            const response = await this.service.get(`/farmer/export${params}`, {
                responseType: 'blob',
            })

            this.downloadBlob(response.data, this.getExportedFileName('farmers', 'xlsx'))
            return true
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async importFarmers(file: File, company_id: number | null, employee_id: number | null, type : string): Promise<Array<Farmer>> {
        try {
            let params = `?type=${type}`
            if(employee_id){
                const encodedEmployeeId = encodeURIComponent(employee_id)
                params += `&employee_id=${encodedEmployeeId}`
            }
            if(company_id){
                params += `&company_id=${company_id}`
            }
            const formData = new FormData()
            formData.append('file', file, file.name)
            const response = await this.service.post(`/farmer/import${params}`, formData)
            return response.data || []
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }
    
}

export default UsersApi
