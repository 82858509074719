import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { STATUS_CODES } from 'http';

import ConfigApi, { Language, Laboratory, Type, Family, Improper, Pattern, Package, PatternDocument, PackageDocument, Status, StatusType, Partial, Price, Specie, Ingredient, ProductCategory, SpecieDocument, RefSupplier, RefSupplierDocument, RefOriginDocument, RefOrigin, Transport, TransportDocument, Processing, ProcessingDocument, ProductCategoryDocument, Origin, OriginDocument, Supplier, SupplierDocument, TransportTranslation, OriginTranslation, DataList, DataListRequest, PatternWithoutIdActive, Analyte, AnalyteTestPackage, ThresholdsType } from '../api/configApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';
import { tableReducer as analyteReducer } from './analyteSlice';

interface SubStore<T> {
    data: Data<T>
    byLabId?: Data<Array<string>>
    filtered: Array<string>
    pagination: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchSingleStatus?: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchStatusByLabId?: 'idle' | 'loading' | 'succeeded' | 'failed'
    deleteStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postAndPutStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    fetchSingleError?: string | undefined
    deleteError: string | undefined
    postAndPutError: string | undefined
}

interface SubsStoreLanguage extends SubStore<Language> {
    dataByCode: { [key: string]: Language }
}

interface SubStorePcpw<T> {
    data: Data<T>
    byLabId?: Data<Array<string>>
    filtered: Array<string>
    filteredPcpw: Array<string>
    pagination: Pagination
    paginationPcpw: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchStatusByLabId?: 'idle' | 'loading' | 'succeeded' | 'failed'
    deleteStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postAndPutStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    deleteError: string | undefined
    postAndPutError: string | undefined
}

interface StatusStore {
    data: { [key in StatusType]: Data<Status> }
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
}


export interface State {
    dataLists: SubStore<DataList>
    languages: SubsStoreLanguage
    laboratories: SubStore<Laboratory>
    types: SubStore<Type>
    families: SubStore<Family>
    impropers: SubStore<Improper>
    ingredients: SubStore<Ingredient>
    patterns: SubStorePcpw<Pattern>
    packages: SubStorePcpw<Package>
    processing: SubStore<Processing>
    productCategories: SubStore<ProductCategory>
    refOrigins: SubStore<RefOrigin>
    origins: SubStore<Origin>
    refSuppliers: SubStore<RefSupplier>
    suppliers: SubStore<Supplier>
    species: SubStore<Specie>
    transports: SubStore<Transport>
    status: StatusStore
}

export type OriginsExportType = 'origins' | 'translations'
export type RefOriginsExportType = 'ref_origins' | 'translations'

const initialState = {
    dataLists: {
        data: {},
        byLabId: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    languages: {
        data: {},
        dataByCode: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    laboratories: {
        data: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        fetchSingleStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        fetchSingleError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    types: {
        data: {},
        byLabId: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    families: {
        data: {},
        byLabId: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        fetchStatusByLabId: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    impropers: {
        data: {},
        byLabId: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    ingredients: {
        data: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    patterns: {
        data: {},
        byLabId: {},
        filtered: [],
        filteredPcpw: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        paginationPcpw: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        fetchStatusByLabId: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    packages: {
        data: {},
        byLabId: {},
        filtered: [],
        filteredPcpw: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        paginationPcpw: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    processing: {
        data: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    productCategories: {
        data: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    refAnalytes: {
        data: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined

    },
    refOrigins: {
        data: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    origins: {
        data: {},
        byLabId: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    refSuppliers: {
        data: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    suppliers: {
        data: {},
        byLabId: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    species: {
        data: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    tests: {
        data: {},
        byLabId: {},
        filtered: [],
        filteredPcpw: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        paginationPcpw: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    transports: {
        data: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    },
    status: {
        data: {
            "analysis": {},
            "control-plan": {}
        },
        fetchStatus: 'idle',
        fetchError: undefined
    }
} as State

export const fetchDataLists = createAsyncThunk(
    'config/fetchDataLists',
    async () => {
        const response = await ConfigApi.getInstance().getDataLists()
        return response as Array<DataList>
    }
)

export const deleteDataList = createAsyncThunk<void, { form_id: number, field_id: number }>(
    "config/deleteDataList",
    async ({ form_id, field_id }: { form_id: number, field_id: number }, { dispatch }) => {
        await ConfigApi.getInstance().deleteDataList(form_id, field_id)
        dispatch(deleteDataListReducer({ sample_form_id: form_id, field_id: field_id }))
        return
    }
)

export const upsertDataList = createAsyncThunk<DataList, { data_list: DataListRequest, sample_form_id: number, field_id: number }>(
    'config/upsertDataList',
    async ({ data_list, sample_form_id, field_id }: { data_list: DataListRequest, sample_form_id: number, field_id: number }, { dispatch }) => {
        const response = await ConfigApi.getInstance().upsertDataList(data_list, sample_form_id, field_id)
        const retDataList: DataList = response
        dispatch(dataListReducer({ data_list: retDataList }))
        return retDataList
    }
)

export const fetchLanguages = createAsyncThunk(
    'config/fetchLanguages',
    async () => {
        const response = await ConfigApi.getLanguages()
        return response as Array<Language>
    }
)

export const fetchLaboratories = createAsyncThunk(
    'config/fetchLaboratories',
    async () => {
        const response = await ConfigApi.getInstance().getLaboratories()
        return response as Array<Laboratory>
    }
)

export const fetchSingleLaboratory = createAsyncThunk(
    'config/fetchSingleLaboratory',
    async (laboratory_id: number) => {
        const response = await ConfigApi.getInstance().getLaboratoryById(laboratory_id)
        return response as Laboratory
    }
)

export const fetchTypes = createAsyncThunk(
    'config/fetchTypes',
    async () => {
        const response = await ConfigApi.getInstance().getTypes()
        return response as Array<Type>
    }
)

export const deleteType = createAsyncThunk<void, number>(
    "config/deleteType",
    async (id: number, { dispatch, getState }) => {
        const response = await ConfigApi.getInstance().deleteType(id)

        if (response) {
            const globalState: any = getState()
            const types: Data<Type> = globalState.config.types.data
            const families: Data<Family> = globalState.config.families.data
            const impropers: Data<Improper> = globalState.config.impropers.data

            const typeDisabled: Type = { ...types[id] }
            typeDisabled.active = false
            dispatch(typeReducer({ type: typeDisabled }))

            Object.keys(families).forEach((familyId) => {
                if (families[familyId].type_id === id)
                    dispatch(deleteFamily(families[familyId].id))
            })

            Object.keys(impropers).forEach((improperId) => {
                if (impropers[improperId].type_id === id)
                    dispatch(deleteImproper(Number(impropers[improperId].id)))
            })
        } else {
            throw new Error("Unable to delete family")
        }
        return
    }
)

export const fetchFamilies = createAsyncThunk(
    'config/fetchFamilies',
    async (laboratory_id?: number) => {
        const response = await ConfigApi.getInstance().getFamilies(laboratory_id ? laboratory_id : undefined)
        return response as Array<Family>
    }
)

export const deleteFamily = createAsyncThunk<void, number>(
    "config/deleteFamily",
    async (id: number, { dispatch, getState }) => {
        const response = await ConfigApi.getInstance().deleteFamily(id)

        if (response) {
            const globalState: any = getState()
            const families: Data<Family> = globalState.config.families.data
            const patterns: Data<Pattern> = globalState.config.patterns.data
            const packages: Data<Package> = globalState.config.packages.data
            const analytes: Data<Analyte> = globalState.analytes.data
            const analyteTestPackages: Data<AnalyteTestPackage> = globalState.analyteTestsPackages.data

            const familyDisabled: Family = { ...families[id] }
            familyDisabled.active = false
            dispatch(familyReducer({ family: familyDisabled }))

            Object.values(patterns)
                .forEach((aPattern) => {
                    const patternDisabled: Pattern = JSON.parse(JSON.stringify(aPattern))
                    dispatch(patternReducer({ pattern: patternDisabled }))
                    Object.values(packages)
                        .filter(item => item.patterns.some(pattern => pattern.pattern_id === aPattern.id))
                        .forEach((aPackage) => {
                            const packageDisabled: Package = JSON.parse(JSON.stringify(aPackage))
                            if (aPackage.patterns.length === 1) {
                                packageDisabled.active = false
                                Object.values(analytes)
                                    .filter(item => !!Object.values(analyteTestPackages).find(atp => atp.package_id === aPackage.id))
                                    .forEach((aAnalyte) => {
                                        const analyteDisabled: Analyte = { ...aAnalyte }
                                        analyteDisabled.active = false
                                        
                                       dispatch(analyteReducer(analyteDisabled))

                                    })
                            }
                            packageDisabled.patterns.splice(packageDisabled.patterns.findIndex((pattern: any) => pattern.pattern_id === aPattern.id), 1);
                            dispatch(packageReducer({ package: packageDisabled }))

                        })
                })
        } else {
            throw new Error("Unable to delete family")
        }
        return
    }
)

export const fetchImpropers = createAsyncThunk(
    'config/fetchImpropers',
    async (laboratory_id?: number) => {
        const response = await ConfigApi.getInstance().getImpropers(laboratory_id ? laboratory_id : undefined)
        return response as Array<Improper>
    }
)

export const deleteImproper = createAsyncThunk(
    'config/deleteImproper',
    async (id: number, { dispatch, getState }) => {
        const response = await ConfigApi.getInstance().deleteImproper(id)

        const globalState: any = getState()
        const impropers: Data<Improper> = globalState.config.impropers.data

        if (response) {
            const improperDisabled: Improper = { ...impropers[id] }
            improperDisabled.active = false
            dispatch(improperReducer({ improper: improperDisabled }))
        } else {
            throw new Error("Unable to delete improper")
        }
        return
    }
)

export const fetchIngredients = createAsyncThunk(
    'config/fetchIngredients',
    async () => {
        const response = await ConfigApi.getInstance().getIngredients()
        return response as Array<Ingredient>
    }
)

export const upsertIngredient = createAsyncThunk<Ingredient, { ingredient: Ingredient, jcode: string | undefined }>(
    'config/upsertIngredient',
    async ({ ingredient, jcode }: { ingredient: Ingredient, jcode: string | undefined }, { dispatch, getState }) => {
        const globalState: any = getState()
        const ingredients: Data<Ingredient> = globalState.config.ingredients.data
        let retIngredient: any = null

        if (jcode && !Object.keys(ingredients).includes(jcode)) {
            ingredient.jcode = jcode
            const response = await ConfigApi.getInstance().postIngredient(ingredient)
            retIngredient = response
        } else {
            if (jcode) {
                const response = await ConfigApi.getInstance().putIngredient(ingredient, jcode)
                retIngredient = response
            }
        }
        dispatch(ingredientReducer(retIngredient))
        return retIngredient
    }
)

export const fetchPatterns = createAsyncThunk(
    'config/fetchPatterns',
    async (laboratory_id?: number) => {
        const response = await ConfigApi.getInstance().getPatterns(laboratory_id ? laboratory_id : undefined)
        return response as Array<Pattern>
    }
)

export const upsertPattern = createAsyncThunk<Pattern, { pattern: PatternWithoutIdActive, id: number, laboratoryId: number }>(
    'config/upsertPattern',
    async ({ pattern, id, laboratoryId }: { pattern: PatternWithoutIdActive, id: number, laboratoryId: number }, { dispatch }) => {
        let retPattern: any = pattern
        if (id === 0) {
            const response = await ConfigApi.getInstance().postPattern(pattern)
            retPattern = response
        } else {
            const response = await ConfigApi.getInstance().putPattern(pattern, id)
            retPattern = response
        }
        dispatch(patternReducer({ laboratoryId: laboratoryId, pattern: retPattern }))
        return retPattern
    }
)

export const updatePatternPartial = createAsyncThunk<Partial, { partial: Partial, id: number }>(
    'config/updatePatternPartial',
    async ({ partial, id }: { partial: Partial, id: number }, { dispatch }) => {
        const ret = partial
        await ConfigApi.getInstance().postPatternPartial(partial, id)

        dispatch(patternPartialReducer({ ret, id }))

        return ret
    }
)

export const updatePatternPrices = createAsyncThunk<Array<Price>, { prices: Array<Price>, id: number }>(
    'config/updatePatternPrices',
    async ({ prices, id }: { prices: Array<Price>, id: number }, { dispatch }) => {
        const ret: any = { "prices": {} }
        prices.forEach(p => {
            ret.prices[p.price_list_id] = p
        })
        await ConfigApi.getInstance().putPatternPrices(Object.values(prices), id)

        dispatch(patternPartialReducer({ ret, id }))

        return ret
    }
)

export const deletePattern = createAsyncThunk<void, number>(
    "config/deletePattern",
    async (id: number, { dispatch, getState }) => {
        const response = await ConfigApi.getInstance().deletePattern(id)
        if (response) {
            const globalState: any = getState()
            const patterns: Data<Pattern> = globalState.config.patterns.data
            const packages: Data<Package> = globalState.config.packages.data
            const analytes: Data<Analyte> = globalState.analytes.data
            const analyteTestPackages: Data<AnalyteTestPackage> = globalState.analyteTestsPackages.data

            const patternDisabled: Pattern = { ...patterns[id] }
            patternDisabled.active = false
            dispatch(patternReducer({ pattern: patternDisabled }))

            Object.values(packages)
                .filter(item => item.patterns.some(pattern => pattern.pattern_id === id))
                .forEach((aPackage) => {
                    const packageDisabled: Package = JSON.parse(JSON.stringify(aPackage))
                    if (aPackage.patterns.length === 1) {
                        packageDisabled.active = false

                        Object.values(analytes)
                        .filter(item => !!Object.values(analyteTestPackages).find(atp => atp.package_id === aPackage.id))
                        .forEach((aTest) => {
                                const analytesDisabled: Analyte = { ...aTest }
                                analytesDisabled.active = false
                                dispatch(analyteReducer(analytesDisabled))
                            })
                    }
                    packageDisabled.patterns.splice(packageDisabled.patterns.findIndex((pattern: any) => pattern.pattern_id === id), 1);
                    dispatch(packageReducer({ package: packageDisabled }))
                })

        } else {
            throw new Error("Unable to delete pattern")
        }
        return
    }
)

export const fetchPackages = createAsyncThunk(
    'config/fetchPackages',
    async (laboratory_id?: number) => {
        const response = await ConfigApi.getInstance().getPackages(laboratory_id ? laboratory_id : undefined)
        return response as Array<Package>
    }
)

export const upsertPackage = createAsyncThunk<Package, { packageObject: PackageDocument, id: number, laboratoryId: number }>(
    'config/upsertPackage',
    async ({ packageObject, id, laboratoryId }: { packageObject: PackageDocument, id: number, laboratoryId: number }, { dispatch }) => {
        let retPackage: any = null
        if (id === 0) {
            const response = await ConfigApi.getInstance().postPackage(packageObject)
            retPackage = response
        } else {
            const response = await ConfigApi.getInstance().putPackage(packageObject, id)
            retPackage = response
        }
        dispatch(packageReducer({ package: retPackage, laboratoryId: laboratoryId }))
        return retPackage
    }
)

export const updatePackagePartial = createAsyncThunk<Partial, { partial: Partial, id: number }>(
    'config/updatePackagePartial',
    async ({ partial, id }: { partial: Partial, id: number }, { dispatch }) => {
        const ret = partial
        await ConfigApi.getInstance().postPackagePartial(partial, id)

        dispatch(packagePartialReducer({ ret, id }))

        return ret
    }
)

export const updatePackagePrices = createAsyncThunk<Array<Price>, { prices: Array<Price>, id: number }>(
    'config/updatePackagePrices',
    async ({ prices, id }: { prices: Array<Price>, id: number }, { dispatch }) => {
        const ret: any = { "prices": {} }
        prices.forEach(p => {
            ret.prices[p.price_list_id] = p
        })
        await ConfigApi.getInstance().putPackagePrices(Object.values(prices), id)

        dispatch(packagePartialReducer({ ret, id }))

        return ret
    }
)

export const fetchProcessing = createAsyncThunk(
    'config/fetchProcessing',
    async () => {
        const response = await ConfigApi.getInstance().getProcessing()
        return response as Array<Processing>
    }
)

export const deleteProcessing = createAsyncThunk(
    'config/deleteProcessing',
    async (id: number, { dispatch, getState }) => {
        const response = await ConfigApi.getInstance().deleteProcessing(id)

        const globalState: any = getState()
        const processing: Data<Processing> = globalState.config.processing.data

        if (response) {
            const processingDisabled: Processing = { ...processing[id] }
            processingDisabled.active = false
            dispatch(processingReducer(processingDisabled))
        } else {
            throw new Error("Unable to delete processing")
        }
        return
    }
)

export const upsertProcessing = createAsyncThunk<Processing, { processing: ProcessingDocument, id: number }>(
    'config/upsertProcessing',
    async ({ processing, id }: { processing: ProcessingDocument, id: number }, { dispatch }) => {
        let retProcessing: any = null
        if (id === 0) {
            const response = await ConfigApi.getInstance().postProcessing(processing)
            retProcessing = response
        } else {
            const response = await ConfigApi.getInstance().putProcessing(processing, id)
            retProcessing = response
        }
        dispatch(processingReducer(retProcessing))
        return retProcessing
    }
)

export const fetchProductCategories = createAsyncThunk(
    'config/fetchProductCategories',
    async () => {
        const response = await ConfigApi.getInstance().getProductCategories()
        return response as Array<ProductCategory>
    }
)

export const deleteProductCategory = createAsyncThunk<void, number>(
    'config/deleteProductCategory',
    async (id: number, { dispatch, getState }) => {
        await ConfigApi.getInstance().deleteProductCategory(id)
        const globalState: any = getState()
        const productCategories: Data<ProductCategory> = globalState.config.productCategories.data

        const productCategoryDisabled: ProductCategory = { ...productCategories[id] }
        productCategoryDisabled.active = false
        dispatch(productCategoryReducer(productCategoryDisabled))
        return
    }
)

export const upsertProductCategory = createAsyncThunk<ProductCategory, { productCategory: ProductCategoryDocument, id: number }>(
    'config/upsertProductCategory',
    async ({ productCategory, id }: { productCategory: ProductCategoryDocument, id: number }, { dispatch }) => {
        let retProductCategory: any = null
        if (id === 0) {
            const response = await ConfigApi.getInstance().postProductCategory(productCategory)
            retProductCategory = response
        } else {
            const response = await ConfigApi.getInstance().putProductCategory(productCategory, id)
            retProductCategory = response
        }
        dispatch(productCategoryReducer(retProductCategory))
        return retProductCategory
    }
)

export const fetchRefOrigins = createAsyncThunk(

    'config/fetchRefOrigins',
    async () => {
        const response = await ConfigApi.getInstance().getRefOrigins()
        return response as Array<RefOrigin>
    }
)

export const deleteRefOrigin = createAsyncThunk(
    'config/deleteRefOrigin',
    async (id: number, { dispatch, getState }) => {
        const response = await ConfigApi.getInstance().deleteRefOrigin(id)

        const globalState: any = getState()
        const refOrigins: Data<RefOrigin> = globalState.config.refOrigins.data

        if (response) {
            const refOriginDisabled: RefOrigin = { ...refOrigins[id] }
            refOriginDisabled.active = false
            dispatch(refOriginReducer(refOriginDisabled))
        } else {
            throw new Error("Unable to delete ref origin")
        }
        return
    }
)

export const upsertRefOrigin = createAsyncThunk<RefOrigin, { refOrigin: RefOriginDocument, id: number }>(
    'config/upsertRefSupplier',
    async ({ refOrigin, id }: { refOrigin: RefOriginDocument, id: number }, { dispatch }) => {
        let retRefOrigin: any = null
        if (id === 0) {
            const response = await ConfigApi.getInstance().postRefOrigin(refOrigin)
            retRefOrigin = response
        } else {
            const response = await ConfigApi.getInstance().putRefOrigin(refOrigin, id)
            retRefOrigin = response
        }
        dispatch(refOriginReducer(retRefOrigin))
        return retRefOrigin
    }
)

export const fetchExportRefOrigins = createAsyncThunk(
    'config/fetchExportRefSuppliers',
    async (type: RefOriginsExportType) => {
        const response = await ConfigApi.getInstance().exportRefOrigins(type)
        return response
    }
)

export const fetchOrigins = createAsyncThunk(
    'config/fetchOrigins',
    async () => {
        const response = await ConfigApi.getInstance().getOrigins()
        return response as Array<Origin>
    }
)

export const deleteOrigin = createAsyncThunk(
    'config/deleteOrigin',
    async (id: number, { dispatch, getState }) => {
        const response = await ConfigApi.getInstance().deleteOrigin(id)

        const globalState: any = getState()
        const origins: Data<Origin> = globalState.config.origins.data

        if (response) {
            const originDisabled: Origin = { ...origins[id] }
            originDisabled.active = false
            dispatch(originReducer({ origin: originDisabled }))
        } else {
            throw new Error("Unable to delete origin")
        }
        return
    }
)

export const upsertOrigin = createAsyncThunk<Origin, { origin: OriginDocument, id: number }>(
    'config/upsertOrigin',
    async ({ origin, id }: { origin: OriginDocument, id: number }, { dispatch }) => {
        let retOrigin: any = null
        if (id === 0) {
            const response = await ConfigApi.getInstance().postOrigin(origin)
            retOrigin = response
        } else {
            const response = await ConfigApi.getInstance().putOrigin(origin, id)
            retOrigin = response
        }
        dispatch(originReducer({ origin: retOrigin }))
        return retOrigin
    }
)

export const fetchExportOrigins = createAsyncThunk(
    'config/fetchExportOrigins',
    async ({labId, type}: {labId: number, type: OriginsExportType}) => {
        const response = await ConfigApi.getInstance().exportOrigins(labId, type)
        return response
    }
)

export const fetchExportRefSuppliers = createAsyncThunk(
    'config/fetchExportRefSuppliers',
    async () => {
        const response = await ConfigApi.getInstance().exportRefSuppliers()
        return response
    }
)

export const fetchExportRuleAnalyteTestThresholds = createAsyncThunk(
    'config/fetchExportRuleAnalyteTestThresholds',
    async ({ type, labId }: { type: string, labId: number }) => {
        const response = await ConfigApi.getInstance().exportRuleAnalyteTestThresholds(type, labId)
        return response
    }
)

export const fetchRefSuppliers = createAsyncThunk(
    'config/fetchRefSuppliers',
    async () => {
        const response = await ConfigApi.getInstance().getRefSuppliers()
        return response as Array<RefSupplier>
    }
)

export const deleteRefSupplier = createAsyncThunk(
    'config/deleteRefSupplier',
    async (id: number, { dispatch, getState }) => {
        const response = await ConfigApi.getInstance().deleteRefSupplier(id)

        const globalState: any = getState()
        const refSuppliers: Data<RefSupplier> = globalState.config.refSuppliers.data

        if (response) {
            const refSupplierDisabled: RefSupplier = { ...refSuppliers[id] }
            refSupplierDisabled.active = false
            dispatch(refSupplierReducer(refSupplierDisabled))
        } else {
            throw new Error("Unable to delete ref supplier")
        }
        return
    }
)

export const upsertRefSupplier = createAsyncThunk<RefSupplier, { refSupplier: RefSupplierDocument, id: number }>(
    'config/upsertRefSupplier',
    async ({ refSupplier, id }: { refSupplier: RefSupplierDocument, id: number }, { dispatch }) => {
        let retRefSupplier: any = null
        if (id === 0) {
            const response = await ConfigApi.getInstance().postRefSupplier(refSupplier)
            retRefSupplier = response
        } else {
            const response = await ConfigApi.getInstance().putRefSupplier(refSupplier, id)
            retRefSupplier = response
        }
        dispatch(refSupplierReducer(retRefSupplier))
        return retRefSupplier
    }
)

export const fetchSuppliers = createAsyncThunk(
    'config/fetchSuppliers',
    async () => {
        const response = await ConfigApi.getInstance().getSuppliers()
        return response as Array<Supplier>
    }
)

export const fetchExportSuppliers = createAsyncThunk(
    'config/fetchExportSuppliers',
    async (labId: number) => {
        const response = await ConfigApi.getInstance().exportSuppliers(labId)
        return response
    }
)

export const deleteSupplier = createAsyncThunk(
    'config/deleteSupplier',
    async (id: number, { dispatch, getState }) => {
        const response = await ConfigApi.getInstance().deleteSupplier(id)

        const globalState: any = getState()
        const suppliers: Data<Supplier> = globalState.config.suppliers.data

        if (response) {
            const supplierDisabled: Supplier = { ...suppliers[id] }
            supplierDisabled.active = false
            dispatch(supplierReducer({ supplier: supplierDisabled }))
        } else {
            throw new Error("Unable to delete supplier")
        }
        return
    }
)

export const upsertSupplier = createAsyncThunk<Supplier, { supplier: SupplierDocument, id: number }>(
    'config/upsertSupplier',
    async ({ supplier, id }: { supplier: SupplierDocument, id: number }, { dispatch }) => {
        let retSupplier: any = null
        if (id === 0) {
            const response = await ConfigApi.getInstance().postSupplier(supplier)
            retSupplier = response
        } else {
            const response = await ConfigApi.getInstance().putSupplier(supplier, id)
            retSupplier = response
        }
        dispatch(supplierReducer({ supplier: retSupplier }))
        return retSupplier
    }
)

export const fetchSpecies = createAsyncThunk(
    'config/fetchSpecies',
    async () => {
        const response = await ConfigApi.getInstance().getSpecies()
        return response as Array<Specie>
    }
)

export const deleteSpecie = createAsyncThunk(
    'config/deleteSpecie',
    async (id: number, { dispatch, getState }) => {
        const response = await ConfigApi.getInstance().deleteSpecie(id)

        const globalState: any = getState()
        const species: Data<Specie> = globalState.config.species.data

        if (response) {
            const specieDisabled: Specie = { ...species[id] }
            specieDisabled.active = false
            dispatch(specieReducer(specieDisabled))
        } else {
            throw new Error("Unable to delete specie")
        }
        return
    }
)

export const upsertSpecie = createAsyncThunk<Specie, { specie: SpecieDocument, id: number }>(
    'config/upsertSpecie',
    async ({ specie, id }: { specie: SpecieDocument, id: number }, { dispatch }) => {
        let retSpecie: any = null
        if (id === 0) {
            const response = await ConfigApi.getInstance().postSpecie(specie)
            retSpecie = response
        } else {
            const response = await ConfigApi.getInstance().putSpecie(specie, id)
            retSpecie = response
        }
        dispatch(specieReducer(retSpecie))
        return retSpecie
    }
)

export const fetchTransports = createAsyncThunk(
    'config/fetchTransports',
    async () => {
        const response = await ConfigApi.getInstance().getTransports()
        return response as Array<Transport>
    }
)

export const deleteTransport = createAsyncThunk(
    'config/deleteTransport',
    async (id: number, { dispatch, getState }) => {
        const response = await ConfigApi.getInstance().deleteTransport(id)

        const globalState: any = getState()
        const transports: Data<Transport> = globalState.config.transports.data

        if (response) {
            const transportDisabled: Transport = { ...transports[id] }
            transportDisabled.active = false
            dispatch(transportReducer(transportDisabled))
        } else {
            throw new Error("Unable to delete transport")
        }
        return
    }
)

export const upsertTransport = createAsyncThunk<Transport, { transport: TransportDocument, id: number }>(
    'config/upsertTransport',
    async ({ transport, id }: { transport: TransportDocument, id: number }, { dispatch }) => {
        let retTransport: any = null
        if (id === 0) {
            const response = await ConfigApi.getInstance().postTransport(transport)
            retTransport = response
        } else {
            const response = await ConfigApi.getInstance().putTransport(transport, id)
            retTransport = response
        }
        dispatch(transportReducer(retTransport))
        return retTransport
    }
)


export const fetchStatusList = createAsyncThunk(
    'config/fetchStatus',
    async () => {
        const response = await ConfigApi.getInstance().getStatus()
        return response as Array<Status>
    }
)

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        deleteDataListReducer(state: State, action: PayloadAction<{ sample_form_id: number, field_id: number }>) {
            const item = action.payload
            if (item) {
                const datalist = state.dataLists.data[item.sample_form_id + ',' + item.field_id]
                if (datalist) {

                    // Delete datalist id from byLabId if exists
                    if (state.dataLists.byLabId && state.dataLists.byLabId[datalist.laboratory_id]) {
                        const index = state.dataLists.byLabId[datalist.laboratory_id].findIndex(tuple => tuple === item.sample_form_id + ',' + item.field_id)
                        if (index !== -1) state.dataLists.byLabId[datalist.laboratory_id].splice(index, 1)
                    }
                    delete state.dataLists.data[item.sample_form_id + ',' + item.field_id]
                }
            }
            state.dataLists.deleteStatus = 'succeeded'
        },
        dataListReducer(state: State, action: PayloadAction<{ data_list: DataList }>) {
            const item = action.payload.data_list

            if (item) {
                state.dataLists.data[item.sample_form_id + ',' + item.field_id] = item

                // Add datalist id in byLabId if not exists
                if (state.dataLists.byLabId) {
                    if (state.dataLists.byLabId[item.laboratory_id]) {
                        const index = state.dataLists.byLabId[item.laboratory_id].findIndex(tuple => tuple === item.sample_form_id + ',' + item.field_id)
                        if (index === -1) state.dataLists.byLabId[item.laboratory_id].push(item.sample_form_id + ',' + item.field_id)
                    } else {
                        state.dataLists.byLabId[item.laboratory_id] = [item.sample_form_id + ',' + item.field_id]
                    }
                }
            }

            state.dataLists.postAndPutStatus = 'succeeded'
        },
        setDataListFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.dataLists.filtered = action.payload
        },
        setDataListFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.dataLists.pagination.filterBy = filterBy
        },
        setDataListSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.dataLists.pagination.sortBy = action.payload
        },
        refreshDataLists(state: State) {
            state.dataLists.fetchStatus = 'idle'
        },
        familyReducer(state: State, action: PayloadAction<{ laboratoryId?: number, family: Family }>) {
            const item = action.payload.family
            const translations: any = []
            Object.keys(item.translations).forEach((key: any) => {
                translations[item.translations[key].language_id] = { name_translation: item.translations[key].name_translation, comment_translation: item.translations[key].comment_translation, language_id: item.translations[key].language_id }
            })
            item.translations = translations
            if (item)
                state.families.data[item.id] = item
            if (state.families.filtered.indexOf(item.id.toString()) === -1) {
                state.families.filtered.push(item.id.toString())
            }
            if (item.active && action.payload.laboratoryId) {
                const labId = action.payload.laboratoryId
                if (!state.families.byLabId) {
                    state.families.byLabId = {}
                }
                if (!state.families.byLabId[labId]) {
                    state.families.byLabId[labId] = []
                }
                if (state.families.byLabId && state.families.byLabId[labId].indexOf(item.id.toString()) === -1) {
                    state.families.byLabId[labId].push(item.id.toString())
                }
            }
            state.families.postAndPutStatus = 'succeeded'
        },
        setFamilyFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.families.filtered = action.payload
        },
        setFamilyFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.families.pagination.filterBy = filterBy
        },
        setFamilySortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.families.pagination.sortBy = action.payload
        },
        refreshFamilies(state: State) {
            state.families.fetchStatus = 'idle'
        },
        laboratoryReducer(state: State, action: PayloadAction<Laboratory>) {
            const item = action.payload
            if (item.id)
                state.laboratories.data[item.id] = item
            state.laboratories.postAndPutStatus = 'succeeded'
        },
        setLaboratoryFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.laboratories.filtered = action.payload
        },
        setLaboratoryFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.laboratories.pagination.filterBy = filterBy
        },
        setLaboratorySortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.laboratories.pagination.sortBy = action.payload
        },
        refreshLaboratories(state: State) {
            state.laboratories.fetchStatus = 'idle'
        },
        deleteImproperReducer(state: State, action: PayloadAction<number>) {
            const item = action.payload
            if (item)
                delete state.impropers.data[item]
            state.impropers.filtered.splice(state.impropers.filtered.indexOf(item.toString()), 1)
            state.impropers.deleteStatus = 'succeeded'
        },
        improperReducer(state: State, action: PayloadAction<{ improper: Improper, laboratoryId?: number }>) {
            const item = action.payload.improper
            const translations: any = []
            Object.keys(item.translations).forEach((key: any) => {
                translations[item.translations[key].language_id] = { improper_translation: item.translations[key].improper_translation, language_id: item.translations[key].language_id }
            })
            item.translations = translations
            if (item)
                state.impropers.data[item.id] = item
            if (state.impropers.filtered.indexOf(item.id.toString()) !== -1) {
                state.impropers.filtered[state.impropers.filtered.indexOf(item.id.toString())] = item.id.toString()
            } else {
                state.impropers.filtered.push(item.id.toString())
            }
            if (item.active && action.payload.laboratoryId) {
                const labId = action.payload.laboratoryId
                if (!state.impropers.byLabId) {
                    state.impropers.byLabId = {}
                }
                if (!state.impropers.byLabId[labId]) {
                    state.impropers.byLabId[labId] = []
                }
                if (state.impropers.byLabId && state.impropers.byLabId[labId].indexOf(item.id.toString()) === -1) {
                    state.impropers.byLabId[labId].push(item.id.toString())
                }
            }
            state.impropers.postAndPutStatus = 'succeeded'
        },
        setImproperFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.impropers.filtered = action.payload
        },
        setImproperFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.impropers.pagination.filterBy = filterBy
        },
        setImproperSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.impropers.pagination.sortBy = action.payload
        },
        refreshImpropers(state: State) {
            state.impropers.fetchStatus = 'idle'
        },
        ingredientReducer(state: State, action: PayloadAction<Ingredient>) {
            const item = action.payload
            if (item && item.jcode)
                state.ingredients.data[item.jcode] = item
            state.ingredients.postAndPutStatus = 'succeeded'
        },
        setIngredientFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.ingredients.filtered = action.payload
        },
        setIngredientFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.ingredients.pagination.filterBy = filterBy
        },
        setIngredientSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.ingredients.pagination.sortBy = action.payload
        },
        deletePatternReducer(state: State, action: PayloadAction<number>) {
            const item = action.payload
            if (item)
                delete state.patterns.data[item]
            state.patterns.filtered.splice(state.patterns.filtered.indexOf(item.toString()), 1)
            state.patterns.filteredPcpw.splice(state.patterns.filteredPcpw.indexOf(item.toString()), 1)
            state.patterns.deleteStatus = 'succeeded'
            state.packages.fetchStatus = 'idle'
        },
        patternReducer(state: State, action: PayloadAction<{ pattern: Pattern, laboratoryId?: number }>) {
            const item = action.payload.pattern
            const translations: any = []
            Object.keys(item.translations).forEach((key: any) => {
                translations[item.translations[key].language_id] = { name_translation: item.translations[key].name_translation, option_translation: item.translations[key].option_translation, language_id: item.translations[key].language_id }
            })
            item.translations = translations
            if (item)
                state.patterns.data[item.id] = item
            if (state.patterns.filtered.indexOf(item.id.toString()) !== -1) {
                state.patterns.filtered[state.patterns.filtered.indexOf(item.id.toString())] = item.id.toString()
            } else {
                state.patterns.filtered.push(item.id.toString())
            }
            if (state.patterns.filteredPcpw.indexOf(item.id.toString()) !== -1) {
                state.patterns.filteredPcpw[state.patterns.filteredPcpw.indexOf(item.id.toString())] = item.id.toString()
            } else {
                state.patterns.filteredPcpw.push(item.id.toString())
            }
            if (item.active && action.payload.laboratoryId) {
                const labId = action.payload.laboratoryId
                if (!state.patterns.byLabId) {
                    state.patterns.byLabId = {}
                }
                if (!state.patterns.byLabId[labId]) {
                    state.patterns.byLabId[labId] = []
                }
                if (state.patterns.byLabId?.[labId].indexOf(item.id.toString()) === -1) {
                    state.patterns.byLabId?.[labId].push(item.id.toString())
                }
            }
            state.patterns.postAndPutStatus = 'succeeded'
        },
        patternPartialReducer(state: State, action: PayloadAction<any>) {
            const item = action.payload.ret
            const id = action.payload.id
            if (item)
                state.patterns.data[id] = { ...state.patterns.data[id], ...item }
            if (state.patterns.filtered.indexOf(id.toString()) !== -1) {
                state.patterns.filtered[state.patterns.filtered.indexOf(id.toString())] = id.toString()
            } else {
                state.patterns.filtered.push(id.toString())
            }
            if (state.patterns.filteredPcpw.indexOf(id.toString()) !== -1) {
                state.patterns.filteredPcpw[state.patterns.filteredPcpw.indexOf(id.toString())] = id.toString()
            } else {
                state.patterns.filteredPcpw.push(id.toString())
            }
            state.patterns.postAndPutStatus = 'succeeded'
        },
        setPatternFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.patterns.filtered = action.payload
        },
        setPatternFilteredPcpw(state: State, action: PayloadAction<Array<string>>) {
            state.patterns.filteredPcpw = action.payload
        },
        setPatternFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.patterns.pagination.filterBy = filterBy
        },
        setPatternSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.patterns.pagination.sortBy = action.payload
        },
        setPatternPackageTestFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.patterns.paginationPcpw.filterBy = filterBy
            state.packages.paginationPcpw.filterBy = filterBy
        },
        setPatternPackageTestSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.patterns.paginationPcpw.sortBy = action.payload
            state.packages.paginationPcpw.sortBy = action.payload
        },
        refreshPatterns(state: State) {
            state.patterns.fetchStatusByLabId = 'idle'
        },
        deletePackageReducer(state: State, action: PayloadAction<number>) {
            const item = action.payload
            if (item)
                delete state.packages.data[item]
            state.packages.filtered.splice(state.packages.filtered.indexOf(item.toString()), 1)
            state.packages.filteredPcpw.splice(state.packages.filteredPcpw.indexOf(item.toString()), 1)
            state.packages.deleteStatus = 'succeeded'
        },
        packageReducer(state: State, action: PayloadAction<{ laboratoryId?: number, package: Package }>) {
            const item = action.payload.package
            const translations: any = []
            Object.keys(item.translations).forEach((key: any) => {
                translations[item.translations[key].language_id] = { name_translation: item.translations[key].name_translation, language_id: item.translations[key].language_id }
            })
            item.translations = translations
            if (item)
                state.packages.postAndPutStatus = 'idle'
            state.packages.data[item.id] = item
            if (item.active) {
                if (state.packages.filtered.indexOf(item.id.toString()) !== -1) {
                    state.packages.filtered[state.packages.filtered.indexOf(item.id.toString())] = item.id.toString()
                } else {
                    state.packages.filtered.push(item.id.toString())
                }
                if (state.packages.filteredPcpw.indexOf(item.id.toString()) !== -1) {
                    state.packages.filteredPcpw[state.packages.filteredPcpw.indexOf(item.id.toString())] = item.id.toString()
                } else {
                    state.packages.filteredPcpw.push(item.id.toString())
                }
                if (item.active && action.payload.laboratoryId) {
                    const labId = action.payload.laboratoryId
                    if (!state.packages.byLabId) {
                        state.packages.byLabId = {}
                    }
                    if (!state.packages.byLabId[labId]) {
                        state.packages.byLabId[labId] = []
                    }
                    if (state.packages.byLabId?.[labId].indexOf(item.id.toString()) === -1) {
                        state.packages.byLabId?.[labId].push(item.id.toString())
                    }
                }
            }
            state.packages.postAndPutStatus = 'succeeded'
        },
        packagePartialReducer(state: State, action: PayloadAction<any>) {
            const item = action.payload.ret
            const id = action.payload.id
            if (item)
                state.packages.data[id] = { ...state.packages.data[id], ...item }
            if (state.packages.filtered.indexOf(id.toString()) !== -1) {
                state.packages.filtered[state.packages.filtered.indexOf(id.toString())] = id.toString()
            } else {
                state.packages.filtered.push(id.toString())
            }
            if (state.packages.filteredPcpw.indexOf(id.toString()) !== -1) {
                state.packages.filteredPcpw[state.packages.filteredPcpw.indexOf(id.toString())] = id.toString()
            } else {
                state.packages.filteredPcpw.push(id.toString())
            }
            state.packages.postAndPutStatus = 'succeeded'
        },
        setPackageFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.packages.filtered = action.payload
        },
        setPackageFilteredPcpw(state: State, action: PayloadAction<Array<string>>) {
            state.packages.filteredPcpw = action.payload
        },
        setPackageFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.packages.pagination.filterBy = filterBy
        },
        setPackageSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.packages.pagination.sortBy = action.payload
        },
        refreshPackages(state: State) {
            state.packages.fetchStatus = 'idle'
        },
        deleteProcessingReducer(state: State, action: PayloadAction<number>) {
            const item = action.payload
            if (item)
                delete state.processing.data[item]
            state.processing.filtered.splice(state.processing.filtered.indexOf(item.toString()), 1)
            state.processing.deleteStatus = 'succeeded'
        },
        processingReducer(state: State, action: PayloadAction<Transport>) {
            const item = action.payload
            const translations: any = []
            Object.keys(item.translations).forEach((key: any) => {
                translations[item.translations[key].language_id] = { name_translation: item.translations[key].name_translation, language_id: item.translations[key].language_id }
            })
            item.translations = translations
            if (item.id)
                state.processing.data[item.id] = item
            state.processing.postAndPutStatus = 'succeeded'
        },
        setProcessingFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.processing.filtered = action.payload
        },
        setProcessingFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.processing.pagination.filterBy = filterBy
        },
        setProcessingSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.processing.pagination.sortBy = action.payload
        },
        refreshProcessing(state: State) {
            state.processing.fetchStatus = 'idle'
        },
        deleteProductCategoryReducer(state: State, action: PayloadAction<number>) {
            const item = action.payload
            if (item)
                delete state.productCategories.data[item]
            state.productCategories.filtered.splice(state.productCategories.filtered.indexOf(item.toString()), 1)
            state.productCategories.deleteStatus = 'succeeded'
        },
        productCategoryReducer(state: State, action: PayloadAction<ProductCategory>) {
            const item = action.payload
            const translations: any = []
            Object.keys(item.translations).forEach((key: any) => {
                translations[item.translations[key].language_id] = { name_translation: item.translations[key].name_translation, language_id: item.translations[key].language_id }
            })
            item.translations = translations
            if (item.id)
                state.productCategories.data[item.id] = item
            state.productCategories.postAndPutStatus = 'succeeded'
        },
        setProductCategoryFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.productCategories.filtered = action.payload
        },
        setProductCategoryFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.productCategories.pagination.filterBy = filterBy
        },
        setProductCategorySortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.productCategories.pagination.sortBy = action.payload
        },
        refreshProductCategories(state: State) {
            state.productCategories.fetchStatus = 'idle'
        },
        deleteRefOriginReducer(state: State, action: PayloadAction<number>) {
            const item = action.payload
            if (item)
                delete state.refOrigins.data[item]
            state.refOrigins.filtered.splice(state.refOrigins.filtered.indexOf(item.toString()), 1)
            state.refOrigins.deleteStatus = 'succeeded'
        },
        refOriginReducer(state: State, action: PayloadAction<RefOrigin>) {
            const item = action.payload
            const translations: any = []
            Object.keys(item.translations).forEach((key: any) => {
                translations[item.translations[key].language_id] = { name_translation: item.translations[key].name_translation, language_id: item.translations[key].language_id, ref_origin_id: item.id }
            })
            item.translations = translations
            if (item.id)
                state.refOrigins.data[item.id] = item
            state.refOrigins.postAndPutStatus = 'succeeded'
        },
        setRefOriginFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.refOrigins.filtered = action.payload
        },
        setRefOriginFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.refOrigins.pagination.filterBy = filterBy
        },
        setRefOriginSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.refOrigins.pagination.sortBy = action.payload
        },
        originReducer(state: State, action: PayloadAction<{ laboratoryId?: number, origin: Origin }>) {
            const item = action.payload.origin
            const labId = item.laboratory_id

            const translations: OriginTranslation[] = []
            Object.keys(item.translations).forEach(key => {
                translations[item.translations[parseInt(key)].language_id] = { name_translation: item.translations[parseInt(key)].name_translation, language_id: item.translations[parseInt(key)].language_id }
            })
            item.translations = translations

            if (item)
                state.origins.data[item.id] = item
            if (state.origins.filtered.indexOf(item.id.toString()) !== -1) {
                state.origins.filtered[state.origins.filtered.indexOf(item.id.toString())] = item.id.toString()
            } else {
                state.origins.filtered.push(item.id.toString())
            }
            if (!state.origins.byLabId) {
                state.origins.byLabId = {}
            }
            if (!state.origins.byLabId[labId]) {
                state.origins.byLabId[labId] = []
            }
            if (item.active && state.origins.byLabId && state.origins.byLabId[labId].indexOf(item.id.toString()) === -1) {
                state.origins.byLabId[labId].push(item.id.toString())
            }

            state.origins.postAndPutStatus = 'succeeded'
        },
        setOriginFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.origins.filtered = action.payload
        },
        setOriginFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.origins.pagination.filterBy = filterBy
        },
        setOriginSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.origins.pagination.sortBy = action.payload
        },
        refreshOrigins(state: State) {
            state.origins.fetchStatus = 'idle'
        },
        deleteRefSupplierReducer(state: State, action: PayloadAction<number>) {
            const item = action.payload
            if (item)
                delete state.refSuppliers.data[item]
            state.refSuppliers.filtered.splice(state.refSuppliers.filtered.indexOf(item.toString()), 1)
            state.refSuppliers.deleteStatus = 'succeeded'
        },
        refSupplierReducer(state: State, action: PayloadAction<RefSupplier>) {
            const item = action.payload
            if (item.id)
                state.refSuppliers.data[item.id] = item
            state.refSuppliers.postAndPutStatus = 'succeeded'
        },
        setRefSupplierFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.refSuppliers.filtered = action.payload
        },
        setRefSupplierFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.refSuppliers.pagination.filterBy = filterBy
        },
        setRefSupplierSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.refSuppliers.pagination.sortBy = action.payload
        },
        refreshRefSuppliers(state: State) {
            state.refSuppliers.fetchStatus = 'idle'
        },
        supplierReducer(state: State, action: PayloadAction<{ laboratoryId?: number, supplier: Supplier }>) {
            const item = action.payload.supplier
            const labId = item.laboratory_id

            if (item)
                state.suppliers.data[item.id] = item
            if (state.suppliers.filtered.indexOf(item.id.toString()) !== -1) {
                state.suppliers.filtered[state.suppliers.filtered.indexOf(item.id.toString())] = item.id.toString()
            } else {
                state.suppliers.filtered.push(item.id.toString())
            }
            if (!state.suppliers.byLabId) {
                state.suppliers.byLabId = {}
            }
            if (!state.suppliers.byLabId[labId]) {
                state.suppliers.byLabId[labId] = []
            }
            if (item.active && state.suppliers.byLabId && state.suppliers.byLabId[labId].indexOf(item.id.toString()) === -1) {
                state.suppliers.byLabId[labId].push(item.id.toString())
            }

            state.suppliers.postAndPutStatus = 'succeeded'
        },
        setSupplierFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.suppliers.filtered = action.payload
        },
        setSupplierFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.suppliers.pagination.filterBy = filterBy
        },
        setSupplierSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.suppliers.pagination.sortBy = action.payload
        },
        refreshSuppliers(state: State) {
            state.suppliers.fetchStatus = 'idle'
        },
        deleteSpecieReducer(state: State, action: PayloadAction<number>) {
            const item = action.payload
            if (item)
                delete state.species.data[item]
            state.species.filtered.splice(state.species.filtered.indexOf(item.toString()), 1)
            state.species.deleteStatus = 'succeeded'
        },
        specieReducer(state: State, action: PayloadAction<Specie>) {
            const item = action.payload
            const translations: any = []
            Object.keys(item.translations).forEach((key: any) => {
                translations[item.translations[key].language_id] = { name_translation: item.translations[key].name_translation, language_id: item.translations[key].language_id }
            })
            item.translations = translations
            if (item.id)
                state.species.data[item.id] = item
            state.species.postAndPutStatus = 'succeeded'
        },
        setSpecieFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.species.filtered = action.payload
        },
        setSpecieFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.species.pagination.filterBy = filterBy
        },
        setSpecieSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.species.pagination.sortBy = action.payload
        },
        refreshSpecies(state: State) {
            state.species.fetchStatus = 'idle'
        },
       

        deleteTransportReducer(state: State, action: PayloadAction<number>) {
            const item = action.payload
            if (item)
                delete state.transports.data[item]
            state.transports.filtered.splice(state.transports.filtered.indexOf(item.toString()), 1)
            state.transports.deleteStatus = 'succeeded'
        },
        transportReducer(state: State, action: PayloadAction<Transport>) {
            const item = action.payload
            const translations: TransportTranslation[] = []
            Object.keys(item.translations).forEach(key => {
                translations[item.translations[parseInt(key)].language_id] = { name_translation: item.translations[parseInt(key)].name_translation, language_id: item.translations[parseInt(key)].language_id }
            })
            item.translations = translations
            if (item.id)
                state.transports.data[item.id] = item
            state.transports.postAndPutStatus = 'succeeded'
        },
        setTransportFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.transports.filtered = action.payload
        },
        setTransportFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.transports.pagination.filterBy = filterBy
        },
        setTransportSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.transports.pagination.sortBy = action.payload
        },
        refreshTransports(state: State) {
            state.transports.fetchStatus = 'idle'
        },
        typeReducer(state: State, action: PayloadAction<{ type: Type }>) {
            const item = action.payload.type
            const labId = item.laboratory_id
            const translations: any = []
            Object.keys(item.translations).forEach((key: any) => {
                translations[item.translations[key].language_id] = { name_translation: item.translations[key].name_translation, language_id: item.translations[key].language_id }
            })
            item.translations = translations
            if (item)
                state.types.data[item.id] = item
            if (state.types.filtered.indexOf(item.id.toString()) !== -1) {
            } else {
                state.types.filtered.push(item.id.toString())
            }
            if (!state.types.byLabId) {
                state.types.byLabId = {}
            }
            if (!state.types.byLabId[labId]) {
                state.types.byLabId[labId] = []
            }
            if (item.active && state.types.byLabId && state.types.byLabId[labId].indexOf(item.id.toString()) === -1) {
                state.types.byLabId[labId].push(item.id.toString())
            }
            state.types.postAndPutStatus = 'succeeded'
        },
        setTypeFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.types.filtered = action.payload
        },
        setTypeFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.types.pagination.filterBy = filterBy
        },
        setTypeSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.types.pagination.sortBy = action.payload
        },
        refreshTypes(state: State) {
            state.types.fetchStatus = 'idle'
        },
    },
    extraReducers: builder => {
        // Languages API call reducers
        builder.addCase(fetchLanguages.pending, (state) => {
            state.languages.fetchStatus = 'loading'
        })
        builder.addCase(fetchLanguages.fulfilled, (state, action) => {
            state.languages.fetchStatus = 'succeeded'
            action.payload.forEach((item: Language) => {
                state.languages.data[item.id] = item
                state.languages.dataByCode[item.code_language] = item

            })


        })
        builder.addCase(fetchLanguages.rejected, (state, action) => {
            state.languages.fetchStatus = 'failed'
            state.languages.fetchError = action.error.message
        })

        // Data List API call reducers
        builder.addCase(fetchDataLists.pending, (state) => {
            state.dataLists.fetchStatus = 'loading'
        })
        builder.addCase(fetchDataLists.fulfilled, (state, action) => {
            state.dataLists.byLabId = {}
            action.payload.forEach((item: DataList) => {
                if (state.dataLists.byLabId) {
                    if (!state.dataLists.byLabId[item.laboratory_id]) {
                        state.dataLists.byLabId[item.laboratory_id] = []
                    }
                    state.dataLists.byLabId[item.laboratory_id].push(item.sample_form_id.toString() + ',' + item.field_id.toString())
                    state.dataLists.data[item.sample_form_id + ',' + item.field_id] = item
                }
            })
            state.dataLists.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchDataLists.rejected, (state, action) => {
            state.dataLists.fetchStatus = 'failed'
            state.dataLists.fetchError = action.error.message
        })

        // Laboratories API call reducers
        builder.addCase(fetchLaboratories.pending, (state) => {
            state.laboratories.fetchStatus = 'loading'
        })
        builder.addCase(fetchLaboratories.fulfilled, (state, action) => {
            state.laboratories.fetchStatus = 'succeeded'
            action.payload.forEach((item: Laboratory) => {
                state.laboratories.data[item.id] = Object.assign({}, state.laboratories.data[item.id], item)
            })
        })
        builder.addCase(fetchLaboratories.rejected, (state, action) => {
            state.laboratories.fetchStatus = 'failed'
            state.laboratories.fetchError = action.error.message
        })
        builder.addCase(fetchSingleLaboratory.pending, (state) => {
            state.laboratories.fetchSingleStatus = 'loading'
        })
        builder.addCase(fetchSingleLaboratory.fulfilled, (state, action) => {
            state.laboratories.fetchSingleStatus = 'succeeded'
            const item = action.payload
            if (item)
                state.laboratories.data[item.id] = item
        })
        builder.addCase(fetchSingleLaboratory.rejected, (state, action) => {
            state.laboratories.fetchSingleStatus = 'failed'
            state.laboratories.fetchSingleError = action.error.message
        })

        // Types API call reducers
        builder.addCase(fetchTypes.pending, (state) => {
            state.types.fetchStatus = 'loading'
        })
        builder.addCase(fetchTypes.fulfilled, (state, action) => {
            state.types.byLabId = {}
            action.payload.forEach((item: Type) => {
                if (state.types.byLabId) {
                    if (item.active && !state.types.byLabId[item.laboratory_id]) {
                        state.types.byLabId[item.laboratory_id] = []
                    }
                    if (item.active)
                        state.types.byLabId[item.laboratory_id].push(item.id?.toString())
                    state.types.data[item.id] = item
                }
            })
            state.types.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchTypes.rejected, (state, action) => {
            state.types.fetchStatus = 'failed'
            state.types.fetchError = action.error.message
        })
        // Families API call reducers

        builder.addCase(fetchFamilies.pending, (state, action) => {
            if (action.meta.arg) {
                state.families.fetchStatusByLabId = 'loading'
            } else {
                state.families.fetchStatus = 'loading'
            }

        })
        builder.addCase(fetchFamilies.fulfilled, (state, action) => {
            if (!state.families.byLabId) {
                state.families.byLabId = {}
            }

            if (state.families.byLabId && action.meta.arg) {
                state.families.byLabId [action.meta.arg] = []
            }

            action.payload.forEach((item: Family) => {
                if (state.families.byLabId) {
                    if (!state.families.byLabId[item.laboratory_id]) {
                        state.families.byLabId[item.laboratory_id] = []

                    }
                    if (item.active && !state.families.byLabId[item.laboratory_id].includes(String(item.id))) {
                        state.families.byLabId[item.laboratory_id].push(String(item.id))
                    }
                    state.families.data[item.id] = item
                }
            })

            state.families.fetchStatusByLabId = 'succeeded'
            state.families.fetchStatus = 'succeeded'

        })
        builder.addCase(fetchFamilies.rejected, (state, action) => {
            if (action.meta.arg) {
                state.families.fetchStatusByLabId = 'failed'
            } else {
                state.families.fetchStatus = 'failed'
            }
            state.families.fetchError = action.error.message
        })



        // Impropers API call reducers
        builder.addCase(fetchImpropers.pending, (state) => {
            state.impropers.fetchStatus = 'loading'
        })
        builder.addCase(fetchImpropers.fulfilled, (state, action) => {
            if (!state.impropers.byLabId) {
                state.impropers.byLabId = {}
            }
            if (state.impropers.byLabId && action.meta.arg) {
                state.impropers.byLabId[action.meta.arg] = []
            }
            action.payload.forEach((item: Improper) => {
                if (state.impropers.byLabId) {
                    if (item.active && action.meta.arg)
                        state.impropers.byLabId[action.meta.arg].push(item.id?.toString())
                    state.impropers.data[item.id] = item
                }
            })
            state.families.fetchStatus = 'succeeded'

            action.payload.forEach((item: Improper) => {
                state.impropers.data[item.id] = item
            })
            state.impropers.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchImpropers.rejected, (state, action) => {
            state.impropers.fetchStatus = 'failed'
            state.impropers.fetchError = action.error.message
        })

        // Ingredients API call reducers
        builder.addCase(fetchIngredients.pending, (state) => {
            state.ingredients.fetchStatus = 'loading'
        })
        builder.addCase(fetchIngredients.fulfilled, (state, action) => {
            action.payload.forEach((item: Ingredient) => {
                if (item.jcode)
                    state.ingredients.data[item.jcode] = item
            })
            state.ingredients.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchIngredients.rejected, (state, action) => {
            state.ingredients.fetchStatus = 'failed'
            state.ingredients.fetchError = action.error.message
        })

        // Patterns API call reducers
        builder.addCase(fetchPatterns.pending, (state, action) => {
            if (action.meta.arg) {
                state.patterns.fetchStatusByLabId = 'loading'
            } else {
                state.patterns.fetchStatus = 'loading'
            }
        })
        builder.addCase(fetchPatterns.fulfilled, (state, action) => {
            if (!state.patterns.byLabId) {
                state.patterns.byLabId = {}
            }
            if (state.patterns.byLabId && action.meta.arg) {
                state.patterns.byLabId[action.meta.arg] = []
            }
            action.payload.forEach((item: Pattern) => {
                if (item.active && action.meta.arg)
                    state.patterns.byLabId?.[action.meta.arg].push(item.id?.toString())
                state.patterns.data[item.id] = item
            })
            if (action.meta.arg) {
                state.patterns.fetchStatusByLabId = 'succeeded'

            } else {
                state.patterns.fetchStatus = 'succeeded'
            }
        })
        builder.addCase(fetchPatterns.rejected, (state, action) => {
            if (action.meta.arg) {
                state.patterns.fetchStatusByLabId = 'failed'
            } else {
                state.patterns.fetchStatus = 'failed'
            }
            state.patterns.fetchError = action.error.message
        })

        // Packages API call reducers
        builder.addCase(fetchPackages.pending, (state) => {
            state.packages.fetchStatus = 'loading'
        })
        builder.addCase(fetchPackages.fulfilled, (state, action) => {
            if (!state.packages.byLabId) {
                state.packages.byLabId = {}
            }
            if (state.packages.byLabId && action.meta.arg) {
                state.packages.byLabId[action.meta.arg] = []
            }
            action.payload.forEach((item: Package) => {
                if (item.active && action.meta.arg)
                    state.packages.byLabId?.[action.meta.arg].push(item.id?.toString())
                state.packages.data[item.id] = item
            })
            state.packages.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchPackages.rejected, (state, action) => {
            state.packages.fetchStatus = 'failed'
            state.packages.fetchError = action.error.message
        })

        // Ref Processing API call reducers
        builder.addCase(fetchProcessing.pending, (state) => {
            state.processing.fetchStatus = 'loading'
        })
        builder.addCase(fetchProcessing.fulfilled, (state, action) => {
            action.payload.forEach((item: Processing) => {
                if (item.active)
                    state.processing.data[item.id] = item
            })
            state.processing.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchProcessing.rejected, (state, action) => {
            state.processing.fetchStatus = 'failed'
            state.processing.fetchError = action.error.message
        })

        //Species API call reducers
        builder.addCase(fetchProductCategories.pending, (state) => {
            state.productCategories.fetchStatus = 'loading'
        })
        builder.addCase(fetchProductCategories.fulfilled, (state, action) => {
            action.payload.forEach((item: ProductCategory) => {
                if (item.active)
                    state.productCategories.data[item.id] = item
            })
            state.productCategories.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchProductCategories.rejected, (state, action) => {
            state.productCategories.fetchStatus = 'failed'
            state.productCategories.fetchError = action.error.message
        })

        // Ref Origins API call reducers
        builder.addCase(fetchRefOrigins.pending, (state) => {
            state.refOrigins.fetchStatus = 'loading'
        })
        builder.addCase(fetchRefOrigins.fulfilled, (state, action) => {
            action.payload.forEach((item: RefOrigin) => {
                if (item.active)
                    state.refOrigins.data[item.id] = item
            })
            state.refOrigins.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchRefOrigins.rejected, (state, action) => {
            state.refOrigins.fetchStatus = 'failed'
            state.refOrigins.fetchError = action.error.message
        })

        // Origins API call reducers
        builder.addCase(fetchOrigins.pending, (state) => {
            state.origins.fetchStatus = 'loading'
        })
        builder.addCase(fetchOrigins.fulfilled, (state, action) => {
            action.payload.forEach((item: Origin) => {
                if (state.origins.byLabId) {
                    if (item.active && !state.origins.byLabId[item.laboratory_id]) {
                        state.origins.byLabId[item.laboratory_id] = []
                    }
                    if (item.active)
                        state.origins.byLabId[item.laboratory_id].push(item.id?.toString())
                    state.origins.data[item.id] = item
                }
            })
            state.origins.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchOrigins.rejected, (state, action) => {
            state.origins.fetchStatus = 'failed'
            state.origins.fetchError = action.error.message
        })

        // Ref Suppliers API call reducers
        builder.addCase(fetchRefSuppliers.pending, (state) => {
            state.refSuppliers.fetchStatus = 'loading'
        })
        builder.addCase(fetchRefSuppliers.fulfilled, (state, action) => {
            action.payload.forEach((item: RefSupplier) => {
                if (item.active)
                    state.refSuppliers.data[item.id] = item
            })
            state.refSuppliers.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchRefSuppliers.rejected, (state, action) => {
            state.refSuppliers.fetchStatus = 'failed'
            state.refSuppliers.fetchError = action.error.message
        })

        // Suppliers API call reducers
        builder.addCase(fetchSuppliers.pending, (state) => {
            state.suppliers.fetchStatus = 'loading'
        })
        builder.addCase(fetchSuppliers.fulfilled, (state, action) => {
            action.payload.forEach((item: Supplier) => {
                if (state.suppliers.byLabId) {
                    if (item.active && !state.suppliers.byLabId[item.laboratory_id]) {
                        state.suppliers.byLabId[item.laboratory_id] = []
                    }
                    if (item.active)
                        state.suppliers.byLabId[item.laboratory_id].push(item.id?.toString())
                    state.suppliers.data[item.id] = item
                }
            })
            state.suppliers.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchSuppliers.rejected, (state, action) => {
            state.suppliers.fetchStatus = 'failed'
            state.suppliers.fetchError = action.error.message
        })

        //Species API call reducers
        builder.addCase(fetchSpecies.pending, (state) => {
            state.species.fetchStatus = 'loading'
        })
        builder.addCase(fetchSpecies.fulfilled, (state, action) => {
            action.payload.forEach((item: Specie) => {
                if (item.active)
                    state.species.data[item.id] = item
            })
            state.species.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchSpecies.rejected, (state, action) => {
            state.species.fetchStatus = 'failed'
            state.species.fetchError = action.error.message
        })

        // Transports API call reducers
        builder.addCase(fetchTransports.pending, (state) => {
            state.transports.fetchStatus = 'loading'
        })
        builder.addCase(fetchTransports.fulfilled, (state, action) => {
            action.payload.forEach((item: Transport) => {
                if (item.active)
                    state.transports.data[item.id] = item
            })
            state.transports.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchTransports.rejected, (state, action) => {
            state.transports.fetchStatus = 'failed'
            state.transports.fetchError = action.error.message
        })

        // Status API call reducers
        builder.addCase(fetchStatusList.pending, (state) => {
            state.status.fetchStatus = 'loading'
        })
        builder.addCase(fetchStatusList.fulfilled, (state, action) => {
            action.payload.forEach((item: Status) => {
                state.status.data[item.type][item.key] = item
            })
            state.status.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchStatusList.rejected, (state, action) => {
            state.status.fetchStatus = 'failed'
            state.status.fetchError = action.error.message
        })
    }
})

export const {
    deleteDataListReducer,
    deleteImproperReducer,
    deletePatternReducer,
    deletePackageReducer,
    deleteProductCategoryReducer,
    deleteProcessingReducer,
    deleteRefOriginReducer,
    deleteRefSupplierReducer,
    deleteSpecieReducer,
    deleteTransportReducer,
    dataListReducer,
    familyReducer,
    improperReducer,
    ingredientReducer,
    laboratoryReducer,
    patternReducer,
    patternPartialReducer,
    packageReducer,
    packagePartialReducer,
    processingReducer,
    productCategoryReducer,
    refOriginReducer,
    originReducer,
    refSupplierReducer,
    supplierReducer,
    specieReducer,
    transportReducer,
    typeReducer,
    setLaboratoryFiltered,
    setLaboratoryFilterBy,
    setLaboratorySortBy,
    setDataListFiltered,
    setDataListFilterBy,
    setDataListSortBy,
    setFamilyFiltered,
    setFamilyFilterBy,
    setFamilySortBy,
    setImproperFiltered,
    setImproperFilterBy,
    setImproperSortBy,
    setIngredientFiltered,
    setIngredientFilterBy,
    setIngredientSortBy,
    setPatternFiltered,
    setPatternFilteredPcpw,
    setPatternFilterBy,
    setPatternSortBy,
    setPatternPackageTestFilterBy,
    setPatternPackageTestSortBy,
    setPackageFiltered,
    setPackageFilteredPcpw,
    setPackageFilterBy,
    setPackageSortBy,
    setProcessingFiltered,
    setProcessingFilterBy,
    setProcessingSortBy,
    setProductCategoryFiltered,
    setProductCategoryFilterBy,
    setProductCategorySortBy,
    setRefOriginFiltered,
    setRefOriginFilterBy,
    setRefOriginSortBy,
    setOriginFiltered,
    setOriginFilterBy,
    setOriginSortBy,
    setRefSupplierFiltered,
    setRefSupplierFilterBy,
    setRefSupplierSortBy,
    setSupplierFiltered,
    setSupplierFilterBy,
    setSupplierSortBy,
    setSpecieFiltered,
    setSpecieFilterBy,
    setSpecieSortBy,
    setTransportFiltered,
    setTransportFilterBy,
    setTransportSortBy,
    setTypeFiltered,
    setTypeFilterBy,
    setTypeSortBy,
    refreshDataLists,
    refreshLaboratories,
    refreshFamilies,
    refreshImpropers,
    refreshPatterns,
    refreshPackages,
    refreshOrigins,
    refreshSuppliers,
    refreshTypes
} = configSlice.actions

export default configSlice.reducer