import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ConfigApi, {RefAnalyte } from '../api/configApi';
import { buildDefaultExtraReducersWithFrontPagination, CurrentState, getDefaultReducersWithFrontPagination, getInitialState } from './DefaultSlice';

const initialState = getInitialState<RefAnalyte>()



// ref analytes
export const fetchRefAnalytes = createAsyncThunk(
    'config/fetchRefAnalytes', 
    async () => {
       
        const response =  await ConfigApi.getInstance().getRefAnalytes() 
        return response as Array<RefAnalyte>
    }
)

export const fetchExportRefAnalytes = createAsyncThunk(
    'config/fetchExportRefAnalyte', 
    async () => {
        const response =  await ConfigApi.getInstance().exportRefAnalyte()
        return response 
    }
)


const addItemToRefAnalyte =   (state:CurrentState<RefAnalyte>,item:RefAnalyte) => {

    if (item.id) 
    state.data[item.id] = item
}

const postDeleteRefAnalyte = (state:CurrentState<RefAnalyte>,item:RefAnalyte)=> {
    delete state.data[item.id]
                state.filtered.splice(state.filtered.indexOf(item.id),1)
}


const refAnalyteSlice = createSlice({
    name: 'refAnalytes',
    initialState,
     reducers: getDefaultReducersWithFrontPagination(addItemToRefAnalyte,postDeleteRefAnalyte)
    ,
    extraReducers: builder => {
            buildDefaultExtraReducersWithFrontPagination(
              addItemToRefAnalyte,
                builder,
                fetchRefAnalytes
            )


          
        }
})

export const {
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    setNbByPage,
    requestPage
} = refAnalyteSlice.actions

export default refAnalyteSlice.reducer