import React, { useState, useContext, useRef, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as _ from 'lodash';

import { RootState } from '../../../../state/rootReducer';
import { Row, Col, Button, Container, Form, Modal, Accordion, Card, AccordionContext, useAccordionButton } from 'react-bootstrap';
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as CaretDownFill } from 'bootstrap-icons/icons/caret-down-fill.svg'
import { ReactComponent as CaretUpFill } from 'bootstrap-icons/icons/caret-up-fill.svg'
import { ReactComponent as PdfIcon } from "bootstrap-icons/icons/file-earmark-pdf.svg";
import GoBackButton from '../../../../common/components/GoBackButton';
import StatusMatrixBar from '../../../../common/components/StatusMatrixBar';
import Loader from '../../../../common/components/Loader';
import userHasRequiredRoles from '../../../../common/auth/userHasRequiredRoles';
import { buildSelectNormalStyle } from '../../../../common/utils/utils';
import InlineLoader from "../../../../common/components/InlineLoader";

import { useSelector, useDispatch } from 'react-redux';
import { downloadMemo, fetchMatrixTemplates, fetchMatrixTemplatesByPassLimit } from '../../../../state/matrixTemplateSlice';
import { fetchMatrixTemplateInputsByTemplateId } from '../../../../state/matrixTemplateInputSlice';

import MatrixApi, { Matrix, MatrixTemplate, MatrixTemplateInput, BodyMatrixCreate, BackupMode, MatrixTemplateInputDb, MatrixTemplateInputsFormated, ResultSource, InputFieldItem, InputFieldsTable } from '../../../../api/matrixApi';

import { Laboratory, RefOrigin, RefSupplier } from '../../../../api/configApi';
import { Company } from '../../../../api/usersApi';

import { Formik, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup';
import { fetchSingleMatrice, fetchSingleMatriceSilent, updateMatrixResults } from '../../../../state/matrixSlice';

interface CreateMatrixContainerProps extends WithTranslation {
    mode: BackupMode,
    matrixDb?: Matrix,
}

const CreateMatrix: React.FC<CreateMatrixContainerProps> = ({ t, mode, matrixDb }) => {

    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    const matrixTemplateInputs = useSelector((state: RootState) => state.matrixTemplateInputs.data)
    const inputsByTemplateId = useSelector((state: RootState) => state.matrixTemplateInputs.byTemplateId)
    const { user: authUser, authCurLabId } = useSelector((state: RootState) => state.authUser)
    const companies = useSelector((state: RootState) => state.companies.data)
    const matrixTemplates = useSelector((state: RootState) => state.matrixTemplates.data)
    const refOrigins = useSelector((state: RootState) => state.config.refOrigins.data)
    const refSuppliers = useSelector((state: RootState) => state.config.refSuppliers.data)
    const ingredients = useSelector((state: RootState) => state.config.ingredients.data)
    const matrixTemplatesStatus = useSelector((state: RootState) => state.matrixTemplates.fetchStatusByPass)
    const matrixTemplatesInputsStatus = useSelector((state: RootState) => state.matrixTemplateInputs.fetchStatus)
    const dashboardSelectedValues = useSelector((state: RootState) => state.dashboard.selectedValues)
    const matrixFields = useSelector((state: RootState) => state.dashboard.matrixFields)

    const [processing, setProcessing] = useState(false);
    const [showExportcodeModal, setShowExportcodeModal] = useState(false);
    const [showAllInputsOkModal, setShowAllInputsOkModal] = useState(false);
    const [showInputsNotOkModal, setShowInputsNotOkModal] = useState(false);
    const [matrixLastVersionStatus, setMatrixLastVersionStatus] = useState('');
    const [matrixLastVersionDatas, setMatrixLastVersionDatas] = useState<Matrix | undefined>(undefined)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [apiError, setApiError] = useState(undefined)
    const [laboratoryId, setLaboratoryId] = useState(0)
    const [templateId, setTemplateId] = useState(matrixDb ? matrixDb.template_id.toString() : '')
    const [apiBadResults, setApiBadResults] = useState([])
    const [loadingExportCode, setLoadingExportCode] = useState(false)
    const [triggerInputs, setTriggerInputs] = useState(false)

    const dispatch = useDispatch()
    const history = useHistory()

    const exportCodeinputRef = useRef<HTMLInputElement>(null) // to come back in export_code
    const categoryInputRef = useRef<HTMLInputElement>(null) // to come back in export_code

    const laboratoriesFiltered = useRef(Object.values(laboratories).filter(l => l.lab_type === "cargill" && (authUser.companies.filter(c => companies[c.company_id] && companies[c.company_id].formulation_id === l.id).length > 0 || authUser.customers.filter(c => companies[c.company_id] && companies[c.company_id].formulation_id === l.id).length > 0 || authUser.formulations.find(f => f.laboratory_id === l.id) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']))));

    useEffect(() => {
        if (laboratoriesFiltered.current.length === 1 && mode === 'creation') {
            dispatch(fetchMatrixTemplatesByPassLimit({ laboratoryId: laboratoriesFiltered.current[0].id, page: 1 }))
            setLaboratoryId(laboratoriesFiltered.current[0].id)
        }
    }, [laboratoriesFiltered.current])

    useEffect(() => {
        if (['edition', 'update'].includes(mode) && matrixDb?.template_id && companies[matrixDb?.company_id].formulation_id) {
            dispatch(fetchMatrixTemplatesByPassLimit({ laboratoryId: companies[matrixDb.company_id].formulation_id!, page: 1 }))
        }
    }, [])

    useEffect(() => {
        if (templateId != '' && laboratoryId != 0) {
            dispatch(fetchMatrixTemplateInputsByTemplateId({ laboratoryId, templateId }))
        }
    }, [laboratoryId, templateId])

    const handleFocusOnExportCode = async () => {
        setMatrixLastVersionStatus('')
        setShowExportcodeModal(false)
    }

    const transformData = (data: MatrixTemplateInputDb): MatrixTemplateInputsFormated =>
        Object.fromEntries(Object.entries(data).map(([key, analytes]) => [
            key,
            analytes.map(({ analyte_name, is_toggle, default_value, unit, analyte_id, active }) => ({
                name: analyte_name.name,
                id: analyte_id,
                is_toggle,
                default_value,
                unit,
                //Pour les inputs toggle, si la source du résultat de l’ancienne matrice est « Input », on pré-rempli l’input pour la nouvelle matrice, sinon la source est « Autocalc » et on laisse l’input vide.
                input:
                    dashboardSelectedValues.selected_values.length > 0 && dashboardSelectedValues.selected_values.find((e) => e.analyte_id.toUpperCase() === analyte_id.toUpperCase()) ? dashboardSelectedValues.selected_values.find((e) => e.analyte_id.toUpperCase() === analyte_id.toUpperCase())?.value.toString() : //values from dashboard case

                        (matrixDb ? //Edit case
                            matrixDb &&
                                matrixDb.results &&
                                analyte_id !== undefined &&
                                matrixDb.results.find(i => i && i.analyte_id === analyte_id)?.source === 'Input' &&
                                matrixDb.results.find(i => i && i.analyte_id === analyte_id && i.source === 'Input')?.ai_result !== null ?
                                matrixDb?.results?.find(i => i && i.analyte_id === analyte_id && i.source === 'Input')?.ai_result
                                : ''
                            //Create case
                            : is_toggle === true ?
                                '' : default_value === null ? "" : String(default_value)),
                active
            })),
        ])
        );

    interface IProps {
        eventKey: string
        name: string
    }

    function ContextAwareToggle({ eventKey, name }: IProps) {
        const { activeEventKey } = useContext(AccordionContext);
        const decoratedOnClick = useAccordionButton(
            eventKey,
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <Row className={`ms-0 me-0 align-items-center rounded ${isCurrentEventKey ? "bgColorAccordionMatrixOpen ps-0" : "bgColorAccordionMatrixClosed text-white ps-0"}`} >
                <Col className='mt-0 mb-0'>
                    <Card.Header onClick={decoratedOnClick} className={`border-0 ${isCurrentEventKey ? "bgColorAccordionMatrixOpen ps-0" : "bgColorAccordionMatrixClosed ps-0"}`} >
                        <Row className="p-0">
                            <Col xs="auto" className="ps-0">
                                <span className="ps-2 fw-bold " > {name} </span>
                            </Col>
                            <Col xs="auto" className="text-end ms-auto">
                                <>
                                    {isCurrentEventKey ?
                                        <CaretUpFill width="16px" height="100%" />
                                        :
                                        <CaretDownFill width="16px" height="100%" />
                                    }
                                </>
                            </Col>
                        </Row>
                    </Card.Header>
                </Col>
            </Row>
        );
    }

    const validationSchema = Yup.object().shape({
        company_id: Yup.number()
            .moreThan(0, t('matrix.matrix.form_errors.company_required'))
            .required(t('matrix.matrix.form_errors.company_required')),
        laboratory_id: Yup.number()
            .moreThan(0, t('matrix.matrix.form_errors.laboratory_required'))
            .required(t('matrix.matrix.form_errors.laboratory_required')),
        export_code: Yup.string()
            .max(255, t('forms_errors.max_length'))
            .required(t('matrix.matrix.form_errors.export_code_required')),
        comment: Yup.string()
            .nullable()
            .max(255, t('forms_errors.max_length')),
        matrix_template_id: Yup.number()
            .nullable()
            .required(t('matrix.matrix.msg_matrix_template_not_defined')),
        category: Yup.string()
            .required(t('matrix.matrix.form_errors.category_required')),
        sub_category: Yup.string().nullable().test("required",
            (v, context) => {
                if (Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(context.parent.company_id))) && item.category.name === context.parent.category && (item.sub_category.name === v || item.sub_category.name === '')).length > 0) {
                    return true
                } else {
                    return context.createError({ path: context.path, message: t('matrix.matrix.form_errors.sub_category_required') })
                }
            }
        ),
        raw_material: Yup.string().nullable().test("required",
            (v, context) => {
                if (Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(context.parent.company_id))) && item.category.name === context.parent.category && (item.sub_category.name === context.parent.sub_category || item.sub_category.name === '') && (item.raw_material.name === v || item.raw_material.name === '')).length > 0) {
                    return true
                } else {
                    return context.createError({ path: context.path, message: t('matrix.matrix.form_errors.raw_material_required') })
                }
            }
        )
    })

    return (
        <Container className={'mt-5 p-0'}>
            {processing && <Loader />}

            {formSubmitted &&
                <Container className="mt-3 mb-3">
                    test
                </Container>
            }

            {!formSubmitted &&
                <Formik
                    initialValues={{
                        //Bloc Entreprise
                        laboratory_id:
                            matrixDb && matrixTemplates[matrixDb.template_id]
                                ? matrixTemplates[matrixDb.template_id].laboratory_id //Edit mode case
                                : laboratoriesFiltered.current.length == 1 //If user has only one laboratory, set laboratory_id (creation mode)
                                    ? laboratoriesFiltered.current[0].id
                                    : 0,

                        company_id: matrixDb ? matrixDb.company_id : Object.values(companies).filter(c => c.formulation_id === (laboratoriesFiltered.current.length == 1 ? laboratoriesFiltered.current[0].id : 0)).length === 1 ? Object.values(companies).filter(c => c.formulation_id === (laboratoriesFiltered.current.length == 1 ? laboratoriesFiltered.current[0].id : 0))[0].id! : 0,
                        export_code: matrixDb ? matrixDb.export_code : '',
                        version: matrixDb ?
                            mode === "update" ? matrixDb.version + 1 : matrixDb.version
                            : 1,
                        matrix_status: matrixDb ? matrixDb.matrix_status : '',
                        //Bloc Matrice
                        matrix_template_id: matrixDb ? matrixDb.template_id : null,
                        category: matrixDb && matrixTemplates[matrixDb.template_id]?.active ? matrixTemplates[matrixDb.template_id].category.name : '',
                        sub_category: matrixDb && matrixTemplates[matrixDb.template_id]?.active && matrixTemplates[matrixDb.template_id].sub_category ? matrixTemplates[matrixDb.template_id].sub_category.name : '',
                        raw_material: matrixDb && matrixTemplates[matrixDb.template_id]?.active && matrixTemplates[matrixDb.template_id].raw_material ? matrixTemplates[matrixDb.template_id].raw_material.name : '',
                        jcode: matrixDb && matrixTemplates[matrixDb.template_id]?.active ? matrixTemplates[matrixDb.template_id].jcode : '',
                        ref_origins: matrixDb ? matrixDb.origin_id || 0 : 0,
                        ref_suppliers: matrixDb ? matrixDb.supplier_id || 0 : 0,
                        customer_name: matrixDb ? matrixDb.customer_name || '' : '',
                        comment: matrixDb ? matrixDb.comment || '' : '',

                        //Bloc Inputs
                        inputFields: {},

                        //Force mode for API calls (bypass controls)
                        force: false
                    }}

                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        // When button submits form and form is in the process of submitting, submit button is disabled
                        setSubmitting(true)
                        setApiError(undefined)

                        const inputFields = values.inputFields;
                        const transformedData = Object.keys(inputFields).flatMap((groupKey) =>
                            (inputFields as InputFieldsTable)[groupKey].map((item: InputFieldItem) => ({
                                analyte_id: item.id.toString(),
                                ai_result: item.input || '',
                                unit: item.unit,
                                source: ResultSource.input,
                                group_name: groupKey
                            }))
                        );

                        const matrix: BodyMatrixCreate = {
                            company_id: values.company_id,
                            export_code: values.export_code,
                            version: values.version,
                            matrix_status: values.matrix_status,
                            template_id: values.matrix_template_id ? values.matrix_template_id : 0,
                            jcode: values.matrix_template_id && matrixTemplates[values.matrix_template_id]?.jcode ? matrixTemplates[values.matrix_template_id].jcode : '',
                            origin_id: values.ref_origins,
                            supplier_id: values.ref_suppliers,
                            customer_name: values.customer_name,
                            comment: values.comment,
                            creation_mode: 'form',
                            results: transformedData
                        }

                        MatrixApi.getInstance().backupMatrix(mode, values.force, matrix)
                            .then((response) => {
                                dispatch(fetchSingleMatriceSilent({ companyId: matrix.company_id.toString(), exportCode: matrix.export_code, version: matrix.version.toString() }))
                                setShowAllInputsOkModal(true);
                            })
                            .catch((err) => {
                                if (err.response && err.response.data) {
                                    //In case of 400 error : invalid inputs (out of range) or missing inputs or input > max group                               
                                    // Filter the results with either analyte_status or group_status as 'NOK'
                                    const problematicResults = err.response.data.filter(
                                        (result: { analyte_status: string; group_status: string; }) => result.analyte_status === 'NOK' || result.group_status === 'NOK'
                                    );
                                    if (problematicResults.length > 0) {
                                        setShowInputsNotOkModal(true);
                                        //store bad results in state
                                        setApiBadResults(problematicResults);
                                    }
                                } else {
                                    console.log(err)
                                    setApiError(t('forms_errors.unexpected_error'))
                                }
                            })
                            .finally(() => {
                                setSubmitting(false)
                            })
                    }}
                >
                    {/* Callback function containing Formik state and helpers that handle common form actions */}
                    {function myForm({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,
                        resetForm }) {

                        const subCategories = Object.values(matrixTemplates).filter(item =>
                            item.active &&
                            (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) &&
                            item.category.name === values.category
                        ).map(item => item.sub_category)

                        const rawMaterials = Object.values(matrixTemplates).filter(item =>
                            item.active &&
                            (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) &&
                            item.category.name === values.category
                        ).map(item => item.raw_material)

                        const matrixTemplatesForCompany = Object.entries(matrixTemplates)
                            .filter(([key, item]) => item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id)))
                            .reduce((acc: any, [key, item]: [any, MatrixTemplate]) => { acc[key] = item; return acc; }, {});

                        useEffect(() => {

                            if (['edition', 'update'].includes(mode) && matrixDb?.template_id && matrixTemplates[matrixDb?.template_id!]) {
                                
                                    setFieldValue('laboratory_id', matrixTemplates[matrixDb?.template_id!].laboratory_id)
                                    setFieldValue('category', matrixFields ? matrixFields.category : matrixDb.category)
                                    setFieldValue('sub_category', matrixFields ? matrixFields.sub_category : matrixDb.sub_category)
                                    setFieldValue('raw_material', matrixFields ? matrixFields.raw_material : matrixDb.raw_material)
                                    if(matrixFields) {
                                        updateTemplateAndJCode(matrixFields.category, matrixFields.sub_category, matrixFields.raw_material, matrixFields.company_id);
                                    } else {
                                        updateTemplateAndJCode(matrixDb.category!, matrixDb.sub_category!, matrixDb.raw_material!, matrixDb.company_id!);
                                    }
                                    setTriggerInputs(true)

                                    if(dashboardSelectedValues.commentary !== '') {
                                        setFieldValue('comment', dashboardSelectedValues.commentary)
                                    }

                                

                            } else if (['edition', 'update'].includes(mode) && matrixDb?.template_id) {
                                
                                    setFieldValue('laboratory_id', companies[matrixDb?.company_id!].formulation_id)
                                    setFieldValue('category', matrixFields ? matrixFields.category : matrixDb.category)
                                    setFieldValue('sub_category', matrixFields ? matrixFields.sub_category : matrixDb.sub_category)
                                    setFieldValue('raw_material', matrixFields ? matrixFields.raw_material : matrixDb.raw_material)
                                    if(matrixFields) {
                                        updateTemplateAndJCode(matrixFields.category, matrixFields.sub_category, matrixFields.raw_material, matrixFields.company_id);
                                    } else {
                                        updateTemplateAndJCode(matrixDb.category!, matrixDb.sub_category!, matrixDb.raw_material!, matrixDb.company_id!);
                                    }                                    
                                    setTriggerInputs(true)

                                    if(dashboardSelectedValues.commentary !== '') {
                                        setFieldValue('comment', dashboardSelectedValues.commentary)
                                    }
                            }

                            else if (mode === 'creation' && matrixFields) {
                                setFieldValue('laboratory_id', matrixFields.laboratory_id)
                                setLaboratoryId(matrixFields.laboratory_id)
                                setFieldValue('company_id', matrixFields.company_id)
                                setFieldValue('export_code', matrixFields.export_code)
                                setTemplateId(matrixFields.template_id.toString())
                                setFieldValue('category', matrixFields.category)
                                setFieldValue('sub_category', matrixFields.sub_category)
                                setFieldValue('raw_material', matrixFields.raw_material)
                                setFieldValue('comment', dashboardSelectedValues.commentary)

                                updateTemplateAndJCode(matrixFields.category, matrixFields.sub_category, matrixFields.raw_material, matrixFields.company_id);
                                setTriggerInputs(true)
                            }
                        }, [matrixTemplates])

                        useEffect(() => {
                            if (values.laboratory_id > 0) {
                                dispatch(fetchMatrixTemplatesByPassLimit({ laboratoryId: values.laboratory_id, page: 1 }))
                            }
                        }, [values.laboratory_id])

                        useEffect(() => {
                            //Load inputFields only on create (no matrixDb) OR on edit (matridDb) if matrix template is active & available for the user (userLaboratories)
                            if (!matrixDb || (matrixDb && matrixTemplatesForCompany[matrixDb.template_id]?.active && laboratoriesFiltered.current.find(lab => lab.id === matrixTemplatesForCompany[matrixDb.template_id].laboratory_id))) {
                                //group template inputs by group name and sort by order
                                let filteredMatrixTemplateInputs: MatrixTemplateInput[] = []
                                if (values.matrix_template_id && inputsByTemplateId[values.matrix_template_id]) {
                                    filteredMatrixTemplateInputs = Object.values(matrixTemplateInputs).filter(input => inputsByTemplateId[values.matrix_template_id!].includes(input.id))
                                }
                                const grouped: _.Dictionary<MatrixTemplateInput[]> = _.groupBy(filteredMatrixTemplateInputs, obj => obj.group.name)
                                const groupedAndSorted: _.Dictionary<MatrixTemplateInput[]> = _.mapValues(grouped, group =>
                                    _.orderBy(group, 'order')
                                );
                                const formatted = transformData(groupedAndSorted);
                                setFieldValue('inputFields', formatted)
                            }
                            setTriggerInputs(false)
                        }, [matrixTemplateInputs, triggerInputs])

                        useEffect(() => {
                            //If category & sub_category & raw_material are selected, get the template id for the selected raw material / category / sub_category
                            //Shunt filters if no sub_category or raw_material
                            updateTemplateAndJCode(values.category, values.sub_category, values.raw_material, values.company_id);

                        }, [values.category, values.sub_category, values.raw_material, values.company_id])

                        //Reinit Formik on creation mode change
                        useEffect(() => {
                            if (mode === 'creation') {
                                resetForm({
                                    values: {
                                        laboratory_id: (laboratoriesFiltered.current.length == 1 ? laboratoriesFiltered.current[0].id : 0),
                                        company_id: Object.values(companies).filter(c => c.formulation_id === (laboratoriesFiltered.current.length == 1 ? laboratoriesFiltered.current[0].id : 0)).length === 1 ? Object.values(companies).filter(c => c.formulation_id === (laboratoriesFiltered.current.length == 1 ? laboratoriesFiltered.current[0].id : 0))[0].id! : 0,
                                        export_code: '',
                                        version: 1,
                                        matrix_status: '',
                                        matrix_template_id: null,
                                        category: '',
                                        sub_category: '',
                                        raw_material: '',
                                        jcode: '',
                                        ref_origins: 0,
                                        ref_suppliers: 0,
                                        customer_name: '',
                                        comment: '',
                                        inputFields: {},
                                        force: false
                                    }
                                });
                            }
                        }, [mode])

                        //On UPDATE mode, we get latest version number by requesting the matrix last version
                        useEffect(() => {
                            if (mode === 'update') {
                                MatrixApi.getInstance().getMatrixLastVersion(values.company_id, values.export_code).then((lastVersionMatrix) => {
                                    setFieldValue('version', lastVersionMatrix.version + 1);
                                })
                            }
                        }, [mode])

                        function updateTemplateAndJCode(category: string, subCategory: string, rawMaterial: string, companyId: number) {
                            if (category && subCategories.length > 0 ? category : true && rawMaterials.length > 0 ? rawMaterial : true) {
                                const templateId = Object.values(matrixTemplates).filter(item => item.active &&
                                    (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(companyId))) &&
                                    String(item.category.name) === String(category) &&
                                    (subCategories.length > 0 && item.sub_category ? String(item.sub_category.name) === String(subCategory) : true) &&
                                    (rawMaterials.length > 0 && item.raw_material ? String(item.raw_material.name) === String(rawMaterial) : true)
                                ).map(item => item.id).find(item => item === item);

                                setFieldValue('matrix_template_id', templateId);
                                setTemplateId(templateId ? templateId.toString() : '');
                                setFieldValue('jcode', templateId ? matrixTemplates[templateId].jcode : '');
                            }
                        }

                        return (

                            <Form onSubmit={handleSubmit}>
                                {isSubmitting &&
                                    <Loader />
                                }
                                {apiError &&
                                    <Container className="text-danger">
                                        <br />
                                        {apiError}
                                        <ErrorMessage name="matrix_template_id" component="div" />
                                    </Container>
                                }

                                <Container className="p-0">
                                    <Modal show={showInputsNotOkModal}>
                                        <Modal.Header>
                                            <Modal.Title>{apiBadResults.some((result: any) => result.group_status === 'NOK') ? t("matrix.matrix.msg_matrix_inputs_max_group") : t("matrix.matrix.msg_matrix_inputs_not_ok")}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Footer>
                                            <Button
                                                variant="primary"
                                                onClick={
                                                    () => {
                                                        setShowInputsNotOkModal(false);
                                                        // reset style
                                                        Object.keys(values.inputFields as InputFieldsTable).forEach(
                                                            k => {
                                                                (values.inputFields as InputFieldsTable)[k as string].forEach(
                                                                    f => {
                                                                        const inputField = document.getElementById('inputField_' + f.id) as HTMLInputElement;
                                                                        if (inputField) {
                                                                            inputField.classList.remove('is-invalid');
                                                                            const inputFieldHelp = document.getElementById('inputFieldHelp_' + f.id) as HTMLInputElement;
                                                                            inputFieldHelp.innerHTML = ``;
                                                                        }
                                                                    }
                                                                )
                                                            }
                                                        )
                                                        //get ApiBadResults from state & set input fields in error style 
                                                        apiBadResults.forEach((result: { analyte_id: string; min: number; max: number; max_group?: number }) => {
                                                            const inputField = document.getElementById('inputField_' + result.analyte_id) as HTMLInputElement;
                                                            if (inputField) {
                                                                inputField.classList.add('is-invalid');
                                                                const inputFieldHelp = document.getElementById('inputFieldHelp_' + result.analyte_id) as HTMLInputElement;
                                                                if (result.max_group) {
                                                                    inputFieldHelp.innerHTML = `max group : ${result.max_group}`;
                                                                }
                                                                else {
                                                                    if ((result.max || result.max === 0) && (result.min || result.min === 0)) {
                                                                        inputFieldHelp.innerHTML = `${result.min} - ${result.max}`;
                                                                    } else if ((result.max || result.max === 0) && (result.min === null || result.min === undefined)) {
                                                                        inputFieldHelp.innerHTML = `< ${result.max}`;
                                                                    } else if ((result.min || result.min === 0) && (result.max === null || result.max === undefined)) {
                                                                        inputFieldHelp.innerHTML = `> ${result.min}`;
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    }
                                                }
                                            >
                                                {t("matrix.matrix.buttons.check_datas")}
                                            </Button>
                                            <Button
                                                disabled={apiBadResults.some((result: any) => result.group_status === 'NOK')}
                                                variant="primary"
                                                onClick={
                                                    () => {
                                                        setShowInputsNotOkModal(false);
                                                        setFieldValue('force', true) // will call api with force = true
                                                        handleSubmit()
                                                    }
                                                }
                                            >
                                                {t("matrix.matrix.buttons.dont_check_datas")}
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal show={showAllInputsOkModal}>
                                        <Modal.Header>
                                            <Modal.Title>{t("matrix.matrix.msg_matrix_saved")}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Footer>
                                            <Link to="/secure/matrix/my_matrix">
                                                <Button variant="primary">{t("matrix.matrix.buttons.my_matrix")}</Button>
                                            </Link>
                                            <Button variant="primary"
                                                onClick={() => {
                                                    //Tous les champs sont effacés à l’exception du laboratoire et de l’entreprise.                                                   
                                                    setFieldValue('export_code', '')
                                                    setFieldValue('category', '')
                                                    setFieldValue('sub_category', '')
                                                    setFieldValue('raw_material', '')
                                                    setFieldValue('jcode', '')
                                                    setFieldValue('matrix_template_id', null)
                                                    setFieldValue('ref_origins', 0)
                                                    setFieldValue('ref_suppliers', 0)
                                                    setFieldValue('customer_name', '')
                                                    setFieldValue('comment', '')
                                                    setFieldValue('inputFields', {})
                                                    setFieldValue('force', false)
                                                    //close modal
                                                    setShowAllInputsOkModal(false)
                                                }
                                                }
                                            >
                                                {t("matrix.matrix.buttons.new_matrix")}
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal show={showExportcodeModal}>
                                        <Modal.Header>
                                            <Modal.Title>{t("matrix.matrix.msg_export_code_already_exist")}</Modal.Title>
                                        </Modal.Header>
                                        {(matrixLastVersionStatus === "Archived" || matrixLastVersionStatus === "Active") &&
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={handleFocusOnExportCode}>
                                                    {t("matrix.matrix.buttons.update_export_code")}
                                                </Button>
                                                <Link to={`/secure/matrix/edit_matrix/update/${matrixLastVersionDatas?.template_id}/${matrixLastVersionDatas?.company_id}/${matrixLastVersionDatas?.export_code}/${matrixLastVersionDatas?.version}`}>
                                                    <Button onClick={(e) => {
                                                        setShowExportcodeModal(false)
                                                    }}
                                                        variant="primary">{t("matrix.matrix.buttons.update_matrix")}</Button>
                                                </Link>
                                            </Modal.Footer>
                                        }

                                        {(matrixLastVersionStatus === "Pending Autocalc") &&
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={handleFocusOnExportCode}>
                                                    {t("matrix.matrix.buttons.update_export_code")}
                                                </Button>
                                            </Modal.Footer>
                                        }

                                        {(["In progress", "Ready", "Autocalc issue", "Administration issue", "Autocalc integration issue"].includes(matrixLastVersionStatus)) &&
                                            <Modal.Footer>
                                                <Button variant="primary" className='me-3' onClick={handleFocusOnExportCode}>
                                                    {t("matrix.matrix.buttons.update_export_code")}
                                                </Button>
                                                <Link to={`/secure/matrix/edit_matrix/edition/${matrixLastVersionDatas?.template_id}/${matrixLastVersionDatas?.company_id}/${matrixLastVersionDatas?.export_code}/${matrixLastVersionDatas?.version}`}>
                                                    <Button onClick={(e) => {
                                                        setShowExportcodeModal(false)
                                                    }} variant="primary">{t("matrix.matrix.buttons.edit_matrix")}</Button>
                                                </Link>
                                            </Modal.Footer>
                                        }
                                    </Modal>

                                    <Row className="no-print">
                                        <Col xs="auto">
                                            <GoBackButton label={t('matrix.matrix.return_to_my_list_matrix')} />
                                        </Col>
                                    </Row>
                                    <Row className="pt-2 " >
                                        <Col>
                                            <h4 className="pb-2 fw-bold">
                                                {matrixDb ? (
                                                    <>
                                                        <span>Matrix n°</span>
                                                        <span className="text-primary fw-bold">
                                                            {` ${matrixDb.export_code}`}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span>{`${t('matrix.matrix.create_matrix')}`}</span>
                                                )}
                                            </h4>
                                        </Col>
                                    </Row>
                                    {matrixDb &&  //mode edition/update
                                        <Row className="mt-4 mb-4">
                                            <StatusMatrixBar
                                                Date1={matrixDb.creation_date != null ? new Date(matrixDb.creation_date).toString() : undefined}
                                                Date2={matrixDb.last_update != null ? new Date(matrixDb.last_update).toString() : undefined}
                                                Date3={matrixDb.ready_date != null ? new Date(matrixDb.ready_date).toString() : undefined}
                                                Date4={matrixDb.active_date != null ? new Date(matrixDb.active_date).toString() : undefined}
                                                Date5={matrixDb.last_export != null ? new Date(matrixDb.last_export).toString() : undefined}
                                                Date6={matrixDb.archived_date != null ? new Date(matrixDb.archived_date).toString() : undefined}
                                                Status={matrixDb.matrix_status} />
                                        </Row>
                                    }
                                </Container>
                                <Container>

                                    <>
                                        <Container className="p-2 bg-white mb-4 rounded-3">
                                            <Row >
                                                <Col lg>
                                                    <h4 className="text-primary fw-bold">
                                                        {t('matrix.matrix.company')}
                                                    </h4>
                                                </Col>
                                            </Row>
                                            <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                            <Row>
                                                {(() => {
                                                    if (laboratoriesFiltered.current.length > 1) {
                                                        return (
                                                            <Col lg>
                                                                <Form.Group>
                                                                    <Form.Label className="text-break">{t('matrix.matrix.formulation_team')} : *</Form.Label>
                                                                    <Select
                                                                        name="laboratory_id"
                                                                        isSearchable={true}
                                                                        value={values.laboratory_id ? laboratories[values.laboratory_id] : 0}
                                                                        isClearable={true}
                                                                        noOptionsMessage={() => t('matrix.matrix.form.laboratory.select_no_options')}
                                                                        placeholder={t('matrix.matrix.form.laboratory.select_placeholder')}
                                                                        getOptionLabel={(l: Laboratory) => l.name}
                                                                        getOptionValue={(l: Laboratory) => l.id}
                                                                        options={laboratoriesFiltered.current}
                                                                        isDisabled={matrixDb}
                                                                        onChange={(l: Laboratory) => {
                                                                            setFieldValue('laboratory_id', l ? l.id : 0)
                                                                            setLaboratoryId(l ? l.id : 0)
                                                                            setFieldValue('company_id', l ? Object.values(companies).filter(c => c.formulation_id === l.id).length === 1 ? Object.values(companies).filter(c => c.formulation_id === l.id)[0].id : 0 : 0)
                                                                            setFieldValue('export_code', '')
                                                                            setFieldValue('category', '')
                                                                            setFieldValue('sub_category', '')
                                                                            setFieldValue('raw_material', '')
                                                                            setFieldValue('inputFields', [])
                                                                        }}
                                                                        styles={buildSelectNormalStyle(touched.laboratory_id, typeof errors.laboratory_id === "string")}
                                                                    />
                                                                    {touched.laboratory_id && !!errors.laboratory_id ? <Form.Text className="invalid-feedback d-block">{errors.laboratory_id}</Form.Text> : null}
                                                                </Form.Group>
                                                            </Col>
                                                        )
                                                    }
                                                })()}

                                                <Col lg>
                                                    <Form.Group>
                                                        <Form.Label className="text-break">{t('matrix.matrix.company')} : *</Form.Label>
                                                        <Select
                                                            name="company_id"
                                                            isSearchable={true}
                                                            value={values.company_id ? companies[values.company_id] : null}
                                                            isClearable={false}
                                                            noOptionsMessage={() => t('matrix.matrix.form.company.select_no_options')}
                                                            placeholder={t('matrix.matrix.form.company.select_placeholder')}
                                                            getOptionLabel={(c: Company) => c.name}
                                                            getOptionValue={(c: Company) => c.id}
                                                            options={Object.values(companies).filter(c => c.formulation_id === values.laboratory_id)}
                                                            isDisabled={!values.laboratory_id || matrixDb}
                                                            onChange={(company: Company) => {
                                                                setFieldValue('company_id', company ? company.id : null)
                                                                setFieldValue('export_code', '')
                                                                setFieldValue('category', '')
                                                                setFieldValue('sub_category', '')
                                                                setFieldValue('raw_material', '')
                                                                setFieldValue('inputFields', [])
                                                                // Automatically select category, sub_category & raw_material                
                                                                const filteredCategories = Object.values(matrixTemplates).filter(template => template.active && (template.crit_company === '*' || (template.crit_company && template.crit_company.split('; ').includes(String(company.id))))).map(item => item.category.name)
                                                                    .reduce(function (previous: any, current) {
                                                                        if (!previous.find(function (prevItem: any) {
                                                                            return prevItem === current;
                                                                        })) {
                                                                            previous.push(current);
                                                                        }
                                                                        return previous;
                                                                    }, [])
                                                                if (filteredCategories.length === 1) {
                                                                    setFieldValue('category', filteredCategories[0]);
                                                                    const filteredSubCategories = Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(company.id))) && item.category.name === filteredCategories[0]).map(item => item.sub_category.name || '')
                                                                        .reduce(function (previous: any, current) {
                                                                            if (!previous.find(function (prevItem: any) {
                                                                                return prevItem === current;
                                                                            })) {
                                                                                previous.push(current);
                                                                            }
                                                                            return previous;
                                                                        }, [])
                                                                    if (filteredSubCategories.length === 1) {
                                                                        setFieldValue('sub_category', filteredSubCategories[0]);
                                                                        const filteredRawMaterial = Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(company.id))) && item.category.name === filteredCategories[0] && item.sub_category && item.sub_category.name === filteredSubCategories[0]).map(item => item.raw_material.name || '')
                                                                        if (filteredRawMaterial.length === 1) {
                                                                            setFieldValue('raw_material', filteredRawMaterial[0]);
                                                                            // needed because setFieldValue does does seem to trigger useEffect
                                                                            updateTemplateAndJCode(filteredCategories[0], filteredSubCategories[0], filteredRawMaterial[0], company.id as number)
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            styles={buildSelectNormalStyle(touched.company_id, typeof errors.company_id === "string")}
                                                        />
                                                        {touched.company_id && !!errors.company_id ? <Form.Text className="invalid-feedback d-block">{errors.company_id}</Form.Text> : null}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col lg>
                                                    <Form.Group>
                                                        <Form.Label className="text-break">{t('matrix.matrix.export_code')} : *</Form.Label>
                                                        <Form.Control
                                                            className={touched.export_code && errors.export_code ? "is-invalid" : undefined}
                                                            ref={exportCodeinputRef}
                                                            type="text"
                                                            name='export_code'
                                                            value={values.export_code}
                                                            onBlur={async (e) => {
                                                                // we have to do this to not let some1 create a matrix when checking export code
                                                                setLoadingExportCode(true)
                                                                handleBlur(e)
                                                                if (values.company_id && e.target.value && e.relatedTarget?.getAttribute('role') !== 'dialog') { // need to do this because when we focus on export_code from Modal, blur is called (so we are in a loop)                                                     
                                                                    const lastVersion = await MatrixApi.getInstance().getMatrixLastVersion(values.company_id, e.target.value)
                                                                    if (lastVersion) {
                                                                        setMatrixLastVersionStatus(lastVersion.matrix_status)
                                                                        exportCodeinputRef.current?.focus()
                                                                        setShowExportcodeModal(true)
                                                                        //Backup values for modal link "Edit Matrix"
                                                                        setMatrixLastVersionDatas(lastVersion)
                                                                    }
                                                                }
                                                                setLoadingExportCode(false)
                                                            }}
                                                            onChange={handleChange}
                                                            disabled={!values.company_id || !!matrixDb}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{errors.export_code}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg>
                                                    <Form.Group>
                                                        <Form.Label className="text-break">{t('matrix.matrix.version')} :</Form.Label>
                                                        <>
                                                            <br /><span>{values.version}</span>
                                                        </>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container className="p-2 bg-white mb-4 rounded-3">
                                            <Row >
                                                <Col lg>
                                                    <h4 className="text-primary fw-bold">
                                                        {t('matrix.matrix.matrix')}
                                                    </h4>
                                                    <ErrorMessage name="matrix_template_id" component="div" className="text-danger" />
                                                    {matrixDb && !matrixTemplates[matrixDb.template_id]?.active &&
                                                        <div className="text-danger">{t('matrix.matrix.msg_matrix_template_error')}</div>
                                                    }
                                                    {matrixDb && !matrixTemplates[matrixDb.template_id]?.crit_company &&
                                                        <div className="text-danger">{t('matrix.matrix.msg_matrix_template_error_company')}</div>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                            <Row>
                                                <Col lg>
                                                    {matrixTemplatesStatus === 'loading' &&
                                                        <InlineLoader />
                                                    }
                                                    {(matrixTemplatesStatus === 'idle' || matrixTemplatesStatus === 'succeeded') &&
                                                        <Form.Group>
                                                            <Form.Label className="text-break">{t('matrix.matrix.category')} : *</Form.Label>
                                                            <Select
                                                                name="category"
                                                                isSearchable={true}
                                                                value={
                                                                    values.category ?
                                                                        Object.values(matrixTemplates).filter(template =>
                                                                            template.active && (template.crit_company === '*' ? Object.values(companies).map(company => company.id).includes(values.company_id)
                                                                                : template.crit_company && template.crit_company?.split('; ').includes(String(values.company_id))
                                                                            )
                                                                        ).map(template => template.category).find(template => template.name === values.category)
                                                                        :
                                                                        null
                                                                }
                                                                isClearable={false}
                                                                noOptionsMessage={() => t('matrix.matrix.form.category.select_no_options')}
                                                                placeholder={t('matrix.matrix.form.category.select_placeholder')}
                                                                getOptionLabel={(category: MatrixTemplate["category"]) => category.translations[authUser.locale as keyof typeof category.translations] || category.name}
                                                                getOptionValue={(category: MatrixTemplate["category"]) => category.name}
                                                                options={Object.values(matrixTemplates).filter(template => template.active && (template.crit_company === '*' || (template.crit_company && template.crit_company.split('; ').includes(String(values.company_id))))).map(item => item.category)
                                                                    .reduce(function (previous: any, current) {
                                                                        if (!previous.find(function (prevItem: any) {
                                                                            return prevItem.name === current.name;
                                                                        })) {
                                                                            previous.push(current);
                                                                        }
                                                                        return previous;
                                                                    }, [])
                                                                }
                                                                isDisabled={!values.company_id}
                                                                onChange={(category: MatrixTemplate["category"]) => {
                                                                    setFieldValue('category', category ? category.name : null)
                                                                    setFieldValue('sub_category', '')
                                                                    setFieldValue('raw_material', '')
                                                                    setFieldValue('inputFields', [])
                                                                    // Automatically select sub_category & raw_material
                                                                    const filteredSubCategories = Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) && item.category.name === category.name).map(item => item.sub_category.name || '')
                                                                        .reduce(function (previous: any, current) {
                                                                            if (!previous.find(function (prevItem: any) {
                                                                                return prevItem === current;
                                                                            })) {
                                                                                previous.push(current);
                                                                            }
                                                                            return previous;
                                                                        }, [])
                                                                    if (filteredSubCategories.length === 1) {
                                                                        setFieldValue('sub_category', filteredSubCategories[0]);
                                                                        const filteredRawMaterial = Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) && item.category.name === category.name && item.sub_category && item.sub_category.name === filteredSubCategories[0]).map(item => item.raw_material.name || '')
                                                                        if (filteredRawMaterial.length === 1) {
                                                                            setFieldValue('raw_material', filteredRawMaterial[0]);
                                                                            // needed because setFieldValue does does seem to trigger useEffect
                                                                            updateTemplateAndJCode(category.name, filteredSubCategories[0], filteredRawMaterial[0], values.company_id as number)
                                                                        }
                                                                    }
                                                                }}
                                                                styles={buildSelectNormalStyle(touched.category, typeof errors.category === "string")}
                                                            />
                                                            {getIn(touched, "category") && typeof getIn(errors, "category") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors, "category")}</Form.Text> : null}

                                                        </Form.Group>
                                                    }
                                                </Col>

                                                {Object.values(matrixTemplates).filter(item =>
                                                    item.active &&
                                                    (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) &&
                                                    item.category.name === values.category &&
                                                    item.sub_category.name !== ''
                                                ).map(item => item.sub_category).length > 0 &&
                                                    <Col lg>
                                                        <Form.Group>
                                                            <Form.Label className="text-break">{t('matrix.matrix.sub_category')} :</Form.Label>
                                                            <Select
                                                                name="sub_category"
                                                                isSearchable={true}
                                                                value={values.sub_category ? Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id)))).map(item => item.sub_category || { name: '', translations: { fr: '', en: '', es: '', it: '', pl: '', tur: '', pt: '', nl: '', de: '' } }).find(item => item && item.name === values.sub_category) : null}// ADD LANG: TO CHANGE
                                                                isClearable={false}
                                                                noOptionsMessage={() => t('matrix.matrix.form.sub_category.select_no_options')}
                                                                placeholder={t('matrix.matrix.form.sub_category.select_placeholder')}
                                                                getOptionLabel={(sub_category: MatrixTemplate["sub_category"]) => sub_category.translations[authUser.locale as keyof typeof sub_category.translations] || sub_category.name}
                                                                getOptionValue={(sub_category: MatrixTemplate["sub_category"]) => sub_category.name}
                                                                options={Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) && item.category.name === values.category).map(item => item.sub_category || { name: '', translations: { fr: '', en: '', es: '', it: '', pl: '', tur: '', pt: '', nl: '', de: '' } })// ADD LANG: TO CHANGE
                                                                    .reduce(function (previous: any, current) {
                                                                        if (!previous.find(function (prevItem: any) {
                                                                            return prevItem === current || (prevItem && current && prevItem.name === current.name);
                                                                        })) {
                                                                            previous.push(current);
                                                                        }
                                                                        return previous;
                                                                    }, [])
                                                                }
                                                                isDisabled={!values.company_id}
                                                                onChange={(sub_category: MatrixTemplate["sub_category"]) => {
                                                                    setFieldValue('sub_category', sub_category ? sub_category.name : null)
                                                                    setFieldValue('raw_material', '')
                                                                    setFieldValue('inputFields', [])
                                                                    // Automatically select raw_material
                                                                    const filteredRawMaterial = Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) && item.category.name === values.category && item.sub_category && item.sub_category.name === sub_category.name).map(item => item.raw_material.name || '')
                                                                    if (filteredRawMaterial.length === 1) {
                                                                        setFieldValue('raw_material', filteredRawMaterial[0]);
                                                                        // needed because setFieldValue does does seem to trigger useEffect
                                                                        updateTemplateAndJCode(values.category, sub_category.name, filteredRawMaterial[0], values.company_id as number)
                                                                    }
                                                                }}
                                                                styles={buildSelectNormalStyle(touched.sub_category, typeof errors.sub_category === "string")}
                                                            />
                                                            {getIn(touched, "sub_category") && typeof getIn(errors, "sub_category") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors, "sub_category")}</Form.Text> : null}

                                                        </Form.Group>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row>
                                                {Object.values(matrixTemplates).filter(item =>
                                                    item.active &&
                                                    (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) &&
                                                    item.category.name === values.category &&
                                                    item.raw_material.name !== ''
                                                ).map(item => item.raw_material).length > 0 &&

                                                    <Col lg>
                                                        <Form.Group>
                                                            <Form.Label className="text-break">{t('matrix.matrix.raw_material')} :</Form.Label>
                                                            <Select
                                                                name="raw_material"
                                                                isSearchable={true}
                                                                value={values.raw_material ? Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id)))).map(item => item.raw_material || { name: '', translations: { fr: '', en: '', es: '', it: '', pl: '', tur: '', pt: '', nl: '', de: '' } }).find(item => item && item.name === values.raw_material) : null}// ADD LANG: TO CHANGE
                                                                isClearable={false}
                                                                noOptionsMessage={() => t('matrix.matrix.form.raw_material.select_no_options')}
                                                                placeholder={t('matrix.matrix.form.raw_material.select_placeholder')}
                                                                getOptionLabel={(rawmaterial: MatrixTemplate["raw_material"]) => rawmaterial.translations[authUser.locale as keyof typeof rawmaterial.translations] || rawmaterial.name}
                                                                getOptionValue={(rawmaterial: MatrixTemplate["raw_material"]) => rawmaterial.name}
                                                                options={Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) && item.category.name === values.category && item.sub_category && item.sub_category.name === values.sub_category).map(item => item.raw_material || { name: '', translations: { fr: '', en: '', es: '', it: '', pl: '', tur: '', pt: '', nl: '', de: '' } })}// ADD LANG: TO CHANGE
                                                                isDisabled={subCategories.length > 0 ? !values.sub_category : false}
                                                                onChange={(rawmaterial: MatrixTemplate["raw_material"]) => {
                                                                    if (rawmaterial.name !== values.raw_material) {
                                                                        setFieldValue('raw_material', rawmaterial ? rawmaterial.name : null)
                                                                        setFieldValue('inputFields', values.inputFields)
                                                                    }
                                                                }}
                                                                styles={buildSelectNormalStyle(touched.raw_material, typeof errors.raw_material === "string")}
                                                            />
                                                            {getIn(touched, "raw_material") && typeof getIn(errors, "raw_material") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors, "raw_material")}</Form.Text> : null}

                                                        </Form.Group>
                                                    </Col>
                                                }

                                                <Col lg>
                                                    <Form.Group>
                                                        <Form.Label className="text-break">{t('matrix.matrix.jcode')} :</Form.Label> <br />
                                                        <span className="text-black fw-bold">
                                                            {/* will display 1jCode of the selected matrix template */}
                                                            {values.matrix_template_id && matrixTemplates[values.matrix_template_id]?.jcode ? matrixTemplates[values.matrix_template_id]?.jcode + (ingredients[matrixTemplates[values.matrix_template_id]?.jcode]?.name ? ' - ' + ingredients[matrixTemplates[values.matrix_template_id]?.jcode]?.name + ' ' : ' ') : t('matrix.matrix.no_jcode')}

                                                            {/* will display the memo icon if the selected matrix template has a memo */}
                                                            {values.matrix_template_id && matrixTemplates[values.matrix_template_id]?.memo_url && matrixTemplates[values.matrix_template_id]?.memo_url !== null &&
                                                                <a onClick={
                                                                    () => {
                                                                        if (values.matrix_template_id) {
                                                                            dispatch(downloadMemo({
                                                                                id: values.matrix_template_id,
                                                                                laboratoryId: values.laboratory_id,
                                                                                url: matrixTemplates[values.matrix_template_id].memo_url
                                                                            }
                                                                            ))
                                                                        }
                                                                    }
                                                                } href="javascript:void(0);">
                                                                    <PdfIcon width="24px" height="100%" className="align-text-top" />
                                                                </a>
                                                            }
                                                        </span>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg>
                                                    <Form.Group>
                                                        <Form.Label className="text-break">{t('matrix.matrix.origin')} :</Form.Label>
                                                        <Select
                                                            name="ref_origins"
                                                            isSearchable={true}
                                                            value={values.ref_origins ? refOrigins[values.ref_origins] : null}
                                                            isClearable={true}
                                                            noOptionsMessage={() => t('matrix.matrix.form.origins.select_no_options')}
                                                            placeholder={t('matrix.matrix.form.origins.select_placeholder')}
                                                            getOptionLabel={(refOrigin: RefOrigin) => refOrigin.name}
                                                            getOptionValue={(refOrigin: RefOrigin) => refOrigin.id}
                                                            options={Object.values(refOrigins).filter((refOrigin => (refOrigin.active)))}
                                                            onChange={(refOrigin: RefOrigin) => {
                                                                setFieldValue('ref_origins', refOrigin ? refOrigin.id : 0)
                                                            }}
                                                            styles={buildSelectNormalStyle(touched.ref_origins, typeof errors.ref_origins === "string")}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col lg>
                                                    <Form.Group>
                                                        <Form.Label className="text-break">{t('matrix.matrix.supplier')} :</Form.Label>
                                                        <Select
                                                            name="ref_suppliers"
                                                            isSearchable={true}
                                                            value={values.ref_suppliers ? refSuppliers[values.ref_suppliers] : null}
                                                            isClearable={true}
                                                            noOptionsMessage={() => t('matrix.matrix.form.suppliers.select_no_options')}
                                                            placeholder={t('matrix.matrix.form.suppliers.select_placeholder')}
                                                            getOptionLabel={(refSupplier: RefSupplier) => refSupplier.name}
                                                            getOptionValue={(refSupplier: RefSupplier) => refSupplier.id}
                                                            options={Object.values(refSuppliers).filter((refSupplier => (refSupplier.active)))}
                                                            onChange={(refSupplier: RefSupplier) => {
                                                                setFieldValue('ref_suppliers', refSupplier ? refSupplier.id : null)
                                                            }}
                                                            styles={buildSelectNormalStyle(touched.ref_suppliers, typeof errors.ref_suppliers === "string")}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg>
                                                    <Form.Group>
                                                        <Form.Label className="text-break">{t('matrix.matrix.customer_name')} :</Form.Label>
                                                        <Form.Control ref={categoryInputRef} className={touched.customer_name && errors.customer_name ? "is-invalid" : undefined} type="text" name='customer_name' value={values.customer_name} onChange={handleChange} onBlur={handleBlur} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg>
                                                    <Form.Group>
                                                        <Form.Label className="text-break">{t('matrix.matrix.comment')} :</Form.Label>
                                                        <Form.Control className={touched.comment && errors.comment ? "is-invalid" : undefined} as="textarea" rows={3} name='comment' value={values.comment} onChange={handleChange} onBlur={handleBlur} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Container className="p-2 bg-white mb-4 rounded-3">
                                            <Row >
                                                <Col lg>
                                                    <h4 className="text-primary fw-bold">
                                                        {t('matrix.matrix.inputs')}
                                                    </h4>
                                                </Col>
                                            </Row>
                                            <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>

                                            {matrixTemplatesInputsStatus === 'loading' &&
                                                <InlineLoader />
                                            }

                                            {Object.entries(values.inputFields).map(([groupName, analytes], groupIndex) => (

                                                <React.Fragment key={`${groupName}-${groupIndex}`}>
                                                    <Row className='mb-3'>
                                                        <Col lg>
                                                            <Accordion>
                                                                <Card>
                                                                    <ContextAwareToggle eventKey={String(groupIndex)} name={groupName} />
                                                                    <Accordion.Collapse eventKey={String(groupIndex)} >
                                                                        <Card.Body>
                                                                            <Row>
                                                                                {/* @ts-ignore */}
                                                                                {values.inputFields[groupName].map((analyte: any, key: number) => {
                                                                                    if (!(values.inputFields as InputFieldsTable)[groupName][key].active) {
                                                                                        return null
                                                                                    }
                                                                                    return (
                                                                                        <Col id={`${groupName}#${key}`} key={`${groupName}#${key}`} lg={6} >
                                                                                            <Form.Group as={Row} >
                                                                                                <Form.Label column sm={5}>
                                                                                                    {analyte.name} {'('}{analyte.unit}{')'} : {analyte.is_toggle === true ? '#' : '*'}
                                                                                                </Form.Label>
                                                                                                <Col sm={7}>
                                                                                                    <Form.Control
                                                                                                        className={touched.category && errors.comment ? "is-invalid" : undefined}
                                                                                                        type="text"
                                                                                                        name={`inputFields.${groupName}[${key}].input`}
                                                                                                        //@ts-ignore
                                                                                                        value={values.inputFields[groupName][key].input}
                                                                                                        onChange={handleChange}
                                                                                                        onBlur={handleBlur}
                                                                                                        id={'inputField_' + analyte.id}
                                                                                                    />
                                                                                                    <Form.Text className="text-muted" id={'inputFieldHelp_' + analyte.id}></Form.Text>
                                                                                                </Col>
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                    )
                                                                                }

                                                                                )}
                                                                            </Row>
                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                </Card>
                                                            </Accordion>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            ))}
                                            <Row>
                                                <Col lg>
                                                    <Row className="mt-4 pb-3" ><Col lg className="lineTitle"></Col></Row>
                                                    <Form.Group className="text-end">
                                                        <Form.Label ><span className="text-black">#: {t('matrix.matrix.advice')}</span></Form.Label>
                                                    </Form.Group>
                                                    <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </>
                                </Container>
                                <Container>
                                    <Row className='mt-3'>
                                        <Col className='text-end'>
                                            {matrixDb &&
                                                <Button variant="secondary" className='me-3' onClick={() => history.goBack()}>
                                                    {t("matrix.matrix.buttons.cancel_edits")}
                                                </Button>
                                            }
                                            <Button disabled={isSubmitting || loadingExportCode} variant="primary" type="submit">
                                                {matrixDb ?
                                                    mode === 'edition' ? t('matrix.matrix.buttons.validate_modifications') : t('matrix.matrix.buttons.update_matrix')
                                                    :
                                                    t("matrix.matrix.buttons.create")
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        );
                    }}
                </Formik>
            }
        </Container>
    )
}

export default withTranslation()(CreateMatrix)