import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../state/rootReducer'
import { ReactComponent as HomeIcon } from '../resources/home.svg'
import { ReactComponent as AvatarIcon } from '../resources/person-circle.svg';
import { useOktaAuth } from '@okta/okta-react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import userHasRequiredRoles from '../auth/userHasRequiredRoles';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import NavLink from 'react-bootstrap/NavLink';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Link } from "react-router-dom";
import useReduxFilter from '../utils/useReduxFilter';
import { filterByReducer } from '../../state/analysisSlice';
import useSecurityRoles from '../../common/auth/useSecurityRoles';
import MatrixImport from '../../pages/matrix/MatrixImport';
import userHasOnlyRole from '../auth/userHasOnlyRole';
import { resetMatrixFields } from '../../state/dashboardSlice';
import { AppDispatch } from '../../state/store';


const Header: React.FC<WithTranslation> = ({ t }) => {
    const { oktaAuth } = useOktaAuth();
    const dispatch = useDispatch<AppDispatch>();

    const { goToWithFilter } = useReduxFilter()

    const { user } = useSelector((state: RootState) => state.authUser)
    const { defaultFilters } = useSelector((state: RootState) => state.analysis.analysis)
    const theme = useSelector((state: RootState) => state.authUser.theme)

    const { userHasOneRoleLevelLaboratories, userHasOnePdcLevelAdmin } = useSecurityRoles()

    const logout = async () => {
        await oktaAuth.revokeAccessToken()
        await oktaAuth.signOut({revokeAccessToken: true, revokeRefreshToken: true})
    }

    return (

        <Navbar expand="lg">
            <Navbar.Toggle aria-controls="menu-navbar-nav" data-target="#menu-navbar-nav" >
                <Nav>
                    <Nav.Link as={Link} className="nav-link" to='/secure/analysis'>
                        <HomeIcon width="22px" height="100%" className="align-text-top" />
                    </Nav.Link>
                </Nav>
            </Navbar.Toggle>
            <Navbar.Brand as={Link} to="/secure/analysis" className="navbar-brand mx-auto">
                <img
                    alt=""
                    src={theme && theme.headerLogo ? `data:image/png;base64,${theme.headerLogo}` : "/provilab-30.png"}
                    width="auto"
                    height="30"
                    className="align-top d-inline-block d-md-none"
                />
                <img
                    alt=""
                    src={theme && theme.headerLogo ? `data:image/png;base64,${theme.headerLogo}` : "/provilab-60.png"}
                    width="auto"
                    height="60"
                    className="align-top d-none d-md-inline-block"
                />
            </Navbar.Brand>
            <Dropdown align="end" className="userbox me-lg-5">
                <Dropdown.Toggle as={NavLink} id="user-nav-dropdown" className="d-flex align-items-center" aria-label="Dropdown User">
                    <AvatarIcon width="30px" height="100%" className="text-secondary" />
                    <Container className="d-none d-md-block flex-column align-items-start text-start">
                        <Row className="username">
                            <Col>{user.firstname + " " + user.lastname}</Col>
                        </Row>
                        <Row className="usermail">
                            <Col>{user.email}</Col>
                        </Row>
                    </Container>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item disabled className="d-block d-md-none flex-column align-items-start text-start">
                        <Row className="username">
                            <Col>{user.firstname + " " + user.lastname}</Col>
                        </Row>
                        <Row className="usermail">
                            <Col>{user.email}</Col>
                        </Row>
                    </Dropdown.Item>
                    <Dropdown.Divider className="d-block d-md-none" />
                    <Dropdown.Item as={Link} to="/secure/user/profile">{t('nav.my_profile')}</Dropdown.Item>
                    {userHasRequiredRoles(['MyProvilab_ROLE_technician', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson', 'MyProvilab_ROLE_controlPlan', 'MyProvilab_ROLE_usersAdmin']) ? (
                        <Dropdown.Item as={Link} to="/secure/user/breeders">{t('nav.my_breeders')}</Dropdown.Item>
                    ) : (null)}
                    <Dropdown.Divider />
                    <Dropdown.Item href="#" onClick={logout}>{t('nav.logout')}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Navbar.Collapse hidden={true} id="menu-navbar-nav" className="flex-lg-grow-0 order-lg-first ms-lg-5 text-start">
                <Nav>
                    <Nav.Link as={Link} to='/secure/home'>
                        <HomeIcon width="22px" height="100%" className="align-text-top me-3" />
                        <span className="ps-1">{t('nav.home')}</span>
                    </Nav.Link>
                    {!userHasOnlyRole('MyProvilab_ROLE_nutrition') &&
                        <NavDropdown title={t('nav.my_analysis_requests')} id="analysis-nav-dropdown">
                            <NavDropdown.Item onClick={() => { goToWithFilter('/secure/analysis', defaultFilters.recent, filterByReducer) }}>{t('nav.my_analysis_requests_last')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => { goToWithFilter('/secure/analysis', defaultFilters.registered, filterByReducer) }}>{t('nav.my_analysis_requests_registered')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => { goToWithFilter('/secure/analysis', defaultFilters.inprogress, filterByReducer) }}>{t('nav.my_analysis_requests_inprogress')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => { goToWithFilter('/secure/analysis', defaultFilters.invalid, filterByReducer) }}>{t('nav.my_analysis_requests_invalid')}</NavDropdown.Item>
                        </NavDropdown>
                    }
                    {userHasRequiredRoles(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_technician', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson', 'MyProvilab_ROLE_quality']) ? (
                        <NavDropdown title={t('nav.new_analysis_request')} id="new-analysis-nav-dropdown">
                            {userHasRequiredRoles(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_technician', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson']) ? (
                                <NavDropdown.Item as={Link} to="/secure/analysis/fodder">{t('nav.fodder')}</NavDropdown.Item>
                            ) : (null)}
                            {userHasRequiredRoles(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_quality', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson']) ? (
                                <NavDropdown.Item as={Link} to="/secure/analysis/rawmaterial">{t('nav.raw_material')}</NavDropdown.Item>
                            ) : (null)}
                            {userHasRequiredRoles(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_quality', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson']) ? (
                                <NavDropdown.Item as={Link} to="/secure/analysis/feed">{t('nav.feed')}</NavDropdown.Item>
                            ) : (null)}
                        </NavDropdown>
                    ) : (null)}                  
                    {userHasRequiredRoles(['MyProvilab_ROLE_controlPlan', 'MyProvilab_ROLE_usersAdmin']) ? (
                        <NavDropdown title={t('nav.control_plan')} id="pdc-nav-dropdown">
                    {(userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']) || ( userHasOnePdcLevelAdmin(user))) &&
                                <NavDropdown.Item as={Link} to="/secure/pdc/0/0">{t('nav.create_control_plan')}</NavDropdown.Item>
                            }
                            <NavDropdown.Item as={Link} to="/secure/pdcs">{t('nav.my_control_plans')}</NavDropdown.Item>
                        </NavDropdown>
                    ) : (null)}
                    {!userHasOnlyRole('MyProvilab_ROLE_nutrition') &&
                        <NavDropdown title={t('nav.results')} id="analysis-nav-dropdown">
                        {userHasRequiredRoles(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'])  && (userHasOneRoleLevelLaboratories(user) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) ?
                            (<NavDropdown.Item as={Link} to="/secure/laboratory/result_input">{t('admin_labo.nav.labels.result_input')}</NavDropdown.Item>
                            ) : (null)}
                            {userHasRequiredRoles(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'])  && (userHasOneRoleLevelLaboratories(user) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) ? (
                                <NavDropdown.Item as={Link} to='/secure/laboratory/handle-nirs'>{t('admin_labo.nav.labels.handle_nirs')}</NavDropdown.Item>
                            ) : (null)}                       
                            <NavDropdown.Item as={Link} to="/secure/results">{t('nav.my_analysis_requests_done')}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/secure/export_screen'>{t('nav.export_screen')}</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/secure/dashboard/results_analysis">{t('nav.dashboard')}</NavDropdown.Item>
                        </NavDropdown>
                    }
                    
                    {userHasRequiredRoles(['MyProvilab_ROLE_nutrition', 'MyProvilab_ROLE_usersAdmin']) ? (
                        <NavDropdown title={t('nav.manage_matrix')} id="matrix-nav-dropdown">
                            <NavDropdown.Item onClick={() => dispatch(resetMatrixFields())} as={Link} to="/secure/matrix/new_matrix">{t('nav.new_matrix') } </NavDropdown.Item>
                            <NavDropdown.Item as={MatrixImport} type="link" ></NavDropdown.Item>
                            <NavDropdown.Item onClick={() => dispatch(resetMatrixFields())} as={Link} to="/secure/matrix/my_matrix">{t('nav.my_matrix')}</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => dispatch(resetMatrixFields())} as={Link} to="/secure/dashboard/matrix">{t('nav.dashboard_matrix')}</NavDropdown.Item>
                        </NavDropdown>
                    ) : (null)}

                    {userHasRequiredRoles(['MyProvilab_ROLE_nutrition']) && user.formulations.length > 0 || userHasRequiredRoles([ 'MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_usersAdmin'])  && (userHasOneRoleLevelLaboratories(user) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) ? (
                        <NavDropdown title={t('nav.administration')} id="administration-nav-dropdown">
                            {userHasRequiredRoles(['MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'])  && (userHasOneRoleLevelLaboratories(user) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) ? (
                                <NavDropdown.Item as={Link} to="/secure/laboratory">{t('nav.laboratory')}</NavDropdown.Item>
                            ) : (null)}
                            {userHasRequiredRoles(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_usersAdmin'])  && (userHasOneRoleLevelLaboratories(user) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) ? (
                                <NavDropdown.Item as={Link} to="/secure/admin">{t('nav.admin')}</NavDropdown.Item>
                            ):null}
                            {userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']) || (userHasRequiredRoles(['MyProvilab_ROLE_nutrition']) && user.formulations.length > 0) ? (
                                <NavDropdown.Item as={Link} to="/secure/matrix_management">{t('nav.matrix_management')}</NavDropdown.Item>
                            ) : (null)}
                        </NavDropdown>
                    ) : (null)}
                    
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default withTranslation()(Header)