import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import MatrixApi, { BodyDeleteMatrices, BodyDeleteMatrix, BodyMatrixCreate, Matrix, MatrixKey, StatusMatrix, getKeyForMatrix } from '../api/matrixApi';
import { Data, FilterBy, SortBy, Filter } from '../types/types';
import moment from 'moment';

interface Page {
    ids: Array<MatrixKey>
    fetching: boolean
}

interface Pagination {
    nbByPage: number
    nbPage: number
    currentPage: number
    filterBy: FilterBy
    sortBy: Array<SortBy>
    pages: Data<Page>
    count: number
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: string | undefined
}

interface SubStore<Matrix> {
    data: Data<Matrix>
    singleMatrix: Matrix | undefined
    activeMatrix: Matrix | undefined
    pagination: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    deleteStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postAndPutStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    deleteError: string | undefined
    postAndPutError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    defaultFilters: Data<FilterBy>
    activeMatrixFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed',
    activeMatrixFetchError:  string | undefined
}
export interface State {
    matrix: SubStore<Matrix>
}

const initialState = {
    matrix: { 
        data: {},
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filter: undefined,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined,
        singleFetchStatus: 'idle',
        singleFetchError: undefined,
        defaultFilters: {
            recent: {
                date: {gte: moment().subtract(2, "months").format("YYYY-MM-DD") }
            }
        },
        singleMatrix: undefined,
        activeMatrix: undefined,
        activeMatrixFetchStatus: 'idle',
        activeMatrixFetchError: undefined,

    }
} as State

export const fetchLastActiveMatrixVersion = createAsyncThunk(
    'matrix/fetchLastActiveMatrixVersion', 
    async (args: {page: number, company_name: string, export_code: string}, thunkApi) => {
        const globalState: any = thunkApi.getState()

        const pagination: Pagination = globalState.matrix.matrix.pagination 

        const filter = {...pagination.filterBy, matrix_status: {in: [StatusMatrix.active, StatusMatrix.archived]}, company_name: {like: args.company_name}, export_code: {like: args.export_code}}

        const response = await MatrixApi.getInstance().getMatrices(filter, [{name: "version", direction: "-"}], pagination.nbByPage, (args.page-1)*pagination.nbByPage)
        const activeMatrix = response.count > 0 ? response.data[0] : undefined

        return activeMatrix
    }
)

export const fetchMatrices = createAsyncThunk(
    'matrix/fetchMatrices', 
    async (args: {page: number}, thunkApi) => {
        const globalState: any = thunkApi.getState()

        const pagination: Pagination = globalState.matrix.matrix.pagination 

        const response = await MatrixApi.getInstance().getMatrices(pagination.filterBy, pagination.sortBy, pagination.nbByPage, (args.page-1)*pagination.nbByPage)
        return {curPage: args.page, ...response} as {count: number, curPage: number, data: Array<Matrix>}
    }
)

export const fetchSingleMatrice = createAsyncThunk(
    'matrix/fetchSingleMatrices', 
    async ({companyId, exportCode, version}: {companyId: string, exportCode: string, version: string}) => {

        const response = await MatrixApi.getInstance().getMatrix(Number(companyId), exportCode, Number(version))
        return response as Matrix
    }
)

export const fetchSingleMatriceSilent = createAsyncThunk(
    'matrix/fetchSingleMatriceSilent', 
    async ({companyId, exportCode, version}: {companyId: string, exportCode: string, version: string}) => {

        const response = await MatrixApi.getInstance().getMatrix(Number(companyId), exportCode, Number(version))
        return response as Matrix
    }
)

export const deleteMatrices = createAsyncThunk(
    'matrix/deleteMatrices',
    async ({body}: {body: BodyDeleteMatrices}, {dispatch}) => {
        await MatrixApi.getInstance().deleteMatrices(body)
        return body
    }
)

export const archiveMatrix = createAsyncThunk(
    'matrix/archiveMatrix',
    async ({companyId, exportCode, version}: {companyId: number, exportCode: string, version: number}, {dispatch}) => {
        const response = await MatrixApi.getInstance().updateMatrixStatus(companyId, exportCode, version, 'Archived')
        dispatch(archiveMatrixReducer(response))
        return response
    }
)

export const downloadMatrices = createAsyncThunk(
    'matrix/dowloadMatrices',
    async ({jobRequest}: {jobRequest: FormData}) => {
        const response = await MatrixApi.getInstance().postMatrixJob(jobRequest)
        return response
    }
)

const matrixSlice = createSlice({
    name: 'matrix',
    initialState,
    reducers: {
        matrixReducer(state: State, action: PayloadAction<Matrix>) {
            const item = action.payload
            if (item) {
                state.matrix.data[getKeyForMatrix(item)] = item
            }
            state.matrix.postAndPutStatus = 'succeeded'
        },
        updateMatrixResults(state: State, action: PayloadAction<{matrix: Matrix, results: any}>) {
            const item = action.payload
            if (item) {
                const matrixResponse = {...item.matrix}
                matrixResponse.results = item.results
                state.matrix.data[getKeyForMatrix(item.matrix)] = matrixResponse
            }
            state.matrix.postAndPutStatus = 'succeeded'
        },
        archiveMatrixReducer(state: State, action: PayloadAction<Matrix>) {
            const item = action.payload
            if (item) {
                state.matrix.data[getKeyForMatrix(item)] = item
            }
        },
        refreshMatrix(state: State){
            state.matrix.data = {}
            state.matrix.singleMatrix = undefined
            state.matrix.activeMatrix = undefined
            state.matrix.fetchStatus = 'idle'
            state.matrix.singleFetchStatus = 'idle'
            state.matrix.activeMatrixFetchStatus = 'idle'
            state.matrix.deleteStatus = 'idle'
            state.matrix.pagination.status = 'idle'
        },
        filterByReducer(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.matrix.pagination.filterBy = filterBy
            state.matrix.pagination.pages = {}
            state.matrix.pagination.currentPage = 1
            state.matrix.pagination.status = 'idle'
        },
        addFilterByClause(state: State, action: PayloadAction<{name: string, filter: Filter}>) {
            if (Object.keys(action.payload.filter).length > 0) {
                state.matrix.pagination.filterBy[action.payload.name] = action.payload.filter
            } else {
                delete state.matrix.pagination.filterBy[action.payload.name]
            }
            state.matrix.pagination.pages = {}
            state.matrix.pagination.currentPage = 1
            state.matrix.pagination.status = 'idle'
        },
        removeFilterByClauseByName(state: State, action: PayloadAction<string>) {
            delete state.matrix.pagination.filterBy[action.payload]
            state.matrix.pagination.pages = {}
            state.matrix.pagination.currentPage = 1
            state.matrix.pagination.status = 'idle'
        },
        sortByReducer(state: State, action: PayloadAction<Array<SortBy>>) {
            state.matrix.pagination.sortBy = action.payload
            state.matrix.pagination.pages = {}
            state.matrix.pagination.currentPage = 1
            state.matrix.pagination.status = 'idle'
        },
        addSortByClause(state: State, action: PayloadAction<SortBy>) {
            const index = state.matrix.pagination.sortBy.findIndex(item => item.name === action.payload.name)
            if (index > -1) {
                state.matrix.pagination.sortBy[index] = action.payload
            } else {
                state.matrix.pagination.sortBy.push(action.payload)
            }
            state.matrix.pagination.pages = {}
            state.matrix.pagination.currentPage = 1
            state.matrix.pagination.status = 'idle'
        },
        removeSortByClauseByName(state: State, action: PayloadAction<string>) {
            const index = state.matrix.pagination.sortBy.findIndex(item => item.name === action.payload)
            if (index > -1) {
                state.matrix.pagination.sortBy.splice(index, 1)
            }
            state.matrix.pagination.pages = {}
            state.matrix.pagination.currentPage = 1
            state.matrix.pagination.status = 'idle'
        },
        filterReducer(state: State, action: PayloadAction<string|undefined>) {
            if (action.payload === "" || action.payload === undefined) {
                delete state.matrix.pagination.filterBy.all
            } else {
                state.matrix.pagination.filterBy.all = { like: action.payload }
            }
            state.matrix.pagination.status = 'idle'
        },
        requestPage(state: State, action: PayloadAction<number>) {
            state.matrix.pagination.currentPage = action.payload
        },
        setNbByPage(state: State, action: PayloadAction<number>) {
            state.matrix.pagination.nbByPage = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchLastActiveMatrixVersion.pending, (state) => {

            state.matrix.activeMatrixFetchStatus = "loading";
        });
        builder.addCase(fetchLastActiveMatrixVersion.fulfilled, (state, action) => {

            state.matrix.activeMatrix = action.payload;
            state.matrix.activeMatrixFetchStatus = "succeeded";
        });
        builder.addCase(fetchLastActiveMatrixVersion.rejected, (state, action) => {
            state.matrix.activeMatrixFetchStatus = "failed";
            state.matrix.activeMatrixFetchError = action.error.message;
        });
        builder.addCase(fetchMatrices.pending, (state) => {
            state.matrix.fetchStatus = 'loading'
            state.matrix.pagination.status = 'loading'
        })
        builder.addCase(fetchMatrices.fulfilled, (state, action) => {
            if(typeof action.payload !== "string"){
                action.payload.data.forEach((item: Matrix) => {
                    if (item.company_id && item.export_code && item.version) {
                        state.matrix.data[getKeyForMatrix(item)] = item
                    }
                })

                const count = action.payload.count
                state.matrix.pagination.count = count
                state.matrix.pagination.nbPage = Math.ceil(count / state.matrix.pagination.nbByPage)

                state.matrix.pagination.pages[action.payload.curPage] = {
                    ids: action.payload.data.map(item => ({company_id: item.company_id, export_code: item.export_code, version: item.version})),
                    fetching: false
                }
                state.matrix.pagination.currentPage = action.payload.curPage                
            }
            state.matrix.pagination.status = 'succeeded'
            state.matrix.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchMatrices.rejected, (state, action) => {
            state.matrix.fetchStatus = 'failed'
            state.matrix.fetchError = action.error.message
            state.matrix.pagination.status = 'failed'
        })
        builder.addCase(deleteMatrices.pending, (state) => {
            state.matrix.deleteStatus = 'loading'
        })
        builder.addCase(deleteMatrices.fulfilled, (state, action) => {
            if(typeof action.payload !== "string"){
                action.payload.matrices.forEach((item: BodyDeleteMatrix) => {
                    if (action.payload.company_id && item.export_code && item.version) {
                        const id = state.matrix.pagination.pages[state.matrix.pagination.currentPage].ids.filter((id) => id.company_id === action.payload.company_id && id.export_code === item.export_code && id.version === item.version)[0]
                        const index = state.matrix.pagination.pages[state.matrix.pagination.currentPage].ids.indexOf(id)
                        state.matrix.pagination.pages[state.matrix.pagination.currentPage].ids.splice(index, 1)
                        delete state.matrix.data[`${action.payload.company_id}#${item.export_code}#${item.version}`]
                    }
                })             
            }
            state.matrix.deleteStatus = 'succeeded'
        })
        builder.addCase(deleteMatrices.rejected, (state, action) => {
            state.matrix.deleteStatus = 'failed'
            state.matrix.deleteError = action.error.message
        })
        builder.addCase(fetchSingleMatrice.pending, (state) => {

            state.matrix.singleFetchStatus = "loading";
        });
        builder.addCase(fetchSingleMatrice.fulfilled, (state, action) => {
            const item: Matrix = action.payload
            if (item.id) {
                state.matrix.data[item.id] = item;
            }
            state.matrix.singleMatrix = item
            state.matrix.singleFetchStatus = "succeeded";
        });
        builder.addCase(fetchSingleMatrice.rejected, (state, action) => {
            state.matrix.singleFetchStatus = "failed";
            state.matrix.singleFetchError = action.error.message;
        });
        builder.addCase(fetchSingleMatriceSilent.fulfilled, (state, action) => {
            const item: Matrix = action.payload
            if (item.id) {
                state.matrix.data[item.id] = item;
            }
        });
    }
  })



export const {
    matrixReducer,
    updateMatrixResults,
    archiveMatrixReducer,
    refreshMatrix,
    filterByReducer,
    addFilterByClause,
    removeFilterByClauseByName,
    sortByReducer,
    addSortByClause,
    removeSortByClauseByName,
    filterReducer,
    requestPage,
    setNbByPage
} = matrixSlice.actions

export default matrixSlice.reducer