import { createSlice, PayloadAction } from '@reduxjs/toolkit'


interface FooterStateForMobile {
    footerState: boolean
    
}

interface CurrentState {
    footerStateForMobile: FooterStateForMobile
    hideFooter: boolean
 
}

const initialState = {
    footerStateForMobile: {
        footerState: false },
    hideFooter: false
    } as CurrentState


const footerSlice = createSlice({
    name: 'footerState',
    initialState,
    reducers: {
        updateStateFooter(state, action: PayloadAction<FooterStateForMobile>) {
            state.footerStateForMobile = action.payload
        },
        updateHideStateFooter(state, action: PayloadAction<boolean>) {
            state.hideFooter = action.payload
        },
    }
  })

  export const {
    updateStateFooter,
    updateHideStateFooter
  } = footerSlice.actions

  export default footerSlice.reducer