import { CurrentUser } from "../../state/authUserSlice"
import { Data } from "../../types/types"
import isNumeric from 'is-number'
import SubErrorDetails from "./exceptions/SubErrorDetails"
import { t } from "i18next"

export type Rename<T, K extends keyof T, N extends string> = Pick<T, Exclude<keyof T, K>> & { [P in N]: T[K] }


export const buildSelectSmallStyle = (isTouched?: boolean, isError?: boolean): Data<(styles: Data<string>, state: Data<boolean | string | number>) => unknown> => {
    return ({
        control: (styles: Data<string>, state: Data<boolean | string | number>) => ({
            ...styles,
            borderColor: (isTouched && isError) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
            boxShadow: state.isFocused ? (isTouched && isError) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
            '&:hover': {
                borderColor: (isTouched && isError) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                boxShadow: state.isFocused ? (isTouched && isError) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
            },
            minHeight: '30px',
            height: '30px',

        }),
        valueContainer: (styles: Data<string>) => ({
            ...styles,
            height: '30px',
            padding: '0 6px'
        }),
        singleValue: (styles: Data<string>) => ({
            ...styles,
            paddingBottom: '3px'
        }),
        input: (styles: Data<string>) => ({
            ...styles,
            margin: '0px',
        }),
        indicatorsContainer: (styles: Data<string>) => ({
            ...styles,
            height: '30px',
        }),
        dropdownIndicator: (styles: Data<string>) => ({
            ...styles,
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 7,
            paddingBottom: 7,
        }),
        clearIndicator: (styles: Data<string>) => ({
            ...styles,
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 7,
            paddingBottom: 7,
        }),
        menu: (styles: Data<string>) => ({
            ...styles,
            zIndex: '1031',
            minWidth: '300px'
        }),

        option: (styles: Data<string>) => ({
            ...styles,
            textAlign: 'left'
        })
    })
}

export const buildSelectSmallStyleWithSmallMenu = (isTouched?: boolean, isError?: boolean): Data<(styles: Data<string>, state: Data<boolean | string | number>) => unknown> => {
    return ({
        control: (styles: Data<string>, state: Data<boolean | string | number>) => ({
            ...styles,
            borderColor: (isTouched && isError) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
            boxShadow: state.isFocused ? (isTouched && isError) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
            '&:hover': {
                borderColor: (isTouched && isError) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                boxShadow: state.isFocused ? (isTouched && isError) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
            },
            minHeight: '30px',
            height: '30px',

        }),
        valueContainer: (styles: Data<string>) => ({
            ...styles,
            height: '30px',
            padding: '0 6px'
        }),
        singleValue: (styles: Data<string>) => ({
            ...styles,
            paddingBottom: '3px'
        }),
        input: (styles: Data<string>) => ({
            ...styles,
            margin: '0px',
        }),
        indicatorsContainer: (styles: Data<string>) => ({
            ...styles,
            height: '30px',
        }),
        dropdownIndicator: (styles: Data<string>) => ({
            ...styles,
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 7,
            paddingBottom: 7,
        }),
        clearIndicator: (styles: Data<string>) => ({
            ...styles,
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 7,
            paddingBottom: 7,
        }),
        menu: (styles: Data<string>) => ({
            ...styles,
            zIndex: '1031',
            minWidth: '50px'
        }),

        option: (styles: Data<string>) => ({
            ...styles,
            textAlign: 'left'
        })
    })
}

export const buildSelectNormalStyle = (isTouched?: boolean, isError?: boolean): Data<(styles: Data<string>, state: Data<boolean | string | number>) => unknown> => {
    return ({
        control: (styles: Data<string>, state: Data<string|number|boolean>) => ({
            ...styles,
            borderColor: (isTouched && isError) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
            boxShadow: state.isFocused ? (isTouched && isError) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
            '&:hover': {
                borderColor: (isTouched && isError) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                boxShadow: state.isFocused ? (isTouched && isError) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
            },
        }),
        option: (base: Data<string>) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: 25
        }),
        menu: (styles: Data<string>) => ({
            ...styles,
            zIndex: '9999',
            width: '100%',
            marginLeft: '0%'
        })
    })
}

export const buildSelectNormalStyleWithColor = (isTouched?: boolean, isError?: boolean): Data<(styles: Data<string>, state: Data<boolean | string | number>) => unknown> => {
    return ({
        control: (styles: Data<string>, state: Data<string|number|boolean>) => ({
            ...styles,
            borderColor: (isTouched && isError) ? '#dc3545' : "rgb(var(--bs-secondary-rgb))",
            boxShadow: state.isFocused ? (isTouched && isError) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
            '&:hover': {
                borderColor: (isTouched && isError) ? '#dc3545' : "rgb(var(--bs-secondary-rgb))",
                boxShadow: state.isFocused ? (isTouched && isError) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
            },
        }),
        option: (base: Data<string>) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: 25
        }),
        menu: (styles: Data<string>) => ({
            ...styles,
            zIndex: '9999',
            width: '100%',
            marginLeft: '0%'
        })
    })
}

export async function dataURLtoFile(dataurl : any, filename:any) {
    const base64 = await fetch(dataurl)
    const blob = await base64.blob()
    return new File([blob], filename, {type: blob.type})
}

export function printPotentialNumber(idAnalysis: number,displayId: string, authUser: CurrentUser){
    return idAnalysis.toString() === displayId ? Intl.NumberFormat(authUser.locale).format(idAnalysis) : displayId
}

export const buildImportPrefix = (e: SubErrorDetails) => {
    let prefix = ''
    if (e.path && e.path.length > 0) {
        const parsedPath = e.path.split(':')
        prefix += t('errors.import.prefix1', { line: parsedPath[0], column: parsedPath[1] })
        if (parsedPath.length > 2) {
            prefix += t('errors.import.prefix2', { field: parsedPath[2] })
        }
        prefix += ' - '
    }
    return prefix
}

export default {buildSelectSmallStyle, buildSelectSmallStyleWithSmallMenu, buildSelectNormalStyle, dataURLtoFile, printPotentialNumber}