import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import i18n from '../i18n';
import moment from 'moment';

import AnalysisApi, {Analysis, AnalysisInputResult, StatusLabel} from '../api/analysisApi';
import { FilterBy, SortBy, Filter, Data, TupleId } from '../types/types';

interface Page {
    ids: Array<TupleId>
    fetching: boolean
}

interface Pagination {
    nbByPage: number
    nbPage: number
    currentPage: number
    filterBy: FilterBy
    sortBy: Array<SortBy>
    pages: Data<Page>
    count: number
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: string | undefined
}

export enum StatusAnalysis {
    registered = "registered",
    received_fixed = "received_fixed",
    received_compliant = "received_compliant",
    received_improper = "received_improper",
    canceled = "canceled",
    partial = "partial",
    done = "done"
}

interface SubStore<Analysis> {
    data: Data<Data<Analysis>>
    dataByDisplayedId: Data<Data<Analysis>>
    pagination: Pagination
    resultsFiltered: Array<string>
    nbInvalid: number
    nbResults: number
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    deleteStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postAndPutStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    deleteError: string | undefined
    postAndPutError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    fetchInvalidStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchInvalidError: string | undefined
    resultsFilter: {
        filterBy: FilterBy
        sortBy: Array<SortBy>
    }
    selectedAnalysisResults : Analysis | undefined
    defaultFilters: Data<FilterBy>
    inputResultsFiltered : Array<string>
    inputResultsFilter: {
        filterBy: FilterBy
        sortBy: Array<SortBy>
    }
}
export interface State {
    analysis: SubStore<Analysis>
}

const initialState = {
    analysis: { 
        data: {},
        dataByDisplayedId: {},
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filter: undefined,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        resultsFiltered: [],
        inputResultsFiltered: [],
        nbInvalid: 0,
        nbResults: 0,
        fetchStatus: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined,
        singleFetchStatus: 'idle',
        singleFetchError: undefined,
        fetchInvalidStatus: 'idle',
        fetchInvalidError: undefined,
        resultsFilter: {
            filterBy: {},
            sortBy: []
        },
        inputResultsFilter: {
            filterBy: {},
            sortBy: []
        },
        selectedAnalysisResults: undefined,
        defaultFilters: {
            recent: {
                date: {gte: moment().subtract(2, "months").format("YYYY-MM-DD") }
            },
            registered: {
                date: {gte: moment().subtract(1, "months").format("YYYY-MM-DD") },
                status: {
                    in: ["registered"] 
                }
            },
            inprogress: {
                date: {gte: moment().subtract(2, "months").format("YYYY-MM-DD") },
                status: {
                    in: ["received_compliant"] 
                }
            },
            done: {
                status: {
                    in: ["partial", "done"] 
                }
            },
            recentDone: {
                status: {
                    in: ["partial", "done"] 
                },
                date: {gte: moment().subtract(2, "months").format("YYYY-MM-DD") }
            },
            invalid: {
                status: {
                    in: ["received_improper"] 
                }
            }
        }
    }
} as State

export const fetchAnalysis = createAsyncThunk(
    'analysis/fetchAnalysis', 
    async (args: {page: number}, thunkApi) => {/* *|MARCADOR_CURSOR|* */
    
        const globalState: any = thunkApi.getState()

        const pagination: Pagination = globalState.analysis.analysis.pagination

        const response = await AnalysisApi.getInstance().getAnalysis(pagination.filterBy, pagination.sortBy, pagination.nbByPage, (args.page-1)*pagination.nbByPage)
        return {curPage: args.page, ...response} as {result_count: number,count: number, curPage: number, data: Array<Analysis>}
    }
)

export const fetchSingleAnalysis = createAsyncThunk(
    'analysis/fetchSingleAnalysis', 
    async ({laboratoryId, analysisId}: {laboratoryId: number, analysisId: number}) => {
        const response = await AnalysisApi.getInstance().getAnalysisById(laboratoryId, analysisId)
        return response as Analysis
    }
)

export const fetchSingleAnalysisDisplayedById = createAsyncThunk(
    'analysis/fetchSingleAnalysisDisplayedById', 
    async ({laboratoryId, displayId}: {laboratoryId: number, displayId: string}) => {
        const response = await AnalysisApi.getInstance().getAnalysisByDisplayedId(laboratoryId, displayId)
        return response as Analysis
    }
)

export const fetchInvalidAnalysis = createAsyncThunk(
    'analysis/fetchInvalidAnalysis', 
    async (args, thunkApi) => {
        const filterBy = {
            status: {
                in: ["received_improper"]
            }
        }

        const response = await AnalysisApi.getInstance().getAnalysis(filterBy, [], 25, 0)
        return response.count as number
    }
)

export const updateInputResult = createAsyncThunk(
    'analysis/updateInputResult',
    async ({ laboratory_id, analysis_id, input_analysis_results }: { laboratory_id: number, analysis_id: number, input_analysis_results: AnalysisInputResult }, { dispatch }) => {
        const response = await AnalysisApi.getInstance().updateInputResult(laboratory_id, analysis_id, input_analysis_results);
        dispatch(inputResultReducer({ laboratory_id: laboratory_id, analysis_id : analysis_id, input_analysis_results: response }))
        return response
        
    }
)

const analysisSlice = createSlice({
    name: 'analysis',
    initialState,
    reducers: {
        inputResultReducer(state: State, action: PayloadAction<{ laboratory_id: number, analysis_id: number, input_analysis_results: AnalysisInputResult }>) {
            const laboratoryId = action.payload.laboratory_id
            const analysisId = action.payload.analysis_id
            const inputAnalysisResult = action.payload.input_analysis_results
            const inputResults = state.analysis.data[laboratoryId][analysisId].input_analysis_results
            
            const indexInput = inputResults?.findIndex((input ) => inputAnalysisResult.code_analyte === input.code_analyte && inputAnalysisResult.code_test === input.code_test)
            if(indexInput !== undefined && indexInput >= 0){
                inputResults![indexInput] = inputAnalysisResult
            }else{
                inputResults?.push(inputAnalysisResult)
            }
            
        },
        analysisReducer(state: State, action: PayloadAction<Analysis>) {
            const item = action.payload
            if (item) {
                if (!state.analysis.data[item.laboratory_id]) {
                    state.analysis.data[item.laboratory_id] = {}
                }
                state.analysis.data[item.laboratory_id][item.id] = item

                if (state.analysis.selectedAnalysisResults && state.analysis.selectedAnalysisResults.laboratory_id === item.laboratory_id && state.analysis.selectedAnalysisResults.id === item.id){ 
                    state.analysis.selectedAnalysisResults = state.analysis.data[item.laboratory_id][item.id]
                }
            }
                state.analysis.postAndPutStatus = 'succeeded'
        },
        refreshAnalysis(state: State){
            state.analysis.data = {}
            state.analysis.fetchStatus = 'idle'
            state.analysis.singleFetchStatus = 'idle'
        },
        filterByReducer(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            delete state.analysis.selectedAnalysisResults
            state.analysis.pagination.filterBy = filterBy
            state.analysis.pagination.pages = {}
            state.analysis.pagination.currentPage = 1
            state.analysis.pagination.status = 'idle'
        },
        addFilterByClause(state: State, action: PayloadAction<{name: string, filter: Filter}>) {
            if (Object.keys(action.payload.filter).length > 0) {
                state.analysis.pagination.filterBy[action.payload.name] = action.payload.filter
            } else {
                delete state.analysis.pagination.filterBy[action.payload.name]
            }
            state.analysis.pagination.pages = {}
            state.analysis.pagination.currentPage = 1
            state.analysis.pagination.status = 'idle'
        },
        removeFilterByClauseByName(state: State, action: PayloadAction<string>) {
            delete state.analysis.pagination.filterBy[action.payload]
            state.analysis.pagination.pages = {}
            state.analysis.pagination.currentPage = 1
            state.analysis.pagination.status = 'idle'
        },
        sortByReducer(state: State, action: PayloadAction<Array<SortBy>>) {
            state.analysis.pagination.sortBy = action.payload
            delete state.analysis.selectedAnalysisResults
            state.analysis.pagination.pages = {}
            state.analysis.pagination.currentPage = 1
            state.analysis.pagination.status = 'idle'
        },
        addSortByClause(state: State, action: PayloadAction<SortBy>) {
            const index = state.analysis.pagination.sortBy.findIndex(item => item.name === action.payload.name)
            if (index > -1) {
                state.analysis.pagination.sortBy[index] = action.payload
            } else {
                state.analysis.pagination.sortBy.push(action.payload)
            }
            state.analysis.pagination.pages = {}
            state.analysis.pagination.currentPage = 1
            state.analysis.pagination.status = 'idle'
        },
        removeSortByClauseByName(state: State, action: PayloadAction<string>) {
            const index = state.analysis.pagination.sortBy.findIndex(item => item.name === action.payload)
            if (index > -1) {
                state.analysis.pagination.sortBy.splice(index, 1)
            }
            state.analysis.pagination.pages = {}
            state.analysis.pagination.currentPage = 1
            state.analysis.pagination.status = 'idle'
        },
        filterReducer(state: State, action: PayloadAction<string|undefined>) {
            if (action.payload === "" || action.payload === undefined) {
                delete state.analysis.pagination.filterBy.all
            } else {
                state.analysis.pagination.filterBy.all = { like: action.payload }
            }
            state.analysis.pagination.status = 'idle'
        },
        requestPage(state: State, action: PayloadAction<number>) {
            state.analysis.pagination.currentPage = action.payload
        },
        setNbByPage(state: State, action: PayloadAction<number>) {
            state.analysis.pagination.nbByPage = action.payload
        },
        singleFilterByReducer(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.analysis.resultsFilter.filterBy = filterBy
        },
        singleSortByReducer(state: State, action: PayloadAction<Array<SortBy>>) {
            state.analysis.resultsFilter.sortBy = action.payload
        },
        selectedAnalysisResultsReducer(state: State, action: PayloadAction<Analysis>){
            state.analysis.selectedAnalysisResults = action.payload
        },
        deleteAnalysisResultsReducer(state: State){
            delete state.analysis.selectedAnalysisResults
        },
        setResultsFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.analysis.resultsFiltered = action.payload
        },
        resetNbResults(state: State) {
            state.analysis.nbResults = 0
        },
        setInputResultsFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.analysis.inputResultsFiltered = action.payload
        },
        inputResultsFilterByReducer(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.analysis.inputResultsFilter.filterBy = filterBy
        },
        inputResultsSortByReducer(state: State, action: PayloadAction<Array<SortBy>>) {
            state.analysis.inputResultsFilter.sortBy = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchAnalysis.pending, (state) => {
            state.analysis.fetchStatus = 'loading'
            state.analysis.pagination.status = 'loading'
        })
        builder.addCase(fetchAnalysis.fulfilled, (state, action) => {
            if(typeof action.payload !== "string"){
                action.payload.data.forEach((item: Analysis) => {
                    if (item.id) {
                        if (!state.analysis.data[item.laboratory_id]) {
                            state.analysis.data[item.laboratory_id] = {}
                        }
                        state.analysis.data[item.laboratory_id][item.id] = item
                    }
                })

                const count = action.payload.count
                state.analysis.pagination.count = count
                state.analysis.pagination.nbPage = Math.ceil(count / state.analysis.pagination.nbByPage)

                state.analysis.pagination.pages[action.payload.curPage] = {
                    ids: action.payload.data.map(item => ({labId: String(item.laboratory_id), id: String(item.id)})),
                    fetching: false
                }
                state.analysis.pagination.currentPage = action.payload.curPage

                if(action.payload.result_count){
                    state.analysis.nbResults = action.payload.result_count
                }
                else{
                    state.analysis.nbResults = 0
                }
                
            }
            state.analysis.pagination.status = 'succeeded'
            state.analysis.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchAnalysis.rejected, (state, action) => {
            state.analysis.fetchStatus = 'failed'
            state.analysis.fetchError = action.error.message
            state.analysis.pagination.status = 'failed'
        })

        builder.addCase(fetchSingleAnalysis.pending, (state) => {
            state.analysis.singleFetchStatus = 'loading'
        })
        builder.addCase(fetchSingleAnalysis.fulfilled, (state, action) => {
            const item = action.payload
            if (item) {
                if (!state.analysis.data[item.laboratory_id]) {
                    state.analysis.data[item.laboratory_id] = {}
                }
                state.analysis.data[item.laboratory_id][item.id] = item
            }

            if (state.analysis.selectedAnalysisResults && state.analysis.selectedAnalysisResults.id === item.id && state.analysis.selectedAnalysisResults.laboratory_id === item.laboratory_id){
                state.analysis.selectedAnalysisResults = action.payload
            }

            state.analysis.singleFetchStatus = 'succeeded'
        })
        builder.addCase(fetchSingleAnalysis.rejected, (state, action) => {
            state.analysis.singleFetchStatus = 'failed'
            state.analysis.singleFetchError = action.error.message
        })

        builder.addCase(fetchSingleAnalysisDisplayedById.pending, (state) => {
            state.analysis.singleFetchStatus = 'loading'
        })
        builder.addCase(fetchSingleAnalysisDisplayedById.fulfilled, (state, action) => {
            const item = action.payload
            if (item) {
                if (!state.analysis.data[item.laboratory_id]) {
                    state.analysis.data[item.laboratory_id] = {}
                }
                state.analysis.data[item.laboratory_id][item.id] = item

                if (!state.analysis.dataByDisplayedId[item.laboratory_id]) {
                    state.analysis.dataByDisplayedId[item.laboratory_id] = {}
                }
                state.analysis.dataByDisplayedId[item.laboratory_id][item.display_id] = item
                

                if (state.analysis.selectedAnalysisResults && state.analysis.selectedAnalysisResults.id === item.id && state.analysis.selectedAnalysisResults.laboratory_id === item.laboratory_id){
                    state.analysis.selectedAnalysisResults = action.payload
                }
            }
            state.analysis.singleFetchStatus = 'succeeded'
        })
        builder.addCase(fetchSingleAnalysisDisplayedById.rejected, (state, action) => {
            state.analysis.singleFetchStatus = 'failed'
            state.analysis.singleFetchError = action.error.message
        })


        builder.addCase(fetchInvalidAnalysis.pending, (state) => {
            state.analysis.fetchInvalidStatus = 'loading'
        })
        builder.addCase(fetchInvalidAnalysis.fulfilled, (state, action) => {
            state.analysis.nbInvalid = action.payload
            state.analysis.fetchInvalidStatus = 'succeeded'
        })
        builder.addCase(fetchInvalidAnalysis.rejected, (state, action) => {
            state.analysis.fetchInvalidError = action.error.message
            state.analysis.fetchInvalidStatus = 'failed'
        })

    }
  })

  export const {
    analysisReducer,
    refreshAnalysis,
    filterByReducer,
    addFilterByClause,
    removeFilterByClauseByName,
    sortByReducer,
    addSortByClause,
    removeSortByClauseByName,
    filterReducer,
    requestPage,
    setNbByPage,
    singleFilterByReducer,
    singleSortByReducer,
    selectedAnalysisResultsReducer,
    setResultsFiltered,
    deleteAnalysisResultsReducer,
    resetNbResults,
    inputResultReducer,
    setInputResultsFiltered,
    inputResultsFilterByReducer,
    inputResultsSortByReducer
  } = analysisSlice.actions

  export default analysisSlice.reducer