import React, { useEffect,useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/rootReducer'
import Container from 'react-bootstrap/Container';
import { useParams } from "react-router-dom";

import Loader from '../../../common/components/Loader';
import CreateMatrix from './components/createMatrix';
import { fetchAllCompanies } from '../../../state/companiesSlice';
import { fetchLaboratories } from '../../../state/configSlice';
import { fetchRefOrigins } from '../../../state/configSlice';
import { fetchIngredients } from '../../../state/configSlice';
import { fetchRefSuppliers } from '../../../state/configSlice';
import { fetchSingleMatrice, refreshMatrix } from '../../../state/matrixSlice';
import { fetchMatrixTemplateById,reinitMatrixTemplates } from '../../../state/matrixTemplateSlice';
import { fetchMatrixTemplateInputsByTemplateId } from '../../../state/matrixTemplateInputSlice';
import { BackupMode } from '../../../api/matrixApi';

type MatrixContainerProps = WithTranslation

interface urlParams {
    mode: BackupMode,
    templateId?: string,
    companyId?: string,
    exportCode?: string,
    version?: string
}   

const MatrixContainer: React.FC<MatrixContainerProps> = ({ t }) => {

    let { mode = "creation", templateId, companyId, exportCode, version } = useParams<urlParams>();      
    exportCode = decodeURIComponent(exportCode!)

    const dispatch = useDispatch()
    const matrixError = useSelector((state: RootState) => state.matrix.matrix.fetchError)
    const companyStatus = useSelector((state: RootState) => state.companies.fetchStatus)
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const refOriginsStatus = useSelector( (state: RootState) => state.config.refOrigins.fetchStatus )
    const refSupplierStatus = useSelector( (state: RootState) => state.config.refSuppliers.fetchStatus )   
    const ingredientsStatus = useSelector((state: RootState) => state.config.ingredients.fetchStatus)

    const matrix = useSelector((state: RootState) => state.matrix.matrix.data)
    const matrixStatus = useSelector((state: RootState) => state.matrix.matrix.singleFetchStatus)
    const matrixTemplatesStatus = useSelector((state: RootState) => state.matrixTemplates.fetchStatus)
    const matrixTemplates = useSelector((state: RootState) => state.matrixTemplates.data)
    const matrixTemplateInputsStatus = useSelector((state: RootState) => state.matrixTemplateInputs.fetchStatus)

    const [laboratoryId, setLabId] = useState(0)

    useEffect(() => {
        if (companyStatus === 'idle') {
            dispatch(fetchAllCompanies())        
        }       
        if (laboratoriesStatus === 'idle') {            
            dispatch(fetchLaboratories())
        }   
        if (ingredientsStatus === 'idle') {
            dispatch(fetchIngredients())
        } 
        if(refOriginsStatus === 'idle') {
            dispatch(fetchRefOrigins())
        }
        if(refSupplierStatus === 'idle') {
            dispatch(fetchRefSuppliers())
        }

        if (matrixStatus === 'idle' && (mode === 'edition' || mode =='update') && companyId && exportCode && version) {
            dispatch(fetchSingleMatrice({ companyId, exportCode, version }))
        }

        if (matrixTemplateInputsStatus === 'idle' && (mode === 'edition' || mode =='update') && templateId) {
            dispatch(fetchMatrixTemplateInputsByTemplateId({ laboratoryId, templateId: templateId }))
        }

        if (templateId) {
            dispatch(fetchMatrixTemplateById({ templateId })) 
        }

    }, [dispatch, companyStatus, laboratoriesStatus, ingredientsStatus, refOriginsStatus, refSupplierStatus, mode, templateId ])   

    useEffect(() => {
        //clear matrix state on mode change
        if (mode === 'creation') {            
            dispatch(refreshMatrix())
            dispatch(reinitMatrixTemplates())
        }
    }, [mode])

    useEffect(() => {
        if (templateId && matrixTemplates[templateId]) {
            setLabId(matrixTemplates[templateId].laboratory_id)
        }
    }, [matrixTemplates])

    useEffect(() => {
        if (laboratoryId && templateId) {
            dispatch(fetchMatrixTemplateInputsByTemplateId({ laboratoryId, templateId }))
        }
    }, [laboratoryId, templateId])

    return (
        <Container>            
            {(companyStatus === 'loading' 
            || laboratoriesStatus === 'loading'
            || (templateId? matrixTemplatesStatus === 'loading' : false) 
            || (templateId? matrixTemplateInputsStatus === 'loading' : false )
            || (templateId? matrixStatus === 'loading' : false)
            ) &&
                <Loader />
            } 
            {companyStatus === 'succeeded' 
                && laboratoriesStatus === 'succeeded'
                && (templateId? matrixTemplateInputsStatus === 'succeeded' : true )
                && (templateId? matrixStatus === 'succeeded' : true) &&
                <Container>                    
                    <CreateMatrix mode={mode} matrixDb={matrix[`${companyId}#${exportCode}#${version}`]} />                   
                </Container>
            }
            {matrixError}           
            {(companyStatus === 'failed' || laboratoriesStatus === 'failed' || matrixStatus === 'failed' || matrixTemplatesStatus === 'failed' || matrixTemplateInputsStatus === 'failed') &&
                <div className="text-danger">{matrixError}</div>
            }
        </Container>
    )
}

export default withTranslation()(MatrixContainer)