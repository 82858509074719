import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ExternalServicesApi, { getKeyForTabAssocNirEquation, NirEquation } from '../api/externalServicesApi';

import { buildDefaultExtraReducersWithFrontPagination, CurrentState, getDefaultReducersWithFrontPagination, getInitialState } from './DefaultSlice';


const initialState = getInitialState<NirEquation>()



export const fetchNirEquations = createAsyncThunk(
    'config/fetchNirEquations', 
    async () => {
        return await ExternalServicesApi.getInstance().getNirEquations() 
    }
)

export const fetchExportNirEquations = createAsyncThunk(
    'config/fetchExportNirEquations', 
    async () => {
       return await ExternalServicesApi.getInstance().exportNirEquations()
    }
)

const addItemToNirEquations =   (state:CurrentState<NirEquation>,item:NirEquation) => {

    if (item.equation && item.nir_type) 
    state.data[getKeyForTabAssocNirEquation(item)] = item
}

const postDeleteNirEquations = (state:CurrentState<NirEquation>,item:NirEquation)=> {
    delete state.data[getKeyForTabAssocNirEquation(item)]
                state.filtered.splice(state.filtered.indexOf(getKeyForTabAssocNirEquation(item)),1)
}


const NirEquationsSlice = createSlice({
    name: 'nir_equations',
    initialState,
     reducers: getDefaultReducersWithFrontPagination(addItemToNirEquations,postDeleteNirEquations)
    ,
    extraReducers: builder => {
            buildDefaultExtraReducersWithFrontPagination(
              addItemToNirEquations,
                builder,
                fetchNirEquations
            )
          
        }
})

export const {
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage
} = NirEquationsSlice.actions

export default NirEquationsSlice.reducer