import { OverlayTrigger, Popover } from "react-bootstrap";
import { Language, TranslationsJson } from "../../api/configApi";
import { ReactComponent as Translated } from 'bootstrap-icons/icons/globe2.svg'
import { useSelector } from "react-redux";
import { RootState } from "../../state/rootReducer";
import React from 'react'


interface CellWithTranslationsProp {
    json_name: TranslationsJson
    id: string,
    headerName: string
    showTranslationInCell?: boolean
    placement?:  ("top" | "right" | "bottom" | "left")
}

const CellWithTranslation: React.FC<CellWithTranslationsProp> = ({ json_name, id, headerName, showTranslationInCell = false, placement = "right" }) => {
    const { language } = useSelector((state: RootState) => state.local)
    const languagesByCode: { [key: string]: Language } = useSelector((state: RootState) => state.config.languages.dataByCode)

    if(json_name && json_name.translations && Object.keys(json_name.translations).filter(key => json_name.translations[key] !== '').length > 0){
        return (
            <OverlayTrigger
                placement={placement}
                overlay={
                    <Popover id={`option-translations-${id}`}>
                        <Popover.Header as="h3">{headerName}</Popover.Header>
                        <Popover.Body>
                            {Object.keys(json_name.translations).filter(key => languagesByCode[key] && json_name.translations[key] !== '').map(key => {
                                return (
                                    <div key={key}>
                                        <span><i><b>{languagesByCode[key].name} : {json_name.translations[key]}</b></i></span>
                                        <br />
                                    </div>
                                )
                            })
                            }
    
                        </Popover.Body>
                    </Popover>
                }
            >
    
                <div className="d-flex justify-content-between">
                    <span>&nbsp;</span>
                    <span> {
                        showTranslationInCell
                            ? json_name.translations[language.locale] ? json_name.translations[language.locale] : json_name.name
                            : json_name.name
                    }
    
                    </span>
                    <span>
                        {Object.values(json_name.translations).length > 0 && <Translated width="16px" height="100%" className="align-text-top" />}
                    </span>
                </div>
    
    
            </OverlayTrigger>
        );
    }else{
        return (    
            <div>
                <span>{json_name?.name || ''}</span>
            </div>
        );
    }
}

export default CellWithTranslation