import { Analyte, Package, Pattern, PriceContainer, PriceListFlat } from "../../../api/configApi"
import { Data } from "../../../types/types"

import { SamplePackage as CPPackage, Sample } from '../../../api/controlPlanApi';
import { IPackages } from "../../../common/components/ModalPackagesTests";


export interface ControlPlanCalculatedInfos {
    nb_test: number
    pattern_price: number | null
    test_prices: number[] | null
    packages_prices: number | null
    all_tests_prices: number | null
    unit_prices: number | null
    pattern_cost: number | null
    test_cost: number[] | null
    package_cost: number | null
    all_test_cost: number | null
    unit_cost: number | null
    sample_packages: CPPackage[]
}

let countCurrency = 0

function getAllPricesAndCosts(pattern: Pattern, priceLists: Data<PriceListFlat>, companyId: number, samplePackages: CPPackage[]): ControlPlanCalculatedInfos {

    const nbTest = [0].concat(samplePackages.map(pVal => pVal.sample_package_tests.length)).reduce((p, c) => p + c);

    const patternPrice = getPatternPrice(priceLists, companyId, pattern);
    const testPrices = samplePackages.filter((pack)=> pack.sample_package_tests.filter((test) => test.price != null).length > 0).length > 0 ? samplePackages.map(t => t.sample_package_tests.filter((test) => test.price != null).reduce((p, c) => p + (c.price || 0), 0)) : null;
    const packagesPrices = samplePackages.filter((pack) => pack.price != null).length > 0 ? samplePackages.filter((pack) => pack.price != null).reduce((p, c) => p + (c.price || 0), 0) :null;
    const allTestsPrices = testPrices != null ? testPrices.reduce((p, c) => p + c, 0) : null;
    let unitPrices: number | null = null
    if (allTestsPrices != null || packagesPrices != null || patternPrice != null) {
        unitPrices = (allTestsPrices || 0) + (packagesPrices || 0) + (patternPrice || 0);
    } 

    const patternCost = pattern.cost != null ? pattern.cost : null;
    const testCost =  samplePackages.filter((pack)=> pack.sample_package_tests.filter((test) => test.cost != null).length > 0).length > 0 ? samplePackages.map(t => t.sample_package_tests.filter((test) => test.cost != null).reduce((p, c) => p + (c.cost || 0), 0)) : null;
    const packagesCost = samplePackages.filter((pack) => pack.cost != null).length > 0 ? samplePackages.filter((pack) => pack.cost != null).reduce((p, c) => p + (c.cost || 0), 0) : null;
    const allTestsCost = testCost != null ? testCost.reduce((p, c) => p + c, 0) : null;
    let unitCost: number | null = null
    if (allTestsCost != null || packagesCost != null || patternCost != null) {
        unitCost = (allTestsCost || 0) + (packagesCost || 0) + (patternCost || 0);
    } 

    return {
        nb_test: nbTest,
        pattern_price: patternPrice,
        test_prices: testPrices,
        packages_prices: packagesPrices,
        all_tests_prices: allTestsPrices,
        unit_prices: unitPrices,
        pattern_cost: patternCost,
        test_cost: testCost,
        package_cost: packagesCost,
        all_test_cost: allTestsCost,
        unit_cost: unitCost,
        sample_packages: samplePackages

    }
}

function getPatternPrice(priceLists: Data<PriceListFlat>, companyId: number, pattern: Pattern) {
    const priceList = Object.values(priceLists).filter(l => l.crit_companies === '*' || l.crit_companies.split(',').includes(String(companyId)));

    const patternPrice = Object.values(pattern.prices).find(p => priceList && priceList.some(pl => pl.id === p.price_list_id))?.price;
    return patternPrice != null ? patternPrice : null;
}

// PACKAGES, PATTERN, TESTS

function getPrice(priceLists: Data<PriceListFlat>, companyId: number, priceContainer: PriceContainer) {
    const priceList = Object.values(priceLists).filter(l => l.crit_companies === '*' || l.crit_companies.split(',').includes(String(companyId)));
    const priceContainerInPriceList = Object.values(priceContainer.prices).filter(p => priceList && priceList.some(pl => pl.id === p.price_list_id))
    priceContainerInPriceList.sort((a, b) => priceList.find((pl) => pl.id === a.price_list_id)!.order < priceList.find((pl) => pl.id === b.price_list_id)!.order ? -1 : 1);
    return priceContainerInPriceList[0]?.price != null ? priceContainerInPriceList[0]?.price : null;
}

function getCurrency(priceLists: Data<PriceListFlat>, companyId: number) {
    const priceList = Object.values(priceLists).filter(l => l.crit_companies === '*' || l.crit_companies.split(',').includes(String(companyId)));
    return priceList.sort((a, b) => a.order < b.order ? -1 : 1)[0]?.currency || "EUR"
}

export default { getAllPricesAndCosts, getPrice, getCurrency }
