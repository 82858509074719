import { useOktaAuth } from '@okta/okta-react';
import { CurrentUser } from "../../state/authUserSlice"

const useSecurityRoles = () => {
    
    const { oktaAuth } = useOktaAuth();
    
    const userIsInternal = () => {
        return userHasRequiredRoles(['MyProvilab_InternalUsers', 'MyProvilab_ROLE_usersAdmin'])
    }
    const userGrantUpdateRegistered = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => 
        checkRolesAndRoleLevels([ 
        'MyProvilab_ROLE_technician',
        'MyProvilab_ROLE_technicianAdmin',
        'MyProvilab_ROLE_quality',
        'MyProvilab_ROLE_salesperson',
        'MyProvilab_ROLE_laboratory',
        'MyProvilab_ROLE_laboratoryAdmin',
        'MyProvilab_ROLE_customerLabAdmin',
        'MyProvilab_ROLE_controlPlan',
        'MyProvilab_ROLE_usersAdmin'
    ], laboratoryId, user)

    const userGrantUpdateReceived = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) =>
        checkRolesAndRoleLevels([
        'MyProvilab_ROLE_salesperson',
        'MyProvilab_ROLE_laboratory',
        'MyProvilab_ROLE_laboratoryAdmin',
        'MyProvilab_ROLE_customerLabAdmin',
        'MyProvilab_ROLE_usersAdmin'
    ], laboratoryId, user)

    const userGrantUpdateDone = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => 
        checkRolesAndRoleLevels([
        'MyProvilab_ROLE_laboratory',
        'MyProvilab_ROLE_laboratoryAdmin',
        'MyProvilab_ROLE_customerLabAdmin',
        'MyProvilab_ROLE_usersAdmin'
    ], laboratoryId, user)

    const userGrantLaboratory = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined)  => checkRolesAndRoleLevels(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'], laboratoryId, user)
    const userGrantLaboratoryAdmin = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => checkRolesAndRoleLevels(['MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'], laboratoryId, user)
    const userControlPlanAdmin = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) =>  checkRolesAndRoleLevels([
        'MyProvilab_ROLE_usersAdmin'
    ], laboratoryId, user) || checkRolesAndPdcLevelsControlPlanAdmin(['MyProvilab_ROLE_controlPlan'], laboratoryId, user)

    const userHasRoleAll = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined)  => (
        checkRolesAndRoleLevels(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'], laboratoryId, user)
    )
    const userHasRoleClient = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined)  => (
        checkRolesAndRoleLevels(['MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_readOnly', 'MyProvilab_ROLE_readOnlyFG', 'MyProvilab_ROLE_readOnlyRM', 'MyProvilab_ROLE_readOnlyFP', 'MyProvilab_ROLE_salesperson', 'MyProvilab_ROLE_controlPlan'], laboratoryId, user)
    )
    const userHasRoleClientButNotCpOrReadOnly = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined)  => (
        checkRolesAndRoleLevels(['MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_readOnlyFG', 'MyProvilab_ROLE_readOnlyRM', 'MyProvilab_ROLE_readOnlyFP', 'MyProvilab_ROLE_salesperson'], laboratoryId, user)
    )
    const userHasRoleUser = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined)  => (
        checkRolesAndRoleLevels(['MyProvilab_ROLE_technician', 'MyProvilab_ROLE_quality'], laboratoryId, user)
    )

    const userHasRoleCancel = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined)  => checkRolesAndRoleLevels(['MyProvilab_ROLE_salesperson', 'MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'], laboratoryId, user)
    const userHasRoleTechnician = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined)  => checkRolesAndRoleLevels(['MyProvilab_ROLE_technician'], laboratoryId, user)
    const userHasRoleReadOnly = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => checkRolesAndRoleLevels(['MyProvilab_ROLE_readOnly'], laboratoryId, user)
    const userHasRoleReadOnlyFG = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => checkRolesAndRoleLevels(['MyProvilab_ROLE_readOnlyFG'], laboratoryId, user)
    const userHasRoleReadOnlyRM = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => checkRolesAndRoleLevels(['MyProvilab_ROLE_readOnlyRM'], laboratoryId, user)
    const userHasRoleReadOnlyFP = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => checkRolesAndRoleLevels(['MyProvilab_ROLE_readOnlyFP'], laboratoryId, user)
    const userHasRoleQuality = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined)  => checkRolesAndRoleLevels(['MyProvilab_ROLE_quality'], laboratoryId, user)
    const userHasRoleAdmin = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => checkRolesAndRoleLevels(['MyProvilab_ROLE_usersAdmin'], laboratoryId, user)
    const userHasRoleAllCp = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => checkRolesAndRoleLevels(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'], laboratoryId, user) || checkRolesAndPdcLevelsControlPlanAdmin(['MyProvilab_ROLE_controlPlan'], laboratoryId, user)
    const userHasRoleClientCp = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => checkRolesAndRoleLevels(['MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_readOnly', 'MyProvilab_ROLE_readOnlyFG', 'MyProvilab_ROLE_readOnlyRM', 'MyProvilab_ROLE_readOnlyFP', 'MyProvilab_ROLE_salesperson', 'MyProvilab_ROLE_controlPlan'], laboratoryId, user)

    const userGrantControlPlan = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => checkRolesAndRoleLevels([ 'MyProvilab_ROLE_controlPlan','MyProvilab_ROLE_usersAdmin', ], laboratoryId, user)
    const userGrantControlPlanAdmin = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => checkRolesAndPdcLevelsControlPlanAdmin([ 'MyProvilab_ROLE_controlPlan','MyProvilab_ROLE_usersAdmin', ], laboratoryId, user)


    const userGrantFodder = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => (
        checkRolesAndRoleLevels(
            ['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_technician', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson'], 
            laboratoryId, user
        )
    )
    const userGrantRawMaterial = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => (
        checkRolesAndRoleLevels(
            ['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_quality', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson'], 
            laboratoryId, user
        )
    )
    const userGrantFeed = (laboratoryId: number | undefined = undefined, user: CurrentUser | undefined = undefined) => (
        checkRolesAndRoleLevels(
            ['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_quality', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson' ], 
            laboratoryId, user
        )
    )

    function userHasRequiredRoles(requiredRoles: Array<string>): boolean {
        try {
            const accessToken = oktaAuth.getAccessToken()
            if (accessToken) {
                const accessDecoded = oktaAuth.token.decode(accessToken)
    
                let userHasRequiredRoles = false
                if (accessDecoded.payload.groups && Array.isArray(accessDecoded.payload.groups)) {
                    userHasRequiredRoles = accessDecoded.payload.groups.some((userGroup) => requiredRoles.includes(userGroup as string))
                }
                return userHasRequiredRoles
            }
        } catch(err) {
            return false
        }
        return false
    }

    function userHasOneRoleLevelLaboratories(user: CurrentUser | undefined): boolean {
        let roleLevelsOk = false
        if (user) {
            roleLevelsOk = user.role_levels.find(r => r.level === 'laboratory') !== undefined        }
        return roleLevelsOk
    }

    function userHasOnePdcLevelAdmin (user: CurrentUser | undefined): boolean {
        let pdcLevelsOk = false
        if (user) {
            pdcLevelsOk = userHasRequiredRoles(['MyProvilab_ROLE_controlPlan']) && user.pdc_levels.find(p => p.level === 'admin') !== undefined        }
        return pdcLevelsOk
    }

    const checkRolesAndRoleLevels = (roles: string[], laboratoryId: number | undefined, user: CurrentUser | undefined): boolean => {
        let ok = userHasRequiredRoles(roles)

        if (ok && laboratoryId && user) {
            if (!userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) {
                let roleLevelsOk = false
                if (userHasRequiredRoles(['MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_controlPlan', 'MyProvilab_ROLE_laboratory'].filter(r => roles.includes(r)))) {
                    roleLevelsOk = user.role_levels.find(r => r.laboratory_id === laboratoryId && r.level === 'laboratory') !== undefined
                }

                if (!roleLevelsOk) {
                    if (userHasRequiredRoles(['MyProvilab_ROLE_technician',
                        'MyProvilab_ROLE_technicianAdmin',
                        'MyProvilab_ROLE_quality',
                        'MyProvilab_ROLE_controlPlan',
                        'MyProvilab_ROLE_salesperson',
                        'MyProvilab_ROLE_readOnly',
                        'MyProvilab_ROLE_readOnlyFG',
                        'MyProvilab_ROLE_readOnlyRM',
                        'MyProvilab_ROLE_readOnlyFP'].filter(r => roles.includes(r)))) {
                        roleLevelsOk = user.role_levels.find(r => r.laboratory_id === laboratoryId) !== undefined
                    }
                }
                ok = ok && roleLevelsOk
            }
        }
        return ok
    }

    const checkRolesAndPdcLevelsControlPlanAdmin = (roles: string[], laboratoryId: number | undefined, user: CurrentUser | undefined): boolean => {
        let ok = userHasRequiredRoles(roles)

        if (ok && laboratoryId && user) {
            if (!userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) {
                let pdcLevelsOk = false
                pdcLevelsOk = user.pdc_levels.find(p => p.laboratory_id === laboratoryId && p.level === 'admin') !== undefined

                ok = ok && pdcLevelsOk
            }
        }
        return ok
    }

    return {
        userIsInternal,
        userHasRoleAdmin,
        userHasRoleQuality,
        userHasRoleAll,
        userHasRoleReadOnly,
        userHasRoleReadOnlyFG,
        userHasRoleReadOnlyRM,
        userHasRoleReadOnlyFP,
        userHasRoleCancel,
        userHasRoleClient,
        userHasRoleTechnician,
        userHasRoleUser,
        userGrantLaboratory,
        userGrantLaboratoryAdmin,
        userControlPlanAdmin,
        userHasRoleClientButNotCpOrReadOnly,
        userGrantUpdateDone,
        userGrantUpdateReceived,
        userGrantUpdateRegistered,
        userHasRoleAllCp,
        userHasRoleClientCp,
        userGrantControlPlan,
        userGrantControlPlanAdmin,
        userGrantFodder,
        userGrantRawMaterial,
        userGrantFeed,
        userHasOneRoleLevelLaboratories,
        userHasOnePdcLevelAdmin
    }
}

export default useSecurityRoles


