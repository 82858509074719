import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sortByReducer } from '../../state/analysisSlice';

const useReduxFilter = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const goToWithFilter = (to: string, filter: any, filterReducer: (filter: any) => void) => {
        dispatch(filterReducer(filter))
        dispatch(sortByReducer([]))
        history.push(to)
    }

    return { goToWithFilter }
}

export default useReduxFilter
