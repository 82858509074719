import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ConfigApi, { BaseTests, BaseTestsKey, getKeyForTabAssocBaseTests} from '../api/configApi';
import {buildDefaultExtraReducersWithFrontPagination, CurrentState, getDefaultReducersWithFrontPagination, getInitialState } from './DefaultSlice';

const initialState = getInitialState<BaseTests>()

// test_categories

export const fetchExportBaseTests = createAsyncThunk(
    'config/fetchExportBaseTests',
    async (lab_id: number) => {
        const response = await ConfigApi.getInstance().exportBaseTests(lab_id)
        return response
    }
)

export const fetchBaseTests = createAsyncThunk(
    'config/fetchBaseTests', 
    async () => {
        const response =  await ConfigApi.getInstance().getAllBaseTests() 
        return response
    }
)

export const fetchSingleBaseTests = createAsyncThunk(
    'analysis/fetchSingleBaseTests', 
    async (baseTestsKey: BaseTestsKey) => {
        const response = await ConfigApi.getInstance().getBaseTests(baseTestsKey.laboratory_id,baseTestsKey.results_pattern_name,baseTestsKey.type_code,baseTestsKey.family_id)
        return response
    }
)


export const deleteBaseTests = createAsyncThunk(
    'config/deleteBaseTests',
    async (baseTests:BaseTestsKey,{ dispatch }) => {
        const response = await ConfigApi.getInstance().deleteBaseTests(baseTests.laboratory_id,baseTests.results_pattern_name,baseTests.type_code,baseTests.family_id)

        if (!response) {
            throw new Error("Unable to delete test category")
        }

        dispatch(deleteReducer(baseTests))


        return true
    }
)

export const upsertBaseTests= createAsyncThunk<BaseTests, {baseTests: BaseTests, baseTestsKey: BaseTestsKey|undefined}>(
    'config/upsertBaseTests', 
    async ({baseTests, baseTestsKey}: {baseTests: BaseTests, baseTestsKey: BaseTestsKey|undefined}, { dispatch }) => { 

        let retBaseTests: BaseTests|null = null

        if (!baseTestsKey) {
            const response = await ConfigApi.getInstance().postBaseTests(baseTests)
            retBaseTests = response
        } else {
                const response = await ConfigApi.getInstance().putBaseTests(baseTests, baseTestsKey)
                retBaseTests = response 
        }
        retBaseTests.id = getKeyForTabAssocBaseTests(retBaseTests)
        dispatch(tableReducer(retBaseTests))
        return retBaseTests
    }
)

const addItemToBaseTests=   (state:CurrentState<BaseTests>,item:BaseTests) => {

    if (item.laboratory_id && item.results_pattern_name && item.type_code && item.family_id) 
    state.data[getKeyForTabAssocBaseTests(item)] = item
}

const postDeleteBaseTests = (state:CurrentState<BaseTests>,item:BaseTestsKey)=> {
    delete state.data[getKeyForTabAssocBaseTests(item)]
                state.filtered.splice(state.filtered.indexOf(getKeyForTabAssocBaseTests(item)),1)
}


const baseTestsSlice = createSlice({
    name: 'base tests',
    initialState,
     reducers: getDefaultReducersWithFrontPagination(addItemToBaseTests,postDeleteBaseTests)
    ,
    extraReducers: builder => {
            buildDefaultExtraReducersWithFrontPagination(
                addItemToBaseTests,
                builder,
                fetchBaseTests
            )
        }
})

export const {
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage
} = baseTestsSlice.actions

export default baseTestsSlice.reducer