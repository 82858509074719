
function dbt(items: any, item_id: number|string, language_id: number, field: string): string {
    let translation: string
    if (items[item_id]) {
        const item = items[item_id]
        if (item.translations && item.translations[language_id] && item.translations[language_id][field+"_translation"]) {
            translation = item.translations[language_id][field+"_translation"]
        } else {
            translation = item[field]
        }
    } else {
        translation = ""
    }
    return translation
}

export default dbt