import React, { useContext } from 'react';
import { ReactComponent as CaretDownFill } from 'bootstrap-icons/icons/caret-down-fill.svg'
import { ReactComponent as CaretUpFill } from 'bootstrap-icons/icons/caret-up-fill.svg'
import { AccordionContext, Button, useAccordionButton } from 'react-bootstrap';


interface IProps {
    eventKey: string
    className?: string
}

const ContextAwareToggle = ({ eventKey, className }: IProps) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => {},
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Button 
            variant="link" 
            className={`d-flex align-item-center text-body ${className}`} 
            onClick={decoratedOnClick} 
        >
            {isCurrentEventKey ?
                <CaretUpFill width="16px" height="100%" />
            :
                <CaretDownFill width="16px" height="100%" />
            }
        </Button>

    );
}

export default ContextAwareToggle