import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/rootReducer'

import Container from 'react-bootstrap/Container';

import Loader from '../../../common/components/Loader'
import AnalysisTable from '../../../common/components/AnalysisTable'

import { fetchFamilies, fetchTypes, fetchPatterns, fetchLaboratories, fetchImpropers } from '../../../state/configSlice'
import { fetchAllCompanies } from '../../../state/companiesSlice'
import { fetchEmployees } from '../../../state/employeesSlice'
import { fetchFarmers } from '../../../state/farmerSlice'
import { fetchAnalysis, fetchSingleAnalysis, filterByReducer, selectedAnalysisResultsReducer } from '../../../state/analysisSlice'
import { fetchNirs } from '../../../state/nirSlice';

import { fetchPdcDA } from '../../../state/pdcSlice';

import { Col, Form, Row } from 'react-bootstrap';
import ResultsTable from '../../../common/components/ResultsTable';
import CustomField from '../analysis/components/components/CustomField';
import { useFormik } from 'formik';
import { fetchFields } from '../../../state/fieldsSlice';
import { fetchForms } from '../../../state/formsSlice';
import { Form as CForm } from '../../../api/configApi';
import { TupleId } from '../../../types/types';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import Footer from '../../../common/components/Footer';
import { useHistory } from 'react-router-dom';
import { printPotentialNumber } from '../../../common/utils/utils';

type ResultsListContainerProps = WithTranslation

const ResultsListContainer: React.FC<ResultsListContainerProps> = ({ t }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { defaultFilters } = useSelector((state: RootState) => state.analysis.analysis)

    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    const selectedAnalysis = useSelector((state: RootState) => state.analysis.analysis.selectedAnalysisResults)

    const { typeLabels } = useSelector((state: RootState) => state.local)

    // fields state
    const fields = useSelector((state: RootState) => state.fields.data)
    
    // forms state
    const forms = useSelector((state: RootState) => state.forms.data)
    
    // Forms & fields state
    const { fetchStatus: formsStatus, fetchError: formsError } = useSelector((state: RootState) => state.forms)
    const { fetchStatus: fieldsStatus, fetchError: fieldsError } = useSelector((state: RootState) => state.fields)

    // types state
    const types = useSelector((state: RootState) => state.config.types.data)

    // families state
    const families = useSelector((state: RootState) => state.config.families.data)
    const familiesStatus = useSelector((state: RootState) => state.config.families.fetchStatus)
    const familiesError = useSelector((state: RootState) => state.config.families.fetchError)

    // Companies state
    const companiesAllStatus = useSelector((state: RootState) => state.companies.fetchAllStatus)
    const companiesError = useSelector((state: RootState) => state.companies.fetchError)

    // Employees state
    const employeesStatus = useSelector((state: RootState) => state.employees.fetchStatus)
    const employeesError = useSelector((state: RootState) => state.employees.fetchError)

    // pdc state
    const pdcStatus = useSelector((state: RootState) => state.pdc.fetchDAStatus)
    const pdcError = useSelector((state: RootState) => state.pdc.fetchError)

    // Types state
    const typesStatus = useSelector((state: RootState) => state.config.types.fetchStatus)
    const typesError = useSelector((state: RootState) => state.config.types.fetchError)

    // Patterns state
    const patternsStatus = useSelector((state: RootState) => state.config.patterns.fetchStatus)
    const patternsError = useSelector((state: RootState) => state.config.patterns.fetchError)

    // Farmers state
    const farmersStatusByUser = useSelector((state: RootState) => state.farmers.farmer.fetchStatusByUser)
    const farmersError = useSelector((state: RootState) => state.farmers.farmer.fetchError)

    // Impropers state
    const impropersStatus = useSelector((state: RootState) => state.config.impropers.fetchStatus)
    const impropersError = useSelector((state: RootState) => state.config.impropers.fetchError)
    
    //  Laboratories state
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const laboratoriesError = useSelector((state: RootState) => state.config.laboratories.fetchError)

    //  Nirs state
    const nirsStatus = useSelector((state: RootState) => state.nir.fetchStatus)
    const nirsError = useSelector((state: RootState) => state.nir.fetchError)

    // Analysis state
    const analysis = useSelector((state: RootState) => state.analysis.analysis.data)
    const fetchStatus = useSelector((state: RootState) => state.analysis.analysis.fetchStatus)
    const fetchError = useSelector((state: RootState) => state.analysis.analysis.fetchError)
    const fetchSingleStatus = useSelector((state:RootState) => state.analysis.analysis.singleFetchStatus)
    const pagination = useSelector((state: RootState) => state.analysis.analysis.pagination)
    
    const [customForm, setCustomForm] = useState<CForm|undefined>(undefined)

    useEffect(() => {
        dispatch(filterByReducer(defaultFilters.recentDone))
        
    }, [history])

    useEffect(() => {
        if (fieldsStatus === 'idle') {
            dispatch(fetchFields())
        }
        if (formsStatus === 'idle') {
            dispatch(fetchForms({}))
        }
        if (pdcStatus === 'idle') {
            dispatch(fetchPdcDA({page: 0}))
        }
        if (employeesStatus === 'idle') {
            dispatch(fetchEmployees())
        }
        if (typesStatus === 'idle') {
            dispatch(fetchTypes())
        }
        if (patternsStatus === 'idle') {
            dispatch(fetchPatterns())
        }
        if (companiesAllStatus === 'idle') {
            dispatch(fetchAllCompanies())
        }
        if (familiesStatus === 'idle') {
            dispatch(fetchFamilies())
        }
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }
        if (nirsStatus === 'idle') {
            dispatch(fetchNirs())
        }
        if (farmersStatusByUser === 'idle') {
            dispatch(fetchFarmers())
        }
        if (impropersStatus === 'idle') {
            dispatch(fetchImpropers())
        }
        if (pdcStatus === 'succeeded'
            && employeesStatus === 'succeeded'
            && typesStatus === 'succeeded'
            && patternsStatus === 'succeeded'
            && familiesStatus === 'succeeded'
            && companiesAllStatus === 'succeeded'
            && farmersStatusByUser === 'succeeded'
            && impropersStatus === 'succeeded'
            && formsStatus === 'succeeded'
            && fieldsStatus === 'succeeded'
            && nirsStatus === 'succeeded'
            && fetchStatus === 'idle') {
            dispatch(fetchAnalysis({page: 1}))
        }

    }, [authUser, analysis, familiesStatus, employeesStatus, fetchStatus, typesStatus, patternsStatus, companiesAllStatus, farmersStatusByUser, pdcStatus, impropersStatus, nirsStatus, dispatch])

    useEffect(() => {
        if (pagination.filterBy && pagination.status === 'idle') {
            dispatch(fetchAnalysis({page: 1}))
        }
        
    }, [dispatch, pagination.filterBy, pagination.sortBy, pagination.status])

    useEffect(() => {
        if(selectedAnalysis && fetchStatus === 'succeeded'){
            dispatch(fetchSingleAnalysis({laboratoryId: selectedAnalysis.laboratory_id, analysisId: selectedAnalysis.id}))
            if(selectedAnalysis.additional_analysis && selectedAnalysis.additional_analysis[0] && selectedAnalysis.additional_analysis[0].ref_laboratory_id && selectedAnalysis.additional_analysis[0].ref_analysis_id){
                dispatch(fetchSingleAnalysis({laboratoryId: selectedAnalysis.additional_analysis[0].ref_laboratory_id, analysisId: selectedAnalysis.additional_analysis[0].ref_analysis_id}))
            }
        }
    }, [fetchStatus])

    useEffect(() => {
        if(fetchStatus === 'succeeded' && pagination.status === 'succeeded' && pagination.pages[pagination.currentPage] && !pagination.pages[pagination.currentPage].fetching){
            const firstAnalysisTupleId: TupleId = pagination.pages[pagination.currentPage].ids[0]
            if(firstAnalysisTupleId
                && !selectedAnalysis
                && analysis[firstAnalysisTupleId.labId][firstAnalysisTupleId.id]
                && (analysis[firstAnalysisTupleId.labId][firstAnalysisTupleId.id].status === 'done' || analysis[firstAnalysisTupleId.labId][firstAnalysisTupleId.id].status === 'partial')){
                dispatch(selectedAnalysisResultsReducer(analysis[firstAnalysisTupleId.labId][firstAnalysisTupleId.id]))
            }
        }
    }, [fetchStatus, pagination.status])
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formikProps = useFormik<any>({
        initialValues: undefined,
        onSubmit: () => undefined
    })

    const { values, setValues } = formikProps

    useEffect(() => {
        if(selectedAnalysis){
            dispatch(fetchSingleAnalysis({laboratoryId: selectedAnalysis.laboratory_id, analysisId: selectedAnalysis.id}))
            if(selectedAnalysis.additional_analysis && selectedAnalysis.additional_analysis[0] && selectedAnalysis.additional_analysis[0].ref_laboratory_id && selectedAnalysis.additional_analysis[0].ref_analysis_id){
                dispatch(fetchSingleAnalysis({laboratoryId: selectedAnalysis.additional_analysis[0].ref_laboratory_id, analysisId: selectedAnalysis.additional_analysis[0].ref_analysis_id}))
            }
        }
    }, [selectedAnalysis?.laboratory_id + '#' + selectedAnalysis?.id])

    useEffect(() => {
        if(selectedAnalysis){
            const currentCustomForm = Object.values(forms).find(f => laboratories[selectedAnalysis.laboratory_id] && f.laboratory_id === laboratories[selectedAnalysis.laboratory_id].id && f.type_code === selectedAnalysis.type_code)
            setCustomForm(currentCustomForm)
            
            let flattenFields: number[] = []
            if (currentCustomForm) {
                // Flatten form's fields to initialize missing values in further steps
                const flattenClientFields = currentCustomForm.fields.client.map(line => line.filter(col => fields[col.field_id].storage_type === "dynamic"))
                    .reduce<number[]>((acc, cur) => {
                        const fieldIds = cur.map(c => c.field_id)
                        return acc.concat(fieldIds)
                    }, [])
                const flattenSampleFields = currentCustomForm.fields.sample.map(line => line.filter(col => fields[col.field_id].storage_type === "dynamic"))
                    .reduce<number[]>((acc, cur) => {
                        const fieldIds = cur.map(c => c.field_id)
                        return acc.concat(fieldIds)
                    }, [])
                flattenFields = flattenClientFields.concat(flattenSampleFields)
            }

            setValues({
                ...selectedAnalysis, 
                custom_values: selectedAnalysis.custom_values.concat(
                    flattenFields.filter(fieldId => !selectedAnalysis.custom_values.map(cv => cv.field_id).includes(fieldId))
                    .map(fieldId => (
                        { field_id: fieldId, value: null }
                    ))
                )
            })
        }
    }, [selectedAnalysis, formsStatus, laboratoriesStatus])
    return (
        <>
            {(pdcStatus === 'loading' || familiesStatus === 'loading' || fetchStatus === 'loading' || typesStatus === 'loading' || patternsStatus === 'loading' || employeesStatus === 'loading' || companiesAllStatus === 'loading' || farmersStatusByUser === 'loading' || impropersStatus === 'loading' || formsStatus === 'loading' || fieldsStatus === 'loading' || laboratoriesStatus === 'loading') &&
                <Loader />
            }
            {pdcStatus === 'succeeded' && familiesStatus === 'succeeded' && fetchStatus === 'succeeded' && typesStatus === 'succeeded' && patternsStatus === 'succeeded' && employeesStatus === 'succeeded' && companiesAllStatus === 'succeeded' && farmersStatusByUser === 'succeeded' && impropersStatus === 'succeeded' && formsStatus === 'succeeded' && fieldsStatus === 'succeeded' && laboratoriesStatus === 'succeeded' &&
                <Container className="analysis-results-container m-0" style={{paddingLeft: "0px"}}>
                    <SplitterLayout vertical={true} percentage={true} secondaryInitialSize={63} customClassName="split-panel-height">
                        <Container className="analysis-results-container pe-4">
                            <AnalysisTable laboratory={laboratories[authUser.laboratories[0]]} />
                        </Container>
                        <Container className="analysis-results-container d-flex align-items-start flex-column p-0" style={{height: "100%"}}>
                            {(fetchSingleStatus === 'loading') &&
                                <Loader isInner={true} />
                            }
                            {selectedAnalysis && values && fetchSingleStatus === 'succeeded' &&
                                <Row className="analysis-results-container ms-auto me-auto pe-4" style={{width: "inherit"}}>
                                    <Col>
                                        <Container className="p-2 bg-white mb-2 rounded-3">
                                            <Row >
                                                <Col lg>
                                                    <h5 className="text-primary fw-bold">
                                                        {t('analysis.ar')} <span className="text-primary fw-bold"> {t('forms.analysis.number')}{printPotentialNumber(selectedAnalysis.id, selectedAnalysis.display_id, authUser)}</span>
                                                    </h5>
                                                </Col>
                                            </Row>
                                        </Container>

                                        <Container className="p-2 bg-white mb-2 rounded-3">
                                            <Row >
                                                <Col lg>
                                                    <h4 className="text-primary fw-bold"> {`${types[selectedAnalysis.type_id] ? types[selectedAnalysis.type_id].name : ''} : ${families[selectedAnalysis.family_id] ? families[selectedAnalysis.family_id].name : ''}`}</h4>
                                                </Col>
                                            </Row>

                                            <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>

                                            {selectedAnalysis.comment &&
                                                <Row>
                                                    <Col>
                                                        <Container className="p-1 mb-2 rounded-3 bg-info">
                                                            <p className="with-linebreak text-black fw-bold fst-italic">{selectedAnalysis.comment} </p>
                                                        </Container>
                                                    </Col>
                                                </Row>
                                            }

                                            {families[selectedAnalysis.family_id] && families[selectedAnalysis.family_id].details_required === true &&
                                                <Row className="mb-2">
                                                    <Col lg="auto">
                                                        <Form.Group controlId="formFamily_details">
                                                            <Form.Label>
                                                                <span className="text-black fw-bold">{t('forms.analysis.' + typeLabels[selectedAnalysis.type_code] + '_detail')}</span> 
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <span className="with-linebreak">{selectedAnalysis.family_details}</span>
                                                    </Col>
                                                </Row>
                                            }

                                            {
                                                customForm && customForm.fields.sample.map((line, lIndex) => {
                                                    return (line.map((col, cIndex) => {
                                                            return (
                                                                <Row key={lIndex + '-' + cIndex}>
                                                                    <CustomField 
                                                                        laboratoryId={selectedAnalysis.laboratory_id} // Current laboratory ID
                                                                        familyId={selectedAnalysis.family_id} // Current family ID
                                                                        formId={customForm.id ?? 0} // Id of the form (used to retrieve matching datalist)
                                                                        fieldId={col.field_id} // ID of the field to display
                                                                        isReadOnly={true} // Boolean to know if field is editable or not
                                                                        isRequired={col.required} // Boolean to know if the field is required or not
                                                                        disabled={false} // Boolean to know if field is disabled
                                                                        formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                                    />
                                                                </Row>
                                                            )
                                                        })
                                                    )
                                                })
                                            }
                                        </Container>
                                    </Col>
                                    <Col lg={9}>
                                        <ResultsTable analysis={analysis[selectedAnalysis.laboratory_id][selectedAnalysis.id]} user={authUser}></ResultsTable>
                                    </Col>
                                </Row>
                            }
                            <Footer enableHidden={false}/>
                        </Container>
                    </SplitterLayout>
                </Container>
            }
            {(pdcStatus === 'failed' || nirsStatus === 'failed' || familiesStatus === 'failed' || fetchStatus === 'failed' || typesStatus === 'failed' || patternsStatus === 'failed' || employeesStatus === 'failed' || companiesAllStatus === 'failed' || farmersStatusByUser === 'failed' || impropersStatus === 'failed' || formsStatus === 'failed' || fieldsStatus === 'failed') &&
                <Container className="mt-2 bg-white text-danger">
                    {laboratoriesError && <p>{t('analysis_list.table.columns.laboratory') + ' - ' + t('forms_errors.unexpected_error') + ' (' + laboratoriesError + ')'}</p>}
                    {familiesError && <p>{t('analysis_list.table.columns.product') + ' - ' + t('forms_errors.unexpected_error') + ' (' + familiesError + ')'}</p>}
                    {typesError && <p>{t('analysis_list.table.columns.echantillon') + ' - ' + t('forms_errors.unexpected_error') + ' (' + typesError + ')'}</p>}
                    {patternsError && <p>{t('analysis_list.table.columns.motif') + ' - ' + t('forms_errors.unexpected_error') + ' (' + patternsError + ')'}</p>}
                    {employeesError && <p>{t('analysis_list.table.columns.applicant') + ' - ' + t('forms_errors.unexpected_error') + ' (' + employeesError + ')'}</p>}
                    {companiesError && <p>{t('analysis_list.table.columns.nameCustomer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + companiesError + ')'}</p>}
                    {farmersError && <p>{t('analysis_list.table.columns.farmer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + farmersError + ')'}</p>}
                    {impropersError && <p>{t('forms.analysis.impropers.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + impropersError + ')'}</p>}
                    {pdcError && <p>{t('analysis_list.table.columns.pdc') + ' - ' + t('forms_errors.unexpected_error') + ' (' + pdcError + ')'}</p>}
                    {formsError && <p>{"forms" + ' - ' + t('forms_errors.unexpected_error') + ' (' + formsError + ')'}</p>}
                    {fieldsError && <p>{"fields" + ' - ' + t('forms_errors.unexpected_error') + ' (' + fieldsError + ')'}</p>}
                    {nirsError && <p>{"nirs" + ' - ' + t('forms_errors.unexpected_error') + ' (' + nirsError + ')'}</p>}
                    {fetchError && <p>{t('analysis_list.see_my_analysis_requests') + ' - ' + t('forms_errors.unexpected_error') + ' (' + fetchError + ')'}</p>}
                </Container>
            }
        </>
    )
}

export default withTranslation()(ResultsListContainer)
