import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import UsersApi, {Farmer} from '../api/usersApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';

interface SubStore<T> {
    data: Data<T>
    byEmployeeId: Data<Array<string>>
    filtered: Array<string>
    pagination: Pagination
    myPagination: Pagination
    fetchStatusByUser: 'idle' | 'loading' | 'succeeded' | 'failed'
    deleteStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postAndPutStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    filterStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    deleteError: string | undefined
    postAndPutError: string | undefined
}

export interface State {
    farmer: SubStore<Farmer>
}

const initialState = {
    farmer: { 
        data: {},
        byEmployeeId: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        myPagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: 'idle',
            error: undefined
        },
        fetchStatusByUser: 'idle',
        deleteStatus: 'idle',
        postAndPutStatus: 'idle',
        filterStatus: 'idle',
        fetchError: undefined,
        deleteError: undefined,
        postAndPutError: undefined
    }
} as State

export const fetchFarmers = createAsyncThunk(
    'farmer/fetchFarmers', 
    async () => {
        const response = await UsersApi.getInstance().getFarmers()
        return response as Array<Farmer>
    }
)


export const fetchExportFarmers = createAsyncThunk<boolean, { company_id: number | null, employee_id: number | null, type : string }>(
    'farmer/fetchExportFarmers', 
    async ({ company_id, employee_id, type }: { company_id: number | null, employee_id: number | null, type : string }) => {
       return await UsersApi.getInstance().exportFarmers(company_id, employee_id, type)
    }
)


const configSlice = createSlice({
    name: 'farmer',
    initialState,
    reducers: {
        deleteFarmerReducer(state: State, action: PayloadAction<Farmer>) {
            const item = action.payload
            if (item.id) {
                delete state.farmer.data[item.id]
                state.farmer.filtered.splice(state.farmer.filtered.indexOf(item.id.toString()), 1)
            } 
            state.farmer.fetchStatusByUser = 'idle'
            state.farmer.deleteStatus = 'succeeded'
        },
        farmerReducer(state: State, action: PayloadAction<Farmer>) {
            const item = action.payload
            if (item && item.id) {
                state.farmer.data[item.id] = item
                
                if (!state.farmer.byEmployeeId[item.employee_id]){
                    state.farmer.byEmployeeId[item.employee_id] = []
                }
                const index = state.farmer.byEmployeeId[item.employee_id].indexOf(item.id.toString())
                if(index === -1){
                    state.farmer.byEmployeeId[item.employee_id].push(item.id.toString())
                }
            }
                state.farmer.postAndPutStatus = 'succeeded'
        },
        setFiltered(state: State, action: PayloadAction<Array<string>>) {
            state.farmer.filtered = action.payload
            state.farmer.filterStatus = 'succeeded'
        },
        setFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.farmer.pagination.filterBy = filterBy
        },
        setSortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.farmer.pagination.sortBy = action.payload
        },
        refreshFilter(state: State) {
            state.farmer.filterStatus = 'idle'
        },
        refreshFarmers(state: State) {
            state.farmer.fetchStatusByUser = 'idle'
        },
        setMyFilterBy(state: State, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.farmer.myPagination.filterBy = filterBy
        },
        setMySortBy(state: State, action: PayloadAction<Array<SortBy>>) {
            state.farmer.myPagination.sortBy = action.payload
        },
    },
    extraReducers: builder => {
        // Languages API call reducers
        builder.addCase(fetchFarmers.pending, (state) => {
            state.farmer.fetchStatusByUser = 'loading'
        })
        builder.addCase(fetchFarmers.fulfilled, (state, action) => {
            state.farmer.byEmployeeId = {}
            action.payload.forEach((item: Farmer) => {
                if (item.id) {
                    state.farmer.data[item.id] = item
                    if (!state.farmer.byEmployeeId[item.employee_id]){
                        state.farmer.byEmployeeId[item.employee_id] = []
                    }
                    if (item.id) {
                        state.farmer.byEmployeeId[item.employee_id].push(item.id.toString())
                    }
                }
            });
            state.farmer.fetchStatusByUser = 'succeeded'
        })
        builder.addCase(fetchFarmers.rejected, (state, action) => {
            state.farmer.fetchStatusByUser = 'failed'
            state.farmer.fetchError = action.error.message
        })
    }
  })

  export const {
      deleteFarmerReducer,
      farmerReducer,
      setFiltered,
      setFilterBy,
      setSortBy,
      refreshFilter,
      refreshFarmers,
      setMyFilterBy,
      setMySortBy
  } = configSlice.actions

  export default configSlice.reducer