import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Row, Col, Button, Container, Table, Badge } from 'react-bootstrap';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';
import { setSamplesFilterBy, setSamplesFiltered, setSamplesSortBy, requestSamplesPage, setSamplesNbByPage, selectUnselectLinesOfSample, selectUnselectResultOfSample, selectUnselectAllLinesOfSamples, setHeaderSelected, selectUnselectAllResultsOfAnalyte, getKeyForDashbpardAnalysisHeader } from '../../../state/dashboardSlice';
import CustomPagination from '../../../common/components/CustomPagination';
import ButtonHeaderList from '../../../common/components/ButtonHeaderList';
import { ReactComponent as CheckedIcon } from 'bootstrap-icons/icons/check-square.svg'
import { ReactComponent as UncheckedIcon } from 'bootstrap-icons/icons/square.svg'
import { ReactComponent as IndeterminateCheckIcon } from 'bootstrap-icons/icons/dash-square.svg'
import { ReactComponent as XIcon } from 'bootstrap-icons/icons/x.svg'
import { ScrollSyncNode } from 'scroll-sync-react'

interface DashboardSamplesComponentContainerProps extends WithTranslation {
    dashboardName: string
}

const DashboardSamplesComponent: React.FC<DashboardSamplesComponentContainerProps> = ({ t, dashboardName }) => {
    const dispatch = useDispatch()

    // pagination state
    const {allowedPageSizes } = useSelector( (state: RootState) => state.local )

    // user state
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    // languages
    const languages = useSelector((state: RootState) => state.config.languages.data)
    const language = Object.values(languages).find(lg => lg.id === Number(authUser.language_id))

    // samples state
    const samplesForData = useSelector((state: RootState) => state.dashboard.samplesForData)
    const resultsHeader = useSelector((state: RootState) => state.dashboard.resultsHeaders)
    const filtered = useSelector((state: RootState) => state.dashboard.filteredSamples)
    const pagination = useSelector((state: RootState) => state.dashboard.paginationSamples)
    const paramsFromComponents = useSelector((state: RootState) => state.dashboard.paramsFromComponents)
    const fieldsForSamplesInfo = useSelector((state: RootState) => state.dashboard.fieldsForSamplesInfo)

    // local states
    const [isChecked, setIsChecked] = useState(true);

    // types for fields
    const typeDateOfFields: Array<"number" | "label" | "filter" | "date" | "list" | "check" | "boolean"> = ["date"]
    const typeNumberOfFields: Array<"number" | "label" | "filter" | "date" | "list" | "check" | "boolean"> = ["number"]
    const resultsFields = () => {
        const fields = {
            validation_date: {
                type: typeDateOfFields,
                sortable: true
            },
            creation_date: {
                type: typeDateOfFields,
                sortable: true
            }
        }
        resultsHeader.forEach(resultHeader => {
            Object.assign(fields, {[resultHeader.name.translations[language && language.code_language ? language.code_language : ''] || resultHeader.name.name]: { type: typeNumberOfFields, sortable: true, noTrad: true }})
        })
        fieldsForSamplesInfo.forEach(field => {
            Object.assign(fields, {[field.field]: { type: [field.typeOfField], sortable: true, noTrad: false }})
        })
        return fields
    }

    const { ListHeader, filterAndSortList, FilterList } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "dashboard.samples.columns.",
        onlyActive: false,
        size: 'xs',
        fields: resultsFields(),
        reducers: {
            filterBy: setSamplesFilterBy,
            sortBy: setSamplesSortBy
        }
    })

    useEffect(() => {
        const filteredList = filterAndSortList(
            Object.values(samplesForData).map(item => {
                const returnedItem = {
                    ...item,
                    id: item.laboratory_id + '#' + item.analysis_id,
                }
                resultsHeader.forEach(resultHeader => {
                    Object.assign(returnedItem, {[resultHeader.name.translations[language && language.code_language ? language.code_language : ''] || resultHeader.name.name]: item.results.find((f) => f.analyte_id.toUpperCase() === resultHeader.id.toUpperCase()) && item.results.find((f) => f.analyte_id.toUpperCase() === resultHeader.id.toUpperCase())?.default_unit_result ? Number(item.results.find((f) => f.analyte_id.toUpperCase() === resultHeader.id.toUpperCase())?.default_unit_result) : 0})
                })
                return returnedItem
            })
        )
        dispatch(setSamplesFiltered(filteredList))
        dispatch(requestSamplesPage(1))
        dispatch(setSamplesNbByPage(pagination.nbByPage))
    }, [pagination.filterBy, pagination.sortBy])

    useEffect(() => {
        if(paramsFromComponents.sampleSelectedInGraphs !== ''){
            const filter = {display_id: {like: paramsFromComponents.sampleSelectedInGraphs}}
            dispatch(setSamplesFilterBy(filter))
        }else{
            dispatch(setSamplesFilterBy({}))
        }
    }, [paramsFromComponents.sampleSelectedInGraphs])

    return (
        <Container className={'mt-2 p-0 ms-0 me-0'}>
            <Container className="p-2 bg-white mb-4 rounded-3">
                <Row >
                    <Col lg="auto">
                        <h4 className="text-primary fw-bold">
                            <Badge pill bg="primary">{Intl.NumberFormat(authUser.locale).format(pagination.count)}</Badge> {t('dashboard.samples.title')}
                        </h4>
                    </Col>
                    <Col className='mt-1'>
                        <FilterList />
                    </Col>
                </Row>
                <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                <Row>
                    <ScrollSyncNode group="horiz-scroll">
                        <Container className='ms-2 me-2 p-0 mb-3 noshrink' style={{overflow: "auto", minHeight: "170px"}}>
                            <Table key='samples' striped hover size='sm' className="bg-white text-center table-fixed table-xs">
                                <thead className='bg-white'>
                                    <tr>
                                        <th className="p-0" />
                                        <th className="p-1 fixed-width-button">
                                            <ButtonHeaderList
                                                title={""}
                                                name="select"
                                                type={["check"]}
                                                onClickSelect={() => {
                                                    // Select/unselect all sample filtered and select/unselect all results of samples filtered
                                                    dispatch(selectUnselectAllLinesOfSamples({samples: filtered.slice((pagination.currentPage -1)*pagination.nbByPage,(pagination.currentPage -1)*pagination.nbByPage + (pagination.nbByPage)), bool: !isChecked}))
                                                    setIsChecked(!isChecked)
                                                }}
                                                isChecked={isChecked}
                                            />
                                        </th>
                                        <th className="p-1 fixed-width-with-button">
                                            <ListHeader name={paramsFromComponents.date} />
                                        </th>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <th className="p-1" key={`header-${header.id}`}>
                                                    <ListHeader name={header.name.translations[language && language.code_language ? language.code_language : ''] || header.name.name} deleteIcon={true} deleteIconState={header.selected} onClickDelete={() => {
                                                        // Select/unselect all results for the analyte selected
                                                        dispatch(selectUnselectAllResultsOfAnalyte({samples: filtered.slice((pagination.currentPage -1)*pagination.nbByPage,(pagination.currentPage -1)*pagination.nbByPage + (pagination.nbByPage)), bool: !header.selected, analyteId: header.id}))
                                                        dispatch(setHeaderSelected({bool: !header.selected, analyteId: header.id}))
                                                    }} />
                                                </th>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return (
                                                <th className="p-1" key={`header-${field.field}`}>
                                                    <ListHeader name={field.field} />
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtered.slice((pagination.currentPage -1)*pagination.nbByPage,(pagination.currentPage -1)*pagination.nbByPage + (pagination.nbByPage)).map((id) => {
                                        return(
                                            <tr key={id} className={paramsFromComponents.sampleSelectedInGraphs === samplesForData[id].display_id ? 'bold-to-child' : ''}>
                                                <td className="p-1 table-hover-hint" />
                                                <td>
                                                    <Button className="p-0 text-primary" variant="link" onClick={() => {
                                                        // Select/unselect sample and select/unselect all results
                                                        dispatch(selectUnselectLinesOfSample(id))
                                                    }} >
                                                        {/* Checked icon when sample and all results are selected */}
                                                        {samplesForData[id].results.filter(r => !r.selected).length === 0 &&
                                                            <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                                        }
                                                        {/* Unchecked icon when sample and all results are not selected */}
                                                        {samplesForData[id].results.filter(r => r.selected).length === 0 &&
                                                            <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                                        }
                                                        {/* Indeterminate icon when we have results selected and other not selected */}
                                                        {samplesForData[id].results.filter(r => !r.selected).length > 0 && samplesForData[id].results.filter(r => r.selected).length > 0 &&
                                                            <IndeterminateCheckIcon width="18px" height="18px" className="align-text-top" />
                                                        }
                                                    </Button>
                                                </td>
                                                <td>{Intl.DateTimeFormat(authUser.locale).format(new Date(samplesForData[id][paramsFromComponents.date]))}</td>
                                                {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                    return (
                                                        <td onClick={() => {
                                                            // Select/unselect result of a sample
                                                            dispatch(selectUnselectResultOfSample({key: id, analyteId: header.id}))
                                                        }} key={`td-${header.id}`}>
                                                            {samplesForData[id].results.find(r => r.analyte_id.toUpperCase() === header.id.toUpperCase())?.default_unit_result}
                                                            {samplesForData[id].results.find(r => r.analyte_id.toUpperCase() === header.id.toUpperCase()) && !samplesForData[id].results.find(r => r.analyte_id.toUpperCase() === header.id.toUpperCase())?.selected &&
                                                                <XIcon width="18px" height="18px" className="align-text-top text-primary" />
                                                            }
                                                        </td>
                                                    )
                                                })}
                                                {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                    const sfd = samplesForData[id]
                                                    switch (field.typeOfField) {
                                                        case "date":
                                                            return (                                                                
                                                                //In case of date is null, we display an empty cell instead of 01/01/1970
                                                                <td>{sfd[field.field as keyof typeof sfd] ? Intl.DateTimeFormat(authUser.locale).format( new Date(sfd[field.field as keyof typeof sfd] as Date)) : ''}</td>
                                                            )                                                            
                                                            break;
                                                        case "boolean":
                                                            return (
                                                                <td>{sfd[field.field as keyof typeof sfd] ? <CheckedIcon width="15px" height="15px" className="align-text-top"/> : <UncheckedIcon width="15px" height="15px" className="align-text-top"/>}</td>
                                                            )                                                            
                                                            break;
                                                        default:
                                                            return (
                                                                <td>{sfd[field.field as keyof typeof sfd]}</td>
                                                            )
                                                            break;
                                                    }
                                                })}
                                            </tr>     
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Container>
                    </ScrollSyncNode>
                    <CustomPagination
                        nbPages={pagination.nbPage}
                        curPage={pagination.currentPage}
                        nbNeighbours={1}
                        curPageSize={pagination.nbByPage}
                        allowedPageSizes={allowedPageSizes}
                        goToPage={(page) => { dispatch(requestSamplesPage(page))}}
                        setPageSize={(nbPage) => { dispatch(setSamplesNbByPage(nbPage)); dispatch(requestSamplesPage(1));}}
                    />
                </Row>
            </Container>
        </Container>
    )
}

export default withTranslation()(DashboardSamplesComponent)