import React from "react";

import { Toast } from 'react-bootstrap';
import { ReactComponent as SquareFillIcon } from 'bootstrap-icons/icons/square-fill.svg';
import { useTranslation } from "react-i18next";
import { useServiceWorkerStatus } from "../utils/registrationStatus";

const SWUpdateToast: React.FC = () => {

  const {t} = useTranslation()

  // Service worker status listener
  const {showReload, waitingWorker, mustRefresh} = useServiceWorkerStatus()

  /**
   * Tells the service worker to skip the waiting phase and then reloads the page
   */ 
  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    window.location.reload();
  }

  /**
   * Trigger service worker update and page refresh if required on initial load
   */
  React.useEffect(() => {
    if (mustRefresh) {
      console.log("Refresh automatically to apply service worker update.")
      waitingWorker?.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    }
  }, [mustRefresh])

  /**
   * Return warning component that notify the user that an update is available
   */
  return (
    
      <Toast show={showReload}>
          <Toast.Header closeButton={false}>
              <SquareFillIcon width="20px" height="100%" className="text-primary rounded me-2" />
              <strong className="me-auto">{t('updates.title')}</strong>
          </Toast.Header>
          <Toast.Body>{t('updates.body')} <button onClick={() => reloadPage()}>{t('updates.button')}</button></Toast.Body>
      </Toast>
  )
};

export default SWUpdateToast;