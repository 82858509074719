import React, { useState, useContext } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/rootReducer';
import { Row, Col, Button, Container, Form, Modal, Accordion, Card, AccordionContext, useAccordionButton, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as CaretDownFill } from 'bootstrap-icons/icons/caret-down-fill.svg'
import { ReactComponent as CaretUpFill } from 'bootstrap-icons/icons/caret-up-fill.svg'
import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg'
import { ReactComponent as DashboardIcon } from 'bootstrap-icons/icons/bar-chart-fill.svg'
import GoBackButton from '../../../../common/components/GoBackButton';
import MatrixApi, { Matrix, MatrixKey, MatrixTemplateInput, ResultSource, ValorizationResponse } from '../../../../api/matrixApi';
import { ReactComponent as PdfIcon } from "bootstrap-icons/icons/file-earmark-pdf.svg";
import StatusMatrixBar from '../../../../common/components/StatusMatrixBar';
import Loader from '../../../../common/components/Loader';
import { archiveMatrix, deleteMatrices } from '../../../../state/matrixSlice';
import { AppDispatch } from '../../../../state/store';
import { downloadMemo } from '../../../../state/matrixTemplateSlice';
import ModalMatrixValorization from '../../matrix_list/components/ModalMatrixValorization';
import InlineLoader from '../../../../common/components/InlineLoader';
import ModalExportMatrix from '../../../../common/components/ModalExportMatrix';
import { postMatrixJob } from '../../../../state/matrixJobsSlice';
import { setMatrixFields } from '../../../../state/dashboardSlice';

interface ConsultMatrixContainerProps extends WithTranslation {
    matrixDb: Matrix,
}


const ConsultMatrix: React.FC<ConsultMatrixContainerProps> = ({ t, matrixDb }) => {
    const dispatch = useDispatch<AppDispatch>();

    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    const refOrigins = useSelector((state: RootState) => state.config.refOrigins.data)
    const refSuppliers = useSelector((state: RootState) => state.config.refSuppliers.data)
    const ingredients = useSelector((state: RootState) => state.config.ingredients.data)
    const matrixTemplateInputs = useSelector((state: RootState) => state.matrixTemplateInputs.data)
    const inputsByTemplateId = useSelector((state: RootState) => state.matrixTemplateInputs.byTemplateId)
    const autocalcCallState = useSelector((state: RootState) => state.autocalcCallState.data)
    const matrixTemplates = useSelector((state: RootState) => state.matrixTemplates.data)
    const [processing, setProcessing] = useState(false);
    const companies = useSelector((state: RootState) => state.companies.data)
    const preferences = useSelector((state: RootState) => state.preferences.data)

    const [showArchive, setShowArchive] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showDownload, setShowDownload] = useState(false);
    const [showCalculate, setShowCalculate] = useState(false);
    const [show, setShow] = useState(false);
    const history = useHistory();

    const handleCloseArchive = () => setShowArchive(false);
    const handleShowArchive = () => setShowArchive(true);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);
    const [isCalculating, setIsCalculating] = useState(false);
    const [valoRes, setValoRes] = useState<ValorizationResponse[]>([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const message = t("matrix_errors.unexpected_error");
    const [showModalExport, setShowModalExport] = useState(false)
    const [selectedMatrixIds, setSelectedMatrixIds] = useState<MatrixKey[]>([{ company_id: matrixDb.company_id, export_code: matrixDb.export_code, version: matrixDb.version }])

    interface IProps {
        eventKey: string
        name: string
    }
    const filteredMatrixTemplateInputs = Object.values(matrixTemplateInputs).filter(input => inputsByTemplateId[matrixDb.template_id] && inputsByTemplateId[matrixDb.template_id].includes(input.id))
    let grouped: _.Dictionary<MatrixTemplateInput[]> = _.groupBy(filteredMatrixTemplateInputs, obj => obj.group.name)
    grouped = _.mapValues(grouped, group => _.orderBy(group, 'order'));

    async function deleteMatrixOnClick() {
        handleCloseDelete();
        setProcessing(true);
        try {
            dispatch(deleteMatrices({ body: { company_id: matrixDb.company_id, matrices: [{ export_code: matrixDb.export_code, version: matrixDb.version }] } }))
            history.push('/secure/matrix/my_matrix')
        } catch (err) {
            console.log(err)
            handleShow();
        } finally {
            setProcessing(false);
        }
    }

    async function archiveMatrixOnClick() {
        handleCloseArchive();
        setProcessing(true);
        try {
            dispatch(archiveMatrix({ companyId: matrixDb.company_id, exportCode: matrixDb.export_code, version: matrixDb.version }))
        } catch (err) {
            console.log(err)
            handleShow();
        } finally {
            setProcessing(false);
        }
    }

    function ContextAwareToggle({ eventKey, name }: IProps) {
        const { activeEventKey } = useContext(AccordionContext);
        const decoratedOnClick = useAccordionButton(
            eventKey,
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <Row className={`ms-0 me-0 align-items-center rounded ${isCurrentEventKey ? "bgColorAccordionMatrixOpen ps-0" : "bgColorAccordionMatrixClosed text-white ps-0"}`} >

                <Col className='mt-0 mb-0'>
                    <Card.Header onClick={decoratedOnClick} className={`border-0 ${isCurrentEventKey ? "bgColorAccordionMatrixOpen ps-0" : "bgColorAccordionMatrixClosed ps-0"}`} >
                        <Row className="p-0">
                            <Col xs="auto" className="ps-0">
                                <span className="ps-2 fw-bold " > {name} </span>
                            </Col>
                            <Col xs="auto" className="text-end ms-auto">

                                <>
                                    {isCurrentEventKey ?
                                        <CaretUpFill width="16px" height="100%" />
                                        :
                                        <CaretDownFill width="16px" height="100%" />
                                    }
                                </>

                            </Col>
                        </Row>
                    </Card.Header>
                </Col>
            </Row>

        );
    }

    function launchDeleteMatrix() {
        handleShowDelete();
    }

    function launchArchiveMatrix() {
        handleShowArchive();
    }

    function launchDownloadMatrix() {
        // formData object for job post
        const formData = new FormData();
        formData.append("type", "export");
        formData.append("filter[company_id]", selectedMatrixIds[0].company_id.toString());
        selectedMatrixIds.forEach((matrix, index) => {
            formData.append('filter[matrices][' + index + '][export_code]', matrix.export_code)
            formData.append('filter[matrices][' + index + '][version]', matrix.version.toString())
        })
        formData.append("output[export_name]", '');
        formData.append("output[excel_format]", 'inputs');

        dispatch(postMatrixJob(formData))
    }

    const handleCalculate = async () => {
        setIsCalculating(true)

        const valoResTemp: ValorizationResponse = await MatrixApi.getInstance().valorizeMatrix({
            company_id: matrixDb.company_id,
            export_codes: new Array(matrixDb.export_code)
        })

        setValoRes(new Array(valoResTemp))
        setShowCalculate(true)
        setIsCalculating(false)
    }

    return (
        <Container className={'mt-5 p-0'}>
            {processing && <Loader />}

            <ModalExportMatrix selectedMatricesIds={selectedMatrixIds} setShowModal={setShowModalExport} showModal={showModalExport} />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("error")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("buttons.delete")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("matrix.matrix.msg_delete_single_matrix")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        {t("buttons.cancel")}
                    </Button>
                    <Button variant="primary" onClick={deleteMatrixOnClick}>
                        {t("buttons.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showArchive} onHide={handleCloseArchive}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("matrix.matrix.buttons.archive")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("matrix.matrix.msg_archive_single_matrix")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseArchive}>
                        {t("buttons.cancel")}
                    </Button>
                    <Button variant="primary" onClick={archiveMatrixOnClick}>
                        {t("buttons.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalMatrixValorization valoRes={valoRes} setShowModalValo={setShowCalculate} showModalValo={showCalculate} />

            <Container className="p-0">
                <Row className="no-print">
                    <Col xs="auto">
                        <GoBackButton label={t('matrix.matrix.return_to_my_list_matrix')} />
                    </Col>
                </Row>
                <Row className="pt-2 " >
                    <Col>
                        <h4 className="pb-2 fw-bold">
                            {`${t('matrix.matrix.consult_matrix')}`}
                            <span className="text-primary fw-bold">
                                {` ${matrixDb.export_code}`}
                            </span>
                        </h4>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    <StatusMatrixBar
                        Date1={matrixDb.creation_date != null ? new Date(matrixDb.creation_date).toString() : undefined}
                        Date2={matrixDb.last_update != null ? new Date(matrixDb.last_update).toString() : undefined}
                        Date3={matrixDb.ready_date != null ? new Date(matrixDb.ready_date).toString() : undefined}
                        Date4={matrixDb.active_date != null ? new Date(matrixDb.active_date).toString() : undefined}
                        Date5={matrixDb.last_export != null ? new Date(matrixDb.last_export).toString() : undefined}
                        Date6={matrixDb.archived_date != null ? new Date(matrixDb.archived_date).toString() : undefined}
                        Status={matrixDb.matrix_status} />
                </Row>
            </Container>
            <Container>
                <>
                    <Container className="p-2 bg-white mb-4 rounded-3">
                        <Row >
                            <Col lg>
                                <h4 className="text-primary fw-bold">
                                    {t('matrix.matrix.company')}
                                </h4>
                            </Col>
                            {['In progress', 'Ready', 'Autocalc integration issue', 'Administration issue', 'Autocalc issue', 'Active'].includes(matrixDb.matrix_status) &&
                                <Col sm="auto" className="text-end">
                                    <Link className="btn btn-link p-0 pe-1 text-primary no-print" to={`/secure/matrix/edit_matrix/edition/${matrixDb.template_id}/${matrixDb.company_id}/${encodeURIComponent(matrixDb.export_code)}/${matrixDb.version}`}>
                                        <EditIcon width="24px" height="100%" className="align-text-top" />
                                    </Link>
                                </Col>
                            }
                        </Row>
                        <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                        <Row>
                            <Col lg>
                                <Form.Group>
                                    <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.formulation_team')} :</span> {(matrixDb.company_id && companies[matrixDb.company_id] && companies[matrixDb.company_id].formulation_id) ? laboratories[companies[matrixDb.company_id].formulation_id!].name : ""} </Form.Label>
                                </Form.Group>
                            </Col>
                            <Col lg>
                                <Form.Group>
                                    <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.company')} :</span> {matrixDb.company_id ? companies[matrixDb.company_id].name : ""} </Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <Form.Group>
                                    <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.export_code')} :</span> {matrixDb.export_code ? matrixDb.export_code : ""} </Form.Label>
                                </Form.Group>
                            </Col>
                            <Col lg>
                                <Row>
                                    <Col lg={2}>
                                        <Form.Group>
                                            <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.version')} :</span> {matrixDb.version ? matrixDb.version : ""} </Form.Label>
                                        </Form.Group>

                                    </Col>
                                    <Col>
                                        {(
                                            preferences[`${matrixDb.company_id}#${matrixDb.export_code}#${matrixDb.version}`]
                                        ) &&
                                            <OverlayTrigger
                                                placement={"left"}
                                                overlay={
                                                    <Popover id={`popover-preferences`}>
                                                        <Popover.Body>
                                                            {t('matrix.matrix.help.preferences')}
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <Button className="p-0 pe-1 text-secondary" variant="link" onClick={(e) => {
                                                    e.stopPropagation();
                                                    dispatch(setMatrixFields({
                                                        laboratory_id: companies[matrixDb.company_id].laboratory_id,
                                                        company_id: matrixDb.company_id,
                                                        export_code: matrixDb.export_code,
                                                        category: matrixDb.category ? matrixDb.category : '',
                                                        sub_category: matrixDb.sub_category ? matrixDb.sub_category : '',
                                                        raw_material: matrixDb.raw_material ? matrixDb.raw_material : '',
                                                        template_id: matrixDb.template_id
                                                    }))

                                                    history.push(`/secure/dashboard/matrix`)
                                                }} >
                                                    {/* Update */}
                                                    <DashboardIcon width="24px" height="100%" className="align-text-top" />
                                                </Button>
                                            </OverlayTrigger>
                                        }
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Container>
                    <Container className="p-2 bg-white mb-4 rounded-3">
                        <Row >
                            <Col lg>
                                <h4 className="text-primary fw-bold">
                                    {t('matrix.matrix.matrix')}
                                </h4>
                            </Col>
                            {['In progress', 'Ready', 'Autocalc integration issue', 'Administration issue', 'Autocalc issue', 'Active'].includes(matrixDb.matrix_status) &&
                                <Col sm="auto" className="text-end">
                                    <Link className="btn btn-link p-0 pe-1 text-primary no-print" to={`/secure/matrix/edit_matrix/edition/${matrixDb.template_id}/${matrixDb.company_id}/${encodeURIComponent(matrixDb.export_code)}/${matrixDb.version}`}>
                                        <EditIcon width="24px" height="100%" className="align-text-top" />
                                    </Link>
                                </Col>
                            }
                        </Row>
                        <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                        <Row>
                            <Col lg>
                                <Form.Group>
                                    <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.category')} :</span> {matrixDb.category ? matrixDb.category : ""} </Form.Label>
                                </Form.Group>
                            </Col>
                            {matrixDb.sub_category &&
                                <Col lg>
                                    <Form.Group>
                                        <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.sub_category')} :</span> {matrixDb.sub_category ? matrixDb.sub_category : ""} </Form.Label>
                                    </Form.Group>
                                </Col>
                            }
                        </Row>
                        <Row>
                            {matrixDb.raw_material &&
                                <Col lg>
                                    <Form.Group>
                                        <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.raw_material')} :</span> {matrixDb.raw_material ? matrixDb.raw_material : ""} </Form.Label>
                                    </Form.Group>
                                </Col>
                            }
                            <Col lg>
                                <Form.Group>
                                    <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.jcode')} :</span> {matrixDb.jcode ? ingredients[matrixDb.jcode] ? `${matrixDb.jcode} - ${ingredients[matrixDb.jcode].name}` : matrixDb.jcode : matrixDb.template_id && matrixTemplates[matrixDb.template_id] ? ingredients[matrixTemplates[matrixDb.template_id].jcode] ? `${matrixTemplates[matrixDb.template_id].jcode} - ${ingredients[matrixTemplates[matrixDb.template_id].jcode].name}` : matrixTemplates[matrixDb.template_id].jcode ? matrixTemplates[matrixDb.template_id].jcode : t('matrix.matrix.no_jcode') : t('matrix.matrix.no_jcode')} </Form.Label>
                                    {/* will display the memo icon if the selected matrix template has a memo */}
                                    {matrixDb.template_id && matrixTemplates[matrixDb.template_id]?.memo_url && matrixTemplates[matrixDb.template_id]?.memo_url !== null &&
                                        <a onClick={
                                            () => {
                                                if (matrixDb.template_id) {
                                                    dispatch(downloadMemo({
                                                        id: matrixDb.template_id,
                                                        laboratoryId: matrixTemplates[matrixDb.template_id].laboratory_id,
                                                        url: matrixTemplates[matrixDb.template_id].memo_url
                                                    }
                                                    ))
                                                }
                                            }
                                        } href="javascript:void(0);">
                                            <PdfIcon width="24px" height="100%" className="align-text-top" />
                                        </a>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <Form.Group>
                                    <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.origin')} :</span> {matrixDb.origin_id && refOrigins[matrixDb.origin_id] ? refOrigins[matrixDb.origin_id].name : ""} </Form.Label>
                                </Form.Group>
                            </Col>
                            <Col lg>
                                <Form.Group>
                                    <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.supplier')} :</span> {matrixDb.supplier_id && refSuppliers[matrixDb.supplier_id] ? refSuppliers[matrixDb.supplier_id].name : ""} </Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <Form.Group>
                                    <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.customer_name')} :</span> {matrixDb.customer_name ? matrixDb.customer_name : ""} </Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg>
                                <Form.Group>
                                    <Form.Label className="text-break"><span className="text-black fw-bold">{t('matrix.matrix.comment')} :</span> {matrixDb.comment ? matrixDb.comment : ""} </Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                    <Container className="p-2 bg-white mb-4 rounded-3">
                        <Row >
                            <Col lg>
                                <h4 className="text-primary fw-bold">
                                    {t('matrix.matrix.inputs')}
                                </h4>
                            </Col>
                            {['In progress', 'Ready', 'Autocalc integration issue', 'Administration issue', 'Autocalc issue', 'Active'].includes(matrixDb.matrix_status) &&
                                <Col sm="auto" className="text-end">
                                    <Link className="btn btn-link p-0 pe-1 text-primary no-print" to={`/secure/matrix/edit_matrix/edition/${matrixDb.template_id}/${matrixDb.company_id}/${encodeURIComponent(matrixDb.export_code)}/${matrixDb.version}`}>
                                        <EditIcon width="24px" height="100%" className="align-text-top" />
                                    </Link>
                                </Col>
                            }
                        </Row>
                        <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                        {_.map(grouped, (element, key, index) => (
                            <>
                                {(matrixDb.results[0]) && element.some((object) => (matrixDb.results.filter((e) => e && (e.analyte_id === object.analyte_id) && (e.source === "Input")).length > 0)) &&
                                    <Row className='mb-3'>
                                        <Col lg>
                                            <Accordion>
                                                <Card>
                                                    <ContextAwareToggle eventKey={String(index)} name={key} />
                                                    <Accordion.Collapse eventKey={String(index)} >
                                                        <Card.Body>
                                                            <Row>
                                                                {element.map((object) => (
                                                                    <>
                                                                        {matrixDb.results.filter((e) => (e.analyte_id === object.analyte_id) && (e.source === ResultSource.input))[0] &&
                                                                            <Col lg={6}>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <Form.Group >
                                                                                            <Form.Label ><span className="text-black">{object.analyte_name.name} {'('}{object.unit}{')'} : {object.is_toggle === true ? '#' : '*'}</span></Form.Label>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <Form.Group >
                                                                                            <Form.Label ><span className="text-black">{matrixDb.results.filter((e) => e.analyte_id === object.analyte_id)[0].ai_result}</span></Form.Label>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        }
                                                                    </>
                                                                ))}
                                                            </Row>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                }
                            </>

                        ))}

                        {matrixDb.results.filter((e) => e && e.source === "Input").length < 1 &&
                            <Row>
                                <Col className='ms-3'>
                                    <Form.Group >
                                        <Form.Label ><span className="text-black">{t('matrix.matrix.no_inputs')}</span></Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col lg>
                                <Row className="mt-4 pb-3" ><Col lg className="lineTitle"></Col></Row>
                                <Form.Group className="text-end">
                                    <Form.Label ><span className="text-black">#: {t('matrix.matrix.advice')}</span></Form.Label>
                                </Form.Group>
                                <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                            </Col>
                        </Row>
                    </Container>
                </>

                {['Autocalc integration issue', 'Administration issue', 'Autocalc issue'].includes(matrixDb.matrix_status) &&
                    <Container className="p-2 bg-white mb-4 rounded-3">
                        <Row >
                            <Col lg>
                                <h4 className="text-primary fw-bold">
                                    {t('matrix.matrix.outputs')}
                                </h4>
                            </Col>
                        </Row>
                        <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                        <Row>
                            <Col className='ms-3'>
                                {autocalcCallState[matrixDb.company_id + "#" + matrixDb.export_code + "#" + matrixDb.version] && autocalcCallState[matrixDb.company_id + "#" + matrixDb.export_code + "#" + matrixDb.version].error_code &&
                                    <>
                                        <p><span className='text-black fw-bold'>{t('matrix.matrix.status.' + matrixDb.matrix_status)}:</span> {t('external_call_state.error.' + autocalcCallState[matrixDb.company_id + "#" + matrixDb.export_code + "#" + matrixDb.version].error_code, { param: autocalcCallState[matrixDb.company_id + "#" + matrixDb.export_code + "#" + matrixDb.version].param })}</p>
                                        {autocalcCallState[matrixDb.company_id + "#" + matrixDb.export_code + "#" + matrixDb.version].details.external_message &&
                                            <p><span className='text-black fw-bold'>{t('external_call_state.error.details')}:</span> {autocalcCallState[matrixDb.company_id + "#" + matrixDb.export_code + "#" + matrixDb.version].details.external_message}</p>
                                        }
                                        {autocalcCallState[matrixDb.company_id + "#" + matrixDb.export_code + "#" + matrixDb.version].error_message &&
                                            <p><span className='text-black fw-bold'>{t('external_call_state.error.details')}:</span> {autocalcCallState[matrixDb.company_id + "#" + matrixDb.export_code + "#" + matrixDb.version].error_message}</p>
                                        }
                                    </>
                                }
                            </Col>
                        </Row>
                    </Container>
                }
            </Container>
            <Container>
                <Row className='mt-3'>
                    <Col className='text-end'>
                        {['In progress', 'Ready', 'Autocalc integration issue', 'Administration issue', 'Autocalc issue'].includes(matrixDb.matrix_status) &&
                            <Button className='me-3' variant="dark" onClick={launchDeleteMatrix.bind(null)}>
                                {t("matrix.matrix.buttons.delete")}
                            </Button>
                        }
                        {['In progress', 'Ready', 'Autocalc integration issue', 'Administration issue', 'Autocalc issue', 'Active'].includes(matrixDb.matrix_status) &&
                            <Button className='me-3 ' variant="primary" onClick={() => history.push(`/secure/matrix/edit_matrix/edition/${matrixDb.template_id}/${matrixDb.company_id}/${encodeURIComponent(matrixDb.export_code)}/${matrixDb.version}`)}>
                                {t("matrix.matrix.buttons.edit")}
                            </Button>
                        }
                        {['Ready', 'Autocalc integration issue', 'Administration issue', 'Autocalc issue', 'Active', 'Archived'].includes(matrixDb.matrix_status) && !isCalculating &&
                            <Button className='me-3' variant="primary" onClick={() => handleCalculate()}>
                                {t("matrix.matrix.buttons.calculate")}
                            </Button>
                        }
                        {isCalculating &&
                            <InlineLoader />
                        }
                        {['Active', 'Archived'].includes(matrixDb.matrix_status) &&
                            <Button className='me-3' variant="primary" onClick={() => setShowModalExport(true)}>
                                {t("matrix.matrix.buttons.export")}
                            </Button>
                        }
                        {['In progress', 'Ready', 'Autocalc integration issue', 'Administration issue', 'Autocalc issue', 'Active', 'Archived'].includes(matrixDb.matrix_status) &&
                            <Button className='me-3' variant="primary" onClick={launchDownloadMatrix.bind(null)}>
                                {t("matrix.matrix.buttons.download")}
                            </Button>
                        }
                        {['Active'].includes(matrixDb.matrix_status) &&
                            <Button className='me-3' variant="primary" onClick={launchArchiveMatrix.bind(null)}>
                                {t("matrix.matrix.buttons.archive")}
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>

        </Container>
    )
}

export default withTranslation()(ConsultMatrix)