/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react'

import './App.scss'

import { Switch, useRouteMatch } from 'react-router-dom'

import { SecureRoute } from '@okta/okta-react'
import RoleSecureRoute from './common/auth/RoleSecureRoute'
import { useDispatch } from 'react-redux'

import useViewport from './common/utils/useViewport'

import HomeContainer from './pages/home/HomeContainer'
import ProfileContainer from './pages/profile/ProfileContainer'
import AnalysisListContainer from './pages/analysis/analysisList/AnalysisListContainer'
import ConsultAnalysisContainer from './pages/analysis/analysis/ConsultAnalysisContainer'
import FarmerListContainer from './pages/farmer/FarmerListContainer'
import SelectAnalysisMobile from './pages/analysis/analysis/components/SelectAnalysisMobile'
import PdcListContainer from './pages/pdc/PdcListContainer'
import PdcContainer from './pages/pdc/PdcContainer'
import PdcUseContainer from './pages/analysis/analysis/PdcUseContainer'
import AnalysisContainer from './pages/analysis/analysis/AnalysisContainer'

import { updateStateFooter, updateHideStateFooter } from '../src/state/footerSlice';
import { withTranslation, WithTranslation } from 'react-i18next';
import ResultsContainer from './pages/analysis/results/ResultsContainer';
import ResultsListContainer from './pages/analysis/resultsList/resultsListContainer';
import Export from './pages/analysis/export/ExportExcelContainer'
import MatrixManagementRoutes from './pages/matrix_management/MatrixManagementRoutes'
import MatrixRoutes from './pages/matrix/MatrixRoutes'
import DashboardContainer from './pages/dashboard/DashboardContainer'

// Lazy loading of laboratory and administration components (trigger multi chunk generation at build)
const AdministrationRoutes = React.lazy(() => import('./pages/administration/AdministrationRoutes'))
const LaboratoryRoutes = React.lazy(() => import('./pages/laboratory/LaboratoryRoutes'))

const AppRouter: React.FC<WithTranslation> = () => {
    const match = useRouteMatch()
    const dispatch = useDispatch()

    const { lg } = useViewport()

    useEffect(() => {
        const regex = new RegExp(`^${match.path}/analysis/[1-9][0-9]*$`);
        if (regex.test(location.pathname) || location.pathname  === `${match.path}/analysis` || location.pathname  === `${match.path}/analysis/`
            || location.pathname  === `${match.path}/home/` || location.pathname  === `${match.path}/home`){
            dispatch(updateStateFooter({footerState:false}))
            dispatch(updateHideStateFooter(false))
        }else if(location.pathname  === `${match.path}/results` || location.pathname  === `${match.path}/results/`){
            dispatch(updateStateFooter({footerState:false}))
            dispatch(updateHideStateFooter(true))
        }else if(location.pathname  === `${match.path}/laboratory/handle-nirs` || location.pathname  === `${match.path}/laboratory/handle-nirs/`){
            dispatch(updateStateFooter({footerState:false}))
            dispatch(updateHideStateFooter(true))
        }else if(location.pathname  === `${match.path}/laboratory/result_input` || location.pathname  === `${match.path}/laboratory/result_input/`){
            dispatch(updateStateFooter({footerState:false}))
            dispatch(updateHideStateFooter(true))
        }else{
            dispatch(updateStateFooter({footerState:true}))
            dispatch(updateHideStateFooter(false))
        }
        
    }, [dispatch, match.path, location.pathname])

    return (
        <Switch>
            <SecureRoute path={`${match.path}/home`}>{lg ? <HomeContainer /> : <AnalysisListContainer />}</SecureRoute>
            <RoleSecureRoute
                exact
                path={`${match.path}/analysis/fodder`}
                requiredRoles={[
                    'MyProvilab_ROLE_laboratory',
                    'MyProvilab_ROLE_laboratoryAdmin',
                    'MyProvilab_ROLE_customerLabAdmin',
                    'MyProvilab_ROLE_usersAdmin',
                    'MyProvilab_ROLE_technician',
                    'MyProvilab_ROLE_technicianAdmin',
                    'MyProvilab_ROLE_salesperson',
                    'MyProvilab_ROLE_controlPlan'
                ]}
            >
                <AnalysisContainer typeAnalysis="FG" />
            </RoleSecureRoute>
            <RoleSecureRoute
                exact
                path={`${match.path}/analysis/rawmaterial`}
                requiredRoles={[
                    'MyProvilab_ROLE_laboratory',
                    'MyProvilab_ROLE_laboratoryAdmin',
                    'MyProvilab_ROLE_customerLabAdmin',
                    'MyProvilab_ROLE_usersAdmin',
                    'MyProvilab_ROLE_technicianAdmin',
                    'MyProvilab_ROLE_salesperson',
                    'MyProvilab_ROLE_controlPlan',
                    'MyProvilab_ROLE_quality',
                ]}
            >
                <AnalysisContainer typeAnalysis="RM" />
            </RoleSecureRoute>
            <RoleSecureRoute
                exact
                path={`${match.path}/analysis/feed`}
                requiredRoles={[
                    'MyProvilab_ROLE_laboratory',
                    'MyProvilab_ROLE_laboratoryAdmin',
                    'MyProvilab_ROLE_customerLabAdmin',
                    'MyProvilab_ROLE_usersAdmin',
                    'MyProvilab_ROLE_technicianAdmin',
                    'MyProvilab_ROLE_salesperson',
                    'MyProvilab_ROLE_controlPlan',
                    'MyProvilab_ROLE_quality',
                ]}
            >
                <AnalysisContainer typeAnalysis="FP" />
            </RoleSecureRoute>
            <SecureRoute exact path={`${match.path}/selectAnalysis`}>
                <SelectAnalysisMobile />
            </SecureRoute>
            <SecureRoute exact path={`${match.path}/analysis`}>
                <AnalysisListContainer />
            </SecureRoute>
            <SecureRoute exact path={`${match.path}/results`}>
                <ResultsListContainer />
            </SecureRoute>
            <SecureRoute path={`${match.path}/export_screen`}>
                <Export />
            </SecureRoute>
            <SecureRoute path={`${match.path}/export_screen`}>
                <Export />
            </SecureRoute>
            <SecureRoute exact path={`${match.path}/analysis/results`}>
                <ResultsContainer />
            </SecureRoute>
            <SecureRoute exact path={`${match.path}/analysis/:laboratoryId/:analysisId`}>
                <ConsultAnalysisContainer key={new Date().getTime().toString()} /> {/* Force reload on parameters change (for additional analysis consultation) */}
            </SecureRoute>
            <RoleSecureRoute
                exact
                path={`${match.path}/analysis/:laboratoryId/:analysisId/edit/:initStep`}
                requiredRoles={[
                    'MyProvilab_ROLE_laboratory',
                    'MyProvilab_ROLE_laboratoryAdmin',
                    'MyProvilab_ROLE_customerLabAdmin',
                    'MyProvilab_ROLE_usersAdmin',
                    'MyProvilab_ROLE_technician',
                    'MyProvilab_ROLE_technicianAdmin',
                    'MyProvilab_ROLE_salesperson',
                    'MyProvilab_ROLE_controlPlan',
                    'MyProvilab_ROLE_quality',
                ]}
            >
                <AnalysisContainer />
            </RoleSecureRoute>
            <RoleSecureRoute
                exact
                path={`${match.path}/pdcs`}
                requiredRoles={['MyProvilab_ROLE_controlPlan', 'MyProvilab_ROLE_usersAdmin']}
            >
                <PdcListContainer />
            </RoleSecureRoute>
            <RoleSecureRoute
                exact
                path={`${match.path}/pdc/use/:laboratoryId/:pdcId/:sampleId`}
                requiredRoles={['MyProvilab_ROLE_controlPlan', 'MyProvilab_ROLE_usersAdmin']}
            >
                <PdcUseContainer />
            </RoleSecureRoute>
            <RoleSecureRoute
                exact
                path={`${match.path}/pdc/:laboratoryId/:pdcId`}
                requiredRoles={['MyProvilab_ROLE_controlPlan', 'MyProvilab_ROLE_usersAdmin']}
            >
                <PdcContainer />
            </RoleSecureRoute>

            <SecureRoute path={`${match.path}/user/profile`}>
                <ProfileContainer />
            </SecureRoute>
            <RoleSecureRoute
                path={`${match.path}/user/breeders`}
                requiredRoles={['MyProvilab_ROLE_technician', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson', 'MyProvilab_ROLE_controlPlan', 'MyProvilab_ROLE_usersAdmin']}
            >
                <FarmerListContainer />
            </RoleSecureRoute>

            {/* Laboratory menu and screens */}
            <SecureRoute path={`${match.path}/laboratory`}>
                <LaboratoryRoutes />
            </SecureRoute>

            {/* Administration menu and screens */}
            <SecureRoute path={`${match.path}/admin`}>
                <AdministrationRoutes />
            </SecureRoute>

            {/* My Matrix menu and screens */}
            <SecureRoute path={`${match.path}/matrix`}>
                <MatrixRoutes />
            </SecureRoute>

            {/* Matrix Management menu and screens */}
            <SecureRoute path={`${match.path}/matrix_management`}>
                <MatrixManagementRoutes />
            </SecureRoute>

            {/* Dashboard results analysis */}
            <SecureRoute path={`${match.path}/dashboard/results_analysis`}>
                <DashboardContainer dashboardName={"results-analysis"} />
            </SecureRoute>

            {/* Dashboard matrix */}
            <SecureRoute path={`${match.path}/dashboard/matrix`}>
                <DashboardContainer dashboardName={"matrix"} />
            </SecureRoute>
        </Switch>
    )
}

export default withTranslation()(AppRouter)
