import { Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/rootReducer";
import React, { useEffect } from 'react'
import InlineLoader from "./InlineLoader";
import { withTranslation, WithTranslation } from 'react-i18next'
import { fetchFamilies, fetchImpropers, fetchLaboratories, fetchLanguages, fetchPatterns, fetchTypes } from '../../state/configSlice'
import AnalysisTable from './AnalysisTable'
import { fetchPdcDA } from '../../state/pdcSlice'
import { fetchEmployees } from '../../state/employeesSlice'
import { fetchFarmers } from '../../state/farmerSlice'
import { fetchCompanies } from '../../state/companiesSlice'


interface AnalysisTableContainerPropsContainer extends WithTranslation {
    currentLaboratoryId: number,
    origin?: 'EXPORT_EXCEL'
}



const AnalysisTableContainer: React.FC<AnalysisTableContainerPropsContainer> = ({t,currentLaboratoryId, origin }) => {
  
    const dispatch = useDispatch()
   // analysis state

   const fetchStatus = useSelector((state: RootState) => state.analysis.analysis.fetchStatus)
   const fetchError = useSelector((state: RootState) => state.analysis.analysis.fetchError)
   
   // Companies state
   const companiesStatus = useSelector((state: RootState) => state.companies.fetchStatus)
   const companiesError = useSelector((state: RootState) => state.companies.fetchError)
   const companiesByLabId = useSelector((state: RootState) => state.companies.byLabId)

   // Families state
   const familiesStatusByLabId = useSelector((state: RootState) => state.config.families.fetchStatusByLabId)
   const familiesError = useSelector((state: RootState) => state.config.families.fetchError)
   const familiesByLabId = useSelector((state: RootState) => state.config.families.byLabId)

   // laboratories state

   const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
   const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
   const laboratoriesError = useSelector((state: RootState) => state.config.laboratories.fetchError)

   // Languages state
   const languagesStatus = useSelector((state: RootState) => state.config.languages.fetchStatus)

   // Employees state
   const employeesStatus = useSelector((state: RootState) => state.employees.fetchStatus)
   const employeesError = useSelector((state: RootState) => state.employees.fetchError)

   // Patterns state
   const patternsStatus = useSelector((state: RootState) => state.config.patterns.fetchStatus)
   const patternsError = useSelector((state: RootState) => state.config.patterns.fetchError)

   // Farmers state
   const farmersStatusByUser = useSelector((state: RootState) => state.farmers.farmer.fetchStatusByUser)
   const farmersError = useSelector((state: RootState) => state.farmers.farmer.fetchError)

   // Types state
   const typesStatus = useSelector((state: RootState) => state.config.types.fetchStatus)
   const typesError = useSelector((state: RootState) => state.config.types.fetchError)

   // Impropers state
   const impropersStatus = useSelector((state: RootState) => state.config.impropers.fetchStatus)
   const impropersError = useSelector((state: RootState) => state.config.impropers.fetchError)
   

   // pdc state
   const pdcStatus = useSelector((state: RootState) => state.pdc.fetchDAStatus)
   const pdcError = useSelector((state: RootState) => state.pdc.fetchError)

   useEffect(() => {
    if (languagesStatus === 'idle') {
        dispatch(fetchLanguages())
    }

    if (laboratoriesStatus === 'idle') {
        dispatch(fetchLaboratories())
    }

    if (pdcStatus === 'idle') {
        dispatch(fetchPdcDA({ page: 0 }))
    }

    if (patternsStatus === 'idle') {
        dispatch(fetchPatterns())
    }

    if (farmersStatusByUser === 'idle') {
        dispatch(fetchFarmers())
    }

    if (typesStatus === 'idle') {
        dispatch(fetchTypes())

    }

    if (employeesStatus === 'idle') {
        dispatch(fetchEmployees())
    }

    if (impropersStatus === 'idle') {
        dispatch(fetchImpropers())
    }

    if (companiesStatus === "idle" || companiesStatus === "succeeded" && (!companiesByLabId || !companiesByLabId[currentLaboratoryId])) {
        dispatch(fetchCompanies(currentLaboratoryId))
    }

    if (familiesStatusByLabId === "idle" || familiesStatusByLabId === "succeeded" && (!familiesByLabId || !familiesByLabId[currentLaboratoryId])) {
        dispatch(fetchFamilies(currentLaboratoryId))
    }


}, [languagesStatus, laboratoriesStatus, companiesStatus, familiesStatusByLabId, pdcStatus, employeesStatus, patternsStatus, farmersStatusByUser, typesStatus, impropersStatus])

    return (

        <Row>{(laboratoriesStatus === 'loading' || pdcStatus === 'loading' || familiesStatusByLabId === 'loading' || fetchStatus === 'loading' || typesStatus === 'loading' || patternsStatus === 'loading' || employeesStatus === 'loading' || companiesStatus === 'loading' || farmersStatusByUser === 'loading' || impropersStatus === 'loading') &&
            <InlineLoader />
        }
            {laboratoriesStatus === 'succeeded' && pdcStatus === 'succeeded' && familiesStatusByLabId === 'succeeded' && fetchStatus === 'succeeded' && typesStatus === 'succeeded' && patternsStatus === 'succeeded' && employeesStatus === 'succeeded' && companiesStatus === 'succeeded' && farmersStatusByUser === 'succeeded' && impropersStatus === 'succeeded' &&
                <AnalysisTable laboratory={laboratories[currentLaboratoryId]} origin= {origin} />
            }
            {(laboratoriesStatus === 'failed' || pdcStatus === 'failed' || familiesStatusByLabId === 'failed' || fetchStatus === 'failed' || typesStatus === 'failed' || patternsStatus === 'failed' || employeesStatus === 'failed' || companiesStatus === 'failed' || farmersStatusByUser === 'failed' || impropersStatus === 'failed') &&
                <Container className="mt-2 bg-white text-danger">
                    {laboratoriesError && <p>{t('analysis_list.table.columns.laboratory') + ' - ' + t('forms_errors.unexpected_error') + ' (' + laboratoriesError + ')'}</p>}
                    {familiesError && <p>{t('analysis_list.table.columns.product') + ' - ' + t('forms_errors.unexpected_error') + ' (' + familiesError + ')'}</p>}
                    {typesError && <p>{t('analysis_list.table.columns.echantillon') + ' - ' + t('forms_errors.unexpected_error') + ' (' + typesError + ')'}</p>}
                    {patternsError && <p>{t('analysis_list.table.columns.motif') + ' - ' + t('forms_errors.unexpected_error') + ' (' + patternsError + ')'}</p>}
                    {employeesError && <p>{t('analysis_list.table.columns.applicant') + ' - ' + t('forms_errors.unexpected_error') + ' (' + employeesError + ')'}</p>}
                    {companiesError && <p>{t('analysis_list.table.columns.nameCustomer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + companiesError + ')'}</p>}
                    {farmersError && <p>{t('analysis_list.table.columns.farmer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + farmersError + ')'}</p>}
                    {impropersError && <p>{t('forms.analysis.impropers.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + impropersError + ')'}</p>}
                    {pdcError && <p>{t('analysis_list.table.columns.pdc') + ' - ' + t('forms_errors.unexpected_error') + ' (' + pdcError + ')'}</p>}
                    {fetchError && <p>{t('analysis_list.see_my_analysis_requests') + ' - ' + t('forms_errors.unexpected_error') + ' (' + fetchError + ')'}</p>}
                </Container>
            }

        </Row>
    );
}

export default withTranslation()(AnalysisTableContainer)


