import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Button } from 'react-bootstrap';
import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg'


interface UploadMatrixMemoFileProp extends WithTranslation {
    uploadAction: any,
    id:any,
    onButtonClick: any
}

const UploadMatrixMemoFile: React.FC<UploadMatrixMemoFileProp> = ({ t, uploadAction, id, onButtonClick }) => {

    const SUPPORTED_TYPES = ["application/pdf"]

    const onMemoFileChange = (file: any) => {
        uploadAction(file);
    };

    const handleButtonClick = () => {
        // Lorsque le bouton est cliqué, déclenchez la fonction onButtonClick passée en tant que prop avec un paramètre
        onButtonClick(id); // Exemple de passage d'un nouveau paramètre
      }


    return (
        <Form.Group controlId={'memo'} className="mb-2" >
            <Button className="p-0 pe-1 text-secondary" variant="link" onClick={handleButtonClick}>
                <Form.Label className="m-0 fw-normal px-1 hover-button">
                    <EditIcon width="24px" height="100%" className="align-text-top" />
                </Form.Label>
            </Button>
            <Form.Control
                style={{ display: 'none' }}
                className='me-1'
                type="file"
                accept={SUPPORTED_TYPES.join(',')}
                onChange={onMemoFileChange}
            />
        </Form.Group>
    )
}

export default withTranslation()(UploadMatrixMemoFile)
