import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Container, Button, Modal, Form, Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { setResultsHeader, setSamplesFilterBy, setSamplesSortBy } from '../../../state/dashboardSlice';
import { Formik, getIn } from 'formik';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';
import * as Yup from 'yup';

interface ModalDashboardParamsAnalytesComponentContainerProps extends WithTranslation {
    dashboardName: string,
    showModal: boolean,
    setShowModal: any
}

const ModalDashboardParamsAnalytesComponent: React.FC<ModalDashboardParamsAnalytesComponentContainerProps> = ({ t, dashboardName, showModal, setShowModal }) => {
    const dispatch = useDispatch()

    // user state
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    // languages
    const languages = useSelector((state: RootState) => state.config.languages.data)
    const language = Object.values(languages).find(lg => lg.id === Number(authUser.language_id))

    // samples state
    const resultsHeader = useSelector((state: RootState) => state.dashboard.resultsHeaders)
    const pagination = useSelector((state: RootState) => state.dashboard.paginationSamples)
    const maxAnalytes = useSelector((state: RootState) => state.manageDashboard.data)[`${dashboardName}#dashboard_analysis_header`]?.properties.max_analytes
    const maxGraphs = useSelector((state: RootState) => state.manageDashboard.data)[`${dashboardName}#dashboard_analysis_header`]?.properties.max_graphs

    const validationSchema = Yup.object().shape({
        analytes: Yup.array().of(Yup.object().shape({
            decimals: Yup.number().required(t('dashboard.errors.decimals_required')),
        }))
    })

    // types for fields
    const resultsFields = () => {
        const fields = {
            analytes: {
                type: [],
                sortable: false
            },
            display_in_graph: {
                type: [],
                sortable: false
            },
            display_in_tables: {
                type: [],
                sortable: false
            },
            decimals: {
                type: [],
                sortable: false
            },
        }
        return fields
    }

    const { ListHeader, filterAndSortList, FilterList } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "dashboard.params.modal_analyte.columns.",
        onlyActive: false,
        size: 'xs',
        fields: resultsFields(),
        reducers: {
            filterBy: setSamplesFilterBy,
            sortBy: setSamplesSortBy
        }
    })

    return (
        <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title><span className="text-primary fw-bold"> {t('dashboard.params.modal_analyte.title')} </span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    validationSchema={dashboardName === 'matrix' ? validationSchema : undefined}
                    initialValues={{
                        analytes: resultsHeader.map((rh) => { return { analyte_id: rh.id.toUpperCase(), display_in_tables: rh.display_in_selection, display_in_graph: rh.display_in_graph, decimals: dashboardName === 'matrix' ? rh.decimals ? rh.decimals : 3 : undefined } })
                    }}
                    onSubmit={(values) => {
                        const arrayForHeaderUpdated: any[] = []
                        resultsHeader.forEach(rh => {
                            arrayForHeaderUpdated.push({ 
                                id: rh.id.toUpperCase(), 
                                name: rh.name, 
                                selected: rh.selected, 
                                display_in_graph: values.analytes.find(a => a.analyte_id.toUpperCase() === rh.id.toUpperCase())?.display_in_graph, 
                                display_in_selection: values.analytes.find(a => a.analyte_id.toUpperCase() === rh.id.toUpperCase())?.display_in_tables, 
                                decimals: values.analytes.find(a => a.analyte_id.toUpperCase() === rh.id.toUpperCase())?.decimals })
                        })
                        dispatch(setResultsHeader(arrayForHeaderUpdated))
                        setShowModal(false)
                    }}
                >
                    {/* Callback function containing Formik state and helpers that handle common form actions */}
                    {function myForm({
                        values,
                        setFieldValue,
                        handleBlur,
                        touched,
                        errors,
                        handleChange,
                        handleSubmit }) {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Container style={{ maxHeight: '400px', overflow: 'auto' }}>
                                    <Table striped hover size='sm' className="bg-white text-center table-fixed table-xs" style={{ maxHeight: '400px' }}>
                                        <thead className='bg-white'>
                                            <tr>
                                                <th className="p-0" />
                                                <th className="p-1">
                                                    <ListHeader name="analytes" />
                                                </th>
                                                <th className="p-1">
                                                    <ListHeader name="display_in_tables" />
                                                </th>
                                                <th className="p-1">
                                                    <ListHeader name="display_in_graph" />
                                                </th>
                                                {dashboardName === 'matrix' &&
                                                    <th className="p-1">
                                                        <ListHeader name="decimals" />
                                                    </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {resultsHeader.length > 0 && resultsHeader.map((header, index) => {
                                                return (
                                                    <tr key={header.id}>
                                                        <td className="p-1 table-hover-hint" />
                                                        <td>{header.name.translations[language && language.code_language ? language.code_language : '']}</td>
                                                        <td>
                                                            <Form.Group className="mb-2" controlId="formDisplayInTables">
                                                                <Form.Check
                                                                    className="mt-1"
                                                                    aria-label={t(`analytes[${index}].display_in_tables`)}
                                                                    type="checkbox"
                                                                    name={`analytes[${index}].display_in_tables`}
                                                                    checked={values.analytes[index].display_in_tables}
                                                                    onChange={() => {
                                                                        if (values.analytes[index].display_in_tables || (!values.analytes[index].display_in_tables && values.analytes.filter(a => a.display_in_tables).length < maxAnalytes)) {
                                                                            setFieldValue(`analytes[${index}].display_in_tables`, !values.analytes[index].display_in_tables)
                                                                            if (values.analytes[index].display_in_tables) {
                                                                                setFieldValue(`analytes[${index}].display_in_graph`, false)
                                                                            }
                                                                        }
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group className="mb-2" controlId="formDisplayInGraph">
                                                                <Form.Check
                                                                    className="mt-1"
                                                                    aria-label={t(`analytes[${index}].display_in_graph`)}
                                                                    type="checkbox"
                                                                    name={`analytes[${index}].display_in_graph`}
                                                                    checked={values.analytes[index].display_in_graph}
                                                                    onChange={() => {
                                                                        if (values.analytes[index].display_in_graph || (!values.analytes[index].display_in_graph && values.analytes.filter(a => a.display_in_graph).length < maxGraphs)) {
                                                                            setFieldValue(`analytes[${index}].display_in_graph`, !values.analytes[index].display_in_graph)
                                                                        }
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    disabled={!values.analytes[index].display_in_tables}
                                                                />
                                                            </Form.Group>
                                                        </td>
                                                        {dashboardName === 'matrix' &&
                                                            <td>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Popover id={`popover-type-${index}`} className={!getIn(touched, `analytes[${index}].decimals`) || typeof getIn(errors, `analytes[${index}].decimals`) !== 'string' ? "d-none" : ""}>
                                                                            <Popover.Body>
                                                                                <Form.Text className="invalid-feedback d-block">{getIn(errors, `analytes[${index}].decimals`)}</Form.Text>
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Form.Group controlId="formDecimals">
                                                                        <Form.Control
                                                                            className="text-center"
                                                                            style={{ borderRadius: '10px' }}
                                                                            placeholder={t('dashboard.params.modal_analyte.columns.select_placeholder')}
                                                                            type="text"
                                                                            name={`analytes[${index}].decimals`}
                                                                            value={values.analytes[index].decimals}
                                                                            onChange={handleChange}
                                                                            isInvalid={getIn(touched, `analytes[${index}].decimals`) && !!getIn(errors, `analytes[${index}].decimals`)}

                                                                        />
                                                                    </Form.Group>
                                                                </OverlayTrigger>
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Container>
                                <Container className='d-flex justify-content-end'>
                                    <Button className="m-1 no-print" type="submit" variant="secondary" > {t('dashboard.params.modal_analyte.validate')} </Button>
                                </Container>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default withTranslation()(ModalDashboardParamsAnalytesComponent)