import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import SearchAnalysis from './components/SearchAnalysis';
import NavBox from '../../common/components/NavBox';
import userHasRequiredRoles from '../../common/auth/userHasRequiredRoles';
import userHasOnlyRole from '../../common/auth/userHasOnlyRole';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import { fetchInvalidAnalysis, filterByReducer } from '../../state/analysisSlice';
import useSecurityRoles from '../../common/auth/useSecurityRoles';


type HomeContainerProps = WithTranslation

const HomeContainer: React.FC<HomeContainerProps> = ({t}) => {

    const dispatch = useDispatch()

    const { nbInvalid, defaultFilters } = useSelector((state: RootState) => state.analysis.analysis)

    useEffect(() => {
        dispatch(fetchInvalidAnalysis())
    }, [])

    const { user: authUser} = useSelector((state: RootState) => state.authUser)

    const { userHasOneRoleLevelLaboratories, userHasOnePdcLevelAdmin } = useSecurityRoles()

    let tabAnalysis: any = []

    if(userHasRequiredRoles(['MyProvilab_ROLE_technician'])) tabAnalysis.push({key: t('nav.fodder'), link: '/secure/analysis/fodder'})
    if(userHasRequiredRoles(['MyProvilab_ROLE_quality'])){
        tabAnalysis.push({key: t('nav.raw_material'), link: '/secure/analysis/rawmaterial'})
        tabAnalysis.push({key: t('nav.feed'), link: '/secure/analysis/feed'})
    }
    if(userHasRequiredRoles(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson'])) tabAnalysis = [{key: t('nav.fodder'), link: '/secure/analysis/fodder'}, {key: t('nav.raw_material'), link: '/secure/analysis/rawmaterial'}, {key: t('nav.feed'), link: '/secure/analysis/feed'}]
    
    return (
        <Container>
            
            {!userHasOnlyRole('MyProvilab_ROLE_nutrition') &&
                <Row className="justify-content-center">
                    <SearchAnalysis />
                </Row>
            }
            
            {userHasRequiredRoles(['MyProvilab_ROLE_controlPlan', 'MyProvilab_ROLE_usersAdmin']) ? (
                <Container>
                <Row className="mt-2">
                    {userHasRequiredRoles(['MyProvilab_ROLE_technician', 'MyProvilab_ROLE_quality', 'MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson']) &&
                        
                            <Col lg>
                                <NavBox 
                                    label={t('nav.new_analysis_request')} 
                                    buttons={tabAnalysis}
                                />
                            </Col>
                    }
                    {(userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']) || (userHasOnePdcLevelAdmin(authUser))) &&
                        
                        <Col lg>
                            <NavBox 
                                label={t('nav.control_plan')} 
                                buttons={[
                                    {key: t('nav.create_control_plan'), link: '/secure/pdc/0/0'},
                                    {key: t('nav.my_control_plans'), link: '/secure/pdcs'},
                                ]}
                            />
                        </Col>
                    }
                    {userHasRequiredRoles([ 'MyProvilab_ROLE_controlPlan']) &&  !userHasRequiredRoles([ 'MyProvilab_ROLE_usersAdmin']) &&  !userHasOnePdcLevelAdmin(authUser) &&
                        
                        <Col lg>
                            <NavBox 
                                label={t('nav.control_plan')} 
                                buttons={[
                                    {key: t('nav.my_control_plans'), link: '/secure/pdcs'},
                                ]}
                            />
                        </Col>
                    }
                </Row>
                <Row className="mt-2">
                    <Col lg>
                        <NavBox 
                            label={t('home.see_my_analysis_requests')} 
                            buttons={[
                                {
                                    key: t('nav.my_analysis_requests_last'), 
                                    link: '/secure/analysis', 
                                    filter: defaultFilters.recent,
                                    filterReducer: filterByReducer
                                },
                                {
                                    key: t('nav.my_analysis_requests_registered'), 
                                    link: '/secure/analysis',
                                    filter: defaultFilters.registered,
                                    filterReducer: filterByReducer
                                },
                                {
                                    key: t('nav.my_analysis_requests_invalid'), 
                                    link: '/secure/analysis', 
                                    filter: defaultFilters.invalid,
                                    filterReducer: filterByReducer,
                                    color: nbInvalid > 0 ? "warning" : "dark"
                                },
                                {
                                    key: t('nav.my_analysis_requests_inprogress'), 
                                    link: '/secure/analysis',
                                    filter: defaultFilters.inprogress,
                                    filterReducer: filterByReducer
                                },
                            ]}
                        />
                    </Col>
                    <Col lg>
                        {userHasRequiredRoles(['MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_usersAdmin']) && (userHasOneRoleLevelLaboratories(authUser) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) ? (
                            <NavBox 
                                label={t('home.see_my_results')} 
                                buttons={[
                                    {
                                        key: t('admin_labo.nav.labels.result_input'), 
                                        link: '/secure/laboratory/result_input',
                                        filter: defaultFilters.done,
                                        filterReducer: filterByReducer
                                    },
                                    {
                                        key: t('admin_labo.nav.labels.handle_nirs'), 
                                        link: '/secure/laboratory/handle-nirs',
                                        filter: defaultFilters.done,
                                        filterReducer: filterByReducer
                                    },
                                    {
                                        key: t('nav.my_analysis_requests_done'), 
                                        link: '/secure/results',
                                        filter: defaultFilters.done,
                                        filterReducer: filterByReducer
                                    },
                                    {
                                        key: t('nav.export_screen'), 
                                        link: '/secure/export_screen',
                                        filter: defaultFilters.done,
                                        filterReducer: filterByReducer
                                    },
                                    {
                                        key: t('nav.dashboard'), 
                                        link: '/secure/dashboard/results_analysis'
                                    }
                                ]}
                            />
                        ) : (
                            <NavBox 
                                label={t('home.see_my_results')} 
                                buttons={[
                                    
                                    {
                                        key: t('nav.my_analysis_requests_done'), 
                                        link: '/secure/results',
                                        filter: defaultFilters.done,
                                        filterReducer: filterByReducer
                                    },
                                    {
                                        key: t('nav.export_screen'), 
                                        link: '/secure/export_screen',
                                        filter: defaultFilters.done,
                                        filterReducer: filterByReducer
                                    },
                                    {
                                        key: t('nav.dashboard'), 
                                        link: '/secure/dashboard/results_analysis'
                                    }
                                ]}
                            />
                        )}
                    </Col>
                </Row>
                </Container>
                ) : (
                    <Container>
                        {!userHasOnlyRole('MyProvilab_ROLE_nutrition') &&
                            <Row className="mt-2">
                                {userHasRequiredRoles(['MyProvilab_ROLE_technician', 'MyProvilab_ROLE_quality', 'MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_technicianAdmin', 'MyProvilab_ROLE_salesperson']) &&
                                    
                                        <Col lg>
                                            <NavBox 
                                                label={t('nav.new_analysis_request')} 
                                                buttons={tabAnalysis}
                                            />
                                        </Col>
                                }
                                <Col lg>
                                    <NavBox 
                                        label={t('home.see_my_analysis_requests')} 
                                        buttons={[
                                            {
                                                key: t('nav.my_analysis_requests_last'), 
                                                link: '/secure/analysis', 
                                                filter: defaultFilters.recent,
                                                filterReducer: filterByReducer
                                            },
                                            {
                                                key: t('nav.my_analysis_requests_registered'), 
                                                link: '/secure/analysis',
                                                filter: defaultFilters.registered,
                                                filterReducer: filterByReducer
                                            },
                                            {
                                                key: t('nav.my_analysis_requests_invalid'), 
                                                link: '/secure/analysis', 
                                                filter: defaultFilters.invalid,
                                                filterReducer: filterByReducer,
                                                color: nbInvalid > 0 ? "warning" : "dark"
                                            },
                                            {
                                                key: t('nav.my_analysis_requests_inprogress'), 
                                                link: '/secure/analysis',
                                                filter: defaultFilters.inprogress,
                                                filterReducer: filterByReducer
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col lg>
                                    {userHasRequiredRoles(['MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_usersAdmin']) && (userHasOneRoleLevelLaboratories(authUser) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) ? (
                                        <NavBox 
                                            label={t('home.see_my_results')} 
                                            buttons={[
                                                {
                                                    key: t('admin_labo.nav.labels.result_input'), 
                                                    link: '/secure/laboratory/result_input',
                                                    filter: defaultFilters.done,
                                                    filterReducer: filterByReducer
                                                },
                                                {
                                                    key: t('admin_labo.nav.labels.handle_nirs'), 
                                                    link: '/secure/laboratory/handle-nirs',
                                                    filter: defaultFilters.done,
                                                    filterReducer: filterByReducer
                                                },
                                                {
                                                    key: t('nav.my_analysis_requests_done'), 
                                                    link: '/secure/results',
                                                    filter: defaultFilters.done,
                                                    filterReducer: filterByReducer
                                                },
                                                {
                                                    key: t('nav.export_screen'), 
                                                    link: '/secure/export_screen',
                                                    filter: defaultFilters.done,
                                                    filterReducer: filterByReducer
                                                },
                                                {
                                                    key: t('nav.dashboard'), 
                                                    link: '/secure/dashboard/results_analysis'
                                                }
                                            ]}
                                        />
                                    ) : (
                                        <NavBox 
                                            label={t('home.see_my_results')} 
                                            buttons={[
                                                {
                                                    key: t('nav.my_analysis_requests_done'), 
                                                    link: '/secure/results',
                                                    filter: defaultFilters.done,
                                                    filterReducer: filterByReducer
                                                },
                                                {
                                                    key: t('nav.export_screen'), 
                                                    link: '/secure/export_screen',
                                                    filter: defaultFilters.done,
                                                    filterReducer: filterByReducer
                                                },
                                                {
                                                    key: t('nav.dashboard'), 
                                                    link: '/secure/dashboard/results_analysis'
                                                }
                                            ]}
                                        />
                                    )}
                                </Col>
                            </Row>
                    }
                </Container>
                )
            }
            <Container className={ !userHasOnlyRole('MyProvilab_ROLE_nutrition') ? "" : "mt-5" }>
                <Row className="mt-2">
                    {userHasRequiredRoles(['MyProvilab_ROLE_nutrition', 'MyProvilab_ROLE_usersAdmin']) ? (
                        <Col lg className="">
                            <NavBox 
                                label={t('nav.manage_matrix')}
                                buttons={[
                                    {key: t('nav.new_matrix') , link: '/secure/matrix/new_matrix'},
                                    {key: t('nav.import_matrix'), uploadMatrix:true},
                                    {key: t('nav.my_matrix'), link: '/secure/matrix/my_matrix'},
                                    {key: t('nav.dashboard_matrix'), link: '/secure/dashboard/matrix', resetMatrixFields: true}

                                ]}
                            />
                        </Col>
                    ) : (null)}
                    {userHasRequiredRoles(['MyProvilab_ROLE_nutrition']) && authUser.formulations.length > 0 || userHasRequiredRoles(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'])  && (userHasOneRoleLevelLaboratories(authUser) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) ? (
                        <Col lg className="">
                            <NavBox 
                                label={t('nav.admin')}
                                buttons={[
                                    {key: t('nav.laboratory'), link: '/secure/laboratory', hidden: !(userHasRequiredRoles(['MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'])  && (userHasOneRoleLevelLaboratories(authUser) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])))},
                                    {key: t('nav.admin') , link: '/secure/admin', hidden: !(userHasRequiredRoles(['MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin', 'MyProvilab_ROLE_usersAdmin'])  && (userHasOneRoleLevelLaboratories(authUser) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])))},
                                    {key: t('nav.matrix_management'), link: '/secure/matrix_management', hidden: !(userHasRequiredRoles(['MyProvilab_ROLE_nutrition']) && authUser.formulations.length > 0 || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']) && (userHasOneRoleLevelLaboratories(authUser) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])))}
                                ]}
                            />
                        </Col>
                    ) : (null)}
                </Row>
            </Container>
        </Container>
    )
}

export default withTranslation()(HomeContainer)