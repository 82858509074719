import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import PublishApi, { ReportTemplates } from '../api/publishApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';

interface CurrentState {
    data: Data<ReportTemplates>
    filtered: Array<string>
    pagination: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postError: string | undefined
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    byCompany: {},
    fetchStatus: 'idle',
    fetchError: undefined,
    singleFetchStatus: 'idle',
    singleFetchError: undefined,
    postStatus: 'idle',
    postError: undefined
} as CurrentState


export const fetchReportTemplates = createAsyncThunk(
    'ReportTemplates',
    async () => {
        const response = await PublishApi.getInstance().getReportTemplates()
        return response as Array<ReportTemplates>
    }
)

export const deleteSingleReportTemplate = createAsyncThunk(
    'ReportTemplates/deleteReportTemplate',
    async (name: string) => {
        await PublishApi.getInstance().deleteReportTemplate(name)
        return
    }
)

export const deleteSingleTheme = createAsyncThunk(
    'ReportTemplates/deleteReportTemplateTheme',
    async ({ name, theme }: { name: string, theme: string }) => {
        await PublishApi.getInstance().deleteReportTemplateTheme(name, theme)
        return
    }
)


const ReportTemplateslice = createSlice({
    name: 'reportTemplates',
    initialState,
    reducers: {
        refreshReportsTemplate(state: CurrentState) {
            state.data = {}
            state.fetchStatus = 'loading'
        },
        updateReportTemplate(state: CurrentState, action: PayloadAction<ReportTemplates>) {
            const item = action.payload
            if (item.name)
                state.data[item.name] = item
        },
        deleteReportTemplate(state: CurrentState, action: PayloadAction<string>) {
            const name = action.payload
            if (name) {
                delete state.data[name]
                const index = state.filtered.indexOf(name)
                if (index > -1) {
                    state.filtered.splice(index, 1)
                }
            }
        },
        deleteTheme(state: CurrentState, action: PayloadAction<{ name: string, theme: string }>) {
            const item = action.payload
            if (item.name && state.data[item.name] && item.theme) {
                const index = state.data[item.name].themes.findIndex(theme => (theme.name === item.theme))
                if (index > -1) {
                    state.data[item.name].themes.splice(index, 1)
                }
            }
        },
        setFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload
        },
        setFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy
        },
        setSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchReportTemplates.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchReportTemplates.fulfilled, (state, action) => {
            action.payload.forEach((item: ReportTemplates) => {
                state.data[item.name] = item
            })
            state.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchReportTemplates.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
    }
})

export const {
    updateReportTemplate,
    deleteReportTemplate,
    setFiltered,
    setFilterBy,
    deleteTheme,
    setSortBy,
    refreshReportsTemplate,
} = ReportTemplateslice.actions

export default ReportTemplateslice.reducer