import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import UsersApi, {InternalUser, CompaniesIds, FormulationsIds} from '../api/usersApi';
import ConfigApi, { Laboratory, TresholdsColors } from '../api/configApi';

export interface AuthUserProfile {
    name: string
    email: string
    external_id: string
    picture?: string
}

export interface CurrentUser extends InternalUser {
    locale: string
}

export interface Theme {
    laboratory_id: number
    themePrimary: string
    themeSecondary: string
    themePrimaryLight: string
    footerLink: string
    footerLinkLabel: string
    headerLogo: string | undefined
    footerLogo: string | undefined
    themeThresholds?: {[key in TresholdsColors]: string}
}

interface CurrentState {
    user: CurrentUser
    theme: Theme
    authCompanies: Array<CompaniesIds>
    authFormulations: Array<FormulationsIds>
    authCustomers: Array<CompaniesIds>
    fetchStatusThemeThresholds: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchStatusTheme: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    session: {
        expiresAt: string|undefined
        triggerRefresh: boolean
    }
    authCurLabId: number
}

const initialState = {
    user: {
        id: 0,
        external_id: "",
        firstname: "",
        lastname: "",
        code: "",
        disabled: false,
        employee_id: 0,
        email: "",
        locale: "",
        language_id: 1,
        laboratories: [],
        owned_laboratories: [],
        customers: [],
        companies: [],
        formulations: [],
        sample_notifs: [],
        control_plan_notifs: [],
        role_levels: [],
        pdc_levels: [],
        all_customers_laboratories: [],
        additional_customers: [],
        mail_partial_attachment: false,
    },
    theme: {
        laboratory_id: 0,
        themePrimary: "",
        themeSecondary: "",
        themePrimaryLight: "",
        footerLink: "",
        footerLinkLabel: "",
        headerLogo: undefined,
        footerLogo: undefined,
    },
    authCompanies: [],
    authFormulations: [],
    authCustomers: [],
    fetchStatusTheme: 'idle',
    fetchStatusThemeThresholds: 'idle',
    fetchStatus: 'idle',
    fetchError: undefined,
    session: {
        expiresAt: undefined,
        triggerRefresh: false
    },
    authCurLabId: 0
  } as CurrentState

export const userLogin = createAsyncThunk(
    'user/userLogin', 
    async (auth_user: AuthUserProfile, {dispatch}) => {
        let retUser: CurrentUser|undefined
        try {
            const internalUser = await UsersApi.getInstance().getInternalUserByExternalId(auth_user.external_id)
            const locale = (await ConfigApi.getLanguageById(internalUser.language_id)).code_language

            retUser = {
                ...internalUser,
                external_id: auth_user.external_id,
                email: auth_user.email,
                locale: locale
            }
            dispatch(fetchTheme(retUser.role_levels[0].laboratory_id))
        } catch (err) {
            retUser = {
                id: 0,
                external_id: auth_user.external_id,
                firstname: auth_user.name,
                lastname: "",
                code: "",
                disabled: false,
                employee_id: 0,
                email: auth_user.email,
                locale: 'fr',
                language_id: 1,
                laboratories: [],
                owned_laboratories: [],
                customers: [],
                companies: [],
                formulations: [],
                sample_notifs: [],
                control_plan_notifs: [],
                role_levels: [],
                pdc_levels: [],
                all_customers_laboratories: [],
                additional_customers: [],
                mail_partial_attachment: false
            }
        }
        return retUser
    }
)

export const fetchTheme = createAsyncThunk(
    'config/fetchTheme', 
    async (laboratory_id: number) => {
        const response = await ConfigApi.getInstance().getLaboratoryById(laboratory_id)
        return response as Laboratory
    }
)

export const fetchThemeThresholds = createAsyncThunk(
    'config/fetchThemeThresholds', 
    async (laboratory_id: number) => {
        const response = await ConfigApi.getInstance().getLaboratoryById(laboratory_id)
        return response as Laboratory
    }
)

export const setAuthCurLabId = createAsyncThunk(
    'config/setAuthCurLabId',
    async (laboratory_id: number, {dispatch}) => {
        dispatch(authCurLabIdReducer(laboratory_id))
        dispatch(fetchThemeThresholds(laboratory_id))
    }
)

const authUserSlice = createSlice({
    name: 'authUser',
    initialState,
    reducers: {
        userLogout(state) {
            state.user = initialState.user
        },
        userUpdate(state, action: PayloadAction<CurrentUser>) {
            const  user  = action.payload
            state.user = user
            state.authCompanies = [ ...state.user.companies ]
            state.authCustomers = [ ...state.user.customers ]
            if (state.user.additional_customers){
                state.authCustomers = [ ...state.user.additional_customers ]

            }
            state.authCurLabId = state.user.role_levels[0].laboratory_id
        },
        setSessionExpiresAt(state, action:PayloadAction<string>) {
            state.session.triggerRefresh = false
            state.session.expiresAt =action.payload

        },
        triggerRefreshSession(state) {
            state.session.triggerRefresh = true
        },
        themeReducer(state, action: PayloadAction<Laboratory>){
            const item = action.payload
            if (item.id && item.id === state.theme.laboratory_id)
                state.theme = { 
                    laboratory_id: item.id, 
                    themePrimary: item.theme_primary, 
                    themeSecondary: item.theme_secondary, 
                    themePrimaryLight: item.theme_primary_light, 
                    footerLink: item.footer_link, 
                    footerLinkLabel: item.footer_link_label, 
                    headerLogo: item.header_logo, 
                    footerLogo: item.footer_logo,
                    themeThresholds: item.theme_thresholds
                }
        },
        authCurLabIdReducer(state, action: PayloadAction<number>) {
            state.authCurLabId = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(userLogin.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(userLogin.fulfilled, (state, action) => {
            state.fetchStatus = 'succeeded'
            state.user = action.payload
            state.authCompanies = [ ...state.user.companies ]
            state.authCustomers = [ ...state.user.customers ]
            if (state.user.additional_customers){
                state.authCustomers = [ ...state.user.additional_customers, ...state.authCustomers ]

            }
            state.authCurLabId = state.user.role_levels[0].laboratory_id
        })
        builder.addCase(userLogin.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
        builder.addCase(fetchTheme.pending, (state) => {
            state.fetchStatusTheme = 'loading'
        })
        builder.addCase(fetchTheme.fulfilled, (state, action) => {
            state.fetchStatusTheme = 'succeeded'
            const item = action.payload
            if (item) {
                state.theme = { 
                    laboratory_id: item.id, 
                    themePrimary: item.theme_primary, 
                    themeSecondary: item.theme_secondary, 
                    themePrimaryLight: item.theme_primary_light, 
                    footerLink: item.footer_link, 
                    footerLinkLabel: item.footer_link_label, 
                    headerLogo: item.header_logo, 
                    footerLogo: item.footer_logo,
                    themeThresholds: item.theme_thresholds
                }
            }
        })
        builder.addCase(fetchTheme.rejected, (state) => {
            state.fetchStatusTheme = 'failed'
        })
        builder.addCase(fetchThemeThresholds.pending, (state) => {
            state.fetchStatusThemeThresholds = 'loading'
        })
        builder.addCase(fetchThemeThresholds.fulfilled, (state, action) => {
            state.fetchStatusThemeThresholds = 'succeeded'
            const item = action.payload
            if (item) {
                state.theme = { 
                    laboratory_id: item.id, 
                    themePrimary: item.theme_primary, 
                    themeSecondary: item.theme_secondary, 
                    themePrimaryLight: item.theme_primary_light, 
                    footerLink: item.footer_link, 
                    footerLinkLabel: item.footer_link_label, 
                    headerLogo: item.header_logo, 
                    footerLogo: item.footer_logo,
                    themeThresholds: item.theme_thresholds
                }
            }
        })
        builder.addCase(fetchThemeThresholds.rejected, (state) => {
            state.fetchStatusThemeThresholds = 'failed'
        })
    }
  })

  export const {
    userLogout,
    userUpdate,
    setSessionExpiresAt,
    triggerRefreshSession,
    themeReducer,
    authCurLabIdReducer
  } = authUserSlice.actions

  export default authUserSlice.reducer
