import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Modal, Row, Table, OverlayTrigger, Popover } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CheckedIcon } from 'bootstrap-icons/icons/check-square.svg'
import { ReactComponent as UncheckedIcon } from 'bootstrap-icons/icons/square.svg'
import { ReactComponent as ArchiveIcon } from 'bootstrap-icons/icons/folder2-open.svg'
import { ReactComponent as CalculateIcon } from 'bootstrap-icons/icons/calculator.svg'
import { ReactComponent as UpdateIcon } from 'bootstrap-icons/icons/arrow-repeat.svg'
import { ReactComponent as DashboardIcon } from 'bootstrap-icons/icons/bar-chart-fill.svg'
import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg'
import useFilterToolkit from "../../../../common/utils/useFilterToolkit";
import { RootState } from "../../../../state/rootReducer";
import CustomPagination from "../../../../common/components/CustomPagination";
import { AppDispatch } from "../../../../state/store";
import MatrixApi, { BodyDeleteMatrices, MatrixKey, ValorizationResponse } from "../../../../api/matrixApi";
import { archiveMatrix, deleteMatrices, fetchMatrices, fetchSingleMatrice, filterByReducer, requestPage, setNbByPage, sortByReducer } from "../../../../state/matrixSlice";
import ButtonHeaderList from '../../../../common/components/ButtonHeaderList';
import { useHistory } from "react-router-dom";
import ModalMatrixValorization from "./ModalMatrixValorization";
import InlineLoader from "../../../../common/components/InlineLoader";
import ModalExportMatrix from "../../../../common/components/ModalExportMatrix";
import InlineLoaderWithoutText from "../../../../common/components/InlineLoaderWithoutText";
import { postMatrixJob } from "../../../../state/matrixJobsSlice";
import { resetMatrixFields, setMatrixFields } from "../../../../state/dashboardSlice";

type MatrixListProps = WithTranslation

const MatrixList: React.FC<MatrixListProps> = ({ t }) => {
    const dispatch = useDispatch<AppDispatch>();

    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    // Language state
    const { allowedPageSizes } = useSelector((state: RootState) => state.local);

    // matrix state
    const matrices = useSelector((state: RootState) => state.matrix.matrix.data)
    const pagination = useSelector((state: RootState) => state.matrix.matrix.pagination);
    const deleteStatus = useSelector((state: RootState) => state.matrix.matrix.deleteStatus);
    const deleteError = useSelector((state: RootState) => state.matrix.matrix.deleteError);
    const postJobStatus = useSelector((state: RootState) => state.matrixJobs.postStatus)

    //preferences fields
    const preferences = useSelector((state: RootState) => state.preferences.data)
    const matrixFields = useSelector((state: RootState) => state.dashboard.matrixFields)

    const history = useHistory()

    // company state
    const companies = useSelector((state: RootState) => state.companies.data)

    // employee state
    const employees = useSelector((state: RootState) => state.employees.data)

    // local states
    const [isChecked, setIsChecked] = useState(false);
    const [selectedMatrixIds, setSelectedMatrixIds] = useState<MatrixKey[]>([])
    const handleCloseAction = () => setShowAction(false);
    const handleShowAction = () => setShowAction(true);
    const handleCloseCompaniesModal = () => setShowCompaniesModal(false);
    const [showAction, setShowAction] = useState(false);
    const [showCompaniesModal, setShowCompaniesModal] = useState(false);
    const [messageAction, setMessageAction] = useState("");
    const [messageCompaniesModal, setMessageCompaniesModal] = useState("");

    const [showModalValo, setShowModalValo] = useState(false)

    const [showModalExport, setShowModalExport] = useState(false)
    const [buttonType, setButtonType] = useState<'export' | 'calculate' | null>(null)

    const [isCalculating, setIsCalculating] = useState(false)
    const [loadingStates, setLoadingStates] = useState<{[key: string]: boolean}>({});
    


    // valorization

    const [valoRes, setValoRes] = useState<ValorizationResponse[]>([])

    // Filter
    const { ListHeader, filterAndSortList, FilterList } = useFilterToolkit(
        pagination.filterBy,
        pagination.sortBy,
        {
            i18nPrefix: "matrix.matrix.columns.",
            onlyActive: false,
            fields: {
                company_id: { type: ["filter"], sortable: true },
                export_code: { type: ["filter"], sortable: true },
                version: { type: ["filter"], sortable: true },
                category: { type: ["filter"], sortable: true },
                sub_category: { type: ["filter"], sortable: true },
                raw_material: { type: ["filter"], sortable: true },
                customer_name: { type: ["filter"], sortable: true },
                matrix_status: {
                    type: ["list"],
                    sortable: true,
                    inOpt: {
                        t: {
                            tPrefix: "matrix.matrix.status.",
                            elements: [
                                'In progress',
                                'Autocalc issue',
                                'Autocalc integration issue',
                                'Administration issue',
                                'Pending Autocalc',
                                'Archived',
                                'Ready',
                                'Active'
                            ]
                        }
                    },
                },
                jcode: { type: ["filter"], sortable: true },
                last_update_by: { type: ["filter"], sortable: true },
                last_update: { type: ["date"], sortable: true },
                last_export: { type: ["date"], sortable: true },
            },
            reducers: {
                filterBy: filterByReducer,
                sortBy: sortByReducer,
            },
        }
    );

    const resetSelected = () => {
        setSelectedMatrixIds([])
        setIsChecked(false)
    }

    useEffect(() => {
        resetSelected()
    }, [pagination.filterBy, pagination.sortBy])

    useEffect(() => {
        dispatch(resetMatrixFields())
    }, [])

    const selectAll = (isChecked: boolean) => {
        setIsChecked(isChecked)
        if (isChecked) {
            if (pagination.pages[pagination.currentPage]) {
                setSelectedMatrixIds(pagination.pages[pagination.currentPage].ids)
            }
        } else {
            setSelectedMatrixIds([])
        }
    }

    const toggleSelected = (matrixKey: MatrixKey) => {
        if (selectedMatrixIds.find(k => k.company_id === matrixKey.company_id && k.export_code === matrixKey.export_code && k.version === matrixKey.version)) {
            setSelectedMatrixIds(selectedMatrixIds.filter(key => !(key.company_id === matrixKey.company_id && key.export_code === matrixKey.export_code && key.version === matrixKey.version)))
        } else {
            setSelectedMatrixIds([...selectedMatrixIds, matrixKey])
        }
    }

    interface StringMap {
        [key: string]: string
    }

    const statusVariantMap: StringMap = {
        'In progress': "primary",
        'Autocalc issue': "warning",
        'Autocalc integration issue': "warning",
        'Administration issue': "warning",
        'Pending Autocalc': "primary-light",
        'Archived': "dark",
        'Ready': "secondary",
        'Active': "success"
    }
    const reduceCompanies = () => {
        const groupedMatrix: { [companyId: number]: MatrixKey[] } = groupMatrixByCompanyIds()
        return Object.entries(groupedMatrix).map(
            key => {
                return (<tr key={key[0]} >
                    <td>{companies[key[0]].name}</td>
                </tr>)
            }
        )
    }

    const groupMatrixByCompanyIds = () => {
        const groupedMatrix = selectedMatrixIds.reduce((acc: any, matrixId) => {
            const companyId = matrixId.company_id;

            if (!acc[companyId]) {
                acc[companyId] = [];
            }

            acc[companyId].push(matrixId);

            return acc;
        }, {});
        return groupedMatrix
    }

    const handleCalculate = async () => {
        setIsCalculating(true)
        const valoResArray: ValorizationResponse[] = []
        const groupedMatrixByCompanyIds: { [companyId: number]: MatrixKey[] } = groupMatrixByCompanyIds()
        for (const companyId in groupedMatrixByCompanyIds) {
            if (groupedMatrixByCompanyIds.hasOwnProperty(companyId)) {

                const exportCodes: string[] = []

                groupedMatrixByCompanyIds[companyId].forEach(
                    key => {
                        exportCodes.push(key.export_code)
                    }
                )
                const valoResTemp: ValorizationResponse = await MatrixApi.getInstance().valorizeMatrix({
                    company_id: Number(companyId),
                    export_codes: exportCodes
                })

                valoResTemp.matrices.forEach(m => {
                    dispatch(fetchSingleMatrice({
                        companyId: String(companyId), exportCode: m.export_code, version: String(m.version)
                    }))
                })

                valoResArray.push(valoResTemp)
            }
            setValoRes(valoResArray)
            setShowModalValo(true)
            setIsCalculating(false)
        }
        dispatch(fetchMatrices({ page: 1 }));        
    }

    const handleAction = async (type: 'export' | 'calculate' | 'download' | 'delete') => {

        if (selectedMatrixIds.length <= 0) {
            setMessageAction(t('matrix.matrix.action_modal.no_selected_matrices'))
            handleShowAction()

        } else if (type === 'delete' && selectedMatrixIds.filter((matrixId) => ['Archived', 'Active', 'Pending Autocalc'].includes(matrices[`${matrixId.company_id}#${matrixId.export_code}#${matrixId.version}`].matrix_status)).length > 0) {
            setMessageAction(t('matrix.matrix.action_modal.invalid_status'))
            handleShowAction()

        } else if  (!['calculate','export'].includes(type) && selectedMatrixIds.filter((matrixId) => matrixId.company_id === selectedMatrixIds[0].company_id).length !== selectedMatrixIds.length) {

            setMessageAction(t('matrix.matrix.action_modal.multiple_companies'))
            handleShowAction()

        } else if (type === 'delete') {
            const body: BodyDeleteMatrices = {
                company_id: selectedMatrixIds[0].company_id,
                matrices: selectedMatrixIds.map((matrixId) => { return { export_code: matrixId.export_code, version: matrixId.version } })
            }
            dispatch(deleteMatrices({ body }))
            setSelectedMatrixIds([])

        } else if (type === 'calculate') {

            if (selectedMatrixIds.filter((matrixId) => matrixId.company_id === selectedMatrixIds[0].company_id).length !== selectedMatrixIds.length) {

                setMessageCompaniesModal(t('matrix.matrix.companies_modal.multiple_companies'))
                setShowCompaniesModal(true)
                setButtonType('calculate')
            } else {
                handleCalculate()
            }
        }
        else if (type === 'download') {
            // formData object for job post
            const formData = new FormData();
            formData.append("type", "export");
            formData.append("filter[company_id]", selectedMatrixIds[0].company_id.toString());
            selectedMatrixIds.forEach((matrix, index) => {
                formData.append('filter[matrices]['+ index +'][export_code]', matrix.export_code)
                formData.append('filter[matrices]['+ index +'][version]', matrix.version.toString())
            })
            formData.append("output[export_name]", '');
            formData.append("output[excel_format]", 'inputs');

            dispatch(postMatrixJob(formData))
        }

        else if (type === 'export'){
            if (selectedMatrixIds.filter((matrixId) => !['Archived', 'Active'].includes(matrices[`${matrixId.company_id}#${matrixId.export_code}#${matrixId.version}`].matrix_status)).length > 0) {
                setMessageAction(t('matrix.matrix.action_modal.invalid_status'))
                handleShowAction()
             }
            else if (selectedMatrixIds.filter((matrixId) => matrixId.company_id === selectedMatrixIds[0].company_id).length !== selectedMatrixIds.length) {

                setMessageCompaniesModal(t('matrix.matrix.companies_modal.multiple_companies'))
                setShowCompaniesModal(true)
                setButtonType('export')

            } 
           
            else {
                setShowModalExport(true)
            }
        }
    }

    return (
        <Container className="mt-2 container-xxl">
            <Modal size="lg" show={showAction} onHide={handleCloseAction}>
                <Modal.Header>
                    <Modal.Title><span className="text-primary fw-bold"> {t('matrix.matrix.action_modal.title')} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{messageAction}</p>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showCompaniesModal} onHide={handleCloseCompaniesModal}>
                <Modal.Header>
                    <Modal.Title><span className="text-primary fw-bold"> {t('matrix.matrix.companies_modal.title')} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{messageCompaniesModal}</p>
                    {reduceCompanies()}
                    <p></p>
                    {buttonType === 'calculate' &&
                        <Button className="ms-2 col-auto" size="sm" variant="secondary" type="button" onClick={async (e) => {
                            handleCalculate()
                            handleCloseCompaniesModal()
                        }}>
                            <span className="px-1">
                                {t('buttons.calculate')}
                            </span>
                        </Button>
                    }

                    {buttonType === 'export' &&
                        <Button className="ms-2 col-auto" size="sm" variant="secondary" type="button" onClick={async () => {
                            setShowModalExport(true)
                            handleCloseCompaniesModal()
                        }}>
                            <span className="px-1">
                                {t('buttons.export')}
                            </span>
                        </Button>
                    }

                    <Button className="ms-2 col-auto" size="sm" variant="secondary" type="button" onClick={async (e) => {
                        handleCloseCompaniesModal()
                    }}>
                        <span className="px-1">
                            {t('buttons.cancel')}
                        </span>
                    </Button>
                </Modal.Body>
            </Modal>

            <ModalMatrixValorization valoRes={valoRes} setShowModalValo={setShowModalValo} showModalValo={showModalValo} />

            <ModalExportMatrix selectedMatricesIds={selectedMatrixIds} setShowModal={setShowModalExport} showModal={showModalExport} />

            <Row className="mt-2 mx-1">
                <Col>
                    <FilterList />
                </Col>
                <Col lg={"auto"}>
                    <Row>
                        <OverlayTrigger
                            placement={"left"}
                            overlay={
                                <Popover id={`popover-calculate`}>
                                    <Popover.Body>
                                        {t('matrix.matrix.help.calculate_multiple')}
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <Col lg={"auto"}>
                                {!isCalculating &&
                                    <Button size="sm" variant="secondary" type="button" onClick={async (e) => {
                                        handleAction('calculate')
                                    }}>
                                        <span className="px-1">
                                            {t('buttons.calculate')}
                                        </span>
                                    </Button>
                                }
                                {isCalculating &&
                                    <InlineLoader />
                                }
                            </Col>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement={"left"}
                            overlay={
                                <Popover id={`popover-calculate`}>
                                    <Popover.Body>
                                        {t('matrix.matrix.help.export_multiple')}
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <Col lg={"auto"}>
                                <Button size="sm" variant="secondary" type="button" onClick={() => { handleAction('export') }}>
                                    <span className="px-1">
                                        {t('buttons.export')}
                                    </span>
                                </Button>
                            </Col>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement={"left"}
                            overlay={
                                <Popover id={`popover-calculate`}>
                                    <Popover.Body>
                                        {t('matrix.matrix.help.download_multiple')}
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <Col lg={"auto"}>
                                {(postJobStatus === 'idle' || postJobStatus === 'succeeded' || postJobStatus === 'failed') &&
                                    <Button size="sm" variant="secondary" type="button" onClick={() => { handleAction('download') }}>
                                        <span className="px-1">
                                            {t('buttons.download')}
                                        </span>
                                    </Button>
                                }
                                {postJobStatus === 'loading' &&
                                    <InlineLoader />
                                }
                            </Col>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement={"left"}
                            overlay={
                                <Popover id={`popover-calculate`}>
                                    <Popover.Body>
                                        {t('matrix.matrix.help.delete_multiple')}
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <Col lg={"auto"}>
                                <Button size="sm" variant="secondary" type="button" onClick={() => { handleAction('delete') }}>
                                    <span className="px-1">
                                        {t('buttons.delete')}
                                    </span>
                                </Button>
                            </Col>
                        </OverlayTrigger>
                    </Row>
                </Col>
            </Row>

            {deleteStatus === 'failed' &&
                <Row className="mt-2 mx-1">
                    <p className="text-danger">{deleteError}</p>
                </Row>
            }


            <Table
                striped
                hover
                responsive="lg"
                className="mt-2 bg-white text-center reduc-size"
            >
                <thead>
                    <tr>
                        <th className="p-1" />
                        <th>
                            <ListHeader name="export_code" />
                        </th>
                        <th>
                            <ListHeader name="version" />
                        </th>
                        <th>
                            <ListHeader name="category" />
                        </th>
                        <th>
                            <ListHeader name="sub_category" />
                        </th>
                        <th>
                            <ListHeader name="raw_material" />
                        </th>
                        <th>
                            <ListHeader name="jcode" />
                        </th>
                        <th>
                            <ListHeader name="customer_name" />
                        </th>
                        <th>
                            <ListHeader name="matrix_status" />
                        </th>
                        <th>
                            <ListHeader name="last_update" />
                        </th>
                        <th>
                            <ListHeader name="last_update_by" />
                        </th>
                        <th>
                            <ListHeader name="company_id" />
                        </th>
                        <th>
                            <ListHeader name="last_export" />
                        </th>
                        <th className="actions">{t("matrix.matrix.columns.actions")}</th>

                        <th className="p-1">
                            <ButtonHeaderList
                                title={""}
                                name="select"
                                type={["check"]}
                                onClickSelect={selectAll}
                                isChecked={isChecked}

                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {pagination.pages[pagination.currentPage] && !pagination.pages[pagination.currentPage].fetching && pagination.pages[pagination.currentPage].ids.map((key) => {
                        const matrix = matrices[`${key.company_id}#${key.export_code}#${key.version}`]
                        return (
                            <tr key={`${key.company_id}#${key.export_code}#${key.version}`} className="align-middle" onClick={() => { history.push(`/secure/matrix/${matrix.template_id}/${key.company_id}/${encodeURIComponent(key.export_code)}/${key.version}`) }}>
                                <td className="p-1 table-hover-hint" />
                                <td className="text-primary fw-bold">
                                    {matrix.export_code}
                                </td>
                                <td>{matrix.version}</td>
                                <td>{matrix.category}</td>
                                <td>{matrix.sub_category}</td>
                                <td>{matrix.raw_material}</td>
                                <td>{matrix.jcode}</td>
                                <td>{matrix.customer_name}</td>
                                <td>
                                    <Badge bg={statusVariantMap[matrix.matrix_status]}>
                                        <span>{t('matrix.matrix.status.' + matrix.matrix_status)}</span>
                                    </Badge>
                                </td>
                                <td>{matrix.last_update ? new Intl.DateTimeFormat(authUser.locale).format(new Date(matrix.last_update)) : ""}</td>
                                <td>{employees[matrix.last_update_by] ? `${employees[matrix.last_update_by].firstname} ${employees[matrix.last_update_by].lastname}` : ''}</td>
                                <td>{companies[matrix.company_id] ? companies[matrix.company_id].name : ''}</td>
                                <td>{matrix.last_export ? new Intl.DateTimeFormat(authUser.locale).format(new Date(matrix.last_export)) : ""}</td>
                                <td className="text-nowrap">
                                    {(
                                        ['In progress', 'Ready', 'Autocalc issue', 'Autocalc integration issue', 'Administration issue'].includes(matrix.matrix_status)
                                    ) &&
                                        <OverlayTrigger
                                            placement={"left"}
                                            overlay={
                                                <Popover id={`popover-edit`}>
                                                    <Popover.Body>
                                                        {t('matrix.matrix.help.edit')}
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <Button className="p-0 pe-1 text-secondary" variant="link" onClick={(e) => { e.stopPropagation(); history.push(`/secure/matrix/edit_matrix/edition/${matrix.template_id}/${matrix.company_id}/${encodeURIComponent(matrix.export_code)}/${matrix.version}`) }} >
                                                {/* Edit */}
                                                <EditIcon width="24px" height="100%" className="align-text-top" />
                                            </Button>
                                        </OverlayTrigger>
                                    }
                                    {(
                                        ['Ready', 'Autocalc issue', 'Autocalc integration issue', 'Administration issue', 'Active', 'Archived'].includes(matrix.matrix_status)
                                    ) &&
                                        <OverlayTrigger
                                            placement={"left"}
                                            overlay={
                                                <Popover id={`popover-calculate`}>
                                                    <Popover.Body>
                                                        {t('matrix.matrix.help.calculate')}
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <Button className="p-0 pe-1 text-secondary" variant="link" onClick={async (e) => {
                                                e.stopPropagation();
                                                setLoadingStates(prevLoadingStates => ({
                                                    ...prevLoadingStates,
                                                    [`${key.company_id}#${key.export_code}#${key.version}`]: true
                                                }));
                                                const valoResTemp = await MatrixApi.getInstance().valorizeMatrix({
                                                    company_id: matrix.company_id,
                                                    export_codes: [matrix.export_code]

                                                })

                                                valoResTemp.matrices.forEach(m => {
                                                    dispatch(fetchSingleMatrice({
                                                        companyId: String(matrix.company_id), exportCode: m.export_code, version: String(m.version)
                                                    }))
                                                })

                                                setValoRes([valoResTemp])
                                                setLoadingStates(prevLoadingStates => ({
                                                    ...prevLoadingStates,
                                                    [`${key.company_id}#${key.export_code}#${key.version}`]: false
                                                }));
                                                setShowModalValo(true)
                                                dispatch(fetchMatrices({ page: 1 }));
                                            }}>
                                                {loadingStates[`${key.company_id}#${key.export_code}#${key.version}`] ? (
                                                    <InlineLoaderWithoutText />
                                                ) : (
                                                    <CalculateIcon width="24px" height="100%" className="align-text-top" />
                                                )}
                                            </Button>
                                        </OverlayTrigger>
                                    }
                                    {(
                                        ['Active'].includes(matrix.matrix_status)
                                    ) &&
                                        <OverlayTrigger
                                            placement={"left"}
                                            overlay={
                                                <Popover id={`popover-archive`}>
                                                    <Popover.Body>
                                                        {t('matrix.matrix.help.archive')}
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <Button className="p-0 pe-1 text-secondary" variant="link" onClick={(e) => { e.stopPropagation(); dispatch(archiveMatrix({ companyId: matrix.company_id, exportCode: matrix.export_code, version: matrix.version })) }} >
                                                {/* Archive */}
                                                <ArchiveIcon width="24px" height="100%" className="align-text-top" />
                                            </Button>
                                        </OverlayTrigger>
                                    }
                                    {(
                                        ['Active', 'Archived'].includes(matrix.matrix_status)
                                    ) &&
                                        <OverlayTrigger
                                            placement={"left"}
                                            overlay={
                                                <Popover id={`popover-update`}>
                                                    <Popover.Body>
                                                        {t('matrix.matrix.help.update')}
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <Button className="p-0 pe-1 text-secondary" variant="link" onClick={(e) => { e.stopPropagation(); history.push(`/secure/matrix/edit_matrix/update/${matrix.template_id}/${matrix.company_id}/${encodeURIComponent(matrix.export_code)}/${matrix.version}`) }} >
                                                {/* Update */}
                                                <UpdateIcon width="24px" height="100%" className="align-text-top" />
                                            </Button>
                                        </OverlayTrigger>
                                    }
                                    {(
                                        preferences[`${matrix.company_id}#${matrix.export_code}#${matrix.version}`]
                                    ) &&
                                        <OverlayTrigger
                                            placement={"left"}
                                            overlay={
                                                <Popover id={`popover-preferences`}>
                                                    <Popover.Body>
                                                        {t('matrix.matrix.help.preferences')}
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <Button className="p-0 pe-1 text-secondary" variant="link" onClick={(e) => { 
                                                e.stopPropagation(); 
                                                dispatch(setMatrixFields({ 
                                                    laboratory_id: companies[matrix.company_id].laboratory_id, 
                                                    company_id: matrix.company_id, 
                                                    export_code: matrix.export_code, 
                                                    category: matrix.category ? matrix.category : '',
                                                    sub_category: matrix.sub_category ? matrix.sub_category : '',
                                                    raw_material: matrix.raw_material ? matrix.raw_material : '', 
                                                    template_id: matrix.template_id 
                                                }))
                                                
                                                history.push(`/secure/dashboard/matrix`)
                                                }} >
                                                {/* Update */}
                                                <DashboardIcon width="24px" height="100%" className="align-text-top" />
                                            </Button>
                                        </OverlayTrigger>
                                    }
                                </td>
                                <td>
                                    <Button className="p-0 text-primary" variant="link" onClick={(e) => { e.stopPropagation(); toggleSelected(key) }} >
                                        {(selectedMatrixIds.filter((select) => select.company_id === key.company_id && select.export_code === key.export_code && select.version === key.version).length > 0 ?
                                            <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                            : <UncheckedIcon width="18px" height="18px" className="align-text-top" />)
                                        }
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <CustomPagination
                nbPages={pagination.nbPage}
                curPage={pagination.currentPage}
                nbNeighbours={1}
                curPageSize={pagination.nbByPage}
                allowedPageSizes={allowedPageSizes}
                goToPage={(page) => {
                    dispatch(requestPage(page));
                    dispatch(fetchMatrices({ page }));
                    resetSelected();
                }}
                setPageSize={(nbPage) => {
                    dispatch(setNbByPage(nbPage));
                    dispatch(fetchMatrices({ page: 1 }));
                    resetSelected();
                }}
            />
        </Container>
    );
};

export default withTranslation()(MatrixList);
