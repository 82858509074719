import { Data } from "../../../types/types"
import { DashboardSamplesData, ResultHeader, ResultsForData, ValueGroupBy, ValuesForSearch } from "../../../state/dashboardSlice"
import { others } from "./DashboardGroupTableComponent";
import { Weight } from "./ModalDashboardParamsWeightComponent";
import { Filter } from "../../../api/dashboardApi";
import { useEffect, useRef } from "react";

const calculateDeviationFromArray = (arr: number[]) => {
    // Creating the mean with Array.reduce
    let mean = arr.reduce((acc, curr) => {
        return acc + curr
    }, 0) / arr.length;

    // Assigning (value - mean) ^ 2 to every array item
    arr = arr.map((k) => {
        return (k - mean) ** 2
    })

    // Calculating the sum of updated array
    let sum = arr.reduce((acc, curr) => acc + curr, 0);

    // Calculating the variance
    let variance = sum / arr.length

    // Returning the Standered deviation
    return Math.sqrt(sum / arr.length)
}

// calc slope and intercept
// then use resulting y = mx + b to create trendline
const lineFit = function(points: any[]){
    const sI = slopeAndIntercept(points);
    if (sI && points[0] && points[points.length-1]){
        // we have slope/intercept, get points on fit line
        let N = points.length;
        let rV = [];
        rV.push({x: points[0]['date'], y: sI.slope * new Date(points[0]['date']).getTime() + sI.intercept, label: 'trend'});
        rV.push({x: points[N-1]['date'], y: sI.slope * new Date(points[N-1]['date']).getTime() + sI.intercept, label: 'trend'});
        return rV;
    }
    return [];
}

// simple linear regression
const slopeAndIntercept = function(points: any[]){
    let rV = {slope: 0, intercept: 0, rSquared: 0}
    let N = points.length
    let sumX = 0 
    let sumY = 0
    let sumXx = 0
    let sumYy = 0
    let sumXy = 0

    // can't fit with 0 or 1 point
    if (N < 2){
        return rV;
    }
    
    for (let i = 0; i < N; i++){
        let x = new Date(points[i]['date']).getTime()
        let y = points[i]['value']
        sumX += x;
        sumY += y;
        sumXx += (x*x);
        sumYy += (y*y);
        sumXy += (x*y);
    }

    // calc slope and intercept
    rV['slope'] = ((N * sumXy) - (sumX * sumY)) / (N * sumXx - (sumX*sumX));
    rV['intercept'] = (sumY - rV['slope'] * sumX) / N;
    rV['rSquared'] = Math.abs((rV['slope'] * (sumXy - (sumX * sumY) / N)) / (sumYy - ((sumY * sumY) / N)));

    return rV;
}

export interface DatasCalculated {
    number: number,
    summ: number,
    min: number,
    max: number,
    average: number,
    deviation: number,
    averageMinus: number,
    averagePlus: number,
    averageMinus2: number,
    averagePlus2: number,
    averageMinus3: number,
    averagePlus3: number,
    slope: number,
    coef: number,
    results: number[],
    datasForSlope: Array<Data<any>>,
    minDate: Date|null,
    maxDate: Date|null,
    trend: Array<{x: Date, y: number}>
    weighted_summ?: number
    weighted_num?: number
    weighted_average?: number
}

interface CommonFilter {
    type: string,
    period_field: string,
    from: string,
    to: string,
    exclude_outliers: boolean,
    filter: Filter[]
}


export const useDidMountEffect = (func : any , deps : any) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

export const calculateAll = (samplesForData: Data<DashboardSamplesData>, resultsHeader: ResultHeader[], date: string, dashboardName: string, weights?: Weight[], common_filter?: CommonFilter, initialDatas: boolean = false) => {
    let dataOfHeaders: Data<DatasCalculated> = {}
    // initialize datas
    resultsHeader.filter(rh => rh.display_in_graph || rh.display_in_selection).forEach(rh => {
        dashboardName === 'matrix' ?
            dataOfHeaders[rh.id.toUpperCase()] = { number: 0, summ: 0, min: 0, max: 0, average: 0, deviation: 0, averageMinus: 0, averagePlus: 0, averageMinus2: 0, averagePlus2: 0, averageMinus3: 0, averagePlus3: 0, weighted_summ: 0, weighted_num: 0, weighted_average: 0, slope: 0, coef: 0, results: [], datasForSlope: [], minDate: null, maxDate: null, trend: [] }
            : dataOfHeaders[rh.id.toUpperCase()] = { number: 0, summ: 0, min: 0, max: 0, average: 0, deviation: 0, averageMinus: 0, averagePlus: 0, averageMinus2: 0, averagePlus2: 0, averageMinus3: 0, averagePlus3: 0, slope: 0, coef: 0, results: [], datasForSlope: [], minDate: null, maxDate: null, trend: [] }
    })

    const weightsWithoutLastXDays = weights ? weights
        .filter((w) => w.field !== 'last_x_days')
        : [];

    const sortedLastXDays = weights ? weights
        .filter((w) => w.field === 'last_x_days')
        .sort((a, b) => Number(a.value) - Number(b.value))
        : [];
    // calculation
    Object.values(samplesForData).forEach(sfd => {

        sfd.results.filter(r => resultsHeader.find(rh => rh.id.toUpperCase() === r.analyte_id.toUpperCase())?.display_in_graph || resultsHeader.find(rh => rh.id.toUpperCase() === r.analyte_id.toUpperCase())?.display_in_selection).forEach(r => {
            if (r.selected || initialDatas) {
                if (dataOfHeaders[r.analyte_id.toUpperCase()].minDate === null || sfd[date as 'creation_date' | 'validation_date'] < dataOfHeaders[r.analyte_id.toUpperCase()].minDate!) {
                    dataOfHeaders[r.analyte_id.toUpperCase()].minDate = sfd[date as 'creation_date' | 'validation_date']
                }
                if (dataOfHeaders[r.analyte_id.toUpperCase()].maxDate === null || sfd[date as 'creation_date' | 'validation_date'] > dataOfHeaders[r.analyte_id.toUpperCase()].maxDate!) {
                    dataOfHeaders[r.analyte_id.toUpperCase()].maxDate = sfd[date as 'creation_date' | 'validation_date']
                }
                // to calculate number
                dataOfHeaders[r.analyte_id.toUpperCase()].number = dataOfHeaders[r.analyte_id.toUpperCase()].number + 1 || 1
                // to calculate summ
                dataOfHeaders[r.analyte_id.toUpperCase()].summ += Number(r.default_unit_result)
                if (dashboardName === 'matrix') {
                    let weightProduct = 1;

                    weightsWithoutLastXDays?.forEach((w) => {
                        switch (w.field) {
                            case "method_type":
                                r.method_type === w.value ? weightProduct *= w.weight : null;
                                break;

                            case "origin":
                                sfd.origin === w.value ? weightProduct *= w.weight : null;
                                break;

                            case "factory":
                                sfd.plant === w.value ? weightProduct *= w.weight : null;
                                break;

                            case "supplier":
                                sfd.provider === w.value ? weightProduct *= w.weight : null;
                                break;

                            case "company":
                                String(sfd.company_id) == w.value ? weightProduct *= w.weight : null;
                                break;

                            default:
                                break;
                        }
                    })
                    if(common_filter) {
                        const period_field = common_filter.period_field;
                        let to = new Date(common_filter.to);
                        let startDate = new Date(to);
                        let endDate = new Date(to);
                        let sfdDate = new Date(sfd.delivery_date)
                        sortedLastXDays.forEach((element) => {
                            switch (period_field) {
                                case "delivery_date":
                                    startDate.setDate(to.getDate() - Number(element.value));
                                    (sfdDate > startDate && sfdDate <= endDate) ? weightProduct *= element.weight : null;
                                    endDate = new Date(startDate);
                                    break;

                                case "validation_date":
                                    startDate.setDate(to.getDate() - Number(element.value));
                                    (sfdDate > startDate && sfdDate <= endDate) ? weightProduct *= element.weight : null;
                                    endDate = new Date(startDate);
                                    break;

                                case "creation_date":
                                    startDate.setDate(to.getDate() - Number(element.value));
                                    (sfdDate > startDate && sfdDate <= endDate) ? weightProduct *= element.weight : null;
                                    endDate = new Date(startDate);
                                    break;

                                case "sample_date":
                                    startDate.setDate(to.getDate() - Number(element.value));
                                    (sfdDate > startDate && sfdDate <= endDate) ? weightProduct *= element.weight : null;
                                    endDate = new Date(startDate);
                                    break;
                            }
                        })
                    }
                    dataOfHeaders[r.analyte_id.toUpperCase()].weighted_num! += weightProduct;

                    dataOfHeaders[r.analyte_id.toUpperCase()].weighted_summ! += (weightProduct * Number(r.default_unit_result));

                }
                
                // to add to results for deviation
                dataOfHeaders[r.analyte_id.toUpperCase()].results.push(Number(r.default_unit_result))
                // to add datas for slope
                const dateData = date === 'creation_date' ? sfd.creation_date : sfd.validation_date
                dataOfHeaders[r.analyte_id.toUpperCase()].datasForSlope.push({date: dateData, value: Number(r.default_unit_result)})
                // to calculate min
                if (Number(r.default_unit_result) < dataOfHeaders[r.analyte_id.toUpperCase()].min || dataOfHeaders[r.analyte_id.toUpperCase()].min === 0) {
                    dataOfHeaders[r.analyte_id.toUpperCase()].min = Number(r.default_unit_result)
                }
                // to calculate max
                if (Number(r.default_unit_result) > dataOfHeaders[r.analyte_id.toUpperCase()].max || dataOfHeaders[r.analyte_id.toUpperCase()].max === 0) {
                    dataOfHeaders[r.analyte_id.toUpperCase()].max = Number(r.default_unit_result)
                }
            }
        })
    })

    resultsHeader.filter(rh => rh.display_in_graph || rh.display_in_selection).forEach(rh => {

        // to calculate average
        dataOfHeaders[rh.id.toUpperCase()].average = dataOfHeaders[rh.id.toUpperCase()].summ / dataOfHeaders[rh.id.toUpperCase()].number
        //to calculate deviation
        dataOfHeaders[rh.id.toUpperCase()].deviation = calculateDeviationFromArray(dataOfHeaders[rh.id.toUpperCase()].results)
        // to calculate average with deviation
        dataOfHeaders[rh.id.toUpperCase()].averageMinus = dataOfHeaders[rh.id.toUpperCase()].average - dataOfHeaders[rh.id.toUpperCase()].deviation
        dataOfHeaders[rh.id.toUpperCase()].averagePlus = dataOfHeaders[rh.id.toUpperCase()].average + dataOfHeaders[rh.id.toUpperCase()].deviation
        dataOfHeaders[rh.id.toUpperCase()].averageMinus2 = dataOfHeaders[rh.id.toUpperCase()].average - 2 * dataOfHeaders[rh.id.toUpperCase()].deviation
        dataOfHeaders[rh.id.toUpperCase()].averagePlus2 = dataOfHeaders[rh.id.toUpperCase()].average + 2 * dataOfHeaders[rh.id.toUpperCase()].deviation
        dataOfHeaders[rh.id.toUpperCase()].averageMinus3 = dataOfHeaders[rh.id.toUpperCase()].average - 3 * dataOfHeaders[rh.id.toUpperCase()].deviation
        dataOfHeaders[rh.id.toUpperCase()].averagePlus3 = dataOfHeaders[rh.id.toUpperCase()].average + 3 * dataOfHeaders[rh.id.toUpperCase()].deviation
        // to calculate slope
        const trend = slopeAndIntercept(dataOfHeaders[rh.id.toUpperCase()].datasForSlope)
        dataOfHeaders[rh.id.toUpperCase()].slope = Number.isNaN(trend.slope) ? 0 : trend.slope*100000000 // added because values are like 2e-7
        // to calculate trend
        dataOfHeaders[rh.id.toUpperCase()].trend = lineFit(dataOfHeaders[rh.id.toUpperCase()].datasForSlope)
        // to calculate coef
        dataOfHeaders[rh.id.toUpperCase()].coef = dataOfHeaders[rh.id.toUpperCase()].deviation / dataOfHeaders[rh.id.toUpperCase()].average
        if (dashboardName === 'matrix') {
            // to calculate weighted_average
            dataOfHeaders[rh.id.toUpperCase()].weighted_average = dataOfHeaders[rh.id.toUpperCase()].weighted_summ! / dataOfHeaders[rh.id.toUpperCase()].weighted_num!
        }
    })

    return dataOfHeaders
}

export const calculateByGroup = (samplesForData: Data<DashboardSamplesData>, resultsHeader: ResultHeader[], date: string, field: string, values: string[], valuesForOthers: any[]) => {
    let dataOfHeaders: Data<DatasCalculated> = {}
    // initialise datas
    resultsHeader.filter(rh => rh.display_in_graph || rh.display_in_selection).forEach(rh => {
        dataOfHeaders[rh.id.toUpperCase()] = { number: 0, summ: 0, min: 0, max: 0, average: 0, deviation: 0, averageMinus: 0, averagePlus: 0, averageMinus2: 0, averagePlus2: 0, averageMinus3: 0, averagePlus3: 0, slope: 0, coef: 0, results: [], datasForSlope: [], minDate: null, maxDate: null, trend: []}
    })
    // calculation
    Object.values(samplesForData).filter(sfd => values.length === 0 ? !valuesForOthers.includes(sfd[field as keyof typeof sfd].toString()) : values.includes((sfd[field as keyof typeof sfd] as any)?.toString())).forEach(sfd => {
        sfd.results.filter(r => resultsHeader.find(rh => rh.id.toUpperCase() === r.analyte_id.toUpperCase())?.display_in_graph || resultsHeader.find(rh => rh.id.toUpperCase() === r.analyte_id.toUpperCase())?.display_in_selection).forEach(r => {
            if (r.selected) {
                // to add min and max date
                if (dataOfHeaders[r.analyte_id.toUpperCase()].minDate === null || sfd[date as 'creation_date' | 'validation_date'] < dataOfHeaders[r.analyte_id.toUpperCase()].minDate!) {
                    dataOfHeaders[r.analyte_id.toUpperCase()].minDate = sfd[date as 'creation_date' | 'validation_date']
                }
                if (dataOfHeaders[r.analyte_id.toUpperCase()].maxDate === null || sfd[date as 'creation_date' | 'validation_date'] > dataOfHeaders[r.analyte_id.toUpperCase()].maxDate!) {
                    dataOfHeaders[r.analyte_id.toUpperCase()].maxDate = sfd[date as 'creation_date' | 'validation_date']
                }
                // to calculate number
                dataOfHeaders[r.analyte_id.toUpperCase()].number = dataOfHeaders[r.analyte_id.toUpperCase()].number + 1 || 1
                // to calculate summ
                dataOfHeaders[r.analyte_id.toUpperCase()].summ += Number(r.default_unit_result)
                // to add to results for deviation
                dataOfHeaders[r.analyte_id.toUpperCase()].results.push(Number(r.default_unit_result))
                // to add datas for slope
                const dateData = date === 'creation_date' ? sfd.creation_date : sfd.validation_date
                dataOfHeaders[r.analyte_id.toUpperCase()].datasForSlope.push({date: dateData, value: Number(r.default_unit_result)})
                // to calculate min
                if (Number(r.default_unit_result) < dataOfHeaders[r.analyte_id.toUpperCase()].min || dataOfHeaders[r.analyte_id.toUpperCase()].min === 0) {
                    dataOfHeaders[r.analyte_id.toUpperCase()].min = Number(r.default_unit_result)
                }
                // to calculate max
                if (Number(r.default_unit_result) > dataOfHeaders[r.analyte_id.toUpperCase()].max || dataOfHeaders[r.analyte_id.toUpperCase()].max === 0) {
                    dataOfHeaders[r.analyte_id.toUpperCase()].max = Number(r.default_unit_result)
                }
            }
        })
    })
    resultsHeader.filter(rh => rh.display_in_graph || rh.display_in_selection).forEach(rh => {
        // to calculate average
        dataOfHeaders[rh.id.toUpperCase()].average = dataOfHeaders[rh.id.toUpperCase()].summ / dataOfHeaders[rh.id.toUpperCase()].number
        //to calculate deviation
        dataOfHeaders[rh.id.toUpperCase()].deviation = calculateDeviationFromArray(dataOfHeaders[rh.id.toUpperCase()].results)
        // to calculate average with deviation
        dataOfHeaders[rh.id.toUpperCase()].averageMinus = dataOfHeaders[rh.id.toUpperCase()].average - dataOfHeaders[rh.id.toUpperCase()].deviation
        dataOfHeaders[rh.id.toUpperCase()].averagePlus = dataOfHeaders[rh.id.toUpperCase()].average + dataOfHeaders[rh.id.toUpperCase()].deviation
        dataOfHeaders[rh.id.toUpperCase()].averageMinus2 = dataOfHeaders[rh.id.toUpperCase()].average - 2 * dataOfHeaders[rh.id.toUpperCase()].deviation
        dataOfHeaders[rh.id.toUpperCase()].averagePlus2 = dataOfHeaders[rh.id.toUpperCase()].average + 2 * dataOfHeaders[rh.id.toUpperCase()].deviation
        dataOfHeaders[rh.id.toUpperCase()].averageMinus3 = dataOfHeaders[rh.id.toUpperCase()].average - 3 * dataOfHeaders[rh.id.toUpperCase()].deviation
        dataOfHeaders[rh.id.toUpperCase()].averagePlus3 = dataOfHeaders[rh.id.toUpperCase()].average + 3 * dataOfHeaders[rh.id.toUpperCase()].deviation
        // to calculate slope
        const trend = slopeAndIntercept(dataOfHeaders[rh.id.toUpperCase()].datasForSlope)
        dataOfHeaders[rh.id.toUpperCase()].slope = Number.isNaN(trend.slope) ? 0 : trend.slope*100000000 // added because values are like 2e-7
        // to calculate trend
        dataOfHeaders[rh.id.toUpperCase()].trend = lineFit(dataOfHeaders[rh.id.toUpperCase()].datasForSlope)
        // to calculate coef
        dataOfHeaders[rh.id.toUpperCase()].coef = dataOfHeaders[rh.id.toUpperCase()].deviation / dataOfHeaders[rh.id.toUpperCase()].average

    })


    return dataOfHeaders
}

export default { calculateAll }