import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../../App.scss';

import { useOktaAuth } from '@okta/okta-react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';
import { AuthUserProfile, userLogout, userLogin } from '../../state/authUserSlice';

import AnalysisApi from '../../api/analysisApi'
import UsersApi from '../../api/usersApi'
import ConfigApi from '../../api/configApi'
import ControlPlanApi from '../../api/controlPlanApi'
import PublishApi from '../../api/publishApi'
import ExternalServicesApi from '../../api/externalServicesApi'

import {fetchAllExportJobs} from "../../state/exportExcelSlice";

import Loader from '../components/Loader';
import { SecureRoute } from '@okta/okta-react';
import { Container } from 'react-bootstrap';
import ApplyBranding from '../utils/ApplyBranding';
import { AppDispatch } from '../../state/store';
import MatrixApi from '../../api/matrixApi';
import DashboardApi from '../../api/dashboardApi';

interface AuthUserManagerProps {
    path: string
    exact?: boolean
    children: React.ReactNode
}

const AuthUserManager: React.FC<AuthUserManagerProps> = ({ path, exact = false, children }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>()
    const { oktaAuth, authState } = useOktaAuth();

    const { fetchStatus: languagesStatus, fetchError: languagesError } = useSelector((state: RootState) => state.config.languages)
    const { fetchStatus: userStatus, fetchError: userError, user: authUser, fetchStatusTheme, theme } = useSelector((state: RootState) => state.authUser)

    useEffect(() => {
        // First Run after login
        if (authState && authState.isAuthenticated) {
            if (authUser.id === 0) {
            
                // Initialize API instance to use logged user access token
                const accessToken = oktaAuth.getAccessToken()
                if (accessToken) {
                    AnalysisApi.initInstance(accessToken)
                    ConfigApi.initInstance(accessToken)
                    UsersApi.initInstance(accessToken)
                    ControlPlanApi.initInstance(accessToken)
                    PublishApi.initInstance(accessToken)
                    ExternalServicesApi.initInstance(accessToken)
                    MatrixApi.initInstance(accessToken)
                    DashboardApi.initInstance(accessToken)

                }

                oktaAuth.getUser()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((userInfo: any) => {
                    const authUserProfile: AuthUserProfile = {
                        name: userInfo.name,
                        email: userInfo.preferred_username,
                        external_id: userInfo.sub,
                        picture: userInfo.picture
                    }

                    dispatch(userLogin(authUserProfile))
                    dispatch(fetchAllExportJobs())

                })
            }
        } else {
            dispatch(userLogout())
        }

    }, [authState]); // Update if authState changes

    
    const [themePrimary, setThemePrimary] = useState('')
    const [themePrimaryLight, setThemePrimaryLight] = useState('')
    const [themeSecondary, setThemeSecondary] = useState('')

    interface Color {
        name: string
        hex: string
    }
    const [themeThresholds, setThemeThresholds] = useState<Array<Color>>([])

    const defaultThresholds = [
        {name: "worst", hex: "#e49a9c"},
        {name: "bad", hex: "#ffe380"},
        {name: "average", hex: "#cceb94"},
        {name: "good", hex: "#80dbff"},
        {name: "better", hex: "#bf80ff"},
    ]

    /**
     * Update theme colors when theme change
     */
    useEffect(() => {
        if(theme){
            if(theme.themePrimary){
                setThemePrimary(theme.themePrimary)
            }else{
                setThemePrimary('#007681')
            }
            if(theme.themeSecondary){
                setThemeSecondary(theme.themeSecondary)                
            }else{
                setThemeSecondary('#005F86')
            }
            if(theme.themePrimaryLight){
                setThemePrimaryLight(theme.themePrimaryLight)                
            }else{
                setThemePrimaryLight('#97CFCD')
            }
            if (theme.themeThresholds) {
                const colorArray = Object.entries(theme.themeThresholds).map(([key, value]) => {
                    return ({
                        name: key,
                        hex: value
                    })
                })
                setThemeThresholds(colorArray)
            } else {
                setThemeThresholds(defaultThresholds)
            }
        }else{
            setThemePrimary('#007681')
            setThemeSecondary('#005F86')
            setThemePrimaryLight('#97CFCD')
            setThemeThresholds(defaultThresholds)
        }
    }, [theme])

    return (
        <SecureRoute path={path} exact={exact}>
            { userStatus !== 'failed' && languagesStatus !== 'failed' && userStatus !== 'succeeded' && languagesStatus !== 'succeeded' &&
                <Loader />
            }
            { userStatus === 'succeeded' && languagesStatus === 'succeeded' && (fetchStatusTheme === 'succeeded' || fetchStatusTheme === 'failed') && 
                <>
                    <ApplyBranding colors={{ 
                        "bs-primary": themePrimary, 
                        "bs-secondary": themeSecondary, 
                        "bs-primary-light": themePrimaryLight ,
                        ...themeThresholds.reduce((a, color) => ({ ...a, [`mp-${color.name}`]: color.hex }), {})
                    }} />
                    {children}
                </>
            }
            { (userStatus === 'failed' || languagesStatus === 'failed') &&
                <Container className="mt-2 bg-white text-danger">
                    {userError && <p>{t('forms_errors.unexpected_error') + ' (' + userError + ')'}</p>}
                    {languagesError && <p>{t('forms_errors.unexpected_error') + ' (' + languagesError + ')'}</p>}
                </Container>
            }
        </SecureRoute>
    )
}

export default AuthUserManager