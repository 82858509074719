import env from '../utils/env'

const oktaAuthConfig = {
    issuer: env.OKTA_DOMAIN + env.OKTA_ENDPOINT,
    clientId: env.OKTA_CLIENTID,
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin + '/logout',
    tokenManager: {
        // expireEarlySeconds: 3570, // for development purpose (renew token at 90sec)
        expireEarlySeconds: 120, // trigger token expiration 120 sec before effective expiration
        autoRenew: false,
        autoRemove: false
    }
};
  
const oktaSignInConfig = {
    logo: '/provilab-60.png',
    logoText: 'Provilab',
    i18n: {
        en: {
            'primaryauth.title': 'Sign in to MyProvilab',
            'customButton.sso': 'Cargill log in',
            'customButton.signup': 'Sign Up',
        },
        fr: {
            'primaryauth.title': 'Se connecter à MonProvilab',
            'customButton.sso': 'Accès Cargill',
            'customButton.signup': 'Créer un compte MonProvilab',
        },
        es: {
            'primaryauth.title': 'Iniciar sesión en MyProvilab',
            'customButton.sso': 'Acceso a Cargill',
            'customButton.signup': 'Crea un account MyProvilab',
        },
        it: {
            'primaryauth.title': 'Accedi a MyProvilab',
            'customButton.sso': 'Cargill Accesso',
            'customButton.signup': 'Crear una cuenta MiProvilab',
        },
    },
    baseUrl: env.OKTA_DOMAIN,
    clientId: env.OKTA_CLIENTID,
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin + '/logout',
    authParams: {
        issuer: env.OKTA_DOMAIN + env.OKTA_ENDPOINT,
        scopes: ['openid', 'profile', 'offline_access']
    },
    helpLinks: {},
    customButtons: [
        {
            i18nKey: 'customButton.sso',
            className: 'button-primary',
            click: function() {
                window.location.href = `${env.OKTA_SSO_LINK}?fromURI=${window.location.origin}/login?fromLogin=true`;
            }
        }
    ]
};
  
  export { oktaAuthConfig, oktaSignInConfig };