import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Row, Container, Table, Form, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';
import { setSamplesFilterBy, setSamplesSortBy, setDataOfAnalytes, setInitialDataOfAnalytes } from '../../../state/dashboardSlice';
import { calculateAll } from './utils';
import { ScrollSyncNode } from 'scroll-sync-react'
import { getIn } from 'formik';
import { SelectedValue, SelectedValues } from '../../../api/dashboardApi';
import { convertAutocalcAnalytesApiToAutocalcAnalytes } from '../../../api/configApi';
import { Data } from '../../../types/types';

interface DashboardSelectionComponentContainerProps extends WithTranslation {
    dashboardName: string
    setFieldValue?: (f: string, value: any) => void
    handleChange?: (e: React.FocusEvent<any>) => void
    values?: SelectedValues
    errors?: any
    touched?: any
}

const DashboardSelectionComponent: React.FC<DashboardSelectionComponentContainerProps> = ({ t, dashboardName, setFieldValue, values, touched, errors, handleChange }) => {
    const dispatch = useDispatch()

    // user state
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    // languages
    const languages = useSelector((state: RootState) => state.config.languages.data)
    const language = Object.values(languages).find(lg => lg.id === Number(authUser.language_id))

    // samples state
    const samplesForData = useSelector((state: RootState) => state.dashboard.samplesForData)
    const resultsHeader = useSelector((state: RootState) => state.dashboard.resultsHeaders)
    const pagination = useSelector((state: RootState) => state.dashboard.paginationSamples)
    const paramsFromComponents = useSelector((state: RootState) => state.dashboard.paramsFromComponents)
    const datasCalculated = useSelector((state: RootState) => state.dashboard.dataOfAnalytes)
    const fieldsForSamplesInfo = useSelector((state: RootState) => state.dashboard.fieldsForSamplesInfo)
    const preferencesStatus = useSelector((state: RootState) => state.preferences.singleFetchStatus)
    const preferences = useSelector((state: RootState) => state.preferences.singlePreferences)
    const [initialized, setInitialized] = useState(false)
    const [initSelectedValues, setInitSelectedValues] = useState<Data<SelectedValue>>({})

    const resultsFields = () => {
        const fields = {
            selection: {
                type: [],
                sortable: false
            }
        }
        resultsHeader.forEach(resultHeader => {
            Object.assign(fields, {[resultHeader.name.translations[language && language.code_language ? language.code_language : ''] || resultHeader.name.name]: { type: [], sortable: false, noTrad: true }})
        })
        fieldsForSamplesInfo.forEach(field => {
            Object.assign(fields, { [field.field]: { type: [], sortable: false, noTrad: false } })
        })
        return fields
    }

    const { ListHeader, filterAndSortList, FilterList } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "dashboard.selection.columns.",
        onlyActive: false,
        size: 'xs',
        fields: resultsFields(),
        reducers: {
            filterBy: setSamplesFilterBy,
            sortBy: setSamplesSortBy
        }
    })

    useEffect(() => {
        if (Object.values(samplesForData).length > 0) {
            dispatch(setDataOfAnalytes(calculateAll(samplesForData, resultsHeader, paramsFromComponents.date, dashboardName)))
            dispatch(setInitialDataOfAnalytes(calculateAll(samplesForData, resultsHeader, paramsFromComponents.date, dashboardName, undefined, undefined, true)))
        }
    }, [samplesForData, resultsHeader])

    useEffect(() => {
        resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map((header) => {
            const index = resultsHeader.findIndex((result) => String(result.id) === header.id)

            if (dashboardName === 'matrix' && setFieldValue) {
                let selected = null
                if (preferences && preferencesStatus === 'succeeded') {
                    selected = preferences.selected_values.find((sv) => sv.analyte_id.toUpperCase() === header.id.toUpperCase())
                    setFieldValue(`selected_values[${index}]`, { analyte_id: header.id, value: selected ? selected.value : 0 })
                }
                setFieldValue(`selected_values[${index}]`, {
                    analyte_id: header.id,
                    value: selected ?
                        selected.value :
                        datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].weighted_average ?
                            Number(datasCalculated[header.id.toUpperCase()].weighted_average!.toFixed(3)) :
                            0
                })
            }
        })


    }, [resultsHeader, datasCalculated])

    return (
        <Container className={'mt-2 p-0 ms-0 me-0'}>
            <Container className="p-2 bg-white rounded-3 mb-0">
                <Row>
                    <ScrollSyncNode group="horiz-scroll">
                        <Container className='ms-2 me-2 p-0 noshrink' style={{ overflow: "auto" }}>
                            <Table key='selection' striped hover size='sm' className="bg-white text-center table-fixed table-xs mb-0">
                                <thead className='bg-white'>
                                    <tr>
                                        <th className="p-0" />
                                        <th className="p-1 fixed-width">
                                            <ListHeader name='selection' />
                                        </th>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <th className="p-1" key={`header-${header.id}`}>
                                                    <ListHeader name={ header.name.translations[language && language.code_language ? language.code_language : ''] || header.name.name } addClassToButton="add-pad" />
                                                </th>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return (
                                                <th className="p-1" key={`selection-${field.field}`}>
                                                    <ListHeader name={field.field} addClassToButton="add-pad-no-sort" />
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key="number">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.number')}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`number-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].number ? datasCalculated[header.id.toUpperCase()].number : 0}</td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return (
                                                <td></td>
                                            )
                                        })}
                                    </tr>
                                    <tr key="min">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.min')}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`min-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].min ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].min) : 0}</td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return (
                                                <td></td>
                                            )
                                        })}
                                    </tr>
                                    <tr key="max">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.max')}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`max-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].max ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].max) : 0}</td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return (
                                                <td></td>
                                            )
                                        })}
                                    </tr>
                                    {paramsFromComponents.average &&
                                        <tr key="average">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.average')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`average-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].average ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].average) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return (
                                                    <td></td>
                                                )
                                            })}
                                        </tr>
                                    }
                                    <tr key="deviation">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.deviation')}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`deviation-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].deviation ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].deviation) : 0}</td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return (
                                                <td></td>
                                            )
                                        })}
                                    </tr>
                                    {paramsFromComponents.average1 &&
                                        <tr key="averageplus">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageplus')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averageplus-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].averagePlus ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].averagePlus) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return (
                                                    <td></td>
                                                )
                                            })}
                                        </tr>
                                    }
                                    {paramsFromComponents.average1 &&
                                        <tr key="averageminus">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageminus')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averagemin-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].averageMinus ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].averageMinus) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return (
                                                    <td></td>
                                                )
                                            })}
                                        </tr>
                                    }
                                    {paramsFromComponents.average2 &&
                                        <tr key="averageplus2">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageplus2')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averageplus2-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].averagePlus2 ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].averagePlus2) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return (
                                                    <td></td>
                                                )
                                            })}
                                        </tr>
                                    }
                                    {paramsFromComponents.average2 &&
                                        <tr key="averageminus2">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageminus2')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averagemin2-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].averageMinus2 ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].averageMinus2) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return (
                                                    <td></td>
                                                )
                                            })}
                                        </tr>
                                    }
                                    {paramsFromComponents.average3 &&
                                        <tr key="averageplus3">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageplus3')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averageplus3-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].averagePlus3 ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].averagePlus3) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return (
                                                    <td></td>
                                                )
                                            })}
                                        </tr>
                                    }
                                    {paramsFromComponents.average3 &&
                                        <tr key="averageminus3">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageminus3')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averagemin3-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].averageMinus3 ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].averageMinus3) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return (
                                                    <td></td>
                                                )
                                            })}
                                        </tr>
                                    }
                                    {paramsFromComponents.trend &&
                                        <tr key="trend">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.trend')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`trend-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].slope ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].slope) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return (
                                                    <td></td>
                                                )
                                            })}
                                        </tr>
                                    }
                                    <tr key="coef">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.coef')}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`coef-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].coef ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].coef) : 0}</td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return (
                                                <td></td>
                                            )
                                        })}
                                    </tr>
                                    {dashboardName === 'matrix' &&
                                        <>
                                            <tr key="weighted_average">
                                                <td className="p-1 table-hover-hint" />
                                                <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.weighted_average')}</td>
                                                {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                    return (
                                                        <td className='ps-1 pe-1' key={`weighted_average-${header.id}`}>{datasCalculated[header.id.toUpperCase()] && datasCalculated[header.id.toUpperCase()].weighted_average ? Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(datasCalculated[header.id.toUpperCase()].weighted_average!) : 0}</td>
                                                    )
                                                })}
                                                {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                    return (
                                                        <td></td>
                                                    )
                                                })}
                                            </tr>

                                            <tr key="selected_values">
                                                <td className="p-1 table-hover-hint" />
                                                <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.selected_values')}</td>
                                                {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map((header) => {
                                                    const indexAnalyte = resultsHeader.findIndex((result) => String(result.id) === header.id)

                                                    return (
                                                        <td className='ps-1 pe-1' key={`selected_values-${indexAnalyte}`}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Popover id={`popover-selected_value-${indexAnalyte}`} className={!getIn(touched, `selected_values[${indexAnalyte}]`) || typeof getIn(errors, `selected_values[${indexAnalyte}].value`) !== 'string' ? "d-none" : ""}>
                                                                        <Popover.Body>
                                                                            <Form.Text className="invalid-feedback d-block">{getIn(errors, `selected_values[${indexAnalyte}].value`)}</Form.Text>
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        isInvalid={getIn(touched, `selected_values[${indexAnalyte}]`) && !!getIn(errors, `selected_values[${indexAnalyte}]`)}
                                                                        style={{ borderRadius: '10px' }}
                                                                        className="text-center w-50 mx-auto m-1"
                                                                        type="text"
                                                                        name={`selected_values[${indexAnalyte}]`}
                                                                        value={values && values.selected_values[indexAnalyte] ? values.selected_values[indexAnalyte].value : 0}
                                                                        onChange={(e) => {
                                                                            if (setFieldValue) setFieldValue(`selected_values[${indexAnalyte}]`, { analyte_id: header.id, value: e.target.value })
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </OverlayTrigger>
                                                        </td>
                                                    )
                                                })}
                                                {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                    return (
                                                        <td></td>
                                                    )
                                                })}
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </Table>

                            {dashboardName === 'matrix' &&
                                <Container>
                                    <Form.Group>
                                        <Row className='mt-2 mb-2'>
                                            <Col>
                                                <Form.Label>{t('dashboard.selection.commentary')} : </Form.Label>
                                            </Col>
                                            <Col lg={9}>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t('dashboard.selection.commentary_placeholder')}
                                                    name='commentary'
                                                    value={values ? values.commentary : ""}
                                                    as='textarea'
                                                    rows={3}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                </Container>
                            }

                        </Container>
                    </ScrollSyncNode>
                </Row>
            </Container>
        </Container>
    )
}

export default withTranslation()(DashboardSelectionComponent)