import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import ConfigApi, { RuleAnalyteTestThresholdDocument, Threshold } from '../api/configApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';

interface CurrentState {
    data: Data<Array<RuleAnalyteTestThresholdDocument>>
    filtered: Array<string>
    pagination: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postError: string | undefined
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    byCompany: {},
    fetchStatus: 'idle',
    fetchError: undefined,
    singleFetchStatus: 'idle',
    singleFetchError: undefined,
    postStatus: 'idle',
    postError: undefined
} as CurrentState


export const fetchThresholds = createAsyncThunk(
    'thresholds',
    async ({ laboratoryId }: { laboratoryId: number }) => {
        const response = await ConfigApi.getInstance().getThresholds(laboratoryId)
        return response as Threshold
    }
)


const thresholdsSlice = createSlice({
    name: 'thresholds',
    initialState,
    reducers: {
        refreshAnalysis(state: CurrentState){
            state.data = {}
            state.fetchStatus = 'loading'
        },
        updateThreshold(state: CurrentState, action: PayloadAction<Threshold>) {
            const item = action.payload
            if (item.laboratory_id)
                state.data[item.laboratory_id] = item.rule
        },
        setFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload
        },
        setFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy
        },
        setSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchThresholds.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchThresholds.fulfilled, (state, action) => {

            state.data[action.payload.laboratory_id] = action.payload.rule
            state.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchThresholds.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
    }
})

export const {
    updateThreshold,
    setFiltered,
    setFilterBy,
    setSortBy,
    refreshAnalysis,
} = thresholdsSlice.actions

export default thresholdsSlice.reducer