
import { useState, useEffect } from 'react';

// Local variables to handle worker state on initial load or refresh
let currentShowReload = false
let currentWaitingWorker: ServiceWorker|null = null
let currentMustRefresh = false

// List of subscription to propagate status to all tabs
const subscriptions = new Set<(status: boolean, registration: ServiceWorkerRegistration, mustRefresh: boolean) => void>();

/**
 * Subscribe current tab to listen service worker events. Designed to be use as an useEffect function.
 * @param setStatus 
 * @returns 
 */
function subscribe(setStatus: (showReload: boolean, registration: ServiceWorkerRegistration, mustRefresh: boolean) => void) {
    subscriptions.add(setStatus);

    return () => {
        subscriptions.delete(setStatus);
    };
}

/**
 * Dispatch event to all subscribed tabs.
 * Update local variables to insure coherency for further usage of useServiceWorkerStatus
 * @param showReload 
 * @param registration 
 */
function dispatch(showReload: boolean, registration: ServiceWorkerRegistration, mustRefresh: boolean) {
    currentShowReload = showReload
    currentWaitingWorker = registration.waiting
    currentMustRefresh = mustRefresh
    subscriptions.forEach((setStatus) => setStatus(showReload, registration, mustRefresh));
}

/**
 * React hook to use service worker status data
 * @returns 
 */
function useServiceWorkerStatus() {

    // Initialize local state with current service worker status
    const [showReload, setShowReload] = useState(currentShowReload);
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(currentWaitingWorker);
    const [mustRefresh, setMustRefresh] = useState(currentMustRefresh);
    
    // Subscribe calling component to be notified of service worker status updates
    useEffect(() => subscribe((showReload: boolean, registration: ServiceWorkerRegistration, mustRefresh: boolean) => {
        setShowReload(showReload)
        setWaitingWorker(registration.waiting)
        setMustRefresh(mustRefresh)
    }), []);

    return {showReload, waitingWorker, mustRefresh};
}

/**
 * Service worker init callback to check if an update is pending
 * @param registration 
 */
 function onInit(registration: ServiceWorkerRegistration) {
    if (registration.waiting !== null) {
        dispatch(true, registration, true);
    }
}

/**
 * Service worker updated but waiting for stale tabs to close callback
 * @param registration 
 */
function onUpdate(registration: ServiceWorkerRegistration) {
    dispatch(true, registration, false);
}

/**
 * Service worker updated and running callback
 * @param registration 
 */
function onSuccess(registration: ServiceWorkerRegistration) {
    dispatch(false, registration, false);
}

export { useServiceWorkerStatus, onInit, onUpdate, onSuccess };