import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import ConfigApi, {
    PriceList, PriceListFlat, PriceListList, getKeyForTabAssocPriceListFlat,
} from "../api/configApi";
import {
    buildDefaultExtraReducersWithFrontPagination,
    CurrentState,
    getDefaultReducersWithFrontPagination,
    getInitialState,
} from "./DefaultSlice";

const initialState = getInitialState<PriceListFlat>();

// test_categories

export const fetchPriceLists = createAsyncThunk(
    "config/fetchPriceLists",
    async ({ laboratoryId }: { laboratoryId: number }) => {
        const response = await ConfigApi.getInstance().getPriceLists(
            laboratoryId
        );
        return response.price_lists.map(
            p => ({
                ...p,
                laboratory_id: response.laboratory_id
            })
        );
    }
);




const addItemToPriceList = (state: CurrentState<PriceListFlat>, item: PriceListFlat) => {
    state.data[item.id] = item;
};

const postDeletePriceList = (
    state: CurrentState<PriceListFlat>,
    item: PriceListFlat
) => {
    delete state.data[getKeyForTabAssocPriceListFlat(item)];
    state.filtered.splice(
        state.filtered.indexOf(getKeyForTabAssocPriceListFlat(item)),
        1
    );
};


const priceListSlice = createSlice({
    name: "price lists",
    initialState,
    reducers: {...getDefaultReducersWithFrontPagination(
        addItemToPriceList,
        postDeletePriceList  
    ), updatePriceList : (state: CurrentState<PriceListFlat>, action: PayloadAction<PriceListFlat[]>) => {
        state.data = {};
        action.payload.forEach((item: PriceListFlat) => {
            addItemToPriceList(state, item);
        });
    }},
    extraReducers: (builder) => {
        buildDefaultExtraReducersWithFrontPagination(
            addItemToPriceList,
            builder,
            fetchPriceLists
        );
    },
});

export const {
    updatePriceList,
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage,
} = priceListSlice.actions;

export default priceListSlice.reducer;
