import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import {ReactComponent as BackIcon} from 'bootstrap-icons/icons/arrow-left-short.svg'

interface GoBackProps extends WithTranslation {
    to: string
    label: string
}

const GoBack: React.FC<GoBackProps> = ({to, label}: GoBackProps) => {
    return (
        <Link className="text-decoration-none" to={to}>
            <BackIcon width="30px" height="100%" className="bg-primary text-white dot fw-bold"  />
            <span className="ps-2 text-body fw-bold align-middle">{label}</span>
        </Link>
    )
}

export default withTranslation()(GoBack)