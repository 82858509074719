import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Row, Container, Col, Button, Modal, Form, Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { GroupBy, setDataOfAnalytes, setInitialDataOfAnalytes, setSamplesFilterBy, setSamplesSortBy, setWeights } from '../../../state/dashboardSlice';
import { Formik, FormikProps, getIn } from 'formik';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';
import Select from 'react-select'
import { calculateAll } from './utils';
import { ReactComponent as DeleteIcon } from 'bootstrap-icons/icons/x-circle.svg'
import * as Yup from 'yup';
import { Company } from '../../../api/usersApi';
import { Data } from '../../../types/types';

interface ModalDashboardParamsWeightComponentContainerProps extends WithTranslation {
    dashboardName: string,
    showModal: boolean,
    setShowModal: any
}

export interface FieldSelect {
    value: string
    label: string
}

export interface Weight {
    field: 'method_type' | 'origin' | 'supplier' | 'last_x_days' | 'factory' | 'company',
    value: string,
    weight: number
}


const ModalDashboardParamsWeightComponent: React.FC<ModalDashboardParamsWeightComponentContainerProps> = ({ t, dashboardName, showModal, setShowModal }) => {

    type fromValues = {
        weights: Weight[],
    }

    const dispatch = useDispatch()

    // samples state
    const pagination = useSelector((state: RootState) => state.dashboard.paginationSamples)
    const samplesForData = useSelector((state: RootState) => state.dashboard.samplesForData)
    const resultsHeader = useSelector((state: RootState) => state.dashboard.resultsHeaders)
    const paramsFromComponents = useSelector((state: RootState) => state.dashboard.paramsFromComponents)
    const weights = useSelector((state: RootState) => state.dashboard.weights)
    const valuesForSearch = useSelector((state: RootState) => state.dashboard.valuesForSearch)
    const companies = useSelector((state: RootState) => state.companies.data)

    // types for fields
    const fields = () => {
        const fields = {
            field: {
                type: [],
                sortable: false
            },
            value: {
                type: [],
                sortable: false
            },
            weight: {
                type: [],
                sortable: false
            }
        }
        return fields
    }

    const { ListHeader } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "dashboard.options.modal_weight.columns.",
        onlyActive: false,
        size: 'xs',
        fields: fields(),
        reducers: {
            filterBy: setSamplesFilterBy,
            sortBy: setSamplesSortBy
        }
    })

    const validationSchema = Yup.object().shape({
        weights: Yup.array().of(Yup.object().shape({
            value: Yup.string().trim().required(t('dashboard.errors.value_required')),
            weight: Yup.number().required(t('dashboard.errors.weight_required')),
        }))
    })


    const initialValue = {
        weights: weights.map((weight) => ({
            field: weight.field,
            value: weight.value,
            weight: weight.weight
        }))
    }

    function getCompanyIds(): Company[] {
        const arraySfd = Object.values(samplesForData)
        const filteredArray: Company[] = []
        arraySfd.forEach((e) => {
            if (e.company_id && !filteredArray.find((element) => element.id === e.company_id)) {
                filteredArray.push(companies[e.company_id])
            }
        })

        return filteredArray
    }

    function getMethodTypes(): string[] {
        const arraySfd = Object.values(samplesForData)
        const filteredArray: string[] = []
        arraySfd.forEach((e) => {
            e.results.forEach((r) => {
                if (!filteredArray.find((element) => element === r.method_type)) {
                    filteredArray.push(r.method_type)
                }
            })

        })

        return filteredArray
    }


    function getOrigins(): string[] {
        const arraySfd = Object.values(samplesForData)
        const filteredArray: string[] = []
        arraySfd.forEach((e) => {
            if (e.origin && !filteredArray.find((element) => element === e.origin)) {
                filteredArray.push(e.origin)
            }
        })

        return filteredArray
    }

    function getSuppliers(): string[] {
        const arraySfd = Object.values(samplesForData)
        const filteredArray: string[] = []
        arraySfd.forEach((e) => {
            if (e.provider && !filteredArray.find((element) => element === e.provider)) {
                filteredArray.push(e.provider)
            }
        })

        return filteredArray
    }

    function getFactories(): string[] {
        const arraySfd = Object.values(samplesForData)
        const filteredArray: string[] = []
        arraySfd.forEach((e) => {
            if (e.plant && !filteredArray.find((element) => element === e.plant)) {
                filteredArray.push(e.plant)
            }
        })

        return filteredArray
    }

    const WeightField = () => {
        const fields = ['last_x_days']
        if (getCompanyIds().length > 0) fields.push('company')
        if (getFactories().length > 0) fields.push('factory')
        if (getMethodTypes().length > 0) fields.push('method_type')
        if (getSuppliers().length > 0) fields.push('supplier')
        if (getOrigins().length > 0) fields.push('origin')
        return Array.from(new Set(fields)).map(t => ({ value: t, label: t }))
    }

    return (
        <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title><span className="text-primary fw-bold"> {t('dashboard.options.modal_weight.title')} </span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValue}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        dispatch(setDataOfAnalytes(calculateAll(samplesForData, resultsHeader, paramsFromComponents.date, dashboardName, values.weights, valuesForSearch?.common_filter)))
                        dispatch(setInitialDataOfAnalytes(calculateAll(samplesForData, resultsHeader, paramsFromComponents.date, dashboardName, values.weights, valuesForSearch?.common_filter, true)))
                        dispatch(setWeights(values.weights))
                        setShowModal(false)
                    }}
                >
                    {/* Callback function containing Formik sampleForm and helpers that handle common form actions */}
                    {(props: FormikProps<fromValues>) => {
                        const {
                            touched,
                            errors,
                            values,
                            handleSubmit,
                            handleChange,
                            setFieldValue,
                        } = props

                        return (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Table
                                        striped
                                        hover
                                        responsive="lg"
                                        className="mt-2 bg-white text-center"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="p-1" />
                                                <th>
                                                    <ListHeader name="field" />
                                                </th>
                                                <th>
                                                    <ListHeader name="value" />
                                                </th>
                                                <th>
                                                    <ListHeader name="weight" />
                                                </th>
                                                <th>{t('dashboard.options.modal_weight.columns.actions')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                values.weights.map((weight, index) => {
                                                    return (
                                                        <tr key={'weight' + index}>
                                                            <td className="p-1 table-hover-hint" />
                                                            <td>
                                                                <Form.Group controlId='field'>
                                                                    <Select
                                                                        name={` weights[${index}].field`}
                                                                        value={{ label: weight.field, value: weight.field }}
                                                                        isClearable={false}
                                                                        options={WeightField()}
                                                                        getOptionLabel={(field: FieldSelect) => field.label}
                                                                        getOptionValue={(field: FieldSelect) => field.value}
                                                                        onChange={(field: FieldSelect) => {
                                                                            setFieldValue(`weights[${index}].field`, field.value)
                                                                            setFieldValue(`weights[${index}].value`, "")

                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </td>

                                                            <td className="text-primary">
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Popover id={`popover-type-${index}`} className={!getIn(touched, `weights[${index}].value`) || typeof getIn(errors, `weights[${index}].value`) !== 'string' ? "d-none" : ""}>
                                                                            <Popover.Body>
                                                                                <Form.Text className="invalid-feedback d-block">{getIn(errors, `weights[${index}].value`)}</Form.Text>
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Form.Group controlId="value">
                                                                        {values.weights[index] && values.weights[index].field && values.weights[index].field === 'last_x_days' &&
                                                                            <Form.Control
                                                                                className="text-center"
                                                                                placeholder={t('dashboard.options.modal_weight.columns.enter_placeholder')}
                                                                                type="text"
                                                                                name={`weights[${index}].value`}
                                                                                value={weight.value}
                                                                                onChange={handleChange}
                                                                                isInvalid={getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)}

                                                                            />
                                                                        }
                                                                        {values.weights[index] && values.weights[index].field && values.weights[index].field === 'company' &&
                                                                            <Select
                                                                                styles={{
                                                                                    control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                                                        ...styles,
                                                                                        borderColor: (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                        boxShadow: state.isFocused ? (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                        '&:hover': {
                                                                                            borderColor: (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                            boxShadow: state.isFocused ? (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                        }

                                                                                    }),
                                                                                    menu: (styles: Data<string>) => ({
                                                                                        ...styles,
                                                                                        zIndex: '1031'
                                                                                    })
                                                                                }}
                                                                                name={`weights[${index}].value`}
                                                                                value={weight.value ? companies[weight.value] : ""}
                                                                                isClearable={false}
                                                                                placeholder={t('dashboard.options.modal_weight.columns.enter_placeholder')}
                                                                                options={getCompanyIds()}
                                                                                getOptionLabel={(c: Company) => c.name}
                                                                                getOptionValue={(c: Company) => c.id}
                                                                                onChange={(company: Company) => {
                                                                                    setFieldValue(`weights[${index}].value`, company ? company.id : "")
                                                                                }}
                                                                            />
                                                                        }
                                                                        {values.weights[index] && values.weights[index].field && values.weights[index].field === 'origin' &&
                                                                            <Select
                                                                                styles={{
                                                                                    control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                                                        ...styles,
                                                                                        borderColor: (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                        boxShadow: state.isFocused ? (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                        '&:hover': {
                                                                                            borderColor: (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                            boxShadow: state.isFocused ? (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                        }

                                                                                    }),
                                                                                    menu: (styles: Data<string>) => ({
                                                                                        ...styles,
                                                                                        zIndex: '1031'
                                                                                    })
                                                                                }}
                                                                                name={` weights[${index}].value`}
                                                                                value={weight.value ? { label: weight.value, value: weight.value } : ""}
                                                                                isClearable={false}
                                                                                placeholder={t('dashboard.options.modal_weight.columns.enter_placeholder')}
                                                                                options={getOrigins().map(t => ({ value: t, label: t }))}
                                                                                getOptionLabel={(value: FieldSelect) => value.label}
                                                                                getOptionValue={(value: FieldSelect) => value.value}
                                                                                onChange={(value: FieldSelect) => {
                                                                                    setFieldValue(`weights[${index}].value`, value.value)
                                                                                }}
                                                                            />
                                                                        }
                                                                        {values.weights[index] && values.weights[index].field && values.weights[index].field === 'supplier' &&
                                                                            <Select
                                                                                styles={{
                                                                                    control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                                                        ...styles,
                                                                                        borderColor: (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                        boxShadow: state.isFocused ? (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                        '&:hover': {
                                                                                            borderColor: (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                            boxShadow: state.isFocused ? (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                        }

                                                                                    }),
                                                                                    menu: (styles: Data<string>) => ({
                                                                                        ...styles,
                                                                                        zIndex: '1031'
                                                                                    })
                                                                                }}
                                                                                name={` weights[${index}].value`}
                                                                                value={weight.value ? { label: weight.value, value: weight.value } : ""}
                                                                                isClearable={false}
                                                                                placeholder={t('dashboard.options.modal_weight.columns.enter_placeholder')}
                                                                                options={getSuppliers().map(t => ({ value: t, label: t }))}
                                                                                getOptionLabel={(value: FieldSelect) => value.label}
                                                                                getOptionValue={(value: FieldSelect) => value.value}
                                                                                onChange={(value: FieldSelect) => {
                                                                                    setFieldValue(`weights[${index}].value`, value.value)
                                                                                }}
                                                                            />
                                                                        }
                                                                        {values.weights[index] && values.weights[index].field && values.weights[index].field === 'factory' &&
                                                                            <Select
                                                                                styles={{
                                                                                    control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                                                        ...styles,
                                                                                        borderColor: (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                        boxShadow: state.isFocused ? (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                        '&:hover': {
                                                                                            borderColor: (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                            boxShadow: state.isFocused ? (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                        }

                                                                                    }),
                                                                                    menu: (styles: Data<string>) => ({
                                                                                        ...styles,
                                                                                        zIndex: '1031'
                                                                                    })
                                                                                }}
                                                                                name={` weights[${index}].value`}
                                                                                value={weight.value ? { label: weight.value, value: weight.value } : ""}
                                                                                isClearable={false}
                                                                                placeholder={t('dashboard.options.modal_weight.columns.enter_placeholder')}
                                                                                options={getFactories().map(t => ({ value: t, label: t }))}
                                                                                getOptionLabel={(value: FieldSelect) => value.label}
                                                                                getOptionValue={(value: FieldSelect) => value.value}
                                                                                onChange={(value: FieldSelect) => {
                                                                                    setFieldValue(`weights[${index}].value`, value.value)
                                                                                }}
                                                                            />
                                                                        }
                                                                        {values.weights[index] && values.weights[index].field && values.weights[index].field === 'method_type' &&
                                                                            <Select
                                                                                styles={{
                                                                                    control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                                                        ...styles,
                                                                                        borderColor: (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                        boxShadow: state.isFocused ? (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                        '&:hover': {
                                                                                            borderColor: (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                            boxShadow: state.isFocused ? (getIn(touched, `weights[${index}].value`) && !!getIn(errors, `weights[${index}].value`)) ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                        }

                                                                                    }),
                                                                                    menu: (styles: Data<string>) => ({
                                                                                        ...styles,
                                                                                        zIndex: '1031'
                                                                                    })
                                                                                }}
                                                                                name={` weights[${index}].value`}
                                                                                value={weight.value ? { label: weight.value, value: weight.value } : ""}
                                                                                isClearable={false}
                                                                                placeholder={t('dashboard.options.modal_weight.columns.enter_placeholder')}
                                                                                options={getMethodTypes().map(t => ({ value: t, label: t }))}
                                                                                getOptionLabel={(value: FieldSelect) => value.label}
                                                                                getOptionValue={(value: FieldSelect) => value.value}
                                                                                onChange={(value: FieldSelect) => {
                                                                                    setFieldValue(`weights[${index}].value`, value.value)
                                                                                }}
                                                                            />
                                                                        }
                                                                    </Form.Group>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="text-primary fw-bold">
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Popover id={`popover-type-${index}`} className={!getIn(touched, `weights[${index}].weight`) || typeof getIn(errors, `weights[${index}].weight`) !== 'string' ? "d-none" : ""}>
                                                                            <Popover.Body>
                                                                                <Form.Text className="invalid-feedback d-block">{getIn(errors, `weights[${index}].weight`)}</Form.Text>
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Form.Group controlId="weight">
                                                                        <Form.Control
                                                                            className="text-center"
                                                                            placeholder={t('dashboard.options.modal_weight.columns.enter_placeholder')}
                                                                            type="text"
                                                                            name={`weights[${index}].weight`}
                                                                            value={weight.weight}
                                                                            onChange={handleChange}
                                                                            isInvalid={getIn(touched, `weights[${index}].weight`) && !!getIn(errors, `weights[${index}].weight`)}

                                                                        />
                                                                    </Form.Group>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td className="text-nowrap">
                                                                <Button size="sm" variant="link" className="p-0 pe-1 text-danger" onClick={() => {
                                                                    const weightsArray = Array.from(values.weights)
                                                                    weightsArray.splice(index, 1)
                                                                    setFieldValue("weights", weightsArray)
                                                                }}>
                                                                    <DeleteIcon width="24px" height="100%" className="align-text-top" />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Form.Group>

                                <Container className='d-flex justify-content-end'>
                                    <Button className="m-1 no-print" variant="secondary" onClick={() => {
                                        const weightsArray = Array.from(values.weights)
                                        weightsArray.push({
                                            field: "method_type",
                                            value: "",
                                            weight: 1
                                        })
                                        setFieldValue("weights", weightsArray)
                                    }}> {t('dashboard.options.modal_weight.add_weight')}</Button>
                                    <Button className="m-1 no-print" type="submit" variant="secondary"> {t('dashboard.options.modal_weight.validate')}</Button>
                                </Container>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default withTranslation()(ModalDashboardParamsWeightComponent)