import { WithTranslation, withTranslation } from "react-i18next";
import { ValorizationResponse } from "../../../../api/matrixApi"
import { Modal, Table } from "react-bootstrap";
import useFilterToolkit from "../../../../common/utils/useFilterToolkit";
import ButtonHeaderList from "../../../../common/components/ButtonHeaderList";
import { ReactComponent as SquareIcon } from 'bootstrap-icons/icons/square-fill.svg'
import React from 'react'


interface ModalMatrixValorizationProps extends WithTranslation {
    valoRes: ValorizationResponse[]
    showModalValo: boolean
    setShowModalValo: (show: boolean) => void

}


const ModalMatrixalorization: React.FC<ModalMatrixValorizationProps> = ({ t, valoRes, showModalValo, setShowModalValo }: ModalMatrixValorizationProps) => {

    return (
        <Modal size="lg" show={showModalValo} onHide={() => setShowModalValo(false)}>
            <Modal.Header>
                <Modal.Title><span className="text-primary fw-bold"> {t('matrix.matrix.modal_valo.title')} </span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table
                    striped
                    hover
                    responsive="lg"
                    className="mt-2 bg-white text-center"
                    size="md"
                >
                    <thead>
                        <tr>
                            <th className="p-1" />
                            <th><ButtonHeaderList name="export_code" title={t("matrix.matrix.modal_valo.export_code")} type={["label"]} /></th>
                            <th><ButtonHeaderList name="version" title={t("matrix.matrix.modal_valo.version")} type={["label"]} /></th>
                            <th><ButtonHeaderList name="status" title={t("matrix.matrix.modal_valo.status")} type={["label"]} /></th>
                            <th><ButtonHeaderList name="result" title={t("matrix.matrix.modal_valo.result")} type={["label"]} /></th>
                            <th><ButtonHeaderList name="comment" title={t("matrix.matrix.modal_valo.comment")} type={["label"]} /></th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            valoRes.map(e => {
                                return e.matrices.map((res) => {
                                    return (
                                        <tr key={res.export_code}>
                                            <td className="p-1 table-hover-hint" />
                                            <td>{res.export_code}</td>
                                            <td>{res.version}</td>
                                            <td>{res.matrix_status}</td>
                                            <td><SquareIcon className='d-block mx-auto' style={{ color: (res?.valo_status === 'OK' ? 'green' : 'red') }} /></td>
                                            <td>{t("matrix.matrix.modal_valo.messages."+res.message_id)}</td>
                                        </tr>
                                    );
                                })
                            })
                        }
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    )
}

export default withTranslation()(ModalMatrixalorization)