import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import dbt from '../../../common/utils/dbTranslation';
import userHasRequiredRoles from '../../../common/auth/userHasRequiredRoles'
import { ReactComponent as CheckedIcon } from 'bootstrap-icons/icons/check-square.svg'
import { ReactComponent as UncheckedIcon } from 'bootstrap-icons/icons/square.svg'
import { Link, useHistory, useLocation } from "react-router-dom";
import * as Yup from 'yup';

import { Table, Row, Col, Button, Container, Form, Modal, Accordion } from 'react-bootstrap';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { fetchFamilies, fetchLanguages, fetchPackages, fetchPatterns, fetchTypes } from '../../../state/configSlice';
import { fetchAllCompanies } from '../../../state/companiesSlice';
import { pdcReducer, singleFilterByReducer, singleSortByReducer } from '../../../state/pdcSlice';
import ControlPlanApi, { ControlPlan, SamplePackage, Sample, SamplePackageTest } from '../../../api/controlPlanApi';
import { fetchEmployees } from '../../../state/employeesSlice'
import { ReactComponent as AddIcon } from 'bootstrap-icons/icons/plus.svg'
import Loader from '../../../common/components/Loader';
import GoBack from '../../../common/components/GoBack';
import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg'
import Select from 'react-select'
import { getIn, useFormik } from 'formik';
import StatusPdcBar from '../../../common/components/StatusPdcBar';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';
import moment from 'moment';
import Reference from 'yup/lib/Reference';
import { Location } from 'history';
import { Prompt } from 'react-router-dom';
import { Company } from '../../../api/usersApi';
import { Data, TupleId } from '../../../types/types';
import ControlPlanRow from './ControlPlanRow'
import { Laboratory, Pattern, getAnalyteKey } from '../../../api/configApi';
import { setAuthCurLabId } from '../../../state/authUserSlice';
import useSecurityRoles from '../../../common/auth/useSecurityRoles';
import { fetchPriceLists } from '../../../state/priceListSlice';
import { fetchPatternFamilies } from '../../../state/patternsFamiliesSlice';
import PricesCalculator, { ControlPlanCalculatedInfos } from '../helpers/PricesCalculator';
import { fetchAnalytes } from '../../../state/analyteSlice';
import { fetchAnalyteTestPackages } from '../../../state/analyteTestsPackagesSlice';

interface ControlPlanFormProps extends WithTranslation {
    dataKey: TupleId,
}

enum ControlPlanStatus {
    created = "created",
    published = "published",
    canceled = "canceled",
    closed = "closed"
}

const ControlPlanForm: React.FC<ControlPlanFormProps> = ({ t, dataKey }) => {

    const dispatch = useDispatch()
    const { userIsInternal, userControlPlanAdmin, userGrantControlPlanAdmin, userHasRoleAdmin, userHasRoleClientCp, userHasRoleUser, userHasOnePdcLevelAdmin } = useSecurityRoles()

    const history = useHistory()
    const query = new URLSearchParams(useLocation().search)

    // user state
    const { user: authUser, authCompanies, authCustomers, authCurLabId } = useSelector((state: RootState) => state.authUser)
    const hasMultipleLabs: boolean = authUser.laboratories.length > 1 

    const fetchStatus = useSelector((state: RootState) => state.authUser.fetchStatus)
    const fetchError = useSelector((state: RootState) => state.authUser.fetchError)

    // types state
    const types = useSelector((state: RootState) => state.config.types.data)
    const typesStatus = useSelector((state: RootState) => state.config.types.fetchStatus)
    const typesError = useSelector((state: RootState) => state.config.types.fetchError)

    // patterns state
    const patterns = useSelector((state: RootState) => state.config.patterns.data)
    const patternsStatus = useSelector((state: RootState) => state.config.patterns.fetchStatus)
    const patternsError = useSelector((state: RootState) => state.config.patterns.fetchError)

    // languages state
    const languagesStatus = useSelector((state: RootState) => state.config.languages.fetchStatus)
    const language = useSelector((state: RootState) => state.local.language)

    //  Laboratories state
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)

    // families state
    const families = useSelector((state: RootState) => state.config.families.data)
    const familiesStatus = useSelector((state: RootState) => state.config.families.fetchStatus)
    const familiesError = useSelector((state: RootState) => state.config.families.fetchError)

    // Package state
    const packages = useSelector((state: RootState) => state.config.packages.data)
    const packagesStatus = useSelector((state: RootState) => state.config.packages.fetchStatus)
    const packagesError = useSelector((state: RootState) => state.config.packages.fetchError)

    // Test state
    const analytes = useSelector((state: RootState) => state.analytes.data)
    const analytesStatus = useSelector((state: RootState) => state.analytes.fetchStatus)
    const analytesError = useSelector((state: RootState) => state.analytes.fetchError)

    // Companies state
    const companies = useSelector((state: RootState) => state.companies.data)
    const companiesByLabId = useSelector((state: RootState) => state.companies.byLabId)
    const companiesAllStatus = useSelector((state: RootState) => state.companies.fetchAllStatus)
    const companiesError = useSelector((state: RootState) => state.companies.fetchError)

    // Employees state
    const employees = useSelector((state: RootState) => state.employees.data)
    const employeesStatus = useSelector((state: RootState) => state.employees.fetchStatus)
    const employeesError = useSelector((state: RootState) => state.employees.fetchError)

    //Price lists state

    const priceListStatus = useSelector((state: RootState) => state.priceList.fetchStatus)
    const priceListerror = useSelector((state: RootState) => state.priceList.fetchError)
    const priceLists = useSelector((state: RootState) => state.priceList.data);


    // analyteTestPackage state
    const analyteTestPackageError = useSelector(
     (state: RootState) => state.analyteTestsPackages.fetchError
    );
    const analyteTestPackageStatus = useSelector(
        (state: RootState) => state.analyteTestsPackages.fetchStatus
       );
  
    const controlPlans = useSelector((state: RootState) => state.pdc.data)

    const [createdId, setCreatedId] = useState<number | undefined>(undefined)

    const { filterBy, sortBy } = useSelector((state: RootState) => state.pdc.samplesFilter)

    const [apiStatusError, setApiStatusError] = useState(undefined)

    const [edit, setEdit] = useState(true);

    // patternsFamiliesState
    const patternsFamiliesError = useSelector(
        (state: RootState) => state.patternsFamilies.fetchError
    );
    const patternsFamiliesStatus = useSelector(
        (state: RootState) => state.patternsFamilies.fetchStatus
        );
    

    let hasRoleAdmin = false
    let hasRoleClient = false
    let hasRoleUser = false
    let hasRolePDCAdmin = false
    let isInternal = false


    updateRights(dataKey.id !== '0' && dataKey.labId !== '0'? Number(dataKey.labId) : undefined );

    const canChooseCompany = (hasRoleAdmin || (hasRoleClient && authCompanies.concat(authCustomers).length > 1) || (hasRoleUser && authCompanies.length > 1))

    useEffect(() => {
        if (dataKey.id !== "0" && query.get("edit") !== "true") {
            setEdit(false)
        }
        if (!userGrantControlPlanAdmin(undefined, authUser) ) {
            setEdit(false)
        }
    }, [dataKey, hasRolePDCAdmin])

    useEffect(() => {
        if (dataKey.id !== "0" && (dataKey.id !== String(values.id) || dataKey.labId !== String(values.laboratory_id))) {
            resetForm({ values: controlPlans[dataKey.labId][dataKey.id] })
            setEdit(false)
        }
    }, [dataKey])

    const { FilterList, ListHeader, filterAndSortList } = useFilterToolkit(filterBy, sortBy, {
        i18nPrefix: "pdc.table.columns.",
        onlyActive: false,
        fields: {
            type: {
                type: ["list"],
                sortable: true,
                inOpt: {
                    dbt: {
                        elements: types,
                        field: "name",
                        idFieldName: "type_id"
                    }
                }
            },
            family: {
                type: ["filter"],
                sortable: true
            },
            denomination: {
                type: ["filter"],
                sortable: true
            },
            pattern: {
                type: ["filter"],
                sortable: true
            },
            nb_sample: {
                type: ["number"],
                sortable: true
            },
            nb_used: {
                type: ["number"],
                sortable: true
            },
            nb_test: {
                type: ["number"],
                sortable: true
            },
            total_price: {
                type: ["number"],
                sortable: true,
            },
            total_cost: {
                type: ["number"],
                sortable: true
            },
            unit_price: {
                type: ["number"],
                sortable: true
            },
            unit_cost: {
                type: ["number"],
                sortable: true
            }

        },
        reducers: {
            filterBy: singleFilterByReducer,
            sortBy: singleSortByReducer
        }
    })

    const emptyControlPlan: ControlPlan = {
        id: 0,
        company_id: !canChooseCompany ? authUser.companies[0].company_id : 0,
        laboratory_id: userGrantControlPlanAdmin(authCurLabId, authUser) ? authCurLabId: Object.values(laboratories).find(l => userGrantControlPlanAdmin(l.id, authUser))?.id || 0, 
        start_date: "",
        end_date: "",
        name: "",
        status: "created",
        billable: false,
        nb_sample: 0,
        nb_used: 0,
        nb_test: 0,
        last_date: "",
        total_price: null,
        total_cost: null,
        control_plan_samples: []
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim(t("pdc.errors.name_required"))
            .required(t("pdc.errors.name_required")),
        billable: Yup.boolean()
            .required(t("pdc.errors.billable_required")),
        start_date: Yup.date()
            .required(t("pdc.errors.start_date_required")),
        end_date: Yup.date()
            .min(
                Yup.ref('start_date'),
                t("pdc.errors.end_date_start_date")
            )
            .required(t("pdc.errors.end_date_required")),
        laboratory_id: Yup.number()
            .notOneOf([0], t("pdc.errors.laboratory_required"))
            .required(t("pdc.errors.laboratory_required")),
        company_id: Yup.number()
            .notOneOf([0], t("pdc.errors.company_required"))
            .required(t("pdc.errors.company_required")),
        control_plan_samples: Yup.array().of(
            Yup.object().shape({
                type_id: Yup.number()
                    .notOneOf([0], t("pdc.errors.type_required"))
                    .required(t("pdc.errors.type_required")),
                family_id: Yup.number()
                    .notOneOf([0], t("pdc.errors.family_required"))
                    .required(t("pdc.errors.family_required")),
                pattern_id: Yup.number()
                    .notOneOf([0], t("pdc.errors.pattern_required"))
                    .required(t("pdc.errors.pattern_required")),
                nb_used: Yup.number(),
                nb_sample: Yup.number()
                    .integer(t("pdc.errors.nb_sample_integer"))
                    .notOneOf([0], t("pdc.errors.nb_sample_zero"))
                    .positive(t("pdc.errors.nb_sample_positive"))
                    .required(t("pdc.errors.nb_sample_required"))
                    .min(Yup.ref("nb_used") as Reference<number>, t("pdc.errors.nb_sample_gte_nb_used")),
                nb_test: Yup.number()
                    .notOneOf([0], t("pdc.errors.tests_required"))
        })),
    })

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [post, setPost] = useState(false)
    const [apiError, setApiError] = useState(undefined)
    const [showError, setShowError] = useState(false)

    const formik = useFormik<ControlPlan>({
        initialValues: dataKey.id !== "0" ? controlPlans[dataKey.labId][dataKey.id] : emptyControlPlan,
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
                // When button submits form and form is in the process of submitting, submit button is disabled
                setSubmitting(true)
                setApiError(undefined)
                
                const samples =  values.control_plan_samples ? values.control_plan_samples.map(sample => (
                     {
                        id: sample.id,
                        type_id: sample.type_id,
                        family_id: sample.family_id,
                        family_details: sample.family_details ? sample.family_details : "",
                        denomination: sample.denomination ? sample.denomination : "",
                        pattern_id: sample.pattern_id,
                        nb_sample: sample.nb_sample,
                        comment: sample.comment ? sample.comment : "",
                        sample_packages: sample.sample_packages.map(sample_package => 
                            ({...sample_package,
                                sample_package_tests: sample_package.sample_package_tests.map(sample_package_test => 
                                    ({...sample_package_test,
                                        theoretical_value :  sample_package_test.theoretical_value
                                    })
                                )
                            })
                         
                        ),
                        pattern_price: sample.pattern_price,
                        currency: sample.currency ? sample.currency : '',
                        pattern_cost: sample.pattern_cost,
                        pattern_period: sample.pattern_period,
                        pattern_weight: sample.pattern_weight
                        
                }

            ))
                : []


            const controlPlanRequest = {
                company_id: values.company_id,
                start_date: new Date(values.start_date).toISOString(),
                end_date: new Date(values.end_date).toISOString(),
                name: values.name,
                billable: values.billable,
                control_plan_samples: samples,
                total_price: values.total_price,
                total_cost: values.total_cost,
                
            }

            if (typeof values.id === "number" && values.id === 0) {
                try {
                    const response = await ControlPlanApi.getInstance().postPdc(controlPlanRequest, values.laboratory_id)
                    setCreatedId(response.id)
                    resetForm({values: response})
                    dispatch(pdcReducer(response))
                    if (!modalVisible) setFormSubmitted(true)
                    setPost(true)
                } catch(err) {
                    setApiError((err as any).message)
                    setShowError(true)
                } finally {
                    setSubmitting(false)
                }
            } else {
                try {
                    if (values.id) {
                        const response = await ControlPlanApi.getInstance().updatePdc(controlPlanRequest, values.id, values.laboratory_id)
                        resetForm({ values: response })
                        dispatch(pdcReducer(response))
    
                        if (!modalVisible) setFormSubmitted(true)
                        setPost(false)
                    }
                } catch(err) {
                    setApiError((err as any).message)
                    setShowError(true)
                } finally {
                    setSubmitting(false)
                }
            }
        }
    })

    const { initialValues,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        resetForm,
        setValues,
        isSubmitting } = formik

    useEffect(() => {
        if (dataKey.id !== "0" && (dataKey.id !== String(values.id) || dataKey.labId !== String(values.laboratory_id))) {
            resetForm({ values: controlPlans[dataKey.labId][dataKey.id] })
            setEdit(false)
        }
    }, [dataKey])

    const updateStatus = async (status: ControlPlanStatus) => {
        try {
            if(values.id){
                const response = await ControlPlanApi.getInstance().updateStatusPdc(status, values.id, values.laboratory_id)
                setApiStatusError(undefined)

                // Update local state 
                resetForm({ values: response })

                // Update local state 
                await setFieldValue("controlPlan", response)
                resetForm({ values: response })

                // Update redux state
                dispatch(pdcReducer(response))
            }
        } catch (err) {
            setApiStatusError(t('forms_errors.unexpected_error'))
        }
    }

    useEffect(() => {
        if (typesStatus === 'idle') {
            dispatch(fetchTypes())
        }
        if (familiesStatus === 'idle') {
            dispatch(fetchFamilies())
        }
        if (languagesStatus === 'idle') {
            dispatch(fetchLanguages())
        }
        if (patternsStatus === 'idle') {
            dispatch(fetchPatterns())
        }
        if (packagesStatus === 'idle') {
            dispatch(fetchPackages())
        }
        if (analytesStatus === 'idle') {
            dispatch(fetchAnalytes())
        }
        if (analyteTestPackageStatus === 'idle') {
            dispatch(fetchAnalyteTestPackages({laboratoryId: authCurLabId}))
        }
        if (employeesStatus === 'idle') {
            dispatch(fetchEmployees())
        }
        if (companiesAllStatus === 'idle') {
            dispatch(fetchAllCompanies())
        }
        if(priceListStatus === 'idle'){
            dispatch(fetchPriceLists({laboratoryId: authCurLabId}))
        }        
        if (patternsFamiliesStatus === 'idle') {
            dispatch(fetchPatternFamilies({ laboratoryId: authCurLabId }));
        }
    }, [employeesStatus, authUser, typesStatus, analytesStatus, analyteTestPackageStatus, packagesStatus, familiesStatus, languagesStatus, patternsStatus, patternsFamiliesStatus])

    const [showPopupCancel, setShowPopupCancel] = useState(false);
    const [showPopupClose, setShowPopupClose] = useState(false);
    const [showPopupDuplicate, setShowPopupDuplicate] = useState(false);
    const [showPopupPublish, setShowPopupPublish] = useState(false);

    const [modalVisible, setModalVisible] = useState(false);

    const [filteredSamplesIds, setFilteredSamplesIds] = useState<Array<string>>([])

    useEffect(() => {
        if (values.control_plan_samples) {
            const enriched = values.control_plan_samples.map((item: Sample) => (
                {
                    ...item,
                    type: dbt(types, item.type_id, language.id, "name"),
                    family: dbt(families, item.family_id, language.id, "name"),
                    pattern: dbt(patterns, item.pattern_id, language.id, "name")
                }
            ))
            const filtered = filterAndSortList(enriched)
            setFilteredSamplesIds(filtered)
        }
    }, [filterBy, sortBy, values.control_plan_samples])

    const getNumberOfNonUsed = (cps: Sample[]) => {
        let nb = 0
        for (const cp of cps) {
            if (cp.nb_used)
            nb += cp.nb_sample - cp.nb_used
        }
        return nb
    }

    const hasError = () => {
        if (Object.keys(errors).length === 0) {
            return false
        } else {
            return true
        }
    }

    const changesAreNotSaved = () => {
        if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {

        if (values.control_plan_samples && values.status === ControlPlanStatus.created ) {
            refreshPWC()
        }
    }, [values.company_id, values.status])

    const refreshPWC = () => {
        if (values.control_plan_samples !== undefined) {
            const updCpsArray = [...values.control_plan_samples]
            
            filteredSamplesIds.map(id => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const index = values.control_plan_samples!.findIndex(cps => cps.id === Number(id))
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const cps: Sample = index !== -1 && values.control_plan_samples![index] ? JSON.parse(JSON.stringify(values.control_plan_samples![index])) : null
                if (cps && cps.pattern_id) {
                    const pattern: Pattern = patterns[cps.pattern_id]
                    const sample_packages = cps.sample_packages
                    sample_packages.forEach((sp) => {
                        sp.price = PricesCalculator.getPrice(priceLists, values.company_id, packages[sp.package_id])
                        sp.currency = PricesCalculator.getCurrency(priceLists, values.company_id)
                        sp.sample_package_tests.forEach((spt) => {
                            spt.price = PricesCalculator.getPrice(priceLists, values.company_id, analytes[getAnalyteKey({...spt, laboratory_id: values.laboratory_id})])
                            spt.currency = PricesCalculator.getCurrency(priceLists, values.company_id)
                        }) 
                    })

                    const allPricesAndCosts = PricesCalculator.getAllPricesAndCosts(
                        pattern,
                        priceLists,
                        values.company_id,
                        sample_packages


                    )

                  
                    const updCps = {
                        ...cps,
                        pattern_id: pattern !== null ? pattern.id : 0,
                        nb_test: allPricesAndCosts.nb_test,
                        pattern_price: allPricesAndCosts.pattern_price,
                        currency: PricesCalculator.getCurrency(priceLists, values.company_id),
                        pattern_cost: pattern.cost,
                        pattern_period: pattern.period,
                        pattern_weight: pattern.weight,
                        unit_price: allPricesAndCosts.unit_prices,
                        total_sample_price: allPricesAndCosts.unit_prices != null ? allPricesAndCosts.unit_prices * Number(cps.nb_sample || 0) : null,
                        unit_cost: allPricesAndCosts.unit_cost,
                        total_sample_cost: allPricesAndCosts.unit_cost != null ? allPricesAndCosts.unit_cost * Number(cps.nb_sample || 0) : null,
                    }

                    updCpsArray[index] = updCps
                } else {
                    updCpsArray[index] = cps
                }
                setFieldTouched(`control_plan_samples[${index}].type_id`, false)
                setFieldTouched(`control_plan_samples[${index}].family_id`, false);
                setFieldTouched(`control_plan_samples[${index}].denomination`, false)
                setFieldTouched(`control_plan_samples[${index}].pattern_id`, false); 
                setFieldTouched(`control_plan_samples[${index}].nb_test`, false);
            })
            const updatedValues = {
                ...values,
                control_plan_samples: updCpsArray,
                nb_test: updCpsArray.reduce((accumulator, object) => { return accumulator + Number(object.nb_test) }, 0),
                total_price: updCpsArray.filter((cps) => cps.total_sample_price != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_price != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_price) }, 0) : null,
            }

            setValues(updatedValues)
        }

    }
    interface Props {
        when?: boolean | undefined;
        navigate: (path: string) => void;
        shouldBlockNavigation: boolean;
    }

    const RouteLeavingGuard = ({
        when,
        navigate,
        shouldBlockNavigation,
    }: Props) => {
        const [lastLocation, setLastLocation] = useState<Location | null>(null);
        const [confirmedNavigation, setConfirmedNavigation] = useState(false);
        const closeModal = () => {
            setModalVisible(false);
        };
        const handleBlockedNavigation = (nextLocation: Location): boolean => {
            if (!confirmedNavigation && shouldBlockNavigation) {
                setModalVisible(true);
                setLastLocation(nextLocation);
                return false;
            }
            return true;
        };
        const handleConfirmNavigationClick = () => {
            setModalVisible(false);
            setConfirmedNavigation(true);
        };
        useEffect(() => {
            if (confirmedNavigation && lastLocation) {
                // Navigate to the previous blocked location with your navigate function
                navigate(lastLocation.pathname);
            }
        }, [confirmedNavigation, lastLocation, navigate]);
        return (
            <>
                <Prompt when={when} message={handleBlockedNavigation} />
                <Modal className="no-print" size="lg" show={modalVisible} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('pdc.save_changes')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="m-2">
                            {t('pdc.save_changes_message1')}<br />
                            {t('pdc.save_changes_message2')}
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <Button className="m-1 no-print" variant="dark" onClick={closeModal} >{t('pdc.stay')}</Button>
                            </Col>
                            <Col className="text-center">
                                {!hasError() && <Button className="m-1 no-print" variant="danger" onClick={handleConfirmNavigationClick} >{t('pdc.quit_without_save')}</Button>}
                            </Col>
                            <Col className="text-center">
                                {hasError() ?
                                    <Button className="m-1 no-print" variant="danger" onClick={handleConfirmNavigationClick} >{t('pdc.quit_without_save')}</Button>
                                    :
                                    <Button className="m-1 no-print" variant="secondary" type="submit" onClick={() => { handleSubmit(); handleConfirmNavigationClick() }} >{t('pdc.save_and_quit')}</Button>
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        )
    };

    return (
        <Container className="mt-5 p-0 pdc-container">
            {RouteLeavingGuard({ when: changesAreNotSaved(), navigate: history.push, shouldBlockNavigation: true })}

            {employeesStatus === 'succeeded' && companiesAllStatus === 'succeeded' && familiesStatus === 'succeeded' && patternsStatus === 'succeeded' && packagesStatus === 'succeeded' && analytesStatus === 'succeeded' && analyteTestPackageStatus === 'succeeded' && fetchStatus === 'succeeded' &&
                <>

                    {/* Error modal */}
                    <Modal className="no-print" size="lg" show={showError} onHide={() => setShowError(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('error')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="m-2 text-danger">
                                {apiError}
                            </Row>
                            <Row>
                                <Col className="text-end">
                                    <Button className="m-1 no-print" variant="secondary" onClick={() => { setShowError(false) }} >{t('buttons.cancel')}</Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>

                    {/* Cancel warning modal */}
                    <Modal className="no-print" size="lg" show={showPopupCancel} onHide={() => setShowPopupCancel(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('pdc.question_cancel_pdc')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="m-2">
                                {t('pdc.message_cancel_pdc1')}<br />
                                {t('pdc.message_cancel_pdc2')}
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <Button className="m-1 no-print" variant="secondary" onClick={() => { setShowPopupCancel(false) }} >{t('pdc.go_back')}</Button>
                                </Col>
                                <Col className="text-center">
                                    <Button className="m-1 no-print" variant="danger" onClick={() => { updateStatus(ControlPlanStatus.canceled); setShowPopupCancel(false) }} >{t('pdc.cancel_pdc')}</Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>

                    {/* Close warning modal */}
                    <Modal className="no-print" size="lg" show={showPopupClose} onHide={() => setShowPopupClose(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('pdc.question_close_pdc')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="m-2">
                                {t('pdc.message_close_pdc1')}<br />
                                {t('pdc.message_close_pdc2')}
                                {values.control_plan_samples && getNumberOfNonUsed(values.control_plan_samples)}
                                {t('pdc.message_close_pdc3')}
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <Button className="m-1 no-print" variant="secondary" onClick={() => { setShowPopupClose(false) }} >{t('pdc.go_back')}</Button>
                                </Col>
                                <Col className="text-center">
                                    <Button className="m-1 no-print" variant="danger" onClick={() => { updateStatus(ControlPlanStatus.closed); setShowPopupClose(false) }} >{t('pdc.close_pdc')}</Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>

                    {/* Publish warning modal */}
                    <Modal className="no-print" size="lg" show={showPopupPublish} onHide={() => setShowPopupPublish(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('pdc.question_publish_pdc')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="m-2">
                                {values.control_plan_samples && values.control_plan_samples.length > 0 &&
                                    <>
                                        {t('pdc.message_publish_pdc1')}<br />
                                        {t('pdc.message_publish_pdc2')}<br />
                                        {t('pdc.message_publish_pdc3')}
                                    </>
                                }
                                {values.control_plan_samples && values.control_plan_samples.length === 0 &&
                                    <>
                                        {t('pdc.message_publish_pdc_without_sample')}<br />
                                    </>
                                }
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <Button className="m-1 no-print" variant="secondary" onClick={() => { setShowPopupPublish(false) }} >{t('pdc.go_back')}</Button>
                                </Col>
                                {values.control_plan_samples && values.control_plan_samples.length > 0 &&
                                    <Col className="text-center">
                                        <Button className="m-1 no-print" variant="success" onClick={() => { updateStatus(ControlPlanStatus.published); setShowPopupPublish(false) }} >{t('pdc.publish_pdc')}</Button>
                                    </Col>
                                }
                            </Row>
                        </Modal.Body>
                    </Modal>

                    {/* Duplicate warning modal */}
                    <Modal className="no-print" size="lg" show={showPopupDuplicate} onHide={() => setShowPopupDuplicate(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('pdc.question_duplicate_pdc')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="m-2">
                                {t('pdc.message_duplicate_pdc1')}<br />
                                {t('pdc.message_duplicate_pdc2')}
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <Button className="m-1 no-print" variant="secondary" onClick={() => { setShowPopupDuplicate(false) }} >{t('pdc.go_back')}</Button>
                                </Col>
                                <Col className="text-center">
                                    <Button className="m-1 no-print" variant="danger" onClick={async () => {
                                        const duplicate = { ...values }
                                        duplicate.id = 0
                                        duplicate.status = 'created'
                                        duplicate.nb_used = 0

                                        if (duplicate.control_plan_samples) {
                                            const cps = duplicate.control_plan_samples.map(sample => {
                                                const dupPattern = patterns[sample.pattern_id].active && families[sample.family_id].active ? patterns[sample.pattern_id] : undefined

                                                const sample_packages:any = patterns[sample.pattern_id].active && families[sample.family_id].active ?
                                                sample.sample_packages.filter(p => packages[p.package_id] && packages[p.package_id].active && p.sample_package_tests.filter(t => analytes[getAnalyteKey({...t, laboratory_id: values.laboratory_id})] && analytes[getAnalyteKey({...t, laboratory_id: values.laboratory_id})].active).length > 0)
                                                    .map(p => {
                                                        return ({
                                                            ...p,
                                                            price: PricesCalculator.getPrice(priceLists, duplicate.company_id, packages[p.package_id]),
                                                            currency: PricesCalculator.getCurrency(priceLists, duplicate.company_id),
                                                            cost: packages[p.package_id].cost,
                                                            period: packages[p.package_id].period,
                                                            weight: packages[p.package_id].weight,
                                                            sample_package_tests: p.sample_package_tests.filter(t => analytes[getAnalyteKey({...t, laboratory_id: values.laboratory_id})] && analytes[getAnalyteKey({...t, laboratory_id: values.laboratory_id})].active)
                                                                .map(t => {
                                                                    const analyteId = getAnalyteKey({...t, laboratory_id: values.laboratory_id})
                                                                    return ({
                                                                        ...t,
                                                                        price: PricesCalculator.getPrice(priceLists, duplicate.company_id, analytes[analyteId]) ,
                                                                        currency: PricesCalculator.getCurrency(priceLists, values.company_id),
                                                                        cost: analytes[analyteId].cost,
                                                                        period: analytes[analyteId].period,
                                                                        weight: analytes[analyteId].weight,
                                                                    })
                                                                })
                                                        })
                                                    }) : []
                                                    
                                                
                                                let allPricesAndCosts: ControlPlanCalculatedInfos | undefined = undefined
                                                if(dupPattern){
                                                    allPricesAndCosts = PricesCalculator.getAllPricesAndCosts(
                                                        dupPattern,
                                                        priceLists,
                                                        values.company_id,
                                                        sample_packages
                                                    );
                                                }
                                                return ({
                                                    ...sample,
                                                    nb_used: 0,
                                                    family_id: families[sample.family_id].active ? sample.family_id : 0,
                                                    pattern_id: patterns[sample.pattern_id].active && families[sample.family_id].active ? sample.pattern_id : 0,
                                                    pattern_price: dupPattern ? PricesCalculator.getPrice(priceLists, duplicate.company_id, dupPattern) : 0,
                                                    currency: dupPattern ? PricesCalculator.getCurrency(priceLists, duplicate.company_id):'',
                                                    pattern_cost: dupPattern ? dupPattern.cost : null,
                                                    pattern_period: dupPattern ? dupPattern.period : null,
                                                    pattern_weight: dupPattern ? dupPattern.weight : null,
                                                    sample_packages: sample_packages,
                                                    unit_price: allPricesAndCosts ? allPricesAndCosts.unit_prices : null,
                                                    unit_cost: allPricesAndCosts ? allPricesAndCosts.unit_cost : null,
                                                    total_sample_price: allPricesAndCosts && allPricesAndCosts.unit_prices !=null ? allPricesAndCosts.unit_prices * Number(sample.nb_sample || 0) : null,
                                                    total_sample_cost: allPricesAndCosts && allPricesAndCosts.unit_cost != null ? allPricesAndCosts.unit_cost * Number(sample.nb_sample || 0) : null,
                                                    nb_test: patterns[sample.pattern_id].active && families[sample.family_id].active ? [0].concat(sample.sample_packages.filter(p => packages[p.package_id] && packages[p.package_id].active).map(p => ({ ...p, sample_package_tests: p.sample_package_tests.filter(t => analytes[getAnalyteKey({...t, laboratory_id: values.laboratory_id})] && analytes[getAnalyteKey({...t, laboratory_id: values.laboratory_id})].active) })).map(p => p.sample_package_tests.length)).reduce((p, c) => p + c) : 0
                                                })
                                            })
                                            duplicate.control_plan_samples = cps
                                            duplicate.nb_test = [0].concat(cps.map(sample => sample.nb_test)).reduce((p, c) => p + c)
                                            duplicate.total_price = cps.filter((cps) => cps.total_sample_price != null).length > 0 ? cps.filter((cps) => cps.total_sample_price != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_price)}, 0) : null,
                                            duplicate.total_cost = cps.filter((cps) => cps.total_sample_cost != null).length > 0 ? cps.filter((cps) => cps.total_sample_cost != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_cost)}, 0) : null
                                       
                                        }

                                        resetForm({ values: duplicate })

                                        setEdit(true)
                                        setPost(true)
                                        setShowPopupDuplicate(false)
                                    }
                                    } >{t('pdc.duplicate')}</Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>

                    {/* After submit modal */}
                    <Modal size="lg" show={formSubmitted}>
                        <Modal.Header>
                            <Modal.Title><span className="text-primary fw-bold">{t('pdc.pdc')} {t('pdc.registered')} </span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <span>{t('pdc.msg_success_add')}<br /></span>
                            <Row className="pt-2">
                                <Col className="text-start">
                                    <Link className="btn btn-secondary" to="/secure/pdcs">{t('nav.my_control_plans')} </Link>
                                </Col>
                                {post &&
                                    <Col className="text-start">
                                        <Button variant="secondary" onClick={() => { setFormSubmitted(false); setPost(false); history.push(`/secure/pdc/${values.laboratory_id}/${createdId}`) }}>{t('pdc.see_my_control_plan')} </Button>
                                    </Col>
                                }
                                <Col className="text-end">
                                    {post &&
                                        <Button variant="secondary" onClick={async () => { setFormSubmitted(false);await resetForm({ values: emptyControlPlan }); }}>{t('nav.create_control_plan')} </Button>
                                    }
                                    {!post &&
                                        <Button variant="secondary" onClick={() => { setEdit(false); setFormSubmitted(false) }}>{t('pdc.see_my_control_plan')} </Button>
                                    }
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>

                    <Form onSubmit={handleSubmit}>
                        {isSubmitting &&
                            <Loader />
                        }

                        <Container className="p-0">
                            <Row className="no-print">
                                <Col xs="auto">
                                    <GoBack to="/secure/pdcs/" label={t('pdc.return_to_my_list_pdc')} />
                                </Col>
                            </Row>
                            <Row className="pt-2 " >
                                <Col>
                                    <h4 className="pb-2 fw-bold"> {t('pdc.pdc')} <span className="text-primary fw-bold"> {Number(values.id) !== 0 ? t('pdc.number') + values.id : null}</span></h4>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                {!post &&
                                    <StatusPdcBar
                                        Date1={values.created_date ? new Date(values.created_date).toString() : undefined}
                                        Date2={values.last_update ? new Date(values.last_update).toString() : undefined}
                                        Date3={values.published_date ? new Date(values.published_date).toString() : undefined}
                                        Date4={values.canceled_date ? new Date(values.canceled_date).toString() : undefined}
                                        Date5={values.closed_date ? new Date(values.closed_date).toString() : undefined}
                                        Status={values.status} />
                                }
                            </Row>
                        </Container>
                        <Container className="pt-2">
                            <Container className="p-2 bg-white mb-2 rounded-3">
                                <Row >
                                    <Col lg>
                                        {!edit && <h4 className="text-primary fw-bold">{t('pdc.customer')}</h4>}
                                        {edit && (hasRoleAdmin || (hasRoleClient && authCompanies.concat(authCustomers).length > 1) || (hasRoleUser && authCompanies.length > 1)) &&
                                            <Container className="p-0" >
                                                <Row>
                                                    <Col xs="auto">
                                                        <Form.Label><h4 className="text-primary fw-bold">{t('pdc.customer')} *</h4></Form.Label>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        }
                                    </Col>
                                    <Col sm={1} className="text-end">
                                        {(values.status === ControlPlanStatus.created || values.status === ControlPlanStatus.published) && hasRolePDCAdmin && !edit && !edit &&
                                            <Button className="p-0 pe-1 text-primary no-print" variant="link" onClick={() => { setEdit(true) }}>
                                                <EditIcon width="24px" height="100%" className="align-text-top" />
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                                <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                <Row className="pb-2" >
                                    {(hasMultipleLabs || hasRoleAdmin) && 
                                    <Col>
                                        <Form.Group as={Row} controlId="formLaboratory">
                                            {!edit &&
                                                <Form.Group className="mb-2" controlId="formLaboratory">
                                                    <Form.Label><span className="text-black fw-bold">{t('forms.pdc.laboratory')}</span> {laboratories[values.laboratory_id] ? laboratories[values.laboratory_id].name : ""} </Form.Label>
                                                </Form.Group>
                                            }
                                            {edit &&
                                            <>
                                                <Col xs="auto" className="d-flex align-items-center">
                                                    <Form.Label className="m-0 text-black fw-bold">{t('forms.pdc.laboratory')} *</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Select 
                                                        noOptionsMessage={() => t('admin.users.form.no_laboratory')}
                                                        value={laboratories[values.laboratory_id]}
                                                        placeholder={t('admin.users.form.default_laboratory')}
                                                        getOptionLabel={(laboratory: Laboratory) => laboratory.name}
                                                        getOptionValue={(laboratory: Laboratory) => laboratory.id}
                                                        isDisabled={values.status !== "created"}
                                                        isClearable={false}
                                                        options={Object.values(laboratories).filter(l => userGrantControlPlanAdmin(l.id, authUser))}

                                                        onChange={async (laboratory: Laboratory) => {
                                                            if (laboratory) {
                                                                if (values.laboratory_id !== laboratory.id) {
                                                            
                                                                    const updatedValues = {
                                                                        ...values, 
                                                                        laboratory_id: Number(laboratory.id),
                                                                        company_id: 0,
                                                                        control_plan_samples: [],
                                                                        nb_sample: 0,
                                                                        nb_used: 0,
                                                                        nb_test: 0,
                                                                        total_price: null,
                                                                        total_cost: null
                                                                    }
                                                                    setValues(updatedValues)
                                                                    dispatch(setAuthCurLabId(laboratory.id))
                                                                }
                                                            } 
                                                        }}
                                                        styles={{
                                                            menu: (styles: Data<string>) => ({
                                                                ...styles,
                                                                'z-index': '1031'
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Form.Group>  
                                    </Col>
                                    }
                                    <Col>
                                        <Form.Group as={Row} controlId="formCustomer">
                                            {!edit &&
                                                <Form.Group className="mb-2" controlId="formLaboratory">
                                                    <Form.Label><span className="text-black fw-bold">{t('forms.pdc.company')}</span> {companies[values.company_id] ? companies[values.company_id].name : ""} </Form.Label>
                                                </Form.Group>
                                            }
                                            {edit &&
                                            <>
                                                <Col xs="auto" className="d-flex align-items-center">
                                                    <Form.Label className="m-0 text-black fw-bold">{t('forms.pdc.company')} *</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Select
                                                        className={getIn(touched, "company_id") && typeof getIn(errors, "company_id") === 'string' ? `imgErrorInvalidInput` : ``}
                                                        styles={{
                                                            control: (styles: Data<string|number>, state: Data<string|number|boolean>) => ({
                                                                ...styles,
                                                                borderColor: (getIn(touched, "company_id") && typeof getIn(errors, "company_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                boxShadow: state.isFocused ? (getIn(touched, "company_id") && typeof getIn(errors, "company_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                '&:hover': {
                                                                    borderColor: (getIn(touched, "company_id") && typeof getIn(errors, "company_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                    boxShadow: state.isFocused ? (getIn(touched, "company_id") && typeof getIn(errors, "company_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                }

                                                            }),
                                                            menu: (styles: Data<string|number>) => ({
                                                                ...styles,
                                                                zIndex: '1031'
                                                            })
                                                        }}

                                                        

                                                        noOptionsMessage={() => t('forms.pdc.companyNotFound')}
                                                        value={values.company_id && companies[values.company_id]}
                                                        name='company_id'
                                                        placeholder=""
                                                        getOptionLabel={(company: Company) => company.name}
                                                        getOptionValue={(company: Company) => company.id}
                                                        isDisabled={values.status !== "created"}
                                                        isClearable={true}
                                                        options={companiesByLabId[values.laboratory_id] && companiesByLabId[values.laboratory_id].map(id => companies[id])
                                                            .filter(item => item.active && item.id && (hasRoleAdmin ||
                                                                (hasRoleClient && (authCompanies.some(c => c.company_id === item.id && c.lab_type === laboratories[values.laboratory_id]?.lab_type) || authCustomers.some(c => c.company_id === item.id && c.lab_type === laboratories[values.laboratory_id]?.lab_type))) ||
                                                                (hasRoleUser && authCompanies.some(c => c.company_id === item.id && c.lab_type === laboratories[values.laboratory_id]?.lab_type))))
                                                            .sort((a, b) => a.name.localeCompare(b.name))
                                                        }

                                                        onChange={async (company: Company) => {
                                                            if (company) {
                                                                const oldValue = values.company_id
                                                                if (oldValue !== company.id) {
                                                                    await setFieldValue("company_id", company.id)
                                                                }
                                                            } else {
                                                                await setFieldValue("company_id", 0)
                                                                await setFieldTouched("company_id", true)
                                                                await setFieldTouched("company_id", false)
                                                            }
                                                        }}
                                                        onBlur={async () => { await setFieldTouched("company_id", true) }}
                                                    />
                                                    {getIn(touched, "company_id") && typeof getIn(errors, "company_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors, "company_id")}</Form.Text> : null}
                                                </Col>
                                            </>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg>
                                        {!edit &&
                                            <Form.Group className="mb-2" controlId="formName">
                                                <Form.Label><span className="text-black fw-bold">{t('pdc.name')}</span> {values.name} </Form.Label>
                                            </Form.Group>
                                        }
                                        {edit &&
                                            <Form.Group className="mb-2" controlId="formName">
                                                <Row>
                                                    <Col xs="auto" className="d-flex align-items-center">
                                                        <Form.Label className="m-0 text-black fw-bold">{t('pdc.name')} *</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control isInvalid={getIn(touched, "name") && !!getIn(errors, "name")} type="boolean" name='name' maxLength={255} value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                                        <Form.Control.Feedback type="invalid">{getIn(errors, "name")}</Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        }
                                    </Col>
                                    <Col lg className="d-flex align-items-center">
                                        {!edit &&
                                            <Form.Group className="mb-2" controlId="formBillable">
                                                <Form.Label>
                                                    <span className="text-black fw-bold">{t('pdc.billable')}</span>
                                                    {values.billable ?
                                                        <CheckedIcon width="20px" height="100%" className="align-text-top" />
                                                        :
                                                        <UncheckedIcon width="20px" height="100%" className="align-text-top" />}
                                                </Form.Label>
                                            </Form.Group>
                                        }
                                        {edit &&
                                            <Form.Group className="mb-2" controlId="formBillable">
                                                <Row>
                                                    <Col xs="auto" className="d-flex align-items-center">
                                                        <Form.Label className="align-middle m-0 text-black fw-bold">{t('pdc.billable')}</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Check
                                                            className="mt-1"
                                                            aria-label={t('pdc.billable')}
                                                            isInvalid={getIn(touched, "billable") && !!getIn(errors, "billable")}
                                                            type="checkbox"
                                                            name='billable'
                                                            checked={values.billable}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{getIn(errors, "billable")}</Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg>
                                        {!edit &&
                                            <Form.Group className="mb-2" controlId="formStartDate">
                                                <Form.Label><span className="text-black fw-bold">{t('pdc.start_date')}</span> {new Intl.DateTimeFormat(authUser.locale).format(new Date(values.start_date))} </Form.Label>
                                            </Form.Group>
                                        }
                                        {edit &&
                                            <Form.Group className="mb-2" controlId="formStartDate">
                                                <Row>
                                                    <Col xs="auto" className="d-flex align-items-center">
                                                        <Form.Label className="text-black fw-bold">{t('pdc.start_date')} *</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control isInvalid={getIn(touched, "start_date") && !!getIn(errors, "start_date")} type="date" name='start_date' maxLength={255} value={moment(values.start_date).format("YYYY-MM-DD")} onChange={handleChange} onBlur={handleBlur} />
                                                        <Form.Control.Feedback type="invalid">{getIn(errors, "start_date")}</Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        }
                                    </Col>
                                    <Col lg>
                                        {!edit &&
                                            <Form.Group className="mb-2" controlId="formEndDate">
                                                <Form.Label><span className="text-black fw-bold">{t('pdc.end_date')}</span> {new Intl.DateTimeFormat(authUser.locale).format(new Date(values.end_date))} </Form.Label>
                                            </Form.Group>
                                        }
                                        {edit &&
                                            <Form.Group className="mb-2" controlId="formEndDate">
                                                <Row>
                                                    <Col xs="auto" className="d-flex align-items-center">
                                                        <Form.Label className="text-black fw-bold">{t('pdc.end_date')} *</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control isInvalid={getIn(touched, "end_date") && !!getIn(errors, "end_date")} type="date" name='end_date' maxLength={255} value={moment(values.end_date).format("YYYY-MM-DD")} onChange={handleChange} onBlur={handleBlur} />
                                                        <Form.Control.Feedback type="invalid">{getIn(errors, "end_date")}</Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        }
                                    </Col>
                                </Row>
                                {hasRolePDCAdmin && Number(values.id) !== 0 &&
                                    <Row>
                                      
                                        <Col lg>
                                            {values.last_update_by && employees[values.last_update_by] &&
                                                <Form.Group className="mb-2">
                                                    <Form.Label><span className="text-black fw-bold">{t('pdc.last_update_by')} </span>{values.last_update_by ? employees[values.last_update_by].firstname + " " + employees[values.last_update_by].lastname : ""}</Form.Label>
                                                </Form.Group>
                                              }
                                        </Col>
                                      
                                        <Col lg>
                                            <Form.Group className="mb-2" controlId="formLastUpdate">
                                                <Form.Label><span className="text-black fw-bold">{t('pdc.last_update')} </span> {values.last_update ? Intl.DateTimeFormat(authUser.locale, { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" }).format(new Date(values.last_update)) : ""} </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                }
                            </Container>
                        </Container>

                        {!edit &&
                            <Container className="pt-2 justify-content-center mt-2">
                                <Row className="d-flex flex-row-reverse">
                                    <Col>
                                        <FilterList />
                                    </Col>
                                </Row>
                            </Container>
                        }
                        <Accordion>
                            <Container className="bg-white p-0 mb-2 rounded-3">
                                <Table striped hover responsive="xl" className="bg-white text-center">
                                    <thead>
                                        {edit ?
                                            <tr>
                                                <th>{t('pdc.table.columns.type')}</th>
                                                <th>{t('pdc.table.columns.family')}</th>
                                                <th>{t('pdc.table.columns.denomination')}</th>
                                                <th>{t('pdc.table.columns.pattern')}</th>
                                                <th>{t('pdc.table.columns.nb_sample')}</th>
                                                <th>{t('pdc.table.columns.nb_used')}</th>
                                                <th>{t('pdc.table.columns.nb_test')}</th>
                                                <th>{t('pdc.table.columns.total_price')}</th>
                                                {hasRolePDCAdmin && isInternal && 
                                                    <th>{t('pdc.table.columns.total_cost')}</th>
                                                }
                                                <th>{t('pdc.table.columns.unit_price')}</th>
                                                {hasRolePDCAdmin && isInternal && 
                                                    <th>{t('pdc.table.columns.unit_cost')}</th>
                                                }
                                                <th></th>
                                            </tr>
                                            :
                                            <tr>
                                                <th>{<ListHeader name="type" />}</th>
                                                <th>{<ListHeader name="family" />}</th>
                                                <th>{<ListHeader name="denomination" />}</th>
                                                <th>{<ListHeader name="pattern" />}</th>
                                                <th>{<ListHeader name="nb_sample" />}</th>
                                                <th>{<ListHeader name="nb_used" />}</th>
                                                <th>{<ListHeader name="nb_test" />}</th>
                                                <th>{<ListHeader name="total_price" />}</th>
                                                {hasRolePDCAdmin && isInternal && 
                                                    <th>{<ListHeader name="total_cost" />}</th>
                                                }
                                                <th>{<ListHeader name="unit_price" />}</th>
                                                {hasRolePDCAdmin && isInternal && 
                                                    <th>{<ListHeader name="unit_cost" />}</th>
                                                }

                                                <th></th>
                                            </tr>
                                        }
                                    </thead>
                                    <tbody>
                                        {values.control_plan_samples &&
                                            filteredSamplesIds.map(id => {
                                                const cps = values.control_plan_samples
                                                if (cps) {
                                                    const cpsIndex = cps.findIndex(cps => cps.id === Number(id))
                                                    const cpsKey = cpsIndex > -1 ? cps[cpsIndex] : ({} as Sample)
                                                    return { index: cpsIndex, key: cpsKey }
                                                } else {
                                                    return { index: -1, key: ({} as Sample) }
                                                }
                                            }).map((entry) => {
                                                if (entry.index > -1 && entry.key) {
                                                    const index = entry.index
                                                    const sample = entry.key
                                                    return (
                                                        <ControlPlanRow key={index} index={index} sample={sample} edit={edit} currentLaboratoryId={values.laboratory_id} formikProps={formik}/>
                                                        )
                                                } else {
                                                    return null
                                                }
                                            })}

                                    </tbody>


                                    <tfoot>
                                        <tr className="align-middle">
                                            <td colSpan={4} className="fw-bold">{t('pdc.total')}</td>
                                            <td>{values.nb_sample}</td>
                                            <td>{values.nb_used}</td>
                                            <td>{values.nb_test}</td>
                                            <td>{values.total_price != null ? Intl.NumberFormat(authUser.locale, { style: 'currency', currency: values.control_plan_samples && values.control_plan_samples.length > 0 && values.control_plan_samples[0].currency ? values.control_plan_samples[0].currency  : laboratories[values.laboratory_id].currency }).format(values.total_price) : ''}</td>
                                            {hasRolePDCAdmin && 
                                                <td>{values.total_cost != null ? Intl.NumberFormat(authUser.locale, { style: 'currency', currency: values.control_plan_samples && values.control_plan_samples.length > 0 && values.control_plan_samples[0].currency ? values.control_plan_samples[0].currency : laboratories[values.laboratory_id].currency }).format(values.total_cost) : ''}</td>
                                            }
                                            <td></td>
                                            {hasRolePDCAdmin && 
                                                <td></td>
                                            }
                                            <td></td>
                                        </tr>
                                    </tfoot>

                                </Table>

                                {edit &&
                                    <Row className="mb-2">
                                        <Col className="d-flex justify-content-end">
                                            <Button size="sm" className="me-2 mb-2 no-print" type="submit" variant="secondary"
                                                onClick={async () => {
                                                    const cps = values.control_plan_samples
                                                    if (cps) {
                                                        await setFieldValue('control_plan_samples', cps.concat([{
                                                            control_plan_id: values.id,
                                                            id: -1 - cps.length,
                                                            type_id: 0,
                                                            family_id: 0,
                                                            family_details: "",
                                                            denomination: "",
                                                            pattern_id: 0,
                                                            comment: "",
                                                            nb_sample: 0,
                                                            nb_used: 0,
                                                            nb_test: 0,
                                                            pattern_price: null,
                                                            currency: "",
                                                            pattern_cost:null,
                                                            pattern_period: null,
                                                            pattern_weight: null,
                                                            total_sample_price: null,
                                                            total_sample_cost: null,
                                                            unit_price: null,
                                                            unit_cost: null,
                                                            sample_packages: []
                                                        }]))
                                                    }
                                                }}
                                            >
                                                <AddIcon width="20px" height="100%" className="align-text-top" /> {t('buttons.add')}
                                            </Button>
                                        </Col>
                                    </Row>
                                }

                            </Container>

                        </Accordion>

                        <Container>
                            {(apiStatusError !== undefined) &&
                                <Container className="mt-2 bg-white text-danger">
                                    <p>{t('pdc.updateStatus') + ' - ' + t('forms_errors.unexpected_error') + ' (' + apiStatusError + ')'}</p>
                                </Container>
                            }
                            <Row className="mt-2">
                                <Col md="auto" className="p-0 me-auto">
                                    {values.status === ControlPlanStatus.created && hasRolePDCAdmin && !edit &&
                                        <Button className="m-1 no-print" variant="danger" onClick={() => { setShowPopupCancel(true) }} > {t('pdc.cancel')} </Button>
                                    }
                                    {values.status === ControlPlanStatus.published && hasRolePDCAdmin && !edit &&
                                        <Button className="m-1 no-print" variant="danger" onClick={() => { setShowPopupClose(true) }} > {t('pdc.close')} </Button>
                                    }
                                    {edit && values.id !== 0 &&
                                        <Button className="m-1 no-print" variant="dark" onClick={async () => { await resetForm(); setEdit(false) }} > {t('pdc.cancel_edit')} </Button>
                                    }
                                </Col> 

                                <Col md="auto" className="p-0 ms-auto">
                                    {(values.status === ControlPlanStatus.created || values.status === ControlPlanStatus.published) && hasRolePDCAdmin && !edit && !edit &&
                                        <Button className="m-1 no-print" variant="primary" onClick={() => { setEdit(true) }} > {t('pdc.edit')} </Button>
                                    }
                                    {hasRolePDCAdmin && !edit &&
                                        <Button className="m-1 no-print" variant="primary" onClick={() => { setShowPopupDuplicate(true) }} >{t('pdc.duplicate')}</Button>
                                    }
                                    {values.status === ControlPlanStatus.created && hasRolePDCAdmin && !edit &&
                                        <Button className="m-1 no-print" variant="success" onClick={() => { setShowPopupPublish(true) }} >{t('pdc.publish')}</Button>
                                    }
                                    {edit &&
                                        <Button disabled={hasError()} className="m-1 no-print" type="submit" variant={hasError() ? "dark" : "secondary"} > {t('pdc.save')} </Button>
                                    }
                                </Col>

                            </Row>
                        </Container>

                    </Form>
                </>

            }
            {(employeesStatus === 'loading' || companiesAllStatus === 'loading' || familiesStatus === 'loading' || typesStatus === 'loading' || patternsStatus === 'loading' || packagesStatus === 'loading' || analytesStatus === 'loading' || fetchStatus === 'loading') &&
                <Loader />
            }
            {(employeesStatus === 'failed' || companiesAllStatus === 'failed' || familiesStatus === 'failed' || typesStatus === 'failed' || patternsStatus === 'failed' || packagesStatus === 'failed' || analytesStatus === 'failed' || analyteTestPackageStatus === 'failed' || fetchStatus === 'failed') &&
                <Container className="mt-2 bg-white text-danger">
                    {familiesError && <p>{t('pdc.family') + ' - ' + t('forms_errors.unexpected_error') + ' (' + familiesError + ')'}</p>}
                    {typesError && <p>{t('pdc.type') + ' - ' + t('forms_errors.unexpected_error') + ' (' + typesError + ')'}</p>}
                    {patternsError && <p>{t('pdc.pattern') + ' - ' + t('forms_errors.unexpected_error') + ' (' + patternsError + ')'}</p>}
                    {companiesError && <p>{t('pdc.customer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + companiesError + ')'}</p>}
                    {employeesError && <p>{t('admin.employees.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + employeesError + ')'}</p>}
                    {packagesError && <p>{t('admin_labo.packages.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + packagesError + ')'}</p>}
                    {analytesError && <p>{t('admin_labo.tests.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + analytesError + ')'}</p>}
                    {analyteTestPackageError && <p>{t('admin_labo.manage_relations.title_analyte_tests_packages') + ' - ' + t('forms_errors.unexpected_error') + ' (' + analyteTestPackageError + ')'}</p>}

                    {fetchError && <p>{t('pdc.user') + ' - ' + t('forms_errors.unexpected_error') + ' (' + fetchError + ')'}</p>}

                </Container>
            }
        </Container>
    )

    function updateRights(laboratoryId: number | undefined) {
        isInternal = userIsInternal()
        hasRoleAdmin = userHasRoleAdmin(laboratoryId, authUser);
        hasRoleClient = userHasRoleClientCp(laboratoryId, authUser);
        hasRoleUser = userHasRoleUser(laboratoryId, authUser);
        hasRolePDCAdmin = userGrantControlPlanAdmin(laboratoryId, authUser);
    }
}

export default withTranslation()(ControlPlanForm)