import { Col, useAccordionButton } from 'react-bootstrap';

interface IProps {
    eventKey: string
    className?: string
    label: string
}

const ContextAwareToggle = ({ eventKey, className, label }: IProps) => {
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => {},
    );

    return (
        <Col>
            <h5 onClick={decoratedOnClick} className={className}>{label}</h5>
        </Col>

    );
}

export default ContextAwareToggle