import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import ConfigApi, {
    RulesResultsPatternsDocument,
    RuleResultsPatterns,
} from "../api/configApi";
import { Data, FilterBy, Pagination, SortBy } from "../types/types";

interface CurrentState {
    data: Data<Array<RulesResultsPatternsDocument>>;
    filtered: Array<string>;
    pagination: Pagination;
    fetchStatus: "idle" | "loading" | "succeeded" | "failed";
    fetchError: string | undefined;
    singleFetchStatus: "idle" | "loading" | "succeeded" | "failed";
    singleFetchError: string | undefined;
    postStatus: "idle" | "loading" | "succeeded" | "failed";
    postError: string | undefined;
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    byCompany: {},
    fetchStatus: "idle",
    fetchError: undefined,
    singleFetchStatus: "idle",
    singleFetchError: undefined,
    postStatus: "idle",
    postError: undefined,
} as CurrentState;

export const fetchRuleResultsPatterns = createAsyncThunk(
    "ruleResultsPatterns",
    async ({ laboratoryId }: { laboratoryId: number }) => {
        const response = await ConfigApi.getInstance().getRuleResultsPatterns(
            laboratoryId
        );
        return response as RuleResultsPatterns;
    }
);

const ruleResultsPatternsSlice = createSlice({
    name: "ruleResultsPatterns",
    initialState,
    reducers: {
        updateRuleResultsPatterns(
            state: CurrentState,
            action: PayloadAction<RuleResultsPatterns>
        ) {
            const item = action.payload;
            if (item.laboratory_id) state.data[item.laboratory_id] = item.rule;
        },

        setFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload;
        },
        setFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload };
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all;
            }
            state.pagination.filterBy = filterBy;
        },
        setSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload;
        },
        refresh(state: CurrentState) {
            state.fetchStatus = "idle";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRuleResultsPatterns.pending, (state) => {
            state.fetchStatus = "loading";
        });
        builder.addCase(fetchRuleResultsPatterns.fulfilled, (state, action) => {
            if (action.payload) {
                state.data[action.payload.laboratory_id] = action.payload.rule;
            }
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchRuleResultsPatterns.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
    },
});

export const {
    updateRuleResultsPatterns,
    setFiltered,
    setFilterBy,
    setSortBy,
    refresh,
} = ruleResultsPatternsSlice.actions;

export default ruleResultsPatternsSlice.reducer;
