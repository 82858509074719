import FileDownload from 'js-file-download';
import AnalysisApi from '../../api/analysisApi';
import { FilterBy, TupleId } from '../../types/types';

async function getResults(checkedAnalysis: TupleId[], pdf: boolean, plb: boolean): Promise<void> {

    if (checkedAnalysis.length === 1) {
        const analysis = await AnalysisApi.getInstance().getAnalysisById(Number(checkedAnalysis[0].labId), Number(checkedAnalysis[0].id))
        if (analysis &&  analysis.additional_analysis && analysis.additional_analysis?.length > 0) {
            await getResultsByFilter({ pk: { inTuple: checkedAnalysis } }, pdf, plb)
        } else {
            const keys: any = await AnalysisApi.getInstance().getResultsByAnalysisId(Number(checkedAnalysis[0].labId), Number(checkedAnalysis[0].id), pdf, plb, false)
            for (const key of keys.files_tab) {
                try {
                    const response = await AnalysisApi.getInstance().getDocument(key)
                    FileDownload(response, decodeURIComponent(key!.split('/').pop()))
                } catch (err) {
                    throw new Error("Cannot download analysis results document")
                }
            }
        }

    } else {
        await getResultsByFilter({pk: {inTuple: checkedAnalysis}}, pdf, plb)
    }
}

export async function getResultsByFilter(filter: FilterBy, pdf: boolean, plb: boolean): Promise<void> {
    try {
        const response = await AnalysisApi.getInstance().getResults(filter, pdf, plb)
        FileDownload(response, `results.zip`)
    } catch(err) {
        throw new Error('Cannot download analysis results zip')
    }
}

export default getResults