import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/rootReducer'

import { useParams } from "react-router-dom";
import { fetchSingleAnalysis } from '../../../state/analysisSlice';
import { fetchFamilies, fetchImpropers, fetchLaboratories, fetchLanguages, fetchPackages, fetchPatterns, fetchTypes } from '../../../state/configSlice'
import { fetchFarmers} from '../../../state/farmerSlice'
import { fetchEmployees } from '../../../state/employeesSlice'
import { fetchAllCompanies } from '../../../state/companiesSlice'

import Loader from '../../../common/components/Loader';
import Container from 'react-bootstrap/Container';
import { fetchSinglePdc } from '../../../state/pdcSlice';
import AnalysisForm from './components/AnalysisForm';
import { AnalysisType } from '../../../state/localSlice';
import { fetchFields } from '../../../state/fieldsSlice';
import { fetchForms } from '../../../state/formsSlice';
import { fetchNirs } from '../../../state/nirSlice';
import { AdditionalAnalysis, Analysis } from '../../../api/analysisApi';
import { fetchPatternFamilies } from '../../../state/patternsFamiliesSlice';
import { fetchPriceLists } from '../../../state/priceListSlice';
import { fetchAnalyteTestPackages } from '../../../state/analyteTestsPackagesSlice';
import { fetchAnalytes } from '../../../state/analyteSlice';
import { fetchPackagesPatterns } from '../../../state/packagesPatternsSlice';

interface AnalysisContainerProps extends WithTranslation {
    typeAnalysis?: AnalysisType,
}

interface urlParams {
    laboratoryId: string
    analysisId: string
    initStep: string
}

const AnalysisContainer: React.FC<AnalysisContainerProps> = ({ t, typeAnalysis }) => {
    const { laboratoryId, analysisId, initStep } = useParams<urlParams>();

    const dispatch = useDispatch()
    
    const { user: authUser, fetchStatus: authUserStatus, fetchError: authUserError, authCurLabId } = useSelector((state: RootState) => state.authUser)

    // pdc state
    const controlPlans = useSelector((state: RootState) => state.pdc.data)
    const pdcStatus = useSelector((state: RootState) => state.pdc.singleFetchStatus)
    const pdcError = useSelector((state: RootState) => state.pdc.fetchError)

    // Analysis state
    const analysis = useSelector((state: RootState) => state.analysis.analysis.data)
    const analysisStatus = useSelector((state: RootState) => state.analysis.analysis.singleFetchStatus)
    const allAnalysisStatus = useSelector((state: RootState) => state.analysis.analysis.fetchStatus)
    const analysisError = useSelector((state: RootState) => state.analysis.analysis.singleFetchError)

    // Forms & fields state
    const { fetchStatus: formsStatus, fetchError: formsError } = useSelector((state: RootState) => state.forms)
    const { fetchStatus: fieldsStatus, fetchError: fieldsError } = useSelector((state: RootState) => state.fields)

    // types state
    const typesStatus = useSelector((state: RootState) => state.config.types.fetchStatus)
    const typesError = useSelector((state: RootState) => state.config.types.fetchError)

    // patterns state
    const patternsStatus = useSelector((state: RootState) => state.config.patterns.fetchStatus)
    const patternsError = useSelector((state: RootState) => state.config.patterns.fetchError)

    // patternsFamiliesState
    const patternsFamiliesError = useSelector(
        (state: RootState) => state.patternsFamilies.fetchError
    );
    const patternsFamiliesStatus = useSelector(
        (state: RootState) => state.patternsFamilies.fetchStatus
        );

    // price lists state
    const priceListStatus = useSelector((state: RootState) => state.priceList.fetchStatus)
    const priceListerror = useSelector((state: RootState) => state.priceList.fetchError)
    const priceLists = useSelector((state: RootState) => state.priceList.data);
        
    // analyteTestPackage state
    const analyteTestPackageError = useSelector(
      (state: RootState) => state.analyteTestsPackages.fetchError
     );
     const analyteTestPackageStatus = useSelector(
         (state: RootState) => state.analyteTestsPackages.fetchStatus
     );

         // packagesPatterns state
    const packagesPatternsError = useSelector(
        (state: RootState) => state.packagesPatterns.fetchError
       );
       const packagesPatternsStatus = useSelector(
           (state: RootState) => state.packagesPatterns.fetchStatus
       );
    
    // languages state
    const languagesStatus = useSelector((state: RootState) => state.config.languages.fetchStatus)

    //  Laboratories state
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)

    //  Nirs state
    const nirsStatus = useSelector((state: RootState) => state.nir.fetchStatus)
    const nirsError = useSelector((state: RootState) => state.nir.fetchError)

    // families state
    const familiesStatus = useSelector((state: RootState) => state.config.families.fetchStatus)
    const familiesError = useSelector((state: RootState) => state.config.families.fetchError)

    // farmers state
    const farmersStatusByUser = useSelector((state: RootState) => state.farmers.farmer.fetchStatusByUser)
    const farmersError = useSelector((state: RootState) => state.farmers.farmer.fetchError)

    // Package state
    const packagesStatus = useSelector((state: RootState) => state.config.packages.fetchStatus)
    const packagesError = useSelector((state: RootState) => state.config.packages.fetchError)

    // Analyte state
    const analyteStatus = useSelector((state: RootState) => state.analytes.fetchStatus)
    const analyteError = useSelector((state: RootState) => state.analytes.fetchError)

    // company state
    const companiesAllStatus = useSelector((state: RootState) => state.companies.fetchAllStatus)
    const companiesError = useSelector((state: RootState) => state.companies.fetchError)

    // Employees state
    const employeesStatus = useSelector((state: RootState) => state.employees.fetchStatus)
    const employeesError = useSelector((state: RootState) => state.employees.fetchError)

    // Impropers state
    const impropersStatus = useSelector((state: RootState) => state.config.impropers.fetchStatus)
    const impropersError = useSelector((state: RootState) => state.config.impropers.fetchError)

    const [errorMessage, setErrorMessage] = useState(false)

    useEffect(() => {
        if (fieldsStatus === 'idle') {
            dispatch(fetchFields())
        }
        if (formsStatus === 'idle') {
            dispatch(fetchForms({}))
        }
        if (typesStatus === 'idle') {
            dispatch(fetchTypes())
        }
        if (familiesStatus === 'idle') {
            dispatch(fetchFamilies())
        }
        if (languagesStatus === 'idle') {
            dispatch(fetchLanguages())
        }
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }
        if (nirsStatus === 'idle') {
            dispatch(fetchNirs())
        }
        if (farmersStatusByUser === 'idle') {
            dispatch(fetchFarmers())
        }
        if (companiesAllStatus === 'idle') {
            dispatch(fetchAllCompanies())
        }
        if (employeesStatus === 'idle') {
            dispatch(fetchEmployees())
        }
        if (patternsStatus === 'idle') {
            dispatch(fetchPatterns())
        }
        if (packagesStatus === 'idle') {
            dispatch(fetchPackages())
        }
        if (analyteStatus === 'idle') {
            dispatch(fetchAnalytes())
        }
        if (impropersStatus === 'idle') {
            dispatch(fetchImpropers())
        }
        
        if (patternsFamiliesStatus === 'idle') {
            if(!laboratoryId){
                if(laboratories[authCurLabId]?.ref_lab_id){
                    dispatch(fetchPatternFamilies({ laboratoryId: laboratories[authCurLabId].ref_lab_id!}));
                }else{
                    dispatch(fetchPatternFamilies({ laboratoryId: authCurLabId}));
                }
            }else{
                dispatch(fetchPatternFamilies({ laboratoryId: Number(laboratoryId)}));
            }
        }

        if (priceListStatus === 'idle'){
            if(!laboratoryId){
                if(laboratories[authCurLabId]?.ref_lab_id){
                    dispatch(fetchPriceLists({laboratoryId: laboratories[authCurLabId].ref_lab_id!}))
                }else{
                    dispatch(fetchPriceLists({laboratoryId: authCurLabId}))
                }
            }else{
                dispatch(fetchPriceLists({ laboratoryId: Number(laboratoryId)}));
            }
        }

        if (analyteTestPackageStatus === 'idle'){
            if(!laboratoryId){
                if(laboratories[authCurLabId]?.ref_lab_id){
                    dispatch(fetchAnalyteTestPackages({laboratoryId: laboratories[authCurLabId].ref_lab_id!}))
                }else{
                    dispatch(fetchAnalyteTestPackages({laboratoryId: authCurLabId}))
                }
            }else{
                dispatch(fetchAnalyteTestPackages({ laboratoryId: Number(laboratoryId)}));
            }
        }

        if (packagesPatternsStatus === 'idle'){
            if(!laboratoryId){
                if(laboratories[authCurLabId]?.ref_lab_id){
                    dispatch(fetchPackagesPatterns({laboratoryId: laboratories[authCurLabId].ref_lab_id!}))
                }else{
                    dispatch(fetchPackagesPatterns({laboratoryId: authCurLabId}))
                }
            }else{
                dispatch(fetchPackagesPatterns({ laboratoryId: Number(laboratoryId)}));
            }
        }

        if (laboratoryId && analysisId && analysis[laboratoryId] && analysis[laboratoryId][analysisId] && analysisStatus === 'succeeded') {
           
            const idPdc = analysis[laboratoryId][analysisId].id_pdc
            if (!analysis[laboratoryId] || !analysis[laboratoryId][analysisId]) {
                setErrorMessage(true)
            } else {
                if (analysisId && idPdc && (pdcStatus === 'idle' || !controlPlans[laboratoryId] || !controlPlans[laboratoryId][idPdc])) {
                    dispatch(fetchSinglePdc({ pdcId: Number(analysis[laboratoryId][analysisId].id_pdc), laboratoryId: analysis[laboratoryId][analysisId].laboratory_id }))
                }
            }
        }
    }, [dispatch, analysisId, analysisStatus, errorMessage, authUser, companiesAllStatus, farmersStatusByUser, analyteStatus, packagesStatus, familiesStatus, languagesStatus, patternsStatus, impropersStatus, employeesStatus, analysis, typesStatus, laboratoriesStatus, pdcStatus, controlPlans, fieldsStatus, formsStatus, nirsStatus, patternsFamiliesStatus, priceListStatus, analyteTestPackageStatus, packagesPatternsStatus ])

    useEffect(() => {
        if(!laboratoryId){
            if(laboratories[authCurLabId]?.ref_lab_id){
                dispatch(fetchPatternFamilies({ laboratoryId: laboratories[authCurLabId].ref_lab_id!}));
            }else{
                dispatch(fetchPatternFamilies({ laboratoryId: authCurLabId}));
            }
        }else{
            dispatch(fetchPatternFamilies({ laboratoryId: Number(laboratoryId)}));
        }

        if(!laboratoryId){
            if(laboratories[authCurLabId]?.ref_lab_id){
                dispatch(fetchPriceLists({laboratoryId: laboratories[authCurLabId].ref_lab_id!}))
            }else{
                dispatch(fetchPriceLists({laboratoryId: authCurLabId}))
            }
        }else{
            dispatch(fetchPriceLists({ laboratoryId: Number(laboratoryId)}));
        }

        if(!laboratoryId){
            if(laboratories[authCurLabId]?.ref_lab_id){
                dispatch(fetchAnalyteTestPackages({laboratoryId: laboratories[authCurLabId].ref_lab_id!}))
            }else{
                dispatch(fetchAnalyteTestPackages({laboratoryId: authCurLabId}))
            }
        }else{
            dispatch(fetchAnalyteTestPackages({ laboratoryId: Number(laboratoryId)}));
        }

        if(!laboratoryId){
            if(laboratories[authCurLabId]?.ref_lab_id){
                dispatch(fetchPackagesPatterns({laboratoryId: laboratories[authCurLabId].ref_lab_id!}))
            }else{
                dispatch(fetchPackagesPatterns({laboratoryId: authCurLabId}))
            }
        }else{
            dispatch(fetchPackagesPatterns({ laboratoryId: Number(laboratoryId)}));
        }
    }, [dispatch, laboratoriesStatus])


    useEffect(() => {
        if(analysisId !== undefined){
            dispatch(fetchSingleAnalysis({laboratoryId: Number(laboratoryId), analysisId: Number(analysisId)}))
        }
    }, [])

    useEffect(() => {
        if(!laboratoryId){
            if(laboratories[authCurLabId]?.ref_lab_id){
                dispatch(fetchPatternFamilies({ laboratoryId: laboratories[authCurLabId].ref_lab_id!}))
                dispatch(fetchPriceLists({laboratoryId: laboratories[authCurLabId].ref_lab_id!}))
                dispatch(fetchAnalyteTestPackages({laboratoryId: laboratories[authCurLabId].ref_lab_id!}))
                dispatch(fetchPackagesPatterns({laboratoryId: laboratories[authCurLabId].ref_lab_id!}))
            }else{
                dispatch(fetchPatternFamilies({ laboratoryId: authCurLabId}))
                dispatch(fetchPriceLists({laboratoryId: authCurLabId}))
                dispatch(fetchAnalyteTestPackages({laboratoryId: authCurLabId}))
                dispatch(fetchPackagesPatterns({laboratoryId: authCurLabId}))
            }
            dispatch(fetchFamilies(authCurLabId))
        }else{
            dispatch(fetchPatternFamilies({ laboratoryId: Number(laboratoryId)}))
            dispatch(fetchPriceLists({laboratoryId: Number(laboratoryId)}))
            dispatch(fetchAnalyteTestPackages({laboratoryId: Number(laboratoryId)}))
            dispatch(fetchPackagesPatterns({laboratoryId: Number(laboratoryId)}))
            dispatch(fetchFamilies(Number(laboratoryId)))
        }
    }, [authCurLabId, laboratoryId])
    
    useEffect(() => {
        if(laboratoryId && analysisId && analysis[laboratoryId] && analysis[laboratoryId][analysisId]){
            const addAnalysis = analysis[laboratoryId][analysisId].additional_analysis
            if(addAnalysis && addAnalysis[0]){
                const refLabId = addAnalysis[0].ref_laboratory_id
                const refAnalysisId = addAnalysis[0].ref_analysis_id
                dispatch(fetchSingleAnalysis({laboratoryId: refLabId, analysisId: refAnalysisId}))
            }
        }
    }, [laboratoryId, analysisId])

    useEffect(() => {
        if (analysisId !== undefined && (!analysis[laboratoryId] || !analysis[laboratoryId][analysisId])) {
            dispatch(fetchSingleAnalysis({laboratoryId: Number(laboratoryId), analysisId: Number(analysisId)}))
        }
        const idPdc = analysis[laboratoryId] && analysis[laboratoryId][analysisId] ? analysis[laboratoryId][analysisId].id_pdc : null
        if ((allAnalysisStatus === 'succeeded' || analysisStatus === 'succeeded') && analysisId && idPdc && (!controlPlans[laboratoryId] || !controlPlans[laboratoryId][idPdc])) {
            dispatch(fetchSinglePdc({ pdcId: Number(analysis[laboratoryId][analysisId].id_pdc), laboratoryId: analysis[laboratoryId][analysisId].laboratory_id }))
        }
    }, [allAnalysisStatus, analysis, analysisId, analysisStatus, controlPlans, dispatch])

    return (
        <Container className="mt-2 p-0">

            {(
                analysisStatus === 'loading' ||
                impropersStatus === 'loading' ||
                companiesAllStatus === 'loading' ||
                employeesStatus === 'loading' ||
                laboratoriesStatus === 'loading' ||
                nirsStatus === 'loading' ||
                familiesStatus === 'loading' ||
                typesStatus === 'loading' ||
                patternsStatus === 'loading' ||
                packagesStatus === 'loading' ||
                analyteStatus === 'loading' ||
                authUserStatus === 'loading' ||
                formsStatus === "loading" ||
                fieldsStatus === "loading" ||
                farmersStatusByUser === 'loading' ||
                (analysisId !== undefined && (!analysis[laboratoryId] || !analysis[laboratoryId][analysisId] || analysis[laboratoryId][analysisId].analysis_packages === undefined)) ||
                (analysisId !== undefined && (analysis[laboratoryId][analysisId].additional_analysis?.some(a => analysis[a.ref_laboratory_id][a.ref_analysis_id].analysis_packages === undefined)))

            ) &&
                <Loader />
            }

            {companiesAllStatus === 'succeeded' &&
                employeesStatus === 'succeeded' &&
                laboratoriesStatus === 'succeeded' &&
                nirsStatus === 'succeeded' &&
                typesStatus === 'succeeded' &&
                familiesStatus === 'succeeded' &&
                patternsStatus === 'succeeded' &&
                packagesStatus === 'succeeded' &&
                analyteStatus === 'succeeded' &&
                authUserStatus === 'succeeded' &&
                formsStatus === "succeeded" &&
                fieldsStatus === "succeeded" &&
                analysisStatus === 'succeeded' &&
                analysisId !== undefined && errorMessage === false &&
                analysis[laboratoryId] && analysis[laboratoryId][analysisId] &&
                analysis[laboratoryId][analysisId].analysis_packages != undefined &&
                (!analysis[laboratoryId][analysisId].additional_analysis || analysis[laboratoryId][analysisId].additional_analysis?.length === 0  || (analysis[laboratoryId][analysisId].additional_analysis && (analysis[laboratoryId][analysisId].additional_analysis  as AdditionalAnalysis[]).length > 0 && analysis[laboratoryId][analysisId].additional_analysis?.every(a => analysis[a.ref_laboratory_id][a.ref_analysis_id].analysis_packages !== undefined))) &&
                (analysis[laboratoryId][analysisId].id_pdc === null || pdcStatus === 'succeeded') &&
                <Container className="p-0">
                    <Container className="mt-2 p-0">
                        <AnalysisForm analysisDB={analysis[laboratoryId][analysisId]} typeAnalysis={analysis[laboratoryId][analysisId].type_code as AnalysisType} initStepParam={initStep} />
                    </Container>
                </Container>
            }

            {companiesAllStatus === 'succeeded' &&
                employeesStatus === 'succeeded' &&
                laboratoriesStatus === 'succeeded' &&
                nirsStatus === 'succeeded' &&
                typesStatus === 'succeeded' &&
                familiesStatus === 'succeeded' &&
                patternsStatus === 'succeeded' &&
                packagesStatus === 'succeeded' &&
                analyteStatus === 'succeeded' &&
                authUserStatus === 'succeeded' &&
                formsStatus === "succeeded" &&
                fieldsStatus === "succeeded" &&
                analysisId === undefined && errorMessage === false &&
                typeAnalysis &&
                <Container className="p-0">
                    <Container className="mt-2 p-0">
                        <AnalysisForm typeAnalysis={typeAnalysis} />
                    </Container>
                </Container>
            }

            
            {(!analysis[laboratoryId] || !analysis[laboratoryId][analysisId]) && (
                analysisStatus === 'failed' ||
                impropersStatus === 'failed' ||
                companiesAllStatus === 'failed' ||
                employeesStatus === 'failed' ||
                laboratoriesStatus === 'failed' ||
                nirsStatus === 'failed' ||
                familiesStatus === 'failed' ||
                typesStatus === 'failed' ||
                patternsStatus === 'failed' ||
                packagesStatus === 'failed' ||
                analyteStatus === 'failed' ||
                authUserStatus === 'failed' ||
                formsStatus === "failed" ||
                fieldsStatus === "failed" ||
                farmersStatusByUser === 'failed' ||
                pdcStatus === 'failed'
            ) &&
                <Container className="mt-2 bg-white text-danger">
                    {analysisError && <p>{t('forms_errors.unexpected_error') + ' (' + analysisError + ')'}</p>}
                    {typesError && <p>{t('forms.analysis.type') + ' - ' + t('forms_errors.unexpected_error') + ' (' + typesError + ')'}</p>}
                    {familiesError && <p>{t('forms.analysis.family') + ' - ' + t('forms_errors.unexpected_error') + ' (' + familiesError + ')'}</p>}
                    {patternsError && <p>{t('forms.analysis.pattern') + ' - ' + t('forms_errors.unexpected_error') + ' (' + patternsError + ')'}</p>}
                    {farmersError && <p>{t('forms.analysis.farmer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + farmersError + ')'}</p>}
                    {(packagesError || analyteError) && <p>{t('forms.analysis.management_packagesTests') + ' - ' + t('forms_errors.unexpected_error') + ' (' + (packagesError !== "undefined" ? packagesError : "") + (analyteError !== "undefined" ? analyteError : "") + ')'}</p>}
                    {authUserStatus && <p>{t('forms.analysis.user') + ' - ' + t('forms_errors.unexpected_error') + ' (' + authUserError + ')'}</p>}
                    {companiesError && <p>{t('forms.analysis.company') + ' - ' + t('forms_errors.unexpected_error') + ' (' + companiesError + ')'}</p>}
                    {employeesStatus && <p>{t('forms.analysis.technician') + ' - ' + t('forms_errors.unexpected_error') + ' (' + employeesError + ')'}</p>}
                    {impropersError && <p>{t('forms.analysis.impropers.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + impropersError + ')'}</p>}
                    {pdcError && <p>{t('forms.analysis.pdc.title') + ' - ' + t('forms_errors.unexpected_error') + ' (' + pdcError + ')'}</p>}
                    {fieldsError && <p>{"Custom fields" + ' - ' + t('forms_errors.unexpected_error') + ' (' + fieldsError + ')'}</p>}
                    {formsError && <p>{"Custom forms" + ' - ' + t('forms_errors.unexpected_error') + ' (' + formsError + ')'}</p>}
                    {nirsError && <p>{"Nirs" + ' - ' + t('forms_errors.unexpected_error') + ' (' + nirsError + ')'}</p>}
                </Container>
            }

            { errorMessage &&
                <Container className="mt-2 bg-white text-danger">
                    <p>{t('home.analysis_request_ID_not_found')}</p>
                </Container>
            }

        </Container>
    )
}

export default withTranslation()(AnalysisContainer)