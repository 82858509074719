import React, { useState, useEffect, useRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Row, Col, Button, Container, Form, Accordion, useAccordionButton } from 'react-bootstrap';
import { AppDispatch } from '../../../state/store';
import * as Yup from 'yup';
import { Formik, getIn } from 'formik';
import Select from 'react-select'
import { buildSelectNormalStyle, buildSelectNormalStyleWithColor } from '../../../common/utils/utils';
import moment from 'moment';
import MultiSelect from '../../../common/components/MultiSelect';
import { ReactComponent as DeleteCircleRed } from '../../../common/resources/DeleteCircleRed.svg'
import { ValuesForSearch, postFilters, resetFiltersState, resetMatrixFields, resetParamsFromComponents, resetSearchState, search, setDate, setFiltersForGroup, setMatrixFields, setSelectOptions, setType, setValuesForSearch, setWeights } from '../../../state/dashboardSlice';
import { AnalyteRequest, Filter, FiltersRequest, Outliers, PeriodField, SearchRequest } from '../../../api/dashboardApi';
import InlineLoaderWithoutText from '../../../common/components/InlineLoaderWithoutText';
import ContextAwareToggle from '../../../common/components/ContextAwareToggle';
import MatrixApi, { Matrix, MatrixTemplate } from '../../../api/matrixApi';
import { fetchMatrixTemplatesByPassLimit } from '../../../state/matrixTemplateSlice';
import { fetchLastActiveMatrixVersion, fetchSingleMatrice, refreshMatrix } from '../../../state/matrixSlice';
import userHasRequiredRoles from '../../../common/auth/userHasRequiredRoles';
import { Laboratory } from '../../../api/configApi';
import InlineLoader from '../../../common/components/InlineLoader';
import { Company } from '../../../api/usersApi';
import { fetchDashboardPreferences, resetSinglePreferences } from '../../../state/dashboardPreferencesSlice';
import { fetchMatrixTemplateInputs } from '../../../state/matrixTemplateInputSlice';

interface DashboardFilterComponentContainerProps extends WithTranslation {
    dashboardName: string
    triggerMatrixFields: boolean
    setTriggerMatrixFields: React.Dispatch<React.SetStateAction<boolean>>
}

interface FieldSelect {
    value: string
    label: string
}

interface FilterSet {
    filter: Filter[]
}

const DashboardFilterComponent: React.FC<DashboardFilterComponentContainerProps> = ({ t, dashboardName, triggerMatrixFields, setTriggerMatrixFields }) => {
    const dispatch = useDispatch<AppDispatch>();

    // Filters state
    const filters = useSelector((state: RootState) => state.dashboard.filters)
    const postFiltersStatus = useSelector((state: RootState) => state.dashboard.postFiltersStatus)
    const postFiltersError: string | undefined = useSelector((state: RootState) => state.dashboard.postFiltersError)

    // Search state
    const searchStatus = useSelector((state: RootState) => state.dashboard.searchStatus)
    const searchError: string | undefined = useSelector((state: RootState) => state.dashboard.searchError)
    const outliers = useSelector((state: RootState) => state.dashboard.outliers)
    const paramsFromComponents = useSelector((state: RootState) => state.dashboard.paramsFromComponents)
    const { user: authUser } = useSelector((state: RootState) => state.authUser)
    const companies = useSelector((state: RootState) => state.companies.data)
    const valuesForSearch = useSelector((state: RootState) => state.dashboard.valuesForSearch)

    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    const laboratoriesFiltered = useRef(Object.values(laboratories).filter(l => l.lab_type === "cargill" && (authUser.companies.filter(c => companies[c.company_id] && companies[c.company_id].formulation_id === l.id).length > 0 || authUser.customers.filter(c => companies[c.company_id] && companies[c.company_id].formulation_id === l.id).length > 0 || authUser.formulations.find(f => f.laboratory_id === l.id) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']))));

    const preferences = useSelector((state: RootState) => state.preferences.singlePreferences)
    const matrixFields = useSelector((state: RootState) => state.dashboard.matrixFields)

    const matrixTemplates = useSelector((state: RootState) => state.matrixTemplates.data)
    const matrixTemplatesStatus = useSelector((state: RootState) => state.matrixTemplates.fetchStatus)

    const [laboratoryId, setLaboratoryId] = useState(matrixFields ? matrixFields.laboratory_id : 0)
    const [currentMatrix, setCurrentMatrix] = useState<Matrix | null>(null)
    const [showFilters, setShowFilters] = useState(dashboardName === 'matrix' ? false : true)
    const [isValidatingMatrix, setValidateMatrix] = useState(false)
    const [isValidatedMatrix, setValidatedMatrix] = useState(false)

    // state for submit
    const [submitType, setSubmitType] = useState('');

    // state to reset filters
    const [triggerResetFilters, setTriggerResetFilters] = useState(false);

    //hide search fields
    const [resetSearch, setResetSearch] = useState(false);


    useEffect(() => {
        if (dashboardName === 'matrix') {
            if (matrixTemplatesStatus === 'idle' && laboratoryId) {
                dispatch(fetchMatrixTemplatesByPassLimit({ laboratoryId: laboratoryId, page: 1 }))
            }
        }

    }, [matrixTemplatesStatus, laboratoryId])

    useEffect(() => {
        if (laboratoriesFiltered.current.length === 1) {
            dispatch(fetchMatrixTemplatesByPassLimit({ laboratoryId: laboratoriesFiltered.current[0].id, page: 1 }))
            setLaboratoryId(laboratoriesFiltered.current[0].id)
        }
    }, [laboratoriesFiltered.current])

    const validationSchemaResultsAnalysis = Yup.object().shape({
        common_filter: Yup.object().shape({
            type: Yup.string().required(t('dashboard.filter.form_errors.type_required')),
            period_field: Yup.string()
                .required(t('dashboard.filter.form_errors.date_required')),
            from: Yup.date()
                .required(t('dashboard.filter.form_errors.from_required')),
            to: Yup.date()
                .min(
                    Yup.ref('from'),
                    t("dashboard.filter.form_errors.from_to")
                )
                .required(t('dashboard.filter.form_errors.to_required')),
        }),
    })

    const validationSchemaMatrix = Yup.object().shape({
        common_filter: Yup.object().shape({
            period_field: Yup.string()
                .required(t('dashboard.filter.form_errors.date_required')),
            from: Yup.date()
                .required(t('dashboard.filter.form_errors.from_required')),
            to: Yup.date()
                .min(
                    Yup.ref('from'),
                    t("dashboard.filter.form_errors.from_to")
                )
                .required(t('dashboard.filter.form_errors.to_required')),
        }),
        laboratory_id: Yup.number()
            .min(1, t('dashboard.filter.form_errors.laboratory_required'))
            .required(t('dashboard.filter.form_errors.laboratory_required')),

        company_id: Yup.number().when(["laboratory_id"], {
            is: (laboratory_id: number) => (laboratory_id && laboratory_id > 0),
            then: Yup.number()
                .min(1, t('dashboard.filter.form_errors.company_required'))
                .required(t('dashboard.filter.form_errors.company_required')),
        }),

        export_code: Yup.string().when(["company_id"], {
            is: (company_id: number) => (company_id && company_id > 0),
            then: Yup.string()
                .required(t('dashboard.filter.form_errors.export_code_required')),
        }),

        category: Yup.string().when([], {
            is: () => (submitType === 'search'),
            then: Yup.string().required(t('dashboard.filter.form_errors.category_required')),
        }),
    })

    const AnalysisType = () => Array.from(new Set(['FG', 'RM', 'FP'])).map(t => ({ value: t, label: t }))

    const AnalysisDate = () => {
        const set = () => {
            switch (dashboardName) {
                case 'results-analysis': return ['creation_date', 'validation_date']
                case 'matrix': return ['creation_date', 'validation_date', 'delivery_date', 'sample_date']
                default: return []
            }
        }
        return Array.from(new Set(set())).map(d => ({ value: d, label: t(`dashboard.filter.form.date.${d}`) }))
    }

    // Add in array to add default filters
    const AnalysisDefaultFilters = () => Array.from(new Set(['exclude_outliers'])).map(f => ({ value: f, label: f }))

    return (
        <Formik
            key={dashboardName}
            initialValues={{
                laboratory_id: matrixFields ? matrixFields.laboratory_id : 0,
                company_id: matrixFields ? matrixFields.company_id : 0,
                template_id: 0,
                export_code: matrixFields ? matrixFields.export_code : '',
                category: '',
                sub_category: '',
                raw_material: '',
                common_filter: {
                    type: '',
                    period_field: 'creation_date' as "creation_date" | "sample_date" | "delivery_date" | "validation_date",
                    from: new Date((new Date).setMonth((new Date).getMonth() - 3)),
                    to: new Date,
                    exclude_outliers: false,
                    filter: []
                },
                filter_set: [],
                analytes: []
            }}

            validationSchema={dashboardName === 'matrix' ? validationSchemaMatrix : validationSchemaResultsAnalysis}
            onSubmit={async (values) => {
                if (submitType === 'filters') {

                    // call api for filters
                    dispatch(resetSearchState())
                    const body: FiltersRequest = {
                        company_id: dashboardName === 'matrix' ? values.company_id : undefined,
                        type: dashboardName === 'results-analysis' ? values.common_filter.type : '',
                        period_field: values.common_filter.period_field,
                        from: new Date(values.common_filter.from).toISOString(),
                        to: new Date(values.common_filter.to).toISOString()
                    }
                    dispatch(setDate(values.common_filter.period_field as PeriodField))
                    if (dashboardName === 'results-analysis') {
                        dispatch(setType(values.common_filter.type as 'FG' | 'RM' | 'FP'))
                    }
                    dispatch(postFilters({ dashboardName, body }))
                }
                if (submitType === 'search') {
                    // call api for search
                    dispatch(resetSearchState())

                    const outliersToSend: Outliers = { outliers_on: outliers.outliers_on, mdi: outliers.mdi, gh: outliers.gh, low_high_limit: outliers.low_high_limit, analyte_max: outliers.analytes.filter(am => am.analyte_id !== '' && am.max_value !== null && am.checked).map(am => { return { analyte_id: am.analyte_id, value: am.max_value } }), analyte_min: outliers.analytes.filter(am => am.analyte_id !== '' && am.min_value !== null && am.checked).map(am => { return { analyte_id: am.analyte_id, value: am.min_value } }) }

                    let analytes: AnalyteRequest[] = []

                    if (dashboardName === 'matrix') {
                        const matrixTemplateInputs = await MatrixApi.getInstance().getMatrixTemplateInputsByLaboratory(laboratoryId)
                        analytes = matrixTemplateInputs.data.filter(inputs => inputs.matrix_id === values.template_id).map((mti) => {
                            return { analyte_id: mti.analyte_id, display_table: true } as AnalyteRequest
                        })
                    }

                    const body: SearchRequest = {
                        analytes: dashboardName === 'matrix' ? analytes : undefined,
                        company_id: dashboardName === 'matrix' ? values.company_id : undefined,
                        common_filter: { ...values.common_filter, from: new Date(values.common_filter.from).toISOString(), to: new Date(values.common_filter.to).toISOString() },
                        filter_set: values.filter_set,
                        outliers: outliersToSend.outliers_on === '' ? undefined : outliersToSend
                    }
                    dispatch(setFiltersForGroup(body))

                    let filter = values.common_filter.filter.map((f: Filter) => { return { field: f.field, values: f.values } })
                    let filterSets = values.filter_set.map((fs: FilterSet) => { return { filter: fs.filter.map((f: Filter) => { return { field: f.field, values: f.values } }) } })
                    dispatch(setValuesForSearch({analytes: body.analytes, company_id: body.company_id, common_filter: { ...values.common_filter, filter: filter, from: new Date(values.common_filter.from).toString(), to: new Date(values.common_filter.to).toString() }, filter_set: filterSets}))

                    dispatch(search({ dashboardName, body }))
                }
            }}
        >

            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {function myForm({
                values,
                errors,
                touched,
                validateForm,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue }) {

                const resetFilters = () => {
                    setFieldValue('common_filter.filter', [])
                    setFieldValue('common_filter.exclude_outliers', false)
                    setFieldValue('filter_set', [])
                    dispatch(resetFiltersState())
                }

                useEffect(() => {
                    setTriggerResetFilters(true)
                    setValidatedMatrix(false
                        )
                    dispatch(resetSinglePreferences())
                    if (dashboardName === 'results-analysis') {
                        setShowFilters(true)
                    }
                    else {
                        setShowFilters(false)
                    }
                    dispatch(resetParamsFromComponents())
                }, [dashboardName])

                useEffect(() => {
                    if (dashboardName === 'matrix' && postFiltersStatus === "succeeded" && preferences) {
                        dispatch(setWeights(preferences.weights))
                        setFieldValue('common_filter.filter', preferences.common_filter && preferences.common_filter.filter ? preferences.common_filter.filter.map(f => { return { field: f.field, values: f.values } }) : [])
                        setFieldValue('common_filter.exclude_outliers', preferences.common_filter && preferences.common_filter.exclude_outliers ? preferences.common_filter.exclude_outliers : false)
                        setFieldValue('filter_set', preferences.filter_set ? preferences.filter_set.map(fs => { return { filter: fs.filter.map(f => { return { field: f.field, values: f.values } }) } }) : [])
                    }
                }, [postFiltersStatus])

                useEffect(() => {
                    if (triggerResetFilters || resetSearch) {
                        resetFilters()
                        dispatch(resetSearchState())
                        setTriggerResetFilters(false)
                    }
                }, [triggerResetFilters, resetSearch])

                useEffect(() => {
                    if (triggerMatrixFields) {
                        dispatch(setMatrixFields({ laboratory_id: values.laboratory_id, company_id: values.company_id, export_code: values.export_code, category: values.category, sub_category: values.sub_category, raw_material: values.raw_material, template_id: values.template_id }))

                        setTriggerMatrixFields(false)
                    }
                }, [triggerMatrixFields])

                useEffect(() => {
                    if (dashboardName === 'matrix') {
                        if (preferences) {
                            dispatch(setWeights(preferences.weights))
                            setFieldValue('category', preferences.category ? preferences.category : values.category)
                            setFieldValue('sub_category', preferences.sub_category ? preferences.sub_category : values.sub_category)
                            setFieldValue('raw_material', preferences.raw_material ? preferences.raw_material : values.raw_material)
                            setFieldValue('common_filter.exclude_outliers', preferences.common_filter && preferences.common_filter.exclude_outliers ? preferences.common_filter.exclude_outliers : values.common_filter.exclude_outliers)
                            setFieldValue('common_filter.from', preferences.common_filter && preferences.common_filter.from ? preferences.common_filter.from : values.common_filter.from)
                            setFieldValue('common_filter.to', preferences.common_filter && preferences.common_filter.to ? preferences.common_filter.to : values.common_filter.to)
                            setFieldValue('common_filter.period_field', preferences.common_filter && preferences.common_filter.period_field ? preferences.common_filter.period_field : values.common_filter.period_field)
                        }
                        if (currentMatrix && matrixTemplates && matrixTemplates[currentMatrix.template_id] && matrixTemplates[currentMatrix.template_id].active) {
                            setFieldValue('category', currentMatrix.category ? currentMatrix.category : "")
                            setFieldValue('sub_category', currentMatrix.sub_category ? currentMatrix.sub_category : "")
                            setFieldValue('raw_material', currentMatrix.raw_material ? currentMatrix.raw_material : "")
                        }
                    }
                }, [preferences, currentMatrix])

                useEffect(() => {
                    if (laboratoriesFiltered.current.length === 1) {
                        setFieldValue('laboratory_id', laboratoriesFiltered.current[0].id)
                    }
                }, [laboratoriesFiltered.current])

                useEffect(() => {
                    const companiesFiltered = Object.values(companies).filter(c => c.formulation_id === laboratoryId)
                    if (companiesFiltered.length === 1) {
                        setFieldValue('company_id', companiesFiltered[0].id)
                    }
                }, [companies, laboratoryId])

                useEffect(() => {
                    let filter = values.common_filter.filter.map((f: Filter) => { return { field: f.field, values: f.values } })
                    let filterSets = values.filter_set.map((fs: FilterSet) => { return { filter: fs.filter.map((f: Filter) => { return { field: f.field, values: f.values } }) } })
                    let analytes = valuesForSearch?.analytes
                    
                    const body: ValuesForSearch = {
                        analytes: dashboardName === 'matrix' ? analytes : undefined,
                        company_id: dashboardName === 'matrix' ? values.company_id : undefined,
                        common_filter: { ...values.common_filter, filter: filter, from: new Date(values.common_filter.from).toString(), to: new Date(values.common_filter.to).toString() },
                        filter_set: filterSets
                    }
                    dispatch(setValuesForSearch(body))
                }, [values, JSON.stringify(values.common_filter.filter), JSON.stringify(values.filter_set)])

                useEffect(() => {
                    //If category & sub_category & raw_material are selected, get the template id for the selected raw material / category / sub_category
                    //Shunt filters if no sub_category or raw_material
                    updateTemplate(values.category, values.sub_category, values.raw_material, values.company_id);

                }, [values.category, values.sub_category, values.raw_material, values.company_id])

                async function validateMatrix() {
                    try {
                        setValidateMatrix(true)
                        setCurrentMatrix(null)
                        dispatch(refreshMatrix())

                        const validate = await validateForm(values)
                        if (Object.values(validate).length === 0) {
                            setValidatedMatrix(true)
                            const matrix = values.company_id ? await MatrixApi.getInstance().getMatrixLastVersion(values.company_id, values.export_code) : null

                            if (matrix) {

                                //set matrix local state
                                setCurrentMatrix(matrix)
                                setFieldValue('template_id', matrix.template_id);
                                //set last matrix version state in redux
                                dispatch(fetchSingleMatrice({ companyId: String(matrix.company_id), exportCode: matrix.export_code, version: String(matrix.version) }))

                                //set last active matrix version state in redux
                                dispatch(fetchLastActiveMatrixVersion({ page: 1, company_name: companies[matrix.company_id].name, export_code: matrix.export_code }))

                                dispatch(fetchMatrixTemplateInputs({ laboratoryId: laboratoryId, page: 1 }))
                                dispatch(fetchDashboardPreferences({ company_id: values.company_id ? values.company_id : 0, export_code: values.export_code, version: matrix.version }))
                            } else {
                                dispatch(fetchDashboardPreferences({ company_id: values.company_id ? values.company_id : 0, export_code: values.export_code, version: 1 }))
                            }
                            setShowFilters(true)
                        }


                        setValidateMatrix(false)
                    } catch (e) {
                        setShowFilters(false)
                        setValidateMatrix(false)
                    }
                    setResetSearch(false)
                }

                const subCategories = Object.values(matrixTemplates).filter(item =>
                    item.active &&
                    (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) &&
                    item.category.name === values.category
                ).map(item => item.sub_category)

                const rawMaterials = Object.values(matrixTemplates).filter(item =>
                    item.active &&
                    (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) &&
                    item.category.name === values.category
                ).map(item => item.raw_material)

                function updateTemplate(category: string, subCategory: string, rawMaterial: string, companyId: number) {

                    if (category && subCategories.length > 0 ? category : true && rawMaterials.length > 0 ? rawMaterial : true) {
                        const templateId = Object.values(matrixTemplates).filter(item => item.active &&
                            (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(companyId))) &&
                            String(item.category.name) === String(category) &&
                            (subCategories.length > 0 && item.sub_category ? String(item.sub_category.name) === String(subCategory) : true) &&
                            (rawMaterials.length > 0 && item.raw_material ? String(item.raw_material.name) === String(rawMaterial) : true)
                        ).map(item => item.id).find(item => item === item);

                        setFieldValue('template_id', templateId);
                    }
                }

                return (

                    <Form onSubmit={handleSubmit}>
                        <>
                            {dashboardName === "matrix" &&
                                <Container className={'mt-2 p-0 ms-0 me-0 container-xl'}>
                                    <Accordion defaultActiveKey={"matrix"}>
                                        <Container className="p-2 bg-white rounded-3 container-xl">
                                            <Row onClick={useAccordionButton("matrix", () => { })} >
                                                <Col lg>
                                                    <h4 className="text-primary fw-bold">
                                                        {t('dashboard.matrix.title')}
                                                    </h4>
                                                </Col>
                                                <Col lg="auto">
                                                    <ContextAwareToggle eventKey={"matrix"} />
                                                </Col>
                                            </Row>
                                            <Accordion.Collapse eventKey={"matrix"}>
                                                <>
                                                    <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                                    <Row className='mb-3'>
                                                        {(() => {
                                                            if (laboratoriesFiltered.current.length > 1) {
                                                                return (
                                                                    <Col lg={4}>
                                                                        <Form.Group>

                                                                            <Row className='mt-2'>
                                                                                <Col>
                                                                                    <Form.Label className="text-break">{t('matrix.matrix.formulation_team')} : *</Form.Label>
                                                                                </Col>
                                                                                <Col lg={8}>
                                                                                    <Select
                                                                                        name="laboratory_id"
                                                                                        isSearchable={true}
                                                                                        value={values.laboratory_id ? laboratories[values.laboratory_id] : 0}
                                                                                        isClearable={true}
                                                                                        noOptionsMessage={() => t('matrix.matrix.form.laboratory.select_no_options')}
                                                                                        placeholder={t('dashboard.filter.form.laboratory.laboratory_placeholder')}
                                                                                        getOptionLabel={(l: Laboratory) => l.name}
                                                                                        getOptionValue={(l: Laboratory) => l.id}
                                                                                        options={laboratoriesFiltered.current}
                                                                                        onChange={async (l: Laboratory) => {
                                                                                            setValidatedMatrix(false)
                                                                                            await setFieldValue('laboratory_id', l ? l.id : 0)
                                                                                            setLaboratoryId(l ? l.id : 0)
                                                                                            await setFieldValue('company_id', l ?
                                                                                                Object.values(companies).filter(c => c.formulation_id === l.id).length === 1 ?
                                                                                                    Object.values(companies).filter(c => c.formulation_id === l.id)[0].id : 0
                                                                                                : 0)
                                                                                            await setFieldValue('export_code', '')
                                                                                            await setFieldValue('category', '')
                                                                                            await setFieldValue('sub_category', '')
                                                                                            await setFieldValue('raw_material', '')
                                                                                            setTriggerResetFilters(true)
                                                                                            setShowFilters(false)

                                                                                        }}
                                                                                        styles={buildSelectNormalStyle(touched.laboratory_id, typeof errors.laboratory_id === "string")}
                                                                                    />
                                                                                    {touched.laboratory_id && !!errors.laboratory_id ? <Form.Text className="invalid-feedback d-block">{errors.laboratory_id}</Form.Text> : null}
                                                                                </Col>
                                                                            </Row>

                                                                        </Form.Group>

                                                                    </Col>
                                                                )
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (values.laboratory_id === 0 || Object.values(companies).filter(c => c.formulation_id === values.laboratory_id).length > 1) {
                                                                return (
                                                                    <Col lg={4}>
                                                                        <Form.Group>

                                                                            <Row className='mt-2'>
                                                                                <Col>
                                                                                    <Form.Label className="text-break">{t('matrix.matrix.company')} : *</Form.Label>
                                                                                </Col>
                                                                                <Col lg={8}>
                                                                                    <Select
                                                                                        name="company_id"
                                                                                        isSearchable={true}
                                                                                        value={values.company_id ? companies[values.company_id] : null}
                                                                                        isClearable={false}
                                                                                        noOptionsMessage={() => t('matrix.matrix.form.company.select_no_options')}
                                                                                        placeholder={t('dashboard.filter.form.company.company_placeholder')}
                                                                                        getOptionLabel={(c: Company) => c.name}
                                                                                        getOptionValue={(c: Company) => c.id}
                                                                                        options={Object.values(companies).filter(c => c.formulation_id === values.laboratory_id)}
                                                                                        isDisabled={!values.laboratory_id}
                                                                                        onChange={async (company: Company) => {
                                                                                            setValidatedMatrix(false)
                                                                                            await setFieldValue('company_id', company ? company.id : null)
                                                                                            await setFieldValue('export_code', '')
                                                                                            await setFieldValue('category', '')
                                                                                            await setFieldValue('sub_category', '')
                                                                                            await setFieldValue('raw_material', '')
                                                                                            // Automatically select category, sub_category & raw_material                
                                                                                            const filteredCategories = Object.values(matrixTemplates).filter(template => template.active && (template.crit_company === '*' || (template.crit_company && template.crit_company.split('; ').includes(String(company.id))))).map(item => item.category.name)
                                                                                                .reduce(function (previous: any, current) {
                                                                                                    if (!previous.find(function (prevItem: any) {
                                                                                                        return prevItem === current;
                                                                                                    })) {
                                                                                                        previous.push(current);
                                                                                                    }
                                                                                                    return previous;
                                                                                                }, [])
                                                                                            if (filteredCategories.length === 1) {
                                                                                                setFieldValue('category', filteredCategories[0]);
                                                                                                const filteredSubCategories = Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(company.id))) && item.category.name === filteredCategories[0]).map(item => item.sub_category.name || '')
                                                                                                    .reduce(function (previous: any, current) {
                                                                                                        if (!previous.find(function (prevItem: any) {
                                                                                                            return prevItem === current;
                                                                                                        })) {
                                                                                                            previous.push(current);
                                                                                                        }
                                                                                                        return previous;
                                                                                                    }, [])
                                                                                                if (filteredSubCategories.length === 1) {
                                                                                                    setFieldValue('sub_category', filteredSubCategories[0]);
                                                                                                    const filteredRawMaterial = Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(company.id))) && item.category.name === filteredCategories[0] && item.sub_category && item.sub_category.name === filteredSubCategories[0]).map(item => item.raw_material.name || '')
                                                                                                    if (filteredRawMaterial.length === 1) {
                                                                                                        setFieldValue('raw_material', filteredRawMaterial[0]);

                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            setTriggerResetFilters(true)
                                                                                            setShowFilters(false)
                                                                                        }}
                                                                                        styles={buildSelectNormalStyle(touched.company_id, typeof errors.company_id === "string")}
                                                                                    />
                                                                                    {touched.company_id && !!errors.company_id ? <Form.Text className="invalid-feedback d-block">{errors.company_id}</Form.Text> : null}
                                                                                </Col>
                                                                            </Row>

                                                                        </Form.Group>

                                                                    </Col>
                                                                )
                                                            }
                                                        })()}
                                                        <Col lg={4}>
                                                            <Form.Group>
                                                                <Row className='mt-2'>
                                                                    <Col>
                                                                        <Form.Label className="text-break">{t('matrix.matrix.export_code')} : *</Form.Label>
                                                                    </Col>
                                                                    <Col lg={8}>
                                                                        <Form.Control
                                                                            className={touched.export_code && errors.export_code ? "is-invalid" : undefined}
                                                                            type="text"
                                                                            name='export_code'
                                                                            placeholder={t('dashboard.filter.form.export_code.export_code_placeholder')}
                                                                            value={values.export_code ? values.export_code : ""}
                                                                            onChange={async (export_code) => {
                                                                                setValidatedMatrix(false)
                                                                                await setFieldValue('export_code', export_code.target.value)
                                                                                await setFieldValue('category', '')
                                                                                await setFieldValue('sub_category', '')
                                                                                await setFieldValue('raw_material', '')
                                                                                setResetSearch(true)
                                                                                setShowFilters(false)
                                                                            }}

                                                                            disabled={!values.company_id}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">{errors.export_code}</Form.Control.Feedback>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>

                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-3'>
                                                        <Col lg={4}>

                                                            {isValidatedMatrix &&
                                                                <>
                                                                    {matrixTemplatesStatus === 'loading' &&
                                                                        <InlineLoader />
                                                                    }
                                                                    {(matrixTemplatesStatus === 'idle' || matrixTemplatesStatus === 'succeeded') &&
                                                                        <Form.Group>
                                                                            <Row className='mt-3'>
                                                                                <Col>
                                                                                    <Form.Label className="text-break">{t('matrix.matrix.category')} : *</Form.Label>
                                                                                </Col>
                                                                                <Col lg={8}>
                                                                                    <Select
                                                                                        name="category"
                                                                                        isSearchable={true}
                                                                                        value={
                                                                                            values.category ?
                                                                                                Object.values(matrixTemplates).filter(template =>
                                                                                                    template.active && (template.crit_company === '*' ? Object.values(companies).map(company => company.id).includes(values.company_id)
                                                                                                        : template.crit_company && template.crit_company?.split('; ').includes(String(values.company_id))
                                                                                                    )
                                                                                                ).map(template => template.category).find(template => template.name === values.category)
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        isClearable={false}
                                                                                        noOptionsMessage={() => t('matrix.matrix.form.category.select_no_options')}
                                                                                        placeholder={t('dashboard.filter.form.category.category_placeholder')}
                                                                                        getOptionLabel={(category: MatrixTemplate["category"]) => category.translations[authUser.locale as keyof typeof category.translations] || category.name}
                                                                                        getOptionValue={(category: MatrixTemplate["category"]) => category.name}
                                                                                        options={Object.values(matrixTemplates).filter(template => template.active && (template.crit_company === '*' || (template.crit_company && template.crit_company.split('; ').includes(String(values.company_id))))).map(item => item.category)
                                                                                            .reduce(function (previous: any, current) {
                                                                                                if (!previous.find(function (prevItem: any) {
                                                                                                    return prevItem.name === current.name;
                                                                                                })) {
                                                                                                    previous.push(current);
                                                                                                }
                                                                                                return previous;
                                                                                            }, [])
                                                                                        }
                                                                                        isDisabled={!values.export_code}
                                                                                        onChange={(category: MatrixTemplate["category"]) => {
                                                                                            setFieldValue('category', category ? category.name : null)
                                                                                            setFieldValue('sub_category', '')
                                                                                            setFieldValue('raw_material', '')
                                                                                            // Automatically select sub_category & raw_material
                                                                                            const filteredSubCategories = Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) && item.category.name === category.name).map(item => item.sub_category.name || '')
                                                                                                .reduce(function (previous: any, current) {
                                                                                                    if (!previous.find(function (prevItem: any) {
                                                                                                        return prevItem === current;
                                                                                                    })) {
                                                                                                        previous.push(current);
                                                                                                    }
                                                                                                    return previous;
                                                                                                }, [])
                                                                                            if (filteredSubCategories.length === 1) {
                                                                                                setFieldValue('sub_category', filteredSubCategories[0]);
                                                                                                const filteredRawMaterial = Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) && item.category.name === category.name && item.sub_category && item.sub_category.name === filteredSubCategories[0]).map(item => item.raw_material.name || '')
                                                                                                if (filteredRawMaterial.length === 1) {
                                                                                                    setFieldValue('raw_material', filteredRawMaterial[0]);
                                                                                                    // needed because setFieldValue does does seem to trigger useEffect
                                                                                                    updateTemplate(category.name, filteredSubCategories[0], filteredRawMaterial[0], values.company_id as number)
                                                                                                }
                                                                                            }
                                                                                            setTriggerResetFilters(true)
                                                                                        }}
                                                                                        styles={buildSelectNormalStyle(touched.category, typeof errors.category === "string")}
                                                                                    />

                                                                                    {getIn(touched, "category") && typeof getIn(errors, "category") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors, "category")}</Form.Text> : null}

                                                                                </Col>
                                                                            </Row>
                                                                        </Form.Group>
                                                                    }
                                                                </>
                                                            }
                                                        </Col>
                                                        <Col lg={4}>

                                                            {isValidatedMatrix &&
                                                                <Form.Group>

                                                                    <Row className='mt-3'>
                                                                        <Col>
                                                                            <Form.Label className="text-break">{t('matrix.matrix.sub_category')} :</Form.Label>
                                                                        </Col>
                                                                        <Col lg={8}>
                                                                            <Select
                                                                                name="sub_category"
                                                                                isSearchable={true}
                                                                                value={values.sub_category ? Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id)))).map(item => item.sub_category || { name: '', translations: { fr: '', en: '', es: '', it: '' } }).find(item => item && item.name === values.sub_category) : null}
                                                                                isClearable={false}
                                                                                noOptionsMessage={() => t('matrix.matrix.form.sub_category.select_no_options')}
                                                                                placeholder={t('dashboard.filter.form.sub_category.sub_category_placeholder')}
                                                                                getOptionLabel={(sub_category: MatrixTemplate["sub_category"]) => sub_category.translations[authUser.locale as keyof typeof sub_category.translations] || sub_category.name}
                                                                                getOptionValue={(sub_category: MatrixTemplate["sub_category"]) => sub_category.name}
                                                                                options={Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) && item.category.name === values.category).map(item => item.sub_category || { name: '', translations: { fr: '', en: '', es: '', it: '' } })
                                                                                    .reduce(function (previous: any, current) {
                                                                                        if (!previous.find(function (prevItem: any) {
                                                                                            return prevItem === current || (prevItem && current && prevItem.name === current.name);
                                                                                        })) {
                                                                                            previous.push(current);
                                                                                        }
                                                                                        return previous;
                                                                                    }, [])
                                                                                }
                                                                                isDisabled={!values.export_code}
                                                                                onChange={async (sub_category: MatrixTemplate["sub_category"]) => {
                                                                                    await setFieldValue('sub_category', sub_category ? sub_category.name : null)
                                                                                    await setFieldValue('raw_material', '')
                                                                                    // Automatically select raw_material
                                                                                    const filteredRawMaterial = Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) && item.category.name === values.category && item.sub_category && item.sub_category.name === sub_category.name).map(item => item.raw_material.name || '')
                                                                                    if (filteredRawMaterial.length === 1) {
                                                                                        await setFieldValue('raw_material', filteredRawMaterial[0]);
                                                                                        // needed because setFieldValue does does seem to trigger useEffect
                                                                                        updateTemplate(values.category, sub_category.name, filteredRawMaterial[0], values.company_id as number)
                                                                                    }
                                                                                    setTriggerResetFilters(true)

                                                                                }}
                                                                                styles={buildSelectNormalStyle(touched.sub_category, typeof errors.sub_category === "string")}
                                                                            />
                                                                            {getIn(touched, "sub_category") && typeof getIn(errors, "sub_category") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors, "sub_category")}</Form.Text> : null}
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            }
                                                        </Col>
                                                        <Col lg={4}>

                                                            {isValidatedMatrix &&
                                                                <Form.Group>

                                                                    <Row className='mt-3'>
                                                                        <Col>
                                                                            <Form.Label className="text-break">{t('matrix.matrix.raw_material')} :</Form.Label>

                                                                        </Col>
                                                                        <Col lg={8}>
                                                                            <Select
                                                                                name="raw_material"
                                                                                isSearchable={true}
                                                                                value={values.raw_material ? Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id)))).map(item => item.raw_material || { name: '', translations: { fr: '', en: '', es: '', it: '' } }).find(item => item && item.name === values.raw_material) : null}
                                                                                isClearable={false}
                                                                                noOptionsMessage={() => t('matrix.matrix.form.raw_material.select_no_options')}
                                                                                placeholder={t('dashboard.filter.form.raw_material.raw_material_placeholder')}
                                                                                getOptionLabel={(rawmaterial: MatrixTemplate["raw_material"]) => rawmaterial.translations[authUser.locale as keyof typeof rawmaterial.translations] || rawmaterial.name}
                                                                                getOptionValue={(rawmaterial: MatrixTemplate["raw_material"]) => rawmaterial.name}
                                                                                options={Object.values(matrixTemplates).filter(item => item.active && (item.crit_company === '*' || item.crit_company?.split('; ').includes(String(values.company_id))) && item.category.name === values.category && item.sub_category && item.sub_category.name === values.sub_category).map(item => item.raw_material || { name: '', translations: { fr: '', en: '', es: '', it: '' } })}
                                                                                isDisabled={!values.export_code}
                                                                                onChange={async (rawmaterial: MatrixTemplate["raw_material"]) => {
                                                                                    if (rawmaterial.name !== values.raw_material) {
                                                                                        await setFieldValue('raw_material', rawmaterial ? rawmaterial.name : null)
                                                                                    }
                                                                                    setTriggerResetFilters(true)

                                                                                }}
                                                                                styles={buildSelectNormalStyle(touched.raw_material, typeof errors.raw_material === "string")}
                                                                            />
                                                                            {getIn(touched, "raw_material") && typeof getIn(errors, "raw_material") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors, "raw_material")}</Form.Text> : null}
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    {!isValidatedMatrix &&
                                                        <Row className='mb-2 pe-3'>
                                                            <Container className='d-flex justify-content-end'>
                                                                <Button className="no-print" type="submit" variant="secondary" disabled={isValidatingMatrix && submitType === 'matrix'} onClick={async () => {
                                                                    await setSubmitType('matrix')
                                                                    await validateMatrix()
                                                                }}>
                                                                    {isValidatingMatrix &&
                                                                        <InlineLoaderWithoutText />
                                                                    }
                                                                    {!isValidatingMatrix &&
                                                                        t('dashboard.filter.button.validate_matrix')
                                                                    }
                                                                </Button>
                                                            </Container>
                                                        </Row>
                                                    }
                                                </>
                                            </Accordion.Collapse>
                                        </Container>


                                    </Accordion>
                                </Container>
                            }
                            {showFilters &&

                                <Container className={'mt-2 p-0 ms-0 me-0 container-xl'}>

                                    <Accordion defaultActiveKey={"filters"}>

                                        <Container className="p-2 bg-white rounded-3 container-xl">
                                            <Row onClick={() => useAccordionButton("filters", () => { })} >
                                                <Col lg>
                                                    <h4 className="text-primary fw-bold">
                                                        {t('dashboard.filter.title')}
                                                    </h4>
                                                </Col>

                                                <Col lg="auto">
                                                    <ContextAwareToggle eventKey={"filters"} />
                                                </Col>
                                            </Row>
                                            <Accordion.Collapse eventKey={"filters"}>
                                                <>
                                                    <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                                    {dashboardName === 'results-analysis' &&
                                                        <Row className='mb-2'>
                                                            <Col lg={4}>
                                                                <Form.Group>
                                                                    <Container className='d-flex container-xl'>
                                                                        <Form.Label className="text-break">{t('dashboard.filter.type')} : *</Form.Label>
                                                                        <Container className='m-0 w-75'>
                                                                            <Select
                                                                                name="common_filter.type"
                                                                                value={AnalysisType().find(t => t.value === values.common_filter.type)}
                                                                                isClearable={false}
                                                                                noOptionsMessage={() => t('dashboard.filter.form.type.select_no_options')}
                                                                                placeholder={t('dashboard.filter.form.type.select_placeholder')}
                                                                                options={AnalysisType()}
                                                                                getOptionLabel={(type: FieldSelect) => type.label}
                                                                                getOptionValue={(type: FieldSelect) => type.value}
                                                                                onChange={(type: FieldSelect) => {
                                                                                    setFieldValue('common_filter.type', type.value)
                                                                                    setTriggerResetFilters(true)
                                                                                }}
                                                                                styles={buildSelectNormalStyle(touched.common_filter?.type, typeof errors.common_filter?.type === "string")}
                                                                            />
                                                                            {touched.common_filter?.type && !!errors.common_filter?.type ? <Form.Text className="invalid-feedback d-block">{errors.common_filter?.type}</Form.Text> : null}
                                                                        </Container>
                                                                    </Container>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Row className='mb-3'>
                                                        <Col lg={4}>
                                                            <Form.Group>
                                                                <Container className='d-flex'>
                                                                    <Form.Label className="text-break">{t('dashboard.filter.period_field')} : *</Form.Label>
                                                                    <Container className='m-0 w-75'>
                                                                        <Select
                                                                            name="common_filter.period_field"
                                                                            value={AnalysisDate().find(d => d.value === values.common_filter.period_field)}
                                                                            isClearable={false}
                                                                            noOptionsMessage={() => t('dashboard.filter.form.period_field.select_no_options')}
                                                                            placeholder={t('dashboard.filter.form.period_field.select_placeholder')}
                                                                            options={AnalysisDate()}
                                                                            getOptionLabel={(period_field: FieldSelect) => period_field.label}
                                                                            getOptionValue={(period_field: FieldSelect) => period_field.value}
                                                                            onChange={(period_field: FieldSelect) => {
                                                                                setFieldValue('common_filter.period_field', period_field.value)
                                                                                setTriggerResetFilters(true)
                                                                            }}
                                                                            styles={buildSelectNormalStyle(touched.common_filter?.period_field, typeof errors.common_filter?.period_field === "string")}
                                                                        />
                                                                    </Container>
                                                                </Container>
                                                                {touched.common_filter?.period_field && !!errors.common_filter?.period_field ? <Form.Text className="invalid-feedback d-block">{errors.common_filter?.period_field}</Form.Text> : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Form.Group>
                                                                <Row>
                                                                    <Col xs="auto" className="d-flex align-items-center">
                                                                        <Form.Label className="text-break">{t('dashboard.filter.from')} : *</Form.Label>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Control className='w-75' isInvalid={getIn(touched, "common_filter.from") && !!getIn(errors, "common_filter.from")} type="date" name='common_filter.from' maxLength={255} value={values.common_filter.from && moment(values.common_filter.from).isValid() ? moment(values.common_filter.from).format("YYYY-MM-DD") : undefined} onChange={handleChange} onBlur={handleBlur} />
                                                                        <Form.Control.Feedback type="invalid">{getIn(errors, "from")}</Form.Control.Feedback>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Form.Group>
                                                                <Row>
                                                                    <Col xs="auto" className="d-flex align-items-center">
                                                                        <Form.Label className="text-break">{t('dashboard.filter.to')} : *</Form.Label>
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Control className='w-75' isInvalid={getIn(touched, "common_filter.to") && !!getIn(errors, "common_filter.to")} type="date" name='common_filter.to' maxLength={255} value={values.common_filter.to && moment(values.common_filter.from).isValid() ? moment(values.common_filter.to).format("YYYY-MM-DD") : undefined} onChange={handleChange} onBlur={handleBlur} />
                                                                        <Form.Control.Feedback type="invalid">{getIn(errors, "common_filter.to")}</Form.Control.Feedback>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <Container className='d-flex justify-content-end'>
                                                                {postFiltersStatus === 'failed' && postFiltersError &&
                                                                    <Container className="text-danger">
                                                                        {`${t('errors.internal_server_error')} : ${postFiltersError}`}
                                                                    </Container>
                                                                }
                                                                <Button className="no-print" type="submit" variant="secondary" disabled={postFiltersStatus === 'loading'} onClick={() => {
                                                                    setSubmitType('filters')
                                                                    resetFilters()


                                                                }}>
                                                                    {postFiltersStatus === 'loading' &&
                                                                        <InlineLoaderWithoutText />
                                                                    }
                                                                    {(postFiltersStatus === 'idle' || postFiltersStatus === 'failed' || postFiltersStatus === 'succeeded') &&
                                                                        t('dashboard.filter.button.get_filters')
                                                                    }
                                                                </Button>
                                                            </Container>
                                                        </Col>
                                                    </Row>

                                                    {postFiltersStatus === 'succeeded' &&
                                                        <Row className="mb-2" ><Col lg className="lineTitle"></Col></Row>
                                                    }
                                                    {postFiltersStatus === 'succeeded' &&
                                                        <Container className='container-xl'>
                                                            {/* Common filters */}
                                                            <Row className='mb-3'>
                                                                <Row>
                                                                    {/* Static filters */}
                                                                    {/* Add a condition to add default filters */}
                                                                    {values.common_filter.exclude_outliers &&
                                                                        <Col lg={6} className='d-flex align-items-center' key='exclude-outliers' >
                                                                            <Row>
                                                                                <Col className='d-flex align-items-center' >
                                                                                    <Button size="sm" variant="link" className="d-flex align-items-center ms-1"
                                                                                        onClick={() => {
                                                                                            setFieldValue('common_filter.exclude_outliers', false)
                                                                                            dispatch(setSelectOptions('filterOutliers'))
                                                                                        }}
                                                                                    >
                                                                                        <DeleteCircleRed className="colorHoverSvg align-text-top text-secondary p-0" width="15px" height="100%" />
                                                                                    </Button>
                                                                                    {' ' + t(`dashboard.filter.form.add_filter.exclude_outliers`)}
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    }
                                                                    {/* Dynamic filters */}
                                                                    {values.common_filter.filter && values.common_filter.filter.filter((f: Filter) => filters.filter.find(filter => filter.field === f.field)).map((filter: Filter, indexFilter: number) => {

                                                                        return (
                                                                            <Col lg={6} key={`filter-${indexFilter}`}>
                                                                                <Container style={{ backgroundColor: '#f3f3f3', borderRadius: 5 }} className={'p-1 m-1'}>
                                                                                    <Row>
                                                                                        <Col lg={4} className={'d-flex align-items-center'}>
                                                                                            <Button size="sm" variant="link" className="d-flex align-items-center ms-1"
                                                                                                onClick={() => {
                                                                                                    const filterValues: Filter[] = JSON.parse(JSON.stringify(values.common_filter.filter))
                                                                                                    filterValues.splice(indexFilter, 1)
                                                                                                    setFieldValue('common_filter.filter', filterValues)
                                                                                                }}
                                                                                            >
                                                                                                <DeleteCircleRed className="colorHoverSvg align-text-top text-secondary p-0" width="15px" height="100%" />
                                                                                            </Button>
                                                                                            {' ' + (t(`dashboard.filter.form.add_filter.${filter.field}`).includes('dashboard.filter.form.add_filter.') ? filter.field : t(`dashboard.filter.form.add_filter.${filter.field}`))} :
                                                                                        </Col>
                                                                                        {/* Don't show when default filter */}
                                                                                        {AnalysisDefaultFilters().filter(df => df.label === filter.field).length === 0 &&
                                                                                            <Col>
                                                                                                <MultiSelect
                                                                                                    options={filters.filter.filter(f => filter.field === f.field).length > 0 ? filters.filter.filter(f => filter.field === f.field)[0].values.map(v => { return { label: v, value: v } }) : []}
                                                                                                    onChange={(valuesSelect: any[]) => {
                                                                                                        const filterValues: Filter[] = JSON.parse(JSON.stringify(values.common_filter.filter))
                                                                                                        filterValues[indexFilter].values = valuesSelect.length > 0 ? valuesSelect.map(v => v.value) : []
                                                                                                        setFieldValue('common_filter.filter', filterValues)
                                                                                                    }}
                                                                                                    value={values.common_filter.filter.filter((f: any) => filter.field === f.field).length > 0 ? (values.common_filter.filter.filter((f: any) => filter.field === f.field)[0] as any).values.map((v: any) => { return { label: v, value: v } }) : []}
                                                                                                    isSelectAll={true}
                                                                                                    menuPlacement={"bottom"}
                                                                                                    key={filter.field}
                                                                                                />
                                                                                            </Col>
                                                                                        }
                                                                                    </Row>
                                                                                </Container>
                                                                            </Col>
                                                                        )
                                                                    })}
                                                                </Row>
                                                                <Row className='mb-3 mt-3'>
                                                                    <Col lg className='d-flex justify-content-end'>
                                                                        <Select className='w-25'
                                                                            name="addFilter"
                                                                            isClearable={false}
                                                                            noOptionsMessage={() => t('dashboard.filter.form.add_filter.select_no_options')}
                                                                            placeholder={t('dashboard.filter.form.add_filter.select_placeholder')}
                                                                            // Add in filter to add default filters
                                                                            options={AnalysisDefaultFilters().filter(f => values.common_filter.exclude_outliers === false).concat(filters.filter.filter(f => values.common_filter.filter.filter((cf: Filter) => cf.field === f.field).length === 0).map(f => { return { label: f.field, value: f.field } }))}
                                                                            getOptionLabel={(filter: FieldSelect) => t(`dashboard.filter.form.add_filter.${filter.label}`).includes('dashboard.filter.form.add_filter.') ? filter.label : t(`dashboard.filter.form.add_filter.${filter.label}`)}
                                                                            getOptionValue={(filter: FieldSelect) => filter.value}
                                                                            onChange={(filter: FieldSelect) => {
                                                                                const filterValues: Filter[] = JSON.parse(JSON.stringify(values.common_filter.filter))
                                                                                let value: Array<string> = []
                                                                                switch (filter.label) {
                                                                                    case 'exclude_outliers':
                                                                                        setFieldValue('common_filter.exclude_outliers', true)
                                                                                        dispatch(setSelectOptions('filterOutliers'))
                                                                                        if (paramsFromComponents.outliers) {
                                                                                            dispatch(setSelectOptions('outliers'))
                                                                                        }
                                                                                        break;
                                                                                    default:
                                                                                        filterValues.push({
                                                                                            field: filter.label,
                                                                                            values: value
                                                                                        })
                                                                                        setFieldValue('common_filter.filter', filterValues)
                                                                                        break;
                                                                                }
                                                                            }}
                                                                            styles={buildSelectNormalStyleWithColor()}
                                                                            // this line bellow make that the placeholder is always displayed
                                                                            controlShouldRenderValue={false}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Row>

                                                            {/* Filter sets */}
                                                            {values.filter_set && values.filter_set.map((fs: FilterSet) => {
                                                                return fs.filter ? { filter: fs.filter.filter(f => filters.filter.find(filter => f.field === filter.field)) } : { filter: [] } as FilterSet
                                                            }).map((filterSet: FilterSet, indexFilterSet: number) => {
                                                                return (
                                                                    <Row className='mb-3' key={`filterset-${indexFilterSet}`}>
                                                                        <Container className="mb-3 p-2 bg-primary rounded-3">
                                                                            <Row>
                                                                                <Col>
                                                                                    <p className='m-0 p-0 text-white'>{t('dashboard.filter.title_filter_set')} {indexFilterSet + 1}</p>
                                                                                </Col>
                                                                                <Col className='d-flex justify-content-end'>
                                                                                    <Button size="sm" variant="link" className="p-0 ms-1"
                                                                                        onClick={() => {
                                                                                            const filterSetsValues: FilterSet[] = values.filter_set
                                                                                            filterSetsValues.splice(indexFilterSet, 1)
                                                                                            setFieldValue('filter_set', filterSetsValues)
                                                                                        }}
                                                                                    >
                                                                                        <DeleteCircleRed className="whiteDeleteCircle colorHoverSvg align-text-top p-0" width="20px" height="100%" />
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </Container>
                                                                        <Row>
                                                                            {filterSet.filter && filterSet.filter.map((filter: Filter, indexFilter: number) => {
                                                                                return (
                                                                                    <Col lg={6} key={`filters-${indexFilter}`}>
                                                                                        <Container style={{ backgroundColor: '#f3f3f3', borderRadius: 5 }} className={'p-1 m-1'}>
                                                                                            <Row>
                                                                                                <Col lg={4} className={'d-flex align-items-center'}>
                                                                                                    <Button size="sm" variant="link" className=" d-flex align-items-center ms-1"
                                                                                                        onClick={() => {
                                                                                                            const filterSetsValues: FilterSet[] = JSON.parse(JSON.stringify(values.filter_set))
                                                                                                            const filterValues: Filter[] = filterSetsValues[indexFilterSet].filter
                                                                                                            filterValues.splice(indexFilter, 1)
                                                                                                            filterSetsValues[indexFilterSet].filter = filterValues
                                                                                                            setFieldValue('filter_set', filterSetsValues)
                                                                                                        }}
                                                                                                    >
                                                                                                        <DeleteCircleRed className="colorHoverSvg text-secondary p-0" width="15px" height="100%" />
                                                                                                    </Button>
                                                                                                    {' ' + (t(`dashboard.filter.form.add_filter.${filter.field}`).includes('dashboard.filter.form.add_filter.') ? filter.field : t(`dashboard.filter.form.add_filter.${filter.field}`))} :
                                                                                                </Col>
                                                                                                {/* Don't show when default filter */}
                                                                                                {AnalysisDefaultFilters().filter(df => df.label === filter.field).length === 0 &&
                                                                                                    <Col >
                                                                                                        <MultiSelect
                                                                                                            options={filters.filter.filter(f => filter.field === f.field).length > 0 ? filters.filter.filter(f => filter.field === f.field)[0].values.map(v => { return { label: v, value: v } }) : []}
                                                                                                            onChange={(valuesSelect: any[]) => {
                                                                                                                const filterSetsValues: FilterSet[] = JSON.parse(JSON.stringify(values.filter_set))
                                                                                                                const filterValues: Filter[] = filterSetsValues[indexFilterSet].filter
                                                                                                                filterValues[indexFilter].values = valuesSelect.length > 0 ? valuesSelect.map(v => v.value) : []
                                                                                                                filterSetsValues[indexFilterSet].filter = filterValues
                                                                                                                setFieldValue('filter_set', filterSetsValues)
                                                                                                            }}
                                                                                                            value={(values.filter_set[indexFilterSet] as FilterSet).filter.filter((f: Filter) => filter.field === f.field).length > 0 ? ((values.filter_set[indexFilterSet] as FilterSet).filter.filter((f: Filter) => filter.field === f.field)[0]).values.map((v: string) => { return { label: v, value: v } }) : []}

                                                                                                            isSelectAll={true}
                                                                                                            menuPlacement={"bottom"}
                                                                                                            key={filter.field}
                                                                                                        />
                                                                                                    </Col>
                                                                                                }
                                                                                            </Row>
                                                                                        </Container>
                                                                                    </Col>
                                                                                )
                                                                            })}
                                                                        </Row>
                                                                        <Row className='mt-3 mb-2'>
                                                                            <Col lg className='d-flex justify-content-end'>
                                                                                <Select className='w-25'
                                                                                    name="addFilter"
                                                                                    isClearable={false}
                                                                                    noOptionsMessage={() => t('dashboard.filter.form.add_filter.select_no_options')}
                                                                                    placeholder={t('dashboard.filter.form.add_filter.select_placeholder')}
                                                                                    options={filters.filter.filter(f => (values.filter_set[indexFilterSet] as FilterSet).filter.filter((cf: Filter) => cf.field === f.field).length === 0).map(f => { return { label: f.field, value: f.field } })}
                                                                                    getOptionLabel={(filter: FieldSelect) => t(`dashboard.filter.form.add_filter.${filter.label}`).includes('dashboard.filter.form.add_filter.') ? filter.label : t(`dashboard.filter.form.add_filter.${filter.label}`)}
                                                                                    getOptionValue={(filter: FieldSelect) => filter.value}
                                                                                    onChange={(filter: FieldSelect) => {
                                                                                        const filterSetsValues: FilterSet[] = values.filter_set
                                                                                        const filterValues: Filter[] = filterSetsValues[indexFilterSet].filter
                                                                                        let value: Array<string> = []
                                                                                        filterValues.push({
                                                                                            field: filter.label,
                                                                                            values: value
                                                                                        })
                                                                                        filterSetsValues[indexFilterSet].filter = filterValues
                                                                                        setFieldValue('filter_set', filterSetsValues)
                                                                                    }}
                                                                                    styles={buildSelectNormalStyleWithColor()}
                                                                                    // this line bellow make that the placeholder is always displayed
                                                                                    controlShouldRenderValue={false}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Row>
                                                                )
                                                            })}
                                                            <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                                            {/* Button for filter sets */}
                                                            <Row>
                                                                <Col lg className='d-flex justify-content-end'>
                                                                    <Button className="m-1 no-print" variant="secondary" onClick={async () => {
                                                                        const filterSetsValues: FilterSet[] = JSON.parse(JSON.stringify(values.filter_set))
                                                                        filterSetsValues.push({ filter: [] })
                                                                        await setFieldValue('filter_set', filterSetsValues)
                                                                    }}>{t('dashboard.filter.button.add_filter_set')}</Button>
                                                                    <Button className="m-1 no-print" type="submit" variant="secondary" onClick={() => setSubmitType('search')}>
                                                                        {searchStatus === 'loading' &&
                                                                            <InlineLoaderWithoutText />
                                                                        }
                                                                        {(searchStatus === 'idle' || searchStatus === 'failed' || searchStatus === 'succeeded') &&
                                                                            t('dashboard.filter.button.search')
                                                                        }
                                                                    </Button>
                                                                </Col>
                                                            </Row>

                                                            {searchStatus === 'failed' && searchError &&
                                                                <Container className="text-danger d-flex justify-content-end">
                                                                    {`${t('errors.internal_server_error')} : ${searchError}`}
                                                                </Container>
                                                            }
                                                        </Container>
                                                    }
                                                </>
                                            </Accordion.Collapse>
                                        </Container>
                                    </Accordion>
                                </Container>
                            }
                        </>
                    </Form>
                );
            }}
        </Formik>
    )
}

export default withTranslation()(DashboardFilterComponent)