import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Row, Form, Button, Col, ListGroup, Container, Modal } from 'react-bootstrap';
import Select from 'react-select';

import { ReactComponent as DeleteIcon } from 'bootstrap-icons/icons/x-circle.svg'
import { ReactComponent as AddIcon } from 'bootstrap-icons/icons/plus.svg'

import { CustomValue, Transport } from '../../../../../api/analysisApi';
import { Supplier, Origin, Transport as CTransport, ProductDenomination, Laboratory } from '../../../../../api/configApi';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../state/rootReducer';

import InlineLoader from '../../../../../common/components/InlineLoader';
import dbt from '../../../../../common/utils/dbTranslation';
import { FormikProps, getIn } from 'formik';
import { fetchFields } from '../../../../../state/fieldsSlice';
import { fetchOrigins, fetchProcessing, fetchSuppliers, fetchTransports, fetchFamilies, fetchDataLists } from '../../../../../state/configSlice';
import { Data } from '../../../../../types/types';
import moment from 'moment';
import { buildSelectNormalStyle } from '../../../../../common/utils/utils';
import { fetchFarmers } from '../../../../../state/farmerSlice';
import { Farmer } from '../../../../../api/usersApi';
import useViewport from '../../../../../common/utils/useViewport';
import useSecurityRoles from '../../../../../common/auth/useSecurityRoles';
import FarmerForm from '../../../../farmer/components/FarmerForm'
import FarmerFormAdmin from '../../../../administration/farmers/components/FarmerForm'
import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg'
import { fetchProductDenominations, fetchProductDenominationsByLaboratory } from '../../../../../state/productDenominationsSlice';

interface CustomFieldProps extends WithTranslation {
    laboratoryId: number // Current laboratory ID
    familyId: number // Current family ID
    formId: number // Id of the form (used to retrieve matching datalist)
    fieldId: number // ID of the field to display
    isReadOnly: boolean// Boolean to know if field is editable or not
    isRequired: boolean// Boolean to know if field is required or not
    disabled: boolean// Boolean to know if field is disabled
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formikProps: FormikProps<any>// All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
    formikPrefix?: string // Optionnal field prefix in formik values (ie. "analysis." for "analysis.<field storage>" name resolution)
    formikPrefixCopy?: string // Optionnal field prefix in formik values (ie. "analysis." for "analysis.<field storage>" name resolution) in which we are going to copy fields of formikPrefix
    triggerRefreshCustomFields?: boolean | null// Boolean to trigger refresh of custom fields when new analysis

}

interface ItemValue {
    id: string
    name: string
    active: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    translations: Data<any>
}

interface Fetcher {
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetcher: () => void
}

const CustomField: React.FC<CustomFieldProps> = ({ t, laboratoryId, familyId, formId, fieldId, isReadOnly, isRequired, disabled, formikProps, formikPrefix = "", formikPrefixCopy = "", triggerRefreshCustomFields = null }) => {

    const dispatch = useDispatch()
    const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } = formikProps

    const { lg } = useViewport()
    const mobile = !lg
    const { userHasRoleAll, userHasRoleClient, userHasRoleUser } = useSecurityRoles()
    type TypeFieldAnalysis = 'analysis' | 'additionalAnalysis'

    // languages state
    const { language } = useSelector((state: RootState) => state.local)
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    // Fields state
    const { data: fields, fetchStatus: fieldsStatus, fetchError: fieldsError } = useSelector((state: RootState) => state.fields)

    // Datasources states
    const { data: datalists, fetchStatus: datalistsStatus } = useSelector((state: RootState) => state.config.dataLists)
    const { data: suppliers, byLabId: suppliersByLabId, fetchStatus: suppliersStatus } = useSelector((state: RootState) => state.config.suppliers)
    const { data: origins, byLabId: originsByLabId, fetchStatus: originsStatus } = useSelector((state: RootState) => state.config.origins)
    const { data: processing, fetchStatus: processingStatus } = useSelector((state: RootState) => state.config.processing)
    const { data: transports, fetchStatus: transportsStatus } = useSelector((state: RootState) => state.config.transports)
    const { data: farmers, fetchStatusByUser: farmersStatus } = useSelector((state: RootState) => state.farmers.farmer)
    const { data: productDenominations, fetchStatus: productDenominationsStatus } = useSelector((state: RootState) => state.productDenominations)

    // Companies state
    const { data: companies, fetchStatus: companiesStatus } = useSelector((state: RootState) => state.companies)

    // Employee state
    const employeesByCompanyId = useSelector((state: RootState) => state.employees.byCompany)

    // Families state
    const { data: families, fetchStatus: familiesStatus } = useSelector((state: RootState) => state.config.families)

    const [show, setShow] = useState(false);
    const [showAdmin, setShowAdmin] = useState(false);
    const farmerPost: Farmer = { id: 0, employee_id: 0, name: "", code: "", zip_code: "", city: "", country: "", active: true }
    const [farmerPostPut, setFarmerPostPut] = useState(farmerPost);
    const [oldArrayFarmersLength, setOldArrayFarmersLength] = useState(0);
    const [typeFarmerModal, setTypeFarmerModal] = useState<TypeFieldAnalysis>('analysis');
    const [input, setInput] = useState("");
    const [valueTemp, setValueTemp] = useState<string | null>(null);
    const [loading, setLoading] = React.useState(true)
    const [storageName, setStorageName] = React.useState("")
    const [storageNameCopy, setStorageNameCopy] = React.useState("")

    const [listValues, setListValues] = React.useState<Data<ItemValue> | undefined>(undefined)

    // Effect to refresh custom fields when new analysis
    useEffect(() => {
        setValueTemp(null);
    }, [triggerRefreshCustomFields])

    // List status and data fetcher for static data sources
    const sourceFetchers: Data<Fetcher[]> = {
        suppliers: [
            { status: familiesStatus, fetcher: fetchFamilies },
            { status: suppliersStatus, fetcher: fetchSuppliers }
        ],
        origins: [
            { status: familiesStatus, fetcher: fetchFamilies },
            { status: originsStatus, fetcher: fetchOrigins }
        ],
        processing: [
            { status: processingStatus, fetcher: fetchProcessing },
        ],
        transports: [
            { status: transportsStatus, fetcher: fetchTransports },
        ],
        farmers: [
            { status: farmersStatus, fetcher: fetchFarmers },
        ],
        product_denomination: [
            { status: productDenominationsStatus, fetcher: () => fetchProductDenominations() },
        ]
    }


    /**
     * It takes a field id and a lab id, and returns a list of values for that field, filtered by the
     * lab id and family if required
     * @param {number} lFormId - the id of the form
     * @param {number} lFieldId - the id of the field
     * @param {number} labId - the id of the lab
     * @param {number} famId - the family id
     * @returns A dictionary of ItemValue objects
     */
    const buildListValues = (fieldStorageName: string, lFormId: number, lFieldId: number, labId: number, famId: number): Data<ItemValue> => {
        let listValues: Data<ItemValue> = {}
        if (fields[lFieldId].source_type === "dynamic") {
            // Get datalist matching current field
            const fieldDatalist = datalists[lFormId + "," + lFieldId] || { values: [] }
            // Map datalist values to ItemValue Dictionnary
            listValues = fieldDatalist.values.reduce<Data<ItemValue>>((acc, v) => {
                acc[v] = {
                    id: v,
                    name: v,
                    active: true,
                    translations: {}
                }
                return acc
            }, {})

            const currentValue = getInputValue(getIn(values, fieldStorageName))
            if (currentValue && currentValue !== "" && !Object.values(listValues).map(v => v.id).includes(currentValue)) {
                listValues[currentValue] = { id: currentValue, name: currentValue, active: true, translations: {} }
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let itemList: Data<any> | undefined
            switch (fields[lFieldId].source_name) {
                case "suppliers": {
                    if (suppliersByLabId && suppliersByLabId[labId]) {
                        itemList = suppliersByLabId[labId]
                            .filter(sId => famId === 0 || families[famId].suppliers.length === 0 || families[famId].suppliers.includes(Number(sId)))
                            .reduce<Data<Supplier>>((acc, sId) => {
                                acc[sId] = suppliers[sId]
                                return acc
                            }, {})
                    }
                    break;
                }
                case "origins": {

                    if (originsByLabId && originsByLabId[labId]) {
                        itemList = originsByLabId[labId]
                            .filter(sId => famId === 0 || families[famId].origins.length === 0 || families[famId].origins.includes(Number(sId)))
                            .reduce<Data<Origin>>((acc, oId) => {
                                acc[oId] = origins[oId]
                                return acc
                            }, {})
                    }
                    break;
                }
                case "processing": {
                    itemList = processing
                    break;
                }
                case "transports": {
                    itemList = transports
                    break;
                }
                case "farmers": {
                    itemList = farmers
                    break;
                }
                case "product_denomination": {
                    itemList = productDenominations
                    break;
                }
                default:
                    throw new Error("Unknown source name")
            }

            // Map item list to generic ItemValue object
            if (itemList) {
                Object.entries(itemList).forEach(([key, value]) => {
                    listValues[key] = {
                        id: value.id,
                        name: value.name,
                        active: value.active,
                        translations: value.translations
                    }
                })
            }
        }
        return listValues
    }

    /**
     * It takes a field id and returns the name of the field in the formik values object
     * @param {number} id - The id of the field
     * @param {string} id - The formik prefix
     * @returns The storage name of the field
     */
    const buildStorageName = (id: number, formikPrefix: string): string => {

        const prefix = formikPrefix ? formikPrefix + "." : ""
        switch (fields[id].storage_type) {
            case "static":
            case "static_id":
            case "static_list":
                return prefix + fields[id].storage_name
            case "dynamic": {
                const customValues: Array<CustomValue> = [...getIn(values, prefix + "custom_values")]
                const customValuesIndex = customValues.findIndex(cv => cv.field_id === id)

                if (!Array.isArray(customValues) || customValuesIndex === -1) {
                    throw new Error("All custom values must be initialized in formik state before loading component with field id " + id)
                }

                return prefix + `custom_values[${customValuesIndex}].value`
            }
            default:
                throw new Error("Invalid field storage type")
        }
    }

    /**
     * It checks if the data sources for a given field are loaded
     * @param {number} id - the id of the field
     * @returns A boolean value
     */
    const isSourceLoaded = (id: number): boolean => {

        if (fields[id].source_type === "dynamic") {

            return datalistsStatus === "succeeded" || isReadOnly
        } else {
            const sourceName = fields[id].source_name
            

            if (sourceName) {
                
                switch (sourceName) {
                    case "suppliers":
                        if (suppliersStatus === "failed" || familiesStatus === "failed") throw new Error("Source fetching failed for suppliers")
                        return suppliersStatus === "succeeded" && familiesStatus === "succeeded"
                    case "origins":
                        if (originsStatus === "failed" || familiesStatus === "failed") throw new Error("Source fetching failed for origins")
                        return originsStatus === "succeeded" && familiesStatus === "succeeded"
                    case "processing":
                        if (processingStatus === "failed") throw new Error("Source fetching failed for processing")
                        return processingStatus === "succeeded"
                    case "transports":
                        if (transportsStatus === "failed") throw new Error("Source fetching failed for transports")
                        return transportsStatus === "succeeded"
                    case "farmers":
                        if (farmersStatus === "failed") throw new Error("Source fetching failed for farmers")
                        return farmersStatus === "succeeded"
                    case "product_denomination":
                       
                        if (productDenominationsStatus === "failed") throw new Error("Source fetching failed for product denomination")
                        return productDenominationsStatus === "succeeded"
                    default:
                        throw new Error("Unknown source name")
                }
            }
        }
        return true
    }

    /**
     * If the field is a string, return the value if it's not null, otherwise return an empty string.
     * If the field is a number, return the value if it's not null, otherwise return an empty string.
     * If the field is a date, return the value if it's not null, otherwise return an empty string. If
     * the field is a datalist, return the value if it's not null, otherwise return an empty string
     * @param {any} value - The value of the field
     * @returns The value of the field.
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getReadOnlyValue = (value: any) => {
        switch (fields[fieldId].data_type) {
            case "string":
                return value !== null ? value : ""
            case "checkbox":
                return value !== null ? value : "false"
            case "number":
                return value !== null && value !== "" ? new Intl.NumberFormat(authUser.locale).format(Number(value)) : ""
            case "date":
                return value !== null && value !== undefined && value !== "" ? new Intl.DateTimeFormat(authUser.locale).format(new Date(value)) : ""
            case "datalist": {
                if (fields[fieldId].storage_type !== "static_id") {
                    return value !== null && value !== "" ? value : ""
                } else {
                    return dbt(listValues, value, language.id, "name")
                }
            }

        }
    }

    /**
     * If the field is a string, return the value if it's not null, otherwise return an empty string.
     * If the field is a number or a datalist, return the value if it's not null or an empty string,
     * otherwise return an empty string. If the field is a date, return the value if it's not null,
     * otherwise return an empty string
     * @param {any} value - The value of the field.
     * @returns The value of the field
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getInputValue = (value: any) => {
        switch (fields[fieldId].data_type) {
            case "string":
                return value !== null ? value : ""
            case "checkbox":
                return value !== null ? value : "false"
            case "number":
            case "datalist":
                if (value !== null && value !== "") {
                    return fields[fieldId].storage_type === "static_id" ? Number(value) : value
                }
                return ""
            case "date":
                return value !== null ? moment(value).format("YYYY-MM-DD") : ""
        }
        return ""
    }

    React.useEffect(() => {
        // Fetch fields
        if (fieldsStatus === 'idle') {
            dispatch(fetchFields())

        }


        // When fields fetched and current field exists
        if (fieldsStatus === 'succeeded' && fields[fieldId]) {
            try {
                
                // Generate field storage name

                const fieldStorageName = buildStorageName(fieldId, formikPrefix)
                setStorageName(fieldStorageName)

                if (formikPrefixCopy) {
                    const fieldStorageNameCopy = buildStorageName(fieldId, formikPrefixCopy)
                    setStorageNameCopy(fieldStorageNameCopy)
                }
                
                
                // If required list of values are still not loaded, load it, 
                // else build generic list from values if required and set laoding to false
                if (!isSourceLoaded(fieldId)) {
                    
                    // For dynamic field, fetch dynamic fields list of values if not readonly
                    if (fields[fieldId].source_type === "dynamic" && !isReadOnly) {
                        if (datalistsStatus === "idle") {
                            dispatch(fetchDataLists())
                        }
                    }

                    // For static fields, fetch static list of values, even if readonly
                    if (fields[fieldId].source_type === "static") {
                        let fetchers: Array<Fetcher> = []

                        const sourceName = fields[fieldId].source_name
                        if (sourceName) fetchers = sourceFetchers[sourceName]

                        if (fetchers.length > 0) {
                            fetchers.forEach(f => {

                                if (f.status === "idle")
                                    dispatch(f.fetcher())
                            })
                        }
                    }
                } else {

                    // Build generic list of values from values retrieved from states
                    if (fields[fieldId].source_type === "static" || fields[fieldId].source_type === "dynamic") {

                        setListValues(buildListValues(storageName !== "" ? storageName : fieldStorageName, formId, fieldId, laboratoryId, familyId))
                    }
                    setLoading(false)
                }

            } catch (err: unknown) {
                console.warn(err)
            }
        }
    }, [formId, fieldId, fields, fieldsStatus, laboratoryId, familyId, suppliersStatus, originsStatus, processingStatus, transportsStatus, productDenominationsStatus, farmersStatus, familiesStatus, datalistsStatus, formikProps.values])

    if (fieldsStatus === 'idle' || fieldsStatus === 'loading' || loading) {
        return (
            <Form.Group className="mb-2" controlId={storageName}>
                <Form.Label>{fieldsStatus === "succeeded" ? dbt(fields, fieldId, language.id, "name") + t("colon") : ""}</Form.Label>
                <InlineLoader />
            </Form.Group>
        )
    }

    if (fieldsStatus === 'failed' || fieldsStatus === 'succeeded' && !fields[fieldId]) {
        return (
            <>
                <Form.Text className="text-danger">Unable to retrieve field configuration</Form.Text>
                {fieldsError &&
                    <Form.Text className="text-danger">{fieldsError}</Form.Text>
                }
            </>
        )
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeInput = (newValue: string, { action }: any) => {
        switch (action) {
            case 'input-change':
                setInput(newValue)
                return
            default:
                return
        }
    }

    function updateRights(laboratoryId: number) {
        hasRoleAll = userHasRoleAll(laboratoryId, authUser);
        hasRoleClient = userHasRoleClient(laboratoryId, authUser);
        hasRoleUser = userHasRoleUser(laboratoryId, authUser);
    }

    let hasRoleAll = false
    let hasRoleClient = false
    let hasRoleUser = false

    updateRights(laboratoryId);

    function showFarmerModal(analysisField: TypeFieldAnalysis, idFarmer: number | undefined, idEmployee: number) {
        setTypeFarmerModal(analysisField)
        if (idEmployee && idEmployee !== 0) {
            if (idFarmer === 0 || idFarmer === undefined || idFarmer === null) {
                const employeeFarmers = Object.values(farmers).filter(item => item.employee_id === idEmployee)
                setOldArrayFarmersLength(employeeFarmers.length)
                if (input !== '') {
                    setFarmerPostPut({ ...farmerPost, name: input, employee_id: idEmployee })
                } else {
                    setFarmerPostPut({ ...farmerPost, employee_id: idEmployee })
                }
            } else {
                setFarmerPostPut(farmers[idFarmer])
            }
            if (hasRoleAll || hasRoleClient) {
                setShowAdmin(true)
            }
            else {
                setShow(true)
            }
        }
    }

    const handleFarmerCreated = async (analysisField: TypeFieldAnalysis) => {
        if (values.analysis.employee_id > 0) {
            const employeeFarmers = Object.values(farmers).filter(item => item.employee_id === values.analysis.employee_id)
            if (oldArrayFarmersLength !== employeeFarmers.length) {
                if (!values.analysis.farmer_id) {
                    const farmerId = employeeFarmers.reduce((max, e) => (e.id && max ? e.id > max ? e.id : max : 0), employeeFarmers[0].id)
                    setInput('')
                    await setFieldValue(`${analysisField}.farmer_id`, farmerId)
                }
            }
        }
    }

    const handleCloseAdmin = (analysisField: TypeFieldAnalysis) => {
        handleFarmerCreated(analysisField)
        setShowAdmin(false)
    }

    const handleClose = (analysisField: TypeFieldAnalysis) => {
        handleFarmerCreated(analysisField)
        setShow(false)

    }

    if (fieldsStatus === 'succeeded' && fields[fieldId] && !loading) {

        if (!fields[fieldId].active) {
            return null
        } else {
            return (
                <>
                    {isReadOnly ?
                        <Form.Group className={`mb-1 text-break ${fields[fieldId].data_type === "checkbox" ? 'd-flex ' : ''}`} controlId="formTitleCustomField">
                            {(fields[fieldId].data_type !== "custom" || (fields[fieldId].data_type === "custom" && fields[fieldId].input_name !== "farmers")) &&
                                <>
                                    <Form.Label className="text-black fw-bold">{dbt(fields, fieldId, language.id, "name") + t("colon")}</Form.Label>
                                    <span className={`ps-2`}>
                                        {fields[fieldId].data_type === "checkbox"
                                            ? <Form.Check
                                                className="text-black fw-bold"
                                                type="checkbox"
                                                name={storageName}
                                                checked={getInputValue(getIn(values, storageName)) === 'true'}
                                                onBlur={handleBlur}
                                                disabled={true}
                                            />
                                            : getReadOnlyValue(getIn(values, storageName))}
                                    </span>
                                </>
                            }

                            {fields[fieldId].data_type === "custom" && fields[fieldId].input_name === "transports" &&
                                <ul>
                                    {(getIn(values, storageName) as Array<Transport>).map((transport, index) => {
                                        return (
                                            <li key={index}>{dbt(transports, transport.transport_id, language.id, "name") + " - " + transport.distance + " km"}</li>
                                        )
                                    })
                                    }

                                </ul>

                            }
                            {fields[fieldId].data_type === "custom" && fields[fieldId].input_name === "farmers" &&
                                <span>
                                    <Row>
                                        <Col lg>
                                            <Form.Group>
                                                <Form.Label className="text-break"><span className="text-black fw-bold">{t('forms.analysis.farmer')}</span> {farmers[getIn(values, 'farmer_id')] ? farmers[getIn(values, 'farmer_id')].name : ''} </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col lg>
                                            <Form.Group>
                                                <Form.Label className="text-break"><span className="text-black fw-bold">{t('forms.analysis.code')}</span> {farmers[getIn(values, 'farmer_id')] ? farmers[getIn(values, 'farmer_id')].code : ''} </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg>
                                            <Form.Group>
                                                <Form.Label className="text-break"><span className="text-black fw-bold">{t('forms.analysis.zip_code')} </span> {farmers[getIn(values, 'farmer_id')] ? farmers[getIn(values, 'farmer_id')].zip_code : ''} </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col lg>
                                            <Form.Group>
                                                <Form.Label className="text-break"><span className="text-black fw-bold">{t('forms.analysis.city')} </span> {farmers[getIn(values, 'farmer_id')] ? farmers[getIn(values, 'farmer_id')].city : ''} </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col lg>
                                            <Form.Group>
                                                <Form.Label className="text-break"><span className="text-black fw-bold">{t('forms.analysis.country')} </span> {farmers[getIn(values, 'farmer_id')] ? farmers[getIn(values, 'farmer_id')].country : ''}</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </span>

                            }
                            {fields[fieldId].data_type === "custom" && fields[fieldId].input_name === "product_denomination" &&
                                <span>
                                    <Row>
                                        <Col>

                                            {productDenominations[values.denomination_id] ? (productDenominations[values.denomination_id].code + ' - ' + productDenominations[values.denomination_id].name) : ''}
                                        </Col>
                                    </Row>
                                </span>

                            }

                        </Form.Group>
                        :
                        <Form.Group className={`mb-2 ${fields[fieldId].data_type === "checkbox" ? 'd-flex ' : ''}`} controlId={storageName}>
                            <Form.Label>{dbt(fields, fieldId, language.id, "name") + t("colon") + (isRequired ? " *" : "")}</Form.Label>

                            {(() => {
                                switch (fields[fieldId].data_type) {
                                    case "datalist": {
                                        // For datalist, display a select input
                                        const currentValue = getInputValue(getIn(values, storageName))
                                        return (

                                            <Select
                                                className={getIn(touched, storageName) && typeof getIn(errors, storageName) === 'string' ? `imgErrorInvalidInput` : ``}
                                                styles={buildSelectNormalStyle(getIn(touched, storageName), typeof getIn(errors, storageName) === 'string')}
                                                noOptionsMessage={() => t("no_option_found")}
                                                value={listValues && listValues[currentValue] ? listValues[currentValue] : currentValue !== "" ? { id: currentValue, name: currentValue, active: true, translations: {} } : undefined}
                                                name={storageName}
                                                placeholder=""
                                                getOptionLabel={(item: ItemValue) => listValues ? dbt(listValues, item.id, language.id, "name") : ""}
                                                getOptionValue={(item: ItemValue) => fields[fieldId].storage_type === "static_id" ? item.id : item.name}
                                                isClearable={true}
                                                isDisabled={disabled}
                                                options={listValues &&
                                                    Object.values(listValues).filter(item => item.active || item.id === currentValue)
                                                }
                                                onChange={async (item: ItemValue) => {
                                                    [storageName, storageNameCopy].filter(s => !!s).forEach(
                                                        async s => {
                                                            if (item && item !== null) {
                                                                await setFieldValue(s, fields[fieldId].storage_type === "static_id" ? item.id : item.name)

                                                            } else {
                                                                await setFieldValue(s, fields[fieldId].storage_type === "static_id" ? null : "")
                                                            }
                                                        }
                                                    )

                                                    await setFieldTouched(storageName, true)



                                                }}
                                                onBlur={async () => await setFieldTouched(storageName, true)}
                                            />
                                        )
                                    }
                                    case "checkbox":
                                        return (
                                            <Form.Check
                                                className="ps-2"
                                                type="checkbox"
                                                name={storageName}
                                                disabled={disabled}
                                                checked={getInputValue(getIn(values, storageName)) === 'true'}
                                                onChange={async () => {
                                                    [storageName, storageNameCopy].filter(s => !!s).forEach(
                                                        async s => {
                                                            await setFieldValue(s, (!(getInputValue(getIn(values, storageName)) === 'true')).toString())
                                                        }
                                                    )
                                                }}
                                                onBlur={handleBlur}

                                            />
                                        )
                                    case "string":
                                    case "number":
                                    case "date":
                                        // For string, number or sdate, display an input matching the given type
                                        return (
                                            <Form.Control
                                                isInvalid={getIn(touched, storageName) && typeof getIn(errors, storageName) === "string"}
                                                name={storageName}
                                                value={valueTemp != null ? valueTemp : getInputValue(getIn(values, storageName))}
                                                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                                                    
                                                    let fieldValue: string | null = null
                                                    if (e.target.value) {
                                                        setValueTemp(String(e.target.value))
                                                    } else {
                                                        setValueTemp(fields[fieldId].data_type === "string" ? "" : null)
                                                    }

                                                 

                                                }}
                                                onKeyDown={fields[fieldId].data_type === "number" ? (evt: React.KeyboardEvent<HTMLInputElement>) => (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '+' || (fields[fieldId].name === "Havest year" &&  evt.key === '.' )) && evt.preventDefault() : undefined}
                                                onBlur={() => {    [storageName, storageNameCopy].filter(s => !!s).forEach(
                                                    async s => {
                                                        await setFieldValue(s, valueTemp != null ? valueTemp : getInputValue(getIn(values, storageName)))
                                                    }
                                                )}}
                                                disabled={disabled}
                                                {...Object.assign({},
                                                    fields[fieldId].data_type === "string" ? { type: "text", maxLength: 255 } : null,
                                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                    fields[fieldId].data_type === "number" ? { type: "number", onWheel: (e: any) => e.target.blur() } : null,
                                                    fields[fieldId].data_type === "date" ? { type: "date" } : null,
                                                    fields[fieldId].data_type === "checkbox" ? { type: "checkbox" } : null,
                                                )}
                                            />
                                        )
                                    case "custom":
                                        // For custom, generate the matching complex input set
                                        switch (fields[fieldId].input_name) {
                                            case "transports": {
                                                const transportsList: Array<Transport> = getIn(values, storageName)
                                                return (
                                                    <>
                                                        <ListGroup className="h-20rem mb-2">
                                                            {transportsList.map((transport, index) => {
                                                                return (
                                                                    <ListGroup.Item key={index}>
                                                                        <Row>
                                                                            <Col>
                                                                                <Row>
                                                                                    <Col xs={12} lg>
                                                                                        <Select
                                                                                            className={getIn(touched, `${storageName}[${index}].transport_id`) && typeof getIn(errors, `${storageName}[${index}].transport_id`) === 'string' ? `imgErrorInvalidInput` : ``}
                                                                                            styles={buildSelectNormalStyle(getIn(touched, `${storageName}[${index}].transport_id`), typeof getIn(errors, `${storageName}[${index}].transport_id`) === 'string')}
                                                                                            noOptionsMessage={() => t("no_option_found")}
                                                                                            value={transports[transport.transport_id]}
                                                                                            name={`${storageName}[${index}].transport_id`}
                                                                                            placeholder=""
                                                                                            getOptionLabel={(item: CTransport) => dbt(transports, item.id, language.id, "name")}
                                                                                            getOptionValue={(item: CTransport) => item.id}
                                                                                            isClearable={false}
                                                                                            isDisabled={disabled}
                                                                                            options={Object.values(transports).filter(item => item.active || item.id === transport.transport_id) || []}
                                                                                            onChange={async (item: CTransport) => {
                                                                                                if (item && item !== null) {

                                                                                                    [storageName, storageNameCopy].filter(s => !!s).forEach(
                                                                                                        async s => {
                                                                                                            await setFieldValue(`${s}[${index}].transport_id`, item.id)
                                                                                                        }
                                                                                                    )
                                                                                                }
                                                                                                await setFieldTouched(`${storageName}[${index}].transport_id`, true)
                                                                                            }}
                                                                                            onBlur={async () => await setFieldTouched(`${storageName}[${index}].transport_id`, true)}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col xs={12} lg={4} className="mt-2 mt-lg-0">
                                                                                        <Row>
                                                                                            <Form.Label column xs="auto">{t('forms.analysis.transports_distance')}</Form.Label>

                                                                                            <Col>
                                                                                                <Form.Control
                                                                                                    isInvalid={getIn(touched, `${storageName}[${index}].distance`) && typeof getIn(errors, `${storageName}[${index}].distance`) === "string"}
                                                                                                    name={`${storageName}[${index}].distance`}
                                                                                                    value={transport.distance || ""}
                                                                                                    onChange={(e) => {

                                                                                                        [storageName, storageNameCopy].filter(s => !!s).forEach(
                                                                                                            async s => {
                                                                                                                await setFieldValue(`${s}[${index}].distance`, e.target.value)
                                                                                                            }
                                                                                                        )

                                                                                                    }}
                                                                                                    onBlur={handleBlur}
                                                                                                    disabled={disabled}
                                                                                                    type="number"
                                                                                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                                                    onWheel={(e: any) => e.target.blur()}
                                                                                                />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col xs="auto" className="d-flex align-items-center">
                                                                                <Button className="text-danger p-0" variant="link" onClick={async () => {
                                                                                    const updated = [...getIn(values, storageName)]
                                                                                    updated.splice(index, 1)
                                                                                    await setFieldValue(storageName, updated)
                                                                                }} >
                                                                                    <DeleteIcon width="20px" height="100%" className="align-text-top" />
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col>
                                                                                {getIn(touched, `${storageName}[${index}].transport_id`) && typeof getIn(errors, `${storageName}[${index}].transport_id`) === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors, `${storageName}[${index}].transport_id`)}</Form.Text> : null}
                                                                                {getIn(touched, `${storageName}[${index}].distance`) && typeof getIn(errors, `${storageName}[${index}].distance`) === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors, `${storageName}[${index}].distance`)}</Form.Text> : null}
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroup.Item>
                                                                )
                                                            })}
                                                        </ListGroup>
                                                        <Form.Group as={Row}>
                                                            <Col>
                                                                <Button size="sm" disabled={disabled} variant="secondary" type="button"
                                                                    onClick={async () => {
                                                                        const updated = [
                                                                            ...getIn(values, storageName),
                                                                            { transport_id: 0, distance: null }
                                                                        ]
                                                                        await setFieldValue(storageName, updated)
                                                                    }} >
                                                                    <AddIcon width="20px" height="100%" className="align-text-mid" />
                                                                    {t('buttons.add')}
                                                                </Button>
                                                                <Button size="sm" disabled={disabled} variant="danger" type="button" className="ms-2"
                                                                    onClick={async () => await setFieldValue(storageName, [])} >
                                                                    {t('buttons.remove_all')}
                                                                </Button>
                                                            </Col>
                                                        </Form.Group>
                                                    </>
                                                )
                                            }
                                            case "farmers": {
                                                return (
                                                    <Container className="p-0 pb-2" >
                                                        <Container>
                                                            <Modal size="lg" show={showAdmin} onHide={handleCloseAdmin.bind(null, typeFarmerModal === 'analysis' ? 'analysis' : 'additionalAnalysis', values, setFieldValue)}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>{farmerPostPut.id === 0 ? t('forms.farmers.title') : t('forms.farmers.title_modify')}</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <FarmerFormAdmin currentLaboratoryId={laboratoryId} currentCompanyId={values.analysis.company_id} currentEmployeeId={values.analysis.employee_id} farmerObject={farmerPostPut} />
                                                                </Modal.Body>
                                                            </Modal>

                                                            <Modal size="lg" show={show} onHide={handleClose.bind(null, typeFarmerModal === 'analysis' ? 'analysis' : 'additionalAnalysis', values, setFieldValue)}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>{farmerPostPut.id === 0 ? t('forms.farmers.title') : t('forms.farmers.title_modify')}</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <FarmerForm farmerObject={farmerPostPut} />
                                                                </Modal.Body>
                                                            </Modal>
                                                        </Container>
                                                        <Form.Group className="mb-2" controlId="formFarmer" >
                                                            <Row>
                                                                <Col>
                                                                    <Select
                                                                        className={getIn(touched, `${storageName}.farmer_id`) && typeof getIn(errors, `${storageName}.farmer_id`) === 'string' ? `imgErrorInvalidInput` : ``}
                                                                        styles={{
                                                                            control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                                                ...styles,
                                                                                borderColor: (getIn(touched, `${storageName}.farmer_id`) && typeof getIn(errors, `${storageName}.farmer_id`) === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                boxShadow: state.isFocused ? (getIn(touched, `${storageName}.farmer_id`) && typeof getIn(errors, `${storageName}.farmer_id`) === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                '&:hover': {
                                                                                    borderColor: (getIn(touched, `${storageName}.farmer_id`) && typeof getIn(errors, `${storageName}.farmer_id`) === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                    boxShadow: state.isFocused ? (getIn(touched, `${storageName}.farmer_id`) && typeof getIn(errors, `${storageName}.farmer_id`) === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                                }
                                                                            }),
                                                                            menu: (styles: Data<string>) => ({
                                                                                ...styles,
                                                                                zIndex: '1031'
                                                                            })
                                                                        }}
                                                                        noOptionsMessage={() => t('forms.analysis.farmerNotFound')}
                                                                        value={values.analysis.farmer_id && farmers[values.analysis.farmer_id]}
                                                                        name='analysis.farmer_id'
                                                                        placeholder=""
                                                                        onInputChange={handleChangeInput}
                                                                        getOptionLabel={(farmer: Farmer) => {
                                                                            if (farmer.name === "") {
                                                                                return ""
                                                                            } else {
                                                                                let line = farmer.name
                                                                                if (farmer.code) line += " - " + farmer.code
                                                                                if (farmer.zip_code) line += " - " + farmer.zip_code
                                                                                if (farmer.city) line += " - " + farmer.city
                                                                                if (farmer.country) line += " - " + farmer.country
                                                                                return line
                                                                            }
                                                                        }}
                                                                        getOptionValue={(farmer: Farmer) => farmer.id}
                                                                        isDisabled={Number(values.analysis.employee_id) === 0 && (hasRoleAll || hasRoleClient) ? true : disabled}
                                                                        isClearable={true}
                                                                        options={
                                                                            companies && companies[values.analysis.company_id] && companies[values.analysis.company_id].farmer_level === 'employee' ?
                                                                                Object.values(farmers)
                                                                                    .filter(item => item.employee_id === values.analysis.employee_id && item.active === true)
                                                                                    .sort((a, b) => (a.name).localeCompare(b.name))
                                                                                :
                                                                                Object.values(farmers)
                                                                                    .filter(item => employeesByCompanyId && employeesByCompanyId[values.analysis.company_id] && employeesByCompanyId[values.analysis.company_id].includes(String(item.employee_id)) && item.active === true)
                                                                                    .sort((a, b) => (a.name).localeCompare(b.name))
                                                                        }


                                                                        onChange={async (farmer: Farmer) => {
                                                                            if (farmer) {
                                                                                await setFieldValue("analysis.farmer_id", farmer.id)
                                                                                if (values.additionalAnalysis) await setFieldValue("additionalAnalysis.farmer_id", farmer.id)
                                                                            } else {
                                                                                await setFieldValue("analysis.farmer_id", 0)
                                                                                if (values.additionalAnalysis) await setFieldValue("additionalAnalysis.farmer_id", 0)
                                                                            }
                                                                            setFieldTouched("analysis.farmer_id", true)
                                                                            if (values.additionalAnalysis) await setFieldTouched("additionalAnalysis.farmer_id", true)
                                                                        }}
                                                                        onBlur={async () => {
                                                                            setFieldTouched("analysis.farmer_id", true)
                                                                            if (values.additionalAnalysis) setFieldTouched("additionalAnalysis.farmer_id", true)
                                                                        }}
                                                                    />
                                                                    {getIn(touched.analysis, "farmer_id") && typeof getIn(errors.analysis, "farmer_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "farmer_id")}</Form.Text> : null}
                                                                    {Number(values.analysis.employee_id) === 0 && (hasRoleAll || hasRoleClient) ? <Form.Text className="invalid-feedback d-block">{t('forms.analysis.needEmployeeForSelectFarmer')}</Form.Text> : null}
                                                                </Col>
                                                                <Col xs="auto">
                                                                    <Button className="btn-block" variant="secondary"
                                                                        onClick={showFarmerModal.bind(null, 'analysis', values.analysis.farmer_id, values.analysis.employee_id)}
                                                                        disabled={values.analysis.employee_id === 0 && (hasRoleAll || hasRoleClient)}
                                                                    >
                                                                        {!mobile ? (!values.analysis.farmer_id ? t('buttons.add') : t('buttons.edit')) : (!values.analysis.farmer_id ? <AddIcon width="20px" height="100%" className="align-text-top" /> : <EditIcon width="20px" height="100%" className="align-text-top" />)}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        {values.analysis.farmer_id !== 0 && values.analysis.farmer_id !== undefined && farmers[values.analysis.farmer_id] &&
                                                            <Row>
                                                                <Col xs={!mobile ? 4 : 12}>
                                                                    <Form.Group className="mb-2">
                                                                        <Form.Label><span className="text-black fw-bold">{t('forms.analysis.code')}</span> {farmers[values.analysis.farmer_id].code} </Form.Label>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={!mobile ? 1 : 12}>
                                                                    <Form.Group className="mb-2">
                                                                        <Form.Label><span className="text-black fw-bold">{t('forms.analysis.zip_code')} </span> {farmers[values.analysis.farmer_id].zip_code} </Form.Label>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={!mobile ? 3 : 12}>
                                                                    <Form.Group className="mb-2">
                                                                        <Form.Label><span className="text-black fw-bold">{t('forms.analysis.city')} </span> {farmers[values.analysis.farmer_id].city} </Form.Label>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={!mobile ? 3 : 12}>
                                                                    <Form.Group className="mb-2">
                                                                        <Form.Label><span className="text-black fw-bold">{t('forms.analysis.country')} </span> {farmers[values.analysis.farmer_id].country}</Form.Label>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </Container>
                                                )
                                            }
                                            case "product_denomination": {
                                                return (
                                                    <Container className="p-0 pb-2" >

                                                        <Select
                                                            className={getIn(touched, `${storageName}.denomination_id`) && typeof getIn(errors, `${storageName}.denomination_id`) === 'string' ? `imgErrorInvalidInput` : ``}
                                                            noOptionsMessage={() => t('forms.analysis.productDenominationNotFound')}
                                                            value={formikPrefix === 'analysis' ?(values.analysis.denomination_id && productDenominations[values.analysis.denomination_id]): (values.additionalAnalysis?.denomination_id && productDenominations[values.additionalAnalysis?.denomination_id])}
                                                            name={`${formikPrefix}.denomination_id`}
                                                            placeholder=""
                                                            onInputChange={handleChangeInput}
                                                            getOptionLabel={(productDenomination: ProductDenomination) => {
                                                                if (productDenomination.name === "") {
                                                                    return ""
                                                                } else {
                                                                    return (productDenomination.name + " - " + productDenomination.code)
                                                                }
                                                            }}
                                                            getOptionValue={(productDenomination: ProductDenomination) => productDenomination.id}
                                                            isClearable={true}
                                                            options={
                                                                Object.values(productDenominations).filter((item) => item.active && item.laboratory_id === laboratoryId)
                                                            }


                                                            onChange={async (productDenomination: ProductDenomination) => {
                                                                if (productDenomination) {
                                                                    if (formikPrefix === 'analysis'){
                                                                        await setFieldValue('analysis.denomination_id', productDenomination.id)
                                                                    }
                                                                    else {
                                                                        if (values.additionalAnalysis) await setFieldValue("additionalAnalysis.denomination_id", productDenomination.id)
                                                                    }
                                                                    

                                                                } else {
                                                                    if (formikPrefix === 'analysis'){
                                                                        await setFieldValue("analysis.denomination_id", 0)
                                                                    }
                                                                    else {
                                                                        if (values.additionalAnalysis) await setFieldValue("additionalAnalysis.denomination_id", 0)
                                                                    }
                                                                }
                                                                setFieldTouched("analysis.denomination_id", true)
                                                                if (values.additionalAnalysis) await setFieldTouched("additionalAnalysis.denomination_id", true)
                                                            }}
                                                            onBlur={async () => {
                                                                setFieldTouched("analysis.denomination_id", true)
                                                                if (values.additionalAnalysis) setFieldTouched("additionalAnalysis.denomination_id", true)
                                                            }}
                                                        />
                                                        {getIn(touched.analysis, "denomination_id") && typeof getIn(errors.analysis, "denomination_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "denomination_id")}</Form.Text> : null}
                                                    </Container>
                                                )
                                            }
                                        }
                                }
                            })()}
                            {getIn(touched, storageName) && typeof getIn(errors, storageName) === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors, storageName)}</Form.Text> : null}
                        </Form.Group>
                    }
                </>
            )
        }
    } else {
        return <Form.Text className="text-danger">Unexpected behavior</Form.Text>
    }
}

export default withTranslation()(CustomField)