import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { useTranslation } from 'react-i18next';

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import './i18n';
import App from './App';

import store from './state/store';
import SWUpdateToast from './common/components/SWUpdateToast';

import { onInit, onUpdate, onSuccess } from "./common/utils/registrationStatus";
import SWExportExcelProgress from './common/components/SWExportExcelProgress';
import { ToastContainer } from 'react-bootstrap';
import SWMatricesProgress from './common/components/SWMatricesProgress';

const UnsecuredPage: React.FC = () => {
  
  const { t } = useTranslation()
  
  return (
    <div>
      <h1 className="text-danger">{t("security.clickjacking.title")}</h1>
      <p>{t("security.clickjacking.text")}</p>
    </div>
  )
}
 
// Won't render the application if WebApp is under Clickjacking attack
if(window.self === window.top) {
  ReactDOM.render(
    <React.StrictMode>

      {/* Redux store provider and application content */}
      <Provider store={store}>
        <App />
        <ToastContainer className="p-3 sticky-toast" position="bottom-end" style={{zIndex: 9999}}>
          {/* Application need update warning */}
          <SWUpdateToast />
          {/* Component to regulary check export jobs */}
          <SWExportExcelProgress/>
          <SWMatricesProgress/>

        </ToastContainer>
        

        
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
} else{
  ReactDOM.render(
    <UnsecuredPage />, 
    document.getElementById('root')
  );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onInit,
  onUpdate,
  onSuccess,
});