import React, { ChangeEventHandler } from 'react';
import {Form } from 'react-bootstrap';
import { Analysis } from '../../../../../api/analysisApi';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/rootReducer';



interface FormCheckboxUrgentProps {
    changeStatutUrgent?:ChangeEventHandler
    analysisFieldName: string
    analysis:Analysis
    handleBlur?:ChangeEventHandler
    markup:number
    readOnly?:boolean
}

const FormCheckboxUrgent: React.FC <FormCheckboxUrgentProps> = ({changeStatutUrgent,analysis,handleBlur,markup,readOnly, analysisFieldName}) => {
    const authUser = useSelector((state: RootState) => state.authUser.user)

return (
    <div>
        <Form.Check 
            type="checkbox" 
            name="`${analysisFieldName}.urgent`" 
            checked={analysis.urgent === undefined || analysis.urgent === null? false:analysis.urgent} 
            onChange={changeStatutUrgent} 
            readOnly={readOnly}
            onBlur={handleBlur} 
            label={t('forms.analysis.urgent')}
        />
        {analysis.urgent && markup > 0 &&
            <Form.Label>{t('forms.analysis.messageUrgent',{markup:Intl.NumberFormat(authUser.locale).format(markup)})}</Form.Label>
        }
    </div>

);

}

export default FormCheckboxUrgent