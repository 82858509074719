/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import env from "../common/utils/env";
import AbstractApi from "./AbstractApi";
import { JsonName } from "./analysisApi";

export interface FiltersRequest {
    company_id?: number,
    type: string,
    period_field: string,
    from: string,
    to: string
}

export interface FilterSet {
    filter: Filter[]
}

export interface Filter {
    field: string,
    values: string[]
}

export interface FiltersResponse {
    filter: Filter[]
}

export interface Outliers {
    outliers_on: string,
    mdi: boolean,
    gh: boolean,
    low_high_limit: boolean,
    analyte_max: Analyte[],
    analyte_min: Analyte[]
}

export interface Analyte {
    analyte_id: string,
    value: number | null
}

export interface AnalyteRequest {
    analyte_id: string,
    display_table: boolean,
}

export interface CommonFilter {
    type: string,
    period_field: string,
    from: string,
    to: string,
    exclude_outliers: boolean,
    filter: Filter[]
}

export interface CustomValues {
    field_default_name: string,
    field_translation: string,
    value: string
}

export interface Results {
    analyte_id: string,
    analyte_name: JsonName,
    default_unit: string,
    default_unit_result: number,
    method_type: string,
    is_mdi_ok: boolean,
    is_gh_ok: boolean,
    is_in_between_limit: boolean,
    target: number,
    warning_low: number,
    warning_high: number,
    limit_low: number,
    limit_high: number,
    outlier: boolean
}

export interface DashboardAnalysisHeader {
    analysis_id: number,
    laboratory_id: number,
    laboratory_name: string,
    display_id: string,
    company_id: number,
    company_name: string,
    employee_id: number,
    group: string,
    pattern: string,
    control_plan: string,
    creation_date: Date,
    validation_date: Date,
    last_update: Date,
    plant: string,
    denomination: string,
    customer_reference: string,
    sample_date: Date,
    silo: string,
    delivery_date: Date,
    lot: string,
    provider: string,
    origin: string,
    processing_name: string,
    harvest_year: number,
    farmer: string,
    harvest_date: Date,
    manufacturing_date: Date,
    formula: string,
    is_mdi_ok: boolean,
    is_gh_ok: boolean,
    is_in_between_limit: boolean,
    day: number,
    month: number,
    year: number,
    custom_values: CustomValues[],
    results: Results[],
    outlier: boolean,
    nir_name: string,
    family_name: string
}


export enum DashboardJobType {
    daily = 'DAILY',
    weekly = 'WEEKLY',
    yearly = 'YEARLY'
}

export enum DashboardJobMode {
    full = 'FULL',
    delta = 'DELTA'
}

export interface DashboardJobFrequencies {
    id: number
    name: string
    table_name: string
    type: DashboardJobType
    mode: DashboardJobMode
    date_exec: Date
    hours: string
    minutes: string
    timezone: string
    job_id: string
    job_status: JobStatus
    job_last_exec: Date
    error_message: string
}

export interface DashboardJobFrequenciesValue {
    id: number
    name: string
    table_name: string
    type: DashboardJobType
    mode: DashboardJobMode
    date_exec: Date | null
    time : string
    timezone: string
    job_id: string
    job_status: JobStatus
    job_last_exec: Date | null
    error_message: string
}

export enum JobStatus {
    success = 'SUCCESS',
    error = 'ERROR'
}

export interface SearchRequest {
    matrix_template_id?: number
    company_id?: number,
    common_filter: CommonFilter,
    filter_set: FilterSet[],
    analytes?: AnalyteRequest[],
    outliers?: Outliers
}

export interface DashboardProperties {
    rm_retention_days: number;
    fp_retention_days: number;
    fg_retention_days: number;
    max_analytes: number;
    max_graphs: number;
    max_group_values: number;
  }
  
export interface ManageDashboard {
    dashboard_name: string;
    table_name: string;
    properties: DashboardProperties;
}

export type DashboardTable = {
    table_name: string
    last_update: Date
  }

export type Preference = {
    category: string,
    sub_category: string,
    raw_material: string,
    common_filter: {
        period_field: 'creation_date' | 'validation_date' | 'sample_date' | 'delivery_date',
        from: Date,
        to: Date,
        exclude_outliers: boolean,
        filter: {
            field: string,
            values: Array<string>
        }[]
    },
    filter_set: {
        filter: {
            field: string,
            values: Array<string>
        }[]
    }[],
    analytes: {
        analyte_id: string,
        display_table: boolean,
        display_graph: boolean,
        decimals: number,
    }[],
    options: {
        average: boolean,
        average_1sd: boolean,
        average_2sd: boolean,
        average_3sd: boolean,
        selected_values: boolean,
        active_matrix: boolean,
        connect_dots: boolean
        data_show: 'initial_filtering' | 'selection',
        trend: boolean,
        show_outliers: boolean
    },
    groups: {
        field: string,
        values: {
            value: string,
            color: string
        }[]
    },
    weights: {
        field: 'method_type' | 'origin' | 'supplier' | 'last_x_days' | 'factory' | 'company',
        value: string,
        weight: number
    }[],
    samples_info: {
        field: string,
        display: boolean
    }[],
    selected_values: SelectedValue[]
}

export interface PreferenceDb {
    company_id: number,
    export_code: string,
    version: number,
    preferences: Preference
}


export interface SelectedValue {
    analyte_id: string,
    value: number
}

export interface SelectedValues {
    selected_values: SelectedValue[],
    commentary: string
}

export type PeriodField = 'validation_date' | 'creation_date' | 'delivery_date' | 'sample_date'
export type Selection = 'selection' | 'initial_filtering'

export function getKeyForTabAssocManageDashboard(manageDashboard: ManageDashboard): string {
    return manageDashboard.dashboard_name + '#' + manageDashboard.table_name
}

export function getKeyForPreferences(preference: PreferenceDb): string {
    return preference ? preference.company_id + '#' + preference.export_code + '#' + preference.version : ''
}

class DashboardApi extends AbstractApi {
    private static instance: DashboardApi;



    public static getInstance() {
        if (!DashboardApi.instance) {
            throw new Error("Initialize instance before.");
        }

        return DashboardApi.instance;
    }

    public static initInstance(token: string) {
        DashboardApi.instance = new DashboardApi(env.DASHBOARD_API_URL, token, 150000);
    }

    public async postFilters(dashboardName: string, body: FiltersRequest): Promise<FiltersResponse> {
        try {
            const response = await this.service.post(`/secure/dashboard/analysis/filters/${encodeURIComponent(dashboardName)}`, body)
            const data: FiltersResponse = response.data
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async search(dashboardName: string, body: SearchRequest): Promise<DashboardAnalysisHeader[]> {
        try {
            const response = await this.service.post(`/secure/dashboard/analysis/search/${encodeURIComponent(dashboardName)}`, body)
            const data: DashboardAnalysisHeader[] = response.data.map((sfd: any) => {return {...sfd, analysis_id: sfd.id}})
            return data
        } catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getAllDashboardPreferences(): Promise<PreferenceDb[]> {
        try{
            const response = await this.service.get(`/secure/dashboard/matrix/preferences`)
            const data: PreferenceDb[] = response.data !== '' ? response.data : []
            return data

        }catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getDashboardPreferences(company_id: number, export_code: string, version: number): Promise<Preference | null> {
        try{
            const response = await this.service.get(`/secure/dashboard/matrix/preferences/${company_id}/${export_code}/${version}`)
            const data: Preference | null = response.data !== '' ? response.data : null
            return data

        }catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putDashboardPreferences(company_id: number, export_code: string, version: number, body: Preference): Promise<Preference | null> {
        try{
            const response = await this.service.put(`/secure/dashboard/matrix/preferences/${company_id}/${export_code}/${version}`, body)
            const data: Preference | null = response.data
            return data

        }catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getManageDashboard(): Promise<ManageDashboard[]> {
        try{
            const response = await this.service.get(`/secure/admin/dashboard`)
            const data: ManageDashboard[] = response.data
            return data

        }catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getDashboardTables(): Promise<DashboardTable[]> {
        try{
            const response = await this.service.get(`/secure/admin/dashboard/tables`)
            const data: DashboardTable[] = response.data
            return data

        }catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async putManageDashboard( body: ManageDashboard[] ): Promise<ManageDashboard[]> {
        try{
            const response = await this.service.put(`/secure/admin/dashboard`, body)
            const data: ManageDashboard[] = response.data
            return data

        }catch (err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getDashboardJobFrequencies(): Promise<DashboardJobFrequencies[]> {
        try{

            const response = await this.service.get(`/secure/admin/dashboard/job-frequencies`)

            const data: DashboardJobFrequencies[] = response.data
            return data

        }catch (err) {
            throw AbstractApi.handleError(err)
        }
    }


    public async putDashboardJobFrequencies( body: DashboardJobFrequencies[] ): Promise<DashboardJobFrequencies[]> {
        try{
            const response = await this.service.put(`/secure/admin/dashboard/job-frequencies`, body)
            const data: DashboardJobFrequencies[] = response.data
            return data

        }catch (err) {
            throw AbstractApi.handleError(err)
        }
    }
}

export default DashboardApi;
