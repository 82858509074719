import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import UsersApi, { InternalUser } from '../api/usersApi';

interface Dictionary<T> {
    [key: string]: T
}

interface CurrentState {
    data: Dictionary<InternalUser>
    filtered: Array<string>
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
}

const initialState = {
    data: {},
    filtered: [],
    fetchStatus: 'idle',
    fetchError: undefined,
    singleFetchStatus: 'idle',
    singleFetchError: undefined
} as CurrentState

export const fetchInternalUsers = createAsyncThunk(
    'users/fetchInternalUsers', 
    async () => {
        const response = await UsersApi.getInstance().getInternalUsers()
        return response as Array<InternalUser>
    }
)

export const fetchSingleInternalUser = createAsyncThunk(
    'users/fetchSingleInternalUser', 
    async (externalId: string) => {
        const response = await UsersApi.getInstance().getUserByExternalId(externalId)
        return response as InternalUser
    }
)

const internalUsersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchInternalUsers.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchInternalUsers.fulfilled, (state, action) => {
            state.fetchStatus = 'succeeded'
            action.payload.forEach((item: InternalUser) => {
                if (item.id)
                    state.data[item.id] = item
            })
        })
        builder.addCase(fetchInternalUsers.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
        builder.addCase(fetchSingleInternalUser.pending, (state) => {
            state.singleFetchStatus = 'loading'
        })
        builder.addCase(fetchSingleInternalUser.fulfilled, (state, action) => {
            state.singleFetchStatus = 'succeeded'
            const item = action.payload
            if (item && item.id) {
                state.data[item.id] = item
            }
        })
        builder.addCase(fetchSingleInternalUser.rejected, (state, action) => {
            state.singleFetchStatus = 'failed'
            state.singleFetchError = action.error.message
        })
    }
  })

  export const {
    setFiltered
  } = internalUsersSlice.actions

  export default internalUsersSlice.reducer