import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/rootReducer'

import {ReactComponent as EditIcon} from 'bootstrap-icons/icons/pencil-square.svg'
import {ReactComponent as DeleteIcon} from 'bootstrap-icons/icons/x-circle.svg'
import Button from 'react-bootstrap/Button';

import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { ListGroup, Row, Col } from 'react-bootstrap';
import FarmerForm from './FarmerForm'

import UsersApi from '../../../api/usersApi';
import { farmerReducer, setMyFilterBy, setMySortBy, setFiltered } from '../../../state/farmerSlice';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';
type FarmerListProps = WithTranslation

const FarmerList: React.FC<FarmerListProps> = ({t}) => {
    
    const dispatch = useDispatch()
    const { user: authUser } = useSelector( (state: RootState) => state.authUser )
    
    const farmers = useSelector( (state: RootState) => state.farmers.farmer.data )
    const farmersByEmployeeId = useSelector( (state: RootState) => state.farmers.farmer.byEmployeeId )
    const employeesByCompanyId = useSelector( (state: RootState) => state.employees.byCompany )
    const companies = useSelector ((state: RootState) => state.companies.data)
    const farmersFiltered = useSelector( (state: RootState) => state.farmers.farmer.filtered )
    const pagination = useSelector( (state: RootState) => state.farmers.farmer.myPagination )

    const [farmerDelete, setFarmerDelete] = useState({id: 0, employee_id: authUser.employee_id, name: "", code: "", zip_code: "", city: "", country: "", active : true})

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPut, setShowPut] = useState(false);
  
    const handleClosePut = () => setShowPut(false);
    const handleShowPut = () => setShowPut(true);

    const [showDelete, setShowDelete] = useState(false);
  
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);

    const message = t('forms_errors.unexpected_error')

    const { FilterList, ListHeader, filterAndSortList } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "admin.farmers.columns.",
        onlyActive: true,
        fields: {
            name: {
                type: ["filter"],
                sortable: true
            },
            code: {
                type: ["filter"],
                sortable: true
            },
            zip_code: {
                type: ["filter"],
                sortable: true
            },
            city: {
                type: ["filter"],
                sortable: true
            },
            department: {
                type: ["filter"],
                sortable: true
            }
        },
        reducers: {
            filterBy: setMyFilterBy,
            sortBy: setMySortBy
        }
    })

    useEffect(() => {
        let idsToFilter = farmersByEmployeeId[authUser.employee_id] ? farmersByEmployeeId[authUser.employee_id] : []

        const authCompaniesLevelEntreprise = authUser.companies.map(c => companies[c.company_id]).filter(f => f.farmer_level === 'company')

        // add farmers of companies with farmer_level entreprise
        if (authCompaniesLevelEntreprise.length > 0){
          authCompaniesLevelEntreprise.forEach(
            c => {
                if (c.id && employeesByCompanyId[c.id]){
                    idsToFilter = idsToFilter.concat( employeesByCompanyId[c.id].map(eId => 
                        farmersByEmployeeId[eId] ? farmersByEmployeeId[eId] : []
                    ).reduce((p,c) => p.concat(c), []))
                }
            }
          )
        
        }

        const uniqIdsToFilter = [...new Set(idsToFilter)]

        const filteredList = filterAndSortList(
            uniqIdsToFilter.map(id => ({
                ...farmers[id], 
                department: farmers[id].country,
                all: farmers[id].code + farmers[id].name + farmers[id].zip_code + farmers[id].city + farmers[id].country
            }))
        )
        dispatch(setFiltered(filteredList))
    }, [pagination.filterBy, pagination.sortBy, farmers, farmersByEmployeeId, authUser.employee_id])


    const [farmerPut, setFarmerPut] = useState({id: 0, employee_id: authUser.employee_id, name: "", code: "", zip_code: "", city: "", country: "", active : true})

    function putFarmerOnClick(farmerPutParam: any){
        setFarmerPut(farmerPutParam)
        handleShowPut()
    }

    function launchDelete(farmer:any){
        return ((e: any) => {
            e.stopPropagation()
            setFarmerDelete(farmer)
            handleShowDelete()
        })
    }

    function deleteFarmerOnClick(){
        handleCloseDelete()
        UsersApi.getInstance().deleteFarmer(farmerDelete.id)
            .then((response) => {
                const farmerDisabled:any = {...farmerDelete}
                farmerDisabled.active = false
                dispatch(farmerReducer(farmerDisabled))
            })
            .catch((err) => {
                handleShow()
            })
    }

    return (
        <>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{t('error')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('buttons.close')}
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                <Modal.Title>{t('buttons.delete')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('admin.farmers.msg_delete')}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDelete}>
                    {t('buttons.cancel')}
                </Button>
                <Button variant="primary" onClick={deleteFarmerOnClick}>
                    {t('buttons.confirm')}
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showPut} onHide={handleClosePut}>
                <Modal.Header closeButton>
                <Modal.Title>{t('forms.farmers.title_modify')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FarmerForm farmerObject={farmerPut} />
                </Modal.Body>
            </Modal>

            {/* Display for mobile and tablet */}
            <Container className="d-lg-none mt-2 mb-2 p-0">
                {farmersFiltered.map(id => {
                    return (
                        <ListGroup key={id} horizontal className="text-center smalltext" onClick={putFarmerOnClick.bind(null, farmers[id])}>
                            <ListGroup.Item className="col-4 bg-light text-primary p-2 d-flex justify-content-center align-items-center">
                                <span className="fw-bold text-break" >{farmers[id].name}</span>
                            </ListGroup.Item>
                            <ListGroup.Item className="col-6 p-2 ">
                                <span className="text-size-75">{
                                    t('admin.farmers.columns.code') + " : " + farmers[id].code
                                }</span>
                                <br />
                                <span className="text-size-75">{
                                    farmers[id].zip_code + " - " + farmers[id].city
                                }</span>
                                <br />
                                <span className="text-size-75">{
                                    farmers[id].country
                                }</span>
                            </ListGroup.Item>
                            <ListGroup.Item className="col-2 p-2 d-flex justify-content-center align-items-center">
                                <Button className="p-0 pe-1 text-danger" variant="link" onClick={launchDelete(farmers[id])}>
                                    <DeleteIcon width="20px" height="100%" className="align-text-top"/>
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    )
                })}
            </Container>

            <Container className="mt-2">
                <Row className="d-none d-lg-flex">
                    <Col>
                        <FilterList />
                    </Col>
                </Row>
            </Container>

            <Table striped hover responsive="lg" className="mt-2 d-none d-lg-table bg-white text-center">
                <thead>
                    <tr>
                        <th className="p-1" />
                        <th><ListHeader name="name" /></th>
                        <th><ListHeader name="code" /></th>
                        <th><ListHeader name="zip_code" /></th>
                        <th><ListHeader name="city" /></th>
                        <th><ListHeader name="department" /></th>
                        <th>{t('admin.farmers.columns.actions')}</th>
                    </tr>
                </thead>
                
                    <tbody>
                        {farmersFiltered.map(id => {
                            return (
                                <tr key={id}>
                                    <td className="p-1 table-hover-hint" />
                                    <td className="text-primary fw-bold">{farmers[id].name}</td>
                                    <td>{farmers[id].code}</td>
                                    <td>{farmers[id].zip_code}</td>
                                    <td>{farmers[id].city}</td>
                                    <td>{farmers[id].country}</td>
                                    <td>
                                        <Button className="p-0 pe-1 text-secondary" variant="link" onClick={putFarmerOnClick.bind(null, farmers[id])}>
                                            <EditIcon width="24px" height="100%" className="align-text-top"/>
                                        </Button>
                                        <Button className="p-0 pe-1 text-danger" variant="link" onClick={launchDelete(farmers[id])}>
                                            <DeleteIcon width="24px" height="100%" className="align-text-top"/>
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
            </Table>
        </>
    )
}

export default withTranslation()(FarmerList)