import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import DashboardApi, { Preference, PreferenceDb, getKeyForPreferences } from "../api/dashboardApi";
import { Data } from "../types/types";

interface CurrentState {
    singlePreferences: Preference | null;
    singleFetchStatus: "idle" | "loading" | "succeeded" | "failed";
    singleFetchError: string | undefined;
    data: Data<PreferenceDb>;
    fetchStatus: "idle" | "loading" | "succeeded" | "failed";
    fetchError: string | undefined;
}

const initialState = {
    singlePreferences: null,
    singleFetchStatus: "idle",
    singleFetchError: undefined,
    data: {},
    fetchStatus: "idle",
    fetchError: undefined

} as CurrentState;

export const fetchDashboardPreferences = createAsyncThunk(
    "fetchDashboardPreferences",
    async ({company_id, export_code, version}: {company_id: number, export_code: string, version: number}) => { 

        const response = await DashboardApi.getInstance().getDashboardPreferences(company_id, export_code, version);
        return response as Preference;
    }
); 

export const fetchAllDashboardPreferences = createAsyncThunk(
    "fetchAllDashboardPreferences",
    async () => { 

        const response = await DashboardApi.getInstance().getAllDashboardPreferences();
        return response as PreferenceDb[];
    }
); 
    
const dashboardPreferencesSlice = createSlice({
    name: "dashboardPreferencesSlice",
    initialState,
    reducers: {
        resetSinglePreferences(state: CurrentState) {
            state.singlePreferences = null
            state.singleFetchStatus = "idle";
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchDashboardPreferences.pending, (state) => {
            state.singleFetchStatus = "loading";
        });
        builder.addCase(fetchDashboardPreferences.fulfilled, (state, action) => {
            state.singlePreferences = action.payload;
            state.singleFetchStatus = "succeeded";
        });
        builder.addCase(fetchDashboardPreferences.rejected, (state, action) => {
            state.singleFetchStatus = "failed";
            state.singleFetchError = action.error.message;
        });
        builder.addCase(fetchAllDashboardPreferences.pending, (state) => {
            state.fetchStatus = "loading";
        });
        builder.addCase(fetchAllDashboardPreferences.fulfilled, (state, action) => {
            action.payload.forEach((item: PreferenceDb) => {
                if (item) state.data[getKeyForPreferences(item)] = item
            });
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchAllDashboardPreferences.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
    },
});

export const {
    resetSinglePreferences
} = dashboardPreferencesSlice.actions;

export default dashboardPreferencesSlice.reducer;