import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import dbt from '../common/utils/dbTranslation';
import i18n from '../i18n';

import ControlPlanApi, {ControlPlan, StatusLabel} from '../api/controlPlanApi';
import { FilterBy, SortBy, Filter, TupleId, Data } from '../types/types';

interface Page {
    ids: Array<TupleId>
    fetching: boolean
}

interface Pagination {
    nbByPage: number
    nbPage: number
    currentPage: number
    filterBy: FilterBy
    sortBy: Array<SortBy>
    pages: Data<Page>
    count: number
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: string | undefined
}

export enum statusPdc {
    created = "created",
    published = "published",
    canceled = "canceled",
    closed = "closed"
  }

interface CurrentState {
    data: Data<Data<ControlPlan>>
    dataDA: Data<Data<ControlPlan>>
    pagination: Pagination
    samplesFilter: {
        filterBy: FilterBy
        sortBy: Array<SortBy>
    }
    defaultFilters: Data<FilterBy>
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchDAStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    deleteStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postAndPutStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    deleteError: string | undefined
    postAndPutError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
}

const initialState = {
    data: {},
    dataDA: {},
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filter: undefined,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    singleData: undefined,
    samplesFilter: {
        filterBy: {},
        sortBy: []
    },
    defaultFilters: {
        recentDone: {
            status: {
                in: ["published","created"]
            }
        }
    },
    fetchStatus: 'idle',
    fetchDAStatus: 'idle',
    deleteStatus: 'idle',
    postAndPutStatus: 'idle',
    fetchError: undefined,
    deleteError: undefined,
    postAndPutError: undefined,
    singleFetchStatus: 'idle',
    singleFetchError: undefined
} as CurrentState

export const fetchPdc = createAsyncThunk(
    'control-plan/fetchPdc', 
    async (args: {page: number}, thunkApi) => {

        const globalState: any = thunkApi.getState()
        const statusList: Array<string> = globalState.local.pdcStatus
        const statusLabels: Array<StatusLabel> = statusList.map(item => (
            {
                status: item,
                label: i18n.t('pdc.status.' + item)
            }
        ))

        const pagination: Pagination = globalState.pdc.pagination

        let response = null

        if(args.page === 0){
            response = await ControlPlanApi.getInstance().getPdc([], {}, [], 0, 0)
        }else{
            response = await ControlPlanApi.getInstance().getPdc(statusLabels, pagination.filterBy, pagination.sortBy, pagination.nbByPage, (args.page-1)*pagination.nbByPage)
        }

        return {curPage: args.page, ...response} as {count: number, curPage: number, data: Array<ControlPlan>}
    }
)

export const fetchPdcDA = createAsyncThunk(
    'control-plan/fetchPdcDA', 
    async (args: {page: number}, thunkApi) => {

        const globalState: any = thunkApi.getState()
        const statusList: Array<string> = globalState.local.pdcStatus
        const statusLabels: Array<StatusLabel> = statusList.map(item => (
            {
                status: item,
                label: i18n.t('pdc.status.' + item)
            }
        ))

        const pagination: Pagination = globalState.pdc.pagination

        let response = null

        if(args.page === 0){
            response = await ControlPlanApi.getInstance().getPdc([], {}, [], 0, 0)
        }else{
            response = await ControlPlanApi.getInstance().getPdc(statusLabels, pagination.filterBy, pagination.sortBy, pagination.nbByPage, (args.page-1)*pagination.nbByPage)
        }

        return {curPage: args.page, ...response} as {count: number, curPage: number, data: Array<ControlPlan>}
    }
)

export const fetchSinglePdc = createAsyncThunk(
    'control-plan/fetchSinglePdc', 
    async ({pdcId,laboratoryId} : {pdcId: number, laboratoryId: number}) => {
        const response = await ControlPlanApi.getInstance().getPdcById(pdcId, laboratoryId)
        return response as ControlPlan
    }
)


const filterField = (item: any, filter: Filter): boolean => {
    
    let result = true
    if (filter.like) {
        result = result && item.toString().toUpperCase().includes(filter.like.toUpperCase())
    } 

    let d: Date
    if (filter.gte) {
        if (item instanceof Date) {
            d = new Date(filter.gte)
            d.setHours(0, 0, 0, 0)
            item.setHours(0, 0, 0, 0)
            result = result && item >= d
        } else {
            result = result && item.toString().toUpperCase() >= filter.gte.toString().toUpperCase()
        }
    }
    if (filter.lte) {
        if (item instanceof Date) {
            d = new Date(filter.lte)
            d.setHours(0, 0, 0, 0)
            item.setHours(0, 0, 0, 0)
            result = result && item <= d
        } else {
            result = result && item.toString().toUpperCase() <= filter.lte.toString().toUpperCase()
        }
    }

    return result
}

function filterByGlobalAndFields(analysisArray: any[], filter: string | undefined, filterBy: FilterBy) {
    return analysisArray.filter(item =>
        (
            // Filter by global value
            !filter ||
            Object.keys(item).some(k => item[k] &&
                (filter && item[k].toString().toUpperCase().includes(filter.toUpperCase()) ) 
            ) 
        ) && (
            // Filter fields by field
            Object.keys(item).every(k => 
                !filterBy[k] || (
                    filterBy[k] && (
                        item[k] && ( 
                            filterField(item[k], filterBy[k]) 
                        )
                    )
                )
            )
        )
    );
}

const fieldSorter = (fields: Array<any>) => {
    return function (a: any, b: any) {
        return fields
            .map(function (o: any) {
                let dir = 1;
                if (o[0] === '-') {
                    dir = -1;
                }
                o = o.substring(1);
                if (a[o] !== null && b[o] !== null && typeof (a[o]) !== 'undefined' && typeof (b[o]) !== 'undefined') {
                    if (o === "id") {
                        if (a[o] > b[o]) return dir;
                        if (a[o] < b[o]) return -(dir);
                    } else {
                        if (a[o].toString().toUpperCase() > b[o].toString().toUpperCase()) return dir;
                        if (a[o].toString().toUpperCase() < b[o].toString().toUpperCase()) return -(dir);
                    }
                }
                return 0;
            })
            .reduce(function firstNonZeroValue(p: any, n: any) {
                return p ? p : n;
            }, 0);
    };
}

export const paginationReducer = createAsyncThunk(
    'control-plan/paginationReducer', 
    async (args: {page: number}, thunkApi): Promise<Array<any>> => {

        thunkApi.dispatch(requestPage(args.page))

        const globalState: any = thunkApi.getState()

        const language = globalState.local.language
        const pdcStatus = globalState.local.pdcStatus

        const companies = globalState.companies.data

        const pdc = globalState.pdc.data
        const filter = globalState.pdc.pagination.filter
        const filterBy: FilterBy = globalState.pdc.pagination.filterBy
        const sortBy: SortBy = globalState.pdc.pagination.sortBy

        let arrayPdc = []

        for (const key in pdc) {
            const Frontpdc: any = {}
            Frontpdc.id = pdc[key].id
            Frontpdc.name = dbt(pdc, pdc[key].name, language.id, "name")
            Frontpdc.status = typeof pdc[key].status === "undefined" || pdc[key].status === null || pdcStatus.indexOf(pdc[key].status) === -1 ? "" : pdc[key].status
            Frontpdc.statusTranslated = typeof pdc[key].status === "undefined" || pdc[key].status === null || pdcStatus.indexOf(pdc[key].status) === -1 ? "" : i18n.t('analysis.status.' + pdc[key].status)

            Frontpdc.start_date = new Date(pdc[key].start_date)
            Frontpdc.end_date = new Date(pdc[key].end_date)

            Frontpdc.company = typeof pdc[key].company_id === "undefined" || pdc[key].company_id === null ? "" : companies[pdc[key].company_id] === undefined || companies[pdc[key].company_id] === null ? "" : companies[pdc[key].company_id].name
            Frontpdc.last_update = new Date(pdc[key].last_update)
            Frontpdc.billable = pdc[key].billable
            Frontpdc.samples = pdc[key].samples_number
            Frontpdc.used = pdc[key].used_samples
            Frontpdc.tests = pdc[key].tests_number
            arrayPdc.push(Frontpdc)
        }

        arrayPdc = filterByGlobalAndFields(arrayPdc, filter, filterBy)

        const sortStringArray: string[] = Object.entries(sortBy).map(([name, value]) => value.direction + name)
        if (sortStringArray.length > 0) {
            arrayPdc.sort(fieldSorter(sortStringArray));
        } else {
            arrayPdc.sort((a: any, b: any) => (a.id < b.id) ? 1 : ((b.id < a.id) ? -1 : 0))
        }

        return arrayPdc
    }
)

const pdcSlice = createSlice({
    name: 'pdc',
    initialState,
    reducers: {
        pdcReducer(state: CurrentState, action: PayloadAction<ControlPlan>) {
            const item = action.payload
            if (item && item.id) {
                if (!state.data[item.laboratory_id]) {
                    state.data[item.laboratory_id] = {}
                }
                state.data[item.laboratory_id][item.id] = item
                if (!state.dataDA[item.laboratory_id]) {
                    state.dataDA[item.laboratory_id] = {}
                }
                state.dataDA[item.laboratory_id][item.id] = item
                state.postAndPutStatus = 'succeeded'
            }
        },
        filterByReducer(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy
            state.pagination.pages = {}
            state.pagination.currentPage = 1
        },
        addFilterByClause(state: CurrentState, action: PayloadAction<{name: string, filter: Filter}>) {
            if (Object.keys(action.payload.filter).length > 0) {
                state.pagination.filterBy[action.payload.name] = action.payload.filter
            } else {
                delete state.pagination.filterBy[action.payload.name]
            }
            state.pagination.pages = {}
            state.pagination.currentPage = 1
        },
        removeFilterByClauseByName(state: CurrentState, action: PayloadAction<string>) {
            delete state.pagination.filterBy[action.payload]
            state.pagination.pages = {}
            state.pagination.currentPage = 1
        },
        sortByReducer(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload
            state.pagination.pages = {}
            state.pagination.currentPage = 1
        },
        addSortByClause(state: CurrentState, action: PayloadAction<SortBy>) {
            const index = state.pagination.sortBy.findIndex(item => item.name === action.payload.name)
            if (index > -1) {
                state.pagination.sortBy[index] = action.payload
            } else {
                state.pagination.sortBy.push(action.payload)
            }
            state.pagination.pages = {}
            state.pagination.currentPage = 1
        },
        removeSortByClauseByName(state: CurrentState, action: PayloadAction<string>) {
            const index = state.pagination.sortBy.findIndex(item => item.name === action.payload)
            if (index > -1) {
                state.pagination.sortBy.splice(index, 1)
            }
            state.pagination.pages = {}
            state.pagination.currentPage = 1
        },
        filterReducer(state: CurrentState, action: PayloadAction<string|undefined>) {
            if (action.payload === "" || action.payload === undefined) {
                delete state.pagination.filterBy.all
            } else {
                state.pagination.filterBy.all = { like: action.payload }
            }
        },
        requestPage(state: CurrentState, action: PayloadAction<number>) {
            state.pagination.currentPage = action.payload
        },
        setNbByPage(state: CurrentState, action: PayloadAction<number>) {
            state.pagination.nbByPage = action.payload
        },
        singleFilterByReducer(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.samplesFilter.filterBy = filterBy
        },
        singleSortByReducer(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.samplesFilter.sortBy = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchPdc.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchPdc.fulfilled, (state, action) => {
            state.fetchStatus = 'succeeded'
            if(typeof action.payload !== "string"){
                action.payload.data.forEach((item: ControlPlan) => {
                    if (item.id) {
                        if (!state.data[item.laboratory_id]) {
                            state.data[item.laboratory_id] = {}
                        }
                        state.data[item.laboratory_id][item.id] = item
                    }
                })

                const count = action.payload.count
                state.pagination.count = count
                state.pagination.nbPage = Math.ceil(count / state.pagination.nbByPage)

                state.pagination.pages[action.payload.curPage] = {
                    ids: action.payload.data.map(item => ({labId: String(item.laboratory_id), id: String(item.id)})),
                    fetching: false
                }
                state.pagination.currentPage = action.payload.curPage
                
            }
        })
        builder.addCase(fetchPdc.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })

        builder.addCase(fetchPdcDA.pending, (state) => {
            state.fetchDAStatus = 'loading'
        })
        builder.addCase(fetchPdcDA.fulfilled, (state, action) => {
            state.fetchDAStatus = 'succeeded'
            if(typeof action.payload !== "string"){
                action.payload.data.forEach((item: ControlPlan) => {
                    if (item.id) {
                        if (!state.dataDA[item.laboratory_id]) {
                            state.dataDA[item.laboratory_id] = {}
                        }
                        state.dataDA[item.laboratory_id][item.id] = item
                    }
                })
            }
        })
        builder.addCase(fetchPdcDA.rejected, (state, action) => {
            state.fetchDAStatus = 'failed'
            state.fetchError = action.error.message
        })

        builder.addCase(fetchSinglePdc.pending, (state) => {
            state.singleFetchStatus = 'loading'
        })
        builder.addCase(fetchSinglePdc.fulfilled, (state, action) => {
            state.singleFetchStatus = 'succeeded'
            const item = action.payload
            if (item && item.id) {
                if (!state.data[item.laboratory_id]) {
                    state.data[item.laboratory_id] = {}
                }
                state.data[item.laboratory_id][item.id] = item
            }
        })
        builder.addCase(fetchSinglePdc.rejected, (state, action) => {
            state.singleFetchStatus = 'failed'
            state.singleFetchError = action.error.message
        })

        builder.addCase(paginationReducer.pending, (state) => {
            state.pagination.status = 'loading'
        })
        builder.addCase(paginationReducer.fulfilled, (state, action) => {
            
            state.pagination.pages = {}

            let curPage = 0
            if(typeof action.payload !== "string" && action.payload.length > 0){
                action.payload.forEach((item: ControlPlan, index: number) => {
                    if (index % state.pagination.nbByPage === 0) {
                        if (state.pagination.pages[curPage]) {
                            state.pagination.pages[curPage].fetching = false
                        }
                        curPage = (index / state.pagination.nbByPage) + 1 
                        state.pagination.pages[curPage] = {ids: [], fetching: true}
                    }
                    state.pagination.pages[curPage].ids.push({labId: String(item.laboratory_id), id: String(item.id)})
                })
                state.pagination.pages[curPage].fetching = false
            }
            state.pagination.status = 'succeeded'
            state.pagination.error = undefined
        })
        builder.addCase(paginationReducer.rejected, (state, action) => {
            state.pagination.status = 'failed'
            state.pagination.error = action.error.message
        })
    }
  })

  export const {
    pdcReducer,
    filterByReducer,
    addFilterByClause,
    removeFilterByClauseByName,
    sortByReducer,
    addSortByClause,
    removeSortByClauseByName,
    filterReducer,
    requestPage,
    setNbByPage,
    singleFilterByReducer,
    singleSortByReducer
  } = pdcSlice.actions

  export default pdcSlice.reducer