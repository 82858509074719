import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container, Form, Button, Modal, Row, Col, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import BarcodeReader from 'react-barcode-reader'

import { RootState } from '../../../../state/rootReducer';
import { getIn, useFormik, validateYupSchema, yupToFormErrors } from 'formik';
import Loader from '../../../../common/components/Loader';
import { ReactComponent as DeleteCircleRed } from '../../../../common/resources/DeleteCircleRed.svg'
import dbt from '../../../../common/utils/dbTranslation';
import { analysisReducer, StatusAnalysis, refreshAnalysis, filterByReducer } from '../../../../state/analysisSlice';
import AnalysisApi, { Analysis, AnalysisRequest, AnalysisPackage, AnalysisPackageTests } from '../../../../api/analysisApi';
import * as Yup from 'yup';
import GoBack from '../../../../common/components/GoBack';
import { Link, useHistory } from "react-router-dom";
import { Company, Employee, Farmer } from '../../../../api/usersApi';
import StatusBar from '../../../../common/components/StatusBar';
import userHasRequiredRoles from '../../../../common/auth/userHasRequiredRoles';
import Select from 'react-select'
import FarmerForm from '../../../farmer/components/FarmerForm'
import FarmerFormAdmin from '../../../administration/farmers/components/FarmerForm'
import ControlPlanApi from '../../../../api/controlPlanApi';
import { pdcReducer } from '../../../../state/pdcSlice';
import PcpwComponent from '../../../../common/components/PcpwComponent';
import pcpwUtils, { IPcpw } from '../../../../common/utils/pcpwUtils';
import ModalPackagesTests, { CheckStatus, IPackages } from '../../../../common/components/ModalPackagesTests';
import AutonomousField from '../../../../common/components/AutonomousField';

import StatusBarMobile from '../../../../common/components/StatusBarMobile';

import { ReactComponent as AddIcon } from 'bootstrap-icons/icons/plus.svg'
import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg'
import useViewport from '../../../../common/utils/useViewport';
import { Family, Form as CustomForm, FormFieldDocument, Laboratory, Pattern, Type, allowTheoreticalValueEnum, getAnalyteKey, PackagePattern, AnalyteTestPackage, AnalyteKey, Analyte } from '../../../../api/configApi';
import { CurrentUser, setAuthCurLabId } from '../../../../state/authUserSlice';
import { Data } from '../../../../types/types';
import CustomField from './components/CustomField';
import FormCheckBoxUrgent from './components/FormCheckboxUrgent'
import { AnalysisType } from '../../../../state/localSlice';
import GoBackButton from '../../../../common/components/GoBackButton';
import { Nir } from '../../../../api/externalServicesApi';
import useSecurityRoles from '../../../../common/auth/useSecurityRoles';
import useReduxFilter from '../../../../common/utils/useReduxFilter';
import { AnalysisInputResultStatus } from '../../../../api/analysisApi';
import { fetchExportRefAnalytes } from '../../../../state/refAnalyteSlice';
import { fetchProductDenominationsByLaboratory, refreshProductDenominations } from '../../../../state/productDenominationsSlice';
import PricesCalculator from '../../../pdc/helpers/PricesCalculator';
import { locale } from 'moment';
import { PatternPackageTestUtils } from '../../../pdc/helpers/PatternPackageTestsUtils';

interface EditAnalysisContainerProps extends WithTranslation {
    typeAnalysis?: AnalysisType,
    analysisDB?: Analysis,
    initStepParam?: string
}

type TypeFieldAnalysis = 'analysis' | 'additionalAnalysis'

const AnalysisForm: React.FC<EditAnalysisContainerProps> = ({ t, typeAnalysis, analysisDB, initStepParam }) => {
    let InitStep = Number(initStepParam)

    const dispatch = useDispatch()
    
    const { userHasRoleAdmin, userHasRoleAll, userHasRoleClientButNotCpOrReadOnly, userHasRoleClient, userHasRoleUser, userGrantUpdateRegistered, userGrantUpdateReceived, userGrantUpdateDone, userGrantFodder, userGrantFeed, userGrantRawMaterial } = useSecurityRoles()
    const isUserAdmin : boolean = userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])
    
    // user state
    const { user: authUser, authCompanies, authCustomers, authCurLabId } = useSelector((state: RootState) => state.authUser)

    const { lg } = useViewport()
    const mobile = !lg

    // Analysis state
    const analysis = useSelector((state: RootState) => state.analysis.analysis.data)

    // pdc state
    const controlPlans = useSelector((state: RootState) => state.pdc.data)
    const history = useHistory()

    // patterns state
    const patterns = useSelector((state: RootState) => state.config.patterns.data)

    // languages state
    const { language } = useSelector((state: RootState) => state.local)

    //  Laboratories state
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)

    const isLabAuthorizedForAnalysisType = (labId: number): boolean => {
        switch (typeAnalysis) {
            case 'FG':
                return userGrantFodder(labId, authUser)
            case 'FP':
                return userGrantFeed(labId, authUser)
            case 'RM':
                return userGrantRawMaterial(labId, authUser)
        }

        return false
    }

    //  Nir state
    const nirs = useSelector((state: RootState) => state.nir.data)

    // families state
    const families = useSelector((state: RootState) => state.config.families.data)

    // farmers state
    const farmers = useSelector((state: RootState) => state.farmers.farmer.data)

    // Package state
    const packages = useSelector((state: RootState) => state.config.packages.data)

    // Analyte Tests state
    const analytes = useSelector((state: RootState) => state.analytes.data)

    // analytes tests packages
    const analyteTestsPackages: Data<AnalyteTestPackage> = useSelector(
        (state: RootState) => state.analyteTestsPackages.data
        );

    //packages patterns
    const packagesPatterns: Data<PackagePattern> = useSelector(
        (state: RootState) => state.packagesPatterns.data
        );
    // Types
    const types = useSelector((state: RootState) => state.config.types.data)
    const typesByLabId = useSelector((state: RootState) => state.config.types.byLabId)

    // Employees state
    const employees = useSelector((state: RootState) => state.employees.data)
    const employeesByCompanyId = useSelector((state: RootState) => state.employees.byCompany)

    // company state
    const companies = useSelector((state: RootState) => state.companies.data)
    const companiesByLabId = useSelector((state: RootState) => state.companies.byLabId)

    // Impropers state
    const impropers = useSelector((state: RootState) => state.config.impropers.data)

    // Forms & fields state
    const forms = useSelector((state: RootState) => state.forms.data)
    const fields = useSelector((state: RootState) => state.fields.data)

    // patterns Families
    const patternsFamilies = useSelector((state: RootState) => state.patternsFamilies.data)

    // price lists state
    const priceLists = useSelector((state: RootState) => state.priceList.data);

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [apiError, setApiError] = useState<string | undefined>(undefined)

    const [analysisRep, setAnalysisRep] = useState<Analysis | undefined>(undefined);
    const [additionalAnalysisRep, setAdditionalAnalysisRep] = useState<Analysis | null | undefined>(undefined);


    const [showPackages, setShowPackages] = useState(false);
    const [showCargillAnalysis, setShowCargillAnalysis] = useState(analysisDB && analysisDB.additional_analysis && analysisDB.additional_analysis.length > 0 ? true : false);
    const [isNir, setIsNir] = useState(analysisDB && analysisDB.nir_code ? true : false);

    const [controlFamilyDetail, setControlFamilyDetail] = useState(false);

    const [triggerRefreshCustomFields, setTriggerRefreshCustomFields] = useState<boolean | null>(null);

    const [analysisPdcId, setAnalysisPdcId] = useState(0)

    const patternsPackageTestUtils = new PatternPackageTestUtils({
        analytes: analytes,
        analyteTestPackages: analyteTestsPackages,
        authUser: authUser,
        language: language,
        packages: packages,
        patterns: patterns,
        priceLists: priceLists
    })

    const [packagesData, setPackagesData] = useState<IPackages | undefined>(undefined)

    const getAuthorizedLabId = () => {
        const authorizedLabIds = Object.values(laboratories).filter(l => isLabAuthorizedForAnalysisType(l.id)).map(l => l.id)
        const currentLabId = authorizedLabIds.includes(authCurLabId) ? authCurLabId : authorizedLabIds[0]
        if (authCurLabId !== currentLabId) dispatch(setAuthCurLabId(currentLabId))
        return currentLabId
    }

    const getDefaultEmployeeFromCompany = (companyId: number, authUser: CurrentUser): number => {
        const employeesOfCompany = Object.values(employees)
                        .filter(item => (!item.disabled && item.companies.map(c => c.company_id).includes(companyId)))
        return employeesOfCompany.length === 1 ? Number(employeesOfCompany[0].id) : (employeesOfCompany.find(e => e.id === authUser.employee_id) ? authUser.employee_id : 0);
    }
    

    const customForm = Object.values(forms).find(f => f.laboratory_id === (analysisDB && analysisDB.laboratory_id || values && values.analysis.laboratory_id || getAuthorizedLabId()) && f.type_code === (analysisDB && analysisDB.type_code || values && values.analysis.type_code || typeAnalysis || 'FG'))

    const customFormAdditionalAnalysis: CustomForm | undefined = Object.values(forms).find(f => laboratories[analysisDB?.laboratory_id || getAuthorizedLabId()].lab_type === 'client' && f.laboratory_id === (((analysisDB && analysisDB.laboratory_id && laboratories[analysisDB.laboratory_id].ref_lab_id) || laboratories[getAuthorizedLabId()].ref_lab_id)) && f.type_code === (analysisDB && analysisDB.type_code || values && values.analysis.type_code || typeAnalysis || 'FG'))

    const getFlattenFields = (isDynamic: boolean, form: CustomForm) => {
        let flattenFields: number[] = []
        if (form) {
            // Flatten form's fields to initialize missing values in further steps
            const flattenClientFields = form.fields.client.map(line => line.filter(col => (fields[col.field_id].storage_type === "dynamic") === isDynamic))
                .reduce<number[]>((acc, cur) => {
                    const fieldIds = cur.map(c => c.field_id)
                    return acc.concat(fieldIds)
                }, [])
            const flattenSampleFields = form.fields.sample.map(line => line.filter(col => (fields[col.field_id].storage_type === "dynamic") === isDynamic))
                .reduce<number[]>((acc, cur) => {
                    const fieldIds = cur.map(c => c.field_id)
                    return acc.concat(fieldIds)
                }, [])
            flattenFields = flattenClientFields.concat(flattenSampleFields)
        }
        return flattenFields
    }

    const getFlattenDynamicFields = (form: CustomForm) => {
        return getFlattenFields(true, form)
    }

    const getFlattenStaticFields = (form: CustomForm) => {
        return getFlattenFields(false, form)
    }
    const getFlattenStaticAndDynamicFields = (form: CustomForm) => {
        return getFlattenDynamicFields(form).concat(getFlattenStaticFields(form))
    }

    const { goToWithFilter } = useReduxFilter()
    const { defaultFilters } = useSelector((state: RootState) => state.analysis.analysis)

    const getNirsOfCompany = (company_id: number) => {
        let nirsByCurrentAnalysisType = Object.values(types).filter(type => type.code === typeAnalysis).map(type => type.nir_types.map(t => t.nir_code)).flat(1)
        return Object.values(nirs).filter(nir => company_id && companies[company_id].nir_codes && companies[company_id].nir_codes.includes(nir.nir_code) && nirsByCurrentAnalysisType.includes(nir.nir_code));
    }



    const initAnalysis = (type?: AnalysisType, curAnalysis?: Analysis): { analysis: Analysis, additionalAnalysis: Analysis | null } => {

        const flattenFields = customForm ? getFlattenDynamicFields(customForm) : []

        const additionalFlattenFields = customFormAdditionalAnalysis ? getFlattenDynamicFields(customFormAdditionalAnalysis) : []


        // If analysis object provided, it is an edition. Return analysis object values with missing custom values initialization
        if (curAnalysis) {
            let additionalAnalysis: Analysis | null = null
            if (curAnalysis && curAnalysis.additional_analysis && curAnalysis.additional_analysis.length > 0) {
                const rawAdditionalAnalysis = analysis[curAnalysis.additional_analysis[0].ref_laboratory_id][curAnalysis.additional_analysis[0].ref_analysis_id]
                additionalAnalysis = {
                    ...rawAdditionalAnalysis,
                    custom_values: rawAdditionalAnalysis.custom_values.concat(
                        additionalFlattenFields.filter(fieldId => !rawAdditionalAnalysis.custom_values.map(cv => cv.field_id).includes(fieldId))
                            .map(fieldId => (
                                { field_id: fieldId, value: null }
                            ))
                    )
                }
            }

            const analysisAndAdditionalAnalysis =  Object.assign(
                {
                    analysis: {
                        ...curAnalysis,
                        analysis_packages: curAnalysis.analysis_packages.map(
                            ap => ({
                                ...ap,
                                analysis_package_tests: [...ap.analysis_package_tests]
                                    .sort((a: AnalysisPackageTests, b: AnalysisPackageTests) => 
                                        (PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup( {...a, laboratory_id: curAnalysis.laboratory_id}, analytes, language, false)).localeCompare(PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup( {...b, laboratory_id: curAnalysis.laboratory_id}, analytes, language, false)))

                            })
                        ),
                        custom_values: curAnalysis.custom_values.concat(
                            flattenFields.filter(fieldId => !curAnalysis.custom_values.map(cv => cv.field_id).includes(fieldId))
                                .map(fieldId => (
                                    { field_id: fieldId, value: null }
                                ))
                        )
                    },
                },
                additionalAnalysis ? { additionalAnalysis } : null
            )
            return analysisAndAdditionalAnalysis
        }

        const defaultLaboratoryId = getAuthorizedLabId()
        updateRights(defaultLaboratoryId)
        const authUserCompanies = getAuthUserCompanies(defaultLaboratoryId).map(c => ({ company_id: c.id as number, lab_type: laboratories[defaultLaboratoryId].lab_type === 'client' ? laboratories[c.client_laboratory_id as number].lab_type : laboratories[c.laboratory_id].lab_type }))


        const defaultCompanId = authUserCompanies.length === 1 ? authUserCompanies[0].company_id : 0
        const defaultCompanLabType = authUserCompanies.length === 1 ? authUserCompanies[0].lab_type : 0
 
        const defaultNirCode = defaultCompanId && defaultCompanLabType === 'client' ? (getNirsOfCompany(defaultCompanId)[0]?.nir_code || '') : ''
        // Get first sample group from analysis type
        const [defaultAnalysisType] = Object.values(types).filter(t => t.active && t.code === type && t.laboratory_id === defaultLaboratoryId && (laboratories[defaultLaboratoryId].lab_type !== 'client' || t.nir_types.length === 0 || !defaultNirCode || t.nir_types.find(nt => nt.nir_code === defaultNirCode)))

        // Return empty analysis with given sample type and default lab, requester, company and sample sub-type
        // All expected custom form's fields value are initialized
        
        const employeesOfCompany = Object.values(employees)
                    .filter(item => (!item.disabled && item.companies.map(c => c.company_id).includes(defaultCompanId)))

                    return {
            analysis: {
                id: 0,
                display_id: '',
                status: "",
                farmer_id: 0,
                silo: "",
                harvest_date: null,
                harvest_year: null,
                sample_comment: "",
                delivery_date: null,
                provider: "",
                provider_id: undefined,
                origin: "",
                origin_id: undefined,
                processing_id: undefined,
                lot: "",
                factory: "",
                manufacturing_date: null,
                farmer: "",
                formula_code: "",
                employee_id:  employeesOfCompany.length === 1 ? Number(employeesOfCompany[0].id) : (employeesOfCompany.find(e => e.id === authUser.employee_id) ? authUser.employee_id : 0),  
                laboratory_id: defaultLaboratoryId,
                company_id: defaultCompanId,
                nir_code: defaultNirCode,
                type_code: typeAnalysis || "FG",
                type_id: defaultAnalysisType ? defaultAnalysisType.id : 0,
                family_id: 0,
                family_details: "",
                pattern_id: 0,
                price: null,
                currency: '',
                cost: null,
                period: null,
                weight: null,
                client_reference: "",
                sample_date: null,
                creation_date: null,
                receipt_date: null,
                receipt_glims_date: null,
                conformity_date: null,
                validation_date: null,
                cancellation_date: null,
                denomination: "",
                denomination_id: undefined,
                comment: "",
                id_pdc: null,
                id_sample: null,
                analysis_packages: [],
                urgent: false,
                internal_status: '',
                input_result_status: AnalysisInputResultStatus.empty,
                input_result_status_date: undefined,
                input_analysis_results: [],
                custom_values: flattenFields.map(fieldId => (
                    { field_id: fieldId, value: null }
                )),
                transports: []
            },
            additionalAnalysis: null
        }
    }

    let NameTypeAnalysis = ""
    let familyRequiredError = ""
    let InitMinStep = 1
    let InitMaxStep = 1

    switch (analysisDB ? analysisDB.type_code : typeAnalysis) {
        case "FG":
            NameTypeAnalysis = t('forms.analysis.FG')
            familyRequiredError = t("forms_errors.analysis.fodder_required")
            if (InitStep === 1 || initStepParam === undefined) InitStep = 1
            InitMinStep = 1
            InitMaxStep = 3
            break;
        case "RM":
            NameTypeAnalysis = t('forms.analysis.RM')
            familyRequiredError = t("forms_errors.analysis.raw_material_required")
            if (InitStep === 1 || initStepParam === undefined) InitStep = 1
            InitMinStep = 1
            InitMaxStep = 3
            break;
        case "FP":
            NameTypeAnalysis = t('forms.analysis.FP')
            familyRequiredError = t("forms_errors.analysis.feed_required")
            if (InitStep === 1 || initStepParam === undefined) InitStep = 1
            InitMinStep = 1
            InitMaxStep = 3
            break;
    }

    const [step, setstep] = useState(InitStep);
    const [minStep] = useState(InitMinStep);
    const [maxStep] = useState(InitMaxStep);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const customFormValidationSchema = (form: CustomForm | undefined): Data<any> => {
        if (form) {
            const maxDate = new Date(9999, 12, 31);
            // Flatten required form's fields
            const flattenRequiredClientFields = form.fields.client.map(line => line.filter(col => col.required))
                .reduce<number[]>((acc, cur) => {
                    const fieldIds = cur.map(c => c.field_id)
                    return acc.concat(fieldIds)
                }, [])
            // Flatten form's fields
            const flattenClientFields = form.fields.client.map(line => line)
                .reduce<number[]>((acc, cur) => {
                    const fieldIds = cur.map(c => c.field_id)
                    return acc.concat(fieldIds)
                }, [])
            // Flatten required sample fields
            const flattenRequiredSampleFields = form.fields.sample.map(line => line.filter(col => col.required))
                .reduce<number[]>((acc, cur) => {
                    const fieldIds = cur.map(c => c.field_id)
                    return acc.concat(fieldIds)
                }, [])
            // Flatten sample fields
            const flattenSampleFields = form.fields.sample.map(line => line)
                .reduce<number[]>((acc, cur) => {
                    const fieldIds = cur.map(c => c.field_id)
                    return acc.concat(fieldIds)
                }, [])
            const flattenRequiredFields = flattenRequiredClientFields.concat(flattenRequiredSampleFields)
            const flattenFieldsDoc = flattenClientFields.concat(flattenSampleFields)
            // Assert that custom values contains all dynamic fields with valid value
            const dynamicFields = flattenFieldsDoc.filter(id => fields[id].storage_type === "dynamic")
            const requiredDynamicFields = flattenRequiredFields.filter(id => fields[id].storage_type === "dynamic")
            const customValues = {
                custom_values: Yup.array().of(
                    Yup.object().shape(
                        {
                            field_id: Yup.number(),
                            value: Yup.string().nullable()
                                .test("required",
                                    (v, context) => {
                                        const id = Number(context.parent.field_id)
                                        const isRequired = requiredDynamicFields.includes(id)
                                        if (isRequired && v && v !== "" || !isRequired) {
                                            return true
                                        } else {
                                            return context.createError({ path: context.path, message: t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }) })
                                        }
                                    }
                                ).test("date",
                                    (v, context) => {
                                        const id = Number(context.parent.field_id)
                                        if (fields[id].data_type === "date" && v && new Date(v) > maxDate) {
                                            return context.createError({ path: context.path, message: t('forms_errors.field_date_max', { field: dbt(fields, id, language.id, "name") }) })
                                        }else {
                                            return true
                                        }
                                    }
                                )
                        }
                    )
                )
            }
            // Assert that all static fields values are provided
            const staticFieldsDoc = flattenFieldsDoc.filter(id => fields[id].storage_type !== "dynamic")
            const fieldDoc = form.fields.client.concat(form.fields.sample).reduce((a, c) => a.concat(c),[])
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const staticFields = staticFieldsDoc.reduce<Data<any>>((acc, id) => {
                const formFieldDoc = fieldDoc.find( e => e.field_id === id)
                const field = fields[id]
                if (field.storage_name) {
                    let schema
                    switch (field.data_type) {
                        case "string":
                            if (formFieldDoc?.required === true){
                                schema = Yup.string()
                                    .required(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                    .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                            }else{
                                schema = Yup.string().nullable()
                                    .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                            }
                            break;
                        case "number":
                            if (formFieldDoc?.required === true){
                                if (field.name === "Havest year"){
                                    schema = Yup.string()
                                        .required(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                        .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                        .length(4, t('forms_errors.harvest_year'))
                                }else{
                                schema = Yup.string()
                                    .required(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                    .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                }
                            }else{
                                if (field.name === "Havest year"){
                                    schema = Yup.string().nullable()
                                        .length(4, t('forms_errors.harvest_year'))
                                }else{
                                schema = Yup.string().nullable()
                                    .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                }
                            }
                            break;
                        case "datalist":
                            if (field.storage_type === "static_id") {
                                schema = Yup.number().nullable()
                                    .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                    .notOneOf([0], t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                
                            }else {
                                if (formFieldDoc?.required === true){
                                    schema = Yup.string()
                                        .required(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                        .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                }else{
                                    schema = Yup.string().nullable()
                                        .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                }}
                            break;
                        case "date":
                                if (formFieldDoc?.required === true){
                                    schema = Yup.date()
                                        .required(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                        .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                        .max(maxDate , t("forms_errors.field_date_max", { field: dbt(fields, id, language.id, "name") }))
                                }else{
                                    schema = Yup.date().nullable()
                                        .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                        .max(maxDate , t("forms_errors.field_date_max", { field: dbt(fields, id, language.id, "name") }))
                                }
                            break;
                        case "custom":
                            switch (field.input_name) {
                                case "transports":
                                    if (formFieldDoc?.required === true){
                                        Yup.array().of(Yup.object().shape({
                                            transport_id: Yup.number()
                                                .required(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                                .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                                .notOneOf([0], t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") })),
                                            distance: Yup.number()
                                                .required(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                                .typeError(t('forms_errors.field_required', { field: t('forms.analysis.transports_distance') }))
                                        }))
                                        }else{
                                            schema = Yup.array().of(Yup.object().shape({
                                                transport_id: Yup.number().nullable()
                                                    .typeError(t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }))
                                                    .notOneOf([0], t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") })),
                                                distance: Yup.number().nullable()
                                                    .typeError(t('forms_errors.field_required', { field: t('forms.analysis.transports_distance') }))
                                            }))
                                                .required(t('forms_errors.at_least_one_required', { field: dbt(fields, id, language.id, "name") }))
                                                .min(1, t('forms_errors.at_least_one_required', { field: dbt(fields, id, language.id, "name") }))
                                        }
                                    break;
                                 case "farmers":
                                    if (formFieldDoc?.required === true){
                                        schema = Yup.string().nullable()
                                            .test("required",
                                                (v, context) => {
                                                    if (context.parent.farmer_id && context.parent.farmer_id !== "") {
                                                        return true
                                                    } else {
                                                        return context.createError({ path: "analysis.farmer_id", message: t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }) })
                                                    }
                                                }
                                            )
                                    }
                                    break;
                                case "product_denomination":
                                    if (formFieldDoc?.required === true){
                                        schema = Yup.string().nullable()
                                            .test("required",
                                                (v, context) => {
                                                    if (context.parent.denomination_id && context.parent.denomination_id !== "") {
                                                        return true
                                                    } else {
                                                        return context.createError({ path: "analysis.denomination_id", message: t('forms_errors.field_required', { field: dbt(fields, id, language.id, "name") }) })
                                                    }
                                                }
                                        )
                                    }
                                    break;
                            }
                    }
                    acc[field.storage_name] = schema
                }
                return acc
            }, {})

            return {
                ...staticFields,
                ...customValues
            }
        } 
        return {}

    }

    interface IValues {
        analysis: Analysis,
        additionalAnalysis: Analysis | null
    }

    // calcul du prix de l'analyse
    const getPriceBaseAnalysis = (analysisValues: Analysis) => {
        if (patterns[analysisValues.pattern_id]) {
            const patternPrice = PricesCalculator.getPrice(priceLists, analysisValues.company_id,patterns[analysisValues.pattern_id])
            return getPriceAugmenteSiUrgent(patternPrice, analysisValues);
        } else {
            return 0
        }
    }

    // Changement du statut (urgent ou non)
    const changeStatutUrgent = async (e: React.ChangeEvent<HTMLInputElement>, analysis: Analysis, analysisField: TypeFieldAnalysis) => {
        if (analysisField === 'analysis') {
            values.analysis.urgent = e.target.checked
        }

        else if (analysisField === 'additionalAnalysis') {
            if (values.additionalAnalysis) {
                values.additionalAnalysis.urgent = e.target.checked
            }
        }

        if (patterns[analysis.pattern_id]) {
            setFieldValue(`${analysisField}.price`, PricesCalculator.getPrice(priceLists, analysis.company_id, patterns[analysis.pattern_id]));
            const analysis_packages = Object.values(analysis.analysis_packages)
                .map(pVal => ({
                    ...pVal,
                    price: pVal.price ? getPriceAugmenteSiUrgent(pVal.price, analysis): 0,
                    analysis_package_tests: Object.values(pVal.analysis_package_tests)
                        .map(tVal => ({
                            ...tVal,
                            price: tVal.price ?  getPriceAugmenteSiUrgent(tVal.price, analysis) : 0
                        }))
                }));

            setFieldValue(`${analysisField}.analysis_packages`, analysis_packages);

            const updatedAnalysis = {
                ...analysis,
                price: getPriceBaseAnalysis(analysis),
            }
            if (analysisField === 'analysis') {
                setTotalPcpwBase({
                    price: pcpwUtils.calculatePatternPrice(updatedAnalysis, analysis_packages),
                    period: pcpwUtils.calculatePatternPeriod(updatedAnalysis, analysis_packages),
                    weight: pcpwUtils.calculatePatternWeight(updatedAnalysis, analysis_packages)
                });
            }
            else if (analysisField === 'additionalAnalysis') {
                setTotalPcpwAdditional({
                    price: pcpwUtils.calculatePatternPrice(updatedAnalysis, analysis_packages),
                    period: pcpwUtils.calculatePatternPeriod(updatedAnalysis, analysis_packages),
                    weight: pcpwUtils.calculatePatternWeight(updatedAnalysis, analysis_packages)
                });
            }

        } else {
            setFieldValue(`${analysisField}.pattern_id`, undefined);
        }

    }




    const changeStatutUrgentBase = (e: React.ChangeEvent<HTMLInputElement>) => {
        changeStatutUrgent(e, values.analysis, 'analysis')
    }

    const changeStatutUrgentAdditional = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (values.additionalAnalysis) {
            changeStatutUrgent(e, values.additionalAnalysis, 'additionalAnalysis')
        }
    }

    const getAnalysisRequest = (analysis: Analysis, analysisPackages: AnalysisPackage[]) : AnalysisRequest => {
        const analysisRequest: AnalysisRequest = {
            farmer_id: analysis.farmer_id ? analysis.farmer_id : undefined,
            silo: analysis.silo || "",
            harvest_date: analysis.harvest_date ? analysis.harvest_date : undefined,
            harvest_year: analysis.harvest_year ? analysis.harvest_year : undefined,
            sample_comment: analysis.sample_comment,
            delivery_date: analysis.delivery_date ? analysis.delivery_date : undefined,
            provider: analysis.provider,
            provider_id: analysis.provider_id,
            origin: analysis.origin,
            origin_id: analysis.origin_id,
            lot: analysis.lot,
            factory: analysis.factory,
            manufacturing_date: analysis.manufacturing_date ? analysis.manufacturing_date : undefined,
            farmer: analysis.farmer,
            formula_code: analysis.formula_code,
            employee_id: analysis.employee_id,
            company_id: analysis.company_id,
            type_code: analysis.type_code,
            type_id: analysis.type_id,
            family_id: analysis.family_id,
            family_details: analysis.family_details,
            pattern_id: analysis.pattern_id ? analysis.pattern_id : null,
            client_reference: analysis.client_reference,
            sample_date: analysis.sample_date ? new Date(analysis.sample_date) : undefined,
            denomination: analysis.denomination,
            denomination_id: analysis.denomination_id ? analysis.denomination_id : undefined,
            comment: analysis.comment ? analysis.comment : "",
            id_pdc: analysis.id_pdc || undefined,
            id_sample: analysis.id_sample || undefined,
            analysis_packages: analysisPackages,
            price: values.additionalAnalysis ? 0 : getPriceBaseAnalysis(analysis),
            currency: analysis.currency,
            period: analysis.pattern_id ? patterns[analysis.pattern_id].period : 0,
            weight: analysis.pattern_id ? patterns[analysis.pattern_id].weight : 0,
            urgent: analysis.urgent,
            custom_values: analysis.custom_values || [],
            transports: analysis.transports || [],
            nir_code: analysis.nir_code ? analysis.nir_code : null,
            additional_analysis: [],
            processing_id: values.analysis.processing_id
        }

        return analysisRequest
    }


    const formikProps = useFormik<IValues>({
        initialValues: initAnalysis(typeAnalysis, analysisDB),

        validate: (values) => { // validation use values so we can't use validationSchema
            try {
                validateYupSchema(values, validationSchema, true, values);
            } catch (err) {
                return yupToFormErrors(err); //for rendering validation errors
            }

            return {};
        },

        onSubmit: async (values, { setSubmitting }) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true)
            setApiError(undefined)

            const analysis_packages: AnalysisPackage[] = []
            values.analysis.analysis_packages.forEach((arrayItemPackage) => {
                if (arrayItemPackage.analysis_package_tests.filter((obj) => isAnalyteChecked(arrayItemPackage.package_id, obj.code_test, obj.code_analyte, values.analysis) === 'checked').length > 0 || (arrayItemPackage.analysis_package_tests.length === 0 && isPackageChecked(arrayItemPackage.package_id, values.analysis) === 'checked')) {
                    const aPackage: AnalysisPackage = {
                        package_id: Number(arrayItemPackage.package_id),
                        comment: arrayItemPackage.comment,
                        price: arrayItemPackage.price,
                        currency: arrayItemPackage.currency,
                        period: arrayItemPackage.period,
                        weight: arrayItemPackage.weight,
                        analysis_package_tests: []
                    }
                    arrayItemPackage.analysis_package_tests.forEach((arrayItemTest) => {
                        if (isAnalyteChecked(arrayItemPackage.package_id, arrayItemTest.code_test, arrayItemTest.code_analyte, values.analysis) === 'checked') {
                            const aTest: AnalysisPackageTests = {
                                code_test: arrayItemTest.code_test,
                                code_analyte: arrayItemTest.code_analyte,
                                analysis_group_name: arrayItemTest.analysis_group_name,
                                theoretical_value: arrayItemTest.theoretical_value,
                                price: arrayItemTest.price,
                                currency: arrayItemTest.currency,
                                period: arrayItemTest.period,
                                weight: arrayItemTest.weight,
                                unit: arrayItemTest.unit
                            }
                            aPackage.analysis_package_tests.push(aTest)
                        }
                    })
                    analysis_packages.push(aPackage)
                }
            })

            // if no additional analysis and no nir, this one is the cargill analysis, if nir this one is the nir one
            const analysis: AnalysisRequest = getAnalysisRequest(values.analysis, analysis_packages)

            let additionalAnalysis: AnalysisRequest | null = null

            // if additional analysis, this one is the cargill analysis
            if (values.additionalAnalysis) {
                additionalAnalysis = getAnalysisRequest(values.additionalAnalysis, [])
            }

            try {
                if (values.analysis.id !== 0) {
                    if (additionalAnalysis && values.additionalAnalysis) {
                        // if additional
                        const refLabId = laboratories[values.additionalAnalysis.laboratory_id].ref_lab_id
                        const parentLab: number = refLabId ? refLabId : values.additionalAnalysis.laboratory_id

                        let updatedAdditionalAnalysis

                        if (analysisDB?.additional_analysis && analysisDB.additional_analysis.length > 0 && analysisDB.additional_analysis[0].ref_analysis_id) {
                            updatedAdditionalAnalysis = await AnalysisApi.getInstance().updateAnalysis(additionalAnalysis, analysisDB.additional_analysis[0].ref_laboratory_id, analysisDB.additional_analysis[0].ref_analysis_id)
                        }
                        else {
                            updatedAdditionalAnalysis = await AnalysisApi.getInstance().postAnalysis(parentLab, additionalAnalysis)
                        }

                        analysis.additional_analysis = [{ ref_laboratory_id: updatedAdditionalAnalysis.laboratory_id, ref_analysis_id: updatedAdditionalAnalysis.id }]

                        const updatedAnalysis = await AnalysisApi.getInstance().updateAnalysis(analysis, values.analysis.laboratory_id, values.analysis.id)

                        setAnalysisRep(updatedAnalysis)
                        setAdditionalAnalysisRep(updatedAdditionalAnalysis)
                        dispatch(analysisReducer(updatedAdditionalAnalysis))
                        dispatch(analysisReducer(updatedAnalysis))
                        setFormSubmitted(true)
                    } else {
                        // if no additional
                        const updatedAnalysis = await AnalysisApi.getInstance().updateAnalysis(analysis, values.analysis.laboratory_id, values.analysis.id)

                        setAnalysisRep(updatedAnalysis)
                        dispatch(analysisReducer(updatedAnalysis))
                        setFormSubmitted(true)
                    }
                } else if (values.analysis.id_pdc !== null && values.analysis.id_sample !== null) {
                    try{
                        const updatedAnalysis = await AnalysisApi.getInstance().postAnalysis(values.analysis.laboratory_id, analysis)
                        const updatedPdc = await ControlPlanApi.getInstance().getPdcById(values.analysis.id_pdc, values.analysis.laboratory_id)

                        setAnalysisRep(updatedAnalysis)
                        setAnalysisPdcId(updatedAnalysis.id)
                        dispatch(analysisReducer(updatedAnalysis))
                        dispatch(pdcReducer(updatedPdc))
                        dispatch(refreshAnalysis())
                        setFormSubmitted(true)
                    } catch (err) {
                        throw err
                    }
                } else {
                    if (additionalAnalysis && values.additionalAnalysis) {
                        // if additional
                        const refLabId = laboratories[values.additionalAnalysis.laboratory_id].ref_lab_id
                        const parentLab: number = refLabId ? refLabId : values.additionalAnalysis.laboratory_id
                        const updatedAdditionalAnalysis = await AnalysisApi.getInstance().postAnalysis(parentLab, additionalAnalysis)

                        analysis.additional_analysis = [{ ref_laboratory_id: updatedAdditionalAnalysis.laboratory_id, ref_analysis_id: updatedAdditionalAnalysis.id }]

                        const updatedAnalysis = await AnalysisApi.getInstance().postAnalysis(values.analysis.laboratory_id, analysis)

                        setAnalysisRep(updatedAnalysis)
                        setAdditionalAnalysisRep(updatedAdditionalAnalysis)
                        dispatch(analysisReducer(updatedAdditionalAnalysis))
                        dispatch(analysisReducer(updatedAnalysis))
                        dispatch(refreshAnalysis())
                        setFormSubmitted(true)
                    } else {
                        // if no additional
                        const updatedAnalysis = await AnalysisApi.getInstance().postAnalysis(values.analysis.laboratory_id, analysis)
                        setAnalysisRep(updatedAnalysis)
                        dispatch(analysisReducer(updatedAnalysis))
                        dispatch(refreshAnalysis())
                        setFormSubmitted(true)
                    }
                }
            } catch (err) {
                console.error('Error while creating analysis', err)
                const message = err instanceof Error ? err.message : ""
                setApiError(t('forms_errors.unexpected_error') + "(" + message + ")")
            } finally {
                setSubmitting(false)
            }
        }
    })

    const { initialValues,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setValues,
        setFieldValue,
        setFieldTouched,
        validateForm,
        resetForm,
        isSubmitting } = formikProps

    const [labId, setLabId] = useState(values.analysis.laboratory_id)

    const validationSchema = Yup.object().shape({
        analysis: Yup.object().shape({
            ...((!showCargillAnalysis && laboratories[labId].lab_type === 'client') && {
            nir_code: Yup.string()
                .required(t("forms_errors.analysis.nir_required")),
            }),
            employee_id: Yup.number()
                .notOneOf([0], t("forms_errors.analysis.technician_required"))
                .required(t("forms_errors.analysis.technician_required")),
            company_id: Yup.number()
                .notOneOf([0], t("forms_errors.analysis.company_required"))
                .required(t("forms_errors.analysis.company_required")),
            
            analysis_packages: Yup.array().of(Yup.object().shape({
                analysis_package_tests: Yup.array().of(Yup.object().shape({
                    theoretical_value: Yup.number().nullable(true)
                        .test("required",
                            (v, context) => {
                                const id = getAnalyteKey({
                                    code_test: context.parent.code_test,
                                    code_analyte: context.parent.code_analyte,
                                    laboratory_id: values.analysis.laboratory_id
                                })
                                
                                const isRequired = analytes[id].allow_theoretical_value === allowTheoreticalValueEnum.MANDATORY 
                                if (isRequired && v != null || !isRequired) {
                                    return true
                                } else {
                                    return context.createError({ path: context.path, message: t("forms_errors.analysis.theoretical_value_required") })
                                }
                            })
                    }))
                    .test({
                        message: t("forms_errors.analysis.no_tests"),
                        test: arr => (( arr && arr?.length > 0 ) || (!values.analysis.pattern_id || !!values.analysis.pattern_id && !patterns[values.analysis.pattern_id].package_test_mandatory))
                    })
                }))
                .test({
                    message: t("forms_errors.analysis.no_tests"),
                    test: arr => (( arr && arr?.length > 0 ) || (!values.analysis.pattern_id || !!values.analysis.pattern_id && !patterns[values.analysis.pattern_id].package_test_mandatory))
                }), 
            family_id: Yup.number()
                .notOneOf([0], familyRequiredError)
                .required(familyRequiredError),
            ...((!showCargillAnalysis && laboratories[labId].lab_type === 'cargill') && {
                pattern_id: Yup.number()
                    .notOneOf([0], t("forms_errors.analysis.pattern_required"))
                    .required(t("forms_errors.analysis.pattern_required")),
            }),
            ...(controlFamilyDetail === true && {
                family_details: Yup.string()
                    .required(t("forms_errors.analysis.family_details_required"))
            }),
            ...(customFormValidationSchema(customForm)),
        }),
        ...((showCargillAnalysis) && {
            additionalAnalysis: Yup.object().shape({
                employee_id: Yup.number()
                    .notOneOf([0], t("forms_errors.analysis.technician_required"))
                    .required(t("forms_errors.analysis.technician_required")),
                company_id: Yup.number()
                    .notOneOf([0], t("forms_errors.analysis.company_required"))
                    .required(t("forms_errors.analysis.company_required")),
                analysis_packages: Yup.array().of(Yup.object().shape({
                    analysis_package_tests: Yup.array().of(Yup.object().shape({
                        theoretical_value: Yup.number().nullable(true)
                            .test("required",
                                (v, context) => {
                                    const id = getAnalyteKey({
                                        code_test: context.parent.code_test,
                                        code_analyte: context.parent.code_analyte,
                                        laboratory_id: values.analysis.laboratory_id
                                    })
                                    const isRequired = analytes[id].allow_theoretical_value === allowTheoreticalValueEnum.MANDATORY 
                                    if (isRequired && v != null || !isRequired) {
                                        return true
                                    } else {
                                        return context.createError({ path: context.path, message: t("forms_errors.analysis.theoretical_value_required") })
                                    }
                                })
                        }))
                    })),    
                family_id: Yup.number()
                    .notOneOf([0], familyRequiredError)
                    .required(familyRequiredError),
                pattern_id: Yup.number()
                    .notOneOf([0], t("forms_errors.analysis.pattern_required"))
                    .required(t("forms_errors.analysis.pattern_required")),
                ...(controlFamilyDetail === true && {
                    family_details: Yup.string()
                        .required(t("forms_errors.analysis.family_details_required"))
                }),
                ...(customFormValidationSchema(customFormAdditionalAnalysis)),
            }),
        }),

    })

    const getStatePcpw = (analysis: Analysis | null): IPcpw => ({
        price: analysis ? (analysis.total_price || pcpwUtils.calculatePatternPrice(analysis, analysis.analysis_packages)) : 0,
        period: analysis ? (analysis.max_period || pcpwUtils.calculatePatternPeriod(analysis, analysis.analysis_packages)) : 0,
        weight: analysis ? (analysis.total_weight || pcpwUtils.calculatePatternWeight(analysis, analysis.analysis_packages)) : 0

    })


    const [totalPcpwBase, setTotalPcpwBase] = useState<IPcpw>(getStatePcpw(values.analysis))

    const [totalPcpwAdditional, setTotalPcpwAdditional] = useState<IPcpw>(getStatePcpw(values.additionalAnalysis))

    let hasRoleAll = false
    let hasRoleAdmin = false
    let hasRoleClient = false
    let hasRoleUser = false
    let hasRoleClientButNotCpOrReadOnly = false
    
    let grantUpdateRegistered = false

    let grantUpdateReceived = false

    let grantUpdateDone = false

    updateRights(values.analysis.laboratory_id);

    const handleClosePut = () => {
        if (analysisRep) {
            const duplicationAnalysis: Analysis = { ...analysisRep };
            let duplicationAdditionalAnalysis: Analysis | null = null
            if (additionalAnalysisRep) {
                duplicationAdditionalAnalysis = { ...additionalAnalysisRep }
            }
            duplicationAnalysis.id = 0;
            resetForm({ values: { analysis: { ...duplicationAnalysis }, additionalAnalysis: duplicationAdditionalAnalysis } })
            setFormSubmitted(false);
        }
    }

    const handleShowPackages = (analysis: Analysis) => {
   
        setPackagesData(patternsPackageTestUtils.getPackageData(
            analysis,
            'analysis',
            patternsPackageTestUtils.convertAnalysisPackageToBasePackage(analysis.analysis_packages),
            analysis.laboratory_id,
            analysis.company_id
        ))
        setShowPackages(true)
    }

    const handleShowCargillAnalysis = () => {
        const flattenDynamicFields = customFormAdditionalAnalysis ? getFlattenDynamicFields(customFormAdditionalAnalysis): []
        const analysis: Analysis = {
            id: 0,
            display_id: values.analysis.display_id,
            employee_id: values.analysis.employee_id,
            company_id: values.analysis.company_id,
            farmer_id: values.analysis.farmer_id,
            laboratory_id: laboratories[values.analysis.laboratory_id].ref_lab_id || 0,
            type_code: values.analysis.type_code,
            type_id: types[values.analysis.type_id].ref_type_id || 0,
            family_id: families[values.analysis.family_id] && families[values.analysis.family_id].ref_family_id || 0,
            family_details: '',
            pattern_id: 0,
            client_reference: '',
            sample_date: null,
            denomination: '',
            denomination_id: undefined,
            client_sample_id: undefined,
            comment: '',
            status: '',
            creation_date: null,
            receipt_date: null,
            receipt_glims_date: null,
            conformity_date: null,
            validation_date: null,
            cancellation_date: null,
            analysis_packages: [],
            id_pdc: null,
            id_sample: null,
            price: null,
            currency: '',
            cost: null,
            period: null,
            weight: null,
            urgent: false,
            nir_code: '',
            internal_status: '',
            input_result_status: AnalysisInputResultStatus.empty,
            input_result_status_date: undefined,
            input_analysis_results: [],
            custom_values: flattenDynamicFields.map(fieldId => (
                { field_id: fieldId, value: null }
            )),
            transports: [],
            silo: '',
            harvest_date: null,
            harvest_year: null,
            sample_comment: '',
            delivery_date: null,
            provider: '',
            provider_id: undefined,
            origin: '',
            origin_id: undefined,
            lot: '',
            factory: '',
            manufacturing_date: null,
            farmer: '',
            formula_code: '',
            processing_id: undefined,
        }

        // add custom values of client analysis if they are in cargill analysis
        if (customForm && customFormAdditionalAnalysis) {
            values.analysis.custom_values.filter(f => flattenDynamicFields.includes(f.field_id)).forEach(
                c => {
                    analysis.custom_values = analysis.custom_values.filter(c2 => c2.field_id !== c.field_id)
                    analysis.custom_values.push(c)
                }
            )

            // fill static fields of additional analysis
            const fieldsStatic = getFlattenStaticFields(customFormAdditionalAnalysis);

            
            fieldsStatic.forEach(
                f => {
                    if (fields[f] && fields[f].storage_name && analysis) {
                        if (Object.keys(analysis).includes(fields[f].storage_name as string)) {
                            const field: (keyof Analysis) = fields[f].storage_name as keyof Analysis
                            if (values.analysis[field]) {
                                analysis[field] = values.analysis[field] as never;
                            }
                        }
                    }
                }
            )



        }

        setFieldValue('additionalAnalysis', analysis)
        setShowCargillAnalysis(true)
    }

    const handleCloseCargillAnalysis = () => {
        setFieldValue('additionalAnalysis', null)
        setShowCargillAnalysis(false)
    }

    const isPackageChecked = (package_id: number, analysis: Analysis | null): CheckStatus => {
        if (analysis) {
           return patternsPackageTestUtils.isPackageChecked(package_id, patternsPackageTestUtils.convertAnalysisPackageToBasePackage(analysis.analysis_packages), analysis.laboratory_id)
        }
        else {
            return 'unchecked'
        }
    }

    const isAnalyteChecked = (package_id: number, code_test: string, code_analyte: string, analysis: Analysis | null): CheckStatus => {
        if (analysis) {
            const packagesArray = analysis.analysis_packages.find((p) => p.package_id === package_id)
            if (packagesArray) {
                const testsIds = packagesArray.analysis_package_tests.map((st) => st.code_test + '#' + st.code_analyte)
                if (testsIds.includes(code_test + '#' + code_analyte))
                    return 'checked'
                else
                    return 'unchecked'
            } else {
                return 'unchecked'
            }

        }
        else {
            return 'unchecked'
        }

    }

    const handleTestsSelection = (analysis: Analysis, analysisField: TypeFieldAnalysis) => {
        return async (data: IPackages) => {
            const labId = analysisField === 'additionalAnalysis' ?  values.additionalAnalysis?.laboratory_id || 0 : values.analysis.laboratory_id

            const analysis_packages: Array<AnalysisPackage> = data.packages.filter(pVal => pVal.check === 'checked' || pVal.check === 'indeterminate').map(pVal => (
                {
                    package_id: Number(pVal.key),
                    comment: pVal.comment || "",
                    id: data.packages.indexOf(pVal) + 1,
                    period: packages[Number(pVal.key)].period,
                    price: getPriceAugmenteSiUrgent(PricesCalculator.getPrice(priceLists, analysis.company_id, packages[Number(pVal.key)]), analysis),
                    currency: PricesCalculator.getCurrency(priceLists, values.analysis.company_id),
                    weight: packages[Number(pVal.key)].weight,
                    analysis_package_tests: pVal.tests.filter(tVal => tVal.check === 'checked').map(tVal => (
                        {
                            code_test: tVal.code_test,
                            code_analyte: tVal.code_analyte,
                            price: getPriceAugmenteSiUrgent(PricesCalculator.getPrice(priceLists, values.analysis.company_id, analytes[getAnalyteKey({...tVal, laboratory_id: labId})]), analysisField === 'analysis' ? values.analysis : values.additionalAnalysis),
                            analysis_group_name: analytes[getAnalyteKey({...tVal, laboratory_id: labId})].analysis_group_name ? analytes[getAnalyteKey({...tVal, laboratory_id: labId})].analysis_group_name?.translations[language.locale]: analytes[getAnalyteKey({...tVal, laboratory_id: labId})].analysis_group_name?.name || '',
                            currency: PricesCalculator.getCurrency(priceLists, values.analysis.company_id),
                            period: analytes[getAnalyteKey({...tVal, laboratory_id: labId})].period,
                            weight: analytes[getAnalyteKey({...tVal, laboratory_id: labId})].weight,
                            theoretical_value: tVal.theoretical_value != null ? tVal.theoretical_value : null,
                            unit: analytes[getAnalyteKey({...tVal, laboratory_id: labId})].unit
                        }
                    ))
                }
            ))

            const updatedAnalysis = {
                ...analysis,
                price: getPriceBaseAnalysis(analysis),
                currency: PricesCalculator.getCurrency(priceLists, analysis.company_id)
            };

            await setFieldValue(`${analysisField}.analysis_packages`, analysis_packages)

            if (analysisField === 'additionalAnalysis'){
                setTotalPcpwAdditional({
                    price: pcpwUtils.calculatePatternPrice(updatedAnalysis, analysis_packages),
                    period: pcpwUtils.calculatePatternPeriod(updatedAnalysis, analysis_packages),
                    weight: pcpwUtils.calculatePatternWeight(updatedAnalysis, analysis_packages)
                })
            }

            else if (analysisField === 'analysis'){

                setTotalPcpwBase({
                    price: pcpwUtils.calculatePatternPrice(updatedAnalysis, analysis_packages),
                    period: pcpwUtils.calculatePatternPeriod(updatedAnalysis, analysis_packages),
                    weight: pcpwUtils.calculatePatternWeight(updatedAnalysis, analysis_packages)
                })
        }
        }
    }

    const isDenominationDisabled = (): boolean => {
        if (values.analysis.id_pdc !== null && controlPlans[values.analysis.laboratory_id] && controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc] && controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc].control_plan_samples && !hasRoleAll) {
            const sample = controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc].control_plan_samples?.find(cps => cps.id === Number(values.analysis.id_sample))
            if (sample) {
                return typeof sample.denomination === "string" && sample.denomination !== "" && sample.denomination === values.analysis.denomination
            } else {
                return false
            }
        } else {
            return false
        }
    }

    function updateRights(laboratoryId: number) {
        hasRoleAll = userHasRoleAll(laboratoryId, authUser);
        hasRoleAdmin = userHasRoleAdmin(laboratoryId, authUser);
        hasRoleClient = userHasRoleClient(laboratoryId, authUser);
        hasRoleClientButNotCpOrReadOnly = userHasRoleClientButNotCpOrReadOnly(laboratoryId, authUser)
        hasRoleUser = userHasRoleUser(laboratoryId, authUser);

        grantUpdateRegistered = userGrantUpdateRegistered(laboratoryId, authUser);
        grantUpdateReceived = userGrantUpdateReceived(laboratoryId, authUser);
        grantUpdateDone = userGrantUpdateDone(laboratoryId, authUser);
    }

    function displayDetailsLabel(analysisTypeCode: string) {
        if (analysisTypeCode && analysisTypeCode !== "") {
            switch (analysisTypeCode) {
                case "FG":
                    return t('forms.analysis.FG_detail')
                case "RM":
                    return t('forms.analysis.RM_detail')
                case "FP":
                    return t('forms.analysis.FP_detail')
            }
        }
    }

    function getPriceAugmenteSiUrgent(price: number, analysis: Analysis | null) {
        let markupMultip: number
        if (analysis && analysis.urgent) {
            markupMultip = (1 + laboratories[analysis.laboratory_id].markup / 100)
        }
        else {
            markupMultip = 1
        }

        return price != null ? price * markupMultip : null;
    }

    // Handle form reset after first analysis creation
    const newAnalysisForm = async () => {
        if (analysisRep) {
            const prevValues = { ...analysisRep }

            // Restore some values from previous analysis
            const newAnalysis: Analysis = Object.assign(
                {
                    ...initialValues.analysis,
                    laboratory_id: prevValues.laboratory_id,
                    company_id: prevValues.company_id,
                    nir_code: prevValues.nir_code,
                    employee_id: prevValues.employee_id,
                    type_id: prevValues.type_id
                },
                prevValues.farmer_id ? { farmer_id: prevValues.farmer_id } : null
            )
            resetForm({ values: { analysis: { ...newAnalysis }, additionalAnalysis: null } });
            setTriggerRefreshCustomFields(!triggerRefreshCustomFields);
            handleCloseCargillAnalysis();
            setstep(1);
            setFormSubmitted(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const getRequiredFieldsStorageNames = (formFields: FormFieldDocument[][]): string[] => {
        const storageNames: string[] = []

        // Flatten required form's fields
        const flattenFields = formFields.map(line => line.filter(col => col.required))
            .reduce<number[]>((acc, cur) => {
                const fieldIds = cur.map(c => c.field_id)
                return acc.concat(fieldIds)
            }, [])

        // Add dynamic values storage name path to list
        const requiredDynamicFields = flattenFields.filter(id => fields[id].storage_type === "dynamic")
        requiredDynamicFields.forEach(dfId => {
            values.analysis.custom_values.forEach((cv, index) => {
                if (cv.field_id === dfId) {
                    storageNames.push(`analysis.custom_values[${index}].value`)
                }
            })
        })

        // Add static values storage name path to list
        const requiredStaticFields = flattenFields.filter(id => fields[id].storage_type !== "dynamic")
        requiredStaticFields.forEach(sfId => {
            const field = fields[sfId]
            storageNames.push(`analysis.${field.storage_name}`)
            if (field.data_type === "custom") {
                switch (field.input_name) {
                    case "transports":
                        values.analysis.transports.forEach((tra, index) => {
                            storageNames.push(`analysis.transports[${index}].transport_id`)
                            storageNames.push(`analysis.transports[${index}].distance`)
                        })
                }
            }
        })
        return storageNames
    }

    function getAuthUserCompanies(laboratoryId: number) {
        return companiesByLabId[laboratoryId] ? companiesByLabId[laboratoryId].map(id => companies[id])
            .filter(item => item.active && (hasRoleAll ||
                ((hasRoleClient || hasRoleUser) && item.id && (authCompanies.some(c => c.company_id === item.id && laboratories[laboratoryId]?.lab_type === c.lab_type) && (!hasRoleUser || authCompanies.find(c => employeesByCompanyId[c.company_id].includes(String(authUser.employee_id))) ) || authCustomers.some(c => c.company_id === item.id && laboratories[laboratoryId]?.lab_type === c.lab_type))))) : [];
    }

    function  canUserChangeEmployee (companyId: number)  {
        return hasRoleAdmin || (authCustomers.find(c => c.company_id === companyId));
    }


    const [type, setType] = useState(typeAnalysis)



    // Effect to handle analysis type update (ie. switch from forage to raw material)
    useEffect(() => {
        if (type !== typeAnalysis) {
            setType(typeAnalysis)

            resetForm({ values: initAnalysis(typeAnalysis, analysisDB) })
            handleCloseCargillAnalysis()
        }
    }, [typeAnalysis])

    // Effect to handle form content update on laboratory update
    useEffect(() => {
        

        if (values.analysis.laboratory_id !== labId) {

            setLabId(values.analysis.laboratory_id)

            resetForm({ values: initAnalysis(typeAnalysis, analysisDB) })
            handleCloseCargillAnalysis()
        }
    }, [values.analysis.laboratory_id])

    // Effect to handle nir change
    useEffect(() => {
        if (values.analysis.nir_code) {
            setIsNir(true)
        } else {
            setIsNir(false)
        }
    }, [values.analysis.nir_code])


    const handleBarcodeError = (data: string, err: string) => {
        console.error("Error while reading barcode", { data, err })
    }

    /**
     * Handle barcode inputs (managed by laboratory)
     */
    const handleBarcodeScan = async (data: string) => {
        console.log(JSON.stringify(data))
        const curLab = laboratories[values.analysis.laboratory_id]

        // If current laboratory owns barcodes configuration
        if (curLab && curLab.barcodes && curLab.barcodes.length > 0) {
            // For each barcode (every used to break loop if required)
            const noBreak = curLab.barcodes.every(barcode => {
                // Build RegExp from configuration string
                const re = new RegExp(barcode.regex)
                // Check input data toward regexp and capture fields
                const tags = data.match(re)
                // If regex matched, handle captured fields value
                if (tags && tags[0]) {
                    tags.shift() // Remove first item which is the full string
                    let family:Family | undefined = undefined
                    let employee: Employee | undefined = undefined
                    const updatedAnalysis:any = {
                        ...values
                    }
                    tags.forEach((t:any, index) => {
                        // Fill analysis field value with parsed input data
                            if(barcode.mappings[index] === "employee_code"){
                                updatedAnalysis.analysis.employee_id = 0
                                updatedAnalysis.analysis.company_id = 0
                                //liste des entreprises de l'employé connecté
                                const compAuthUser = Object.values(companies).filter((company) => ((isUserAdmin && company.active && (company.laboratory_id === values.analysis.laboratory_id || company.client_laboratory_id === values.analysis.laboratory_id)) || (authCompanies.concat(authCustomers)).some(c => c.company_id === company.id && c.lab_type === laboratories[values.analysis.laboratory_id]?.lab_type) && company.active && (company.laboratory_id === values.analysis.laboratory_id || company.laboratory_id === laboratories[values.analysis.laboratory_id]?.ref_lab_id && company.client_laboratory_id === values.analysis.laboratory_id) && company.id))

                                // l'enployé pour qui il faut créer la DA
                                employee = Object.values(employees).filter(item => (!item.disabled)).find((employee) => employee.code === t)

                                if(!employee){
                                    console.warn("employee_code unknown", t)
                                }else{
                                    //liste des entreprises de l'enployé pour qui il faut créer une da 
                                    const employeeComp = employee?.companies!.concat(employee?.customers ? employee?.customers : []).concat(Object.values(companies).filter((company) => (employee!.all_customers_laboratories.find(lab => lab.laboratory_id === values.analysis.laboratory_id && lab.laboratory_id ===company.laboratory_id || lab.laboratory_id === company.client_laboratory_id) )).map((company) => ({company_id : company.id!, lab_type: laboratories[values.analysis.laboratory_id]?.lab_type as 'cargill' | 'client'})))
                                    
                                   const employeeCompAvailable = employeeComp.filter((comp) => (compAuthUser.map(compUser => compUser.id).includes(comp.company_id) && canUserChangeEmployee(comp.company_id)))

                                    if(employeeCompAvailable.length > 0){
                                        const company = Object.values(companies)
                                        .filter((company) => ((isUserAdmin && company.active && (company.laboratory_id === values.analysis.laboratory_id || company.client_laboratory_id === values.analysis.laboratory_id)) || employeeComp.some(c => c.company_id === company.id && c.lab_type === laboratories[values.analysis.laboratory_id]?.lab_type) && company.active && (company.laboratory_id === values.analysis.laboratory_id || company.laboratory_id === laboratories[values.analysis.laboratory_id]?.ref_lab_id && company.client_laboratory_id === values.analysis.laboratory_id) && company.id))
                                        if(!company[0]){
                                            console.warn("Company unknown")
                                        }else{
                                            updatedAnalysis.analysis.company_id =  company[0].id
                                        }
                                        updatedAnalysis.analysis.employee_id = employee.id
                                    }else{
                                        console.warn("You are not authorized to create a analysis for this employee", t)
                                    }

                        
                                }
                            }else if(barcode.mappings[index] === "farmer_code"){
                                updatedAnalysis.analysis.farmer_id = 0
                                if(employee){
                                    const farmersArray = companies && companies[values.analysis.company_id] && companies[values.analysis.company_id].farmer_level === 'employee' ?
                                    Object.values(farmers)
                                        .filter(item => item.employee_id === employee!.id && item.active === true)
                                        .sort((a, b) => (a.name).localeCompare(b.name))
                                    :
                                    Object.values(farmers)
                                        .filter(item => employeesByCompanyId && employeesByCompanyId[values.analysis.company_id] && employeesByCompanyId[values.analysis.company_id].includes(String(item.employee_id)) && item.active === true)
                                        .sort((a, b) => (a.name).localeCompare(b.name))
                                        const farmer = farmersArray.find((farmer) => farmer.code === t)
                                        if(!farmer){
                                            console.warn("farmer_code unknown", t)
                                        }else{
                                            updatedAnalysis.analysis.farmer_id = farmer.id
                                        }
                                }
                            }else if(barcode.mappings[index] === "family_code"){
                                updatedAnalysis.analysis.family_id = 0
                                family = Object.values(families).filter(item => item.active && item.type_id === values.analysis.type_id && !item.pdc).find((family) => family.code === t)
                                if(!family){
                                    console.warn("family_code unknown", t)
                                }else{
                                    updatedAnalysis.analysis.family_id =family.id
                                    setControlFamilyDetail(family.details_required)
                                }
                            }else if(barcode.mappings[index] === "pattern_name"){
                                updatedAnalysis.pattern_id = 0
                                updatedAnalysis.price = null
                                updatedAnalysis.cost = null
                                updatedAnalysis.period = null
                                updatedAnalysis.weight = null
                                updatedAnalysis.analysis_packages= []
                                if(family){
                                    const pattern = Object.values(patterns).filter(item => {
                                        const patternFamily = Object.values(patternsFamilies).find(p => p.pattern_id === item.id && (!p.family_id || p.family_id === family!.id) && values.analysis.type_id === p.type_id && item.active && !item.pdc)
                                        return !!patternFamily
                                    }).find((pattern) => pattern.name === t)
                                    if(!pattern){
                                        console.warn("pattern_name unknown", t)
                                    }else{
                                        updatedAnalysis.analysis.pattern_id = pattern.id
                                        updatedAnalysis.analysis.analysis_packages = []
                                        updatedAnalysis.analysis.price  =  getPriceAugmenteSiUrgent(PricesCalculator.getPrice(priceLists, values.analysis.company_id, pattern), values.analysis)
                                        updatedAnalysis.analysis.currency  = PricesCalculator.getCurrency(priceLists, values.analysis.company_id)
                                        updatedAnalysis.analysis.cost  = pattern.cost
                                        updatedAnalysis.analysis.period  = pattern.period
                                        updatedAnalysis.analysis.weight  = pattern.weight
                                    }
                                }
                            }
                            else{
                                if (barcode.mappings[index] in updatedAnalysis.analysis) {
                                    if(barcode.mappings[index] === "comment"){
                                        updatedAnalysis.analysis[barcode.mappings[index]] = t.replaceAll(/\\n/g,'\n')
                                    }else{
                                        updatedAnalysis.analysis[barcode.mappings[index]] = t
                                    }
                                    
                                }else{
                                    console.warn("Property unknown", barcode.mappings[index])
                                }
                            }
                    })
                    setValues(updatedAnalysis)

                    setTotalPcpwBase({
                        price: pcpwUtils.calculatePatternPrice(updatedAnalysis.analysis, []),
                        period: pcpwUtils.calculatePatternPeriod(updatedAnalysis.analysis, []),
                        weight: pcpwUtils.calculatePatternWeight(updatedAnalysis.analysis, [])
                    })
                    return false; // break the loop if barcode already matched
                }
                return true; // continue the loop
            })
            if (noBreak) {
                console.warn("No matching barcode found for following input", data)
            }
        }
    }



    return (
        <Container className={!mobile ? "mt-5 p-0" : "p-0"}>
            <Row className="m-0"></Row>
            {!mobile && analysisRep &&
                <Modal size="lg" show={formSubmitted} onHide={(!analysisDB || analysisDB.id === 0 && analysisDB.id_pdc === null) && hasRoleAll ? handleClosePut : undefined} >
                    <Modal.Header>
                        <Modal.Title>
                            <span className="text-primary fw-bold">{t('forms.analysis.analysis_request')} {analysisDB && analysisDB.id !== 0 ? NameTypeAnalysis : null} {t('forms.analysis.number')}{analysisRep.display_id} </span>
                            {analysisRep.additional_analysis && analysisRep.additional_analysis.length > 0 &&
                                <span className="text-primary fw-bold"> - {laboratories[analysisRep.additional_analysis[0].ref_laboratory_id].name} {t('forms.analysis.number')}{
                                    Intl.NumberFormat(authUser.locale).format(analysisRep.additional_analysis[0].ref_analysis_id)} </span>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {analysisDB && analysisDB.id !== 0 &&
                            <span>{t('forms.analysis.msg_success_modify')}</span>
                        }
                        {(!analysisDB || analysisDB.id === 0) &&
                            <>
                                <span>{t('forms.analysis.msg_success_add')}<br /></span>
                                <span>{t('forms.analysis.modify_informations')}<br /></span>
                                <br />
                                {(() => {
                                    let laboratory = laboratories[analysisRep.laboratory_id]
                                    let analysisId = analysisRep.id
                                    if (analysisRep.additional_analysis && analysisRep.additional_analysis.length > 0) {
                                        const [additionalAnalysis] = analysisRep.additional_analysis
                                        laboratory = laboratories[additionalAnalysis.ref_laboratory_id]
                                        analysisId = additionalAnalysis.ref_analysis_id
                                    }

                                    if (laboratory.lab_type === 'cargill') {
                                        return (
                                            <>
                                                <span>{t('forms.analysis.register_number_sample_1')} <span className="fw-bold">{Intl.NumberFormat(authUser.locale).format(analysisId)}</span> {t('forms.analysis.register_number_sample_2')}<br /></span>
                                                {laboratory.address1 &&
                                                    <>
                                                        <span>{t('forms.analysis.register_address_send')}<br /></span>
                                                        <div className='ps-4 my-1 mb-2 fw-bold'>
                                                            <span>{t('forms.laboratories.title')} {laboratory.name}<br /></span>
                                                            <span>{laboratory.address1}<br /></span>
                                                            <span>{laboratory.address2 ? laboratory.address2 : ''}{laboratory.address2 ? <br /> : ''}</span>
                                                            <span>{laboratory.zip_code ? laboratory.zip_code : ''}{laboratory.zip_code && laboratory.city ? ' ' : ''}{laboratory.city ? laboratory.city : ''}</span>
                                                            <span>{laboratory.country && (laboratory.zip_code || laboratory.city) ? <br /> : ''}{laboratory.country ? laboratory.country : ''}</span>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        )
                                    } else {
                                        return null
                                    }
                                })()}

                            </>
                        }

                        <Row>

                            <Col xs={(!analysisDB || analysisDB.id === 0) ? 4 : 6} md={4}>
                                <Button variant="secondary" onClick={() => { goToWithFilter('/secure/analysis', defaultFilters.recent, filterByReducer) }}> {t('nav.my_analysis_requests')} </Button>
                            </Col>

                            {(analysisDB && analysisDB.id !== 0) &&
                                <Col xs={6} md={{ span: 4, offset: 4 }}>
                                    <Link className="btn btn-secondary" to={`/secure/analysis/${analysisRep.laboratory_id}/${analysisRep.id}`}> {t('nav.see_my_modifications')} </Link>
                                </Col>
                            }
                            {((analysisDB && analysisDB.id === 0 && analysisDB.id_pdc !== null)) &&
                                <>
                                    <Col xs={4} md={4}>
                                        <Button variant="secondary" onClick={() => { history.push(`/secure/pdc/${analysisRep.laboratory_id}/${analysisRep.id_pdc}`) }}> {t('pdc.return_to_my_pdc')} </Button>
                                    </Col>
                                    <Col xs={4} md={4}>
                                        <Link className="btn btn-secondary" to={`/secure/analysis/${analysisRep.laboratory_id}/${analysisPdcId}`}> {t('pdc.see_my_da')} </Link>
                                    </Col>
                                </>
                            }
                            {((!analysisDB || analysisDB.id === 0 && analysisDB.id_pdc === null)) &&
                                <Col className="text-end">
                                    <Button variant="secondary" onClick={newAnalysisForm}>
                                        {t('nav.new_analysis_request')}
                                    </Button>
                                </Col>
                            }
                        </Row>
                    </Modal.Body>
                </Modal>
            }


            {mobile && analysisRep &&
                <Modal size="lg" show={formSubmitted} onHide={() => { setFormSubmitted(false) }}>
                    <Modal.Header>
                        <Modal.Title><span className="text-primary fw-bold">{(!analysisDB || analysisDB.id === 0) ? t('forms.analysis.new_request') : t('forms.analysis.analysis_request')} {NameTypeAnalysis} {t('forms.analysis.number')}{analysisRep.display_id} </span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {(apiError === undefined && Object.prototype.hasOwnProperty.call(analysisRep, "error") === false && (!analysisDB || analysisDB.id === 0)) &&
                            <>
                                <span>{t('forms.analysis.register_number_sample_1')} {Intl.NumberFormat(authUser.locale).format(analysisRep.id)} {t('forms.analysis.register_number_sample_2')}<br /></span>
                                <span>{t('forms.analysis.msg_success_add')}<br /></span>
                                <span>{t('forms.analysis.modify_informations')}<br /></span>
                            </>
                        }
                        {(apiError === undefined && Object.prototype.hasOwnProperty.call(analysisRep, "error") === false && analysisDB && analysisDB.id !== 0) &&
                            <span>{t('forms.analysis.msg_success_modify')}</span>
                        }
                        <Row className="pt-2">
                            <Col className={(!analysisDB || analysisDB.id === 0) ? "text-start" : "text-end"}>
                                <Link className="btn btn-secondary" to="/secure/analysis">{t('nav.my_analysis_requests')} </Link>
                            </Col>
                            {(!analysisDB || analysisDB.id === 0 && analysisDB.id_pdc === null) &&
                                <Col className="text-end">
                                    <Button variant="secondary" onClick={newAnalysisForm}>
                                        {t('nav.new_analysis_request')}
                                    </Button>
                                </Col>
                            }
                        </Row>
                    </Modal.Body>
                </Modal>
            }

            {!mobile &&
                <BarcodeReader
                    onError={handleBarcodeError}
                    onScan={handleBarcodeScan}
                    avgTimeByChar={100}
                    timeBeforeScanTest={200}
                />
            }

            <Modal size="lg" show={apiError !== undefined} onHide={() => { setApiError(undefined) }} >
                <Modal.Header closeButton={apiError !== undefined} >
                    <Modal.Title><span className="text-primary fw-bold">{t('forms.analysis.analysis_request')} {analysisDB && analysisDB.id !== 0 ? NameTypeAnalysis : null} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="danger">{t('forms.analysis.msg_error_add')} {apiError} </Alert>
                </Modal.Body>
            </Modal>

            <Container className="p-0">

                <Form onSubmit={handleSubmit}>
                    {isSubmitting &&
                        <Loader />
                    }
                    {apiError &&
                        <Container className="text-danger">
                            {apiError}
                        </Container>
                    }

                    <Container>
                        {!mobile && ((values.analysis.id_pdc === null && values.analysis.id === 0) || values.analysis.id !== 0) &&
                            <GoBackButton label={t('analysis.return_to_my_list_analysis')} />
                        }
                        {values.analysis.id === 0 && values.analysis.id_pdc !== null &&
                            <GoBack to={`/secure/pdc/${values.analysis.laboratory_id}/${values.analysis.id_pdc}`} label={t('pdc.return_to_my_pdc')} />
                        }
                        {values.analysis.id !== 0 && !mobile &&
                            <h4 className="pb-2 mt-3 fw-bold">
                                {t('forms.analysis.analysis_request')} {NameTypeAnalysis}
                                <span className="text-primary fw-bold">
                                    {t('forms.analysis.number')}
                                    {values.analysis.display_id}{values.analysis.id_pdc !== null ? " - " : null}
                                </span>
                                {values.analysis.id_pdc !== null ? t('pdc.pdc') : null}
                                <span className="text-primary fw-bold">
                                    {values.analysis.id_pdc !== null ? controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc].name : null}
                                </span>
                            </h4>
                        }
                        {values.analysis.id === 0 && values.analysis.id_pdc !== null && !mobile &&
                            <h4 className="pb-2 mt-3 fw-bold"> {t('pdc.pdc')} <span className="text-primary fw-bold"> {t('pdc.number')}{Intl.NumberFormat(authUser.locale).format(values.analysis.id_pdc)} - {controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc].name}</span></h4>
                        }
                        {values.analysis.id === 0 && values.analysis.id_pdc === null && !mobile &&
                            <h1 className="pb-2 mt-3 fw-bold"> {t('forms.analysis.analysis_request')}</h1>
                        }

                        {mobile &&
                            <Row className="align-items-end">
                                <Col xs={12}>
                                    <GoBack to="/secure/analysis" label={t('analysis.return_to_my_list_analysis')} />
                                </Col>
                                <Col xs={12}>
                                    {values.analysis.id === 0 ? (
                                        <h5 className="pb-2 pt-2 fw-bold">{t('forms.analysis.new_requestMobile')} {NameTypeAnalysis}</h5>
                                    ) : (
                                        <h5 className="pb-1 pt-2 fw-bold">{t('forms.analysis.analysis_request')}  <span className="text-primary fw-bold"> {t('forms.analysis.number')}{values.analysis.display_id}</span></h5>
                                    )}
                                </Col>
                            </Row>
                        }
                        {values.analysis.id !== 0 && values.analysis.id_pdc !== null && mobile &&
                            <Row className="align-items-end">
                                <Col>
                                    <h5 className="fw-bold"> {t('pdc.ar')} <span className="text-primary fw-bold"> {controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc].name}</span></h5>
                                </Col>
                            </Row>
                        }

                        {values.analysis.id !== 0 && !mobile &&
                            <StatusBar
                                Date1={values.analysis.creation_date != null ? new Date(values.analysis.creation_date).toString() : undefined}
                                Date2={values.analysis.last_update != null ? new Date(values.analysis.last_update).toString() : undefined}
                                Date3={values.analysis.receipt_date != null ? new Date(values.analysis.receipt_date).toString() : undefined}
                                Date4={values.analysis.conformity_date != null ? new Date(values.analysis.conformity_date).toString() : undefined}
                                Date6={values.analysis.validation_date != null ? new Date(values.analysis.validation_date).toString() : undefined}
                                Date7={values.analysis.cancellation_date != null ? new Date(values.analysis.cancellation_date).toString() : undefined} />
                        }

                        {mobile && <Row className="mt-1"><StatusBarMobile Step={step} /></Row>}

                        {values.analysis.status === StatusAnalysis.received_improper && values.analysis.improper_id && !mobile &&
                            <Row className="pt-2 pb-2  " >
                                <Col className="d-flex">
                                    <h4 className="fw-bold d-flex"> {t('forms.analysis.impropers.title')} : <h4 className="text-danger fw-bold">{dbt(impropers, values.analysis.improper_id, language.id, "improper")}{values.analysis.comment_improper !== undefined ? ` (${values.analysis.comment_improper})` : ``}</h4>  </h4>
                                </Col>
                            </Row>
                        }

                        {((mobile && step === 1) || !mobile) &&
                            <Container className="p-2 bg-white mb-2 rounded-3">
                                {!mobile &&
                                    <>
                                        <Row>
                                            <Col lg>
                                                <h4 className="text-primary fw-bold">{t('forms.analysis.customer')}</h4>
                                            </Col>
                                        </Row>
                                        <Row className="pb-2" ><Col lg className="lineTitle"></Col></Row>
                                    </>
                                }
                                {(() => {
                                    const authUserLaboratories = Object.values(laboratories).filter(l => { return isLabAuthorizedForAnalysisType(l.id) })
                                    const hasMultipleLabs: boolean = authUserLaboratories.length > 1
                                    const authUserCompanies = getAuthUserCompanies(values.analysis.laboratory_id)
                                    const hasMultipleCompanies = authUserCompanies.length > 1
                                    const authUserCompanyNirs = laboratories[values.analysis.laboratory_id].lab_type === 'client' ? getNirsOfCompany(values.analysis.company_id) : []
                                    const hasMultipleNirs = authUserCompanyNirs.length > 1

                                    if (hasMultipleLabs || hasMultipleCompanies || hasMultipleNirs) {
                                        return (
                                            <Container className="p-0 pb-2" >
                                                <Row className="mt-2 mb-1">
                                                    {(hasMultipleLabs || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) &&
                                                        <Form.Group as={Col} controlId="formlaboratory">
                                                            {!mobile ?
                                                                <Form.Label>
                                                                    {t('forms.analysis.laboratory')} : *
                                                                </Form.Label>
                                                                :
                                                                <Form.Label>
                                                                    <h5 className="ms-1 text-primary fw-bold">{t('forms.analysis.laboratory')} : *</h5>
                                                                </Form.Label>
                                                            }
                                                            <Select
                                                                noOptionsMessage={() => t('admin.users.form.no_laboratory')}
                                                                value={values.analysis.laboratory_id === 0 ? '' : laboratories[values.analysis.laboratory_id]}
                                                                placeholder={t('admin.users.form.default_laboratory')}
                                                                getOptionLabel={(laboratory: Laboratory) => laboratory.name}
                                                                getOptionValue={(laboratory: Laboratory) => laboratory.id}
                                                                isClearable={false}
                                                                options={authUserLaboratories}
                                                                isDisabled={values.analysis.id_pdc !== null || values.analysis.id !== 0}
                                                                onChange={(laboratory: Laboratory) => {
                                                                    if (laboratory) {
                                                                        if (values.analysis.laboratory_id !== laboratory.id) {

                                                                            const newCustomForm = Object.values(forms).find(f => f.laboratory_id === laboratory.id && f.type_code === (values && values.analysis.type_code || typeAnalysis || 'FG'))
                                                                            const flattenFields = newCustomForm ? getFlattenDynamicFields(newCustomForm) : []

                                                                            let newAdditionalCustomForm: CustomForm | undefined
                                                                            let additionalFlattenFields: number[] | undefined
                                                                            if (laboratory.lab_type === 'client') {
                                                                                newAdditionalCustomForm = Object.values(forms).find(f => f.laboratory_id === (laboratories[laboratory.id].ref_lab_id) && f.type_code === (analysisDB && analysisDB.type_code || values && values.analysis.type_code || typeAnalysis || 'FG'))
                                                                                additionalFlattenFields = newAdditionalCustomForm ? getFlattenDynamicFields(newAdditionalCustomForm) : []
                                                                            }
                                                                            const [analysisType] = Object.values(types).filter(t => t.active && t.code === type && t.laboratory_id === laboratory.id && (laboratories[laboratory.id].lab_type !== 'client' || t.nir_types.length === 0 || !values.analysis.nir_code || t.nir_types.find(nt => nt.nir_code === values.analysis.nir_code)))
                                                                            const familyId = analysisType && Object.values(families).filter(item => item.active && item.type_id === analysisType.id).length === 1 ? Object.values(families).filter(item => item.active && item.type_id === analysisType.id)[0].id : 0
                                                                            const refFamilyId = analysisType && Object.values(families).filter(item => item.active && item.type_id === analysisType.ref_type_id).length === 1 ? Object.values(families).filter(item => item.active && item.type_id === analysisType.ref_type_id)[0].id : 0
                                                                            const updated = {
                                                                                ...values,
                                                                                analysis: Object.assign(
                                                                                    {
                                                                                        ...values.analysis,
                                                                                        company_id: 0,
                                                                                        type_id: analysisType ? analysisType.id : 0,
                                                                                        family_id: familyId,
                                                                                        family_comment: "",
                                                                                        laboratory_id: laboratory.id,
                                                                                        nir_code: '',
                                                                                        customValues: flattenFields.map(fieldId => (
                                                                                            { field_id: fieldId, value: null }
                                                                                        )),
                                                                                    },
                                                                                    !hasRoleUser && (hasRoleClient || hasRoleAll) ? { employee_id: 0 } : null
                                                                                ),
                                                                                additionalAnalysis: values.additionalAnalysis ? Object.assign(
                                                                                    {
                                                                                        ...values.additionalAnalysis,
                                                                                        company_id: 0,
                                                                                        type_id: analysisType && analysisType.ref_type_id ? analysisType.ref_type_id : 0,
                                                                                        family_id: refFamilyId,
                                                                                        family_comment: "",
                                                                                        laboratory_id: laboratory.id,
                                                                                    },
                                                                                    additionalFlattenFields ? {
                                                                                        customValues: additionalFlattenFields.map(fieldId => (
                                                                                            { field_id: fieldId, value: null }
                                                                                        ))
                                                                                    } : null,
                                                                                    !hasRoleUser && (hasRoleClient || hasRoleAll) ? { employee_id: 0 } : null
                                                                                ) : null
                                                                            }
                                                                            setValues(updated)
                                                                            dispatch(setAuthCurLabId(laboratory.id))
                                                                            dispatch(refreshProductDenominations())
                                                                            
                                                                        }
                                                                    }
                                                                }}
                                                                styles={{
                                                                    menu: (styles: Data<string>) => ({
                                                                        ...styles,
                                                                        zIndex: '1031'
                                                                    })
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    }
                                                    
                                                    {(hasMultipleCompanies || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])) &&
                                                        <Form.Group as={Col} className="mb-2" controlId="formCustomer">
                                                            {!mobile ?
                                                                <Form.Label>
                                                                    {t('forms.analysis.customer')} : *
                                                                </Form.Label>
                                                                :
                                                                <Form.Label>
                                                                    <h5 className="ms-1 text-primary fw-bold">{t('forms.analysis.customer')} : *</h5>
                                                                </Form.Label>
                                                            }
                                                            <Select
                                                                className={getIn(touched.analysis, "company_id") && typeof getIn(errors.analysis, "company_id") === 'string' ? `imgErrorInvalidInput` : ``}
                                                                styles={{
                                                                    control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                                        ...styles,
                                                                        borderColor: (getIn(touched.analysis, "company_id") && typeof getIn(errors.analysis, "company_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                        boxShadow: state.isFocused ? (getIn(touched.analysis, "company_id") && typeof getIn(errors.analysis, "company_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                        '&:hover': {
                                                                            borderColor: (getIn(touched.analysis, "company_id") && typeof getIn(errors.analysis, "company_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                            boxShadow: state.isFocused ? (getIn(touched.analysis, "company_id") && typeof getIn(errors.analysis, "company_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                        }

                                                                    }),
                                                                    menu: (styles: Data<string>) => ({
                                                                        ...styles,
                                                                        zIndex: '1031'
                                                                    })
                                                                }}
                                                                noOptionsMessage={() => t('forms.analysis.companyNotFound')}
                                                                value={values.analysis.company_id && companies[values.analysis.company_id]}
                                                                name='analysis.company_id'
                                                                placeholder={t('forms.analysis.default_company')}
                                                                getOptionLabel={(company: Company) => company.name}
                                                                getOptionValue={(company: Company) => company.id}
                                                                isDisabled={values.analysis.id_pdc !== null && controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc] && !hasRoleAll}
                                                                isClearable={true}
                                                                options={Object.values(companies).filter(
                                                                    (company) => ((isUserAdmin && company.active && (company.laboratory_id === values.analysis.laboratory_id || company.client_laboratory_id === values.analysis.laboratory_id)) || (authCompanies.concat(authCustomers)).some(c => c.company_id === company.id && c.lab_type === laboratories[values.analysis.laboratory_id]?.lab_type) && company.active && (company.laboratory_id === values.analysis.laboratory_id || company.laboratory_id === laboratories[values.analysis.laboratory_id]?.ref_lab_id && company.client_laboratory_id === values.analysis.laboratory_id) && company.id
                                    ))}
                                                                onChange={async (company: Company) => {
                                                                    if (company && company.id) {
                                                                        const oldValue = values.analysis.company_id
                                                                        if (oldValue !== company.id) {
                                                                            const updatedValues = {
                                                                                ...values,
                                                                                }
                                                                            updatedValues.analysis.pattern_id = 0
                                                                            updatedValues.analysis.analysis_packages = []
                                                                            if(updatedValues.additionalAnalysis){
                                                                                updatedValues.additionalAnalysis.pattern_id = 0
                                                                                updatedValues.additionalAnalysis.analysis_packages = []
                                                                            }
                                                                            const employeeId = getDefaultEmployeeFromCompany(company.id, authUser)
                                                                            updatedValues.analysis.company_id = company.id
                                                                            if(updatedValues.additionalAnalysis) updatedValues.additionalAnalysis.company_id= company.id
                                                                            if ((!hasRoleUser && (hasRoleClient || hasRoleAll)) || (hasRoleUser && authCustomers.find(c => c.company_id === company.id))) {
                                                                                updatedValues.analysis.employee_id = employeeId                                                                              
                                                                                if(updatedValues.additionalAnalysis) updatedValues.additionalAnalysis.employee_id = employeeId
                                                                            }
                                                                            if (hasRoleUser && !authCustomers.find(c => c.company_id === company.id)){
                                                                                updatedValues.analysis.employee_id = authUser.employee_id                                                                              
                                                                            }   
                                                                            if (["FG", "RM"].includes(values.analysis.type_code)) {
                                                                                updatedValues.analysis.farmer_id = 0
                                                                                if (updatedValues.additionalAnalysis) updatedValues.additionalAnalysis.farmer_id = 0                                                                              
                                                                            }

                                                                            const nirsOfCompany = getNirsOfCompany(company.id)

                                                                            if (laboratories[values.analysis.laboratory_id].lab_type === 'client' && nirsOfCompany.length === 1) {
                                                                                updatedValues.analysis.nir_code = nirsOfCompany[0].nir_code
                                                                            }
                                                                            else {
                                                                                updatedValues.analysis.nir_code = ''

                                                                            }

                                                                            let typeId = 0
                                                                            if(Object.values(types).filter(type => type.active && type.code === values.analysis.type_code && type.laboratory_id === values.analysis.laboratory_id && (company.nir_codes.length === 0 || type.nir_types.length === 0 || type.nir_types.filter(nirType => company.nir_codes.includes(nirType.nir_code)).length > 0)).length > 0){
                                                                                typeId = Object.values(types).filter(type => type.active && type.code === values.analysis.type_code && type.laboratory_id === values.analysis.laboratory_id && (company.nir_codes.length === 0 || type.nir_types.length === 0 || type.nir_types.filter(nirType => company.nir_codes.includes(nirType.nir_code)).length > 0))[0].id
                                                                                updatedValues.analysis.type_id = Object.values(types).filter(type => type.active && type.code === values.analysis.type_code && type.laboratory_id === values.analysis.laboratory_id && (company.nir_codes.length === 0 || type.nir_types.length === 0 || type.nir_types.filter(nirType => company.nir_codes.includes(nirType.nir_code)).length > 0))[0].id
                                                                            }else{
                                                                                updatedValues.analysis.type_id = 0
                                                                            }
                                                                            
                                                                            if(Object.values(families).filter(item => item.active && item.type_id === typeId).length === 1){
                                                                                updatedValues.analysis.family_id = Object.values(families).filter(item => item.active && item.type_id === typeId)[0].id
                                                                            }else{
                                                                                updatedValues.analysis.family_id = 0
                                                                            }

                                                                            setValues(updatedValues)

                                                                        }
                                                                    } else {
                                                                        const updatedValues = {
                                                                            ...values,
                                                                            }
                                                                         updatedValues.analysis.company_id = 0
                                                                        if (updatedValues.additionalAnalysis) updatedValues.additionalAnalysis.company_id = 0 
                                                                         updatedValues.analysis.employee_id = 0
                                                                         if (updatedValues.additionalAnalysis) updatedValues.additionalAnalysis.employee_id = 0
                                                                                                                                                                                                               
                                                                        if (["FG", "RM"].includes(values.analysis.type_code)) {
                                                                            updatedValues.analysis.farmer_id = 0
                                                                            if (updatedValues.additionalAnalysis) updatedValues.additionalAnalysis.farmer_id = 0
                                                                        }
                                                                        updatedValues.analysis.nir_code = ''
                                                                        updatedValues.analysis.type_id = 0
                                                                        updatedValues.analysis.family_id = 0
                                                                        updatedValues.analysis.pattern_id = 0
                                                                        updatedValues.analysis.analysis_packages = []
                                                                        if(updatedValues.additionalAnalysis){
                                                                            updatedValues.additionalAnalysis.pattern_id = 0
                                                                            updatedValues.additionalAnalysis.analysis_packages = []
                                                                        }
                                                                        setValues(updatedValues)
                                                                    }
                                                                    setFieldTouched("analysis.company_id", true)
                                                                    if (values.additionalAnalysis) setFieldTouched("additionalAnalysis.company_id", true)
                                                                    setTotalPcpwAdditional({ price: null, period: null, weight: null })
                                                                    setTotalPcpwBase({ price: null, period: null, weight: null })
                                                                  

                                                                }}
                                                                onBlur={async () => {
                                                                    setFieldTouched("analysis.company_id", true)
                                                                    if (values.additionalAnalysis) setFieldTouched("additionalAnalysis.company_id", true)
                                                                }}
                                                            />
                                                            {getIn(touched.analysis, "company_id") && typeof getIn(errors.analysis, "company_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "company_id")}</Form.Text> : null}
                                                        </Form.Group>
                                                    }
                                                    {hasMultipleNirs &&
                                                        <Form.Group as={!mobile ? Col : Row} controlId="formNir" className={!mobile ? "" : "pe-0"}>
                                                            {!mobile ?
                                                                <Form.Label>
                                                                    {t('forms.analysis.nir')} : *
                                                                </Form.Label>
                                                                :
                                                                <Form.Label>
                                                                    <h5 className="ms-1 text-primary fw-bold">{t('forms.analysis.nir')} : *</h5>
                                                                </Form.Label>
                                                            }
                                                            <Select
                                                                className={!mobile ? "" : "pe-0 " + getIn(touched.analysis, "nir_code") && typeof getIn(errors.analysis, "nir_code") === 'string' ? `imgErrorInvalidInput` : ``}
                                                                styles={{
                                                                    control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                                        ...styles,
                                                                        borderColor: (getIn(touched.analysis, "nir_code") && typeof getIn(errors.analysis, "nir_code") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                        boxShadow: state.isFocused ? (getIn(touched.analysis, "nir_code") && typeof getIn(errors.analysis, "nir_code") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                        '&:hover': {
                                                                            borderColor: (getIn(touched.analysis, "nir_code") && typeof getIn(errors.analysis, "nir_code") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                            boxShadow: state.isFocused ? (getIn(touched.analysis, "nir_code") && typeof getIn(errors.analysis, "nir_code") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                        }

                                                                    }),
                                                                    menu: (styles: Data<string>) => ({
                                                                        ...styles,
                                                                        zIndex: '1031'
                                                                    })
                                                                }}
                                                                noOptionsMessage={() => t('forms.analysis.no_nir')}
                                                                value={values.analysis.nir_code ? nirs[`${values.analysis.laboratory_id}#${values.analysis.nir_code}`] : ''}
                                                                placeholder={t('forms.analysis.default_nir')}
                                                                getOptionLabel={(nir: Nir) => nir.name}
                                                                getOptionValue={(nir: Nir) => `${nir.laboratory_id}#${nir.nir_code}`}
                                                                isClearable={false}
                                                                options={authUserCompanyNirs}
                                                                isDisabled={!values.analysis.company_id}
                                                                onChange={(nir: Nir) => {
                                                                    const analysisTypes = Object.values(types).filter(t => t.active && t.code === type && t.laboratory_id === values.analysis.laboratory_id && (laboratories[values.analysis.laboratory_id].lab_type !== 'client' || t.nir_types.length === 0 || nir.nir_code && t.nir_types.find(nt => nt.nir_code === nir.nir_code)))

                                                                    let newType: Type | undefined
                                                                    if (values.analysis.type_id && types[values.analysis.type_id].nir_types.length !== 0 && !types[values.analysis.type_id].nir_types.find(nt => nt.nir_code === nir.nir_code)) {
                                                                        newType = analysisTypes[0]
                                                                    }

                                                                    const updated = {
                                                                        ...values,
                                                                        analysis: Object.assign(
                                                                            {
                                                                                ...values.analysis,
                                                                                nir_code: nir.nir_code,
                                                                            },
                                                                            newType ? { type_id: newType.id, family_id: 0 } : null,
                                                                        ),
                                                                        additionalAnalysis: values.additionalAnalysis ? Object.assign(
                                                                            {
                                                                                ...values.additionalAnalysis,
                                                                            },
                                                                            newType && newType.ref_type_id ? { type_id: newType.ref_type_id, family_id: 0 } : null,
                                                                        ) : null
                                                                    }
                                                                    setValues(updated)
                                                                }}
                                                            />
                                                            {getIn(touched.analysis, "nir_code") && typeof getIn(errors.analysis, "nir_code") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "nir_code")}</Form.Text> : null}
                                                        </Form.Group>
                                                    }
                                                </Row>
                                            </Container>
                                        )
                                    } else {
                                        return null
                                    }


                                })()}
                                {canUserChangeEmployee(values.analysis.company_id) &&
                                    <Container className="p-0 pb-2" >
                                        {!mobile ?
                                            <Form.Label>
                                                {t('forms.analysis.technician')} *
                                            </Form.Label>
                                            :
                                            <Form.Label>
                                                <h5 className="ms-1 text-primary fw-bold">{t('forms.analysis.technician')} *</h5>
                                            </Form.Label>
                                        }
                                        <Form.Group className="mb-2" controlId="formTechnician" >
                                            <Select
                                                className={getIn(touched.analysis, "employee_id") && typeof getIn(errors.analysis, "employee_id") === 'string' ? `imgErrorInvalidInput` : ``}
                                                styles={{
                                                    control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                        ...styles,
                                                        borderColor: (getIn(touched.analysis, "employee_id") && typeof getIn(errors.analysis, "employee_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                        boxShadow: state.isFocused ? (getIn(touched.analysis, "employee_id") && typeof getIn(errors.analysis, "employee_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                        '&:hover': {
                                                            borderColor: (getIn(touched.analysis, "employee_id") && typeof getIn(errors.analysis, "employee_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                            boxShadow: state.isFocused ? (getIn(touched.analysis, "employee_id") && typeof getIn(errors.analysis, "employee_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                        }
                                                    }),
                                                    menu: (styles: Data<string>) => ({
                                                        ...styles,
                                                        zIndex: '1031'
                                                    })
                                                }}

                                                noOptionsMessage={() => t('forms.analysis.applicantNotFound')}
                                                value={values.analysis.employee_id && employees[values.analysis.employee_id]}
                                                name='analysis.employee_id'
                                                placeholder=""
                                                getOptionLabel={(employee: Employee) => employee.firstname + " " + employee.lastname}
                                                getOptionValue={(employee: Employee) => employee.id}
                                                isDisabled={(Number(values.analysis.company_id) === 0 && (hasRoleAll || hasRoleClient)) || (values.analysis.id === 0 && !hasRoleAll && !hasRoleClient && (!hasRoleUser ||  !authCustomers.find(c => c.company_id === values.analysis.company_id))) || (values.analysis.id_pdc !== null && controlPlans[values.analysis.laboratory_id] && controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc] && !hasRoleAll && !hasRoleClient) ? true : false}
                                                isClearable={true}
                                                options={Object.values(employees)
                                                    .filter(item => values.analysis.employee_id === item.id ||
                                                        (!item.disabled && item.companies.map(c => c.company_id).includes(values.analysis.company_id)))
                                                    .sort((a, b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname))
                                                }

                                                onChange={async (employee: Employee) => {
                                                    if (employee) {
                                                        const oldValue = values.analysis.employee_id
                                                        if (oldValue !== employee.id) {
                                                            await setFieldValue("analysis.employee_id", employee.id)
                                                            if (values.additionalAnalysis) await setFieldValue("additionalAnalysis.employee_id", employee.id)
                                                            if (["FG", "RM"].includes(values.analysis.type_code)) {
                                                                await setFieldValue("analysis.farmer_id", 0)
                                                                if (values.additionalAnalysis) await setFieldValue("additionalAnalysis.farmer_id", 0)
                                                            }
                                                        }
                                                    } else {
                                                        await setFieldValue("analysis.employee_id", 0)
                                                        if (values.additionalAnalysis) await setFieldValue("additionalAnalysis.employee_id", 0)                                                        
                                                        if (["FG", "RM"].includes(values.analysis.type_code)) {
                                                            await setFieldValue("analysis.farmer_id", 0)
                                                            if (values.additionalAnalysis) await setFieldValue("additionalAnalysis.farmer_id", 0)
                                                        }
                                                    }
                                                    setFieldTouched("analysis.employee_id", true)
                                                    if (values.additionalAnalysis) setFieldTouched("additionalAnalysis.employee_id", true)
                                                }}
                                                onBlur={async () => {
                                                    setFieldTouched("analysis.employee_id", true)
                                                    if (values.additionalAnalysis) setFieldTouched("additionalAnalysis.employee_id", true)
                                                }}
                                            />
                                            {getIn(touched.analysis, "employee_id") && typeof getIn(errors.analysis, "employee_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "employee_id")}</Form.Text> : null}
                                            {Number(values.analysis.company_id) === 0 && (hasRoleAll || hasRoleClient) ? <Form.Text className="invalid-feedback d-block">{t('forms.analysis.needClientForSelectFarmer')}</Form.Text> : null}
                                        </Form.Group>
                                    </Container>
                                }
                                
                                {!canUserChangeEmployee(values.analysis.company_id) &&
                                    <Row>
                                        <Col className='mt-2'>
                                            <Form.Group className="mb-2">
                                                <Form.Label><span className="text-black fw-bold">{t('forms.analysis.technician')}</span> {values.analysis.employee_id ? employees[values.analysis.employee_id].firstname + ' ' + employees[values.analysis.employee_id].lastname : ''} </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                }
                                {customForm && customForm.fields.client.map((line, lIndex) => {
                                    return (
                                        <Row key={lIndex}>
                                            {line.map((col, cIndex) => {
                                                return (
                                                    <Col lg key={cIndex}>
                                                        <CustomField
                                                            laboratoryId={values.analysis.laboratory_id} // Current laboratory ID
                                                            familyId={values.analysis.family_id} // Current family ID
                                                            formId={customForm.id ?? 0} // Id of the form (used to retrieve matching datalist)
                                                            fieldId={col.field_id} // ID of the field to display
                                                            isReadOnly={false} // Boolean to know if field is editable or not
                                                            isRequired={col.required} // Boolean to know if the field is required or not
                                                            disabled={fields[col.field_id].storage_name === "denomination" ? isDenominationDisabled() : false} // Boolean to know if field is disabled
                                                            formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                            formikPrefix="analysis" // Optionnal field prefix in formik values (ie. "analysis." for "analysis.<field storage>" name resolution)
                                                            formikPrefixCopy={values.additionalAnalysis ? "additionalAnalysis" : ""}
                                                            triggerRefreshCustomFields={triggerRefreshCustomFields}
                                                        />
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    )
                                })}
                            </Container>
                        }

                        {((mobile && step === 2) || !mobile) &&
                            <Container className="p-2 bg-white mb-2 rounded-3">
                                <Container className="p-0">
                                    {!mobile ?
                                        <>
                                            <Col className="p-0">
                                                <h4 className="text-primary fw-bold"> {NameTypeAnalysis} : *</h4>
                                            </Col>
                                            <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                        </>
                                        :
                                        <Col className="pe-1 my-2">
                                            <h5 className="text-primary fw-bold"> {NameTypeAnalysis} : *</h5>
                                        </Col>
                                    }
                                    <Row>
                                        {(() => {
                                            const analysisTypes = Object.values(types).filter(type => type.active && type.code === values.analysis.type_code && type.laboratory_id === values.analysis.laboratory_id && (!values.analysis.nir_code || type.nir_types.length === 0 || type.nir_types.filter(nirType => nirType.nir_code === values.analysis.nir_code).length > 0))
                                            const hasMultipleTypesByCode = analysisTypes.length > 1

                                            return (
                                                <>
                                                    {hasMultipleTypesByCode &&
                                                        <Col>
                                                            <Form.Group className={mobile ? "mb-4" : "mb-2"} controlId="formType" >
                                                                <Form.Label>{t('forms.analysis.type')} *</Form.Label>
                                                                <Select
                                                                    className={getIn(touched.analysis, "type_id") && typeof getIn(errors.analysis, "type_id") === 'string' ? `imgErrorInvalidInput` : ``}
                                                                    styles={{
                                                                        control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                                            ...styles,
                                                                            borderColor: (getIn(touched.analysis, "type_id") && typeof getIn(errors.analysis, "type_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                            boxShadow: state.isFocused ? (getIn(touched.analysis, "type_id") && typeof getIn(errors.analysis, "type_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                            '&:hover': {
                                                                                borderColor: (getIn(touched.analysis, "type_id") && typeof getIn(errors.analysis, "type_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                                boxShadow: state.isFocused ? (getIn(touched.analysis, "type_id") && typeof getIn(errors.analysis, "type_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                            }
                                                                        }),
                                                                        option: (base: Data<string>) => ({
                                                                            ...base,
                                                                            paddingTop: 0,
                                                                            paddingBottom: 0,
                                                                            minHeight: 25
                                                                        }),
                                                                        menu: (styles: Data<string>) => ({
                                                                            ...styles,
                                                                            zIndex: '1031',
                                                                            width: mobile ? '170%' : '100%',
                                                                        })
                                                                    }}

                                                                    noOptionsMessage={() => t('forms.analysis.typeNotFound')}
                                                                    value={values.analysis.type_id !== 0 ? types[values.analysis.type_id] : { id: 0, name: "" }}
                                                                    name='analysis.family_id'
                                                                    placeholder=""
                                                                    getOptionLabel={(type: Type) => dbt(types, type.id, language.id, "name")}
                                                                    getOptionValue={(type: Type) => type.id}
                                                                    isClearable={false}
                                                                    isDisabled={false}
                                                                    options={analysisTypes}

                                                                    onChange={async (type: Type) => {
                                                                        const oldValue = values.analysis.type_id
                                                                        if (type) {
                                                                            if(oldValue !== type.id){
                                                                                await setFieldValue("analysis.type_id", type !== null ? type.id : 0)
                                                                                if(Object.values(families).filter(item => item.active && item.type_id === type.id).length === 1){
                                                                                    await setFieldValue("analysis.family_id", Object.values(families).filter(item => item.active && item.type_id === type.id)[0].id)
                                                                                }else{
                                                                                    await setFieldValue("analysis.family_id", 0)
                                                                                }

                                                                                if (values.additionalAnalysis) {
                                                                                    await setFieldValue("additionalAnalysis.type_id", type && type.ref_type_id ? type.ref_type_id : 0)
                                                                                    if(Object.values(families).filter(item => item.active && item.type_id === type.ref_type_id).length === 1){
                                                                                        await setFieldValue("additionalAnalysis.family_id", Object.values(families).filter(item => item.active && item.type_id === type.ref_type_id)[0].id)
                                                                                    }else{
                                                                                        await setFieldValue("additionalAnalysis.family_id", 0)
                                                                                    }
                                                                                }
                                                                            }
                                                                        }else{
                                                                            await setFieldValue("analysis.family_id", 0)
                                                                            if (values.additionalAnalysis) await setFieldValue("additionalAnalysis.family_id", 0)
                                                                        }
                                                                        setTotalPcpwAdditional({ price: null, period: null, weight: null })
                                                                        setTotalPcpwBase({ price: null, period: null, weight: null })
                                                                    }}
                                                                />
                                                                {getIn(touched.analysis, "type_id") && typeof getIn(errors.analysis, "type_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "type_id")}</Form.Text> : null}
                                                            </Form.Group>
                                                        </Col>
                                                    }
                                                    <Col >
                                                        <Form.Group className={mobile ? "mb-4" : "mb-2"} controlId="formFamily" >
                                                            {hasMultipleTypesByCode && <Form.Label>{t('forms.analysis.family')} *</Form.Label>}
                                                            <Select
                                                                className={getIn(touched.analysis, "family_id") && typeof getIn(errors.analysis, "family_id") === 'string' ? `imgErrorInvalidInput` : ``}
                                                                styles={{
                                                                    control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                                        ...styles,
                                                                        borderColor: (getIn(touched.analysis, "family_id") && typeof getIn(errors.analysis, "family_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                        boxShadow: state.isFocused ? (getIn(touched.analysis, "family_id") && typeof getIn(errors.analysis, "family_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                        '&:hover': {
                                                                            borderColor: (getIn(touched.analysis, "family_id") && typeof getIn(errors.analysis, "family_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                            boxShadow: state.isFocused ? (getIn(touched.analysis, "family_id") && typeof getIn(errors.analysis, "family_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                                        }
                                                                    }),
                                                                    option: (base: Data<string>) => ({
                                                                        ...base,
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        minHeight: 25
                                                                    }),
                                                                    menu: (styles: Data<string>) => ({
                                                                        ...styles,
                                                                        zIndex: '1031',
                                                                        width: mobile && hasMultipleTypesByCode ? '170%' : '100%',
                                                                        marginLeft: mobile && hasMultipleTypesByCode ? '-70%' : '0%'
                                                                    })
                                                                }}

                                                                noOptionsMessage={() => values.analysis.type_code === "FG" ? t('forms.analysis.fodderNotFound') : values.analysis.type_code === "RM" ? t('forms.analysis.raw_materialNotFound') : values.analysis.type_code === "FP" ? t('forms.analysis.feedNotFound') : ""}
                                                                value={values.analysis.family_id !== 0 ? families[values.analysis.family_id] : { id: 0, name: "" }}
                                                                name='analysis.family_id'
                                                                placeholder=""
                                                                getOptionLabel={(family: Family) => dbt(families, family.id, language.id, "name")}
                                                                getOptionValue={(family: Family) => family.id}
                                                                isClearable={true}
                                                                isDisabled={values.analysis.id_pdc !== null && controlPlans[values.analysis.laboratory_id] && controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc] && !hasRoleAll ? true : false}
                                                                options={Object.values(families)
                                                                    .filter(item => item.active && item.type_id === values.analysis.type_id && !item.pdc)
                                                                    .sort((a, b) => (dbt(families, a.id, language.id, "name")).localeCompare(dbt(families, b.id, language.id, "name")))}

                                                                onChange={async (family: Family) => {
                                                                    if (family) {
                                                                        const patternsOfFamily =  Object.values(patterns)
                                                                        .filter(item => {
                                                                            const patternFamily = Object.values(patternsFamilies).find(p => p.pattern_id === item.id && (!p.family_id || p.family_id === family.id) && values.analysis.type_id === p.type_id && item.active && !item.pdc)
                                                                            return !!patternFamily
                                                                        })

                                                                        const updated = {
                                                                            ...values,
                                                                            analysis: {
                                                                                ...values.analysis,
                                                                                family_id: family ? family.id : 0,
                                                                                family_comment: family.comment,
                                                                                pattern_id: patternsOfFamily.length === 1 ?  patternsOfFamily[0].id : 0,
                                                                                price: patternsOfFamily.length === 1 ? getPriceAugmenteSiUrgent(PricesCalculator.getPrice(priceLists, values.analysis.company_id, patternsOfFamily[0]), values.analysis) : null,
                                                                                cost:patternsOfFamily.length === 1 ?  patternsOfFamily[0].cost : null,
                                                                                period: patternsOfFamily.length === 1 ?  patternsOfFamily[0].period : null,
                                                                                weight: patternsOfFamily.length === 1 ?  patternsOfFamily[0].weight: null,
                                                                                analysis_packages: []
                                                                            },
                                                                            additionalAnalysis: values.additionalAnalysis ? {
                                                                                ...values.additionalAnalysis,
                                                                                family_id: family && family.ref_family_id ? family.ref_family_id : 0,
                                                                                family_comment: family.comment,
                                                                                pattern_id: 0,
                                                                                price: null,
                                                                                cost: null,
                                                                                period: null,
                                                                                weight: null,
                                                                                analysis_packages: []
                                                                            } : null
                                                                        }

                                                                        setControlFamilyDetail(family.details_required)
                                                                        await setValues(updated)

                                                                        if(patternsOfFamily.length === 1){
                                                                            setTotalPcpwBase({
                                                                                price: pcpwUtils.calculatePatternPrice(updated.analysis, []),
                                                                                period: pcpwUtils.calculatePatternPeriod(updated.analysis, []),
                                                                                weight: pcpwUtils.calculatePatternWeight(updated.analysis, [])
                                                                            })
                                                                        }else{
                                                                            setTotalPcpwAdditional({ price: null, period: null, weight: null })
                                                                            setTotalPcpwBase({ price: null, period: null, weight: null })
                                                                        }

                                                                    } else {
                                                                        await setFieldValue("analysis.family_id", 0)
                                                                        await setFieldValue("additionalAnalysis.family_id", 0)
                                                                        setTotalPcpwAdditional({ price: null, period: null, weight: null })
                                                                        setTotalPcpwBase({ price: null, period: null, weight: null })
                                                                    }
                                                                  
                                                                }}
                                                            />
                                                            {getIn(touched.analysis, "family_id") && typeof getIn(errors.analysis, "family_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "family_id")}</Form.Text> : null}
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                            )
                                        })()}
                                    </Row>

                                </Container>

                                {dbt(families, values.analysis.family_id, language.id, "comment") &&
                                    <Row>
                                        <Col>
                                            <Container className="p-1 mb-2 bg-info rounded-3">
                                                <p className="with-linebreak text-black fw-bold fst-italic">{dbt(families, values.analysis.family_id, language.id, "comment")} </p>
                                            </Container>
                                        </Col>
                                    </Row>
                                }

                                {values.analysis.family_id !== 0 && families[values.analysis.family_id].details_required === true &&
                                    <Container className="p-0">
                                        <Row>
                                            <Col lg>
                                                <Form.Group className="mb-2" controlId="formFamily_details">
                                                    <Form.Label>{displayDetailsLabel(values.analysis.type_code)} *</Form.Label>
                                                    <AutonomousField
                                                        value={values.analysis.family_details}
                                                        isInvalid={getIn(touched.analysis, "family_details") && !!getIn(errors.analysis, "family_details")}
                                                        storageName={'analysis.family_details'}
                                                        rows={3}
                                                        maxLength={255}
                                                        formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                        triggerRefreshCustomFields={triggerRefreshCustomFields}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "family_details")}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Container>
                                }

                                {customForm && customForm.fields.sample.map((line, lIndex) => {
                                    return (
                                        <Row key={lIndex}>
                                            {line.map((col, cIndex) => {
                                                return (
                                                    <Col lg key={cIndex} className={`${fields[col.field_id].data_type === "checkbox" ? 'd-flex align-items-end' : ''}`}>
                                                        <CustomField

                                                            laboratoryId={values.analysis.laboratory_id} // Current laboratory ID
                                                            familyId={values.analysis.family_id} // Current family ID
                                                            formId={customForm.id ?? 0} // Id of the form (used to retrieve matching datalist)
                                                            fieldId={col.field_id} // ID of the field to display
                                                            isReadOnly={false} // Boolean to know if field is editable or not
                                                            isRequired={col.required} // Boolean to know if the field is required or not
                                                            disabled={fields[col.field_id].storage_name === "denomination" ? isDenominationDisabled() : false} // Boolean to know if field is disabled
                                                            formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                            formikPrefix="analysis" // Optionnal field prefix in formik values (ie. "analysis." for "analysis.<field storage>" name resolution)
                                                            formikPrefixCopy={values.additionalAnalysis ? "additionalAnalysis" : ""}
                                                            triggerRefreshCustomFields={triggerRefreshCustomFields}

                                                        />
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    )
                                })}

                            </Container>
                        }

                        {((mobile && step === 3) || !mobile) && laboratories[values.analysis.laboratory_id].lab_type === 'client' &&
                            <Container className="p-2 bg-white mb-2 rounded-3" >
                                <Container className="p-0">
                                    <Row className={mobile ? "mb-4" : "mb-2"}>
                                        <Col className="pe-1 my-2">
                                            {!mobile ?
                                                <h4 className="text-primary fw-bold">{t('forms.analysis.nirAnalysis')}</h4>
                                                :
                                                <h5 className="text-primary fw-bold">{t('forms.analysis.nirAnalysis')}</h5>
                                            }
                                        </Col>
                                    </Row>
                                    {!mobile && hasRoleAll &&
                                        <Row className="pb-3"><Col lg className="lineTitle"></Col></Row>
                                    }
                                    {hasRoleAll &&
                                        <Row>
                                            <Col xs={mobile ? 12 : undefined}>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>
                                                        <span className="text-black fw-bold">{t('forms.analysis.nir_productgroup')}{t('colon')} </span>
                                                        {(() => {
                                                            const nir_type = types[values.analysis.type_id]?.nir_types.find(nt => nt.nir_code === values.analysis.nir_code)

                                                            return (
                                                                <span>{nir_type ? nir_type.nir_type_code : types[values.analysis.type_id]?.name}</span>
                                                            )
                                                        })()}
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={mobile ? 12 : undefined}>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>
                                                        <span className="text-black fw-bold">{t('forms.analysis.nir_product')}{t('colon')} </span>{values.analysis.family_id && families[values.analysis.family_id] ? families[values.analysis.family_id].name : ''}
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    }
                                </Container>
                            </Container>
                        }
                        {((mobile && step === 3) || !mobile) && laboratories[values.analysis.laboratory_id].lab_type === 'cargill' &&
                            <Container className="p-2 bg-white mb-2 rounded-3" >
                                <Container className="p-0">
                                    <Form.Group as={mobile ? Row : undefined} className={mobile ? "mb-4" : "mb-2"} controlId="formPattern">

                                        {!mobile ?
                                            <>
                                                <Row>
                                                    <Col xs="auto">
                                                        <h4 className="text-primary fw-bold">{t('forms.analysis.pattern')} *</h4>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <PcpwComponent
                                                            price={totalPcpwBase.price}
                                                            period={totalPcpwBase.period}
                                                            weight={totalPcpwBase.weight}
                                                            currency={values.analysis.pattern_id ? PricesCalculator.getCurrency(priceLists, values.analysis.company_id) : laboratories[values.analysis.laboratory_id].currency}
                                                            type={"pattern"}
                                                            size="lg">
                                                        </PcpwComponent>
                                                    </Col>
                                                </Row>
                                                <Row className="pb-3"><Col lg className="lineTitle"></Col></Row>
                                            </>
                                            :
                                            <Col className="pe-1 my-2">
                                                <h5 className="text-primary fw-bold">{t('forms.analysis.patternMobile')} *</h5>
                                            </Col>
                                        }
                                
                                        <Row className="m-0">

                                            <Col xs={!mobile ? 6 : 12} className={!mobile ? "p-0 pe-3" : "ps-0 my-1"}>
                                                <Select
                                                    className={getIn(touched.analysis, "pattern_id") && typeof getIn(errors.analysis, "pattern_id") === 'string' ? `imgErrorInvalidInput` : ``}
                                                    styles={{
                                                        control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                            ...styles,
                                                            borderColor: (getIn(touched.analysis, "pattern_id") && typeof getIn(errors.analysis, "pattern_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                            boxShadow: state.isFocused ? (getIn(touched.analysis, "pattern_id") && typeof getIn(errors.analysis, "pattern_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                            '&:hover': {
                                                                borderColor: (getIn(touched.analysis, "pattern_id") && typeof getIn(errors.analysis, "pattern_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                boxShadow: state.isFocused ? (getIn(touched.analysis, "pattern_id") && typeof getIn(errors.analysis, "pattern_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                            }
                                                        }),
                                                        option: (base: Data<string>) => ({
                                                            ...base,
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                            minHeight: 25
                                                        }),
                                                        menu: (styles: Data<string>) => ({
                                                            ...styles,
                                                            zIndex: '1031',
                                                            width: '100%',
                                                            marginLeft: '0%'
                                                        })
                                                    }}

                                                    noOptionsMessage={() => t('forms.analysis.patternNotFound')}
                                                    value={values.analysis.pattern_id !== 0 ? patterns[values.analysis.pattern_id] : { id: 0, name: "" }}
                                                    name='analysis.pattern_id'
                                                    placeholder=""
                                                    getOptionLabel={(pattern: Pattern) => dbt(patterns, pattern.id, language.id, "name")}
                                                    getOptionValue={(pattern: Pattern) => pattern.id}
                                                    isClearable={true}
                                                    isDisabled={Number(values.analysis.family_id) === 0 || (values.analysis.id_pdc !== null && controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc] && !hasRoleAll) ? true : false}
                                                    options={
                                                        Number(values.analysis.family_id) !== 0 &&
                                                        
                                                        Object.values(patterns)
                                                        .filter(item => {
                                                            const patternFamily = Object.values(patternsFamilies).find(p => p.pattern_id === item.id && (!p.family_id || p.family_id === values.analysis.family_id) && values.analysis.type_id === p.type_id && item.active && !item.pdc)
                                                            return !!patternFamily
                                                        })
                                                            .sort((a, b) => (dbt(patterns, a.id, language.id, "name")).localeCompare(dbt(patterns, b.id, language.id, "name")))
                                                    }

                                                    

                                                    onChange={async (pattern: Pattern) => {
                                                        if (pattern) {
                                                            const updatedAnalysis: Analysis = {
                                                                ...values.analysis,
                                                                pattern_id: pattern.id,
                                                                analysis_packages:[],
                                                                price:  getPriceAugmenteSiUrgent(PricesCalculator.getPrice(priceLists, values.analysis.company_id, pattern), values.analysis),
                                                                currency: PricesCalculator.getCurrency(priceLists, values.analysis.company_id),
                                                                cost: pattern.cost,
                                                                period: pattern.period,
                                                                weight: pattern.weight,
                                                            }

                                                            setValues(
                                                                {
                                                                    analysis: updatedAnalysis,
                                                                    additionalAnalysis: values.additionalAnalysis
                                                                }
                                                            )

                                                            setTotalPcpwBase({
                                                                price: pcpwUtils.calculatePatternPrice(updatedAnalysis, []),
                                                                period: pcpwUtils.calculatePatternPeriod(updatedAnalysis, []),
                                                                weight: pcpwUtils.calculatePatternWeight(updatedAnalysis, [])
                                                            })
                                                            setPackagesData(patternsPackageTestUtils.getPackageData(
                                                                updatedAnalysis,
                                                                'analysis',
                                                                patternsPackageTestUtils.convertAnalysisPackageToBasePackage(updatedAnalysis.analysis_packages),
                                                                updatedAnalysis.laboratory_id,
                                                                updatedAnalysis.company_id
                                                            ))
                                                        } else {
                                                            const updated = {
                                                                ...values,
                                                                analysis: {
                                                                    ...values.analysis,
                                                                    pattern_id: 0,
                                                                    price: null,
                                                                    cost: null,
                                                                    period: null,
                                                                    weight: null,
                                                                    analysis_packages: []
                                                                },
                                                                additionalAnalysis: values.additionalAnalysis ? {
                                                                    ...values.additionalAnalysis,
                                                                    pattern_id: 0,
                                                                    price: null,
                                                                    cost: null,
                                                                    period: null,
                                                                    weight: null,
                                                                    analysis_packages: []
                                                                } : null
                                                            }

                                                            await setValues(updated)
                                                            setTotalPcpwAdditional({ price: null, period: null, weight: null })
                                                            setTotalPcpwBase({ price: null, period: null, weight: null })
                                                        }
                                                    }}
                                                />
                                                {getIn(touched.analysis, "pattern_id") && typeof getIn(errors.analysis, "pattern_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "pattern_id")}</Form.Text> : null}
                                                {Number(values.analysis.family_id) === 0 ? <Form.Text className="invalid-feedback d-block">{values.analysis.type_code === "FG" ? t('forms.analysis.needFodderForSelectPattern') : values.analysis.type_code === "RM" ? t('forms.analysis.needRaw_materialForSelectPattern') : values.analysis.type_code === "FP" ? t('forms.analysis.needFeedForSelectPattern') : ""}</Form.Text> : null}

                                            </Col>

                                            {/* urgent (desktop) */}
                                            {!mobile &&
                                                <Col className="d-flex align-items-center">
                                                    <FormCheckBoxUrgent
                                                        analysis={values.analysis}
                                                        analysisFieldName='analysis'
                                                        changeStatutUrgent={changeStatutUrgentBase}
                                                        handleBlur={handleBlur}
                                                        markup={laboratories[values.analysis.laboratory_id].markup}
                                                    />
                                                </Col>
                                            }
                                        </Row>

                                        {mobile &&
                                            <Col>
                                                <PcpwComponent
                                                    price={totalPcpwBase.price}
                                                    period={totalPcpwBase.period}
                                                    weight={totalPcpwBase.weight}
                                                    currency={PricesCalculator.getCurrency(priceLists, values.analysis.company_id)}
                                                    type={"pattern"}>
                                                </PcpwComponent>
                                            </Col>
                                        }


                                        <Row className="m-0">
                                            <Col xs={12} lg={6} className="p-0">
                                                {values.analysis.pattern_id !== 0 && dbt(patterns, values.analysis.pattern_id, language.id, "option") &&
                                                    <Container className="mt-2 mb-1 p-1 rounded-3 bg-info">
                                                        <p className="with-linebreak text-black fw-bold fst-italic">{dbt(patterns, values.analysis.pattern_id, language.id, "option")} </p>
                                                    </Container>
                                                }
                                            </Col>
                                            {mobile &&
                                                <Col xs={12} className="p-0">
                                                    <FormCheckBoxUrgent
                                                        analysis={values.analysis}
                                                        analysisFieldName='analysis'
                                                        changeStatutUrgent={changeStatutUrgentBase}
                                                        handleBlur={handleBlur}
                                                        markup={laboratories[values.analysis.laboratory_id].markup}
                                                    />
                                                </Col>
                                            }

                                            <Col xs={12} lg={6} className="ps-lg-4 p-0">
                                                <Form.Group className="mb-2" controlId="formTranslationsName">
                                                    <Form.Label>{t('forms.analysis.comment')}</Form.Label>
                                                    <AutonomousField
                                                        value={values.analysis.comment}
                                                        isInvalid={getIn(touched.analysis, "comment") && !!getIn(errors.analysis, "comment")}
                                                        storageName={'analysis.comment'}
                                                        rows={3}
                                                        maxLength={1024}
                                                        formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                        triggerRefreshCustomFields={triggerRefreshCustomFields}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "comment")}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                    </Form.Group>

                                    {!mobile && values.analysis.analysis_packages.length > 0 &&
                                        <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                    }

                                    <Container>
                                        <Row>
                                            {values.analysis.analysis_packages.map((arrayItemPackage, indexPackage: number) => {
                                                if ((arrayItemPackage.analysis_package_tests !== undefined && arrayItemPackage.analysis_package_tests.length === 0) || (arrayItemPackage.analysis_package_tests !== undefined && arrayItemPackage.analysis_package_tests.filter((obj) => isAnalyteChecked(arrayItemPackage.package_id, obj.code_test, obj.code_analyte, values.analysis) === 'checked').length > 0)) {
                                                    return (
                                                        <Col key={arrayItemPackage.package_id} sm={!mobile ? 6 : 4} className="p-0">
                                                            <Col>
                                                                <Row>
                                                                    <Col xs={dbt(packages, arrayItemPackage.package_id, language.id, "name").length > 75 ? 9 : "auto"} className="text-break" > <span className="text-black fw-bold">{dbt(packages, arrayItemPackage.package_id, language.id, "name")}</span> </Col>
                                                                    <Col xs="auto">
                                                                        {!mobile &&
                                                                            <PcpwComponent
                                                                                price={arrayItemPackage.price}
                                                                                period={arrayItemPackage.period}
                                                                                currency={arrayItemPackage.currency || laboratories[values.analysis.laboratory_id].currency}
                                                                                size={"md"}>
                                                                            </PcpwComponent>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <Row> <Col xs="auto" className="text-break"> <span className="with-linebreak text-black fw-normal fst-italic">{arrayItemPackage.comment}</span></Col></Row>
                                                            </Col>  <ul>
                                                                
                                                                {PatternPackageTestUtils.getTestsListByPackage(arrayItemPackage.analysis_package_tests, values.analysis.laboratory_id, analytes, language, authUser, laboratories[values.analysis.laboratory_id].currency).map((arrayItemTests, index: number) => {
                                                                    const indexTest = values.analysis.analysis_packages[indexPackage].analysis_package_tests.findIndex(t => t.code_analyte === arrayItemTests.keys[0].code_analyte && t.code_test === arrayItemTests.keys[0].code_test)
                                                                    if (isAnalyteChecked(arrayItemPackage.package_id, arrayItemTests.keys[0].code_test, arrayItemTests.keys[0].code_analyte, values.analysis) === 'checked') {
                                                                        return (
                                                                            <li key={getAnalyteKey({code_test : arrayItemTests.keys[0].code_test, code_analyte: arrayItemTests.keys[0].code_analyte, laboratory_id: values.analysis.laboratory_id})}>
                                                                                <Row>
                                                                                    <Col xs={!mobile ? 7 : 12} className="text-break">
                                                                                        {arrayItemTests.displayName}
                                                                                       
                                                                                        {values.analysis.id_pdc === null &&
                                                                                            <Button size="sm" variant="link" className="p-0 ms-1"
                                                                                                onClick={async () => {
                                                                                                    let updatedTests = [...values.analysis.analysis_packages[indexPackage].analysis_package_tests]
                                                                                                    const updatedPackages = [...values.analysis.analysis_packages.filter((p, i) => i !== indexPackage)]
                                                                                                    updatedTests = updatedTests.filter((t, i) => i !== indexTest && (!t.analysis_group_name || t.analysis_group_name !== updatedTests[indexTest].analysis_group_name))
                                                                                                    if (updatedTests.length > 0) {
                                                                                                        await setFieldValue(`analysis.analysis_packages[${indexPackage}].analysis_package_tests`, updatedTests);
                                                                                                    } else {
                                                                                                        await setFieldValue(`analysis.analysis_packages`, values.analysis.analysis_packages.filter((p, i) => i !== indexPackage))
                                                                                                    }

                                                                                                    if (updatedTests.length > 0) {
                                                                                                        updatedPackages.push({ ...values.analysis.analysis_packages[indexPackage], analysis_package_tests: updatedTests })
                                                                                                    }

                                                                                                    setTotalPcpwBase({
                                                                                                        price: pcpwUtils.calculatePatternPrice(values.analysis, updatedPackages),
                                                                                                        period: pcpwUtils.calculatePatternPeriod(values.analysis, updatedPackages),
                                                                                                        weight: pcpwUtils.calculatePatternWeight(values.analysis, updatedPackages)
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                                <DeleteCircleRed className="colorHoverSvg align-text-top text-secondary p-0" width="15px" height="100%" />
                                                                                            </Button>
                                                                                        }

                                                                                        {arrayItemTests.theoretical_value != null ? " (VT: " + arrayItemTests.theoretical_value + ") " : null}

                                                                                        {arrayItemTests.numberTestRegroup === 1 && ([allowTheoreticalValueEnum.MANDATORY, allowTheoreticalValueEnum.OPTIONAL].includes(analytes[getAnalyteKey({code_test : arrayItemTests.keys[0].code_test, code_analyte: arrayItemTests.keys[0].code_analyte, laboratory_id: values.analysis.laboratory_id})].allow_theoretical_value) && (values.analysis.id_pdc === null || (values.analysis.id_pdc !== null &&  (userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin']) || (controlPlans[values.analysis.laboratory_id] && controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc] && controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc].control_plan_samples && controlPlans[values.analysis.laboratory_id][values.analysis.id_pdc].control_plan_samples?.find(sample => sample.id === values.analysis.id_sample)?.sample_packages.find(pckg => pckg.package_id === arrayItemPackage.package_id)?.sample_package_tests.find(test => test.code_test === arrayItemTests.keys[0].code_test && test.code_analyte === arrayItemTests.keys[0].code_analyte)?.theoretical_value === null )))))  &&
                                                                                            <Row className="">
                                                                                                <Col xs="auto" className="d-none d-lg-table pe-1 fw-normal fst-italic">
                                                                                                    {t('forms.analysis.theoretical_value')}{analytes[getAnalyteKey({code_test : arrayItemTests.keys[0].code_test, code_analyte: arrayItemTests.keys[0].code_analyte, laboratory_id: values.analysis.laboratory_id})].allow_theoretical_value === allowTheoreticalValueEnum.MANDATORY ? ' *' : ''} :
                                                                                                </Col>
                                                                                                <Col className="col ps-0 pe-0 align-self-center" >
                                                                                                    <Form.Control
                                                                                                        className={"form-control-xs w-50"}
                                                                                                        type="number"
                                                                                                        isInvalid={getIn(touched.analysis, `analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`) && typeof getIn(errors.analysis, `analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`) === 'string'}
                                                                                                        name={`analysis.analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`}
                                                                                                        onKeyDown={(evt: React.KeyboardEvent) => (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '+') && evt.preventDefault()}
                                                                                                        value={arrayItemTests.theoretical_value != null ? arrayItemTests.theoretical_value : undefined}
                                                                                                        onChange={(e) => {
                                                                                                            const decimal = e.target.value.split('.')[1] != null || e.target.value.split('.')[1] !== undefined ? e.target.value.split('.')[1] : ""
                                                                                                            setFieldValue(`analysis.analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`, e.target.value !== '' ? (Number(e.target.value) <= 9999999.999 && decimal.length <= 3 && Number(e.target.value) >= 0 ? Number(e.target.value) : arrayItemTests.theoretical_value) : null);
                                                                                                        }}
                                                                                                        onBlur={handleBlur} />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        }
                                                                                         {getIn(touched.analysis, `analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`) && typeof getIn(errors.analysis, `analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`) === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, `analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`)}</Form.Text> : null}

                                                                                   
                                                                                    </Col>
                                                                                    {!mobile &&
                                                                                        <Col xs={4}>

                                                                                        </Col>
                                                                                    }
                                                                                </Row>
                                                                            </li>
                                                                        )
                                                                    }
                                                                })}
                                                            </ul>
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </Row>
                                    </Container>

                                    {(values.analysis.id_pdc === null) &&
                                        <Button
                                            className={!mobile ? "" : "btn-block"}
                                            disabled={values.analysis.pattern_id > 0 && patternsPackageTestUtils.getPackagesInPattern(values.analysis, values.analysis.laboratory_id).length > 0 ? false : true}
                                            name="analysis.packages" variant={values.analysis.pattern_id > 0 &&  patternsPackageTestUtils.getPackagesInPattern(values.analysis, values.analysis.laboratory_id).length > 0 ? "secondary" : "dark"}
                                            onClick={() => {
                                                handleShowPackages(values.analysis)
                                            }}
                                        >
                                            {t('forms.analysis.management_packagesTests')}
                                        </Button>
                                    }
                                    {getIn(touched.analysis, "analysis_packages") && typeof getIn(errors.analysis, "analysis_packages") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "analysis_packages")}</Form.Text> : null}
                                </Container>
                            </Container>
                        }

                        {((mobile && step === 3) || !mobile) && !showCargillAnalysis && laboratories[values.analysis.laboratory_id].lab_type === 'client' && laboratories[values.analysis.laboratory_id].combined_analysis_allowed &&
                            <Button
                                className={!mobile ? "mb-2" : "mb-2 btn-block w-100"}
                                onClick={() => {
                                    handleShowCargillAnalysis()
                                }}
                            >
                                {t('forms.analysis.add_cargill_analysis')}
                            </Button>
                        }
                        {((mobile && step === 3) || !mobile) && showCargillAnalysis && values.additionalAnalysis &&
                            <Container className="p-2 bg-white mb-2 rounded-3" >
                                <Row>
                                    <Col xs={!mobile ? "auto" : "10"}>
                                        <h4 className="text-primary fw-bold">{t('forms.analysis.cargill_analysis') + ' : ' + laboratories[values.additionalAnalysis.laboratory_id].name + (values.additionalAnalysis.id !== 0 ? ' - ' + values.additionalAnalysis.id : '')}</h4>
                                    </Col>
                                    {!mobile &&
                                        <Col xs="auto">
                                            <PcpwComponent
                                                price={totalPcpwAdditional.price}
                                                period={totalPcpwAdditional.period}
                                                weight={totalPcpwAdditional.weight}
                                                currency={values.analysis.currency || laboratories[values.analysis.laboratory_id].currency}
                                                type={"pattern"}
                                                size="lg">
                                            </PcpwComponent>
                                        </Col>
                                    }
                                    <Col xs={!mobile ? "auto" : "2"} className='ms-auto'>
                                        <Button size="sm" variant="link"
                                            onClick={() => {
                                                handleCloseCargillAnalysis()
                                            }}
                                        >
                                            <DeleteCircleRed className="colorHoverSvg align-text-top text-secondary p-0" width="30px" height="100%" />
                                        </Button>
                                    </Col>
                                </Row>
                                {mobile &&
                                    <Row>
                                        <Col xs="auto">
                                            <PcpwComponent
                                                price={totalPcpwAdditional.price}
                                                period={totalPcpwAdditional.period}
                                                weight={totalPcpwAdditional.weight}
                                                currency={values.additionalAnalysis.currency || laboratories[values.additionalAnalysis.laboratory_id].currency}
                                                type={"pattern"}
                                                size="lg">
                                            </PcpwComponent>
                                        </Col>
                                    </Row>
                                }
                                <Row className="pb-2" ><Col lg className="lineTitle"></Col></Row>
                                <Container className="p-0">
                                    <Row>
                                        <Col>
                                            <Form.Label><span className="fw-bold">{t('forms.analysis.type')}{t('colon')}</span>{dbt(types, values.additionalAnalysis.type_id, authUser.language_id, 'name')}</Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Label><span className="fw-bold">{t('forms.analysis.family')}</span> {dbt(families, values.additionalAnalysis.family_id, authUser.language_id, 'name')}</Form.Label>
                                        </Col>
                                    </Row>

                                    {!mobile && (customFormAdditionalAnalysis && customFormAdditionalAnalysis.fields.client.length > 0) &&
                                        <>
                                            <Row>
                                                <Col lg>
                                                    <h5 className="text-primary fw-bold">{t('forms.analysis.customer')}</h5>
                                                </Col>
                                            </Row>
                                            <Row className="pb-2" ><Col lg className="lineTitle"></Col></Row>
                                        </>
                                    }

                                    {customFormAdditionalAnalysis && customFormAdditionalAnalysis.fields.client.map((line, lIndex) => {
                                        const additionalAnalysis = values.additionalAnalysis
                                        if (additionalAnalysis){
                                        return (
                                            <Row key={lIndex}>
                                                {line.map((col, cIndex) => {
                                                    return (
                                                        <Col lg key={cIndex} className={`${fields[col.field_id].data_type === "checkbox" ? 'd-flex align-items-end' : ''}`}>
                                                            <CustomField
                                                                laboratoryId={additionalAnalysis.laboratory_id} // Current laboratory ID
                                                                familyId={additionalAnalysis.family_id} // Current family ID
                                                                formId={customFormAdditionalAnalysis.id ?? 0} // Id of the form (used to retrieve matching datalist)
                                                                fieldId={col.field_id} // ID of the field to display
                                                                isReadOnly={false} // Boolean to know if field is editable or not
                                                                isRequired={col.required} // Boolean to know if the field is required or not
                                                                disabled={customForm ? getFlattenStaticAndDynamicFields(customForm).includes(col.field_id) && !['provider_id', 'denomination_id', 'origin_id'].includes(fields[col.field_id].storage_name || '') ? true : (fields[col.field_id].storage_name === "denomination" ? isDenominationDisabled() : false) : false} // Boolean to know if field is disabled
                                                                formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                                formikPrefix="additionalAnalysis" // Optionnal field prefix in formik values (ie. "analysis." for "analysis.<field storage>" name resolution)
                                                                triggerRefreshCustomFields={triggerRefreshCustomFields}
                                                            />
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        )
                                            }
                                    })}

                                </Container>
                                <Container className="p-0">
                                    {!mobile && customFormAdditionalAnalysis && customFormAdditionalAnalysis.fields.sample.length > 0 &&
                                        <>
                                            <Row>
                                                <Col lg>
                                                    <h5 className="text-primary fw-bold">{NameTypeAnalysis} : *</h5>
                                                </Col>
                                            </Row>
                                            <Row className="pb-2" ><Col lg className="lineTitle"></Col></Row>
                                        </>
                                    }
                                    {(() => {
                                        const additionalAnalysis = values.additionalAnalysis
                                        if (additionalAnalysis && customFormAdditionalAnalysis) {
                                            return customFormAdditionalAnalysis.fields.sample.map((line, lIndex) => {
                                                return (
                                                    <Row key={lIndex}>
                                                        {line.map((col, cIndex) => {
                                                            return (
                                                                <Col lg key={cIndex} className={`${fields[col.field_id].data_type === "checkbox" ? 'd-flex align-items-end' : ''}`}>
                                                                    <CustomField
                                                                        laboratoryId={additionalAnalysis.laboratory_id} // Current laboratory ID
                                                                        familyId={additionalAnalysis.family_id} // Current family ID
                                                                        formId={customFormAdditionalAnalysis.id ?? 0} // Id of the form (used to retrieve matching datalist)
                                                                        fieldId={col.field_id} // ID of the field to display
                                                                        isReadOnly={false} // Boolean to know if field is editable or not
                                                                        isRequired={col.required} // Boolean to know if the field is required or not
                                                                        disabled={customForm ? getFlattenStaticAndDynamicFields(customForm).includes(col.field_id) && !['provider_id', 'denomination_id', 'origin_id'].includes(fields[col.field_id].storage_name || '') ? true : (fields[col.field_id].storage_name === "denomination" ? isDenominationDisabled() : false) : false} // Boolean to know if field is disabled
                                                                        formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                                        formikPrefix="additionalAnalysis" // Optionnal field prefix in formik values (ie. "analysis." for "analysis.<field storage>" name resolution)
                                                                        triggerRefreshCustomFields={triggerRefreshCustomFields}
                                                                    />
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                )
                                            })
                                        }
                                    })()}
                                </Container>
                                <Container className="p-0">
                                    <Form.Group as={mobile ? Row : undefined} className={mobile ? "mb-4" : "mb-2"} controlId="formPattern">

                                        {!mobile ?
                                            <>
                                                <Row>
                                                    <Col xs="auto">
                                                        <h5 className="text-primary fw-bold">{t('forms.analysis.pattern')} *</h5>
                                                    </Col>
                                                </Row>
                                                <Row className="pb-3"><Col lg className="lineTitle"></Col></Row>
                                            </>
                                            :
                                            <Col className="pe-1 my-2">
                                                <h5 className="text-primary fw-bold">{t('forms.analysis.patternMobile')} *</h5>
                                            </Col>
                                        }

                                        <Row className="m-0">
                                     
                                            <Col xs={!mobile ? 6 : 12} className={!mobile ? "p-0 pe-3" : "ps-0 my-1"}>
                                                <Select
                                                    className={getIn(touched.additionalAnalysis, "pattern_id") && typeof getIn(errors.additionalAnalysis, "pattern_id") === 'string' ? `imgErrorInvalidInput` : ``}
                                                    styles={{
                                                        control: (styles: Data<string>, state: Data<string | number | boolean>) => ({
                                                            ...styles,
                                                            borderColor: (getIn(touched.additionalAnalysis, "pattern_id") && typeof getIn(errors.additionalAnalysis, "pattern_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                            boxShadow: state.isFocused ? (getIn(touched.additionalAnalysis, "pattern_id") && typeof getIn(errors.additionalAnalysis, "pattern_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                            '&:hover': {
                                                                borderColor: (getIn(touched.additionalAnalysis, "pattern_id") && typeof getIn(errors.additionalAnalysis, "pattern_id") === 'string') ? '#dc3545' : state.isFocused ? "#02e9ff" : "#ced4da",
                                                                boxShadow: state.isFocused ? (getIn(touched.additionalAnalysis, "pattern_id") && typeof getIn(errors.additionalAnalysis, "pattern_id") === 'string') ? '0 0 0 0.2rem rgb(158 43 47 / 25%);' : `0 0 0 0.2rem rgb(0 118 129 / 25%);` : 'none',
                                                            }
                                                        }),
                                                        option: (base: Data<string>) => ({
                                                            ...base,
                                                            paddingTop: 0,
                                                            paddingBottom: 0,
                                                            minHeight: 25
                                                        }),
                                                        menu: (styles: Data<string>) => ({
                                                            ...styles,
                                                            zIndex: '1031',
                                                            width: '100%',
                                                            marginLeft: '0%'
                                                        })
                                                    }}

                                                    noOptionsMessage={() => t('forms.analysis.patternNotFound')}
                                                    value={values.additionalAnalysis.pattern_id !== 0 ? patterns[values.additionalAnalysis.pattern_id] : { id: 0, name: "" }}
                                                    name='additionalAnalysis.pattern_id'
                                                    placeholder=""
                                                    getOptionLabel={(pattern: Pattern) => dbt(patterns, pattern.id, language.id, "name")}
                                                    getOptionValue={(pattern: Pattern) => pattern.id}
                                                    isClearable={true}
                                                    isDisabled={Number(values.additionalAnalysis.family_id) === 0 || (values.additionalAnalysis.id_pdc !== null && controlPlans[values.additionalAnalysis.laboratory_id][values.additionalAnalysis.id_pdc] && !hasRoleAll) ? true : false}
                                                    options={
                                                        Number(values.additionalAnalysis.family_id) !== 0 &&
                                                        Object.values(patterns)
                                                        .filter(item => {
                                                            const patternFamily = Object.values(patternsFamilies).find(p => p.pattern_id === item.id && (!p.family_id || p.family_id === values.additionalAnalysis?.family_id) && values.additionalAnalysis?.type_id === p.type_id && item.active && !item.pdc)
                                                            return !!patternFamily
                                                        })
                                                            .sort((a, b) => (dbt(patterns, a.id, language.id, "name")).localeCompare(dbt(patterns, b.id, language.id, "name")))
                                                    }

                                                    onChange={async (pattern: Pattern) => {
                                                        if (pattern) {
                                                            await setFieldValue("additionalAnalysis.pattern_id", pattern.id);
                                                            await setFieldValue("additionalAnalysis.price", values.additionalAnalysis ? PricesCalculator.getPrice(priceLists, values.additionalAnalysis.company_id, pattern): 0)
                                                            await setFieldValue("additionalAnalysis.cost", pattern.cost)
                                                            await setFieldValue("additionalAnalysis.period", pattern.period)
                                                            await setFieldValue("additionalAnalysis.weight", pattern.weight)           

                                                            if (values.additionalAnalysis) {

                                                                const updatedAnalysis: Analysis = {
                                                                    ...values.additionalAnalysis,
                                                                    pattern_id: pattern.id,
                                                                    price: values.additionalAnalysis ? getPriceAugmenteSiUrgent(PricesCalculator.getPrice(priceLists, values.additionalAnalysis.company_id, pattern), values.additionalAnalysis): 0,
                                                                    currency: values.additionalAnalysis ? PricesCalculator.getCurrency(priceLists, values.additionalAnalysis.company_id): '',
                                                                    cost: pattern.cost,
                                                                    period: pattern.period,
                                                                    weight: pattern.weight,

                                                                }

                                                                setTotalPcpwBase({
                                                                    price: pcpwUtils.calculatePatternPrice(updatedAnalysis, []),
                                                                    period: pcpwUtils.calculatePatternPeriod(updatedAnalysis, []),
                                                                    weight: pcpwUtils.calculatePatternWeight(updatedAnalysis, [])
                                                                })
                                                                setPackagesData(patternsPackageTestUtils.getPackageData(
                                                                    values.additionalAnalysis,
                                                                    'analysis',
                                                                    patternsPackageTestUtils.convertAnalysisPackageToBasePackage(values.additionalAnalysis.analysis_packages),
                                                                    values.additionalAnalysis.laboratory_id,
                                                                    values.additionalAnalysis.company_id
                                                                ))
                                                            }

                                                            setFieldTouched("additionalAnalysis.pattern_id", true)
                                                        } else {
                                                            await setFieldValue("additionalAnalysis.pattern_id", undefined);    
                                                        }
                                                    }}
                                                />
                                                {getIn(touched.additionalAnalysis, "pattern_id") && typeof getIn(errors.additionalAnalysis, "pattern_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.additionalAnalysis, "pattern_id")}</Form.Text> : null}
                                                {Number(values.additionalAnalysis.family_id) === 0 ? <Form.Text className="invalid-feedback d-block">{values.additionalAnalysis.type_code === "FG" ? t('forms.analysis.needFodderForSelectPattern') : values.additionalAnalysis.type_code === "RM" ? t('forms.analysis.needRaw_materialForSelectPattern') : values.analysis.type_code === "FP" ? t('forms.analysis.needFeedForSelectPattern') : ""}</Form.Text> : null}

                                            </Col>

                                            {/* urgent (desktop) */}
                                            {!mobile &&
                                                <Col className="d-flex align-items-center">
                                                    <FormCheckBoxUrgent
                                                        analysis={values.additionalAnalysis}
                                                        analysisFieldName='additionalAnalysis'
                                                        changeStatutUrgent={changeStatutUrgentAdditional}
                                                        handleBlur={handleBlur}
                                                        markup={laboratories[values.additionalAnalysis.laboratory_id].markup}
                                                    />
                                                </Col>
                                            }
                                        </Row>

                                        {mobile &&
                                            <Col>
                                                <PcpwComponent
                                                    price={totalPcpwAdditional.price}
                                                    period={totalPcpwAdditional.period}
                                                    weight={totalPcpwAdditional.weight}
                                                    currency={values.additionalAnalysis.currency || laboratories[values.additionalAnalysis.laboratory_id].currency}
                                                    type={"pattern"}>
                                                </PcpwComponent>
                                            </Col>
                                        }


                                        <Row className="m-0">
                                            <Col xs={12} lg={6} className="p-0">
                                                {values.additionalAnalysis.pattern_id !== 0 && dbt(patterns, values.additionalAnalysis.pattern_id, language.id, "option") &&
                                                    <Container className="mt-2 mb-1 p-1 rounded-3 bg-info">
                                                        <p className="with-linebreak text-black fw-bold fst-italic">{dbt(patterns, values.additionalAnalysis.pattern_id, language.id, "option")} </p>
                                                    </Container>
                                                }
                                            </Col>
                                            {mobile &&
                                                <Col xs={12} className="p-0">
                                                    <FormCheckBoxUrgent
                                                        analysis={values.additionalAnalysis}
                                                        analysisFieldName='additionalAnalysis'
                                                        changeStatutUrgent={changeStatutUrgentAdditional}
                                                        handleBlur={handleBlur}
                                                        markup={laboratories[values.additionalAnalysis.laboratory_id].markup}
                                                    />
                                                </Col>
                                            }

                                            <Col xs={12} lg={6} className="ps-lg-4 p-0">
                                                <Form.Group className="mb-2" controlId="formTranslationsName">
                                                    <Form.Label>{t('forms.analysis.comment')}</Form.Label>
                                                    <AutonomousField
                                                        value={values.additionalAnalysis.comment}
                                                        isInvalid={getIn(touched.additionalAnalysis, "comment") && !!getIn(errors.additionalAnalysis, "comment")}
                                                        storageName={'additionalAnalysis.comment'}
                                                        rows={3}
                                                        maxLength={1024}
                                                        formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                        triggerRefreshCustomFields={triggerRefreshCustomFields}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{getIn(errors.additionalAnalysis, "comment")}</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                    </Form.Group>

                                    {!mobile && values.additionalAnalysis.analysis_packages.length > 0 &&
                                        <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                    }

                                    <Container>
                                        <Row>
                                            {values.additionalAnalysis.analysis_packages.map((arrayItemPackage, indexPackage: number) => {
                                                if (values.additionalAnalysis && ((arrayItemPackage.analysis_package_tests !== undefined && arrayItemPackage.analysis_package_tests.length === 0 && isPackageChecked(arrayItemPackage.package_id, values.additionalAnalysis) === 'checked') || (arrayItemPackage.analysis_package_tests !== undefined && arrayItemPackage.analysis_package_tests.filter((obj) => isAnalyteChecked(arrayItemPackage.package_id, obj.code_test, obj.code_analyte, values.additionalAnalysis) === 'checked').length > 0))) {
                                                    return (
                                                        <Col key={arrayItemPackage.package_id} sm={!mobile ? 6 : 4} className="p-0">
                                                            <Col>
                                                                <Row>
                                                                    <Col xs={dbt(packages, arrayItemPackage.package_id, language.id, "name").length > 75 ? 9 : "auto"} className="text-break" > <span className="text-black fw-bold">{dbt(packages, arrayItemPackage.package_id, language.id, "name")}</span> </Col>
                                                                    <Col xs="auto">
                                                                        {!mobile &&
                                                                            <PcpwComponent
                                                                                price={arrayItemPackage.price}
                                                                                period={arrayItemPackage.period}
                                                                                currency={arrayItemPackage.currency || laboratories[values.additionalAnalysis.laboratory_id].currency}
                                                                                size={"md"}>
                                                                            </PcpwComponent>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <Row> <Col xs="auto" className="text-break"> <span className="with-linebreak text-black fw-normal fst-italic">{arrayItemPackage.comment}</span></Col></Row>
                                                            </Col>  <ul>
                                                                {PatternPackageTestUtils.getTestsListByPackage(arrayItemPackage.analysis_package_tests, values.additionalAnalysis.laboratory_id, analytes, language, authUser, laboratories[values.additionalAnalysis.laboratory_id].currency).map((arrayItemTests, index: number) => {
                                                                    const additionalAnalysis = values.additionalAnalysis
                                                                    const indexTest = values.analysis.analysis_packages[indexPackage].analysis_package_tests.findIndex(t => t.code_analyte === arrayItemTests.keys[0].code_analyte && t.code_test === arrayItemTests.keys[0].code_test)
                                                                    if (additionalAnalysis && isAnalyteChecked(arrayItemPackage.package_id, arrayItemTests.keys[0].code_test, arrayItemTests.keys[0].code_analyte, additionalAnalysis) === 'checked') {
                                                                        return (
                                                                            <li key={getAnalyteKey({code_test : arrayItemTests.keys[0].code_test, code_analyte: arrayItemTests.keys[0].code_analyte, laboratory_id: values.additionalAnalysis?.laboratory_id || 0})}>
                                                                                <Row>
                                                                                    <Col xs={!mobile ? 7 : 12} className="text-break">
                                                                                        {arrayItemTests.displayName}

                                                                                        {additionalAnalysis && (additionalAnalysis.id_pdc === null) &&
                                                                                            <Button size="sm" variant="link" className="p-0 ms-1"
                                                                                                onClick={async () => {
                                                                                                    let updatedTests = [...additionalAnalysis.analysis_packages[indexPackage].analysis_package_tests]
                                                                                                    const updatedPackages = [...additionalAnalysis.analysis_packages.filter((p, i) => i !== indexPackage)]
                                                                                                    updatedTests = updatedTests.filter((t, i) => i !== indexTest && (!t.analysis_group_name || t.analysis_group_name !== updatedTests[indexTest].analysis_group_name))
                                                                                                    if (updatedTests.length > 0) {
                                                                                                        await setFieldValue(`additionalAnalysis.analysis_packages[${indexPackage}].analysis_package_tests`, updatedTests);
                                                                                                    } else {
                                                                                                        await setFieldValue(`additionalAnalysis.analysis_packages`, additionalAnalysis.analysis_packages.filter((p, i) => i !== indexPackage))
                                                                                                    }

                                                                                                    if (updatedTests.length > 0) {
                                                                                                        updatedPackages.push({ ...additionalAnalysis.analysis_packages[indexPackage], analysis_package_tests: updatedTests })
                                                                                                    }

                                                                                                    setTotalPcpwAdditional({
                                                                                                        price: pcpwUtils.calculatePatternPrice(additionalAnalysis, updatedPackages),
                                                                                                        period: pcpwUtils.calculatePatternPeriod(additionalAnalysis, updatedPackages),
                                                                                                        weight: pcpwUtils.calculatePatternWeight(additionalAnalysis, updatedPackages)
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                                <DeleteCircleRed className="colorHoverSvg align-text-top text-secondary p-0" width="15px" height="100%" />
                                                                                            </Button>
                                                                                        }

                                                                                        {arrayItemTests.theoretical_value != null ? " (VT: " + arrayItemTests.theoretical_value + ") " : null}
                                                                                        {arrayItemTests.numberTestRegroup === 1 && ([allowTheoreticalValueEnum.MANDATORY, allowTheoreticalValueEnum.OPTIONAL].includes(analytes[getAnalyteKey({code_test : arrayItemTests.keys[0].code_test, code_analyte: arrayItemTests.keys[0].code_analyte, laboratory_id: values.additionalAnalysis?.laboratory_id || 0})].allow_theoretical_value) && (additionalAnalysis.id_pdc === null || (additionalAnalysis.id_pdc !== null &&  (userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin', 'MyProvilab_ROLE_laboratory', 'MyProvilab_ROLE_laboratoryAdmin', 'MyProvilab_ROLE_customerLabAdmin']) || (controlPlans[additionalAnalysis.laboratory_id] && controlPlans[additionalAnalysis.laboratory_id][additionalAnalysis.id_pdc] && controlPlans[additionalAnalysis.laboratory_id][additionalAnalysis.id_pdc].control_plan_samples && controlPlans[additionalAnalysis.laboratory_id][additionalAnalysis.id_pdc].control_plan_samples?.find(sample => sample.id === additionalAnalysis.id_sample)?.sample_packages.find(pckg => pckg.package_id === arrayItemPackage.package_id)?.sample_package_tests.find(test => test.code_test === arrayItemTests.keys[0].code_test && test.code_analyte === arrayItemTests.keys[0].code_analyte)?.theoretical_value === null)))))  &&
                                                                                            <Row className="">
                                                                                                <Col xs="auto" className="d-none d-lg-table pe-1 fw-normal fst-italic">
                                                                                                {t('forms.analysis.theoretical_value')}{analytes[getAnalyteKey({code_test : arrayItemTests.keys[0].code_test, code_analyte: arrayItemTests.keys[0].code_analyte, laboratory_id: values.additionalAnalysis?.laboratory_id || 0})].allow_theoretical_value === allowTheoreticalValueEnum.MANDATORY ? ' *' : ''} :
                                                                                                </Col>
                                                                                                <Col className="col ps-0 pe-0 align-self-center" >
                                                                                                    <Form.Control
                                                                                                        className={"form-control-xs w-50"}
                                                                                                        type="number"
                                                                                                        isInvalid={getIn(touched.additionalAnalysis, `analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`) && typeof getIn(errors.additionalAnalysis, `analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`) === 'string'}

                                                                                                        name={`additionalAnalysis.analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`}
                                                                                                        onKeyDown={(evt: React.KeyboardEvent) => (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '+') && evt.preventDefault()}
                                                                                                        value={arrayItemTests.theoretical_value != null ? arrayItemTests.theoretical_value : undefined}
                                                                                                        onChange={(e) => {
                                                                                                            const decimal = e.target.value.split('.')[1] != null || e.target.value.split('.')[1] !== undefined ? e.target.value.split('.')[1] : ""
                                                                                                            setFieldValue(`additionalAnalysis.analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`,e.target.value !== '' ?  (Number(e.target.value) <= 9999999.999 && decimal.length <= 3 && Number(e.target.value) >= 0 ? e.target.value : arrayItemTests.theoretical_value) : null);
                                                                                                        }}
                                                                                                        onBlur={handleBlur} />
                                                                                                    {getIn(touched.additionalAnalysis, `analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`) && typeof getIn(errors.additionalAnalysis, `analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`) === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.additionalAnalysis, `analysis_packages[${indexPackage}].analysis_package_tests[${indexTest}].theoretical_value`)}</Form.Text> : null}

                                                                                                </Col>
                                                                                            </Row>
                                                                                        }
                                                                                    </Col>
                                                                                    {!mobile &&
                                                                                        <Col xs={4}>
                                                                                            <PcpwComponent
                                                                                                price={arrayItemPackage.analysis_package_tests[indexTest].price}
                                                                                                period={arrayItemPackage.analysis_package_tests[indexTest].period}
                                                                                                currency={arrayItemPackage.analysis_package_tests[indexTest].currency || laboratories[values.additionalAnalysis!.laboratory_id].currency}
                                                                                                size={"sm"}>
                                                                                            </PcpwComponent>
                                                                                        </Col>
                                                                                    }
                                                                                </Row>
                                                                            </li>
                                                                        )
                                                                    } else {
                                                                        return null
                                                                    }
                                                                })}
                                                            </ul>
                                                        </Col>
                                                    )
                                                }
                                            })}
                                        </Row>
                                    </Container>

                                    {(values.additionalAnalysis.id_pdc === null) &&
                                        <Button
                                            className={!mobile ? "" : "btn-block"}
                                            disabled={values.additionalAnalysis.pattern_id > 0  && patternsPackageTestUtils.getPackagesInPattern(values.additionalAnalysis, values.additionalAnalysis.laboratory_id).length > 0 ? false : true} 
                                            name="additionalAnalysis.packages" 
                                            variant={values.additionalAnalysis.pattern_id > 0  && patternsPackageTestUtils.getPackagesInPattern(values.additionalAnalysis, values.additionalAnalysis.laboratory_id).length > 0 ? "secondary" : "dark"}
                                            onClick={() => {
                                                if (values.additionalAnalysis) {
                                                    handleShowPackages(values.additionalAnalysis)
                                                }
                                            }}
                                        >
                                            {t('forms.analysis.management_packagesTests')}
                                        </Button>
                                    }
                                    {getIn(touched.additionalAnalysis, "analysis_packages") && typeof getIn(errors.additionalAnalysis, "analysis_packages") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.additionalAnalysis, "analysis_packages")}</Form.Text> : null}
                                </Container>
                            </Container>
                        }

                        {((mobile && step === 3) || !mobile) &&
                            <span>
                                {getIn(touched.analysis, "company_id") && typeof getIn(errors.analysis, "company_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "company_id")}</Form.Text> : null}
                                {getIn(touched.analysis, "employee_id") && typeof getIn(errors.analysis, "employee_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "employee_id")}</Form.Text> : null}
                                {getIn(touched.analysis, "farmer_id") && typeof getIn(errors.analysis, "farmer_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "farmer_id")}</Form.Text> : null}
                                {getIn(touched.analysis, "family_id") && typeof getIn(errors.analysis, "family_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "family_id")}</Form.Text> : null}
                                {getIn(touched.analysis, "family_details") && typeof getIn(errors.analysis, "family_details") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "family_details")}</Form.Text> : null}
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "family_details")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "denomination")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "harvest_date")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "client_reference")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "sample_date")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "silo")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "sample_comment")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "family_details")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "denomination")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "delivery_date")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "client_reference")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "sample_date")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "provider")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "origin")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "lot")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "family_details")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "denomination")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "manufacturing_date")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "client_reference")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "sample_date")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "lot")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "factory")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "formula_code")}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "farmer")}</Form.Control.Feedback>
                                {getIn(touched.analysis, "pattern_id") && typeof getIn(errors.analysis, "pattern_id") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "pattern_id")}</Form.Text> : null}
                                <Form.Control.Feedback type="invalid">{getIn(errors.analysis, "comment")}</Form.Control.Feedback>
                                {getIn(touched.analysis, "analysis_packages") && typeof getIn(errors.analysis, "analysis_packages") === 'string' ? <Form.Text className="invalid-feedback d-block">{getIn(errors.analysis, "analysis_packages")}</Form.Text> : null}
                            </span>
                        }
                        
                        <Row>
                            {mobile && step === minStep &&
                                <Col xs="auto">
                                    <GoBack to="/secure/analysis" label={t('analysis.return_to_my_list_analysis')} />
                                </Col>
                            }
                            {mobile && step > minStep &&
                                <Col >
                                    <Button variant="secondary" onClick={() => { if (step > minStep) { setstep(step - 1) } }} > {t('forms.analysis.back')} </Button>
                                </Col>
                            }
                            {mobile && step < maxStep &&
                                <Col className="text-end">
                                    {(() => {
                                        let requiredField: string[] = []
                                        switch (step) {
                                            case 1:
                                                requiredField.push('analysis.company_id')
                                                requiredField.push('analysis.employee_id')
                                                if (values.analysis.type_code === "FG") {
                                                    requiredField.push('analysis.farmer_id')
                                                }
                                                if (customForm) {
                                                    requiredField = requiredField.concat(getRequiredFieldsStorageNames(customForm.fields.client))
                                                }
                                                break;
                                            case 2:
                                                requiredField.push('analysis.family_id')
                                                if (values.analysis.family_id !== 0 && families[values.analysis.family_id].details_required === true) {
                                                    requiredField.push('analysis.family_details')
                                                }
                                                if (customForm) {
                                                    requiredField = requiredField.concat(getRequiredFieldsStorageNames(customForm.fields.sample))
                                                }
                                                break;
                                        }
                                        return (
                                            <Button variant={requiredField.reduce<boolean>((acc, cur) => acc || (typeof getIn(errors, cur) === "string"), false) ? "dark" : "secondary"}
                                                onClick={async () => {

                                                    for (const field of requiredField) {
                                                        await setFieldTouched(field, true)
                                                    }
                                                    const errorsObj = await validateForm()

                                                    if (!requiredField.reduce<boolean>((acc, cur) => acc || (typeof getIn(errorsObj, cur) === "string"), false)) {
                                                        if (step < maxStep) {
                                                            setstep(step + 1)
                                                        }
                                                    }
                                                }}
                                            >
                                                {t('forms.analysis.next')}
                                            </Button>
                                        )
                                    })()
                                    }

                                </Col>
                            }
                            {step === 3 && (!analysisDB || analysisDB.id === 0) && mobile &&
                                <Col xs="auto" className="text-end">
                                    <Button disabled={isSubmitting} variant="success" type="submit" >{t('buttons.add')}</Button>
                                </Col>
                            }
                            {step === 3 && (
                                (['registered'].includes(values.analysis.status) && grantUpdateRegistered)
                                ||
                                (['received_improper'].includes(values.analysis.status) && grantUpdateRegistered && values.analysis.improper_id && impropers[values.analysis.improper_id].editable)
                                ||
                                (['received_improper', 'received_compliant', 'received_fixed'].includes(values.analysis.status) && grantUpdateReceived)
                                ||
                                (['partial', 'done'].includes(values.analysis.status) && grantUpdateDone)

                            ) && analysisDB && analysisDB.id !== 0 && mobile &&
                                <Col xs="auto" className="text-end">
                                    <Button disabled={isSubmitting} className="btn-block" variant="success" type="submit" >{t('forms.analysis.modifyMobile')}</Button>
                                </Col>
                            }

                            {!mobile && values.analysis.id_pdc === null && values.analysis.id === 0 &&
                                <Col>
                                    <GoBack to="/secure/analysis" label={t('analysis.return_to_my_list_analysis')} />
                                </Col>
                            }
                            {values.analysis.id === 0 && values.analysis.id_pdc !== null &&
                                <Col>
                                    <GoBack to={`/secure/pdc/${values.analysis.laboratory_id}/${values.analysis.id_pdc}`} label={t('pdc.return_to_my_pdc')} />
                                </Col>
                            }

                            {(
                                (['registered'].includes(values.analysis.status) && grantUpdateRegistered)
                                ||
                                (['received_improper'].includes(values.analysis.status) && grantUpdateRegistered && values.analysis.improper_id && impropers[values.analysis.improper_id].editable)
                                ||
                                (['received_improper', 'received_compliant', 'received_fixed'].includes(values.analysis.status) && grantUpdateReceived)
                                ||
                                (['partial', 'done'].includes(values.analysis.status) && grantUpdateDone)

                            ) && !mobile && analysisDB && analysisDB.id !== 0 &&
                                <Container>
                                    <Row>
                                        <Col>
                                            <GoBack to="/secure/analysis" label={t('analysis.return_to_my_list_analysis')} />
                                        </Col>
                                        <Col>
                                            <Row className="flex-row-reverse">
                                                <Col md="auto" className="mt-2 text-end" >
                                                    <Button disabled={isSubmitting} variant="success" type="submit" >{t('forms.analysis.modify')}</Button>
                                                </Col>
                                                <Col md="auto" className="mt-2 pe-0 text-end" >
                                                    <Link className="btn btn-danger" to={`/secure/analysis/${values.analysis.laboratory_id}/${values.analysis.id}`} onClick={() => { setValues(initialValues) }} >{t('forms.analysis.cancel_edit')}</Link>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            }
                            {(analysisDB && analysisDB.id === 0 && analysisDB.id_pdc !== null) && !mobile &&
                                <Col className="text-end">
                                    <Button disabled={isSubmitting} variant="success" type="submit">{t('pdc.use')}</Button>
                                </Col>
                            }
                            {(!analysisDB || analysisDB.id === 0 && analysisDB.id_pdc === null) && !mobile &&
                                <Col className="text-end">
                                    <Button disabled={isSubmitting} variant="secondary" type="submit">{t('buttons.add')}</Button>
                                </Col>
                            }
                        </Row>
                    </Container>
                    
                    {packagesData && packagesData.packages.length > 0 &&
                        <ModalPackagesTests
                            data={packagesData}
                            companyId={values.analysis.company_id}
                            priceLists={priceLists}
                            setShowPackages={setShowPackages}
                            showPackages={showPackages}
                            submitValues={values.additionalAnalysis ? handleTestsSelection(values.additionalAnalysis, 'additionalAnalysis') : handleTestsSelection(values.analysis, 'analysis')}
                            type={"analysis"}
                            itemLabId={values.additionalAnalysis ? values.additionalAnalysis.laboratory_id || 0 :values.analysis.laboratory_id}
                        />
                    }

                </Form>
            </Container>
        </Container>

    )

}

export default withTranslation()(AnalysisForm)

