import React, { useState, useEffect, useCallback, useRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Row, Container, Button, } from 'react-bootstrap';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Title,
    TimeScale,
    LineController
} from 'chart.js';
import { Scatter, getElementAtEvent } from 'react-chartjs-2';
import "chartjs-adapter-date-fns";
import ChartZoom from 'chartjs-plugin-zoom';
import { DashboardSamplesData, getKeyForDashbpardAnalysisHeader, setSampleSelectedInGraphs } from '../../../state/dashboardSlice';
import { ReactComponent as DotsIcon } from 'bootstrap-icons/icons/three-dots.svg'
import { Data } from '../../../types/types';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, Title, TimeScale, LineController, ChartZoom);

interface DashboardGenericGraphComponentContainerProps extends WithTranslation {
    dashboardName: string,
    analyteId: string
    values: {
        selected_values: {
            analyte_id: string,
            value: number
        }[];
        commentary: string;
    }
}

const DashboardGenericGraphComponent: React.FC<DashboardGenericGraphComponentContainerProps> = ({ t, dashboardName, analyteId, values }) => {
    const dispatch = useDispatch()

    // user state
    const { user: authUser } = useSelector((state: RootState) => state.authUser)
    const theme = useSelector((state: RootState) => state.authUser.theme)

    // languages
    const languages = useSelector((state: RootState) => state.config.languages.data)
    const language = Object.values(languages).find(lg => lg.id === Number(authUser.language_id))

    // samples state
    const paramsFromComponents = useSelector((state: RootState) => state.dashboard.paramsFromComponents)
    const groupBy = useSelector((state: RootState) => state.dashboard.groupBy)
    const samplesForData = useSelector((state: RootState) => state.dashboard.samplesForData)
    const initialSamplesForData = useSelector((state: RootState) => state.dashboard.initialSamplesForData)
    const resultsHeader = useSelector((state: RootState) => state.dashboard.resultsHeaders)
    const datasCalculated = useSelector((state: RootState) => state.dashboard.dataOfAnalytes)
    const averageOutliers = useSelector((state: RootState) => state.dashboard.averageOutliers)
    const averageOutliersResults = useSelector((state: RootState) => state.dashboard.averageOutliersResults)
    const initialDatasCalculated = useSelector((state: RootState) => state.dashboard.initialDataOfAnalytes)
    const activeMatrix = useSelector((state: RootState) => state.matrix.matrix.activeMatrix)

    // local state
    const [data, setData] = useState<any>({ datasets: [] })

    const [sampleSet, setSampleSet] = useState<Data<DashboardSamplesData>>({})

    useEffect(() => {
        paramsFromComponents.dataShow === 'initial_filtering' ? setSampleSet(initialSamplesForData) : setSampleSet(samplesForData)
    }, [samplesForData, paramsFromComponents.dataShow])

    // Change datas according to parameters
    useEffect(() => {
        const datasetsForGraph: any = []


        // add dataset on different color for selected sample
        if (paramsFromComponents.sampleSelectedInGraphs !== "") {
            datasetsForGraph.push({
                type: 'scatter',
                label: 'SelectedSample',
                data: Object.values(sampleSet).filter(sfd => sfd.display_id === paramsFromComponents.sampleSelectedInGraphs && sfd.results.find(r => r.selected && r.analyte_id.toUpperCase() === analyteId.toUpperCase()) !== undefined).map(sfd => { return { x: sfd[paramsFromComponents.date], y: Number(sfd.results.find(r => r.analyte_id.toUpperCase() === analyteId.toUpperCase())?.default_unit_result), label: sfd.display_id } }),
                backgroundColor: '#000000',
                borderColor: '#000000',
                showLine: paramsFromComponents.connectDots,
                spanGaps: true
            })
        }

        // group by
        if (groupBy.field && groupBy.field !== '' && groupBy.values && groupBy.values.length > 0) {
            groupBy.values.forEach(v => {
                datasetsForGraph.push({
                    type: 'scatter',
                    label: `GroupBy ${groupBy.field}`,
                    data: Object.values(sampleSet).filter(sfd => sfd.display_id !== paramsFromComponents.sampleSelectedInGraphs && sfd[groupBy.field as keyof typeof sfd]?.toString() === v.value && sfd.results.find(r => r.selected && r.analyte_id.toUpperCase() === analyteId.toUpperCase() && (!r.outlier && !averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sfd)}#${r.analyte_id.toUpperCase()}`])) !== undefined && (!sfd.outlier && !averageOutliers[`${getKeyForDashbpardAnalysisHeader(sfd)}`])).map(sfd => {return {x: sfd[paramsFromComponents.date], y: Number(sfd.results.find(r => r.analyte_id.toUpperCase() === analyteId.toUpperCase())?.default_unit_result), label: sfd.display_id}}),
                    backgroundColor: v.color,
                    borderColor: v.color,
                    showLine: paramsFromComponents.connectDots,
                    spanGaps: true
                })
            });
        }

        // if show trend
        if(!(groupBy.field && groupBy.field !== '' && groupBy.values && groupBy.values.length > 0)){
            datasetsForGraph.push({
                type: 'scatter',
                label: analyteId,
                data: Object.values(sampleSet).filter(sfd => sfd.display_id !== paramsFromComponents.sampleSelectedInGraphs && sfd.results.find(r => r.selected && r.analyte_id.toUpperCase() === analyteId.toUpperCase() && (!r.outlier && !averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sfd)}#${r.analyte_id.toUpperCase()}`])) !== undefined && (!sfd.outlier && !averageOutliers[`${getKeyForDashbpardAnalysisHeader(sfd)}`])).map(sfd => { return { x: sfd[paramsFromComponents.date], y: Number(sfd.results.find(r => r.analyte_id.toUpperCase() === analyteId.toUpperCase())?.default_unit_result), label: sfd.display_id } }),
                backgroundColor: theme.themePrimary,
                borderColor: theme.themePrimary,
                showLine: paramsFromComponents.connectDots,
                spanGaps: true
            })
        }

        // if show trend
        if(paramsFromComponents.trend){
            datasetsForGraph.push({
                type: 'line',
                label: 'trend',
                data: datasCalculated[analyteId.toUpperCase()].trend,
                borderColor: theme.themePrimaryLight,
                backgroundColor: theme.themePrimaryLight
            })
        }

        // if show average
        if (paramsFromComponents.average) {
            datasetsForGraph.push({
                type: 'line',
                label: 'average',
                data: datasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'selection' ? [
                    { x: datasCalculated[analyteId.toUpperCase()].minDate, y: datasCalculated[analyteId.toUpperCase()].average, label: 'average' },
                    { x: datasCalculated[analyteId.toUpperCase()].maxDate, y: datasCalculated[analyteId.toUpperCase()].average, label: 'average' }
                ] : initialDatasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'initial_filtering' ? [
                    { x: initialDatasCalculated[analyteId.toUpperCase()].minDate, y: initialDatasCalculated[analyteId.toUpperCase()].average, label: 'average' },
                    { x: initialDatasCalculated[analyteId.toUpperCase()].maxDate, y: initialDatasCalculated[analyteId.toUpperCase()].average, label: 'average' }
                ] : [],
                borderColor: theme.themeSecondary,
                backgroundColor: theme.themeSecondary
            })
        }

        // if show average 1
        if (paramsFromComponents.average1) {
            datasetsForGraph.push({
                type: 'line',
                label: 'average plus 1',
                data: datasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'selection' ? [
                    { x: datasCalculated[analyteId.toUpperCase()].minDate, y: datasCalculated[analyteId.toUpperCase()].averagePlus, label: 'averageplus' },
                    { x: datasCalculated[analyteId.toUpperCase()].maxDate, y: datasCalculated[analyteId.toUpperCase()].averagePlus, label: 'averageplus' }
                ] : initialDatasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'initial_filtering' ? [
                    { x: initialDatasCalculated[analyteId.toUpperCase()].minDate, y: initialDatasCalculated[analyteId.toUpperCase()].averagePlus, label: 'averageplus' },
                    { x: initialDatasCalculated[analyteId.toUpperCase()].maxDate, y: initialDatasCalculated[analyteId.toUpperCase()].averagePlus, label: 'averageplus' }
                ] : [],
                borderColor: theme.themeSecondary,
                backgroundColor: theme.themeSecondary
            })
            datasetsForGraph.push({
                type: 'line',
                label: 'average minus 1',
                data: datasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'selection' ? [
                    { x: datasCalculated[analyteId.toUpperCase()].minDate, y: datasCalculated[analyteId.toUpperCase()].averageMinus, label: 'averageminus' },
                    { x: datasCalculated[analyteId.toUpperCase()].maxDate, y: datasCalculated[analyteId.toUpperCase()].averageMinus, label: 'averageminus' }
                ] : initialDatasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'initial_filtering' ? [
                    { x: initialDatasCalculated[analyteId.toUpperCase()].minDate, y: initialDatasCalculated[analyteId.toUpperCase()].averageMinus, label: 'averageminus' },
                    { x: initialDatasCalculated[analyteId.toUpperCase()].maxDate, y: initialDatasCalculated[analyteId.toUpperCase()].averageMinus, label: 'averageminus' }
                ] : [],
                borderColor: theme.themeSecondary,
                backgroundColor: theme.themeSecondary
            })
        }

        // if show average 2
        if (paramsFromComponents.average2) {
            datasetsForGraph.push({
                type: 'line',
                label: 'average plus 2',
                data: datasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'selection' ? [
                    { x: datasCalculated[analyteId.toUpperCase()].minDate, y: datasCalculated[analyteId.toUpperCase()].averagePlus2, label: 'averageplus2' },
                    { x: datasCalculated[analyteId.toUpperCase()].maxDate, y: datasCalculated[analyteId.toUpperCase()].averagePlus2, label: 'averageplus2' }
                ] : initialDatasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'initial_filtering' ? [
                    { x: initialDatasCalculated[analyteId.toUpperCase()].minDate, y: initialDatasCalculated[analyteId.toUpperCase()].averagePlus2, label: 'averageplus2' },
                    { x: initialDatasCalculated[analyteId.toUpperCase()].maxDate, y: initialDatasCalculated[analyteId.toUpperCase()].averagePlus2, label: 'averageplus2' }
                ] : [],
                borderColor: theme.themeSecondary,
                backgroundColor: theme.themeSecondary
            })
            datasetsForGraph.push({
                type: 'line',
                label: 'average minus 2',
                data: datasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'selection' ? [
                    { x: datasCalculated[analyteId.toUpperCase()].minDate, y: datasCalculated[analyteId.toUpperCase()].averageMinus2, label: 'averageminus2' },
                    { x: datasCalculated[analyteId.toUpperCase()].maxDate, y: datasCalculated[analyteId.toUpperCase()].averageMinus2, label: 'averageminus2' }
                ] : initialDatasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'initial_filtering' ? [
                    { x: initialDatasCalculated[analyteId.toUpperCase()].minDate, y: initialDatasCalculated[analyteId.toUpperCase()].averageMinus2, label: 'averageminus2' },
                    { x: initialDatasCalculated[analyteId.toUpperCase()].maxDate, y: initialDatasCalculated[analyteId.toUpperCase()].averageMinus2, label: 'averageminus2' }
                ] : [],
                borderColor: theme.themeSecondary,
                backgroundColor: theme.themeSecondary
            })
        }

        // if show average 3
        if (paramsFromComponents.average3) {
            datasetsForGraph.push({
                type: 'line',
                label: 'average plus 3',
                data: datasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'selection' ? [
                    { x: datasCalculated[analyteId.toUpperCase()].minDate, y: datasCalculated[analyteId.toUpperCase()].averagePlus3, label: 'averageplus3' },
                    { x: datasCalculated[analyteId.toUpperCase()].maxDate, y: datasCalculated[analyteId.toUpperCase()].averagePlus3, label: 'averageplus3' }
                ] : initialDatasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'initial_filtering' ? [
                    { x: initialDatasCalculated[analyteId.toUpperCase()].minDate, y: initialDatasCalculated[analyteId.toUpperCase()].averagePlus3, label: 'averageplus3' },
                    { x: initialDatasCalculated[analyteId.toUpperCase()].maxDate, y: initialDatasCalculated[analyteId.toUpperCase()].averagePlus3, label: 'averageplus3' }
                ] : [],
                borderColor: theme.themeSecondary,
                backgroundColor: theme.themeSecondary
            })
            datasetsForGraph.push({
                type: 'line',
                label: 'average minus 3',
                data: datasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'selection' ? [
                    { x: datasCalculated[analyteId.toUpperCase()].minDate, y: datasCalculated[analyteId.toUpperCase()].averageMinus3, label: 'averageminus3' },
                    { x: datasCalculated[analyteId.toUpperCase()].maxDate, y: datasCalculated[analyteId.toUpperCase()].averageMinus3, label: 'averageminus3' }
                ] : initialDatasCalculated[analyteId.toUpperCase()] && paramsFromComponents.dataShow === 'initial_filtering' ? [
                    { x: initialDatasCalculated[analyteId.toUpperCase()].minDate, y: initialDatasCalculated[analyteId.toUpperCase()].averageMinus3, label: 'averageminus3' },
                    { x: initialDatasCalculated[analyteId.toUpperCase()].maxDate, y: initialDatasCalculated[analyteId.toUpperCase()].averageMinus3, label: 'averageminus3' }
                ] : [],
                borderColor: theme.themeSecondary,
                backgroundColor: theme.themeSecondary
            })
        }

        // if show target
        if (paramsFromComponents.targetValues) {
            datasetsForGraph.push({
                type: 'scatter',
                label: 'target',                
                data: Object.values(sampleSet).filter(sfd => sfd.display_id !== paramsFromComponents.sampleSelectedInGraphs && sfd.results.find(r => r.selected && r.analyte_id.toUpperCase() === analyteId.toUpperCase() && (!r.outlier && !averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sfd)}#${r.analyte_id.toUpperCase()}`])) !== undefined && (!sfd.outlier && !averageOutliers[`${getKeyForDashbpardAnalysisHeader(sfd)}`])).map(sfd => { return { x: sfd[paramsFromComponents.date], y: sfd.results.find(r => r.analyte_id.toUpperCase() === analyteId.toUpperCase())?.target, label: sfd.display_id } }),
                backgroundColor: theme.themeSecondary,
                borderColor: theme.themeSecondary,
                showLine: paramsFromComponents.connectDots,
                spanGaps: true,
            })
        }

        // if show warning
        if (paramsFromComponents.warning) {
            datasetsForGraph.push({
                type: 'scatter',
                label: 'warning high',
                data: Object.values(sampleSet).filter(sfd => sfd.display_id !== paramsFromComponents.sampleSelectedInGraphs && sfd.results.find(r => r.selected && r.analyte_id.toUpperCase() === analyteId.toUpperCase() && (!r.outlier && !averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sfd)}#${r.analyte_id.toUpperCase()}`])) !== undefined && (!sfd.outlier && !averageOutliers[`${getKeyForDashbpardAnalysisHeader(sfd)}`])).map(sfd => { return { x: sfd[paramsFromComponents.date], y: sfd.results.find(r => r.analyte_id.toUpperCase() === analyteId.toUpperCase())?.warning_high, label: sfd.display_id } }),
                backgroundColor: '#FFFF00',
                borderColor: '#FFFF00',
                showLine: paramsFromComponents.connectDots,
                spanGaps: true,
            })
            datasetsForGraph.push({
                type: 'scatter',
                label: 'warning low',
                data: Object.values(sampleSet).filter(sfd => sfd.display_id !== paramsFromComponents.sampleSelectedInGraphs && sfd.results.find(r => r.selected && r.analyte_id.toUpperCase() === analyteId.toUpperCase() && (!r.outlier && !averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sfd)}#${r.analyte_id.toUpperCase()}`])) !== undefined && (!sfd.outlier && !averageOutliers[`${getKeyForDashbpardAnalysisHeader(sfd)}`])).map(sfd => { return { x: sfd[paramsFromComponents.date], y: sfd.results.find(r => r.analyte_id.toUpperCase() === analyteId.toUpperCase())?.warning_low, label: sfd.display_id } }),
                backgroundColor: '#FFFF00',
                borderColor: '#FFFF00',
                showLine: paramsFromComponents.connectDots,
                spanGaps: true,
            })
        }

        // if show limit
        if (paramsFromComponents.limits) {
            datasetsForGraph.push({
                type: 'scatter',
                label: 'limit high',
                data: Object.values(sampleSet).filter(sfd => sfd.display_id !== paramsFromComponents.sampleSelectedInGraphs && sfd.results.find(r => r.selected && r.analyte_id.toUpperCase() === analyteId.toUpperCase() && (!r.outlier && !averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sfd)}#${r.analyte_id.toUpperCase()}`])) !== undefined && (!sfd.outlier && !averageOutliers[`${getKeyForDashbpardAnalysisHeader(sfd)}`])).map(sfd => { return { x: sfd[paramsFromComponents.date], y: sfd.results.find(r => r.analyte_id.toUpperCase() === analyteId.toUpperCase())?.limit_high, label: sfd.display_id } }),
                backgroundColor: '#FF0000',
                borderColor: '#FF0000',
                showLine: paramsFromComponents.connectDots,
                spanGaps: true
            })
            datasetsForGraph.push({
                type: 'scatter',
                label: 'limit low',
                data: Object.values(sampleSet).filter(sfd => sfd.display_id !== paramsFromComponents.sampleSelectedInGraphs && sfd.results.find(r => r.selected && r.analyte_id.toUpperCase() === analyteId.toUpperCase() && (!r.outlier && !averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sfd)}#${r.analyte_id.toUpperCase()}`])) !== undefined && (!sfd.outlier && !averageOutliers[`${getKeyForDashbpardAnalysisHeader(sfd)}`])).map(sfd => { return { x: sfd[paramsFromComponents.date], y: sfd.results.find(r => r.analyte_id.toUpperCase() === analyteId.toUpperCase())?.limit_low, label: sfd.display_id } }),
                backgroundColor: '#FF0000',
                borderColor: '#FF0000',
                showLine: paramsFromComponents.connectDots,
                spanGaps: true
            })
        }

        // if show outliers
        if (paramsFromComponents.outliers) {
            datasetsForGraph.push({
                type: 'scatter',
                label: 'outlier',
                pointStyle: dashboardName === 'matrix' ? 'triangle' : 'circle',
                data: Object.values(sampleSet).filter(sfd => sfd.display_id !== paramsFromComponents.sampleSelectedInGraphs && sfd.results.find(r => r.selected && r.analyte_id.toUpperCase() === analyteId.toUpperCase()) !== undefined && (sfd.outlier || averageOutliers[`${getKeyForDashbpardAnalysisHeader(sfd)}`] || sfd.results.find(r => r.selected && r.analyte_id.toUpperCase() === analyteId.toUpperCase() && (r.outlier || averageOutliersResults[`${getKeyForDashbpardAnalysisHeader(sfd)}#${r.analyte_id.toUpperCase()}`])) !== undefined)).map(sfd => { return { x: sfd[paramsFromComponents.date], y: Number(sfd.results.find(r => r.analyte_id.toUpperCase() === analyteId.toUpperCase())?.default_unit_result), label: sfd.display_id } }),
                backgroundColor: '#000000',
                borderColor: '#000000',
                showLine: paramsFromComponents.connectDots,
                spanGaps: true
            })
        }

        // if show selected values
        if (paramsFromComponents.selectedValues) {
            const selectedValue = values.selected_values.find((sv) => sv.analyte_id.toUpperCase() === analyteId.toUpperCase())
            if (selectedValue) {
                datasetsForGraph.push({
                    type: 'line',
                    label: 'selected_values',
                    data: [{ x: datasCalculated[analyteId.toUpperCase()].minDate, y: selectedValue.value, label: 'selected_values' },
                    { x: datasCalculated[analyteId.toUpperCase()].maxDate, y: selectedValue.value, label: 'selected_values' }],
                    borderColor: '#db9600',
                    backgroundColor: '#db9600'
                })
            }
        }

        // if show activeMatrix
        if (paramsFromComponents.activeMatrix) {
            const activeMatrixValue = activeMatrix && activeMatrix.results && activeMatrix.results.length > 0 ? activeMatrix.results.find((r) => r && r.analyte_id.toUpperCase() === analyteId.toUpperCase()) : null
            if(activeMatrixValue) {
                datasetsForGraph.push({
                    type: 'line',
                    label: 'active_matrix',
                    data: [{ x: datasCalculated[analyteId.toUpperCase()].minDate, y: activeMatrixValue.ai_result, label: 'active_matrix' },
                    { x: datasCalculated[analyteId.toUpperCase()].maxDate, y: activeMatrixValue.ai_result, label: 'active_matrix' }],
                    borderColor: '#59ff6f',
                    backgroundColor: '#59ff6f'
                })
            }
        }

        setData({ ...data, datasets: datasetsForGraph })
    }, [paramsFromComponents, analyteId, datasCalculated, groupBy, averageOutliers, averageOutliersResults, sampleSet, values.selected_values])

    // options for graph
    const options: any = {
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        elements: {
            line: {
                borderColor: theme.themePrimary,
                backgroundColor: theme.themePrimary
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: resultsHeader.find(rh => rh.id.toUpperCase() === analyteId.toUpperCase())?.name.translations[language && language.code_language ? language.code_language : ''] || resultsHeader.find(rh => rh.id.toUpperCase() === analyteId.toUpperCase())?.name.name || ''
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => {
                        let label = ''
                        switch (context.raw.label) {
                            case 'average':
                                label = t('dashboard.selection.lines.average')
                                break;
                            case 'averageplus':
                                label = t('dashboard.selection.lines.averageplus')
                                break;
                            case 'averageminus':
                                label = t('dashboard.selection.lines.averageminus')
                                break;
                            case 'averageplus2':
                                label = t('dashboard.selection.lines.averageplus2')
                                break;
                            case 'averageminus2':
                                label = t('dashboard.selection.lines.averageminus2')
                                break;
                            case 'averageplus3':
                                label = t('dashboard.selection.lines.averageplus3')
                                break;
                            case 'averageminus3':
                                label = t('dashboard.selection.lines.averageminus3')
                                break;
                            case 'trend':
                                label = t('dashboard.selection.lines.trend')
                                break;
                            case 'selected_values':
                                label = t('dashboard.selection.lines.selected_values')
                                break;
                            case 'active_matrix':
                                label = t('dashboard.selection.lines.active_matrix')
                                break;
                            default:
                                label = t('dashboard.graphs.hover.da') + ' ' + context.raw.label + ' (' + Intl.DateTimeFormat(authUser.locale).format(new Date(context.parsed.x)) + ';' + context.parsed.y + ')';
                                break;
                        }
                        return label
                    }
                }
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                        modifierKey: 'ctrl'
                    },
                    mode: "xy",
                    speed: 100
                },
                pan: {
                    enabled: true,
                    mode: "xy",
                    speed: 100,
                    modifierKey: 'ctrl'
                },
                limits: {
                    y: { max: 'original', min: 'original' },
                    x: { max: 'original', min: 'original' }
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day'
                }
            }
        }
    }

    // download functions
    let ref: any = useRef(null)
    const downloadImage = useCallback(() => {
        const link = document.createElement("a")
        link.download = `${analyteId}.png`
        link.href = ref.current.toBase64Image()
        link.click()
    }, [])

    const downloadCSV = useCallback(() => {
        let csv = 'X;Y;Data\n'
        data.datasets.forEach((dataset: any, index: any) => {
            dataset.data.forEach((point: any) => {
                csv += point.x + ';' + point.y + ';' + dataset.label + '\n';
            });
        });

        const link = document.createElement("a")
        link.download = `${analyteId}.csv`
        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        link.click()
    }, [data])

    const onClick = (event: any) => {
        const elem = getElementAtEvent(ref.current, event)[0]
        if (elem) {
            dispatch(setSampleSelectedInGraphs(data.datasets[elem.datasetIndex].data[elem.index].label));
        } else {
            dispatch(setSampleSelectedInGraphs(''));
        }
    }

    return (
        <Container className={`mt-0 ${resultsHeader.filter(rh => rh.display_in_graph).length - resultsHeader.filter(rh => rh.display_in_graph).indexOf(resultsHeader.filter(rh => rh.display_in_graph).find(rh => rh.id.toUpperCase() === analyteId.toUpperCase())!) > 2 ? 'mb-5' : ''} p-0 ms-0 me-0`} style={{ height: '250px', width: '800px' }}>
            <Container className='d-flex justify-content-end'>
                <Button className="p-0 text-primary" variant="link" style={{ marginBottom: '-30px', zIndex: '1' }} onClick={() => {
                    downloadImage();
                    downloadCSV();
                }} >
                    <DotsIcon width="18px" height="18px" />
                </Button>
            </Container>
            <Scatter ref={ref} key={analyteId.toUpperCase()} options={options} data={data} onClick={onClick} />
        </Container>
    )
}

export default withTranslation()(DashboardGenericGraphComponent)