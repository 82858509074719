import React from "react";
import { useLocation } from "react-router-dom";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    let { search } = useLocation();
    search = decodeURIComponent(search)
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

export default useQuery