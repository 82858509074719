import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ConfigApi, { AutocalcAnalyte, getKeyForTabAssocAutocalcAnalyte } from '../api/configApi';
import { buildDefaultExtraReducersWithFrontPagination, CurrentState, getDefaultReducersWithFrontPagination, getInitialState } from './DefaultSlice';

const initialState = getInitialState<AutocalcAnalyte>()

export const fetchAutocalcAnalytes = createAsyncThunk(
    'config/fetchAutocalcAnalytes', 
    async () => {
        return await ConfigApi.getInstance().getAutocalcAnalytes() 
    }
)

export const fetchExportAutocalcAnalytes = createAsyncThunk(
    'config/fetchExportAnalytes', 
    async () => {
       return await ConfigApi.getInstance().exportAutocalcAnalytes()
    }
)

const addItemToAnalyte =   (state:CurrentState<AutocalcAnalyte>,item:AutocalcAnalyte) => {
    if (item.laboratory_id && item.analyte_id) 
    state.data[getKeyForTabAssocAutocalcAnalyte(item)] = item
}

const postDeleteAnalyte = (state:CurrentState<AutocalcAnalyte>,item:AutocalcAnalyte)=> {
    delete state.data[getKeyForTabAssocAutocalcAnalyte(item)]
    state.filtered.splice(state.filtered.indexOf(getKeyForTabAssocAutocalcAnalyte(item)),1)
}

const autocalcAnalyteSlice = createSlice({
    name: 'autocalcAnalytes',
    initialState,
     reducers: getDefaultReducersWithFrontPagination(addItemToAnalyte,postDeleteAnalyte)
    ,
    extraReducers: builder => {
            buildDefaultExtraReducersWithFrontPagination(
              addItemToAnalyte,
                builder,
                fetchAutocalcAnalytes
            )  
        }
})

export const {
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage
} = autocalcAnalyteSlice.actions

export default autocalcAnalyteSlice.reducer