import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Row, Container, Col, Button } from 'react-bootstrap';
import { ReactComponent as CheckedIcon } from 'bootstrap-icons/icons/check-square.svg'
import { ReactComponent as UncheckedIcon } from 'bootstrap-icons/icons/square.svg'
import { OptionType, setGroupBy, setSelectOptions, setDataShow } from '../../../state/dashboardSlice';
import Select from 'react-select'
import { buildSelectSmallStyleWithSmallMenu } from '../../../common/utils/utils';
import ModalDashboardOptionsGroupsComponent from './ModalDashboardOptionsGroupsComponent';
import { FieldSelect } from './ModalDashboardParamsWeightComponent';
import { Selection } from '../../../api/dashboardApi';
import { ReactComponent as DeleteCircleRed } from '../../../common/resources/DeleteCircleRed.svg'

interface OptionSelect {
    label: string,
    value: number
}

interface DashboardOptionsComponentContainerProps extends WithTranslation {
    dashboardName: string
}

const DashboardOptionsComponent: React.FC<DashboardOptionsComponentContainerProps> = ({ t, dashboardName }) => {
    const dispatch = useDispatch()

    // samples state
    const paramsFromComponents = useSelector((state: RootState) => state.dashboard.paramsFromComponents)
    const valuesForSearch = useSelector((state: RootState) => state.dashboard.valuesForSearch)

    let select = { label: "1", value: 1 }

    if (paramsFromComponents.average2) {
        select = { label: "2", value: 2 }
    }
    if (paramsFromComponents.average3) {
        select = { label: "3", value: 3 }
    }

    // local state
    const [selectAverage, setSelectAverage] = useState(select);
    const [showGroup, setShowGroup] = useState(false);
    const groupBy = useSelector((state: RootState) => state.dashboard.groupBy)

    return (
        <Container className={'mt-2 p-0 ms-0 me-0'}>
            <ModalDashboardOptionsGroupsComponent dashboardName={dashboardName} showModal={showGroup} setShowModal={setShowGroup} />

            <Container className="p-2 bg-white rounded-3 mb-0">
                <Row>
                    <Col lg>
                        <h4 className="text-primary fw-bold">
                            {t('dashboard.options.title')}
                        </h4>
                    </Col>
                </Row>
                <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                {/* Average */}
                <Row>
                    <Col lg={2}>
                        <Button className="p-0 text-primary" variant="link" onClick={() => {
                            dispatch(setSelectOptions('average'))
                        }} >
                            {paramsFromComponents.average &&
                                <CheckedIcon width="18px" height="18px" className="align-text-top" />
                            }
                            {!paramsFromComponents.average &&
                                <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                            }
                        </Button>
                    </Col>
                    <Col lg={10} className='ps-0'>
                        <p>{t('dashboard.options.average')}</p>
                    </Col>
                </Row>
                {/* Average min max */}
                <Row>
                    <Col lg={2}>
                        <Button className="p-0 text-primary" variant="link" onClick={() => {
                            dispatch(setSelectOptions(`average${selectAverage.label}` as OptionType))
                        }} >
                            {paramsFromComponents[`average${selectAverage.label}` as OptionType] &&
                                <CheckedIcon width="18px" height="18px" className="align-text-top" />
                            }
                            {!paramsFromComponents[`average${selectAverage.label}` as OptionType] &&
                                <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                            }
                        </Button>
                    </Col>
                    <Col lg={5} className='ps-0 pe-0'>
                        <p>{t('dashboard.options.averageplusmin')}</p>
                    </Col>
                    <Col lg={3} className='ps-0 pe-0'>
                        <Select
                            name="select-average"
                            value={selectAverage}
                            isClearable={false}
                            options={[{ label: "1", value: 1 }, { label: "2", value: 2 }, { label: "3", value: 3 }]}
                            getOptionLabel={(option: OptionSelect) => option.label}
                            getOptionValue={(option: OptionSelect) => option.value}
                            onChange={(option: OptionSelect) => {
                                if (paramsFromComponents[`average${selectAverage.label}` as OptionType]) {
                                    dispatch(setSelectOptions(`average${selectAverage.label}` as OptionType))
                                    dispatch(setSelectOptions(`average${option.label}` as OptionType))
                                }
                                setSelectAverage(option);
                            }}
                            styles={buildSelectSmallStyleWithSmallMenu()}
                        />
                    </Col>
                    <Col lg={1} className='ps-1 pe-0'>
                        <p>{t('dashboard.options.sd')}</p>
                    </Col>
                </Row>
                {dashboardName === 'results-analysis' &&
                    <>
                        {/* target value */}
                        <Row>
                            <Col lg={2}>
                                <Button className="p-0 text-primary" variant="link" onClick={() => {
                                    dispatch(setSelectOptions('targetValues'))
                                }} >
                                    {paramsFromComponents.targetValues &&
                                        <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                    {!paramsFromComponents.targetValues &&
                                        <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                </Button>
                            </Col>
                            <Col lg={10} className='ps-0'>
                                <p>{t('dashboard.options.target_value')}</p>
                            </Col>
                        </Row>
                        {/* warning */}
                        <Row>
                            <Col lg={2}>
                                <Button className="p-0 text-primary" variant="link" onClick={() => {
                                    dispatch(setSelectOptions('warning'))
                                }} >
                                    {paramsFromComponents.warning &&
                                        <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                    {!paramsFromComponents.warning &&
                                        <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                </Button>
                            </Col>
                            <Col lg={10} className='ps-0'>
                                <p>{t('dashboard.options.warning')}</p>
                            </Col>
                        </Row>
                        {/* limits */}

                        <Row>
                            <Col lg={2}>
                                <Button className="p-0 text-primary" variant="link" onClick={() => {
                                    dispatch(setSelectOptions('limits'))
                                }} >
                                    {paramsFromComponents.limits &&
                                        <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                    {!paramsFromComponents.limits &&
                                        <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                </Button>
                            </Col>
                            <Col lg={10} className='ps-0'>
                                <p>{t('dashboard.options.limits')}</p>
                            </Col>
                        </Row>
                    </>
                }
                {dashboardName === 'matrix' &&
                    <>
                        {/* Selected values */}

                        <Row>
                            <Col lg={2}>
                                <Button className="p-0 text-primary" variant="link" onClick={() => {
                                    dispatch(setSelectOptions('selectedValues'))
                                }} >
                                    {paramsFromComponents.selectedValues &&
                                        <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                    {!paramsFromComponents.selectedValues &&
                                        <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                </Button>
                            </Col>
                            <Col lg={10} className='ps-0'>
                                <p>{t('dashboard.options.selected_values')}</p>
                            </Col>
                        </Row>
                        {/* Active matrix values */}
                        <Row>
                            <Col lg={2}>
                                <Button className="p-0 text-primary" variant="link" onClick={() => {
                                    dispatch(setSelectOptions('activeMatrix'))
                                }} >
                                    {paramsFromComponents.activeMatrix &&
                                        <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                    {!paramsFromComponents.activeMatrix &&
                                        <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                </Button>
                            </Col>
                            <Col lg={10} className='ps-0'>
                                <p>{t('dashboard.options.active_matrix')}</p>
                            </Col>
                        </Row>
                    </>

                }
                {/* connect dots */}
                <Row>
                    <Col lg={2}>
                        <Button className="p-0 text-primary" variant="link" onClick={() => {
                            dispatch(setSelectOptions('connectDots'))
                        }} >
                            {paramsFromComponents.connectDots &&
                                <CheckedIcon width="18px" height="18px" className="align-text-top" />
                            }
                            {!paramsFromComponents.connectDots &&
                                <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                            }
                        </Button>
                    </Col>
                    <Col lg={10} className='ps-0'>
                        <p>{t('dashboard.options.connect_dots')}</p>
                    </Col>
                </Row>
                {dashboardName === 'results-analysis' &&
                    <>
                        {/* trend */}
                        <Row>
                            <Col lg={2}>
                                <Button className="p-0 text-primary" variant="link" onClick={() => {
                                    dispatch(setSelectOptions('trend'))
                                }} >
                                    {paramsFromComponents.trend &&
                                        <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                    {!paramsFromComponents.trend &&
                                        <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                    }
                                </Button>
                            </Col>
                            <Col lg={10} className='ps-0'>
                                <p>{t('dashboard.options.trend')}</p>
                            </Col>
                        </Row>



                        {/* outliers */}
                        {!paramsFromComponents.filterOutliers &&
                            <Row>
                                <Col lg={2}>
                                    <Button className="p-0 text-primary" variant="link" onClick={() => {
                                        dispatch(setSelectOptions('outliers'))
                                    }} >
                                        {paramsFromComponents.outliers &&
                                            <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                        }
                                        {!paramsFromComponents.outliers &&
                                            <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                        }
                                    </Button>
                                </Col>
                                <Col lg={10} className='ps-0'>
                                    <p>{t('dashboard.options.outliers')}</p>
                                </Col>
                            </Row>
                        }
                    </>

                }
                {dashboardName === 'matrix' &&
                    <Container className={'mb-2 p-0'} style={{ backgroundColor: '#f3f3f3', borderRadius: 5 }}>
                        {/*Select Option*/}
                        <Row className='mb-3'>
                            <Col lg>
                                <Select
                                    name={`option`}
                                    value={paramsFromComponents.dataShow ? { label: paramsFromComponents.dataShow, value: paramsFromComponents.dataShow } : 'selection'}
                                    isClearable={false}
                                    options={['selection', 'initial_filtering'].map((e) => { return { label: e, value: e } })}
                                    getOptionLabel={(value: FieldSelect) => t(`dashboard.params.select.${value.label}`)}
                                    getOptionValue={(value: FieldSelect) => value.value}
                                    onChange={(value: FieldSelect) => {
                                        dispatch(setDataShow(value.value as Selection))
                                    }}
                                />
                            </Col>
                        </Row>
                        <Container >
                            {/* trend */}
                            <Row>
                                <Col lg={1} className='ps-2'>
                                    <p>  -</p>
                                </Col>
                                <Col lg={2}>
                                    <Button className="p-0 text-primary " variant="link" onClick={() => {
                                        dispatch(setSelectOptions('trend'))
                                    }} >
                                        {paramsFromComponents.trend &&
                                            <CheckedIcon width="18px" height="18px" className="align-text-top bg-white" />
                                        }
                                        {!paramsFromComponents.trend &&
                                            <UncheckedIcon width="18px" height="18px" className="align-text-top bg-white" />
                                        }
                                    </Button>
                                </Col>
                                <Col lg={9} className='ps-0'>
                                    <p>{t('dashboard.options.trend')}</p>
                                </Col>
                            </Row>
                            {/* outliers */}

                            {!paramsFromComponents.filterOutliers &&
                                <Row>
                                    <Col lg={1} className='ps-2'>
                                    <p>  -</p>
                                    </Col>
                                    <Col lg={2}>
                                        <Button className="p-0 text-primary " variant="link" onClick={() => {
                                            dispatch(setSelectOptions('outliers'))
                                        }} >
                                            {paramsFromComponents.outliers &&
                                                <CheckedIcon width="18px" height="18px" className="align-text-top bg-white" />
                                            }
                                            {!paramsFromComponents.outliers &&
                                                <UncheckedIcon width="18px" height="18px" className="align-text-top bg-white" />
                                            }
                                        </Button>
                                    </Col>
                                    <Col lg={9} className='ps-0'>
                                        <p>{t('dashboard.options.outliers')}</p>
                                    </Col>
                                </Row>
                            }

                        </Container>
                    </Container>
                }

                {/* Groups */}
                <Row>
                    <Col lg={"auto"}>
                        <Button className="no-print m-auto" variant="secondary" onClick={() => {
                            setShowGroup(true)
                        }} >
                            {t('dashboard.options.groups')}
                        </Button>
                    </Col>
                    <Col lg={"auto"} className="m-auto">
                        {/* Button to empty group selection */}
                        {groupBy.field && groupBy.field !== '' && 
                            <Button size="sm" variant="link" className="p-0 ms-1"
                                onClick={() => {
                                    dispatch(setGroupBy({field: "", values: []}))
                                }}
                            >
                                <DeleteCircleRed className="colorHoverSvg align-text-top text-secondary p-0" width="38px" height="100%" />
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        </Container >
    )
}

export default withTranslation()(DashboardOptionsComponent)