/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import axiosRetry from "axios-retry";
import env from "../common/utils/env";
import AbstractApi from "./AbstractApi";
import * as Downloader from "../common/utils/download";

export interface AnalysisReports {
    id: string // front id
    laboratory_id: number
    name: string
    type_codes: Array<'FG' | 'FP' | 'RM'>
    template: string
    background: string
    isAppendix: boolean
    type_appendix: string
}

export function getKeyAnalysisReports(analysisReport: AnalysisReports): string {
    return `${analysisReport.laboratory_id}#${analysisReport.name}`
}

export interface Theme {
    name: string
    link: string
    file?: File
}

export interface ReportTemplates {
    name: string
    link: string
    template?: File
    themes: Array<Theme>
}

export interface ThemeDocument {
    name: string
    file: File
}
export type ReportTemplateDocument = {
    name: string
    template: File
    themes: Array<ThemeDocument>
}

export type ExportType = 'PLB' | 'XMLMAX' | 'XMLNDS' | 'FRB'
export type ResultType = 'AI' | 'DM'

export type JobStatus = 'created' | 'queued' | 'running' | 'done' | 'failed' | 'cancelled' | 'cancelling'
export type SampleType = 'FG' | 'RM' | 'FP'

export type ExportFormat = 'complete' | 'summary' | 'vertical-table' | 'horizontal-table'
export type ExportFileType = 'csv' | 'xlsx'

export interface ExportSystemKey {
    laboratory_id: number;
    export_type: ExportType
    code_test: string;
    code_analyte: string;
}
export interface ExportSystem extends ExportSystemKey {
    id: string; // front id
    laboratory_id: number
    export_type: ExportType
    code_test: string
    code_analyte: string
    code: string
    name: string
    result_type: ResultType
    conversion_factor: number
    unit: string
}

export function getKeyForTabAssocExportSystem(exportSystem: ExportSystemKey): string {
    return (
        exportSystem.laboratory_id +
        "#" +
        exportSystem.export_type +
        "#" +
        exportSystem.code_test +
        "#" +
        exportSystem.code_analyte
    );
}


export interface ExportFilter {
    laboratory_id: number
    period: {
        gte: string | null // date
        lte: string | null // date (to have good format)
    }
    sample_types: {
        in: Array<SampleType>
    } | null
    companies: {
        in: Array<number>
    } | null
    families: {
        in: Array<number>
    } | null
    nutrients: {
        in: Array<string>
    } | null
    analysis_ids: {
        in: Array<number>
    } | null
}
export interface ExportRequest {
    filter: ExportFilter
    output: {
        format: ExportFormat
        file_type: ExportFileType
    }
}

export interface ExportResponse {
    id: string // job's uuid to poll on
    status: JobStatus
    created_at: Date
    config: ExportRequest
}

export interface ExportStatus extends ExportResponse {
    progress: number
}

export interface ExportJobsDb extends ExportResponse {
    user_id: number
    progress: number
    url: string
    config: ExportRequest
}

export interface ReportRequest{
    laboratory_id: number
    report_name: string // Matching analysis report name
    theme_name: string // Matching one of analysis report's themes' name
    analysis_id: number
}



const standaloneInstance = axios.create({
    baseURL: env.PUBLISH_API_URL,
    timeout: 60000,
});

axiosRetry(standaloneInstance, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
});

class PublishApi extends AbstractApi {
    private static instance: PublishApi;

    public static getInstance() {
        if (!PublishApi.instance) {
            throw new Error("Initialize instance before.");
        }
        return PublishApi.instance;
    }

    public static initInstance(token: string) {
        PublishApi.instance = new PublishApi(env.PUBLISH_API_URL, token);
    }

    public async getAnalysisReports(withBackground: boolean): Promise<Array<AnalysisReports>> {
        try {
            const response = await this.service.get(`/secure/analysis-reports?with_background=${withBackground}`);
            const data: Array<AnalysisReports> = response.data;

            for (const dataElem of data) {
                dataElem.id = getKeyAnalysisReports(dataElem);
            }
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async getAnalysisReport(laboratory_id: number, name: string): Promise<AnalysisReports> {
        try {
            const response = await this.service.get(
                `/secure/analysis-reports/${encodeURIComponent(laboratory_id)}/${encodeURIComponent(name)}`
            );
            const data: AnalysisReports = response.data;
            data.id = getKeyAnalysisReports(data);
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async postAnalysisReport(analysisReport: FormData): Promise<AnalysisReports> {
        try {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            const response = await this.service.post(`/secure/analysis-reports`, analysisReport, config);
            const data: AnalysisReports = response.data;
            data.id = getKeyAnalysisReports(data);
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async putAnalysisReport(analysisReport: FormData, laboratory_id: number, name: string): Promise<AnalysisReports> {
        try {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            const response = await this.service.put(`/secure/analysis-reports/${encodeURIComponent(laboratory_id)}/${encodeURIComponent(name)}`, analysisReport, config);
            const data: AnalysisReports = response.data;
            data.id = getKeyAnalysisReports(data);
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async deleteAnalysisReport(laboratory_id: number, name: string): Promise<boolean> {
        try {
            const response = await this.service.delete(`/secure/analysis-reports/${encodeURIComponent(laboratory_id)}/${encodeURIComponent(name)}`);
            return response.data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }


    public async getReportTemplates(): Promise<Array<ReportTemplates>> {
        try {
            const response = await this.service.get("/secure/templates");
            const data: Array<ReportTemplates> = response.data;
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async getReportTemplate(name: string): Promise<ReportTemplates> {
        try {
            const response = await this.service.get(`/secure/template/${encodeURIComponent(name)}`);
            const data: ReportTemplates = response.data;
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async getReportTemplateFiles(name: string): Promise<Blob> {
        try {
            const response = await this.service.get(`/secure/templates/${encodeURIComponent(name)}/files`, { responseType: 'blob' });
            return response.data || [];
        } catch (err) {
            const realError = await (err as any).response.data.text()  // need because response type blob
            throw JSON.parse(realError).error;        }
    }


    public async deleteReportTemplate(name: string): Promise<boolean> {
        try {
            const response = await this.service.delete(`/secure/templates/${encodeURIComponent(name)}`);
            return response.data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async postReportTemplate(reportTemplate: FormData): Promise<ReportTemplates> {
        try {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            const response = await this.service.post(`/secure/templates/`, reportTemplate, config);
            return response.data as ReportTemplates;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async putReportTemplate(reportTemplate: FormData, name: string): Promise<ReportTemplates> {
        try {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            const response = await this.service.put(`/secure/templates/${encodeURIComponent(name)}`, reportTemplate, config);
            return response.data as ReportTemplates;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async deleteReportTemplateTheme(name: string, theme: string): Promise<boolean> {
        try {
            const response = await this.service.delete(`/secure/templates/${encodeURIComponent(name)}/themes/${encodeURIComponent(theme)}`);
            return response.data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async importExportSystem(file: File, lab_id: number): Promise<Array<ExportSystem>> {
        try {
            const formData = new FormData();
            formData.append("file", file, file.name);
            const response = await this.service.post(
                `/secure/export-systems/import?lab_id=${lab_id}`,
                formData
            );
            return response.data || [];
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async getExportSystem(
        lab_id: number,
        export_type: ExportType,
        code_test: string,
        code_analyte: string
    ): Promise<ExportSystem> {
        try {
            const response = await this.service.get(
                `/secure/export-systems/${lab_id}/${encodeURIComponent(export_type)}/${encodeURIComponent(code_test)}/${encodeURIComponent(code_analyte)}`
            );
            const data: ExportSystem = response.data;
            data.id = getKeyForTabAssocExportSystem(data); // ajout d'un id pour le front
            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async exportExportSystems(lab_id: number): Promise<boolean> {
        try {
            const response = await this.service.get(
                `/secure/export-systems/export?lab_id=${lab_id}`,
                { responseType: "blob" }
            );

            Downloader.downloadBlob(response.data, Downloader.getExportedFileName('export-systems', 'xlsx'))
            return true;
        } catch (err) {
            throw await AbstractApi.handleBlobError(err);
        }
    }

    public async getExportSystems(): Promise<Array<ExportSystem>> {
        try {
            const response = await this.service.get("/secure/export-systems");
            const data: Array<ExportSystem> = response.data;
            data.forEach((dataElem) => {
                dataElem.id = getKeyForTabAssocExportSystem(dataElem);
            });

            return data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async postExportJob(exportRequest: ExportRequest): Promise<ExportResponse> {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const response = await this.service.post("/secure/exports", exportRequest, config);
            return response.data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async getExportJobStatus(jobId: string): Promise<ExportStatus> {
        try {

            const response = await this.service.get(`/secure/exports/${jobId}/status`);
            return response.data;
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async downloadExportJob(jobId: string, exportFileType: ExportFileType): Promise<boolean> {


        try {
            const response = await this.service.get(`/secure/exports/${jobId}/download`,
                { responseType: "blob" }
            );

            Downloader.downloadBlob(response.data, Downloader.getExportedFileName(`export-jobs`, exportFileType))
            return true;
        } catch (err) {
            throw await AbstractApi.handleBlobError(err);
        }
    }


    public async cancelExportJob(jobId: string): Promise<boolean> {
        try {

            await this.service.delete(`/secure/exports/${jobId}`);
            return true
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async getAllExportJobs(): Promise<ExportJobsDb[]> {
        try {

            const response = await this.service.get(`/secure/exports`);
            return response.data as ExportJobsDb[];
        } catch (err) {
            throw AbstractApi.handleError(err);
        }
    }

    public async downloadReportPreview(reportRequest: ReportRequest): Promise<boolean> {
        try {

            const response = await this.service.post("/secure/reports", reportRequest,  {
                headers: {
                    "Content-Type": "application/json",
                },
                responseType: 'blob'
            }
);
            Downloader.downloadBlob(response.data, Downloader.getExportedFileName('reports', 'pdf'))

            return true
        } catch (err) {
            throw await AbstractApi.handleBlobError(err);
        }
    }




}

export default PublishApi;
