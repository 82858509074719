import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container, Form, Col, Row, Pagination, FormSelect } from 'react-bootstrap';


interface CustomPaginationProps extends WithTranslation {
    nbPages: number
    curPage: number
    nbNeighbours: number
    curPageSize: number
    allowedPageSizes: Array<number>
    goToPage: (page: number) => void
    setPageSize: (nbPage: number) => void

}

const CustomPagination: React.FC<CustomPaginationProps> = ({ t, nbPages, curPage, nbNeighbours, curPageSize, allowedPageSizes, goToPage, setPageSize }) => {

    const computePages = (): Array<number> => {
        
        const allPages: Array<number> = []
        for (let i = 1; i <= nbPages; i++) {
            allPages.push(i)
        }
        const pages = allPages.filter(p => p > 1 && p < nbPages && p >= curPage - nbNeighbours && p <= curPage + nbNeighbours)

        return pages
    }

    const pages = computePages()
    
    return (
        <Container>
            <Row>
                <Col xs={12} lg>
                    <Pagination size="sm" className="justify-content-center justify-content-lg-end">
                        <Pagination.First onClick={() => curPage >1 && goToPage(1)} />
                        <Pagination.Prev onClick={() => curPage > 1 && goToPage(curPage - 1)} />
                        <Pagination.Item active={curPage === 1} onClick={() => goToPage(1)}>
                            {1}
                        </Pagination.Item>
                        {curPage - nbNeighbours*2 > 1 && 
                            <Pagination.Ellipsis onClick={() => goToPage(curPage - nbNeighbours*2 - 1)}/>
                        }
                        {pages.map(page => (
                                <Pagination.Item key={page} active={curPage === page} onClick={() => goToPage(page)}>
                                    {page}
                                </Pagination.Item>
                            )
                        )}
                        {curPage + nbNeighbours*2 < nbPages && 
                            <Pagination.Ellipsis onClick={() => goToPage(curPage + nbNeighbours*2 + 1)}/>
                        }
                        {nbPages > 1 &&
                            <Pagination.Item active={curPage === nbPages} onClick={() => goToPage(nbPages)}>
                                {nbPages}
                            </Pagination.Item>
                        }
                        
                        <Pagination.Next onClick={() => curPage < nbPages && goToPage(curPage + 1)} />
                        <Pagination.Last onClick={() => curPage < nbPages && goToPage(nbPages)} />
                    </Pagination>
                </Col>
                <Col xs={12} lg>
                    <Form.Group as={Row} className="justify-content-center justify-content-lg-start">
                        <Col xs="auto">
                            <Form.Label>{t('pagination.view_by')}</Form.Label>
                        </Col>
                        <Col xs={4} lg={2}>
                            <FormSelect size="sm" value={curPageSize} onChange={(e) => { setPageSize(Number(e.target.value)) }}>
                                {allowedPageSizes.map(size => (
                                    <option key={size} value={size}>{size}</option>
                                ))}
                            </FormSelect>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    )

}

export default withTranslation()(CustomPagination)