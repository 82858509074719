import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import UsersApi, { Employee, CompaniesIds } from '../api/usersApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';

interface CurrentState {
    data: Data<Employee>
    filtered: Array<string>
    byCompany: Data<Array<string>>
    pagination: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postError: string | undefined
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    byCompany: {},
    fetchStatus: 'idle',
    fetchError: undefined,
    singleFetchStatus: 'idle',
    singleFetchError: undefined,
    postStatus: 'idle',
    postError: undefined
} as CurrentState

export const fetchEmployees = createAsyncThunk(
    'users/fetchEmployees',
    async () => {
        const response = await UsersApi.getInstance().getEmployees()
        return response as Array<Employee>
    }
)

export const fetchSingleEmployee = createAsyncThunk(
    'users/fetchSingleEmployee',
    async (id: number) => {
        const response = await UsersApi.getInstance().getEmployeeById(id)
        return response as Employee
    }
)

const employeesSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        addEmployee(state: CurrentState, action: PayloadAction<Employee>) {
            const item = action.payload
            item.companies.forEach((company: CompaniesIds) => {
            if (!state.byCompany[company.company_id]) state.byCompany[company.company_id] = []
            if (item.id) {
                state.data[item.id] = item
                state.byCompany[company.company_id].push(String(item.id))
            }
            })
        },
        updateEmployee(state: CurrentState, action: PayloadAction<Employee>) {
            const item = action.payload
            if (item.id)
                state.data[item.id] = item
        },
        deactivateEmployee(state: CurrentState, action: PayloadAction<number>) {
            const id = action.payload
            state.data[id].disabled = true
        },
        activateEmployee(state: CurrentState, action: PayloadAction<number>) {
            const id = action.payload
            state.data[id].disabled = false
        },
        setFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload
        },
        setFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy
        },
        setSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchEmployees.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchEmployees.fulfilled, (state, action) => {
            state.byCompany = {}
            action.payload.forEach((item: Employee) => {
                if (item.companies.length > 0) {
                    item.companies.forEach((company: CompaniesIds) => {
                        if (!state.byCompany[company.company_id]) state.byCompany[company.company_id] = []
                        if (item.id) {
                            state.data[item.id] = item
                            state.byCompany[company.company_id].push(String(item.id))
                        }
                    })
                } else {
                    if (item.id) {
                        state.data[item.id] = item
                    }
                }
                
            })
            state.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchEmployees.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
        builder.addCase(fetchSingleEmployee.pending, (state) => {
            state.singleFetchStatus = 'loading'
        })
        builder.addCase(fetchSingleEmployee.fulfilled, (state, action) => {
            const item = action.payload
            if (item && item.id) {
                state.data[item.id] = item
                item.companies.forEach((company: CompaniesIds) => {
                    if (!state.byCompany[company.company_id]) {
                        state.byCompany[company.company_id] = []
                    }
                    if (item.id !== undefined) {
                        if (state.byCompany[company.company_id].indexOf(item.id.toString()) === -1) {
                            state.byCompany[company.company_id].push(item.id.toString())
                        }
                    }
                })
            }
            state.singleFetchStatus = 'succeeded'
        })
        builder.addCase(fetchSingleEmployee.rejected, (state, action) => {
            state.singleFetchStatus = 'failed'
            state.singleFetchError = action.error.message
        })
    }
})

export const {
    addEmployee,
    updateEmployee,
    deactivateEmployee,
    activateEmployee,
    setFiltered,
    setFilterBy,
    setSortBy
} = employeesSlice.actions

export default employeesSlice.reducer