import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../state/rootReducer';

import { ReactComponent as DeleteCircle } from 'bootstrap-icons/icons/x-circle.svg'
import { ReactComponent as DeleteCircleFill } from 'bootstrap-icons/icons/x-circle-fill.svg'
import { ReactComponent as Collapsed } from 'bootstrap-icons/icons/caret-down-fill.svg';
import { ReactComponent as Uncollapsed } from 'bootstrap-icons/icons/caret-up-fill.svg';
import { ReactComponent as NotFiltered } from 'bootstrap-icons/icons/funnel.svg';
import { ReactComponent as Filtered } from 'bootstrap-icons/icons/funnel-fill.svg';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

import dbt from './dbTranslation';

import { FilterBy, SortBy, FilterEnum, Filter, Data } from '../../types/types';
import { Accordion, AccordionContext, Card, Modal, Row, useAccordionButton } from 'react-bootstrap';
import useViewport from './useViewport';
import ButtonHeaderList from '../components/ButtonHeaderList';
import moment from 'moment';
import removeAccents from 'remove-accents';
import FilterForm from '../components/FilterForm'

type FilterRender = {
    type: string
    name: string
    label: string
    editable: boolean
}

interface Options {
    i18nPrefix: string
    onlyActive?: boolean
    filterOnlyLaboratoryRoleLevel?: boolean
    size?: "xs" | "sm"
    maxHeight?: string
    fields: {
        [name: string]: {
            type: Array<"number" | "label" | "filter" | "date" | "list" | "check" | "boolean">
            sortable?: boolean
            editable?: boolean
            noTrad?: boolean
            nonEditableList?: Array<string>
            defaultFilter?: Array<string>
            modal?: {
                displayed?: boolean
                collapsible?: boolean
            }
            inOpt?: {
                mergeTwin?: boolean
                multipleValues?: boolean
                dbt?: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    elements: Data<any>
                    field: string
                    idFieldName: string
                    dbtIdFieldName?: string
                },
                t?: {
                    tPrefix: string
                    elements: Array<string>
                },
                raw?: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    elements: Data<any>
                }
            },
            boolOpt?: {
                labels?: { [key in 'true' | 'false']: string }
            }
        }
    },
    reducers: {
        filterBy: (f: FilterBy) => void
        sortBy: (f: Array<SortBy>) => void
    }
}

const useFilterToolkit = (filterBy: FilterBy, sortBy: Array<SortBy>, options: Options) => {
    const { lg } = useViewport()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const { user: authUser } = useSelector((state: RootState) => state.authUser)
    const { language } = useSelector((state: RootState) => state.local)



    const resetFilter = () => {
        dispatch(options.reducers.sortBy([]))
        // TODO: make those generics
        const filter: FilterBy = {}
        if (options.filterOnlyLaboratoryRoleLevel) {
            filter.filterOnlyLaboratoryRoleLevel = { bool: true }
        }
        if (options.fields["status"]?.defaultFilter) {
            filter.status = { in: options.fields["status"].defaultFilter }
        }
        if (options.fields["internal_status"]?.defaultFilter) {
            filter.internal_status = { in: options.fields["internal_status"].defaultFilter }
        }
        if (options.fields["input_result_status"]?.defaultFilter) {
            filter.input_result_status = { in: options.fields["input_result_status"].defaultFilter }
        }
        dispatch(options.reducers.filterBy(filter))
    }

    const addFilter = (name: string, filter: Filter | undefined) => {
        const updatedFilterBy = { ...filterBy }
        if (filter) {
            updatedFilterBy[name] = filter

            if (filter.in && filter.in.length === 0) {
                if (Object.keys(filter).length > 1) {
                    delete updatedFilterBy[name].in
                } else {
                    delete updatedFilterBy[name]
                }
            }
        } else {
            if (options.fields[name].defaultFilter) {
                updatedFilterBy[name] = { ...updatedFilterBy[name], in: options.fields[name].defaultFilter }
            } else {
                delete updatedFilterBy[name]
            }
        }

        dispatch(options.reducers.filterBy(updatedFilterBy))
    }

    const addSort = (name: string, direction: string) => {
        const updatedSortBy = [...sortBy]

        const sort = {
            name,
            direction
        }

        const indexOf = sortBy.findIndex(x => x.name === name)
        if (indexOf > -1) {
            updatedSortBy[indexOf] = sort
        } else {
            updatedSortBy.push(sort)
        }
        dispatch(options.reducers.sortBy(updatedSortBy))
    }

    const deleteFilterSortByName = (name: string, type: string) => {
        switch (type) {
            case "filter": {
                const updatedFilters = { ...filterBy }
                if (options.fields[name] && options.fields[name].defaultFilter) {
                    updatedFilters[name] = { ...updatedFilters[name], in: options.fields[name].defaultFilter }
                } else {
                    delete updatedFilters[name]
                }
                dispatch(options.reducers.filterBy(updatedFilters))
                break;
            }
            case "sort": {
                const updatedSorts = [...sortBy]
                const indexOf = sortBy.findIndex(item => item.name === name)
                if (indexOf > -1) {
                    updatedSorts.splice(indexOf, 1)
                }
                dispatch(options.reducers.sortBy(updatedSorts))
                break;
            }
        }
    }

    const fieldSorter = (sortList: Array<SortBy>) => {
        return function (a: any, b: any) {
            return sortList
                .map(function (sort: SortBy) {
                    let dir = 1;
                    if (sort.direction === '-') {
                        dir = -1;
                    }
                    if (a[sort.name] !== null && b[sort.name] !== null && typeof (a[sort.name]) !== 'undefined' && typeof (b[sort.name]) !== 'undefined') {
                        if (typeof a[sort.name] === "number" && typeof b[sort.name] === "number") {
                            if (a[sort.name] > b[sort.name]) return dir;
                            if (a[sort.name] < b[sort.name]) return -(dir);
                        } else {

                            if (a[sort.name].toString().toUpperCase() > b[sort.name].toString().toUpperCase()) return dir;
                            if (a[sort.name].toString().toUpperCase() < b[sort.name].toString().toUpperCase()) return -(dir);
                        }
                    }
                    return 0;
                })
                .reduce(function firstNonZeroValue(p: any, n: any) {
                    return p ? p : n;
                }, 0);
        };
    }

    const filterAndSortList = (list: Array<any>): Array<string> => {

        const filteredList = list.filter(elem => {
            let match = true
            if (typeof options.onlyActive === "undefined" || options.onlyActive) {
                match = match && elem.active
            }


            for (const field of Object.keys(filterBy)) {
                if (filterBy[field].like) {
                    match = match && elem[field] !== null && removeAccents(elem[field].toString()).toUpperCase().includes(removeAccents(filterBy[field].like!).toUpperCase())
                }

                if (filterBy[field].in && options.fields[field]) {
                    const inOpt = options.fields[field].inOpt!
                    if (inOpt.raw) {
                        if (typeof inOpt.multipleValues !== "undefined" && inOpt.multipleValues) {
                            match = match && elem[field] !== null && elem[field].some((item: any) => filterBy[field].in!.includes(item))

                        } else {
                            match = match && elem[field] !== null && filterBy[field].in!.includes(elem[field])
                        }
                    }
                    if (inOpt.dbt) {
                        if (typeof inOpt.multipleValues !== "undefined" && inOpt.multipleValues) {
                            match = match && elem[field] !== null && elem[inOpt.dbt.idFieldName].some((item: any) => filterBy[field].in!.includes(item))

                        } else {
                            match = match && elem[field] !== null && filterBy[field].in!.includes(String(elem[inOpt.dbt.idFieldName]))

                        }
                    }
                }

                if (filterBy[field].gte || filterBy[field].lte) {
                    if (filterBy[field].gte) {
                        const gteIsDate = moment(filterBy[field].gte!, 'YYYY-MM-DD', true).isValid()
                        if (gteIsDate) {
                            match = match && elem[field] !== null && moment(elem[field]).isAfter(filterBy[field].gte)

                        } else {

                            match = match && elem[field] !== null && elem[field] >= Number(filterBy[field].gte)
                        }
                    }
                    if (filterBy[field].lte) {
                        const lteIsDate = moment(filterBy[field].lte!, 'YYYY-MM-DD', true).isValid()
                        if (lteIsDate) {
                            match = match && elem[field] !== null && moment(elem[field]).isBefore(filterBy[field].lte)

                        } else {
                            match = match && elem[field] !== null && elem[field] <= Number(filterBy[field].lte)
                        }
                    }
                }
                if (typeof filterBy[field].bool != "undefined") {
                    match = match && elem[field] !== null && String(elem[field]) === String(filterBy[field].bool)

                }
            }
            return match
        })
        filteredList.sort(fieldSorter(sortBy))

        return filteredList.map(item => String(item.id))

    }

    interface FilterListProps {
        _filterBy?: FilterBy
        _deleteFilterSortByName?: (name: string, type: string) => void
        _resetFilter?: () => void
    }

    const FilterList: React.FC<FilterListProps> = ({ _filterBy, _deleteFilterSortByName, _resetFilter }) => {
        const [filterArray, setFilterArray] = useState<FilterRender[]>([])

        useEffect(() => {
            const TabfilterElementRender: FilterRender[] = []

            const filterByConf = _filterBy ? _filterBy : filterBy

            Object.keys(filterByConf)
                .filter(key => key !== "all" && key !== "filterOnlyLaboratoryRoleLevel")
                .forEach(key => {
                    const filterRender: any = {}
                    filterRender.name = key
                    filterRender.type = "filter"
                    filterRender.label = options.fields[key] && options.fields[key].noTrad ? key + " : " : t(options.i18nPrefix + key) + " : "
                    filterRender.editable = options.fields[key] ? options.fields[key].editable : true

                    Object.keys(filterByConf[key])
                        .forEach((filterKey, index) => {
                            switch (filterKey) {
                                case "like":
                                    if (index > 0) filterRender.label += ", "
                                    filterRender.label += t('sortFilter.contains') + " " + filterByConf[key][filterKey] + " "
                                    break;
                                case "in":
                                    const mergeTwinArray: String[] = [];
                                    if (index > 0) filterRender.label += ", "
                                    filterRender.label += t('sortFilter.in') + " " + filterByConf[key][filterKey]?.map(item => {
                                        const inOpt = options.fields[key]?.inOpt
                                        if (options.fields[key] && inOpt) {
                                            if (inOpt.t) {
                                                return t(inOpt.t.tPrefix + item)
                                            } else if (inOpt.dbt) {
                                                const label = dbt(inOpt.dbt.elements, item, language.id, inOpt.dbt.field)
                                                if (inOpt.mergeTwin && !mergeTwinArray.includes(label)) {
                                                    mergeTwinArray.push(label)
                                                    return label
                                                } else if (!inOpt.mergeTwin) {
                                                    const label = dbt(inOpt.dbt.elements, item, language.id, inOpt.dbt.field)
                                                    return label
                                                }
                                            } else if (inOpt.raw && inOpt.raw.elements) {
                                                return inOpt.raw.elements[item]
                                            } else {
                                                return item
                                            }
                                        } else {
                                            return item
                                        }
                                    }).filter(item => item).join(', ')
                                    break;
                                case "gte": {
                                    if (moment(filterByConf[key][filterKey]!, 'YYYY-MM-DD', true).isValid()) {
                                        filterRender.label += t('sortFilter.from') + " " + new Intl.DateTimeFormat(authUser.locale).format(new Date(filterByConf[key][filterKey]!)) + " "
                                    } else if (moment(filterByConf[key][filterKey]!, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid()) {
                                        filterRender.label += t('sortFilter.from') + " " + new Intl.DateTimeFormat(authUser.locale, { dateStyle: 'short', timeStyle: 'medium' }).format(new Date(filterByConf[key][filterKey]!)) + " "
                                    } else {
                                        filterRender.label += t('sortFilter.from_number') + " " + (filterByConf[key][filterKey]) + " "
                                    }
                                    break;
                                }
                                case "lte": {
                                    if (moment(filterByConf[key][filterKey]!, 'YYYY-MM-DD', true).isValid()) {
                                        filterRender.label += t('sortFilter.to') + " " + new Intl.DateTimeFormat(authUser.locale).format(new Date(filterByConf[key][filterKey]!)) + " "
                                    } else if (moment(filterByConf[key][filterKey]!, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid()) {
                                        filterRender.label += t('sortFilter.to') + " " + new Intl.DateTimeFormat(authUser.locale, { dateStyle: 'short', timeStyle: 'medium' }).format(new Date(filterByConf[key][filterKey]!)) + " "
                                    }
                                    else {
                                        filterRender.label += t('sortFilter.to_number') + " " + (filterByConf[key][filterKey]) + " "
                                    }
                                    break;
                                }
                                case "eq":
                                    if (index > 0) filterRender.label += ", "
                                    if (typeof filterByConf[key][filterKey] === "string" || typeof filterByConf[key][filterKey] === "number") {
                                        filterRender.label += t('sortFilter.equals') + " " + filterByConf[key][filterKey]
                                    }
                                    if (typeof filterByConf[key][filterKey] === "boolean") {
                                        filterRender.label += filterByConf[key][filterKey] ? t('yes') : t('no')
                                    }
                                    break;
                                case "bool": {
                                    if (index > 0) filterRender.label += ", "
                                    const labels = options.fields[key]?.boolOpt ? options.fields[key]?.boolOpt!.labels : undefined
                                    if (filterByConf[key][filterKey]) {
                                        filterRender.label += labels && labels!.true ? labels!.true : t('yes')
                                    }
                                    if (!filterByConf[key][filterKey]) {
                                        filterRender.label += labels && labels!.false ? labels!.false : t('no')
                                    }
                                    break;
                                }
                            }
                        })
                    TabfilterElementRender.push(filterRender)
                })

            sortBy.forEach(item => {
                const sortRender: FilterRender = {
                    name: item.name,
                    type: "sort",
                    label: (options.fields[item.name] && options.fields[item.name].noTrad ? item.name : t(options.i18nPrefix + item.name)) + " : " + (item.direction === "-" ? t('sortFilter.descending') : t('sortFilter.ascending')),
                    editable: true
                }
                TabfilterElementRender.push(sortRender)
            })
            setFilterArray(TabfilterElementRender)
        }, [_filterBy])

        const deleteFilter = _deleteFilterSortByName ? _deleteFilterSortByName : deleteFilterSortByName
        const reset = _resetFilter ? _resetFilter : resetFilter

        return (
            <>
                <Row>
                    {filterArray.length > 0 && filterArray.find((filterElement: FilterRender) => filterElement.editable !== false) &&
                        <>
                            {lg &&
                                <Col xs="auto" className="p-0 me-2 d-flex align-items-start" >
                                    <span className="fw-bold">{t('sortFilter.currentFilter')}</span>
                                </Col>
                            }
                            <Col className="p-0 me-2 d-flex align-items-start" >
                                <Row>
                                    <Col>
                                        {filterArray.map((filterElement: FilterRender) => {
                                            if (filterElement.editable !== false) {
                                                return (
                                                    <span className="ms-2 text-wrap" key={filterElement.type + "-" + filterElement.name}>
                                                        <span className="smalltext">{filterElement.label}</span>
                                                        <Button variant="link" className="text-danger p-0 ms-1" style={{ height: 20, width: 15 }}>
                                                            <DeleteCircle className="align-top pb-1" id="testid" width="15px" height="20px" onClick={() => deleteFilter(filterElement.name, filterElement.type)} />
                                                        </Button>
                                                        {!lg &&
                                                            <br />
                                                        }
                                                    </span>
                                                )
                                            }
                                        })}
                                    </Col>
                                </Row>
                            </Col>
                            {lg &&
                                <Col xs="auto" className="pl-0 pt-0 pb-0 pr-1 me-auto" >
                                    <Button size="sm" className="ms-2" variant="dark" type="button" onClick={() => reset()}>
                                        <DeleteCircleFill width="16px" height="100%" className="text-white align-text-bottom" />
                                        <span className="ms-1" > {t('sortFilter.resetFilter')}</span>
                                    </Button>
                                </Col>
                            }
                        </>
                    }
                </Row>
                {!lg && filterArray.length > 0 &&
                    <Row className="justify-content-center mt-2" >
                        <Col xs="auto" className="flex-fill">
                            <Button size="sm" className="w-100" variant="dark" type="button" onClick={() => reset()}>
                                <DeleteCircleFill width="16px" height="100%" className="text-white align-text-bottom" />
                                <span className="ms-1" > {t('sortFilter.resetFilter')}</span>
                            </Button>
                        </Col>
                    </Row>
                }
            </>
        )
    }

    const buildFilterEnum = (name: string): Array<FilterEnum> => {
        let filterEnum: Array<FilterEnum> = []
        if (options.fields[name]) {
            const inOpt = options.fields[name].inOpt
            if (inOpt && inOpt.dbt) {
                const elements = inOpt.dbt.elements
                const fieldName = inOpt.dbt.field
                const dbtFieldName = inOpt.dbt.dbtIdFieldName ? inOpt.dbt.dbtIdFieldName : "id"
                filterEnum = Object.values(elements).map(item => ({ name: item[dbtFieldName].toString(), label: dbt(elements, item[dbtFieldName], language.id, fieldName) }))
            } else if (inOpt && inOpt.t) {
                const elements = inOpt.t.elements
                filterEnum = elements.map(fieldName => ({ name: fieldName, label: t(inOpt.t!.tPrefix + fieldName) }))
            } else if (inOpt && inOpt.raw) {
                const elements = inOpt.raw.elements
                filterEnum = Object.entries(elements).map(([fieldName, value]) => ({ name: fieldName, label: value }))
            }
        }
        return filterEnum
    }

    interface ListHeaderProps {
        name: string,
        deleteIcon?: boolean
        deleteIconState?: boolean
        onClickDelete?: () => void
        addClassToButton?: string
        addStyleToButton?: any
        noPrefix?: boolean
        mergeTwin?: boolean
    }
    const ListHeader: React.FC<ListHeaderProps> = ({ name, deleteIcon = false, deleteIconState = true, onClickDelete = () => (null), addClassToButton = "", addStyleToButton = null, noPrefix, mergeTwin = false }) => {

        const filterEnum = buildFilterEnum(name)
        return (
            <ButtonHeaderList
                addSort={addSort}
                title={noPrefix || (options.fields[name] && options.fields[name].noTrad) ? name : t(options.i18nPrefix + name)}
                name={name}
                type={options.fields[name].type}
                sortable={!!options.fields[name].sortable}
                addFilter={addFilter}
                filterByEnum={filterEnum.length > 0 ? filterEnum : undefined}
                curSort={sortBy.filter(elem => (elem.name === name)).length > 0 ? sortBy.filter(elem => (elem.name === name))[0].direction : ""}
                curFilter={filterBy[name] ? filterBy[name] : undefined}
                boolLabels={options.fields[name].boolOpt ? options.fields[name].boolOpt!.labels : undefined}
                size={options.size}
                maxHeight={options.maxHeight}
                disableFilter={options.fields[name].editable === false ? true : false}
                deleteIcon={deleteIcon}
                deleteIconState={deleteIconState}
                onClickDelete={onClickDelete}
                addClassToButton={addClassToButton}
                addStyleToButton={addStyleToButton}
                mergeTwin={mergeTwin}
            />
        )
    }

    const FitlerModalButton: React.FC = () => {
        const [showFilters, setShowFilters] = useState(false);

        const handleShowFilters = () => {
            setShowFilters(true)
        }

        const handleCloseFilters = () => {
            setShowFilters(false)
        }

        return (
            <>
                <FiltersModal show={showFilters} onHide={handleCloseFilters} />
                <Button className="me-1" size="sm" variant={Object.keys(filterBy).some(item => item !== 'all') ? "success" : "secondary"}
                    onClick={e => { handleShowFilters() }}
                >
                    {Object.keys(filterBy).some(item => item !== 'all') ?
                        <Filtered width="20px" height="100%" className="align-text-top" />
                        : <NotFiltered width="20px" height="100%" className="align-text-top" />
                    }

                </Button>
            </>
        )
    }


    interface MobileFiltersModalProps {
        show: boolean
        onHide: () => void
    }

    const FiltersModal: React.FC<MobileFiltersModalProps> = ({ show, onHide }) => {

        const dispatch = useDispatch()
        const { t } = useTranslation()

        const [modalFilterBy, setModalFilterBy] = useState<FilterBy>(filterBy)

        const handleShowFilters = () => {
            setModalFilterBy(filterBy)
        }

        const applyFilters = () => {
            dispatch(options.reducers.filterBy(Object.assign({ ...modalFilterBy },
                filterBy.all ? { all: { ...filterBy.all } } : null
            )))
            onHide()
        }

        const handleCloseFilters = () => {
            const formFilters = Object.assign({ ...modalFilterBy },
                filterBy.all ? { all: { ...filterBy.all } } : null
            )
            if (JSON.stringify(filterBy) !== JSON.stringify(formFilters)) {
                applyFilters()
            } else {
                onHide()
            }
        }

        const resetFilter = () => {
            setModalFilterBy({})
        }

        const deleteFilterSortByName = (idElement: string, type: string) => {
            switch (type) {
                case "filter": {
                    const updated = { ...modalFilterBy }
                    delete updated[idElement]
                    setModalFilterBy(updated)
                    break;
                }
                case "sort":
                    break;
            }
        }

        interface IProps {
            eventKey: string
            title?: string
        }

        const ContextAwareToggle = ({ eventKey, title }: IProps) => {
            const { activeEventKey } = useContext(AccordionContext);

            const decoratedOnClick = useAccordionButton(
                eventKey
            );

            const isCurrentEventKey = activeEventKey === eventKey;

            return (
                <Row className={`align-items-center`} >
                    <Col>
                        <Card.Header onClick={decoratedOnClick} >
                            <Row className="p-0">
                                <Col xs={10}>
                                    <h5>{title}</h5>
                                </Col>
                                <Col xs={2} className="text-end">
                                    {isCurrentEventKey ?
                                        <Uncollapsed width="16px" height="100%" className="text-dark align-text-bottom" />
                                        :
                                        <Collapsed width="16px" height="100%" className="text-dark align-text-bottom" />
                                    }
                                </Col>
                            </Row>
                        </Card.Header>
                    </Col>
                </Row>

            );
        }

        const filterReducer = (name: string, filter: Filter | undefined) => {
            const updated = { ...modalFilterBy }
            if (typeof filter === "undefined") {
                delete updated[name]
            } else {
                updated[name] = filter
            }
            setModalFilterBy(updated)
        }

        return (

            <Modal size="lg" show={show} onShow={handleShowFilters} onHide={handleCloseFilters}>
                <Modal.Header closeButton>
                    <Modal.Title><span className="text-primary fw-bold"> {t('sortFilter.filters')} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FilterList _filterBy={modalFilterBy} _deleteFilterSortByName={deleteFilterSortByName} _resetFilter={resetFilter} />

                    <Row className="justify-content-center mt-2" >
                        <Col xs="auto" className="flex-fill">
                            <Button size="sm" className="w-100" variant="secondary" type="button" onClick={() => applyFilters()}>
                                <span className="ms-1" > {t('sortFilter.applyFilter')}</span>
                            </Button>
                        </Col>
                    </Row>

                    <Accordion className="mt-2">
                        {Object.entries(options.fields)
                            .filter(([field, opt]) => opt.modal && opt.modal.displayed)
                            .map(([field, opt]) => {

                                const filterEnum = buildFilterEnum(field)

                                return (
                                    <Card key={field}>

                                        {(opt.modal && opt.modal.collapsible) ?
                                            <ContextAwareToggle eventKey={field} title={options.fields[field] && options.fields[field].noTrad ? field : t(options.i18nPrefix + field)} />
                                            :
                                            <Row>
                                                <Col>
                                                    <Card.Header>
                                                        <h5>{options.fields[field] && options.fields[field].noTrad ? field : t(options.i18nPrefix + field)}</h5>
                                                    </Card.Header>
                                                </Col>
                                            </Row>
                                        }

                                        {opt.modal && typeof opt.modal.collapsible !== undefined && opt.modal.collapsible ?
                                            <Accordion.Collapse eventKey={field}>
                                                <FilterForm name={field} type={opt.type} filter={modalFilterBy[field]} filterReducer={filterReducer} filterByEnum={filterEnum.length > 0 ? filterEnum : undefined} boolLabels={options.fields[field].boolOpt ? options.fields[field].boolOpt!.labels : undefined} />
                                            </Accordion.Collapse>
                                            :
                                            <FilterForm name={field} type={opt.type} filter={modalFilterBy[field]} filterReducer={filterReducer} filterByEnum={filterEnum.length > 0 ? filterEnum : undefined} boolLabels={options.fields[field].boolOpt ? options.fields[field].boolOpt!.labels : undefined} />
                                        }
                                    </Card>
                                )
                            })
                        }
                    </Accordion>
                </Modal.Body>
            </Modal>

        )
    }

    return ({
        FilterList,
        ListHeader,
        FitlerModalButton,
        filterAndSortList
    })
}

export default useFilterToolkit