import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import ConfigApi, {
    AnalyteTestPackage, AnalyteTestsPackages, getKeyForTabAssocAnalyteTestPackage,
} from "../api/configApi";
import {
    buildDefaultExtraReducersWithFrontPagination,
    CurrentState,
    getDefaultReducersWithFrontPagination,
    getInitialState,
} from "./DefaultSlice";

const initialState = getInitialState<AnalyteTestPackage>();

// analyte_tests_packages

export const fetchAnalyteTestPackages = createAsyncThunk(
    "config/fetchAnalyteTestPackages",
    async ({ laboratoryId }: { laboratoryId: number }) => {
        const response = await ConfigApi.getInstance().getRelationships(
            laboratoryId,
            'analytes_test_packages'
        );
        return (response as AnalyteTestsPackages).analytes_test_packages
    }
);

export const fetchDeleteAnalyteTestPackage = createAsyncThunk(
    "config/fetchDeleteAnalyteTestPackage",
    async (analyteTestPackage: AnalyteTestPackage, { dispatch }) => {
        const response = await ConfigApi.getInstance().deleteRelationship(analyteTestPackage.laboratory_id, analyteTestPackage );

        if (!response) {
            throw new Error("Unable to delete analyte test package");
        }   

        dispatch(deleteReducer(analyteTestPackage));

        return true;
    }
);



const addItemToAnalyteTestsPackages = (
    state: CurrentState<AnalyteTestPackage>,
    item: AnalyteTestPackage
) => {
        if (item.laboratory_id && item.code_analyte && item.code_test && item.package_id){
            state.data[getKeyForTabAssocAnalyteTestPackage(item)] = item;
            state.data[getKeyForTabAssocAnalyteTestPackage(item)].id = getKeyForTabAssocAnalyteTestPackage(item)
        }

};

const postDeleteAnalyteTestPackage = (
    state: CurrentState<AnalyteTestPackage>,
    item: AnalyteTestPackage
) => {
    delete state.data[getKeyForTabAssocAnalyteTestPackage(item)];
    state.filtered.splice(
        state.filtered.indexOf(getKeyForTabAssocAnalyteTestPackage(item)),
        1
    );
};


const analyteTestPackageSlice = createSlice({
    name: "relationship analyte tests packages",
    initialState,
    reducers: {...getDefaultReducersWithFrontPagination(
        addItemToAnalyteTestsPackages,
        postDeleteAnalyteTestPackage  
    ), updateAnalyteTestPackage : (state: CurrentState<AnalyteTestPackage>, action: PayloadAction<AnalyteTestPackage[]>) => {
        state.data = {};
        action.payload.forEach((item: AnalyteTestPackage) => {
            addItemToAnalyteTestsPackages(state, item);
        });
    }},
    extraReducers: (builder) => {
        buildDefaultExtraReducersWithFrontPagination(
            addItemToAnalyteTestsPackages,
            builder,
            fetchAnalyteTestPackages
        );
    },
});

export const {
    updateAnalyteTestPackage,
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage,
} = analyteTestPackageSlice.actions;

export default analyteTestPackageSlice.reducer;
