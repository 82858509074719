import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Row, Container, Table } from 'react-bootstrap';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';
import { setSamplesFilterBy, setSamplesSortBy, setDataOfAnalytes, setInitialDataOfAnalytes } from '../../../state/dashboardSlice';
import { calculateAll } from './utils';
import { ScrollSyncNode } from 'scroll-sync-react'

interface DashboardInitialFilteringComponentContainerProps extends WithTranslation {
    dashboardName: string
}

const DashboardInitialFilteringComponent: React.FC<DashboardInitialFilteringComponentContainerProps> = ({ t, dashboardName }) => {
    const dispatch = useDispatch()

    // user state
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    // languages
    const languages = useSelector((state: RootState) => state.config.languages.data)
    const language = Object.values(languages).find(lg => lg.id === Number(authUser.language_id))

    // samples state
    const samplesForData = useSelector((state: RootState) => state.dashboard.samplesForData)
    const resultsHeader = useSelector((state: RootState) => state.dashboard.resultsHeaders)
    const pagination = useSelector((state: RootState) => state.dashboard.paginationSamples)
    const paramsFromComponents = useSelector((state: RootState) => state.dashboard.paramsFromComponents)
    const initialDatasCalculated = useSelector((state: RootState) => state.dashboard.initialDataOfAnalytes)
    const fieldsForSamplesInfo = useSelector((state: RootState) => state.dashboard.fieldsForSamplesInfo)

    const resultsFields = () => {
        const fields = {
            initial_filtering: {
                type: [],
                sortable: false
            }
        }
        resultsHeader.forEach(resultHeader => {
            Object.assign(fields, {[resultHeader.name.translations[language && language.code_language ? language.code_language : '']]: { type: [], sortable: false, noTrad: true }})
        })
        fieldsForSamplesInfo.forEach(field => {
            Object.assign(fields, {[field.field]: { type: [], sortable: false, noTrad: false }})
        })
        return fields
    }

    const { ListHeader, filterAndSortList, FilterList } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "dashboard.initial_filtering.columns.",
        onlyActive: false,
        size: 'xs',
        fields: resultsFields(),
        reducers: {
            filterBy: setSamplesFilterBy,
            sortBy: setSamplesSortBy
        }
    })

    useEffect(() => {
        if(Object.values(samplesForData).length > 0){
            dispatch(setInitialDataOfAnalytes(calculateAll(samplesForData, resultsHeader, paramsFromComponents.date, dashboardName)))
        }
    }, [])

    return (
        <Container className={'mt-2 p-0 ms-0 me-0'}>
            <Container className="p-2 bg-white rounded-3 mb-0">
                <Row>
                    <ScrollSyncNode group="horiz-scroll">
                        <Container className='ms-2 me-2 p-0 noshrink' style={{overflow: "auto"}}>
                            <Table key='initial_filtering' striped hover size='sm' className="bg-white text-center table-fixed table-xs mb-0">
                                <thead className='bg-white'>
                                    <tr>
                                        <th className="p-0" />
                                        <th className="p-1 fixed-width">
                                            <ListHeader name='initial_filtering' />
                                        </th>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <th className="p-1" key={`header-${header.id}`}>
                                                    <ListHeader name={header.name.translations[language && language.code_language ? language.code_language : '']} addClassToButton="add-pad" />
                                                </th>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return (
                                                <th className="p-1" key={`initial_filtering-${field.field}`}>
                                                    <ListHeader name={field.field} addClassToButton="add-pad-no-sort" />
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key="number">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.number')}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`number-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].number ? initialDatasCalculated[header.id.toUpperCase()].number : 0}</td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return(
                                                <td></td> 
                                        )})}
                                    </tr>
                                    <tr key="min">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.min')}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`min-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].min ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].min) : 0}</td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return(
                                                <td></td> 
                                        )})}
                                    </tr>
                                    <tr key="max">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.max')}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`max-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].max ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].max) : 0}</td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return(
                                                <td></td> 
                                        )})}
                                    </tr>
                                    {paramsFromComponents.average &&
                                        <tr key="average">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.average')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`average-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].average ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].average) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return(
                                                    <td></td> 
                                            )})}
                                        </tr>
                                    }
                                    <tr key="deviation">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.deviation')}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`deviation-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].deviation ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].deviation) : 0}</td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return(
                                                <td></td> 
                                        )})}
                                    </tr>
                                    {paramsFromComponents.average1 &&
                                        <tr key="averageplus">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.averageplus')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averageplus-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].averagePlus ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].averagePlus) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return(
                                                    <td></td> 
                                            )})}
                                        </tr>
                                    }
                                    {paramsFromComponents.average1 &&
                                        <tr key="averageminus">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.averageminus')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averagemin-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].averageMinus ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].averageMinus) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return(
                                                    <td></td> 
                                            )})}
                                        </tr>
                                    }
                                    {paramsFromComponents.average2 &&
                                        <tr key="averageplus2">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.averageplus2')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averageplus2-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].averagePlus2 ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].averagePlus2) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return(
                                                    <td></td> 
                                            )})}
                                        </tr>
                                    }
                                    {paramsFromComponents.average2 &&
                                        <tr key="averageminus2">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.averageminus2')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averagemin2-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].averageMinus2 ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].averageMinus2) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return(
                                                    <td></td> 
                                            )})}
                                        </tr>
                                    }
                                    {paramsFromComponents.average3 &&
                                        <tr key="averageplus3">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.averageplus3')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averageplus3-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].averagePlus3 ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].averagePlus3) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return(
                                                    <td></td> 
                                            )})}
                                        </tr>
                                    }
                                    {paramsFromComponents.average3 &&
                                        <tr key="averageminus3">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.averageminus3')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`averagemin3-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].averageMinus3 ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].averageMinus3) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return(
                                                    <td></td> 
                                            )})}
                                        </tr>
                                    }
                                    {paramsFromComponents.trend &&
                                        <tr key="trend">
                                            <td className="p-1 table-hover-hint" />
                                            <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.trend')}</td>
                                            {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                                return (
                                                    <td className='ps-1 pe-1' key={`trend-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].slope ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].slope) : 0}</td>
                                                )
                                            })}
                                            {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                                return(
                                                    <td></td> 
                                            )})}
                                        </tr>
                                    }
                                    <tr key="coef">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.coef')}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`coef-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].coef ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].coef) : 0}</td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return(
                                                <td></td> 
                                        )})}
                                    </tr>
                                    <tr key="weighted_average">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{t('dashboard.initial_filtering.lines.weighted_average')}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`weighted_average-${header.id}`}>{initialDatasCalculated[header.id.toUpperCase()] && initialDatasCalculated[header.id.toUpperCase()].weighted_average ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(initialDatasCalculated[header.id.toUpperCase()].weighted_average!) : 0}</td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return(
                                                <td></td> 
                                        )})}
                                    </tr>
                                </tbody>
                            </Table>
                        </Container>
                    </ScrollSyncNode>
                </Row>
            </Container>
        </Container>
    )
}

export default withTranslation()(DashboardInitialFilteringComponent)