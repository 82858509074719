import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import Container from 'react-bootstrap/Container';

const InlineLoader: React.FC<WithTranslation> = ({t}) => (
    <Container>
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
        <span className="ms-2">{t('loading')}</span>
    </Container>
)

export default withTranslation()(InlineLoader)
