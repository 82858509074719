import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Row, Container, Col, Button, Modal, Form } from 'react-bootstrap';
import { AnalyteForModal, resetSearchState, search, setAverageOutliers, setFiltersForGroup, setOutliers } from '../../../state/dashboardSlice';
import { Formik } from 'formik';
import { AnalyteRequest, Outliers, SearchRequest } from '../../../api/dashboardApi';
import { buildSelectSmallStyle, buildSelectSmallStyleWithSmallMenu } from '../../../common/utils/utils';
import Select from 'react-select'
import { ReactComponent as CheckedIcon } from 'bootstrap-icons/icons/check-square.svg'
import { ReactComponent as UncheckedIcon } from 'bootstrap-icons/icons/square.svg'
import MatrixApi, { Matrix, MatrixTemplate } from '../../../api/matrixApi';

interface ModalDashboardParamsOutliersComponentContainerProps extends WithTranslation {
    dashboardName: string,
    showModal: boolean,
    setShowModal: any
}

interface OptionSelect {
    label: string,
    value: string
}

const on: OptionSelect[] = [
    {label: 'sample', value: 'sample'},
    {label: 'analyte', value: 'analyte'}
]

const ModalDashboardParamsOutliersComponent: React.FC<ModalDashboardParamsOutliersComponentContainerProps> = ({ t, dashboardName, showModal, setShowModal }) => {
    const dispatch = useDispatch()

    // user state
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    // languages
    const languages = useSelector((state: RootState) => state.config.languages.data)
    const language = Object.values(languages).find(lg => lg.id === Number(authUser.language_id))
    const companies = useSelector((state: RootState) => state.companies.data)

    // samples state
    const outliers = useSelector((state: RootState) => state.dashboard.outliers)
    const valuesForSearch = useSelector((state: RootState) => state.dashboard.valuesForSearch)
    const averageOutliers = useSelector((state: RootState) => state.dashboard.paramsFromComponents.averageOutliers)
    const params = useSelector((state: RootState) => state.dashboard.paramsFromComponents)
    const analytes = useSelector((state: RootState) => state.dashboard.resultsHeaders).map(rh => {return {label: rh.name.translations[language && language.code_language ? language.code_language : ''], value: rh.id}})

    return (
        <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title><span className="text-primary fw-bold"> {t('dashboard.params.modal_outliers.title')} </span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        outliers: outliers
                    }}
                    onSubmit={async (values) => {
                        const outliersToSend: Outliers = {outliers_on: values.outliers.outliers_on, mdi: values.outliers.mdi, gh: values.outliers.gh, low_high_limit: values.outliers.low_high_limit, analyte_max: values.outliers.analytes.filter(am => am.analyte_id !== '' && am.max_value && am.checked).map(am => {return {analyte_id: am.analyte_id, value: am.max_value}}), analyte_min: values.outliers.analytes.filter(am => am.analyte_id !== '' && am.min_value && am.checked).map(am => {return {analyte_id: am.analyte_id, value: am.min_value}})}
                        dispatch(setOutliers(values.outliers))
                        if(valuesForSearch && values.outliers !== outliers){
                            dispatch(resetSearchState())
                            let filter = valuesForSearch.common_filter.filter.map((f: any) => {return {field: f.field, values: f.values}})
                            let filterSets = valuesForSearch.filter_set.map((fs: any) => {return {filter: fs.filter.map((f: any) => {return {field: f.field, values: f.values}})}})
    
                            const body: SearchRequest = {
                                analytes: dashboardName === 'matrix' ? valuesForSearch.analytes : undefined,
                                company_id: dashboardName === 'matrix' ? valuesForSearch.company_id : undefined,
                                common_filter: {...valuesForSearch.common_filter, filter: filter, from: new Date(valuesForSearch.common_filter.from).toISOString(), to: new Date(valuesForSearch.common_filter.to).toISOString()},
                                filter_set: filterSets,
                                outliers: outliersToSend ? outliersToSend : undefined
                            }
                            dispatch(setFiltersForGroup(body))
                            dispatch(search({dashboardName, body}))
                        }
                        setShowModal(false)
                    }}
                >
                    {/* Callback function containing Formik state and helpers that handle common form actions */}
                    {function myForm({   
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit }){
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Container>
                                        <Row>
                                            <Col lg='auto'>
                                                {t('dashboard.params.modal_outliers.on')}
                                            </Col>
                                            <Col lg='auto'>
                                                <Select
                                                    name="select-on"
                                                    value={values.outliers.outliers_on !== '' ? {label: values.outliers.outliers_on, value: values.outliers.outliers_on} : null}
                                                    isClearable={false}
                                                    options={on}
                                                    placeholder={t('dashboard.params.modal_outliers.select_placeholder')}
                                                    getOptionLabel={(option: OptionSelect) => t(`dashboard.params.modal_outliers.${option.label}`)}
                                                    getOptionValue={(option: OptionSelect) => option.value}
                                                    onChange={(option: OptionSelect) => {
                                                        setFieldValue('outliers.outliers_on', option.value)
                                                    }}
                                                    styles={buildSelectSmallStyleWithSmallMenu()}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='mt-4'>
                                            <Col lg={1}>
                                                <Button className="p-0 text-primary" variant="link" onClick={() => {
                                                    setFieldValue('outliers.mdi', !values.outliers?.mdi)
                                                }} >
                                                    {values.outliers.mdi &&
                                                        <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                                    }
                                                    {!values.outliers.mdi &&
                                                        <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                                    }
                                                </Button>
                                            </Col>
                                            <Col lg={11} className='ps-0'>
                                                <p>{t('dashboard.params.modal_outliers.mdi')}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={1}>
                                                <Button className="p-0 text-primary" variant="link" onClick={() => {
                                                    setFieldValue('outliers.gh', !values.outliers?.gh)
                                                }} >
                                                    {values.outliers.gh &&
                                                        <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                                    }
                                                    {!values.outliers.gh &&
                                                        <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                                    }
                                                </Button>
                                            </Col>
                                            <Col lg={11} className='ps-0'>
                                                <p>{t('dashboard.params.modal_outliers.gh')}</p>
                                            </Col>                                            
                                        </Row>
                                        <Row>
                                            <Col lg={1}>
                                                <Button className="p-0 text-primary" variant="link" onClick={() => {
                                                    setFieldValue('outliers.low_high_limit', !values.outliers?.low_high_limit)
                                                }} >
                                                    {values.outliers.low_high_limit &&
                                                        <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                                    }
                                                    {!values.outliers.low_high_limit &&
                                                        <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                                    }
                                                </Button>
                                            </Col>
                                            <Col lg={11} className='ps-0'>
                                                <p>{t('dashboard.params.modal_outliers.low_high_limit')}</p>
                                            </Col>                                             
                                        </Row>
                                        {(params.average1 || params.average2 || params.average3) &&
                                            <Row>
                                                <Col lg={1}>
                                                    <Button className="p-0 text-primary" variant="link" onClick={() => {
                                                        dispatch(setAverageOutliers())
                                                    }} >
                                                        {averageOutliers &&
                                                            <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                                        }
                                                        {!averageOutliers &&
                                                            <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                                        }
                                                    </Button>
                                                </Col>
                                                <Col lg={11} className='ps-0'>
                                                    <p>{t('dashboard.params.modal_outliers.average_outliers', {sd: params.average1 ? '1' : params.average2 ? '2' : '3'})}</p>
                                                </Col>                                              
                                            </Row>
                                        }
                                        {values.outliers.analytes.map((a, index) => {
                                            return (
                                                <Row>
                                                    <Col lg={1}>
                                                        <Button className="p-0 text-primary" variant="link" onClick={() => {
                                                            let arrayAnalytes: AnalyteForModal[] = JSON.parse(JSON.stringify(values.outliers.analytes))
                                                            arrayAnalytes[index].checked = !a.checked
                                                            arrayAnalytes = arrayAnalytes.filter(am => am.analyte_id !== '')
                                                            if(!a.checked){
                                                                arrayAnalytes.push({analyte_id: '', min_value: null, max_value: null, checked: false})
                                                            }
                                                            setFieldValue('outliers.analytes', arrayAnalytes)
                                                        }} >
                                                            {a.checked &&
                                                                <CheckedIcon width="18px" height="18px" className="align-text-top" />
                                                            }
                                                            {!a.checked &&
                                                                <UncheckedIcon width="18px" height="18px" className="align-text-top" />
                                                            }
                                                        </Button>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Select
                                                            name={`select-analyte-${index}`}
                                                            value={analytes.find(an => an.value === a.analyte_id)}
                                                            isClearable={true}
                                                            options={analytes.filter(an => values.outliers.analytes.filter(v => an.value === v.analyte_id).length < 1)}
                                                            placeholder={t('dashboard.params.modal_outliers.select_placeholder_analytes')}
                                                            getOptionLabel={(option: OptionSelect) => option.label}
                                                            getOptionValue={(option: OptionSelect) => option.value}
                                                            onChange={(option: OptionSelect) => {
                                                                setFieldValue(`outliers.analytes[${index}].analyte_id`, option ? option.value : '')
                                                            }}
                                                            styles={buildSelectSmallStyle()}
                                                        />
                                                    </Col>   
                                                    <Col lg='auto'>
                                                        {' < '}
                                                    </Col>  
                                                    <Col lg={2}>
                                                        <Form.Control size="sm" type="number" name={`outliers.analytes[${index}].min_value`} maxLength={255} value={values.outliers.analytes[index].min_value ? values.outliers.analytes[index].min_value! : undefined} onChange={handleChange} onBlur={handleBlur} />
                                                    </Col>
                                                    <Col lg='auto'>
                                                        {t('dashboard.params.modal_outliers.or')}
                                                    </Col>  
                                                    <Col lg='auto'>
                                                        {' > '}
                                                    </Col>  
                                                    <Col lg={2}>
                                                        <Form.Control size="sm" type="number" name={`outliers.analytes[${index}].max_value`} maxLength={255} value={values.outliers.analytes[index].max_value ? values.outliers.analytes[index].max_value! : undefined} onChange={handleChange} onBlur={handleBlur} />
                                                    </Col>                                            
                                                </Row>
                                        )})}
                                    </Container>
                                    <Container className='d-flex justify-content-end'>
                                        <Button className="m-1 no-print" type="submit" variant="secondary" disabled={values.outliers.outliers_on === ''}>{t('dashboard.params.modal_outliers.validate')}</Button>
                                    </Container>
                                </Form>
                            );
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default withTranslation()(ModalDashboardParamsOutliersComponent)