import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import ConfigApi, { Fields, FieldsTranslation } from '../api/configApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';

interface CurrentState {
    data: Data<Fields>
    filtered: Array<string>
    pagination: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postError: string | undefined
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    byCompany: {},
    fetchStatus: 'idle',
    fetchError: undefined,
    singleFetchStatus: 'idle',
    singleFetchError: undefined,
    postStatus: 'idle',
    postError: undefined
} as CurrentState

export const fetchFields = createAsyncThunk(
    'fields',
    async () => {
        const response = await ConfigApi.getInstance().getFields()
        return response as Array<Fields>
    }
)


const employeesSlice = createSlice({
    name: 'fields',
    initialState,
    reducers: {
        addField(state: CurrentState, action: PayloadAction<Fields>) {
            const item = action.payload
            const translations: FieldsTranslation[] = []
            Object.keys(item.translations).forEach((key: any) => {
                translations[item.translations[key].language_id] = { name_translation: item.translations[key].name_translation, language_id: item.translations[key].language_id }
            })
            item.translations = translations
            if (item) {
                state.data[item.id] = item
            }
        },
        updateField(state: CurrentState, action: PayloadAction<Fields>) {
            const item = action.payload
            if (item.id)
                state.data[item.id] = item
        },
        desactivateField(state: CurrentState, action: PayloadAction<number>) {
            const id = action.payload
            state.data[id].active = false
        },
        activateField(state: CurrentState, action: PayloadAction<number>) {
            const id = action.payload
            state.data[id].active = true
        },
        setFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload
        },
        setFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy
        },
        setSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchFields.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchFields.fulfilled, (state, action) => {
            action.payload.forEach((item: Fields) => {
                state.data[item.id] = item
            })
            state.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchFields.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
    }
})

export const {
    addField,
    updateField,
    setFiltered,
    setFilterBy,
    setSortBy,
    desactivateField,
    activateField
} = employeesSlice.actions

export default employeesSlice.reducer