import React, { useEffect, ChangeEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../state/rootReducer'

import { ReactComponent as SearchIcon } from 'bootstrap-icons/icons/search.svg'
import { ReactComponent as RefreshIcon } from 'bootstrap-icons/icons/arrow-clockwise.svg'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

import Loader from '../../common/components/Loader'
import ControlPlanList from './components/ControlPlanList'

import { fetchAllCompanies } from '../../state/companiesSlice'
import { fetchPdc, filterByReducer, filterReducer } from '../../state/pdcSlice'

import { useState } from 'react';
import { fetchEmployees } from '../../state/employeesSlice';
import { fetchStatusList } from '../../state/configSlice';
import { fetchLaboratories } from '../../state/configSlice';
import { useHistory } from 'react-router-dom';

type PdcListContainerProps = WithTranslation

const PdcListContainer: React.FC<PdcListContainerProps> = ({ t }) => {

    type InputEvent = ChangeEvent<HTMLInputElement>
    type ChangeHandler = (e: InputEvent) => void

    const dispatch = useDispatch()
    const history = useHistory()
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    const { defaultFilters } = useSelector((state: RootState) => state.pdc)

    // Companies state
    const companiesAllStatus = useSelector((state: RootState) => state.companies.fetchAllStatus)
    const companiesError = useSelector((state: RootState) => state.companies.fetchError)

    // Employees state
    const employeesStatus = useSelector((state: RootState) => state.employees.fetchStatus)
    const employeesError = useSelector((state: RootState) => state.employees.fetchError)

    // Laboratories state
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const laboratoriesError = useSelector((state: RootState) => state.config.laboratories.fetchError)


    // PDC status state
    const statusListStatus = useSelector((state: RootState) => state.config.status.fetchStatus)
    const statusListError = useSelector((state: RootState) => state.config.status.fetchError)

    // pdc state
    const pdc = useSelector((state: RootState) => state.pdc.data)
    const fetchStatus = useSelector((state: RootState) => state.pdc.fetchStatus)
    const fetchError = useSelector((state: RootState) => state.pdc.fetchError)
    const pagination = useSelector((state: RootState) => state.pdc.pagination)

    const [filter, setFilter] = useState<string | undefined>(pagination.filterBy.all ? pagination.filterBy.all.like : '')
    
    const onFilterChanged: ChangeHandler = e => {
        setFilter(e.target.value)
    }

    function triggerFilter() {
        dispatch(filterReducer(filter))
        dispatch(fetchPdc({page: 1}))
    }

    //Catch event 'Enter' key
    function KeyDown(e: { key: string; }) {
        if (e.key === 'Enter') {
            triggerFilter()
        }
    }

    useEffect(() => {
        if (pagination.filterBy) {
            dispatch(fetchPdc({page: 1}))
        }  
    }, [pagination.filterBy, pagination.sortBy])

    useEffect(() => {
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }
        
    }, [laboratoriesStatus])
    
    useEffect(() => {
        dispatch(filterByReducer(defaultFilters.recentDone))
        
    }, [history])

    useEffect(() => {
        if (statusListStatus === 'idle') {
            dispatch(fetchStatusList())
        }
        if (companiesAllStatus === 'idle') {
            dispatch(fetchAllCompanies())
        }
        if (employeesStatus === 'idle') {
            dispatch(fetchEmployees())
        }
        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }
        if (companiesAllStatus === 'succeeded' && employeesStatus === 'succeeded' && statusListStatus === 'succeeded'
            && fetchStatus === 'idle') {
                dispatch(filterByReducer({}))
                dispatch(fetchPdc({page: 1}))
        }
    }, [authUser, pdc, fetchStatus, companiesAllStatus, employeesStatus, statusListStatus])



    return (

        <Container className="justify-content-center">

            <Row className="mt-2 mt-lg-5 align-center">
                <Col xs={12} md={7} lg={8} className="mb-2 me-auto">
                    <span className="bg-primary text-white dot fw-bold">
                        {pagination.count}
                    </span>
                    <span className="ps-2 fw-bold">{t('pdc_list.see_my_pdc')}</span>
                </Col>
                <Col xs={10} md={4} lg={3} className="pe-1">
                    <InputGroup size="sm">
                        <FormControl
                            placeholder={t('pdc_list.search_pdc_placeholder')}
                            aria-label={t('pdc_list.search_pdc')}
                            value={filter}
                            onChange={onFilterChanged}
                            onKeyDown={(e) => KeyDown(e)}
                        />
                        <Button variant="secondary" onClick={() => triggerFilter()}>
                            <SearchIcon width="20px" height="100%" className="align-text-top" />
                        </Button>
                    </InputGroup>
                </Col>
                <Col xs="auto" className="ps-0">
                <Button size="sm" variant="secondary"
                        onClick={()=> { dispatch(fetchPdc({page: pagination.currentPage})) }}
                    >
                        <RefreshIcon width="20px" height="100%" className="align-text-top" />
                    </Button>
                </Col>
            </Row>

            {(companiesAllStatus === 'loading' || employeesStatus === 'loading' || fetchStatus === 'loading' || statusListStatus === 'loading' || laboratoriesStatus === 'loading') &&
                <Loader />
            }
            {fetchStatus === 'succeeded' && companiesAllStatus === 'succeeded' && employeesStatus === 'succeeded' &&  statusListStatus === 'succeeded' &&  laboratoriesStatus === 'succeeded' &&
                <ControlPlanList />
            }
            {(companiesAllStatus === 'failed' || employeesStatus ==='failed' || fetchStatus === 'failed' || statusListStatus === 'failed' || laboratoriesStatus === 'failed') &&
                <Container className="mt-2 bg-white text-danger">
                    {laboratoriesStatus && <p>{t('pdc_list.table.columns.laboratory') + ' - ' + t('forms_errors.unexpected_error') + ' (' + laboratoriesError + ')'}</p>}
                    {companiesError && <p>{t('pdc_list.table.columns.nameCustomer') + ' - ' + t('forms_errors.unexpected_error') + ' (' + companiesError + ')'}</p>}
                    {employeesError && <p>{t('pdc_list.table.columns.last_update_by') + ' - ' + t('forms_errors.unexpected_error') + ' (' + employeesError + ')'}</p>}
                    {statusListError && <p>{t('pdc_list.table.columns.status') + ' - ' + t('forms_errors.unexpected_error') + ' (' + statusListError + ')'}</p>}
                    {fetchError && <p>{t('pdc_list.see_my_pdc') + ' - ' + t('forms_errors.unexpected_error') + ' (' + fetchError + ')'}</p>}
                </Container>
            }
        </Container>
    )
}

export default withTranslation()(PdcListContainer)
