import { ReactComponent as RefreshIcon } from 'bootstrap-icons/icons/arrow-clockwise.svg';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../common/components/Loader';
import { fetchLaboratories } from '../../../state/configSlice';
import { setAuthCurLabId } from '../../../state/authUserSlice';
import { RootState } from '../../../state/rootReducer';
import MatrixExportsImportExportContainer from './components/MatrixExportsImportExportContainer';

import InlineLoader from '../../../common/components/InlineLoader';
import SelectControl from '../../../common/components/SelectControl';
import userHasRequiredRoles from '../../../common/auth/userHasRequiredRoles';
import { AppDispatch } from '../../../state/store';
import { fetchMatrixExports } from '../../../state/matrixExportsSlice';
import MatrixExportsList from './components/MatrixExportsList';
import { fetchCompaniesByFormulation } from '../../../state/companiesSlice';
import { fetchRefAnalytes } from '../../../state/refAnalyteSlice';


const MatrixExportsListContainer: React.FC<WithTranslation> = ({ t }) => {
    const dispatch = useDispatch<AppDispatch>()
    const { user: authUser, authCurLabId } = useSelector((state: RootState) => state.authUser)
    const pagination = useSelector((state: RootState) => state.matrixExports.pagination)

    // api error
    const [apiError, setApiError] = useState<unknown>(undefined)

    //matrix exports state
    const matrixExports = useSelector(
        (state: RootState) => state.matrixExports.data
    );
    const matrixExportsByLabId = useSelector(
        (state: RootState) => state.matrixExports.byLabId
    );
    const filteredMatrixExports = useSelector(
        (state: RootState) => state.matrixExports.filtered
    );
    const matrixExportsError = useSelector(
        (state: RootState) => state.matrixExports.fetchError
    );
    const matrixExportsStatus = useSelector(
        (state: RootState) => state.matrixExports.fetchStatus
    );

    // laboratories state
    const laboratoriesStatus = useSelector((state: RootState) => state.config.laboratories.fetchStatus)
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)

    const [laboratoryId, setCurrentLaboratoryId] = useState(authUser.formulations && authUser.formulations.some(formulation => formulation.laboratory_id === authCurLabId) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']) ? authCurLabId : authUser.formulations[0].laboratory_id)

    const laboratoriesError = useSelector((state: RootState) => state.config.laboratories.fetchError)
     
    // companies

    const companiesStatus = useSelector((state: RootState) => state.companies.fetchStatus)
    const companiesError = useSelector((state: RootState) => state.companies.fetchError)
    const companiesByFormulation = useSelector( (state: RootState) => state.companies.byFormulation )

     // refAnalytes state
  const refAnalytes = useSelector((state: RootState) => state.refAnalytes.data)
  const refAnalytesStatus = useSelector((state: RootState) => state.refAnalytes.fetchStatus)
  const refAnalytesError = useSelector((state: RootState) => state.refAnalytes.fetchError)
    
  function getLaboratoryId (): number{
    if (!authUser.formulations.find(f => f.laboratory_id === laboratoryId && f.lab_type === 'cargill') ){
        const firstFormulationLabId = authUser.formulations.find(f => f.lab_type === 'cargill')?.laboratory_id
        if (firstFormulationLabId){
            return firstFormulationLabId                
        }
    }
    return laboratoryId
}

    useEffect(() => {

        if (companiesStatus === "idle" || companiesStatus === "succeeded" && (!companiesByFormulation || !companiesByFormulation[getLaboratoryId()])) {
           dispatch(fetchCompaniesByFormulation(getLaboratoryId()))
        }

        if (refAnalytesStatus === 'idle') {
            dispatch(fetchRefAnalytes())
          }

        if (laboratoriesStatus === 'idle') {
            dispatch(fetchLaboratories())
        }

        if (matrixExportsStatus === 'idle') {
            dispatch(fetchMatrixExports())
        }

    }, [matrixExportsStatus, laboratoriesStatus, companiesStatus, refAnalytesStatus])

    return (
        <Container className="justify-content-center container-xxl" >

            <Row className="mt-5 me-auto" >
                <Col>
                    <h5 className="d-inline">
                        <Badge pill bg="primary">{Intl.NumberFormat(authUser.locale).format(Object.keys(filteredMatrixExports).length)}</Badge>
                        <span className="ms-2 align-text-bottom fw-bold">{t('matrix.matrix_exports.title')}</span>
                    </h5>

                </Col>

                <Col md="auto">
                    <Row>
                        <Col>
                            <MatrixExportsImportExportContainer laboratoryId={getLaboratoryId()} type='exports' />
                        </Col>

                        <Col>
                            <MatrixExportsImportExportContainer laboratoryId={getLaboratoryId()} type='companies' />
                        </Col>

                    </Row>

                </Col>

                <Col xs={2} md={3} lg={3}>
                    {laboratoriesStatus === 'loading' &&
                        <InlineLoader />}
                            {laboratoriesStatus === 'succeeded' && (() => {
                                const ownedLaboratoriesList = Object.values(laboratories).filter(l => l.lab_type === 'cargill' && ((authUser.formulations && authUser.formulations.some(formulation => formulation.laboratory_id === l.id) && userHasRequiredRoles(['MyProvilab_ROLE_nutrition'])) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])))
                                const ownedLaboratories = Object.fromEntries(ownedLaboratoriesList.map(l => [l.id, l]))
                                const hasMultipleLabs: boolean = ownedLaboratoriesList.length > 1 || (authUser.formulations.length > 0 && userHasRequiredRoles(['MyProvilab_ROLE_nutrition'])) || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])

                                if (hasMultipleLabs) {
                                    return (
                                        <SelectControl name="laboratories" items={ownedLaboratories} currentItemId={laboratoryId} onChange={(e) => {
                                            setCurrentLaboratoryId(Number(e.target.value))
                                            dispatch(setAuthCurLabId(Number(e.target.value)))

                                        }}
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })()}
                    {laboratoriesStatus === 'failed' &&
                        <div>{laboratoriesError}</div>}
                </Col>

                <Col xs lg="1">
                    <Button variant="secondary" onClick={() => dispatch(fetchMatrixExports())}>
                        <RefreshIcon width="20px" height="100%" className="align-text-top" />
                    </Button>
                </Col>
            </Row>
            {(matrixExportsStatus === 'loading' || refAnalytesStatus === 'loading' || companiesStatus === 'loading') &&
                <Loader />
            }
            {matrixExportsStatus === 'succeeded' && refAnalytesStatus === 'succeeded' && companiesStatus === 'succeeded' &&
                <MatrixExportsList currentLaboratoryId={laboratoryId} />
            }
            {matrixExportsStatus === 'failed' &&
                <div className="text-danger">Matrix exports : {matrixExportsError}</div>
            }
               {refAnalytesStatus === 'failed' &&
                <div className="text-danger">Ref Analytes : {refAnalytesError}</div>
            }
               {companiesStatus === 'failed' &&
                <div className="text-danger">Companies : {companiesError}</div>
            }
        </Container>
    )
}

export default withTranslation()(MatrixExportsListContainer)

