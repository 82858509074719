import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import { Link } from "react-router-dom";

import userHasRequiredRoles from './userHasRequiredRoles';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';

interface RoleSecureRouteProps {
    path: string
    exact?: boolean
    requiredRoles: Array<string>
    children: React.ReactNode
} 

const RoleSecureRoute: React.FC<RoleSecureRouteProps> = ({ path, exact = false, requiredRoles, children }) => {
    const { t } = useTranslation();

    return (
        <SecureRoute path={path} exact={exact}>
            {userHasRequiredRoles(requiredRoles) ? (
                children
            ) : (
                <Container className="mt-5 justify-content-center">
                    <h1>{t('access_denied')}</h1>
                    <p>
                        <span>{t('access_denied_msg')}</span> <br/>
                        <span>{t('msg_go_back')}<Link to="/secure/home">{t('nav.home')}</Link></span>
                    </p>
                </Container>
            )}
        </SecureRoute>
    )
}

export default RoleSecureRoute