import React from 'react';
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { withTranslation, WithTranslation } from 'react-i18next';

interface StatusProps extends WithTranslation {
    Step: number

}

const status: React.FC<StatusProps> = ({ t, Step }) => {


    return (
        <Container>
            <Row className="text-center">
                <Col xs={4} className="p-0"  >
                    <Row className="justify-content-md-center" >
                        <Col className="p-0 my-auto"></Col>
                        <Col xs="auto" className="p-0" >
                            <span className={`bg-primary dot fw-bold text-white`}> 1 </span>
                        </Col>
                        <Col className="p-0 my-auto"  >
                            <span className={Step < 2 ? "bg-dark lineStatus" : "bg-primary lineStatus"}></span>
                        </Col>
                    </Row>
                </Col>
                <Col xs={4} className="p-0"  >
                    <Row className="justify-content-md-center" >
                        <Col className="p-0 my-auto">
                            <span className={Step < 2 ? "bg-dark lineStatus" : "bg-primary lineStatus"}></span>
                        </Col>
                        <Col xs="auto" className="p-0" >
                            <span className={Step < 2 ? "bg-dark text-white dot fw-bold" : "bg-primary text-white dot fw-bold"}> 2 </span>
                        </Col>
                        <Col className="p-0 my-auto"  >
                            <span className={Step < 3 ? "bg-dark lineStatus" : "bg-primary lineStatus"}></span>
                        </Col>
                    </Row>
                </Col>
                <Col xs={2} className="p-0"  >
                    <Row className="justify-content-md-center" >

                        <Col className="p-0 my-auto">
                            <span className={Step < 3 ? "bg-dark lineStatus" : "bg-primary lineStatus"}> </span>
                        </Col>
                        <Col xs="auto" className="p-0" >
                            <span className={Step < 3 ? "bg-dark text-white dot fw-bold" : "bg-primary text-white dot fw-bold"}> 3 </span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="pt-2 pb-2 justify-content-md-center" >
                <Col xs={4} className="text-center">
                    <span className={Step >= 1 ? "fw-bold" : "text-black-50" }> {t('analysis.status_analysis_mobile.client')} </span>
                </Col>
                <Col xs={4} className="text-center">
                    <span className={Step >= 2 ? "fw-bold" : "text-black-50" }> {t('analysis.status_analysis_mobile.sample')} </span>
                </Col>
                <Col xs={4} className="text-center">
                    <span className={Step >= 3 ?  "fw-bold" : "text-black-50" }> {t('analysis.status_analysis_mobile.analysis')} </span>
                </Col>

            </Row>
        </Container>
    )
}

export default withTranslation()(status)
