import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Link, useLocation } from 'react-router-dom';
import OktaSignInWidget from './OktaSignInWidget';
import Button from 'react-bootstrap/Button';
import { useOktaAuth } from '@okta/okta-react';
import env from '../utils/env';

import axios from 'axios'
import Loader from '../components/Loader';
import { Tokens } from '@okta/okta-auth-js';

interface LoginContainerProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: any
}

const LoginContainer: React.FC<LoginContainerProps> = ({ config }) => {
    const { oktaAuth, authState } = useOktaAuth();
    const { t } = useTranslation()
    const query = new URLSearchParams(useLocation().search);

    const onSuccess = (tokens: Tokens) => {
        oktaAuth.handleLoginRedirect(tokens);
    };

    const onError = (err: unknown) => {
        console.error('error logging in', err);
    };

    
    useEffect( () => {
        if  (authState && authState.isAuthenticated){
             document.body.classList.remove('bg-login')
        }else{
             document.body.classList.add('bg-login')
        }

     }, [authState])

    useEffect( () => {
        if (query.get('fromLogin')) {
            axios.get(`${env.OKTA_DOMAIN}/api/v1/sessions/me`, {withCredentials: true})
            .then( () => {
                oktaAuth.signInWithRedirect({prompt: 'none', scopes: ['openid', 'profile', 'offline_access']})
            }).catch(err => {
                console.error("Get session error: ", err)
            })
        }
    }, [oktaAuth, query])

    if ((authState && authState.isPending) || query.get('fromLogin')) return <Loader />;

    return (
        authState && authState.isAuthenticated ?
            <Redirect to={{ pathname: '/' }}/>
        :
            <OktaSignInWidget
                config={config}
                onSuccess={onSuccess}
                onError={onError}/>
    )
};
export default LoginContainer;