import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ConfigApi, {AutocalcResultsFilter, AutocalcResultsFilterExcel } from '../api/configApi';
import { buildDefaultExtraReducersWithFrontPagination, CurrentState, getDefaultReducersWithFrontPagination, getInitialState } from './DefaultSlice';

const initialState = getInitialState<AutocalcResultsFilterExcel>()

async function convertAutocalcResultsFilterToExcel(autocalcResultsFilter: AutocalcResultsFilter[]): Promise<AutocalcResultsFilterExcel[]>{
    const autocalcResultsFilterExcel: AutocalcResultsFilterExcel[] = []
    autocalcResultsFilter.forEach(autocalcResult => {
        autocalcResult.autocalc_ids.forEach(idAutocalc => {
            autocalcResultsFilterExcel.push({laboratory_id: autocalcResult.laboratory_id, autocalc_id: idAutocalc})      
        });
    });
    return autocalcResultsFilterExcel
}

// AutocalcResultsFilter
export const fetchAutocalcResultsFilter = createAsyncThunk(
    'config/fetchAutocalcResultsFilter', 
    async () => {
       
        const response =  await ConfigApi.getInstance().getAutocalcResultsFilter() 
        const autocalcResultsFilterExcel: AutocalcResultsFilterExcel[] = await convertAutocalcResultsFilterToExcel(response)
        return autocalcResultsFilterExcel
    }
)

export const fetchExportAutocalcResultsFilter = createAsyncThunk(
    'config/fetchExportAutocalcResultsFilter', 
    async () => {
        const response =  await ConfigApi.getInstance().exportAutocalcResultsFilter()
        return response 
    }
)


const addItemToAutocalcResultsFilter =   (state:CurrentState<AutocalcResultsFilterExcel>,item:AutocalcResultsFilterExcel) => {

    if (item.laboratory_id && item.autocalc_id) 
        state.data[`${item.laboratory_id}#${item.autocalc_id}`] = item
}

const postDeleteAutocalcResultsFilter = (state:CurrentState<AutocalcResultsFilterExcel>,item:AutocalcResultsFilterExcel)=> {
    delete state.data[`${item.laboratory_id}#${item.autocalc_id}`]
                state.filtered.splice(state.filtered.indexOf(`${item.laboratory_id}#${item.autocalc_id}`),1)
}


const autocalcResultsFilterSlice = createSlice({
    name: 'autocalcResultsFilter',
    initialState,
     reducers: getDefaultReducersWithFrontPagination(addItemToAutocalcResultsFilter,postDeleteAutocalcResultsFilter)
    ,
    extraReducers: builder => {
            buildDefaultExtraReducersWithFrontPagination(
              addItemToAutocalcResultsFilter,
                builder,
                fetchAutocalcResultsFilter
            )


          
        }
})

export const {
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    setNbByPage,
    requestPage
} = autocalcResultsFilterSlice.actions

export default autocalcResultsFilterSlice.reducer