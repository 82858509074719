import { useEffect, useState } from 'react';

const gridBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
}

const useViewport = () => {

    const computeSize = (width: number) => {
        const activeGrids = Object.entries(gridBreakpoints).filter((item) => width > item[1]).map((item) => item[0])
        return {
            xs: activeGrids.includes("xs"),
            sm: activeGrids.includes("sm"),
            md: activeGrids.includes("md"),
            lg: activeGrids.includes("lg"),
            xl: activeGrids.includes("xl")
        }
    }

    const [ size, setSize ] = useState(computeSize(window.innerWidth))

    useEffect(() => {
        const handleWindowResize = () => {
            setSize(computeSize(window.innerWidth))
        };
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])

    return { ...size }

}

export default useViewport
