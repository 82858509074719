import { t } from "i18next"
import { Analysis, AnalysisPackage, AnalysisPackageTests } from "../../../api/analysisApi"
import { Analyte, AnalyteKey, AnalyteTestPackage, AnalyteTestsPackages, Package, Pattern, PriceList, PriceListFlat, getAnalyteKey } from "../../../api/configApi"
import { User } from "../../../api/usersApi"
import { CurrentUser } from "../../../state/authUserSlice"
import { Language } from "../../../state/localSlice"
import { Data } from "../../../types/types"
import { CheckStatus, IPackages, ITest } from "../../../common/components/ModalPackagesTests"
import {  Sample, SamplePackage, SamplePackageTest } from "../../../api/controlPlanApi"
import dbt from "../../../common/utils/dbTranslation"
import PricesCalculator from "./PricesCalculator"
import userHasRequiredRoles from '../../../common/auth/userHasRequiredRoles';

// Interface shared with Control plan and analysis packages
export interface BasePackage {
    package_id: number
    comment?: string
    package_tests: {
        code_test: string
        code_analyte: string
        theoretical_value?: number | null
    }[]
}

export interface PatternPackagesTestUtilsDep {
     analytes: Data<Analyte>
     analyteTestPackages: Data<AnalyteTestPackage>
     packages: Data<Package>
     patterns: Data<Pattern>
     language: Language
     priceLists: Data<PriceListFlat>
     authUser: CurrentUser
}
export interface TestListDisplay {
    keys:{
        code_test: string
        code_analyte: string
    }[],
    displayName: string
    theoretical_value?: number | null
    price?: number  | null
    currency?: string
    cost?: number  | null
    weight?: number | null
    period?: number | null
    unit?: string
    numberTestRegroup:number
}
export class PatternPackageTestUtils {
    private deps : PatternPackagesTestUtilsDep
    

    constructor (deps: PatternPackagesTestUtilsDep){
        this.deps = deps

    }

 static getAnalyteNameOrTranslationOrGroup = (analyteKey: AnalyteKey, analytes: Data<Analyte>, language: Language, printGroupInsteadOfName: boolean ) => {
    if (analytes[getAnalyteKey(analyteKey)]) {
        const nameToShow = printGroupInsteadOfName ? (analytes[getAnalyteKey(analyteKey)].analysis_group_name || analytes[getAnalyteKey(analyteKey)].json_name):analytes[getAnalyteKey(analyteKey)].json_name
        return nameToShow.translations && nameToShow.translations[language.locale] ? nameToShow.translations[language.locale] : nameToShow.name
    }
    return ''
}

 static getAnalyteLine = ( packageTest: AnalysisPackageTests | SamplePackageTest | ITest, laboratoryId: number, analytes: Data<Analyte>, language: Language, authUser: CurrentUser, defaultCurrency: string, printGroupInsteadOfName: boolean) => {
    return `${PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup({...packageTest, laboratory_id: laboratoryId}, analytes, language,  printGroupInsteadOfName)} ${packageTest.unit ? `(${packageTest.unit})` :''} ${ (packageTest.price != null ) ? 
         Intl.NumberFormat(authUser.locale, { style: 'currency', currency: packageTest.currency || defaultCurrency }).format(packageTest.price).replace(/\s/g, '')
         :''
        } ${packageTest.weight != null ? '- '+packageTest.weight+t('pcpw.weight_unit') : ''} ${packageTest.period != null ? '- '+packageTest.period+t('pcpw.period_unit') :''}`
}

 static getTestsOrGroups = (packageTests: (AnalysisPackageTests | ITest)[]) => {
    const testsByAnalysisGroupName: {[key: string]: AnalysisPackageTests | ITest } = {}
    packageTests.forEach(
        pt => {
            if (pt.analysis_group_name){

                if(!testsByAnalysisGroupName[pt.analysis_group_name]){
                    testsByAnalysisGroupName[pt.analysis_group_name] = {...pt}
                }


                else {

                testsByAnalysisGroupName[pt.analysis_group_name].weight =   (testsByAnalysisGroupName[pt.analysis_group_name].weight || 0) + (pt.weight || 0) ;
                testsByAnalysisGroupName[pt.analysis_group_name].period =  Math.max((testsByAnalysisGroupName[pt.analysis_group_name].period || 0) ,(pt.period || 0))
                testsByAnalysisGroupName[pt.analysis_group_name].price = (testsByAnalysisGroupName[pt.analysis_group_name].price || 0) + (pt.price || 0);
                    
                }
            }
        }
    )
    return packageTests.filter(p => !p.analysis_group_name).concat(Object.values(testsByAnalysisGroupName))
}



static getTestsListByPackage = (packageTests: (AnalysisPackageTests | SamplePackageTest | ITest)[], laboratoryId: number, analytes: Data<Analyte>, language: Language,  authUser: CurrentUser, defaultCurrency: string) => {
    const labView = authUser.role_levels.find(r => r.laboratory_id === laboratoryId && r.level === 'laboratory')
    const testsByAnalysisGroupName: {[key: string]: TestListDisplay } = {}
    packageTests.forEach(
        pt => {
            const key = pt.analysis_group_name  && pt.analysis_group_name !== '' ? pt.analysis_group_name : `${pt.code_analyte}#${pt.code_test}`
            if(!testsByAnalysisGroupName[key]){
                testsByAnalysisGroupName[key] = {...pt, displayName:"", numberTestRegroup:0, keys:[{code_analyte:pt.code_analyte,code_test:pt.code_test}]}
           
                if (pt.analysis_group_name){
                
                    //cas 3
                    const listTestSameGroup = packageTests.filter(packTest => packTest.analysis_group_name === pt.analysis_group_name)
                    testsByAnalysisGroupName[key].numberTestRegroup = listTestSameGroup.length
                        if(labView){
                            //cas 2/3 labo
                            testsByAnalysisGroupName[key].displayName = listTestSameGroup.map(pt=>(pt.code_test)).join("/") 
                        }else{
                            // cas 2/3 hors labo
                            testsByAnalysisGroupName[key].displayName =  PatternPackageTestUtils.getAnalyteLine(pt, laboratoryId,analytes,language,authUser, defaultCurrency, true)
                        }
                    if(listTestSameGroup.length > 1){
                        testsByAnalysisGroupName[key].keys = [...Object.values(listTestSameGroup).map(pt => {return {code_analyte:pt.code_analyte,code_test:pt.code_test}})]
                        testsByAnalysisGroupName[key].weight =   Object.values(listTestSameGroup).reduce((a, b) => a + (b.weight || 0), 0) ; 
                        testsByAnalysisGroupName[key].period = Math.max(...Object.values(listTestSameGroup).map(o => (o.period || 0))); 
                        testsByAnalysisGroupName[key].price = Object.values(listTestSameGroup).reduce((a, b) => a + (b.price || 0), 0);
                        
                    }
                    //cas 1
                }else{
                    testsByAnalysisGroupName[key].numberTestRegroup = 1
                    if(labView){
                        //cas 1 labo
                        testsByAnalysisGroupName[key].displayName = pt.code_test
                    }else{
                        // cas 1 hors labo
                        testsByAnalysisGroupName[key].displayName =  PatternPackageTestUtils.getAnalyteLine(pt, laboratoryId,analytes,language,authUser, defaultCurrency, false)
                    }
                }
            }
        }
    )
    return Object.values(testsByAnalysisGroupName).sort((a,b) => (a.displayName).localeCompare(b.displayName))
}


isPackageChecked = (package_id: number, packages: (BasePackage)[], laboratoryId: number) => {
    const packagesArray = packages.find((p) => p.package_id === package_id)
    if (packagesArray) {
        const testsIds = packagesArray.package_tests.map((st) =>({
            code_test: st.code_test,
            code_analyte: st.code_analyte
        }))
        
        const everyTests =  Object.values(this.deps.analytes).filter(t => Object.values(this.deps.analyteTestPackages).some (p => p.package_id === package_id && p.laboratory_id === laboratoryId && t.code_test === p.code_test && t.code_analyte === p.code_analyte) && t.active && t.laboratory_id === laboratoryId).every(t => testsIds.some(s => s.code_test === t.code_test && s.code_analyte === t.code_analyte))
        const someTests =  Object.values(this.deps.analytes).filter(t => Object.values(this.deps.analyteTestPackages).some (p => p.package_id === package_id && p.laboratory_id === laboratoryId && t.code_test === p.code_test && t.code_analyte === p.code_analyte) && t.active && t.laboratory_id === laboratoryId).some(t => testsIds.some(s => s.code_test === t.code_test && s.code_analyte === t.code_analyte))
        if (everyTests)
            return 'checked'
        else if (someTests)
            return 'indeterminate'
        else
            return 'unchecked'
    } else {
        return 'unchecked'
    }
}

 isAnalyteChecked = (package_id: number, codeTest: string, codeAnalyte: string, basePackages: BasePackage[]): CheckStatus => {

        const packagesArray = basePackages.find(p => p.package_id === package_id)
        if (packagesArray) {
            const testsIds = packagesArray.package_tests.map(st => st.code_test + '#' + st.code_analyte)
            if (testsIds.includes(codeTest + '#' + codeAnalyte))
                return 'checked'
            else
                return 'unchecked'
        } else {
            return 'unchecked'
        }
}

convertAnalysisPackageToBasePackage = (analysisPackages: AnalysisPackage[]) => {
    return analysisPackages.map(
        s => ({
            package_id: s.package_id,
            package_tests: s.analysis_package_tests.map(
                pt => ({
                    code_test: pt.code_test,
                    code_analyte: pt.code_analyte
                })
            )
        })
    )
}

convertSamplePackageToBasePackage = (samplePackage: SamplePackage[]) => {
    return samplePackage.map(
        s => ({
            package_id: s.package_id,
            package_tests: s.sample_package_tests.map(
                pt => ({
                    code_test: pt.code_test,
                    code_analyte: pt.code_analyte
                })
            )
        })
    )
}


getPackagesInPattern(sampleOrAnalysis: Sample | Analysis, laboratoryId: number): Package[] {
   return Object.values(this.deps.packages).filter((pVal) => pVal.active && pVal.laboratory_id === laboratoryId && pVal.patterns.some(p => p.pattern_id === sampleOrAnalysis.pattern_id))
}

 getPackageData(sampleOrAnalysis: Sample | Analysis, type: 'pdc' | 'analysis', basePackages: BasePackage[], laboratoryId: number, companyId: number): IPackages {
 let analyteFiltered = Object.values(this.deps.analytes).filter((tVal) => tVal.active && tVal.laboratory_id === laboratoryId)

  const packagesInPattern = this.getPackagesInPattern(sampleOrAnalysis,laboratoryId).sort((a, b) => (dbt(this.deps.packages, a.id, this.deps.language.id, "name")).localeCompare(dbt(this.deps.packages, b.id, this.deps.language.id, "name")))

  var testsByPackage: { [packageId: number] : Analyte[]; } = {}; 

   Object.values(this.deps.analyteTestPackages).filter( atp => packagesInPattern.find((pack) => pack.id === atp.package_id)).forEach((pack) => {
   const analyte =  analyteFiltered.find((analyte) => (analyte.code_analyte === pack.code_analyte && analyte.code_test === pack.code_test))
   if(analyte){
        if (!(pack.package_id in testsByPackage)) {
            testsByPackage[pack.package_id] = []
        }
        testsByPackage[pack.package_id].push(analyte)
    }
  })

  Object.values(testsByPackage).forEach((tests)=> {
    tests.sort((a: Analyte, b: Analyte) => (PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup( {...a, laboratory_id: laboratoryId}, this.deps.analytes, this.deps.language, true)).localeCompare(PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup( {...b, laboratory_id: laboratoryId}, this.deps.analytes, this.deps.language, true)))
  })

    return {
        packages:
            packagesInPattern
                .map((pVal) => (
                    {
                        key: String(pVal.id),
                        check: type === 'analysis' ? (((sampleOrAnalysis as Analysis).analysis_packages.find(ap => ap.package_id === pVal.id) ? this.isPackageChecked(pVal.id, basePackages, laboratoryId) : 'unchecked')) : (((sampleOrAnalysis as Sample).sample_packages.find(ap => ap.package_id === pVal.id) ? this.isPackageChecked(pVal.id, basePackages, laboratoryId) : 'unchecked')),
                        price: PricesCalculator.getPrice(this.deps.priceLists, companyId, this.deps.packages[Number(pVal.id)]),
                        currency: PricesCalculator.getCurrency(this.deps.priceLists, companyId),
                        comment: basePackages.filter(item => item.package_id === pVal.id)[0] !== undefined ? basePackages.filter(item => item.package_id === pVal.id)[0].comment : "",
                        cost: type === 'pdc' ? this.deps.packages[pVal.id].cost : undefined,
                        period: this.deps.packages[pVal.id].period,
                        weight: this.deps.packages[pVal.id].weight,
                        tests: testsByPackage[pVal.id] ? testsByPackage[pVal.id]
                            .map((tVal) => { 
                                let  theoreticalValue: number | null = null
                                let packageTests:  AnalysisPackageTests | SamplePackageTest  | undefined = undefined 
                                let useDefaulltValue = false
                                if(type === 'analysis'){
                                    packageTests = (sampleOrAnalysis as Analysis).analysis_packages.find(ap => ap.package_id === pVal.id) && (sampleOrAnalysis as Analysis).analysis_packages.find(ap => ap.package_id === pVal.id)!.analysis_package_tests.find(apt => apt.code_analyte === tVal.code_analyte && apt.code_test === tVal.code_test)
                                }else if(type === 'pdc'){
                                    packageTests = (sampleOrAnalysis as Sample).sample_packages.find(ap => ap.package_id === pVal.id) && (sampleOrAnalysis as Sample).sample_packages.find(ap => ap.package_id === pVal.id)!.sample_package_tests.find(apt => apt.code_analyte === tVal.code_analyte && apt.code_test === tVal.code_test)
                                }
                                if(packageTests){
                                    if(packageTests.theoretical_value != null){
                                        theoreticalValue =   packageTests.theoretical_value
                                    }else{
                                        useDefaulltValue = true
                                    }
                                }else{
                                    useDefaulltValue = true
                                }
                                if(useDefaulltValue){
                                    const testInBasePackage =   basePackages.filter(item => item.package_id === pVal.id)[0] !== undefined && basePackages.filter(item => item.package_id === pVal.id)[0].package_tests.filter(item => item.code_test === tVal.code_test && item.code_analyte === tVal.code_analyte)[0] !== undefined ? basePackages.filter(item => item.package_id === pVal.id)[0].package_tests.filter(item => item.code_test === tVal.code_test && item.code_analyte === tVal.code_analyte)[0] : undefined
                                    theoreticalValue =  testInBasePackage !== undefined && testInBasePackage.theoretical_value != null ? testInBasePackage.theoretical_value : null
                                }
                                return {
                                code_test: String(tVal.code_test),
                                code_analyte: String(tVal.code_analyte),
                                analysis_group_name: tVal.analysis_group_name ? (tVal.analysis_group_name.translations ? tVal.analysis_group_name.translations[this.deps.language.locale] : tVal.analysis_group_name.name): '',
                                currency: PricesCalculator.getCurrency(this.deps.priceLists, companyId),
                                check:  (packageTests ? 'checked' : 'unchecked') || this.isAnalyteChecked(pVal.id, tVal.code_test, tVal.code_analyte, basePackages),
                                theoretical_value:theoreticalValue,
                                price: PricesCalculator.getPrice(this.deps.priceLists, companyId, tVal) ,
                                cost: type === 'pdc' ? this.deps.analytes[tVal.id].cost: undefined,
                                unit: this.deps.analytes[tVal.id].unit,
                                period: this.deps.analytes[tVal.id].period,
                                weight: this.deps.analytes[tVal.id].weight
                            }}) :[]
                    }
                )),
        patternPcpw: {
            // Controle sur le sampleOrAnalysis.pattern_id car les DA nir n'ont pas de pattern
            price: this.deps.patterns[sampleOrAnalysis.pattern_id] ? PricesCalculator.getPrice(this.deps.priceLists, companyId, this.deps.patterns[sampleOrAnalysis.pattern_id]) : null,
            cost: type === 'pdc' && this.deps.patterns[sampleOrAnalysis.pattern_id] ? this.deps.patterns[sampleOrAnalysis.pattern_id].cost : undefined,
            period: this.deps.patterns[sampleOrAnalysis.pattern_id] ? this.deps.patterns[sampleOrAnalysis.pattern_id].period : null,
            weight: this.deps.patterns[sampleOrAnalysis.pattern_id] ? this.deps.patterns[sampleOrAnalysis.pattern_id].weight : null,
            currency: PricesCalculator.getCurrency(this.deps.priceLists, companyId)
        }
    }
}



}

