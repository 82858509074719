import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ConfigApi, { TargetValues, getKeyForTabAssocTargetValues } from '../api/configApi';
import { buildDefaultExtraReducersWithFrontPagination, CurrentState, getDefaultReducersWithFrontPagination, getInitialState } from './DefaultSlice';

const initialState = getInitialState<TargetValues>()


export const fetchTargetValues = createAsyncThunk(
    'config/fetchTargetValues',
    async () => {
        return await ConfigApi.getInstance().getTargetValues()
    }
)

export const fetchExportTargetValues = createAsyncThunk(
    'config/fetchExportTargetValues',
    async (lab_id: number) => {
        const response = await ConfigApi.getInstance().exportTargetValues(lab_id)
        return response
    }
)

export const deleteTargetValues = createAsyncThunk(
    'config/deleteBaseTests',
    async (targetValues:TargetValues,{ dispatch }) => {
        await ConfigApi.getInstance().deleteTargetValues(targetValues)
        dispatch(deleteReducer(targetValues))
        return true
    }
)

const addItemToTargetValues = (state: CurrentState<TargetValues>, item: TargetValues) => {
    if (item.laboratory_id && item.type_code && item.starting_date && item.ref_field_id && item.ref_field_value && item.company_id && item.analyte_id) {
        state.data[getKeyForTabAssocTargetValues(item)] = item
    }
}

const postDeleteTargetValues = (state: CurrentState<TargetValues>, item: TargetValues) => {
    delete state.data[getKeyForTabAssocTargetValues(item)]
    state.filtered.splice(state.filtered.indexOf(getKeyForTabAssocTargetValues(item)), 1)
}

const ruleResultsPatternTestsSlice = createSlice({
    name: 'targetValues',
    initialState,
    reducers: getDefaultReducersWithFrontPagination(addItemToTargetValues, postDeleteTargetValues)
    ,
    extraReducers: builder => {
        buildDefaultExtraReducersWithFrontPagination(
            addItemToTargetValues,
            builder,
            fetchTargetValues
        )
    }
})
export const {
    setFiltered,
    setFilterBy,
    setSortBy,
    requestPage,
    setNbByPage,
    tableReducer,
    deleteReducer


} = ruleResultsPatternTestsSlice.actions

export default ruleResultsPatternTestsSlice.reducer