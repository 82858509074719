import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import MatrixApi, { MatrixTemplate } from '../api/matrixApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';

interface CurrentState {
    data: Data<MatrixTemplate>;
    filtered: Array<string>;
    pagination: Pagination;
    fetchStatus: "idle" | "loading" | "succeeded" | "failed";
    fetchError: string | undefined;
    fetchStatusByPass: "idle" | "loading" | "succeeded" | "failed";
    fetchErrorByPass: string | undefined;
    singleFetchStatus: "idle" | "loading" | "succeeded" | "failed";
    singleFetchError: string | undefined;
    postStatus: "idle" | "loading" | "succeeded" | "failed";
    postError: string | undefined;
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    byCompany: {},
    fetchStatus: "idle",
    fetchError: undefined,
    fetchStatusByPass: "idle",
    fetchErrorByPass: undefined,
    singleFetchStatus: "idle",
    singleFetchError: undefined,
    postStatus: "idle",
    postError: undefined,
} as CurrentState;

export const fetchMatrixTemplates = createAsyncThunk(
    'matrix/fetchMatrixTemplates',
    async (args: { laboratoryId: number, page: number }, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(purgeReducer())
        const globalState: any = thunkApi.getState()
        const pagination: Pagination = globalState.matrixTemplates.pagination

        const filter = {...pagination.filterBy, active: {bool: true}}

        const response = await MatrixApi.getInstance().getMatrixTemplatesByLaboratory(args.laboratoryId, filter, pagination.sortBy, pagination.nbByPage, (args.page - 1) * pagination.nbByPage)
        return { curPage: args.page, ...response } as { count: number, curPage: number, data: Array<MatrixTemplate> }
    }
)

export const fetchMatrixTemplatesByPassLimit = createAsyncThunk(
    'matrix/fetchMatrixTemplatesByPassLimit',
    async (args: { laboratoryId: number, page: number }, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(purgeReducer())
        const globalState: any = thunkApi.getState()
        const pagination: Pagination = globalState.matrixTemplates.pagination

        const filter = {...pagination.filterBy, active: {bool: true}, byPassLimit: {bool: true}}

        const response = await MatrixApi.getInstance().getMatrixTemplatesByLaboratory(args.laboratoryId, filter, pagination.sortBy, pagination.nbByPage, (args.page - 1) * pagination.nbByPage)
        return { curPage: args.page, ...response } as { count: number, curPage: number, data: Array<MatrixTemplate> }
    }
)

export const fetchMatrixTemplateById = createAsyncThunk(
    'matrix/fetchMatrixTemplateById',
    async ({templateId}: { templateId: string}, thunkApi) => {/* *|MARCADOR_CURSOR|* */
        const { dispatch } = thunkApi;
        dispatch(purgeReducer())

        const response = await MatrixApi.getInstance().getMatrixTemplateById(Number(templateId))
        return response as MatrixTemplate
    }
)

export const fetchImportMatrixTemplates = createAsyncThunk(
    'matrix/fetchImportMatrixTemplates',
    async ({ type, laboratoryId, file }: { type: string, laboratoryId: number, file: File }) => {
        return await MatrixApi.getInstance().importMatrixTemplates(type, file, laboratoryId)
    }
)

export const fetchExportMatrixTemplates = createAsyncThunk(
    'matrix/fetchExportMatrixTemplates',
    async ({ type, laboratoryId }: { type: string, laboratoryId: number }) => {
        return await MatrixApi.getInstance().exportMatrixTemplates(type, laboratoryId)
    }
)

export const desactivateMatrixTemplate = createAsyncThunk(
    'matrix/desactivateMatrixTemplate',
    async ({ laboratoryId, id }: { laboratoryId: number, id: number }, { dispatch }) => {
        const response = await MatrixApi.getInstance().desactivateMatrixTemplate(laboratoryId, id)

        if (!response) {
            throw new Error("Unable to delete matrix template")
        }

        dispatch(deleteReducer(id))

        return true
    }
)

export const deleteMatrixTemplateMemo = createAsyncThunk(
    'matrix/desactivateMatrixTemplate',
    async ({ laboratoryId, id }: { laboratoryId: number, id: number }) => {
        return await MatrixApi.getInstance().deleteMatrixTemplateMemo(laboratoryId, id)
    }
)

export const uploadMemo = createAsyncThunk(
    'matrix/uploadMatrixTemplateMemo',
    async ({ laboratoryId, id, file }: { laboratoryId: number, id: number, file: File }) => {
        return await MatrixApi.getInstance().uploadMatrixTemplateMemo(laboratoryId, id, file)
    }
)

export const downloadMemo = createAsyncThunk(
    'matrix/downloadMatrixTemplateMemo', 
    async ({laboratoryId, id, url}: {laboratoryId: number, id: number, url: string}) => {
       return await MatrixApi.getInstance().downloadMatrixTemplateMemo(laboratoryId, id, url)
    }
)


const matrixTemplatesSlice = createSlice({
    name: "matrixTemplates",
    initialState,
    reducers: {

        purgeReducer(state: CurrentState, action: PayloadAction<void>) {
            state.data = {}
            state.filtered = []
        },

        deleteReducer(
            state: CurrentState,
            action: PayloadAction<number>
        ) {
            const item = action.payload;
            if (item) {
                const id = state.pagination.pages[state.pagination.currentPage].ids.filter((id) => Number(id) === action.payload)[0]
                const index = state.pagination.pages[state.pagination.currentPage].ids.indexOf(id)
                state.pagination.pages[state.pagination.currentPage].ids.splice(index, 1)
                state.pagination.count = state.pagination.count-1
                delete state.data[item]
            }
        },

        setMatrixTemplatesFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload;
        },
        setMatrixTemplatesFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy
            state.pagination.pages = {}
            state.pagination.currentPage = 1
            state.pagination.status = 'idle'
        },
        setMatrixTemplatesSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload;
            state.pagination.pages = {}
            state.pagination.currentPage = 1
            state.pagination.status = 'idle'
        },
        refreshMatrixTemplates(state: CurrentState) {
            state.fetchStatus = "idle";
        },
        reinitMatrixTemplates(state: CurrentState) {           
            Object.assign(state, initialState);
        },
        requestPage(state: CurrentState, action: PayloadAction<number>) {
            state.pagination.currentPage = action.payload;
        },
        setNbByPage(state: CurrentState, action: PayloadAction<number>) {
            const nbByPage = action.payload;

            state.pagination.nbByPage = nbByPage;
            const count = state.filtered.length;
            state.pagination.count = count;
            state.pagination.nbPage = Math.ceil(
                count / state.pagination.nbByPage
            );
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchMatrixTemplates.pending, (state) => {
            state.fetchStatus = "loading";
            state.pagination.status = 'loading'

        });
        builder.addCase(fetchMatrixTemplates.fulfilled, (state, action) => {            
            if (typeof action.payload !== "string") {

                action.payload.data.forEach((item: MatrixTemplate) => {
                    if (item.id) state.data[item.id] = item
                });

                const count = action.payload.count

                state.pagination.count = count
                state.pagination.nbPage = Math.ceil(count / state.pagination.nbByPage)

                state.pagination.pages[action.payload.curPage] = {
                    ids: action.payload.data.map(item => String(item.id)),
                    fetching: false
                }

                state.pagination.currentPage = action.payload.curPage
            }
            state.fetchStatus = "succeeded";
            state.pagination.status = 'succeeded'
        });
        builder.addCase(fetchExportMatrixTemplates.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
            state.pagination.status = 'failed';
        });
        builder.addCase(fetchMatrixTemplatesByPassLimit.pending, (state) => {
            state.fetchStatusByPass = "loading";
        });
        builder.addCase(fetchMatrixTemplatesByPassLimit.fulfilled, (state, action) => {            
            if (typeof action.payload !== "string") {
                action.payload.data.forEach((item: MatrixTemplate) => {
                    if (item.id) state.data[item.id] = item
                });
            }
            state.fetchStatus = "idle";
            state.pagination.status = 'idle';
            state.fetchStatusByPass = 'succeeded';
        });
        builder.addCase(fetchMatrixTemplatesByPassLimit.rejected, (state, action) => {
            state.fetchStatusByPass = "failed";
            state.fetchErrorByPass = action.error.message;
        });
        builder.addCase(fetchMatrixTemplateById.pending, (state) => {
            state.fetchStatus = "loading";
        });
        builder.addCase(fetchMatrixTemplateById.fulfilled, (state, action) => {
            const item: MatrixTemplate = action.payload
            if (item.active && item.id) {
                state.data[item.id] = item;
            }
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchMatrixTemplateById.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
    },
});

export const {
    purgeReducer,
    setMatrixTemplatesFilterBy,
    setMatrixTemplatesFiltered,
    setMatrixTemplatesSortBy,
    refreshMatrixTemplates,
    reinitMatrixTemplates,
    deleteReducer,
    requestPage,
    setNbByPage
} = matrixTemplatesSlice.actions

export default matrixTemplatesSlice.reducer