import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import MatrixApi, { MatrixTemplateInput } from '../api/matrixApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';

interface CurrentState {
    data: Data<MatrixTemplateInput>;
    filtered: Array<string>;
    pagination: Pagination;
    byTemplateId: Data<Array<string>>,
    fetchStatus: "idle" | "loading" | "succeeded" | "failed";
    fetchError: string | undefined;
    singleFetchStatus: "idle" | "loading" | "succeeded" | "failed";
    singleFetchError: string | undefined;
    postStatus: "idle" | "loading" | "succeeded" | "failed";
    postError: string | undefined;
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    byCompany: {},
    byTemplateId: {},
    fetchStatus: "idle",
    fetchError: undefined,
    singleFetchStatus: "idle",
    singleFetchError: undefined,
    postStatus: "idle",
    postError: undefined,
} as CurrentState;

export const fetchMatrixTemplateInputs = createAsyncThunk(
    'matrix/fetchMatrixTemplateInputs',
    async (args: { laboratoryId: number, page: number }, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(purgeReducer())

        const globalState: any = thunkApi.getState()

        const pagination: Pagination = globalState.matrixTemplateInputs.pagination  

        const filter = {...pagination.filterBy, active: {bool: true}}

        const response = await MatrixApi.getInstance().getMatrixTemplateInputsByLaboratory(args.laboratoryId, filter, pagination.sortBy, pagination.nbByPage, (args.page - 1) * pagination.nbByPage)
        return { curPage: args.page, ...response } as { count: number, curPage: number, data: Array<MatrixTemplateInput> }    
})

export const fetchMatrixTemplateInputsByTemplateId = createAsyncThunk(
    'matrix/fetchMatrixTemplateInputsByTemplateId',
    async (args: { laboratoryId: number, templateId: string}, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(purgeReducer())
        const filter = {
            matrix_id: {
                in: [Number(args.templateId)],
            },
            active: {bool: true}
        }
        const response = await MatrixApi.getInstance().getMatrixTemplateInputsByLaboratory(args.laboratoryId, filter)
        return response.data 
    }
)

export const fetchImportMatrixTemplateInputs = createAsyncThunk(
    'matrix/fetchImportMatrixTemplateInputs',
    async ({ type, laboratoryId, file }: { type: string, laboratoryId: number, file: File }) => {
        return await MatrixApi.getInstance().importMatrixTemplateInputs(type, file, laboratoryId)
    }
)

export const fetchExportMatrixTemplateInputs = createAsyncThunk(
    'matrix/fetchExportMatrixTemplateInputs',
    async ({ type, laboratoryId }: { type: string, laboratoryId: number }) => {
        return await MatrixApi.getInstance().exportMatrixTemplateInputs(type, laboratoryId)
    }
)

export const desactivateMatrixTemplateInput = createAsyncThunk(
    'matrix/desactivateMatrixTemplateInput',
    async ({ laboratoryId, id, analyteId }: { laboratoryId: number, id: number, analyteId: string }, { dispatch }) => {
        const response = await MatrixApi.getInstance().desactivateMatrixTemplateInput(laboratoryId, id, analyteId)

        if (!response) {
            throw new Error("Unable to delete matrix template")
        }

        dispatch(deleteReducer(id + '#' + analyteId))
        return true
    }
)

const matrixTemplateInputsSlice = createSlice({
    name: "matrixTemplateInputsSlice",
    initialState,
    reducers: {
        purgeReducer(state: CurrentState, action: PayloadAction<void>) {
            state.data = {}
            state.filtered = []
        },
        deleteReducer(
            state: CurrentState,
            action: PayloadAction<string>
        ) {
            const item = action.payload;
            if (item) {
                const id = state.pagination.pages[state.pagination.currentPage].ids.filter((id) => id === action.payload)[0]
                const index = state.pagination.pages[state.pagination.currentPage].ids.indexOf(id)
                state.pagination.pages[state.pagination.currentPage].ids.splice(index, 1)
                state.pagination.count = state.pagination.count-1
                delete state.data[item]
            }
        },
        setMatrixTemplateInputsFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload;
        },
        setMatrixTemplateInputsFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload }
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy
            state.pagination.pages = {}
            state.pagination.currentPage = 1
            state.pagination.status = 'idle'
        },
        setMatrixTemplateInputsSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload;
            state.pagination.pages = {}
            state.pagination.currentPage = 1
            state.pagination.status = 'idle'
        },
        refreshMatrixTemplateInputs(state: CurrentState) {
            state.fetchStatus = "idle";
        },
        requestPage(state: CurrentState, action: PayloadAction<number>) {
            state.pagination.currentPage = action.payload;
        },
        setNbByPage(state: CurrentState, action: PayloadAction<number>) {
            const nbByPage = action.payload;

            state.pagination.nbByPage = nbByPage;
            const count = state.filtered.length;
            state.pagination.count = count;
            state.pagination.nbPage = Math.ceil(
                count / state.pagination.nbByPage
            );
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchMatrixTemplateInputs.pending, (state) => {
            state.fetchStatus = "loading";
        });
        builder.addCase(fetchMatrixTemplateInputs.fulfilled, (state, action) => {
            if (typeof action.payload !== "string") {
                state.byTemplateId = {}
                action.payload.data.forEach((item: MatrixTemplateInput) => {
                    let key = ""
                    if (item.matrix_id && item.analyte_id) {
                        if (!state.byTemplateId[item.matrix_id]) {
                            state.byTemplateId[item.matrix_id] = []
                        }
                        key = item.matrix_id + '#' + item.analyte_id
                        state.data[key] = item
                        state.data[key].id = key
                        if (state.byTemplateId[item.matrix_id].indexOf(key) === -1) {
                             state.byTemplateId[item.matrix_id].push(key);
                        }
                    }
                });

                const count = action.payload.count

                state.pagination.count = count
                state.pagination.nbPage = Math.ceil(count / state.pagination.nbByPage)
                state.pagination.pages[action.payload.curPage] = {
                    ids: action.payload.data.map(item => String(item.matrix_id)+'#'+item.analyte_id),
                    fetching: false
                }

                state.pagination.currentPage = action.payload.curPage
            }
            state.fetchStatus = "succeeded";
            state.pagination.status = 'succeeded'
        });
        builder.addCase(fetchMatrixTemplateInputs.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
        builder.addCase(fetchMatrixTemplateInputsByTemplateId.pending, (state) => {
            state.fetchStatus = "loading";
        });
        builder.addCase(fetchMatrixTemplateInputsByTemplateId.fulfilled, (state, action) => {
            action.payload.forEach((item: MatrixTemplateInput) => {
                let key = ""
                if(item.matrix_id && item.analyte_id) {
                    if (!state.byTemplateId[item.matrix_id]) {
                        state.byTemplateId[item.matrix_id] = []
                    }
                    key = item.matrix_id + '#' + item.analyte_id
                    state.data[key] = item
                    state.data[key].id = key
                    if (state.byTemplateId[item.matrix_id].indexOf(key) === -1) {
                        state.byTemplateId[item.matrix_id].push(key);
                   }
                }
            });
            
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchMatrixTemplateInputsByTemplateId.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
    },
});

export const {
    purgeReducer,
    setMatrixTemplateInputsFilterBy,
    setMatrixTemplateInputsFiltered,
    setMatrixTemplateInputsSortBy,
    refreshMatrixTemplateInputs,
    deleteReducer,
    requestPage,
    setNbByPage
} = matrixTemplateInputsSlice.actions

export default matrixTemplateInputsSlice.reducer