import { WithTranslation, withTranslation } from "react-i18next";
import { Modal, Table } from "react-bootstrap";

import { ReactComponent as SquareIcon } from 'bootstrap-icons/icons/square-fill.svg'
import { ResultsDetails } from "../../api/matrixApi";
import React from 'react'
import ButtonHeaderList from "../../common/components/ButtonHeaderList";
import SubErrorDetails from "../../common/utils/exceptions/SubErrorDetails";
import { buildImportPrefix } from "../../common/utils/utils";


interface ModalMatrixDetailsImportProps extends WithTranslation {
    resultsDetails: ResultsDetails[]
    showModalDetailsImport: boolean
    setShowModalDetailsImport: (show: boolean) => void

}


const ModalMatrixDetailsImport: React.FC<ModalMatrixDetailsImportProps> = ({ t, resultsDetails: resultDetails, showModalDetailsImport: showModalValo, setShowModalDetailsImport: setShowModalValo }: ModalMatrixDetailsImportProps) => {



    return (

<Modal size="lg" show={showModalValo}  onHide={() => setShowModalValo(false)}>
<Modal.Header>
    <Modal.Title><span className="text-primary fw-bold"> {t('matrix.matrix.modal_details_import.title')} </span></Modal.Title>
</Modal.Header>
<Modal.Body>
<Table
striped
hover
responsive="lg"
className="mt-2 bg-white text-center"
size="md"
>
<thead>
    <tr>
        <th className="p-1" />
        <th><ButtonHeaderList name= "export_code" title={t("matrix.matrix.modal_details_import.export_code")} type={["label"]} /></th>
        <th><ButtonHeaderList name="result" title={t("matrix.matrix.modal_details_import.result")} type={["label"]} /></th>
        <th><ButtonHeaderList name="comment" title={t("matrix.matrix.modal_details_import.comment")} type={["label"]} /></th>

                            </tr>
</thead>
<tbody>
    {
        resultDetails
            .map((res) => {
                return (
                    <tr key={res.export_code}>
                        <td className="p-1 table-hover-hint" />
                        <td>{res.export_code}</td>
                        <td><SquareIcon className = 'd-block mx-auto' style={{color: (res?.import_status === 'OK' ? 'green': 'red')}} /></td>
                    
                        <td> {'errors' in res.errors && 
                                res.errors.errors.map((e, index) => {
                                const detailMsg = t(`errors.import.${e.errorCode}`)

                                if(e.errorCode === "MISSING_REQUIRED_FIELD"){
                                    const prefix = t(`matrix.matrix.modal_details_import.comments.MissingRequieredField`)

                                    return <div key={index}>{`${prefix}${detailMsg} : ${e.path && e.path.length > 0 ? e.path.split(':')[1] : ""}`}</div>

                                } else {
                                const prefix = buildImportPrefix(e)
                                return <div key={index}>{`${prefix}${detailMsg} (${e.message})`}</div>
                                }
                            })

                        }

                            {!('errors' in res.errors) &&
                                t(`matrix.matrix.modal_details_import.comments.${res.comment}`, res.params)
                            }
                        </td>
                      
                      

                    </tr>
                );
            })
    }

</tbody>
</Table>                </Modal.Body>
</Modal>

)

}

export default withTranslation()(ModalMatrixDetailsImport)