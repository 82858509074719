import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
    buildDefaultExtraReducersWithFrontPagination,
    CurrentState,
    getDefaultReducersWithFrontPagination,
    getInitialState,
} from "./DefaultSlice";

import ExternalServicesApi, { NirBinary } from "../api/externalServicesApi";

const initialState = getInitialState<NirBinary>();

export const fetchNirBinaries = createAsyncThunk(
    "nir/fetchNirBinaries",
    async () => {
        const response = await ExternalServicesApi.getInstance().getNirBinaries();
        return response;
    }
);

export const deleteNirBinaries = createAsyncThunk(
    "nir/deleteNir",
    async (build: string) => {
        await ExternalServicesApi.getInstance().deleteNirBinary(build);
        return;
    }
);

const putNirBinary = (state: CurrentState<NirBinary>, item: NirBinary) => {
    if (item.build) {
        state.data[item.build] = item;
    }
};

const removeNirBinary = (state: CurrentState<NirBinary>, build: string) => {
    if (state.data[build]) {
        delete state.data[build];
    }
    const fitleredIndex = state.filtered.indexOf(build)
    if (fitleredIndex > -1) {
        state.filtered.splice(fitleredIndex, 1);
    }
};

const nirSlice = createSlice({
    name: "nirBinaries",
    initialState,
    reducers: {...getDefaultReducersWithFrontPagination(
        putNirBinary,
        removeNirBinary  
    )},
    extraReducers: (builder) => {
        buildDefaultExtraReducersWithFrontPagination(
            putNirBinary,
            builder,
            fetchNirBinaries
        );

        // deleteNir reducers
        builder.addCase(deleteNirBinaries.pending, (state) => {
            state.deleteStatus = "loading";
        });
        builder.addCase(deleteNirBinaries.fulfilled, (state, action) => {
            const item = action.meta.arg
            removeNirBinary(state, item)
            state.deleteStatus = "succeeded";
        });
        builder.addCase(deleteNirBinaries.rejected, (state, action) => {
            state.deleteStatus = "failed";
            state.deleteError = action.error.message;
        });
    },
});

export const {
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage,
} = nirSlice.actions;

export default nirSlice.reducer;
