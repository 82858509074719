import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
    buildDefaultExtraReducersWithFrontPagination,
    CurrentState,
    getDefaultReducersWithFrontPagination,
    getInitialState,
} from "./DefaultSlice";

import ExternalServicesApi, { getNirId, Nir } from "../api/externalServicesApi";
import ApiError from "../common/utils/exceptions/ApiError";

const initialState = getInitialState<Nir>();

export const fetchNirs = createAsyncThunk(
    "nir/fetchNirs",
    async (laboratory_id?: number) => {
        const response = await ExternalServicesApi.getInstance().getNirs(laboratory_id);
        return response;
    }
);

export const fetchNir = createAsyncThunk(
    "nir/fetchNir",
    async ({ laboratory_id, nir_code }: { laboratory_id: number, nir_code: string }) => {
        const response = await ExternalServicesApi.getInstance().getNir(laboratory_id, nir_code);
        return response;
    }
);

export const upsertNir = createAsyncThunk(
    "nir/createNir",
    async ({ laboratory_id, nir_code, nir }: { laboratory_id: number, nir_code: string, nir: Nir }, {rejectWithValue}) => {
        try {
            const {id, healthy, alert, ...payload} = nir
            if (!nir.id) {
                const response = await ExternalServicesApi.getInstance().postNir(laboratory_id, payload);
                return response;
            } else {
                const response = await ExternalServicesApi.getInstance().putNir(laboratory_id, nir_code, payload);
                return response;
            }
        } catch (err) {
            if (err instanceof ApiError) {
                return rejectWithValue({...err, message: err.message})
            }
            throw err
        }
    }
);

export const deleteNir = createAsyncThunk(
    "nir/deleteNir",
    async ({ laboratory_id, nir_code }: { laboratory_id: number, nir_code: string }) => {
        await ExternalServicesApi.getInstance().deleteNir(laboratory_id, nir_code);
        return;
    }
);

const putNir = (state: CurrentState<Nir>, item: Nir) => {
    if (item.laboratory_id && item.nir_code) {
        state.data[getNirId(item)] = item;
    }
};

const removeNir = (state: CurrentState<Nir>, itemKey: { laboratory_id: number, nir_code: string }) => {
    if (state.data[getNirId(itemKey)]) {
        delete state.data[getNirId(itemKey)];
    }
    const fitleredIndex = state.filtered.indexOf(getNirId(itemKey))
    if (fitleredIndex > -1) {
        state.filtered.splice(fitleredIndex, 1);
    }
};

const nirSlice = createSlice({
    name: "nir",
    initialState,
    reducers: {...getDefaultReducersWithFrontPagination(
        putNir,
        removeNir  
    )},
    extraReducers: (builder) => {
        buildDefaultExtraReducersWithFrontPagination(
            putNir,
            builder,
            fetchNirs
        );
        // fetchNir resducers
        builder.addCase(fetchNir.pending, (state) => {
            state.singleFetchStatus = "loading";
        });
        builder.addCase(fetchNir.fulfilled, (state, action) => {
            const item = action.payload
            putNir(state, item)
            state.singleFetchStatus = "succeeded";
        });
        builder.addCase(fetchNir.rejected, (state, action) => {
            state.singleFetchStatus = "failed";
            state.singleFetchError = action.error.message;
        });
        // createNir reducers
        builder.addCase(upsertNir.fulfilled, (state, action) => {
            const item = action.payload
            if (item) {
                putNir(state, item)
                state.postStatus = "succeeded";
            }
        });
        builder.addCase(upsertNir.rejected, (state, action) => {
            state.postStatus = "failed";
            state.postError = action.error.message;
        });
        builder.addCase(upsertNir.pending, (state) => {
            state.postStatus = "loading";
        });
        // deleteNir reducers
        builder.addCase(deleteNir.pending, (state) => {
            state.deleteStatus = "loading";
        });
        builder.addCase(deleteNir.fulfilled, (state, action) => {
            const item = action.meta.arg
            removeNir(state, item)
            state.deleteStatus = "succeeded";
        });
        builder.addCase(deleteNir.rejected, (state, action) => {
            state.deleteStatus = "failed";
            state.deleteError = action.error.message;
        });
    },
});

export const {
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage,
} = nirSlice.actions;

export default nirSlice.reducer;
