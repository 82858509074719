import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import AnalysisApi from '../../../../../api/analysisApi';

import { analysisReducer, StatusAnalysis } from '../../../../../state/analysisSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../state/rootReducer';

import InlineLoader from '../../../../../common/components/InlineLoader';
import dbt from '../../../../../common/utils/dbTranslation';

interface AnalysisImproperFormProps extends WithTranslation {
        analysis: any
        close: any
        setAnalysis: any
    }

const AnalysisImproperForm: React.FC<AnalysisImproperFormProps> = ({t, analysis, close, setAnalysis}) => {

    // Impropers state
    const impropers = useSelector( (state: RootState) => state.config.impropers.data )
    const impropersStatus = useSelector( (state: RootState) => state.config.impropers.fetchStatus )
    const impropersError = useSelector( (state: RootState) => state.config.impropers.fetchError )
    // languages state
    const { language } = useSelector( (state: RootState) => state.local)
    
    const [apiStatusError, setApiStatusError] = useState(undefined)
    const [statusError, setStatusError] = useState(undefined)

    const [improperChecked, setImproperChecked] = useState(-1)

    const [CommentImproper, setCommentImproper] = useState("")

    const dispatch = useDispatch()

    const updateStatus = async (status:StatusAnalysis)=> {

        if(improperChecked===-1){
            setStatusError(t('forms_errors.analysis.improper_cause_required'))
        }else{
            await AnalysisApi.getInstance().updateStatusAnalysis(status, analysis.laboratory_id, analysis.id, improperChecked, CommentImproper)
            .then((analysisRep: any) => { 
                if (Object.prototype.hasOwnProperty.call(analysisRep, "error")){
                    setApiStatusError(t('forms_errors.unexpected_error'))
                }else{
                //update local state 
                const analysisData:any = {...analysis}
                analysisData.last_update = analysisRep.last_update     
                analysisData.status = analysisRep.status
                analysisData.cancellation_date = analysisRep.cancellation_date !== undefined ? analysisRep.cancellation_date : null
                analysisData.conformity_date = analysisRep.conformity_date !== undefined ? analysisRep.conformity_date : null
                analysisData.receipt_date =  analysisRep.receipt_date !== undefined ? analysisRep.receipt_date : null
                analysisData.validation_date = analysisRep.validation_date !== undefined ? analysisRep.validation_date : null
                analysisData.improper_id = analysisRep.improper_id
                analysisData.comment_improper = analysisRep.comment_improper
                setAnalysis(analysisData)
    
                //dispatch analysis in root state analsyis
                const analysisdispatch:any = {...analysis}
                analysisdispatch.last_update = analysisRep.last_update     
                analysisdispatch.status = analysisRep.status
                analysisdispatch.cancellation_date = analysisRep.cancellation_date !== undefined ? analysisRep.cancellation_date : null
                analysisdispatch.conformity_date = analysisRep.conformity_date !== undefined ? analysisRep.conformity_date : null
                analysisdispatch.receipt_date =  analysisRep.receipt_date !== undefined ? analysisRep.receipt_date : null
                analysisdispatch.validation_date = analysisRep.validation_date !== undefined ? analysisRep.validation_date : null
                analysisdispatch.improper_id = analysisRep.improper_id
                analysisdispatch.comment_improper = analysisRep.comment_improper
                
                dispatch(analysisReducer(analysisdispatch))   
                setApiStatusError(undefined)
                close()
                }           
            })
            .catch(() => {
                setApiStatusError(t('forms_errors.unexpected_error'))
            })
        }
        }

    return (
        <Container>
            <Row className="p-2">
                { (apiStatusError !== undefined) &&   
                                    <Container className="mt-2 bg-white text-danger">
                                        <p>{t('forms.analysis.updateStatus') + ' - ' + t('forms_errors.unexpected_error') + ' (' + apiStatusError + ')'}</p>
                                    </Container>
                                }
                { (statusError !== undefined) &&   
                                    <Container className="mt-2 bg-white text-danger">
                                        <p>{t('forms.analysis.updateStatus') + ' - ' +  statusError}</p>
                                    </Container>
                                }
                    {impropersStatus === 'loading' && 
                        <InlineLoader /> }
                    {impropersStatus === 'succeeded' &&     
                        <Form className = "btn-block">          
                            {Object.keys(impropers).map((id:any) => {
                                if(impropers[id].active === true && impropers[id].type_id === analysis.type_id){
                                    return (
                                        <Row>
                                        <Col key={id} className="m-3"> 
                                            <Form.Check
                                                type={`radio`}
                                                label={dbt(impropers, id, language.id, "improper")}
                                                id={id}
                                                value={id}
                                                onChange={() => {setImproperChecked(id)}}
                                                name={`improper`}
                                            />
                                        </Col>   
                                        
                                        <Col key={id} className="m-3"> 
                                            <div>{impropers[id].editable ? t('analysis.receive_improper_editable') : t('analysis.receive_improper_not_editable')}</div>
                                        </Col> 
                                        </Row>
                                    )
                                }else{
                                    return null
                                }
                            })} 
                            <Row className="m-3">
                             <Col > 
                             <Form.Control  
                                type="text" 
                                as="textarea" 
                                className="btn-block"
                                rows={2}
                                maxLength={1000} 
                                onChange={event => {
                                    setCommentImproper(event.target.value)
                                   
                                }}/>
                             </Col>
                             </Row>
                        </Form>  
                    }                        
                    {impropersStatus === 'failed' && 
                        <div>{impropersError}</div> }
            </Row>
            <Row className="p-2">
                <Col className="text-end">
                    <Button variant="secondary" onClick={() => {updateStatus(StatusAnalysis.received_improper)}}>{t('forms.analysis.impropers.validate') }</Button>
                </Col>
            </Row>
        </Container>
    )
}

export  default withTranslation()(AnalysisImproperForm)