import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Row, Container, Accordion, Col } from 'react-bootstrap';
import { ScrollSyncNode } from 'scroll-sync-react'
import ContextAwareToggle from '../../../common/components/ContextAwareToggle';
import DashboardGroupTableComponent, { others } from './DashboardGroupTableComponent';
import { DashboardSamplesData } from '../../../state/dashboardSlice';

interface DashboardGroupByComponentContainerProps extends WithTranslation {
    dashboardName: string
}

const DashboardGroupByComponent: React.FC<DashboardGroupByComponentContainerProps> = ({ t, dashboardName }) => {
    // samples state
    const groupBy = useSelector((state: RootState) => state.dashboard.groupBy)
    const datas = useSelector((state: RootState) => state.dashboard.samplesForData)
    
    // local states
    const [displayOthers, setDisplayOthers] = useState(false)

    useEffect(() => {
        let totalNumberOfGroups = 0
        groupBy.values.forEach(v => {
            totalNumberOfGroups += Object.values(datas).filter(d => d[groupBy.field as keyof DashboardSamplesData]?.toString() === v.value).length
        })
        if(groupBy.values && groupBy.values.length > 0 && (groupBy.values.filter(v => (!v.color || v.color === '') && v.value !== others).length > 0 || totalNumberOfGroups !== Object.values(datas).length)){
            setDisplayOthers(true)
        }else{
            setDisplayOthers(false)
        }
    }, [groupBy])

    return (
        <Container className={'mt-2 p-0 ms-0 me-0'}>
            <Container className="p-2 bg-white rounded-3 mb-0">
                <Accordion defaultActiveKey={"group_by"}>
                    <Row>
                        <Col lg>
                            <h4 className="text-primary fw-bold">
                            {t('dashboard.group_by.title')}
                            </h4>
                        </Col>
                        <Col lg="auto">
                            <ContextAwareToggle eventKey={"group_by"} />
                        </Col>
                    </Row>
                    <Accordion.Collapse eventKey={"group_by"}>
                        <>
                            <Row><Col lg className="lineTitle"></Col></Row>
                            <Row>
                                <ScrollSyncNode group="horiz-scroll">
                                    <Container className='ms-2 me-2 p-0 noshrink' style={{overflow: "auto"}}>
                                        {groupBy.values && groupBy.values.length > 0 && groupBy.values.filter((v, index) => (v.color && v.color !== '' && v.value !== others) && (groupBy.values.filter((vc, indexVc) => vc.color === v.color && vc.value !== v.value && indexVc < index).length < 1)).map(v => {
                                            return (
                                                <DashboardGroupTableComponent dashboardName={dashboardName} value={v.value} color={v.color} />
                                            )
                                        })}
                                        {displayOthers &&
                                            <DashboardGroupTableComponent dashboardName={dashboardName} value='otherValuesNotInGroupsOrWithoutColor' color='' />
                                        }
                                    </Container>
                                </ScrollSyncNode>
                            </Row>
                        </>
                    </Accordion.Collapse>
                </Accordion>
            </Container>
        </Container>
    )
}

export default withTranslation()(DashboardGroupByComponent)