import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import ConfigApi, {
    getKeyForTabAssocTestCategory,
    TestCategory,
    TestCategoryKey
} from "../api/configApi";
import {
    buildDefaultExtraReducersWithFrontPagination,
    CurrentState,
    getDefaultReducersWithFrontPagination,
    getInitialState,
} from "./DefaultSlice";

const initialState = getInitialState<TestCategory>();

// test_categories

export const fetchTestCategories = createAsyncThunk(
    "config/fetchTestCategories",
    async ({ laboratoryId }: { laboratoryId: number }) => {
        const response = await ConfigApi.getInstance().getTestCategories(
            laboratoryId
        );
        return response;
    }
);

export const fetchSingleTestCategory = createAsyncThunk(
    "analysis/fetchSingleTestCategory",
    async (testCategoryKey: TestCategoryKey) => {
        const response = await ConfigApi.getInstance().getTestCategory(
            testCategoryKey.laboratory_id,
            testCategoryKey.results_pattern_name,
            testCategoryKey.code
        );
        return response;
    }
);

export const deleteTestCategory = createAsyncThunk(
    "config/deleteTestCategory",
    async (testCategory: TestCategoryKey, { dispatch }) => {
        const response = await ConfigApi.getInstance().deleteTestCategory(
            testCategory.laboratory_id,
            testCategory.results_pattern_name,
            testCategory.code
        );

        if (!response) {
            throw new Error("Unable to delete test category");
        }

        dispatch(deleteReducer(testCategory));

        return true;
    }
);

const addItemToTestCategory = (
    state: CurrentState<TestCategory>,
    item: TestCategory
) => {
    if (item.laboratory_id && item.results_pattern_name && item.code)
        state.data[getKeyForTabAssocTestCategory(item)] = item;
};

const postDeleteTestCategory = (
    state: CurrentState<TestCategory>,
    item: TestCategoryKey
) => {
    delete state.data[getKeyForTabAssocTestCategory(item)];
    state.filtered.splice(
        state.filtered.indexOf(getKeyForTabAssocTestCategory(item)),
        1
    );
};


const testCategorySlice = createSlice({
    name: "test categories",
    initialState,
    reducers: {...getDefaultReducersWithFrontPagination(
        addItemToTestCategory,
        postDeleteTestCategory  
    ), updateTestCategories : (state: CurrentState<TestCategory>, action: PayloadAction<TestCategory[]>) => {
        state.data = {};
        action.payload.forEach((item: TestCategory) => {
            addItemToTestCategory(state, item);
        });
    }},
    extraReducers: (builder) => {
        buildDefaultExtraReducersWithFrontPagination(
            addItemToTestCategory,
            builder,
            fetchTestCategories
        );
    },
});

export const {
    updateTestCategories,
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage,
} = testCategorySlice.actions;

export default testCategorySlice.reducer;
