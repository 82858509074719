import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import AnalysisApi, { ExternalCallState, System } from "../api/analysisApi";
import { Data, FilterBy, Pagination, SortBy } from "../types/types";

interface CurrentState {
    data: Data<ExternalCallState>;
    filtered: Array<string>;
    pagination: Pagination;
    fetchStatus: "idle" | "loading" | "succeeded" | "failed";
    fetchError: string | undefined;
    singleFetchStatus: "idle" | "loading" | "succeeded" | "failed";
    singleFetchError: string | undefined;
    postStatus: "idle" | "loading" | "succeeded" | "failed";
    postError: string | undefined;
}

const initialState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    byCompany: {},
    fetchStatus: "idle",
    fetchError: undefined,
    singleFetchStatus: "idle",
    singleFetchError: undefined,
    postStatus: "idle",
    postError: undefined,
} as CurrentState;

export const fetchExternalCallState = createAsyncThunk(
    "fetchExternalCallState",
    async ({laboratoryId, analysisId, system}: {laboratoryId: number, analysisId: number, system: System}) => { 
        const response = await AnalysisApi.getInstance().getExternalCallState(laboratoryId, analysisId, system);
        return response as ExternalCallState;
    }
); 

const externalCallStateSlice = createSlice({
    name: "externalCallState",
    initialState,
    reducers: {
        upsertExternalCallState(
            state: CurrentState,
            action: PayloadAction<ExternalCallState>
        ) {
            const item = action.payload;
            if (item.laboratory_id && item.analysis_id) state.data[item.laboratory_id+"#"+item.analysis_id] = item;
        },

        setExternalCallStateFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload;

        },
        setExternalCallStateFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload };
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all;
            }
            state.pagination.filterBy = filterBy;
        },
        setExternalCallStateSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload;
        },
        refreshExternalCallState(state: CurrentState) {
            state.fetchStatus = "idle";
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchExternalCallState.pending, (state) => {
            state.fetchStatus = "loading";
        });
        builder.addCase(fetchExternalCallState.fulfilled, (state, action) => {
            const item = action.payload;

            if (item.laboratory_id && item.analysis_id) state.data[item.laboratory_id+"#"+item.analysis_id] = item
            state.fetchStatus = "succeeded";
        });
        builder.addCase(fetchExternalCallState.rejected, (state, action) => {
            state.fetchStatus = "failed";
            state.fetchError = action.error.message;
        });
    },
});

export const {
    upsertExternalCallState,
    setExternalCallStateFiltered,
    setExternalCallStateFilterBy,
    setExternalCallStateSortBy,
    refreshExternalCallState,
} = externalCallStateSlice.actions;

export default externalCallStateSlice.reducer;