import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import ConfigApi, {
    PackagePattern,
    PackagesPatterns,
     getKeyForTabAssocPackagePattern,
} from "../api/configApi";
import {
    buildDefaultExtraReducersWithFrontPagination,
    CurrentState,
    getDefaultReducersWithFrontPagination,
    getInitialState,
} from "./DefaultSlice";

const initialState = getInitialState<PackagePattern>();

// packages_patterns

export const fetchPackagesPatterns = createAsyncThunk(
    "config/fetchPackagesPatterns",
    async ({ laboratoryId }: { laboratoryId: number }) => {
        const response = await ConfigApi.getInstance().getRelationships(
            laboratoryId,
            'packages_patterns'
        );
        return (response as PackagesPatterns).packages_patterns
    }
);


export const fetchDeletePackagePattern = createAsyncThunk(
    "config/fetchDeletePackagePattern",
    async (packagePattern: PackagePattern, { dispatch }) => {
        const response = await ConfigApi.getInstance().deleteRelationship(packagePattern.laboratory_id, packagePattern );

        if (!response) {
            throw new Error("Unable to delete package pattern");
        }   

        dispatch(deleteReducer(packagePattern));

        return true;
    }
);


const addItemToPackagesPatterns = (
    state: CurrentState<PackagePattern>,
    item: PackagePattern
) => {
        if (item.laboratory_id && item.pattern_id && item.package_id){
            state.data[getKeyForTabAssocPackagePattern(item)] = item;
            state.data[getKeyForTabAssocPackagePattern(item)].id = getKeyForTabAssocPackagePattern(item)
        }

};

const postDeletePackagePattern = (
    state: CurrentState<PackagePattern>,
    item: PackagePattern
) => {
    delete state.data[getKeyForTabAssocPackagePattern(item)];
    state.filtered.splice(
        state.filtered.indexOf(getKeyForTabAssocPackagePattern(item)),
        1
    );
};


const packagesPatternsSlice = createSlice({
    name: "relationship packagesPatterns",
    initialState,
    reducers: {...getDefaultReducersWithFrontPagination(
        addItemToPackagesPatterns,
        postDeletePackagePattern  
    ), updatePackagePattern : (state: CurrentState<PackagePattern>, action: PayloadAction<PackagePattern[]>) => {
        state.data = {};
        action.payload.forEach((item: PackagePattern) => {
            addItemToPackagesPatterns(state, item);
        });
    }},
    extraReducers: (builder) => {
        buildDefaultExtraReducersWithFrontPagination(
            addItemToPackagesPatterns,
            builder,
            fetchPackagesPatterns
        );
    },
});

export const {
    updatePackagePattern,
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage,
} = packagesPatternsSlice.actions;

export default packagesPatternsSlice.reducer;
