import React, { useEffect, useState, MouseEvent } from 'react';
import { Badge, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Laboratory, RawAnalysisColumns } from '../../api/configApi';
import AnalysisApi, { Analysis, NirInternalStatus, TypeAnalysisInputResultStatus } from '../../api/analysisApi';
import useFilterToolkit from '../utils/useFilterToolkit';
import { RootState } from '../../state/rootReducer';
import { deleteAnalysisResultsReducer, fetchAnalysis, filterByReducer, selectedAnalysisResultsReducer, setNbByPage, sortByReducer } from '../../state/analysisSlice';
import userHasRequiredRoles from '../auth/userHasRequiredRoles';
import ButtonHeaderList from './ButtonHeaderList';
import { StringMap } from 'i18next';
import { Data, TupleId } from '../../types/types';
import getResults from '../utils/getResults';
import dbt from '../utils/dbTranslation';
import { ReactComponent as SeeIcon } from 'bootstrap-icons/icons/zoom-in.svg'
import { useHistory } from 'react-router-dom';
import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg'
import { ReactComponent as CheckedIcon } from 'bootstrap-icons/icons/check-square.svg'
import { ReactComponent as UncheckedIcon } from 'bootstrap-icons/icons/square.svg'
import { ReactComponent as RefreshIcon } from 'bootstrap-icons/icons/arrow-clockwise.svg'
import Button from 'react-bootstrap/Button'
import CustomPagination from './CustomPagination';
import ModalExportExcel from '../../pages/analysis/export/ModalExportExcel';
import { postExportExcel } from '../../state/exportExcelSlice';
import { ExportRequest } from '../../api/publishApi';
import ModalDownload from './ModalDownload';
import useSecurityRoles from '../auth/useSecurityRoles';
import productDenominationsSlice, { fetchProductDenominations } from '../../state/productDenominationsSlice';
import { printPotentialNumber } from '../utils/utils';

interface AnalysisTableProps {
    laboratory: Laboratory,
    origin?: 'EXPORT_EXCEL' | 'NIR' | 'INPUT'
}

const AnalysisTable: React.FC<AnalysisTableProps> = ({ origin = undefined }) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { userGrantUpdateDone, userGrantUpdateReceived, userGrantUpdateRegistered, userHasRoleAll, userHasRoleClient, userHasRoleQuality, userHasRoleReadOnly, userHasRoleTechnician } = useSecurityRoles()

    const history = useHistory()

    const { user: authUser } = useSelector((state: RootState) => state.authUser)
    const hasMultipleLabs: boolean = authUser.laboratories.length > 1 || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])

    // Analysis state
    const analysis = useSelector((state: RootState) => state.analysis.analysis.data)
    const pagination = useSelector((state: RootState) => state.analysis.analysis.pagination)
    const fetchStatus = useSelector((state: RootState) => state.analysis.analysis.fetchStatus)
    const selectedAnalysis = useSelector((state: RootState) => state.analysis.analysis.selectedAnalysisResults)
    const origins = useSelector((state: RootState) => state.config.origins)
    const suppliers = useSelector((state: RootState) => state.config.suppliers)
    // pdc state
    const pdc = useSelector((state: RootState) => state.pdc.dataDA)

    // Config states
    const types = useSelector((state: RootState) => state.config.types.data)
    const families = useSelector((state: RootState) => state.config.families.data)
    const patterns = useSelector((state: RootState) => state.config.patterns.data)

    // Laboratory state
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    const isUserAdmin : boolean = userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])
    const authUserLaboratories = Object.fromEntries(Object.values(laboratories).filter(l => isUserAdmin || authUser.role_levels.find(r => r.laboratory_id === l.id) !== undefined).map(l => ([l.id, l])))

    // Local state
    const { language, analysisStatus, allowedPageSizes, nirStatus, inputStatus } = useSelector((state: RootState) => state.local)

    // Users state
    const farmers = useSelector((state: RootState) => state.farmers.farmer.data)
    const productDenominations = useSelector((state: RootState) => state.productDenominations.data)
    const employees = useSelector((state: RootState) => state.employees.data)
    const companies = useSelector((state: RootState) => state.companies.data)
    const typesCargill: Data<string> = { "FG": "FG", "RM": "RM", "FP": "FP" }
    // Impropers state
    const impropers = useSelector((state: RootState) => state.config.impropers.data)

    //  Nir state
    const nirs = useSelector((state: RootState) => state.nir.data)

    const [isChecked, setIsChecked] = useState(false);
    const [selectedAnalysisIds, setSelectedAnalysisIds] = useState<TupleId[]>([])

    // export modal
    const [showExportExcel, setShowExportExcel] = useState(false)
    const handleShowExportExcel = () => {
        const allLabEquals = selectedAnalysisIds.every(tuple => tuple.labId === selectedAnalysisIds[0].labId)
        if (selectedAnalysisIds.length > 0 && allLabEquals) {
            setShowExportExcel(true)
        }

        if (selectedAnalysisIds.length <= 0) {
            setMsgError(t('analysis.export_error_checked_msg'))
            handleShowError()
        }

        else if (!allLabEquals) {
            setMsgError(t('analysis.export_error_laboratory_checked_msg'))
            handleShowError()
        }
    }

    const handleExportExcel = () => {
        return async (data: ExportRequest) => {
            await dispatch(postExportExcel(data))
        }
    }
    const hasRoleTechnician = userHasRoleTechnician()
    const hasRoleQuality = userHasRoleQuality()
    const hasRoleAll = userHasRoleAll()
    const hasRoleClient = userHasRoleClient()
    const hasRoleReadOnly = userHasRoleReadOnly()

    const statusVariantMap: StringMap = {
        'registered': "",
        'received_improper': "warning",
        'received_compliant': "info",
        'received_fixed': "",
        'canceled': "dark",
        'partial': "secondary",
        'done': "success",
        'Pending NIR': "info",
        'Integration issue': "danger",
        'NIR blocked': "danger",
        "Publication issue": "warning",
        'in progress': "",
        "pending_results": "info",
        "pending_validation": "done",
        "Pending calculation": "info",
        "Calculation issue": "danger",
        "Pending Autocalc": "info",
        "Autocalc issue": "danger",
        "Administration issue": "danger",
        "Autocalc integration issue": "danger",
        "Ambiguity issue": "danger",

    }

    const grantUpdateRegistered = userGrantUpdateRegistered()

    const grantUpdateReceived = userGrantUpdateReceived()

    const grantUpdateDone = userGrantUpdateDone()

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showError, setShowError] = useState(false);

    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const [msgError, setMsgError] = useState("");

    const LabFiltered = userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']) ? laboratories : Object.values(laboratories)
        .filter(laboratory => authUser.role_levels.find((rl:any) => rl.laboratory_id === laboratories[laboratory.id].id && rl.level === "laboratory") !== undefined)
        .reduce((acc, laboratory) => {
            return { ...acc, [laboratory.id]: laboratory };
          }, {});
    const { FilterList, ListHeader, filterAndSortList } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "analysis_list.table.columns.",
        filterOnlyLaboratoryRoleLevel: (origin === 'INPUT' || origin === 'NIR') ? true : false,
        size: "xs",
        maxHeight: "200px",
        fields: {
            laboratory: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["list"],
                sortable: true,
                inOpt: origin === 'EXPORT_EXCEL' ? undefined : {
                    dbt: {
                        elements: (origin === 'INPUT' || origin === 'NIR') ? LabFiltered : authUserLaboratories,
                        field: "name",
                        idFieldName: "laboratory"
                    }
                }
            },
            nir: {
                type: ["filter"],
                sortable: true
            },
            ref_sample_id: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["number"],
                modal: {
                    displayed: true,
                    collapsible: true
                },
                sortable: true
            },
            ...((origin !== 'NIR') && {
                date: {
                    type: origin === 'EXPORT_EXCEL' ? ["label"] : ["date"],
                    sortable: true,
                    modal: {
                        displayed: true
                    }
                }
            }),
            ...((origin === 'NIR') && {
                internal_status_date: {
                    type: ["date"],
                    sortable: true,
                    modal: {
                        displayed: true
                    }
                }
            }),
            status: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["list"],
                sortable: true,
                editable: false,
                nonEditableList: (origin === 'INPUT' || origin === 'NIR') ? undefined : ["done", "partial"],
                defaultFilter: (origin === 'INPUT' || origin === 'NIR') ? ['received_compliant', 'partial'] : [],
                modal: {
                    displayed: true
                },
                inOpt: origin === 'EXPORT_EXCEL' ? undefined : {
                    t: {
                        tPrefix: "analysis.status.",
                        elements: analysisStatus
                    }
                }
            },
            ...((origin === 'NIR') && {
                internal_status: {
                    type: ["list"],
                    sortable: true,
                    editable: true,
                    nonEditableList: ["Pending NIR", "Integration issue", "NIR blocked", "Pending results", "Pending validation", "Pending calculation", "Calculation issue", "in progress", "partial", "released", "Publication issue", "Pending Autocalc", "Autocalc issue", "Administration issue", "Autocalc integration issue", "Ambiguity issue"] as NirInternalStatus[],
                    modal: {
                        displayed: true
                    },
                    inOpt: {
                        t: {
                            tPrefix: "analysis.internal_status.",
                            elements: nirStatus
                        }
                    }
                }
            }),
            ...((origin === 'INPUT') && {
                input_result_status: {
                    type: ["list"],
                    sortable: true,
                    editable: true,
                    nonEditableList: ['Pending results', 'Pending validation'] as TypeAnalysisInputResultStatus[],
                    defaultFilter: ['Pending results', 'Pending validation'] as TypeAnalysisInputResultStatus[],
                    modal: {
                        displayed: true
                    },
                    inOpt: {
                        t: {
                            tPrefix: "analysis.input_result_status.",
                            elements: inputStatus
                        }
                    }
                }
            }),
            input_result_status_date: {
                type: ["date"],
                sortable: true,
                modal: {
                    displayed: true
                }
            },
            farmer: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter"],
                modal: {
                    displayed: true,
                    collapsible: true
                },
                sortable: true
            },
            id: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["number"],
                modal: {
                    displayed: true,
                    collapsible: true
                },
                sortable: true
            },
            family: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter"],
                modal: {
                    displayed: true,
                    collapsible: true
                },
                sortable: true
            },
            denomination: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter"],
                modal: {
                    displayed: true,
                    collapsible: true
                },
                sortable: true
            },
            type: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["list"],
                sortable: true,
                inOpt: origin === 'EXPORT_EXCEL' ? undefined : {
                    mergeTwin: true,
                    dbt: {
                        elements: Object.fromEntries(Object.entries(types).filter(([k,v]) => v.active)),
                        field: "name",
                        idFieldName: "type_id"
                    }
                }
            },

            ...((!origin) && {
                type_cargill: {
                    type: ["list"],
                    sortable: true,
                    editable: true,
                    modal: {
                        displayed: true
                    },
                    inOpt: {
                        raw: {
                            elements: typesCargill,
                        }
                    }
                }
            }),

            pattern: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter"],
                sortable: true

            },
            employee: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter"],
                sortable: true
            },
            company: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter"],
                sortable: true
            },
            lot: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter"],
                modal: {
                    displayed: true,
                    collapsible: true
                },
                sortable: true
            },
            client_reference: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter"],
                modal: {
                    displayed: true,
                    collapsible: true
                },
                sortable: true
            },
            pdc: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter", "boolean"],
                sortable: true,
                boolOpt: {
                    labels: {
                        true: t('pdc.is_pdc'),
                        false: t('pdc.is_not_pdc')
                    }
                }
            },
            origin: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter"],
                sortable: true
            },
            supplier: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter"],
                sortable: true
            },
            client_denomination: {
                type: origin === 'EXPORT_EXCEL' ? ["label"] : ["filter"],
                sortable: true
            },
        },
        reducers: {
            filterBy: filterByReducer,
            sortBy: sortByReducer
        }
    })

    useEffect(() => {

        resetSelected()

    }, [pagination.filterBy, pagination.sortBy])


    const selectAll = (isChecked: boolean) => {
        setIsChecked(isChecked)
        if (isChecked) {
            if (pagination.pages[pagination.currentPage]) {
                setSelectedAnalysisIds(pagination.pages[pagination.currentPage].ids)
            }
        } else {
            setSelectedAnalysisIds([])
        }
    }

    const toggleSelected = (tuple: TupleId) => {
        return (e: MouseEvent) => {
            e.stopPropagation()
            if (selectedAnalysisIds.find(k => k.labId === tuple.labId && k.id === tuple.id)) {
                setSelectedAnalysisIds(selectedAnalysisIds.filter(key => !(key.labId === tuple.labId && key.id === tuple.id)))
            } else {
                setSelectedAnalysisIds([...selectedAnalysisIds, tuple])
            }
        }
    }

    const resetSelected = () => {
        setSelectedAnalysisIds([])
        setIsChecked(false)
    }

    const download = async () => {
        let bool = false
        for (let i = 0; i < selectedAnalysisIds.length; i++) {
                if(bool === false){
                    await AnalysisApi.getInstance().getResultsByAnalysisId(Number(selectedAnalysisIds[i].labId), Number(selectedAnalysisIds[i].id), false, true, false).then((keys) => {
                        if (keys.files_tab.length > 0) {
                            bool = true;
                        } 
                    }).catch(() => {
                        bool = false
                    });
                }
        }
        if (bool) {
            handleShow()
        } else {
            getResultsFormatting(true, false)
        }
    }

    const exportExcel = () => {
        handleShowExportExcel()
    }

    const getResultsFormatting = async (pdf: boolean, plb: boolean) => {
        try {
            const analysisWithResults = selectedAnalysisIds.filter(tuple => analysis[tuple.labId][tuple.id].status === "partial" || analysis[tuple.labId][tuple.id].status === "done")
            if (analysisWithResults.length > 0) {
                await getResults(analysisWithResults, pdf, plb)
            } else {
                setMsgError(t('analysis.download_error_checked_msg'))
                handleShowError()
            }
        } catch (err) {
            setMsgError(t("forms_errors.unexpected_error"))
            handleShowError()
        }
        handleClose()
    }

    function selectAnalysis(analysis: Analysis) {
        dispatch(selectedAnalysisResultsReducer(analysis))
    }

    function refresh() {
        dispatch(deleteAnalysisResultsReducer())
        dispatch(fetchAnalysis({ page: pagination.currentPage }))
    }

    useEffect(() => {
        dispatch(fetchProductDenominations())
    }, [])

    useEffect(() => {
        if (selectedAnalysis) {
            const analysisDocument = document.getElementById(`${selectedAnalysis.laboratory_id}#${selectedAnalysis.id}`);
            if (analysisDocument) {
                setTimeout(function () {
                    analysisDocument.scrollIntoView({
                        behavior: 'auto',
                        block: 'center',
                        inline: 'center'
                    });
                }, 1)
            }
        }
    }, [history])

    // catch arrows key press
    useEffect(() => {
        const onArrowPress = (e: any) => {
            if (selectedAnalysis && fetchStatus === 'succeeded' && pagination.pages[pagination.currentPage] && !pagination.pages[pagination.currentPage].fetching) {
                const ids = pagination.pages[pagination.currentPage].ids
                const labIdString = selectedAnalysis.laboratory_id.toString()
                const idString = selectedAnalysis.id.toString()
                const index = ids.findIndex((tupleId: TupleId) => tupleId && tupleId.labId === labIdString && tupleId.id === idString)
                if (e.key === 'ArrowUp' && index && ids[index - 1] && analysis[ids[index - 1].labId][ids[index - 1].id]) {
                    dispatch(selectedAnalysisResultsReducer(analysis[ids[index - 1].labId][ids[index - 1].id]))
                    const analysisDocument = document.getElementById(`${ids[index - 1].labId}#${ids[index - 1].id}`);
                    if (analysisDocument) {
                        analysisDocument.scrollIntoView({
                            behavior: 'auto',
                            block: 'center',
                            inline: 'center'
                        });
                    }
                }
                if (e.key === 'ArrowDown' && index > -1 && ids[index + 1] && analysis[ids[index + 1].labId][ids[index + 1].id]) {
                    dispatch(selectedAnalysisResultsReducer(analysis[ids[index + 1].labId][ids[index + 1].id]))
                    const analysisDocument = document.getElementById(`${ids[index + 1].labId}#${ids[index + 1].id}`);
                    if (analysisDocument) {
                        analysisDocument.scrollIntoView({
                            behavior: 'auto',
                            block: 'center',
                            inline: 'center'
                        });
                    }
                }
            }
        }
        window.addEventListener('keydown', onArrowPress)
        return () => { window.removeEventListener('keydown', onArrowPress) }
    }, [selectedAnalysis])

    const getDisplayColumn = (column: 'company' | 'type_cargill' | 'ref_sample_id' | 'pattern' | 'farmer' | 'employee' | 'lot' | 'client_reference' | 'denomination' | 'origin' | 'supplier' | 'client_denomination') => {
        return Object.values(laboratories).find((lab: Laboratory) => (userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']) || authUser.laboratories.includes(lab.id)) && lab.raw_analysis_columns && lab.analysis_results_columns[column])
    }

    type RawAnalysisColumnsKey = keyof { [key in RawAnalysisColumns]: boolean }
    const displayColumnNir = (columnName: RawAnalysisColumnsKey) => {
        return (
            Object.values(laboratories).find((lab: Laboratory) => (userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin']) || authUser.laboratories.includes(lab.id)) && lab.lab_type === 'client' && lab.raw_analysis_columns && lab.raw_analysis_columns[columnName])
        )
    }
    return (
        <Container className="analysis-results-container">
            <Modal size="lg" show={showError} onHide={handleCloseError}>
                <Modal.Header>
                    <Modal.Title><span className="text-primary fw-bold"> {t('error')} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{msgError}</p>
                </Modal.Body>
            </Modal>


            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title><span className="text-primary fw-bold"> {t('analysis_list.choose_files_to_download')} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row className="pt-2">
                        <Col className="text-start">
                            <Button style={{ width: 360 }} variant="secondary" onClick={getResultsFormatting.bind(null, true, false)}> {t('analysis_list.reports')} </Button>
                        </Col>
                        <Col className="text-end">
                            <Button style={{ width: 360 }} variant="secondary" onClick={getResultsFormatting.bind(null, false, true)}> {t('analysis_list.lynx_files')} </Button>
                        </Col>

                    </Row>

                    <Row className="pt-0 mt-3">
                        <Col className="text-center" >
                            <Button className="btn btn-default btn-block" variant="secondary" onClick={getResultsFormatting.bind(null, true, true)}> {t('analysis_list.all')} </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <ModalDownload selectedAnalysisIds={selectedAnalysisIds} setShowModal={setShow} showModal={show} />

            <ModalExportExcel exportRequestFront={{
                analysis_ids: selectedAnalysisIds.map(tuple => Number(tuple.id)),
                analytes: [],
                companies: [],
                startDate: null,
                endDate: null,
                families: [],
                selectedAnalyte: null,
                selectedCompany: null,
                selectedFamily: null,
                type_codes: [],
                laboratoryId: Number(selectedAnalysisIds[0]?.labId) // only one laboratory for the moment
            }} setShowExportExcel={setShowExportExcel} showExportExcel={showExportExcel} submitValues={handleExportExcel()} nbResults={0} />

            {origin !== 'EXPORT_EXCEL' &&

                <Container className="mt-1 analysis-results-container">

                    <Row className="d-none d-lg-flex mt-3">
                        <Col md={"auto"} className="d-flex align-items-start">
                            <h5>
                                <Badge pill bg="primary">{Intl.NumberFormat(authUser.locale).format(pagination.count)}</Badge>
                                <span className="ms-2 align-text-bottom fw-bold">{t('analysis_list.see_my_analysis_requests')}</span>
                            </h5>
                        </Col>

                        <Col>
                            <FilterList />
                        </Col>

                        <Col md="auto" className="ps-0 pe-2 text-end" >
                            {(origin !== 'NIR' && origin !== 'INPUT') &&
                                <>
                                    <Button size="sm" variant="secondary" onClick={download}> {t('analysis_list.download')} </Button>
                                    <Button size="sm" variant="secondary" className="ms-2" onClick={exportExcel}> {t('analysis_list.export')} </Button>
                                </>
                            }
                            <Button size="sm" variant="secondary" className="ms-2" onClick={() => { refresh() }} >
                                <RefreshIcon width="16px" height="100%" className="align-text-top" />
                            </Button>
                        </Col>

                    </Row>

                </Container>

            }

            <Container className="analysis-results-container mb-2 mt-2 ">
                <Table striped hover size='sm' className="bg-white text-center table-fixed table-xs">
                    <thead className='bg-white'>
                        <tr>
                            <th className="p-0" />
                            {hasMultipleLabs &&
                                <th className="p-1">
                                    <ListHeader name="laboratory" />
                                </th>
                            }

                            {displayColumnNir('nir') && origin === 'NIR' &&
                                <th className="p-1">
                                    <ListHeader name="nir" />
                                </th>
                            }
                            <th className="p-1">
                                <ListHeader name="id" />
                            </th>
                            {getDisplayColumn('ref_sample_id') && origin !== 'NIR' &&
                                <th className="p-1">
                                    <ListHeader name="ref_sample_id" />
                                </th>
                            }

                            {getDisplayColumn('type_cargill') && !origin &&

                                <th className="p-1">
                                    <ListHeader name="type_cargill" />
                                </th>
                            }
                            {(hasRoleClient || hasRoleAll || hasRoleQuality) &&
                                <th className="p-1">
                                    <ListHeader name="type" mergeTwin={true}/>
                                </th>
                            }
                            {(origin !== 'NIR' && origin !== 'INPUT') &&
                                <th className="p-1">
                                    <ListHeader name="status" />
                                </th>
                            }

                            {(origin === 'NIR') &&
                                <th className="p-1">
                                    <ListHeader name="internal_status" />
                                </th>
                            }
                            {(origin !== 'INPUT') &&
                                <th className="p-1">
                                    <ListHeader name={(origin !== "NIR") ? "date" : "internal_status_date"} />
                                </th>
                            }
                            {origin === 'INPUT' &&
                                <th className="p-1">
                                    <ListHeader name="input_result_status" />
                                </th>
                            }
                            {origin === 'INPUT' &&
                                <th className="p-1">
                                    <ListHeader name="input_result_status_date" />
                                </th>
                            }
                            {(origin !== 'NIR' || displayColumnNir('product')) &&
                                <th className="p-1">
                                    <ListHeader name="family" />
                                </th>
                            }
                            {((origin !== 'NIR' && getDisplayColumn('denomination')) || displayColumnNir('denomination')) &&
                                <th className="p-1">
                                    <ListHeader name="denomination" />
                                </th>
                            }
                            {origin !== 'NIR' && getDisplayColumn('pattern') &&
                                <th className="p-1">
                                    <ListHeader name="pattern" />
                                </th>
                            }
                            {(hasRoleClient || hasRoleAll || hasRoleTechnician) && ((origin !== 'NIR' && getDisplayColumn('farmer')) || displayColumnNir('farmer')) &&
                                <th className="p-1">
                                    <ListHeader name="farmer" />
                                </th>
                            }
                            {(hasRoleClient || hasRoleAll || hasRoleReadOnly) && ((origin !== 'NIR' && getDisplayColumn('employee')) || displayColumnNir('requester')) &&
                                <th className="p-1">
                                    <ListHeader name="employee" />
                                </th>
                            }
                            {((origin !== 'NIR' && getDisplayColumn('company')) || displayColumnNir('company')) &&
                                <th className="p-1">
                                    <ListHeader name="company" />
                                </th>
                            }
                            {((origin !== 'NIR' && getDisplayColumn('lot')) || displayColumnNir('lot')) &&
                                <th className="p-1">
                                    <ListHeader name="lot" />
                                </th>
                            }
                            {((origin !== 'NIR' && getDisplayColumn('client_reference')) || displayColumnNir('client_reference')) &&
                                <th className="p-1">
                                    <ListHeader name="client_reference" />
                                </th>
                            }

                            <th className="p-1">
                                <ListHeader name="pdc" />
                            </th>

                            {((origin !== 'NIR' && getDisplayColumn('origin')) || displayColumnNir('origin')) &&

                                <th className="p-1">
                                    <ListHeader name="origin" />
                                </th>
                            }
                            {((origin !== 'NIR' && getDisplayColumn('supplier')) || displayColumnNir('supplier')) &&

                                <th className="p-1">
                                    <ListHeader name="supplier" />
                                </th>
                            }
                            {((origin !== 'NIR' && getDisplayColumn('client_denomination')) || displayColumnNir('client_denomination')) &&

                                <th className="p-1">
                                    <ListHeader name="client_denomination" />
                                </th>
                            }
                            {origin !== 'EXPORT_EXCEL' &&
                                <th></th>
                            }

                            {origin !== 'EXPORT_EXCEL' && origin !== 'NIR' &&
                                <th className="p-1">
                                    <ButtonHeaderList
                                        title={""}
                                        name="pdc"
                                        type={["check"]}
                                        onClickSelect={selectAll}
                                        isChecked={isChecked}
                                        size="xs"
                                    />
                                </th>
                            }
                            <th className="p-0" />
                        </tr>
                    </thead>
                    {fetchStatus === 'succeeded' &&
                        <tbody>
                            {pagination.pages[pagination.currentPage] && !pagination.pages[pagination.currentPage].fetching && pagination.pages[pagination.currentPage].ids.map((key) => {
                                const analysisObject = analysis[key.labId][key.id]
                                return (

                                    <tr key={`${key.labId}#${key.id}`} id={`${key.labId}#${key.id}`} className={selectedAnalysis && selectedAnalysis.laboratory_id === analysisObject.laboratory_id && selectedAnalysis.id === analysisObject.id ? "align-middle bold-to-child" : "align-middle"} onClick={() => { selectAnalysis(analysisObject) }}>
                                        {selectedAnalysis && selectedAnalysis.laboratory_id === analysisObject.laboratory_id && selectedAnalysis.id === analysisObject.id &&
                                            <td className="table-hover-hint bg-dark opacity-75" />
                                        }
                                        {(!selectedAnalysis || selectedAnalysis.laboratory_id !== analysisObject.laboratory_id || selectedAnalysis.id !== analysisObject.id) &&
                                            <td className="table-hover-hint" />
                                        }
                                        {hasMultipleLabs && <td className="text-primary fw-bold">{dbt(laboratories, analysisObject.laboratory_id, language.id, "name")}</td>}
                                        {displayColumnNir('nir') && origin === 'NIR' &&
                                            <td >{nirs && nirs[`${analysisObject.laboratory_id}#${analysisObject.nir_code}`] ? nirs[`${analysisObject.laboratory_id}#${analysisObject.nir_code}`].name : ''}</td>
                                        }
                                        <td className="text-primary fw-bold">{printPotentialNumber(analysisObject.id, analysisObject.display_id, authUser)}</td>
                                        
                                        {getDisplayColumn('ref_sample_id') && origin !== 'NIR' &&
                                            <td>{analysisObject.additional_analysis && analysisObject.additional_analysis[0] && analysisObject.additional_analysis[0].ref_laboratory_id && analysisObject.additional_analysis[0].ref_analysis_id ? analysisObject.additional_analysis[0].ref_analysis_id : ''}</td>
                                        }

                                        {getDisplayColumn('type_cargill') && !origin &&
                                            <td>{analysisObject.type_code}</td>
                                        }

                                        {(hasRoleClient || hasRoleAll || hasRoleQuality) && <td>{dbt(types, analysisObject.type_id, language.id, "name")}</td>}
                                        {(origin !== 'NIR' && origin !== 'INPUT') &&
                                            <td>
                                                {Object.values(analysisStatus).includes(analysisObject.status) &&
                                                    <Badge bg={statusVariantMap[analysisObject.status]} className={analysisObject.status}>
                                                        <span>{t('analysis.status.' + analysisObject.status)}</span>
                                                    </Badge>
                                                }
                                            </td>
                                        }
                                        {(origin === 'NIR') &&
                                            <td>
                                                {Object.values(nirStatus).includes(analysisObject.internal_status) && analysisObject.status === 'canceled' &&
                                                    <Badge bg={statusVariantMap[analysisObject.status]} className={analysisObject.status.toLowerCase().replace(' ', '_')}>
                                                        <span>{t('analysis.status.' + analysisObject.status)}</span>
                                                    </Badge>
                                                }
                                                {Object.values(nirStatus).includes(analysisObject.internal_status) && analysisObject.status !== 'canceled' &&
                                                    <Badge bg={statusVariantMap[analysisObject.internal_status]} className={analysisObject.internal_status.toLowerCase().replace(' ', '_')}>
                                                        <span>{t('analysis.internal_status.' + analysisObject.internal_status)}</span>
                                                    </Badge>
                                                }
                                            </td>
                                        }
                                        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                                        {(origin !== 'INPUT') &&
                                            <td>{origin !== 'NIR' ? analysisObject.last_date && new Intl.DateTimeFormat(authUser.locale).format(new Date(analysisObject.last_date!)) : analysisObject.internal_status_date && new Intl.DateTimeFormat(authUser.locale).format(new Date(analysisObject.internal_status_date))}</td>
                                        }
                                        {origin === 'INPUT' &&
                                            <td>
                                                {Object.values(inputStatus).includes(analysisObject.input_result_status) &&
                                                    <Badge bg={statusVariantMap[analysisObject.input_result_status]} className={analysisObject.input_result_status.toLowerCase().replace(' ', '_')}>
                                                        <span>{t('analysis.input_result_status.' + analysisObject.input_result_status)}</span>
                                                    </Badge>
                                                }
                                            </td>
                                        }
                                        {origin === 'INPUT' &&
                                            <td>{analysisObject.input_result_status_date && new Intl.DateTimeFormat(authUser.locale).format(new Date(analysisObject.input_result_status_date))}</td>
                                        }
                                        {(origin !== 'NIR' || displayColumnNir('product')) &&
                                            <td>{
                                                typeof analysisObject.denomination === "string" && analysisObject.denomination !== "" && !getDisplayColumn('denomination') && origin !== 'NIR' ? // In case of NIR, we always display family
                                                    analysisObject.denomination
                                                    : typeof analysisObject.family_details === "string" && analysisObject.family_details !== "" && analysisObject.type_code === "FG" && (!getDisplayColumn('denomination') || origin === 'NIR') ?
                                                        analysisObject.family_details
                                                        : typeof analysisObject.family_id === "number" && families[analysisObject.family_id] ?
                                                            dbt(families, analysisObject.family_id, language.id, "name")
                                                            : ""
                                            }</td>
                                        }
                                        {((origin !== 'NIR' && getDisplayColumn('denomination')) || displayColumnNir('denomination')) &&
                                            <td>{typeof analysisObject.denomination === "string" && analysisObject.denomination !== "" ? analysisObject.denomination : ""}</td>
                                        }
                                        {origin !== 'NIR' && getDisplayColumn('pattern') &&
                                            <td>{dbt(patterns, analysisObject.pattern_id, language.id, "name")}</td>
                                        }
                                        {(hasRoleClient || hasRoleAll || hasRoleTechnician) && ((origin !== 'NIR' && getDisplayColumn('farmer')) || displayColumnNir('farmer')) &&
                                            <td>{
                                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                typeof analysisObject.farmer_id === "number" && farmers[analysisObject.farmer_id!] ?
                                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                    farmers[analysisObject.farmer_id!].code !== "" ? farmers[analysisObject.farmer_id!].code + "/" + farmers[analysisObject.farmer_id!].name : farmers[analysisObject.farmer_id!].name
                                                    : ""
                                            }</td>
                                        }
                                        {(hasRoleClient || hasRoleAll || hasRoleReadOnly) && ((origin !== 'NIR' && getDisplayColumn('employee')) || displayColumnNir('requester')) &&
                                            <td>{
                                                typeof analysisObject.employee_id === "number" && employees[analysisObject.employee_id] ?
                                                    employees[analysisObject.employee_id].firstname + " " + employees[analysisObject.employee_id].lastname
                                                    : ""
                                            }</td>
                                        }
                                        {((origin !== 'NIR' && getDisplayColumn('company')) || displayColumnNir('company')) &&
                                            <td>{
                                                typeof analysisObject.company_id === "number" && companies[analysisObject.company_id] ?
                                                    companies[analysisObject.company_id].name
                                                    : ""
                                            }</td>
                                        }
                                        {((origin !== 'NIR' && getDisplayColumn('lot')) || displayColumnNir('lot')) &&
                                            <td>{typeof analysisObject.lot === "string" && analysisObject.lot !== "" ? analysisObject.lot : ""}</td>
                                        }
                                        {((origin !== 'NIR' && getDisplayColumn('client_reference')) || displayColumnNir('client_reference')) &&
                                            <td>{typeof analysisObject.client_reference === "string" && analysisObject.client_reference !== "" ? analysisObject.client_reference : ""}</td>
                                        }
                                        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}

                                        <td>{analysis[key.labId] && analysisObject.id_pdc !== null && pdc[key.labId] && pdc[key.labId][analysisObject.id_pdc!] ? pdc[key.labId][analysisObject.id_pdc!].name : null}</td>

                                        {((origin !== 'NIR' && getDisplayColumn('origin')) || displayColumnNir('origin')) &&
                                            <td>{analysis[key.labId] && analysisObject.origin_id !== null && origins.data[analysisObject.origin_id!] ? origins.data[analysisObject.origin_id!].name : null} </td>
                                        }

                                        {((origin !== 'NIR' && getDisplayColumn('supplier')) || displayColumnNir('supplier')) &&
                                            <td>{analysis[key.labId] && analysisObject.provider_id !== null && suppliers.data[analysisObject.provider_id!] ? suppliers.data[analysisObject.provider_id!].name : null}</td>
                                        }

                                        {((origin !== 'NIR' && getDisplayColumn('client_denomination')) || displayColumnNir('client_denomination')) &&
                                            <td>{analysis[key.labId] && analysisObject.denomination_id && analysisObject.denomination_id !== null && productDenominations[analysisObject.denomination_id] ? productDenominations[analysisObject.denomination_id].name : ""}</td>
                                        }

                                        {origin !== 'EXPORT_EXCEL' &&
                                            <td className="text-nowrap">
                                                <Button className="p-0 pe-1 text-primary lh-1" variant="link" onClick={(e) => { e.stopPropagation(); history.push(`/secure/analysis/${analysisObject.laboratory_id}/${analysisObject.id}`) }} >
                                                    <SeeIcon width="16px" height="100%" className="align-text-top" />
                                                </Button>
                                                {(
                                                    (['registered'].includes(analysisObject.status) && grantUpdateRegistered)
                                                    ||
                                                    (['received_improper'].includes(analysisObject.status) && grantUpdateRegistered && analysisObject.improper_id && impropers[analysisObject.improper_id].editable)
                                                    ||
                                                    (['received_improper', 'received_compliant', 'received_fixed'].includes(analysisObject.status) && grantUpdateReceived)
                                                    ||
                                                    (['partial', 'done'].includes(analysisObject.status) && grantUpdateDone)

                                                ) &&
                                                    <Button className="p-0 pe-1 text-secondary lh-1" variant="link" onClick={(e) => { e.stopPropagation(); history.push(`/secure/analysis/${analysisObject.laboratory_id}/${analysisObject.id}/edit/1`) }} >
                                                        <EditIcon width="16px" height="100%" className="align-text-top" />
                                                    </Button>
                                                }
                                            </td>

                                        }

                                        {origin !== 'EXPORT_EXCEL' && origin !== 'NIR' &&
                                            <td>
                                                <Button className="p-0 text-primary lh-1" variant="link" onClick={toggleSelected(key)} >
                                                    {(selectedAnalysisIds.includes(key) ?
                                                        <CheckedIcon width="14px" height="100%" className="align-text-top" />
                                                        : <UncheckedIcon width="14px" height="100%" className="align-text-top" />)
                                                    }
                                                </Button>
                                            </td>
                                        }

                                        {selectedAnalysis && selectedAnalysis.laboratory_id === analysisObject.laboratory_id && selectedAnalysis.id === analysisObject.id &&
                                            <td className="table-hover-hint bg-dark opacity-75" />
                                        }
                                        {(!selectedAnalysis || selectedAnalysis.laboratory_id !== analysisObject.laboratory_id || selectedAnalysis.id !== analysisObject.id) &&
                                            <td className="table-hover-hint" />
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    }
                </Table>
                <CustomPagination
                    nbPages={pagination.nbPage}
                    curPage={pagination.currentPage}
                    nbNeighbours={1}
                    curPageSize={pagination.nbByPage}
                    allowedPageSizes={allowedPageSizes}
                    goToPage={(page) => { dispatch(fetchAnalysis({ page })); resetSelected(); dispatch(deleteAnalysisResultsReducer()) }}
                    setPageSize={(nbPage) => { dispatch(setNbByPage(nbPage)); dispatch(fetchAnalysis({ page: 1 })); resetSelected(); dispatch(deleteAnalysisResultsReducer()) }}
                />
            </Container>
        </Container>
    )
}

export default AnalysisTable
