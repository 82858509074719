// This code generates correct css custom properties
// from any color code (no named color yet)

import React from 'react'
import { Helmet } from 'react-helmet'
import identity from 'lodash/identity'
import map from 'lodash/map'
import trim from 'lodash/trim'
import { Data } from '../../types/types'

const HOVER_RATIO = 10

const printCss = (suffix = '', convert: (string: string) => string = identity) => {
  return (value: string, property: string) => `--${property}${suffix ? '-' + suffix : ''}: ${convert(value)};`
}

const rgbToHsl = (red: string, green: string, blue: string): {h: number, s: number, l: number} => {
  const r = Number(trim(red)) / 255
  const g = Number(trim(green)) / 255
  const b = Number(trim(blue)) / 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h,
    s,
    l = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
      default:
        h = 0
        break
    }
    h /= 6
  }

  h = Math.round(360 * h)
  s = Math.round(100 * s)
  l = Math.round(100 * l)

  return {h, s, l}
}

function hue2rgb(p: number, q: number, t: number){
  if(t < 0) t += 1;
  if(t > 1) t -= 1;
  if(t < 1/6) return p + (q - p) * 6 * t;
  if(t < 1/2) return q;
  if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
  return p;
}

const hslToRgb = (hsl: { h: number, s: number, l: number}) => { 

  const h = hsl.h / 360
  const s = hsl.s / 100
  const l = hsl.l / 100

  let r, g, b;

  if(s == 0){
    r = g = b = l; // achromatic
  } else {
    

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }

  return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255)};
}

const rgbToHex = (r: number, g: number, b: number): string => {
  const toHex = (x: number) => {
    const hex =x.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

const hexToRgb = (color: string): {r: number, g: number, b:number} => {
  if (color.length === 4) {
    const r = parseInt(color.substr(1, 1) + color.substr(1, 1), 16).toString()
    const g = parseInt(color.substr(2, 1) + color.substr(2, 1), 16).toString()
    const b = parseInt(color.substr(3, 1) + color.substr(3, 1), 16).toString()
    return {r: Number(r), g: Number(g), b: Number(b)}
  } else {
    const r = parseInt(color.substr(1, 2), 16).toString()
    const g = parseInt(color.substr(3, 2), 16).toString()
    const b = parseInt(color.substr(5, 2), 16).toString()
    return {r: Number(r), g: Number(g), b: Number(b)}
  }
}

const colorToHslRgb = (color: string) => {
  if (color.startsWith('#')) {
    const rgb = hexToRgb(color)
    return {
      hsl: rgbToHsl(String(rgb.r), String(rgb.g), String(rgb.b)),
      rgb
    }
  } else if (color.startsWith('rgba')) {
    const [r, g, b] = color.slice(5, -1).split(',')
    return {
      hsl: rgbToHsl(r, g, b),
      rgb: {r: Number(r), g: Number(g), b: Number(b)}
    }
  } else if (color.startsWith('rgb')) {
    const [r, g, b] = color.slice(4, -1).split(',')
    return {
      hsl: rgbToHsl(r, g, b),
      rgb: {r: Number(r), g: Number(g), b: Number(b)}
    }
  } else if (color.startsWith('hsla')) {
    const hsl = color.slice(5, -1).split(',').slice(0, 3).map(x => Number(x))
    return {
      hsl: {h: hsl[0], s: hsl[1], l: hsl[2]},
      rgb: hslToRgb({h: hsl[0], s: hsl[1], l: hsl[2]})
    }
  } else if (color.startsWith('hsl')) {
    const hsl = color.slice(4, -1).split(',').map(x => Number(x))
    return {
      hsl: {h: hsl[0], s: hsl[1], l: hsl[2]},
      rgb: hslToRgb({h: hsl[0], s: hsl[1], l: hsl[2]})
    }
  } else {
    // named color values are not yet supported
    console.warn('Named color values are not supported in the config. Convert it manually using this chart: https://htmlcolorcodes.com/color-names/')
    // defaults to dark gray
    return {
      hsl: {h: 0, s:0, l:16},
      rgb: {r: 41, g:41, b: 41}
    }
  }
}

const scaleColor = (colorHex: string): string => {
  const hslrgb = colorToHslRgb(colorHex)
  const hoverHsl = hslrgb.hsl.l < 20 ? {...hslrgb.hsl, l: hslrgb.hsl.l + HOVER_RATIO} : {...hslrgb.hsl, l: hslrgb.hsl.l - HOVER_RATIO}
  const hoverRgb = hslToRgb(hoverHsl)
  return rgbToHex(hoverRgb.r, hoverRgb.g, hoverRgb.b)

}

interface ApplyBrandingProps {
  colors: Data<string>
}

export const ApplyBranding: React.FC<ApplyBrandingProps> = ({ colors }: ApplyBrandingProps) => {
  if (colors) {
    const hoverColors: Data<string> = {}
    Object.keys(colors).forEach((key: string) => { 
      const hoverColor = scaleColor(colors[key]) 
      hoverColors[key] = hoverColor
    })
    return (
      <Helmet>
        <style>
          {`:root {
          ${colors && map(
            colors,
            printCss('', color => {
              return color
            })
          ).join("\n")}
          ${colors && map(
            colors,
            printCss('rgb', color => {
              const hslrgb = colorToHslRgb(color)
              return `${hslrgb.rgb.r}, ${hslrgb.rgb.g}, ${hslrgb.rgb.b}`
            })
          ).join("\n")}
          ${hoverColors && map(
            hoverColors,
            printCss('hover', color => {
              return color
            })
          ).join("\n")}
          ${hoverColors && map(
            hoverColors,
            printCss('hover-rgb', color => {
              const hslrgb = colorToHslRgb(color)
              return `${hslrgb.rgb.r}, ${hslrgb.rgb.g}, ${hslrgb.rgb.b}`
            })
          ).join("\n")}
          }`}
        </style>
      </Helmet>
    )
  } else return null
}

export default ApplyBranding