import { WithTranslation, withTranslation } from "react-i18next";
import { Button, Col, Container, Modal, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import MatrixApi, { ExcelExportType, MatrixTemplate } from "../../../../api/matrixApi";
import { Data } from "../../../../types/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state/rootReducer";
import UploadMatrixExcelFile from "../../../../common/components/UploadMatrixExcelFile";
import { useState } from "react";
import userHasRequiredRoles from "../../../../common/auth/userHasRequiredRoles";
import { AppDispatch } from "../../../../state/store";
import { fetchExportMatrixExports, fetchMatrixExports } from "../../../../state/matrixExportsSlice";
import ExceptionModal from "../../../../common/utils/ExceptionModal";
import { ReactComponent as QuestionCircle } from 'bootstrap-icons/icons/question-circle.svg'

interface MatrixExportsListProps extends WithTranslation {
    type: string;
    laboratoryId: number;
}
const MatrixExportsImportExportContainer: React.FC<MatrixExportsListProps> = ({
    t,
    type,
    laboratoryId,
}) => {

    const dispatch = useDispatch<AppDispatch>()
    const { user: authUser, authCurLabId } = useSelector((state: RootState) => state.authUser)
    const pagination = useSelector((state: RootState) => state.matrixTemplates.pagination)

    // api error
    const [apiError, setApiError] = useState<unknown>(undefined)

    const onExcelFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setApiError(undefined)
        if (e.target.files && e.target.files[0]) {
            try {
                await MatrixApi.getInstance().importMatrixExports(type as ExcelExportType, e.target.files[0], laboratoryId)
                dispatch(fetchMatrixExports())
            } catch (err) {
                setApiError(err)
            } finally {
                e.target.value = '' // to be able to select the same file twice
            }
        }
    };


    return (
        <Container className="border border-primary rounded ">
            
            <ExceptionModal
                type="import"
                title={t('matrix.matrix_exports.title')}
                message={t('matrix.matrix_exports.errors.msg_import_failed')}
                error={apiError}
                onHide={() => {
                    setApiError(undefined)
                }}
            />
            <Row>
                <Col>
                    <span className="px-1">{t(`matrix.matrix_exports.type.${type}`)}</span>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Popover style={{ maxWidth: 'none' }} >
                                <Popover.Header as="h3">{t(`matrix.matrix_exports.helper.header.${type}`)}</Popover.Header>
                                <Popover.Body>
                                    <div> {t(`matrix.matrix_exports.helper.phrase.${type}`)}  </div>

                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <QuestionCircle width="16px" height="100%"/>

                    </OverlayTrigger>
                </Col>
            </Row>
            <Row>
                <Col >
                    <UploadMatrixExcelFile uploadAction={onExcelFileChange} type={type} />
                </Col>
                <Col>
                    <Button className="btn btn-xs" variant="secondary" type="button" onClick={() => dispatch(fetchExportMatrixExports({ type: type, laboratoryId: laboratoryId }))}>
                        <span className="px-1">
                            {t('buttons.export')}
                        </span>
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};
export default withTranslation()(MatrixExportsImportExportContainer);
