import { Analysis, AnalysisPackage } from "../../api/analysisApi"
import { PatternPackageTestUtils } from "../../pages/pdc/helpers/PatternPackageTestsUtils"

export interface IPcpw {
    price?: number | null
    cost?: number | null
    period?: number | null
    weight?: number | null
    currency?: string
}

const pcpwUtils = {
    
    calculatePatternPrice: (analysis: Analysis | null, packagesValues?: Array<AnalysisPackage>): number | null => {
        if (!analysis || analysis.pattern_id === 0 || !packagesValues) return null
        let price: number | null = null
        if(analysis.price != null  || packagesValues.filter((pack) => pack.price != null || pack.analysis_package_tests.filter((test)=> test.price != null).length > 0).length > 0){
            price = analysis.price || 0
            for (let indexPackage = 0; indexPackage < packagesValues.length; indexPackage++) {
                price += packagesValues[indexPackage].price || 0
                if (packagesValues[indexPackage].analysis_package_tests !== undefined) {
                    for (let indexTest = 0; indexTest < packagesValues[indexPackage].analysis_package_tests.length; indexTest++) {
                        price += packagesValues[indexPackage].analysis_package_tests[indexTest].price || 0
                    }
                }
            }
        }
        return price
    },

    calculatePatternCost: (analysis: Analysis | null, packagesValues?: Array<AnalysisPackage>): number | null  => {

        if (!analysis || analysis.pattern_id === 0 || !packagesValues) return null

        let cost: number | null = null
        if(analysis.cost != null  || packagesValues.filter((pack) => pack.cost != null || pack.analysis_package_tests.filter((test)=> test.cost != null).length > 0).length > 0){
            cost = analysis.cost || 0
            for (let indexPackage = 0; indexPackage < packagesValues.length; indexPackage++) {
                cost += packagesValues[indexPackage].cost || 0
                if (packagesValues[indexPackage].analysis_package_tests !== undefined) {
                    for (let indexTest = 0; indexTest < packagesValues[indexPackage].analysis_package_tests.length; indexTest++) {
                        cost += packagesValues[indexPackage].analysis_package_tests[indexTest].cost || 0
                    }
                }
            }
        }
        return cost
    },


    calculatePatternPeriod: (analysis: Analysis | null, packagesValues?: Array<AnalysisPackage>): number | null => {

        if (!analysis || analysis.pattern_id === 0 || !packagesValues) return null

        let period: number | null = null
        if(analysis.period != null  || packagesValues.filter((pack) => pack.period != null || pack.analysis_package_tests.filter((test)=> test.period != null).length > 0).length > 0){
            period = analysis.period || 0
            for (let indexPackage = 0; indexPackage < packagesValues.length; indexPackage++) {
                if (packagesValues[indexPackage].period !== undefined && (packagesValues[indexPackage].period as number) > period) {
                    period = (packagesValues[indexPackage].period as number)
                }
                if (packagesValues[indexPackage].analysis_package_tests !== undefined) {
                    const testsOrGroups = PatternPackageTestUtils.getTestsOrGroups(packagesValues[indexPackage].analysis_package_tests)
                    for (let indexTest = 0; indexTest < testsOrGroups.length; indexTest++) {
                        if (testsOrGroups[indexTest].period !== undefined && (testsOrGroups[indexTest].period as number) > period) {
                            period = testsOrGroups[indexTest].period as number
                        }
                    }
                }
            }
        }
        return period
    },

    calculatePatternWeight: (analysis: Analysis | null, packagesValues?: AnalysisPackage []): number | null => {

        if (!analysis || analysis.pattern_id === 0 || !packagesValues) return null

        let weight : number | null = null
        if(analysis.weight != null || packagesValues.filter((pack) => pack.weight != null || pack.analysis_package_tests.filter((test)=> test.weight != null).length > 0).length > 0){
            weight = analysis.weight || 0
            for (let indexPackage = 0; indexPackage < packagesValues.length; indexPackage++) {
                weight += packagesValues[indexPackage].weight || 0
                if (packagesValues[indexPackage].analysis_package_tests !== undefined) {
                    for (let indexTest = 0; indexTest < packagesValues[indexPackage].analysis_package_tests.length; indexTest++) {
                        weight += packagesValues[indexPackage].analysis_package_tests[indexTest].weight || 0
                    }
                }
            }
        }
        return weight
    }
}

export default pcpwUtils