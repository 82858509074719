import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import HttpApi from 'i18next-http-backend';
import { initReactI18next  } from "react-i18next";

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(detector)
  .use(Backend)
  .use(initReactI18next ) // passes i18n down to react-i18next
  .init({
    backend: {
      backends: [
        LocalStorageBackend,
        HttpApi
      ],
      backendOptions: [{
        customHeaders: {
          cache: "no-store"
        },
        expirationTime: 7*24*60*60*1000,
        defaultVersion: 'v1.227',
        versions: {} // ex.: versions: { en: 'v1.2', fr: 'v1.1' }
      }]
    },

    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
    },

    fallbackLng: 'en',

    keySeparator: '.', // to use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    react: {
      useSuspense: false
    }
  });

export default i18n;
