import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import Loader from '../components/Loader';

interface CustomLoginCallbackProps {
    restoreOriginalUri: any
}

const CustomLoginCallback: React.FC<CustomLoginCallbackProps> = ({ restoreOriginalUri }) => {
    
    const { oktaAuth } = useOktaAuth();

    // Handle token retrieval
    useEffect(() => {
        oktaAuth.handleLoginRedirect().then(() => {
            restoreOriginalUri()
        })
    }, [oktaAuth, restoreOriginalUri]);

    return (
        <Loader />
    )
}


export default CustomLoginCallback;