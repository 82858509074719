import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../state/rootReducer'

import { ReactComponent as CheckedIcon } from 'bootstrap-icons/icons/check-square.svg'
import { ReactComponent as UncheckedIcon } from 'bootstrap-icons/icons/square.svg'
import { ReactComponent as SeeIcon } from 'bootstrap-icons/icons/zoom-in.svg'
import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg'

import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button'
import { useHistory } from "react-router-dom";

import { fetchPdc, filterByReducer, sortByReducer, setNbByPage } from '../../../state/pdcSlice';
import userHasRequiredRoles from '../../../common/auth/userHasRequiredRoles';
import dbt from '../../../common/utils/dbTranslation';
import Loader from '../../../common/components/Loader';
import CustomPagination from '../../../common/components/CustomPagination';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';
import useSecurityRoles from '../../../common/auth/useSecurityRoles';



type ControlPlanListProps = WithTranslation

interface StringMap {
    [key: string]: string
}

const ControlPlanList: React.FC<ControlPlanListProps> = ({ t }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { userIsInternal, userControlPlanAdmin, userGrantControlPlan, userGrantControlPlanAdmin } = useSecurityRoles()

    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    const pdc = useSelector((state: RootState) => state.pdc.data)
    const pagination = useSelector((state: RootState) => state.pdc.pagination)

    const hasMultipleLabs : boolean = authUser.laboratories.length > 1 || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])

    // Local state
    const { language, pdcStatus, allowedPageSizes } = useSelector((state: RootState) => state.local)
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    const companies = useSelector((state: RootState) => state.companies.data)
    const pdcStatusList = useSelector((state: RootState) => state.config.status.data['control-plan'])

    const statusVariantMap: StringMap = {
        'created': "created",
        'published': "published",
        'canceled': "canceled",
        'closed': "closed"
    }

    const { FilterList, ListHeader } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "pdc_list.table.columns.",
        fields: {
            laboratory: {
                type: ["list"],
                sortable: true,
                inOpt: {
                    dbt: {
                        elements: Object.values(laboratories).filter(l => userGrantControlPlan(l.id, authUser)).reduce( (obj, item) => Object.assign(obj, { [item.id]: item }), {}),
                        field: "name",
                        idFieldName: "laboratory",
                    }
                }
            },
            id: {
                type: ["number"],
                sortable: true
            },
            name: {
                type: ["filter"],
                sortable: true
            },
            status: {
                type: ["list"],
                sortable: true,
                inOpt: {
                    dbt: {
                        elements: pdcStatusList,
                        field: "label",
                        idFieldName: "key",
                        dbtIdFieldName: "key"
                    }
                }
            },
            company: {
                type: ["filter"],
                sortable: true
            },
            last_date: {
                type: ["date"],
                sortable: true
            },
            last_update_by: {
                type: ["filter"],
                sortable: true
            },
            start_date: {
                type: ["date"],
                sortable: true
            },
            end_date: {
                type: ["date"],
                sortable: true
            },
            billable: {
                type: ["boolean"],
                sortable: true,
                boolOpt: {
                    labels: {
                        true: t('pdc.facture'),
                        false: t('pdc.not_facture')
                    }
                }
            },
            samples_number: {
                type: ["number"],
                sortable: true
            },
            samples_used: {
                type: ["number"],
                sortable: true
            },
            tests_number: {
                type: ["number"],
                sortable: true
            },
            price: {
                type: ["number"],
                sortable: true
            },
            cost: {
                type: ["number"],
                sortable: true
            },
        },
        reducers: {
            filterBy: filterByReducer,
            sortBy: sortByReducer
        }
    })

    return (
        <>            
            {(pagination.status === 'loading') &&
                <Loader />
            }

            <Container className="justify-content-center mt-2">
                <Row className="d-flex flex-row-reverse">
                    <Col>
                        <FilterList />
                    </Col>
                </Row>

            </Container>

            {/* Display for desktop */}

            <Container className="d-flex justify-content-center">
                <Table striped hover responsive="lg" className="table table-responsive d-none d-lg-table bg-white text-center mt-2">
                    <thead>
                        <tr>
                            <th className="p-0" />
                            {hasMultipleLabs &&
                                <th className="p-1">
                                    <ListHeader name="laboratory" />
                                </th>
                            }
                            <th className="p-1">
                                <ListHeader name="id" />
                            </th>
                            <th className="p-1">
                                <ListHeader name="name" />
                            </th>
                            <th className="p-1">
                                <ListHeader name="status" />
                            </th>
                            <th className="p-1">
                                <ListHeader name="company" />
                            </th>

                            {userControlPlanAdmin() &&
                                <th className="p-1">
                                    <ListHeader name="last_date" />
                                </th>
                            }
                            <th className="p-1">
                                <ListHeader name="start_date" />
                            </th>
                            <th className="p-1">
                                <ListHeader name="end_date" />
                            </th>
                            <th className="p-1">
                                <ListHeader name="billable" />
                            </th>
                            <th className="p-1">
                                <ListHeader name="samples_number" />
                            </th>
                            <th className="p-1">
                                <ListHeader name="samples_used" />
                            </th>
                            <th className="p-1">
                                <ListHeader name="tests_number" />
                            </th>
                            <th className="p-1">
                                <ListHeader name="price" />
                            </th>

                            { userGrantControlPlanAdmin(undefined, authUser) && userIsInternal() &&
                                <th className="p-1">
                                    <ListHeader name="cost" />
                                </th>
                            }

                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {pagination.pages[pagination.currentPage] && !pagination.pages[pagination.currentPage].fetching && pagination.pages[pagination.currentPage].ids.map((key) => {
                            if(laboratories[pdc[key.labId][key.id].laboratory_id]) { 
                                return (
                                
                                        <tr key={pdc[key.labId][key.id].id} className="align-middle" onClick={() => { history.push(`/secure/pdc/${pdc[key.labId][key.id].laboratory_id}/${pdc[key.labId][key.id].id}`) }}>
                                            <td className="p-1 table-hover-hint" />
                                            {hasMultipleLabs && <td className="text-primary fw-bold">{dbt(laboratories, pdc[key.labId][key.id].laboratory_id, language.id, "name")}</td> }
                                            <td className="text-primary fw-bold">{Intl.NumberFormat(authUser.locale).format(pdc[key.labId][key.id].id)}</td>
                                            <td>{pdc[key.labId][key.id].name}</td>
                                            <td>
                                                {Object.values(pdcStatus).includes(pdc[key.labId][key.id].status) &&
                                                    <Badge bg={statusVariantMap[pdc[key.labId][key.id].status]} className={pdc[key.labId][key.id].status}>
                                                        <span>{dbt(pdcStatusList, pdc[key.labId][key.id].status, language.id, "label")}</span>
                                                    </Badge>
                                                }
                                            </td>
                                            <td>{dbt(companies, pdc[key.labId][key.id].company_id, language.id, "name")}</td>
                                            {userControlPlanAdmin () &&
                                                <td>{userControlPlanAdmin(Number(key.labId), authUser) ? new Intl.DateTimeFormat(authUser.locale).format(new Date((pdc[key.labId][key.id].last_date))):''}</td>
                                            }
                                            <td>{new Intl.DateTimeFormat(authUser.locale).format(new Date((pdc[key.labId][key.id].start_date)))}</td>
                                            <td>{new Intl.DateTimeFormat(authUser.locale).format(new Date((pdc[key.labId][key.id].end_date)))}</td>
                                            <td>{pdc[key.labId][key.id].billable ? <CheckedIcon width="24px" height="24px" className="align-text-top"/> : <UncheckedIcon width="24px" height="24px" className="align-text-top"/>}</td>
                                            <td>{pdc[key.labId][key.id].nb_sample}</td>
                                            <td>{pdc[key.labId][key.id].nb_used}</td>


                                            <td>{pdc[key.labId][key.id].nb_test}</td>
                                            <td>
                                                {pdc[key.labId][key.id].total_price !== null ? Intl.NumberFormat(authUser.locale, {style: 'currency', currency: pdc[key.labId][key.id].control_plan_samples && pdc[key.labId][key.id].control_plan_samples!.length > 0 ? pdc[key.labId][key.id].control_plan_samples![0].currency : laboratories[pdc[key.labId][key.id].laboratory_id].currency}).format((Number(pdc[key.labId][key.id].total_price) !== undefined ? Number(pdc[key.labId][key.id].total_price): 0)) : ''}</td>
                                            
                                            {userGrantControlPlanAdmin(undefined, authUser) && userIsInternal() &&
                                                <td>{pdc[key.labId][key.id].total_cost !== null ?  userGrantControlPlanAdmin(Number(key.labId), authUser) ? Intl.NumberFormat(authUser.locale, {style: 'currency', currency: laboratories[pdc[key.labId][key.id].laboratory_id].currency}).format((Number(pdc[key.labId][key.id].total_cost) !== undefined ? Number(pdc[key.labId][key.id].total_cost): 0)):'' : ''}</td>
                                            }
                                            <td className="text-nowrap">
                                                <Button className="p-0 pe-1 text-primary" variant="link" onClick={(e) => { e.stopPropagation(); history.push(`/secure/pdc/${pdc[key.labId][key.id].laboratory_id}/${pdc[key.labId][key.id].id}`) }} >
                                                    <SeeIcon width="24px" height="100%" className="align-text-top" />
                                                </Button>
                                                {(
                                                    (['created'].includes(pdc[key.labId][key.id].status) && userGrantControlPlanAdmin(Number(key.labId), authUser))
                                                    ||
                                                    (['published'].includes(pdc[key.labId][key.id].status) && userGrantControlPlanAdmin(Number(key.labId), authUser))
                                                    
                                                ) &&
                                                    <Button className="p-0 pe-1 text-secondary" variant="link" onClick={(e) => { e.stopPropagation(); history.push(`/secure/pdc/${pdc[key.labId][key.id].laboratory_id}/${pdc[key.labId][key.id].id}?edit=true`) }} >
                                                        <EditIcon width="24px" height="100%" className="align-text-top" />
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                        
                                    )
                            }
                        })}


                    </tbody>
                </Table>
            </Container>
        
            <CustomPagination 
                nbPages={pagination.nbPage}
                curPage={pagination.currentPage}
                nbNeighbours={1}
                curPageSize={pagination.nbByPage}
                allowedPageSizes={allowedPageSizes}
                goToPage={(page) => { dispatch(fetchPdc({page}))}}
                setPageSize={(nbPage) => { dispatch(setNbByPage(nbPage)); dispatch(fetchPdc({page: 1}))}}
            />

        </>
    )

}

export default withTranslation()(ControlPlanList)