import React from 'react';

interface LoaderProps {
    isInner?: boolean
}

const Loader: React.FC<LoaderProps> = ({isInner = false}) => (
    <div className={"text-center first-plan " + (isInner ? "loader-container-wrapper" : "loader-wrapper")}>
        <span className="spinner-border" role="status" aria-hidden="true" />
    </div>
)

export default Loader
