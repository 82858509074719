import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { Link } from "react-router-dom";
import useReduxFilter from '../utils/useReduxFilter';
import MatrixImport from '../../pages/matrix/MatrixImport';
import { resetMatrixFields } from '../../state/dashboardSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../state/store';

interface ButtonProps {
    key: string
    link?: string
    filterReducer?: (filter: any) => void
    filter?: any
    color?: string
    hidden?: boolean
    uploadMatrix? : boolean
    resetMatrixFields? : boolean
}

interface NavBoxProps {
    label: string
    buttons: Array<ButtonProps>
    nbButtonMaxPLine?: number
}

const NavBox: React.FC<NavBoxProps> = ({label, buttons, nbButtonMaxPLine = 3}) => {

    const { goToWithFilter } = useReduxFilter()
    const dispatch = useDispatch<AppDispatch>();

    return (
        <Container className="p-0 navbox h-100 text-center align-items-center" >
            <Container className={buttons.length > nbButtonMaxPLine ? "p-0 pt-3 pt-lg-4 pb-3" : "p-0 pt-3 pt-lg-4 pb-3 pb-lg-4"} >
                <Row className="label mx-auto">
                    <Col>{label}</Col>
                </Row>
                <Row className="mx-auto">
                    {buttons.filter( h=> h.hidden != true).map(button => {
                        return (
                            <>
                                {!button.uploadMatrix && button.link &&
                                    <Col className="mx-auto mt-3" lg={buttons.length > nbButtonMaxPLine ? 6 : true} key={button.key}>
                                        {button.filter && button.filterReducer &&
                                            <Button onClick={() => {
                                                goToWithFilter(button.link as string, button.filter!, button.filterReducer!)
                                            }} variant={button.color ? button.color : "secondary"} className={buttons.length > 2 && buttons.length > nbButtonMaxPLine ? "mx-auto text-nowrap w-100" : "mx-auto text-nowrap"}>{button.key}</Button>
                                        }
                                        {(!button.filter || !button.filterReducer) &&
                                            <Link onClick={() => {
                                                if(button.resetMatrixFields) {
                                                    dispatch(resetMatrixFields())
                                                }
                                            }} to={button.link as string} className={"btn btn-" + (button.color ? button.color : "secondary") + " mx-auto text-nowrap " + (buttons.length > 2 && buttons.length > nbButtonMaxPLine ? "w-100" : "")}>{button.key}</Link>
                                        }
                                    </Col>
                                }

                                {button.uploadMatrix &&
                                     <Col className="mx-auto mt-3" lg={buttons.length > nbButtonMaxPLine ? 6 : true} key={button.key}>
                                        <MatrixImport type="button"></MatrixImport>
                                    </Col>
                                }
                            </>
                        )
                    })}
                </Row>
            </Container>
        </Container>
    )
}

export default NavBox