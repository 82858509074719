import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Row, Container, Table, Form, Col } from 'react-bootstrap';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';
import { setSamplesFilterBy, setSamplesSortBy, selectUnselectLinesOfSample, selectUnselectResultOfSample, setDataOfAnalytes } from '../../../state/dashboardSlice';
import { DatasCalculated, calculateAll } from './utils';
import { Data } from '../../../types/types';
import { ScrollSyncNode } from 'scroll-sync-react'
import { Matrix } from '../../../api/matrixApi';

interface DashboardSelectionComponentContainerProps extends WithTranslation {
    dashboardName: string
    matrice: Matrix

}

const DashboardSelectionComponent: React.FC<DashboardSelectionComponentContainerProps> = ({ t, dashboardName, matrice }) => {
    const dispatch = useDispatch()

    // user state
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    // languages
    const languages = useSelector((state: RootState) => state.config.languages.data)
    const language = Object.values(languages).find(lg => lg.id === Number(authUser.language_id))

    // samples state
    const samplesForData = useSelector((state: RootState) => state.dashboard.samplesForData)
    const resultsHeader = useSelector((state: RootState) => state.dashboard.resultsHeaders)
    const pagination = useSelector((state: RootState) => state.dashboard.paginationSamples)
    const paramsFromComponents = useSelector((state: RootState) => state.dashboard.paramsFromComponents)
    const fieldsForSamplesInfo = useSelector((state: RootState) => state.dashboard.fieldsForSamplesInfo)

    const resultsFields = () => {
        const fields = {
            former_matrix: {
                type: [],
                sortable: false
            }
        }
        resultsHeader.forEach(resultHeader => {
            Object.assign(fields, { [resultHeader.name.translations[language && language.code_language ? language.code_language : '']]: { type: [], sortable: false, noTrad: true } })
        })
        fieldsForSamplesInfo.forEach(field => {
            Object.assign(fields, { [field.field]: { type: [], sortable: false, noTrad: false } })
        })
        return fields
    }

    const { ListHeader, filterAndSortList, FilterList } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "dashboard.former_matrix.columns.",
        onlyActive: false,
        size: 'xs',
        fields: resultsFields(),
        reducers: {
            filterBy: setSamplesFilterBy,
            sortBy: setSamplesSortBy
        }
    })

    useEffect(() => {
        if (Object.values(samplesForData).length > 0) {
            dispatch(setDataOfAnalytes(calculateAll(samplesForData, resultsHeader, paramsFromComponents.date, dashboardName)))
        }
    }, [samplesForData, resultsHeader])

    return (
        <Container className={'mt-2 p-0 ms-0 me-0'}>
            <Container className="p-2 bg-white rounded-3 mb-0">
                <Row>
                    <ScrollSyncNode group="horiz-scroll">
                        <Container className='ms-2 me-2 p-0 noshrink' style={{ overflow: "auto" }}>
                            <Table key='former_matrix' striped hover size='sm' className="bg-white text-center table-fixed table-xs mb-0">
                                <thead className='bg-white'>
                                    <tr>
                                        <th className="p-0" />
                                        <th className="p-1 fixed-width">
                                            <ListHeader name='former_matrix' />
                                        </th>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <th className="p-1" key={`header-${header.id}`}>
                                                    <ListHeader name={header.name.translations[language && language.code_language ? language.code_language : '']} addClassToButton="add-pad" />
                                                </th>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return (
                                                <th className="p-1" key={`selection-${field.field}`}>
                                                    <ListHeader name={field.field} addClassToButton="add-pad-no-sort" />
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key="matrix">
                                        <td className="p-1 table-hover-hint" />
                                        <td className='text-primary fw-bold pe-1' >{'V' + matrice.version}</td>
                                        {resultsHeader.length > 0 && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                                            return (
                                                <td className='ps-1 pe-1' key={`former_matrix-${header.id}`}>
                                                    {matrice.results && matrice.results.length > 0 ? 
                                                        matrice.results.find((r) => r && r.analyte_id.toUpperCase() === header.id.toUpperCase())?.ai_result !== "" ?
                                                        matrice.results.find((r) => r && r.analyte_id.toUpperCase() === header.id.toUpperCase())?.ai_result : "-"
                                                        : "-"}
                                                </td>
                                            )
                                        })}
                                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                                            return (
                                                <td></td>
                                            )
                                        })}
                                    </tr>
                                    
                                </tbody>
                            </Table>

                           

                        </Container>
                    </ScrollSyncNode>
                </Row>
            </Container>
        </Container>
    )
}

export default withTranslation()(DashboardSelectionComponent)