import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import ApiError from '../common/utils/exceptions/ApiError';

import { Data, Pagination } from '../types/types';
import MatrixApi, { MatrixRequestImport, MatrixRequestExport, MatrixResponse, StatusResponse } from '../api/matrixApi';

interface CurrentState {
    data: Data<MatrixResponse>
    dataStatus: Data<StatusResponse>

    byLabId: Data<Array<string>>
    filtered: Array<string>
    pagination: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    fetchStatusOfGetStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchErrorOfGetStatus: string | undefined
    fetchStatusOfDownload: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchErrorOfDownload: string | undefined
    fetchAllStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchAllError: string | undefined
    filterStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postError: string | undefined
    postJob: 'idle' | 'loading' | 'succeeded' | 'failed'
    postJobError: string | undefined
}

const initialState = {
    data: {},
    dataStatus:{},
    byLabId: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    fetchStatus: 'idle',
    fetchError: undefined,
    fetchStatusOfGetStatus: 'idle',
    fetchErrorOfGetStatus: undefined,
    fetchStatusOfDownload: 'idle',
    fetchErrorOfDownload: undefined,
    fetchAllStatus: 'idle',
    fetchAllError: undefined,
    filterStatus: 'idle',
    singleFetchStatus: 'idle',
    singleFetchError: undefined,
    postStatus: 'idle',
    postError: undefined,
    postJob: 'idle',
    postJobError: undefined
} as CurrentState

export const postMatrixJob = createAsyncThunk(
    'matrixJob/postMatrixJob', 
    async (request: MatrixRequestImport | MatrixRequestExport | FormData, {rejectWithValue}) => {
        try {
            const response = await MatrixApi.getInstance().postMatrixJob(request)
            return response as MatrixResponse
        } catch(err) {
            if (err instanceof ApiError) {
                return rejectWithValue({...err, message: err.message})
            }
            throw err
        }
    }
)

export const fetchMatrixJobStatus = createAsyncThunk(
    'matrixJobs/fetchMatrixJobStatus', 
    async (jobId:string, {rejectWithValue}) => {
        try {
            const response = await MatrixApi.getInstance().getMatrixJobStatus(jobId)
            return response as StatusResponse
        }
        catch (err){
            if (err instanceof ApiError) {
                return rejectWithValue({...err, message: err.message})
            }
            throw err
        }

        }
)

export const fetchDownloadMatrixJob = createAsyncThunk(
    'exportExcel/fetchDownloadExcel', 
    async (exportStatus: StatusResponse, {rejectWithValue}) => {
        try {
            await MatrixApi.getInstance().downloadMatrixJob(exportStatus.id, exportStatus.url, (exportStatus.config as MatrixRequestExport).output.export_name)
            
            return exportStatus.id
        } catch(err) {
            if (err instanceof ApiError) {
                return rejectWithValue({...err, message: err.message})
            }
            throw err
        }
    }
)

export const fetchCancelMatrixJob = createAsyncThunk(
    'matrixjobs/fetchCancelMatrixJob', 
    async (jobId: string, {rejectWithValue}) => {
        try {
            await MatrixApi.getInstance().cancelMatrixJob(jobId)
            
            return jobId
        } catch(err) {
            if (err instanceof ApiError) {
                return rejectWithValue({...err, message: err.message})
            }
            throw err
        }

    }
)

export const fetchAllMatrixJobs = createAsyncThunk(
    'matrixJobs/fetchAllMatrixJobs', 
    async (arg, {rejectWithValue}) => {
        try {
            const response = await MatrixApi.getInstance().getAllMatrixJobs()
            
            return response
        } catch(err) {
            if (err instanceof ApiError) {
                return rejectWithValue({...err, message: err.message})
            }
            throw err
        }

    }
)



const matrixJobsSlice = createSlice({
    name: 'matrixJobs',
    initialState,
    reducers: {
        matrixJobReducer(state: CurrentState, action: PayloadAction<MatrixResponse>) {
            state.data[action.payload.id] = action.payload
            state.dataStatus[action.payload.id] = {... action.payload, progress: 0, results_details: []}
        },
    },
    extraReducers: builder => {
          // Languages API call reducers
          builder.addCase(postMatrixJob.pending, (state) => {
            state.postJob = 'loading'
        })
        builder.addCase(postMatrixJob.fulfilled, (state, action) => {
            state.data[action.payload.id] = action.payload
            state.dataStatus[action.payload.id] = {... action.payload, progress: 0, results_details: []}

            state.postJob = 'succeeded'
        })
        builder.addCase(postMatrixJob.rejected, (state, action) => {
            state.postJob = 'failed'
            state.postJobError = action.error.message
        })

            // Languages API call reducers
            builder.addCase(fetchMatrixJobStatus.pending, (state) => {

                state.fetchStatusOfGetStatus = 'loading'
            })
            builder.addCase(fetchMatrixJobStatus.fulfilled, (state, action) => {
                if (action.payload.status !== 'cancelled'){
                    state.dataStatus[action.payload.id] = action.payload
                }
                state.fetchStatusOfGetStatus = 'succeeded'
            })
            builder.addCase(fetchMatrixJobStatus.rejected, (state, action) => {
                state.fetchStatusOfGetStatus = 'failed'
                state.fetchErrorOfGetStatus = action.error.message
            })

            builder.addCase(fetchDownloadMatrixJob.fulfilled, (state, action) => {

                delete state.dataStatus[action.payload]
                delete state.data[action.payload]

                state.fetchStatusOfDownload = 'succeeded'
            })

            
            builder.addCase(fetchDownloadMatrixJob.pending, (state) => {
                state.fetchStatusOfDownload = 'loading'
           
            })
    

            
            builder.addCase(fetchDownloadMatrixJob.rejected, (state, action) => {
                state.fetchStatusOfDownload = 'failed'
                state.fetchErrorOfDownload = action.error.message
            })

            builder.addCase(fetchCancelMatrixJob.fulfilled, (state, action) => {
                // action.payload = id

                delete state.dataStatus[action.payload]
                delete state.data[action.payload]

                state.fetchStatusOfGetStatus = 'succeeded'
            })

            
            builder.addCase(fetchCancelMatrixJob.pending, (state) => {
                state.fetchStatusOfGetStatus = 'loading'
            })
    

            
            builder.addCase(fetchCancelMatrixJob.rejected, (state, action) => {

                state.fetchStatusOfGetStatus = 'failed'
                state.fetchErrorOfGetStatus = action.error.message
            })

            builder.addCase(fetchAllMatrixJobs.fulfilled, (state, action) => {
                state.data = {}
                state.dataStatus = {}
                action.payload.forEach(
                    exportJob => {
                        if (!['cancelled','cancelling', 'failed'].includes(exportJob.status)){
                            state.data[exportJob.id] = exportJob
                        }
                    }
                )

                state.fetchAllStatus = 'succeeded'
            })

            builder.addCase(fetchAllMatrixJobs.pending, (state) => {

                state.fetchAllStatus = 'loading'
            })
          
            builder.addCase(fetchAllMatrixJobs.rejected, (state, action) => {
                state.fetchAllStatus = 'failed'
                state.fetchAllError = action.error.message          
            })
    }
  })

  export const {
    matrixJobReducer: exportExcelReducer
  } = matrixJobsSlice.actions

  export default matrixJobsSlice.reducer