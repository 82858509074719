import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import dbt from '../../../../../common/utils/dbTranslation';
import userHasRequiredRoles from '../../../../../common/auth/userHasRequiredRoles'

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../state/rootReducer';
import { analysisReducer, StatusAnalysis } from '../../../../../state/analysisSlice';
import AnalysisApi, { Analysis, AnalysisPackage, AnalysisPackageTests } from '../../../../../api/analysisApi';

import { Row, Col, Button, Container, Form, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import AnalysisImproperForm from '../components/AnalysisImproperForm';

import StatusBar from '../../../../../common/components/StatusBar';
import GoBack from '../../../../../common/components/GoBack';

import { ReactComponent as EditIcon } from 'bootstrap-icons/icons/pencil-square.svg'
import getResults from '../../../../../common/utils/getResults';

import PrintLabelButton from '../components/PrintLabelButton';
import ControlPlanApi from '../../../../../api/controlPlanApi';
import { pdcReducer } from '../../../../../state/pdcSlice';
import PcpwComponent from '../../../../../common/components/PcpwComponent';
import pcpwUtils from '../../../../../common/utils/pcpwUtils';
import { TupleId } from '../../../../../types/types';
import { getNirId } from "../../../../../api/externalServicesApi";

import CustomField from '../components/CustomField';
import FormCheckBoxUrgent from '../components/FormCheckboxUrgent'

import ResultsTable from '../../../../../common/components/ResultsTable';
import GoBackButton from '../../../../../common/components/GoBackButton';
import useSecurityRoles from '../../../../../common/auth/useSecurityRoles';
import { printPotentialNumber } from '../../../../../common/utils/utils';
import { getAnalyteKey } from '../../../../../api/configApi';
import PricesCalculator from '../../../../pdc/helpers/PricesCalculator';
import { PatternPackageTestUtils } from '../../../../pdc/helpers/PatternPackageTestsUtils';

interface ConsultAnalysisContainerProps extends WithTranslation {
    analysisDB: Analysis,
}

const ConsultAnalysis: React.FC<ConsultAnalysisContainerProps> = ({ t, analysisDB }) => {

    const dispatch = useDispatch()
    const { userGrantUpdateRegistered, userGrantUpdateReceived, userGrantUpdateDone, userGrantLaboratory, userGrantLaboratoryAdmin, userHasRoleClient, userHasRoleUser, userHasRoleCancel, userHasRoleAll } = useSecurityRoles()

    const { typeLabels } = useSelector((state: RootState) => state.local)

    // user state
    const authUser = useSelector((state: RootState) => state.authUser.user)

    // fields state
    const fields = useSelector((state: RootState) => state.fields.data)
    
    // forms state
    const forms = useSelector((state: RootState) => state.forms.data)

    // pdc state
    const controlPlans = useSelector((state: RootState) => state.pdc.data)

    // patterns state
    const patterns = useSelector((state: RootState) => state.config.patterns.data)

    // languages state
    const language = useSelector((state: RootState) => state.local.language)

    //  Laboratories state
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)
    const hasMultipleLabs: boolean = authUser.laboratories.length > 1 || userHasRequiredRoles(['MyProvilab_ROLE_usersAdmin'])

    // types state
    const types = useSelector((state: RootState) => state.config.types.data)
    // families state
    const families = useSelector((state: RootState) => state.config.families.data)
    
    // Package state
    const packages = useSelector((state: RootState) => state.config.packages.data)
    const packagesStatus = useSelector((state: RootState) => state.config.packages.fetchStatus)

    // Analyte state
    const analytes = useSelector((state: RootState) => state.analytes.data)
    const analyteStatus = useSelector((state: RootState) => state.analytes.fetchStatus)

    // Companies state
    const companies = useSelector((state: RootState) => state.companies.data)

    // Employees state
    const employees = useSelector((state: RootState) => state.employees.data)

    // Nirs state
    const nirs = useSelector((state: RootState) => state.nir.data)

    // Impropers state
    const impropers = useSelector((state: RootState) => state.config.impropers.data)

    const analysis = useSelector((state: RootState) => state.analysis.analysis.data)

    const [apiStatusError, setApiStatusError] = useState(undefined)

    const [packageWithTests, setpackageWithTests] = useState<AnalysisPackage[]>([]);
    
    const [canUpdate, setCanUpdate] = useState(false)

    const grantUpdateRegistered = userGrantUpdateRegistered(analysisDB.laboratory_id, authUser)

    const grantUpdateReceived =userGrantUpdateReceived(analysisDB.laboratory_id, authUser)
    const grantUpdateDone = userGrantUpdateDone(analysisDB.laboratory_id, authUser)
    const grantLaboratory = userGrantLaboratory(analysisDB.laboratory_id, authUser)
    const grantLaboratoryAdmin = userGrantLaboratoryAdmin(analysisDB.laboratory_id, authUser)
    const hasRoleClient = userHasRoleClient(analysisDB.laboratory_id, authUser)
    const hasRoleUser = userHasRoleUser(analysisDB.laboratory_id, authUser)
    const hasRoleCancel = userHasRoleCancel(analysisDB.laboratory_id, authUser)

    const [plb, setPlb] = useState(false);

    const customForm = Object.values(forms).find(f => f.laboratory_id === analysisDB.laboratory_id && f.type_code === analysisDB.type_code)

    let flattenFields: number[] = []
    if (customForm) {
        // Flatten form's fields to initialize missing values in further steps
        const flattenClientFields = customForm.fields.client.map(line => line.filter(col => fields[col.field_id].storage_type === "dynamic"))
            .reduce<number[]>((acc, cur) => {
                const fieldIds = cur.map(c => c.field_id)
                return acc.concat(fieldIds)
            }, [])
        const flattenSampleFields = customForm.fields.sample.map(line => line.filter(col => fields[col.field_id].storage_type === "dynamic"))
            .reduce<number[]>((acc, cur) => {
                const fieldIds = cur.map(c => c.field_id)
                return acc.concat(fieldIds)
            }, [])
        flattenFields = flattenClientFields.concat(flattenSampleFields)
    }

    const formikProps = useFormik<Analysis>({
        initialValues: {
            ...analysisDB,
            analysis_packages: analysisDB.analysis_packages.map(
                ap => ({
                    ...ap,
                    analysis_package_tests: [...ap.analysis_package_tests]
                        .sort((a: AnalysisPackageTests, b: AnalysisPackageTests) => 
                            (PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup( {...a, laboratory_id: analysisDB.laboratory_id}, analytes, language, false)).localeCompare(PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup( {...b, laboratory_id: analysisDB.laboratory_id}, analytes, language, false)))

                })
            ), 
            custom_values: analysisDB.custom_values.concat(
                flattenFields.filter(fieldId => !analysisDB.custom_values.map(cv => cv.field_id).includes(fieldId))
                .map(fieldId => (
                    { field_id: fieldId, value: null }
                ))
            )
        },
        onSubmit: () => undefined
    })

    const { values, setValues } = formikProps

    useEffect(()=> {
        if (values.status === StatusAnalysis.partial || values.status === StatusAnalysis.done) {
            AnalysisApi.getInstance().getResultsByAnalysisId(values.laboratory_id, values.id, false, true, false).then((keys) => {
                if (keys.files_tab.length > 0) {
                    setPlb(true);
                } else {
                    setPlb(false);
                }
            }).catch(() => {
                setPlb(false);
            });
        }
        if (values) {
            const isUpdateAllowed = (
                grantUpdateRegistered && 
                values.status === StatusAnalysis.registered
            ) || (
                grantUpdateRegistered && 
                values.status === StatusAnalysis.received_improper &&
                values.improper_id && impropers[values.improper_id].editable
            ) || (
                grantUpdateReceived && (
                    values.status === StatusAnalysis.received_compliant ||
                    values.status === StatusAnalysis.received_improper
                )
            ) || (
                grantUpdateDone  && (
                    values.status === StatusAnalysis.partial ||
                    values.status === StatusAnalysis.done
                )
            )
            setCanUpdate(isUpdateAllowed)
        }
    }, [values, grantUpdateDone, grantUpdateReceived, grantUpdateRegistered])

    useEffect(() => {
        if (packagesStatus === 'succeeded' && analyteStatus === 'succeeded') {
            const packageWithTestsTmp: AnalysisPackage[] = []
            for (const Apackage of values.analysis_packages) {
                const testsInAnalysis: AnalysisPackageTests[] = []
                for (const Atest of Apackage.analysis_package_tests) {
                    testsInAnalysis.push({ ...Atest, ...{ name: PatternPackageTestUtils.getAnalyteNameOrTranslationOrGroup({...Atest, laboratory_id: values.laboratory_id}, analytes, language, false) }, ...{ theoreticalVal: Atest.theoretical_value } })
                }
                packageWithTestsTmp.push({ ...Apackage, ...{ name: dbt(packages, Apackage.package_id, language.id, "name") }, ...{ comment: Apackage.comment != null ? Apackage.comment : "" }, ...{ analysis_package_tests: testsInAnalysis } })
            }
            setpackageWithTests(packageWithTestsTmp)
        }
    }, [ analyteStatus, packagesStatus, dispatch, values.analysis_packages, packages, language.id, analytes])

    const [show, setShow] = useState(false);
    const [showRegistered, setShowRegistered] = useState(false);
    const [showDeleteResults, setShowDeleteResults] = useState(false);

    const [showPopupCancel, setShowPopupCancel] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseRegistered = () => setShowRegistered(false);
    const handleShowRegistered = () => setShowRegistered(true);
    const handleCloseDeleteResults = () => setShowDeleteResults(false);
    const handleShowDeleteResults = () => setShowDeleteResults(true);

    const [showError, setShowError] = useState(false);
  
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const [msgError, setMsgError] = useState("");

    const updateStatus = async (status: StatusAnalysis): Promise<void> => {        
        try {
            const analysisRep = await AnalysisApi.getInstance().updateStatusAnalysis(status, values.laboratory_id, values.id)

            if (Object.prototype.hasOwnProperty.call(analysisRep, "error")) {
                setApiStatusError(t('forms_errors.unexpected_error'))
            } else {
                //update local state 
                const analysisData: Analysis = { ...values }
                analysisData.last_update = analysisRep.last_update
                analysisData.status = analysisRep.status
                analysisData.cancellation_date = analysisRep.cancellation_date !== undefined ? analysisRep.cancellation_date : null
                analysisData.conformity_date = analysisRep.conformity_date !== undefined ? analysisRep.conformity_date : null
                analysisData.receipt_date = analysisRep.receipt_date !== undefined ? analysisRep.receipt_date : null
                analysisData.validation_date = analysisRep.validation_date !== undefined ? analysisRep.validation_date : null
                setValues(analysisData)

                //dispatch analysis in root state analsyis
                const analysisdispatch: Analysis = { ...analysisDB }
                analysisdispatch.last_update = analysisRep.last_update
                analysisdispatch.status = analysisRep.status
                analysisdispatch.cancellation_date = analysisRep.cancellation_date !== undefined ? analysisRep.cancellation_date : null
                analysisdispatch.conformity_date = analysisRep.conformity_date !== undefined ? analysisRep.conformity_date : null
                analysisdispatch.receipt_date = analysisRep.receipt_date !== undefined ? analysisRep.receipt_date : null
                analysisdispatch.validation_date = analysisRep.validation_date !== undefined ? analysisRep.validation_date : null
                
                
                dispatch(analysisReducer(analysisdispatch))
                if (analysisDB.id_pdc && analysisDB.id_sample) {
                    const pdc = await ControlPlanApi.getInstance().getPdcById(analysisDB.id_pdc, analysisDB.laboratory_id)
                    dispatch(pdcReducer(pdc))
                }
                setApiStatusError(undefined)
                return
            }
        } catch(err) {
            setApiStatusError(t('forms_errors.unexpected_error'))
            return
        }

    }

    const deleteGlimsResults = async (analysis_id : number, laboratory_id : number) => {
        try {
            await AnalysisApi.getInstance().deleteGlimsResults(laboratory_id, analysis_id)
            const analysisdispatch: Analysis = await AnalysisApi.getInstance().getAnalysisById(laboratory_id, analysis_id);
            dispatch(analysisReducer(analysisdispatch));
            setValues(analysisdispatch);
        } catch {
            setApiStatusError(t('forms_errors.unexpected_error'))
            return
        }
    }

    const familieInitValue = {
        id: values.family_id,
        comment: families[values.family_id] !== undefined ? dbt(families,values.family_id, language.id, "comment") : "",
        detailsRequired: families[values.family_id] !== undefined ? families[values.family_id].details_required : false,
        name: families[values.family_id] !== undefined ? dbt(families, values.family_id, language.id, "name") : ""
    }

    const patternInitValue = {
        id: values.pattern_id,
        option: patterns[values.pattern_id] !== undefined ? dbt(patterns, values.pattern_id, language.id, "option") : false,
        name: patterns[values.pattern_id] !== undefined ? dbt(patterns, values.pattern_id, language.id, "name") : "",
        price: values.price,
        currency: values.currency,
        cost: values.cost,
        period: values.period,
        weight: values.weight
    }

    const analysisInitValue = {
        denomination: values.denomination,
        client_reference: values.client_reference,
        comment: values.comment,
        sample_date: values.sample_date && values.sample_date != null ? new Intl.DateTimeFormat(authUser.locale).format(new Date(values.sample_date)) : "",
        family_details: values.family_details,
        employee_id: values.employee_id,
        company_id: values.company_id,
        price: values.price,
        currency: values.currency,
        cost: values.cost,
        period: values.period,
        weight: values.weight,
        total_price: values.total_price,
        total_weight: values.total_weight,
        max_period: values.max_period,
        urgent: values.urgent

    }

    const getResultsFormatting = (checkedAnalysis: TupleId[], withPdf: boolean, withPlb: boolean) => {
        return async () => {
            if(checkedAnalysis.length === 0){
                setMsgError(t('analysis.download_error_checked_msg'))
                handleShowError()
            }else{
                try {
                    await getResults(checkedAnalysis, withPdf, withPlb)
                } catch(err) {
                    setMsgError(t("forms_errors.unexpected_error"))
                    handleShowError()
                }
            }
            handleClose()
        }
    }

    return (
        <Container className={values.status == 'done' || values.status == 'partial' ? 'mt-5 p-0 result-consult-container' : 'mt-5 p-0'}>
            <Modal size="lg" show={showError}  onHide={handleCloseError}>
                <Modal.Header>
                    <Modal.Title><span className="text-primary fw-bold"> {t('error')} </span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{msgError}</p>
                </Modal.Body>
            </Modal>

            
            <Modal className="no-print" size="lg" show={showPopupCancel} onHide={() => setShowPopupCancel(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('forms.analysis.question_cancel_da')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="m-2">
                        {t('forms.analysis.message_cancel_da')}
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button className="m-1 no-print" variant="secondary" onClick={() => { setShowPopupCancel(false) }} >{t('forms.analysis.go_back')}</Button>
                        </Col>
                        <Col className="text-center"> 
                            <Button className="m-1 no-print" variant="danger" onClick={() => { updateStatus(StatusAnalysis.canceled); setShowPopupCancel(false) }} >{t('forms.analysis.cancel_da')}</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal className="no-print" size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('forms.analysis.impropers.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AnalysisImproperForm analysis={values} close={handleClose} setAnalysis={setValues} />
                </Modal.Body>
            </Modal>

            <Modal className="no-print" size="lg" show={showRegistered} onHide={handleCloseRegistered}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('forms.analysis.registered_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="m-2">
                        {t('forms.analysis.registered_message')}
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button className="m-1 no-print" variant="secondary" onClick={() => { setShowRegistered(false) }} >{t('forms.analysis.go_back')}</Button>
                        </Col>
                        <Col className="text-center"> 
                            <Button className="m-1 no-print" variant="danger" onClick={() => { updateStatus(StatusAnalysis.registered); setShowRegistered(false) }} >{t('analysis.rollback_registered')}</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal className="no-print" size="lg" show={showDeleteResults} onHide={handleCloseDeleteResults}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('forms.analysis.delete_results_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="m-2">
                        {t('forms.analysis.delete_results_message')}
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button className="m-1 no-print" variant="secondary" onClick={() => { setShowDeleteResults(false) }} >{t('forms.analysis.go_back')}</Button>
                        </Col>
                        <Col className="text-center"> 
                            <Button className="m-1 no-print" variant="danger" onClick={() => { deleteGlimsResults(analysisDB.id, analysisDB.laboratory_id) ; setShowDeleteResults(false) }} >{t('analysis.delete_glims_results')}</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        
            <Container className="p-0">
                <Row className="no-print">
                    <Col xs="auto">
                        <GoBackButton label={t('analysis.return_to_my_list_analysis')} />
                    </Col>
                </Row>
                <Row className="pt-2 " >
                    <Col>
                        <h4 className="pb-2 fw-bold">
                            {`${t('forms.analysis.analysis_request')} ${t('forms.analysis.' + typeLabels[values.type_code])}`}
                            <span className="text-primary fw-bold">
                                {` ${t('forms.analysis.number')}${printPotentialNumber(values.id, values.display_id, authUser)}${values.id_pdc !== null || (values.additional_analysis && values.additional_analysis[0] && values.additional_analysis[0].ref_laboratory_id && values.additional_analysis[0].ref_analysis_id) ? " - " : ''}`}
                            </span>
                            {` ${values.id_pdc !== null ? t('pdc.pdc') : '' }`}
                            {` ${values.additional_analysis && values.additional_analysis[0] && values.additional_analysis[0].ref_laboratory_id && values.additional_analysis[0].ref_analysis_id ? t('forms.analysis.cargill_analysis') : '' }`}
                            <span className="text-primary fw-bold">
                                {` ${values.id_pdc !== null ? controlPlans[values.laboratory_id][values.id_pdc].name : ''}`}
                                {values.additional_analysis && values.additional_analysis[0] && values.additional_analysis[0].ref_laboratory_id && values.additional_analysis[0].ref_analysis_id ?
                                        <Link className="text-decoration-none text-primary fw-bold" to={`/secure/analysis/${values.additional_analysis[0].ref_laboratory_id}/${values.additional_analysis[0].ref_analysis_id}`}>
                                            {` ${t('forms.analysis.number')}${Intl.NumberFormat(authUser.locale).format(values.additional_analysis[0].ref_analysis_id)}`}
                                        </Link>
                                    : ''
                                }
                            </span>
                        </h4>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <StatusBar
                        Date1={values.creation_date != null ? new Date(values.creation_date).toString() : undefined}
                        Date2={values.last_update != null ? new Date(values.last_update).toString() : undefined}
                        Date3={values.receipt_date != null ? new Date(values.receipt_date).toString() : undefined}
                        Date4={values.conformity_date != null ? new Date(values.conformity_date).toString() : undefined}
                        Date5={values.receipt_glims_date != null ? new Date(values.receipt_glims_date).toString() : undefined}
                        Date6={values.validation_date != null ? new Date(values.validation_date).toString() : undefined}
                        Date7={values.cancellation_date != null ? new Date(values.cancellation_date).toString() : undefined}
                        Status={values.status} />
                </Row>
                {values.status === StatusAnalysis.received_improper &&
                    <Row className="pt-2 pb-2  " >
                        <Col className="d-flex">
                            <h4 className="text-break fw-bold d-flex">{t('forms.analysis.impropers.title')} :&nbsp;<span className="text-break text-danger fw-bold">{values.improper_id ? dbt(impropers, values.improper_id, language.id, "improper") : ""}{values.comment_improper !== undefined ? ` (${values.comment_improper})` : ``}</span>  </h4>
                        </Col>
                    </Row>
                }
            </Container>
            
            <Container className={values.status == 'done' || values.status == 'partial' ? 'result-consult-container' : ''}>
                <Row>
                    <Col lg={values.status == 'done' || values.status == 'partial' ? 3 : 12}>
                        <Container className="p-2 bg-white mb-2 rounded-3">
                            <Row >
                                <Col lg>
                                    <h4 className="text-primary fw-bold">
                                        {(() => {
                                            if (hasMultipleLabs) {
                                                return values.status == 'done' || values.status == 'partial' ? `${t('forms.analysis.laboratory')} - ${laboratories[values.laboratory_id].name}` : `${t('forms.analysis.laboratory')} - ${laboratories[values.laboratory_id].name}  |   `
                                            } else {
                                                return ""
                                            }
                                        })()}
                                        {(values.status == 'done' || values.status == 'partial') && <><br></br></>}
                                        {t('forms.analysis.customer')} - {companies[values.company_id] ? companies[values.company_id].name : ""}
                                        {values.nir_code && nirs[getNirId({laboratory_id: values.laboratory_id, nir_code: values.nir_code})] &&
                                            <>
                                                {`  |   ${t('forms.analysis.nir')} - ${nirs[getNirId({laboratory_id: values.laboratory_id, nir_code: values.nir_code})].name}`}
                                            </>
                                        }
                                    </h4>
                            </Col>
                            {canUpdate &&
                                <Col sm="auto" className="text-end">
                                    <Link className="btn btn-link p-0 pe-1 text-primary no-print" to={`/secure/analysis/${values.laboratory_id}/${values.id}/edit/1`}>
                                        <EditIcon width="24px" height="100%" className="align-text-top" />
                                    </Link>
                                </Col>
                            }
                            </Row>
                            <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                            <Row>
                                <Col lg>
                                    <Form.Group>
                                        <Form.Label className="text-break"><span className="text-black fw-bold">{t('forms.analysis.technician')}</span> {employees[analysisInitValue.employee_id] ? employees[analysisInitValue.employee_id].firstname + " " + employees[analysisInitValue.employee_id].lastname : ""} </Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {customForm && customForm.fields.client.map((line, lIndex) => {
                                return (
                                    <Row key={lIndex}>
                                        {line.map((col, cIndex) => {
                                            return (
                                                <Col lg={values.status == 'done' || values.status == 'partial' ? 12 : 6} key={cIndex}>
                                                    <CustomField 
                                                        laboratoryId={values.laboratory_id} // Current laboratory ID
                                                        familyId={values.family_id} // Current family ID
                                                        formId={customForm.id ?? 0} // Id of the form (used to retrieve matching datalist)
                                                        fieldId={col.field_id} // ID of the field to display
                                                        isReadOnly={true} // Boolean to know if field is editable or not
                                                        isRequired={col.required} // Boolean to know if the field is required or not
                                                        disabled={false} // Boolean to know if field is disabled
                                                        formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                    />
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                )
                            })}
                        </Container>


                        <Container className="p-2 bg-white mb-2 rounded-3">
                            <Row >
                                <Col lg>
                                    <h4 className="text-primary fw-bold"> {`${types[values.type_id].name} : ${familieInitValue.name}`}</h4>
                                </Col>
                                {canUpdate &&
                                    <Col sm="auto" className="text-end">
                                        <Link className="btn btn-link p-0 pe-1 text-primary no-print" to={`/secure/analysis/${values.laboratory_id}/${values.id}/edit/2`}>
                                            <EditIcon width="24px" height="100%" className="align-text-top" />
                                        </Link>
                                    </Col>
                                }

                            </Row>

                            <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>

                            {familieInitValue.comment &&
                                <Row>
                                    <Col>
                                        <Container className="p-1 mb-2 rounded-3 bg-info">
                                            <p className="with-linebreak text-black fw-bold fst-italic">{familieInitValue.comment} </p>
                                        </Container>
                                    </Col>
                                </Row>
                            }

                            {familieInitValue.detailsRequired === true &&
                                <Row className="mb-2">
                                    <Col lg="auto">
                                        <Form.Group controlId="formFamily_details">
                                            <Form.Label>
                                                <span className="text-black fw-bold">{t('forms.analysis.' + typeLabels[values.type_code] + '_detail')}</span> 
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <span className="with-linebreak">{analysisInitValue.family_details}</span>
                                    </Col>
                                </Row>
                            }

                            {customForm && customForm.fields.sample.map((line, lIndex) => {
                                return (
                                    <Row key={lIndex}>
                                        {line.map((col, cIndex) => {
                                            return (
                                                <Col lg={values.status == 'done' || values.status == 'partial' ? 12 : 6} key={cIndex}>
                                                    <CustomField 
                                                        laboratoryId={values.laboratory_id} // Current laboratory ID
                                                        familyId={values.family_id} // Current family ID
                                                        formId={customForm.id ?? 0} // Id of the form (used to retrieve matching datalist)
                                                        fieldId={col.field_id} // ID of the field to display
                                                        isReadOnly={true} // Boolean to know if field is editable or not
                                                        isRequired={col.required} // Boolean to know if the field is required or not
                                                        disabled={false} // Boolean to know if field is disabled
                                                        formikProps={formikProps} // All Formik props from parent form (includes values, errors, touched, setFieldValue, etc.)
                                                    />
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                )
                            })}
                        </Container>

                        {values.nir_code &&
                            <Container className="p-2 bg-white mb-2 rounded-3" >
                                <Row>
                                    <Col>
                                        <h4 className="text-primary fw-bold">{t('forms.analysis.nirAnalysis')}</h4>
                                    </Col>
                                </Row>
                                {userHasRoleAll(values.laboratory_id, authUser) && 
                                    <>
                                        <Row className="pb-3"><Col lg className="lineTitle"></Col></Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>
                                                        <span className="text-black fw-bold">{t('forms.analysis.nir_productgroup')}{t('colon')} </span>
                                                        {(() => {
                                                            const nir_type = types[values.type_id].nir_types.find(nt => nt.nir_code === values.nir_code)
                                                        
                                                            return (
                                                                <span>{nir_type ? nir_type.nir_type_code : types[values.type_id].name}</span>
                                                            )
                                                        })()}
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-2">
                                                    <Form.Label>
                                                        <span className="text-black fw-bold">{t('forms.analysis.nir_product')}{t('colon')} </span>{values.family_id && families[values.family_id] ? families[values.family_id].name : ''} 
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Container>
                        }


                        {!values.nir_code && 
                            <Container className="p-2 bg-white mb-2 rounded-3" >
                                <Row>
                                    <Col>
                                        <Container className="p-2 bg-white mb-2 rounded-3" >
                                            <Row>
                                                <Col xs="auto">
                                                    <h4 className="text-primary fw-bold">{t('forms.analysis.pattern')} {patternInitValue.name}</h4>
                                                </Col>
                                                {values.status != 'done' && values.status != 'partial' &&
                                                    <Col xs="auto">
                                                        <PcpwComponent 
                                                            price={analysisInitValue.total_price ? analysisInitValue.total_price : pcpwUtils.calculatePatternPrice(values, values.analysis_packages)}
                                                            period={analysisInitValue.max_period ? analysisInitValue.max_period : pcpwUtils.calculatePatternPeriod(values, values.analysis_packages)} 
                                                            weight={analysisInitValue.total_weight ? analysisInitValue.total_weight : pcpwUtils.calculatePatternWeight(values, values.analysis_packages)} 
                                                            currency={analysisInitValue.currency} 
                                                            size={"lg"}
                                                            type="pattern">
                                                        </PcpwComponent>
                                                    </Col>
                                                }
                                                {canUpdate &&
                                                    <Col xs="auto" className="ms-auto text-end">
                                                        <Link className="btn btn-link p-0 pe-1 text-primary no-print" to={`/secure/analysis/${values.laboratory_id}/${values.id}/edit/3`}>
                                                            <EditIcon width="24px" height="100%" className="align-text-top" />
                                                        </Link>
                                                    </Col>
                                                }
                                            </Row>
                                            {(values.status == 'done' || values.status == 'partial') &&
                                                <Row className="mb-1">
                                                    <Col>
                                                        <PcpwComponent 
                                                            price={analysisInitValue.total_price ? analysisInitValue.total_price : pcpwUtils.calculatePatternPrice(values, values.analysis_packages)}
                                                            period={analysisInitValue.max_period ? analysisInitValue.max_period : pcpwUtils.calculatePatternPeriod(values, values.analysis_packages)} 
                                                            weight={analysisInitValue.total_weight ? analysisInitValue.total_weight : pcpwUtils.calculatePatternWeight(values, values.analysis_packages)} 
                                                            currency={analysisInitValue.currency} 
                                                            size={"lg"}>
                                                        </PcpwComponent>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>

                                            <Row>
                                                <Col lg={values.status == 'done' || values.status == 'partial' ? 0 : 6} />
                                                <Col lg={values.status == 'done' || values.status == 'partial' ? 12 : 6}>
                                                    <Form.Group className="mt-2" controlId="formUrgent">
                                                        <FormCheckBoxUrgent
                                                            analysis = {values}
                                                            analysisFieldName = 'analysis'
                                                            markup = {laboratories[values.laboratory_id].markup}
                                                            readOnly = {true}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row>
                                            {patternInitValue.option &&
                                                <Col lg={values.status == 'done' || values.status == 'partial' ? 12 : 6}>
                                                    <Container className="p-1 rounded-3 bg-info">
                                                        <p className="with-linebreak text-black fw-bold fst-italic">{patternInitValue.option} </p>
                                                    </Container>
                                                </Col>
                                            }
                                            {analysisInitValue.comment &&
                                                <Col lg={values.status == 'done' || values.status == 'partial' ? 12 : 6} className={values.status == 'done' || values.status == 'partial' ? 'mt-1' : ''}>
                                                    <Row className="mb-2">
                                                        <Col lg="auto">
                                                            <Form.Group controlId="formTranslationsName">
                                                                <Form.Label>
                                                                    <span className="text-black fw-bold">{t('forms.analysis.comment')}</span> 
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <span className="with-linebreak">{analysisInitValue.comment}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            </Row>

                                            {packageWithTests.length > 0 && (patternInitValue.option || analysisInitValue.comment) &&
                                                <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                                            }

                                            <Row >
                                                {packageWithTests.map((arrayItemPackage) => {
                                                    return (
                                                        <Col key={arrayItemPackage.id} lg={values.status == 'done' || values.status == 'partial' ? 12 : 6} sm={6} className="p-0" >
                                                            <Container>
                                                                <Row>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col xs={values.status == 'done' || values.status == 'partial' ? 12 : 7} className="text-break" > <span className="text-black fw-bold">{dbt(packages, arrayItemPackage.package_id, language.id, "name")} </span> </Col>
                                                                            <Col>
                                                                                <PcpwComponent 
                                                                                    price={arrayItemPackage.price}
                                                                                    period={arrayItemPackage.period}
                                                                                    currency={arrayItemPackage.currency} 
                                                                                    size={"md"}>
                                                                                </PcpwComponent>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row> <Col className="text-break"> <span className="with-linebreak text-black fw-normal fst-italic">{arrayItemPackage.comment}</span></Col></Row>
                                                                    </Col>
                                                                </Row>
                                                                <ul>
                                                                    {PatternPackageTestUtils.getTestsListByPackage(arrayItemPackage.analysis_package_tests, values.laboratory_id, analytes, language, authUser, laboratories[values.laboratory_id].currency).map((arrayItemTests, index) => {
                                                                        return (
                                                                            <li key={index}>
                                                                                <Row>
                                                                                    <Col xs={values.status == 'done' || values.status == 'partial' ? 12 : 7} className="text-break">
                                                                                        {arrayItemTests.displayName}
                                                                                        {arrayItemTests.theoretical_value !== null &&
                                                                                            <span className="smalltext">{" (" + t('forms.analysis.theo_value_short') + arrayItemTests.theoretical_value + ") "}</span>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </Container>
                                                        </Col>
                                                    )
                                                }
                                                )
                                                }
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        }
                        
                    </Col>

                    {(values.status == 'done' || values.status == 'partial') && 
                        <Col lg={9}>
                            <ResultsTable analysis={values} user={authUser}></ResultsTable>
                        </Col>
                    }
                </Row>
            </Container>

            <Container>
                {(apiStatusError !== undefined) &&
                    <Container className="mt-2 bg-white text-danger">
                        <p>{t('forms.analysis.updateStatus') + ' - ' + t('forms_errors.unexpected_error') + ' (' + apiStatusError + ')'}</p>
                    </Container>
                }
                <Row className="mt-2 ">

                    
                    <Col md="auto" className="p-0 me-auto">

                        {/* Cancel Button */}
                        {(
                            ((values.status === StatusAnalysis.received_compliant || values.status === StatusAnalysis.received_improper || values.status === StatusAnalysis.registered) && hasRoleCancel) ||
                            ((values.status === StatusAnalysis.registered || values.status === StatusAnalysis.received_fixed) && (hasRoleUser || hasRoleClient))
                        ) &&
                            <Button className="m-1 no-print" variant="secondary" onClick={() => { setShowPopupCancel(true) }} >{t('analysis.cancel_analysis')}</Button>
                        }

                        {/* REceive improper Button */}
                        {(values.status === StatusAnalysis.registered || values.status === StatusAnalysis.received_fixed) && grantLaboratory &&
                            <Button className="m-1 no-print" variant="danger" onClick={handleShow} >{t('analysis.receive_improper')}</Button>
                        }
                    </Col>

                    <Col md="auto" className="p-0 ms-auto">

                        {/* Download formulation files button */}
                        {(values.status === StatusAnalysis.partial || values.status === StatusAnalysis.done)
                            && (plb ? <Button className="mt-1 me-4 no-print" variant="secondary" onClick={getResultsFormatting([{ labId: String(values.laboratory_id), id: String(values.id) }], false, true)} > {t('analysis.download_lynx_files')} </Button> : <Button className="mt-1 me-4 no-print" variant="dark" disabled> {t('analysis.download_lynx_files')} </Button>)
                        }

                        {/* Download partial results button */}
                        {values.status === StatusAnalysis.partial &&
                            <Button className="mt-1 no-print" variant="secondary" onClick={getResultsFormatting([{ labId: String(values.laboratory_id), id: String(values.id) }], true, false)} > {t('analysis.download_partial_reports')} </Button>
                        }

                        {/* Download results button */}
                        {values.status === StatusAnalysis.done &&
                            <Button className="mt-1 no-print" variant="secondary" onClick={getResultsFormatting([{ labId: String(values.laboratory_id), id: String(values.id) }], true, false)} > {t('analysis.download_reports')} </Button>
                        }

                    </Col>

                    <Col md="auto" className="p-0 ms-auto d-print-none">
                        {/* Delete GLIMS Result button */}
                        {(values.status === StatusAnalysis.partial || values.status === StatusAnalysis.done) && grantLaboratory &&
                            <Button className="m-1 no-print" variant="danger" onClick={handleShowDeleteResults} >{t('analysis.delete_glims_results')}</Button>
                        }

                        {/* Reset to registered button */}
                        {(values.status === StatusAnalysis.received_compliant || values.status === StatusAnalysis.received_improper || values.status === StatusAnalysis.received_fixed) && grantLaboratoryAdmin && !values.client_sample_id &&
                            <Button className="m-1 no-print" variant="danger" onClick={handleShowRegistered} >{t('analysis.rollback_registered')}</Button>
                        }

                        {/* Print label button */}
                        {grantLaboratory &&
                            <PrintLabelButton analysis={analysis[values.laboratory_id][values.id]} />
                        }

                        {/* Receive proper button */}
                        {(values.status === StatusAnalysis.registered || values.status === StatusAnalysis.received_improper || values.status === StatusAnalysis.received_fixed) && grantLaboratory &&
                            <Button className="m-1 no-print" variant="success" onClick={() => { updateStatus(StatusAnalysis.received_compliant) }} >{t('analysis.receive_proper')}</Button>
                        }
                    </Col>

                </Row>            
                {grantLaboratory && 
                    <Row>
                        <Col xs="auto" className="p-0 mt-2 d-print-none">
                            <GoBack to="/secure/analysis" label={t('analysis.return_to_my_list_analysis')} />
                        </Col>
                        {analysis[values.laboratory_id][values.id].last_print_date && 
                            <Col className="d-flex flex-row-reverse">
                                <span className="smalltext">{t('analysis.last_print_date')}{
                                    Intl.DateTimeFormat(authUser.locale, {
                                        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    }).format(new Date(analysis[values.laboratory_id][values.id].last_print_date!))}
                                </span>
                            </Col>
                        }
                    </Row>
                }
            </Container>
        </Container>
    )
}

export default withTranslation()(ConsultAnalysis)