import React, { useMemo, useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import dbt from '../../../common/utils/dbTranslation';
import { ReactComponent as DeleteIcon } from 'bootstrap-icons/icons/x-circle.svg'
import { ReactComponent as FlaskIcon } from '../../../common/resources/flask.svg'
import { ReactComponent as DeleteCircleRed } from '../../../common/resources/DeleteCircleRed.svg'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { ControlPlan, Sample, SamplePackage } from '../../../api/controlPlanApi';
import { Family as CFamily, Package as CPackage, Type as CType, allowTheoreticalValueEnum, PackagesPatternsOld, getAnalyteKey, Analyte, AnalyteTestPackage } from '../../../api/configApi';
import { Row, Col, Button, Container, Form, Accordion } from 'react-bootstrap';
import Select from 'react-select'
import { getIn, FormikProps } from 'formik';
import ContextAwareToggle from '../../../common/components/ContextAwareToggle';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ModalPackagesTests, { IPackages, CheckStatus } from '../../../common/components/ModalPackagesTests';
import { buildSelectSmallStyle } from '../../../common/utils/utils';
import useSecurityRoles from '../../../common/auth/useSecurityRoles';
import { Pattern } from '../../../api/configApi';
import PricesCalculator from '../helpers/PricesCalculator';
import { Data } from '../../../types/types';
import { PatternPackageTestUtils } from '../helpers/PatternPackageTestsUtils';
import TestCategoryList from '../../laboratory/test_categories/TestCategoryList';

interface ControlPlanRowProps extends WithTranslation {
    index: number,
    sample: Sample,
    edit: boolean,
    currentLaboratoryId: number,
    formikProps: FormikProps<ControlPlan>
}

const ControlPlanRow: React.FC<ControlPlanRowProps> = ({ t, index, sample, edit, currentLaboratoryId, formikProps }) => {
    const { values, touched, errors, handleChange, handleBlur, setValues, setFieldTouched, setFieldValue } = formikProps
    const { userIsInternal, userGrantControlPlanAdmin } = useSecurityRoles()

    // user state
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    //  Laboratories state
    const laboratories = useSelector((state: RootState) => state.config.laboratories.data)

    // types state
    const types = useSelector((state: RootState) => state.config.types.data)
    const typesByLabId = useSelector((state: RootState) => state.config.types.byLabId)

    // families state
    const families = useSelector((state: RootState) => state.config.families.data)

    // patterns state
    const patterns = useSelector((state: RootState) => state.config.patterns.data)

    // Package state
    const packages = useSelector((state: RootState) => state.config.packages.data)

    // Analytes state
    const analytes = useSelector((state: RootState) => state.analytes.data)

    // analytes tests packages
    const analyteTestsPackages: Data<AnalyteTestPackage> = useSelector(
        (state: RootState) => state.analyteTestsPackages.data
        );

    // languages state
    const language = useSelector((state: RootState) => state.local.language)

    // price lists state

    const priceLists = useSelector((state: RootState) => state.priceList.data);

    const patternsFamilies = useSelector((state: RootState) => state.patternsFamilies.data)

    const patternPackageTestUtils = new PatternPackageTestUtils({
        analytes: analytes,
        analyteTestPackages: analyteTestsPackages,
        authUser: authUser,
        language: language,
        packages: packages,
        patterns: patterns,
        priceLists: priceLists
    })
    const handleShowPackages = (sampleIndex: number) => {
        const cps = values.control_plan_samples
        if (cps) {
            const sample = cps[sampleIndex]
            setSampleIndex(sampleIndex)
            setPackagesData(
                patternPackageTestUtils.getPackageData(
                    sample,
                    'pdc',
                    patternPackageTestUtils.convertSamplePackageToBasePackage(sample.sample_packages),
                    values.laboratory_id, 
                    values.company_id,

                )

            )
        }
        setShowPackages(true)
    }

    const [showPackages, setShowPackages] = useState(false);
    const [sampleIndex, setSampleIndex] = useState(-1);
    const [packagesData, setPackagesData] = useState<IPackages | undefined>(undefined)

    const handleTestsSelection = (sampleIndex: number) => {
        return (data: IPackages) => {
            const cps = values.control_plan_samples
            if (cps) {
                const sample = cps[sampleIndex]


                const samplePackages: Array<SamplePackage> = data.packages.filter(pVal => pVal.check === 'checked' || pVal.check === 'indeterminate').map(pVal => (
                    {
                        package_id: Number(pVal.key),
                        comment: pVal.comment ? pVal.comment : '',
                        price: pVal.price != null ? pVal.price : null,
                        currency: PricesCalculator.getCurrency(priceLists, values.company_id),
                        cost: pVal.cost != null ? pVal.cost : null,
                        period: pVal.period != null ? pVal.period : null,
                        weight: pVal.weight != null ? pVal.weight : null,
                        sample_package_tests: pVal.tests.filter(tVal => tVal.check === 'checked').map(tVal => (
                            {
                                code_test: tVal.code_test,
                                code_analyte: tVal.code_analyte,
                                analysis_group_name: tVal.analysis_group_name,
                                theoretical_value: tVal.theoretical_value != null ? tVal.theoretical_value : null,
                                price: tVal.price != null ? tVal.price : null,
                                currency: tVal.currency,
                                cost: tVal.cost != null ? tVal.cost : null,
                                period: tVal.period != null ? tVal.period : null,
                                weight: tVal.weight != null ? tVal.weight : null,
                                unit: tVal.unit
                            }
                        ))
                    }
                ))

                const allPricesAndCosts = PricesCalculator.getAllPricesAndCosts(
                    patterns[sample.pattern_id],
                    priceLists,
                    values.company_id,
                    samplePackages

                )
               
                const updCps = {
                    ...cps[index],
                    nb_test: allPricesAndCosts.nb_test,
                    sample_packages: samplePackages,
                    unit_price: allPricesAndCosts.unit_prices,
                    total_sample_price: allPricesAndCosts.unit_prices !=null ?  allPricesAndCosts.unit_prices * Number(sample.nb_sample) : null,
                    unit_cost: allPricesAndCosts.unit_cost,
                    total_sample_cost: allPricesAndCosts.unit_cost != null ? allPricesAndCosts.unit_cost * Number(sample.nb_sample) : null,
                    pattern_price: allPricesAndCosts.pattern_price,
                    currency: PricesCalculator.getCurrency(priceLists, values.company_id),
                    pattern_cost: allPricesAndCosts.pattern_cost,
                    pattern_period: patterns[sample.pattern_id].period,
                    pattern_weight: patterns[sample.pattern_id].weight,
                }

                const updCpsArray = [...cps]
                updCpsArray[index] = updCps

                const updatedValues = {
                    ...values,
                    control_plan_samples: updCpsArray,
                    nb_test: Number(values.nb_test) - Number(sample.nb_test) + allPricesAndCosts.nb_test,
                    total_price: updCpsArray.filter((cps) => cps.total_sample_price != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_price != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_price)}, 0) : null,
                    total_cost: updCpsArray.filter((cps) => cps.total_sample_cost != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_cost != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_cost)}, 0) : null
                }
                setValues(updatedValues)

                setFieldTouched(`control_plan_samples[${sampleIndex}].nb_test`, false);
            }

        }
    }

    const updatePattern = (pattern: Pattern, familyId: number)  => {
        const samplePackages: SamplePackage[] = [];

        if (cps) {
            const allPricesAndCosts = PricesCalculator.getAllPricesAndCosts(
                pattern,
                priceLists,
                values.company_id,
                samplePackages
            );
            const updCps: Sample = {
                ...cps[index],
                pattern_id: pattern !== null ? pattern.id : 0,
                sample_packages: samplePackages,
                nb_test: allPricesAndCosts.nb_test,
                family_id: familyId,
                pattern_price: allPricesAndCosts.pattern_price,
                currency: PricesCalculator.getCurrency(priceLists, values.company_id),
                pattern_cost: pattern.cost != null ? Number(pattern.cost) : null,
                pattern_period: pattern.period != null ? Number(pattern.period) : null,
                pattern_weight: pattern.weight != null ? Number(pattern.weight) : null,
                unit_price: allPricesAndCosts.unit_prices,
                total_sample_price: allPricesAndCosts.unit_prices !=null ? allPricesAndCosts.unit_prices * Number(cps[index].nb_sample || 0) : null,
                unit_cost: allPricesAndCosts.unit_cost,
                total_sample_cost: allPricesAndCosts.unit_cost != null ? allPricesAndCosts.unit_cost * Number(cps[index].nb_sample || 0) : null,
            };

            const updCpsArray = [...cps];
            updCpsArray[index] = updCps;

            const updatedValues = {
                ...values,
                control_plan_samples: updCpsArray,
                nb_test: Number(values.nb_test) - Number(cps[index].nb_test) + allPricesAndCosts.nb_test,
                total_price: updCpsArray.filter((cps) => cps.total_sample_price != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_price != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_price)}, 0) : null,
                total_cost: updCpsArray.filter((cps) => cps.total_sample_cost != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_cost != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_cost)}, 0) : null
            };
            setValues(updatedValues);

            setFieldTouched(`control_plan_samples[${index}].pattern_id`, true);
            setFieldTouched(`control_plan_samples[${index}].pattern_id`, false);
        }
    }

    const hasRolePDCAdmin = userGrantControlPlanAdmin(currentLaboratoryId, authUser)
    const isInternal = userIsInternal()

    const cps = values.control_plan_samples


    if (cps) {
        return useMemo(
            () => {
                return (
                    <>
                        <tr key={"sample-" + index}>
                            <td className="col-md-2">
                                {!edit && dbt(types, sample.type_id, language.id, "name")}
                                {edit &&
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Popover id={`popover-type-${index}`} className={!getIn(touched, `control_plan_samples[${index}].type_id`) || typeof getIn(errors, `control_plan_samples[${index}].type_id`) !== 'string' ? "d-none" : ""}>
                                                <Popover.Body>
                                                    <Form.Text className="invalid-feedback d-block">{getIn(errors, `control_plan_samples[${index}].type_id`)}</Form.Text>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <span>
                                            <Select
                                                size="sm"
                                                className={getIn(touched, `control_plan_samples[${index}].type_id`) && typeof getIn(errors, `control_plan_samples[${index}].type_id`) === 'string' ? `imgErrorInvalidInput` : ``}
                                                styles={buildSelectSmallStyle(getIn(touched, `control_plan_samples[${index}].type_id`), typeof getIn(errors, `control_plan_samples[${index}].type_id`) === "string")}

                                                noOptionsMessage={() => t('forms.pdc.typeNotFound')}
                                                value={types[cps[index].type_id]}
                                                name={`control_plan_samples[${index}].type_id`}
                                                placeholder=""
                                                getOptionLabel={(type: CType) => dbt(types, type.id, language.id, "name")}
                                                getOptionValue={(type: CType) => type.id}
                                                isDisabled={false}
                                                isClearable={true}
                                                options={Object.values(types)
                                                    .filter(item => typesByLabId && typesByLabId[currentLaboratoryId] && typesByLabId[currentLaboratoryId].includes(item.id.toString()) && item.active)
                                                    .sort((a, b) => (dbt(types, a.id, language.id, "name")).localeCompare(dbt(types, b.id, language.id, "name")))}

                                                onChange={(type: CType) => {
                                                    if (!type || cps[index].type_id !== type.id) {

                                                        const updCps = {
                                                            ...cps[index],
                                                            type_id: type !== null ? type.id : 0,
                                                            family_id: 0,
                                                            pattern_id: 0,
                                                            nb_test: Number(cps[cps.indexOf(cps.filter((item: Sample) => item.id === sample.id)[0])].nb_test) - Number(cps[index].nb_test),
                                                            unit_price: null,
                                                            total_sample_price: null,
                                                            unit_cost: null,
                                                            total_sample_cost: null
                                                        }

                                                        const updCpsArray = [...cps]
                                                        updCpsArray[index] = updCps

                                                        const updatedValues = {
                                                            ...values,
                                                            control_plan_samples: updCpsArray,
                                                            nb_test: Number(values.nb_test) - Number(cps[index].nb_test),
                                                            total_price: updCpsArray.filter((cps) => cps.total_sample_price != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_price != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_price)}, 0) : null,
                                                            total_cost: updCpsArray.filter((cps) => cps.total_sample_cost != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_cost != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_cost)}, 0) : null
                                                       
                                                        }
                                                        setValues(updatedValues)
                                                        setFieldTouched(`control_plan_samples[${index}].type_id`, true)
                                                        setFieldTouched(`control_plan_samples[${index}].type_id`, false)
                                                    }
                                                }}

                                                onBlur={async () => { setFieldTouched(`control_plan_samples[${index}].type_id`, true) }}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                }
                            </td>
                            <td className="col-md-3">
                                {!edit && dbt(families, sample.family_id, language.id, "name")}
                                {edit &&
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Popover id={`popover-type-${index}`} className={!getIn(touched, `control_plan_samples[${index}].family_id`) || typeof getIn(errors, `control_plan_samples[${index}].family_id`) !== 'string' ? "d-none" : ""}>
                                                <Popover.Body>
                                                    <Form.Text className="invalid-feedback d-block">{getIn(errors, `control_plan_samples[${index}].family_id`)}</Form.Text>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <span>
                                            <Select
                                                size="sm"
                                                className={getIn(touched, `control_plan_samples[${index}].family_id`) && typeof getIn(errors, `control_plan_samples[${index}].family_id`) === 'string' ? `imgErrorInvalidInput` : ``}
                                                styles={buildSelectSmallStyle(getIn(touched, `control_plan_samples[${index}].family_id`), typeof getIn(errors, `control_plan_samples[${index}].family_id`) === "string")}

                                                noOptionsMessage={() => t('forms.pdc.familyNotFound')}
                                                value={cps[index].family_id !== 0 ? families[cps[index].family_id] : { id: 0, name: "" }}
                                                name={`control_plan_samples[${index}].family_id`}
                                                placeholder=""
                                                getOptionLabel={(family: CFamily) => dbt(families, family.id, language.id, "name")}
                                                getOptionValue={(family: CFamily) => family.id}
                                                isDisabled={false}
                                                isClearable={true}
                                                options={
                                                    Object.values(families)
                                                        .sort((a, b) => (dbt(families, a.id, language.id, "name")).localeCompare(dbt(families, b.id, language.id, "name")))
                                                        .filter(item => item.type_id === cps[index].type_id && item.active)}

                                                onChange={(family: CFamily) => {
                                                    if (!family || cps[index].family_id !== family.id) {
                                                        const patternsOfFamily = Object.values(patternsFamilies).filter(l => (!l.family_id || l.family_id === family.id) && l.type_id === cps[index].type_id)

                                                        const updCps = {
                                                            ...cps[index],
                                                            family_id: family !== null ? family.id : 0,
                                                            pattern_id: patternsOfFamily.length === 1 ?  patternsOfFamily[0].pattern_id : 0,
                                                            nb_test: Number(cps[cps.indexOf(cps.filter((item: Sample) => item.id === sample.id)[0])].nb_test) - Number(cps[index].nb_test),
                                                            unit_price: null,
                                                            total_sample_price: null,
                                                            unit_cost: null,
                                                            total_sample_cost: null
                                                        }

                                                        const updCpsArray = [...cps]
                                                        updCpsArray[index] = updCps

                                                        const updatedValues = {
                                                            ...values,
                                                            control_plan_samples: updCpsArray,
                                                            total_price: updCpsArray.filter((cps) => cps.total_sample_price != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_price != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_price)}, 0) : null,
                                                            total_cost: updCpsArray.filter((cps) => cps.total_sample_cost != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_cost != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_cost)}, 0) : null
                                                       
                                                        }
                                                        setValues(updatedValues)

                                                        if (updCps.pattern_id){
                                                            updatePattern(patterns[updCps.pattern_id], family.id )
                                                        }
                                                        setFieldTouched(`control_plan_samples[${index}].family_id`, true)
                                                        setFieldTouched(`control_plan_samples[${index}].family_id`, false)

                                                    }
                                                }}
                                                onBlur={async () => { setFieldTouched(`control_plan_samples[${index}].family_id`, true) }}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                }
                            </td>
                            <td className="col-md-3">
                                {!edit && sample.denomination}
                                {edit &&
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Popover id={`popover-type-${index}`} className={!getIn(touched, `control_plan_samples[${index}].denomination`) || typeof getIn(errors, `control_plan_samples[${index}].denomination`) !== 'string' ? "d-none" : ""}>
                                                <Popover.Body>
                                                    <Form.Text className="invalid-feedback d-block">{getIn(errors, `control_plan_samples[${index}].denomination`)}</Form.Text>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <span>
                                            <Form.Control size="sm" isInvalid={getIn(touched, `control_plan_samples[${index}].denomination`) && !!getIn(errors, `control_plan_samples[${index}].denomination`)} type="text" name={`control_plan_samples[${index}].denomination`} maxLength={255} value={cps[index].denomination} onChange={(e) => {
                                                setFieldValue(`control_plan_samples[${index}].denomination`, e.target.value)
                                                setFieldTouched(`control_plan_samples[${index}].denomination`, true)
                                                setFieldTouched(`control_plan_samples[${index}].denomination`, false)
                                            }} onBlur={handleBlur} />
                                        </span>
                                    </OverlayTrigger>
                                }
                            </td>
                            <td className="col-md-2">
                                {!edit && dbt(patterns, sample.pattern_id, language.id, "name")}
                                {edit &&
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Popover id={`popover-type-${index}`} className={!getIn(touched, `control_plan_samples[${index}].pattern_id`) || typeof getIn(errors, `control_plan_samples[${index}].pattern_id`) !== 'string' ? "d-none" : ""}>
                                                <Popover.Body>
                                                    <Form.Text className="invalid-feedback d-block">{getIn(errors, `control_plan_samples[${index}].pattern_id`)}</Form.Text>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <span>
                                            <Select
                                                size="sm"
                                                className={getIn(touched, `control_plan_samples[${index}].pattern_id`) && typeof getIn(errors, `control_plan_samples[${index}].pattern_id`) === 'string' ? `imgErrorInvalidInput` : ``}
                                                styles={buildSelectSmallStyle(getIn(touched, `control_plan_samples[${index}].pattern_id`), typeof getIn(errors, `control_plan_samples[${index}].pattern_id`) === "string")}
                                                noOptionsMessage={() => t('forms.pdc.patternNotFound')}
                                                value={cps[index].pattern_id !== 0 ? patterns[cps[index].pattern_id] : { id: 0, name: "" }}
                                                name={`control_plan_samples[${index}].pattern_id`}
                                                placeholder=""
                                                getOptionLabel={(pattern: CPackage) => dbt(patterns, pattern.id, language.id, "name")}
                                                getOptionValue={(pattern: CPackage) => pattern.id}
                                                isDisabled={false}
                                                isClearable={true}
                                                options={

                                                    Object.values(patterns)
                                                    .filter(item => {
                                                    
                                                        const patternFamily = Object.values(patternsFamilies).find(p => p.pattern_id === item.id && (!p.family_id || p.family_id === cps[index].family_id) && p.type_id === cps[index].type_id)
                                                        return !!patternFamily
                                                    }
                                                        )                     .sort((a, b) => (dbt(patterns, a.id, language.id, "name")).localeCompare(dbt(patterns, b.id, language.id, "name")))}
                                                onChange={(pattern: Pattern) => {
                                                    if (pattern) {
                                                        if (cps[index].pattern_id !== pattern.id) {

                                                            updatePattern(pattern, cps[index].family_id);
                                                        }
                                                    }


                                                
                                                }}
                                                onBlur={async () => { setFieldTouched(`control_plan_samples[${index}].pattern_id`, true) }}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                }
                            </td>
                            <td className="col-md-0">
                                {!edit && sample.nb_sample}
                                {edit &&
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Popover id={`popover-type-${index}`} className={!getIn(touched, `control_plan_samples[${index}].nb_sample`) || typeof getIn(errors, `control_plan_samples[${index}].nb_sample`) !== 'string' ? "d-none" : ""}>
                                                <Popover.Body>
                                                    <Form.Text className="invalid-feedback d-block">{getIn(errors, `control_plan_samples[${index}].nb_sample`)}</Form.Text>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <span>
                                            <Form.Control
                                                size="sm"
                                                isInvalid={getIn(touched, `control_plan_samples[${index}].nb_sample`) && !!getIn(errors, `control_plan_samples[${index}].nb_sample`)}
                                                type="number"
                                                min="0"
                                                name={`control_plan_samples[${index}].nb_sample`}
                                                value={cps[index].nb_sample}
                                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                onWheel={(e: any) => e.target.blur()}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const re = /^\d+$/
                                                    if (re.test(e.target.value)) {

                                                        const oldSampleValue = cps[index].nb_sample
                                                        const oldPriceValue = cps[index].total_sample_price
                                                        const oldCostValue = cps[index].total_sample_cost

                                                        const updCps = {
                                                            ...cps[index],
                                                            nb_sample: Number(e.target.value),
                                                            total_sample_price: cps[index].unit_price != null ?  Number(e.target.value) * Number(cps[index].unit_price) :null,
                                                            total_sample_cost: cps[index].unit_cost != null ?  Number(e.target.value) * Number(cps[index].unit_cost) :null
                                                        }

                                                        const updCpsArray = [...cps]
                                                        updCpsArray[index] = updCps

                                                        const updatedValues = {
                                                            ...values,
                                                            control_plan_samples: updCpsArray,
                                                            nb_sample: Number(values.nb_sample) - Number(oldSampleValue) + Number(e.target.value),
                                                            total_price: updCpsArray.filter((cps) => cps.total_sample_price != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_price != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_price)}, 0) : null,
                                                            total_cost: updCpsArray.filter((cps) => cps.total_sample_cost != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_cost != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_cost)}, 0) : null
                                                        }
                                                        setValues(updatedValues)
                                                        setFieldTouched(`control_plan_samples[${index}].nb_sample`, true);
                                                        setFieldTouched(`control_plan_samples[${index}].nb_sample`, false);
                                                    }
                                                }}
                                                onBlur={handleBlur} />
                                        </span>
                                    </OverlayTrigger>
                                }
                            </td>
                            <td className="col-md-0">{sample.nb_used}</td>
                            <td className="col-md-0">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Popover id={`popover-type-${index}`} className={!getIn(touched, `control_plan_samples[${index}].nb_test`) || typeof getIn(errors, `control_plan_samples[${index}].nb_test`) !== 'string' ? "d-none" : ""}>
                                            <Popover.Body>
                                                <Form.Text className="invalid-feedback d-block">{getIn(errors, `control_plan_samples[${index}].nb_test`)}</Form.Text>
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <span className={getIn(touched, `control_plan_samples[${index}].nb_test`) && typeof getIn(errors, `control_plan_samples[${index}].nb_test`) === "string" ? "text-invalid imgErrorInvalidInput" : ""}>
                                        {sample.nb_test}
                                    </span>
                                </OverlayTrigger>
                            </td>
                            
                            <td className="col-md-0">{sample.total_sample_price != null ? Intl.NumberFormat(authUser.locale, { style: 'currency', currency: sample.currency || laboratories[currentLaboratoryId].currency }).format(sample.total_sample_price !== undefined ? sample.total_sample_price : 0) : ''}</td>
                            {hasRolePDCAdmin && isInternal &&
                                <td className="col-md-0">{sample.total_sample_cost != null ? Intl.NumberFormat(authUser.locale, { style: 'currency', currency: sample.currency || laboratories[currentLaboratoryId].currency }).format(sample.total_sample_cost !== undefined ? sample.total_sample_cost : 0) : ''}</td>
                            }
                            <td className="col-md-0">{sample.unit_price != null ? Intl.NumberFormat(authUser.locale, { style: 'currency', currency: sample.currency || laboratories[currentLaboratoryId].currency }).format(sample.unit_price !== undefined ? sample.unit_price : 0) : ''}</td>
                            {hasRolePDCAdmin && isInternal &&
                                <td className="col-md-0">{sample.unit_cost != null ? Intl.NumberFormat(authUser.locale, { style: 'currency', currency: sample.currency || laboratories[currentLaboratoryId].currency }).format(sample.unit_cost !== undefined ? sample.unit_cost : 0) : ''}</td>
                            }

                            <td className="col-md-1">
                                <Row className="d-flex flex-nowrap">
                                    {!edit && values.status === 'published' && (sample.nb_used ? sample.nb_used < sample.nb_sample : true) &&
                                        <Col className="align-self-center ms-3">
                                            <Link className="btn btn-primary btn-sm m-1 no-print" to={`/secure/pdc/use/${values.laboratory_id}/${values.id}/${sample.id}`} >{t('pdc.use')}</Link>
                                        </Col>
                                    }
                                    {edit &&
                                        <>
                                            <Col className="text-nowrap">
                                                {cps[index].pattern_id !== 0 && Object.values(packages).filter((item: CPackage) => item.patterns.some((pattern: PackagesPatternsOld) => pattern.pattern_id === cps[index].pattern_id)).length > 0 &&
                                                    <Button className="m-1 p-0 pe-1 text-secondary" variant="link" onClick={() => { handleShowPackages(index) }} ><FlaskIcon height="100%" width="19px" className="align-text-top" /></Button>
                                                }
                                                {sample.nb_used === 0 &&
                                                    <Button className="m-1 p-0 pe-1 text-danger" variant="link"
                                                        onClick={() => {
                                                            setSampleIndex(-1)

                                                            const updatedValues = {
                                                                ...values,
                                                                control_plan_samples: cps.filter((item: Sample, i) => i !== index),
                                                                nb_sample: Number(values.nb_sample) - Number(cps[index].nb_sample),
                                                                nb_used: Number(values.nb_used) - Number(cps[index].nb_used),
                                                                nb_test: Number(values.nb_test) - Number(cps[index].nb_test),
                                                                total_price: cps.filter((item: Sample, i) => i !== index).filter((cps) => cps.total_sample_price != null).length > 0 ? cps.filter((item: Sample, i) => i !== index).filter((cps) => cps.total_sample_price != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_price)}, 0) : null,
                                                                total_cost: cps.filter((item: Sample, i) => i !== index).filter((cps) => cps.total_sample_cost != null).length > 0 ? cps.filter((item: Sample, i) => i !== index).filter((cps) => cps.total_sample_cost != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_cost)}, 0) : null
                                                           
                                                            }
                                                            setValues(updatedValues)
                                                        }}
                                                    >
                                                        <DeleteIcon width="24px" height="100%" className="align-text-top" />
                                                    </Button>
                                                }
                                            </Col>
                                        </>
                                    }
                                    <Col className="text-right ms-auto">
                                        {cps[index].sample_packages.length > 0 &&
                                            <ContextAwareToggle eventKey={String(index + 1)} />
                                        }
                                    </Col>
                                </Row>
                            </td>
                        </tr>

                        <tr key={"hidden-" + index} hidden />
                        <tr key={"collapse-" + index}>
                            {cps[index].sample_packages.length > 0 &&
                                <td colSpan={12} className="p-0">
                                    <Accordion.Collapse eventKey={String(index + 1)}>
                                        <>
                                            <Container className="p-2 bg-white mb-2">
                                                <Row className="ms-3 text-align-left">
                                                    {cps[index].sample_packages.map((package_object, packageIndex) => {
                                                        return (
                                                            <Col key={packageIndex} sm={6} className="p-0">
                                                                <Col>
                                                                <Row>
                                                                    <Col xs={dbt(packages, package_object.package_id, language.id, "name").length > 75 ? 9 : "auto"} className="text-break" > <span className="text-black fw-bold">{dbt(packages, package_object.package_id, language.id, "name")}</span> </Col>
                                                                </Row>
                                                                <Row> <Col xs="auto" className="text-break"> <span className="with-linebreak text-black fw-normal fst-italic">{package_object.comment}</span></Col></Row>
                                                            </Col>
                                                                
                                                                <ul>
                                                                    {PatternPackageTestUtils.getTestsListByPackage(package_object.sample_package_tests, currentLaboratoryId, analytes, language, authUser, laboratories[currentLaboratoryId].currency).map((test_object, testIndex) => {
                                                                        return (
                                                                            <li key={getAnalyteKey({code_test : test_object.keys[0].code_test, code_analyte: test_object.keys[0].code_analyte, laboratory_id: currentLaboratoryId})}>
                                                                                <Row>
                                                                                    <Col key={testIndex} className="text-break">
                                                                                        {test_object.displayName}
                                                                                        {test_object.theoretical_value !=null ? " (VT: " + test_object.theoretical_value + ") " : null}
                                                                                        {edit &&
                                                                                            <>
                                                                                                <Button size="sm" variant="link" className="p-0 ms-1"
                                                                                                    onClick={() => {
                                                                                                        let updatedTests = [...cps[index].sample_packages[packageIndex].sample_package_tests]


                                                                                                        const updatedPackages = { ...cps[index].sample_packages[packageIndex] }

                                                                                                        let nbTestWithSameGroup = 1

                                                                                                        if (updatedTests[testIndex].analysis_group_name){
                                                                                                            nbTestWithSameGroup = updatedPackages.sample_package_tests.filter( p => p.analysis_group_name === updatedTests[testIndex].analysis_group_name).length
                                                                                                        }

                                                                                                        updatedTests = updatedTests.filter((t, i) => i !== testIndex && (!t.analysis_group_name || t.analysis_group_name !== updatedTests[testIndex].analysis_group_name))

                    
                                                                                                        updatedPackages.sample_package_tests = updatedTests

                                                                                                        
                                                                                                        let updSamplePackages = [...cps[index].sample_packages]
                                                                                                        let removedPackage: SamplePackage | null = null

                                                                                                        if (updatedTests.length > 0){
                                                                                                            updSamplePackages[packageIndex] = updatedPackages
                                                                                                        }
                                                                                                        else {
                                                                                                            removedPackage = updSamplePackages[packageIndex]
                                                                                                            updSamplePackages.splice(packageIndex,1)
                                                                                                        }

                                                                                                        const allPricesAndCosts = PricesCalculator.getAllPricesAndCosts(
                                                                                                            patterns[sample.pattern_id],
                                                                                                            priceLists,
                                                                                                            values.company_id,
                                                                                                            updSamplePackages
                                                                                        
                                                                                                        )

                                                                                                        const updCps = {
                                                                                                            ...cps[index],
                                                                                                            nb_test: Number(cps[index].nb_test) - nbTestWithSameGroup,
                                                                                                            sample_packages: updSamplePackages,
                                                                                                            unit_price: allPricesAndCosts.unit_prices ,
                                                                                                            total_sample_price: allPricesAndCosts.unit_prices != null ? allPricesAndCosts.unit_prices * Number(cps[index].nb_sample  || 0) : null,
                                                                                                            unit_cost: allPricesAndCosts.unit_cost,
                                                                                                            total_sample_cost:allPricesAndCosts.unit_cost != null ? allPricesAndCosts.unit_cost * Number(cps[index].nb_sample  || 0) : null,
                                                                                                        }

                                                                                                        const updCpsArray = [...cps]
                                                                                                        updCpsArray[index] = updCps
                                                                                            
                                                                                                        setFieldValue(`control_plan_samples`,updCpsArray)
                                                                                                        setFieldValue(`nb_test`, Number(values.nb_test) - nbTestWithSameGroup)
                                                                                                        setFieldValue(`total_price`, updCpsArray.filter((cps) => cps.total_sample_price != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_price != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_price)}, 0) : null)
                                                                                                        setFieldValue(`total_cost`,updCpsArray.filter((cps) => cps.total_sample_cost != null).length > 0 ? updCpsArray.filter((cps) => cps.total_sample_cost != null).reduce((accumulator, object) => { return accumulator + Number(object.total_sample_cost)}, 0) : null)
                                                                                                        setFieldTouched(`control_plan_samples[${index}]`, true);
                                                                                                        setFieldTouched(`control_plan_samples[${index}]`, false);
                                                                                                    }}
                                                                                                    onBlur={handleBlur}
                                                                                                >
                                                                                                    <DeleteCircleRed className="colorHoverSvg align-text-top text-secondary p-0" width="15px" height="100%" />
                                                                                                </Button>
                                                                                                <Row className="">
                                                                                                   

                                                                                                    {test_object.numberTestRegroup === 1 && ([allowTheoreticalValueEnum.MANDATORY, allowTheoreticalValueEnum.OPTIONAL].includes(analytes[getAnalyteKey({code_analyte: test_object.keys[0].code_analyte, code_test: test_object.keys[0].code_test, laboratory_id: currentLaboratoryId})]?.allow_theoretical_value)) &&
                                                                                                        <>
                                                                                                     
                                                                                                            <Col xs="auto" className="pe-1 fw-normal fst-italic">
                                                                                                                {t('forms.analysis.theoretical_value')} :
                                                                                                            </Col>
                                                                                                            <Col className="col ps-0 pe-0 align-self-center" >
                                                                                                                <Form.Control
                                                                                                                    className={"form-control-xs w-50"}
                                                                                                                    type="number"
                                                                                                                    onKeyDown={(evt: React.KeyboardEvent) => (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '+') && evt.preventDefault()}
                                                                                                                    value={test_object.theoretical_value != null ? test_object.theoretical_value  : undefined}
                                                                                                                    onChange={(e) => {
                                                                                                                        const decimal = e.target.value.split('.')[1] != null || e.target.value.split('.')[1] !== undefined ? e.target.value.split('.')[1] : ""
                                                                                                                        setFieldValue(`control_plan_samples[${index}].sample_packages[${packageIndex}].sample_package_tests[${testIndex}].theoretical_value`, e.target.value !== '' ? (Number(e.target.value) <= 9999999.999 && decimal.length <= 3 && Number(e.target.value) >= 0 ? Number(e.target.value) : test_object.theoretical_value) : null);
                                                                                                                    }}
                                                                                                                    onBlur={handleBlur} />
                                                                                                            </Col>
                                                                                                        </>
                                                                                                    }
                                                                                                    <Col>

                                                                                                    </Col>

                                                                                                </Row>
                                                                                            </>
                                                                                        }

                                                                                    </Col>

                                                                                </Row>
                                                                            </li>


                                                                        )

                                                                    })}
                                                                </ul>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </Container>
                                        </>
                                    </Accordion.Collapse>
                                </td>

                            }
                        </tr >

                        {sampleIndex !== -1 && packagesData &&
                            <ModalPackagesTests
                                data={packagesData}
                                setShowPackages={setShowPackages}
                                showPackages={showPackages}
                                companyId={values.company_id}
                                priceLists={priceLists}
                                submitValues={handleTestsSelection(sampleIndex)}
                                type="pdc"
                                itemLabId={values.laboratory_id}
                            />
                        }
                    </>
                )

            },
            [cps[index], cps.length, edit, errors, touched, packagesData, showPackages, formikProps.values.company_id]
        )
    } else {
        return null
    }
}

export default withTranslation()(ControlPlanRow)

