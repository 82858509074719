import qs from 'qs'
import env from '../common/utils/env'
import { FilterBy, SortBy } from '../types/types'
import AbstractApi from './AbstractApi'

export interface StatusLabel {
    status: string
    label: string
}

export interface SamplePackageTest {
    code_test: string,
    code_analyte: string, 
    analysis_group_name?: string
    theoretical_value: number | null,
    price?: number | null,
    currency?: string,
    cost?: number | null,
    weight?: number | null,
    period?: number | null,
    unit?: string
}

export interface SamplePackage {
    package_id: number,
    comment: string,
    price?: number | null,
    currency: string,
    cost?: number | null,
    weight?: number | null,
    period?: number | null,
    sample_package_tests: Array<SamplePackageTest>
}

export interface Sample {
    control_plan_id?: number,
    id: number,
    type_id: number,
    family_id: number,
    family_details: string,
    denomination: string,
    pattern_id: number,
    comment: string,
    nb_sample: number,
    nb_used?: number,
    nb_test?: number,
    pattern_price?: number | null,
    currency?: string
    pattern_cost?: number  | null,
    pattern_weight?: number  | null,
    pattern_period?: number  | null,
    total_sample_price?: number  | null,
    total_sample_cost?: number  | null,
    unit_price?: number  | null,
    unit_cost?: number  | null,
    total_unit_price?: number  | null,
    total_unit_cost?: number  | null,
    sample_packages: Array<SamplePackage>
}

export interface ControlPlanInput {
    company_id: number,
    start_date: string,
    end_date: string,
    name: string,
    billable: boolean,
    control_plan_samples: Array<Sample>
}

export interface ControlPlan {
    id: number,
    company_id: number,
    laboratory_id: number,
    start_date: string,
    end_date: string,
    name: string,
    status: string,
    last_date: string,
    billable: boolean,
    created_by?: number,
    created_date?: string,
    last_update_by?: number,
    last_update?: string,
    published_date?: string,
    canceled_date?: string,
    closed_date?: string,
    nb_sample?: number,
    nb_used?: number,
    nb_test?: number,
    total_price: number | null,
    total_cost: number | null,
    total_unit_price?: number | null,
    total_unit_cost?: number | null,
    control_plan_samples?: Array<Sample>
}

class ControlPlanApi extends AbstractApi { 

    private static instance: ControlPlanApi

    public static getInstance() : ControlPlanApi {
        if (!ControlPlanApi.instance) {
            throw new Error('Initialize instance before.')
        }
        return ControlPlanApi.instance
    }

    public static initInstance(token: string) : void {
        ControlPlanApi.instance = new ControlPlanApi(env.PDC_API_URL, token)
    }

    public async getPdc(statusLabels: Array<StatusLabel>, filterBy: FilterBy, sortBy: Array<SortBy>, limit: number, offset: number): Promise<{count: number, data: Array<ControlPlan>}> {
        try {
            const filters: FilterBy = { ...filterBy }
            const config = {
                params: Object.assign(
                    {
                        limit, 
                        offset
                    },
                    Object.keys(filters).length > 0 ? {filter: qs.stringify(filters)} : null,
                    Object.keys(sortBy).length > 0 ? {sortby: sortBy.map(item => item.direction + item.name)} : null
                )
            }
            const response = await this.service.get('/control-plans', config)
            return {
                count: response.data.count || 0,
                data: response.data.data || []
            }
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async getPdcById(id: number, laboratory_id: number): Promise<ControlPlan> {
        try {
            const response = await this.service.get(`/control-plan/${laboratory_id}/${id}`)
            const data = await response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async postPdc(pdc: ControlPlanInput, laboratory_id: number): Promise<ControlPlan> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await this.service.post('/control-plan/' + laboratory_id, pdc, config)
            const data = await response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async updatePdc(pdc: ControlPlanInput, id: number, laboratory_id: number): Promise<ControlPlan> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await this.service.put(`/control-plan/${laboratory_id}/${id}`, pdc, config)
            const data = await response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async updateStatusPdc(status: string, id: number, laboratory_id: number): Promise<ControlPlan> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const encodedStatus = encodeURIComponent(status)
            const response = await this.service.put(`/control-plan/${laboratory_id}/${id}/status/?status=${encodedStatus}`, config)
          
            const data = response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }

    public async useControlPlanSample(sample_id: number, direction: number): Promise<ControlPlan> {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const response = await this.service.put(`/samples/${sample_id}/use?direction=${direction}`, config)
          
            const data = await response.data
            return data
        } catch(err) {
            throw AbstractApi.handleError(err)
        }
    }
}

export default ControlPlanApi
