import React, { useState, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { Table } from 'react-bootstrap';
import useFilterToolkit from '../../../common/utils/useFilterToolkit';
import { ValueGroupBy, setDataOfAnalytesForGroupBy, setSamplesFilterBy, setSamplesSortBy } from '../../../state/dashboardSlice';
import { calculateByGroup } from './utils';

interface DashboardGroupTableComponentContainerProps extends WithTranslation {
    dashboardName: string,
    value: string,
    color: string
}

export const others = 'otherValuesNotInGroupsOrWithoutColor'

const DashboardGroupTableComponent: React.FC<DashboardGroupTableComponentContainerProps> = ({ t, dashboardName, value, color }) => {
    const dispatch = useDispatch()

    // user state
    const { user: authUser } = useSelector((state: RootState) => state.authUser)

    // languages
    const languages = useSelector((state: RootState) => state.config.languages.data)
    const language = Object.values(languages).find(lg => lg.id === Number(authUser.language_id))

    // samples state
    const samplesForData = useSelector((state: RootState) => state.dashboard.samplesForData)
    const resultsHeader = useSelector((state: RootState) => state.dashboard.resultsHeaders)
    const pagination = useSelector((state: RootState) => state.dashboard.paginationSamples)
    const paramsFromComponents = useSelector((state: RootState) => state.dashboard.paramsFromComponents)
    const groupBy = useSelector((state: RootState) => state.dashboard.groupBy)
    const fieldsForSamplesInfo = useSelector((state: RootState) => state.dashboard.fieldsForSamplesInfo)

    // local states
    const [valueForTable, setValueForTable] = useState<ValueGroupBy | undefined>(undefined)
    const [allColors, setAllColors] = useState<string[]>([])

    const resultsFields = () => {
        const fields = {
            [value]: {
                type: [],
                sortable: false,
                noTrad: value === others ? false : true
            },
            display_id: {
                type: [],
                sortable: false
            }
        }
        resultsHeader.forEach(resultHeader => {
            Object.assign(fields, {[resultHeader.name.translations[language && language.code_language ? language.code_language : ''] || resultHeader.name.name]: { type: [], sortable: false, noTrad: true }})
        })
        fieldsForSamplesInfo.forEach(field => {
            Object.assign(fields, {[field.field]: { type: [], sortable: false, noTrad: false }})
        })
        return fields
    }

    const { ListHeader, filterAndSortList, FilterList } = useFilterToolkit(pagination.filterBy, pagination.sortBy, {
        i18nPrefix: "dashboard.selection.columns.",
        onlyActive: false,
        size: 'xs',
        fields: resultsFields(),
        reducers: {
            filterBy: setSamplesFilterBy,
            sortBy: setSamplesSortBy
        }
    })

    useEffect(() => {
        if(Object.values(samplesForData).length > 0){
            dispatch(setDataOfAnalytesForGroupBy({value, datas: calculateByGroup(samplesForData, resultsHeader, paramsFromComponents.date, groupBy.field, groupBy.values.filter(v => v.color === color).map(v => v.value), groupBy.values.map(v => v.value))}))
        }
    }, [groupBy.field, resultsHeader, samplesForData, value, color])

    useEffect(() => {
        setValueForTable(groupBy.values.find(v => v.value === value))
    }, [groupBy, value])

    useEffect(() => {
        if(groupBy.values.map(v => v.color).toString() !== allColors.toString()){
            setAllColors(groupBy.values.map(v => v.color))
        }
    }, [groupBy])

    return (
        <Table key={`group-by-${value}`} striped hover size='sm' className="bg-white text-center table-fixed table-xs mb-0">
            <thead className='bg-white'>
                <tr>
                    <th className="p-0" />
                    <th className="p-1 fixed-width">
                        <ListHeader name={value} addClassToButton='fixed-width-for-button-group' addStyleToButton={{backgroundColor: `${color}`, borderColor: `${color}`}} />
                    </th>
                    {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                        return (
                            <th className="p-1" key={`group-header-${header.id}`}>
                                <ListHeader name={header.name.translations[language && language.code_language ? language.code_language : ''] || header.name.name} addClassToButton="add-pad" addStyleToButton={{backgroundColor: `${color}`, borderColor: `${color}`}} />
                            </th>
                        )
                    })}
                    {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                        return (
                            <th className="p-1" key={`selection-${field.field}`}>
                                <ListHeader name={field.field} addClassToButton="add-pad-no-sort" addStyleToButton={{backgroundColor: `${color}`, borderColor: `${color}`}} />
                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                <tr key="number">
                    <td className="p-1 table-hover-hint" />
                    <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.number')}</td>
                    {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                        return (
                            <td className='ps-1 pe-1' key={`number-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].number ? valueForTable.dataOfAnalytes[header.id.toUpperCase()].number : 0}</td>
                        )
                    })}
                    {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                        return(
                            <td></td> 
                    )})}
                </tr>
                <tr key="min">
                    <td className="p-1 table-hover-hint" />
                    <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.min')}</td>
                    {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                        return (
                            <td className='ps-1 pe-1' key={`min-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].min ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].min!) : 0}</td>
                        )
                    })}
                    {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                        return(
                            <td></td> 
                    )})}
                </tr>
                <tr key="max">
                    <td className="p-1 table-hover-hint" />
                    <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.max')}</td>
                    {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                        return (
                            <td className='ps-1 pe-1' key={`max-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].max ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].max!) : 0}</td>
                        )
                    })}
                    {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                        return(
                            <td></td> 
                    )})}
                </tr>
                {paramsFromComponents.average &&
                    <tr key="average">
                        <td className="p-1 table-hover-hint" />
                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.average')}</td>
                        {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                            return (
                                <td className='ps-1 pe-1' key={`average-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].average ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].average!) : 0}</td>
                            )
                        })}
                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                            return(
                                <td></td> 
                        )})}
                    </tr>
                }
                <tr key="deviation">
                    <td className="p-1 table-hover-hint" />
                    <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.deviation')}</td>
                    {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                        return (
                            <td className='ps-1 pe-1' key={`deviation-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].deviation ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].deviation!) : 0}</td>
                        )
                    })}
                    {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                        return(
                            <td></td> 
                    )})}
                </tr>
                {paramsFromComponents.average1 &&
                    <tr key="averageplus">
                        <td className="p-1 table-hover-hint" />
                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageplus')}</td>
                        {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                            return (
                                <td className='ps-1 pe-1' key={`averageplus-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].averagePlus ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].averagePlus!) : 0}</td>
                            )
                        })}
                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                            return(
                                <td></td> 
                        )})}
                    </tr>
                }
                {paramsFromComponents.average1 &&
                    <tr key="averageminus">
                        <td className="p-1 table-hover-hint" />
                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageminus')}</td>
                        {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                            return (
                                <td className='ps-1 pe-1' key={`averagemin-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].averageMinus ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].averageMinus!) : 0}</td>
                            )
                        })}
                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                            return(
                                <td></td> 
                        )})}
                    </tr>
                }
                {paramsFromComponents.average2 &&
                    <tr key="averageplus2">
                        <td className="p-1 table-hover-hint" />
                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageplus2')}</td>
                        {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                            return (
                                <td className='ps-1 pe-1' key={`averageplus2-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].averagePlus2 ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].averagePlus2!) : 0}</td>
                            )
                        })}
                        <td></td>
                    </tr>
                }
                {paramsFromComponents.average2 &&
                    <tr key="averageminus2">
                        <td className="p-1 table-hover-hint" />
                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageminus2')}</td>
                        {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                            return (
                                <td className='ps-1 pe-1' key={`averagemin2-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].averageMinus2 ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].averageMinus2!) : 0}</td>
                            )
                        })}
                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                            return(
                                <td></td> 
                        )})}
                    </tr>
                }
                {paramsFromComponents.average3 &&
                    <tr key="averageplus3">
                        <td className="p-1 table-hover-hint" />
                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageplus3')}</td>
                        {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                            return (
                                <td className='ps-1 pe-1' key={`averageplus3-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].averagePlus3 ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].averagePlus3!) : 0}</td>
                            )
                        })}
                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                            return(
                                <td></td> 
                        )})}
                    </tr>
                }
                {paramsFromComponents.average3 &&
                    <tr key="averageminus3">
                        <td className="p-1 table-hover-hint" />
                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.averageminus3')}</td>
                        {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                            return (
                                <td className='ps-1 pe-1' key={`averagemin3-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].averageMinus3 ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].averageMinus3!) : 0}</td>
                            )
                        })}
                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                            return(
                                <td></td> 
                        )})}
                    </tr>
                }
                {paramsFromComponents.trend &&
                    <tr key="trend">
                        <td className="p-1 table-hover-hint" />
                        <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.trend')}</td>
                        {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                            return (
                                <td className='ps-1 pe-1' key={`trend-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].slope ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].slope!) : 0}</td>
                            )
                        })}
                        {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                            return(
                                <td></td> 
                        )})}
                    </tr>
                }
                <tr key="coef">
                    <td className="p-1 table-hover-hint" />
                    <td className='text-primary fw-bold pe-1' >{t('dashboard.selection.lines.coef')}</td>
                    {resultsHeader.length > 0 && valueForTable && valueForTable.value === value && valueForTable.dataOfAnalytes && resultsHeader.filter(rh => rh.display_in_selection).map(header => {
                        return (
                            <td className='ps-1 pe-1' key={`coef-${header.id}`}>{valueForTable.dataOfAnalytes[header.id.toUpperCase()] && valueForTable.dataOfAnalytes[header.id.toUpperCase()].coef ? Intl.NumberFormat(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 3}).format(valueForTable.dataOfAnalytes[header.id.toUpperCase()].coef!) : 0}</td>
                        )
                    })}
                    {fieldsForSamplesInfo.filter(field => field.display).map(field => {
                        return(
                            <td></td> 
                    )})}
                </tr>
            </tbody>
        </Table>
    )
}

export default withTranslation()(DashboardGroupTableComponent)