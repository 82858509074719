import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import ExternalServicesApi, { Alert } from "../api/externalServicesApi";
import { Data, FilterBy, Pagination, SortBy } from '../types/types';
import clone from 'clone'

export interface CurrentState {
    data: Data<Alert>;
    filtered: Array<string>;
    pagination: Pagination;
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    fetchError: string | undefined;
    deleteStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    deleteError?: string;
}

const initialCurrentState = {
    data: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: "idle",
        error: undefined,
    },
    fetchStatus: "idle",
    fetchError: undefined,
    deleteStatus: "idle",
    deleteError: undefined,
} as CurrentState

interface state {
    data: Data<Data<CurrentState>>
}

const initialState = {
    data: {}
} as state

export const fetchAlert = createAsyncThunk(
    'alert',
    async ({ laboratory_id, nir_code }: { laboratory_id: number, nir_code: string }, { dispatch }) => {
        const response = await ExternalServicesApi.getInstance().getAlerts(laboratory_id, nir_code);
        dispatch(alertReducer({ laboratoryId: laboratory_id, nir_code: nir_code, alerts: response }))
        return response
    }
)

export const purgeAlert = createAsyncThunk(
    'purgeAlert',
    async ({ laboratory_id, nir_code }: { laboratory_id: number, nir_code: string }, { dispatch }) => {
        await ExternalServicesApi.getInstance().deleteNirAlerts(laboratory_id, nir_code);
        dispatch(purgeReducer({ laboratory_id: laboratory_id, nir_code: nir_code}))
        return
    }
)

export const deleteAlert = createAsyncThunk(
    'deleteAlert',
    async ({ laboratory_id, nir_code, uuid }: { laboratory_id: number, nir_code: string, uuid: string }, { dispatch }) => {
        await ExternalServicesApi.getInstance().deleteNirAlert(laboratory_id, nir_code, uuid);
        dispatch(deleteReducer({ laboratory_id: laboratory_id, nir_code: nir_code, uuid : uuid}))
        return;
    }
)

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        alertReducer(state: state, action: PayloadAction<{ laboratoryId: number, nir_code: string, alerts: Alert[] }>) {
            const laboratoryId = action.payload.laboratoryId
            const nirCode = action.payload.nir_code
        
            action.payload.alerts.forEach((item: Alert) => {
                if (item.uuid)
                    state.data[item.laboratory_id][item.nir_code].data[item.uuid] = item
            });
            state.data[laboratoryId][nirCode].fetchStatus = 'succeeded'
        },
        setFiltered(
            state: state,
            action: PayloadAction<{ laboratory_id: number, nir_code: string, id: Array<string> }>
        ) {
            const laboratory_id = action.payload.laboratory_id;
            const nir_code = action.payload.nir_code;
            const id = action.payload.id;
            state.data[laboratory_id][nir_code].filtered = id;
        },
        setFilterBy(state: state, action: PayloadAction<{ laboratory_id: number, nir_code: string, filterBy : FilterBy}>) {
            const laboratory_id = action.payload.laboratory_id;
            const nir_code = action.payload.nir_code;
            const filterBy = { ...action.payload.filterBy };
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all;
            }
            state.data[laboratory_id][nir_code].pagination.filterBy = filterBy;
        },
        setSortBy(state: state, action: PayloadAction<{ laboratory_id: number, nir_code: string, sortBy : Array<SortBy>}>
        ) {
            const laboratory_id = action.payload.laboratory_id;
            const nir_code = action.payload.nir_code;
            const sortBy = action.payload.sortBy;
            state.data[laboratory_id][nir_code].pagination.sortBy = sortBy;
        },
        deleteReducer(state: state, action: PayloadAction<{ laboratory_id: number, nir_code: string, uuid : string}>) {
            const laboratory_id = action.payload.laboratory_id;
            const nir_code = action.payload.nir_code;
            const uuid = action.payload.uuid;
         
            delete state.data[laboratory_id][nir_code].data[uuid]
            state.data[laboratory_id][nir_code].filtered.splice(state.data[laboratory_id][nir_code].filtered.indexOf(uuid),1)
        },
        purgeReducer(state: state, action: PayloadAction<{ laboratory_id: number, nir_code: string}>) {
            const laboratory_id = action.payload.laboratory_id;
            const nir_code = action.payload.nir_code;
         
            state.data[laboratory_id][nir_code].data = {}
            state.data[laboratory_id][nir_code].filtered = []
        },
        requestPage(state: state, action: PayloadAction<{ laboratory_id: number, nir_code: string, currentPage : number}>) {
            const laboratory_id = action.payload.laboratory_id;
            const nir_code = action.payload.nir_code;
            const currentPage = action.payload.currentPage;
             state.data[laboratory_id][nir_code].pagination.currentPage = currentPage;

        },
        setNbByPage(state: state, action: PayloadAction<{ laboratory_id: number, nir_code: string, nbByPage : number}>) {
            const laboratory_id = action.payload.laboratory_id;
            const nir_code = action.payload.nir_code;
            const nbByPage = action.payload.nbByPage;

            state.data[laboratory_id][nir_code].pagination.nbByPage = nbByPage;
            const count =  state.data[laboratory_id][nir_code].filtered.length;
            state.data[laboratory_id][nir_code].pagination.count = count;
            state.data[laboratory_id][nir_code].pagination.nbPage = Math.ceil(
                count /  state.data[laboratory_id][nir_code].pagination.nbByPage
            );
        },
    },
    
    extraReducers: builder => {
        builder.addCase(fetchAlert.pending, (state, action) => {
            const laboratory_id = action.meta.arg.laboratory_id;
            const nir_code = action.meta.arg.nir_code;

            if (!state.data[laboratory_id]) {
                state.data[laboratory_id] = {}
            }
            if (!state.data[laboratory_id][nir_code]) {
                state.data[laboratory_id][nir_code] = clone(initialCurrentState)
            }

            state.data[laboratory_id][nir_code].fetchStatus = 'loading'
        })

        builder.addCase(fetchAlert.rejected, (state, action) => {
            const laboratory_id = action.meta.arg.laboratory_id;
            const nir_code = action.meta.arg.nir_code;

            state.data[laboratory_id][nir_code].fetchStatus = 'failed'
            state.data[laboratory_id][nir_code].fetchError = action.error.message
        })
    }
})

export const {
    alertReducer,
    deleteReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    setNbByPage,
    requestPage,
    purgeReducer
} = alertSlice.actions

export default alertSlice.reducer