import {
    ActionReducerMapBuilder,
    AsyncThunk,
    PayloadAction,
} from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import ApiError from "../common/utils/exceptions/ApiError";
import {
    Data,
    Pagination,
    ExecutionStatus,
    SortBy,
    FilterBy,
} from "../types/types";

export const isApiError = (obj: unknown): obj is ApiError => {
    return (
        typeof obj === 'object' && obj !== null && 'status' in obj && 'type' in obj && 'message' in obj
    );
}
export const isError = (obj: unknown): obj is Error => {
    return (
        typeof obj === 'object' && obj !== null && 'message' in obj
    );
}

export interface CurrentState<T> {
    data: Data<T>;
    filtered: Array<string>;
    pagination: Pagination;
    fetchStatus: ExecutionStatus;
    fetchError: string | undefined;
    singleFetchStatus: ExecutionStatus;
    singleFetchError: string | undefined;
    postStatus: ExecutionStatus;
    postError: string | undefined;
    deleteStatus: ExecutionStatus;
    deleteError?: string;
}

export function getInitialState<T>(): CurrentState<T> {
    return {
        data: {},
        filtered: [],
        pagination: {
            nbByPage: 25,
            nbPage: 0,
            currentPage: 1,
            filterBy: {},
            sortBy: [],
            pages: {},
            count: 0,
            status: "idle",
            error: undefined,
        },
        byCompany: {},
        fetchStatus: "idle",
        fetchError: undefined,
        singleFetchStatus: "idle",
        singleFetchError: undefined,
        postStatus: "idle",
        postError: undefined,
        deleteStatus: "idle",
        deleteError: undefined,
    } as CurrentState<T>;
}

export function getDefaultReducersWithFrontPagination<T, TKey,State extends CurrentState<T>=CurrentState<T>>(
    addItemFunction: (state: State, item: T) => void,
    postDeleteItemFunction: (state: State, item: TKey) => void
) {
    return {
        tableReducer(state: State, action: PayloadAction<T>) {
            const item = action.payload;
            addItemFunction(state, item);
        },
        setFiltered(
            state: State,
            action: PayloadAction<Array<string>>
        ) {
            state.filtered = action.payload;
        },
        setFilterBy(state: CurrentState<T>, action: PayloadAction<FilterBy>) {
            const filterBy = { ...action.payload };
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all;
            }
            state.pagination.filterBy = filterBy;
        },
        setSortBy(
            state: State,
            action: PayloadAction<Array<SortBy>>
        ) {
            state.pagination.sortBy = action.payload;
        },
        deleteReducer(state: State, action: PayloadAction<TKey>) {
            const item = action.payload;
            if (item) postDeleteItemFunction(state, item);
            state.deleteStatus = "succeeded";
        },
        requestPage(state: State, action: PayloadAction<number>) {
            state.pagination.currentPage = action.payload;
        },
        setNbByPage(state: State, action: PayloadAction<number>) {
            state.pagination.nbByPage = action.payload;
            const count = state.filtered.length;
            state.pagination.count = count;
            state.pagination.nbPage = Math.ceil(
                count / state.pagination.nbByPage
            );
        },
    };
}

export function buildDefaultExtraReducersWithFrontPagination<T, U,State extends CurrentState<T>=CurrentState<T>>(
    addItemFunction: (state: WritableDraft<State>, item: T) => void,
    builder: ActionReducerMapBuilder<CurrentState<T>>,
    fetchTable: AsyncThunk<T[], U, Record<string, never>>
) {
    builder.addCase(fetchTable.pending, (state) => {
        state.filtered = [];
        state.fetchStatus = "loading";
    });
    builder.addCase(fetchTable.fulfilled, (state, action) => {
        state.data = {};
        state.filtered = [];

        action.payload.forEach((item: T) => {
            addItemFunction(state as WritableDraft<State>, item);
        });

        const count = state.filtered.length;
        state.pagination.count = count;
        state.pagination.nbPage = Math.ceil(count / state.pagination.nbByPage);
        state.fetchStatus = "succeeded";
    });
    builder.addCase(fetchTable.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
    });
}
