import React, { useEffect, useState } from "react";

import {
  Button,
  Toast,
  ProgressBar,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { ReactComponent as SquareFillIcon } from "bootstrap-icons/icons/square-fill.svg";
import { useTranslation } from "react-i18next";
import { RootState } from "../../state/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCancelExportJob,
  fetchDownloadExcel,
  fetchExportExcelStatus,
  postExportExcel,
} from "../../state/exportExcelSlice";
import { AppDispatch } from "../../state/store";
import { isError } from "../../state/DefaultSlice";

let interval: NodeJS.Timer | null = null;

const SWExportExcelProgress: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user: authUser } = useSelector((state: RootState) => state.authUser);

  const { t } = useTranslation();

  const exportResponses = useSelector(
    (state: RootState) => state.exportResponses.data
  );
  const exportStatus = useSelector(
    (state: RootState) => state.exportResponses.dataStatus
  );

  const [showDic, setShowDic] = useState<{[key: string]: { show: boolean, closable: boolean }}>({});

  const [showErrorDic, setShowErrorDic] = useState<{[key: string]: boolean}>({});
  const [apiErrorDic, setApiErrorDic] = useState<{[key: string]: string | undefined}>({});

  const [downloadOnGoing, setDownloadOnGoing] = useState<{[key: string]: boolean}>({})

  const updateShowDic = (id: string, show?: boolean, closable?: boolean) => {
    const clone = {...showDic}
    clone[id] = Object.assign(
      {...clone[id]},
      show !== undefined ? {show} : null,
      closable !== undefined ? {closable} : null,
    )
    setShowDic(clone)
  }

  const checkStatus = () => {
    // don't check if user is not connected
    if (authUser.id !== 0) {
      Object.keys(exportResponses).forEach(async (id) => {
        if (
          !exportStatus[id] ||
          !["cancelled", "done", "failed"].includes(exportStatus[id].status)
        ) {
          await dispatch(fetchExportExcelStatus(id)).unwrap();
          updateShowDic(id, true, false)
        }
      });
    }
  };

  useEffect(() => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(checkStatus, 2500);
  }, [exportResponses, exportStatus, authUser]);

  function showApiError(show: boolean, id: string, err?: unknown) {
    const showError = { ...showErrorDic };
    showError[id] = show;
    setShowErrorDic(showError);

    if (isError(err)) {
      updateShowDic(id, undefined, true)
      const apiError = { ...apiErrorDic };
      apiError[id] = err.message;
      setApiErrorDic(apiError);
    }
  }
  
  /**
   * Return warning component that notify the user that an update is available
   */
  return (
    <>
      {Object.keys(exportStatus).map((id) => {
        
        let progressVariant: string|undefined
        if (exportStatus[id]?.status === 'failed') progressVariant = "danger"
        if (exportStatus[id]?.status === 'done') progressVariant = "success"
        if (exportStatus[id]?.status === 'created') progressVariant = "warning"

        let headerVariant = 'text-primary'
        if (exportStatus[id]?.status === 'failed') headerVariant = "text-danger"
        if (exportStatus[id]?.status === 'done') headerVariant = "text-success"
        if (exportStatus[id]?.status === 'created') headerVariant = "text-warning"

        return (
          <Toast 
            key={id} 
            show={showDic[id] && showDic[id].show} 
            onClose={() => {
              updateShowDic(id, false)
            }}
          >
            <Toast.Header closeButton={showDic[id] && showDic[id].closable || exportStatus[id].status === 'failed'}>
              <SquareFillIcon
                width="20px"
                height="100%"
                className={`${headerVariant} rounded me-2`}
              />

              {exportStatus[id]?.status === "done" && !downloadOnGoing[id] && (
                <strong className="me-auto">{t("notif_export.title_exported")}</strong>
              )}

              {exportStatus[id]?.status === "done" && downloadOnGoing[id] && (
                <strong className="me-auto">{t("notif_export.title_downloading")}</strong>
              )}


              {exportStatus[id]?.status === "running" && (
                <strong className="me-auto">{t("notif_export.title_exporting")}</strong>
              )}

              {exportStatus[id]?.status === "created" && (
                <strong className="me-auto">{t("notif_export.title_waiting")}</strong>
              )}

              {exportStatus[id]?.status === "failed" && (
                <strong className="me-auto text-danger">{t("notif_export.title_failed")}</strong>
              )}
                
              

            </Toast.Header>
            <Toast.Body>

              
              <ProgressBar now={exportStatus[id].progress} variant={progressVariant} />

              <br/>
              { exportStatus[id]?.status === 'failed' &&
                <Alert variant="danger">
                    <span>{t("notif_export.text_failed")}</span>
                    <br/>
                </Alert>
              }

              { exportStatus[id]?.status === 'created' &&
                <Alert variant="warning">
                    <span>{t("notif_export.text_waiting")}</span>
                    <br/>
                </Alert>
              }

              { showErrorDic[id] &&
                <Alert variant="danger">
                    {apiErrorDic[id]}
                </Alert>
              }
                

              <Row className="mt-2">
                <Col className="text-end">

                  {['running','created','done'].includes(exportStatus[id]?.status) && (
                    <Button
                      disabled = {downloadOnGoing[id]}
                      className="ms-2"
                      variant="secondary"
                      onClick={async () => {
                        try {
                            await dispatch(fetchCancelExportJob(id)).unwrap()
                            showApiError(false, id)
                        } catch (err) {
                            showApiError(true, id, err)
                        }

                      }}
                    >
                      {t("buttons.cancel")}
                    </Button>
                  )}

                  {exportStatus[id]?.status === "done" && (
                    <Button
                      disabled={downloadOnGoing[id]}
                      className="ms-2"
                      variant="secondary"
                      onClick={async () => {

                        try{
                          const cloneDownloadOnGoing = {...downloadOnGoing}
                          cloneDownloadOnGoing[id] = true
                          setDownloadOnGoing({
                            ...cloneDownloadOnGoing,
                            
                          })
                          await dispatch(fetchDownloadExcel(exportStatus[id])).unwrap()
                          cloneDownloadOnGoing[id] = false

                          setDownloadOnGoing(cloneDownloadOnGoing)

                          showApiError(false, id)
                         }
                        catch (err) {
                          showApiError(true, id, err)
                          }
                      }}
                    >
                      {t("buttons.download")}
                    </Button>
                  )}

                  {(exportStatus[id]?.status === "failed" || showErrorDic[id]) && (
                    <Button
                      variant="secondary"
                      onClick={async () => {

                        try{
                          dispatch(postExportExcel(exportResponses[id].config)).unwrap()
                          updateShowDic(id, false)
                          showApiError(false, id)
                         }
                        catch (err) {
                          showApiError(true, id, err)
                          }
                      }}
                    >
                      {t("buttons.retry")}
                    </Button>
                  )}


                </Col>
              </Row>
            </Toast.Body>
          </Toast>
        );
      })}
    </>
  );
};

export default SWExportExcelProgress;
