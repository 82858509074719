import React from 'react';
import Row from 'react-bootstrap/Row'
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../state/store';
import { postMatrixJob } from '../../state/matrixJobsSlice';
import { MatrixRequestImport } from '../../api/matrixApi';

export interface MatrixImportProps extends WithTranslation{
    type: 'button' | 'link'
}

const MatrixImport: React.FC<MatrixImportProps> = ({t, type}) => {
    const dispatch = useDispatch<AppDispatch>();

    const SUPPORTED_TYPES = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]

    const onExcelFileChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        
        if (e.target.files && e.target.files[0]) {

        const request: MatrixRequestImport = {
            type: 'import',
            file: e.target.files[0]

        }
        
        dispatch(postMatrixJob(request))

        e.target.value = ''
    }
    };


    return (
        <Form.Group controlId={'test_matrix'} >

            {type === 'link' &&
                <Form.Label className="hover-button dropdown-item no-bottom">
                    {t('nav.import_matrix')}
                </Form.Label>
            }

            {type === 'button' &&
            <Button className='w-100' variant="secondary" type="button">
                <Form.Label className="m-0 fw-normal px-1 hover-button">
                    {t('nav.import_matrix')}
                </Form.Label>
            </Button>

            }
            <Form.Control 
                style={{display:'none'}} 
                className='me-1' 
                type="file" 
                accept={SUPPORTED_TYPES.join(',')}
                onChange={onExcelFileChange}
            />                 
        </Form.Group>
    )
}

export default withTranslation()(MatrixImport)
