import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../state/rootReducer';

import { Container, Form, Button, Row, Col } from 'react-bootstrap';

import Loader from '../../../common/components/Loader';
import { Formik } from 'formik';

import * as Yup from 'yup';

import UsersApi from '../../../api/usersApi';

import { farmerReducer } from '../../../state/farmerSlice';

interface FarmerContainerProps extends WithTranslation {
    farmerObject: any
}

const FarmerForm: React.FC<FarmerContainerProps> = ({t, i18n, farmerObject}) => {
    
    const dispatch = useDispatch()
   
    const [apiError, setApiError] = useState(undefined)
    const [formSubmitted, setFormSubmitted] = useState(false)

     // farmers state
     const farmers = useSelector((state: RootState) => state.farmers.farmer.data)

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim()
            .max(50, t('forms_errors.max_length'))
            .required(t('admin.farmers.errors.name_required'))
            .test("test-name", t('admin.farmers.errors.name_exists'), 
            function(value:any) {
                if(Object.values(farmers).filter(item => item.name === value && item.employee_id === this.parent.employeeId  && item.id !== farmerObject.id).length > 0 ){
                  return false;
                }else{
                  return true
                }
            }),
        zip_code: Yup.string()
            .max(18, t('forms_errors.max_length')),
        city: Yup.string()
            .max(255, t('forms_errors.max_length')),
        country: Yup.string()
            .max(255, t('forms_errors.max_length'))
    })

    return (
        <Container> 

            { formSubmitted && 
                <Container className="mt-3 mb-3">
                   {farmerObject.id === 0 ? t('forms.farmers.msg_success_add') : t('forms.farmers.msg_success_modify')}<br />
                </Container>
            }

            { !formSubmitted &&
                <Formik
                    initialValues={{ 
                        employeeId: farmerObject.employee_id, 
                        code: farmerObject.code, 
                        name: farmerObject.name, 
                        zip_code: farmerObject.zip_code, 
                        city: farmerObject.city, 
                        country: farmerObject.country,
                        active: farmerObject.active
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting, setFieldError}) => {
                        // When button submits form and form is in the process of submitting, submit button is disabled
                        setSubmitting(true)
                        setApiError(undefined)
            
                        const farmer: any = {
                            employee_id: values.employeeId,
                            name: values.name,
                            code: values.code,
                            zip_code: values.zip_code,
                            city: values.city,
                            country: values.country,
                            active: values.active
                        }

                        if(farmerObject.id === 0){
                            
                            UsersApi.getInstance().postFarmer(farmer)
                                .then((response: any) => {
                                    dispatch(farmerReducer(response))
                                    setFormSubmitted(true)
                                })
                                .catch((err) => {
                                    console.error(err)
                                    setApiError(t('forms_errors.unexpected_error'))
                                })
                                .finally(() => {
                                    setSubmitting(false)
                                })
                        }else{

                            UsersApi.getInstance().putFarmer(farmer, farmerObject.id)
                                .then((response) => {
                                    farmer.id = farmerObject.id
                                    dispatch(farmerReducer(farmer))
                                    setFormSubmitted(true)
                                })
                                .catch((err) => {
                                    setApiError(t('forms_errors.unexpected_error'))
                                })
                                .finally(() => {
                                    setSubmitting(false)
                                })
                        }
                    }}
                >
                    {/* Callback function containing Formik state and helpers that handle common form actions */}
                    {( {values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            { isSubmitting && 
                                <Loader />
                            }
                            { apiError && 
                                <Container className="text-danger">
                                    {farmerObject.id === 0 ? t('forms.farmers.msg_failed_add') : t('forms.farmers.msg_failed_modify')}
                                    <br /> 
                                    {apiError}
                                </Container>
                            }

                            <Row>
                                <Col sm="6">
                                    <Form.Group controlId="formName">
                                        <Form.Label>{t('forms.farmers.name')} *</Form.Label>
                                        <Form.Control className={touched.name && errors.name ? "is-invalid" : undefined} type="text" name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
                                        <Form.Text className="invalid-feedback">{errors.name}</Form.Text>
                                    </Form.Group>
                                </Col>

                                <Col sm="6">
                                    <Form.Group controlId="formCode">
                                        <Form.Label>{t('forms.farmers.code')}</Form.Label>
                                        <Form.Control className={touched.code && errors.code ? "is-invalid" : undefined} type="text" name='code' value={values.code} onChange={handleChange} onBlur={handleBlur} />
                                        <Form.Text className="invalid-feedback">{errors.code}</Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="4">
                                    <Form.Group controlId="formZipCode">
                                        <Form.Label>{t('forms.farmers.zip_code')}</Form.Label>
                                        <Form.Control className={touched.zip_code && errors.zip_code ? "is-invalid" : undefined} type="text" name='zip_code' value={values.zip_code} onChange={handleChange} onBlur={handleBlur} />
                                        <Form.Text className="invalid-feedback">{errors.zip_code}</Form.Text>
                                    </Form.Group>
                                </Col>
                            
                                <Col sm="8">
                                    <Form.Group controlId="formCity">
                                        <Form.Label>{t('forms.farmers.city')}</Form.Label>
                                        <Form.Control className={touched.city && errors.city ? "is-invalid" : undefined} type="text" name='city' value={values.city} onChange={handleChange} onBlur={handleBlur} />
                                        <Form.Text className="invalid-feedback">{errors.city}</Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group controlId="formCountry">
                                <Form.Label>{t('forms.farmers.department')}</Form.Label>
                                <Form.Control className={touched.country && errors.country ? "is-invalid" : undefined} type="text" name='country' value={values.country} onChange={handleChange} onBlur={handleBlur} />
                                <Form.Text className="invalid-feedback">{errors.country}</Form.Text>
                            </Form.Group>

                            <Container className="text-end">
                                <Button disabled={isSubmitting} variant="secondary" type="submit">
                                    {farmerObject.id === 0 ? t('buttons.add') : t('buttons.edit')}
                                </Button>
                            </Container>
                        </Form>
                    )}
                </Formik>
            }
        </Container>
    )
}

export default withTranslation()(FarmerForm)