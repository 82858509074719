import React, { ChangeEvent } from 'react';
import dbt from '../utils/dbTranslation';

import { useSelector } from 'react-redux';
import { RootState } from '../../state/rootReducer';

import { FormSelect } from 'react-bootstrap';

type InputEvent = ChangeEvent<HTMLSelectElement>
type ChangeHandler = (e: InputEvent) => void

interface LanguageSelectControlProps {
    items: any
    name: string
    currentItemId: number
    disabled?:boolean
    isInvalid?:boolean
    onChange: ChangeHandler
}

const SelectControl: React.FC<LanguageSelectControlProps> = ({items, name, currentItemId, onChange, disabled = false, isInvalid= false}) => {
    
    const { language } = useSelector( (state: RootState) => state.local )

    return (
        <FormSelect isInvalid = {isInvalid} disabled = {disabled} name={name} value={currentItemId} onChange={onChange} >
            {Object.keys(items).map(id => { 
                return (
                    <option value={items[id].id} key={items[id].id} >
                        {dbt(items, items[id].id, language.id, "name")}
                    </option>
                )
            })}
        </FormSelect>
    )
}

export default SelectControl
