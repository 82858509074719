import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row, Container, Col, Button } from 'react-bootstrap';
import ModalDashboardParamsAnalytesComponent from './ModalDashboardParamsAnalytesComponent';
import ModalDashboardParamsSamplesInfoComponent from './ModalDashboardParamsSamplesInfoComponent';
import ModalDashboardParamsOutliersComponent from './ModalDashboardParamsOutliersComponent';
import ModalDashboardParamsWeightComponent from './ModalDashboardParamsWeightComponent';

interface DashboardParamsComponentContainerProps extends WithTranslation {
    dashboardName: string
}

const DashboardParamsComponent: React.FC<DashboardParamsComponentContainerProps> = ({ t, dashboardName }) => {
    // local state
    const [showAnalytes, setShowAnalytes] = useState(false);
    const [showSamplesInfo, setShowSamplesInfo] = useState(false);
    const [showOutliers, setShowOutliers] = useState(false);
    const [showWeight, setShowWeight] = useState(false)

    return (
        <Container className={'mt-2 p-0 ms-0 me-0'}>
            <ModalDashboardParamsAnalytesComponent dashboardName={dashboardName} showModal={showAnalytes} setShowModal={setShowAnalytes} />
            <ModalDashboardParamsSamplesInfoComponent dashboardName={dashboardName} showModal={showSamplesInfo} setShowModal={setShowSamplesInfo} />
            <ModalDashboardParamsOutliersComponent dashboardName={dashboardName} showModal={showOutliers} setShowModal={setShowOutliers} />
            <ModalDashboardParamsWeightComponent dashboardName={dashboardName} showModal={showWeight} setShowModal={setShowWeight} />

            <Container className="p-2 bg-white rounded-3 mb-0">
                <Row>
                    <Col lg>
                        <h4 className="text-primary fw-bold">
                            {t('dashboard.params.title')}
                        </h4>
                    </Col>
                </Row>
                <Row className="pb-3" ><Col lg className="lineTitle"></Col></Row>
                {/* Aanlyte */}
                <Button className="no-print w-100 m-auto" variant="secondary" onClick={() => {
                    setShowAnalytes(true)
                }} >
                    {t('dashboard.params.analytes')}
                </Button>
                {/* outliers */}
                <Button className="no-print w-100 m-auto mt-2" variant="secondary" onClick={() => {
                    setShowOutliers(true)
                }} >
                    {t('dashboard.params.outliers')}
                </Button>
                {dashboardName === 'matrix' &&
                    <Button className="no-print w-100 m-auto mt-2" variant="secondary" onClick={() => {
                        setShowWeight(true)
                    }} >
                        {t('dashboard.params.weight')}
                    </Button>
                }
                {/* Samples info */}
                <Button className="no-print w-100 m-auto mt-2" variant="secondary" onClick={() => {
                    setShowSamplesInfo(true)
                }} >
                    {t('dashboard.params.samples_info')}
                </Button>
            </Container>
        </Container>
    )
}

export default withTranslation()(DashboardParamsComponent)