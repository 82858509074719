import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button, Dropdown, Container } from 'react-bootstrap';
import { Filter, FilterEnum } from '../../types/types';
import FilterForm from './FilterForm';

import { ReactComponent as NotFiltered } from 'bootstrap-icons/icons/funnel.svg';
import { ReactComponent as Square } from 'bootstrap-icons/icons/square.svg';
import { ReactComponent as XSquare } from 'bootstrap-icons/icons/x-square.svg';
import { ReactComponent as Filtered } from 'bootstrap-icons/icons/funnel-fill.svg';
import { ReactComponent as SortDesc } from 'bootstrap-icons/icons/caret-down-fill.svg';
import { ReactComponent as SortAsc } from 'bootstrap-icons/icons/caret-up-fill.svg';
import { ReactComponent as NoSort1 } from 'bootstrap-icons/icons/caret-up.svg';
import { ReactComponent as NoSort2 } from 'bootstrap-icons/icons/caret-down.svg';
import { ReactComponent as Delete } from 'bootstrap-icons/icons/x-circle-fill.svg'
import { ReactComponent as Check } from 'bootstrap-icons/icons/check-circle-fill.svg'

export type sortElement = {
    idElement: string
    sort: string
    title: string
}

export type filterElement = {
    idElement: string
    filter: string[]
    title: string
}

export type filterDate = {
    idElement: string
    dateFrom: string
    dateTo: string
    title: string
}

interface ButtonHeaderListProps {

    title: string
    name: string
    type: Array<"label" | "date" | "filter" | "list" | "check" | "number" | 'boolean'>
    filterByEnum?: FilterEnum[]
    boolLabels?: { [key in 'true' | 'false']: string }
    curFilter?: Filter
    addFilter?: (name: string, filter: Filter | undefined) => void
    sortable?: boolean
    curSort?: string
    addSort?: (name: string, direction: string) => void
    isChecked?: boolean
    onClickSelect?: (checked: boolean) => void
    size?: "xs" | "sm"
    maxHeight?: string
    disableFilter?: boolean
    deleteIcon?: boolean
    deleteIconState?: boolean
    onClickDelete?: () => void
    addClassToButton?: string
    addStyleToButton?: any
    mergeTwin?: boolean
}

const ButtonHeaderList: React.FC<ButtonHeaderListProps> = ({ addSort = () => (null), title, name, type, filterByEnum = [], addFilter = () => (null), curSort = "", sortable = false, curFilter = undefined, onClickSelect = () => (null), isChecked = false, boolLabels, size = "sm", maxHeight, disableFilter = false, deleteIcon = false, deleteIconState = true, onClickDelete = () => (null), addClassToButton = "", addStyleToButton = null, mergeTwin }) => {

    const [filter, setFilter] = useState<Filter | undefined>(curFilter)


    useEffect(() => {
        setFilter(curFilter)
    }, [curFilter])

    const filterReducer = (name: string, filter: Filter | undefined) => {
        setFilter(filter)
    }

    interface FilterToggleProps extends React.ComponentPropsWithoutRef<'button'> {
        isFiltered: boolean
    }

    const FilterToggle = React.forwardRef<HTMLButtonElement, FilterToggleProps>(function FilterToggle({ children, onClick, isFiltered }, ref) {
        return (
            <Button
                ref={ref}
                onClick={onClick}
                size="sm"
                className={"p-0 ps-1 pe-1" + (size === "xs" ? " lh-1" : "")}
            >
                {children}
                {isFiltered &&
                    <Filtered width={size === "xs" ? "8px" : "12px"} height="100%" className="align-text-top" />
                }
                {!isFiltered &&
                    <NotFiltered width={size === "xs" ? "8px" : "12px"} height="100%" className="align-text-top" />
                }
            </Button>
        )
    });



    interface SelectAllProps extends React.ComponentPropsWithoutRef<'button'> {
        isSelected: boolean
    }

    const SelectAll = React.forwardRef<HTMLButtonElement, SelectAllProps>(function SelectAll({ onClick, isSelected, children }, ref) {
        return (
            <Button
                ref={ref}
                onClick={onClick}
                size="sm"
                className={"p-0 ps-1 pe-1" + (size === "xs" ? " lh-1" : "")}
                style={{ borderBottomLeftRadius: 3, borderTopLeftRadius: 3 }}
            >
                {children}
                {!isSelected &&
                    <Square width={size === "xs" ? "14px" : "16px"} height="100%" className="align-text-top" />
                }
                {isSelected &&
                    <XSquare width={size === "xs" ? "14px" : "16px"} height="100%" className="align-text-top" />
                }

            </Button>

        )
    });

    interface SortButtonProps extends React.ComponentPropsWithoutRef<'button'> {
        sort: string
    }

    const SortToggle = React.forwardRef<HTMLButtonElement, SortButtonProps>(function SortToggle({ children, onClick, sort }, ref) {
        return (
            <Button
                ref={ref}
                onClick={onClick}
                size="sm"
                className={"p-0 ps-1 pe-1" + (size === "xs" ? " lh-1" : "")}
            >
                {children}
                {sort !== "-" && sort !== "+" &&
                    <div className="d-flex flex-column">
                        <NoSort1 width={size === "xs" ? "6px" : "10px"} height="100%" className="align-text-top" />
                        <NoSort2 width={size === "xs" ? "6px" : "10px"} height="100%" className="align-text-top" />
                    </div>
                }
                {sort === "-" &&
                    <SortDesc width={size === "xs" ? "8px" : "12px"} height="100%" className="align-text-top" />
                }
                {sort === "+" &&
                    <SortAsc width={size === "xs" ? "8px" : "12px"} height="100%" className="align-text-top" />
                }
            </Button>
        )
    });

    return (
        <ButtonGroup className="w-100" size="sm" >
            {title !== "" &&
                <Container as={ButtonGroup} size="sm" className="p-0 header-btn text-nowrap">
                    <Button disabled={!deleteIcon} onClick={() => onClickDelete()} className={(size === "xs" ? "fs-9 lh-1  header-btn-fs " : "") + addClassToButton} style={addStyleToButton}>
                        {title}
                        {deleteIcon && deleteIconState &&
                            <Delete className="align-text-top p-0 ms-1" width="15px" height="100%" />
                        }
                        {deleteIcon && !deleteIconState &&
                            <Check className="align-text-top p-0 ms-1" width="15px" height="100%" />
                        }
                    </Button>
                </Container>
            }
            {(type.includes("filter") || type.includes("date") || type.includes("list") || type.includes("number") || type.includes('boolean') || filterByEnum.length > 0) && !disableFilter &&
                <Dropdown as={ButtonGroup} autoClose="outside" data-bs-boundary="body"  >
                    <Dropdown.Toggle as={FilterToggle} size="sm" variant="secondary" id="dropdown-basic" isFiltered={!!curFilter}  />
                    <Dropdown.Menu >
                        <FilterForm name={name} type={type} filter={filter} filterReducer={filterReducer} addFilter={addFilter} filterByEnum={filterByEnum} boolLabels={boolLabels} size={size} maxHeight = {maxHeight} mergeTwin={mergeTwin} />
                    </Dropdown.Menu>
                </Dropdown>
            }
            {sortable &&
                <Dropdown as={ButtonGroup}>
                    <SortToggle sort={curSort}
                        onClick={() => {
                            if (curSort !== "-" && curSort !== "+") {
                                addSort(name, "+")
                            } else if (curSort === "-") {
                                addSort(name, "+")
                            } else if (curSort === "+") {
                                addSort(name, "-")
                            }
                        }}
                    />
                </Dropdown>
            }


            {type.includes("check") &&
                <Dropdown className="mx-auto" as={ButtonGroup}>
                    <SelectAll isSelected={isChecked}
                        onClick={() => {
                            onClickSelect(!isChecked)
                        }}
                    />
                </Dropdown>
            }

        </ButtonGroup>
    )
}

export default ButtonHeaderList
