import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import UsersApi, { Company } from '../api/usersApi';
import { Data, FilterBy, Pagination, SortBy } from '../types/types';

interface CurrentState {
    data: Data<Company>
    byLabId: Data<Array<string>>
    byFormulation: Data<Array<string>>
    filtered: Array<string>
    pagination: Pagination
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchError: string | undefined
    fetchAllStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    fetchAllError: string | undefined
    filterStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    singleFetchError: string | undefined
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
    postError: string | undefined
}

const initialState = {
    data: {},
    byLabId: {},
    byFormulation: {},
    filtered: [],
    pagination: {
        nbByPage: 25,
        nbPage: 0,
        currentPage: 1,
        filterBy: {},
        sortBy: [],
        pages: {},
        count: 0,
        status: 'idle',
        error: undefined
    },
    fetchStatus: 'idle',
    fetchError: undefined,
    fetchAllStatus: 'idle',
    fetchAllError: undefined,
    filterStatus: 'idle',
    singleFetchStatus: 'idle',
    singleFetchError: undefined,
    postStatus: 'idle',
    postError: undefined
} as CurrentState

export const fetchCompanies = createAsyncThunk(
    'companies/fetchCompanies', 
    async (laboratoryId?: number) => {
        const response = await UsersApi.getInstance().getCompanies(laboratoryId)
        return response as Array<Company>
    }
)

export const fetchCompaniesByFormulation = createAsyncThunk(
    'companies/fetchCompaniesByFormulation', 
    async (formulationId?: number) => {
        const response = await UsersApi.getInstance().getCompaniesByFormulation(formulationId)
        return response as Array<Company>
    }
)

export const fetchExportCompanies = createAsyncThunk(
    'config/fetchExportCompanies', 
    async (formulationId: number) => {
        const response =  await UsersApi.getInstance().exportCompanies(formulationId)
        return response 
    }
)

export const fetchAllCompanies = createAsyncThunk(
    'companies/fetchAllCompanies', 
    async () => {
        const response = await UsersApi.getInstance().getAllCompanies()
        return response as Array<Company>
    }
)

export const fetchSingleCompany = createAsyncThunk(
    'companies/fetchSingleCompany', 
    async (id: number) => {
        const response = await UsersApi.getInstance().getCompanyById(id)
        return response as Company
    }
)

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        addCompany(state: CurrentState, action: PayloadAction<Company>) {
            const item = action.payload
            if (item && item.id)
                state.data[item.id] = item
                if (!item.client_laboratory_id) {
                    if (!state.byLabId[item.laboratory_id])
                        state.byLabId[item.laboratory_id] = []
                    state.byLabId[item.laboratory_id].push(String(item.id))
                } else {
                    if (!state.byLabId[item.client_laboratory_id])
                        state.byLabId[item.client_laboratory_id] = []
                    state.byLabId[item.client_laboratory_id].push(String(item.id))
                }
        },
        updateCompany(state: CurrentState, action: PayloadAction<Company>) {
            const item = action.payload
            if (item && item.id)
                state.data[item.id] = item
                if (item.id && state.byLabId[item.laboratory_id].indexOf(item.id.toString()) === -1)
                    state.byLabId[item.laboratory_id].push(String(item.id))
        },
        deleteCompanyReducer(state: CurrentState, action: PayloadAction<Company>){
            const item = action.payload
            if (item.id) {
                state.data[item.id].active = false
                if (state.byLabId[item.laboratory_id].indexOf(item.id.toString()) !== -1)
                    state.byLabId[item.laboratory_id].splice(state.byLabId[item.laboratory_id].indexOf(item.id.toString()), 1)
            }
        },
        setFiltered(state: CurrentState, action: PayloadAction<Array<string>>) {
            state.filtered = action.payload
            state.filterStatus = 'succeeded'
        },
        setFilterBy(state: CurrentState, action: PayloadAction<FilterBy>) {
            const filterBy = {...action.payload}
            if (filterBy.all && filterBy.all.like === "") {
                delete filterBy.all
            }
            state.pagination.filterBy = filterBy
        },
        setSortBy(state: CurrentState, action: PayloadAction<Array<SortBy>>) {
            state.pagination.sortBy = action.payload
        },
        refreshFilter(state: CurrentState) {
            state.filterStatus = 'idle'
        },
        refreshCompanies(state: CurrentState){
            state.fetchStatus = 'idle'
        },
        resetSingleFetch(state: CurrentState) {
            state.singleFetchStatus = 'idle'
            state.singleFetchError = undefined
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCompanies.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchCompanies.fulfilled, (state, action) => {
            if (action.meta.arg && state.byLabId) delete state.byLabId[action.meta.arg]
            if (action.meta.arg && !state.byLabId[action.meta.arg]) {
                state.byLabId[action.meta.arg] = []
            }
            action.payload.forEach((item: Company) => {
                if (!state.byLabId[item.laboratory_id]) state.byLabId[item.laboratory_id] = []
                if (item.active && item.id && state.byLabId[item.laboratory_id] && state.byLabId[item.laboratory_id].indexOf(item.id.toString()) === -1) 
                    state.byLabId[item.laboratory_id].push(item.id.toString())

                if (item.client_laboratory_id) {
                    if (!state.byLabId[item.client_laboratory_id]) state.byLabId[item.client_laboratory_id] = []
                    if (item.active && item.id && state.byLabId[item.client_laboratory_id] && state.byLabId[item.client_laboratory_id].indexOf(item.id.toString()) === -1) 
                        state.byLabId[item.client_laboratory_id].push(item.id.toString())
                }

                if (item.id) 
                    state.data[item.id] = item
            })
            state.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchCompanies.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
        builder.addCase(fetchCompaniesByFormulation.pending, (state) => {
            state.fetchStatus = 'loading'
        })
        builder.addCase(fetchCompaniesByFormulation.fulfilled, (state, action) => {
            if (action.meta.arg && state.byFormulation) delete state.byFormulation[action.meta.arg]
            if (action.meta.arg && !state.byFormulation[action.meta.arg]) {
                state.byFormulation[action.meta.arg] = []
            }
            action.payload.forEach((item: Company) => {
                if(item.formulation_id){
                    if (!state.byFormulation[item.formulation_id]) state.byFormulation[item.formulation_id] = []
                    if (item.active && item.id && state.byFormulation[item.formulation_id] && state.byFormulation[item.formulation_id].indexOf(item.id.toString()) === -1) 
                        state.byFormulation[item.formulation_id].push(item.id.toString())
    
                    if (item.id) 
                        state.data[item.id] = item
                }
            })
            state.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchCompaniesByFormulation.rejected, (state, action) => {
            state.fetchStatus = 'failed'
            state.fetchError = action.error.message
        })
        builder.addCase(fetchAllCompanies.pending, (state) => {
            state.fetchAllStatus = 'loading'
        })
        builder.addCase(fetchAllCompanies.fulfilled, (state, action) => {
            state.byLabId = {}
            action.payload.forEach((item: Company) => {
                if (!state.byLabId[item.laboratory_id]) state.byLabId[item.laboratory_id] = []
                if (item.active && item.id && state.byLabId[item.laboratory_id] && state.byLabId[item.laboratory_id].indexOf(item.id.toString()) === -1) 
                    state.byLabId[item.laboratory_id].push(item.id.toString())

                if (item.client_laboratory_id) {
                    if (!state.byLabId[item.client_laboratory_id]) state.byLabId[item.client_laboratory_id] = []
                    if (item.active && item.id && state.byLabId[item.client_laboratory_id] && state.byLabId[item.client_laboratory_id].indexOf(item.id.toString()) === -1) 
                        state.byLabId[item.client_laboratory_id].push(item.id.toString())
                }

                if (item.id) 
                    state.data[item.id] = item
            })
            state.fetchAllStatus = 'succeeded'
            state.fetchStatus = 'succeeded'
        })
        builder.addCase(fetchAllCompanies.rejected, (state, action) => {
            state.fetchAllStatus = 'failed'
            state.fetchAllError = action.error.message
        })
        builder.addCase(fetchSingleCompany.pending, (state) => {
            state.singleFetchStatus = 'loading'
        })
        builder.addCase(fetchSingleCompany.fulfilled, (state, action) => {
            const item = action.payload
            if (item && item.id) {
                if (item.id !== undefined) {
                    if(!state.byLabId[item.laboratory_id]){
                        state.byLabId[item.laboratory_id] = []
                    }
                    state.byLabId[item.laboratory_id].push(item.id.toString())
                    state.data[item.id] = item
                }
            }
            state.singleFetchStatus = 'succeeded'
        })
        builder.addCase(fetchSingleCompany.rejected, (state, action) => {
            state.singleFetchStatus = 'failed'
            state.singleFetchError = action.error.message
        })
    }
  })

  export const {
    addCompany,
    updateCompany,
    setFiltered,
    setFilterBy,
    setSortBy,
    refreshFilter,
    resetSingleFetch,
    deleteCompanyReducer,
    refreshCompanies
  } = companiesSlice.actions

  export default companiesSlice.reducer