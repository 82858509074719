import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { buildDefaultExtraReducersWithFrontPagination, CurrentState, getDefaultReducersWithFrontPagination, getInitialState } from './DefaultSlice';
import DashboardApi, { ManageDashboard, getKeyForTabAssocManageDashboard } from '../api/dashboardApi';

const initialState = getInitialState<ManageDashboard>()

export const getManageDashboard = createAsyncThunk(
    'getManageDashboard', 
    async () => {
        return await DashboardApi.getInstance().getManageDashboard() 
    }
)

export const putManageDashboard = createAsyncThunk(
    'putManageDashboard', 
    async (body : ManageDashboard[]) => {
       return await DashboardApi.getInstance().putManageDashboard(body)
    }
)

const addItemToDashboard =   (state:CurrentState<ManageDashboard>,item:ManageDashboard) => {
    state.data[getKeyForTabAssocManageDashboard(item)] = item
}

const postDeleteDashboard = (state:CurrentState<ManageDashboard>,item:ManageDashboard)=> {
    delete state.data[getKeyForTabAssocManageDashboard(item)]
    state.filtered.splice(state.filtered.indexOf(getKeyForTabAssocManageDashboard(item)),1)
}

const manageDashboardSlice = createSlice({
    name: 'manageDashboard',
    initialState,
     reducers: {...getDefaultReducersWithFrontPagination(addItemToDashboard,postDeleteDashboard), 
        updateDashboard(state:CurrentState<ManageDashboard>,action: PayloadAction<ManageDashboard[]>){
        state.data = {}
        action.payload.sort((a,b) => a.dashboard_name > b.dashboard_name ? 1 : -1).forEach((item) => {
            state.data[getKeyForTabAssocManageDashboard(item)] = item
        })
    }}
    ,
    extraReducers: builder => {
            buildDefaultExtraReducersWithFrontPagination(
                addItemToDashboard,
                builder,
                getManageDashboard
            )  
        }
})

export const {
    tableReducer,
    setFiltered,
    setFilterBy,
    setSortBy,
    deleteReducer,
    setNbByPage,
    requestPage,
    updateDashboard,
} = manageDashboardSlice.actions

export default manageDashboardSlice.reducer