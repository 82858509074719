import React, { Suspense } from 'react';

import './App.scss';

import {
  BrowserRouter as Router
} from "react-router-dom";

import AppSecurityRouter from './AppSecurityRouter';
import SWUpdateHandler from './common/components/SWUpdateHandler';
import Loader from './common/components/Loader';

const App: React.FC = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Router>
                {/* Component to check service worker update at each navigate event */}
                <SWUpdateHandler />
                {/* Application security router */}
                <AppSecurityRouter />
            </Router>
        </Suspense>
    );
}

export default App;
