import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/x-circle.svg";
import Loader from "../../../../common/components/Loader";
import useFilterToolkit from "../../../../common/utils/useFilterToolkit";
import { RootState } from "../../../../state/rootReducer";
import { Data } from "../../../../types/types";
import CustomPagination from "../../../../common/components/CustomPagination";
import { AppDispatch } from "../../../../state/store";
import MatrixApi, { MatrixExportsFlat } from "../../../../api/matrixApi";
import CellWithTranslation from "../../../../common/components/CellWithTranslation";
import { fetchMatrixExports, setMatrixExportsFilterBy, setMatrixExportsFiltered, setMatrixExportsSortBy, requestPage, setNbByPage, purgeReducer } from "../../../../state/matrixExportsSlice";
import { fetchCompaniesByFormulation } from "../../../../state/companiesSlice";


interface MatrixExportsListProps extends WithTranslation {
    currentLaboratoryId: number;
}

const MatrixExportsList: React.FC<MatrixExportsListProps> = ({
    t,
    currentLaboratoryId,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { exportFormatLabels, language } = useSelector((state: RootState) => state.local)

    const { allowedPageSizes } = useSelector(
        (state: RootState) => state.local
    );


    // authUser

     // user state
     const authUser = useSelector((state: RootState) => state.authUser.user)

    // companies
    const companies = useSelector((state: RootState) => state.companies.data)
    const companiesStatus = useSelector((state: RootState) => state.companies.fetchStatus)
    const companiesByFormulation = useSelector( (state: RootState) => state.companies.byFormulation )

    // ref-analytes

    const refAnalytes = useSelector((state: RootState) => state.refAnalytes.data)

    //matrix exports state
    const matrixExports: Data<MatrixExportsFlat> = useSelector(
        (state: RootState) => state.matrixExports.data
    );
    const matrixExportsByLabId: Array<String> = useSelector(
        (state: RootState) => state.matrixExports.byLabId[currentLaboratoryId]
    );
    const filteredMatrixExports = useSelector(
        (state: RootState) => state.matrixExports.filtered
    );
    const pagination = useSelector(
        (state: RootState) => state.matrixExports.pagination
    );
    const [apiError, setApiError] = useState<unknown>(undefined)


    // Filter
    const { ListHeader, filterAndSortList, FilterList } = useFilterToolkit(
        pagination.filterBy,
        pagination.sortBy,
        {
            i18nPrefix: "matrix.matrix_exports.columns.",
            onlyActive: false,
            fields: {
                name: {
                    type: ["filter"],
                    sortable: true
                },
                crit_company: {
                    type: ["filter"],
                    sortable: true
                },
                format: {
                    type: ["list"],
                    sortable: true,
                    inOpt: {
                        raw: {
                            elements: exportFormatLabels
                        },
                        multipleValues: false
                    }
                },
                analyte_id: {
                    type: ["filter"],
                    sortable: true
                },
                export_name: {
                    type: ["filter"],
                    sortable: true
                },
                export_id: {
                    type: ["filter"],
                    sortable: true
                },
                export_unit: {
                    type: ["filter"],
                    sortable: true
                },
                export_conversion_factor: {
                    type: ["number"],
                    sortable: true
                },
                cargill_only: {
                    type: ["boolean"],
                    sortable: true
                }
            },
            reducers: {
                filterBy: setMatrixExportsFilterBy,
                sortBy: setMatrixExportsSortBy,
            },
        }
    );

    useEffect(() => {
        if (companiesStatus === "idle" || companiesStatus === "succeeded" && (!companiesByFormulation || !companiesByFormulation[currentLaboratoryId])) {
            dispatch(fetchCompaniesByFormulation(currentLaboratoryId))
        }
    }, [currentLaboratoryId])

    useEffect(() => {
        const filteredList = filterAndSortList(
            Object.values(matrixExports).filter(me => me.laboratory_id === currentLaboratoryId).map((item) => (
                {
                    ...item,
                    crit_company: item.crit_company === '*' ? '*' : item.crit_company.split('; ').map(c => companies[c]?.name || `unknown_company_id${c}`).join('#'), //unknown if user has no access to company
                    analyte_id: refAnalytes[item.analyte_id].json_name.translations ? (refAnalytes[item.analyte_id].json_name.translations[language.locale] || refAnalytes[item.analyte_id].json_name.name)  : refAnalytes[item.analyte_id].json_name.name
                }))
        );

        dispatch(setMatrixExportsFiltered(filteredList));
        dispatch(requestPage(1));
        dispatch(setNbByPage(pagination.nbByPage));
    }, [
        matrixExports,
        currentLaboratoryId,
        pagination.sortBy,
        pagination.filterBy
    ]);

    const [show, setShow] = useState(false);
    const [matrixOutputKey, setMatrixOutPutKey] = useState<{
        laboratory_id: number,
        name: string,
        analyte_id: string
    }>({
        laboratory_id: currentLaboratoryId,
        name: '',
        analyte_id: ''
    });
    const [showDelete, setShowDelete] = useState(false);

    const [processing, setProcessing] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseDelete = () => setShowDelete(false);

    const handleShowDelete = () => setShowDelete(true);

    const message = t("forms_errors.unexpected_error");

    function launchDelete(lab_id: number, name: string, analyteId: string) {
        setMatrixOutPutKey({
            analyte_id: analyteId,
            laboratory_id: lab_id,
            name: name
        })
        handleShowDelete();
    }


    async function deleteMatrixExportsOutputOnClick() {
        handleCloseDelete();
        setProcessing(true);
        try {
            dispatch(purgeReducer())
            await MatrixApi.getInstance().deleteMatrixExportsOutput(matrixOutputKey.laboratory_id, matrixOutputKey.name, matrixOutputKey.analyte_id);
            dispatch(fetchMatrixExports())
            
        } catch (err) {
            handleShow();
        } finally {
            setProcessing(false);
        }
    }

    return (
        <Container className="mt-2 container-xxl">
            {processing && <Loader />}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("error")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("buttons.disable")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("matrix.matrix_exports.msg_delete")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        {t("buttons.cancel")}
                    </Button>
                    <Button variant="primary" onClick={deleteMatrixExportsOutputOnClick}>
                        {t("buttons.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>


            <Row className="mt-2 mx-1">
                <Col>
                    <FilterList />
                </Col>
            </Row>


            <Table
                striped
                hover
                responsive="lg"
                className="mt-2 bg-white text-center"
            >
                <thead>
                    <tr>
                        <th className="p-1" />
                        <th><ListHeader name="name" /></th>
                        <th><ListHeader name="crit_company" /></th>
                        <th><ListHeader name="format" /></th>
                        <th><ListHeader name="analyte_id" /></th>
                        <th><ListHeader name="export_name" /></th>
                        <th><ListHeader name="export_id" /></th>
                        <th><ListHeader name="export_unit" /></th>
                        <th><ListHeader name="export_conversion_factor" /></th>
                        <th><ListHeader name="cargill_only" /></th>
                        <th>{t("matrix.matrix_exports.columns.actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredMatrixExports
                            .slice(
                                (pagination.currentPage - 1) *
                                pagination.nbByPage,
                                (pagination.currentPage - 1) *
                                pagination.nbByPage +
                                pagination.nbByPage
                            )
                            .map((id) => {
                                return (
                                    <tr key={id}>
                                        <td className="p-1 table-hover-hint" />
                                        <td>{matrixExports[id].name}</td>
                                        {matrixExports[id].crit_company === '*' ?(
                                            <td>
                                                {matrixExports[id].crit_company}
                                            </td>):(
                                                <td>
                                                    {matrixExports[id].crit_company.split('; ').map((c) => {
                                                        return (
                                                            <div>{companies[c]?.name || `id ${c} (privilege issue)`}</div>
                                                        )
                                                    })}

                                                </td>
                                            )
                                        }
                                        
                                        <td>
                                            {matrixExports[id].format}
                                        </td>
                                        <td className="text-primary fw-bold">
                                        <CellWithTranslation
                                            placement = "top"
                                            headerName={t('matrix.matrix_exports.columns.analyte_id')}
                                            id={id}
                                            json_name={refAnalytes[matrixExports[id].analyte_id].json_name}
                                        />
                                        </td>
                                        <td>
                                            {matrixExports[id].export_name}
                                        </td>
                                        <td>
                                            {matrixExports[id].export_id}
                                        </td>
                                        <td> {matrixExports[id].export_unit}</td>
                                        <td>{Intl.NumberFormat(authUser.locale).format(matrixExports[id].export_conversion_factor)}</td>
                                        <td>  {matrixExports[id]
                                                                .cargill_only
                                                                ? t("yes")
                                                                : t("no")}
                                                        </td>

                                        <td className="text-nowrap">
                                            <Button
                                                className="p-0 pe-1 text-danger"
                                                variant="link"
                                                onClick={launchDelete.bind(null, matrixOutputKey.laboratory_id, matrixExports[id].name, matrixExports[id].analyte_id)}
                                            >
                                                <DeleteIcon
                                                    width="24px"
                                                    height="100%"
                                                    className="align-text-top"
                                                />
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })
                    }

                </tbody>
            </Table>
            <CustomPagination
                nbPages={pagination.nbPage}
                curPage={pagination.currentPage}
                nbNeighbours={1}
                curPageSize={pagination.nbByPage}
                allowedPageSizes={allowedPageSizes}
                goToPage={(page) => {
                    dispatch(requestPage(page));
                }}
                setPageSize={(nbPage) => {
                    dispatch(setNbByPage(nbPage));
                    dispatch(requestPage(1));
                }}
            />
        </Container>
    );
};

export default withTranslation()(MatrixExportsList);


