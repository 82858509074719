import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/x-circle.svg";
import Loader from "../../../../common/components/Loader";
import useFilterToolkit from "../../../../common/utils/useFilterToolkit";
import { RootState } from "../../../../state/rootReducer";
import { Data } from "../../../../types/types";
import CustomPagination from "../../../../common/components/CustomPagination";
import { AppDispatch } from "../../../../state/store";
import MatrixApi, { MatrixTemplate, MatrixTemplateInput } from "../../../../api/matrixApi";
import CellWithTranslation from "../../../../common/components/CellWithTranslation";
import { desactivateMatrixTemplateInput, setMatrixTemplateInputsFilterBy, setMatrixTemplateInputsFiltered, setMatrixTemplateInputsSortBy, setNbByPage, requestPage, fetchExportMatrixTemplateInputs, fetchMatrixTemplateInputs } from "../../../../state/matrixTemplateInputSlice";

interface MatrixTemplateInputsListProps extends WithTranslation {
    currentLaboratoryId: number;
}

const MatrixTemplateInputsList: React.FC<MatrixTemplateInputsListProps> = ({
    t,
    currentLaboratoryId,
}) => {
    const dispatch = useDispatch<AppDispatch>();

    const { allowedPageSizes } = useSelector(
        (state: RootState) => state.local
    );

    const matrixTemplates: Data<MatrixTemplate> = useSelector(
        (state: RootState) => state.matrixTemplates.data
    );
    
    const matrixTemplateInputs: Data<MatrixTemplateInput> = useSelector(
        (state: RootState) => state.matrixTemplateInputs.data
    );
    const pagination = useSelector(
        (state: RootState) => state.matrixTemplateInputs.pagination
    );

    // Filter
    const { ListHeader, filterAndSortList, FilterList } = useFilterToolkit(
        pagination.filterBy,
        pagination.sortBy,
        {
            i18nPrefix: "matrix.matrix_template_inputs.columns.",
            onlyActive: true,
            fields: {
                matrix_id: {
                    type: ["filter"],
                    sortable: true
                },
                category: {
                    type: ["filter"],
                    sortable: true
                },
                sub_category: {
                    type: ["filter"],
                    sortable: true
                },
                raw_material: {
                    type: ["filter"],
                    sortable: true
                },
                analyte_id: {
                    type: ["filter"],
                    sortable: true
                },
                order: {
                    type: ["filter"],
                    sortable: true
                },
                analyte_name: {
                    type: ["filter"],
                    sortable: true
                },
                is_toggle: {
                    type: ["boolean"],
                    sortable: true
                },
                unit: {
                    type: ["filter"],
                    sortable: true
                },
                group: {
                    type: ["filter"],
                    sortable: true
                },
                max_group: {
                    type: ["filter"],
                    sortable: true
                },
                min_value: {
                    type: [],
                    sortable: false
                },
                max_value: {
                    type: [],
                    sortable: false
                },
                default_value: {
                    type: [],
                    sortable: false
                },
            },
            reducers: {
                filterBy: setMatrixTemplateInputsFilterBy,
                sortBy: setMatrixTemplateInputsSortBy,
            },
        }
    );

    const [laboratoryId, setLaboratoryId] = useState(currentLaboratoryId);

    const [show, setShow] = useState(false);
    const [matrixId, setId] = useState(0);
    const [analyteId, setAnalyteId] = useState("");

    const [showDelete, setShowDelete] = useState(false);

    const [processing, setProcessing] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseDelete = () => setShowDelete(false);

    const handleShowDelete = () => setShowDelete(true);

    const message = t("forms_errors.unexpected_error");

    function launchDelete(labId: number, id: number, analyteId: string) {
        setLaboratoryId(labId);
        setId(id);
        setAnalyteId(analyteId)
        handleShowDelete();
    }

    async function desactivateMatrixTemplateInputOnClick() {
        handleCloseDelete();
        setProcessing(true);
        try {
            await dispatch(
                desactivateMatrixTemplateInput({
                    laboratoryId: laboratoryId,
                    id: matrixId,
                    analyteId: analyteId
                })
            ).unwrap();
        } catch (err) {
            console.log(err)
            handleShow();
        } finally {
            setProcessing(false);
        }
    }

    return (
        <Container className="mt-2 container-xxl">
            {processing && <Loader />}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("error")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("buttons.cancel")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("buttons.disable")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("matrix.matrix_template_inputs.msg_delete")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                        {t("buttons.cancel")}
                    </Button>
                    <Button variant="primary" onClick={desactivateMatrixTemplateInputOnClick}>
                        {t("buttons.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row className="mt-2 mx-1">
                <Col>
                    <FilterList />
                </Col>
            </Row>

            <Table
                striped
                hover
                responsive="lg"
                className="mt-2 bg-white text-center"
            >
                <thead>
                    <tr>
                        <th className="p-1" />
                        <th><ListHeader name="matrix_id" /></th>
                        <th><ListHeader name="category" /></th>
                        <th><ListHeader name="sub_category" /></th>
                        <th><ListHeader name="raw_material" /></th>
                        <th><ListHeader name="order" /></th>
                        <th><ListHeader name="analyte_id" /></th>
                        <th><ListHeader name="analyte_name" /></th>
                        <th><ListHeader name="is_toggle" /></th>
                        <th><ListHeader name="unit" /></th>
                        <th><ListHeader name="group" /></th>
                        <th><ListHeader name="max_group" /></th>
                        <th><ListHeader name="min_value" /></th>
                        <th><ListHeader name="max_value" /></th>
                        <th><ListHeader name="default_value" /></th>
                        <th>{t("matrix.matrix_template_inputs.columns.actions")}</th>
                    </tr>
                </thead>
                <tbody>
                {pagination.pages[pagination.currentPage] && !pagination.pages[pagination.currentPage].fetching && pagination.pages[pagination.currentPage].ids.map((id) => {
                    if(matrixTemplateInputs[id]){
                                return (
                                    <tr key={id}>
                                        <td className="p-1 table-hover-hint" />
                                        <td>
                                            {matrixTemplateInputs[id].matrix_id}
                                        </td>
                                        <td>
                                            {matrixTemplateInputs[id].category}
                                        </td>
                                        <td>
                                            {matrixTemplateInputs[id].sub_category}
                                        </td>
                                        <td>
                                            {matrixTemplateInputs[id].raw_material}
                                        </td>
                                        <td>
                                            {matrixTemplateInputs[id].order}
                                        </td>
                                        <td>
                                            {matrixTemplateInputs[id].analyte_id}
                                        </td>
                                        <td>
                                            <CellWithTranslation
                                                headerName={t(
                                                    "matrix.matrix_template_inputs.columns.translation_analyte_name"
                                                )}
                                                id={id}
                                                json_name={
                                                    matrixTemplateInputs[id].analyte_name
                                                }
                                            />
                                        </td>
                                        <td>
                                            {String(matrixTemplateInputs[id].is_toggle)}                                        </td>
                                        <td>
                                            {matrixTemplateInputs[id].unit}
                                        </td>
                                        <td>
                                            <CellWithTranslation
                                                headerName={t(
                                                    "matrix.matrix_template_inputs.columns.translation_analyte_name"
                                                )}
                                                id={id}
                                                json_name={
                                                    matrixTemplateInputs[id].group
                                                }
                                            />
                                        </td>
                                        <td>
                                            {matrixTemplateInputs[id].max_group}
                                        </td>
                                        <td>
                                            {matrixTemplateInputs[id].min_value}
                                        </td>
                                        <td>
                                            {matrixTemplateInputs[id].max_value}
                                        </td>
                                        <td>
                                            {matrixTemplateInputs[id].default_value}
                                        </td>
                                        <td className="text-nowrap">
                                            <Button
                                                className="p-0 pe-1 text-danger"
                                                variant="link"
                                                onClick={launchDelete.bind(null, laboratoryId, matrixTemplateInputs[id].matrix_id, matrixTemplateInputs[id].analyte_id)}
                                            >
                                                <DeleteIcon
                                                    width="24px"
                                                    height="100%"
                                                    className="align-text-top"
                                                />
                                            </Button>
                                        </td>
                                    </tr>
                                );
                        }
                })
                }

                </tbody>
            </Table>
            <CustomPagination
                nbPages={pagination.nbPage}
                curPage={pagination.currentPage}
                nbNeighbours={1}
                curPageSize={pagination.nbByPage}
                allowedPageSizes={allowedPageSizes}
                goToPage={(page) => { dispatch(fetchMatrixTemplateInputs({laboratoryId, page})); }}
                setPageSize={(nbPage) => { dispatch(setNbByPage(nbPage)); dispatch(fetchMatrixTemplateInputs({laboratoryId, page: 1}));}}
            />
        </Container>
    );
};

export default withTranslation()(MatrixTemplateInputsList);


