import React, { useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'

import { Modal, Container, Form, Button, Row, FormSelect, Col } from 'react-bootstrap'

import { Formik, FormikProps, Field, getIn } from 'formik'

import { ExportFileType, ExportFormat, ExportRequest, SampleType } from '../../../api/publishApi'
import { ExportRequestFront } from './ExportExcelContainer'
import Loader from '../../../common/components/Loader'
import { t } from 'i18next'

import { RootState } from '../../../state/rootReducer'

interface ModalExportExcelProps extends WithTranslation {
    exportRequestFront: ExportRequestFront
    submitValues: (data: ExportRequest) => void
    setShowExportExcel: (show: boolean) => void
    showExportExcel: boolean
    nbResults: number
}

interface IFormValues {
    exportFormat: ExportFormat
    exportFileType: ExportFileType
}

const EXCEL_MAX_RESULTS = 50000

const ModalExportExcel: React.FC<ModalExportExcelProps> = ({ exportRequestFront, submitValues, setShowExportExcel, showExportExcel, nbResults }: ModalExportExcelProps) => {
    const { exportFileTypeLabels } = useSelector((state: RootState) => state.local)

    const [formSubmitted, setFormSubmitted] = useState(false)

    return (
        <Container>
            {!formSubmitted && (
                <Modal size="lg" animation={true} show={showExportExcel} onHide={() => setShowExportExcel(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('modal_export_excel.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                exportFormat: 'summary',
                                exportFileType: nbResults > EXCEL_MAX_RESULTS ? "csv" : "xlsx",
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                // When button submits form and form is in the process of submitting, submit button is disabled
                                setSubmitting(true)
                                try {
                                    const exportRequest: ExportRequest = {
                                        filter: {
                                            companies:
                                                exportRequestFront.companies.length === 0
                                                    ? null
                                                    : {
                                                        in: exportRequestFront.companies,
                                                    },
                                            families:
                                                exportRequestFront.families.length === 0
                                                    ? null
                                                    : {
                                                        in: exportRequestFront.families,
                                                    },
                                            laboratory_id: exportRequestFront.laboratoryId,
                                            sample_types:
                                                exportRequestFront.type_codes.length === 0
                                                    ? null
                                                    : {
                                                        in: exportRequestFront.type_codes as SampleType[],
                                                    },
                                            nutrients:
                                                exportRequestFront.analytes.length === 0
                                                    ? null
                                                    : {
                                                        in: exportRequestFront.analytes,
                                                    },
                                            analysis_ids: exportRequestFront.analysis_ids.length === 0
                                                ? null
                                                : {
                                                    in: exportRequestFront.analysis_ids
                                                }
                                            ,
                                            period: {
                                                lte: exportRequestFront.startDate ? new Date(exportRequestFront.startDate).toISOString() : null,
                                                gte: exportRequestFront.endDate ? new Date(exportRequestFront.endDate).toISOString() : null,
                                            }
                                        },
                                        output: {
                                            file_type: values.exportFileType,
                                            format: values.exportFormat,
                                        },
                                    }
                                    submitValues(exportRequest)
                                    setShowExportExcel(false)
                                } catch (err) {
                                    console.error('Unexpected error while submitting companies selection', err)
                                } finally {
                                    setFormSubmitted(false)
                                }
                            }}
                        >
                            {/* Callback function containing Formik state and helpers that handle common form actions */}
                            {(props: FormikProps<IFormValues>) => {
                                const { values, errors, touched, handleSubmit, handleChange, isSubmitting, setFieldValue } = props

                                return (
                                    <Form onSubmit={handleSubmit}>
                                        {isSubmitting && <Loader />}
                                        <Form.Group controlId="formFormat">
                                            <div className="mb-2">{t('modal_export_excel.sub_title')}</div>

                                            <Row className ="m-0 p-0">
                                                <Col onClick={
                                                    () => setFieldValue('exportFormat', "complete")
                                                } className="w-25 border rounded p-3 m-1 ms-0">
                                                    <Field label="" name="exportFormat" type="radio" value="complete"/>

                                                    <Row></Row>
                                                    <strong>{t('modal_export_excel.forms.title_complete_dataset')}</strong>

                                                    <div className="mt-1">{t('modal_export_excel.forms.text_complete_dataset')}</div>
                                                </Col>

                                                <Col onClick={
                                                    () => setFieldValue('exportFormat', "summary")
                                                } className=" w-25 border rounded p-3 m-1">
                                                    <Field label="" name="exportFormat" type="radio" value="summary" />

                                                    <Row></Row>

                                                    <strong>{t('modal_export_excel.forms.title_summarized_dataset')}</strong>

                                                    <div className="mt-1">{t('modal_export_excel.forms.text_summarized_dataset')}</div>
                                                </Col>

                                                <Col 
                                                  onClick={
                                                    () => setFieldValue('exportFormat', "vertical-table")
                                                }
                                                className=" m-1 w-25 border rounded p-3">
                                                    
                                                    <Field label="" name="exportFormat" type="radio" value="vertical-table"/>

                                                    <Row></Row>
                                                    <strong>{t('modal_export_excel.forms.title_vertical_table')}</strong>

                                                    <div className="mt-1">{t('modal_export_excel.forms.text_vertical_table')}</div>
                                                </Col>

                                                <Col 
                                              onClick={
                                                () => setFieldValue('exportFormat', "horizontal-table")
                                            }
                                                className="m-1 w-25 border rounded p-3">
                                                    
                                                    <Field label="" name="exportFormat" type="radio" value="horizontal-table"/>

                                                    <Row></Row>
                                                    <strong>{t('modal_export_excel.forms.title_horizontal_table')}</strong>

                                                    <div className="mt-1">{t('modal_export_excel.forms.text_horizontal_table')}</div>
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        {/* To compensate marginLeft of radio buttons*/}
                                        <Form.Group controlId="formExportFileType">
                                            <Row className='m-0 p-0'>
                                                <Col className="p-0 pt-1">
                                                    <Form.Label>{t('modal_export_excel.forms.file_type')} *</Form.Label>
                                                    <FormSelect name="exportFileType" value={values.exportFileType} onChange={handleChange} isInvalid={getIn(touched, `exportFileType`) && !!getIn(errors, `exportFileType`)}>
                                                        {Object.keys(exportFileTypeLabels).filter(id => nbResults > EXCEL_MAX_RESULTS ? id !== "Excel" : true ).map((id) => {
                                                            return (
                                                                <option value={exportFileTypeLabels[id]} key={id}>
                                                                    {id}
                                                                </option>
                                                            )
                                                        })}
                                                    </FormSelect>
                                                    <Form.Text className="invalid-feedback d-block">{errors.exportFileType}</Form.Text>
                                                    {nbResults > EXCEL_MAX_RESULTS && 
                                                        <Form.Text className="text-warning">{t('export_screen.popover.body_results_limit')}</Form.Text>
                                                    }
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Row className='m-0 p-0'>
                                            <Col className="text-end p-0 pt-1">
                                                <Button disabled={isSubmitting} variant="secondary" type="submit">
                                                    {t('buttons.validate')}
                                                </Button>
                                            </Col>

                                        </Row>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Modal.Body>
                </Modal>
            )}
        </Container>
    )
}

export default withTranslation()(ModalExportExcel)
